import React, { useRef } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { PracticeState } from '@components/PracticeV2/types';
import {
  useIsQuestionLocked,
  usePractice,
} from '@components/PracticeV2/Provider/PracticeProvider';
import GroupDataView from '../GroupDataView';

const AnswerShortText = () => {
  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const { handleAnswerChange, currentAnswer, state } = usePractice();
  const isQuestionLocked = useIsQuestionLocked();
  const handleChange = (ev: { target: { value: string | number } }) => {
    if (isQuestionLocked) {
      return;
    }
    if (state.practiceState === PracticeState.Group) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      // jest doesn't support NodeJS.Timeout
      debounceRef.current = setTimeout(
        () => handleAnswerChange?.(ev.target.value),
        600,
      ) as unknown as NodeJS.Timeout;
    } else {
      handleAnswerChange?.(ev.target.value);
    }
  };
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      data-testid="answer-short-text"
    >
      <TextField
        disabled={isQuestionLocked}
        placeholder="enter your answer here"
        variant="outlined"
        size="small"
        autoFocus
        defaultValue={currentAnswer}
        onChange={handleChange}
        data-testid="short-text-input"
      />
      <GroupDataView />
    </Box>
  );
};

export default AnswerShortText;
