import Cookies from 'universal-cookie';

export const retrieveCachedCourseAndSection = () => {
  const cookies = new Cookies();
  const retrieveSession = decodeURI(cookies.get('sessionHistory'));
  const final = retrieveSession === 'undefined' ? '{}' : retrieveSession;
  const parsedSession = JSON.parse(final || '{}');
  const userLoggedIn = localStorage.getItem('ajs_user_id');
  const cachedCourseId =
    parsedSession[userLoggedIn]?.courseId === null
      ? ''
      : parsedSession[userLoggedIn]?.courseId;
  const cachedSectionId =
    parsedSession[userLoggedIn]?.sectionId === null
      ? ''
      : parsedSession[userLoggedIn]?.sectionId;

  return {
    cachedCourseId,
    cachedSectionId,
  };
};

export const setSectionOrCourse = (sectionId, courseId = null) => {
  const cookies = new Cookies();
  const sessionHistory = decodeURI(cookies.get('sessionHistory')| '{}');
  const userLoggedIn = localStorage.getItem('ajs_user_id');
  const parsedSession = JSON.parse(sessionHistory);
  return cookies.set(
    'sessionHistory',
    encodeURI(
      JSON.stringify({ ...parsedSession, [userLoggedIn]: { courseId, sectionId } }),
    ),
    { path: '/', sameSite: 'none', secure: true },
  );
};

export const checkAndSetSessionHistory = (
  courseSelected = null,
  sectionSelected = null,
) => {
  const cookies = new Cookies();
  const userLoggedIn = localStorage.getItem('ajs_user_id');
  const sessionHistory = cookies.get('sessionHistory');
  const secure =  window.location.origin.includes("feature-env") ? {path: '/'} : {path: '/', sameSite: 'none', secure: true};
  if (sessionHistory == undefined) {
    cookies.set(
      'sessionHistory',
      encodeURI(
        JSON.stringify({
          [userLoggedIn]: { courseId: courseSelected, sectionId: sectionSelected },
        }),
      ),
      secure,
    );
  }
};
