/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
// TODO cache?
import {
  SkillRecord,
  StandardRecord,
} from '@components/Component/Lesson/LessonContainer/Types';
import shuffle from './utils/shuffle';
import stringToSeed from './utils/stringToSeed';
import rateLimiter from './utils/rateLimiter';

interface Question {
  id: string;
  reference: string;
  organisation_id?: number;
  standards?: string[];
  difficulty: string | null;
  depthOfKnowledge?: string;
  bloom: string | null;
  skill?: SkillRecord;
  depth_of_knowledge?: string;
}

interface LearnosityConfig {
  regions: any;
  title: string;
  subtitle?: string;
  questions_api_init_options: any;
  configuration: any;
}

export interface LearnosityMetadata {
  standards_association: Record<string, string[]>;
  difficulty: Record<string, string | null>;
  bloom: Record<string, string | null>;
  depthOfKnowledge: Record<string, string | null>;
  skill: Record<string, SkillRecord | undefined>;
}

export type LearnosityApiService = 'items' | 'assess' | 'author' | 'reports';

interface LearnosityItemsSignatureRequest {
  name: string;
  user_id: string;
  session_id: string;
  activity_id: string;
  rendering_type: 'assess' | 'inline';
  type: 'local_practice' | 'submit_practice' | 'feedback';
  state: 'review' | 'initial' | 'resume' | 'preview';
  items: Question[];
  config: LearnosityConfig;
  session_metadata: LearnosityMetadata;
}

interface LearnosityAuthoringSignatureRequest {
  mode: 'item_list' | 'item_edit';
  config: any;
  reference?: string | boolean;
}

type LearnositySignatureRequest =
  | LearnosityItemsSignatureRequest
  | LearnosityAuthoringSignatureRequest;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LearnositySignature {}
interface LearnosityUser {
  id: string;
  district_id: string;
}

const tagsToHide = [
  'ItemLogicGUID',
  'ItemLogicID',
  'ItemLogicLocalID',
  'VendorID',
  'MaxPoints',
  'Status',
  'ESTranslationVendorID',
  // 'AB GUID',
  'Item Type',
  'Generated',
  'Generation ID',
  'Author',
  'Author ID',
  'Chalktalk Subject ID',
  'Chalktalk Subject',
];

// TODO Language support
// We add "NonExistantSkillName" if the skills is empty
// So we dont end up pulling ALL questions
const getAllFilters = (skills) => {
  const filters = [{ type: 'Language', name: 'EN' }];

  if (skills.length === 0) {
    filters.push({ type: 'Skill', name: 'NonExistantSkillName' });
  }

  return filters;
};

export const convertQuestionToCamelCase = (question: Question): Question => {
  const newQuestion = { ...question };
  newQuestion.depthOfKnowledge = question.depthOfKnowledge || question.depth_of_knowledge;
  delete newQuestion.depth_of_knowledge;
  return newQuestion;
};

// https://reference.learnosity.com/author-api/initialization/config.item_list
const createAuthoringSignatureRequest: (
  user: LearnosityUser,
  skills: Array<SkillRecord>,
) => LearnosityAuthoringSignatureRequest = (user, skills) => ({
  organisation_id: Number(
    process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 653,
  ), // chalktalk-prod
  mode: 'item_list',
  config: {
    global: {
      hide_tags: tagsToHide.map((tag) => ({ type: tag })),
      ab_standards: {
        enable: true,
        aligned_tag_type: 'AB GUID',
        tag_standard_hierarchy: false,
        edit_standards: false,
        filter: false,
      },
    },
    item_list: {
      filter: {
        restricted: {
          status: ['published'],
          tags: {
            all: getAllFilters(skills),
            either: skills.map((skill) => ({ type: 'Skill', name: skill.slug })),
            none: [
              { type: 'Item Type', name: 'ItemOnly' },
              { type: 'Generated', name: '1' },
            ],
          },
        },
      },
      item: {
        enable_selection: true,
        status: false,
        // url: "?questions[':reference']",
        title: {
          show_reference: true,
          show: true,
        },
      },
      limit: 10, // items per page
      toolbar: {
        add: false,
        search: {
          status: false, // disable searching by status
          controls: ['content'],
          tags: {
            show: true, // allow search by tags
          },
        },
      },
    },
    item_edit: {
      item: {
        actions: {
          show: true,
        },
        answers: true,
        columns: false,
        duplicate: {
          show: false,
        },
        save: {
          persist: false,
          show: false,
        },
        mode: {
          default: 'preview',
          show: false,
        },
      },
      widget: {
        edit: false,
        delete: false,
      },
    },
  },
  user: {
    id: 'demos-site',
    firstname: 'Demos',
    lastname: 'User',
    email: 'demos@learnosity.com',
  },
});

const createReviewQuestionSignatureRequest: (
  reference: string,
) => LearnosityAuthoringSignatureRequest = (reference) => ({
  organisation_id: Number(
    process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 654,
  ), // chalktalk-dev
  mode: 'item_edit',
  reference,
  config: {
    global: {
      hide_tags: tagsToHide.map((tag) => ({ type: tag })),
    },
    dependencies: {
      questions_api: {
        preload: true,
        init_options: {
          show_distractor_rationale: true,
        },
      },
    },
    item_edit: {
      item: {
        actions: {
          show: true,
        },
        answers: true,
        columns: false,
        duplicate: {
          show: false,
        },
        save: {
          persist: false,
          show: false,
        },
        mode: {
          default: 'preview',
          show: false,
        },
        back: false,
      },
      widget: {
        edit: false,
        delete: false,
      },
    },
  },
  user: {
    id: 'demos-site',
    firstname: 'Demos',
    lastname: 'User',
    email: 'demos@learnosity.com',
  },
});

// configuration options for the rich text editor in CoAuthor
const coauthor_rich_text_editor_toolbar = [
  {
    items: ['Styles'],
    name: 'style',
  },
  {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '-',
      'TextColor',
      '-',
      'LrnUnderlinedIndicator',
      '-',
      'RemoveFormat',
      'FontSize',
    ],
    name: 'basicstyles',
  },
  {
    items: ['NumberedList', 'BulletedList', '-', 'Indent', 'Outdent'],
    name: 'list',
  },
  {
    items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
    name: 'justify',
  },
  {
    items: ['Link', 'Unlink'],
    name: 'link',
  },
  {
    items: ['Image', 'LrnMath', 'Table', 'Blockquote', 'SpecialChar'],
    name: 'insert',
  },
  {
    items: ['LrnSimpleFeature'],
    name: 'simplefeature',
  },
];

// Sets allowed question types for CoAuthor. We are removing non-autoscorable types for now...
// reference: https://help.learnosity.com/hc/en-us/articles/360000755038-Widget-Template-References-for-Question-Editor-Customization-Navigation
const coauthor_question_type_groups = [
  {
    reference: 'chemistry',
    template_references: [],
  },
  {
    reference: 'mcq',
    template_references: [
      '9e8149bd-e4d8-4dd6-a751-1a113a4b9163', // Multiple Choice
      '908de244-5c71-4c09-b094-7fb49554f2f9', // Multiple Choice - Multiple Response
      '3egs0b24-5gs8-49fc-fds9-4a450sdg31ca', // Multiple Choice - Block Layout
      '33d53a22-1a59-4a03-9671-7f5104edd62e', // Multiple Choice - Image
      'e4bd7256-1d6f-4e2e-9f37-3c4b9f0c24c2', // True/False
      'b1c95dc4-6d9e-4c63-8c61-3c1d6f8d6e2a', // Yes/No
    ],
  },
  {
    reference: 'match',
    template_references: [
      'ef2648de-f826-4674-b17d-71e71889d8e6', // Match List
      '1fa22aac-1f88-47f7-941b-3c77759549e6', // Match Table
      '0c7f68f7-2be6-4ae0-8492-0ab78c8d0010', // Order List
      '63891006-450a-4ffe-9876-74d62efa0152', // Classification
      'd7e4b8a2-1c6f-4e9a-8b3d-5f2c9e4d1b7a', // Sort List
    ],
  },
  {
    reference: 'cloze',
    template_references: [
      '51a8c1e7-f34f-4faf-b211-da458e891fcb', // Cloze with Dropdown
      '2fbba51b-e35e-441f-83c7-2662e2e81fa6', // Cloze with Text
      '457fe101-0667-4a35-b193-b849653acb52', // Cloze with Drag & Drop
      '6e77b403-8f0c-43af-b464-9450e1ac70dc', // Cloze Association
      'f8364191-ada5-4806-83d4-8a36b8fad4b0', // Token Highlight
      '35a850a7-9d3f-4e1c-880a-a340767942b6', // Fill in the Blanks
    ],
  },
  {
    reference: 'highlight',
    template_references: [
      '47f0db98-79e8-4273-946f-90faf4a2ae49', // Highlight Text
      'f0416e39-72fc-4d40-8d6e-c6f97e621fd4', // Highlight Image
      'a1b2c3d4-e5f6-4g7h-8i9j-0k1l2m3n4o5p', // Hot Spot
    ],
  },
  {
    reference: 'math',
    template_references: [
      'ef0ab0ba-235f-459d-9380-a4a106280c9a', // Math Formula
      '7ebe931e-06b8-4fc8-95d2-c7ec4fbf1239', // Math Matrix
      '76504b1a-8f37-4ab8-bcbf-875c966c72af', // Math Simple
      '57b0690f-4e99-4bbc-9c8f-aa3074baa495', // Math Equation Editor
      '11c9093a-dfd3-4852-b1ee-d5e5c3f032df', // Number Line
      '7b810c2c-9100-4ff1-96de-cda23bf34445', // Numerical Response
      '17149c09-83ba-4b1b-afff-a681e7edd8ff', // Math Expression
    ],
  },
  {
    reference: 'graph',
    template_references: [
      'b7dc8b69-c12a-4c4f-ac17-181ac6e0da26', // Graph Plot Points
      'f325a114-bb81-4e7f-86e0-1147416a78a8', // Graph Line
      'f5c683be-b5e0-421d-a593-f6a59312aba2', // Graph Shape
      '02a2d2fe-c028-42d0-b139-e58a60780928', // Graph Function
      'c1d2e3f4-5g6h-7i8j-9k0l-1m2n3o4p5q6r', // Graph Transformation
    ],
  },
  {
    reference: 'chart',
    template_references: [
      '24f76ba0-24af-4be4-aacf-c31c6efd33e3', // Bar Chart
      '8c28ecfd-ab0b-43de-bfac-e572eeb06851', // Line Chart
      'cb9cb540-05b2-44e0-bef3-1b31727c1c37', // Pie Chart
      '2f93c2f1-f7d9-423a-94c9-5df5aabdddc9', // Box Plot
      '1f20bb6e-8685-4eaa-9c70-ef80b811e483', // Histogram
    ],
  },
  {
    reference: 'writespeak',
    template_references: ['4fe6c726-419c-4aff-b189-4a9756a0ffda'],
  },
  {
    reference: 'other',
    template_references: [
      'f0416e39-72fc-4d40-8d6e-c6f97e621fd5', // Label Image
      'd4e5f6g7-h8i9-j0k1-l2m3-n4o5p6q7r8s9', // Token Highlight
      'a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6', // Hottext
    ],
  },
];

const createCoAuthorItemRequest: (userId: string) => LearnosityAuthoringSignatureRequest =
  (userId) => ({
    organisation_id: Number(
      process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 654, // default chalktalk-dev
    ),
    mode: 'item_edit',
    reference: '---', // dont instantiate an item upon load
    config: {
      widget_config: {
        require_validation: true,
      },
      item_edit: {
        mode: {
          default: 'preview',
        },
        widget: {
          edit: true,
          delete: false,
        },
        settings: {
          show: false,
        },
        item: {
          actions: {
            show: true,
          },
          details: {
            source: {
              show: false,
            },
          },
          answers: true,
          back: false,
          columns: false,
          duplicate: {
            show: false,
          },
          mode: {
            default: 'preview',
            show: true,
          },
          tags: {
            show: false,
          },
          save: {
            persist: false, // prevent saving directly to itembank!!
            show: true,
          },
          shared_passage: false, // we dont want users picking passages within the item
        },
      },
      dependencies: {
        questions_api: {
          init_options: {
            show_distractor_rationale: true,
          },
        },
        question_editor_api: {
          init_options: {
            ui: {
              source_button: false,
            },
            rich_text_editor: {
              toolbar_settings: {
                ltr_toolbar: coauthor_rich_text_editor_toolbar,
              },
            },
            question_type_groups: coauthor_question_type_groups,
          },
        },
      },
    },
    user: {
      id: `${userId}`,
    },
  });

const createPassageRequest: (
  user_id: string,
  mode: string,
) => LearnosityAuthoringSignatureRequest = (userId, mode) => ({
  organisation_id: Number(
    process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 654, // default chalktalk-dev
  ),
  reference: '----',
  mode: 'item_edit',
  config: {
    item_edit: {
      item: {
        actions: {
          show: true,
        },
        answers: true,
        columns: false,
        duplicate: {
          show: false,
        },
        save: {
          persist: false,
          show: false,
        },
        mode: {
          default: mode,
          show: false,
        },
      },
      widget: {
        edit: false,
        delete: false,
      },
    },
  },
  user: {
    id: `${userId}`,
  },
});

const createPassageItemsListRequest: (
  passageReference: string,
  userId: string,
) => LearnosityAuthoringSignatureRequest = (passageReference, userId) => ({
  organisation_id: Number(
    process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 654, // default chalktalk-dev
  ),
  mode: 'item_list',
  config: {
    dependencies: {
      questions_api: {
        init_options: {
          show_distractor_rationale: true,
        },
      },
    },
    item_list: {
      toolbar: {
        add: false,
      },
      filter: {
        restricted: {
          tags: {
            all: [
              { type: 'Passage Reference', name: passageReference },
              { type: 'Author ID', name: userId },
            ],
          },
        },
      },
    },
    item_edit: {
      item: {
        reference: {
          show: false,
          edit: false,
        },
        duplicate: {
          show: false,
        },
        mode: {
          default: 'preview',
          show: false,
        },
        save: false,
      },
      widget: {
        delete: false,
        edit: false,
      },
    },
  },
  user: {
    id: `${userId}`,
  },
});

const createLessonReviewAuthoringSignatureRequest: (
  skills: SkillRecord[],
  authorId?: string,
  passageReference?: string,
  standards?: StandardRecord[],
  bloom?: string,
  dok?: string,
  readability?: string,
  grade?: string,
) => LearnosityAuthoringSignatureRequest = (
  skills,
  authorId,
  passageReference,
  standards,
  bloom,
  dok,
  readability,
  grade,
) => {
  const allFilters = getAllFilters(['disable skill filter']);
  const eitherFilters: { type: string; name: string | string[] }[] = [];

  const noneTagsList = [
    { type: 'Item Type', name: 'ItemOnly' },
    { type: 'Skill', name: 'unaligned' },
  ];
  if (authorId) {
    allFilters.push({ type: 'Author ID', name: authorId.toString() });
  } else {
    noneTagsList.push({ type: 'Generated', name: '1' });
  }

  if (passageReference) {
    allFilters.push({ type: 'Passage Reference', name: passageReference });
  }

  if (standards && standards.length) {
    const ab_codes: string[] = [];
    const standard_codes: string[] = [];
    standards.forEach((standard) => {
      if (standard.ab_guid) {
        ab_codes.push(standard.ab_guid);
      } else {
        standard_codes.push(standard.code);
      }
    });
    if (ab_codes.length) {
      eitherFilters.push({ type: 'AB GUID', name: ab_codes });
    }

    if (standard_codes.length) {
      eitherFilters.push({ type: 'Standard', name: standard_codes });
    }
  }

  if (skills && skills.length) {
    eitherFilters.push({ type: 'Skill', name: skills.map((skill) => skill.slug) });
  } else {
    allFilters.push({ type: 'Skill' });
  }

  if (bloom) {
    allFilters.push({ type: 'Blooms Taxonomy', name: bloom });
  }

  if (dok) {
    allFilters.push({ type: 'Depth Of Knowledge', name: dok });
  }

  if (readability) {
    allFilters.push({ type: 'Flesch Readability', name: readability });
  }

  if (grade) {
    allFilters.push({ type: 'Flesch Kincaid Grade', name: grade });
  }

  return {
    organisation_id: Number(
      process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 653,
    ), // chalktalk-prod
    mode: 'item_list',
    config: {
      global: {
        hide_tags: tagsToHide.map((tag) => ({ type: tag })),
        ab_standards: {
          enable: false, // Disable Learnosity's standards filter
          aligned_tag_type: 'AB GUID',
          tag_standard_hierarchy: false,
          edit_standards: false,
          filter: false, // Hide the standards filter
        },
      },
      dependencies: {
        questions_api: {
          preload: true,
          init_options: {
            show_distractor_rationale: true,
          },
        },
      },
      label_bundle: {
        searchQuestionFeatureType: 'Question Type',
      },
      item_list: {
        filter: {
          restricted: {
            status: ['published'],
            tags: {
              all: allFilters,
              either: eitherFilters.length ? eitherFilters : undefined,
              none: noneTagsList,
            },
          },
        },
        item: {
          enable_selection: true,
          status: false,
          // url: "?questions[':reference']",
          title: {
            show_reference: true,
            show: true,
          },
        },
        limit: 10, // items per page
        toolbar: {
          add: false,
          search: {
            status: false, // disable searching by status
            controls: ['content'],
            tags: {
              show: false, // allow search by tags
            },
            widget_type: true,
          },
        },
      },
      item_edit: {
        item: {
          actions: {
            show: true,
          },
          answers: true,
          columns: false,
          duplicate: {
            show: false,
          },
          save: {
            persist: false,
            show: false,
          },
          mode: {
            default: 'preview',
            show: false,
          },
        },
        widget: {
          edit: false,
          delete: false,
        },
      },
    },
    user: {
      id: 'demos-site',
      firstname: 'Demos',
      lastname: 'User',
      email: 'demos@learnosity.com',
    },
  };
};
const createItemsSignatureRequest: (
  name: string,
  user_id: string,
  session_id: string,
  activity_id: string,
  rendering_type: 'assess' | 'inline',
  type: 'local_practice' | 'submit_practice' | 'feedback',
  state: 'review' | 'initial' | 'resume' | 'preview',
  items: Question[],
  config: LearnosityConfig,
) => LearnositySignatureRequest = (
  name,
  user_id,
  session_id,
  activity_id,
  rendering_type,
  type,
  state,
  items,
  config,
) => {
  // derive metadata from questions and build metadata association
  const session_metadata = items?.reduce(
    (metadata, item) => {
      /* eslint-disable no-param-reassign */
      metadata.standards_association[item.id] = item.standards;
      metadata.difficulty[item.id] = item.difficulty;
      metadata.bloom[item.id] = item.bloom;
      metadata.depthOfKnowledge[item.id] =
        item.depthOfKnowledge || item.depth_of_knowledge;
      if (item.skill) {
        metadata.skill[item.id] = item.skill;
      }
      return metadata;
    },
    {
      bloom: {},
      depthOfKnowledge: {},
      difficulty: {},
      standards_association: {},
      skill: {},
    } as LearnosityMetadata,
  );

  return {
    activity_id,
    config,
    items,
    name,
    rendering_type,
    session_id,
    session_metadata,
    state,
    type,
    user_id,
  };
};

const getSignature: (
  baseUrl: string,
  environment: string,
  signatureRequest: LearnositySignatureRequest,
  api: LearnosityApiService,
) => Promise<LearnositySignature> = async (baseUrl, environment, signatureRequest, api) =>
  fetch(`${baseUrl}/signature?environment=${environment}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ api, request: signatureRequest }),
  }).then((res) => res.json());

// TODO error handling
const getSessionQuestions: (
  baseUrl: string,
  environment: string,
  sessionId: string,
) => Promise<Question[]> = async (baseUrl, environment, sessionId) => {
  const questions = await fetch(
    `${baseUrl}/sessionquestions?id=${sessionId}&environment=${environment}`,
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.errorMessage) {
        throw response;
      }
      return response;
    });
  return questions;
};
type getQuestionsArgs = {
  baseUrl: string;
  environment: string;
  standards: string[];
  limit: number;
  lessonCode: string;
  offset?: number;
  publication?: string;
  user: string;
  language: string;
};
const getQuestions = async ({
  baseUrl,
  environment,
  lessonCode,
  limit,
  offset = 0,
  publication = 'CCSS',
  standards,
  user,
  language = 'en',
}: getQuestionsArgs): Promise<Question[]> => {
  const questionsResponse = await rateLimiter(() =>
    fetch(
      `${baseUrl}/v2/questions?standard=${JSON.stringify(
        standards,
      )}&limit=${limit}&offset=${offset}&environment=${environment}&seed=${lessonCode}&publication=${publication}&lang=${language}`,
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((response) => {
        if (response.errorMessage) {
          throw response;
        }
        const userSeed = stringToSeed(user);
        return shuffle(response, userSeed);
      }),
  );
  return questionsResponse || [];
};

export {
  createItemsSignatureRequest,
  createAuthoringSignatureRequest,
  createLessonReviewAuthoringSignatureRequest,
  createReviewQuestionSignatureRequest,
  createCoAuthorItemRequest,
  createPassageRequest,
  createPassageItemsListRequest,
  getSignature,
  getQuestions,
  getSessionQuestions,
  Question,
  LearnosityConfig,
  LearnositySignatureRequest,
  LearnosityAuthoringSignatureRequest,
  LearnosityItemsSignatureRequest,
  LearnositySignature,
};
