/* eslint-disable eqeqeq */
import UserChipList from '@components/PracticeV2/Component/UserChipList';
import {
  useGroupQuestionAnswers,
  usePractice,
} from '@components/PracticeV2/Provider/PracticeProvider';
import { PracticeState } from '@components/PracticeV2/types';

import React from 'react';

const nameMapper = (rec: Record<string, unknown>) => (rec?.student_name as string) || '';
const keyMapper = (rec: Record<string, unknown>) => rec?.student_id as string;
const GroupData = ({ answerId }: { answerId: string | number }) => {
  const { state } = usePractice();
  const membersAnswer = useGroupQuestionAnswers();
  const membersAnsweredThisOption = React.useMemo(
    () => membersAnswer.filter((member) => member.answer == answerId),
    [membersAnswer, answerId],
  );
  if (
    membersAnsweredThisOption.length === 0 ||
    state.practiceState == PracticeState.Individual
  ) {
    return null;
  }
  return (
    <UserChipList
      users={membersAnsweredThisOption}
      nameMapper={nameMapper}
      keyMapper={keyMapper}
    />
  );
};

export default React.memo(GroupData);
