import styled, { css } from 'styled-components';

export const DashboardActivityReportWrap = styled.div`
  padding: 0em 1.5em;
  @media (max-width: 768px) {
    padding: 0em 1em;
  }
`;

export const DashboardActivityReportRankingWrap = styled.div`
  display: flex;
  padding: 0em 1.5em;
  margin-bottom: 2em;
  @media (max-width: 1170px) {
    flex-direction: column;
  }
`;

export const PracticeLessonMasteryWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-basis: inherit;
  height: 580px !important;
  overflow-y: hidden !important;
  @media (max-width: 1170px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    width: fill-available;
  }
  .practice-lesson-mastery-container {
    overflow-y: hidden !important;
  }
`;

export const ActivitySearchWrap = styled.div`
  position: relative;
  cursor: pointer;
  flex: 1;
  width: 100%;
  max-width: 35em;
  input {
    width: 100%;
    outline: none;
    border-radius: 2em;
    padding: 0.5em 1em 0.5em 2.5em;
    font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
    border: 1px solid ${(props) => props.theme.colorStyled.ColorXBorder};
    transition: all 300ms ease;
    &::placeholder {
      font-style: italic;
      opacity: 0.7;
    }
  }
  i {
    transition: all 300ms ease;
    position: absolute;
    left: 0.75em;
    top: calc(50% - 0.5em);
    color: ${(props) => props.theme.colorStyled.ColorTime};
  }
  &:hover,
  &:focus {
    input {
      border-color: #040b19;
      &:focus {
        border-color: #040b19;
      }
    }
    i {
      color: #040b19;
    }
  }
  @media (max-width: 1369px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 1em;
  }
`;

export const ActivityActionWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 10px;
`;

export const DownloadButton = styled.button`
  padding: 0.375em 1.5em;
  border-radius: 0.5em;
  border: none;
  min-width: 8em;
  margin-left: 1em;
  display: flex;
  gap: 0.5rem;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9, 30, 66, 0.25) 0px 0px 3px, rgba(9, 30, 66, 0.31) 0px 0;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(9, 30, 66, 0.75) 0px 0px 3px, rgba(9, 30, 66, 0.38) 0px 0;
  }
  i {
    margin-left: 1em;
    margin-top: 0.5em;
    font-size: ${(props) => props.theme.fontSize.SmallMobileFontSize};
  }
`;

export const ActivityReportRankingWrap = styled.div`
  flex: 1;
  &:first-child {
    margin-right: 1em;
  }
  &:last-child {
    margin-left: 1em;
  }
  @media (max-width: 1170px) {
    margin-bottom: 2em;
    &:first-child {
      margin-right: 0em;
    }
    &:last-child {
      margin-left: 0em;
      margin-bottom: 0em;
    }
  }
`;

export const ActivityReportRankingHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
  font-family: 'MontserratBold';
  span {
    margin-right: 0.5em;
    font-weight: bold;
  }
  .chalktalk-champion {
    font-size: 1.5em;
  }
`;

export const ActivityReportAssignPractice = styled.div`
  padding: 0.5em 1em;
  border-radius: 0.5em;
  margin-left: auto;
  cursor: pointer;
  text-align: center;
  font-family: 'MontserratSemiBold';
  font-size: ${(props) => props.theme.fontSize.DefaultFonts};
  background-color: ${(props) => props.theme.colorStyled.ColorLightOrange};
  &:hover {
    opacity: 0.6;
  }
`;

// active per student

export const ActiveStudentPercentage = styled.div`
  padding: 1em;
  border-radius: 100%;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  background-color: ${(props) => props.theme.colorStyled.ColorSkilLevelTwo};
  position: relative;
  cursor: pointer;
  span {
    font-family: 'MontserratRegular';
    font-size: ${(props) => props.theme.fontSize.DefaultFonts};
  }
`;

export const ActiveStudentEngagement = styled.div`
  padding: 0.5em 1em;
  border-radius: 0.5em;
  background-color: ${(props) => props.theme.colorStyled.ColorSkilLevelOne};
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  span {
    margin-left: 0.5em;
    font-family: 'MontserratRegular';
    font-size: ${(props) => props.theme.fontSize.DefaultFonts};
  }
`;

export const ActiveOverTimeWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .chalktalk-icon-pre {
    margin-right: 0.5em;
  }
  .chalktalk-icon-next {
    margin-left: 0.5em;
  }
  i {
    font-weight: bold;
    color: ${(props) => props.theme.colorStyled.ColorBgDefault};
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const ActiveOverTimeItem = styled.div`
  position: relative;
  margin: 0em 0.5em;
  border-radius: 100%;
  background-color: ${(props) => props.theme.colorStyled.ColorLightOrange};
  ${({ size }) =>
    size &&
    css`
      width: ${size}em;
      height: ${size}em;
    `}
  &:before {
    content: '';
    position: absolute;
    border-radius: 100%;
    width: 0.5em;
    height: 0.5em;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.colorStyled.ColorBgDefault};
  }
`;

export const DashboardActivityReportTabWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75em 1.5em;
  margin-bottom: 1em;
  border-radius: 0.5em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    span {
      margin-right: 1em;
    }
  }
  .react-tabs__tab {
    margin: 0em 1em;
    cursor: pointer;
    transition: all 300ms ease;
    position: relative;
    &:hover {
      color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    }
    &:after {
      content: '';
      position: absolute;
      right: -1em;
      height: 100%;
      width: 1px;
      background-color: ${(props) => props.theme.colorStyled.ColorPrimary};
    }
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
  .react-tabs__tab--selected {
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  }
  li[data-tip] {
    border-bottom: 1px dashed ${(props) => props.theme.colorStyled.ColorTime};
  }
  @media (max-width: 1369px) {
    flex-direction: column;
    padding: 0em;
    background-color: transparent;
    .react-tabs__tab-list {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 1em;
      margin-top: 0.5em;
      border-radius: 0.5em;
      background-color: ${(props) => props.theme.colorStyled.ColorWhite};
      padding: 1em;
    }
  }
  @media (max-width: 768px) {
    padding-right: 2em;
    width: fill-available;
    .react-tabs__tab-list {
      padding: 1em 2em;
      overflow: scroll;
    }
  }
`;
