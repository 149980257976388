import React from 'react';
import Modal from 'react-modal';
import {
  Body,
  Button,
  Heading,
  Illustration,
  Close,
  SubHeading,
} from './GooglePrewarningModal.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    lineHeight: '180%',
    marginRight: '-50%',
    textAlign: 'center',
    padding: '30px',
    width: '80%',
    maxWidth: '500px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    boxShadow:
      'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px',
    border: 'none',
    alignItems: 'center',
    height: '80%',
    minHeight: '500px',
    overflow: 'visible',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: 999,
  },
};

const GooglePrewarningModal = ({ handleContinueEdit, isModalOpen, setIsModalOpen }) => (
  <Modal
    isOpen={isModalOpen}
    ariaHideApp={false}
    shouldCloseOnEsc={false}
    shouldCloseOnOverlayClick={false}
    style={modalStyles}
  >
    <Close className="chalktalk-close" onClick={() => setIsModalOpen(false)} />
    <Heading>Content authoring is now in beta!</Heading>
    <SubHeading>Skip the Google warning to try it out</SubHeading>
    <Illustration src="/images/edit-content.png" alt="Edit Content" />
    <Body>
      On the next popup screen click on
      <br />
      <strong style={{ fontFamily: 'MontserratBold' }}>
        Show Advanced &gt; Go to chalktalk.com (unsafe)
      </strong>
      <br />
      <a
        target="_blank"
        href="https://chalktalk.notion.site/chalktalk/How-to-customize-slides-on-ChalkTalk-8f99c9cc3c074cf3b4aeb250df87248e"
        style={{ textDecoration: 'underline' }}
        rel="noreferrer"
      >
        Read this blog post for more information
      </a>
    </Body>
    <Button type="button" onClick={handleContinueEdit}>
      Take me to the verification popup
    </Button>
  </Modal>
);

export default GooglePrewarningModal;
