import React from 'react';
import PracticeDataProvider from './Provider/PracticeDataProvider';
import PracticeLayout from './Component/PracticeLayout';
import PracticeProvider from './Provider/PracticeProvider';
import PracticeReportProvider from './Provider/PracticeReportProvider';

const index = () => (
  <PracticeDataProvider>
    <PracticeProvider>
      <PracticeReportProvider>
        <PracticeLayout />
      </PracticeReportProvider>
    </PracticeProvider>
  </PracticeDataProvider>
);

export default index;
