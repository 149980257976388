import axios from 'axios';
import * as Sentry from '@sentry/react';

const addImageErrorListener = (container) => {
  const images = container.querySelectorAll('img');
  images.forEach((image) => {
    image.addEventListener('error', (e) => {
      e.target.alt = 'Image failed to load. Our team has been notified.';
      e.target.style = 'background-color:#fff; color: #000;';
      e.target.className = '';
      handleImageLoadingError(e);
    });
  });
};

const handleImageLoadingError = (errorEvent) => {
  axios
    .create()
    .get(errorEvent.target.currentSrc)
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setContext('Error Information', {
          headers: error.config.headers,
          message: error.message,
          response: error.request.responseText,
          stack: error.stack,
          status: error.request.status,
          url: error.config.url,
        });
        scope.setTag('exception', 'Image failed to load.');
        scope.setTag('failedImageURL', error.config.url);
        Sentry.captureException(error);
      });
    });
};

export default addImageErrorListener;
