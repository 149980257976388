import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import AnswerShortText from '../AnswerShortText';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { QUESTION_TYPES, REPORT_TYPES } from '../../utils/enums';
import { transformGrinInAnswer } from '../../utils/func-utils';
import {
  QuestionWrap,
  QuestionHeaderWrap,
  QuestionProgress,
  QuestionContentWrap,
  QuestionAnswer,
  QuestionAction,
  QuestionOrderWrapper,
  QuestionOrderNumber,
  QuestionDesc,
} from './QuestionItem.style';
import { Button, WarningIcon } from '../Generals/stylesheets/Button.style';
import MathJaxItem from '../MathJaxContainer';

const scrollStyle = {
  height: 'calc(100vh - 20em)', // need to make this height responsive
  width: '100%',
};

export default class QuestionItem extends Component {
  static propTypes = {
    questionSelected: PropTypes.object,
    totalQuestions: PropTypes.number,
    prevQuestion: PropTypes.func,
    nextQuestion: PropTypes.func,
    order: PropTypes.number,
    index: PropTypes.number,
    shouldDisplayGrammarHelper: PropTypes.bool,
    openReportErrorModal: PropTypes.func,
    toggleExplanationSideBar: PropTypes.func,
    questionId: PropTypes.number,
  };

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.QUESTION, questionId);
  };

  handleNextButton = () => {
    const { nextQuestion, index, totalQuestions } = this.props;

    if (index < totalQuestions - 1) {
      nextQuestion();
    }
  };

  handlePrevButton = () => {
    const { prevQuestion, index } = this.props;

    if (index > 0) {
      prevQuestion();
    }
  };

  renderAnswer = () => {
    const { questionSelected } = this.props;
    const answerType = _get(
      questionSelected,
      'exam_question.question.question_format',
    );
    const choices = _get(
      questionSelected,
      'exam_question.question.choices',
      [],
    );
    const userChoice = _get(questionSelected, 'question_response.choices', []);
    const correctChoice = _get(
      questionSelected,
      'exam_question.question.correct_choices',
      [],
    );

    const selectedValue = _get(
      questionSelected,
      'question_response.text',
      '',
    );

    const score = _get(questionSelected, 'score', {});

    switch (answerType) {
      case QUESTION_TYPES.SHORT_ANSWER: {
        return (
          <AnswerShortText
            inputValue={selectedValue}
            score={score}
            shouldDisplayAnswer
          />
        );
      }
      case QUESTION_TYPES.GRID_IN: {
        const transformValue = transformGrinInAnswer(selectedValue);
        const selectedValueArr = transformValue.split('');

        const correctAnswerArr = _get(
          questionSelected,
          'exam_question.question.answers',
          '',
        );
        return (
          <AnswerGridChoice
            selectedValue={selectedValueArr}
            correctAnswer={correctAnswerArr}
            score={score}
            shouldDisplayAnswer
            handleNextButton={this.handleNextButton}
            handlePrevButton={this.handlePrevButton}
          />
        );
      }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default:
        return (
          <AnswerMultiChoice
            choices={choices}
            correctChoice={correctChoice}
            userChoice={userChoice}
            shouldDisplayAnswer
            handleNextButton={this.handleNextButton}
            handlePrevButton={this.handlePrevButton}
          />
        );
    }
  };

  renderButtonGroup = () => {
    const {
      index, totalQuestions, prevQuestion, nextQuestion,
    } = this.props;
    const shouldRenderPrevButton = index > 0;
    const shouldRenderNextButton = index < totalQuestions - 1;

    return (
      <React.Fragment>
        {shouldRenderPrevButton && <Button blue textUppercase onClick={prevQuestion}>{'<  Previous'}</Button>}
        {shouldRenderNextButton && <Button blue textUppercase onClick={nextQuestion}>{'Next  >'}</Button>}
      </React.Fragment>
    );
  };

  render() {
    const {
      questionSelected,
      totalQuestions,
      order,
      index,
      shouldDisplayGrammarHelper,
    } = this.props;
    const getQuestionContent = _get(
      questionSelected,
      'exam_question.question.prompt.text',
      '',
    );

    return (
      <QuestionWrap>
        <QuestionHeaderWrap>
          <QuestionProgress>
            Question
            <span>{`${index + 1} / ${totalQuestions}`}</span>
          </QuestionProgress>
          <WarningIcon
            className="chalktalk-error-wr"
            onClick={this.openReportErrorModal}
          />
        </QuestionHeaderWrap>
        <QuestionContentWrap practiceQuestion>
          <ShadowScrollbar autoHide style={scrollStyle}>
            {shouldDisplayGrammarHelper
              && (
                <QuestionOrderWrapper>
                  <span>This question refers to </span>
                  <QuestionOrderNumber>
                    {order}
                  </QuestionOrderNumber>
                </QuestionOrderWrapper>
              )}
            <QuestionDesc>
              <MathJaxItem data={getQuestionContent} />
            </QuestionDesc>
            <QuestionAnswer>
              {this.renderAnswer()}
            </QuestionAnswer>
          </ShadowScrollbar>
          <QuestionAction practiceReviewQuestion>{this.renderButtonGroup()}</QuestionAction>
        </QuestionContentWrap>
      </QuestionWrap>
    );
  }
}
