/* eslint-disable import/prefer-default-export */

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getSectionSkillLevelReport } from '@apis/sections';

export type SkillLevel = {
  current_level: number;
  last_practice: string;
  number_of_sessions: number;
  skill_id: number;
  total_max_score: number;
  total_score: number;
  user_id: number;
};

export const useSectionSkillLevels = (skillId) => {
  const { sectionId } = useParams();

  return useQuery<SkillLevel[]>(
    ['skillLevels', sectionId, skillId],
    async () => {
      const { response } = await getSectionSkillLevelReport(sectionId, skillId);
      return response.data;
    },
    {
      enabled: !!sectionId && !!skillId,
      staleTime: 1000 * 60 * 60,
    },
  );
};
