import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

type ExamDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  dataTestId?: string;
  itemId: number;
  title: string;
  content: any;
  confirmButtonTitle: string;
};

const ExamDialog = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  content,
  confirmButtonTitle,
  dataTestId,
}: ExamDialogProps) => (
  <Dialog
    fullWidth
    maxWidth='xs'
    scroll='paper'
    actions={[
      {
        buttonProps: {
          variant: 'text',
          dataTestId: 'cancel-exam-dialog',
        },
        label: 'Cancel',
        onClick: onClose,
      },
      {
        buttonProps: {
          variant: 'contained',
          dataTestId: 'confirm-exam-dialog',
        },
        label: confirmButtonTitle,
        onClick: onConfirm,
      },
    ]}
    open={isOpen}
    onClose={onClose}
    data-testid={dataTestId}
    title={title}
  >
    <Typography
      sx={{
        textAlign: 'start',
        '& .bold': { fontWeight: 'bold' }
      }}
      variant="body1"
    >
      {content}
    </Typography>
  </Dialog>
);

export default ExamDialog;
