import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import SectionDetail from '../../components/CourseManagementSection/SectionDetail';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import { selectors as userSelectors } from '../../reducers/user';
import {
  selectors as CourseTeacherSelectors,
} from '../../reducers/courseTeachers';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const ownerIds = CourseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find(id => id === userId);

  return {
    isOwner,
    curriculumId,
    subjectsById: CurriculumSelectors.getSubjectListById(state, curriculumId),
    subjects: CurriculumSelectors.getSubjectList(state, curriculumId),
    packagesPerSubject: SectionSelectors.AvailablePackages(state),
  };
};

const mapDispatchToProps = {
  getCurriculumSubjectList: CurriculumActions.getCurriculumSubjectList,
  sectionsUpdate: SectionActions.sectionsUpdate,
  deactivateSection: SectionActions.deactivateSection,
  getAvailablePackages: SectionActions.getAvailablePackages,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionDetail));
