import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {
  ReportsPDFContainer,
  GoBackBtn,
} from '@components/ReportPDFViewer/ReportPDFViewer.style';
import { IframeWrapper, StyledIframe } from '../common/CustomIframe/CustomIframe.styles';

const ReportPDFViewer = () => {
  const { state } = useLocation();
  const { from, pdf, generatedReports } = state;
  return (
    <ReportsPDFContainer>
      <GoBackBtn>
        <Link to={{ pathname: from, state: { from, generatedReports } }}>
          <ArrowCircleLeftIcon />
          {from === '/admin/reports/generator/list' ? (
            <span>
              Back to Generated Reports{' '}
              {generatedReports && `(${generatedReports.length})`}
            </span>
          ) : (
            <span>Back to Report Generator</span>
          )}
        </Link>
      </GoBackBtn>
      <IframeWrapper isReport>
        <StyledIframe src={pdf} isReport />
      </IframeWrapper>
    </ReportsPDFContainer>
  );
};

export default ReportPDFViewer;
