import { connect } from 'react-redux';
import LessonSubNavItem from '../../components/SubNavBarItem/LessonSubNavItem';
import { selectors } from '../../reducers/lessonAssignedActivities';
import { selectors as ActivitySelectors } from '../../reducers/activity';

const mapStateToProps = (state, { lessonItem }) => {
  const { id } = lessonItem;
  return {
    isAssign: selectors.getAssignStatusByLessonId(state, id),
    lessonActivitySummaryById: ActivitySelectors.getLessonActivitySummaryById(state),
    lessonId: id,
  };
};

export default connect(mapStateToProps)(LessonSubNavItem);
