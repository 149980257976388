import {
  call, put, select,
  takeLeading,
} from 'redux-saga/effects';
import * as SectionAPIs from '../../apis/sessions';
import {
  SCHEDULE_LESSON_FORM_SUBMIT,
  actions as ScheduleLessonFormActions,
  selectors as ScheduleLessonFormSelectors,
} from '../../reducers/scheduleForms';

/**
 * ! Need update callback
 */
function* handleAddLessonSection({ sectionId }) {
  try {
    const formList = yield select(ScheduleLessonFormSelectors.getFormList);
    console.log(formList);

    const { response, error } = yield call(SectionAPIs.addSessionStandard, sectionId, formList);
    if (error) throw error;

    yield put(ScheduleLessonFormActions.submitLessonFormSuccess(response));
  } catch (error) {
    yield put(ScheduleLessonFormActions.submitLessonFormSuccess(error));
  }
}

export default function* scheduleForms() {
  yield takeLeading(SCHEDULE_LESSON_FORM_SUBMIT, handleAddLessonSection);
}
