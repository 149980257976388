import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import AnswerExplanation from '../../containers/AnswerExplanation';
import {
  PracticeContentWrapMobile,
  PBMobilePopup,
  PBMobileCol,
  ProgressBarWrapper,
  PracticeFooterBarMobile,
  ProgressBarItem,
  PBMobileSelectedItem,
  ProgressBarCircle,
  PracticeContentGroupMobile,
  PBMobileItem,
  PBMobileItemContent,
} from '../PracticePage/PracticePage.style';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import AnswerExplanationLeftSideBar from '../AnswerExplanationLeftSideBar';
import { NavigationHeaderWrap } from '../QuestionAssets/QuestionAssets.style';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import PracticeReviewItem from '../PracticeReviewItem/PracticeReviewItem';
import QuestionAssets from '../QuestionAssets';

const scrollStyle = {
  height: 'calc(100vh - 10em)',
  width: '100%',
};

class ReviewQuestionContainersMobile extends React.PureComponent {
  state = {
    isQuestionModalOpen: false,
    isOpenSideBar: false,
  };
  toggleQuestionModal = () => {
    this.setState(({ isQuestionModalOpen }) => ({
      isQuestionModalOpen: !isQuestionModalOpen,
    }));
  };

  closeQuestionModal = () => {
    this.setState({
      isQuestionModalOpen: false,
    });
  };

  toggleExplanationSideBar = () => {
    this.setState(({ isOpenSideBar }) => ({
      isOpenSideBar: !isOpenSideBar,
    }));
  };

  selectQuestion = (index) => () => {
    const { selectQuestion } = this.props;
    this.closeQuestionModal();
    selectQuestion(index)();
  };

  renderQuestionAssets = () => {
    const { questions, questionSelected, openReportErrorModal } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(
      questionSelectedObj,
      'exam_question.question.shared_assets',
      [],
    );
    const questionId = _get(questionSelectedObj, 'exam_question.id');

    const isAssetEmpty = assets.length === 0;

    if (isAssetEmpty) {
      return null;
    }

    return (
      <QuestionAssets
        hasFooter
        assets={assets}
        questionId={questionId}
        openReportErrorModal={openReportErrorModal}
      />
    );
  };

  renderMainContent = () => {
    const {
      questions,
      questionSelected,
      prevQuestion,
      nextQuestion,
      openReportErrorModal,
    } = this.props;
    const totalQuestions = questions.length;

    if (totalQuestions === 0) {
      return null;
    }

    const questionSelectedObj = questions[questionSelected];
    const questionOrder = questionSelectedObj.order || null;
    const questionId = _get(questionSelectedObj, 'exam_question.id');

    return (
      <PracticeReviewItem
        questionSelected={questionSelectedObj}
        totalQuestions={totalQuestions}
        nextQuestion={nextQuestion}
        prevQuestion={prevQuestion}
        index={questionSelected}
        order={questionOrder}
        shouldNotDisplayComplete
        toggleExplanationSideBar={this.toggleExplanationSideBar}
        openReportErrorModal={openReportErrorModal}
        questionId={questionId}
      />
    );
  };

  renderProgressBar = () => {
    const { questionSelected, questions } = this.props;

    return (
      <ProgressBarItem isActive isSelected>
        <PBMobileSelectedItem onClick={this.toggleQuestionModal}>
          <ProgressBarCircle />
          {`Question ${questionSelected + 1} of ${questions.length}`}
        </PBMobileSelectedItem>
      </ProgressBarItem>
    );
  };

  renderQuestionModalItem = (colNum) => (question, index) => {
    const { questionSelected } = this.props;
    const currentIndex = 6 * colNum + index;

    return (
      <PBMobileItem
        key={question.exam_question.id}
        isActive
        onClick={this.selectQuestion(currentIndex)}
      >
        <ProgressBarCircle isSelected={questionSelected === currentIndex} />
        <PBMobileItemContent>{`Question ${currentIndex + 1}`}</PBMobileItemContent>
      </PBMobileItem>
    );
  };

  renderQuestionModal = () => {
    const { questions } = this.props;
    const { isQuestionModalOpen } = this.state;
    const firstList = questions.slice(0, 6);
    const secondList = questions.slice(6, 12);
    const hasSecondList = secondList.length > 0;

    return (
      <React.Fragment>
        <PBMobilePopup hasSecondList={hasSecondList}>
          <PBMobileCol>
            {firstList.map(this.renderQuestionModalItem(0))}
          </PBMobileCol>
          {secondList && secondList.length && (
            <PBMobileCol>
              {secondList.map(this.renderQuestionModalItem(1))}
            </PBMobileCol>
          )}
        </PBMobilePopup>
        <OverlayWrap
          isOpen={isQuestionModalOpen}
          onClick={this.toggleQuestionModal}
        />
      </React.Fragment>
    );
  };

  render() {
    const { isOpenSideBar, isQuestionModalOpen } = this.state;
    const { questions, questionSelected, openReportErrorModal } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const explanation = _get(
      questionSelectedObj,
      'exam_question.question.explanation',
      {},
    );
    const questionId = _get(questionSelectedObj, 'exam_question.id');

    return (
      <React.Fragment>
        <AnswerExplanationLeftSideBar
          isSideBarClose={isOpenSideBar}
          toggleLeftSidebar={this.toggleExplanationSideBar}
        >
          <AnswerExplanation
            explanation={explanation}
            questionSelected={questionSelectedObj}
            questionId={questionId}
            openReportErrorModal={openReportErrorModal}
          />
        </AnswerExplanationLeftSideBar>
        <PracticeContentWrapMobile>
          <Tabs defaultIndex={1}>
            <NavigationHeaderWrap>
              <TabList>
                <Tab>Assets</Tab>
                <Tab>Show Questions</Tab>
              </TabList>
            </NavigationHeaderWrap>
            <TabPanel>{this.renderQuestionAssets()}</TabPanel>
            <TabPanel>
              <ShadowScrollbar autoHide style={scrollStyle}>
                <PracticeContentGroupMobile>
                  {this.renderMainContent()}
                </PracticeContentGroupMobile>
              </ShadowScrollbar>
            </TabPanel>
          </Tabs>
          {isQuestionModalOpen && this.renderQuestionModal()}
          <PracticeFooterBarMobile>
            <ProgressBarWrapper>{this.renderProgressBar()}</ProgressBarWrapper>
          </PracticeFooterBarMobile>
        </PracticeContentWrapMobile>
      </React.Fragment>
    );
  }
}

ReviewQuestionContainersMobile.propTypes = {
  questions: PropTypes.array,
  questionSelected: PropTypes.number,
  nextQuestion: PropTypes.func.isRequired,
  prevQuestion: PropTypes.func.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  openReportErrorModal: PropTypes.func,
};

export default ReviewQuestionContainersMobile;
