import styled from 'styled-components';

export const QuestionHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 0.75rem;
  border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  margin-bottom: 1rem;
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
`;
export const QuestionProgress = styled.div`
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
  span {
    margin-left: 0.5em;
  }
`;
