import ShowIf from '@components/Atoms/ShowIf';
import Typography from '@components/Atoms/Typography';
import {
  useActivityGroups,
  useGetGroupActivityInfo,
} from '@components/Component/OLP/tab/hooks/query';
import { studentType } from '@components/Component/OLP/tab/types';
import UserChip from '@components/PracticeV2/Component/UserChip';
import UserChipList from '@components/PracticeV2/Component/UserChipList';
import React from 'react';
import styled from 'styled-components';

const OnlineReviewAnsweredContainer = styled.div`
  padding-left: 2em;
  margin-bottom: 10px;
  .head {
    border-bottom: 1px solid #ccc;
    padding-bottom: 2px;
    color: #ccc;
  }
  .item {
    margin: 5px 0;
  }
`;
type Props = {
  answeredGroups: number[];
  answeredStudents: {
    id: number;
    student_name: string;
  }[];
};

const ReviewAnswered = ({ answeredGroups, answeredStudents }: Props) => {
  const {
    data: { id: groupActivityId },
  } = useGetGroupActivityInfo();
  const { data: groupsQueryData } = useActivityGroups(groupActivityId);
  const groupAnsweredData = React.useMemo(() => {
    if (!groupsQueryData) {
      return [];
    }

    const mappedData = answeredGroups.map((groupId) => {
      const group = groupsQueryData.find(({ id }) => id === groupId);
      if (!group) {
        return null;
      }
      return {
        id: group.id,
        index: groupsQueryData.findIndex(({ id }) => id === groupId) + 1,
        students: group.students,
      };
    });

    return mappedData.filter((group) => group !== null) as {
      id: number;
      index: number;
      students: studentType[];
    }[];
  }, [groupsQueryData, answeredGroups]);
  return (
    <>
      <OnlineReviewAnsweredContainer>
        <div className="head">Individual Phase - Students who answered correctly</div>
        <ShowIf If={answeredStudents.length === 0}>
          <Typography variant="body1" sx={{ p: 1 }}>
            No student answered this question correctly.
          </Typography>
        </ShowIf>
        {answeredStudents.map((student) => (
          <div className="item" key={student.id}>
            <UserChip name={student.student_name} />
          </div>
        ))}
      </OnlineReviewAnsweredContainer>
      <OnlineReviewAnsweredContainer>
        <div className="head">Group Phase - Groups who answered correctly</div>
        <ShowIf If={groupAnsweredData.length === 0}>
          <Typography variant="body1" sx={{ p: 1 }}>
            No group answered this question correctly.
          </Typography>
        </ShowIf>
        {groupAnsweredData.map((group) => (
          <React.Fragment key={group.id}>
            <Typography
              variant="subtitle1"
              sx={{
                pb: 0.5,
                mt: 2,
              }}
            >
              Group {group.index}
            </Typography>
            <UserChipList
              users={group.students}
              nameMapper={(user) => user.student_name}
              keyMapper={(user) => `${user.id}`}
            />
          </React.Fragment>
        ))}
      </OnlineReviewAnsweredContainer>
    </>
  );
};

export default ReviewAnswered;
