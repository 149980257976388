import React from 'react';
import QuestionSelectorProvider from '@components/QuestionSelector/QuestionSelector.provider';

import { ContentFilterProvider } from '@components/ContentFilters/ContentFilterProvider';
import Footer from './Footer';
import Main from './Main';
import { useLessonReview } from '../hooks';
import { LLROrderingType } from '../types';

const LessonReviewSetup = () => {
  const { data: lessonReview } = useLessonReview();
  return (
    <QuestionSelectorProvider
      initialType={lessonReview.type || LLROrderingType.FIXED_ORDERING}
      initialSelectedQuestions={lessonReview.questions.map((q) => q.reference)}
    >
      <ContentFilterProvider>
        <Main />
        <Footer />
      </ContentFilterProvider>
    </QuestionSelectorProvider>
  );
};
export default LessonReviewSetup;
