import React from 'react';
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
  SxProps,
  Theme,
} from '@mui/material';

export interface TypographyProps {
  /** - The Variant to be displayed in different color and  font-family the `Typography` component. */
  variant?: MuiTypographyProps['variant'];
  /** - The no wrap flag to enable no wrapping to the text within the `Typography` component. */
  noWrap?: MuiTypographyProps['noWrap'];
  /** - The Align is to align the text within the `Typography` component. */
  align?: MuiTypographyProps['align'];
  /** - The content to be displayed within the `Typography` component. */
  component?: React.ElementType;
  /** - The content to be displayed within the `Typography` component. */
  children: React.ReactNode;
  /** - Additional CSS properties to apply to the `Typography` component. */
  sx?: SxProps<Theme> | any;
  dataTestId?: string;
}

/**
 * A wrapper component for Material-UI's `Typography` with additional customization options.
 * @returns {React.ReactNode} The rendered `Typography` component.
 */

const Typography = ({ children, dataTestId, sx, ...rest }: TypographyProps) => (
  <MuiTypography data-testid={dataTestId} sx={sx && { ...sx }} {...rest}>
    {children}
  </MuiTypography>
);

export default Typography;
