/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../Generals/FormInput/FormInput';
import { FormGroup } from '../../Generals/stylesheets/Form.style';
import {
  LoginItemWrap,
  LoginItem,
  LoginTitle,
  ButtonStyled,
  LoginErrorMessage,
  LoginContentText,
  LoginAction,
  ResetPasswordMessage,
} from '../../LoginPage/Login.style';
import { Title, Paragraph } from '../../LoginPage/SignUpType.style';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  sectionCode: Yup.string().trim().required('Invalid section code'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  courseCode: '',
};

export default class ForgotEmailForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    findEmail: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    errorMessage: PropTypes.any,
    foundEmail: PropTypes.string.isRequired,
    isSentResetPasswordEmail: PropTypes.bool.isRequired,
    isSendingResetPasswordEmail: PropTypes.bool.isRequired,
  };

  findEmail = (values) => {
    const { findEmail } = this.props;
    findEmail(values);
  };

  resetPassword = () => {
    const { resetPassword, foundEmail } = this.props;
    resetPassword(foundEmail);
  };

  renderVerifyBtn = () => {
    const { isLoading, isSendingResetPasswordEmail } = this.props;
    if (isLoading || isSendingResetPasswordEmail) {
      return <ButtonStyled primary>Loading ...</ButtonStyled>;
    }
    return <ButtonStyled type="submit">Find Account</ButtonStyled>;
  };

  renderEmailFoundSuccess = () => {
    const { foundEmail } = this.props;
    return (
      <LoginContentText>
        <p>
          Your email is
          <span>{foundEmail}</span>. Do you want reset your password?
        </p>
      </LoginContentText>
    );
  };

  renderResetPasswordMessage = () => (
    <ResetPasswordMessage>Password reset e-mail has been sent.</ResetPasswordMessage>
  );

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>;

  renderErrorList = (errorList) => {
    if (!errorList) return null;
    if (_isArray(errorList)) {
      return (
        <LoginErrorMessage>{errorList.map(this.renderErrorMessage)}</LoginErrorMessage>
      );
    }
    return <LoginErrorMessage>{this.renderErrorMessage(errorList, 1)}</LoginErrorMessage>;
  };

  render() {
    const { errorMessage, foundEmail, isSentResetPasswordEmail } = this.props;
    return (
      <LoginItemWrap>
        <LoginItem>
          <Title>Forgot your password and/or email address</Title>
          <Paragraph>Everyone forgets but that shouldn't stop you.</Paragraph>

          {foundEmail && this.renderEmailFoundSuccess()}

          {isSentResetPasswordEmail && this.renderResetPasswordMessage()}

          {!foundEmail && !isSentResetPasswordEmail && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={this.findEmail}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormInput type="text" name="firstName" label="First Name" />
                    <FormInput type="text" name="lastName" label="Last Name" />
                  </FormGroup>
                  <FormInput type="text" name="sectionCode" label="Section Code" />
                  {this.renderErrorList(errorMessage)}
                  {this.renderVerifyBtn()}
                </form>
              )}
            </Formik>
          )}
          {foundEmail ? (
            <LoginAction>
              <ButtonStyled type="button" onClick={this.resetPassword}>
                Reset Password
              </ButtonStyled>
              <Link to="/login">
                <ButtonStyled primary>Back to login</ButtonStyled>
              </Link>
            </LoginAction>
          ) : (
            <Link to="/login">
              <ButtonStyled primary>Back to login</ButtonStyled>
            </Link>
          )}
        </LoginItem>
      </LoginItemWrap>
    );
  }
}
