import React from 'react';
import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
  SxProps,
  Theme,
} from '@mui/material';

export interface SkeletonProps {
  /** - The Variant to be displayed in different shapes the ` Skeleton ` component. */
  variant?: MuiSkeletonProps['variant'];
  /** - The width to set width  the ` Skeleton ` component. */
  width?: MuiSkeletonProps['width'];
  /** - The height to set height  the ` Skeleton ` component. */
  height?: MuiSkeletonProps['height'];
  /** - The content to be displayed within the ` Skeleton ` component. */
  component?: React.ElementType;
  /** - The content to be displayed within the ` Skeleton ` component. */
  children?: React.ReactNode;
  /** - Additional CSS properties to apply to the ` Skeleton ` component. */
  sx?: SxProps<Theme> | any;
}

/**
 * A wrapper component for Material-UI's ` Skeleton ` with additional customization options.
 * @returns {React.ReactNode} The rendered ` Skeleton ` component.
 */

const Skeleton = ({ children, sx, ...rest }: SkeletonProps) => (
  <MuiSkeleton sx={sx && { ...sx }} {...rest}>
    {children}
  </MuiSkeleton>
);

export default Skeleton;
