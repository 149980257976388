import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SessionSubNavItem from '../../components/SubNavBarItem/ReviewSubNavItem';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
import { ROLE_TYPE } from '../../utils/enums';
import { selectors as reviewSelectors } from '../../reducers/sectionReviews';

const mapStateToProps = (state, { match }) => {
  const { sectionId = 0 } = match.params;
  const isTeacher = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR;

  return {
    studentList: sectionStudentSelectors.getStudentList(state, sectionId),
    userId: UserSelectors.getUserId(state),
    isTeacher,
    reviewAllByReviewId: reviewSelectors.getReviewAllByReviewId(state),
  };
};

export default withRouter(connect(mapStateToProps)(SessionSubNavItem));
