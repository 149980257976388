import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import StudentLessonReviewPage from '../../components/LessonReviewPage/StudentLessonReviewPage';
import { selectors as ActivitySelectors } from '../../reducers/activity';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionReviewSelectors } from '../../reducers/sectionReviews';
import { selectors as CurriculumLessonSelectors } from '../../reducers/curriculumLessons';
import { selectors as SectionSkillSelectors } from '../../reducers/sectionSkills';
import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { actions as PracticeActions } from '../../reducers/practice';

const mapStateToProps = (state, { match }) => {
  const userId = UserSelectors.getUserId(state);
  const reviewId = _get(match, 'params.reviewId', '');
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const subjectId = _get(match, 'params.subjectId', '');
  const reviewDetail = SectionReviewSelectors.getReviewDetailById(state, reviewId);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    courseId,
    sectionId,
    subjectId,
    reviewId,
    userId,
    reviewDetail,
    courseDetail,
    sectionDetail,
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    subject: SectionSubjectSelectors.getSubjectDetail(state, subjectId),
    lessonById: CurriculumLessonSelectors.getLessonById(state),
    unitByIds: SectionUnitSelectors.getUnitByIds(state),
    reviewData: ActivitySelectors.getReviewDataByUserId(state, userId),
    isGetSessionData: ActivitySelectors.getIsGetSessionData(state),
    isGetSkillData: SectionSkillSelectors.getIsGetSkillData(state),
    skillByLessonId: SectionSkillSelectors.getSkillByLessonId(state),
  };
};

const mapDispatchToProps = {
  initializeReviewPracticeSession: PracticeActions.initializeReviewPracticeSession,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentLessonReviewPage));
