import styled, { css } from 'styled-components';

export const TextOverflow = styled.span`
  ${({ subject }) => (subject) && css`
    color: #F6A527;
    font-size: 1em;
    overflow-wrap: break-word;
    line-height: 1em;
  }`}
  ${({ subject, large }) => (subject&&large) && css`
    font-size: 1.3em;
    overflow: hidden;
    margin: 0;
    height: 100%;
    padding: 0;
    margin: 0;
    max-height: 100%;
    white-space: normal;
  }`}
  ${({ decreaseFontSize }) => decreaseFontSize && css`
    font-size: 1em;
  `}
`
