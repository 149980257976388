/* eslint-disable import/prefer-default-export */
import { getSectionUnit } from '@apis/sections';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

export const useGetSectionSubjectUnitLesson = () => {
  const { sectionId } = useParams();

  const queryResponse = useQuery(
    ['useGetSectionSubjectUnitLesson', sectionId],
    async () => {
      const { response } = (await getSectionUnit(sectionId)) as unknown as {
        response: { data };
      };

      return response.data || [];
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!sectionId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};
