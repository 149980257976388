import styled, { css } from 'styled-components';

export const ActionButtonGroup = styled.div`
  position: relative;
`;

export const ActionButtonContainer = styled.button`
  border-radius: 0.5em;
  border: 0px;
  height: 100%;
  background: ${(props) => `${props.theme.colorStyled.ColorBoxShadow}de`};
  padding: 8px 6px;
  ${({ isDropDownVisible }) => isDropDownVisible && css`
    background: ${props => props.theme.colorStyled.ColorWhite};
    box-shadow: 1px 1px 3px 1px ${props => props.theme.colorStyled.ColorBoxShadow};
  `}
`;

export const ActionButtonContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionButtonCircle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 2.5px;
  background-color: #000;
`;
