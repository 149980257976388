import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const PostJoinSessionItemWrap = styled.div`
  width: 100%;
  ${({ isCreateSection }) => isCreateSection && css`
    padding: 6px 50px;
    @media (max-width: 768px) {
      padding: 0px 20px;
    }
  }
`}
`;

export const PostJoinSessionItem = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  } 
`;

export const PostJoinSessionTitle = styled.div`
  & > h1 {
    color: #666666;
    font-family: 'MontserratRegular',Arial;
    font-size: 20.0pt;
    line-height: 38px;
  }
  & > p {
    color: #999999;
    font-family: 'Montserrat',Arial;
    font-size: 10.0pt;
    font-style: normal;
    font-weight: 200;
  }
  @media (max-width: 480px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    margin-top: 15%;
  }
`;

export const MainContentWrapper = styled.div`
  display: flex;
  margin: 20px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LeftMainContentWrapper = styled.div`
  display: inline-block;
  width: 60%;
  margin-right: 10px;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-right: 0px;
  }
`;

export const RightMainContentWrapper = styled.div`
  display: inline-block;
  width: 40%;
  padding: 10px;
  background-color: #F3F3F3;
  border: 1px #CCCCCC dashed;
  border-radius: 4px;
  margin-left: 10px;
  @media (max-width: 768px) {
    width: 90%;
    align-items: center;
    margin-top: 15px;
    margin-left: 0px;
    padding: 0px;
    align-self: center;
  }
`;

export const RightContentTitleWrapper = styled.div`
  color: #282828;
  font-family: 'MontserratMedium',Arial;
  font-size: 10.0pt;
  font-weight: bold;
`;

export const RightContentTextWrapper = styled.div`
  color: #282828;
  font-family: 'Montserrat',Arial;
  font-size: 8.0pt;
  font-style: normal;
  font-weight: 200;
`;

export const RightContentItalicTextWrapper = styled.div`
  color: #282828;
  font-family: 'Montserrat-LightItalic',Arial;
  font-size: 7.0pt;
  font-style: italic;
`;

export const WellSessionContext = styled.div`
  width: 75%;
  background-color: #D9D9D9;
  padding: 15px !important;
  border-radius: 25px;
  p {
    color: #282828;
    font-family: 'MontserratMedium',Arial;
    font-size: 10.0pt;
    font-weight: bold;
    text-align: center;
  }
`;

export const InformationLeftContent = styled.div`
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 20px;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TitleEachSubContent = styled.div`
  color: #999999;
  text-align: left;
  text-decoration: none;
  font-family: 'Montserrat-MediumItalic',Arial;
  font-size: 10.0pt;
  font-style: italic;
  font-weight: bold;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ChooseOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  @media (max-width: 768px) {
    text-align: center;
    width: fill-available;
  }
`;

export const CircleIconWrapper = styled.div`
  width: 5%;
  display: inline;
  &::before {
    color: rgb(106, 202, 107);
  }
`;

export const OptionsAfterCircleIcon = styled.div`
  width: 95%;  
  display: inline;
  margin: 0 20px;
  color: #B2B2B2;
  text-align: left;
  text-decoration: none;
  font-family: 'MontserratRegular',Arial;
  font-size: 10.0pt;
`;

export const TableWrapper = styled.div`
  margin-top: 10px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const SmallInformationTable = styled.table`
  width: 50%;
  text-align: center;
  display: inline-block;
  tbody {
    width: 90%;
    display: inline-table;
  }
  tr {
    border: 1px #F3F3F3 solid;
  }
  tr:nth-child(odd) {
    background: #F2F2F2;
  }
  td {
    text-align: center;
    padding: 15px;
    color: #434343;
    font-family: 'Open Sans',Arial;
    font-size: 10.0pt;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonStyled = styled.button`
  width: 60%;
  min-height: 50px;
  margin: 30px 0;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 1px solid #6669C9;
  background-color: #6669C9;
  font-family: 'Montserrat-Regular',Arial;
  font-size: 10.0pt;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-color: #5E5E5E;
    background-color: #5E5E5E;
  }
  @media (max-width: 1440px) {
    padding: 10px 60px;
  }
  @media (max-width: 768px) {
    padding: 10px 10px;
    margin : 5px 0;
  }
`;

export const LinkSkipThisStepWrapper = styled.div`
  text-align: center;
  @media (max-width: 480px) {
    padding-bottom: 10px;
  }
`;

export const LinkSkipThisStep = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  font-family: 'Montserrat',Arial;
  font-size: 8.0pt;
  font-weight: 200;
`;

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;
