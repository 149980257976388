import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';

import { useGetExamEstimatedScore } from '@components/Component/Exams/hooks/query';
import { useHasSectionAllCourseExams } from '@components/Component/Exams/hooks/helpers';
import EstimatedScoreTable from './EstimatedScoreTable';
import EstimatedScoreTableV2 from './EstimatedScoreTableV2';
import { DashboardLine } from '../DashboardSummary/DashboardSummary.style';
import {
  ExamScoreFlex,
  ExamScoreTableContent,
  ExamScoreTableHeader,
} from './DashboardExamScoreReport.style';
import UpcomingExams from './UpcomingExams';
import ShadowSrollbar from '../Scrollbar/ShadowScrollbars';
import SubjectSelect, {
  subjectSelectListGenerator,
  OverallObject,
} from './SubjectSelect';
import { BlockWrap, BlockTitle } from '../Generals/stylesheets/General.style';
import EstimatedScoreSummary from './EstimatedScoreSummary';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};
const DashboardExamScoreReportTable = ({ hasExamV2Access, fetchExamV2Access }) => {
  const { data: estimatedScoreData } = useGetExamEstimatedScore();
  const hasSectionAllCourseExams = useHasSectionAllCourseExams();
  const [selectedSubject, setSelectedSubject] = React.useState(
    subjectSelectListGenerator(
      estimatedScoreData.subjects,
      hasSectionAllCourseExams,
    )?.[0] || OverallObject,
  );
  useEffect(() => {
    fetchExamV2Access?.();
  }, []);
  useEffect(() => {
    setSelectedSubject(
      subjectSelectListGenerator(
        estimatedScoreData.subjects,
        hasSectionAllCourseExams,
      )?.[0] || OverallObject,
    );
  }, [estimatedScoreData, hasSectionAllCourseExams]);
  return (
    <ShadowSrollbar autoHide style={scrollStyle}>
      <ExamScoreFlex>
        <ExamScoreTableContent>
          {/* statistics */}
          <BlockWrap>
            <BlockTitle>
              <span>Summary</span>
            </BlockTitle>
            <EstimatedScoreSummary selectedSubject={selectedSubject} />
          </BlockWrap>
          {/* report table */}
          <BlockWrap>
            <BlockTitle>
              <span>Score Per Student</span>
            </BlockTitle>
            <ExamScoreTableHeader>
              <SubjectSelect
                selectedSubject={selectedSubject}
                setSelectedSubject={setSelectedSubject}
                list={subjectSelectListGenerator(
                  estimatedScoreData.subjects,
                  hasSectionAllCourseExams,
                )}
              />
            </ExamScoreTableHeader>
            {hasExamV2Access ? (
              <EstimatedScoreTableV2 selectedSubject={selectedSubject} />
            ) : (
              <EstimatedScoreTable selectedSubject={selectedSubject} />
            )}
          </BlockWrap>
        </ExamScoreTableContent>

        <MediaQuery minWidth={1280}>
          {(matches) => matches && <DashboardLine />}
        </MediaQuery>
        {/* side bar */}
        <UpcomingExams hasExamV2Access={hasExamV2Access} />
      </ExamScoreFlex>
    </ShadowSrollbar>
  );
};
export default DashboardExamScoreReportTable;
