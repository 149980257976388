import Box from '@components/Atoms/Box';
import Chip from '@components/Atoms/Chip';
import Typography from '@components/Atoms/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const InProgressChip = ({ label }) => (
  <Chip
    variant="outlined"
    label={
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem' }}>
        <Typography
          variant="h3"
          sx={{ fontSize: '0.875rem', color: '#2b1a08', textTransform: 'capitalize' }}
        >
          {label}
        </Typography>
        <CircularProgress size={20} />
      </Box>
    }
  />
);

export default InProgressChip;
