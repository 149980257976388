import Box from '@components/Atoms/Box';
import Card from '@components/Atoms/Card';
import Paper from '@components/Atoms/Paper';
import Typography from '@components/Atoms/Typography';
import React from 'react';
import { CardMedia } from '@mui/material';
import { getFormattedDate } from '@utils/commonUtils';
import FromToBlock from '@components/Atoms/FromToBlock';
import FiveProgress from '@components/Atoms/FiveProgressMeter/FiveProgress';
import { useParams } from 'react-router-dom';
import { useUser } from '@reducers/user/hooks';
import ShowIf from '@components/Atoms/ShowIf';
import InProgressChip from '@components/InProgressChip';
import { LLRSessionResponse, SkillLevelDetails } from './types';

const imgUrl = '/images/icon-svg/exam.svg';

const CardMediaStyle = {
  width: '100%',
  flex: 1,
  height: '100%',
  borderRadius: '0.5rem',
  objectFit: 'contain',
  padding: '0.5rem',
  border: '1px solid #cccccc29',
};

const PracticeCardStyle = {
  border: '1px solid #cccccc29',
  boxShadow: '0px 0px 6px #00000029',
  width: '100%',
  minHeight: 'fit-content',
  overflow: 'visible',
  p: '1rem',
  display: 'flex',
  gap: '1rem',
};

const innerWrapperStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
};

// ------------------------------------------------------------
const ScoreChip = ({ score, maxScore }: { score: number; maxScore: number }) => (
  <Box
    data-testid="score-chip"
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'end',
      gap: '1rem',
      maxHeight: 'fit-content',
    }}
  >
    <Paper
      sx={{
        alignItems: 'center',
        alignSelf: 'center',
        border: (theme) => `solid 0.125rem ${theme.palette.secondary.main}`,
        backgroundColor: (theme) => theme.palette.secondary.light,
        color: 'white',
        borderRadius: '1rem',
        display: 'flex',
        gap: '0.5rem',
        height: '3.5rem',
        justifyContent: 'center',
        padding: '1rem',
        minWidth: '10rem',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          fontSize: '1rem',
          textTransform: 'uppercase',
          color: 'white',
        }}
      >
        Score:
      </Typography>
      <Typography
        dataTestId="practice-total-score"
        variant="h3"
        sx={{ fontSize: '1rem', color: 'white' }}
      >
        {`${score} / ${maxScore}`}
      </Typography>
    </Paper>
  </Box>
);

const PracticeInfo = ({
  name,
  sessionEndedDate,
  skillLevel,
}: {
  name: string;
  sessionEndedDate: Date;
  skillLevel: SkillLevelDetails;
}) => (
  <Box
    data-testid="practice-info"
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
    }}
  >
    <Box sx={{ display: 'flex', gap: '0.25rem', alignItems: 'baseline' }}>
      <Typography
        variant="body1"
        sx={{
          color: '#2b1a08',
          fontSize: '1rem',
        }}
      >
        Score Review :
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: '#2b1a08',
          fontSize: '0.875rem',
          fontWeight: 'bold',
        }}
      >
        {name}
      </Typography>
    </Box>
    <Typography
      variant="subtitle1"
      sx={{
        mt: '0.25rem',
        fontSize: '0.875rem',
      }}
    >
      {getFormattedDate(sessionEndedDate)}
    </Typography>
    <ShowIf If={!skillLevel?.skill_list?.length}>
      <Box sx={{ mt: 'auto' }}>
        <InProgressChip label="Calculating Skill level" />
      </Box>
    </ShowIf>
    <ShowIf If={!!skillLevel?.skill_list?.length}>
      <Box sx={{ mt: 'auto' }}>
        <FromToBlock
          data-testid="average-skill-level"
          from={<FiveProgress level={skillLevel?.initial} />}
          to={<FiveProgress level={skillLevel?.current} />}
          title="Average Skill Level"
        />
      </Box>
    </ShowIf>
  </Box>
);
const ScoreReviewCard = ({
  session,
  index,
}: {
  session: LLRSessionResponse;
  index: number;
}) => {
  const { score, skill } = session;
  const { lessonId } = useParams();
  const user = useUser();
  const skillLevel = skill?.[lessonId]?.[user?.id] || {};

  return (
    <Card sx={PracticeCardStyle} data-testid="score-review-card">
      <CardMedia component="img" image={imgUrl} sx={CardMediaStyle} />
      <Box sx={innerWrapperStyle}>
        <PracticeInfo
          name={`Practice ${index}`}
          sessionEndedDate={session?.ended}
          skillLevel={skillLevel}
        />
        <ScoreChip score={score[0]?.score?.auto} maxScore={score[0]?.score?.auto_max} />
      </Box>
    </Card>
  );
};

export default ScoreReviewCard;
