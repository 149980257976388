import {
  DocumentsType as CTDocumentsType,
  LessonsType as CTLessonsType,
  StandardRecord as CTStandardRecord,
  StandardsRecord as CTStandardsRecord,
  SkillRecord as CTSkillRecord,
} from '@CT-Types/lessonDomain';

export type DocumentsType = CTDocumentsType;

export type StandardRecord = CTStandardRecord;
export type StandardsRecord = CTStandardsRecord;

export type SkillRecord = CTSkillRecord;

export type LessonsType = CTLessonsType;

export type LessonDetailsType = {
  id: number;
  name: string;
  group_order: number;
  plan_url: string;
  can_practice: boolean;
  has_documents: boolean;
  standards: Array<StandardRecord>;
  lessons: Array<LessonsType>;
  skills: Array<SkillRecord>;
};
export type LessonDetailsResponseType = LessonDetailsType;
export enum LessonPracticeStatus {
  CompletedLessonPractice = 1,
  Assigned = 2,
}

export type ScoreObjectType = { totalScore: number; totalMaxScore: number };

export enum LessonTabs {
  LessonPlan = 'lesson-plan',
  Lesson = 'lesson',
  LessonPractice = 'olp',
  Practice = 'practice',
  Intervention = 'intervention',
  StudyHall = 'study-hall',
  LearnosityAdaptivePractice = 'learnosity-adaptive-practice',
}

export type LessonAssignmentDetailObject = {
  section: number;
  unit: number;
  subject: number;
  asset_type: null;
  lesson_group: number;
  assigned_to: number;
  last_assigned: string;
  last_completed: string;
};
export type LessonAssignmentDetailResponseType = Array<LessonAssignmentDetailObject>;

// OERS
export type EducationalResourceObjectType = {
  id: number;
  lesson_node_id: number;
  source_name: string;
  source_url: string;
  source_category: string;
  category_order: number;
};
export type EducationalResourceResponse = Array<EducationalResourceObjectType>;

export type AttendanceObject = {
  id: number;
  recorded: Date;
  lesson: number;
  activity: 'lesson' | 'group';
  section: number;
  recorded_by: number;
  student: number;
  present: boolean | null;
};
export type AttendanceRecordsResponse = Array<AttendanceObject>;

export type UnitMetaObject = {
  lesson_practice: boolean;
  group_activity: boolean;
  unit_practice: boolean;
  lesson_slides: boolean;
};
export type UnitObjectType = {
  id: number;
  name: string;
  slug: string;
  curriculum_subject: number;
  public: boolean;
  premium: boolean;
  meta: UnitMetaObject;
  practice_count: number;
};

export type SubjectObjectType = {
  id: number;
  display_name: string;
  slug: string;
  curriculum_id: number;
  subject_id: number;
  public: boolean;
  required: boolean;
  review_type: number;
  practice_type: number;
  summary: {
    unit_count: number;
    lesson_count: number;
    practice_count: number;
  };
  order: number;
  icon: string;
  version: number;
  units: Array<UnitObjectType>;
};
export type unitMetaDataResponse = Array<SubjectObjectType>;

export interface AccessMetadata {
  message: string;
  feature: {
    id: number;
    name: string;
    slug: string;
  };
  has_access: boolean;
}
export interface FeatureFlagAccessResponse {
  level: string;
  user_access: {
    [key: string]: AccessMetadata;
  };
}
