import { useParams } from 'react-router-dom';
import { useGetSectionSubjectUnitLesson } from 'chalktalk-react/hooks/useGetSectionSubjectUnitLesson';
import { SubjectRecord } from '../types';

const useSubjects = (subjectIdOverride?: number): SubjectRecord[] => {
  const { subjectId } = useParams();
  const { data: allSubjects } = useGetSectionSubjectUnitLesson();

  if (!allSubjects) {
    return [];
  }

  const currentSubjectId = subjectIdOverride || subjectId;

  // This is how we are filtering the subjects based on the first word of the subject name
  // So if we're in "English 9" we will get all subjects that have "English" in their name
  const currentSubject = currentSubjectId
    ? allSubjects.find((subject) => subject.id.toString() === currentSubjectId.toString())
    : null;

  const subjectNameParts = currentSubject?.display_name.split(' ') || [''];

  return currentSubject
    ? allSubjects.filter((subject) => subject.display_name.includes(subjectNameParts[0]))
    : allSubjects;
};

export default useSubjects;
