import Box from '@components/Atoms/Box';
import { useQuestionSelectorHelper } from '@components/QuestionSelector/QuestionSelector';
import { useUser } from '@reducers/user/hooks';
import React, { useCallback } from 'react';
import { AuthorHarness } from '@learnosityassessment';
import { useCourseById } from '@reducers/courses/hooks';
import { useParams } from 'react-router-dom';
import { createLessonReviewAuthoringSignatureRequest } from '@learnosityassessment/AssessmentServiceAPI';
import ShowIf from '@components/Atoms/ShowIf';
import { createRoot } from 'react-dom/client';
import { useCurrentSubject } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { BLOOMS_MAP } from '@components/Selectors/BloomsSelector/BloomsSelector';
import {
  useContentFilterContext,
  useContentFilterElement,
  useContentFilterSkillContext,
  useContentFilterStandardContext,
} from '@components/ContentFilters/ContentFilterProvider';
import { useSkills } from './hooks';
import ItemBankToggleButtonGroup, {
  CHALKTALK,
  MY_QUESTIONS,
} from './ItemBankToggleButtonGroup';
import { useShouldShowCoAuthorFeature } from '../hooks';
import GenerateQuestionsButton from './GenerateQuestionsButton';

import FilterChips from './FilterChips';
import PassageFilter from './PassageFilter';

const useEmptyListHelper = ({ widget, showPreview = false, setOpen }) => {
  const user = useUser();
  const shouldShowCoAuthorFeature = useShouldShowCoAuthorFeature(user.id);

  React.useEffect(() => {
    if (showPreview) {
      return;
    }
    const checkEmptyList = async () => {
      const emptyList = document.querySelector('.lrn-list-view-empty');
      if (emptyList) {
        const label = emptyList.querySelector('.lrn-list-view-empty-label');
        if (label) {
          if (label) {
            label.textContent =
              'No questions found. You can generate new questions with Co-Author.';
          }
        }

        const container = document.createElement('div');

        emptyList.insertBefore(container, emptyList.firstChild);
        const root = createRoot(container);

        root.render(
          <GenerateQuestionsButton
            setOpen={setOpen}
            hasAuthorAideAccess={shouldShowCoAuthorFeature}
          />,
        );

        return () => root.unmount();
      }
    };

    if (widget) {
      const currentWidgetRef = widget;
      currentWidgetRef.on('render:itemlist', checkEmptyList);

      return () => {
        currentWidgetRef.off('render:itemlist', checkEmptyList);
      };
    }
  }, [widget, shouldShowCoAuthorFeature, showPreview, setOpen]);
};

const QuestionSelection = ({ keyId, setOpen }) => {
  const user = useUser();
  const { courseId } = useParams();
  const course = useCourseById(courseId);
  const currentSubject = useCurrentSubject();
  const { data: allSkills = [] } = useSkills();
  const shouldShowCoAuthorFeature = useShouldShowCoAuthorFeature(user.id);
  const {
    selectedPassage,
    selectedBlooms,
    selectedDok,
    selectedGrade,
    selectedReadability,
    setSelectedPassage,
    setSelectedGrade,
    setSelectedReadability,
  } = useContentFilterContext();
  const { selectedSkills, getSelectedSkillObjects } = useContentFilterSkillContext();
  const { selectedStandards, getSelectedStandardObjects } =
    useContentFilterStandardContext();
  const contentFilterRef = React.useRef<HTMLDivElement>(null);
  const ContentFilterElement = useContentFilterElement() as React.ElementType;

  const [widget, setWidget] = React.useState<any>(null);
  useEmptyListHelper({ widget, showPreview: false, setOpen });
  useQuestionSelectorHelper({ widget, showPreview: false });
  const [itemBankKey, setItemBankKey] = React.useState<any>(CHALKTALK);

  const districtId = course?.organization?.district?.id;
  const isMyQuestionBank = itemBankKey === MY_QUESTIONS;
  const shouldShowPassageFilter = currentSubject?.display_name
    ?.toLowerCase()
    ?.includes('english');

  const buildKey = useCallback(() => {
    const standardSkillKey = JSON.stringify([...selectedStandards, ...selectedSkills]);
    const restKey = JSON.stringify([
      selectedBlooms,
      selectedDok,
      selectedPassage?.reference,
      selectedReadability,
      selectedGrade,
    ]);
    return standardSkillKey + restKey + keyId + itemBankKey;
  }, [
    selectedStandards,
    selectedSkills,
    selectedBlooms,
    selectedDok,
    selectedPassage?.reference,
    selectedReadability,
    selectedGrade,
    keyId,
    itemBankKey,
  ]);

  const getSkillsToFilter = () => {
    // return selected skills if they exist
    if (selectedSkills.length) {
      return getSelectedSkillObjects();
    }

    // // if standards were selected but no skills, return empty array
    // // to avoid adding all skills to the filter
    if (selectedStandards.length) {
      return [];
    }

    // if no standards or skills selected, return all skills to filter the entire item bank
    // with items only tagged with skills
    return allSkills;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '1rem',
        mx: '1rem',
      }}
    >
      {/* Filter Row */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {ContentFilterElement && <ContentFilterElement ref={contentFilterRef} />}
        </Box>
        <ShowIf If={shouldShowCoAuthorFeature}>
          <ItemBankToggleButtonGroup setItemBankKey={setItemBankKey} />
        </ShowIf>
      </Box>

      {/* Filter Summary and Passages */}
      <ShowIf If={shouldShowPassageFilter}>
        <PassageFilter
          defaultReadability={selectedReadability}
          defaultGrade={selectedGrade}
          author={itemBankKey === CHALKTALK ? 'chalktalk' : user.id}
          onChange={(changes) => {
            if ('passage' in changes) {
              setSelectedPassage(changes?.passage ?? null);
            }

            if ('readability' in changes) {
              setSelectedReadability(changes?.readability ?? null);
            }

            if ('grade' in changes) {
              setSelectedGrade(changes?.grade ?? null);
            }
          }}
        />
      </ShowIf>
      <ShowIf If={!shouldShowPassageFilter}>
        <FilterChips />
      </ShowIf>

      {/* AuthorApp Widget */}
      <AuthorHarness
        key={buildKey()}
        user={{
          id: `${user.id}`,
          district_id: `${districtId}`,
        }}
        setWidget={setWidget}
        createSignature={() =>
          createLessonReviewAuthoringSignatureRequest(
            getSkillsToFilter(),
            isMyQuestionBank ? user.id : undefined,
            selectedPassage ? selectedPassage.reference : undefined,
            selectedStandards.length ? getSelectedStandardObjects() : undefined,
            selectedBlooms ? BLOOMS_MAP[selectedBlooms] : undefined,
            selectedDok?.toString(),
            selectedReadability ?? undefined,
            selectedGrade ?? undefined,
          )
        }
      />
    </Box>
  );
};

export default QuestionSelection;
