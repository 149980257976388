import {
  all, call, put,
  takeLeading, select,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import * as UserAPIs from '../../apis/users';
import { success as ToastSuccess, error as errorToast } from '../../utils/toast';
import {
  getUserId,
  USER_CHANGE_PASSWORD,
  USER_REPORT_ISSUE,
  SUBMIT_USER_EDIT,
  actions as UserActions,
} from '../../reducers/user';

export function* submitUserEdit({ values }) {
  try {
    const userId = yield select(getUserId);
    const { response, error } = yield call(UserAPIs.submitUserEdit, userId, values);
    if (error) throw error;

    const { data } = response;
    yield put(UserActions.submitUserEditSuccess(data));
    ToastSuccess('Detail were updated successfully');
  } catch (error) {
    const message = Array.isArray(error) ? _get(error, '[0]', 'Unexpected error') : error;
    errorToast(message);
    yield put(UserActions.submitUserEditFailed(error.message || error));
  }
}

export function* changeUserPassword({ oldPass, newPass1, newPass2 }) {
  try {
    const { error } = yield call(UserAPIs.changePassword, {
      old_password: oldPass,
      new_password1: newPass1,
      new_password2: newPass2,
    });
    if (error) throw error;

    yield put(UserActions.changePasswordSuccess());
    ToastSuccess('Password changed successfully');
  } catch (error) {
    yield put(UserActions.changePasswordFailed(error.message || error));
    errorToast('Unable to change your password! Please try again!');
  }
}

function* userReportIssue({ payload, callback }) {
  try {
    const { error } = yield call(UserAPIs.userReportIssue, payload);
    if (error) throw error;

    yield put(UserActions.userReportIssueSuccess());
    ToastSuccess('Feedback was submitted successfully');

    if (callback) {
      callback();
    }
  } catch (error) {
    const message = Array.isArray(error) ? _get(error, '[0]', 'Unexpected error') : error;
    errorToast(message);
    yield put(UserActions.userReportIssueFail(error));
  }
}

export default function* usersSaga() {
  yield all([
    takeLeading(SUBMIT_USER_EDIT, submitUserEdit),
    takeLeading(USER_CHANGE_PASSWORD, changeUserPassword),
    takeLeading(USER_REPORT_ISSUE, userReportIssue),
  ]);
}
