import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import ScheduleAddReviewSession from '../../components/ScheduleAddReviewSession';
import { actions as SectionReviewActions, selectors as SectionReviewSelectors } from '../../reducers/sectionReviews';
import { actions as SectionSubjectActions, selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import { actions as SchedulteModalActions, selectors as ScheduleModalSelectors } from '../../reducers/scheduleModal';

const mapStateToProps = (state, { match, updateScheduleList }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  return {
    sectionId,
    updateScheduleList,
    subjects: SectionSubjectSelectors.getSectionSubjectList(state),
    isAdding: SectionReviewSelectors.isLoadingReviewAdding(state),
    sessionDate: ScheduleModalSelectors.getSessionDate(state),
  };
};

const mapDispatchToProps = {
  sectionReviewAdd: SectionReviewActions.sectionReviewAdd,
  fetchSectionSubjectList: SectionSubjectActions.fetchSectionSubjectList,
  clearSessionDate: SchedulteModalActions.clearSessionDate,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleAddReviewSession));
