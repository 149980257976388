import React from 'react';
import Button from '@components/Atoms/Button/Button';
import Typography from '@components/Atoms/Typography';
import { Avatar, Badge, useMediaQuery } from '@mui/material';
import { FilterType } from './ContentFilters';
import { fontSize } from '@mui/system';
import Box from '@components/Atoms/Box';

interface ContentGroupButtonProps {
  type: FilterType;
  color: string;
  count: number;
  onClick: (type: FilterType, e: HTMLElement) => void;
  disabled?: boolean;
}

const ContentGroupButton = ({
  type,
  color = 'chipGrayCT',
  count,
  onClick,
  disabled = false,
}: ContentGroupButtonProps) => (
  <Box key={type} sx={{ position: 'relative', flex: 1 }}>
    <Badge
      badgeContent={count}
      showZero
      sx={{
        position: 'absolute',
        top: -4,
        right: '0.25rem',
        '& .MuiBadge-badge': {
          backgroundColor: color,
          color: 'white',
        },
      }}
    />
    <Button
      variant="outlined"
      fullWidth
      color="chipGrayCT"
      sx={{
        flex: 1,
      }}
      onClick={(e) => onClick(type, e.currentTarget)}
      disabled={disabled}
    >
      <Typography
        sx={{
          flexGrow: 1,
          textAlign: 'center',
          '&::first-letter': {
            textTransform: 'uppercase',
          },
        }}
      >
        {type}
      </Typography>
    </Button>
  </Box>
);

export default ContentGroupButton;
