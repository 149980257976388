import React from 'react';
import { useDrag } from 'react-dnd';
import Chip from '@mui/material/Chip';
import { useIsTeacher } from '@reducers/user/hooks';
import { GroupStatusEnum } from '../types';

type Props = {
  id: number;
  rowId: number;
  member: {
    status: number;
    student_name: string;
  };
  isActivityStarted: boolean;
};

const StudentChip = ({ id, rowId, member, isActivityStarted }: Props) => {
  const isTeacher = useIsTeacher();
  const [{ isDragging, canDrag }, dragRef] = useDrag({
    type: 'GROUPNAME',
    item: {
      sourceId: id,
      sourceRowId: rowId,
    },
    canDrag: () =>
      !isActivityStarted && isTeacher && member.status !== GroupStatusEnum.FINISHED,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
      canDrag: !!monitor.canDrag(),
    }),
  });
  if (isDragging) {
    return null;
  }
  return (
    <Chip
      variant="outlined"
      label={member.student_name}
      data-testid="student-chip"
      id={`student-name-${id}${rowId}`}
      ref={dragRef}
      sx={[
        {
          // // transform is used to hide background color when dragging
          transform: 'scale(1,1)',
          backgroundColor: 'white',
        },
        canDrag && {
          '&:hover': {
            transform: 'scale(1.2,1.2) !important',
            position: 'absolute',
            cursor: 'grab',
          },
        },
      ]}
    />
  );
};

export default StudentChip;
