import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import CommonUtils, { countLines } from '../../utils/commonUtils';
import { NavigationContentWrap } from './QuestionAssets.style';
import addImageErrorListener from '../../utils/errorCapture';

const scrollStyle = {
  height: 'calc(100vh - 9em)',
  width: '100%',
};

const scrollStyleWithFooter = {
  height: 'calc(100vh - 12em)',
  width: '100%',
};

// type Reading Passage and Answer
const AssetTypeSpecial = ({ asset, hasFooter, scrollBarStyle }) => {
  const assetRef = React.useRef(null);
  const { asset_type: assetType, text } = asset;
  const html = React.useMemo(
    () => CommonUtils.sharedAssetsStandardize(text, assetType).replace(/\\r\\n/g, ''),
    [text, assetType],
  );
  const defaultScrollbarStyle = hasFooter ? scrollStyleWithFooter : scrollStyle;

  React.useEffect(() => {
    if (assetRef.current) {
      countLines(assetRef.current, 0);
      addImageErrorListener(assetRef.current);
    }
  }, [html]);

  return (
    <ScrollWrapper autoHide sx={scrollBarStyle || defaultScrollbarStyle}>
      <NavigationContentWrap dangerouslySetInnerHTML={{ __html: html }} ref={assetRef} />
    </ScrollWrapper>
  );
};

AssetTypeSpecial.propTypes = {
  asset: PropTypes.object,
  hasFooter: PropTypes.bool,
  scrollBarStyle: PropTypes.any,
};

export default AssetTypeSpecial;
