import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LessonSubNavItem from '../SubNavBarItem/LessonSubNavItem';
import { selectors as curriculumLessonSelectors } from '../../reducers/curriculumLessons';
import { selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as UserSelectors } from '../../reducers/user';

export const mapStateToProps = (state, { unitId, lessonId }) => {
  const lessonItem = curriculumLessonSelectors.getLessonByLessonId(state, lessonId);
  const unitItem = sectionUnitSelectors.getUnitById(state, unitId);

  return {
    lessonItem,
    unit: unitItem,
    userId: UserSelectors.getUserId(state),
  };
};

export default withRouter(
  connect(mapStateToProps)(LessonSubNavItem),
);
