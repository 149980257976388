import styled, { css } from 'styled-components';

export const NavigationWrap = styled.div`
  background: #fff;
  padding: 1.5em 1em;
  height: calc(100vh - 3em);
  .primary-layout.show-banner & {
    height: calc(100vh - 3em - 50px);
  }
  ${({ hasFooter }) =>
    hasFooter &&
    css`
      height: calc(100vh - 7.25em);
      .primary-layout.show-banner & {
        height: calc(100vh - 7.25em - 50px);
      }
    `}
  transition: all 400ms ease;
  display: block;
  position: fixed;
  width: 30em;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0em;
    right: 0em;
    z-index: 1;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 0.2) 0px,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .react-tabs {
    height: 100%;
  }
  @media (max-width: 1369px) {
    width: 28em;
  }
  @media (max-width: 768px) {
    left: 6.5em;
    width: calc(100% - 6.5em);
    padding: 0.3125em;
  }
  ${({ isClose }) =>
    isClose &&
    css`
      left: -22em;
      box-shadow: none;
      .react-tabs {
        opacity: 0;
      }
      @media (max-width: 1369px) {
        left: -20em;
      }
      @media (max-width: 768px) {
        left: 6.5em;
        width: calc(100% - 6.5em);
        padding: 0.3125em;
        .react-tabs {
          opacity: 1;
        }
      }
    `}
`;

export const NavigationWrapMobile = styled(NavigationWrap)`
  position: initial;
  padding: 0px;
  height: auto;
`;

export const MobileScrollBarStyle = {
  width: '100%',
  height: 'calc(100vh - 12.5em)',
};

export const NavigationHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 1em;
    li {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      padding: 0.75em 0em;
      color: #b0aba8;
      font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
      border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colorStyled.ColorPrimary};
        border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorBlack};
      }
    }
    .react-tabs__tab--selected {
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
      border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorBgDefault};
      &:hover {
        color: ${(props) => props.theme.colorStyled.ColorBgDefaultHover};
        border-bottom: 1.5px solid
          ${(props) => props.theme.colorStyled.ColorBgDefaultHover};
      }
    }
  }
  @media (max-width: 768px) {
    .react-tabs__tab-list {
      margin-right: 0em;
    }
  }
`;

export const NavigationContentWrap = styled.div`
  padding: 10px 0px;
  height: 100%;
  font-size: ${(props) => props.theme.fontSize.MediumFontSize};
  font-family: 'MontserratRegular';
  margin: 10px 0px 10px 20px;
  padding-top: 2.5em;
  strong,
  b {
    background: gray;
    padding: 0px 8px 0px 8px;
    color: white;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
  img {
    width: 100% !important;
    height: auto !important;
  }
  color: ${(props) => props.theme.colorStyled.ColorDarkGrey};
  line-height: 18px;
  p {
    margin-bottom: 15px;
    display: block;
  }
  ${(props) =>
    props.titleContent &&
    css`
      margin-bottom: 20px;
    `}
  ${(props) =>
    props.hide
      ? css`
          visibility: hidden;
        `
      : css`
          visibility: visible;
        `}
  p[data-event-id="Shared Asset Text"] {
    position: relative;
    span[hidden='true'] {
      display: none;
    }
    span {
      font-size: 1em;
      &.line-no {
        left: -20px;
      }
    }
    span.line-no {
      position: absolute;
      font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
      color: #a5a5a5;
      &.line-no {
        left: -20px;
      }
    }
  }
  @media (max-width: 1369px) {
    font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
    span {
      font-size: ${(props) => props.theme.fontSize.DefaultFontSize} !important;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    p {
      max-width: calc(100% - 75px);
    }
  }
  @media (max-width: 480px) {
    padding-bottom: 10px;
    font-size: ${(props) => props.theme.fontSize.SmallMobileFontSize} !important;
    p {
      max-width: calc(100% - 35px);
    }
    p[data-event-id='Shared Asset Text'] {
      span {
        &.line-no {
          left: -18px;
        }
      }
      span.line-no {
        &.line-no {
          left: -18px;
        }
      }
    }
    span {
      font-size: ${(props) => props.theme.fontSize.XXSmallMobileFontSize} !important;
    }
  }
`;
