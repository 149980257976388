import { connect } from 'react-redux';
import {
  InfoModal as InfoModalComponent,
} from '../../components/ScheduleCalendar/Modals';
import {
  selectors as ScheduleModalSelectors,
  actions as ScheduleModalActions,
} from '../../reducers/scheduleModal';
import { selectors as UserSelectors } from '../../reducers/user';
import { ROLE_TYPE } from '../../utils/enums';

const mapStateToProps = (state) => {
  const isOpen = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR ? ScheduleModalSelectors.isModalOpen(state) : false;
  return {
    isOpen,
    modalData: ScheduleModalSelectors.getModalData(state),
  };
};

const mapDispatchToProps = {
  closeForm: ScheduleModalActions.closeFormModal,
};

export const InfoModal = connect(mapStateToProps, mapDispatchToProps)(InfoModalComponent);
