/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const AnswerMultiChoiceItem = styled.div`
  display: flex;
  align-content: center;
  /* border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorBorder}; */
  width: 100%;
  gap: 0.5rem;
`;

export const AnswerWrapper = styled.div<{ isCorrect: boolean }>`
  display: flex;
  width: 100%;
  align-items: start;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colorStyled.ColorBorder};
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-sizing: border-box;
  gap: 0.5rem;
  .answer-text {
    width: 20%;
    margin-right: 2px;
  }
  ${({ isCorrect }) =>
    isCorrect &&
    css`
      border-color: ${(props) => props.theme.colorStyled.ColorSuccess};
    `}
`;

export const AnswerChoiceContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  padding-top: 0.5rem;
  @media (max-width: 768px) {
    p {
      img {
        width: 100% !important;
        object-fit: scale-down;
      }
    }
  }
`;

export const AnswerChoiceLabel = styled.span<{ isSelected: boolean; disabled: boolean }>`
  height: 1.75rem;
  flex: 0 0 1.75rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'MontserratMedium';
  border: 2px solid ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  font-size: ${(props) => props.theme.fontSize.LargeFontSize};
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${(props) => props.theme.colorStyled.ColorLighterGrey};
      border-color: ${(props) => props.theme.colorStyled.ColorLighterGrey};
      cursor: not-allowed;
    `}
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      color: ${(props) => props.theme.colorStyled.ColorWhite};
      border-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    `}
  padding-top: 1px;
`;
