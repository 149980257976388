import React, { Component } from 'react';
import Modal from 'react-modal';
import { Form, Formik, yupToFormErrors } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import MediaQuery from 'react-responsive';
import { ModalHeader, ModalCLose } from '../Generals/stylesheets/General.style';
import FormInput from '../Generals/FormInput/FormInput';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  EXAM_MID_TYPES,
  EXAM_MID_TYPES_ACT,
  RENDER_TEST_DURATION_OPTIONS,
} from '../../utils/constants';
import { formatDate } from '../../utils/func-utils';
import * as SectionAPI from '../../apis/sections';
import { renderAlertModal } from '../../utils/userAlert';
import { ButtonWrapper } from './LeftSiderBar.style';
import { generateTestAdministrationData } from '@utils/scheduling';

const validationSchema = Yup.object().shape({
  examType: Yup.mixed().required('Required'),
  testDurration: Yup.mixed().required('Required'),
  testAdminstration: Yup.mixed().required('Required'),
  startDate: Yup.date().required('Required'),
});
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '600px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    overflow: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class LeftSideAddOrEditTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examType: '',
      rapid: 0,
      setPlacement: 0,
      examSelected: null,
      dateSelected: '',
      durrationSelected: -1,
      sittingsSelected: 0,
    };
  }

  static propTypes = {
    sectionId: PropTypes.any,
    curriculum_Id: PropTypes.any,
    isAdding: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    isSubmitSuccessfully: PropTypes.bool,
    sectionExamGet: PropTypes.func,
    sectionExamAdd: PropTypes.func,
    sectionExamDelete: PropTypes.func,
    sectionExamUpdate: PropTypes.func,
    examSessons: PropTypes.array,
  };

  renderSectionExam = () => {
    const { curriculumId, examSessons, actionName } = this.props;
    if (actionName === 'Add') {
      if (curriculumId == 1) return EXAM_MID_TYPES.arrays;
      return EXAM_MID_TYPES_ACT.arrays;
    }
    let midCount = 0;
    const exams = examSessons.map((ex) => {
      if (ex.session_type == 0) {
        return { id: ex.id, name: 'Placement-Test' };
      } if (ex.session_type == 1) {
        midCount += 1;
        return { id: ex.id, name: `Mid-Test ${midCount}` };
      }
      return { id: ex.id, name: 'Exit-Test' };
    });
    return exams;
  };

  onClose = () => {
    const { onClose, onSave } = this.props;
    onClose();
    onSave();
  };

  onSubmitForm = async () => {
    const {
      examSelected, dateSelected, durrationSelected, sittingsSelected,
    } = this.state;
    const {
      sectionId,
      curriculumId,
      sectionExamAdd,
      sectionExamUpdate,
      actionName,
      examSessons,
    } = this.props;

    const placementExams = examSessons.filter((ex) => ex.session_type == 0);
    const midExams = examSessons.filter((ex) => ex.session_type == 1);
    const exitExams = examSessons.filter((ex) => ex.session_type == 2);

    if (actionName === 'Add') {
      const payload = {
        start_date: formatDate(dateSelected),
        end_date: formatDate(dateSelected),
        session_type: 1,
        published: false,
        length_type: durrationSelected,
        section: sectionId,
        curriculum: curriculumId,
        sittings_number: sittingsSelected,
      };

      if (
        formatDate(dateSelected)
        < formatDate(placementExams[placementExams.length - 1].start_date)
        || formatDate(dateSelected) > formatDate(exitExams[0].start_date)
      ) {
        renderAlertModal(
          'There are no new tests available for this session type.',
        );
      } else {
        await SectionAPI.updateSectionSettings(sectionId, { sittings_number: sittingsSelected });
        sectionExamAdd(payload);
        this.onClose();
        location.reload();
      }
    } else {
      let scores = [];

      const response = await SectionAPI.getSectionExamsScores(sectionId).then(
        (responseData) => {
          scores = responseData.response.data;
        },
      );

      const isTaking = scores[examSelected.id]
        ? scores[examSelected.id].length > 0
        : true;
      if (isTaking) {
        renderAlertModal(
          "Some students are taking this exam now.You can't apply any changes or delete this exam",
        );
      } else {
        let valid = true;
        if (examSelected.session_type == 0) {
          if (midExams.length > 0) {
            if (formatDate(dateSelected) > formatDate(midExams[0].start_date)) {
              valid = false;
            }
          } else if (
            formatDate(dateSelected) > formatDate(exitExams[0].start_date)
          ) {
            valid = false;
          }
        } else if (examSelected.session_type == 1) {
          if (
            formatDate(dateSelected)
            < formatDate(
              placementExams[placementExams.length - 1].start_date,
            )
            || formatDate(dateSelected) > formatDate(exitExams[0].start_date)
          ) {
            valid = false;
          }
        } else if (midExams.length > 0) {
          if (formatDate(dateSelected)
            < formatDate(midExams[midExams.length - 1].start_date)) {
            valid = false;
          }
        } else if (formatDate(dateSelected)
          < formatDate(placementExams[placementExams.length - 1].start_date)) {
          valid = false;
        }
        if (valid) {
          const payload = {
            start_date: formatDate(dateSelected),
            end_date: formatDate(dateSelected),
            session_type: examSelected.session_type,
            published: examSelected.published,
            length_type: durrationSelected,
            section: examSelected.section,
            curriculum: curriculumId,
          };
          await SectionAPI.updateSectionSettings(sectionId, { sittings_number: sittingsSelected });
          sectionExamUpdate(examSelected.section, examSelected.id, payload);
          this.onClose();
        } else {
          renderAlertModal(
            'There are no new tests available for this session type.',
          );
        }
      }
    }
  };

  deleteExam = () => {
    const { sectionExamDelete, onSave } = this.props;
    const { examSelected } = this.state;
    sectionExamDelete(examSelected.id);
    this.onClose();
  };

  handleDate = (e) => {
    this.setState({ dateSelected: e });
  };

  handleTestDurration = (e) => {
    this.setState({ durrationSelected: e.id });
  };

  handleTestSittings = (e) => {
    this.setState({ sittingsSelected: e.id });
  };

  handleSelectExam = (e) => {
    const { actionName } = this.props;
    if (actionName != 'Add') {
      const exam = this.props.examSessons.find((ex) => ex.id == e.id);
      this.setState({
        examSelected: exam,
        durrationSelected: exam.length_type,
        dateSelected: exam.start_date,
      });
    }
  };

  renderForm = () => {
    const { curriculumId, curriculumName, sectionDetail, actionName } = this.props;
    const { examSelected } = this.state;
    let subjects = [];
    if (sectionDetail) {
      subjects = sectionDetail.subjects.map((s) => s.display_name);
    }
    let disabled = true;
    if (examSelected) {
      if (examSelected.session_type == 1) disabled = false;
    }
    return (
      <Form>
        <FormInput
          type="select"
          name="examType"
          label="Test Type"
          options={this.renderSectionExam()}
          handleChange={this.handleSelectExam}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
        />

        <FormInput
          type="select"
          name="testDurration"
          label="Test Duration"
          disabled={disabled && actionName != 'Add'}
          options={RENDER_TEST_DURATION_OPTIONS(curriculumId, subjects)}
          handleChange={this.handleTestDurration}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
        />

        <FormInput
          type="select"
          name="testAdminstration"
          label="Test Adminstration"
          options={generateTestAdministrationData(subjects, sectionDetail.class_duration, curriculumId)}
          handleChange={this.handleTestSittings}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
        />

        <FormInput
          type="date"
          name="startDate"
          label="Start Date"
          dateFormat="yyyy-MM-dd"
          minDate={new Date()}
          handleChange={this.handleDate}
        />

        <ButtonWrapper>
          <Button
            onClick={this.deleteExam}
            style={{
              background: '#453535',
              color: '#fff',
              borderRadius: '7px',
              display: disabled ? 'none' : 'flex',
              margin: '10px',
            }}
          >
            Delete Test Session
          </Button>
          <Button
            type="submit"
            style={{
              background: '#f99d1e',
              color: '#fff',
              borderRadius: '7px',
              margin: '10px',
            }}
            submit
          >
            Submit Test Session
          </Button>
        </ButtonWrapper>
      </Form>
    );
  };

  customStyle = (matches) => {
    const { content } = modalStyles;
    if (matches) {
      return ({
        ...modalStyles,
        content: {
          ...content,
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          borderRadius: '0px',
          overflow: 'hidden',
        },
      });
    }
    return modalStyles;
  }

  render() {
    const {
      isOpen, sectionDetail, actionName, curriculumId,
    } = this.props;
    const { examSelected } = this.state;
    let subjects = [];
    if (sectionDetail) {
      subjects = sectionDetail.subjects.map((s) => s.display_name);
    }
    const newInitialValues = {};
    if (this.props.actionName != 'Add' && examSelected) {
      newInitialValues.testDurration = RENDER_TEST_DURATION_OPTIONS(
        curriculumId,
        subjects,
      ).find((e) => e.id == examSelected.length_type);
      newInitialValues.startDate = new Date(examSelected.start_date);
      newInitialValues.examType = this.renderSectionExam().find(
        (ex) => ex.id == examSelected.id,
      );
      newInitialValues.testAdminstration = '';
    } else {
      newInitialValues.testDurration = '';
      newInitialValues.startDate = '';
      newInitialValues.examType = '';
      newInitialValues.testAdminstration = '';
    }
    return (
      <MediaQuery maxWidth={768}>
        {matches => (
          <Modal
            isOpen={isOpen}
            onRequestClose={this.onClose}
            style={this.customStyle(matches)}
            ariaHideApp={false}
          >
            <ModalHeader
              style={{
                marginBottom: '2em',
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              {`${actionName} Test Session`}
              <ModalCLose className="chalktalk-close" onClick={this.onClose} />
            </ModalHeader>
            <Formik
              initialValues={newInitialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={this.onSubmitForm}
              render={this.renderForm}
            />
          </Modal>
        )}
      </MediaQuery>
    );
  }
}
