import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginLoadingIndicator from '../LoginPage/LoginLoadingIndicator';

export default class SchoologyLoadingPage extends Component {
  static propTypes = {
    schoologyLogin: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    isAuthenticated: PropTypes.bool,
  }

  componentDidMount() {
    const {
      schoologyLogin, location,
      isAuthenticated, connectSchoology
    } = this.props;
    const { match } = this.props;
    const { params } = match;
    const { code } = params;


    if (!isAuthenticated) schoologyLogin(code);
    else connectSchoology(code);
  }

  render() {
    return <LoginLoadingIndicator content="Connecting your account with Schoology" />;
  }
}
