import React from 'react';
import Button from '@components/Atoms/Button';
import { AttendanceStatusKeys } from './types';

interface AttendanceStatusButtonProps
  extends Omit<React.ComponentProps<typeof Button>, 'children'> {
  presentStatus: boolean | null;
  disabled: boolean;
}

const getBackgroundColor = (value: boolean | null) => {
  if (value) {
    return 'play.main';
  }
  if (value === false) {
    return 'error.main';
  }
  return '#D2D2D2';
};

const getPresenceKey = (status) => {
  if (status === true) {
    return AttendanceStatusKeys.Present;
  }
  if (status === false) {
    return AttendanceStatusKeys.Absent;
  }
  return AttendanceStatusKeys.UnRecorded;
};

const AttendanceStatusButton = ({
  presentStatus,
  disabled,
  ...props
}: AttendanceStatusButtonProps) => {
  const backgroundColor = getBackgroundColor(presentStatus);
  const presence = getPresenceKey(presentStatus);

  return (
    <Button
      data-testid="attendance-status"
      disabled={disabled}
      sx={{
        backgroundColor,
        fontSize: 'fontSize',
        borderRadius: '50%',
        minWidth: '1.5rem',
        minHeight: '1.5rem',
        display: 'flex',
        padding: '0',
        margin: '0',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 300ms ease',
        color: presence === AttendanceStatusKeys.UnRecorded ? 'black' : 'white',
        cursor: 'pointer',
        '@media (max-width: 360px)': {
          alignSelf: 'flex-end',
        },
        '&:hover': {
          backgroundColor,
        },
      }}
      {...props}
    >
      {presence}
    </Button>
  );
};

export default AttendanceStatusButton;
