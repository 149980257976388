import React from 'react';
import { WarningIcon } from '@components/Generals/stylesheets/Button.style';
import { useReportErrorModal } from '@components/PracticeV2/Provider/PracticeReportProvider';
import { REPORT_TYPES } from '@utils/enums';
import { QuestionHeaderWrap, QuestionProgress } from './QuestionsHeader.style';
import { useQuestion } from '../../Provider/PracticeProvider';
import { usePracticeData } from '../../Provider/PracticeDataProvider';

const QuestionsHeader = () => {
  const { numberOfQuestions } = usePracticeData();
  const question = useQuestion();
  const { handleOpenReportModal } = useReportErrorModal();
  return (
    <QuestionHeaderWrap>
      <QuestionProgress>
        Question
        <span>{`${(question?.index || 0) + 1} / ${numberOfQuestions}`}</span>
      </QuestionProgress>
      <WarningIcon
        className="chalktalk-error-wr"
        data-testid="report-error-button"
        onClick={() => handleOpenReportModal(REPORT_TYPES.QUESTION)}
      />
    </QuestionHeaderWrap>
  );
};

export default React.memo(QuestionsHeader);
