/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { getCurriculumExamSections } from '@apis/curriculum';
import { useParams } from 'react-router-dom';
import { useCourseById } from '@reducers/courses/hooks';

export const useGetCurriculumExamSections = () => {
  const { courseId } = useParams();
  const courseDetails = useCourseById(courseId);
  const curriculumId = courseDetails.curriculum;

  const response = useQuery<any>(
    ['getExamSections', curriculumId],
    async () => {
      const res = (await getCurriculumExamSections(curriculumId)) as unknown as {
        data: any;
      };
      return res?.data || [];
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: !!curriculumId,
      staleTime: 1000 * 60 * 60,
      keepPreviousData: false,
      
    },
  );
  return response;
};
