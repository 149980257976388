import styled, { css } from 'styled-components';
import { Button } from '../Generals/stylesheets/Button.style';

export const StudentListHeaderWrap = styled.div`
  display: flex;
  min-height: 1.75em;
  min-width: calc(385px + 56%);
  color: ${props => props.theme.colorStyled.ColorTopicUpIcon};
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorXBorder};
  padding: 1em 0;
  @media (max-width: 768px) {
    height: 3em;
  }
`;
export const StudentTableWrap = styled.div`
  display: none;
  padding: 0 1em;
  background: #f1f1f1;
  overflow: auto;
  flex-direction: column;
  ${props => props.isExpanded && css`
    display: flex;
  `}
`;

export const StudentListWrap = styled.div`
  min-width: calc(385px + 56%);
  padding: 1em 0;
`;

export const StudentListItemWrap = styled.div`
  display: flex;
  align-items: center;
  min-height: 1.875em;
  padding: 0.5em 0;
  @media (max-width: 768px) {
    min-height: 3em;
  }
`;

export const SectionRowItem = styled.div`
  width: 16%;
  text-align: center;
  user-select: none;
  font-size: ${props => props.theme.fontSize.MediumFontSize};
  text-transform: capitalize;
  &:first-child{
    text-align: left;
    flex: 0 0 155px;
  }
  &:last-child{
    flex: 1;
    min-width: 230px;
    text-align: right;
  }
  @media (max-width: 768px) {
    &:first-child{
      flex: 0 0 150px;
    }
  }
`;

export const StudentListHead = styled(SectionRowItem)`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #8D8D8D;
  ${({ textAlign }) => textAlign && css`
    justify-content: center;
  `}
  @media (max-width: 768px) {
    padding: 5px;
  }
`;

export const StudentListItem = styled(SectionRowItem)`
  ${({ groupAction }) => groupAction && css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `};
  @media (max-width: 768px) {
    line-height: 1em;
    padding: 5px;
  }
`;

export const StudentListButton = styled(Button)`
  cursor: pointer;
  margin-left: .75em;
  height: 20px;
  border-radius: 5px;
  font-size: 0.95em;
  min-width: 72px;
  &:hover {
    opacity: 0.7;
  }
`;

export const SortBtnWrap = styled.div`
  margin-left: 5px;
  font-size: ${props => props.theme.fontSize.SmallFontSize};
`;
