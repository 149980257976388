import {
  all, takeLeading,
  call, put,
} from 'redux-saga/effects';
import {
  CURRICULUMS_FETCH_SUBJECT_LIST,
  CURRICULUMS_FETCH_LIST,
  CURRICULUMS_FETCH_STANDARD_LIST,
  CURRICULUMS_FETCH_PLACEMENT_TYPE,
  CURRICULUMS_FETCH_EXIT_TYPE,
  CURRICULUMS_FETCH_TRAINNING,
  CURRICULUMS_FETCH_TEACHER_TRAINING,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import * as CurriculumAPIs from '../../apis/curriculum';

export function* getAllCurriculums() {
  try {
    const { response, error } = yield call(CurriculumAPIs.getCurriculumList);
    if (error) throw error;

    const { data: curriculumList } = response;

    yield put(CurriculumActions.getCurriculumListSuccess(curriculumList));
  } catch (error) {
    yield put(CurriculumActions.getCurriculumListFailed(error.message || error));
  }
}

export function* getAllStandards() {
  try {
    const { response, error } = yield call(CurriculumAPIs.getStandard);
    if (error) throw error;

    const { data: standardList } = response;
    yield put(CurriculumActions.getCurriculumStandardListSuccess(standardList));
  } catch (error) {
    yield put(CurriculumActions.getCurriculumStandardListFailed(error.message || error));
  }
}

export function* getSubjects({ payload }) {
  const { curriculumId } = payload;
  try {
    const { response, error } = yield call(CurriculumAPIs.getSubject, curriculumId);
    if (error) throw error;

    const { data: subjectList } = response;
    yield put(CurriculumActions.getCurriculumSubjectListSuccess(curriculumId, subjectList));
  } catch (error) {
    yield put(CurriculumActions.getCurriculumSubjectListFailed(curriculumId, error.message || error));
  }
}

function* getCurriculumPlacementType({ curriculumId }) {
  const { response, error } = yield call(CurriculumAPIs.getAvailableExamSessions, curriculumId, { t: 0 });

  if (response) {
    const { data } = response;
    yield put(CurriculumActions.getCurriculumPlacementTypeSuccess(curriculumId, data));
  } else {
    yield put(CurriculumActions.getCurriculumPlacementTypeFail());
  }
}

function* getCurriculumExitType({ curriculumId }) {
  const { response, error } = yield call(CurriculumAPIs.getAvailableExamSessions, curriculumId, { t: 2 });

  if (response) {
    const { data } = response;
    yield put(CurriculumActions.getCurriculumExitTypeSuccess(curriculumId, data));
  } else {
    yield put(CurriculumActions.getCurriculumExitTypeFail());
  }
}

function* getCurriculumTraining({ curriId }) {
  const { response, error } = yield call(CurriculumAPIs.getTrainings, curriId);

  if (response) {
    const { data } = response;
    yield put(CurriculumActions.getCurriculumTrainingSuccess(curriId, data));
  } else {
    yield put(CurriculumActions.getCurriculumTrainingFail(error));
  }
}

function* getCurriculumTeacherTraining({ curriId }) {
  const { response, error } = yield call(CurriculumAPIs.getTeacherTraining, curriId);

  if (response) {
    const { data } = response;
    yield put(CurriculumActions.getCurriculumTrainingSuccess(curriId, data));
  } else {
    yield put(CurriculumActions.getCurriculumTrainingFail(error));
  }
}

export default function* curriculumsSaga() {
  yield all([
    takeLeading(CURRICULUMS_FETCH_LIST, getAllCurriculums),
    takeLeading(CURRICULUMS_FETCH_STANDARD_LIST, getAllStandards),
    takeLeading(CURRICULUMS_FETCH_SUBJECT_LIST, getSubjects),
    takeLeading(CURRICULUMS_FETCH_PLACEMENT_TYPE, getCurriculumPlacementType),
    takeLeading(CURRICULUMS_FETCH_EXIT_TYPE, getCurriculumExitType),
    takeLeading(CURRICULUMS_FETCH_TRAINNING, getCurriculumTraining),
    takeLeading(CURRICULUMS_FETCH_TEACHER_TRAINING, getCurriculumTeacherTraining),
  ]);
}
