import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {
  CompareVersionsWrapper,
  CloseButtonWrapper,
  ModalHeaderWrapper,
  ModalHeader,
  ModalHeaderTitle,
  ModalHeaderText,
  ModalHeaderSubWrapper,
  SubmitButton,
  ModalBodyWrapper,
  ModalToggleButtonsWrapper,
  ModalHeaderContent,
  HeaderBottomBorder,
} from './CompareVersionsModal.style';
import ToggleButton from '../../Generals/ToggleButton';
import PresentationDiff from '../../PresentationManagement';
import { formatDateAndTime } from '../../../utils/func-utils';

const compareVersionsModalStyles = {
  content: {
    height: '95%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: 'white',
    overflow: 'unset',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const CompareVersionsModal = (props) => {
  const {
    isModalOpen,
    onCloseModal,
    defaultPresentationId,
    copyPresentationId,
    lessonNodeId,
    isReviewer,
    updateReviewStatus,
    isSubmittingSlide,
    teacherName,
    reviewId,
  } = props;

  const [displayOption, setDisplayOption] = useState(1);
  const [errorCode, setErrorCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [slideModifiedTime, setSlideModifiedTime] = useState({
    default: null,
    copy: null,
  });
  const [isSlideModified, setIsSlideModified] = useState(false);

  const getModifiedTime = async (fileId) => {
    const modifiedTime = await window.gapi.client.drive.files
      .get({
        fileId,
        fields: 'id, name, modifiedTime',
      })
      .then((response) => {
        const res = JSON.parse(response.body);
        return res.modifiedTime;
      })
      .catch((err) => null);
    return modifiedTime;
  };

  const getFormattedModifiedTime = async (presentationId) => {
    const modifiedTime = await getModifiedTime(presentationId);
    if (!modifiedTime) return '';
    const formattedTime = formatDateAndTime(modifiedTime)
      .replace('am', 'a.m.')
      .replace('pm', 'p.m.');
    return formattedTime;
  };

  useEffect(() => {
    setIsSlideModified(false);
    const fetchData = async () => {
      if (await window?.gapi?.auth2?.getAuthInstance()) {
        const isSignedIn = await window.gapi.auth2.getAuthInstance().isSignedIn.get();
        if (isSignedIn && !!copyPresentationId && !!defaultPresentationId) {
          const defaultSlideModifiedTime = await getFormattedModifiedTime(
            defaultPresentationId,
          );
          const copySlideModifiedTime = await getFormattedModifiedTime(
            copyPresentationId,
          );
          setSlideModifiedTime({
            default: defaultSlideModifiedTime,
            copy: copySlideModifiedTime,
          });
        }
      }
    };
    fetchData();
  }, [isModalOpen, copyPresentationId, defaultPresentationId]);

  const handleSubmit = (status) => {
    if (isReviewer) {
      updateReviewStatus(status);
    } else {
      updateReviewStatus(lessonNodeId, reviewId, { status });
    }
  };

  const status = isReviewer ? 'Approved' : 'Pending';

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={onCloseModal}
      style={compareVersionsModalStyles}
      ariaHideApp={false}
    >
      <CompareVersionsWrapper>
        <CloseButtonWrapper isCloseButton onClick={onCloseModal}>
          <i className="chalktalk-close" />
        </CloseButtonWrapper>
        <ModalHeaderWrapper>
          <ModalHeader>
            <ModalHeaderTitle>ChalkTalk Version</ModalHeaderTitle>
            <ModalHeaderText>
              {`Last updated: ${slideModifiedTime.default}`}
            </ModalHeaderText>
            <HeaderBottomBorder />
          </ModalHeader>
          <div style={{ width: '10%' }} />
          <ModalHeader>
            <ModalHeaderSubWrapper>
              <ModalHeaderContent>
                <ModalHeaderTitle>
                  {isReviewer ? `${teacherName}'s Version` : 'My Version'}
                </ModalHeaderTitle>
                <ModalHeaderText>
                  {isSlideModified
                    ? `Last updated: ${slideModifiedTime.copy}`
                    : 'No updates have been made'}
                </ModalHeaderText>
              </ModalHeaderContent>
              <SubmitButton
                onClick={() => handleSubmit(status)}
                disabled={isSubmittingSlide || errorCode || !isSlideModified}
              >
                {isReviewer
                  ? "Merge all changes to ChalkTalk's version"
                  : "Submit my changes to ChalkTalk's version"}
              </SubmitButton>
            </ModalHeaderSubWrapper>
            <HeaderBottomBorder />
          </ModalHeader>
        </ModalHeaderWrapper>
        <ModalBodyWrapper>
          {!isLoading && !errorCode && (
            <ModalToggleButtonsWrapper>
              <ToggleButton
                options={['Show All Slides', 'Show Changes Only']}
                selectedOption={displayOption}
                setSelectedOption={(index) => setDisplayOption(index)}
              />
            </ModalToggleButtonsWrapper>
          )}
          <PresentationDiff
            showAllSlides={displayOption === 0}
            defaultPresentationId={defaultPresentationId}
            copyPresentationId={copyPresentationId}
            isReviewer={isReviewer}
            errorCode={errorCode}
            setErrorCode={setErrorCode}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            setIsSlideModified={setIsSlideModified}
          />
        </ModalBodyWrapper>
      </CompareVersionsWrapper>
    </Modal>
  );
};

CompareVersionsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  copyPresentationId: PropTypes.string,
  defaultPresentationId: PropTypes.string,
  lessonNodeId: PropTypes.number,
  isReviewer: PropTypes.bool,
  updateReviewStatus: PropTypes.func,
  isSubmittingSlide: PropTypes.bool,
  teacherName: PropTypes.string,
  reviewId: PropTypes.number,
};

export default CompareVersionsModal;
