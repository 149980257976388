import React from 'react';
import ShowIf from '../ShowIf';
import Box from '../Box';
import Typography from '../Typography';
import { baseBoxStyle, customTextStyle } from './PropertiesInfo.style';

type PropertiesInfoProps = {
  dataTestId?: string;
  property: string;
  value: string | number | React.ReactNode;
  defaultValue: string;
};

/**
 * A component that displays information about a property, including its value and a default value if none is provided.
 * @returns {JSX.Element} A JSX element that displays information about the property.
 */

const PropertiesInfo = ({
  dataTestId = 'prop-info',
  property,
  value,
  defaultValue = '--',
}: PropertiesInfoProps) => (
  <div data-testid={dataTestId}>
    <ShowIf If={!!property}>
      <Box sx={baseBoxStyle.flex}>
        <Typography
          data-testid={`${dataTestId}-info`}
          variant="subtitle1"
          sx={customTextStyle.noWrapText}
        >
          {property}:
        </Typography>
        <Typography data-testid={`${dataTestId}-value`} variant="body1">
          {value || defaultValue}
        </Typography>
      </Box>
    </ShowIf>
  </div>
);

export default PropertiesInfo;
