import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import LessonExamConfirmModal from '../ConfirmModal';
import ExamSubStudentTable from '../../containers/ExamSubStudentTable';
import CommonUtils from '../../utils/commonUtils';
import ColumnSize from './ColumnSize';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import * as ExamAPI from '../../apis/exam';
import Tooltip from '@mui/material/Tooltip';
import {
  SECTION_STATUS,
  ROLE_TYPE,
  RESULT_STATUS,
  EXAM_TYPE,
} from '../../utils/enums';
import {
  SectionListWrap,
  SectionRowIcon,
  SectionRowWrap,
  SectionRowItem,
  SectionRowContent,
  SectionRowIconImg,
  Wrapper,
} from './ExamSectionTable.style';
import { BlockWrap, BlockTitle } from '../Generals/stylesheets/General.style';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  TableHeadWrapper,
  TableContentItem,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';

class ExamSectionTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isReset: false,
      isModalOpen: null,
      isSelectedSection: null,
    };
  }

  componentDidMount = () => {
    this.submitCachedAnswer();
    window.addEventListener('online', this.submitCachedAnswer);
  }

  componentWillUnmount = () => {
    window.removeEventListener('online', this.submitCachedAnswer);
  }

  onOpenSection = (sectionId) => {
    const { isSelectedSection } = this.state;
    if (sectionId !== null) {
      if (isSelectedSection === sectionId) {
        this.setState({ isSelectedSection: null });
      } else {
        this.setState({ isSelectedSection: sectionId });
      }
    }
  };

  onOpenModal = (sectionId, isReset = false) => {
    const { isModalOpen } = this.state;
    if (sectionId !== null) {
      if (isModalOpen === sectionId) {
        this.setState({ isModalOpen: null, isReset });
      } else {
        this.setState({ isModalOpen: sectionId, isReset });
      }
    }
  };

  onCloseModal = () => {
    this.setState({
      isModalOpen: null,
    });
  };

  redirectToReviewPage = (examSectionId) => {
    const {
      history, sectionId, examSessionId, courseId, userId,
    } = this.props;

    history.push(
      `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/review/${examSectionId}/student/${userId}`,
    );
  };

  redirectToStudentReviewPage = (examSectionId) => {
    const {
      history, sectionId, examSessionId, courseId,
    } = this.props;

    history.push(
      `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/review/${examSectionId}`,
    );
  };

  unlockSection = (examSectionId) => {
    const { examDetail, sectionExamUnlock } = this.props;
    const { id: examSessionId } = examDetail;

    sectionExamUnlock(examSessionId, examSectionId);
    this.onCloseModal();
  };

  pauseSection = (examSecionId) => {
    const { examDetail, sectionExamPause } = this.props;
    const { id: examSessionId } = examDetail;
    sectionExamPause(examSessionId, examSecionId);
    this.onCloseModal();
  };

  resetSection = (examSecionId) => {
    const { examDetail, sectionExamReset } = this.props;
    const { id: examSessionId } = examDetail;
    sectionExamReset(examSessionId, examSecionId);
    this.onCloseModal();
  };

  resetExamSection = (examSecionId) => {
    const { examDetail, sectionExamResetExamSection } = this.props;
    const { id: examSessionId } = examDetail;
    // We use exam reset with get updated score here 
    sectionExamResetExamSection(examSessionId, examSecionId);
    this.onCloseModal();
  }

  beginTheTest = (examSectionId) => {
    const {
      examSessionId,
      examDetail,
      examInitializeResponse,
      userId,
      courseId,
      sectionId,
    } = this.props;
    const examId = _get(examDetail, 'exam.id', '');
    const callback = `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/take/${examSectionId}`;

    examInitializeResponse(
      examSessionId,
      examId,
      examSectionId,
      userId,
      callback,
    );
  };

  openPdfFile = (examSectionId) => {
    const {
      courseId, sectionId, examSessionId, history,
    } = this.props;
    const url = `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/pdf-viewer/${examSectionId}/`;

    history.push(url);
  };

  renderActionUnlock = (sectionId) => {
    const { isModalOpen } = this.state;
    const isOpen = isModalOpen === sectionId;
    return (
      <React.Fragment>
        <Tooltip title="Unlock section for students" placement="top">
          <SectionRowIcon
            className="chalktalk-lock-open"
            onClick={() => this.onOpenModal(sectionId)}
          />
        </Tooltip>
        <LessonExamConfirmModal
          isOpen={isOpen}
          onClose={this.onCloseModal}
          onConfirm={() => this.unlockSection(sectionId)}
          content="Are you sure you want to unlock the section? Students will be able to start the section once it is unlocked."
        />
      </React.Fragment>
    );
  };

  renderActionPauseReset = (sectionId) => {
    const { isModalOpen, isReset } = this.state;
    const isOpen = isModalOpen === sectionId;
    return (
      <React.Fragment>
        <Tooltip title="Pause exam" placement="top">
          <SectionRowIcon
            className="chalktalk-pause"
            onClick={() => this.onOpenModal(sectionId)}
          />
        </Tooltip>

        <Tooltip title="Reset exam timer" placement="top">
          <SectionRowIcon
            data-tip="Reset exam timer"
            onClick={() => this.resetSection(sectionId)}
          >
            <img alt="" src="/images/reset-exam-icon.jpeg" />
          </SectionRowIcon>
        </Tooltip>

        <Tooltip title="Reset the exam for all students that have submitted it. Those still working will not be affected" placement="top">
          <SectionRowIcon
            className="chalktalk-reset"
            onClick={() => this.onOpenModal(sectionId, true)}
          />
        </Tooltip>
        <LessonExamConfirmModal
          isOpen={isOpen && !isReset}
          onConfirm={() => this.pauseSection(sectionId)}
          onClose={this.onCloseModal}
          content="Are you sure you want to pause the section timer? Students will be locked out of their section session once it is paused. Timer will pause until it is unlocked again. All responses will be saved."
        />
        <LessonExamConfirmModal
          isOpen={isOpen && isReset}
          onConfirm={() => this.resetExamSection(sectionId)}
          onClose={this.onCloseModal}
          content="Are you sure you want to reset this exam? The exam will be reset and scores will be deleted. All responses will be saved"
        />
      </React.Fragment>
    );
  };

  renderTableHeader = () => (
    <TableHeadWrapper isExamSection>
      <TableHeader {...ColumnSize[0]}>Section&#39;s Name</TableHeader>
      <TableHeader {...ColumnSize[1]}>Date</TableHeader>
      <TableHeader {...ColumnSize[1]}>Duration</TableHeader>
      <TableHeader {...ColumnSize[2]}>Students</TableHeader>
      <TableHeader {...ColumnSize[3]}>Actions</TableHeader>
    </TableHeadWrapper>
  );

  renderTableHeaderStudent = () => (
    <TableHeadWrapper isExamSection>
      <TableHeader {...ColumnSize[0]}>Section&#39;s Name</TableHeader>
      <TableHeader {...ColumnSize[1]}>Date</TableHeader>
      <TableHeader {...ColumnSize[1]}>Duration</TableHeader>
      <TableHeader {...ColumnSize[2]}>Score</TableHeader>
      <TableHeader {...ColumnSize[3]}>Actions</TableHeader>
    </TableHeadWrapper>
  );

  renderButtonGroup = (section, sectionStatus) => {
    const { id } = section;

    switch (sectionStatus) {
      case SECTION_STATUS.PAUSED:
      case SECTION_STATUS.LOCKED:
        return this.renderActionUnlock(id);

      case SECTION_STATUS.UNLOCKED:
        return this.renderActionPauseReset(id);
      default:
        return null;
    }
  };

  renderExamReviewButton = (section) => {
    const { id } = section;
    const { examDetail } = this.props;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const reviewStatus = _get(sectionMeta, 'can_review', false);
    const enableExamReview = () => {
      const { examDetail, sectionExamReviewUnlock } = this.props;
      const { id: sessionId } = examDetail;
      sectionExamReviewUnlock(sessionId, id);
    };
    const disableExamReview = () => {
      const { examDetail, sectionExamReviewLock } = this.props;
      const { id: sessionId } = examDetail;
      sectionExamReviewLock(sessionId, id);
    };
    if (reviewStatus) {
      return (
        <SectionRowIconImg
          src="/images/reviewAsset.png"
          opacity={1}
          title="Click to hide the answer key from students."
          data-tip="Click to hide the answer key from students."
          data-for="tooltip-students-action"
          onClick={disableExamReview}
        />
      );
    }
    return (
      <SectionRowIconImg
        src="/images/reviewAsset.png"
        opacity={0.5}
        title="Click to show students the answer key."
        data-tip="Click to show students the answer key."
        data-for="tooltip-students-action"
        onClick={enableExamReview}
      />
    );
  };

  renderLockButton = () => (
    <Tooltip title="Locked Exam" placement="top">
      <SectionRowIcon
        className="chalktalk-lock"
      />
    </Tooltip>
  );

  renderBeginButton = (examSectionId, label) => (
    <Button blue onClick={() => this.beginTheTest(examSectionId)}>
      {label}
    </Button>
  );

  renderButtonGroupStudent = (section) => {
    const { examDetail, resultOfStudent } = this.props;
    const { id } = section;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const sectionStatus = _get(
      sectionMeta,
      'section_status',
      SECTION_STATUS.LOCKED,
    );

    const status = _get(resultOfStudent, 'status');
    const sectionMetaOfResult = (resultOfStudent.section_meta || []).find(
      (item) => item.section === id,
    );
    const endedTime = _get(sectionMetaOfResult, 'ended', null);
    const startedTime = _get(sectionMetaOfResult, 'started', null);

    if (status === RESULT_STATUS.PENDING && endedTime) {
      return <Button disabled>Calculating...</Button>;
    }

    switch (sectionStatus) {
      case SECTION_STATUS.PAUSED:
      case SECTION_STATUS.LOCKED:
        return this.renderLockButton();
      case SECTION_STATUS.UNLOCKED: {
        const label = startedTime ? 'Continue Test' : 'Begin test';
        return this.renderBeginButton(id, label);
      }

      default:
        return null;
    }
  };

  renderSectionItem = (section) => {
    const { duration, name, id } = section;
    const { isSelectedSection } = this.state;
    const { studentLength, resultOfSection, examDetail } = this.props;
    const durationStr = CommonUtils.convertMinutesToTestDuration(
      duration,
      true,
    );
    const isSectionSelected = isSelectedSection === id;
    const completedStudent = _get(resultOfSection, id, 0);
    const { length_type: examType } = examDetail;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const sectionStatus = _get(
      sectionMeta,
      'section_status',
      SECTION_STATUS.LOCKED,
    );
    const date = _get(sectionMeta, 'section_date', examDetail.start_date);

    return (
      <SectionRowWrap key={section.id}>
        <SectionRowItem>
          <SectionRowContent>{name}</SectionRowContent>
          <SectionRowContent>{date}</SectionRowContent>
          <SectionRowContent>{durationStr}</SectionRowContent>
          <SectionRowContent>
            {completedStudent}
            /
            {studentLength}
          </SectionRowContent>
          <SectionRowContent actionType rightAlign>
            {/* temporarily removed */}
            {/* {this.renderExamReviewButton(section)} */}
            {this.renderButtonGroup(section, sectionStatus)}
            <Tooltip title="Expand to view the list of students and results" placement="top">
              <SectionRowIcon
                className="chalktalk-list"
                onClick={() => this.onOpenSection(section.id)}
              />
            </Tooltip>
            {examType === EXAM_TYPE.OFFLINE && (
              <Tooltip title="View the exam PDF" placement="top">
                <SectionRowIcon
                  className="chalktalk-pdf-file"
                  onClick={() => this.openPdfFile(id)}
                />
              </Tooltip>
            )}
          </SectionRowContent>
        </SectionRowItem>
        <ExamSubStudentTable
          isExpanded={isSectionSelected}
          examSectionId={id}
          sectionStatus={sectionStatus}
        />
      </SectionRowWrap>
    );
  };

  renderSectionItemStudent = (section) => {
    const { studentSectionReport, examDetail } = this.props;
    const { duration, name, id } = section;
    const durationStr = CommonUtils.convertMinutesToTestDuration(
      duration,
      true,
    );
    const sectionReport = (studentSectionReport || []).find(
      (item) => item.section_id === id,
    );
    const autoScore = _get(sectionReport, 'score.auto', null);
    const autoScoreMax = _get(sectionReport, 'score.auto_max', null);
    const shouldDisplayReviewButton = autoScore !== null && autoScoreMax !== null;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const date = _get(sectionMeta, 'section_date', examDetail.start_date);
    const canReview = _get(sectionMeta, 'can_review', false);
    return (
      <TableContentItem custome key={id} isExamSection>
        <TableContent {...ColumnSize[0]}>{name}</TableContent>
        <TableContent {...ColumnSize[1]}>{date}</TableContent>
        <TableContent {...ColumnSize[1]}>{durationStr}</TableContent>
        <TableContent {...ColumnSize[2]}>
          {`${autoScore !== null ? autoScore : '--'}/${
            autoScoreMax !== null ? autoScoreMax : '--'
          }`}
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          {shouldDisplayReviewButton ? (
            canReview ? (
              <Button onClick={() => this.redirectToStudentReviewPage(id)}>
                Review
              </Button>
            ) : (
              <div
                data-tip="Your teacher hasn’t unlocked the answer key yet."
                title="Your teacher hasn’t unlocked the answer key yet."
                data-for="tooltip-student-review"
              >
                {/* temporarily removed */}
                {/* <Button disabled>Review</Button> */}
              </div>
            )
          ) : (
            this.renderButtonGroupStudent(section)
          )}
        </TableContent>
      </TableContentItem>
    );
  };

  submitCachedAnswer = () => {
    const answerList = JSON.parse(localStorage.getItem('answerList') || '[]');
    const examCompletePayload = JSON.parse(localStorage.getItem('examCompletePayload') || '{}');
    const sessionExamId = localStorage.getItem('sessionExamId');
    const examIsCompleted = localStorage.getItem('examIsCompleted' || false);
    const online = window.navigator.onLine;
    if (!online || sessionExamId == null) return;


    if (answerList.length !== 0 && sessionExamId) {
      localStorage.removeItem('answerList');
      ExamAPI.submitQuestionResponse(sessionExamId, answerList);
    }
    if (examIsCompleted && examCompletePayload && sessionExamId) {
      localStorage.removeItem('examIsCompleted');
      ExamAPI.completeSectionResponse(sessionExamId, examCompletePayload);
    }
    localStorage.removeItem('sessionExamId');
  }

  renderexamSectionContent() {
    const { examDetail, userRole, isGettingSectionExam } = this.props;
    const sections = _get(examDetail, 'exam.sections', []);
    return (
      <>
        <BlockTitle>Test Sections</BlockTitle>
        {isGettingSectionExam ? (
          <TableLoader />
        ) : (
          <React.Fragment>
            {userRole === ROLE_TYPE.INSTRUCTOR && (
              <Wrapper>
                {this.renderTableHeader()}
                <SectionListWrap>
                  {sections.map(this.renderSectionItem)}
                </SectionListWrap>
              </Wrapper>
            )}
            {userRole === ROLE_TYPE.STUDENT && (
              <Wrapper>
                {this.renderTableHeaderStudent()}
                <SectionListWrap>
                  {sections.map(this.renderSectionItemStudent)}
                </SectionListWrap>
              </Wrapper>
            )}
          </React.Fragment>
        )}
      </>
    );
  }

  render() {
    return (
      <BlockWrap>
        {this.renderexamSectionContent()}
      </BlockWrap>
    );
  }
}

ExamSectionTable.propTypes = {
  examDetail: PropTypes.object,
  studentLength: PropTypes.any,
  resultOfSection: PropTypes.object,
  userRole: PropTypes.string,
  examSessionId: PropTypes.any,
  examInitializeResponse: PropTypes.func,
  userId: PropTypes.any,
  courseId: PropTypes.any,
  sectionId: PropTypes.any,
  sectionExamUnlock: PropTypes.func.isRequired,
  sectionExamPause: PropTypes.func.isRequired,
  sectionExamReset: PropTypes.func.isRequired,
  sectionExamResetExamSection: PropTypes.func.isRequired,
  studentSectionReport: PropTypes.array,
  history: PropTypes.any,
  resultOfStudent: PropTypes.object,
  isGettingSectionExam: PropTypes.bool,
};

export default ExamSectionTable;
