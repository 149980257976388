import axios from 'axios';
import sharedPromise from '@utils/shared-promise';
import { handleError } from './utils';

// serialzers
const createCourseSerializer = (courseValues, organization) => ({
  curriculum: courseValues.courseId.id,
  name: courseValues.courseName,
  organization,
  syllabus: courseValues.standardId.id,
  subscription_code: courseValues.subscriptionCode,
  exam_type: courseValues.examType && courseValues.examType.exam_type,
});

const updateCourseSerializer = (courseValues) => ({
  // curriculum: courseValues.courseId.id,
  name: courseValues.courseName,
  // organization,
  // standard: courseValues.standardId.id,
});

// apis

export const getUserCourseList = (userId) => {
  const url = `/api/users/${userId}/courses/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const getUserByCourseList = (courseId, userId) => {
  const url = `/api/users/${userId}/courses/${courseId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const createSubscriptionInfo = (subscriptionValues) => {
  const { free, subscriptionCode } = subscriptionValues;

  const url = '/api/courses/subscription/';
  const payload = free
    ? {
        limited: free,
      }
    : {
        subscription_code: subscriptionCode,
        limited: false,
      };

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const requestInviteCourse = (subscriptionCode) => {
  const url = '/api/courses/request-invite/';
  return axios
    .post(url, { subscription_code: subscriptionCode })

    .then((response) => {
      window.location.href = `/management/course/${response.data}`;
    })
    .catch(handleError);
};

export const createCourse = (courseInfo, organization) => {
  const url = '/api/courses/create/';
  return axios
    .post(url, createCourseSerializer(courseInfo, organization))
    .then((response) => ({ response }))
    .catch(handleError);
};

export const getCourseDetail = (courseId) => {
  const url = `/api/courses/${courseId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const updateCourseDetail = (courseId, courseInfo, organization) => {
  const url = `/api/courses/${courseId}/`;
  return axios
    .put(url, updateCourseSerializer(courseInfo, organization))
    .then((response) => ({ response }))
    .catch(handleError);
};

// course section

export const getSectionListByCourseId = (courseId) => {
  const url = `/api/courses/${courseId}/sections/`;
  return sharedPromise(
    () =>
      axios
        .get(url)
        .then((response) => ({ response }))
        .catch(handleError),
    url,
  );
};

export const getAllSectionsListByCourseId = (courseId) => {
  const url = `/api/courses/${courseId}/sections?all=true`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export function createSection(sectionValues, courseId) {
  const url = `/api/courses/${courseId}/sections/`;
  return axios
    .post(url, sectionValues)
    .then((response) => ({ response }))
    .catch(handleError);
}

// course teacher

export function inviteTeacherToCourse(courseId, teacherInfo) {
  const url = `/api/courses/${courseId}/invite-teacher/`;
  return axios
    .post(url, teacherInfo)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function removeTeacherFromCourse(teacherId, courseId) {
  const url = `/api/courses/${courseId}/teachers/${teacherId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getCourseTeachers(courseId) {
  const url = `/api/courses/${courseId}/teachers/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// course student

export function removeStudentFromCourse(courseId, studentId) {
  const url = `/api/courses/${courseId}/students/${studentId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getCourseStudents(courseId) {
  const url = `/api/courses/${courseId}/students/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// course owner

export function removeOwnerFromCourse(courseId, ownerId) {
  const url = `/api/courses/${courseId}/owners/${ownerId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function addOwnerToCourse(courseId, ownerId) {
  const url = `/api/courses/${courseId}/owners/${ownerId}/`;
  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getCourseOwners(courseId) {
  const url = `/api/courses/${courseId}/owners/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function deactivateCourse(courseId) {
  const url = `/api/courses/${courseId}/deactivate/`;

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function teacherResetStudentPassword(courseId, studentId, newPassword) {
  const url = `/api/courses/${courseId}/reset-student-password/`;
  return axios
    .post(url, {
      student_id: studentId,
      password: newPassword,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}
