/* eslint-disable react/no-array-index-key */
import React from 'react';
import { BreadcrumbsSubGroup, BreadcrumbsSubItems } from './BreadcrumbsSub.style';

export interface BreadcrumbsSubProps {
  breadcumbSubList: Array<string>;
}

export default function BreadcrumbsSub(
  props: BreadcrumbsSubProps
): JSX.Element {
  const { breadcumbSubList: breadcrumbSubList = [] } = props;

  return (
    <BreadcrumbsSubGroup>
      {breadcrumbSubList.map((breadcrumb, index) => (
        <BreadcrumbsSubItems
          current={index + 1 === breadcrumbSubList.length}
          key={index}
        >
          <span>{breadcrumb}</span>
          {!(index + 1 === breadcrumbSubList.length) && (
            <span className="break-line">/</span>
          )}
        </BreadcrumbsSubItems>
      ))}
    </BreadcrumbsSubGroup>
  );
}
