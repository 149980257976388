export const bannerTypes = {
    default: {
        backgroundColor: '#f8f9fa',
        textColor: '#000',
        showIcon: false,
    },
    alert: {
        backgroundColor: '#AC2318',
        textColor: '#fff',
        showIcon: true,
        icon: 'ErrorRoundedIcon',
    }
};