import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import {
  selectors as LeftSideBarSelectors,
  actions as LeftSideBarActions,
} from '../../reducers/leftSideBar';
import LeftSideBarCourseManagement from '../../components/LeftSideBar/LeftSideBarCourseManagement';
import { selectors as CourseSelectors } from '../../reducers/courses';
import {
  actions as CourseTeacherActions,
  selectors as CourseTeacherSelectors,
} from '../../reducers/courseTeachers';
import { selectors as userSelectors } from '../../reducers/user';
import { actions as sectionStudentActions } from '../../reducers/sectionStudent';
import {
  selectors as SectionSelectors,
  actions as SectionActions,
} from '../../reducers/sections';

const mapStateToProps = (state, { match, location }) => {
  const courseIdSelected = _get(match, 'params.courseId', '');
  const shouldDisplaySectionSelect = !!courseIdSelected;
  const courseItem = CourseSelectors.getCourseDetailById(state, courseIdSelected);
  const ownerIds = CourseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find(id => id === userId);
  const searchParams = new URLSearchParams(location.search);
  const isOpenCreateSection = searchParams.get('isOpenCreateSection');

  return ({
    isOpenCreateSection,
    isOwner,
    courseItem,
    courseId: courseIdSelected,
    shouldDisplaySectionSelect,
    sectionList: SectionSelectors.getSectionList(state),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    ownerIds: CourseTeacherSelectors.getOwnerIds(state),
  });
};

const mapDispatchToProps = {
  getAllSectionsByCourse: SectionActions.getAllSectionsByCourse,
  toggleLeftSidebar: LeftSideBarActions.toggleLeftSidebar,
  courseTeacherGetOwner: CourseTeacherActions.courseTeacherGetOwner,
  sectionsStudentSelectAllOrCancelGradeLevel: sectionStudentActions.sectionsStudentSelectAllOrCancelGradeLevel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideBarCourseManagement),
);
