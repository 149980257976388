import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { LoaderWrap } from './LoadingPlaceholder.style';

export default class ListLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    isLargeItem: PropTypes.bool,
  };

  render() {
    const { isLargeItem } = this.props;
    return (
      <LoaderWrap>
        {isLargeItem ? (
          <ContentLoader
            className="list-loader"
            height={1000}
            width={400}
            speed={2}
            primaryColor="#ecebee"
            secondaryColor="#dedbe0"
          >
            <rect x="20" y="0" rx="3" ry="3" width="360" height="110" />
            <rect x="20" y="120" rx="3" ry="3" width="360" height="110" />
            <rect x="20" y="240" rx="3" ry="3" width="360" height="110" />
            <rect x="20" y="360" rx="0" ry="0" width="360" height="110" />
            <rect x="20" y="480" rx="0" ry="0" width="360" height="110" />
            <rect x="20" y="600" rx="0" ry="0" width="360" height="110" />
            <rect x="20" y="720" rx="0" ry="0" width="360" height="110" />
            <rect x="20" y="840" rx="0" ry="0" width="360" height="110" />
            <rect x="20" y="960" rx="0" ry="0" width="360" height="110" />
          </ContentLoader>
        ) : (
          <ContentLoader
            className="list-loader"
            height={1000}
            width={400}
            speed={2}
            primaryColor="#ecebee"
            secondaryColor="#dedbe0"
          >
            <rect x="20" y="0" rx="3" ry="3" width="360" height="35" />
            <rect x="20" y="45" rx="3" ry="3" width="360" height="35" />
            <rect x="20" y="90" rx="3" ry="3" width="360" height="35" />
            <rect x="20" y="135" rx="0" ry="0" width="360" height="35" />
            <rect x="20" y="180" rx="0" ry="0" width="360" height="35" />
            <rect x="20" y="225" rx="0" ry="0" width="360" height="35" />
          </ContentLoader>
        )}
      </LoaderWrap>
    );
  }
}
