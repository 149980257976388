/* eslint-disable import/prefer-default-export */
import React from 'react';

const calculateTimeLeft = (startTime: Date, duration: number) => {
  const difference = duration * 1000 - (new Date().getTime() - startTime.getTime());
  let timeLeft = { minutes: '00', seconds: '00' };

  if (difference > 0) {
    // output in two digit format __:__ format
    const minutes = `${Math.floor((difference / 1000 / 60) % 60)}`;
    const seconds = `${Math.floor((difference / 1000) % 60)}`;
    timeLeft = {
      minutes: minutes.length === 1 ? `0${minutes}` : minutes,
      seconds: seconds.length === 1 ? `0${seconds}` : seconds,
    };
  }

  return `${timeLeft.minutes}:${timeLeft.seconds}`;
};
type useTimerProps = {
  startTime: Date;
  duration: number;
};
export const useTimer = ({ startTime, duration }: useTimerProps) => {
  const [time, setTime] = React.useState(calculateTimeLeft(startTime, duration));

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTime(calculateTimeLeft(startTime, duration));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  return time;
};
// --------------------
type useOnTimeOutProps = {
  onTimeOut: () => void;
  time: string;
};
export const useOnTimeOut = ({ onTimeOut, time }: useOnTimeOutProps, deps: any[]) => {
  const isFiredTimeOutRef = React.useRef(new Date().getTime());
  React.useEffect(() => {
    if (time === '00:00') {
      isFiredTimeOutRef.current = new Date().getTime();
      onTimeOut();
    }
  }, [...deps]);
};
