import styled, { css } from 'styled-components';

export const PracticeCardItem = styled.div`
  flex: 0 0 calc(100%/3 - 3em);
  margin-right: 3em;
  margin-bottom: 1.5em;
  padding: 7px;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: .5em;
  box-shadow: rgba(9,30,66,0.25) 0px 0px 3px, rgba(9,30,66,0.31) 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  i {
    font-size: ${props => props.theme.fontSize.HeadingH2FontSize};
    margin-bottom: .75em;
    margin-right: auto;
  }
  &:nth-child(3n) {
    margin-right: 0em;
  }
  .chalktalk-in-progress {
    border-radius: 100%;
    background-color: #ddd;
  }
`;

export const PracticeCardTitle = styled.div`
  color: #020b0d;
  font-family: 'MontserratMedium';
`;

export const PracticeCardBlock = styled.div`
  margin: 1em 0em;
`;

export const LevelSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5em;
`;

export const Dot = styled.span`
  background: #808080;
  width: .75em;
  height: .75em;
  border-radius: .15em;
  margin-right: .25em;
  ${({ isActive }) => isActive && css`
    background: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  `}
`;

export const ContentLine = styled.div`
  font-size: ${props => props.theme.fontSize.SmallFontSize};
`;

export const PracticeCardAction = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  button {
    width: 100%;
    margin-bottom: .55em;
    font-size: ${props => props.theme.fontSize.SmallFontSize};
    font-family: 'MontserratSemiBold';
    &:last-child {
      margin-bottom: 0em;
    }
  }
`;
