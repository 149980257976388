import { connect } from 'react-redux';
import UnverifiedRoute from '../../components/routes/UnverifiedRoute';
import {
  selectors as AuthSelectors,
} from '../../reducers/auth';
import {
  selectors as UserSelectors,
} from '../../reducers/user';

const mapStateToProps = state => ({
  isEmailConfirmed: AuthSelectors.getConfirmationStatus(state),
  authenticated: AuthSelectors.getAuthenticatedStatus(state),
  userRole: UserSelectors.getUserRole(state),
});

export default connect(mapStateToProps)(UnverifiedRoute);
