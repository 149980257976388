import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import ListLoader from '@components/LoadingPlaceholder/ListLoader';
import {
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  LeftSiderBarWrap,
} from '@components/LeftSideBar/LeftSiderBar.style';
import { ROLE_TYPE } from '@utils/enums';
import { AuthenticatedRoute, TeacherAuthRoute } from '@containers/routes';
import QuestionSelector from '@components/Pages/QuestionSelector';
import Skeleton from '@Atoms/Skeleton/Skeleton';
import PracticeSheet from '@containers/PracticeSheet';
import PracticeReviewManagement from '@containers/PracticeReviewManagement';
import PracticeTeacherReviewManagement from '@containers/PracticeTeacherReviewManagement';

import UnitPracticeReview from '@containers/UnitPracticeReview';
import UnitPracticeOverviewReview from '@containers/UnitPracticeOverviewReview';
import UnitPracticeTeacherReview from '@containers/UnitPracticeTeacherReview';

import LessonPracticeReview from '@containers/LessonPracticeReview';
import LessonPracticeOverviewReview from '@containers/LessonPracticeOverviewReview';
import LessonPracticeTeacherReview from '@containers/LessonPracticeTeacherReview';
import LeftSideBarLessons from '@containers/LeftSideBar/LeftSideBarLessons';
import MainContent from '@containers/MainContent/MainContent';
import LessonManagement from '@containers/LessonManagement/LessonManagement';
import UnitPractice from '@containers/UnitPractice/UnitPractice';
import LessonReviewPage from '@containers/LessonReviewPage/LessonReviewPage';
import StandardsPracticeReview from '@components/StandardsPractice/StandardsPracticeReview';
import StandardsPracticePreview from '@components/StandardsPractice/StandardsPracticePreview';
import StandardsPracticeAssessment from '@components/StandardsPractice/StandardsPracticeAssessment';
import GroupActivitySheet from '@containers/GroupActivitySheet';
import PracticeV2 from '@components/PracticeV2';
import {
  LearnosityAdaptivePracticeAssessment,
  LearnosityAdaptivePracticePreview,
} from '@components/LearnosityAdaptivePractice';
import QuestionSelectorPreview from '@components/QuestionSelector/QuestionSelectorPreview';
import LearnosityLessonReview from '@components/LearnosityLessonReview';
import LearnosityLessonReviewPage from '@components/Pages/LessonReview';
import LearnosityLessonReviewPractice from '@components/LearnosityLessonReview/Practice';
import { PrimaryWrap } from '../Generals/stylesheets/General.style';
import { InteractionContextProvider } from '@components/ElementAnalytics/InteractionContext';

export const LeftSideBarLessonsSkeleton = () => (
  <LeftSiderBarWrap>
    <LeftSiderBarHeader>
      <LeftSiderBarHeaderGroup>
        <Skeleton sx={{ height: '5.625rem' }} />
      </LeftSiderBarHeaderGroup>
    </LeftSiderBarHeader>
    <div
      style={{
        position: 'relative',
      }}
    >
      <ListLoader />
    </div>
  </LeftSiderBarWrap>
);

const LeftSideBarLessonsForManagement = () => (
  <LeftSideBarLessons pathName="/lessons/course" />
);
const WrapWithMainContent = (Component) => {
  const WrappedComponent = (props) => (
    <MainContent>
      <Component {...props} />
    </MainContent>
  );

  WrappedComponent.displayName = `WrapWithMainContent(${
    Component.displayName || Component.name || 'Component'
  })`;

  return WrappedComponent;
};

const LessonManagementRoute = () => (
  <PrimaryWrap>
    <Suspense fallback={<LeftSideBarLessonsSkeleton />}>
      <Switch>
        <Route
          exact
          path="/lessons/course/:courseId?"
          component={LeftSideBarLessonsForManagement}
        />
        <Route
          exact
          path="/lessons/course/:courseId/section/:sectionId?"
          component={LeftSideBarLessonsForManagement}
        />
        <Route
          exact
          path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId"
          component={LeftSideBarLessonsForManagement}
        />
        <Route
          exact
          path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId"
          component={LeftSideBarLessonsForManagement}
        />
        <Route
          exact
          path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice"
          component={LeftSideBarLessonsForManagement}
        />
        <Route
          exact
          path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/review/:reviewId/"
          component={LeftSideBarLessonsForManagement}
        />
        <Route
          exact
          path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/assessment"
          component={LeftSideBarLessonsForManagement}
        />
      </Switch>
    </Suspense>

    <InteractionContextProvider interactionArea="Lessons">
      <Switch>
        <Route
          exact
          path="/lessons/course/:courseId?"
          component={LessonManagement}
        />
        <Route
        exact
        path="/lessons/course/:courseId/section/:sectionId?"
        component={WrapWithMainContent(LessonManagement)}
      />
      <Route
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId"
        component={WrapWithMainContent(LessonManagement)}
      />
      <Route
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId"
        component={WrapWithMainContent(LessonManagement)}
      />
      <Route
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice"
        component={WrapWithMainContent(UnitPractice)}
      />
      <Route
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/review/:reviewId/"
        component={WrapWithMainContent(LessonReviewPage)}
      />
      <Route
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/assessment"
        component={WrapWithMainContent(LearnosityLessonReview)}
      />
      <AuthenticatedRoute
        exact
        disallowed={[ROLE_TYPE.STUDENT]}
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/assessment/setup"
        component={LearnosityLessonReviewPage}
      />
      <AuthenticatedRoute
        exact
        disallowed={[ROLE_TYPE.STUDENT]}
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/question-selector"
        component={QuestionSelector}
      />
      <AuthenticatedRoute
        exact
        disallowed={[ROLE_TYPE.STUDENT]}
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/question-selector/preview"
        component={QuestionSelectorPreview}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/practice/:practiceSessionId"
        component={PracticeSheet}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/online-activity/question/:currentGroup/:groupId/:status"
        component={GroupActivitySheet}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/online-activity/question/:currentGroup/:groupId/1/:onlineResponseId/:userId"
        component={PracticeV2}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/online-activity/question/:currentGroup/:groupId/2/:onlineResponseId/:userId"
        component={PracticeV2}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/online-activity/question/:currentGroup/:groupId/:status/:onlineResponseId/:userId"
        component={GroupActivitySheet}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/online-activity/question/:currentGroup/:groupId/:status/group"
        component={GroupActivitySheet}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/practice-review"
        component={LessonPracticeOverviewReview}
      />
      <TeacherAuthRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/practice-review/student/:studentId"
        component={LessonPracticeTeacherReview}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/practice-review/:practiceSessionId/"
        component={LessonPracticeReview}
      />
      <TeacherAuthRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/practice-result"
        component={PracticeTeacherReviewManagement}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/practice-result/:practiceSessionId"
        component={PracticeReviewManagement}
      />

      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice/:practiceSessionId"
        component={PracticeSheet}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice-review"
        component={UnitPracticeOverviewReview}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/standards-practice/:practiceSessionId/review"
        component={StandardsPracticeReview}
      />
      <TeacherAuthRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/standards-practice/preview"
        component={StandardsPracticePreview}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/standards-practice"
        component={StandardsPracticeAssessment}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/adaptive-practice"
        component={LearnosityAdaptivePracticeAssessment}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/assessment-practice"
        component={LearnosityLessonReviewPractice}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId/adaptive-practice/preview"
        component={LearnosityAdaptivePracticePreview}
      />
      <TeacherAuthRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice-review/student/:studentId"
        component={UnitPracticeTeacherReview}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice-review/:practiceSessionId/"
        component={UnitPracticeReview}
      />
      <AuthenticatedRoute
        exact
        path="/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/practice-result/:practiceSessionId"
          component={PracticeReviewManagement}
        />
      </Switch>
    </InteractionContextProvider>
  </PrimaryWrap>
);

export default LessonManagementRoute;
