import React, { useState } from 'react';
import Button from '@components/Atoms/Button';
import Typography from '@components/Atoms/Typography';
import ShowIf from '@components/Atoms/ShowIf';
import { CircularProgress, ButtonGroup, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmModal from '@components/ConfirmModal';
import Tooltip from '@components/Atoms/Tooltip';
import ShadowScrollbars from '@components/Scrollbar';
import { QuestionButtonsWrapper, MenuColumn } from './styles';
import { GenerationStatusItem, LearnosityItem } from './types';
import { GENERATION_STATUS } from './constants';

const QuestionBox: React.FC<{
  item: LearnosityItem;
  index: number;
  isSelected: boolean;
  selectClick: (reference: string) => void;
  deleteClick: (reference: string) => void;
  isSubmitting: boolean;
}> = ({ item, index, isSelected, selectClick, deleteClick, isSubmitting }) => {
  const variant = isSelected ? 'contained' : 'outlined';
  const hasWindow = typeof window !== 'undefined';
  const width = hasWindow ? window.innerWidth : 0;
  const widthBreakpoint = 1024;
  const customLabel = width < widthBreakpoint ? '' : '(Custom)';
  const generatedLabel = width < widthBreakpoint ? '' : '(Generated)';
  const label = `Q${index + 1} ${item?.is_custom ? customLabel : generatedLabel}`;

  return (
    <ButtonGroup
      disabled={isSubmitting}
      variant={variant}
      sx={{ width: '100%', display: 'flex', my: '1rem' }}
    >
      <Button onClick={() => selectClick(item.reference)} sx={{ flex: 1 }}>
        {label}
      </Button>
      <Button size="small" onClick={() => deleteClick(item.reference)} sx={{ flex: 0 }}>
        <CloseIcon />
      </Button>
    </ButtonGroup>
  );
};

const GenerationStatusBox: React.FC<{ generationStatus: GenerationStatusItem }> = ({
  generationStatus,
}) => (
  <Tooltip title={generationStatus.message as string} arrow placement="top">
    <span style={{ flex: 1 }}>
      <Button
        fullWidth
        variant="outlined"
        color={
          generationStatus.status === GENERATION_STATUS.FAILED.status
            ? 'error'
            : 'secondary'
        }
        sx={{
          my: '1rem',
          display: 'flex',
          cursor: 'default',
          alignItems: 'center',
        }}
      >
        <Box sx={{ pl: '1rem', flex: 1, textAlign: 'left' }}>
          {GENERATION_STATUS[generationStatus.status]?.label || 'Failed'}
        </Box>
        <ShowIf If={generationStatus.status !== GENERATION_STATUS.FAILED.status}>
          <Box sx={{ flex: 0, mr: '0.5rem', mt: '0.1rem' }}>
            <CircularProgress color="inherit" size={14} />
          </Box>
        </ShowIf>
      </Button>
    </span>
  </Tooltip>
);

const QuestionGenerationQuestions: React.FC<{
  generationStatuses: GenerationStatusItem[];
  items: LearnosityItem[];
  selectedItem: LearnosityItem | null;
  selectItem: (reference: string) => void;
  deleteItem: (reference: string) => void;
  submitItems: () => void;
  isSubmitting: boolean;
}> = ({
  generationStatuses,
  items,
  selectedItem,
  selectItem,
  deleteItem,
  submitItems,
  isSubmitting,
}) => {
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const triggerSubmitItems = () => {
    setShowSubmitModal(false);
    submitItems();
  };

  return (
    <QuestionButtonsWrapper>
      <ConfirmModal
        content="Done editing? All questions will be saved and added to the itembank under the selected options."
        isOpen={showSubmitModal}
        onConfirm={triggerSubmitItems}
        onClose={() => setShowSubmitModal(false)}
      />
      <Typography variant="subtitle1">
        Submitting will save all questions to the item bank.
      </Typography>
      <MenuColumn>
        <ShadowScrollbars
          style={{
            height: 'calc(60vh - 2rem)',
            flex: 3,
            marginBottom: '1rem',
          }}
        >
          <Box sx={{ pr: '1rem' }}>
            {items.map((item, index) => (
              <QuestionBox
                key={item.reference}
                item={item}
                index={index}
                isSelected={selectedItem?.reference === item.reference}
                selectClick={selectItem}
                deleteClick={deleteItem}
                isSubmitting={isSubmitting}
              />
            ))}
            {generationStatuses.map((generationStatus) => (
              <GenerationStatusBox
                key={generationStatus.question_id}
                generationStatus={generationStatus}
              />
            ))}
          </Box>
        </ShadowScrollbars>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setShowSubmitModal(true)}
          disabled={isSubmitting}
          sx={{
            flex: 0,
          }}
        >
          <ShowIf If={!isSubmitting}>Add to my Itembank</ShowIf>
          <ShowIf If={isSubmitting}>
            <CircularProgress color="inherit" size={20} />
          </ShowIf>
        </Button>
      </MenuColumn>
    </QuestionButtonsWrapper>
  );
};

export default QuestionGenerationQuestions;
