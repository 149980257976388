import { connect } from 'react-redux';
import GroupActivityTable from '../../components/GroupActivityTable';
import { actions } from '../../reducers/groupActivity';

const mapStateToProps = (state) => {
  const { groupActivity } = state;
  const {
    groupActivities,
    hasGroupActivityStarted,
    updateInfo,
    groupActivityInfo,
    loadingGroupActivity,
  } = groupActivity;

  return {
    groupActivities,
    hasGroupActivityStarted,
    updateInfo,
    groupActivityInfo,
    loadingGroupActivity,
    groupActivityId: groupActivityInfo?.id,
  };
};

const mapDispatchToProps = {
  updateGroupActivity: actions.updateGroupActivity,
  createGroupActivity: actions.createGroupActivity,
  updateGroupArray: actions.updateGroupArray,
  loadGroupActivity: actions.loadGroupActivity,
  resetPracticeForGroup: actions.resetPracticeForGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupActivityTable);
