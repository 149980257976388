import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { PracticeState } from '@components/PracticeV2/types';
import { usePractice } from '@components/PracticeV2/Provider/PracticeProvider';
import ShowIf from '@components/Atoms/ShowIf';
import Alert from '@components/Atoms/Alert';

const tooltipTitle = {
  [PracticeState.Individual]: 'Answer the question to proceed',
  [PracticeState.Group]: 'Wait for group members to select the same answer to proceed',
};
const PracticeControlButtons = () => {
  const { isAbleToMoveNext, handleNextButton, isLastQuestion, state } = usePractice();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pb: '0.5rem',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-end',
      }}
    >
      <ShowIf If={!isAbleToMoveNext}>
        <Alert
          data-testid="helper-text"
          severity="info"
          variant="outlined"
          sx={{
            width: '100%',
          }}
        >
          {tooltipTitle[state.practiceState]}
        </Alert>
      </ShowIf>
      <Button
        variant="contained"
        color="primary"
        disabled={!isAbleToMoveNext}
        onClick={handleNextButton}
        data-testid="next-button"
      >
        {isLastQuestion ? 'Finish' : 'Next'}
      </Button>
    </Box>
  );
};

export default React.memo(PracticeControlButtons);
