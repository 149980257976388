import React, { useState } from 'react';
import Box from '@components/Atoms/Box';
import PassageCreation from '@learnosityassessment/Components/PassageCreation';
import PreviewPassageItems from '@learnosityassessment/Components/PreviewPassageItems';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary';
import ShowIf from '@components/Atoms/ShowIf';
import { Passage } from './types';
import PassageHeader from './PassageHeader';
import PassageList from './PassageList';

export const MODES = {
  LIST_MODE: 'list',
  PASSAGE_MODE: 'passage',
  PASSAGE_ITEMS_MODE: 'items',
  PASSAGE_SELECT_MODE: 'select',
  PASSAGE_GENERATE_MODE: 'generate',
};

const Passages: React.FC<{
  selectPassageCallback: (passage: Passage) => void;
  onCloseCallback: () => void;
  inDialog?: boolean;
}> = ({ selectPassageCallback, onCloseCallback, inDialog = false }) => {
  const [selectedPassage, setSelectedPassage] = useState<Passage | null>(null);
  const [mode, setMode] = useState(MODES.LIST_MODE);

  const switchMode = (toMode: string, passage: Passage | null) => {
    if (toMode === MODES.PASSAGE_SELECT_MODE && passage) {
      selectPassageCallback(passage as Passage);
      if (inDialog) {
        onCloseCallback();
      }
      return;
    }

    setSelectedPassage(passage);
    setMode(toMode);
  };

  const renderMode = () => {
    switch (mode) {
      case MODES.PASSAGE_MODE:
      case MODES.PASSAGE_GENERATE_MODE:
        return (
          <PassageCreation
            passage={selectedPassage}
            switchMode={switchMode}
            isGenerateMode={mode === MODES.PASSAGE_GENERATE_MODE}
          />
        );

      case MODES.PASSAGE_ITEMS_MODE:
        return <PreviewPassageItems passage={selectedPassage as Passage} />;

      default:
        return <PassageList switchMode={switchMode} inDialog={inDialog} />;
    }
  };

  return (
    <Box
      sx={{
        height: inDialog ? '90%' : '75%',
        width: '100%',
        pl: '1rem',
      }}
    >
      <ChalkTalkErrorBoundary>
        <ShowIf If={!inDialog}>
          <PassageHeader
            mode={mode}
            switchMode={switchMode}
            close={onCloseCallback}
            inDialog={inDialog}
          />
        </ShowIf>
        {renderMode()}
      </ChalkTalkErrorBoundary>
    </Box>
  );
};

export default Passages;
