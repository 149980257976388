import styled, { css } from 'styled-components';

export const PracticeTimeWrap = styled.div`
  padding-right: .75em;
  height: 2.5em;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  .chalktalk-icon-next,
  .chalktalk-icon-pre {
    cursor: pointer;
    color: ${props => props.theme.colorStyled.ColorGrey};
    font-size: ${props => props.theme.fontSize.XLargeFontSize};
    &:hover {
      color: ${props => props.theme.colorStyled.ColorBlack};
    }
  }
  .chalktalk-clock{
    font-size: ${props => props.theme.fontSize.HeadingH1FontSize};
  }
  span {
    width: 6em;
    text-align: center;
    transition: all 300ms ease;
    font-size: ${props => props.theme.fontSize.LargeFontSize};
    color: ${props => props.theme.colorStyled.ColorGrey};
  }
  ${props => props.isClose && css`
    span {
      width: 0em;
      opacity: 0;
    }
  `}
`;
