import { connect } from 'react-redux';
import OfflineTestContent from '../../components/OfflineTestContent';
import { selectors, actions } from '../../reducers/exam';

export const mapStateToProps = (state) => ({
  questions: selectors.getQuestions(state),
});

const mapDispatchToProps = {
  examChooseAnswer: actions.examChooseAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfflineTestContent);
