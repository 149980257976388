import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  MobileWrapper,
  TableSkillLevel,
} from '../../Table/TableComponent.style';
import { NotApplicationText } from '../../DashboardSummary/DashboardSummary.style';
import { TableHeader, TableContent } from '../../Table/TableComponent';
import { NoContent } from '../../Generals/stylesheets/General.style';
import { useSectionByIWId, useCriteriaBySectionId, useReportGet } from '../hooks';

const ColumnSize = [{ size: '220', smallSize: true, textCapitalize: true }];
const defaultColumnSize = { smallSize: true, textCenter: true };

const criteriaColumnSize = {
  size: '85',
  smallSize: true,
  textCenter: true,
};
const noteColumnSize = {
  size: '250',
  smallSize: false,
  textCenter: true,
};
// const tableValues = [
//   {
//     uniqKey: 'Student Name',
//     value: 'Student Name',
//   },
//   {
//     uniqKey: 'skill Level',
//     value: 'Skill Level',
//   },
//   {
//     uniqKey: 'Time On Task',
//     value: 'Time On Task',
//   },
//   {
//     uniqKey: 'Last Practice',
//     value: 'Last Practice',
//   },
// ];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};
const CriteriaTableContent = ({ value }) => (
  <TableContent {...criteriaColumnSize}>{value}</TableContent>
);
const CriteriaTableContentList = ({ section, criteria: criteriaValues }) => {
  const { data: criteriaData } = useCriteriaBySectionId({
    sectionId: section.id,
    defaultValue: [],
  });
  return (
    <>
      {criteriaData.map((criteria) => (
        <CriteriaTableContent
          value={criteriaValues[criteria.id] || 'N/A'}
          key={criteria.id}
        />
      ))}
    </>
  );
};

const TableRaw = ({ teacher, sections, values, criteria, notes }) => (
  <TableContentItem>
    <TableContent {...ColumnSize[0]}>{teacher}</TableContent>
    {sections.map((section) => (
      <CriteriaTableContentList section={section} key={section.id} criteria={criteria} />
    ))}
    <TableContent {...noteColumnSize}>{notes}</TableContent>
  </TableContentItem>
);
const TableHeaderForSection = ({ section }) => {
  const { data: criteriaData } = useCriteriaBySectionId({
    sectionId: section.id,
    defaultValue: [],
  });
  const tableHeaderValues = React.useMemo(() => {
    const MappedCriteria = criteriaData.map((criteria) => ({
      criteria,
      uniqKey: criteria.id,
      value: criteria.displayName,
    }));
    return [...MappedCriteria];
  }, [criteriaData]);
  return (
    <>
      {/* <p>{section.displayName}</p> */}

      {tableHeaderValues.map((item, index) => {
        const { value, uniqKey } = item;
        return (
          <TableHeader
            dataTip={value}
            {...criteriaColumnSize}
            value={`${section.displayName?.[0]}${index + 1}`}
            key={uniqKey}
          />
        );
      })}
    </>
  );
};
const TableHeaderComp = ({ tableValues }) => (
  <TableHeadWrapper>
    {tableValues.map((item, index) => {
      const { value, uniqKey, section } = item;
      if (section) {
        return <TableHeaderForSection section={section} key={uniqKey} />;
      }
      return (
        <TableHeader
          {...(ColumnSize[index] ? ColumnSize[index] : defaultColumnSize)}
          value={value}
          key={uniqKey}
        />
      );
    })}
  </TableHeadWrapper>
);
const teacherColumns = {
  uniqKey: 'Teacher Name',
  value: 'Teacher Name',
};
const noteColumn = {
  uniqKey: 'Note',
  value: 'Note',
};

const IWTable = ({ IWId }) => {
  const { data: sectionData } = useSectionByIWId({ IWId, defaultValue: [] });
  const tableHeaderValues = React.useMemo(() => {
    const MappedSection = sectionData.map((section) => ({
      section,
      uniqKey: section.id,
      value: section.displayName,
    }));
    return [teacherColumns, ...MappedSection, noteColumn];
  }, [sectionData]);
  const { data: reportData } = useReportGet({
    defaultValue: [],
    filters: [
      {
        key: 'IWId',
        value: IWId,
      },
    ],
  });
  return (
    <TableContainerWrap>
      {!!reportData.length > 0 ? (
        <MobileWrapper>
          <TableHeaderComp tableValues={tableHeaderValues} />
          <TableContentWrapper>
            {reportData?.map((report) => (
              <TableRaw {...report} sections={sectionData} key={report.id} />
            ))}
          </TableContentWrapper>
        </MobileWrapper>
      ) : (
        <NoContent>
          <NotApplicationText>Data not available</NotApplicationText>
        </NoContent>
      )}
    </TableContainerWrap>
  );
};

export default IWTable;
