import { connect } from 'react-redux';
import PracticeSheet from '../../components/PracticeSheet';
import {
  selectors as PracticeSelectors,
  actions as PracticeActions,
} from '../../reducers/practice';
import { actions as userActions } from '../../reducers/user';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';

const mapStateToProps = (state, { match }) => ({
  courseId : _get(match, 'params.courseId', ''),
  sectionId : _get(match, 'params.sectionId', ''),
  subjectId : _get(match, 'params.subjectId', ''),
  unitId : _get(match, 'params.unitId', ''),
  lessonId : _get(match, 'params.lessonId', ''),
  practiceId: PracticeSelectors.getPracticeId(state), // get practice response Id
  isTakeQuestion: PracticeSelectors.getIsTakeQuestion(state), // get t
  isCompleteResponseSuccess: PracticeSelectors.isCompleteResponseSuccess(state),
});

const mapDispatchToProps = {
  takePracticeSession: PracticeActions.takePracticeSession,
  answerPracticeQuestion: PracticeActions.answerPracticeQuestion,
  completePracticeSession: PracticeActions.completePracticeSession,
  userReportIssue: userActions.userReportIssue,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PracticeSheet));
