import React from 'react';
import LoadingIndicator from '@components/LoadingIndicator';
import { useGetQuestions } from '../../Hooks/Query';
import { Assessment, AssessmentProps } from './Assessment';

interface StandardsAssessmentProps extends Omit<AssessmentProps, 'questions'> {
  publication: string;
  standards: string[];
  questionCount: number;
  offset: number;
  language?: string;
}

const StandardsAssessment = (props: StandardsAssessmentProps) => {
  const { language, activityId, questionCount, offset, publication, standards, userId } =
    props;
  const { data: questions, isLoading } = useGetQuestions({
    language,
    lessonCode: activityId,
    limit: questionCount,
    offset,
    publication,
    standards,
    useErrorBoundary: true,
    user: userId,
  });

  if (isLoading) {
    return <LoadingIndicator content="We are assembling the right questions for you" />;
  }

  return <Assessment questions={questions} {...props} />;
};

export { StandardsAssessment, StandardsAssessmentProps };
