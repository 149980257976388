import { connect } from 'react-redux';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _pick from 'lodash/pick';
import DashboardAttendanceReport from '../../components/DashboardAttendanceReport';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { selectors as CourseSelectors } from '../../reducers/courses';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import { actions as SectionSessionsActions } from '../../reducers/sectionSession';
import {
  actions as SectionStudentActions,
  selectors as sectionStudentSelectors,
  selectors as SectionsStudentActions,
} from '../../reducers/sectionStudent';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', null);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  const lessonAttendanceListArr = SectionSelectors.getSectionAttendanceListBySectionId(
    state,
    sectionId,
  );
  function filterAttendance(activityType) {
    const attendanceArr = lessonAttendanceListArr.filter(
      ({ activity }) => activity === activityType,
    );

    return attendanceArr.reduce((root, current) => {
      root[current.lesson] = {
        section: parseInt(current.section, 10),
        lesson: parseInt(current.lesson, 10),
        activity: activityType,
        recorded_by: current.recorded_by,
        records: root[current.lesson]?.records || [],
      };

      root[current.lesson].records.push({
        student: current.student,
        present: current.present,
        recorded: current.recorded,
      });

      return root;
    }, {});
  }
  const lessonAttendanceList = Object.values(filterAttendance('lesson'));
  const groupAttendanceList = Object.values(filterAttendance('group'));

  return {
    sectionId,
    courseDetail,
    sectionDetail,
    lessonAttendanceList,
    groupAttendanceList,
    studentList: sectionStudentSelectors.getStudentList(state, sectionId),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    studentFilter: SectionsStudentActions.getStudentListWithFilter(state, sectionId),
  };
};
const mapDispatchToProps = {
  sectionsAttendanceGet: SectionActions.sectionsAttendanceGet,
  sectionSessionGet: SectionSessionsActions.sectionSessionGet,
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardAttendanceReport);
