import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorMessageContainer,
  ErrorTitle,
  ErrorMessage,
  PresentationDiffContainer,
} from './PresentationManagement.style';
import SlideDiffs from './SlideDiffs';
import LoadingIndicator from '../LoadingIndicator';

const PresentationDiff = (props) => {
  const {
    defaultPresentationId,
    copyPresentationId,
    setErrorCode,
    setIsLoading,
    showAllSlides,
    isReviewer,
    isLoading,
    errorCode,
    setIsSlideModified,
  } = props;

  const [defaultPresentation, setDefaultPresentation] = useState([]);
  const [copyPresentation, setCopyPresentation] = useState([]);
  const [addedCopySlides, setAddedCopySlides] = useState([]);
  const [deletedCopySlides, setDeletedCopySlides] = useState([]);

  useEffect(() => {
    handleSlidesComparison();
  }, []);

  const fetchSlide = async (presentationId) => {
    const { access_token } = window.gapi.client.getToken();
    const url = `https://slides.googleapis.com/v1/presentations/${presentationId}`;
    const result = await fetch(url, {
      method: 'get',
      headers: new Headers({
        'content-type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      }),
    });
    return result.json();
  };

  const filterSlides = (slideIds1, slideIds2) => {
    const filteredSlideIds = slideIds1.filter(
      (item) => !slideIds2.includes(item),
    );
    return filteredSlideIds;
  };

  const setAddedSlides = (slide) => {
    setAddedCopySlides(prevAddedSlides => [...prevAddedSlides, slide]);
  };

  const setDeletedSlides = (slide) => {
    setDeletedCopySlides(prevDeletedSlides => [...prevDeletedSlides, slide]);
  };

  // removing contentUrl before equality comparison
  // since contentUrl is never same
  const objWithoutContentUrl = (obj) => {
    let slides = JSON.stringify(obj);
    const regex = new RegExp(',?"contentUrl":".*?",?', 'gi');
    slides = slides.replace(regex, '');
    slides = slides.replace(/""/, '","');
    return JSON.parse(slides);
  };

  const handleSlidesComparison = async () => {
    setIsLoading(true);

    let defaultPresentationData = fetchSlide(defaultPresentationId);
    let copyPresentationData = fetchSlide(copyPresentationId);
    let error = null;
    const response = await Promise.all([
      defaultPresentationData,
      copyPresentationData,
    ]).then((res) => res);
    response.map((res) => {
      if (res?.error) {
        error = res.error;
        setIsLoading(false);
        setErrorCode(res.error.code);
      }
    });
    if (error) return;

    [defaultPresentationData, copyPresentationData] = response;

    const defaultSlides = objWithoutContentUrl(defaultPresentationData.slides);
    const copySlides = objWithoutContentUrl(copyPresentationData.slides);

    // check if the slides are modified
    if (JSON.stringify(defaultSlides) === JSON.stringify(copySlides)) {
      setIsSlideModified(false);
    } else {
      setIsSlideModified(true);
    }

    const defaultSlideIds = defaultPresentationData.slides.map(
      (item) => item.objectId,
    );
    const copySlideIds = copyPresentationData.slides.map((item) => item.objectId);
    const adddedSlides = filterSlides(copySlideIds, defaultSlideIds);
    const deletedSlides = filterSlides(defaultSlideIds, copySlideIds);

    defaultSlideIds.map((item, index) => {
      let position = index - 1;

      if (deletedSlides.includes(item) && index !== 0) {
        let previousSlide;
        let previousSlideIndex;
        do {
          previousSlide = defaultSlideIds[position];
          for (let i = 0; i < copyPresentationData.slides.length; i++) {
            if (copyPresentationData.slides[i].objectId === previousSlide) {
              previousSlideIndex = i;
            }
          }
          position -= 1;
        } while (previousSlideIndex === undefined);

        copyPresentationData.slides.splice(previousSlideIndex + 1, 0, {});
      }
      if (deletedSlides.includes(item) && index === 0) {
        copyPresentationData.slides.splice(0, 0, {});
      }
    });

    copySlideIds.map((item, index) => {
      if (adddedSlides.includes(item) && index !== 0) {
        let position = index - 1;
        let previousSlide;
        let previousSlideIndex;
        do {
          previousSlide = copySlideIds[position];
          for (let i = 0; i < defaultPresentationData.slides.length; i++) {
            if (defaultPresentationData.slides[i].objectId === previousSlide) {
              previousSlideIndex = i;
            }
          }
          position -= 1;
        } while (previousSlideIndex === undefined);

        defaultPresentationData.slides.splice(previousSlideIndex + 1, 0, {});
      }
      if (adddedSlides.includes(item) && index === 0) {
        defaultPresentationData.slides.splice(0, 0, {});
      }
    });

    setDefaultPresentation(defaultPresentationData);
    setCopyPresentation(copyPresentationData);
    setIsLoading(false);
  };

  const renderSlideDifference = (slide, index) => {
    const copySlides = copyPresentation?.slides || [];
    let defaultSlideNumber = index + 1;
    let copySlideNumber = index + 1;
    let slideCorrection = 0;

    addedCopySlides.map((adddedSlide) => {
      if (adddedSlide <= defaultSlideNumber) {
        slideCorrection += 1;
      }
    });
    defaultSlideNumber -= slideCorrection;

    slideCorrection = 0;
    deletedCopySlides.map((deletedSlide) => {
      if (deletedSlide <= copySlideNumber) {
        slideCorrection += 1;
      }
    });
    copySlideNumber -= slideCorrection;

    return (
      <SlideDiffs
        key={index}
        defaultSlide={slide}
        copySlide={copySlides[index]}
        defaultSlideNumber={defaultSlideNumber}
        copySlideNumber={copySlideNumber}
        index={index + 1}
        defaultSlideId={defaultPresentation?.presentationId}
        copySlideId={copyPresentation?.presentationId}
        showAllSlides={showAllSlides}
        setAddedSlides={setAddedSlides}
        addedSlides={addedCopySlides}
        setDeletedSlides={setDeletedSlides}
        deletedSlides={deletedCopySlides}
        isReviewer={isReviewer}
      />
    );
  };

  const sortedSlides = defaultPresentation?.slides || [];

  if (isLoading) {
    return (
      <PresentationDiffContainer>
        <LoadingIndicator loading={isLoading} noBackground partial />
      </PresentationDiffContainer>
    );
  }

  if (!isLoading && errorCode === 403) {
    return (
      <PresentationDiffContainer>
        <ErrorMessageContainer>
          <ErrorTitle>Access Denied</ErrorTitle>
          <ErrorMessage>Please request the teacher to make the slide public!</ErrorMessage>
        </ErrorMessageContainer>
      </PresentationDiffContainer>
    );
  }

  return (
    <PresentationDiffContainer>
      {sortedSlides.map((slide, index) => renderSlideDifference(slide, index))}
    </PresentationDiffContainer>
  );
};

PresentationDiff.propTypes = {
  defaultPresentationId: PropTypes.string,
  copyPresentationId: PropTypes.string,
  setErrorCode: PropTypes.func,
  setIsLoading: PropTypes.func,
  showAllSlides: PropTypes.bool,
  isReviewer: PropTypes.bool,
  isLoading: PropTypes.bool,
  errorCode: PropTypes.string,
};

export default PresentationDiff;
