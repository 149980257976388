/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors } from './sectionsReducer';
import { LessonAttendanceActivity } from '@components/Component/Lesson/LessonAttendance/types';

export const useExamScore = () => {
  const { unitId, lessonId } = useParams();
  if (!unitId || !lessonId) {
    console.error('lessonId or unitId is undefined in useExamScore hook');
  }
  const examsScoreSummary = useSelector((state: any) =>
    selectors.examsScoreSummaryByUnitAndLesson(state, unitId, lessonId),
  );
  return examsScoreSummary;
};
export const useCurrentSection = () => {
  const { sectionId } = useParams();
  if (!sectionId) {
    console.error(
      'sectionId is not defined on useCurrentSection you can only use this hook within section routes',
    );
  }
  const currentSection = useSelector((state: any) =>
    selectors.getSectionById(state, sectionId),
  );
  return currentSection;
};
export const useSectionAttendanceList = () => {
  const { sectionId } = useParams();
  const sectionAttendanceList = useSelector((state: any) =>
    selectors.getSectionAttendanceListBySectionId(state, sectionId),
  );
  return sectionAttendanceList;
};

export const useSectionAttendanceListByActivityType = (
  activityType: LessonAttendanceActivity,
) => {
  const sectionAttendanceList = useSectionAttendanceList();
  return sectionAttendanceList.filter(
    (attendance) => attendance.activity === activityType,
  );
};
