/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import { LessonsType, Unit } from '@CT-Types/lessonDomain';
import { useParams } from 'react-router-dom';
import UnitItemsWrapper from './UnitItemsWrapper';
import { VirtualLessonList } from '../UnitLessonItem.style';

interface UnitAccordionProps {
  item: Unit;
  practiceSummaryByUnitId: Record<string, any>;
  subjectVersion: number;
  unitAssessmentVisibleState: Record<string, boolean>;
  isReviewClassEnabled: boolean;
}

const StyledAccordionSummary = styled(AccordionSummary)`
  padding-inline: 1rem !important;
  width: 100%;
`;

const getAllLessonsPerUnit = (unit: Unit): LessonsType[] => {
  const lessons: LessonsType[] = [];

  const extractLessons = (selectedUnit: Unit): void => {
    if (selectedUnit.lessons?.length) {
      lessons.push(...selectedUnit.lessons);
    }
    selectedUnit.children?.forEach(extractLessons);
  };

  extractLessons(unit);
  return lessons;
};
const isUnitActive = (item: Unit, activeUnitId: string) => {
  const recurse = (unit: Unit) => {
    if (unit.id === Number(activeUnitId)) {
      return true;
    }
    return unit.children?.some(recurse);
  };
  return recurse(item);
};
const UnitAccordion: React.FC<UnitAccordionProps> = ({
  item,
  practiceSummaryByUnitId,
  subjectVersion,
  unitAssessmentVisibleState,
  isReviewClassEnabled,
}) => {
  const { unitId: activeUnitId } = useParams();
  const [isExpanded, setIsExpanded] = React.useState(isUnitActive(item, activeUnitId));
  const { 'pacing-guide-id': pacingGuideId } = useSearchParams();
  const allLessons = React.useMemo(() => getAllLessonsPerUnit(item), [item]);
  const { data: pacingGuidesLessons } = useGetPacingGuideLessons();
  const hasPacingGuideLessons = React.useMemo(
    () =>
      allLessons.some((lesson) =>
        pacingGuidesLessons?.some((guide) => guide.lesson === lesson.id),
      ),
    [allLessons, pacingGuidesLessons],
  );

  const handleAccordionChange = React.useCallback(
    (_: React.SyntheticEvent, expanded: boolean) => setIsExpanded(expanded),
    [],
  );

  if (!hasPacingGuideLessons && pacingGuideId) {
    return null;
  }
  return (
    <VirtualLessonList>
      <Accordion expanded={isExpanded} onChange={handleAccordionChange}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ width: '100%', flexShrink: 0, fontSize: 'inherit' }}>
            {item.name}
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Typography
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: 1,
              gap: 1,
              '& .sub-nav-item-wrap': {
                '&:first-of-type .sub-nav-item-wrap_icon-wrap:before': {
                  display: 'none',
                },
                '&:last-of-type .sub-nav-item-wrap_icon-wrap:after': {
                  display: 'none',
                },
              },
            }}
          >
            <UnitItemsWrapper
              unit={item}
              practiceSummaryByUnitId={practiceSummaryByUnitId}
              subjectVersion={subjectVersion}
              unitAssessmentVisibleState={unitAssessmentVisibleState}
              isReviewClassEnabled={isReviewClassEnabled}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </VirtualLessonList>
  );
};

export default React.memo(UnitAccordion);
