import React from 'react';
import {
  call, put, takeLeading, select, take,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ROLE_TYPE } from '../../utils/enums';
import * as SessionAPIs from '../../apis/sessions';
import * as SectionAPI from '../../apis/sections';
import * as ExamAPI from '../../apis/exam';
import {
  SECTION_EXAMS_GET,
  SECTION_EXAMS_ADD,
  SECTION_EXAMS_GET_DETAIL,
  SECTION_EXAMS_UNLOCK,
  SECTION_EXAMS_PAUSE,
  SECTION_EXAMS_RESET,
  SECTION_EXAMS_UPDATE,
  SECTION_EXAMS_DELETE,
  SECTION_EXAMS_UNLOCK_SUCCESS,
  SECTION_EXAMS_PAUSE_SUCCESS,
  SECTION_EXAMS_RESET_SUCCESS,
  SECTION_EXAMS_GET_DETAIL_SUCCESS,
  SECTION_EXAMS_GET_DETAIL_FAIL,
  SECTION_EXAM_REVIEW_UNLOCK,
  SECTION_EXAM_REVIEW_UNLOCK_SUCCESS,
  SECTION_EXAM_REVIEW_LOCK,
  SECTION_EXAMS_RESET_EXAM,
  SECTION_EXAM_GET_V2_ACCESS,
  actions as SectionExamActions,
  selectors as sectionExamSelectors,
} from '../../reducers/sectionExam';
import { actions as ExamResults } from '../../reducers/examResult';
import {
  actions as ScheduleModalActions,
} from '../../reducers/scheduleModal';
import {
  SECTIONS_GET_DETAIL_SUCCESS,
  SECTIONS_GET_DETAIL_FAIL,
  actions as sectionActions,
  selectors as sectionSelectors,
} from '../../reducers/sections';
import { selectors as userSelectors } from '../../reducers/user';
import { error as ToastError, success as ToastSuccess, info } from '../../utils/toast';
import { QueryClientEmitter } from '@utils/queryClientHelper';
import { getFeatureAccess } from '../../apis/adminReports';

/**
 * ! Need update callback
 */
function* sectionExamGet({ sectionId, shouldFetchAgain }) {
  const shouldFetch = shouldFetchAgain ? shouldFetchAgain : yield select(sectionExamSelectors.getShouldFetch, sectionId);

  if (!shouldFetch) {
    return;
  }

  const { response, error } = yield call(SectionAPI.getSectionExam, sectionId);
  if (response) {
    const { data = [] } = response;
    const sortedData = _orderBy(data, 'date', 'asc');
    yield put(SectionExamActions.sectionExamGetSuccess(sortedData, sectionId));
  } else {
    yield put(SectionExamActions.sectionExamGetFail(error));
  }
}

function* sectionExamAdd({ examInfo }) {
  const { response, error } = yield call(SessionAPIs.addSessionExam, examInfo);
  if (response) {
    const { data } = response;
    yield put(SectionExamActions.sectionExamAddSuccess(data));
  } else {
    const errMsg = _get(error, '[0]', 'Something has wrong.');
    yield put(SectionExamActions.sectionExamAddFail(errMsg));
    ToastError(errMsg);
  }
}

function* sectionExamGetDetail({ examSessionId }) {
  const { response, error } = yield call(ExamAPI.getExamDetail, examSessionId);

  if (response) {
    const { data } = response;
    yield put(SectionExamActions.sectionExamGetDetailSuccess(examSessionId, data));
  } else {
    yield put(SectionExamActions.sectionExamGetDetailFail(error));
  }
}

function* sectionExamUnlock({ sessionId, examSectionId }) {
  const { response, error } = yield call(ExamAPI.unlockSessionExam, sessionId, examSectionId);

  if (response) {
    yield put(SectionExamActions.sectionExamUnlockSuccess(sessionId, examSectionId));
    ToastSuccess('Exam section was unlocked successfully.');
  } else {
    yield put(SectionExamActions.sectionExamUnlockFail(error));
    ToastError(error.message || error);
  }
}

function* sectionExamReviewUnlock({ sessionId, examSectionId }) {
  const { response, error } = yield call(ExamAPI.unlockSessionExamReview, sessionId, examSectionId);

  if (response) {
    yield put(SectionExamActions.sectionExamReviewUnlockSuccess(sessionId, examSectionId));
    ToastSuccess('Answer review was enabled successfully.');
  } else {
    ToastError(error.message || error);
  }
}

function* sectionExamReviewLock({ sessionId, examSectionId }) {
  const { response, error } = yield call(ExamAPI.lockSessionExamReview, sessionId, examSectionId);

  if (response) {
    yield put(SectionExamActions.sectionExamReviewLockSuccess(sessionId, examSectionId));
    ToastSuccess('Answer review was disabled successfully.');
  } else {
    ToastError(error.message || error);
  }
}
function* sectionExamReviewUnlockSuccess({ sessionId: examSessionId, examSectionId: sectionId }) {
  const role = yield select(userSelectors.getUserRole);

  if (role === ROLE_TYPE.INSTRUCTOR) {
    return;
  }
  let examSessionDetail = yield select(sectionExamSelectors.getExamDetailById, examSessionId);
  if (_isEmpty(examSessionDetail) || _isEmpty(examSessionDetail.sectionId)) {
    yield put(SectionExamActions.sectionExamGetDetail(examSessionId));
    const { payload, error } = yield take([
      SECTION_EXAMS_GET_DETAIL_SUCCESS,
      SECTION_EXAMS_GET_DETAIL_FAIL,
    ]);

    if (error) {
      info(defaultMessage, { autoClose: false });
      return;
    }
    examSessionDetail = payload;
  }
  const sessionType = examSessionDetail.session_type;
  const sessionTypeObj = { 0: 'Placement', 1: 'Mid', 2: 'Exit' };
  const section = _get(examSessionDetail, 'exam.sections', []);
  const sectionName = section.find((item) => item.id === sectionId);
  const msg = (
    <span>
      {`You can review your answers for ${sessionTypeObj[sessionType]} Test : ${sectionName.name}`}
    </span>
  );

  info(msg, { autoClose: false });
}
function* sectionExamPause({ sessionId, examSectionId }) {
  const { response, error } = yield call(ExamAPI.pauseSessionExam, sessionId, examSectionId);

  if (response) {
    yield put(SectionExamActions.sectionExamPauseSuccess(sessionId, examSectionId));
    ToastSuccess('Exam section timer was paused successfully. Section is now locked.');
  } else {
    yield put(SectionExamActions.sectionExamPauseFail(error));
    ToastError(error.message || error);
  }
}

function* sectionExamReset({ sessionId, examSectionId, studentId }) {
  const { response, error } = yield call(ExamAPI.resetSessionExam,
    sessionId, examSectionId, studentId);

  if (response) {
    yield put(SectionExamActions.sectionExamResetSuccess(sessionId, examSectionId));
    if (!studentId) {
      ToastSuccess('Timer was reset for in-progress exams. Students who have already submitted their exam do not receive extra time.');
    } else {
      ToastSuccess('Timer was reset successfully .');
    }
  } else {
    yield put(SectionExamActions.sectionExamResetFail(error));
    ToastError('Timer was not reset for in-progress exams. Please try again.' || error);
  }
}

function* sectionExamResetExamSection({ sessionId, examSectionId, studentId }) {
  let { response, error } = yield call(ExamAPI.resetSessionExamSection,
    sessionId, examSectionId, studentId);

  if (response) {
    yield put(SectionExamActions.sectionExamResetSuccess(sessionId, examSectionId));
    yield put(ExamResults.examUpdateScore());
    /* Emit an event when the Exam got rested, Should be removed when we get rid of Redux or move the exam-reset API to proper place inside hook */
    yield QueryClientEmitter.emit('invalidateQueries', ['getExamScaledScore']);
    yield QueryClientEmitter.emit('invalidateQueries', ['getExamResult']);

    ToastSuccess('Exam was reset successfully.');
  } else {
    yield put(SectionExamActions.sectionExamResetFail(error));
    if (error && error.includes('400')) {
        error = error.replace(/\[400\]/, '');
  }
    ToastError(error || "Exam was not reset. Please try again.");
  }
}

function* sectionExamUpdate({
  sectionId,
  examId,
  examInfo,
}) {
  /* const payload = {
    ...examInfo,
    exam: examInfo.exam.id,
    length_type: examInfo.length_type.value,
    session_type: examInfo.session_type.value,
    curriculum: examInfo.curriculumId,
    start_date: moment(examInfo.start_date).format('YYYY-MM-DD'),
    end_date: moment(examInfo.end_date).format('YYYY-MM-DD'),
  }; */
  const { response, error } = yield call(ExamAPI.updateSessionExam, examId, examInfo);

  if (response) {
    yield put(SectionExamActions.sectionExamUpdateSuccess(examId, response.data));
    ToastSuccess('Exam session has been updated successfully.');
    yield put(ScheduleModalActions.closeFormModal());
  } else {
    yield put(SectionExamActions.sectionExamUpdateFail(examId, error));
    ToastError(error.message || error);
  }
}

function* sectionExamDelete({ sectionId }) {
  const { response, error } = yield call(SessionAPIs.deleteSessionExam, sectionId);
  if (response) {
    yield put(SectionExamActions.sectionExamDeleteSuccess(sectionId));
    yield put(ScheduleModalActions.closeFormModal());
  } else {
    yield put(SectionExamActions.sectionExamDeleteFail(error));
  }
}

const defaultMessage = 'Your exam is now unlocked. Click the “Begin test” button to proceed.';

function* sectionExamUnlockSuccess({ sessionId: examSessionId }) {
  const role = yield select(userSelectors.getUserRole);

  if (role === ROLE_TYPE.INSTRUCTOR) {
    return;
  }

  let examSessionDetail = yield select(sectionExamSelectors.getExamDetailById, examSessionId);

  if (_isEmpty(examSessionDetail) || _isEmpty(examSessionDetail.sectionId)) {
    yield put(SectionExamActions.sectionExamGetDetail(examSessionId));
    const { payload, error } = yield take([
      SECTION_EXAMS_GET_DETAIL_SUCCESS,
      SECTION_EXAMS_GET_DETAIL_FAIL,
    ]);

    if (!error) {
      info(defaultMessage, { autoClose: false });
      return;
    }
    examSessionDetail = payload;
  }

  const { section: sectionId } = examSessionDetail;
  let sectionDetail = yield select(sectionSelectors.getSectionListById, sectionId);

  if (_isEmpty(sectionDetail) || _isEmpty(sectionDetail.course)) {
    yield put(sectionActions.sectionsGetDetail(sectionId));

    const { payload, error } = yield take([
      SECTIONS_GET_DETAIL_SUCCESS,
      SECTIONS_GET_DETAIL_FAIL,
    ]);

    if (!error) {
      info(defaultMessage, { autoClose: false });
      return;
    }

    sectionDetail = payload;
  }

  const { course: courseId } = sectionDetail;
  const redirectLink = `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}`;
  const msg = (
    <span>
      Your exam has just been unlocked.
      <Link to={redirectLink}>Click here </Link>
      to redirect to exam detail page
    </span>
  );

  info(msg, { autoClose: false });
}

function* sectionExamPauseSuccess() {
  const role = yield select(userSelectors.getUserRole);

  if (role === ROLE_TYPE.INSTRUCTOR) {
    return;
  }

  const msg = 'The exam has been locked by your teacher. Your answers have been saved so you can start where you left off when the teacher continues the exam.';

  info(msg, { autoClose: false });
}

function* sectionExamResetSuccess() {
  const role = yield select(userSelectors.getUserRole);

  if (role === ROLE_TYPE.INSTRUCTOR) {
    return;
  }

  yield put(ExamResults.examUpdateScore());
  const msg = 'Your exam has been reset. Please check your exam detail.';
  info(msg, { autoClose: false });
}

function* fetchExamV2Access(action) {
  try {
    const { response, error } = yield call(getFeatureAccess, "exam-v2");
    if (error) throw error;
    yield put(SectionExamActions.fetchExamV2AccessSuccess(response.data));
  } catch (error) {
    yield put(SectionExamActions.fetchExamV2AccessFailed(error.message || error));
  }
}

export default function* sectionExamSaga() {
  yield takeLeading(SECTION_EXAMS_GET, sectionExamGet);
  yield takeLeading(SECTION_EXAMS_ADD, sectionExamAdd);
  yield takeLeading(SECTION_EXAMS_GET_DETAIL, sectionExamGetDetail);
  yield takeLeading(SECTION_EXAMS_UNLOCK, sectionExamUnlock);
  yield takeLeading(SECTION_EXAM_REVIEW_UNLOCK, sectionExamReviewUnlock);
  yield takeLeading(SECTION_EXAM_REVIEW_UNLOCK_SUCCESS, sectionExamReviewUnlockSuccess);
  yield takeLeading(SECTION_EXAM_REVIEW_LOCK, sectionExamReviewLock);
  yield takeLeading(SECTION_EXAMS_PAUSE, sectionExamPause);
  yield takeLeading(SECTION_EXAMS_RESET, sectionExamReset);
  yield takeLeading(SECTION_EXAMS_UPDATE, sectionExamUpdate);
  yield takeLeading(SECTION_EXAMS_DELETE, sectionExamDelete);
  yield takeLeading(SECTION_EXAMS_UNLOCK_SUCCESS, sectionExamUnlockSuccess);
  yield takeLeading(SECTION_EXAMS_PAUSE_SUCCESS, sectionExamPauseSuccess);
  yield takeLeading(SECTION_EXAMS_RESET_SUCCESS, sectionExamResetSuccess);
  yield takeLeading(SECTION_EXAMS_RESET_EXAM, sectionExamResetExamSection);
  yield takeLeading(SECTION_EXAM_GET_V2_ACCESS, fetchExamV2Access);
}
