import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import {
  PracticeAnswerExplanationWrap,
  StudentListAnswer,
  StudentListAnswerWrap,
  AnswerExplanationDecs,
} from './AnswerExplanation.style';
import {
  QuestionHeaderWrap,
  QuestionContentWrap,
} from '../QuestionItem/QuestionItem.style';
import { FeedbackContainer, FeedbackIcon } from '../Generals/stylesheets/Button.style';
import { ROLE_TYPE, REPORT_TYPES } from '../../utils/enums';
import MathJaxItem from '../MathJaxContainer';

export default class AnswerExplanation extends Component {
  static propTypes = {
    isPracticeNavigation: PropTypes.bool,
    explanation: PropTypes.object,
    studentWithSelectedAnswer: PropTypes.array,
    studentByIds: PropTypes.shape(),
    sectionsStudentGet: PropTypes.func.isRequired,
    sectionId: PropTypes.any,
    openReportErrorModal: PropTypes.func,
    userRole: PropTypes.string,
    questionId: PropTypes.number,
  };

  static defaultProps = {
    studentWithSelectedAnswer: [],
  };

  componentDidMount() {
    const {
      studentByIds,
      sectionsStudentGet,
      sectionId,
      userRole,
    } = this.props;
    if (_isEmpty(studentByIds) && userRole !== ROLE_TYPE.STUDENT) {
      sectionsStudentGet(sectionId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      studentByIds,
      sectionsStudentGet,
      sectionId,
      userRole,
    } = this.props;
    const { sectionId: prevSectionId } = prevProps;

    if (
      prevSectionId !== sectionId
      && _isEmpty(studentByIds)
      && userRole !== ROLE_TYPE.STUDENT
    ) {
      sectionsStudentGet(sectionId);
    }
  }

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.EXPLANATION, questionId);
  };

  renderStudentListAnswer = () => {
    const { studentWithSelectedAnswer, studentByIds } = this.props;
    if (_isEmpty(studentByIds)) return null;
    const uniqueStudent = _uniq(studentWithSelectedAnswer);

    return (
      <StudentListAnswer>
        {uniqueStudent.map((studentId) => {
          if (studentByIds[studentId]) {
            return (
              <span key={studentId}>
                {studentByIds[studentId].first_name
                && studentByIds[studentId].last_name
                  ? `${studentByIds[studentId].first_name} ${studentByIds[studentId].last_name}`
                  : null}
              </span>
            );
          } return <span key={studentId}></span>;
        })}
      </StudentListAnswer>
    );
  };

  render() {
    const {
      isPracticeNavigation,
      explanation,
      studentWithSelectedAnswer,
      unSetHeight,
    } = this.props;
    const text = _get(explanation, 'text', '');

    if (!explanation) {
      return (
        <PracticeAnswerExplanationWrap
          isNavigationClose={isPracticeNavigation}
        />
      );
    }

    return (
      <PracticeAnswerExplanationWrap
        isNavigationClose={isPracticeNavigation}
        unSetHeight={unSetHeight}
      >
        <QuestionHeaderWrap>
          Answer Explanation
          <FeedbackContainer onClick={this.openReportErrorModal}>
            <FeedbackIcon src="/images/feedback.png" />
          </FeedbackContainer>
        </QuestionHeaderWrap>

        <QuestionContentWrap practiceQuestion>
          <AnswerExplanationDecs>
            <MathJaxItem data={text} />
          </AnswerExplanationDecs>
        </QuestionContentWrap>

        {!_isEmpty(studentWithSelectedAnswer) && (
          <StudentListAnswerWrap>
            <p>Students who chose this answer</p>
            {this.renderStudentListAnswer()}
          </StudentListAnswerWrap>
        )}
      </PracticeAnswerExplanationWrap>
    );
  }
}
