import React from 'react';
import Paper from '@components/Atoms/Paper';
import Typography from '@components/Atoms/Typography';
import Box from '@components/Atoms/Box';
import { Divider } from '@mui/material';
import ShowIf from '@components/Atoms/ShowIf';
import Statistic, { StatisticProps } from './Statistic';

type StatisticsGroupProps = {
  title: string;
  image?: string;
  statistics: StatisticProps[];
};

const StatisticsGroup = ({ title, image, statistics }: StatisticsGroupProps) => (
  <Paper sx={{ p: 1 }}>
    <Typography variant="h6" sx={{ color: 'text.primary' }}>
      {title}
    </Typography>
    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
      <ShowIf If={!!image}>
        <Box px={1} style={{ display: 'flex', alignItems: 'center' }}>
          <img src={image} width="75px" alt="" />
        </Box>
      </ShowIf>
      {statistics.map((statistic, index) => (
        <React.Fragment key={statistic.label}>
          {index > 0 && statistics.length > 1 && (
            <Divider orientation="vertical" variant="middle" flexItem />
          )}
          <Statistic {...statistic} />
        </React.Fragment>
      ))}
    </Box>
  </Paper>
);

export default StatisticsGroup;
