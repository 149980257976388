import React from 'react';
import ShowIf from '@components/Atoms/ShowIf';
import Button from '@components/Atoms/Button';

import AttendanceRecordButton from '@components/Component/Lesson/AttendanceRecordButton';

import { useParams, useHistory } from 'react-router-dom';
import WorksheetsButton from '@components/Component/Lesson/WorksheetsButton';
import { useIsTeacher } from '@reducers/user/hooks';
import TooltipDisabledButton from '@components/Generals/TooltipDisabledButton';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import {
  useCurrentGroup,
  useCurrentStudent,
  useIsAllActivityGroupsCompleted,
} from '../hooks/queryStore';
import { useUpdateIndividualGroupActivity } from '../hooks/mutation';
import { activityStatus } from '../types';
import { useGetGroupActivityInfo } from '../hooks/query';
import { useEndActivitySession } from '../Provider/EndSessionProvider';
import { useStartActivity } from '../Provider/StartActivityProvider';

/**
 * A component that displays buttons for actions related to a practice session, including starting a lesson practice,
 * starting an activity, ending the session, previewing questions, reviewing answers, and reopening the session.
 * @return {JSX.Element} - A box element containing the action buttons.
 */
const ActionButtons = () => {
  const history = useHistory();
  const { courseId, sectionId, subjectId, unitId, lessonId } = useParams();
  const isTeacher: boolean = useIsTeacher();
  const { handleEndSession } = useEndActivitySession();
  const { handleStartActivity } = useStartActivity();
  const currentGroupActivity = useCurrentGroup();
  const { 'pacing-guide-id': pacingGuideId } = useSearchParams();

  const { student_id: activityStudentId, online_response_id: onlineResponseId } =
    useCurrentStudent(currentGroupActivity);

  const {
    data: {
      id: groupActivityId,
      lesson: activityLessonId,
      online_activity_status: onlineActivityStatus = 0,
    },
  } = useGetGroupActivityInfo();
  // Custom hook to check if all the group activities completed
  const isAllActivityGroupsCompleted = useIsAllActivityGroupsCompleted();

  const updateIndividualGroupActivity = useUpdateIndividualGroupActivity();

  const startActivityHandler = () => {
    if (isTeacher && groupActivityId) {
      handleStartActivity();
    } else {
      updateIndividualGroupActivity.mutate(
        {
          group_id: currentGroupActivity.id,
          student_id: activityStudentId,
          status: 1,
        },
        {
          onSuccess: () => {
            history.push(
              `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/online-activity/question/${groupActivityId}/${
                currentGroupActivity.id
              }/1/${onlineResponseId}/${activityStudentId}?${
                pacingGuideId ? `pacing-guide-id=${pacingGuideId}` : ''
              }`,
            );
          },
        },
      );
    }
  };
  const endActivityHandler = () => {
    handleEndSession();
  };

  const reviewQuestionsHandler = () => {
    if (isTeacher) {
      history.push({
        pathname: `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/online-activity/question/teacher/${groupActivityId}/review`,
        search: `?activityLessonId=${activityLessonId}${
          pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
        }`,
      });
    } else {
      history.push({
        pathname: `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/online-activity/question/${groupActivityId}/${currentGroupActivity.id}/review/${onlineResponseId}/${activityStudentId}`,
        search: `?${pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''}`,
      });
    }
  };

  return (
    <>
      {/* -----------------  Start Activity Button -------------- */}
      <ShowIf
        If={
          (isTeacher && onlineActivityStatus === activityStatus.notStarted) ||
          (isTeacher && onlineActivityStatus === activityStatus.ended) ||
          (!isTeacher && onlineActivityStatus !== activityStatus.ended)
        }
      >
        <TooltipDisabledButton
          disabled={!isAllActivityGroupsCompleted}
          title="All groups have completed this activity. Reset a group to start the activity again."
        >
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={startActivityHandler}
            color="play"
            data-testid="start-activity-button"
            disabled={
              (!isTeacher && onlineActivityStatus === activityStatus.notStarted) ||
              isAllActivityGroupsCompleted
            }
          >
            Start Activity
          </Button>
        </TooltipDisabledButton>
      </ShowIf>
      {/* -----------------  End session Button ----------------- */}
      <ShowIf If={isTeacher && onlineActivityStatus === activityStatus.started}>
        <Button
          variant="outlined"
          onClick={endActivityHandler}
          color="error"
          data-testid="end-session-button"
        >
          End session
        </Button>
      </ShowIf>
      {/* -----------------  Review Answers Button -------------- */}
      <ShowIf If={isTeacher || onlineActivityStatus === activityStatus.ended}>
        <Button
          variant="outlined"
          data-testid="review-answers-button"
          onClick={reviewQuestionsHandler}
        >
          Review Answers
        </Button>
      </ShowIf>
      {/* ------------------- Record Attendance Button --------- */}
      <ShowIf If={isTeacher}>
        <AttendanceRecordButton
          data-testid="attendance-button"
          dialogTitle="Group Activity Attendance"
        />
      </ShowIf>
      {/* ------------------- Worksheets Button ---------------- */}
      <WorksheetsButton />
    </>
  );
};

export default ActionButtons;
