import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const CreateOrJoinItemWrap = styled.div`
  width: 100%;
`;

export const CreateOrJoinItem = styled.div``;

export const CreateOrJoinTitle = styled.div`
  margin: 10px 0px 40px;
  text-align: center;
  & > h1 {
    color: #000000;
    font-family: 'MontserratRegular',Arial;
    font-size: 20.0pt;
    line-height: 38px;
    @media (max-width: 480px) {
      font-size: 17.0pt;
    }
  }
  & > p {
    color: #000000;
    font-family: 'Montserrat',Arial;
    font-size: 10.0pt;
    font-style: normal;
    font-weight: 200;
    width: 100%;
    padding-top: 10px;
    @media (max-width: 480px) {
      font-size: 9.0pt;
    }
  }
  & > span {
    color: #000000;
    font-family: 'Montserrat',Arial;
    font-size: 9.0pt;
    font-style: normal;
    font-weight: 200;
  }
  .select__value-container {
    width: 85%;
    min-width: unset;
  }

  @media (max-width: 480px) {
    margin: 10px 0px 20px;
    text-align: center;
  }
  @media (max-width: 768px) {
    margin: 10px 0px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CheckItOutText = styled.span`
  color: #000000;
  font-family: 'Montserrat',Arial;
  font-size: 9.0pt;
  font-style: normal;
  font-weight: 200;
`;

export const OptionsCreateOrJoinWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 43px;
  padding: 0 5px;
  margin-bottom: 20px;
  min-height: 20px;
  text-shadow: none;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  margin-top: 20px;
  div {
    margin-bottom: 0;
  }
`;

export const NextIconOptions = styled.i`
  width: 0;
  height: 0;
  font-size: 40px;
  position: absolute;
  right: 40px;
  color: #4A85E8;
`;

export const ContentInsideWellWrapper = styled.div`
  width: 90%;
`;

export const LinkInsideWellWrapper = styled.a`
  font-family: 'Montserrat-Regular',Arial;
  font-size: 9.0pt;
  cursor: pointer;
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
`;
