import React, { PureComponent } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { ModalContent, ModalHeader } from '../ConfirmModal/ConfirmModal.style';
import { ModalCLose } from '../Generals/stylesheets/General.style';
import {
  ErrorExample,
  ErrorExampleTitle,
  ErrorExampleContent,
} from '../StudentRegister/StudentRegister.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '480px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#f4f5f7',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class ErrorModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
  }

  render() {
    const { isOpen, onClose } = this.props;
    return (
      <Modal
        onRequestClose={onClose}
        isOpen={isOpen}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Error Message
          <ModalCLose className="chalktalk-close" onClick={onClose} />
        </ModalHeader>

        <ModalContent>
          <p>The copy/paste data that doesn&#39;t match the schema we look for</p>

          <ErrorExample>
            <ErrorExampleTitle>Example of the schema:</ErrorExampleTitle>

            <ErrorExampleContent>
              <p>Jonas, Ray Black, Fisher, monum@mailinator.com, 12</p>
              <p>Jasmine, Annie , Holder, jasmine@gmail.com, 9</p>
              <p>Hannah, Annie , Monthana, monthana@mailinator.net, 9</p>
              <p>Fatima, Brown,	Robert, gigosyheby@mailinator.net, 10 </p>
            </ErrorExampleContent>
          </ErrorExample>
        </ModalContent>
      </Modal>
    );
  }
}

export default ErrorModal;
