import styled, { css } from 'styled-components';

export const ScheduleWidgetWrap = styled.div`
  display: flex;
  transition: all 400ms ease;
  flex-direction: column;
  padding: 1em 0;
  justify-content: center;
  width: 45vw;
  min-width: max-content;
  margin: auto;
  @media (max-width: 1170px) {
    transform: scale(0.9);
  }
  @media (max-width: 1080px) {
    transform: scale(0.85);
  }
  @media (max-width: 960px) {
    transform: scale(1);
  }
  @media (max-width: 850px) {
    transform: scale(0.9);
  }
  @media (max-width: 768px) {
    transform: scale(0.95);
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  cursor: pointer;
  transition: 200ms ease;
  align-items: center;
  width: 11.5em;
  height: 13.5em;
  justify-content: space-between;
  box-shadow: rgba(9,30,66,0.25) 0px 0px 1px, rgba(9,30,66,0.31) 0px 0;
  background-image: url('/images/BlueRectangle.svg');
  background-position: center;
  padding: 0.2em 0.1em 0.8em;
  margin: 0 0.75em;
  width: 12em;
  height: 14.5em;
  position: relative;
  bottom: 30px;
  ${({ isAssignments }) =>  isAssignments && css `
    background-image: url('/images/RedRectangle.svg');
  `}
  ${({ isCompleted }) =>  isCompleted && css `
    pointer-events: none;
  `}

  ${({ selected }) => selected && css`
      width: 12.7em;
      height: 15.4em;
      padding: 0.4em 0.1em 0.8em;
    `}
  > div {
    > div {
      > div {
        margin-bottom: 0.2em;
        @media (max-width: 480px) {
          margin-bottom: 0.1em;
        }
      }
    }
  }

  &:hover {
    transform: scale(1.10);
    > div {
      transform: scale(1);
      > div {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 480px) {
    width: 9.5em;
    height: 11.5em;
    margin: 0 0.15px;
  }
`;

export const CardLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 11em;
  height: 10em;
  border-radius: 0.5em;
  background: #fff;
  padding: 1em;
  align-items: center;
  justify-content: space-between; 
  margin: 0 0.5em;
  box-shadow: rgba(9,30,66,0.25) 0px 0px 1px, rgba(9,30,66,0.31) 0px 0;
  transition: 200ms ease;
  cursor: pointer;
  
  &:hover {
    transform: scale(1.10);
    > div {
      transform: scale(1.05);
    }
  }

  @media (max-width: 480px) {
    width: 9em;
    height: 8em;
    padding: 0.5em;
    margin: 0 0.75em;
  }
  ${({ selected }) => selected && css`
    width: 12em;
    height: 11em;
  `}
`;

export const CardContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; 

  ${({ defaultCard }) => defaultCard && css`
    justify-content: center; 
  `}

  ${({ status }) => status === 'complete' && css`
    .chalktalk-check-success {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-bottom: -25px;
      margin-left: 40px;

      @media (max-width: 480px) {
        transform: scale(0.8);
      }
    }
  `}
`;

export const CardIconWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 0.2em;

  > div {
    transform: scale(2);
    .chalktalk-quiz .path1:before {
      background: white;
    }
    @media (max-width: 480px) {
      transform: scale(1.75);
    }
  }
  @media (max-width: 480px) {
    margin-bottom: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: 6.2em;
  padding-top: 1.5em;

  @media (max-width: 480px) {
    min-height: 5em;
  }
`;

export const CardText = styled.span`
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  font-weight: 900;
  color: black;
  -webkit-box-orient: vertical;
  word-break: break-word;
  -webkit-line-clamp: 3;

  ${({ size }) => size === 'small' && css`
  font-size: ${props => props.theme.fontSize.MediumFontSize};
  margin-bottom: 0.5em;
  -webkit-line-clamp: 1 !important;
  @media (max-width: 480px) {
    font-size: ${props => props.theme.fontSize.SmallFontSize} !important;
  }
  `}

  ${({ size }) => size === 'smaller' && css`
  font-size: ${props => props.theme.fontSize.MediumFontSize};
  font-weight: normal;
  white-space: nowrap;
  overflow: visible;
  @media (max-width: 480px) {
    font-size: ${props => props.theme.fontSize.SmallerFontSize};
  }
  `}

  ${({ activity_type }) => (activity_type) && css`
    font-family: 'MontserratLight';
  }`}
  ${({ date }) => (date) && css`
    color: #A5A5A5;
    font-size: 0.7em;
}`}
  ${({ selected }) => selected && css`
    color: #fff;
    font-size: ${props => props.theme.fontSize.DefaultFontSize} !important;
    margin-bottom: 0em;
    line-height: 4.2em;
  `}

  ${({ long }) => long && css`
    text-align: center;
    margin-top: 0.5em !important;
    -webkit-line-clamp: 4!important;
    white-space: unset !important;
  `}

  ${({ isCompleted }) => isCompleted && css`
    -webkit-line-clamp: 5 !important;
    line-height: 1.5em;

    @media (max-width: 480px) {
      font-size: 0.9em!important;
    }
  `}

  @media (max-width: 480px) {
    font-size: ${props => props.theme.fontSize.MediumFontSize};
    -webkit-line-clamp: 2;
  }
`;

export const CardListWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;

  @media (max-width: 768px) {
    > div:first-child{
      display: none;
    }

    > div:last-child {
      display: none;
    }
  }
  @media (max-width: 480px) {
    > div:nth-child(2){
      display: none;
    }
  }

  @media (max-width: 1170px) {
    transform: scale(0.9);
  }
  @media (max-width: 1080px) {
    transform: scale(0.8);
  }
  @media (max-width: 768px) {
    transform: scale(0.95);
  }
  @media (max-width: 560px) {
    transform: scale(0.8);
  }
`;

export const ScheduleButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  > button {
    background-color: #4786FC;
    border-radius: 1em;
    font-size: ${props => props.theme.fontSize.MediumFontSize};
    width: 13em;
    margin-right: 0.5em;
    position: absolute;
    bottom: 25px;
    color: white;
    &:hover {
      background-color: #4786FC;
    }
  }
  ${({ grey }) => grey && css`
  > button {
    background: grey;
    &:hover {
      background-color:  grey;
    };
  }
`}
  @media (max-width: 1170px) {
    transform: scale(0.9);
  }
  @media (max-width: 1080px) {
    transform: scale(0.8);
    > button {
      bottom: 1.5em;
    }
  }
  @media (max-width: 768px) {
    transform: scale(0.95);
    > button {
      bottom: 0;
    }
  }
  @media (max-width: 560px) {
    transform: scale(0.8);
    > button {
      bottom: 1.5em;
    }
  }

  @media (max-width: 768px) {
    > button {
      padding: 0 1em;
      margin-top: 10px;
    }
  }
  @media (max-width: 480px) {
    > button {
      width: 11.25em;
      bottom: 2em;
      font-size: ${props => props.theme.fontSize.SmallFontSize};
      margin-right: 0.75em;
    }
  }
`;
