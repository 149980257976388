import { connect } from 'react-redux';
import SchoologyLoadingPage from '../../components/SchoologyLoadingPage';
import { actions as AuthActions, selectors as AuthSelectors } from '../../reducers/auth';

const mapStateToProps = state => ({
  isAuthenticated: AuthSelectors.getAuthenticatedStatus(state),
});

const mapDispatchToProps = {
  schoologyLogin: AuthActions.schoologyLogin,
  connectSchoology: AuthActions.connectSchoology,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchoologyLoadingPage);
