import React from 'react';

type Props = {
  allowedKeys: string[];
  // eslint-disable-next-line no-unused-vars
  onKeyUp: (e: string) => void;
};

const useHotkey = ({ allowedKeys, onKeyUp }: Props) =>
  React.useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (allowedKeys.includes(e.key)) {
        onKeyUp(e.key);
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [allowedKeys, onKeyUp]);

export default useHotkey;
