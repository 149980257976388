import Typography from '@components/Atoms/Typography';
import React from 'react';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import Paper from '@components/Atoms/Paper';
import Box from '@components/Atoms/Box';
import Chip from '@components/Atoms/Chip';
import ShowIf from '@components/Atoms/ShowIf';
import { Box as MuiBox } from '@mui/material';

const contentStyles = {
  fontFamily: (theme) => theme.typography.fontFamily,
  '& strong': {
    fontWeight: 'bold',
  },
  '& em': {
    fontStyle: 'italic',
  },
  '& ul': {
    listStyle: 'circle',
    listStylePosition: 'inside',
    display: 'block',
    paddingInlineStart: '1rem',
    '& > li': {
      listStyle: 'circle',
      display: 'list-item',
      textAlign: 'start',
    },
  },
};

interface PacingGuideInfoCardProps {
  guideInfoText: string;
  taskDeliveryText: string;
}

const PacingGuideInfoCard = ({
  guideInfoText,
  taskDeliveryText,
}: PacingGuideInfoCardProps) => {
  const { 'pacing-guide-id': pacingGuideId } = useSearchParams();

  const hasPacingGuideContext = !!pacingGuideId;
  const hasContent = !!guideInfoText || !!taskDeliveryText;

  if (!hasPacingGuideContext || !hasContent) {
    return null;
  }

  return (
    <Paper
      data-testid="pacing-guide-info-card"
      sx={{ p: 2, width: '100%', mb: '1rem' }}
      elevation={1}
    >
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #e0e0e0',
            pb: 1,
            mb: 1,
          },
          !guideInfoText && {
            borderBottom: 'none',
            pb: 0,
            mb: 0,
          },
        ]}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '1rem',
            color: (theme) => theme.palette.primary.main,
          }}
        >
          Pacing Guide Instructions
        </Typography>
        <ShowIf If={!!taskDeliveryText}>
          <Chip
            label={taskDeliveryText}
            color="secondary"
            size="small"
            clickable={false}
            variant="outlined"
          />
        </ShowIf>
      </Box>

      <ShowIf If={!!guideInfoText}>
        <MuiBox sx={contentStyles} dangerouslySetInnerHTML={{ __html: guideInfoText }} />
      </ShowIf>
    </Paper>
  );
};

export default PacingGuideInfoCard;
