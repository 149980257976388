import React from 'react';
import MenuButton from '@components/Atoms/MenuButton/MenuButton';
import DownloadableWorksheet from '@components/GroupActivity/DownloadWorksheet';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useIsTeacher } from '@reducers/user/hooks';
import { useGetLessonDetail } from '../LessonContainer/hooks/query';

const WorksheetsButton = () => {
  const { data: lessonDetail } = useGetLessonDetail();
  const documents = lessonDetail?.lessons?.[0]?.documents || [];
  const studentDocuments =
    documents?.filter((document) => document.account_type === 1) || [];
  const isTeacher = useIsTeacher();
  const worksheets = isTeacher ? documents : studentDocuments;

  return (
    <MenuButton
      label="Worksheets"
      testId="worksheets"
      buttonProps={{
        variant: 'outlined',
        endIcon: <KeyboardArrowDownIcon />,
      }}
      list={worksheets.map((item) => (
        <DownloadableWorksheet item={item} key={`worksheet_${item.name}`} />
      ))}
    />
  );
};

export default WorksheetsButton;
