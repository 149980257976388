/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';

import {
  PostStudentsInviteItemWrap,
  PostStudentsInviteItem,
  PostStudentsInviteTitle,
  ButtonWrapper,
  ButtonStyled,
} from './PostStudentsInviteModal.style';

const postStudentsInviteModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    minWidth: '541px',
    maxWidth: '700px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    borderLeft: 'solid 22px #4A85E8',
    maxHeight: 'calc(100vh - 30px)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class PostStudentsInviteModal extends PureComponent {
  static propTypes = {
    isShowModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
  }

  render() {
    const { isShowModal, onCloseModal } = this.props;

    return (
      <Modal
        isOpen={isShowModal}
        onRequestClose={onCloseModal}
        style={postStudentsInviteModalStyles}
        ariaHideApp={false}
      >
        <PostStudentsInviteItemWrap>
          <PostStudentsInviteItem>
            <PostStudentsInviteTitle>
              <h1>Invites are sent successfully</h1>
              <p>Students will receive an invite link in their email.</p>
              <ButtonWrapper>
                <ButtonStyled onClick={onCloseModal}>GOT IT</ButtonStyled>
              </ButtonWrapper>
            </PostStudentsInviteTitle>
          </PostStudentsInviteItem>
        </PostStudentsInviteItemWrap>
      </Modal>
    );
  }
}
