import React from 'react';
import Button from '@components/Atoms/Button';
import Dialog from '@components/Atoms/Dialog';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary';
import LessonAttendance from '../LessonAttendance';

interface AttendanceRecordButtonProps {
  onLessonChange?: (lessonId: string | number, status) => void;
  btnLabel?: string;
  dialogTitle?: string;
  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;
}

const AttendanceRecordButton = ({
  onLessonChange,
  btnLabel = 'Record Attendance',
  dialogTitle = 'Record Attendance',
  variant = 'outlined',
  fullWidth,
  ...restProps
}: AttendanceRecordButtonProps) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant={variant}
        onClick={handleClickOpen}
        fullWidth={fullWidth}
        {...restProps}
      >
        {btnLabel}
      </Button>
      <Dialog
        sx={{ '& div': { margin: 0 }, height: '100%' }}
        contentSx={{ pb: 0 }}
        title={dialogTitle}
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        actions={[
          {
            label: 'Close',
            onClick: handleClose,
            buttonProps: {
              variant: 'contained',
              color: 'primary',
            },
          },
        ]}
      >
        <ChalkTalkErrorBoundary>
          <LessonAttendance onLessonChange={onLessonChange} />
        </ChalkTalkErrorBoundary>
      </Dialog>
    </>
  );
};

export default AttendanceRecordButton;
