import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import {
  ActionGroup,
  Button,
} from '../Generals/stylesheets/Button.style';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  TableIcon,
  TableCheckboxLabel,
  TableCheckbox,
  TableCheckMark,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import {
  ModalHeader,
  ModalCLose,
  ModalSubText,
  ModalSubTextList,
  ModalSubHeader,
} from '../Generals/stylesheets/General.style';
import { ColumnSizeStudentSelected } from './ColumSize';
import { SectionSelectWrap } from './CourseManagementSection.style';
import { FormError } from '../Generals/stylesheets/Form.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '960px',
    height: '90vh',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#f4f5f7',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const tableValues = [
  {
    uniqKey: 'studentName',
    value: 'Student Name',
  },
  {
    uniqKey: 'email',
    value: 'Email',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class MoveStudentTable extends Component {
  state = {
    tranferDataList: [],
    transferDataById: {},
    sectionSelected: null,
    sortBy: 'last_name',
    orderBy: ORDER_BY.ASC,
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    sectionList: PropTypes.array,
    selectedStudentIds: PropTypes.array,
    studentById: PropTypes.object,
    selectStudent: PropTypes.func,
    sectionId: PropTypes.any,
    sectionsStudentMove: PropTypes.func,
    moveError: PropTypes.string,
    isMoving: PropTypes.bool,
  };

  componentDidUpdate() {
    const { selectedStudentIds } = this.props;

    if (selectedStudentIds.length === 0) {
      this.onClose();
    }
  }

  componentDidMount() {
    const { selectedStudentIds } = this.props;
    const transferDataById = selectedStudentIds.reduce((prevItem, item) => {
      prevItem[item] = true; // eslint-disable-line

      return prevItem;
    }, {});

    this.setState({
      tranferDataList: selectedStudentIds,
      transferDataById,
    });
  }

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  }

  onSubmit = () => {
    const { sectionSelected, transferDataById } = this.state;
    const { selectedStudentIds, sectionId, sectionsStudentMove } = this.props;

    if (!sectionSelected || selectedStudentIds.length === 0) {
      return;
    }
    const { id } = sectionSelected;

    const payload = selectedStudentIds.map((studentId) => {
      const isTransferData = !!transferDataById[studentId];

      return {
        user: studentId,
        section: id,
        transfer: isTransferData,
      };
    });

    sectionsStudentMove(payload, sectionId, this.onClose);
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  selectSection = (item) => {
    this.setState({
      sectionSelected: item,
    });
  }

  selectTransferData = (id) => {
    const { tranferDataList, transferDataById } = this.state;

    if (!transferDataById[id]) {
      this.setState({
        transferDataById: {
          ...transferDataById,
          [id]: true,
        },
        tranferDataList: [...tranferDataList, id],
      });
    } else {
      delete transferDataById[id];
      this.setState({
        tranferDataList: [...tranferDataList.filter(itemId => itemId !== id)],
        transferDataById: { ...transferDataById },
      });
    }
  }

  renderStudentSelectedHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return (
      <TableHeader
        {...ColumnSizeStudentSelected[index + 1]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  }

  renderStudentSelectedHeader = () => (
    <TableHeadWrapper>
      <TableHeader {...ColumnSizeStudentSelected[0]} value="Transfer data" />
      {tableValues.map(this.renderStudentSelectedHeaderItem)}
      <TableHeader {...ColumnSizeStudentSelected[3]} value="Actions" />
    </TableHeadWrapper>
  );

  renderStudentSelectedItem = (student, index) => {
    const { transferDataById } = this.state;
    const { studentById, selectStudent } = this.props;
    const studentInfo = studentById[student.id];
    const firstName = _get(studentInfo, 'first_name', '');
    const lastName = _get(studentInfo, 'last_name', '');
    const isSelected = !!transferDataById[student.id];

    return (
      <TableContentItem smallSize key={index}>
        <TableContent {...ColumnSizeStudentSelected[0]}>
          <TableCheckboxLabel>
            <TableCheckbox
              type="checkbox"
              checked={isSelected}
              value={isSelected}
              onChange={() => this.selectTransferData(student.id)}
            />
            <TableCheckMark className="checkmark" />
          </TableCheckboxLabel>
        </TableContent>
        <TableContent {...ColumnSizeStudentSelected[1]}>
          {`${firstName} ${lastName}`}
        </TableContent>
        <TableContent {...ColumnSizeStudentSelected[2]}>
          {_get(studentInfo, 'email', '')}
        </TableContent>
        <TableContent {...ColumnSizeStudentSelected[3]}>
          <TableIcon
            className="chalktalk-close"
            onClick={() => selectStudent(student.id)}
          />
        </TableContent>
      </TableContentItem>
    );
  }

  renderActionButtons = () => {
    const { sectionSelected } = this.state;
    const { isMoving } = this.props;
    const isMissingError = !sectionSelected;
    const buttonLabel = isMoving ? 'In Progress' : 'Submit';

    return (
      <ActionGroup alignRight marginTop>
        <Button blue type="submit" disabled={isMissingError || isMoving} onClick={this.onSubmit}>{buttonLabel}</Button>
        <Button onClick={this.onClose}>Cancel</Button>
      </ActionGroup>
    );
  }

  render() {
    const {
      isOpen, sectionList, studentList, selectedStudentIds, moveError,
    } = this.props;
    const selectedStudents = studentList.filter(student => selectedStudentIds.includes(student.id));
    const { sectionSelected, sortBy, orderBy } = this.state;
    const isMissingError = !sectionSelected;
    const filterStudentList = _orderBy(selectedStudents, [d => {
      if (sortBy === 'last_name') return d[sortBy].toUpperCase();
      return d[sortBy];
    }], ORDER_BY[orderBy]);

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Move students to another section
          <ModalCLose className="chalktalk-close" onClick={this.onClose} />
        </ModalHeader>
        <ModalSubHeader>
          <ModalSubText>
            This will remove the students from this section and enroll them into the section you select.
          </ModalSubText>
          <ModalSubTextList>
            <p>Transfer Data</p>
            <li>Only the practice activity will be migrated.</li>
            <li>Results for any Rapid test sessions will not be moved.</li>
            <li>Attendance data will not be moved</li>
          </ModalSubTextList>
        </ModalSubHeader>
        <SectionSelectWrap>
          <Select
            onChange={this.selectSection}
            options={sectionList}
            placeholder="Select their new section"
            className="section-select-container"
            classNamePrefix="section-select"
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
          />
          {isMissingError && <FormError>Required</FormError>}
        </SectionSelectWrap>

        <TableContainerWrap>
          {this.renderStudentSelectedHeader()}
          <TableContentWrapper style={{ height: '25em', overflowY: 'auto' }}>
            {filterStudentList.map(this.renderStudentSelectedItem)}
          </TableContentWrapper>
        </TableContainerWrap>
        {moveError && <FormError center>{moveError}</FormError>}
        {this.renderActionButtons()}
      </Modal>
    );
  }
}
