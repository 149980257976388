import { connect } from 'react-redux';
import SubscriptionForm from '../../components/TeacherRegisterForm/SubscriptionForm';
import { selectors as UserSelectors } from '../../reducers/user';
import {
  selectors as AuthSelectors,
  actions as AuthActions,
} from '../../reducers/auth';
import {
  selectors as RegisterSelectors,
  actions as RegisterActions,
} from '../../reducers/register';
import {
  selectors as CourseSelectors,
  actions as CourseActions,
} from '../../reducers/courses';

const mapStateToProps = (state) => ({
  subscriptionData: UserSelectors.getSubscriptionData(state),
  errorMessage: AuthSelectors.getErrorMessage(state) || CourseSelectors.getRequestInviteError((state)),
  isShowCourseJoinRequest: RegisterSelectors.isShowCourseJoinRequest,
  isRequestedInvite: CourseSelectors.isRequestedInvite(state),
});

const mapDispatchToProps = {
  logout: AuthActions.logout,
  submitSubscriptionCode: RegisterActions.submitSubscriptionCode,
  requestInviteCourse: CourseActions.requestInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm);
