export type studentSessionType = {
  id: number;
  student_name: string;
  status: number;
  score: string;
  previous_exam_question_id: number;
  previous_choice_id: number;
  student_id: number;
  online_response_id: number;
  online_section_activity: number;
  group_id: number;
  order: number;
};
export type activityGroupType = {
  id: number;
  group_status: number;
  students: studentSessionType[];
  score: string;
  section_activity: number;
};

export type updateGroupStatusPayloadType = {
  section_activity: number;
  student_ids: number[];
};

export enum GroupStatusEnum {
  NOT_STARTED = 0,
  IN_PROGRESS_INDIVIDUAL = 1,
  IN_PROGRESS_GROUP = 2,
  FINISHED = 3,
}
