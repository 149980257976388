import React from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import PersonalInformationForm from '../../containers/TeacherRegisterForm/PersonalInformationForm';
import SubscriptionForm from '../../containers/TeacherRegisterForm/SubscriptionForm';
import CourseSetupForm from '../../containers/TeacherRegisterForm/CourseSetupForm';
import SectionCodeForm from '../../containers/TeacherRegisterForm/SectionCodeForm';
import InviteStudentForm from '../../containers/TeacherRegisterForm/InviteStudentForm';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { RegisterStepItemWrap, RegisterStepWrap, StepWrap } from '../StudentRegister/StudentRegister.style';

const stepName = [
  {
    title: 'Account setup',
  },
  {
    title: 'Subscription',
  },
  {
    title: 'Course setup',
  },
  {
    title: 'Your first section',
  },
  {
    title: 'Invite students',
  },
];

// eslint-disable-next-line react/prop-types
const Icon = ({ number }) => (
  <p className={`rcicon rcicon-${number}`}>
    {number}
    .
  </p>
);

class TeacherRegisterStep extends React.Component {
  static propTypes = {
    currentStep: PropTypes.number.isRequired,
  }

  renderRegisterForm = () => {
    const { currentStep } = this.props;
    switch (currentStep) {
      case 0:
        return <PersonalInformationForm />;
      case 1:
        return <SubscriptionForm />;
      case 2:
        return <CourseSetupForm />;
      case 3:
        return <SectionCodeForm />;
      case 4:
        return <InviteStudentForm />;
      default:
        return <PersonalInformationForm />;
    }
  }

  render() {
    const { currentStep } = this.props;

    return (
      <RegisterStepWrap>
        <StepWrap>
          <Steps current={currentStep}>
            {
              stepName.map((name, index) => (
                <Step
                  key={index} // eslint-disable-line
                  title={name.title}
                  icon={<Icon number={index + 1} />}
                />
              ))
            }
          </Steps>
        </StepWrap>

        <ShadowScrollbar
          style={{ height: 'calc(100vh - 120px)' }}
        >
          <RegisterStepItemWrap>
            {this.renderRegisterForm()}
          </RegisterStepItemWrap>
        </ShadowScrollbar>
      </RegisterStepWrap>
    );
  }
}

export default TeacherRegisterStep;
