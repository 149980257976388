import { connect } from 'react-redux';
import AnswerBubbleSingleChoice from '../../components/OfflineTestAnswerSheet/AnswerBubbleSingleChoice';
import { selectors, actions } from '../../reducers/exam';

const mapStateToProps = (state, { question }) => {
  const { id } = question;

  return {
    answer: selectors.getAnswerByQuestionId(state, id),
  };
};

const mapDispatchToProps = {
  examChooseAnswer: actions.examChooseAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerBubbleSingleChoice);
