import React, { useState, useEffect, useMemo } from 'react';
import Box from '@Atoms/Box';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ShowIf from '@Atoms/ShowIf';
import Table from '@Atoms/Table';
import Typography from '@Atoms/Typography';
import Alert from '@mui/material/Alert';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import { useUnits } from '@reducers/sectionUnit/hooks';
import Zoom from '@mui/material/Zoom';
import { useParams, Link } from 'react-router-dom';
import { formatDate } from '@utils/func-utils';
import SkillsSelector from '../LearnosityLessonReview/LessonReviewSetup/SkillsSelector';
import { SkillJsonRecord } from '../LearnosityLessonReview/LessonReviewSetup/hooks';
import { useSectionSkillLevels } from './hooks/useSkillLevels';
import NoSkillSelectedPlaceHolder from './NoSkillSelectedPlaceHolder';
import {
  LightTooltip,
  LessonRow,
} from '../DashboardStandardsReport/DashboardStandardsReport.style';
import { TableSkillLevel } from '../Table/TableComponent.style';

interface DashboardSkillsReportProps {
  subject: {
    id: string | number;
  };
}

interface CoveredLessonPopoverProps {
  lessonList: any[];
  children: React.ReactNode;
  title: string;
  subTitle: string;
  subject: any;
}

const CoveredLessonPopover = ({
  lessonList,
  title,
  subTitle,
  subject,
  children,
}: CoveredLessonPopoverProps) => {
  const { courseId, sectionId } = useParams();
  const completedLessonIds = subject?.completed_lessons?.lessons ?? [];
  const units = useUnits(subject);
  const mapLessonToUnit = React.useMemo(
    () =>
      units.reduce((acc, unit) => {
        unit.lessons.forEach((lesson) => {
          acc[lesson.id] = unit.id;
        });
        return acc;
      }, {}),
    [lessonList, units],
  );
  return (
    <LightTooltip
      leaveDelay={500}
      TransitionComponent={Zoom}
      interactive
      title={
        <Box
          display="flex"
          flexDirection="column"
          gap="1rem"
          minWidth="15rem"
          minHeight="15rem"
        >
          <Box>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="subtitle1">{subTitle}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap="0.5rem">
            {lessonList.map((lesson, index) => (
              <Link
                key={lesson?.id || index}
                to={`/lessons/course/${courseId}/section/${sectionId}/subject/${
                  subject?.id
                }/unit/${mapLessonToUnit[lesson?.id]}/lesson/${lesson?.id}?tab=${
                  LessonTabs.Lesson
                }`}
              >
                <LessonRow active={completedLessonIds?.includes(lesson?.id) || false}>
                  <i className="chalktalk-check-success" />
                  {subject?.display_name || ''}
                  ,#
                  {lesson?.name || ''}
                </LessonRow>
              </Link>
            ))}
          </Box>
        </Box>
      }
    >
      {children}
    </LightTooltip>
  );
};

const SkillLevelIndicator = ({ level }: { level: number | string }) => (
  <TableSkillLevel data-testid="skill-level-indicator" level={Number(level)}>
    {level}
  </TableSkillLevel>
);

const renderSkillLevel = (rowValue: number) => (
  <SkillLevelIndicator level={Number(rowValue)} />
);

const columns = [
  {
    id: 1,
    headerTitle: 'Student Name',
    relatedKey: 'studentName',
    defaultValue: '',
    isSortable: true,
    width: '200px',
  },
  {
    id: 2,
    headerTitle: 'Score',
    relatedKey: 'score',
    defaultValue: '--/--',
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 3,
    headerTitle: 'Skill Level',
    relatedKey: 'level',
    defaultValue: '--',
    style: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    valueGetter: renderSkillLevel,
  },
  {
    id: 4,
    headerTitle: 'Number of Sessions',
    relatedKey: 'numberOfSessions',
    defaultValue: '--',
    style: {
      textAlign: 'center',
    },
  },
  {
    id: 5,
    headerTitle: 'Last Practice Date',
    relatedKey: 'lastPractice',
    defaultValue: '--',
    valueGetter: (date: string) => (date ? formatDate(date) : '--'),
    style: {
      textAlign: 'center',
    },
  },
];
const DashboardSkillsReport = ({ subject }: DashboardSkillsReportProps) => {
  const [selectedSkill, setSelectedSkill] = useState<SkillJsonRecord | null>(null);
  const { data: skillLevels = [] } = useSectionSkillLevels(selectedSkill?.id);
  const students = useSectionStudentWithFilter();
  const completedLessonIds = subject?.completed_lessons?.lessons ?? [];
  const units = useUnits(subject);

  const skillLessons = useMemo(
    () =>
      units.reduce((acc, unit) => {
        const lessons = unit.lessons.filter((lesson) =>
          lesson.skills?.some((skill) => skill.id === selectedSkill?.id),
        );
        return [...acc, ...lessons];
      }, []),
    [units, selectedSkill],
  );

  const isAllSkillLessonsCovered = skillLessons.every((lesson) =>
    completedLessonIds.includes(lesson.id),
  );

  useEffect(() => {
    setSelectedSkill(null);
  }, [subject.id]);

  // from the student list, get the skill level for each student
  const rows = students.map((student) => {
    const studentSkillLevelRecord = skillLevels.find(
      (skill) => skill.user_id === student.id,
    );
    const score = studentSkillLevelRecord?.total_max_score
      ? `${studentSkillLevelRecord?.total_score ?? '--'}/${
          studentSkillLevelRecord?.total_max_score
        }`
      : '--/--';
    return {
      id: student.id,
      studentName: `${student.last_name} ${student.first_name}`,
      score,
      lastPractice: studentSkillLevelRecord?.last_practice,
      level: studentSkillLevelRecord?.current_level,
      numberOfSessions: studentSkillLevelRecord?.number_of_sessions,
    };
  });

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <Box display="flex" gap="1rem" flexDirection="column">
        <Box flex={1}>
          <SkillsSelector
            onChange={setSelectedSkill}
            selectedSkill={selectedSkill}
            subject={subject}
            multiple={false}
          />
        </Box>
        <Box flex={1}>
          <ShowIf If={skillLessons.length && selectedSkill}>
            <CoveredLessonPopover
              lessonList={skillLessons}
              title="Skill Lesson Coverage"
              subTitle={selectedSkill?.name || ''}
              subject={subject}
            >
              <ShowIf If={isAllSkillLessonsCovered}>
                <Alert severity="success" variant="outlined">
                  All lessons covering this skill have been taught
                </Alert>
              </ShowIf>
              <ShowIf If={!isAllSkillLessonsCovered}>
                <Alert severity="info" variant="outlined" icon={<AccessTimeIcon />}>
                  Some Lessons covering this skill have not been taught yet.
                </Alert>
              </ShowIf>
            </CoveredLessonPopover>
          </ShowIf>
        </Box>
      </Box>
      <ShowIf If={!selectedSkill}>
        <NoSkillSelectedPlaceHolder />
      </ShowIf>
      <ShowIf If={!!selectedSkill}>
        <Table rows={rows} columns={columns} hasPagination />
      </ShowIf>
    </Box>
  );
};

export default DashboardSkillsReport;
