// import { openDB } from 'idb';

// const version = 1;
// class indexDB {
//   dbName;

//   ready = false;

//   DB;

//   constructor(name) {
//     this.dbName = name;
//     this.open();
//   }

//   open() {
//     const { dbName } = this;
//     this.DB = openDB(`ck-${dbName}`, version, {
//       upgrade(db) {
//         // Create a store of objects
//         const store = db.createObjectStore(`ck-${dbName}`, {
//           // The 'id' property of the object will be the key.
//           keyPath: 'id',
//           // If it isn't explicitly set, create a value by auto incrementing.
//           autoIncrement: true,
//         });
//         store.createIndex('id', 'id');
//       },
//     });
//     this.ready = true;
//   }

//   async add(data) {
//     (await this.DB).put(
//       `ck-${this.dbName}`,
//       {
//         ...data,
//       },
//       data.id,
//     );
//   }

//   async getAll() {
//     debugger;
//     return (await this.DB).getAllKeys(`ck-${this.dbName}`);
//   }

//   async get(id) {
//     return (await this.DB).get(`ck-${this.dbName}`, id);
//   }

//   async set(id, data) {
//     return (await this.DB).put(`ck-${this.dbName}`, data, id);
//   }

//   async delete(id) {
//     return (await this.DB).delete(`ck-${this.dbName}`, id);
//   }
// }
// export default indexDB;
class LocalStorageDB {
  dbName;

  constructor(name) {
    this.dbName = name;
  }

  getStorageData() {
    return JSON.parse(localStorage.getItem(this.dbName)) || {};
  }

  add(data) {
    const storageData = this.getStorageData();
    storageData[data.id] = data;
    localStorage.setItem(this.dbName, JSON.stringify(storageData));
    return data;
  }

  getAll() {
    const storageData = this.getStorageData();
    return Object.values(storageData) || [];
  }

  get(id) {
    const storageData = this.getStorageData();
    return storageData[id];
  }

  set(data) {
    const storageData = this.getStorageData();
    storageData[data.id] = data;
    localStorage.setItem(this.dbName, JSON.stringify(storageData));
  }

  delete(id) {
    if (Array.isArray(id)) {
      id.forEach((idEle) => {
        this.delete(idEle);
      });
    }
    const storageData = this.getStorageData();
    delete storageData[id];
    localStorage.setItem(this.dbName, JSON.stringify(storageData));
  }
}
export default LocalStorageDB;
