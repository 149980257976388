import { connect } from 'react-redux';
import PracticeQuestionItem from '../../components/OnlinePracticeContent/PracticeQuestionItem';
import {
  actions as PracticeActions,
  selectors as PracticeSelectors,
} from '../../reducers/practice';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { selectors as SideBarSelectors } from '../../reducers/leftSideBar';

export const mapStateToProps = (state, {questionSelected, match}) => {
  const { id } = questionSelected;
  const subjectId = match.params.subjectId || ''

  return {
    answer: PracticeSelectors.getAnswerByQuestionId(state, id), // get answer
    isSubmittingAnswer: PracticeSelectors.isSubmittingAnswer(state),
    subjectId,
  };
};

const mapDispatchToProps = {
  chooseAnswer: PracticeActions.chooseAnswer,
  answerPracticeQuestion: PracticeActions.answerPracticeQuestion,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PracticeQuestionItem),
);
