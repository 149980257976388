import { connect } from 'react-redux';
import UnauthenticateRoute from '../../components/routes/UnauthenticateRoute';
import {
  selectors as AuthSelectors,
  actions as AuthActions,
} from '../../reducers/auth';
import {
  getUserRole,
} from '../../reducers/user';

const mapStateToProps = state => ({
  authenticated: AuthSelectors.getAuthenticatedStatus(state),
  callbackUrl: AuthSelectors.getCallbackURL(state),
  role: getUserRole(state),
  courseId: null, // getCourseId(state),
});
const mapDispatchToProps = {
  logout: AuthActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnauthenticateRoute);
