import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogProps as MuiDialogProps,
  SxProps,
  Theme,
  DialogContent as MuiDialogContent,
  CardMedia,
  Box,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import ShowIf from '../ShowIf';
import Button, { ButtonProps } from '../Button/Button';
import IconButton from '../IconButton';
import Typography from '../Typography';
import {
  closeIcon,
  closeIconWrapper,
  dialogChildrenWrapper,
  dialogContentWrapper,
  dialogHeaderStyle,
  dialogImageStyle,
  dialogSubtitleStyle,
  dialogTitleStyle,
  dialogWrapperStyle,
} from './Dialog.style';
import { sxCombine } from '../utiles';
import Alert from '../Alert';

type DialogAction = {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | any) => void;
  buttonProps?: Omit<ButtonProps, 'children' | 'onClick'>;
};
export interface DialogProps extends MuiDialogProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  title: string;
  subtitle?: string;
  showCloseIcon?: boolean;
  showActionButtons?: boolean;
  img?: string;
  actions?: Array<DialogAction>;
  contentSx?: SxProps<Theme> | any;
  dialogActionsSx?: SxProps<Theme> | any;
  helperText?: string | React.ReactNode;
}

/**
 * A wrapper component for Material-UI's `Dialog` with additional customization options.
 * @returns {React.ReactNode} The rendered `Dialog` component.
 */

const Dialog = ({
  title,
  children,
  actions,
  img,
  showCloseIcon = true,
  showActionButtons = true,
  subtitle,
  contentSx = {} as SxProps<Theme> | any,
  dialogActionsSx = {} as SxProps<Theme> | any,
  helperText,
  ...rest
}: DialogProps) => (
  <MuiDialog {...rest} sx={dialogWrapperStyle}>
    <ShowIf If={showCloseIcon}>
      <IconButton aria-label="close" onClick={rest.onClose as any} sx={closeIconWrapper}>
        <CloseIcon sx={closeIcon} />
      </IconButton>
    </ShowIf>
    <Box sx={sxCombine(dialogContentWrapper, contentSx)}>
      <ShowIf If={!!img}>
        <Box>
          <CardMedia
            component="img"
            height="280px"
            image={img}
            sx={dialogImageStyle}
            alt="dialog-img"
          />
        </Box>
      </ShowIf>
      <MuiDialogTitle sx={dialogHeaderStyle} component={Box}>
        <ShowIf If={!!title}>
          <Typography sx={dialogTitleStyle} variant="h3">
            {title}
          </Typography>
        </ShowIf>
        <ShowIf If={!!subtitle}>
          <Typography sx={dialogSubtitleStyle} variant="subtitle1">
            {subtitle}
          </Typography>
        </ShowIf>
      </MuiDialogTitle>
      <Box sx={dialogChildrenWrapper}>{children}</Box>
    </Box>
    <ShowIf If={showActionButtons}>
      <DialogActions sx={dialogActionsSx}>
        <ShowIf If={!!helperText}>
          <Alert
            data-testid="helper-text"
            severity="info"
            sx={{
              display: 'flex',
              flex: 1,
              width: '100%',
              fontSize: '0.75rem',
              backgroundColor: 'transparent',
              padding: '0',
              paddingInlineStart: '0.5rem',
            }}
          >
            {helperText}
          </Alert>
        </ShowIf>
        {(
          actions || [
            {
              label: 'Cancel',
              onClick: rest.onClose,
            },
          ]
        ).map(({ label, onClick, buttonProps = {} }) => (
          <Button key={label} onClick={onClick} {...buttonProps}>
            {label}
          </Button>
        ))}
      </DialogActions>
    </ShowIf>
  </MuiDialog>
);
export const DialogContent = MuiDialogContent;
export default Dialog;
