import styled, { css } from 'styled-components';

export const CarouselContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
overflow-x: hidden;
`;

export const CarouselWrapper = styled.div`
display: flex;
width: 75%;
position: relative;
`;

export const CarouselContentWrapper = styled.div`
overflow: hidden;
width: 100%;
height: 100%;
`;

export const CarouselContent = styled.div`
display: flex;
transition: all 250ms linear;
-ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
scrollbar-width: none;  /* hide scrollbar in Firefox */
-webkit-scrollbar {
    display: none;
}
.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(100% / 4);
}
`;

export const Arrow = styled.button`
  position: inherit;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  background-color: white;
  border: 0px;
  ${({ leftArrow }) =>
    leftArrow &&
    css`
      img {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    `}
`;
