import React from 'react';

import { useTimer, useOnTimeOut } from './Timer.hook';

/**
 * Timer component props
 */
type TimerProps = {
  /** The start time for the timer */
  startTime: Date;
  /** The duration of the timer in seconds */
  duration: number;
  /** Callback function to be executed when the timer runs out */
  onTimeOut: () => void;
};

/**
 * A simple countdown timer component
 * @param {TimerProps} props - The props for the Timer component
 * @returns {JSX.Element} The Timer component UI
 */
const Timer = ({ startTime, duration, onTimeOut }: TimerProps): JSX.Element => {
  const time = useTimer({ startTime, duration });
  useOnTimeOut({ onTimeOut, time }, [time]);
  return <span>{time}</span>;
};

export default React.memo(Timer);
