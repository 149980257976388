import styled from 'styled-components';
import { BlockWrap } from '../Generals/stylesheets/General.style';

export const CourseManagementContent = styled.div``;

export const InviteStudentWrap = styled.section`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
  @media (max-width: 1280px) {
    flex-wrap: wrap;
    margin-bottom: 0em;
  }
`;

export const StudentTableListWrap = styled.div`
  display: flex;
  flex: 0 0 100%;
  > ${BlockWrap} {
    flex: 1;
  }
`;

export const QuickInviteContainer = styled.section`
  flex: 0 0 25em;
  @media (max-width: 1280px) {
    flex: 0 0 100%;
  }
`;

export const GroupInviteContainer = styled.section`
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: ${props => props.theme.borderRadius.borderbtn};
  padding: 2.2em 1em;
  margin-right: 1em;
  flex: 0 0 calc(65% - 1em);
  .input-text{
    height: 19.5em;
  }
`;

export const TitleBlock = styled.h4`
  font-weight: 700;
  color: ${props => props.theme.colorStyled.ColorPrimary};
  margin-bottom: 1em;
`;

export const DescBlock = styled.h4`
  font-size:  ${props => props.theme.fontSize.MediumFontSize};
  font-family: 'MontserratMedium';
  color: ${props => props.theme.colorStyled.colorLightGrey};
`;
