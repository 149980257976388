/* eslint-disable import/prefer-default-export */

import { submitLessonAttendance } from '@apis/curriculum';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useUser } from '@reducers/user/hooks';
import { AxiosResponse } from 'axios';
import { LessonAttendanceResponse } from '../types';

type AttendanceRecord = {
  student: number;
  present: boolean;
};

interface LessonAttendanceBody {
  activity: string;
  records: AttendanceRecord[];
}

const updateAttendanceData = (prevAttendanceData, updatedAttendanceData) => {
  const hasPrevData = prevAttendanceData.find(
    (attendance) => attendance.student === updatedAttendanceData.student,
  );

  if (hasPrevData) {
    return prevAttendanceData.map((attendance) => {
      if (attendance.student === updatedAttendanceData.student) {
        return updatedAttendanceData;
      }
      return attendance;
    });
  }
  return prevAttendanceData.concat(updatedAttendanceData);
};

export const useSubmitLessonAttendance = () => {
  const queryClient = useQueryClient();
  const { lessonId, sectionId } = useParams();
  const currentUser = useUser();
  const response = useMutation(
    (body: LessonAttendanceBody) => {
      const updatedBody = { ...body, section: sectionId, recorded_by: currentUser.id };
      return submitLessonAttendance(lessonId, updatedBody);
    },
    {
      mutationKey: ['submitLessonAttendance', lessonId],
      retry: 3,
      retryDelay: 5 * 1000,

      onSuccess: (data: { response: AxiosResponse }) => {
        const targetQueryKey = ['getLessonAttendance', sectionId, lessonId];
        const prevAttendanceData = queryClient.getQueryData(
          targetQueryKey,
        ) as LessonAttendanceResponse[];

        if (data.response?.data?.length === 0) {
          return;
        }
        if (data.response?.data?.length === 1) {
          const updatedAttendanceData = data.response?.data[0];
          queryClient.setQueryData(
            targetQueryKey,
            updateAttendanceData(prevAttendanceData, updatedAttendanceData),
          );
        }

        if (data.response?.data?.length > 1) {
          queryClient.setQueryData(targetQueryKey, data.response?.data);
        }
      },
    },
  );
  return response;
};
