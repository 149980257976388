export const BLOOMS = ['Remember', 'Understand', 'Apply', 'Analyze', 'Evaluate'];

export const DOK = [
  'Recall',
  'Skills and Concepts',
  'Strategic Thinking',
  'Extended Thinking',
];

export const QUESTIONS = [
  {
    response_id: 'mcq_002',
    type: 'mcq',
    stimulus:
      '<p>Which of the following animals is shown in the image?</p><img src="https://cdn.britannica.com/02/152302-050-1A984FCB/African-savanna-elephant.jpg?w=300" alt="Image of an elephant" />',
    options: [
      {
        label: 'Lion',
        value: '0',
      },
      {
        label: 'Elephant',
        value: '1',
      },
      {
        label: 'Giraffe',
        value: '2',
      },
      {
        label: 'Zebra',
        value: '3',
      },
    ],
    validation: {
      scoring_type: 'exactMatch',
      valid_response: {
        score: 1,
        value: ['1'],
      },
    },
    instant_feedback: true,
    metadata: {
      difficulty: 'easy',
      subject: 'Biology',
    },
  },
  {
    response_id: '05__e2e2d5d1-8d92-4d4d-a0e5-8d7a9e8c8f16',
    type: 'mcq',
    stimulus: "Who is not a character from 'Friends'?",
    options: [
      {
        label: 'Rachel',
        value: '0',
      },
      {
        label: 'Monica',
        value: '1',
      },
      {
        label: 'Phoebe',
        value: '2',
      },
      {
        label: 'Elaine',
        value: '3',
      },
    ],
    validation: {
      scoring_type: 'exactMatch',
      valid_response: {
        score: 1,
        value: ['3'],
      },
    },
  },
  {
    response_id: '04__c5f8d1d0-87a3-4b9d-87f1-2a087ad6e4c7',
    type: 'mcq',
    is_math: true,
    stimulus: 'Sample mathjax and mathML rendering: \n\n<p>\\({3x+2}\\le8\\)</p>',
    options: [
      {
        label: '\\( -\\frac{7}{3} < x < 5 \\)',
        value: '0',
      },
      {
        label:
          '<math xmlns="http://www.w3.org/1998/Math/MathML" display="block"> <mrow data-mjx-texclass="ORD"> <mover> <mi mathvariant="normal">&#x2207;</mi> <mo stretchy="false">&#x2192;</mo> </mover> </mrow> <mo>&#xD7;</mo> <mrow data-mjx-texclass="ORD"> <mover> <mi>F</mi> <mo stretchy="false">&#x2192;</mo> </mover> </mrow> <mo>=</mo> <mrow data-mjx-texclass="INNER"> <mo data-mjx-texclass="OPEN">(</mo> <mfrac> <mrow> <mi>&#x2202;</mi> <msub> <mi>F</mi> <mi>z</mi> </msub> </mrow> <mrow> <mi>&#x2202;</mi> <mi>y</mi> </mrow> </mfrac> <mo>&#x2212;</mo> <mfrac> <mrow> <mi>&#x2202;</mi> <msub> <mi>F</mi> <mi>y</mi> </msub> </mrow> <mrow> <mi>&#x2202;</mi> <mi>z</mi> </mrow> </mfrac> <mo data-mjx-texclass="CLOSE">)</mo> </mrow> <mrow data-mjx-texclass="ORD"> <mi mathvariant="bold">i</mi> </mrow> <mo>+</mo> <mrow data-mjx-texclass="INNER"> <mo data-mjx-texclass="OPEN">(</mo> <mfrac> <mrow> <mi>&#x2202;</mi> <msub> <mi>F</mi> <mi>x</mi> </msub> </mrow> <mrow> <mi>&#x2202;</mi> <mi>z</mi> </mrow> </mfrac> <mo>&#x2212;</mo> <mfrac> <mrow> <mi>&#x2202;</mi> <msub> <mi>F</mi> <mi>z</mi> </msub> </mrow> <mrow> <mi>&#x2202;</mi> <mi>x</mi> </mrow> </mfrac> <mo data-mjx-texclass="CLOSE">)</mo> </mrow> <mrow data-mjx-texclass="ORD"> <mi mathvariant="bold">j</mi> </mrow>',
        value: '1',
      },
      {
        label: '\\( f(a) = \\frac{1}{2\\pi i} \\oint\\frac{f(z)}{z-a}dz \\)',
        value: '2',
      },
      {
        label: '1',
        value: '3',
      },
    ],
    validation: {
      scoring_type: 'exactMatch',
      valid_response: {
        score: 1,
        value: ['2'],
      },
    },
  },
  {
    response_id: '03__b3a4d6d2-5f27-4b91-9a5b-1b6b5dfc9f72',
    type: 'mcq',
    stimulus:
      'Which of the following is not a programming language?<span class="learnosity-feature" data-alt="A 180-degree standard protractor." data-button="true" data-buttonicon="protractor" data-image="protractor" data-label="Protractor" data-simplefeature_id="9c019ac8-c704-454a-bce2-619e2ec312fa" data-type="imagetool"></span>',
    options: [
      {
        label: 'Python',
        value: '0',
      },
      {
        label: 'Java',
        value: '1',
      },
      {
        label: 'HTML',
        value: '2',
      },
      {
        label: 'Excel',
        value: '3',
      },
    ],
    validation: {
      scoring_type: 'exactMatch',
      valid_response: {
        score: 1,
        value: ['3'],
      },
    },
  },
];

export const SUBJECTS = [
  {
    id: 165,
    display_name: 'Florida Algebra 1',
    slug: 'Algebra_1',
  },
  {
    id: 167,
    display_name: 'Florida English 9',
    slug: 'English_9',
  },
  {
    id: 170,
    display_name: 'Florida English 10',
    slug: 'English_10',
  },
  {
    id: 158,
    display_name: 'Texas Algebra 1',
    slug: 'Algebra_1',
  },
  {
    id: 160,
    display_name: 'Texas English 1',
    slug: 'English_1',
  },
  {
    id: 163,
    display_name: 'Texas English 2',
    slug: 'English_10',
  },
];

export const MATH_DEMO_STANDARD = {
  id: 7561,
  code: 'MA.912.NSO.1.4',
  description:
    'Apply previous understanding of operations with rational numbers to add, subtract, multiply and divide numerical radicals.',
  label:
    'MA.912.NSO.1.4: Apply previous understanding of operations with rational numbers to add, subtract, multiply and divide numerical radicals.',
  categoryName: 'MA.912.NSO',
};

export const ENGLISH_DEMO_STANDARD = {
  id: 7770,
  code: 'ELA.9.V.1.3',
  description:
    'Apply knowledge of context clues, figurative language, word relationships, reference materials, and/or background knowledge to determine the connotative and denotative meaning of words and phrases, appropriate to grade level.',
  label:
    'ELA.9.V.1.3: Apply knowledge of context clues, figurative language, word relationships, reference materials, and/or background knowledge to determine the connotative and denotative meaning of words and phrases, appropriate to grade level.',
  categoryName: 'ELA.9.V',
};

export const GENERATION_STATUS = {
  GENERATING: {
    status: 'GENERATING',
    label: 'Generating...',
  },
  VALIDATING: {
    status: 'VALIDATING',
    label: 'Validating...',
  },
  GENERATING_DISTRACTORS: {
    status: 'GENERATING_DISTRACTORS',
    label: 'Generating Distractors...',
  },
  GENERATED: {
    status: 'GENERATED',
    label: 'Generated',
  },
  FAILED: {
    status: 'FAILED',
    label: 'Failed',
  },
};
