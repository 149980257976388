import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';
import Button from '../Button';
import Box from '../Box';

export type itemsObjectType = {
  /** - The label of the item. */
  label: string;
  /** - The value of the item. */
  value: string | number;
};

export interface SelectProps extends MuiSelectProps {
  /** - The list of items for the Select component. */
  list: Array<itemsObjectType>;
  /**  - The label for the Select component. */
  label: string;
  /**  - The id for the input id and label. */
  id: string;
  /** - The Size of select component. */
  size: 'small' | 'medium';
  /** - The default selected value for the Select component. */
  defaultValue?: string | number;
  /**  - Flag to show empty menuItem at menu list */
  showEmptyItem?: boolean;
  /** - The callback function to be executed when the selection changes. */
  onChangeHandler?: (arg: string | number | object) => void;
  fullWidth?: MuiSelectProps['fullWidth'];
  sx?: SxProps<Theme>;
  selectSx?: SxProps<Theme>;
  value?: string | number;
  emptyItemLabel?: string;
  onMoreItemsButtonClick?: () => void;
  showMoreItemsButton?: boolean;
  /** - If true, emptyItemLabel is displayed even if no items are selected. */
  displayEmpty?: boolean;
}

/** A custom Select component. */

const Select = ({
  list,
  label,
  id = '',
  size = 'medium',
  defaultValue,
  showEmptyItem = false,
  onChangeHandler,
  fullWidth = false,
  emptyItemLabel = 'None',
  sx,
  selectSx,
  value,
  onMoreItemsButtonClick,
  showMoreItemsButton = false,
  displayEmpty,
  ...rest
}: SelectProps) => {
  const [state, setState] = React.useState(defaultValue || '');

  const handleChange = (event: SelectChangeEvent) => {
    setState(event.target.value);
    onChangeHandler?.(event.target.value);
  };
  if (!id) {
    console.error('select component required id');
  }
  return (
    <FormControl
      fullWidth={fullWidth}
      sx={[{ mt: 1 }, (sx as SxProps<Theme> | any) && sx]}
      size={size}
    >
      <InputLabel shrink={displayEmpty} id={`${id}-label`}>{label}</InputLabel>
      <MuiSelect
        label={label}
        notched={displayEmpty}
        value={value || state}
        labelId={`${id}-label`}
        id={`${id}-inputId`}
        defaultValue={defaultValue}
        onChange={handleChange}
        sx={selectSx}
        displayEmpty={displayEmpty}
        {...rest}
      >
        {showEmptyItem && (
          <MenuItem value="">
            <em>{emptyItemLabel}</em>
          </MenuItem>
        )}
        {(list || []).map((item: itemsObjectType) => (
          <MenuItem value={item.value} key={item.label}>
            {item.label}
          </MenuItem>
        ))}
        {showMoreItemsButton && (
          <Box sx={{ display: 'flex', justifyContent: 'center', px: '16px' }}>
            <Button variant="text" size="small" sx={{ width: '100%' }} onClick={() => onMoreItemsButtonClick?.()}>
              Explore More
            </Button>
          </Box>
        )}
      </MuiSelect>
    </FormControl>
  );
};
export default Select;
