/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _isArray from 'lodash/isArray';
import FormInput from '../Generals/FormInput/FormInput';
import {
  LoginItemWrap,
  LoginItem,
  LoginTitle,
  LoginPasswordAction,
  ButtonStyled,
  SpacingLine,
  LoginLinkGroupWrapper,
  LoginErrorMessage,
  HelpText,
} from './Login.style';

import { Paragraph, Title } from './SignUpType.style';

const CLIENT_ID = process.env.CLIENT_ID;
const CLASSLINK_CLIENT_ID = process.env.CLASSLINK_CLIENT_ID;

const REDIRECT_URI = `${window.location.origin}/auth/complete/clever/`;
const CLEVER_LOGIN_URL = `https://clever.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
const CLASSLINK_REDIRECT_URI = `${window.location.origin}/auth/complete/classlink/`;
const CLASSLINK_LOGIN_URL = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${CLASSLINK_REDIRECT_URI}&client_id=${CLASSLINK_CLIENT_ID}&response_type=code`;

const validationSchema = Yup.object().shape({
  email: Yup.string().trim().required('Invalid email'),
  password: Yup.string()
    .trim()
    // .notOneOf(['123456', 123456], 'The password is not 123456')
    // .min(8, 'The password is greater than 8 characters')
    // .matches(/[0-9]/, 'The password contains at least one number')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export default class StudentLoginForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
    };
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.any,
    noTypeLogin: PropTypes.func,
  };

  toggleShowPassword = (isShowPassword) => {
    this.setState({
      isShowPassword,
    });
  };

  login = (values) => {
    const { email, password } = values;
    const { noTypeLogin } = this.props;
    noTypeLogin(email, password);
  };

  renderPasswordAction = () => (
    <LoginPasswordAction>
      <label>
        <input type="checkbox" />
        <span className="checkmark" />
        <span>Remember me</span>
      </label>
      <Link to="/forgot-password">Forgot your password ?</Link>
    </LoginPasswordAction>
  );

  renderLoginBtn = () => {
    const { isLoading } = this.props;
    return isLoading ? (
      <ButtonStyled primary>Loading ...</ButtonStyled>
    ) : (
      <ButtonStyled type="submit">Log In</ButtonStyled>
    );
  };

  renderLinkGroup = () => (
    <LoginLinkGroupWrapper is_clever>
      <a href={CLEVER_LOGIN_URL} label="CLEVER" />
    </LoginLinkGroupWrapper>
  );

  renderClassLinkLinkGroup = () => (
    <LoginLinkGroupWrapper is_clever={false}>
      <a
        label="CLASSLINK"
        href={CLASSLINK_LOGIN_URL}
      />
    </LoginLinkGroupWrapper>
  )

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>;

  renderErrorList = (errorList) => {
    if (!errorList) return null;
    if (_isArray(errorList)) {
      return (
        <LoginErrorMessage>
          {errorList.map(this.renderErrorMessage)}
        </LoginErrorMessage>
      );
    }
    return (
      <LoginErrorMessage>
        {this.renderErrorMessage(errorList, 1)}
      </LoginErrorMessage>
    );
  };

  render() {
    const { isLoading, errorMessage } = this.props;
    const { isShowPassword } = this.state;

    return (
      <LoginItemWrap>
        <LoginItem>
          <LoginTitle>
            <Title>Log In to ChalkTalk</Title>
          </LoginTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.login}
          >
            <Form noValidate>
              <FormInput style={{ fontWeight: 'bold' }} type="email" name="email" label="Email" />
              <FormInput
                type={isShowPassword ? 'text' : 'password'}
                name="password"
                label="Password"
              />
              {this.renderPasswordAction()}
              {this.renderErrorList(errorMessage)}
              {this.renderLoginBtn()}
            </Form>
          </Formik>
          {isLoading ? (
            <ButtonStyled primary>Loading ...</ButtonStyled>
          ) : (
            <Link to="/register/type">
              <ButtonStyled style={{ fontWeight: 'Semi bold' }} type="button" primary>
                Create account
              </ButtonStyled>
            </Link>
          )}
          <hr
            style={{
              boxShadow: 'none',
              background: '#ccc',
              border: 'none',
              height: '1px',
            }}
          />
          {this.renderLinkGroup()}
          {this.renderClassLinkLinkGroup()}
          <HelpText>
            If you are experiencing issues logging in please visit our{' '}
            <a href="https://helpdesk.chalktalk.com/">helpdesk</a> or send an email to{' '}
            <a href="mailto:support@chalktalk.com">support@chalktalk.com</a>.
          </HelpText>
        </LoginItem>
      </LoginItemWrap>
    );
  }
}
