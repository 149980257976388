export const colData = [
  [
    {
      text: '',
      value: '_',
    },
    {
      text: '.',
      value: '.',
    },
    {
      text: '',
      value: '_',
    },
    {
      text: '1',
      value: '1',
    },
    {
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    {
      text: '4',
      value: '4',
    },
    {
      text: '5',
      value: '5',
    },
    {
      text: '6',
      value: '6',
    },
    {
      text: '7',
      value: '7',
    },
    {
      text: '8',
      value: '8',
    },
    {
      text: '9',
      value: '9',
    },
  ],
  [
    {
      text: '/',
      value: '/',
    },
    {
      text: '.',
      value: '.',
    },
    {
      text: '0',
      value: '0',
    },
    {
      text: '1',
      value: '1',
    },
    {
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    {
      text: '4',
      value: '4',
    },
    {
      text: '5',
      value: '5',
    },
    {
      text: '6',
      value: '6',
    },
    {
      text: '7',
      value: '7',
    },
    {
      text: '8',
      value: '8',
    },
    {
      text: '9',
      value: '9',
    },
  ],
  [
    {
      text: '/',
      value: '/',
    },
    {
      text: '.',
      value: '.',
    },
    {
      text: '0',
      value: '0',
    },
    {
      text: '1',
      value: '1',
    },
    {
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    {
      text: '4',
      value: '4',
    },
    {
      text: '5',
      value: '5',
    },
    {
      text: '6',
      value: '6',
    },
    {
      text: '7',
      value: '7',
    },
    {
      text: '8',
      value: '8',
    },
    {
      text: '9',
      value: '9',
    },
  ],
  [
    {
      text: '',
      value: '_',
    },
    {
      text: '.',
      value: '.',
    },
    {
      text: '0',
      value: '0',
    },
    {
      text: '1',
      value: '1',
    },
    {
      text: '2',
      value: '2',
    },
    {
      text: '3',
      value: '3',
    },
    {
      text: '4',
      value: '4',
    },
    {
      text: '5',
      value: '5',
    },
    {
      text: '6',
      value: '6',
    },
    {
      text: '7',
      value: '7',
    },
    {
      text: '8',
      value: '8',
    },
    {
      text: '9',
      value: '9',
    },
  ],
];
