import React from 'react';
import AutoComplete from '@components/Atoms/AutoComplete';
import TextField from '@components/Atoms/TextField';
import { useCurrentSubject } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useGetSkillsBySubject } from '@apis/questiongenerationservice';
import Chip from '@mui/material/Chip';
import { SkillRecord } from '@components/Component/Lesson/LessonContainer/Types';
import { SkillJsonRecord, useJsonSkills } from './hooks';


// TODO: This component will be replaced with the GenericSkillSelector component

type CategorizedSkillRecord = SkillRecord & {
  categoryName: string;
  label: string;
};

export const useCategorizedSkills = (subject, skills) => {
  // Fetch the JSON skills associated with the selected subject
  const { data: skillsMetadata = [] } = useJsonSkills(subject);

  return React.useMemo(
    () =>
      skills
        .map((skill) => {
          const subjectPrefix = subject ? `${subject?.display_name} --> ` : '';
          const skillCategory = skillsMetadata?.find(
            (metadata) => metadata.skillName === skill.name,
          )?.categoryName;
          const categoryPrefix = skillCategory ? `${skillCategory} --> ` : '';

          return {
            ...skill,
            categoryName: skillCategory,
            subjectName: subject?.display_name || '',
            label: `${subjectPrefix}${categoryPrefix}${skill.name}`,
          };
        })
        .sort((a, b) => -(b.categoryName || '').localeCompare(a.categoryName || '')),
    [skills, subject, skillsMetadata],
  );
};

export const useSubjectSkills = (subject) => {
  const fallbackSubject = useCurrentSubject();
  const currentSubject = subject || fallbackSubject;
  const { data: skills = [] } = useGetSkillsBySubject(currentSubject.id);
  const mappedSkills = useCategorizedSkills(currentSubject, skills);

  const affectedSkills = React.useMemo(
    () =>
      mappedSkills.sort(
        (a: CategorizedSkillRecord, b: CategorizedSkillRecord) =>
          -(b.categoryName || '').localeCompare(a.categoryName || ''),
      ),
    [mappedSkills],
  );

  return affectedSkills;
};

const wordsSearch = (options, params) => {
  const words = params.inputValue
    .trim()
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .split(/\s+/);
  // Create a regex pattern that looks for all words in any order
  const regexPattern = words.map((word) => `(?=.*${word})`).join('');
  const regex = new RegExp(regexPattern, 'i');

  return options.filter((option) => regex.test(option.label));
};

type Props = {
  onChange?: (value: CategorizedSkillRecord[]) => void;
  selectedSkills?: SkillRecord[];
  selectedSkill?: SkillJsonRecord | CategorizedSkillRecord | null;
  subject: unknown;
  multiple?: boolean;
  size?: 'medium' | 'small';
  isDisabled?: boolean;
};

const SkillsSelector = ({
  onChange,
  selectedSkills = [],
  selectedSkill,
  subject,
  multiple = true,
  size = 'medium' as 'medium' | 'small',
  isDisabled = false,
}: Props) => {
  const options = useSubjectSkills(subject);
  const categorizedSelectedSkills = useCategorizedSkills(subject, selectedSkills);

  return (
    <AutoComplete
      options={options}
      getOptionLabel={(option: CategorizedSkillRecord) => option.label || ''}
      multiple={multiple}
      title="Skills"
      disabled={isDisabled}
      filterOptions={wordsSearch}
      onChange={(e, newSelection) => {
        if (onChange) {
          onChange(newSelection);
        }
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      size={size}
      groupBy={(option: CategorizedSkillRecord) => option.categoryName || ''}
      value={selectedSkill || categorizedSelectedSkills}
      renderTags={(value, getTagProps) =>
        value.map((option: CategorizedSkillRecord, index) => (
          <Chip
            {...getTagProps({ index })}
            key={option.id}
            label={option.name}
            title={option.label}
            sx={{
              maxWidth: '30rem',
            }}
          />
        ))
      }
      renderOption={(props, item) => (
        <li {...props} key={item.id}>
          {item.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          label="Skills"
          variant={undefined}
          onChange={undefined}
          value={undefined}
          {...params}
          placeholder="Select Skill ..."
          InputProps={{
            ...params.InputProps,
            style: { whiteSpace: 'nowrap' },
          }}
        />
      )}
    />
  );
};

export default SkillsSelector;
