import { Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useStandardSelector } from '@components/GenericStandardSelector/hooks';
import { toast } from 'react-toastify';
import { buildRemoveToastMessage } from '../utils';

interface StandardState {
  selectedStandards: number[];
  manualSelectedStandards: number[];
  removedStandards: number[];
  priorityStandards: object;
  recommendedStandards: object;
}

const initialState: StandardState = {
  selectedStandards: [],
  manualSelectedStandards: [],
  removedStandards: [],
  priorityStandards: {},
  recommendedStandards: {},
};

type StandardAction =
  | { type: 'SET_SELECTED_STANDARDS'; payload: number[] }
  | { type: 'SET_MANUAL_SELECTED_STANDARDS'; payload: number[] }
  | { type: 'SET_REMOVED_STANDARDS'; payload: number[] }
  | { type: 'SET_RECOMMENDED_STANDARDS'; payload: object }
  | { type: 'SET_PRIORITY_STANDARDS'; payload: object };

const standardsReducer: Reducer<StandardState, StandardAction> = (
  state,
  action,
): StandardState => {
  switch (action.type) {
    case 'SET_SELECTED_STANDARDS':
      return { ...state, selectedStandards: action.payload };
    case 'SET_REMOVED_STANDARDS':
      return { ...state, removedStandards: action.payload };
    case 'SET_PRIORITY_STANDARDS':
      return { ...state, priorityStandards: action.payload };
    case 'SET_RECOMMENDED_STANDARDS':
      return { ...state, recommendedStandards: action.payload };
    case 'SET_MANUAL_SELECTED_STANDARDS':
      return { ...state, manualSelectedStandards: action.payload };
    default:
      return state;
  }
};

const useStandardReducer = (subjects) => {
  const [standardState, standardDispatch] = useReducer(standardsReducer, initialState);
  const [prevPriorityStandards, setPrevPriorityStandards] = useState({});
  const { standardMap, standardTree } = useStandardSelector(subjects);
  // disable the next time a toast is shown, for when doing a full reset
  const [disableToast, setDisableToast] = useState(false);

  const setSelectedStandards = (standards: number[]) => {
    standardDispatch({ type: 'SET_SELECTED_STANDARDS', payload: standards });
  };

  const setManuallySelectedStandards = (standards: number[]) => {
    standardDispatch({ type: 'SET_MANUAL_SELECTED_STANDARDS', payload: standards });
  };

  const setRecommendedStandards = (standards: object) => {
    standardDispatch({ type: 'SET_RECOMMENDED_STANDARDS', payload: standards });
  };

  const setPriorityStandards = (standards: object) => {
    standardDispatch({ type: 'SET_PRIORITY_STANDARDS', payload: standards });
  };

  const setRemovedStandards = (standards: number[]) => {
    standardDispatch({ type: 'SET_REMOVED_STANDARDS', payload: standards });
  };

  const addPriorityStandard = (standardId, lessonNames) => {
    setPriorityStandards({
      ...standardState.priorityStandards,
      [standardId]: lessonNames,
    });
  };

  const getStandardById = useCallback(
    (id) => {
      if (id in standardMap) {
        return standardMap[id];
      }
      return null;
    },
    [standardMap],
  );

  const skillStandardAlignmentMap = useMemo(() => {
    const skillMap = {};
    Object.values(standardMap).forEach((standard) => {
      standard.skills.forEach((skillId) => {
        if (!(skillId in skillMap)) {
          skillMap[skillId] = new Set();
        }
        skillMap[skillId].add(standard.id);
      });
    });
    return skillMap;
  }, [standardMap]);

  const getSelectedStandardObjects = () =>
    standardState.selectedStandards.map((id) => getStandardById(id)).filter(Boolean);

  const getStandardTree = () => standardTree;

  // when priority standards change, update the selected standards
  useEffect(() => {
    const previousPrioIds = Object.keys(prevPriorityStandards).map(Number);
    const currentPrioIds = Object.keys(standardState.priorityStandards).map(Number);
    const removedIds = previousPrioIds.filter(
      (id) =>
        !currentPrioIds.includes(id) &&
        !standardState.manualSelectedStandards.includes(id),
    );
    if (removedIds.length && !disableToast) {
      const removedObjects = removedIds.map((id) => getStandardById(id)).filter(Boolean);
      removedObjects.forEach((standard, index) => {
        setTimeout(() => {
          toast.info(
            buildRemoveToastMessage(
              'Standard',
              standard.code,
              'Lessons',
              prevPriorityStandards[standard.id],
              'white',
            ),
            {
              autoClose: 5000,
            },
          );
        }, index * 100); // Small delay to prevent suppression
      });
    }

    if (disableToast) {
      setDisableToast(false);
    }

    setPrevPriorityStandards(standardState.priorityStandards);
    const unselectedManualStandards = standardState.manualSelectedStandards.filter(
      (id) => !currentPrioIds.includes(id),
    );
    // we're tracking removed standards so they dont get re-selected
    const unRemovedPriorityStandards = currentPrioIds.filter(
      (id) => !standardState.removedStandards.includes(id),
    );

    setSelectedStandards([...unRemovedPriorityStandards, ...unselectedManualStandards]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [standardState.priorityStandards]);

  return {
    standardState,
    skillStandardAlignmentMap,
    setRemovedStandards,
    standardDispatch,
    setSelectedStandards,
    setRecommendedStandards,
    setManuallySelectedStandards,
    setPriorityStandards,
    getStandardTree,
    getStandardById,
    getSelectedStandardObjects,
    setDisableToast,
    addPriorityStandard,
  };
};

export default useStandardReducer;
