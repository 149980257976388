import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import TeacherLessonReviewPage from '../../components/LessonReviewPage/TeacherLessonReviewPage';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as activiySelectors } from '../../reducers/activity';
import { selectors as curriculumLessonSelectors } from '../../reducers/curriculumLessons';
import { selectors as sectionReviewSelectors } from '../../reducers/sectionReviews';
import { selectors as sectionSubjectSelectors } from '../../reducers/sectionSubjects';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { selectors as SectionSelectors } from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const courseId = _get(match, 'params.courseId', '');
  const reviewId = _get(match, 'params.reviewId', '');
  const subjectId = _get(match, 'params.subjectId', '');
  const reviewDetail = sectionReviewSelectors.getReviewDetailById(state, reviewId);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    sectionId,
    courseId,
    reviewId,
    subjectId,
    reviewDetail,
    courseDetail,
    sectionDetail,
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    subject: sectionSubjectSelectors.getSubjectDetail(state, subjectId),
    students: sectionStudentSelectors.getStudentListWithFilter(state, sectionId),
    reviewByUserId: activiySelectors.getReviewByUserId(state),
    lessonById: curriculumLessonSelectors.getLessonById(state),
    maxColumns: activiySelectors.getMaxColumns(state),
    isGetSessionData: activiySelectors.getIsGetSessionData(state),
  };
};

export default withRouter(connect(mapStateToProps)(TeacherLessonReviewPage));
