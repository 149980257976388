import { UpcomingEmpty } from '@components/Generals/stylesheets/General.style';
import React, { Suspense } from 'react';
import {
  NavBarList,
} from '@components/SubNavBarItem/SubNavBar.style';
import { formatDate } from '@utils/func-utils';
import _orderBy from 'lodash/orderBy';
import Skeleton from '@Atoms/Skeleton';
import { ExamScoreUpcomingWrap } from './DashboardExamScoreReport.style';
import { ExamItem, useExamSessions } from './hooks';
import TestSubNavItem from '../SubNavBarItem/TestSubNavItem';
import TestSubNavItemV2 from '../SubNavBarItem/TestSubNavItemV2';

type UpcomingExamsProps = {
  hasExamV2Access: boolean
};
const useUpComingExams = (exams?: Array<ExamItem>) => {
  // chalktalk-react/reducers/sectionExam/sectionExam.js:isUpcomingExam
  const latestExam = React.useMemo(() => {
    if (!exams) {
      return {} as ExamItem;
    }
    const examSessionLength = exams.length;

    if (examSessionLength === 0) {
      return {} as ExamItem;
    }

    const today = formatDate();
    const endDateSort = _orderBy(exams, 'end_date', 'asc');
    const upComingExam = endDateSort.find(({ end_date }) => end_date >= today);

    if (!upComingExam) {
      return exams[examSessionLength - 1];
    }

    return upComingExam;
  }, [exams]);
  return latestExam;
};

const UpcomingExams = ({hasExamV2Access}: UpcomingExamsProps) => {
  const { data: sectionExams } = useExamSessions();
  const examItem = useUpComingExams(sectionExams);
  //  TODO: fix the type of examItem and the destructuring
  const {
    id,
    practice_test_order: practiceTestOrder,
  } = examItem;
  const previousExam = sectionExams.find(exam => exam.practice_test_order === practiceTestOrder-1);
  // const hasExamV2Access = true
  if (!Object.keys(examItem).length) {
    return <UpcomingEmpty>{hasExamV2Access ? 'No upcoming testlets' : 'No upcoming exams'} </UpcomingEmpty>;
  }
  return (
    <ExamScoreUpcomingWrap>
      <span>{hasExamV2Access ? 'Upcoming Testlets' : 'Upcoming Exams'}</span>
      <NavBarList key={id.toString()}>
        {hasExamV2Access ? (<TestSubNavItemV2 examItem={examItem} previousExam={previousExam} />) : (<TestSubNavItem examItem={examItem} />)}

      </NavBarList>
    </ExamScoreUpcomingWrap>
  );
};
const UpcomingExamsWrapper = ({hasExamV2Access}: UpcomingExamsProps) => (
  <Suspense fallback={<Skeleton sx={{ height: '8rem' }} />}>
    <UpcomingExams hasExamV2Access={hasExamV2Access}/>
  </Suspense>
);

export default UpcomingExamsWrapper;
