import { REHYDRATE } from 'redux-persist';
import { AUTH_LOGIN_SUCCESS } from '../auth';
import { REGISTER_SUBMIT_SUBSCRIPTION_SUCCESS } from '../register';

export const USER_UPDATE_SCHOOL_INFO = 'user/USER_UPDATE_SCHOOL_INFO';

export const SUBMIT_USER_EDIT = 'user/SUBMIT_USER_EDIT';
export const SUBMIT_USER_EDIT_SUCCESS = 'user/SUBMIT_USER_EDIT_SUCCESS';
export const SUBMIT_USER_EDIT_FAILED = 'user/SUBMIT_USER_EDIT_FAILED';

export const USER_CHANGE_PASSWORD = 'user/CHANGE_PASSWORD';
export const USER_CHANGE_PASSWORD_SUCCESS = 'user/CHANGE_PASSWORD_SUCCESS';
export const USER_CHANGE_PASSWORD_FAILED = 'user/CHANGE_PASSWORD_FAILED';

export const USER_REPORT_ISSUE = 'user/REPORT_ISSUE';
export const USER_REPORT_ISSUE_SUCCESS = 'user/REPORT_ISSUE_SUCCESS';
export const USER_REPORT_ISSUE_FAIL = 'user/REPORT_ISSUE_FAIL';

export const UPDATE_USER_DATA = 'user/UPDATE_USER_DATA';
// ==========================================

const userReportIssue = (questionId, contentType, content, callback) => ({
  type: USER_REPORT_ISSUE,
  payload: {
    content,
    content_type: contentType,
    content_object_id: questionId,
  },
  callback,
});

const userReportIssueSuccess = () => ({
  type: USER_REPORT_ISSUE_SUCCESS,
});

const userReportIssueFail = () => ({
  type: USER_REPORT_ISSUE_FAIL,
});

export const updateSchoolInfo = schoolInfo => ({
  type: USER_UPDATE_SCHOOL_INFO,
  payload: {
    schoolInfo,
  },
});

export const updateUserData = (email) => ({
  type: UPDATE_USER_DATA,
  payload: {
    email,
  },
});

export const submitUserEdit = (values) => ({
  type: SUBMIT_USER_EDIT,
  values,
});

export const submitUserEditSuccess = (user) => ({
  type: SUBMIT_USER_EDIT_SUCCESS,
  user,
});

export const submitUserEditFailed = () => ({
  type: SUBMIT_USER_EDIT_FAILED,
});

export const changePassword = (oldPass, newPass1, newPass2) => ({
  type: USER_CHANGE_PASSWORD,
  oldPass,
  newPass1,
  newPass2,
});

export const changePasswordSuccess = () => ({
  type: USER_CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailed = error => ({
  type: USER_CHANGE_PASSWORD_FAILED,
  error,
});

export const actions = {
  updateSchoolInfo,

  submitUserEdit,
  submitUserEditSuccess,
  submitUserEditFailed,

  changePassword,
  changePasswordSuccess,
  changePasswordFailed,

  userReportIssue,
  userReportIssueSuccess,
  userReportIssueFail,

  updateUserData,
};

// selectors
export const getCurrentUser = ({ user }) => user.userData || {};
export const getUserId = ({ user }) => (user.userData || {}).id;
export const getUserRole = ({ user }) => (user.userData || {}).role;
export const getUserEmail = ({ user }) => user.userData.email;
export const getIsDistrictAdmin = ({ user }) => user.userData.is_district_admin;
export const getSubscriptionData = ({ user }) => user.subscriptionData;
export const getSchoolInfo = ({ user }) => user.schoolInfo;

export const isChangingPassword = ({ user }) => user.isChangingPassword;
export const isChangedPassword = ({ user }) => user.isChangedPassword;
export const getChangingPasswordError = ({ user }) => user.changingPasswordError;

export const isEditingProfile = ({ user }) => user.isEditingProfile;
export const isEditedProfile = ({ user }) => user.isEditedProfile;
export const getEditProfileError = ({ user }) => user.editProfileError;

export const selectors = {
  getCurrentUser,
  getUserId,
  getUserRole,
  getUserEmail,
  getSubscriptionData,
  getSchoolInfo,

  isEditingProfile,
  isEditedProfile,
  getEditProfileError,

  isChangingPassword,
  isChangedPassword,
  getChangingPasswordError,
};

const initialState = {
  userData: null,
  errorMsg: null,
  isLoading: null,
  subscriptionData: null,
  schoolInfo: null,

  isEditingProfile: false,
  isEditedProfile: false,
  editProfileError: '',

  isChangingPassword: false,
  isChangedPassword: false,
  changingPasswordError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...state,
        ...((action.payload || {}).user || {}),
        subscriptionData: null,
        isChangingPassword: false,
        isChangedPassword: false,
        isEditingProfile: false,
        isEditedProfile: false,
        changingPasswordError: '',
      };
    }
    case AUTH_LOGIN_SUCCESS: {
      const { user: userData } = action.payload;
      return {
        ...state,
        userData,
      };
    }

    case UPDATE_USER_DATA: {
      const { userData } = state;
      const { email } = action.payload;
      userData.email = email
      return {
        ...state,
        userData,
      };
    }

    case REGISTER_SUBMIT_SUBSCRIPTION_SUCCESS: {
      const { subscriptionData } = action.payload;
      return {
        ...state,
        subscriptionData,
      };
    }

    case USER_UPDATE_SCHOOL_INFO: {
      const { schoolInfo } = action.payload;

      return {
        ...state,
        schoolInfo,
      };
    }

    case SUBMIT_USER_EDIT: {
      return {
        ...state,
        isEditingProfile: true,
        isEditedProfile: false,
      };
    }

    case SUBMIT_USER_EDIT_SUCCESS: {
      const { user: userData } = action;

      return {
        ...state,
        userData,
        isEditingProfile: false,
        isEditedProfile: true,
      };
    }

    case SUBMIT_USER_EDIT_FAILED: {
      const { error } = action;

      return {
        ...state,
        isEditingProfile: false,
        editProfileError: error,
      };
    }

    case USER_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangingPassword: true,
        isChangedPassword: false,
        changingPasswordError: '',
      };
    }

    case USER_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangingPassword: false,
        isChangedPassword: true,
      };
    }

    case USER_CHANGE_PASSWORD_FAILED: {
      const { error } = action;

      return {
        ...state,
        isChangingPassword: false,
        changingPasswordError: error,
      };
    }

    default:
      return state;
  }
}
