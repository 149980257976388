import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';

import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { useSearchParams } from '@utils/hooks/useSearchParams';

const usePracticeGoBack = () => {
  const history = useHistory();
  const { 'pacing-guide-id': pacingGuideId } = useSearchParams();
  const { courseId, sectionId, subjectId, unitId, lessonId } = useParams();
  const goBack = React.useCallback(
    (
      { showModal } = {
        showModal: false,
      },
    ) => {
      // SetTimeout added to ensure that react-query invalidate the data before page redirection happen.
      // To guarantee that page redirection is the final action to be executed.
      setTimeout(() => {
        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}?tab=${
            LessonTabs.LessonPractice
          }${pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''} `,
          { showModal },
        );
      }, 10);
    },
    [history, courseId, sectionId, subjectId, unitId, lessonId],
  );
  return goBack;
};

export default usePracticeGoBack;
