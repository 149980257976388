import React, { useState, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {
  SlideChangesContainer,
  SlideContainer,
  SlideDetailContainer,
  SlideDiffContainer,
  SlideNumber,
  SlideChangesList,
  SlideChangeItem,
  EmptySlide,
} from './PresentationManagement.style';
import PresentationSlide from './PresentationSlide';

const SlideDiffs = (props) => {
  const {
    defaultSlideNumber,
    copySlideNumber,
    setAddedSlides,
    setDeletedSlides,
    defaultSlide,
    copySlide,
    defaultSlideId,
    copySlideId,
    showAllSlides,
    index,
    addedSlides,
    deletedSlides,
  } = props;

  const [modifiedImages, setModifiedImages] = useState([]);
  const [textChangeList, setTextChangeList] = useState([]);
  const [addedTexts, setAddedTexts] = useState([]);
  const [removedTexts, setRemovedTexts] = useState([]);
  const [transformedObject, setTransformedObject] = useState([]);
  const [styleChanges, setStyleChanges] = useState([]);
  const [addedImage, setAddedImage] = useState(0);
  const [removedImage, setRemovedImage] = useState(0);

  const sortSlideContents = () => {
    if (_isEmpty(defaultSlide)) {
      setAddedSlides(defaultSlideNumber);
      return;
    }
    if (_isEmpty(copySlide)) {
      setDeletedSlides(copySlideNumber);
      return;
    }

    if (!defaultSlide && !copySlide) return;

    const defaultSlidePageElementsIds = defaultSlide.pageElements?.map(({ objectId }) => objectId);
    const copySlidePageElementsIds = copySlide.pageElements?.map(({ objectId }) => objectId);

    if (!defaultSlidePageElementsIds && !copySlidePageElementsIds) return;

    const commonElements = defaultSlidePageElementsIds.filter((item) =>
      copySlidePageElementsIds.includes(item),
    );
    let { pageElements: pageElementsDefault } = defaultSlide;
    let { pageElements: pageElementsCopy } = copySlide;

    const uniqueElementsDefaultSlide = pageElementsDefault.filter(
      (item) => !commonElements.includes(item.objectId),
    );
    const uniqueElementsCopySlide = pageElementsCopy.filter(
      (item) => !commonElements.includes(item.objectId),
    );

    uniqueElementsDefaultSlide.forEach((item) => {
      if (Object.keys(item).includes('shape')) {
        const textElements = item.shape?.text?.textElements;
        getRemovedTexts(textElements);
      } else if (Object.keys(item).includes('image')) {
        setRemovedImage((prevRemovedImage) => prevRemovedImage.removedImage + 1);
      }
    });

    uniqueElementsCopySlide.forEach((item) => {
      if (Object.keys(item).includes('shape')) {
        const textElements = item.shape?.text?.textElements;
        getAddedTexts(textElements);
      } else if (Object.keys(item).includes('image')) {
        setAddedImage((prevAddedImage) => prevAddedImage.addedImage + 1);
      }
    });

    pageElementsDefault = pageElementsDefault.filter((item) =>
      commonElements.includes(item.objectId),
    );
    pageElementsCopy = pageElementsCopy.filter((item) => commonElements.includes(item.objectId));

    pageElementsDefault = [...pageElementsDefault, ...uniqueElementsDefaultSlide];
    pageElementsCopy = [...pageElementsCopy, ...uniqueElementsCopySlide];

    defaultSlide.pageElements = pageElementsDefault;
    copySlide.pageElements = pageElementsCopy;
    getDifference(defaultSlide, copySlide);
  };
  useEffect(() => {
    sortSlideContents();
  }, []);

  const getDifference = (slideOne, slideTwo) => {
    const diff = [];
    let tmp = null;

    if (JSON.stringify(slideOne) === JSON.stringify(slideTwo)) return;

    const keys = [...new Set(Object.keys(slideTwo), Object.keys(slideOne))];

    try {
      keys.forEach((key) => {
        if (Array.isArray(slideOne[key]) && Array.isArray(slideTwo[key])) {
          tmp = slideOne[key].reduce((previous, current, i) => {
            const temp = !!slideTwo[key][i] && getDifference(current, slideTwo[key][i]);
            if (slideTwo[key][i] === undefined) {
              getRemovedTexts([current]);
            }
            if (temp) {
              if (Object.keys(temp).includes('table')) {
                getTextChanges(temp);
              }

              if (Object.keys(temp).includes('transform')) {
                let transformedObjectValue = 'Text position changed';
                if (Object.keys(temp).includes('image')) {
                  transformedObjectValue = 'Image position changed';
                }
                setTransformedObject((prevTransformedObject) => [
                  ...prevTransformedObject,
                  transformedObjectValue,
                ]);
              }

              if (Object.keys(temp).includes('shape')) {
                const modifiedText = {
                  ...temp,
                  objectId: current.objectId,
                  copyObjectId: slideTwo[key][i].objectId,
                  slideId: slideOne.objectId,
                };
                getTextChanges(modifiedText);
                getTextStyleChanges(temp);

                const textElementsDefault = current.shape.text.textElements;
                const textElementsCopy = slideTwo[key][i].shape.text.textElements;
                if (textElementsDefault.length < textElementsCopy.length) {
                  const addedTextElements = textElementsCopy.slice(textElementsDefault.length);
                  getAddedTexts(addedTextElements);
                }
              }
              if (Object.keys(temp).includes('image')) {
                const imageKeys = Object.keys(temp.image);
                let validKeys = imageKeys.filter((imageKey) => imageKey !== 'contentUrl');

                if (validKeys.includes('imageProperties')) {
                  const styleChange = 'Image properties changed';
                  setStyleChanges((prevStyleChanges) => [...prevStyleChanges, styleChange]);
                  validKeys = validKeys.filter((validKey) => validKey !== 'imageProperties');
                }
                if (validKeys.length) {
                  const modifiedImage = {
                    ...temp,
                    objectId: current.objectId,
                    copyObjectId: slideTwo[key][i].objectId,
                    slideId: slideOne.objectId,
                  };
                  setModifiedImages((prevModifiedImages) => [...prevModifiedImages, modifiedImage]);
                }
              }
              previous.push(temp);
            }
            return previous;
          }, []);

          if (Object.keys(tmp).length > 0) diff[key] = tmp;
        } else if (typeof slideOne[key] === 'object' && typeof slideTwo[key] === 'object') {
          tmp = getDifference(slideOne[key], slideTwo[key]);
          if (tmp && Object.keys(tmp).length > 0) {
            diff[key] = tmp;
          }
        } else if (slideOne[key] !== slideTwo[key]) {
          diff[key] = [slideOne[key], slideTwo[key]];
        }
      });
      return diff;
    } catch (err) {
      console.log(err);
    }
  };

  const getAddedTexts = (textElements) => {
    textElements.forEach((textElement) => {
      if (textElement.textRun) {
        const content = textElement?.textRun?.content;
        const addedText = `Added text "${content}"`;
        setAddedTexts((prevAddedTexts) => [...prevAddedTexts, addedText]);
      }
    });
  };

  const getRemovedTexts = (textElements) => {
    textElements.forEach((textElement) => {
      if (textElement.textRun) {
        const content = textElement?.textRun?.content;
        const removedText = `Removed text "${content}"`;
        setRemovedTexts((prevRemovedTexts) => [...prevRemovedTexts, removedText]);
      }
    });
  };

  const getTextChanges = (modifiedText) => {
    if (modifiedText?.shape) {
      const { shape } = modifiedText;
      shape?.text?.textElements.forEach((textElement) => {
        if (textElement?.textRun?.content) {
          const content = textElement?.textRun?.content.map((item) => item.replaceAll('\n', ''));
          const textChange = `"${content?.[0]}" is changed to "${content?.[1]}"`;
          setTextChangeList((prevTextChangeList) => [...prevTextChangeList, textChange]);
        }
      });
    } else if (modifiedText?.table) {
      const { table } = modifiedText;
      const tableRows = table?.tableRows;
      tableRows.forEach((row) => {
        const tableCells = row?.tableCells;
        tableCells.forEach((cell) => {
          const textElements = cell?.text?.textElements;
          textElements.forEach((textElement) => {
            if (textElement?.textRun?.content) {
              const content = textElement?.textRun?.content;
              const textChange = `"${content?.[0]}" is changed to "${content?.[1]}"`;
              setTextChangeList((prevTextChangeList) => [...prevTextChangeList, textChange]);
            }
          });
        });
      });
    }
  };

  const getTextStyleChanges = (modifiedTextStyle) => {
    const textElements = modifiedTextStyle?.shape?.text?.textElements || [];
    textElements.forEach((textElement) => {
      if (textElement?.textRun?.style) {
        let styleChange = 'Text style changed';
        if (textElement.textRun.style.link) {
          styleChange = 'Hyperlink changed';
        }
        setStyleChanges((prevStyleChanges) => [...prevStyleChanges, styleChange]);
      } else if (textElement.paragraphMarker) {
        const styleChange = 'Paragraph style changed';
        setStyleChanges((prevStyleChanges) => [...prevStyleChanges, styleChange]);
      }
    });
  };

  const uniqueStyleChanges = [...new Set(styleChanges)];
  const uniqueTransformedObject = [...new Set(transformedObject)];
  const uniqueTextChangeList = [...new Set(textChangeList)];

  if (
    !uniqueTextChangeList.length &&
    !addedTexts.length &&
    !removedTexts.length &&
    !modifiedImages.length &&
    !addedImage &&
    !removedImage &&
    !addedSlides.includes(index) &&
    !deletedSlides.includes(index) &&
    !uniqueTransformedObject.length &&
    !uniqueStyleChanges.length &&
    !showAllSlides
  ) {
    return <div />;
  }

  const isAdded = addedSlides.includes(index);
  const isDeleted = deletedSlides.includes(index);
  const hasNoChange =
    [
      ...modifiedImages,
      ...uniqueTextChangeList,
      ...addedTexts,
      ...removedTexts,
      ...uniqueTransformedObject,
      ...uniqueStyleChanges,
    ].length === 0 &&
    !isAdded &&
    !isDeleted;

  return (
    <SlideDiffContainer>
      <SlideContainer>
        <SlideDetailContainer>
          {_isEmpty(defaultSlide) ? (
            <EmptySlide>
              <i className="chalktalk-close" />
            </EmptySlide>
          ) : (
            <PresentationSlide presentation={defaultSlideId} position={defaultSlideNumber} />
          )}
          <SlideChangesContainer>
            <SlideNumber>{index}</SlideNumber>
          </SlideChangesContainer>
        </SlideDetailContainer>
        <SlideDetailContainer>
          {_isEmpty(copySlide) ? (
            <EmptySlide>
              <i className="chalktalk-close" />
            </EmptySlide>
          ) : (
            <PresentationSlide presentation={copySlideId} position={copySlideNumber} />
          )}
          <SlideChangesContainer>
            <SlideNumber>{index}</SlideNumber>
            <SlideChangesList>
              {hasNoChange && <span>No changes</span>}
              {isDeleted && <SlideChangeItem>This slide was deleted</SlideChangeItem>}
              {isAdded && <SlideChangeItem>This slide was added</SlideChangeItem>}
              {addedTexts.map((addedText, i) => (
                <SlideChangeItem key={i}>{addedText}</SlideChangeItem>
              ))}
              {uniqueTextChangeList.map((textChange, i) => (
                <SlideChangeItem key={i}>{textChange}</SlideChangeItem>
              ))}
              {modifiedImages.length > 0 && <SlideChangeItem>Image was changed</SlideChangeItem>}
              {addedImage > 0 && !modifiedImages.length && (
                <SlideChangeItem>{`${addedImage} Image was added`}</SlideChangeItem>
              )}
              {removedImage > 0 && !modifiedImages.length && (
                <SlideChangeItem>{`${removedImage} Image was removed`}</SlideChangeItem>
              )}
              {removedTexts.map((removedText, i) => (
                <SlideChangeItem key={i}>{removedText}</SlideChangeItem>
              ))}
              {uniqueTransformedObject.map((uniqueTransformedItem, i) => (
                <SlideChangeItem key={i}>{uniqueTransformedItem}</SlideChangeItem>
              ))}
              {uniqueStyleChanges.map((styleChange, i) => (
                <SlideChangeItem key={i}>{styleChange}</SlideChangeItem>
              ))}
            </SlideChangesList>
          </SlideChangesContainer>
        </SlideDetailContainer>
      </SlideContainer>
    </SlideDiffContainer>
  );
};

SlideDiffs.propTypes = {
  defaultSlideNumber: PropTypes.number,
  copySlideNumber: PropTypes.number,
  setAddedSlides: PropTypes.func,
  setDeletedSlides: PropTypes.func,
  defaultSlide: PropTypes.object,
  copySlide: PropTypes.object,
  defaultSlideId: PropTypes.string,
  copySlideId: PropTypes.string,
  showAllSlides: PropTypes.bool,
  index: PropTypes.number,
  addedSlides: PropTypes.array,
  deletedSlides: PropTypes.array,
};

export default SlideDiffs;
