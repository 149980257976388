import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps, Theme } from '@mui/system';

interface AccordionProps {
  content: React.ReactNode;
  title: React.ReactNode;
  expandIcon?: React.ReactElement;
  defaultExpanded?: boolean;
  disableGutters?: boolean;
  accordionSx?: SxProps<Theme>;
  summarySx?: SxProps<Theme>;
  detailsSx?: SxProps<Theme>;
}

const Accordion = ({
  content,
  title,
  expandIcon = <ExpandMoreIcon />,
  defaultExpanded = false,
  disableGutters = false,
  accordionSx = {},
  summarySx = {},
  detailsSx = {},
  ...props
}: AccordionProps) => {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={handleChange}
      disableGutters={disableGutters}
      sx={{
        '&:before': { display: 'none' },
        ...accordionSx,
      }}
      {...props}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls="panel1-content"
        id="panel1-title"
        sx={{
          ...summarySx,
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          ...detailsSx,
        }}
      >
        {content}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
