import { connect } from 'react-redux';
import StudentQuickInviteForm from '../../components/StudentQuickInviteForm';
import { actions } from '../../reducers/sectionStudent';
import { selectors } from '../../reducers/sections';

const mapStateToProps = (state) => {
  const sectionData = selectors.getFirstSection(state);
  const { id: sectionId } = sectionData;

  return {
    sectionId,
  };
};

const mapDispatchToProps = {
  submitInviteStudent: actions.sectionsStudentInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentQuickInviteForm);
