import { IWDB } from '../db';
// IW

class IW {
  API;

  constructor(API) {
    this.API = API;
  }

  get(id) {
    if (id) {
      return this.API.get(id);
    }
    return this.API.getAll();
  }

  create(data) {
    const newData = {
      createdAt: Date.now(),
      id: Date.now(),
      updatedAt: Date.now(),
      ...data,
    };
    return this.API.add(newData);
  }

  put(data) {
    const newData = {
      ...data,
      updatedAt: Date.now(),
    };
    return this.API.set(newData);
  }

  delete(id) {
    return this.API.delete(id);
  }
}
const iWApi = new IW(IWDB);
export default iWApi;
