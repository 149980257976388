import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import { createSelector } from 'reselect';
import moment from 'moment';
import {
  selectors as LeftSideBarSelector,
} from '../leftSideBar';
import { DATE_FORMAT } from '../../utils/constants';
import { formatDate } from '../../utils/func-utils';
// import { SECTION_ATTENDANCE_SUBMIT_SUCCESS } from '../sectionAttendance';

export const SECTIONS_SESSION_ADD = 'sectionsSession/ADD';
export const SECTIONS_SESSION_ADD_SUCCESS = 'sectionsSession/ADD_SUCCESS';
export const SECTIONS_SESSION_ADD_FAIL = 'sectionsSession/ADD_FAIL';

export const SECTIONS_SESSION_GET_DETAIL = 'sectionsSession/GET_DETAIL';
export const SECTIONS_SESSION_GET_DETAIL_SUCCESS = 'sectionsSession/GET_DETAIL_SUCCESS';
export const SECTIONS_SESSION_GET_DETAIL_FAIL = 'sectionsSession/GET_DETAIL_FAIL';

export const SECTIONS_SESSION_UPDATE = 'sectionsSession/UPDATE';
export const SECTIONS_SESSION_UPDATE_SUCCESS = 'sectionsSession/UPDATE_SUCCESS';
export const SECTIONS_SESSION_UPDATE_FAIL = 'sectionsSession/UPDATE_FAIL';

export const SECTIONS_SESSION_GET = 'sectionsSession/GET';
export const SECTIONS_SESSION_GET_SUCCESS = 'sectionsSession/GET_SUCCESS';
export const SECTIONS_SESSION_GET_FAIL = 'sectionsSession/GET_FAIL';

export const SECTIONS_SESSION_DELETE = 'sectionsSession/DELETE';
export const SECTIONS_SESSION_DELETE_SUCCESS = 'sectionsSession/DELETE_SUCCESS';
export const SECTIONS_SESSION_DELETE_FAIL = 'sectionsSession/DELETE_FAIL';

const getSessionIds = ({ sectionSession }) => _get(sectionSession, 'sessionIds', []);
const getSessionByIds = ({ sectionSession }) => _get(sectionSession, 'sessionByIds', {});

const getSessionList = createSelector(
  getSessionIds,
  getSessionByIds,
  (ids, byIds) => ids.map(id => byIds[id]),
);

const getSessionListWithSubjectId = createSelector(
  getSessionIds,
  getSessionByIds,
  LeftSideBarSelector.getSubjectId,
  (Ids, byIds, subjectId) => (
    Ids.map(id => byIds[id])
      .filter(review => review.subject === subjectId)
  ),
);

const getSessionUpcommingList = createSelector(
  getSessionListWithSubjectId,
  (sessionList) => {
    const today = moment().format(DATE_FORMAT.DATE);
    return sessionList
      .filter(session => ((session.date >= today && session.activity_type != 1)))
      .sort((sessionA, sessionB) => moment(sessionA.date).diff(moment(sessionB.date)));
  },
);

const getSessionUpcommingListWithoutSubject = createSelector(
  getSessionList,
  (sessionList) => {
    const today = moment().format(DATE_FORMAT.DATE);
    const afterDay = moment().add(1, 'd').toDate();
    return sessionList
      .filter(session => ((session.date <= formatDate(afterDay) && session.date >= today && session.activity_type != 1)))
      .sort((sessionA, sessionB) => moment(sessionA.date).diff(moment(sessionB.date)));
  },
);

const getNextSession = (state, lessonId) => {
  const sessionList = getSessionUpcommingList(state);
  const nextSessionListByLesson = sessionList.filter(session => session.lesson == lessonId);  // eslint-disable-line
  if (!nextSessionListByLesson.length) return {};
  return nextSessionListByLesson.shift();
};

const getSessionByUnitAndLession = (state, unitId, lessonId, sectionId) => {
  const sessionList = getSessionList(state);

  return _filter(sessionList, // id in sessionList is number but the args is string
    ({ unit, lesson, section }) => unit == unitId && lesson == lessonId && section == sectionId); // eslint-disable-line
};

const isGettingSectionSession = ({ sectionSession }) => sectionSession.isGetting;
const isUpdatingLesson = ({ sectionSession }) => sectionSession.isUpdating;
const getUpdateErrorMessage = ({ sectionSession }) => sectionSession.updateError;

const isAddSuccess = ({ sectionSession }) => _get(sectionSession, 'addSuccess', false);

const getShouldFetch = ({ sectionSession }, sectionId) => _get(sectionSession, 'hasFetchedOfSectionId', null) != sectionId; // eslint-disable-line
export const selectors = {
  getSessionIds,
  getSessionByIds,

  getSessionList,
  getSessionByUnitAndLession,
  getSessionUpcommingList,
  getSessionUpcommingListWithoutSubject,
  getSessionListWithSubjectId,

  getNextSession,
  isAddSuccess,

  isUpdatingLesson,
  isGettingSectionSession,
  getUpdateErrorMessage,
  getShouldFetch,
};

const sectionSessionGet = (sectionId, subjectId) => ({
  type: SECTIONS_SESSION_GET,
  sectionId,
  subjectId,
});

const sectionSessionGetSuccess = (sessionList, sectionId) => ({
  type: SECTIONS_SESSION_GET_SUCCESS,
  sessionList,
  sectionId,
});

const sectionSessionGetFail = error => ({
  type: SECTIONS_SESSION_GET_FAIL,
  error,
});

const sectionSessionDelete = sectionId => ({
  type: SECTIONS_SESSION_DELETE,
  sectionId,
});

const sectionSessionDeleteSuccess = id => ({
  type: SECTIONS_SESSION_DELETE_SUCCESS,
  id,
});

const sectionSessionDeleteFail = error => ({
  type: SECTIONS_SESSION_DELETE_FAIL,
  error,
});

// action creator

/**
 * @param {*} sectionId String
 * @param {*} sessionData Object
 */

const sectionsSessionAdd = sessionData => ({
  type: SECTIONS_SESSION_ADD,
  sessionData,
});

/**
  * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsSessionAddSuccess = payload => ({
  type: SECTIONS_SESSION_ADD_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsSessionAddFail = payload => ({
  type: SECTIONS_SESSION_ADD_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 * @param {*} sessionId String
 */

const sectionsSessionGetDetail = (sectionId, sessionId) => ({
  type: SECTIONS_SESSION_GET_DETAIL,
  sectionId,
  sessionId,
});

/**
  * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsSessionGetDetailSuccess = payload => ({
  type: SECTIONS_SESSION_ADD_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsSessionGetDetailFail = payload => ({
  type: SECTIONS_SESSION_ADD_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 * @param {*} sessionId String
 * @param {*} sessionData Object
 */

const sectionsSessionUpdate = (sectionId, sessionId, sessionData) => ({
  type: SECTIONS_SESSION_UPDATE,
  sectionId,
  sessionId,
  sessionData,
});

/**
  * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsSessionUpdateSuccess = (sessionId, sessionData) => ({
  type: SECTIONS_SESSION_UPDATE_SUCCESS,
  sessionId,
  sessionData,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsSessionUpdateFail = error => ({
  type: SECTIONS_SESSION_UPDATE_FAIL,
  error,
});

export const actions = {
  // add session of section
  sectionsSessionAdd,
  sectionsSessionAddSuccess,
  sectionsSessionAddFail,
  // get session detail of section
  sectionsSessionGetDetail,
  sectionsSessionGetDetailSuccess,
  sectionsSessionGetDetailFail,
  // update session of section
  sectionsSessionUpdate,
  sectionsSessionUpdateSuccess,
  sectionsSessionUpdateFail,
  // get session
  sectionSessionGet,
  sectionSessionGetSuccess,
  sectionSessionGetFail,

  sectionSessionDelete,
  sectionSessionDeleteSuccess,
  sectionSessionDeleteFail,
};

const initialState = {
  isAdding: false, // is adding session of section
  isGetDetail: false, // is get session detail of section

  isGetting: false,
  sessionIds: [],
  sessionByIds: {},

  addSuccess: false,

  isDelete: false,
  deleteSuccess: false,
  deleteError: false,
  isUpdating: false, // is session of section updating
  updateError: null,
  hasFetchedOfSectionId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // add session of section
    case SECTIONS_SESSION_ADD:
      return { ...state, isAdding: true, addSuccess: false };
    case SECTIONS_SESSION_ADD_SUCCESS: {
      const { payload: sessionObj } = action;
      const { id } = sessionObj;

      return {
        ...state,
        isAdding: false,
        sessionIds: [...state.sessionIds, id],
        sessionByIds: {
          ...state.sessionByIds,
          [id]: sessionObj,
        },
        addSuccess: true,
      };
    }
    case SECTIONS_SESSION_ADD_FAIL:
      return { ...state, isAdding: false };
    // get detail session of section
    case SECTIONS_SESSION_GET_DETAIL:
      return { ...state, isGetDetail: true };
    case SECTIONS_SESSION_GET_DETAIL_SUCCESS:
    case SECTIONS_SESSION_GET_DETAIL_FAIL:
      return { ...state, isGetDetail: false };

    // update session of section
    case SECTIONS_SESSION_UPDATE: {
      return {
        ...state,
        isUpdating: true,
        updateError: null,
      };
    }
    case SECTIONS_SESSION_UPDATE_SUCCESS: {
      const {
        sessionId,
        sessionData,
      } = action;

      return {
        ...state,
        isUpdating: false,
        sessionByIds: {
          ...state.sessionByIds,
          [sessionId]: sessionData,
        },
      };
    }

    case SECTIONS_SESSION_UPDATE_FAIL: {
      const { error } = action;
      return {
        ...state,
        isUpdating: false,
        updateError: error,
      };
    }

    // get session list
    case SECTIONS_SESSION_GET: {
      const { sectionId } = action;
      const shouldFetch = state.hasFetchedOfSectionId != sectionId; // eslint-disable-line

      return { ...state, isGetting: shouldFetch };
    }
    case SECTIONS_SESSION_GET_SUCCESS: {
      const { sessionList, sectionId } = action;
      const Ids = sessionList.map(({ id }) => id);
      const byIds = _keyBy(sessionList, 'id');

      return {
        ...state,
        isGetting: false,
        sessionIds: Ids,
        sessionByIds: byIds,
        hasFetchedOfSectionId: sectionId,
      };
    }
    case SECTIONS_SESSION_GET_FAIL:
      return { ...state, isGetting: false };

      // case SECTION_ATTENDANCE_SUBMIT_SUCCESS: {
      //   const { payload, id } = action;
      //   return {
      //     ...state,
      //     sessionByIds: {
      //       ...state.sessionByIds,
      //       [id]: {
      //         ...state.sessionByIds[id],
      //         attendance: [payload],
      //       },
      //     },
      //   };
      // }

    case SECTIONS_SESSION_DELETE:
      return {
        ...state,
        isDelete: true,
      };

    case SECTIONS_SESSION_DELETE_SUCCESS:
    case SECTIONS_SESSION_DELETE_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
        isDelete: false,
        deleteSuccess: false,
      };
    }
    default: {
      return { ...state };
    }
  }
}
