import { useSelector } from 'react-redux';
import { useCurrentCourse } from '@reducers/courses/hooks';
import { getCurriculumById } from './curriculumsReducer';

type CurriculumDetails = {
  id: number;
  name: string;
  slug: string;
  public: boolean;
  has_exam: boolean;
  adaptive_learnosity_practice_enabled: boolean;
  learnosity_lesson_review_enabled: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const useCurriculumDetails = (): CurriculumDetails | Record<string, never> => {
  const courseDetails = useCurrentCourse();
  const curriculumDetails = useSelector((state: any) =>
    getCurriculumById(state, courseDetails.curriculum),
  );

  return curriculumDetails;
};

export const useIsAdaptiveLearnosityPracticeEnabled = (): boolean => {
  const currentCurriculumDetails = useCurriculumDetails();
  return currentCurriculumDetails?.adaptive_learnosity_practice_enabled ?? false;
};

export const useIsLearnosityLessonReviewEnabled = (): boolean => {
  const currentCurriculumDetails = useCurriculumDetails();
  return currentCurriculumDetails?.learnosity_lesson_review_enabled ?? false;
};
