import React from 'react';
import Paper from '../Paper';
import Typography from '../Typography';
import FiveProgress from './FiveProgress';

type Props = { level: number; title: string; 'data-testid'?: string };

const FiveProgressMater = ({ level, title, 'data-testid': testid }: Props) => (
  <Paper
    data-testid={testid}
    elevation={2}
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '1rem',
      gap: '0.25rem',
      padding: '1rem',
      width: '100%',
    }}
  >
    <Typography
      variant="body1"
      sx={{
        textTransform: 'capitalize',
        width: '100%',
      }}
    >
      {title}
    </Typography>
    <FiveProgress level={level} />
  </Paper>
);

export default FiveProgressMater;
