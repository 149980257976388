import React from 'react';
import PreviewQuestion from '@learnosityassessment/Components/PreviewQuestion';
import {
  useSelectedQuestions,
  useSelectedType,
} from '@components/QuestionSelector/QuestionSelector.provider';
import Button from '@components/Atoms/Button';
import { DragIndicator } from '@mui/icons-material';
import { useDrag, useDrop } from 'react-dnd';
import { Box, Divider } from '@mui/material';
import ShowIf from '@components/Atoms/ShowIf';
import Select from '@components/Atoms/Select';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';
import IconButton from '@components/Atoms/IconButton';
import { LLROrderingType } from '../types';

const dragType = 'QUESTION_DRAG';

const DraggableQuestion = ({
  question,
  index,
  setSelectedQuestion,
  selectedQuestion,
}) => {
  const questionRef = React.useRef(null);
  const { reorderQuestions, type } = useSelectedQuestions();
  const [{ handlerId }, drop] = useDrop({
    accept: dragType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!questionRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = questionRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      reorderQuestions(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const isFixedOrderType = type === 1;

  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: { type: dragType, index },
    canDrag: () => isFixedOrderType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(questionRef));

  return (
    <Box
      ref={questionRef}
      sx={{
        cursor: 'grab',
        opacity: isDragging ? 0 : 1,
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        py: 1,
        background: 'transparent',
      }}
      data-handler-id={handlerId}
    >
      <ShowIf If={isFixedOrderType}>
        <DragIndicator color="primary" fontSize="medium" />
      </ShowIf>
      <Button
        onClick={() => setSelectedQuestion(question)}
        variant="outlined"
        disabled={selectedQuestion === question}
        sx={{ background: 'white' }}
      >
        Question {question}
      </Button>
    </Box>
  );
};

const PreviewQuestions = () => {
  const { selectedQuestions } = useSelectedQuestions();
  const [selectedQuestion, setSelectedQuestion] = React.useState(selectedQuestions[0]);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [type, setType] = useSelectedType();

  return (
    <>
      <Dialog
        title="Question ordering"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      >
        <Typography
          variant="body1"
          sx={{
            p: '1rem',
            py: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          Fixed-Order: the questions will appear for your students in the order you select
          them.
          <Divider />
          Adaptive: generates a 5-question practice session tailored to each student's
          skill level. Each subsequent 5-question session adapts based on their previous
          answers, using questions from the item pool you select below. For best results,
          choose at least 20 questions of varying difficulties.
        </Typography>
      </Dialog>
      <Box
        width="100%"
        display="flex"
        sx={{ background: 'white' }}
        minHeight="100%"
        maxHeight="100%"
      >
        <Box
          display="flex"
          flexDirection="column"
          maxWidth="12.5rem"
          width="100%"
          borderRight="1px solid #e0e0e0"
          minHeight="100%"
          paddingInline={1}
          maxHeight="100%"
          overflow="auto"
        >
          <Box display="flex" alignItems="center" sx={{ p: '0.5rem' }}>
            <Select
              list={[
                { label: 'Fixed-Order', value: LLROrderingType.FIXED_ORDERING },
                { label: 'Adaptive', value: LLROrderingType.ADAPTIVE_ORDERING },
              ]}
              label="Question ordering"
              id="Question ordering"
              value={type}
              size="small"
              sx={{ minWidth: '8rem' }}
              onChange={(e) => {
                setType(e.target.value as LLROrderingType);
              }}
            />
            <IconButton onClick={() => setIsDialogOpen(true)}>
              <ErrorOutlineIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: '0.5rem' }} />
          {selectedQuestions.map((question, i) => (
            <DraggableQuestion
              key={question}
              question={question}
              index={i}
              setSelectedQuestion={setSelectedQuestion}
              selectedQuestion={selectedQuestion}
            />
          ))}
        </Box>
        <Box flex={1}>
          <PreviewQuestion referenceId={selectedQuestion} />
        </Box>
      </Box>
    </>
  );
};

export default PreviewQuestions;
