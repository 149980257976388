/* eslint-disable eqeqeq */
import React from 'react';
import { calculateTimeLeft } from './Timer.utils';

type useTimerProps = {
  startTime: Date;
  duration: number;
};
export const useTimer = ({ startTime, duration }: useTimerProps) => {
  const [time, setTime] = React.useState(calculateTimeLeft(startTime, duration));

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTime(calculateTimeLeft(startTime, duration));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  return time;
};
// --------------------
type useOnTimeOutProps = {
  onTimeOut: () => void;
  time: string;
};
export const useOnTimeOut = ({ onTimeOut, time }: useOnTimeOutProps, deps: any[]) => {
  const isFiredTimeOutRef = React.useRef(new Date().getTime());
  React.useEffect(() => {
    if (typeof onTimeOut != 'function') {
      throw new Error('Please pass OnTimeOut function');
    }
    if (time === '00:00:00') {
      isFiredTimeOutRef.current = new Date().getTime();
      onTimeOut();
    }
  }, [...deps]);
};
