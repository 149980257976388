import { useHistory } from 'react-router-dom';
import { PracticeContainer } from '@components/PracticePage/PracticePage.style';
import PracticeTopNavBar from '@components/PracticeTopNavBar';
import { Preview } from '@learnosityassessment';
import React from 'react';
import {
  useGetLearnosityAdaptivePracticeQuestions,
  useGetLessonDetail,
} from '@components/Component/Lesson/LessonContainer/hooks/query';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';

const LearnosityAdaptivePracticePreview = () => {
  const { data: questions } = useGetLearnosityAdaptivePracticeQuestions();
  const history = useHistory();
  const { data: lessonDetail } = useGetLessonDetail();

  const goBack = () => {
    history.goBack();
  };
  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Go back" goBack={goBack} />
      <ScrollWrapper sx={{ height: 'calc(100vh - 48px)' }}>
        <Preview
          activityId={`${lessonDetail?.id}`}
          title={lessonDetail?.name}
          subtitle=""
          questions={questions}
        />
      </ScrollWrapper>
    </PracticeContainer>
  );
};

export default LearnosityAdaptivePracticePreview;
