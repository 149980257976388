import React, { useState, useRef, useEffect, useMemo } from 'react';
import Collapsible from 'react-collapsible';
import MediaQuery from 'react-responsive';
import ShowIf from '@Atoms/ShowIf';
import {
  useGetEducationalResources,
  useGetLessonDetail,
} from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { useParams } from 'react-router-dom';
import Box from '@Atoms/Box';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import PacingGuideInfoCard from '@components/Component/Lesson/PacingGuide/PacingGuideInfoCard';
import useSlideEventListeners from '@components/LessonDetailTab/useSlideEventListeners';
import useIspringPresentationConnector from '@components/LessonDetailTab/hooks/useIspringPresentationConnector';
import usePresentationEventHandlers from '@components/LessonDetailTab/hooks/usePresentationEventHandlers';
import { v4 as uuidv4 } from 'uuid';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import CustomIframe from '../common/CustomIframe';
import {
  LessonManagementWrap,
  LessonManagementLeftWrap,
  LessonManagementRightWrap,
  LessonVideoTitlte,
  LessonVideoContent,
  LessonSubjectItem,
  LessonTitle,
  LessonItem,
  LessonGroupContent,
  LessonName,
  LessonPlayIcon,
} from '../LessonManagement/LessonManagement.style';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';

const scrollStyle = {
  width: '100%',
};

const scrollStyleMobile = {
  width: '100%',
  marginTop: '1em',
  order: 1,
};

const CATEGORY = {
  TEACHER_MATERIALS: 'Teacher Materials',
  STUDENT_FACING_MATERIALS: 'Student Facing Materials',
  ILLUSTRATIVE_MATHEMATICS: 'Illustrative Mathematics',
};

const groupEducationalResourcesByCategoryOrder = (oers) => {
  const groupedOers = oers?.reduce((acc, oer) => {
    const categoryOrder = oer?.category_order;
    if (!acc[categoryOrder]) {
      acc[categoryOrder] = [];
    }
    acc[categoryOrder].push(oer);
    return acc;
  }, {});

  return Object.values(groupedOers || {}).map((groupedOer) => {
    const groupedOerList = groupedOer;
    const oerObject = groupedOerList?.[0];
    return {
      categoryOrder: oerObject?.category_order,
      categoryName: oerObject?.source_category,
      source_url: oerObject?.source_url,
      oersList: groupedOerList,
    };
  });
};

const handleTrigger = (category, close) => (
  <LessonTitle>
    <p>{category}</p>
    <i className={close ? 'chalktalk-icon-dropdown' : 'chalktalk-icon-up'} />
  </LessonTitle>
);

const EducationalResourcesList = ({ groupedOer, setCurrentSource, gOerIndex }) => (
  <LessonSubjectItem>
    <Collapsible
      open={gOerIndex === 0}
      trigger={handleTrigger(groupedOer?.categoryName, true)}
      triggerWhenOpen={handleTrigger(groupedOer?.categoryName, false)}
    >
      {groupedOer?.oersList?.map((oer) => (
        <LessonItem
          key={oer?.source_name}
          onClick={() =>
            setCurrentSource({
              selectedUrl: oer?.source_url,
              lessonNodeId: oer?.lesson_node_id,
              additionalSourceId: oer?.id,
            })
          }
        >
          <LessonPlayIcon className="chalktalk-play-button" />
          <LessonGroupContent>
            <LessonName>{oer?.source_name}</LessonName>
          </LessonGroupContent>
        </LessonItem>
      ))}
    </Collapsible>
  </LessonSubjectItem>
);

const StudyHall = () => {
  const [currentSource, setCurrentSource] = useState<{
    selectedUrl: string | null;
    lessonNodeId: number | null;
    additionalSourceId: number | null;
  }>({
    selectedUrl: null,
    lessonNodeId: null,
    additionalSourceId: null,
  });
  const isTeacher = useIsTeacher();
  const { sectionId, lessonId } = useParams();
  const { id: userId } = useUser();
  const { data: lessonDetail } = useGetLessonDetail();
  const { data } = useGetPacingGuideLessons(lessonId);
  const pacingGuideLesson = data?.[0];
  const { data: oers } = useGetEducationalResources();
  const groupedOers = useMemo(
    () => groupEducationalResourcesByCategoryOrder(oers),
    [oers],
  );
  const studentOers = useMemo(
    () =>
      groupedOers.filter(
        ({ categoryName }) => categoryName !== CATEGORY.TEACHER_MATERIALS,
      ),
    [groupedOers],
  );
  const shouldRenderSlideLeft = !!lessonDetail;
  if (oers?.length) {
    oers.forEach((item, i) => {
      if (item.source_name === 'Geogebra Interactive Activity') {
        oers.splice(i, 1);
        oers.unshift(item);
      }
    });
  }
  const iframeRef = useRef<HTMLIFrameElement>(null);
  useSlideEventListeners();
  const sessionUUID = uuidv4();
  const controller = useIspringPresentationConnector(
    currentSource?.selectedUrl ?? '',
    iframeRef,
  );
  usePresentationEventHandlers(
    controller,
    sessionUUID,
    userId,
    sectionId,
    currentSource.lessonNodeId ?? 0,
    LessonTabs.StudyHall,
    currentSource.additionalSourceId ?? undefined,
  );

  useEffect(() => {
    if (oers?.length && oers[0].source_url && isTeacher) {
      setCurrentSource({
        selectedUrl: oers[0].source_url,
        lessonNodeId: oers[0].lesson_node_id ?? null,
        additionalSourceId: oers[0].id ?? null,
      });
      return;
    }
    if (
      studentOers?.length &&
      studentOers[0].source_url &&
      oers?.length &&
      oers[0].lesson_node_id
    ) {
      setCurrentSource({
        selectedUrl: studentOers[0].source_url,
        lessonNodeId: oers[0].lesson_node_id ?? null,
        additionalSourceId: oers[0].id ?? null,
      });
    }
  }, [isTeacher, lessonDetail.id, oers, studentOers]);

  return (
    <Box
      sx={{
        height: 'calc(100% - 130px)',
        overflow: 'auto',
        position: 'absolute',
        width: '100%',
      }}
    >
      <DefaultContentWrap>
        <LessonManagementWrap>
          <MediaQuery maxWidth={1280}>
            {(matches) => (
              <div style={matches ? scrollStyleMobile : scrollStyle}>
                <LessonManagementLeftWrap>
                  <PacingGuideInfoCard
                    guideInfoText={pacingGuideLesson?.study_hall_instruction ?? ''}
                    taskDeliveryText={pacingGuideLesson?.study_hall_delivery ?? ''}
                  />
                  <ShowIf If={isTeacher}>
                    {groupedOers?.map((groupedOer, gOerIndex) => (
                      <EducationalResourcesList
                        groupedOer={groupedOer}
                        key={groupedOer?.categoryOrder}
                        gOerIndex={gOerIndex}
                        setCurrentSource={setCurrentSource}
                      />
                    ))}
                  </ShowIf>
                  <ShowIf If={!isTeacher}>
                    {studentOers.map((groupedOer, gOerIndex) => (
                      <EducationalResourcesList
                        groupedOer={groupedOer}
                        key={groupedOer?.categoryOrder}
                        gOerIndex={gOerIndex}
                        setCurrentSource={setCurrentSource}
                      />
                    ))}
                  </ShowIf>
                </LessonManagementLeftWrap>
              </div>
            )}
          </MediaQuery>
          <ShowIf If={shouldRenderSlideLeft}>
            <LessonManagementRightWrap>
              <LessonVideoTitlte>{lessonDetail.name}</LessonVideoTitlte>
              <LessonVideoContent>
                <CustomIframe
                  isStudyHall
                  frameRef={iframeRef}
                  key={lessonDetail?.id}
                  src={currentSource.selectedUrl}
                  showExtraLink
                />
              </LessonVideoContent>
            </LessonManagementRightWrap>
          </ShowIf>
        </LessonManagementWrap>
      </DefaultContentWrap>
    </Box>
  );
};

export default StudyHall;
