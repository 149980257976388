import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';
import { usePractice } from '@components/PracticeV2/Provider/PracticeProvider';

const GroupModeStartDialog = () => {
  const { handleGroupInstructionDialogClose, state } = usePractice();

  return (
    <Dialog
      title="Group Discussion"
      actions={[
        {
          label: 'Continue',
          onClick: handleGroupInstructionDialogClose,
          buttonProps: { variant: 'text' },
        },
      ]}
      open={state.isGroupInstructionDialogOpen}
      onClose={handleGroupInstructionDialogClose}
      aria-labelledby="Group Activity Instructions"
    >
      <Typography variant="body1" sx={{ textAlign: 'start' }}>
        Now is the time to discuss all of the questions within your group. Make sure you
        all agree on one answer for each question. Press next to continue to the next
        question.
      </Typography>
    </Dialog>
  );
};

export default React.memo(GroupModeStartDialog);
