import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import * as SectionAPI from '../../apis/sections';

import {
  SECTION_ATTENDANCE_GET_STANDARD,
  SECTION_ATTENDANCE_SUBMIT,
  actions as sectionAttendanceActions,
} from '../../reducers/sectionAttendance';

//
// function* sectionAttendanceGetStandard({ sessionId }) {
//   const { response, error } = yield call(SessionAPI.getStandardSessionAttendance, sessionId);
//
//   if (!error) {
//     const { data } = response;
//     yield put(sectionAttendanceActions.sectionAttendanceGetStandardSuccess(data));
//   } else {
//     yield put(sectionAttendanceActions.sectionAttendanceGetStandardFail(error));
//   }
// }

function* sectionAttendanceSubmit({ sectionId, sectionAttendanceInfo }) {
  const { response, error } = yield call(SectionAPI.submitSectionAttendance, sectionId, sectionAttendanceInfo);
  if (!error) {
    const { data } = response;
    yield put(sectionAttendanceActions.sectionAttendanceSubmitSuccess(data));
  } else {
    yield put(sectionAttendanceActions.sectionAttendanceGetStandardFail(error));
  }
}

export default function* sectionAttendanceSaga() {
  // yield takeLeading(SECTIONS_SESSION_GET_SUCCESS, fetchAllStandardAttendance);
  // yield takeLeading(SECTION_ATTENDANCE_GET_STANDARD, sectionAttendanceGetStandard);
  // yield takeLeading(SECTION_ATTENDANCE_SUBMIT, sectionAttendanceSubmit);
}
