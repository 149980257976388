import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import _isEmpty from 'lodash/isEmpty';
import MediaQuery from 'react-responsive';
import CardLoader from '../../LoadingPlaceholder/CardLoader';
import {
  CardLoaderWrapper,
  CardText,
  CardContent,
  TextContainer,
  CardIconWrap,
  CardWrapper,
} from './Card.style';
import { NavBarIconWrap, NavBarIcon } from '../../SubNavBarItem/SubNavBar.style';
import history from '../../../utils/history';
import { CURRICULUM_TYPE, PRACTICE_TYPE } from '../../../utils/enums';
import DynamicOverflowTip from '../../DynamicOverflowTip/DynamicOverflowTip';

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initializedAssignment: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { section, subject, unit, lesson } = this.state.initializedAssignment;
    const { courseItem, practiceId, isInitialized } = this.props;
    if (
      !_isEmpty(this.state.initializedAssignment) &&
      prevProps.isInitialized !== isInitialized &&
      isInitialized
    ) {
      if (lesson) {
        history.push(
          `/lessons/course/${courseItem.id}/section/${section}/subject/${subject}/unit/${unit}/lesson/${lesson}/practice/${practiceId}`,
        );
      } else {
        history.push(
          `/lessons/course/${courseItem.id}/section/${section}/subject/${subject}/unit/${unit}/practice/${practiceId}`,
        );
      }
    }
  }

  redirectToLessonDetailPage = () => {
    const {
      courseItem,
      sectionId,
      session,
      isAssignments,
      initializePracticeSession,
      practiceId,
      sectionDetail,
    } = this.props;
    const { pacing_guides } = sectionDetail;
    const {
      id,
      subject,
      unit,
      lesson,
      type,
      exam_id,
      activity_type,
      asset_type,
      section,
      assigned_to,
    } = session;
    const pacingGuideId = pacing_guides.find((pacing) => pacing.subject === subject)?.id;
    if (isAssignments) {
      if (
        courseItem.curriculum == CURRICULUM_TYPE.ACT ||
        courseItem.curriculum == CURRICULUM_TYPE.SAT
      ) {
        if (!lesson) {
          initializePracticeSession(
            lesson,
            section,
            unit,
            assigned_to,
            asset_type,
            PRACTICE_TYPE.ADAPTIVE_PRACTICE,
          );
        } else {
          initializePracticeSession(
            lesson,
            section,
            unit,
            assigned_to,
            _,
            PRACTICE_TYPE.ADAPTIVE_PRACTICE,
          );
        }
        this.setState({
          initializedAssignment: session,
        });
      } else {
        let assignmentsUrl = `/lessons/course/${courseItem.id}/section/${sectionId}/subject/${subject}/unit/${unit}/lesson/${lesson}`;
        history.push({
          pathname: assignmentsUrl,
          search: `?tab=${LessonTabs.Practice}`,
          state: { startAssignment: true },
        });
      }
    } else {
      if (type === 'exams') {
        return history.push(
          `/test/course/${courseItem.id}/section/${sectionId}/exam/${exam_id}`,
        );
      }

      if (type === 'reviews') {
        return history.push(
          `/lessons/course/${courseItem.id}/section/${sectionId}/subject/${subject}/review/${id}`,
        );
      }

      const lessonPath = `lesson/${lesson}`;
      const practicePath = 'practice';
      const endPath = activity_type === 0 ? lessonPath : practicePath;

      return history.push({
        pathname: `/lessons/course/${courseItem.id}/section/${sectionId}/subject/${subject}/unit/${unit}/${endPath}`,
        search: `?tab=${LessonTabs.Lesson}${
          pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
        }`,
      });
    }
  };

  renderIcon = (type, activity_type) => {
    const { center } = this.props;
    let class_name = 'chalktalk-book-open';
    let pathNumber = Array.from(Array(14).keys());
    if (type === 'reviews') {
      class_name = 'chalktalk-neural-network';
    } else {
      class_name = 'chalktalk-quiz';
      pathNumber = Array.from(Array(7).keys());
    }
    return (
      <NavBarIconWrap isSingleItem>
        <NavBarIcon
          className={class_name}
          dashboard={true}
          type={type}
          activity_type={activity_type}
          center={center}
        >
          {type === 'exams' ? (
            <img src="/images/icon-svg/exam.svg" />
          ) : activity_type === 0 && type === 'lessons' ? (
            <img src="/images/icon-svg/open-book.svg" />
          ) : (
            pathNumber.map((number, index) => (
              <i className={`path${number}`} key={index} />
            ))
          )}
        </NavBarIcon>
      </NavBarIconWrap>
    );
  };

  renderDefaultCenterCard = () => (
    <CardContent defaultCard>
      <TextContainer defaultCard>
        <CardText>{moment(new Date()).format('MMM D, YYYY')}</CardText>
        <CardText long>Create your syllabus by scheduling your section</CardText>
      </TextContainer>
    </CardContent>
  );

  renderCard = (session) => {
    const { date, subject, type, section_name, activity_type } = session;
    const { subjectById, status, center } = this.props;
    const subjectName = subjectById ? subjectById[subject]?.display_name : '';
    const title =
      type === 'lessons'
        ? session.name
        : type === 'exams'
        ? 'Full length Practice Test'
        : session.title;

    return (
      <CardContent status={status}>
        {status && <NavBarIcon className="chalktalk-check-success" />}
        <CardIconWrap>{this.renderIcon(type, activity_type)}</CardIconWrap>
        <TextContainer>
          <CardText activity_type={true} size="small">
            {type === 'exams' ? section_name : subjectName}
          </CardText>
          <DynamicOverflowTip subject={true} title={title} large={center} lines={2} />
        </TextContainer>
        <CardText date={true} size="smaller">
          <MediaQuery minWidth={768}>
            {(matches) =>
              matches
                ? moment(date).format('ddd MMM D, YYYY')
                : moment(date).format('ddd MM D, YYYY')
            }
          </MediaQuery>
        </CardText>
      </CardContent>
    );
  };

  render() {
    const { hasEmptySchedule, loading, session, center, isAssignments } = this.props;
    if (isAssignments && session?.empty && !center) {
      return null;
    }
    if (!session || (session.empty && !center) || loading) {
      return (
        <CardLoaderWrapper>
          {hasEmptySchedule && !loading ? (
            this.renderDefaultCenterCard()
          ) : (
            <CardLoader loading={loading} />
          )}
        </CardLoaderWrapper>
      );
    }

    if (center) {
      return (
        <CardWrapper
          selected
          isAssignments={isAssignments}
          isCompleted={session.empty}
          onClick={this.redirectToLessonDetailPage}
        >
          <CardLoaderWrapper selected>
            {session.empty ? (
              <CardText long isCompleted>
                {isAssignments
                  ? 'You have no assignments right now.'
                  : 'Your syllabus is complete. We hope you enjoyed using ChalkTalk.'}
              </CardText>
            ) : (
              this.renderCard(session)
            )}
          </CardLoaderWrapper>
          {center && (
            <CardText selected>
              {!session.empty
                ? isAssignments
                  ? 'Start Practice'
                  : 'Up Next'
                : isAssignments
                ? ''
                : 'Session End'}
            </CardText>
          )}
        </CardWrapper>
      );
    }

    return (
      <CardLoaderWrapper onClick={this.redirectToLessonDetailPage}>
        {this.renderCard(session)}
      </CardLoaderWrapper>
    );
  }
}

Card.propTypes = {
  session: PropTypes.object,
};
