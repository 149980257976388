/* eslint-disable jsx-a11y/accessible-emoji */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import BackgroundLeft from '../BackgroundLeft';
import StudentRegisterForm from '../../containers/StudentRegister/StudentRegisterForm';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { LoginContainerWrap, LoginBlockRight } from '../LoginPage/Login.style';
import {
  RegisterFormInput,
  RegisterAction,
  RegisterMessageContent,
  ButtonStyled,
} from './StudentRegister.style';

export default class StudentRegister extends PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    courseId: PropTypes.number,
  }

  renderIntermediaScreen = () => {
    const { isLoading, courseId } = this.props;
    if (isLoading) {
      return <LoadingIndicator isLoading={isLoading} />;
    }
    return (
      <RegisterFormInput>
        <RegisterMessageContent>
          <h1>&#127881;</h1>
          <p>
            Congratulations!!! You have joined section name
            <br />
            Check your email to confirm your account
          </p>
        </RegisterMessageContent>
        <RegisterAction>
          <Link to={`/dashboard/course/${courseId}/class`}>
            <ButtonStyled type="button">Go to course</ButtonStyled>
          </Link>
        </RegisterAction>
      </RegisterFormInput>
    );
  }

  render() {
    return (
      <LoginContainerWrap>
        <BackgroundLeft />
        <LoginBlockRight>
          <StudentRegisterForm />
        </LoginBlockRight>
      </LoginContainerWrap>
    );
  }
}
