import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import LeftSideBarTest from '../../components/LeftSideBar/LeftSideBarTest';

import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';

import {
  selectors as CourseSelectors,
} from '../../reducers/courses';

import {
  selectors as SectionExamSelectors,
  actions as SectionExamActions,
} from '../../reducers/sectionExam';
import {
  selectors as LeftSidebarSelectors,
  actions as LeftSidebarActions,
} from '../../reducers/leftSideBar';

import {
  selectors as UserSelectors,
} from '../../reducers/user';
import {
  selectors as CurriculumSelectors,
} from '../../reducers/curriculums';

import { ROLE_TYPE } from '../../utils/enums';

const mapStateToProps = (state, { match }) => {
  const courseIdSelected = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const shouldDisplaySectionSelect = !!courseIdSelected;
  const courseData = CourseSelectors.getCourseById(state, courseIdSelected);
  const curriculumId = _get(courseData, 'curriculum', '');
  const curriculumName = CurriculumSelectors.getCurriculumById(state, curriculumId).name;
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    sectionDetail,
    curriculumId,
    curriculumName,
    sectionId,
    courseId: courseIdSelected,
    shouldDisplaySectionSelect,
    isSideBarClose: LeftSidebarSelectors.getSideBar(state),
    examSessons: SectionExamSelectors.getExamSessionList(state),
    isGettingSectionExam: SectionExamSelectors.isGettingSectionExam(state),
    isTeacher: UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR,
  };
};

const mapDispachToProps = {
  sectionExamGet: SectionExamActions.sectionExamGet,
  toggleLeftSidebar: LeftSidebarActions.toggleLeftSidebar,
  sectionExamAdd: SectionExamActions.sectionExamAdd,
  sectionExamDelete: SectionExamActions.sectionExamDelete,
  sectionExamUpdate: SectionExamActions.sectionExamUpdate,

};

export default withRouter(
  connect(mapStateToProps, mapDispachToProps)(LeftSideBarTest),
);
