import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import CONSTANTS from '../../utils/constants';
import FormInput from '../Generals/FormInput/FormInput';
import { QuickInviteContainer } from './CourseManagementInviteStudent.style';
import { FormGroupSection } from '../CourseManagementCreateSectionForm/CourseManagementCreateSectionForm.style';
import { FormStyled } from '../StudentRegister/StudentRegister.style';
import {
  ActionForm,
  Button,
} from '../Generals/stylesheets/Button.style';
import {
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().email('Invalid Email').trim().required('Required'),
  grade: Yup.string().trim().required('Required'),
});

const initialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  grade: '',
};

export default class QuickInviteStudentForm extends PureComponent {
  static propTypes = {
    submitInviteStudent: PropTypes.func.isRequired,
    sectionId: PropTypes.string,
  }

  handleSubmit = (values, { resetForm }) => {
    const { submitInviteStudent, sectionId } = this.props;
    submitInviteStudent([values], sectionId);
    resetForm();
  }

  render() {
    return (
      <QuickInviteContainer>
        <BlockWrap>
          <BlockTitle>Quick Add</BlockTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            <FormStyled noValidate>
              <FormGroupSection>
                <FormInput
                  type="text"
                  name="firstName"
                  label="First Name"
                />
              </FormGroupSection>
              <FormGroupSection>
                <FormInput
                  type="text"
                  name="middleName"
                  label="Middle Name"
                />
              </FormGroupSection>
              <FormGroupSection>
                <FormInput
                  type="text"
                  name="lastName"
                  label="Last Name"
                />
              </FormGroupSection>
              <FormGroupSection>
                <FormInput
                  type="email"
                  name="email"
                  label="Email"
                />
              </FormGroupSection>
              <FormGroupSection>
                <FormInput
                  type="select"
                  name="grade"
                  label="Grade Level (optional)"
                  options={CONSTANTS.GRADE_OPTIONS}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.grade_level}
                />
              </FormGroupSection>
              <ActionForm>
                <Button full>
                  Add
                  <i className="chalktalk-icon-add" />
                </Button>
              </ActionForm>
            </FormStyled>
          </Formik>
        </BlockWrap>
      </QuickInviteContainer>
    );
  }
}
