import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import moment from 'moment';
import UnitPracticeDetail from '../../components/UnitPracticeDetail';
import { selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
// import {
//   actions as sectionActivityActions,
//   selectors as sectionActivitySelectors,
// } from '../../reducers/activity';
import { selectors as UserSelectord } from '../../reducers/user';
import { actions as PracticeActions, selectors as PracticeSelectors } from '../../reducers/practice';
import { actions as UserActivityActions, selectors as UserActivitySelectors } from '../../reducers/userActivities';
// import { calculateSum } from '../../utils/commonUtils';
import { ROLE_TYPE } from '../../utils/enums';
import {
  actions as LessonAssignmentActions,
  selectors as LessonAssignmentSelectors,
} from '../../reducers/lessonAssignment';
import { selectors as sectionSelectors } from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const unitId = _get(match, 'params.unitId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const userRole = UserSelectord.getUserRole(state);
  let practiceSummaryByUserId = {};
  let students = [];
  // let scoreByUserId = {};

  if (userRole !== ROLE_TYPE.STUDENT) {
    students = sectionStudentSelectors.getStudentListWithFilter(state, sectionId);
    const practiceSummary = sectionSelectors.getPracticeSummaryOfUnit(state, unitId);

    practiceSummaryByUserId = practiceSummary.reduce((prevValue, item) => {
      const {
        user: userId, score, max_score: maxScore, last_activity: lastActivity, time_on_task: timeOnTask,
      } = item;
      const curerntValue = _get(prevValue, userId, {});
      const {
        score: currentScore = 0,
        maxScore: currentMaxScore = 0,
        timeOnTask: currentTimeOnTask = 0,
        lastActivity: currentLastActivity = lastActivity,
      } = curerntValue;

      return {
        ...prevValue,
        [userId]: {
          score: currentScore + score,
          maxScore: currentMaxScore + maxScore,
          timeOnTask: currentTimeOnTask + timeOnTask,
          lastActivity: moment(lastActivity).isAfter(moment(currentLastActivity)) ? lastActivity : currentLastActivity,
        },
      };
    }, {});

    // const sectionPracticeList = sectionActivitySelectors.sectionPracticeActivityList(state);
    // const scoreList = calculateSum(sectionPracticeList, students);
    // scoreByUserId = _keyBy(scoreList, 'id');
  }

  const assignedStatus = LessonAssignmentSelectors.getLAByUnitId(state, unitId);
  const assignedStatusByUserId = _keyBy(assignedStatus, 'assigned_to');

  return {
    assignedStatusByUserId,
    practiceSummaryByUserId,
    lessons: sectionUnitSelectors.getAllLessonOfUnit(state, unitId),
    isGettingSectionUnit: sectionUnitSelectors.isGettingSectionUnit(state),
    students,
    // isGetSectionPractice: sectionActivitySelectors.isGetSectionPractice(state),

    currentUserId: UserSelectord.getUserId(state),
    userRole: UserSelectord.getUserRole(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    isInitializing: PracticeSelectors.isInitializing(state),
    isInitialized: PracticeSelectors.isInitialized(state),

    isGettingUserPracticeSessionActivities: UserActivitySelectors.isGettingUserPracticeSessionActivities(state),
    userPracticeActivities: UserActivitySelectors.getUserUnitPracticeSessionActivities(
      state,
      sectionId,
      unitId,
    ),
  };
};

const mapDispatchToProps = ({
  assignLesson: LessonAssignmentActions.assignLesson,
  unAssignLesson: LessonAssignmentActions.unAssignLesson,
  initializePracticeSession: PracticeActions.initializePracticeSession,
  getAllPracticeSessionActivities: UserActivityActions.getAllPracticeSessionActivities,
  lessonAssignmentDetail: LessonAssignmentActions.lessonAssignmentDetail,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnitPracticeDetail));
