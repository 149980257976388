import styled from 'styled-components';

export const Close = styled.i`
  margin-right: 0.5em;
  color: red;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: -40px;
  font-size: 20px;
  color: white;
`;

export const Heading = styled.h1`
  color: #f6a527;
  font-family: MontserratBold;
  font-size: 16px;
`;

export const SubHeading = styled.h2`
  color: #8d8d8d;
  font-family: MontserratMedium;
  font-size: 14px;
`;

export const Illustration = styled.img`
  width: 400px;
`;

export const Body = styled.p`
  color: #000000;
  font-family: MontserratMedium;
  padding: 20px;
`;

export const Button = styled.button`
  height: 2.5em;
  padding: 0.575em 1em;
  border: none;
  font-family: MontserratMedium;
  margin: 0 auto;
  background-color: #2196f3;
  color: white;
  border-radius: 20px;
`;
