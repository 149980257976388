import React from 'react';
import SubscriptionForm from '../../containers/Modals/SubscriptionForm';
import CourseCreatedModal from '../../containers/Modals/CourseCreatedModal';
import SetupYourCourse from '../../containers/Modals/SetupYourCourse';

const MODAL_STEP = {
  SUBSCRIPTION_FORM: 'SUBSCRIPTION_FORM',
  REQUEST_INVITE: 'REQUEST_INVITE',
  CREATE_COURSE_FORM: 'CREATE_COURSE_FORM',
};

class CourseSetupv2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: MODAL_STEP.SUBSCRIPTION_FORM,
    };
  }

  openCreateCourseForm = () => {
    this.setState({
      currentStep: MODAL_STEP.CREATE_COURSE_FORM,
    });
  }

  openRequestInviteModal = (subscriptionData) => {
    this.setState({
      currentStep: MODAL_STEP.REQUEST_INVITE,
      subscriptionData,
    });
  }

  render() {
    const { currentStep, subscriptionData } = this.state;

    switch (currentStep) {
      case MODAL_STEP.SUBSCRIPTION_FORM:
        return (
          <SubscriptionForm
            openRequestInviteModal={this.openRequestInviteModal}
            openCreateCourseForm={this.openCreateCourseForm}
          />
        );
      case MODAL_STEP.REQUEST_INVITE:
        return <CourseCreatedModal subscriptionData={subscriptionData} onClose={this.props.onClose} />;
      case MODAL_STEP.CREATE_COURSE_FORM:
        return <SetupYourCourse onClose={this.props.onClose} />;
      default:
        return null;
    }
  }
}
export default CourseSetupv2;
