import { connect } from 'react-redux';
import _get from 'lodash/get';
import UnitPracticeTeacherReview from '../../components/UnitPracticeTeacherReview';
import { actions as SectionUnitActions, selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as SectionStudentSelectors, actions as SectionStudentActions } from '../../reducers/sectionStudent';
import { actions as ActivityActions, selectors as ActivitySelectors } from '../../reducers/activity';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';

const mapStateToProps = (state, { match }) => {
  const { params } = match;
  const {
    unitId, sectionId, studentId,
  } = params;
  const studentList = SectionStudentSelectors.getStudentByIds(state, sectionId);
  const currentUser = studentList[studentId];
  const practiceList = ActivitySelectors.sectionPracticeActivityListByUserId(state, studentId);
  const practiceListFilter = practiceList.filter(item => item.ended);

  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);

  return {
    currentUser,
    courseData,
    lessons: sectionUnitSelectors.getAllLessonOfUnit(state, unitId),
    isLoading: SectionStudentSelectors.isGettingStudentList(state),
    isGettingLesson: sectionUnitSelectors.isGettingLesson(state),
    practiceList: practiceListFilter,
  };
};

const mapDispatchToProps = {
  getSessionPracticeSessionActivity: ActivityActions.getSessionPracticeSessionActivity,
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPracticeTeacherReview);
