import React from 'react';
import { useIsQuestionLocked } from '@components/PracticeV2/Provider/PracticeProvider';
import { GridItem } from './AnswerGridChoice.style';

type Props = {
  rowData: {
    text: string;
    value: string;
  };
  columnValue: string;
  columnIndex: number;
  onCellClick: (columnIndex: number, value: string) => void;
};

const AnswerGridChoiceCell = ({
  rowData,
  columnValue,
  columnIndex,
  onCellClick,
}: Props) => {
  const { text, value } = rowData;
  const hasValue = text;
  const isQuestionLocked = useIsQuestionLocked();
  const isSelected = columnValue === value && value !== '_'; // no display empty cell
  return (
    <GridItem
      noValue={!hasValue}
      isSelected={isSelected}
      onClick={() => onCellClick(columnIndex, value)}
      className={`${isSelected ? 'selected' : ''}`}
      data-testid="answer-grid-choice-cell"
      disabled={isQuestionLocked}
    >
      <p>{text}</p>
    </GridItem>
  );
};

export default AnswerGridChoiceCell;
