import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import MediaQuery from 'react-responsive';
import { LoaderWrap } from './LoadingPlaceholder.style';

export default class TableLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <MediaQuery maxWidth={960}>
        {matches => (
          <LoaderWrap transformStyle mobile={matches}>
            <ContentLoader
              className="table-loader"
              height={150}
              width={400}
              speed={2}
              primaryColor="#ecebee"
              secondaryColor="#dedbe0"
            >
              <rect x="0" y="0" rx="0" ry="0" width="485" height="20" />
              <rect x="0" y="25" rx="0" ry="0" width="486" height="260" />
            </ContentLoader>
          </LoaderWrap>
        )}
      </MediaQuery>
    );
  }
}
