/* eslint-disable import/prefer-default-export */

import { getLessonAttendance } from '@apis/curriculum';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { LessonAttendanceResponse } from '../types';

export const useGetLessonAttendance = (lessonGroupId?: string | number) => {
  const params = useParams();
  // Despite being labeled as 'lessonId' in the router params, it actually represents the lessonGroupId.
  const effectiveLessonGroupId = lessonGroupId || params.lessonId;

  const queryResponse = useQuery<Array<LessonAttendanceResponse>>(
    ['getLessonAttendance', params.sectionId, effectiveLessonGroupId],
    async () => {
      const { response } = (await getLessonAttendance(
        effectiveLessonGroupId,
        params.sectionId,
      )) as unknown as {
        response: { data: Array<LessonAttendanceResponse> };
      };

      return response.data || {};
    },
    {
      useErrorBoundary: true,
      // suspense: true,
      enabled: !!params.sectionId && !!effectiveLessonGroupId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse as { data: Array<LessonAttendanceResponse> };
};
