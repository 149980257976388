/* eslint-disable import/prefer-default-export */
import axios, { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import {
  SkillRecord,
  StandardRecord,
} from '@components/Component/Lesson/LessonContainer/Types';
import { handleError } from './utils';

export const getStandards = async (
  syllabusgroupId: number | null = null,
  skills: SkillRecord[] = [],
  lessonGroupIds: number[] = [],
): Promise<StandardRecord[] | undefined> => {
  let url = `/api/standards/`;

  const params = new URLSearchParams();

  if (syllabusgroupId) {
    params.append('syllabusgroup_id', syllabusgroupId.toString());
  }

  if (skills.length > 0) {
    params.append('skill_ids', skills.map((skill) => skill.id).join(','));
  }

  if (lessonGroupIds.length > 0) {
    params.append('lesson_group_ids', lessonGroupIds.join(','));
  }

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  try {
    const response: AxiosResponse = await axios.get(url);
    return response.data;
  } catch (error) {
    handleError(error);
    return undefined;
  }
};

export const useGetStandards = (
  syllabusId: number,
  skills: SkillRecord[],
  lessonGroupIds: number[] = [],
) => {
  const queryResponse = useQuery(
    ['useGetStandards', syllabusId, skills, lessonGroupIds],
    async () => {
      const response = await getStandards(syllabusId, skills, lessonGroupIds);
      return response || [];
    },
    {
      suspense: true,
      staleTime: 60 * 60 * 24,
      refetchOnMount: false,
    },
  );

  return { data: queryResponse?.data, isLoading: queryResponse.isLoading };
};

export const useGetStandardsBySyllabus = (syllabusId: number) => useGetStandards(syllabusId, [], []);
