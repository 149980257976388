import styled, { css } from 'styled-components';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

export const LessonCoveredTitleWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const LessonCoveredTitle = styled.p`
  color: #000000;
  font-size: 0.875rem;
`;
export const LessonCoveredStanders = styled.p`
  color: #8d8d8d;
  font-size: 0.875rem;
`;
export const LessonListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LessonRow = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-size: 0.625rem;
  i {
    margin-inline-end: 0.5rem;
    &:before {
    ${({ active }) => !active && css`
      color:  #D1D1D4;
    `}
  }
  }
`;
export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "white",
    fontSize:'0.875rem',
    padding: '1.5rem',
    boxShadow: '0px 0px 1.25rem #00000065',
  },
}))(Tooltip);
