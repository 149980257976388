/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors as curriculumLessonSelectors } from '.';

/**
 *
 * @returns [standard]
 */
export const useStandards = () => {
  const standards = useSelector((state) =>
    curriculumLessonSelectors.getLessonStandard(state),
  );

  return standards ? standards.map((standard) => standard.code) : [];
};

export const useLessonStandards = (lesson) => {
  const standards = React.useMemo(
    () => (lesson?.standards || []).map((standard) => standard?.code).flat(),
    [lesson],
  );
  const reduxStandards = useStandards();

  return lesson ? standards : reduxStandards;
};

export const useLessonDetails = () => {
  const { lessonId } = useParams();
  const lessonDetails = useSelector((state) =>
    curriculumLessonSelectors.getLessonDetailById(state, lessonId),
  );

  return lessonDetails;
};
