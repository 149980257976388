import React from 'react';
import { useGetExamEstimatedScore } from '@components/Component/Exams/hooks/query';
import StatisticsGroup from '@components/Component/Dashboard/StatisticsGroup';
import { StatisticProps } from '@components/Component/Dashboard/Statistic';
import Box from '@components/Atoms/Box';
import { useParams } from 'react-router-dom';
import { OverallObject, SubjectSelectOptionValue } from './SubjectSelect';
import {
  useSectionEstimatedScoreReport,
  useSectionLexileLevelReport,
  useSubjectEstimatedScoreReport,
} from './hooks';

type EstimatedScoreSummaryProps = {
  selectedSubject: SubjectSelectOptionValue;
};

const EstimatedScoreSummary = ({ selectedSubject }: EstimatedScoreSummaryProps) => {
  const { data: estimatedScoreData } = useGetExamEstimatedScore();
  const { sectionId } = useParams();

  const estimatedScoreStats: StatisticProps[] = [];
  const subjectEstimatedScores = useSubjectEstimatedScoreReport();

  const { sectionDelta, sectionMaxScore, sectionScoreRange } =
    useSectionEstimatedScoreReport(sectionId);
  if (selectedSubject.id !== OverallObject.id) {
    const subjectScoreRange =
      subjectEstimatedScores.find((item) => item.id === selectedSubject.id)?.scoreRange ||
      '0 - 0';
    estimatedScoreStats.push({
      delta: sectionDelta,
      label: 'Estimated Score',
      subtext: `out of ${sectionMaxScore}`,
      value: subjectScoreRange,
    });
  } else {
    estimatedScoreStats.push({
      delta: sectionDelta,
      label: 'Estimated Score',
      subtext: `out of ${sectionMaxScore}`,
      value: sectionScoreRange,
    });
  }

  // lexile
  const showLexile = estimatedScoreData?.subjects?.some((subject) => subject.show_lexile);
  const { gradeLevelDelta, gradeLevelValue, lexileValue } =
    useSectionLexileLevelReport(sectionId);

  if (showLexile) {
    estimatedScoreStats.push({
      delta: gradeLevelDelta,
      label: 'Lexile Level',
      subtext: lexileValue,
      value: `${gradeLevelValue}th`,
    });
  }

  return (
    <Box display="flex" justifyContent="space-between">
      <Box flex={1} pr="10px">
        <StatisticsGroup
          image="/images/bg-score.png"
          title="Estimated Scores"
          statistics={estimatedScoreStats}
        />
      </Box>
      <Box flex={1} pl="10px">
        <StatisticsGroup
          title="Estimated Subject Scores"
          statistics={subjectEstimatedScores.map((stat) => ({
            label: stat.name,
            subtext: `out of ${stat.maxScore}`,
            value: stat.scoreRange,
          }))}
        />
      </Box>
    </Box>
  );
};

export default EstimatedScoreSummary;
