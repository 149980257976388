const ColumnSize = [
  { percent: '20', smallSize: true },
  { percent: '20', smallSize: true },
  { smallSize: true, textLowercase: true },
  {
    percent: '15', smallSize: true, textCenter: true, italic: true,
  },
  { size: '80', smallSize: true, textCenter: true },
];

export default ColumnSize;
