/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const StyledCell = styled.div<{
  isOver?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding-block: 0.5em;
  position: relative;
  background-color: transparent;
  min-height: 3rem;
  ${({ isOver }) =>
    isOver &&
    css`
      background-color: #ccc;
    `}
`;
