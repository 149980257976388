import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import ScheduleAddLessonSessionForm from '../../components/ScheduleAddLessonSessionForm';
import {
  actions as SessionActions,
} from '../../reducers/sectionSession';
import { selectors as ScheduleModalSelectors } from '../../reducers/scheduleModal';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  return {
    sectionId,
    sessionDate: ScheduleModalSelectors.getSessionDate(state),
  };
};

const mapDispatchToProps = {
  sectionsSessionAdd: SessionActions.sectionsSessionAdd,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleAddLessonSessionForm));
