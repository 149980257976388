import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
`;

export const TypeContainer = styled.div`
  display: flex;
  font-family: "Open Sans", sans-serif;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Type = styled.div`
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.5s;

  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const Arrow = styled.img`
  width: 50px;
  margin-top: -100px;
  transition: 1s;
  @media (max-width: 480px) {
    width: 40px;
    margin-top: 0px;
  }

  &:hover {
    transform: translate(20px, 0);
    -webkit-transform: translate(20px, 0); /** Safari & Chrome **/
    -o-transform: translate(20px, 0); /** Opera **/
    -moz-transform: translate(20px, 0); /** Firefox **/
  }
`;

export const Paragraph = styled.p`
  color: #707070;
  font-size: 16px;
  margin: 10px 0 50px;
  line-height:normal;
  @media (max-width: 480px) {
    font-size: 14px;
    text-align: center;
    margin: 5px 0 40px;
  }
`;

export const TypeImg = styled.img`
  width: 200px;
  height: 200px;
`;

export const TitleImg = styled.h1`
  font-weight: 900;
  color: #000;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 38px;
  font-weight: bolder;
  margin: 22px 0px;
  line-height: initial;
  @media (max-width: 480px) {
    font-size: 22px;
    text-align: center;
  }
`;
