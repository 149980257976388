import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _filter from 'lodash/filter';
import _set from 'lodash/set';
import _orderBy from 'lodash/orderBy';
import _findLast from 'lodash/findLast';
import moment from 'moment';
import { createSelector } from 'reselect';
import { formatDate } from '../../utils/func-utils';
import { SECTION_STATUS } from '../../utils/enums';
import { getFeatureFlagAccess } from '../../utils/featureFlags';

// action definition
export const SECTION_EXAMS_GET = 'sectionExam/GET';
export const SECTION_EXAMS_GET_SUCCESS = 'sectionExam/GET_SUCCESS';
export const SECTION_EXAMS_GET_FAIL = 'sectionExam/GET_FAIL';

export const SECTION_EXAMS_GET_DETAIL = 'sectionExam/GET_DETAIl';
export const SECTION_EXAMS_GET_DETAIL_SUCCESS = 'sectionExam/GET_DETAIl_SUCCESS';
export const SECTION_EXAMS_GET_DETAIL_FAIL = 'sectionExam/GET_DETAIl_FAIL';

export const SECTION_EXAMS_ADD = 'sectionExam/ADD';
export const SECTION_EXAMS_ADD_SUCCESS = 'sectionExam/ADD_SUCCESS';
export const SECTION_EXAMS_ADD_FAIL = 'sectionExam/ADD_FAIL';

export const SECTION_EXAMS_UNLOCK = 'sectionExam/UNLOCK';
export const SECTION_EXAMS_UNLOCK_SUCCESS = 'sectionExam/UNLOCK_SUCCESS';
export const SECTION_EXAMS_UNLOCK_FAIL = 'sectionExam/UNLOCK_FAIL';

export const SECTION_EXAMS_PAUSE = 'sectionExam/PAUSE';
export const SECTION_EXAMS_PAUSE_SUCCESS = 'sectionExam/PAUSE_SUCCESS';
export const SECTION_EXAMS_PAUSE_FAIL = 'sectionExam/PAUSE_FAIL';

export const SECTION_EXAMS_RESET = 'sectionExam/RESET';
export const SECTION_EXAMS_RESET_SUCCESS = 'sectionExam/RESET_SUCCESS';
export const SECTION_EXAMS_RESET_FAIL = 'sectionExam/RESET_FAIL';

export const SECTION_EXAMS_RESET_EXAM = 'sectionExam/RESET_EXAM';
export const SECTION_EXAMS_RESET_EXAM_SUCCESS = 'sectionExam/RESET_EXAM_SUCCESS';
export const SECTION_EXAMS_RESET_EXAM_FAIL = 'sectionExam/RESET_EXAM_FAIL';

export const SECTION_EXAMS_UPDATE = 'sectionExam/UPDATE';
export const SECTION_EXAMS_UPDATE_SUCCESS = 'sectionExam/UPDATE_SUCCESS';
export const SECTION_EXAMS_UPDATE_FAIL = 'sectionExam/UPDATE_FAIL';

export const SECTION_EXAMS_DELETE = 'sectionExam/DELETE';
export const SECTION_EXAMS_DELETE_SUCCESS = 'sectionExam/DELETE_SUCCESS';
export const SECTION_EXAMS_DELETE_FAIL = 'sectionExam/DELETE_FAIL';

export const SECTION_EXAM_REVIEW_UNLOCK = 'sectionExamReview/UNLOCK';
export const SECTION_EXAM_REVIEW_UNLOCK_SUCCESS = 'sectionExamReview/UNLOCK_SUCCESS';
export const SECTION_EXAM_REVIEW_UNLOCK_FAIL = 'sectionExamReview/UNLOCK_FAIL';

export const SECTION_EXAM_REVIEW_LOCK = 'sectionExamReview/LOCK';
export const SECTION_EXAM_REVIEW_LOCK_SUCCESS = 'sectionExamReview/LOCK_SUCCESS';
export const SECTION_EXAM_REVIEW_LOCK_FAIL = 'sectionExamReview/LOCK_FAIL';

export const SECTION_EXAM_GET_V2_ACCESS = 'exam/SECTION_EXAM_GET_V2_ACCESS';
export const SECTION_EXAM_GET_V2_ACCESS_SUCCESS = 'exam/SECTION_EXAM_GET_V2_ACCESS_SUCCESS';
export const SECTION_EXAM_GET_V2_ACCESS_FAIL = 'exam/SECTION_EXAM_GET_V2_ACCESS_FAIL';

// ====================================

const sectionExamUnlock = (sessionId, examSectionId) => ({
  type: SECTION_EXAMS_UNLOCK,
  sessionId,
  examSectionId,
});

const sectionExamUnlockSuccess = (sessionId, examSectionId) => ({
  type: SECTION_EXAMS_UNLOCK_SUCCESS,
  sessionId,
  examSectionId,
});

const sectionExamUnlockFail = (error) => ({
  type: SECTION_EXAMS_UNLOCK_FAIL,
  error,
});
// ====================================

const sectionExamReviewUnlock = (sessionId, examSectionId) => ({
  type: SECTION_EXAM_REVIEW_UNLOCK,
  sessionId,
  examSectionId,
});

const sectionExamReviewUnlockSuccess = (sessionId, examSectionId) => ({
  type: SECTION_EXAM_REVIEW_UNLOCK_SUCCESS,
  sessionId,
  examSectionId,
});

const sectionExamReviewUnlockFail = (error) => ({
  type: SECTION_EXAM_REVIEW_UNLOCK_FAIL,
  error,
});
// ====================================

const sectionExamReviewLock = (sessionId, examSectionId) => ({
  type: SECTION_EXAM_REVIEW_LOCK,
  sessionId,
  examSectionId,
});

const sectionExamReviewLockSuccess = (sessionId, examSectionId) => ({
  type: SECTION_EXAM_REVIEW_LOCK_SUCCESS,
  sessionId,
  examSectionId,
});

const sectionExamReviewLockFail = (error) => ({
  type: SECTION_EXAM_REVIEW_LOCK_FAIL,
  error,
});
// ====================================

const sectionExamPause = (sessionId, examSectionId) => ({
  type: SECTION_EXAMS_PAUSE,
  sessionId,
  examSectionId,
});

const sectionExamPauseSuccess = (sessionId, examSectionId) => ({
  type: SECTION_EXAMS_PAUSE_SUCCESS,
  sessionId,
  examSectionId,
});

const sectionExamPauseFail = (error) => ({
  type: SECTION_EXAMS_PAUSE_FAIL,
  error,
});

// ====================================

const sectionExamReset = (sessionId, examSectionId, studentId = null) => ({
  type: SECTION_EXAMS_RESET,
  sessionId,
  examSectionId,
  studentId,
});

const sectionExamResetSuccess = (sessionId, examSectionId) => ({
  type: SECTION_EXAMS_RESET_SUCCESS,
  sessionId,
  examSectionId,
});

const sectionExamResetFail = (error) => ({
  type: SECTION_EXAMS_RESET_FAIL,
  error,
});

// ====================================
const sectionExamResetExamSection = (sessionId, examSectionId, studentId = null) => ({
  type: SECTION_EXAMS_RESET_EXAM,
  sessionId,
  examSectionId,
  studentId,
});

const sectionExamResetExamSectionSuccess = (sessionId, examSectionId) => ({
  type: SECTION_EXAMS_RESET_SUCCESS,
  sessionId,
  examSectionId,
});

const sectionExamResetExamSectionFail = (error) => ({
  type: SECTION_EXAMS_RESET_FAIL,
  error,
});

// ====================================

const sectionExamGetDetail = (examSessionId) => ({
  type: SECTION_EXAMS_GET_DETAIL,
  examSessionId,
});

const sectionExamGetDetailSuccess = (examSessionId, payload) => ({
  type: SECTION_EXAMS_GET_DETAIL_SUCCESS,
  payload,
  examSessionId,
});

const sectionExamGetDetailFail = (error) => ({
  type: SECTION_EXAMS_GET_DETAIL_FAIL,
  error,
});

// ====================================

const sectionExamAdd = (examInfo) => ({
  type: SECTION_EXAMS_ADD,
  examInfo,
});

const sectionExamAddSuccess = (examInfo) => ({
  type: SECTION_EXAMS_ADD_SUCCESS,
  examInfo,
});

const sectionExamAddFail = (error) => ({
  type: SECTION_EXAMS_ADD_FAIL,
  error,
});

// ====================================

const sectionExamGet = (sectionId, shouldFetchAgain) => ({
  type: SECTION_EXAMS_GET,
  sectionId,
  shouldFetchAgain
});

const sectionExamGetSuccess = (examList, sectionId) => ({
  type: SECTION_EXAMS_GET_SUCCESS,
  examList,
  sectionId,
});

const sectionExamGetFail = (error) => ({
  type: SECTION_EXAMS_GET_FAIL,
  error,
});

// ====================================

const sectionExamDelete = (sectionId) => ({
  type: SECTION_EXAMS_DELETE,
  sectionId,
});

const sectionExamDeleteSuccess = (id) => ({
  type: SECTION_EXAMS_DELETE_SUCCESS,
  id,
});

const sectionExamDeleteFail = (error) => ({
  type: SECTION_EXAMS_DELETE_FAIL,
  error,
});

// ====================================

const sectionExamUpdate = (sectionId, examId, examInfo) => ({
  type: SECTION_EXAMS_UPDATE,
  sectionId,
  examId,
  examInfo,
});

const sectionExamUpdateSuccess = (examId, examInfo) => ({
  type: SECTION_EXAMS_UPDATE_SUCCESS,
  examId,
  examInfo,
});

const sectionExamUpdateFail = (examId, error) => ({
  type: SECTION_EXAMS_UPDATE_FAIL,
  examId,
  error,
});

// ====================================

const fetchExamV2Access = (hasExamV2Access) => ({
  type: SECTION_EXAM_GET_V2_ACCESS,
  payload: {
    hasExamV2Access,
  },
});

 const fetchExamV2AccessSuccess = (hasExamV2Access) => ({
  type: SECTION_EXAM_GET_V2_ACCESS_SUCCESS,
  payload: {
    hasExamV2Access,
  },
});

 const fetchExamV2AccessFailed = (errorMessage) => ({
  type: SECTION_EXAM_GET_V2_ACCESS_FAIL,
  payload: {
    errorMessage,
  },
});


export const actions = {
  sectionExamGet,
  sectionExamGetSuccess,
  sectionExamGetFail,

  sectionExamAdd,
  sectionExamAddSuccess,
  sectionExamAddFail,

  sectionExamGetDetail,
  sectionExamGetDetailSuccess,
  sectionExamGetDetailFail,

  sectionExamUnlock,
  sectionExamUnlockSuccess,
  sectionExamUnlockFail,

  sectionExamPause,
  sectionExamPauseSuccess,
  sectionExamPauseFail,

  sectionExamReset,
  sectionExamResetSuccess,
  sectionExamResetFail,

  sectionExamUpdate,
  sectionExamUpdateSuccess,
  sectionExamUpdateFail,

  sectionExamDelete,
  sectionExamDeleteSuccess,
  sectionExamDeleteFail,

  sectionExamReviewUnlock,
  sectionExamReviewUnlockSuccess,
  sectionExamReviewUnlockFail,

  sectionExamReviewLock,
  sectionExamReviewLockSuccess,
  sectionExamReviewLockFail,

  sectionExamResetExamSection,
  sectionExamResetExamSectionSuccess,
  sectionExamResetExamSectionFail,

  fetchExamV2Access,
  fetchExamV2AccessSuccess,
  fetchExamV2AccessFailed
};

// ====================================

const getExamSessionIds = ({ sectionExam }) => _get(sectionExam, 'examSessionIds', []);
const getExamBySessionId = ({ sectionExam }) => _get(sectionExam, 'examBySessionId', {});
const getExamSessionList = createSelector(getExamSessionIds, getExamBySessionId, (ids, byIds) => ids.map((id) => byIds[id]));
const getExamDetailById = ({ sectionExam }, examSessionId) => _get(sectionExam, `examBySessionId.${examSessionId}`, {});
const getScoreColumns = (state, examId) => {
  const examDetail = getExamDetailById(state, examId);
  const scoreColumn = _get(examDetail, 'exam.scores', []);
  // only display column if has field is_subscore false
  return _filter(scoreColumn, ({ is_subscore: isSubscore }) => isSubscore === false);
};
const getErrorMsg = ({ sectionExam }) => sectionExam.error;
const isAdding = ({ sectionExam }) => sectionExam.isAdding;
const isSubmitSuccessfully = ({ sectionExam }) => sectionExam.submitSuccess;

const getUpdateErrorMsg = ({ sectionExam }) => sectionExam.getUpdateErrorMsg || '';
const isUpdating = ({ sectionExam }) => sectionExam.isUpdating;
const isUpdateSuccessfully = ({ sectionExam }) => sectionExam.updateSuccess;
const isGettingSectionExam = ({ sectionExam }) => sectionExam.isGetting;
const getExamV2Access = (state, courseId, sectionId) => {
  const ExamAccessData = _get(state.sectionExam, 'hasExamV2Access', '');
  return getFeatureFlagAccess(ExamAccessData, courseId, sectionId, state);
}

const isUpcomingExam = createSelector(
  getExamSessionList,
  (examSessionList) => {
    const examSessionLength = examSessionList.length;

    if (examSessionLength === 0) {
      return null;
    }

    const today = formatDate();
    const endDateSort = _orderBy(examSessionList, 'end_date', 'asc');
    const latestExam = endDateSort.find(({ end_date }) => end_date >= today);

    if (!latestExam) {
      return examSessionList[examSessionLength - 1];
    }

    return latestExam;
  },
);

const getLastestExam = createSelector(
  getExamSessionList,
  (examSessionList) => {
    const examSessionLength = examSessionList.length;

    if (examSessionLength === 0) {
      return null;
    }

    const today = formatDate();
    const startDateSort = _orderBy(examSessionList, 'start_date', 'asc');
    const latestExam = _findLast(startDateSort, (item) => item.start_date <= today);

    if (!latestExam) {
      return examSessionList[0];
    }

    return latestExam;
  },
);

const getShouldFetch = ({ sectionExam }, sectionId) => _get(sectionExam, 'hasFetchedOfSectionId', null) != sectionId; // eslint-disable-line

export const selectors = {
  getExamBySessionId,
  getExamSessionList,
  getExamDetailById,
  getErrorMsg,
  isAdding,
  getScoreColumns,
  isSubmitSuccessfully,

  getUpdateErrorMsg,
  isUpdating,
  isUpdateSuccessfully,
  isUpcomingExam,
  getLastestExam,
  getShouldFetch,

  isGettingSectionExam,
  getExamV2Access
};

// ====================================

// reducers

const initialState = {
  isGetting: false, // is get review detail of section
  examSessionIds: [],
  examBySessionId: {},

  isAdding: false,
  error: null,
  submitSuccess: false,
  details: {},

  isUpdating: false,
  updateSuccess: false,
  updateError: '',

  isDelete: false,
  deleteSuccess: false,
  deleteError: null,
  hasFetchedOfSectionId: null,
  hasExamV2Access: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // add session of section
    case SECTION_EXAMS_GET: {
      const { sectionId, shouldFetchAgain } = action;
      const shouldFetch = shouldFetchAgain? shouldFetchAgain : state.hasFetchedOfSectionId != sectionId; // eslint-disable-line
      return { ...state, isGetting: shouldFetch };
    }

    case SECTION_EXAMS_GET_SUCCESS: {
      const { examList, sectionId } = action;
      const examBySessionId = _keyBy(examList, 'id'); // exam session id
      const examSessionIds = examList.map((exam) => exam.id);

      return {
        ...state,
        isGetting: false,
        examSessionIds,
        examBySessionId,
        hasFetchedOfSectionId: sectionId,
      };
    }
    case SECTION_EXAMS_GET_FAIL:
      return { ...state, isGetting: false };

    case SECTION_EXAMS_ADD:
      return {
        ...state,
        isAdding: true,
        error: null,
        submitSuccess: false,
      };

    case SECTION_EXAMS_ADD_SUCCESS: {
      const { examInfo } = action;
      const { id } = examInfo;

      return {
        ...state,
        isAdding: false,
        examSessionIds: [
          ...state.examSessionIds,
          id,
        ],
        examBySessionId: {
          ...state.examBySessionId,
          [id]: examInfo,
        },
        submitSuccess: true,
      };
    }

    case SECTION_EXAMS_ADD_FAIL: {
      const { payload } = action;
      return {
        ...state,
        isAdding: false,
        error: payload,
      };
    }

    // ======================

    case SECTION_EXAMS_UPDATE:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
        updateSuccess: false,
      };

    case SECTION_EXAMS_UPDATE_SUCCESS: {
      const { examInfo } = action;
      const { id } = examInfo;

      return {
        ...state,
        isUpdating: false,
        examBySessionId: {
          ...state.examBySessionId,
          [id]: examInfo,
        },
        updateSuccess: true,
      };
    }

    case SECTION_EXAMS_UPDATE_FAIL: {
      const { error } = action;

      return {
        ...state,
        isUpdating: false,
        updateError: error,
      };
    }
    // ======================

    case SECTION_EXAMS_GET_DETAIL_SUCCESS: {
      const { payload, examSessionId } = action;

      return {
        ...state,
        examBySessionId:{
          ...state.examBySessionId,
          [examSessionId]:payload,
        },
        details: {
          ...state.details,
          [examSessionId]: payload,
        },
      };
    }

    case SECTION_EXAMS_UNLOCK_SUCCESS: {
      const { sessionId, examSectionId } = action;
      const examDetail = _get(state, `examBySessionId.${sessionId}`, {});
      const examSectionList = _get(examDetail, 'session_metadata.sections', []);
      const newExamSectionList = examSectionList.map((item) => {
        if (item.section_id !== examSectionId) {
          return item;
        }

        return {
          ...item,
          section_status: SECTION_STATUS.UNLOCKED,
        };
      });

      const newExamDetail = _set(examDetail, 'session_metadata.sections', newExamSectionList);

      return {
        ...state,
        examBySessionId:{
          ...state.examBySessionId,
          [sessionId]:{
            ...newExamDetail,
          },
        },
        details: {
          ...state.details,
          [sessionId]: {
            ...newExamDetail,
          },
        },
      };
    }
    case SECTION_EXAM_REVIEW_UNLOCK_SUCCESS: {
      const { sessionId, examSectionId } = action;
      const examDetail = _get(state, `examBySessionId.${sessionId}`, {});
      const examSectionList = _get(examDetail, 'session_metadata.sections', []);
      const newExamSectionList = examSectionList.map((item) => {
        if (item.section_id !== examSectionId) {
          return item;
        }

        return {
          ...item,
          can_review: true,
        };
      });
      const newExamDetail = _set(examDetail, 'session_metadata.sections', newExamSectionList);
      return {
        ...state,
        examBySessionId:{
          ...state.examBySessionId,
          [sessionId]:{
            ...newExamDetail,
          },
        },
        details: {
          ...state.details,
          [sessionId]: {
            ...newExamDetail,
          },
        },
      };
    }
    case SECTION_EXAM_REVIEW_LOCK_SUCCESS: {
      const { sessionId, examSectionId } = action;
      const examDetail = _get(state, `examBySessionId.${sessionId}`, {});
      const examSectionList = _get(examDetail, 'session_metadata.sections', []);
      const newExamSectionList = examSectionList.map((item) => {
        if (item.section_id !== examSectionId) {
          return item;
        }

        return {
          ...item,
          can_review: false,
        };
      });
      const newExamDetail = _set(examDetail, 'session_metadata.sections', newExamSectionList);
      return {
        ...state,
        examBySessionId:{
          ...state.examBySessionId,
          [sessionId]:{
            ...newExamDetail,
          },
        },
        details: {
          ...state.details,
          [sessionId]: {
            ...newExamDetail,
          },
        },
      };
    }
    case SECTION_EXAMS_PAUSE_SUCCESS: {
      const { sessionId, examSectionId } = action;
      const examDetail = _get(state, `examBySessionId.${sessionId}`, {});
      const examSectionList = _get(examDetail, 'session_metadata.sections', []);
      const newExamSectionList = examSectionList.map((item) => {
        if (item.section_id !== examSectionId) {
          return item;
        }

        return {
          ...item,
          section_status: SECTION_STATUS.PAUSED,
        };
      });

      const newExamDetail = _set(examDetail, 'session_metadata.sections', newExamSectionList);

      return {
        ...state,
        examBySessionId:{
          ...state.examBySessionId,
          [sessionId]:{
            ...newExamDetail,
          },
        },
        details: {
          ...state.details,
          [sessionId]: {
            ...newExamDetail,
          },
        },
      };
    }
    case SECTION_EXAMS_DELETE:
      return {
        ...state,
        isDelete: true,
      };

    case SECTION_EXAMS_DELETE_SUCCESS: {
      const { id } = action;

      const examBySessionId = _get(state, 'examBySessionId', {});
      const examSessionIds = state.examSessionIds.filter((x) => x !== id);
      delete examBySessionId[id];

      return {
        ...state,
        deleteError: null,
        isDelete: false,
        deleteSuccess: true,
        examSessionIds: [...examSessionIds],
        examBySessionId,

      };
    }
    case SECTION_EXAMS_DELETE_FAIL: {
      const { error } = action;
      return {
        ...state,
        deleteError: error,
        isDelete: false,
        deleteSuccess: false,
      };
    }
 
    case SECTION_EXAM_GET_V2_ACCESS_SUCCESS: {
      const { hasExamV2Access } = action.payload;
      return {
        ...state,
        hasExamV2Access,
        errorMessage: '',
      };
    }
    case SECTION_EXAM_GET_V2_ACCESS_FAIL: {
      const { errorMessage } = action.payload;
      return {
        ...state,
        hasExamV2Access: false,
        errorMessage,
      };
    }
    default: {
      return { ...state };
    }
  }
}
