// action definition
export const DISTRICTS_FETCH_DISTRICT_LIST = 'districts/DISTRICTS_FETCH_DISTRICT_LIST';
export const DISTRICTS_FETCH_DISTRICT_LIST_SUCCESS = 'districts/DISTRICTS_FETCH_DISTRICT_LIST_SUCCESS';
export const DISTRICTS_FETCH_DISTRICT_LIST_FAIL = 'districts/DISTRICTS_FETCH_DISTRICT_LIST_FAIL';

// selectors
export const getDistrictList = ({ districts }) => districts.districtList;

export const selectors = {
  getDistrictList,
};

// action creators
export const fetchDistrictList = districtQuery => ({
  type: DISTRICTS_FETCH_DISTRICT_LIST,
  payload: {
    districtQuery,
  },
});

export const fetchDistrictListSuccess = districtList => ({
  type: DISTRICTS_FETCH_DISTRICT_LIST_SUCCESS,
  payload: {
    districtList,
  },
});

export const fetchDistrictListFailed = errorMsg => ({
  type: DISTRICTS_FETCH_DISTRICT_LIST_FAIL,
  payload: {
    errorMsg,
  },
});

export const actions = {
  fetchDistrictList,
  fetchDistrictListSuccess,
  fetchDistrictListFailed,
};

// reducers

const initialState = {
  districtList: [],
  isLoading: null,
  errorMsg: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DISTRICTS_FETCH_DISTRICT_LIST: {
      return {
        ...state,
        isLoading: true,
        errorMsg: false,
      };
    }

    case DISTRICTS_FETCH_DISTRICT_LIST_SUCCESS: {
      const { districtList } = action.payload;
      return {
        ...state,
        isLoading: false,
        districtList,
      };
    }

    case DISTRICTS_FETCH_DISTRICT_LIST_FAIL: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMsg,
      };
    }

    default: {
      return state;
    }
  }
}
