import styled, { css } from 'styled-components';

export const AddTeacherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 250px;
  overflow: auto;
  margin-top: 50px;
  form {
    width: 100%;
  }
  ${({ hasBeenAddedTeacher }) => !hasBeenAddedTeacher && css`
    justify-content: center;
  `}
`;

export const AddTeacherBtn = styled.button`
  width: 100%;
  background-color: white;
  font-size: 12pt;
  font-family: MontserratRegular, Arial;
  padding: 2px 0;
  border-radius: 13px;
  border: 3px dashed rgb(220, 220, 220);
  color: rgb(102, 102, 102);
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 10px;
  }
  i, span {
    margin: 0 5px;
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
  min-width: 457px;
  display: flex;
  align-items: center;
  & > div {
    width: 100%;
    margin-right: 10px;
  }
  i {
    color: #CBCBCB;
    margin-bottom: 20px;
  }

  ${({ isSectionCreationModal }) => isSectionCreationModal && css`
    padding: 0px 30px 0 30px;
    & > i {
      font-size: 15px;
    }
    &:last-child {
      margin-right: unset;
    }
  `}

  ${({ isSectionNameModal }) => isSectionNameModal && css`
    flex-direction: column;
    width 100%;
    padding: 0 3em;
    min-width: unset;
    margin: 0 auto;
    & > div, i {
      margin-bottom: unset !important;
    }
    & > i {
      font-size: 15px;
    }
    & > div {
      margin-right: unset;
    }
  `}
  @media (max-width: 768px) {
    min-width: 0px;
  }
`;

export const SectionSelectWrapper = styled.div`
  margin-top: 30px;
`;

export const ErrorText = styled.span`
  margin-top: 5px;
  color: red;
`;
