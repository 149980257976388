import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';

const TestRoute = ({
  authenticated,
  responseId,
  component: Component,
  allowed,
  location,
  path,
  ...rest
}) => (
  <Route
    path={path}
    {...rest}
    render={(props) => {
      if (authenticated && responseId) {
        return (
          <ChalkTalkErrorBoundary key={path}>
            <Component {...props} authenticated />;
          </ChalkTalkErrorBoundary>
        );
      }

      const pathname = location.pathname;
      const course = /course\/(\d+)\//.exec(pathname);
      const section = /section\/(\d+)\//.exec(pathname);
      const exam = /exam\/(\d+)\//.exec(pathname);

      if (course && section && exam) {
        return (
          <Redirect
            to={`/test/course/${course[1]}/section/${section[1]}/exam/${exam[1]}`}
          />
        );
      }
      return <Redirect to="/test" />;
    }}
  />
);

TestRoute.propTypes = {
  component: PropTypes.func,
  authenticated: PropTypes.bool,
  allowed: PropTypes.arrayOf(PropTypes.any),
  location: PropTypes.any,
  responseId: PropTypes.number,
};

TestRoute.defaultProps = {
  component: () => null,
  allowed: [],
};

export default TestRoute;
