/* eslint-disable jsx-a11y/accessible-emoji */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  RegisterItemWrapper,
  RegisterItem,
  RegisterWrap,
  ButtonStyled,
  RegisterMessageContent,
  RegisterCodeStyled,
  RegisterAction,
  RegisterFormTitle,
  RegisterFormWrapper,
  RegisterMessageContentError,
} from '../StudentRegister/StudentRegister.style';

class SectionEnroll extends PureComponent {
  state = {
    sectionCode: '',
  }

  componentWillUnmount() {
    const { sectionSetDefaultEnrollment } = this.props;
    sectionSetDefaultEnrollment();
  }

  handleSectionCodeChanged = ({ target }) => {
    this.setState({
      sectionCode: target.value,
    });
  }

  sectionRequestEnrollment = () => {
    const { sectionCode } = this.state;
    const { sectionRequestEnrollment } = this.props;

    sectionRequestEnrollment(sectionCode.trim());
  }

  renderRequestView = () => {
    const { sectionCode } = this.state;
    const { isRequestingEnrollment, errorMsg, isInModal } = this.props;
    return (
      <RegisterFormWrapper isStudentMobile>
        <RegisterFormTitle isInModal={isInModal}>
          {!isInModal && <h1>Join section</h1>}
          <h3>
            Enter section code provided to you to join others in the course.
          </h3>
        </RegisterFormTitle>
        <RegisterWrap isLoading isInModal={isInModal} isStudentMobile>

          <RegisterMessageContentError isInModal={isInModal}>
            <input
              type="text"
              value={sectionCode}
              onChange={this.handleSectionCodeChanged}
              placeholder="Enter section code"
            />
            {errorMsg && (
              <p>
                <br />
                <br />
                Invalid section code. Please, check your code and try again
              </p>
            )}
          </RegisterMessageContentError>
          <RegisterAction>
            <ButtonStyled
              type="submit"
              onClick={this.sectionRequestEnrollment}
              disabled={isRequestingEnrollment}
            >
              {isRequestingEnrollment ? 'Loading' : 'Submit'}
            </ButtonStyled>
          </RegisterAction>
        </RegisterWrap>
      </RegisterFormWrapper>
    );
  }

  renderRequestedSuccess = () => {
    const { sectionData, isInModal } = this.props;
    const { name = '' } = sectionData;
    return (
      <RegisterWrap isLoading isInModal={isInModal}>
        <RegisterMessageContent isInModal={isInModal}>
          <h1>&#127881;</h1>
          <div>
            You have successfully joined
            <RegisterCodeStyled>
              {name}
            </RegisterCodeStyled>
          </div>
        </RegisterMessageContent>
        <RegisterAction large>
          <Link to="/">
            <ButtonStyled>Go to dashboard</ButtonStyled>
          </Link>
        </RegisterAction>
      </RegisterWrap>
    );
  }

  render() {
    const { isRequestedEnrollment, isInModal } = this.props;
    return (
      <RegisterItemWrapper isInModal={isInModal} isStudentMobile>
        <RegisterItem>
          {isRequestedEnrollment
            ? this.renderRequestedSuccess()
            : this.renderRequestView()}
        </RegisterItem>
      </RegisterItemWrapper>
    );
  }
}

SectionEnroll.propTypes = {
  sectionRequestEnrollment: PropTypes.func.isRequired,
  isRequestedEnrollment: PropTypes.bool.isRequired,
  isRequestingEnrollment: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  sectionData: PropTypes.any,
  isInModal: PropTypes.bool,
  sectionSetDefaultEnrollment: PropTypes.func,
};

export default SectionEnroll;
