import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
// import { CustomToolbarButton } from '../ScheduleCalendar/Calendar.style';
import { CustomToolbarButton } from '../../ScheduleCalendar/Calendar.style';

const RadioButton = styled(CustomToolbarButton)`
  font-weight: normal;
  &:not(:last-child) {
    border-right: 1px solid #e0e0e0;
  }
  ${({ active }) =>
    active &&
    css`
      font-weight: bold;
      color: #d6810c;
      background-color: #ffedd4;
    `};
`;

const RatingGroupWrapper = styled.div`
  margin-block-end: 0.5rem;
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  font-size: 0.625rem;
  width: fit-content;
`;
const RatingGroup = ({
  selected,
  onChangeValue,
  options = [1, 2, 3, 4, 'N/A'],
  defaultValue,
}) => (
  <RatingGroupWrapper>
    {options.map((value) => (
      <RadioButton
        active={selected ? selected === value : defaultValue === value}
        onClick={() => onChangeValue(value)}
        key={value}
      >
        {value}
      </RadioButton>
    ))}
  </RatingGroupWrapper>
);

export default RatingGroup;
