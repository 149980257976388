import styled, { css } from 'styled-components';


export const ReportsPDFContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: calc(100% - 4em);
    justify-content: space-between;
  }
`;

export const GoBackBtn = styled.div`
    a {
        display: flex;
        align-items: center;
        color: #4786FC;
        cursor: pointer;
        text-align: left;
        text-align: left;
        font: normal normal medium 12px/15px Montserrat;
        margin: 20px;
        gap: 10px;
    }
    
`;

