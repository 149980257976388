import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import _values from 'lodash/values';
import _keyBy from 'lodash/keyBy';
import _union from 'lodash/union';

export const EXAM_INITIALLIZE_RESPONSE = 'exam/INITIALLIZE_RESPONSE';
export const EXAM_INITIALLIZE_RESPONSE_SUCCESS = 'exam/INITIALLIZE_RESPONSE_SUCCESS';
export const EXAM_INITIALLIZE_RESPONSE_FAIL = 'exam/INITIALLIZE_RESPONSE_FAIL';

export const EXAM_TAKE_QUESTION = 'exam/TAKE_QUESTION';
export const EXAM_TAKE_QUESTION_SUCCESS = 'exam/TAKE_QUESTION_SUCCESS';
export const EXAM_TAKE_QUESTION_FAIL = 'exam/TAKE_QUESTION_FAIL';

export const EXAM_SUBMIT_QUESTION = 'exam/SUBMIT_QUESTION';
export const EXAM_SUBMIT_QUESTION_SUCCESS = 'exam/SUBMIT_QUESTION_SUCCESS';
export const EXAM_SUBMIT_QUESTION_FAIL = 'exam/SUBMIT_QUESTION_FAIL';

export const EXAM_COMPLETE_RESPONSE = 'exam/COMPLETE_RESPONSE';
export const EXAM_COMPLETE_RESPONSE_SUCCESS = 'exam/COMPLETE_RESPONSE_SUCCESS';
export const EXAM_COMPLETE_RESPONSE_FAIL = 'exam/COMPLETE_RESPONSE_FAIL';

export const EXAM_CHOOSE_ANSWER = 'exam/CHOOSE_ANSWER';
export const EXAM_CLEAR_DATA = 'exam/CLEAR_DATA';
export const EXAM_CHOOSE_PREVIOUS_ANSWERS = 'exam/CHOOSE_PREVIOUS_ANSWERS';
export const EXAM_LAST_QUESTION_INDEX = 'exam/LAST_QUESTION_INDEX';

export const EXAM_RESET_TIMER = 'exam/RESET_TIMER';
export const EXAM_RESET_TIMER_SUCCESS = 'exam/RESET_TIMER_SUCCESS';

export const EXAM_SET_ERROR_CODE = 'exam/SET_ERROR_CODE';



const examSetErrorCode = (errorCode) => ({
  type: EXAM_SET_ERROR_CODE,
  errorCode,
});

const examLastQuestionIndex = (index) => ({
  type: EXAM_LAST_QUESTION_INDEX,
  index,
});

const examResetTimer = () => ({
  type: EXAM_RESET_TIMER,
});

const examResetTimerSuccess = () => ({
  type: EXAM_RESET_TIMER_SUCCESS,
});

const examChoosePreviousAnswer = (answerList) => ({
  type: EXAM_CHOOSE_PREVIOUS_ANSWERS,
  payload: answerList,
});

const examClearData = () => ({
  type: EXAM_CLEAR_DATA,
});

const examChooseAnswer = (questionId, payload) => ({
  type: EXAM_CHOOSE_ANSWER,
  questionId,
  payload,
});

const examInitializeResponse = (sessionId, examId, examSectionId, userId, callback) => ({
  type: EXAM_INITIALLIZE_RESPONSE,
  sessionId,
  callback,
  payload: {
    exam: examId,
    section: examSectionId,
    session: sessionId,
    user: userId,
  },
});

const examInitializeResponseSuccess = (payload) => ({
  type: EXAM_INITIALLIZE_RESPONSE_SUCCESS,
  payload,
});

const examInitializeResponseFail = (error) => ({
  type: EXAM_INITIALLIZE_RESPONSE_FAIL,
  error,
});

const examTakeQuestion = (sessionId, responseId) => ({
  type: EXAM_TAKE_QUESTION,
  sessionId,
  payload: {
    response_id: responseId,
  },
});

const examTakeQuestionSuccess = (payload) => ({
  type: EXAM_TAKE_QUESTION_SUCCESS,
  payload,
});

const examTakeQuestionFail = (error) => ({
  type: EXAM_TAKE_QUESTION_FAIL,
  error,
});

const examSubmitQuestion = ({ sessionId, answers, responseId, callback }) => ({
  type: EXAM_SUBMIT_QUESTION,
  sessionId,
  answers,
  responseId,
  callback,
});

const examSubmitQuestionSuccess = (payload) => ({
  type: EXAM_SUBMIT_QUESTION_SUCCESS,
  payload,
});

const examSubmitQuestionFail = (error) => ({
  type: EXAM_SUBMIT_QUESTION_FAIL,
  error,
});

const examCompleteResponse = (sessionId, responseId, callback) => ({
  type: EXAM_COMPLETE_RESPONSE,
  sessionId,
  callback,
  payload: {
    response_id: responseId,
  },
});

const examCompleteResponseSuccess = (payload) => ({
  type: EXAM_COMPLETE_RESPONSE_SUCCESS,
  payload,
});

const examCompleteResponseFail = (error) => ({
  type: EXAM_COMPLETE_RESPONSE_FAIL,
  error,
});


export const actions = {
  examInitializeResponse,
  examInitializeResponseSuccess,
  examInitializeResponseFail,

  examTakeQuestion,
  examTakeQuestionSuccess,
  examTakeQuestionFail,

  examSubmitQuestion,
  examSubmitQuestionSuccess,
  examSubmitQuestionFail,

  examCompleteResponse,
  examCompleteResponseSuccess,
  examCompleteResponseFail,

  examChooseAnswer,
  examClearData,
  examChoosePreviousAnswer,

  examResetTimer,
  examResetTimerSuccess,
  examLastQuestionIndex,
  examSetErrorCode,

};

const getResponseId = ({ exam }) => _get(exam, 'responseId', '');
const getExamChooseQuestion = ({ exam }) => _get(exam, 'newAnswerIds', '');
const getSecondsRemaining = ({ exam }) => _get(exam, 'secondsRemaining', 0);
const getQuestions = ({ exam }) => _get(exam, 'questions', []);
const getAnswerByQuestionId = ({ exam }, questionId) =>
  _get(exam, ['answerById', questionId], {});
const getAnswerList = ({ exam }) => {
  const getAnswerById = _get(exam, 'answerById', {});

  return _values(getAnswerById);
};

const getNewAnswerList = ({ exam }) => {
  const newAnswerIds = _get(exam, 'newAnswerIds', []);
  const getAnswerById = _get(exam, 'answerById', {});

  const answerlist = newAnswerIds.map((id) => {
    const answer = getAnswerById[id];

    if (answer.delete) {
      return {
        ...answer,
        value: 'None',
      };
    }

    return answer;
  });

  return answerlist.filter((item) => item.value || item.delete);
};

const getQuestionAsset = ({ exam }) => _get(exam, 'assets', []);
const getIsTakeQuestion = ({ exam }) => _get(exam, 'isTakeQuestion', true);

const getShouldResetTimer = ({ exam }) => _get(exam, 'shouldResetTimer', false);
const getLastQuestionIndex = ({ exam }) => _get(exam, 'lastQuestionIndex', 0);
const getErrorStatus = ({ exam }) => _get(exam, 'errorStatus', null);

export const selectors = {
  getResponseId,
  getExamChooseQuestion,
  getSecondsRemaining,
  getQuestions,
  getAnswerByQuestionId,
  getAnswerList,
  getQuestionAsset,
  getIsTakeQuestion,
  getNewAnswerList,
  getShouldResetTimer,
  getErrorStatus,
  getLastQuestionIndex,
};

const intialState = {
  isInitiallize: false,
  responseId: null,
  started: null,
  ended: null,
  examSectionId: null,
  questions: [],

  isTakeQuestion: true,
  isSumitQuestion: false,
  isCompleteResponse: false,
  secondsRemaining: 0,
  answerById: {},
  newAnswerIds: [],
  assets: [],
  shouldResetTimer: false,
  lastQuestionIndex: 0,
  errorStatus: null,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case EXAM_INITIALLIZE_RESPONSE:
      return { ...intialState, isInitiallize: true }; // restart all state when begin a test
    case EXAM_INITIALLIZE_RESPONSE_SUCCESS: {
      const { payload } = action;
      const { id: responseId, section: examSectionId, ...rest } = payload;
      return {
        ...state,
        isInitiallize: false,
        examSectionId,
        responseId,
        ...rest,
      };
    }
    case EXAM_INITIALLIZE_RESPONSE_FAIL:
      return { ...state, isInitiallize: false };
    case EXAM_TAKE_QUESTION:
      return { ...state, isTakeQuestion: true };
    case EXAM_TAKE_QUESTION_SUCCESS: {
      const { payload } = action;
      const {
        exam_questions: examQuestions = [],
        seconds_remaining: secondsRemaining,
        assets = [],
      } = payload;
      const orderQuestion = _orderBy(examQuestions, 'order', 'asc');

      return {
        ...state,
        isTakeQuestion: false,
        questions: orderQuestion,
        secondsRemaining,
        assets,
      };
    }
    case EXAM_TAKE_QUESTION_FAIL:
      return { ...state, isTakeQuestion: false };
    case EXAM_SUBMIT_QUESTION:
      return { ...state, isSumitQuestion: true };
    case EXAM_SUBMIT_QUESTION_SUCCESS: {
      const { payload: oldAnswersList } = action;
      const hasAnswerReturned = (answer) =>
        !![...oldAnswersList].find((old) => old.exam_question === answer);
      const unsavedAnswerIds = [...state.newAnswerIds].filter(
        (answer) => !hasAnswerReturned(answer),
        );
      return { ...state, isSumitQuestion: false, newAnswerIds: unsavedAnswerIds };
    }
    case EXAM_SUBMIT_QUESTION_FAIL:
      return { ...state, isSumitQuestion: false, newAnswerIds: [] };

    case EXAM_COMPLETE_RESPONSE:
      return { ...state, isCompleteResponse: true };
    case EXAM_COMPLETE_RESPONSE_SUCCESS:
    case EXAM_COMPLETE_RESPONSE_FAIL:
      return { ...state, isCompleteResponse: false };
    case EXAM_CHOOSE_ANSWER: {
      const { payload, questionId } = action;
      // payload containers exam_question, value, response_time, delete
      const currentResponseId = state.responseId;
      const { value, delete: isDelete } = payload;
      let newAnswerIds = [...state.newAnswerIds]; // eslint-disable0line

      if (value || isDelete) {
        newAnswerIds = _union(newAnswerIds, [questionId]);
      }

      return {
        ...state,
        newAnswerIds,
        answerById: {
          ...state.answerById,
          [questionId]: {
            response_id: currentResponseId,
            ...payload,
          },
        },
      };
    }
    case EXAM_CLEAR_DATA:
      return intialState;
    case EXAM_CHOOSE_PREVIOUS_ANSWERS: {
      const { payload } = action;
      const answertByQuestionId = _keyBy(payload, 'exam_question_id');

      return {
        ...state,
        answerById: answertByQuestionId,
      };
    }
    case EXAM_RESET_TIMER:
      return { ...state, shouldResetTimer: true };
    case EXAM_RESET_TIMER_SUCCESS:
      return { ...state, shouldResetTimer: false };
    case EXAM_LAST_QUESTION_INDEX:
      return { ...state, lastQuestionIndex: action.index };
    case EXAM_SET_ERROR_CODE:
      return { ...state, errorStatus: action.errorCode };

    default:
      return state;
  }
}
