import React from 'react';

const LOCAL_STORAGE_PREFIX = 'PERSISTENT_SHOW_';

const usePersistShow = (forKey: string) => {
  const [show, setShow] = React.useState<boolean>(
    !localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${forKey}`),
  );
  const hide = React.useCallback(() => {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${forKey}`, 'true');
    setShow(false);
  }, [forKey]);
  return { show, hide };
};

export default usePersistShow;
