import EventEmitter from "@utils/eventEmitter";

/**
 * Enum representing the different types of presentation events.
 */
export enum PresentationEvents {
  SLIDE_CHANGED = 'SLIDE_CHANGED',
  STEP_CHANGED = 'STEP_CHANGED',
  PLAYBACK_COMPLETED = 'PLAYBACK_COMPLETED',
}

/**
 * Interface defining the payload for the SLIDE_CHANGED event.
 */
export interface SlideChangedDataPayload {
  sessionUUID: string;
  slideIndex: number;
  title: string;
  previousSlideIndex: number;
  userId: number;
  sectionId: number;
  lessonNodeId: number;
  lastSlideIndex: number;
  tab: string;
  additionalSourceId?: number;
}

/**
 * Type defining the structure of the event emitter for presentation events.
 * Add new EventTypeEmitter here
 */
type PresentationEventEmitterType = {
  [PresentationEvents.SLIDE_CHANGED]: SlideChangedDataPayload;
  [PresentationEvents.STEP_CHANGED]: void;
  [PresentationEvents.PLAYBACK_COMPLETED]: void;
};

/**
 * Type alias for the message function type used by the event emitter.
 */
export type MessageFnType = defaultMessageFnType<PresentationEventEmitterType>;

/**
 * EventEmitter instance for handling presentation events.
 * 
 * @example
 * // Add new EventTypeEmitter here
 */
const PresentationEventEmitter = new EventEmitter<PresentationEventEmitterType>();
export default PresentationEventEmitter;
