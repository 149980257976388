import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const PostStudentsInviteItemWrap = styled.div`
  width: 100%;
`;

export const PostStudentsInviteItem = styled.div``;

export const PostStudentsInviteTitle = styled.div`
  & > h1 {
    color: #666666;
    font-family: 'MontserratRegular',Arial;
    font-size: 20.0pt;
    line-height: 38px;
  }
  & > p {
    color: #999999;
    font-family: 'Montserrat',Arial;
    font-size: 10.0pt;
    font-style: normal;
    font-weight: 200;
  }
  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const NextIconOptions = styled.i`
  width: 0;
  height: 0;
  font-size: 40px;
  position: relative;
  bottom: 50px;
  right: -570px;
  color: ${(props) => props.theme.colorStyled.ColorOrange};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonStyled = styled.button`
  width: 60%;
  min-height: 50px;
  margin: 30px 0;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 1px solid #6ACA6B;
  background-color: #6ACA6B;
  font-family: 'Montserrat-Regular',Arial;
  font-size: 10.0pt;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-color: #5E5E5E;
    background-color: #5E5E5E;
  }
  @media (max-width: 1440px) {
    padding: 10px 60px;
  }
  @media (max-width: 480px) {
    padding: 10px 20px;
  }
`;
