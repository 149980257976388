const ColumnSize = [
  { percent: '30', smallSize: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  {
    size: '140', smallSize: true, textCenter: true,
  },
];

export default ColumnSize;
