/* eslint-disable react/style-prop-object */
import React, { Component } from 'react';
import { objectOf, any } from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import styled from 'styled-components';

const ScrollContainer = styled.div``;

class ShadowScrollbars extends Component {
  static propTypes = {
    style: objectOf(any).isRequired,
  };

  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {};
  }

  render() {
    const { style, hideScroll, ...props } = this.props;
    const containerStyle = {
      ...style,
      position: 'relative',
    };

    return (
      <ScrollContainer style={containerStyle} id="scroll-container">
        <Scrollbars {...props} />
      </ScrollContainer>
    );
  }
}

export default ShadowScrollbars;
