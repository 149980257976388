export const SCHEDULE_MODAL_FORM_OPEN_FORM = 'scheduleModal/OPEN_FORM';
export const SCHEDULE_MODAL_FORM_CLOSE_FORM = 'scheduleModal/CLOSE_FORM';

export const SCHEDULE_OPTION_OPEN_OPTION = 'scheduleModal/SCHEDULE_OPTION_OPEN_OPTION';
export const SCHEDULE_OPTION_CLOSE_OPTION = 'scheduleModal/SCHEDULE_OPTION_CLOSE_OPTION';

export const SCHEDULE_MODAL_CREATE_FORM_OPEN = 'scheduleModal/SCHEDULE_MODAL_CREATE_FORM_OPEN';
export const SCHEDULE_MODAL_CREATE_FORM_CLOSE = 'scheduleModal/SCHEDULE_MODAL_CREATE_FORM_CLOSE';

export const SCHEDULE_MODAL_CLEAR_SESSION_DATE = 'scheduleModal/SCHEDULE_MODAL_CLEAR_SESSION_DATE';

// selectors
const isModalOpen = ({ scheduleModal }) => scheduleModal.isOpen;
const sessionSelected = ({ scheduleModal }) => scheduleModal.sessionSelected;
const isCreateFormOpen = ({ scheduleModal }) => scheduleModal.isCreateFormOpen;
const getModalData = ({ scheduleModal }) => scheduleModal.modalData;
const getSessionDate = ({ scheduleModal }) => scheduleModal.sessionDate;

export const selectors = {
  isModalOpen,
  sessionSelected,
  isCreateFormOpen,
  getModalData,
  getSessionDate,
};

// action creators

// MISC

const openFormModal = modalData => ({
  type: SCHEDULE_MODAL_FORM_OPEN_FORM,
  modalData,
});

const closeFormModal = () => ({
  type: SCHEDULE_MODAL_FORM_CLOSE_FORM,
});

const openCreateFormModal = date => ({
  type: SCHEDULE_MODAL_CREATE_FORM_OPEN,
  date,
});

const closeCreateFormModal = () => ({
  type: SCHEDULE_MODAL_CREATE_FORM_CLOSE,
});

const clearSessionDate = () => ({
  type: SCHEDULE_MODAL_CLEAR_SESSION_DATE,
});

export const actions = {
  openFormModal,
  closeFormModal,

  openCreateFormModal,
  closeCreateFormModal,

  clearSessionDate,
};

const initialState = {
  isOpen: false,
  sessionSelected: null,
  isCreateFormOpen: false,
  modalData: null,
  sessionDate: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_MODAL_FORM_OPEN_FORM: {
      const { modalData } = action;

      return {
        ...state,
        isOpen: true,
        sessionSelected: null,
        modalData,
      };
    }

    case SCHEDULE_MODAL_FORM_CLOSE_FORM: {
      return {
        ...state,
        isOpen: false,
        modalData: null,
      };
    }

    case SCHEDULE_OPTION_OPEN_OPTION: {
      const { sessionItem } = action;
      const { id, type, date } = sessionItem;
      return {
        ...state,
        sessionSelected: {
          id,
          type,
          date,
        },
      };
    }

    case SCHEDULE_OPTION_CLOSE_OPTION: {
      return {
        ...state,
        sessionSelected: null,
      };
    }

    case SCHEDULE_MODAL_CREATE_FORM_OPEN: {
      const { date } = action;
      return {
        ...state,
        isCreateFormOpen: true,
        sessionDate: date,
      };
    }

    case SCHEDULE_MODAL_CREATE_FORM_CLOSE: {
      return {
        ...state,
        isCreateFormOpen: false,
      };
    }

    case SCHEDULE_MODAL_CLEAR_SESSION_DATE: {
      return {
        ...state,
        sessionDate: null,
      };
    }

    default:
      return state;
  }
}
