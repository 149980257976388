import styled from 'styled-components';
import Box from '@components/Atoms/Box';
import { BlockWrap as OriginalBlockWrap } from '@components/Generals/stylesheets/General.style';

export const StyledBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;
`;

export const StyledBlockWrap = styled(OriginalBlockWrap)<{ inDialog?: boolean }>`
  display: flex;
  background: white;
  align-items: stretch;
  margin-left: ${(props) => (props.inDialog ? '' : '1rem !important')};
  margin-right: ${(props) => (props.inDialog ? '' : '1rem !important')};
  padding-right: ${(props) => (props.inDialog ? '0 !important' : '')};
  padding-left: ${(props) => (props.inDialog ? '0 !important' : '')};
`;

export const QuestionGenerationMenuWrapper = styled.div`
  flex: 0 0 25%;
  position: relative;
  height: 100%;
  padding-bottom: 2rem;
`;

export const EditorWrapper = styled.div`
  flex: 0 0 60%;
  overflow: auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 2%;
    right: 0;
    width: 2px;
    background-color: #ccc;
  }
`;

export const QuestionNavigatorWrapper = styled.div`
  flex: 0 0 15%;
  overflow: auto;
  position: relative;
`;

export const PassageWrapper = styled.div`
  width: 75%;
  position: relative;
  height: 100%;
`;

export const MenuRowWrapper = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

export const MenuTwoRowWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SubmitButtonGroup = styled.div`
  bottom: 10;
  position: absolute;
  width: 100%;
`;

export const MenuColumn = styled.div`
  flex: 1;
  position: relative;
  flex-direction: column;
`;

export const QuestionButtonsWrapper = styled.div`
  position: relative;
  margin-left: 1rem;
`;

export const QuestionGenerationServiceEditorWelcome = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  img {
    width: 50%;
    height: auto;
  }
`;

export const QuestionGenerationEditorWrapper = styled.div`
  .lrn-author {
    padding-inline: 0 !important;
  }
  div.lrn-qe-edit-form {
    margin-top: 30px;
  }

  // disable scoring, layout and detail options in more options section
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='scoring.heading'],
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='scoring.content'],
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='layout.heading'],
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='layout.content'],
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='details.heading'],
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='details.content'],
  div[data-lrn-qe-section='more_options.content']
    > [data-lrn-qe-section='validation.automarkable_fields'],
  div[data-lrn-qe-section='more_options.content']
    > div[data-lrn-qe-section='validation.automarkable_fields']
    + div {
    display: none !important;
  }

  // disable point adjustment in correct answers section
  div[data-lrn-qe-section='validation.content']
    div.lrn-qe-tab-sub-content
    > div.lrn-qe-inline-block {
    display: none !important;
  }

  div[data-lrn-qe-label-path='is_math'] {
    label::after {
      content: ' (Render Math Expressions)';
    }
  }
`;

export const CoAuthorAppWrapper = styled.div`
  &.lrn.lrn-author .lrn-author-item-nav,
  [data-authorapi-selector='nav-bar'],
  .lrn.lrn-author .lrn-author-item-nav {
    display: none;
  }
  .lrn-author-item-nav {
    background: none !important;
  }
  button[data-authorapi-selector='back'] {
    margin-left: 0.8rem !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    color: darkslategrey !important;
  }
  button[data-authorapi-selector='save'] {
    margin-left: 0.8rem !important;
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    color: white !important;
  }

  background: inherit;
  /* Common Rules */
  .lrn {
    font-family: 'MontserratRegular';
    color: #010101 !important;
    background: inherit;
  }
  & .lrn.lrn-author {
    padding-bottom: 2rem;
    padding-inline: 1rem;
    font-family: 'Montserrat';
  }
  .lrn-author-ui {
    border-radius: 12px;
    overflow: clip;
  }

  .lrn-ellipses {
    overflow: visible;
    white-space: break-spaces;
  }

  a[type='button'] {
    color: #4786fc;
  }

  /* fix weird mjx behavior resizing when re-rendering */
  mjx-container {
    font-size: 1.1rem !important;
  }

  button[data-authorapi-selector='validate-questions-toggle'] {
    width: 100% !important;
  }

  div.lrn-qe-toolbar-toggle {
    display: none !important;
  }

  span.lrn-question-type-name {
    font-size: 1.3rem !important;
  }

  button[data-authorapi-selector='back'],
  button[data-authorapi-selector='save'] {
    min-width: 5.5rem;
  }

  ul.lrn-qe-tiles-list
    li:has(a[data-lrn-qe-template-reference='d5d43bd6-d02a-4969-a79a-e10b344549a8']) {
    display: none;
  }
`;
