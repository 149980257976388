import React from 'react';
import { CardMedia } from '@mui/material';
import Card from '@components/Atoms/Card';
import Box from '@components/Atoms/Box';
import Typography from '@components/Atoms/Typography';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useGetUnitDetails } from '@components/Component/Lesson/LessonContainer/hooks';
import ActionButtons from '../ActionButtons/ActionButtons';
import PracticeInfo from '../PracticeInfo';
import {
  actionCardBodyStyle,
  cardBaseStyle,
  mediaCardWrapperStyle,
  mediaCardStyle,
  practiceInfoWrapperStyle,
  typographyCustomStyle,
  buttonsWrapperBoxStyle,
} from './ActionCard.style';

import EndSessionProvider from '../Provider/EndSessionProvider';
import ResetActivityProvider from '../Provider/ResetActivityProvider';

// const boxTipContent = "tipBox content"

const imgUrl = '/images/group_activity.png';

type ActionCardProps = {
  /** Optional data test id */
  dataTestId?: string;
  /** Optional tip text to display at the top of the card */
  tipText?: string | undefined;
  /** Optional React component to show buttons  */
  actionButtons?: React.ReactNode;
  /** Optional React component to show practice information in the card */
  practiceInfo?: React.ReactNode;
};

/** Card component for a practice session action */
const ActionCard = ({
  dataTestId = 'action-card',
  tipText = undefined,
  actionButtons = <ActionButtons />,
  practiceInfo = <PracticeInfo />,
}: ActionCardProps): JSX.Element => {
  const { data: LessonDetail } = useGetLessonDetail();
  const { name } = useGetUnitDetails();

  return (
    <Card sx={cardBaseStyle} data-testid={dataTestId}>
      <ResetActivityProvider>
        <EndSessionProvider>
          <Box sx={mediaCardWrapperStyle}>
            <CardMedia
              sx={mediaCardStyle}
              component="img"
              image={imgUrl}
              alt="group activity icon"
            />
          </Box>
          <Box sx={actionCardBodyStyle}>
            <Box sx={practiceInfoWrapperStyle}>
              <Box sx={{ pt: { xs: 2, sm: 0, md: 0 } }}>
                <Typography variant="subtitle1" data-testid="unit-subtitle">
                  {name}
                </Typography>
                <Typography
                  data-testid="lesson-title"
                  variant="h3"
                  sx={typographyCustomStyle}
                >
                  {LessonDetail?.name}
                </Typography>
              </Box>
              {practiceInfo && practiceInfo}
            </Box>
            {tipText && (
              <Box sx={{ paddingInlineEnd: { xs: 2, sm: 8, md: 17 } }}>
                <Typography variant="subtitle1">{tipText}</Typography>
              </Box>
            )}
            <Box sx={buttonsWrapperBoxStyle}>{actionButtons && actionButtons}</Box>
          </Box>
        </EndSessionProvider>
      </ResetActivityProvider>
    </Card>
  );
};

export default ActionCard;
