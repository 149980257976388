import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '../Box';
import { TableHeaderTypes, rowObjectType } from './Types';
import Row from './Row';
import Cell from './Cell';

const tableBodyStyle = {
  p: 1.25,
  mt: 1.25,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: (theme) => theme.tableCT.backgroundColor,
  borderRadius: 1,
} as SxProps<Theme>;

interface BodyProps<T> {
  rows: T[];
  columns: Array<TableHeaderTypes>;
  sx?: SxProps<Theme> | any;
  page: number;
  rowsPerPage: number;
}
const Body = <T extends rowObjectType>({
  sx,
  rows,
  columns,
  page,
  rowsPerPage,
}: BodyProps<T>) => {
  // Avoid a layout Shift when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  return (
    <Box sx={[tableBodyStyle, sx && { ...sx }]}>
      {(rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
      ).map((row) => (
        <Row key={row.id} data-testid="table-row">
          {columns.map((column) => (
            <Cell
              key={column.id}
              sx={{ flexBasis: column.width, ...column.style }}
              data-testid="table-row-cell"
            >
              {!!column.valueGetter && row?.[column.relatedKey as keyof T]
                ? column.valueGetter(row?.[column.relatedKey as keyof T])
                : row[column.relatedKey as keyof T] || column.defaultValue}
            </Cell>
          ))}
        </Row>
      ))}
      {emptyRows > 0 && (
        <Row sx={{ height: 64 * emptyRows }}>
          <Cell />
        </Row>
      )}
    </Box>
  );
};
export default Body;
