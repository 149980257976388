import { connect } from 'react-redux';
import StudentLoginForm from '../../components/LoginPage/StudentLoginForm';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../../reducers/auth';

export const mapStateToProps = (state) => ({
  errorMessage: AuthSelectors.getErrorMessage(state),
  isLoading: AuthSelectors.getIsLoggingIn(state),
});

const mapDispatchToProps = {
  login: AuthActions.studentLogin,
  noTypeLogin: AuthActions.noTypeLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentLoginForm);
