import styled from 'styled-components';

export const PracticeTimeSeriesWrap = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media (max-width: 1170px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    width: fill-available;
  }
`;

export const TopPracticeWrap = styled.div`
  max-width: 25em;
  min-width: 18em;
  padding: 1em;
  margin-left: 1em;
  border-radius: 0.5em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9, 30, 66, 0.42) 0px 0px 1px, rgba(9, 30, 66, 0.35) 0px 0;
  @media (max-width: 1170px) {
    order: -1;
    flex: 0 0 100%;
    width: 22em;
    margin-left: 0em;
    margin-bottom: 1.5em;
  }
  @media (max-width: 768px) {
    width: fill-available;
  }
`;

export const TopPracticeTitle = styled.div`
  margin-bottom: 0.5em;
  color: ${(props) => props.theme.colorStyled.ColorGrey};
  & > sub {
    font-size: 0.6em;
  }
`;

export const TopPracticeGroup = styled.div``;

export const TopPracticeItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #f3f3f3;
  border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
`;

export const TopPracticeName = styled.div`
  margin: 0 0.75em;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  text-transform: capitalize;
`;

export const TopPracticePercent = styled.div`
  display: flex;
  margin-left: auto;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
`;
