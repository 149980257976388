import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import _isNull from 'lodash/isNull';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import moment from 'moment';
import React, { Component } from 'react';
import { DisappearedLoading, LoopCircleLoading } from 'react-loadingg';
import Modal from 'react-modal';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { QUESTION_TYPES, REPORT_TYPES } from '../../utils/enums';
import { error as ToastError } from '../../utils/toast';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import AnswerShortText from '../AnswerShortText';
import {
  Button,
  FeedbackContainer,
  FeedbackIcon,
} from '../Generals/stylesheets/Button.style';
import {
  QuestionHeaderWrap,
  QuestionProgress,
  QuestionContentWrap,
  QuestionAnswer,
  QuestionAction,
  QuestionOrderWrapper,
  QuestionOrderNumber,
  Wrapper,
  BottomButtons,
  QuestionDesc,
} from '../QuestionItem/QuestionItem.style';
import { OnlineQuestionWrapper } from '../OnlinePracticeContent/OnlinePracticeContent.style';
import MathJaxItem from '../MathJaxContainer';
import { calculatePracticeDuration } from './utils';
import { getTotalDurationOfActivity } from '../../utils/constants';
import { gridinAnswersEqual, isNumerciallyEqual, isNumeric } from '../../utils/func-utils';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%',
    maxWidth: '500px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '18px',
    textAlign: 'center',
    height: '25%',
    minHeight: '200px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class GroupActivityQuestionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: '',
      isSubmitting: false,
      isSubmitted: false,
      currentlySubmitting: false,
      renderNext: false,
      groupStatus: -1,
      locked: false,
      timeoutID: null,
    };
  }

  selectAnswer = (value) => {
    const { onAnswerQuestion, questionSelected, chooseAnswer, answer, status } = this.props;
    const { id } = questionSelected;
    const currentResponseTime = _get(answer, 'response_time', 0);

    
    if(status !== 'review'){
      const ogaStudentHistory = JSON.parse(localStorage.getItem(`oga_student_history`))
      if(ogaStudentHistory?.status === 1){
        let individuaQuestionAnswers = ogaStudentHistory.individuaQuestionAnswers
        const itemIndex = ogaStudentHistory.individuaQuestionAnswers.findIndex((v) => v.id === id);
        if(itemIndex < 0){
          individuaQuestionAnswers.push({id: id, value: value})
        }else{
          individuaQuestionAnswers[itemIndex]['value'] = value;
        }
  
        localStorage.setItem(
          'oga_student_history',
          JSON.stringify({
            ...ogaStudentHistory,
            individuaQuestionAnswers: individuaQuestionAnswers,
          }),
        );
      }else{
        let groupQuestionAnswers = ogaStudentHistory.groupQuestionAnswers
        const itemIndex = ogaStudentHistory.groupQuestionAnswers.findIndex((v) => v.id === id);
        if(itemIndex < 0){
          groupQuestionAnswers.push({id: id, value: value})
        }else{
          groupQuestionAnswers[itemIndex]['value'] = value;
        }
  
        localStorage.setItem(
          'oga_student_history',
          JSON.stringify({
            ...ogaStudentHistory,
            groupQuestionAnswers: groupQuestionAnswers,
          }),
        );
      }
    }

    

    this.setState({ answer: value });
    onAnswerQuestion?.({ answer: value, questionSelected });

    const payload = {
      exam_question_id: id,
      value,
      response_time: currentResponseTime,
      delete: false,
    };

    chooseAnswer(id, payload);
  };

  handleEndOfIndividualPhase = () => {
    const {
      history,
      status,
      nextStage,
      courseId,
      sectionId,
      subjectId,
      unitId,
      lessonId,
      groupId,
      isTeacher,
      userId,
      onlineResponseId,
      currentGroupStudents,
      individualGroupActivity: updateStudentStatus,
      currentGroup,
      resetAnswers
    } = this.props;

    const studentHistory = JSON.parse(
      localStorage.getItem('oga_student_history'),
    );
    const studentCount = studentHistory.studentCount;
    if (studentCount === 1) {
      const previousOGALessonsFinished = JSON.parse(localStorage.getItem('OGALessonsFinished')) || {};
      localStorage.setItem('OGALessonsFinished', JSON.stringify({...previousOGALessonsFinished, [lessonId]: true}))
      updateStudentStatus({
        group_id: groupId,
        student_id: userId,
        status: 3,
      });
      
      localStorage.setItem(
        'oga_student_history',
        JSON.stringify({
          ...studentHistory,
          status: 0
        }),
      );
      
      this.submitScoreSummaryIndividualPhase()
      // redirect him back to the completed screen and show the modal
      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/?tab=${LessonTabs.LessonPractice}`,
        { showModal: true },
      );
      // TODO check why we need to get Online activity questions again
      return this.props.getOnlineActivityQuestions(currentGroup, lessonId);
    }
    resetAnswers();
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/online-activity/question/${currentGroup}/${groupId}/${
        status + 1
      }/${onlineResponseId}/${userId}`,
    );
    // update student status to the next phase ( group discussion )
    updateStudentStatus({
      group_id: groupId,
      student_id: userId,
      status: status + 1,
      update_all_status: true,
    });
    this.setState({ isSubmitted: false, groupStatus: status });
    // can't tell this is necessary, if we are already updating the status and redirecting the url
    return nextStage();
  }

  handleEndOfGroupDiscussion = ({
    differentChoice,
    unLockedChoice,
    questionId,
  }) => {
    const {
      submitData,
      index,
      totalQuestions,
      history,
      status,
      courseId,
      sectionId,
      subjectId,
      unitId,
      isTeacher,
      lessonId,
      nextQuestion,
      questionSelected,
      groupId,
      userId,
      individualGroupActivity,
      currentGroup,
    } = this.props;

    

    const { isSubmitting, isSubmitted } = submitData;

    // check if it is the last question of group discussion
    const ogaStudentHistory = JSON.parse(localStorage.getItem('oga_student_history') || '{}' );
    if (index === totalQuestions - 1 && status == 2) {
      if (this.state.locked && !differentChoice) {
        this.setState({ currentlySubmitting: true, isSubmitting: true });
      }
      if (!differentChoice && !unLockedChoice) {
        // students have completed the activity, submit score summary
        const previousOGALessonsFinished = JSON.parse(localStorage.getItem('OGALessonsFinished')) || {};
        localStorage.setItem('OGALessonsFinished', JSON.stringify({...previousOGALessonsFinished, [lessonId]: true}))
        this.submitScoreSummary();
        individualGroupActivity({
          group_id: groupId,
          student_id: userId,
          status: status + 1,
          update_all_status: true,
        });
        localStorage.removeItem(`oga_practice_${groupId}_individual_answers`);
        localStorage.removeItem('exam_questions');
        localStorage.setItem(
          'oga_student_history',
          JSON.stringify({
            ...ogaStudentHistory,
            status: 0
          }),
        );
        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/?tab=${LessonTabs.LessonPractice}`,
          { showModal: true },
        );
        
        // TODO why do we need to getOnlineActivityQuestions again?
        this.props.getOnlineActivityQuestions(currentGroup, lessonId);
      }

    // if it is not the last question and status === 2
    } else if (index !== totalQuestions - 1 && status === 2) {
      if (this.state.locked && !differentChoice) {
        this.setState({ currentlySubmitting: true, isSubmitting: true });
      }
      if (!differentChoice && !unLockedChoice) {
        this.setState({
          renderNext: true,
          isSubmitted,
          currentlySubmitting: false,
          isSubmitting,
          locked: false,
        });

        if (questionId === questionSelected.id) {
          nextQuestion();
          
          const url_string = window.location.href
          const url = new URL(url_string);
          const questionNumber = url.searchParams.get("question");
          const currentQuestion = questionNumber ? parseInt(questionNumber) : ogaStudentHistory.currentQuestion
          localStorage.setItem(
            'oga_student_history',
            JSON.stringify({
              ...ogaStudentHistory,
              currentQuestion
            }),
          );

        }
      }
    } else {
      if (status !== 'review') {
        nextQuestion();
      }
      this.setState({ renderNext: true, isSubmitted, answer: '' });
    }
  }

  componentDidMount() {
    const { status, index, totalQuestions, questionSelected, groupId } = this.props;
    this.setState({ groupStatus: status, currentlySubmitting: false });
    if (index === totalQuestions - 1 && status == 1) {
      const question_answers = JSON.parse(localStorage.getItem(`oga_practice_${groupId}_individual_answers`))
      if(question_answers){
        const answered_question = question_answers.find(({exam_question_id})=> exam_question_id==questionSelected.id)
        if(answered_question) {
          this.setState({ currentlySubmitting: true, isSubmitting: true, answer: answered_question.value });
        }
      }
  }}

 

  componentDidUpdate(prevProps) {
    const {
      answeredQuestions,
      submitData,
      index,
      totalQuestions,
      history,
      status,
      nextStage,
      groupAnswers,
      courseId,
      sectionId,
      subjectId,
      unitId,
      lessonId,
      nextQuestion,
      questionSelected,
      isTeacher,
      questionAnsweredAlready,
      groupId,
      userId,
      onlineResponseId,
      currentGroupStudents,
      individualGroupActivity,
      currentGroup,
      individualQuestionSubmitted,
      hideModal,
      resetAnswers,
      question,
    } = this.props;
    const {
      submitData: prevSubmitData,
      groupAnswers: prevGroupAnswers,
      questionAnsweredAlready: prevQuestionAnsweredAlready,
      individualQuestionSubmitted: prevIndividualQuestionSubmitted,
    } = prevProps;


    const { answer } = this.state;
    const ogaStudentHistory = JSON.parse(localStorage.getItem(`oga_student_history`))

    if(ogaStudentHistory?.status === 1 && !ogaStudentHistory.individuaSubmitted){
      if(ogaStudentHistory.individuaQuestionAnswers.length > 0){
        const studentQuestionAnswersIndex = ogaStudentHistory.individuaQuestionAnswers.findIndex((v) => v.id === questionSelected.id);
        if(studentQuestionAnswersIndex >= 0){
          const studentAnswerValue = ogaStudentHistory.individuaQuestionAnswers[studentQuestionAnswersIndex].value
          if(answer !== studentAnswerValue){
            this.setState({answer: studentAnswerValue})
          }
        }else if(answer != '') {
          this.setState({answer: ''})
        }
      }
    }else{
      if(ogaStudentHistory?.groupQuestionAnswers?.length > 0){
        const studentQuestionAnswersIndex = ogaStudentHistory.groupQuestionAnswers.findIndex((v) => v.id === questionSelected.id);
        if(studentQuestionAnswersIndex >= 0){
          const studentAnswerValue = ogaStudentHistory.groupQuestionAnswers[studentQuestionAnswersIndex].value
          if(answer !== studentAnswerValue){
            this.setState({answer: studentAnswerValue})
          }
        }else if(answer != '') {
          this.setState({answer: ''})
        }
      }
    }

    const { isSubmitting, isSubmitted } = submitData;
    if (
      !_isEqual(submitData, prevSubmitData) ||
      !_isEqual(groupAnswers, prevGroupAnswers) ||
      !_isEqual(individualQuestionSubmitted, prevIndividualQuestionSubmitted)
    ) {
      if (individualQuestionSubmitted) {
        //TODO check if this is still going to be used, I think that, since we are submitting
        // all at once, we will not need this anymore
        console.log('individualQuestionSubmitted nextQuestion')
        status !== "review" && nextQuestion();
        this.setState({
          renderNext: true,
          isSubmitted: true,
          isSubmitting: false,
          currentlySubmitting: false,
          answer: "",
        });
        return;
      }
      this.setState({ ...this.state, isSubmitting });

      // console.log('conditions that need to be fullfilled to go to the group discussion');

      // console.log('isSubmitted', {isSubmitted});
      // console.log('index', {index});
      // console.log('totalQuestions - 1')
      // console.log('index === totalQuestions - 1', index === totalQuestions - 1);
      // console.log('status', {status});

      if( status !== 'review' && ogaStudentHistory.studentCount == 1 && ogaStudentHistory.individuaSubmitted ){
        return this.handleEndOfIndividualPhase();
      }

      if (isSubmitted) {
        this.setState({ currentlySubmitting: false });

        if (
          index === totalQuestions - 1
          && status !== 2
          && status !== 'review'
        ) {
          return this.handleEndOfIndividualPhase();
        } // end of if last question of individual phase

        const { student_answers, questionId } = groupAnswers;

        // here starts the handling of group discussion questions
        const differentChoice = student_answers.filter(
          (answer) => {
           if(status !== 'review' && questionSelected.question.question_format == QUESTION_TYPES.GRID_IN)
              return !gridinAnswersEqual(answer.text_answer,student_answers[0].text_answer)
            else if(questionSelected.question.question_format == QUESTION_TYPES.SHORT_ANSWER && isNumeric(question.correct_choices))
              return !isNumerciallyEqual(answer.text_answer,student_answers[0].text_answer)
            else
              return answer.text_answer !== student_answers[0].text_answer
          }
        ).length;

        const unLockedChoice = student_answers.filter(
          (answer) => answer.locked === false,
        ).length;

        this.handleEndOfGroupDiscussion({
          differentChoice,
          unLockedChoice,
          questionId,
        });
      } // isSubmitted
    }

    if (
      questionAnsweredAlready
      && !_isEqual(questionAnsweredAlready, prevQuestionAnsweredAlready)
    ) {
      this.setState({ currentlySubmitting: false });
      // if question answered already and it is on the last question, redirect the user to the group discussion
      if (index === totalQuestions - 1 && status !== 2 && status !== 'review') {
        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/online-activity/question/${currentGroup}/${groupId}/${
            status + 1
          }/${onlineResponseId}/${userId}`,
        );
        // TODO if we are moving the user to the group discussion we need to update their status again
        individualGroupActivity({
          group_id: groupId,
          student_id: userId,
          status: status + 1,
        });

        this.setState({ isSubmitted: false, groupStatus: status });
        resetAnswers();
        return nextStage();
      }
      setTimeout(() => {
        nextQuestion();
      }, 2000);

      this.setState({ renderNext: true, isSubmitted: true, answer: '' });
    }

    if ((hideModal && !_isEqual(hideModal, prevProps.hideModal)) || (question === 0 && !_isEqual(status, prevProps.status))) {
      this.setState({ currentlySubmitting: false });
    }

    if (((!_isEqual(question, prevProps.question) && status === 2) || (question === 0 && !_isEqual(status, prevProps.status)))) {
      this.autoHideModalOrMoveToNextQuestionOnTimeout();
    }

    this.handleChangeQuestion(this.props);
  }

  componentWillUnmount() {
    if (this.state.timeoutID) {
      clearTimeout(this.state.timeoutID);
    }
  }

  handleChangeQuestion = (props) => {
    if (props.question === 0) {
      return;
    }

    const {
      question, userId, status, currentGroupStudents, setSelectedQuestion, groupId, questions,
    } = props;
    const prevQuestionId = questions[props.question - 1].id;

    // handle part 1
    if (status === 1) {
      const myIndividualStageAnswers = JSON.parse(localStorage.getItem(`oga_practice_${groupId}_individual_answers`) || '[]');
      const hasAnsweredPrevQuestion = myIndividualStageAnswers.some((answer) => answer.exam_question_id === prevQuestionId);
      // prevent student from answering the current question if they have not answered the previous question
      // if (!hasAnsweredPrevQuestion) {
        // setSelectedQuestion(question - 1);
      // }
    }

    if (status === 2) {
      const examQuestions = JSON.parse(localStorage.getItem('exam_questions') || '[]');
      const prevQuestionStats = examQuestions.find((q) => q.id === prevQuestionId);
      const groupStudents = _get(JSON.parse(localStorage.getItem('localStatusStudentRegister') || '[]'), groupId);
      if (!prevQuestionStats) {
        setSelectedQuestion(question - 1);
        return;
      }

      const hasAnsweredPrevGroupQuestion =
        // there should be an answer from the student
        (prevQuestionStats.group_answers || []).some(
          (answer) => answer.student_id === userId && answer.locked,
        )
        // all students in the group must have answered the question
        && Object.keys(groupStudents).length === (prevQuestionStats.group_answers || []).length
        // all answers must have been submitted
        && (prevQuestionStats.group_answers || []).every((a) => a.locked);
        console.log({ hasAnsweredPrevGroupQuestion });
      // prevent student from answering the current question if they have not answered the previous question
      if (!hasAnsweredPrevGroupQuestion) {
        setSelectedQuestion(question - 1);
      }
    }
  }

  completeActivity = async () => {
    this.submitAnswer();
  };

  goBack = () => {
    const {
      history, courseId, sectionId,
      subjectId, unitId, lessonId, isTeacher,
    } = this.props;
   
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}?tab=${LessonTabs.LessonPractice}`,
    );
  };

  getSelectedQuestion = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return Number.parseInt(searchParams.get('question') || 0, 10);
  };

  groupDiscussionSubmitAnswer = (value, locked) => {
    const {
      userId,
      questionSelected,
      groupId,
      onlineResponseId,
      answerGroupActivityGroupQuestion,
    } = this.props;
    this.setState({ locked });
    const student_answer = {
      student_id: userId,
      text_answer: value,
      locked,
    };
    const payload = {
      student_answer,
      group: groupId,
      exam_question: questionSelected.id,
      text_answer: value,
    };

    answerGroupActivityGroupQuestion(payload, true, locked);
  }

  saveAnswerToLocalStorage = (answer, groupId) => {
    const ogaStudentHistory = JSON.parse(localStorage.getItem(`oga_student_history`))
    const currentQuestion = ogaStudentHistory.currentQuestion
    localStorage.setItem(
      'oga_student_history',
      JSON.stringify({
        ...ogaStudentHistory,
        currentQuestion: currentQuestion + 1
      }),
    );
    const { exam_question_id: questionId } = answer;
    const stored = localStorage.getItem(
      `oga_practice_${groupId}_individual_answers`
    );
    const examQuestions = JSON.parse(localStorage.getItem("exam_questions") || '[]')
    if(examQuestions.length == 0) return
    const {
      question,
    } = examQuestions.find(
      (question) => question.id === questionId
    );
    const { correct_choices } = question;
    if(question.question_format == QUESTION_TYPES.GRID_IN)
      answer.isCorrect = gridinAnswersEqual(correct_choices, answer.value);
    else if(question.question_format == QUESTION_TYPES.SHORT_ANSWER && isNumeric(correct_choices)){
      answer.isCorrect = isNumerciallyEqual(correct_choices, answer.value);
      if(answer.isCorrect) answer.value = correct_choices;
    }
    else if(question.question_format == QUESTION_TYPES.MULTIPLE_CHOICES)
      answer.isCorrect = correct_choices.includes(answer.value);
    else
      answer.isCorrect = true;

    if (_isNull(stored)) {
      const answers = [answer];
      localStorage.setItem(
        `oga_practice_${groupId}_individual_answers`,
        JSON.stringify(answers),
      );
    } else {
      const storedAnswers = [...JSON.parse(stored)];
      const answerPresent = storedAnswers.find((v) => v.exam_question_id === answer.exam_question_id);

      if (!_isUndefined(answerPresent)) return;

      const answers = [...storedAnswers, answer];
      localStorage.setItem(
        `oga_practice_${groupId}_individual_answers`,
        JSON.stringify(answers),
      );
    }
  }

  submitScoreSummary = () => {
    const {
      userId,
      groupId,
      submitScoreSummary,
    } = this.props;
    const groupActivityId = JSON.parse(localStorage.getItem(`groupActivityId`))
    const examQuestions = JSON.parse(
      localStorage.getItem('exam_questions') || '[]',
    );
    
    const studentGroupActivityAnswers = JSON.parse(
      localStorage.getItem(`oga_practice_${groupId}_group__answers`) || '[]',
    );
    const studentIndividualAnswers = JSON.parse(
      localStorage.getItem(`oga_practice_${groupId}_individual_answers`) || '[]',
    );

    let student_score_count = 0
    studentIndividualAnswers.forEach((answer) => {
      if(answer.isCorrect) student_score_count++;
    })

    const answers = studentGroupActivityAnswers[groupId]
    const questionIds = Object.keys(answers);
    let group_score_count = 0
    for(let i=0; i < questionIds.length; i++){
      if(answers[questionIds[i]][userId].isCorrect) group_score_count++;
    }

    let group_questions_answers = {}
    for(let j=0; j < questionIds.length; j++){
      const questionId = questionIds[j];
      group_questions_answers[questionId] = {}
      
      let examSelected = examQuestions.filter((e) => e.id === parseInt(questionId))
      let correct_choices = examSelected[0]['question']['correct_choices']
      let question_format = examSelected[0]['question']['question_format']
      const studentsIds = Object.keys(answers[questionId]);
      
      let studentAnswers = []
      group_questions_answers[questionId]['students_answers'] = []
      for(let i=0; i< studentsIds.length; i++){
        const strStudentId = studentsIds[i].toString()
        let answer = answers[questionId][strStudentId]['text_answer']
        studentAnswers.push(answer)

        group_questions_answers[questionId]['students_answers'].push({
            student_id: strStudentId,
            "text_answer": answer,
            locked: true,
          }) 
      }
     
      let isCorrect = studentAnswers.every( (val, i, arr) => {
        if(question_format === QUESTION_TYPES.GRID_IN) {
          return gridinAnswersEqual(val, arr[0]) && gridinAnswersEqual(correct_choices, studentAnswers[0])
        }
        else if(question_format === QUESTION_TYPES.SHORT_ANSWER){
          return isNumeric(correct_choices)? isNumerciallyEqual(val, arr[0]) && isNumerciallyEqual(correct_choices, studentAnswers[0]) : true;
        }
        else if(question_format === QUESTION_TYPES.MULTIPLE_CHOICES){
          return (val == arr[0]) && isNumerciallyEqual(correct_choices, studentAnswers[0])
        }
      })
      
      group_questions_answers[questionId]['isCorrect'] = isCorrect
      group_questions_answers[questionId]['text_answer'] = studentAnswers[0]

    }


    submitScoreSummary(
      {
        group_id: Number(groupId),
        group_score: group_score_count,
        student_score: { student_id: userId, score: student_score_count },
        group_questions_answers,
      },
      groupActivityId,
    );
  };

  submitScoreSummaryIndividualPhase = () => {
    const {
      userId,
      groupId,
      groupActivityId,
      submitScoreSummary,
    } = this.props;

    const studentIndividualAnswers = JSON.parse(
      localStorage.getItem(`oga_practice_${groupId}_individual_answers`) || '[]',
    );

    let student_score_count = 0
    studentIndividualAnswers.forEach((answer) => {
      if(answer.isCorrect) student_score_count++;
    })
    const answers = studentIndividualAnswers
    let group_questions_answers = {}
    for(let j=0; j < answers.length; j++){
      const questionId = answers[j].exam_question_id;
      group_questions_answers[questionId] = {}
      group_questions_answers[questionId]['students_answers'] = []
      group_questions_answers[questionId]['students_answers'].push({
          student_id: userId,
          "text_answer": answers[j].value,
          locked: true,
        }) 
      group_questions_answers[questionId]['isCorrect'] = answers[j].isCorrect
      group_questions_answers[questionId]['text_answer'] = answers[j].value

    }
    submitScoreSummary(
      {
        group_id: Number(groupId),
        group_score: student_score_count,
        student_score: { student_id: userId, score: student_score_count },
        group_questions_answers,
      },
      groupActivityId,
    );
  }
  
  submitAnswer = () => {
    const {
      userId,
      onlineResponseId,
      groupId,
      answerGroupActivityQuestion,
      questionSelected,
      status,
      nextQuestion,
      totalQuestions,
    } = this.props;
    if (status === 'review') {
      return nextQuestion();
    }

    if (!this.state.answer && status !== 'review') {
      ToastError("You haven't completed the question");
      return null;
    }

    if ((!onlineResponseId || !groupId) && status === 1) {
      ToastError('The teacher has ended this group activity');
      this.goBack();
      return null;
    }

    this.setState({ currentlySubmitting: true });

    if (status === 1) {
      const payload = calculatePracticeDuration({ ...this.props, answer: this.state.answer });
      const { waitForGroupFinish, ...data } = payload;
      this.saveAnswerToLocalStorage(data, groupId);

      const presentQuestion = this.getSelectedQuestion();

      if (presentQuestion === totalQuestions - 1) {
        const stored = JSON.parse(localStorage.getItem(`oga_practice_${groupId}_individual_answers`));
        const ogaStudentHistory = JSON.parse(localStorage.getItem(`oga_student_history`))
        localStorage.setItem(
          'oga_student_history',
          JSON.stringify({
            ...ogaStudentHistory,
            individuaSubmitted: true,
            currentQuestion: 0
          }),
        );
        return answerGroupActivityQuestion(
          stored,
          userId,
          onlineResponseId,
          groupId,
          waitForGroupFinish,
          questionSelected.id,
          true,
        );
      }

      nextQuestion();
      return this.setState({ currentlySubmitting: false });
    }

    if (status === 2) {
      return this.groupDiscussionSubmitAnswer(this.state.answer, true);
    }

    return null;
  };

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.QUESTION, questionId);
  };

  renderAnswer = () => {
    const {
      questionSelected,
      answer,
      status,
      groupAnswers,
      userId,
      correctChoice,
      currentGroupStudents,
      groupId,
      questionId,
      score
    } = this.props;
    const answerType = _get(questionSelected, 'question.question_format');
    let student_answers = groupAnswers.student_answers;
    let answersLocal = JSON.parse(localStorage.getItem(`oga_practice_${groupId}_group__answers`))
    if(!groupAnswers.student_answers.length && answersLocal && status === 2 ){
      student_answers = Object.values(answersLocal[groupId][questionId])
    }
      let groupStudents = currentGroupStudents;
    if(!currentGroupStudents.length && status!='review'){
      groupStudents = Object.values(JSON.parse(localStorage.getItem(`localStatusStudentRegister`))[groupId])
    }

    const groupAnswerAndName =  student_answers?.map((student) => {
      const studentDetail = groupStudents?.filter(
        (eachStudent) => eachStudent.student_id === student?.student_id,
      )[0];
      const currentUser = student?.student_id === userId;
      return {
        ...studentDetail,
        ...student,
        currentUser,
      };
    });
    const groupChoice = groupAnswerAndName?.filter(
      (group) => group?.currentUser === false,
    )[0];
    const user_answer = groupAnswerAndName.find(user => user.student_id == userId);
    const shouldDisplayAnswer = status === 'review'
    switch (answerType) {
      case QUESTION_TYPES.SHORT_ANSWER: {
        const inputValue = _get(answer, 'value', '');
        return (
          <AnswerShortText
            choice_answer={user_answer?.choice_answer}
            selectAnswer={this.selectAnswer}
            inputValue={inputValue}
            score={score}
            shouldDisplayAnswer={shouldDisplayAnswer}
            activity="online-group"
            groupId={groupId}
            questionId={questionId}
            userId={userId}
          />
        );
      }
      case QUESTION_TYPES.GRID_IN: {
        // TODO Refactor code
        const value = _get(answer, 'value', '');
        const transformValue = `${value}____`.substring(0, 4);
        let selectedValueArr = transformValue.split('');
        if(user_answer&&status =='review'){
          selectedValueArr = user_answer.choice_answer?.toString().split('');
        }
        return (
          <AnswerGridChoice
            score={score}
            selectedValue={selectedValueArr}
            selectAnswer={this.selectAnswer}
            activity="online-group"
            groupId={groupId}
            questionId={questionId}
            userId={userId}
            shouldDisplayAnswer={shouldDisplayAnswer}
          />
        );
      }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default: {
        const choices = _get(questionSelected, 'question.choices', []);
        const value = _get(answer, 'value', '');
        const values = Array.isArray(value) ? value : [value];
        const orderChoice = _orderBy(choices, 'order', 'asc');
        const additionalProps = status === 'review' && correctChoice
          ? {
            correctChoice,
            shouldDisplayAnswer: true,
            userChoice: [groupChoice?.text_answer],
          }
          : {};
        return (
          <AnswerMultiChoice
            choice_answer={user_answer?.choice_answer}
            userChoice={[this.state.answer]}
            choices={orderChoice}
            selectAnswer={this.selectAnswer}
            groupStatus={status}
            groupAnswers={groupAnswerAndName}
            activity="online-group"
            groupDiscussionSubmitAnswer={this.groupDiscussionSubmitAnswer}
            {...additionalProps}
          />
        );
      }
    }
  };

  renderButtonGroup = () => {
    const {
      index,
      prevQuestion,
      totalQuestions,
      status,
      isGroupActivity,
    } = this.props;
    const shouldRenderPrevButton = index > 0 && status === 'review';
    const shouldRenderNextButton = index < totalQuestions - 1;
    const { currentlySubmitting } = this.state;
    return (
      <React.Fragment>
        <BottomButtons
          isGroupActivity={isGroupActivity && shouldRenderPrevButton}
          activity={(status === 2 || status === 'review') && 'online-group'}
        >
          {shouldRenderPrevButton && (
            <Button blue mobDesk textUppercase onClick={() => prevQuestion()}>
              {'< Previous'}
            </Button>
          )}
          {shouldRenderNextButton && (
            <Button
              id="LessonPractice-NextQuestion"
              blue
              mobDesk
              textUppercase
              onClick={(!currentlySubmitting && this.submitAnswer) || undefined}
              activity="online-group"
            >
              {currentlySubmitting ? (
                <DisappearedLoading 
                  size="small"
                  color="#fff"
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    width: '4em',
                    height: '1em',
                  }}
                />
              ) : (
                'Next >'
              )}
            </Button>
          )}
          {index === totalQuestions - 1 && status !== 'review' && (
            <Button
              id = {status === 1? "LessonPractice-IndividualCompleted":"LessonPractice-GroupCompleted"}
              blue
              mobDesk
              textUppercase
              onClick={!currentlySubmitting && this.completeActivity || undefined}
              activity="online-group"
            >
              {currentlySubmitting ? (
                <DisappearedLoading
                  size="small"
                  color="#fff"
                  style={{
                    position: 'relative',
                    textAlign: 'center',
                    width: '4em',
                    height: '1em',
                  }}
                />
              ) : (
                'Complete'
              )}
            </Button>
          )}
        </BottomButtons>
      </React.Fragment>
    );
  };

  isModalShowing() {
    const { index, status, totalQuestions, individualQuestionSubmitted, currentGroupStudents, groupId} = this.props;
    if (status=='review'){
      return false;
    }
    const ogaStudentHistory = JSON.parse(localStorage.getItem(`oga_student_history`))

    let groupStudentsLocal = currentGroupStudents;
    if(!currentGroupStudents.length){
      groupStudentsLocal = Object.values(JSON.parse(localStorage.getItem(`localStatusStudentRegister`))[groupId])
    }

    if(ogaStudentHistory?.status === 1){
      return(
        ogaStudentHistory.individuaSubmitted
      );
    }
    
    return (
      this.state.isSubmitting &&
      this.state.currentlySubmitting &&
      (status === 2 || index === totalQuestions - 1) &&
      (groupStudentsLocal.length > 1)
    );
  }

  autoHideModalOrMoveToNextQuestionOnTimeout() {
    const {
      totalQuestions, nextQuestion, groupActivityInfo, question, totalDuration,
    } = this.props;
    const activityStartTime = groupActivityInfo.time_activity_started && moment(
      new Date(groupActivityInfo.time_activity_started),
    );
    const { PART_TWO_DURATION } = getTotalDurationOfActivity(groupActivityInfo.duration);
    const currentTime = moment();
    const differenceInTime = activityStartTime && currentTime.diff(activityStartTime, 'seconds');
    const timeLeft = differenceInTime && (totalDuration - differenceInTime);
    const questionsLeft = totalQuestions - question;
    const totalTime = PART_TWO_DURATION * 1000;
    const timePerQuestion = timeLeft ? (timeLeft * 1000) / questionsLeft : totalTime / totalQuestions;

    if (this.state.timeoutID) {
      clearTimeout(this.state.timeoutID);
    }
    const timeout = setTimeout(() => {
      // If the modal is showing, then user has submitted but other group members are still pending submission
      if (this.isModalShowing()) {
        // hide the modal
        this.setState({ currentlySubmitting: false });
      }
      // if the modal isn"t showing, then the user hasn"t yet submitted an answer
      if (!this.isModalShowing()) {
        nextQuestion();
      }
    }, timePerQuestion);
    this.setState({ timeoutID: timeout });
  }

  render() {
    const {
      questionSelected,
      totalQuestions,
      index,
      order,
      shouldDisplayGrammarHelper,
      status,
    } = this.props;
    const getQuestionContent = _get(
      questionSelected,
      'question.prompt.text',
      '',
    );


    if(status !== 'review'){
      const ogaStudentHistory = JSON.parse(localStorage.getItem(`oga_student_history`) || '{}')
      if(_isEmpty(ogaStudentHistory)) return('')
    }
    return (
      <OnlineQuestionWrapper
        activity={(status === 2 || status === 'review') && 'online-group'}
      >
        <Modal
          isOpen={this.isModalShowing()}
          contentLabel="Submitting"
          style={modalStyles}
          ariaHideApp={false}
        >
          <h1>Please wait for other group members submission</h1>
          <LoopCircleLoading
            color="#000"
            style={{
              position: 'relative',
              textAlign: 'center',
              marginTop: '20px',
              marginBottom: '20px',
            }}
            size="large"
          />
          {status === 2 && (
            <Button onClick={() => this.setState({ currentlySubmitting: false })}>Choose another option</Button>
          )}
        </Modal>
        <QuestionHeaderWrap
          activity={(status === 2 || status === 'review') && 'online-group'}
        >
          <QuestionProgress>
            Question
            <span>{`${index + 1} / ${totalQuestions}`}</span>
          </QuestionProgress>
          <FeedbackContainer onClick={this.openReportErrorModal}>
            <FeedbackIcon src="/images/feedback.png" />
          </FeedbackContainer>
        </QuestionHeaderWrap>
        <QuestionContentWrap>
          <Wrapper>
            {shouldDisplayGrammarHelper && (
              <QuestionOrderWrapper>
                <span>This question refers to </span>
                <QuestionOrderNumber>{order}</QuestionOrderNumber>
              </QuestionOrderWrapper>
            )}
            <QuestionDesc
              activity={(status === 2 || status === 'review') && 'online-group'}
            >
              <MathJaxItem data={getQuestionContent} />
            </QuestionDesc>
            <QuestionAnswer>{this.renderAnswer()}</QuestionAnswer>
          </Wrapper>
          <QuestionAction>{this.renderButtonGroup()}</QuestionAction>
        </QuestionContentWrap>
      </OnlineQuestionWrapper>
    );
  }
}
