import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  NavBarList,
  NavBarItem,
  NavBarName,
  NavBarStatus,
  NavBarItemHead,
} from '../SubNavBarItem/SubNavBar.style';
import ListLoader from '../LoadingPlaceholder/ListLoader';

class SubNavBarTeacherTraining extends Component {
  renderTrainingItem = (trainingItem, index) => {
    const {
      name,
    } = trainingItem;
    const { selectTraining } = this.props;

    return (
      <NavBarItem key={index} onClick={() => selectTraining(trainingItem)}>
        <NavBarItemHead>
          <div>Training</div>
        </NavBarItemHead>
        <NavBarName>
          {name}
        </NavBarName>
        <NavBarStatus>
          Online
        </NavBarStatus>
      </NavBarItem>
    );
  };

  render() {
    const { trainings = [], isGettingTrainning } = this.props;
    return (
      <NavBarList isScroll>
        {isGettingTrainning
          ? <ListLoader isLargeItem />
          : trainings.map(this.renderTrainingItem)}
      </NavBarList>
    );
  }
}

SubNavBarTeacherTraining.propTypes = {
  trainings: PropTypes.array,
  selectTraining: PropTypes.func.isRequired,
  isGettingTrainning: PropTypes.bool,
};

export default SubNavBarTeacherTraining;
