import styled, { css } from 'styled-components';

export const UnitPracticeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    height: 100%;
    width: 90%;
    position: absolute;
  }
`;

export const UnitQuestionTypeWrap = styled.div`
  flex: 0 0 35%;
  height: fit-content;
  border-radius: .325em;
  margin-right: 1.5em;
  background-color: #f9f9f9;
  box-shadow: rgba(9,30,66,0.42) 0px 0px 1px, rgba(9,30,66,0.35) 0px 0px 6px -1px;
  @media (max-width: 1170px) {
    flex: 0 0 100%;
    margin-right: 0em;
    margin-bottom: 3em;
  }
`;

export const UnitQuestionLogo = styled.div`
  padding: 1.5em;
  text-align: center;
  background-color: ${(props) => props.theme.colorStyled.ColorMidBlue};
  border-radius: .325em .325em 0em 0em;
  img {
    width: 4.5em;
    height: auto;
  }
`;

export const UnitQuestionDescription = styled.div`
  padding: 1.5em;
  h3 {
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
    margin-bottom: 1em;
  }
  p {
    line-height: 1.3;
    color: ${(props) => props.theme.colorStyled.ColorTime};
  }
  @media (max-width: 768px) {
    text-align: center;
  } 
`;

export const UnitQuestionTopic = styled.div`
  padding: 1.5em;
  p {
    margin-bottom: 1em;
  }
  @media (max-width: 768px) {
    text-align: center;
  } 
`;

export const UnitQuestionTopicCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    padding: .5em 1em;
    margin-bottom: .5em;
    margin-right: .5em;
    background: #dfe2e6;
    border-radius: .325em;
  }
`;

export const UnitPracticeDetailWrap = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    width: 100%;
  } 
`;

export const UnitPracticeItemWrap = styled.div`
  margin-bottom: 3em;
`;

export const UnitPracticeItem = styled.div`
  display: flex;
  align-items: stretch;
  margin: .125em .125em .5em .125em;
  box-shadow: rgba(9,30,66,0.42) 0px 0px 1px, rgba(9,30,66,0.35) 0px 0px 2px;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  @media (max-width: 768px) {
    width: 98%;
    overflow: auto;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      width: 10px;
      padding: 0 2px;
      margin-left: 2px;
      height: 10px;
    }

    ::-webkit-scrollbar-track {
      border: 1px solid #e0e0e0;
      border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
      background: #757575;
      border-radius: 8px;
      border: 2px solid transparent;
      background-clip: content-box;
    }

    & > div:last-child > div {
      margin-bottom: 0;
    }
  } 

`;

export const UnitName = styled.div`
  display: flex;
  align-items: center;
  padding: .5em;
  flex: 1;
  @media (max-width: 768px) {
    flex: 0 0 145px;
  }
`;

export const UnitScore = styled(UnitName)`
  flex: 0 0 55px;
  justify-content: center;
  @media (max-width: 768px) {
    flex: 0 0 50px;
  }
`;

export const UnitIcon = styled(UnitName)`
  flex: 0 0 55px;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  @media (max-width: 1170px) {
    flex: 0 0 45px;
  }
  @media (max-width: 768px) {
    flex: 0 0 30px;
  }
`;

export const UnitLastActivity = styled(UnitName)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 0 0 80px;
  span {
    line-height: 1.2;
    font-size: ${(props) => props.theme.fontSize.SmallerFontSize};
    &:last-child {
      color: ${(props) => props.theme.colorStyled.ColorTime};
    }
  }
  @media (max-width: 768px) {
    flex: 0 0 80px;
  }
`;

export const UnitActionGroup = styled.div`
  flex: 0 0 220px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 768px) {
    flex: 0 0 180px;
  }
`;

export const UnitActionButton = styled.div`
  display: flex;
  align-items: center;
  height: auto;
  padding: 1em;
  cursor: pointer;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  background-color: ${(props) => props.theme.colorStyled.ColorMidBlue};
  &:hover {
    opacity: .8;
  }
  i {
    margin-left: .25em;
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  }
  ${({ isContinuePractice }) => isContinuePractice && css`
    background-color: ${(props) => props.theme.colorStyled.ColorActiveCheckbox};
  `}

  ${({ inStudentPage }) => inStudentPage && css`
    justify-content: space-between;
  `}

  ${({ green }) => green && css`
    background: ${(props) => props.theme.colorStyled.ColorPrimaryGreen};
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    &:hover {
      background: ${(props) => props.theme.colorStyled.ColorPrimaryGreen};
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    }
  `}
`;

export const UnitSkillLevelWrap = styled.div``;

export const UnitSkillLevel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5em;
  span {
    line-height: 1.3;
    font-size: ${(props) => props.theme.fontSize.DefaultFonts};
  }
`;

export const LevelSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .25em;
`;

export const Dot = styled.span`
  background: #808080;
  width: .75em;
  height: .75em;
  border-radius: .15em;
  margin-right: .25em;
  ${({ isActive }) => isActive && css`
    background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  `}
`;

export const UnitPracticeTitle = styled.div`
  position: relative;
  margin-bottom: 1em;
  h1 {
    position: relative;
    z-index: 2;
    background: #f4f5f7;
    width: fit-content;
    padding-right: .5em;
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0em;
    top: 50%;
    z-index: 1;
    background-color: ${(props) => props.theme.colorStyled.ColorBorder};
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    overflow: auto;
  } 
`;

export const PracticeCoverWrapper = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  } 
`;
