/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios';

export const getPacingGuides = (
  curriculumId?: string,
  subjectId?: string,
  districtId?: string,
) => {
  const url = `/api/pacing_guide/`;
  return axios
    .get(url, {
      // @ts-ignore
      noCache: true,
      params: {
        curriculum: curriculumId,
        subject: subjectId,
        district: districtId,
      },
    })
    .then((response) => ({ response }));
};

export const getPacingGuideLessons = (pacingGuideId?: string, lessonId?: string) => {
  const url = `/api/pacing_guide/lesson_pacing_guide/`;
  return axios
    .get(url, {
      // @ts-ignore
      noCache: true,
      params: {
        pacing_guide: pacingGuideId,
        lesson: lessonId,
      },
    })
    .then((response) => ({ response }));
};
