import React from 'react';

import Box from '@components/Atoms/Box';
import Typography from '@components/Atoms/Typography';
import Paper from '@components/Atoms/Paper';
import { Theme } from '@mui/material';
import { useAttendanceCounts } from '../LessonAttendance/hooks/helpers';

import AttendanceRecordButton from './AttendanceRecordButton';

interface AttendanceCardProps {
  onLessonChange?: (lessonId: string | number, status) => void;
}

const Label = {
  present: 'Present',
  absent: 'Absent',
  unrecorded: 'Unrecorded',
};

const StatusColor = {
  present: (theme: Theme) => theme.palette.play.main,
  absent: (theme: Theme) => theme.palette.error.main,
  unrecorded: (theme: Theme) => theme.grayCT.backgroundColor,
};

const StatusCount = ({ status, count }) => (
  <Paper
    elevation={3}
    sx={{
      display: 'flex',
      padding: '0.5rem',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '80px',
      width: '80px',
      borderRadius: '16px',
    }}
  >
    <Typography
      variant="body3"
      sx={{
        color: StatusColor[status],
      }}
    >
      {Label[status]}
    </Typography>
    <Typography
      variant="body2"
      sx={{
        color: StatusColor[status],
        fontWeight: 'bold',
        fontSize: '2rem',
      }}
    >
      {count || 0}
    </Typography>
  </Paper>
);

const AttendanceCard = ({ onLessonChange }: AttendanceCardProps) => {
  const { totalPresentStudents, totalAbsentStudents, totalUnrecordedStudents } =
    useAttendanceCounts();

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        padding: '1.25rem',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          gap: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2">Lesson Attendance</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          paddingBlock: '0.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <StatusCount status="present" count={totalPresentStudents} />
        <StatusCount status="absent" count={totalAbsentStudents} />
        <StatusCount status="unrecorded" count={totalUnrecordedStudents} />
      </Box>
      <AttendanceRecordButton
        fullWidth
        variant="contained"
        onLessonChange={onLessonChange}
        btnLabel="Record Attendance"
        dialogTitle="Lesson Attendance"
      />
    </Paper>
  );
};

export default AttendanceCard;
