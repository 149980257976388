import React, { useEffect } from 'react';
import { useUser } from '@reducers/user/hooks';
import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';
import _isEmpty from 'lodash/isEmpty';
import { useCurrentCourse } from '@reducers/courses/hooks';

// eslint-disable-next-line comma-spacing
const uniqueMap = <T, K>(arr: T[], cb: (i: T) => K): K[] => {
  const map = new Map<K, K>();
  arr.forEach((item) => {
    map.set(cb(item), cb(item));
  });
  return Array.from(map.values());
};

const AnalyticsSetUp = () => {
  const user = useUser();
  const course = useCurrentCourse();
  useEffect(() => {
    if (_isEmpty(user)) {
      return;
    }
    const org = (user.organizations || []) as Array<{
      name: string;
      id: string;
      district: { name: string; id: string };
    }>;
    // this analytics for segment
    // eslint-disable-next-line no-undef
    (
      window as unknown as Window & {
        analytics: {
          identify: (
            arg0: string,
            arg1: {
              [x: string]: string | number | null | undefined;
            },
          ) => void;
        };
      }
    ).analytics?.identify(user.id, {
      accountType: user.role,
      courseName: course.name,
      // we need this for intercom
      organizationName: org[0]?.name,
      organizationId: org[0]?.id,
      districtId: org[0]?.district?.id,
      districtName: org[0]?.district?.name,
      districts: uniqueMap(org, (o) => ({
        id: o.district?.id,
        name: o.district?.name,
      })),
      Name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
    });
    // this analytics for datadog
    datadogRum.setUser({
      id: user.id,
      accountType: user.role,
      organizationName: uniqueMap(org, (o) => o.name),
      organizationId: uniqueMap(org, (o) => o.id),
      districtName: uniqueMap(org, (o) => o.district?.name),
      districtId: uniqueMap(org, (o) => o.district?.id),
      courseName: course.name,
      courseId: course.id,
    });

    // this analytics for sentry
    Sentry.setUser({ id: user.id });
  }, [user, course]);
  return null;
};

export default AnalyticsSetUp;
