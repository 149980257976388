/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const StyledRow = styled.div<{
  isDropOver: boolean;
  withBorder?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: start;
  border-radius: 1rem;
  padding-block: 0.5em;
  position: relative;
  background-color: transparent;
  min-height: 4rem;
  width: 100%;
  ${({ isDropOver }) =>
    isDropOver &&
    css`
      background-color: #e0e0e0;
    `}
  ${({ withBorder, isDropOver }) =>
    withBorder &&
    css`
      & h2 {
        color: ${({ theme }) => theme.colorStyled.Action};
      }
      border: 1px dashed
        ${({ theme }) =>
          isDropOver ? theme.colorStyled.Action : theme.colorStyled.ColorBorder};
    `}
`;
