import Box from '@components/Atoms/Box';
import Card from '@components/Atoms/Card';
import Typography from '@components/Atoms/Typography';
import {
  actionCardBodyStyle,
  cardBaseStyle,
  mediaCardStyle,
  mediaCardWrapperStyle,
  practiceInfoWrapperStyle,
  typographyCustomStyle,
} from '@components/Component/OLP/tab/ActionCard/ActionCard.style';
import CardMedia from '@mui/material/CardMedia';
import React from 'react';
import Button from '@components/Atoms/Button';
import Tooltip from '@components/Atoms/Tooltip';
import { useLessonReview, useNavigateToLessonReviewSetup } from './hooks';
import { generateLessonReviewType, generateLessonReviewTypeTooltip } from './utils';

const imgUrl = '/images/group_activity.png';
const SetupCard = () => {
  const { data: LessonReview } = useLessonReview();
  const navigateToLessonReviewSetup = useNavigateToLessonReviewSetup();
  return (
    <Card sx={cardBaseStyle}>
      <Box sx={mediaCardWrapperStyle}>
        <CardMedia
          sx={mediaCardStyle}
          component="img"
          image={imgUrl}
          alt="assessment icon"
        />
      </Box>
      <Box sx={actionCardBodyStyle}>
        <Box sx={practiceInfoWrapperStyle}>
          <Box sx={{ pt: { xs: 2, sm: 0, md: 0 }, flex: 1 }}>
            <Typography
              data-testid="assessment-title"
              variant="h3"
              sx={typographyCustomStyle}
            >
              {LessonReview?.name}
            </Typography>
            <Typography
              data-testid="assessment-type-label"
              variant="body1"
              sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}
            >
              Question ordering :
              <Tooltip title={generateLessonReviewTypeTooltip(LessonReview?.type)}>
                <span>
                  <Typography
                    data-testid="assessment-type"
                    variant="subtitle1"
                    sx={{}}
                    component="p"
                  >
                    {generateLessonReviewType(LessonReview?.type)}
                  </Typography>
                </span>
              </Tooltip>
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 2,
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                sx={{ color: 'white' }}
                onClick={navigateToLessonReviewSetup}
              >
                Setup Assessment
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default SetupCard;
