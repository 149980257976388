const promisesCache = {};

const sharedPromise = <T>(promise: () => Promise<T>, key: string) => {
  if (promisesCache[key]) {
    return promisesCache[key];
  }
  promisesCache[key] = promise();
  promisesCache[key].finally(() => delete promisesCache[key]);

  return promisesCache[key];
};
export default sharedPromise;
