import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';
import 'react-image-lightbox/style.css';
import OnlinePracticeContentItem from './OnlinePracticeContentItem';
class OnlinePracticeContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questionSelected: 0,
      shouldDisplayGrammarHelper: false,
      startTime: moment(),
      isLightBoxOpen: false,
      imgSrc: '',
    };
  }

  componentDidMount() {
    const currentNode = ReactDOM.findDOMNode(this); // eslint-disable-line

    if (currentNode) {
      currentNode.addEventListener('click', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    const currentNode = ReactDOM.findDOMNode(this); // eslint-disable-line

    if (currentNode) {
      currentNode.removeEventListener('click', this.handleClick, false);
    }
  }

  openLightBox = (imgSrc) => {
    this.setState({
      isLightBoxOpen: true,
      imgSrc,
    });
  }

  closeLightbox = () => {
    this.setState({
      isLightBoxOpen: false,
      imgSrc: '',
    });
  }

  handleClick = (e) => {
    const { isLightBoxOpen } = this.state;
    const targetTagName = _get(e, 'target.tagName', '');

    if (targetTagName === 'IMG' && !isLightBoxOpen) {
      const src = _get(e, 'target.src', '');
      this.openLightBox(src);
    }
  }

  nextQuestion = () => {
    const { questionSelected } = this.state;
    const { questions } = this.props;

    if ((questionSelected + 1) >= questions.length) return;

    this.setState(prevState => ({
      questionSelected: prevState.questionSelected + 1,
      startTime: moment(),
      shouldDisplayGrammarHelper: false,
    }));
  }

  prevQuestion = () => {
    const { questionSelected } = this.state;
    if ((questionSelected - 1) < 0) return;

    this.setState(prevState => ({
      questionSelected: prevState.questionSelected - 1,
      startTime: moment(),
      shouldDisplayGrammarHelper: false,
    }));
  }

  displayGrammarHelper = (shouldDisplayGrammarHelper) => {
    this.setState({
      shouldDisplayGrammarHelper,
    });
  }

  selectQuestion = (newQuestionSelected, hasAnswer) => () => {
    if (!hasAnswer) return;

    this.setState({
      questionSelected: newQuestionSelected,
      shouldDisplayGrammarHelper: false,
    });
  }

  render() {
    const {
      isLightBoxOpen, imgSrc,
    } = this.state;
    const { props } = this;
    const addtionalProps = {
      ...this.state,
      nextQuestion: this.nextQuestion,
      prevQuestion: this.prevQuestion,
      selectQuestion: this.selectQuestion,
      displayGrammarHelper: this.displayGrammarHelper,
    };

    return (
      <OnlinePracticeContentItem
        closeLightbox={this.closeLightbox}
        isLightBoxOpen={isLightBoxOpen}
        lightBoxImgSrc={imgSrc}
        {...props}
        {...addtionalProps}
      />
    );
  }
}

OnlinePracticeContent.propTypes = {
  questions: PropTypes.array,
  practiceSessionId: PropTypes.any,
  isPracticeNavigation: PropTypes.bool,
  submittedAnswerList: PropTypes.shape(),
};

export default OnlinePracticeContent;
