/* eslint-disable sort-keys */
import { createTheme, darken, lighten } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    tableCT: {
      backgroundColor: string;
      hover: string;
      borderColor: string;
    };
    nav: {
      activeLinkBackGround: React.CSSProperties;
    };
    grayCT: {
      backgroundColor: string;
      hover: string;
      borderColor: string;
      color: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    tableCT?: {
      backgroundColor?: string;
      hover?: string;
      borderColor?: string;
    };
    nav?: {
      activeLinkBackGround?: string;
    };
    grayCT?: {
      backgroundColor?: string;
      hover?: string;
      borderColor?: string;
      color?: string;
    };
  }

  interface TypographyVariants {
    subtitle3: React.CSSProperties;
    body3: React.CSSProperties;
    actionText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    subtitle3?: React.CSSProperties;
    body3?: React.CSSProperties;
    actionText?: React.CSSProperties;
  }
  interface Palette {
    play: Palette['primary'];
    chipGrayCT: Palette['primary'];
  }

  interface PaletteOptions {
    play: PaletteOptions['primary'];
    chipGrayCT: PaletteOptions['primary'];
  }
}
// Update the Button's color prop options
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    play: true;
    chipGrayCT: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    play: true;
    chipGrayCT: true;
  }
}
declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    play: true;
    chipGrayCT: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle3: true;
    body3: true;
    actionText: true;
  }
}
const lightGrayColor = '#8d8d8d30';
const darkGrayColor = '#4A4845';
const playColor = '#49C797';
const MUItheme = createTheme({
  tableCT: {
    backgroundColor: '#FFFFFF',
    hover: '#F7F7F7',
    borderColor: '#EBEBEB',
  },
  nav: {
    activeLinkBackGround: '#dee8fd',
  },
  grayCT: {
    backgroundColor: darkGrayColor,
    hover: darken(darkGrayColor, 0.1),
    borderColor: darkGrayColor,
    color: darkGrayColor,
  },
  palette: {
    primary: {
      main: '#4786FC',
      contrastText: 'rgba(255,255,255,0.87)',
    },
    secondary: {
      main: '#F6A527',
      contrastText: '#ffffff',
    },
    play: {
      main: playColor,
      dark: darken(playColor, 0.2),
      light: lighten(playColor, 0.2),
      contrastText: '#ffffff',
    },
    info: {
      main: '#2196f3',
    },
    warning: {
      main: '#952f2b',
    },
    chipGrayCT: {
      main: darkGrayColor,
      dark: darken(darkGrayColor, 0.1),
      contrastText: '#ffffff',
    },
  },
  typography: {
    button: {
      fontWeight: 500,
      fontFamily: 'Roboto',
    },
    h3: {
      fontFamily: "'Montserrat'",
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '1.5em',
      color: '#F6A527',
      letterSpacing: '-0.016em',
    },
    h4: {
      fontFamily: "'Montserrat'",
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '1.5em',
      color: '#F6A527',
      letterSpacing: '-0.032em',
    },
    h5: {
      fontFamily: "'Montserrat'",
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '1.5em',
      color: '#F6A527',
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "'Montserrat'",
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '1.5em',
      color: '#F6A527',
      letterSpacing: '0.024em',
    },
    subtitle1: {
      fontFamily: '"Roboto"',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '1.5em',
      color: '#8D8D8D',
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: '"Roboto"',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '1.5em',
      color: '#8D8D8D',
      letterSpacing: '-0.04em',
    },
    subtitle3: {
      fontFamily: '"Roboto"',
      fontWeight: 500,
      fontSize: '11px',
      lineHeight: '1.5em',
      color: '#8D8D8D',
      letterSpacing: '0.022em',
    },
    body1: {
      fontFamily: '"Roboto"',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '1.5em',
      color: '#000000',
      letterSpacing: 0,
    },
    body2: {
      fontFamily: '"Roboto"',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '1.5em',
      color: '#000000',
      letterSpacing: 0,
    },
    body3: {
      fontFamily: '"Roboto"',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '1.5em',
      color: '#000000',
      letterSpacing: 0,
    },
    actionText: {
      fontFamily: '"Roboto"',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '1.5em',
      color: '#4786FC',
      letterSpacing: 0,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            lineHeight: '150%',
          }),
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            p: 1.5,
            gap: 1.5,
            display: 'flex',
            borderTop: `solid 1px ${lightGrayColor}`,
            justifyContent: 'end',
            '&>:not(:first-of-type)': {
              marginLeft: '0',
            },
          }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
            '& *': {
              textAlign: 'center',
            },
          }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            '.MuiButton-sizeSmall': {
              px: 1.2,
              py: 0.6,
            },
            '.MuiButton-sizeMedium': {
              px: 2,
              py: 1,
            },
            '.MuiButton-sizeLarge': {
              px: 3,
              py: 1.5,
            },

            textTransform: 'capitalize',
          }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 1px 4px #00000014',
          }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          marginRight: '0px',
        },
      },
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
  shape: {
    borderRadius: 8,
  },
});
export default MUItheme;
