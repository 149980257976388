/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

type ShowIfProps = {
  /** - The boolean value to determine whether to render the children or not. */
  If: boolean | undefined;
  /** - The child components to be rendered conditionally. */
  children: React.ReactNode | React.ReactNode[];
};

/**
 * HOC conditionally renders its children based on the provided boolean value.
 * @returns {JSX.Element|null} The rendered children if the condition is true, otherwise null.
 */
const ShowIf = ({ children, If = false }: ShowIfProps) => {
  if (!If) {
    return null;
  }
  return <>{children}</>;
};

export default ShowIf;
