import { useDrop } from 'react-dnd';
import React from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import {
  MonthItem,
  MonthItemContent,
  MonthItemHeader,
  MonthItemHeaderDate,
  TwoWeekItem,
  TwoWeekItemContent,
  TwoWeekItemHeader,
  TwoWeekItemHeaderDate,
  TwoWeekItemHeaderDay,
} from './Calendar.style';
import { WeekEvent, MonthEvent } from './EventComponents';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { ROLE_TYPE } from '../../utils/enums';
import { renderAlertModal } from '../../utils/userAlert';
import MediaQuery from 'react-responsive';

const today = moment().startOf();

const canDrop = (date, userRole, onEditMode) => (item) => {
  if (onEditMode) {
    if (userRole === ROLE_TYPE.STUDENT) return false;
    return true;
  }
  return false;
};

const dropFn = (
  date,
  updateDate,
  sectionScheduleUpdateFromCalendar,
  curriculumId,
  eventList,
  item
) => {
  const newDate = date.format('YYYY-MM-DD');
  const payload = {
    ...item,
    date: newDate,
    curriculum: curriculumId,
  };



  if (payload.type == 'reviews') {
    sectionScheduleUpdateFromCalendar({ payload, type: 'EDIT_REVIEW' });
  } else if (payload.type == 'lessons') {
    sectionScheduleUpdateFromCalendar({ payload, type: 'EDIT_LESSON' });
  } else if (payload.type == 'exams') {
    if (examValidation(payload, eventList)) {
      sectionScheduleUpdateFromCalendar({ payload, type: 'EDIT_EXAMS' });
    }
  }

  updateDate({
    ...item,
    date: newDate,
    curriculum: curriculumId,
  });
};

const examValidation = (event, eventList) => {
  const exams = eventList.filter((item) => item.type == 'exams');

  const examSessions = exams.sort((exam1, exam2) => compareExamsSession(exam1, exam2));

  const examsAfterEdit = [...exams];
  examsAfterEdit.find((item) => item.id == event.id).start_date = event.date;

  const examDates = examsAfterEdit.sort((exam1, exam2) => compareExamDate(exam1, exam2));
  const sessions = examSessions.map((s) => s.session_type);
  const dates = examDates.map((s) => s.session_type);

  if (JSON.stringify(sessions) !== JSON.stringify(dates)) {
    if (event.session_type == 0) {
      renderAlertModal('The placement exam must be scheduled before any other exam.');
    } else if (event.session_type == 1) {
      renderAlertModal('The midtest exam must be scheduled between the placement and the exit exam.');
    } else {
      renderAlertModal('The exit exam must be scheduled after every other exam.');
    }

    return false;
  }
  return true;
};

const compareExamsSession = (exam1, exam2) => {
  if (exam1.session_type < exam2.session_type) {
    return -1;
  }
  if (exam1.session_type >= exam2.session_type) {
    if (exam1.start_date >= exam2.start_date) {
      return 1;
    }
    return -1;
  }
  return 0;
};

const compareExamDate = (exam1, exam2) => {
  if (exam1.start_date < exam2.start_date) {
    return -1;
  }
  if (exam1.start_date >= exam2.start_date) {
    return 1;
  }

  return 0;
};

const openCreateSessionModal = (date, openCreateFormModal) => {
  const itemDate = moment(date, 'YYYY-MM-DD');
  const newDate = itemDate.toDate();
  const isEditable = itemDate.isSameOrAfter(today, 'day');

  if (!isEditable) return;
  openCreateFormModal(newDate);
};

export const WeekItemComponent = ({
  date,
  isToday,
  eventList,
  key,
  isDisabled,
  updateDate,
  curriculumId,
  userRole,
  openFormModal,
  courseId,
  sectionScheduleUpdateFromCalendar,
  onEditMode,
  scheduleList,
}) => {
  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item) => dropFn(
      date,
      updateDate,
      sectionScheduleUpdateFromCalendar,
      curriculumId,
      scheduleList,
      item
    ),
    canDrop: canDrop(date, userRole, onEditMode),
  });

  return (
    <TwoWeekItem key={key} disabled={isDisabled} ref={drop}>
      <TwoWeekItemHeader>
        <TwoWeekItemHeaderDay>{date.format('MM/DD')}</TwoWeekItemHeaderDay>
        <TwoWeekItemHeaderDate isToday={isToday}>
          {date.format('dddd')}
        </TwoWeekItemHeaderDate>
      </TwoWeekItemHeader>
      <TwoWeekItemContent>
        {eventList.map((e, index) => (
          <WeekEvent
            event={e}
            eventList
            key={index}
            courseId={courseId}
            openEditForm={() => openFormModal(e)}
            userRole={userRole}
          />
        ))}
      </TwoWeekItemContent>
    </TwoWeekItem>
  );
};

WeekItemComponent.propTypes = {
  date: PropTypes.shape(),
  isToday: PropTypes.bool,
  eventList: PropTypes.arrayOf(PropTypes.shape()),
  key: PropTypes.string,
  isDisabled: PropTypes.bool,
  userRole: PropTypes.string,
  updateDate: PropTypes.func.isRequired,
  curriculumId: PropTypes.number,
  openCreateFormModal: PropTypes.func,
  sessionSelected: PropTypes.object,
  openFormModal: PropTypes.func,
  courseId: PropTypes.string,
};

export const TwoWeekItemComponent = (props) => {
  const {
    date,
    isToday,
    eventList,
    key,
    isDisabled,
    updateDate,
    openFormModal,
    curriculumId,
    userRole,
    sectionScheduleUpdateFromCalendar,
    courseId,
    scheduleList,
    onEditMode,
    subjects,
  } = props;
  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item) => dropFn(
      date,
      updateDate,
      sectionScheduleUpdateFromCalendar,
      curriculumId,
      scheduleList,
      item
    ),
    canDrop: canDrop(date, userRole, onEditMode),
  });

  return (
    <TwoWeekItem key={key} disabled={isDisabled} ref={drop}>
      <TwoWeekItemHeader>
        <TwoWeekItemHeaderDate isToday={isToday}>
          {date.format('MM/DD')}
        </TwoWeekItemHeaderDate>
      </TwoWeekItemHeader>
      <TwoWeekItemContent>
        {eventList.map((e, index) => (
          <WeekEvent
            event={e}
            key={index}
            courseId={courseId}
            openEditForm={() => openFormModal(e)}
            userRole={userRole}
            scheduleList={scheduleList}
            subjects={subjects}
          />
        ))}
      </TwoWeekItemContent>
    </TwoWeekItem>
  );
};

TwoWeekItemComponent.propTypes = {
  date: PropTypes.shape(),
  isToday: PropTypes.bool,
  eventList: PropTypes.arrayOf(PropTypes.shape()),
  key: PropTypes.string,
  isDisabled: PropTypes.bool,
  userRole: PropTypes.string,
  updateDate: PropTypes.func.isRequired,
  openFormModal: PropTypes.func.isRequired,
  curriculumId: PropTypes.number,
  openCreateFormModal: PropTypes.func,
  sessionSelected: PropTypes.object,
  courseId: PropTypes.string,
};

export const MonthItemComponent = ({
  date,
  isToday,
  eventList,
  key,
  isDisabled,
  updateDate,
  sectionScheduleUpdateFromCalendar,
  openFormModal,
  curriculumId,
  userRole,
  openCreateFormModal,
  courseId,
  sessionSelected,
  onEditMode,
  scheduleList,
  subjects,
}) => {
  const [, drop] = useDrop({
    accept: 'ITEM',
    drop: (item) => dropFn(
      date,
      updateDate,
      sectionScheduleUpdateFromCalendar,
      curriculumId,
      scheduleList,
      item
    ),
    canDrop: canDrop(date, userRole, onEditMode),
  });

  return (
    <MonthItem key={key} disabled={isDisabled} ref={drop}>
      <MonthItemHeader
        onClick={() => openCreateSessionModal(date, openCreateFormModal)}
      >
        <MonthItemHeaderDate isToday={isToday}>
          {date.format('MM/DD')}
        </MonthItemHeaderDate>
      </MonthItemHeader>
      <ShadowScrollbar
        autoHide
        style={{
          height: 'calc(100% - 3em)',
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <MonthItemContent>
          {eventList.map((e, index) => (
            <MonthEvent
              event={e}
              key={index}
              courseId={courseId}
              openEditForm={() => openFormModal(e)}
              userRole={userRole}
              subjects={subjects}
              scheduleList={scheduleList}
            />
          ))}
        </MonthItemContent>
      </ShadowScrollbar>
    </MonthItem>
  );
};

MonthItemComponent.propTypes = {
  date: PropTypes.shape(),
  isToday: PropTypes.bool,
  eventList: PropTypes.arrayOf(PropTypes.shape()),
  key: PropTypes.string,
  isDisabled: PropTypes.bool,
  userRole: PropTypes.string,
  updateDate: PropTypes.func.isRequired,
  openFormModal: PropTypes.func.isRequired,
  curriculumId: PropTypes.number,
  openCreateFormModal: PropTypes.func,
  sessionSelected: PropTypes.object,
  courseId: PropTypes.string,
};
