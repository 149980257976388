/* eslint-disable react/no-array-index-key */
import React, { PureComponent, Suspense } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import ExamScoreTable from '@components/Component/Exams/ExamScoreTable';
import ExamScoreCard from '@components/Component/Exams/ExamScoreCard';
import ExamScoreCardSkelton from '@components/Component/Exams/ExamScoreCard/ExamScoreCard.skeleton';
import ShowIf from '@Atoms/ShowIf';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { ExamSectionTableV2 } from '../../containers/ExamSectionTable';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import MainContent from '../../containers/MainContent';
import LoadingIndicator from '../LoadingIndicator';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { ROLE_TYPE } from '../../utils/enums';

const scrollStyle = {
  height: 'calc(100% - 4em)',
  width: '100%',
  overflow: 'auto',
};

class TestDetailContentV2 extends PureComponent {
  componentDidMount() {
    const { examSessionId } = this.props;

    if (examSessionId) {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { examSessionId, shouldFetchExamResult } = this.props;
    const {
      examSessionId: prevExamSessionId,
      shouldFetchExamResult: prevShouldFetchExamResult,
    } = prevProps;

    if (
      examSessionId &&
      (!_isEqual(prevExamSessionId, examSessionId) ||
        (shouldFetchExamResult &&
          !_isEqual(shouldFetchExamResult, prevShouldFetchExamResult)))
    ) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    const {
      examSessionId,
      sectionExamGetDetail,
      examResultGetDetail,
      userRole,
      examResultStudentGetDetail,
    } = this.props;

    if (userRole === ROLE_TYPE.INSTRUCTOR) {
      examResultGetDetail(examSessionId);
    }

    if (userRole === ROLE_TYPE.STUDENT) {
      examResultStudentGetDetail(examSessionId);
    }

    sectionExamGetDetail(examSessionId);
  };

  render() {
    const {
      examDetail,
      userRole,
      courseDetail,
      sectionDetail,
      isSideBarClose,
      isGettingSectionExam,
      isGettingExamResult,
    } = this.props;
    const shouldDisplayScoreTable = userRole === ROLE_TYPE.INSTRUCTOR;
    const breadcumbList = ['Test'];
    if (!_isEmpty(examDetail)) {
      const sessionname = `Practice ${examDetail.practice_test_order + 1} Test`;
      breadcumbList.push(sessionname);
    }

    return (
      <MainContent>
        <BreadcumbsFilter
          haveSub={isSideBarClose}
          breadcumbSubList={[courseDetail.name, sectionDetail.name]}
          breadcumbList={breadcumbList}
          mobileTitle="Test"
        />
        <ShowIf If={isGettingExamResult}>
          <LoadingIndicator />
        </ShowIf>
        <ShowIf If={!isGettingExamResult}>
          <ShadowScrollbar autoHide style={scrollStyle}>
            <DefaultContentWrap large>
              <Suspense fallback={<ExamScoreCardSkelton />}>
                <ExamScoreCard />
              </Suspense>
              <ExamSectionTableV2
                examDetail={examDetail}
                isGettingSectionExam={isGettingSectionExam}
              />
              {shouldDisplayScoreTable && <ExamScoreTable />}
            </DefaultContentWrap>
          </ShadowScrollbar>
        </ShowIf>
      </MainContent>
    );
  }
}

TestDetailContentV2.propTypes = {
  sectionExamGetDetail: PropTypes.func,
  examSessionId: PropTypes.any,
  examDetail: PropTypes.object,
  examResultGetDetail: PropTypes.func,
  averageMaxScore: PropTypes.any,
  averageScore: PropTypes.any,
  sectionScoreById: PropTypes.object,
  userRole: PropTypes.string,
  examResultStudentGetDetail: PropTypes.func,
  courseDetail: PropTypes.shape().isRequired,
  sectionDetail: PropTypes.shape().isRequired,
  isSideBarClose: PropTypes.bool,
  isGettingSectionExam: PropTypes.bool,
  currentCurriculum: PropTypes.any,
  isGettingExamResult: PropTypes.bool,
};

export default TestDetailContentV2;
