import axios from 'axios';
import {
  osName, osVersion, fullBrowserVersion, browserName,
} from 'react-device-detect';
import { handleError } from './utils';

// payload including old_password, new_password1, new_password2
export function submitUserEdit(userId, payload) {
  const url = `/api/users/${userId}/`;
  return axios.put(
    url,
    payload,
  )
    .then(response => ({ response }))
    .catch(handleError);
}

export function changePassword(payload) {
  const url = '/api/auth/change-password/';
  return axios.post(
    url,
    payload,
  )
    .then(response => ({ response }))
    .catch(handleError);
}

export function userReportIssue(payload) {
  const url = '/api/users/report-issue/';
  payload = {
    ...payload,
    screen_size: `${window.innerWidth} X ${window.innerHeight}`,
    Browser_info: `${osName} ${osVersion} ${browserName} ${fullBrowserVersion}`,
    replay_url: window.DD_RUM?.getSessionReplayLink(),
    path: window.location.href,
  };

  return axios.post(
    url,
    payload,
  )
    .then(response => ({ response }))
    .catch(handleError);
}
