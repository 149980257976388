import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useReducer,
} from 'react';
import { reportAPI } from './api';

const NEW_IW_VALUES = () => {
  const criteria = {};
  return {
    criteria,
    notes: '',
  };
};
const IWReducerInitialState = {
  currentPageIWId: null,
  currentSiderIWId: null,
  selectedTeacher: null,
  values: NEW_IW_VALUES(),
  isSiderOpen: false,
};
const IWContext = createContext({});
const IWReducer = (state, action) => {
  switch (action.type) {
    case 'setPageIWId':
      return { ...state, currentPageIWId: action.value, values: NEW_IW_VALUES() };
    case 'setSiderIWId':
      return { ...state, currentSiderIWId: action.value };
    case 'setSelectedTeacher':
      return { ...state, selectedTeacher: action.value };
    case 'setValues':
      return { ...state, values: action.value };
    case 'setIsSiderOpen':
      return { ...state, isSiderOpen: action.value };
    case 'setNote':
      return { ...state, values: { ...state.values, notes: action.value } };
    case 'setCriteria':
      return {
        ...state,
        values: {
          ...state.values,
          criteria: { ...state.values.criteria, ...action.value },
        },
      };
    case 'saveReport':
      return {
        ...state,
        currentSiderIWId: null,
        isSiderOpen: false,
        selectedTeacher: null,
        values: NEW_IW_VALUES(),
      };
    default:
      return state;
  }
};
const IWContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(IWReducer, IWReducerInitialState);
  // const [currentIWId, setIWId] = useState(null);
  // const [selectedTeacher, setSelectedTeacher] = useState(null);
  // const [values, setValues] = useState(NEW_IW_VALUES());
  // const [isSiderOpen, setIsSiderOpen] = useState(false);
  const answerCriteria = (id, value) => {
    dispatch({ type: 'setCriteria', value: { [id]: value } });
  };
  const addNote = (value) => {
    dispatch({ type: 'setNote', value });
  };
  const handleSaveReport = () => {
    reportAPI.create({
      teacher: state.selectedTeacher,
      ...state.values,
      IWId: state.currentSiderIWId,
      lesson: null,
    });
    dispatch({ type: 'saveReport' });
  };
  const setPageIWId = (value) => {
    dispatch({ type: 'setPageIWId', value });
  };
  const setSiderIWId = (value) => {
    dispatch({ type: 'setSiderIWId', value });
  };
  const setSelectedTeacher = (value) => {
    dispatch({ type: 'setSelectedTeacher', value });
  };
  const setIsSiderOpen = (value) => {
    dispatch({ type: 'setIsSiderOpen', value });
  };
  const value = useMemo(
    () => ({
      addNote,
      answerCriteria,
      handleSaveReport,
      setIsSiderOpen,
      setPageIWId,
      setSelectedTeacher,
      setSiderIWId,
      ...state,
    }),
    [state],
  );
  return <IWContext.Provider value={value}>{children}</IWContext.Provider>;
};

const useIWContext = () => {
  const context = useContext(IWContext);
  if (!context) {
    throw new Error('useIWContext must be used within a IWContextProvider');
  }
  return context;
};

const WithIW = (Children) => {
  const Comp = (props) => (
    <IWContextProvider>
      <Children {...props} />
    </IWContextProvider>
  );
  Comp.displayName = Children.displayName;
  return Comp;
};

export { useIWContext, IWContextProvider, IWContext, WithIW };
