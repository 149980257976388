import styled from 'styled-components';

export const PracticeAnswerExplanationWrap = styled.div`
  flex: 0 0 30em;
  transition: all 300ms ease;
  padding: 0 2em;
  padding-bottom: 3em;
  height: 100%;
  ${({ unSetHeight }) =>
    unSetHeight &&
    `
      height: unset;
    `}

  overflow-y: scroll;
  @media (max-width: 1369px) {
    flex: 0 0 25em;
  }
  @media (max-width: 1280px) {
    flex: 0 0 100%;
    margin-left: 0em;
  }
  @media (max-width: 768px) {
    margin-top: 3em;
    padding: 0 !important;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StudentListAnswerWrap = styled.div`
  width: 100%;
  p {
    width: 100%;
    color: #797979;
    line-height: 2.5em;
    padding-bottom: .5em;
    margin-bottom: 1em;
    font-size: ${props => props.theme.fontSize.SmallFontSize};
    border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
`;

export const StudentListAnswer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: ${props => props.theme.fontSize.SmallFontSize};
    font-family: 'MontserratSemiBold';
    text-transform: capitalize;
    margin-bottom: .5em;
  }
`;

export const AnswerExplanationDecs = styled.div`
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  font-family: 'MontserratRegular';
  flex: 1;
  color: ${props => props.theme.colorStyled.ColorDarkGrey};
  line-height: 18px;
  margin-bottom: 2em;

  strong{
    font-family: 'MontserratBold';
  }
  p {
    text-align: left;
    padding-bottom: 2em;
  }
  .boldText{
    font-family: 'MontserratBold';
    text-decoration: underline;
    display: inline-block;
    margin-top: 15px;
  }
  .boldText:after {
    content: ":"
  }
`;
