/* eslint-disable react/no-array-index-key */
import React from 'react';
import Box from '@mui/material/Box';
import {
  useIsQuestionLocked,
  usePractice,
} from '@components/PracticeV2/Provider/PracticeProvider';
import AnswerGridChoiceCell from './AnswerGridChoiceCell';
import {
  PracticeAnswerGridChoiceWrap,
  AnswerGridChoiceWrap,
  AnswerGridChoiceItem,
  AnswerGridChoiceColumn,
  AnswerGridChoiceColumnSelected,
  ChoiceSelected,
} from './AnswerGridChoice.style';
import { answerChoiceData } from './AnswerChoiceData';
import GroupDataView from '../GroupDataView';

const selectedValueInit = ['_', '_', '_', '_'];

const AnswerGridChoice = () => {
  const [selectedValue, setSelectedValue] = React.useState(selectedValueInit);
  const { handleAnswerChange, currentAnswer } = usePractice();
  const isQuestionLocked = useIsQuestionLocked();
  const handelOnCellClick = React.useCallback(
    (columnIndex, value) => {
      if (isQuestionLocked) {
        return;
      }
      const newSelectedValue = [...selectedValue];
      newSelectedValue[columnIndex] = value;
      setSelectedValue(newSelectedValue);
      handleAnswerChange(newSelectedValue.join('').replaceAll('_', ''));
    },
    [setSelectedValue, selectedValue, isQuestionLocked],
  );

  React.useEffect(() => {
    if (currentAnswer) {
      const newSelectedValue = [...selectedValueInit];
      (currentAnswer as string).split('').forEach((value, index) => {
        newSelectedValue[index] = value;
      });
      setSelectedValue(newSelectedValue);
    }
  }, []);

  return (
    <Box data-testid="answer-grid-choice">
      <PracticeAnswerGridChoiceWrap>
        <AnswerGridChoiceWrap>
          <AnswerGridChoiceColumnSelected>
            {selectedValue.map((value, index) => {
              const valueDisplay = value !== '_' ? value : '';

              return (
                <ChoiceSelected key={`${value}${index}`} data-testid="selected-values">
                  <p>{valueDisplay}</p>
                </ChoiceSelected>
              );
            })}
          </AnswerGridChoiceColumnSelected>
          <AnswerGridChoiceItem>
            {answerChoiceData.map((col, colIndex) => (
              <AnswerGridChoiceColumn
                key={colIndex}
                data-testid="answer-grid-choice-column"
              >
                {col.map((row, rowIndex) => (
                  <AnswerGridChoiceCell
                    key={`${colIndex}${rowIndex}`}
                    rowData={row}
                    columnValue={selectedValue[colIndex]}
                    columnIndex={colIndex}
                    onCellClick={handelOnCellClick}
                  />
                ))}
              </AnswerGridChoiceColumn>
            ))}
          </AnswerGridChoiceItem>
        </AnswerGridChoiceWrap>
      </PracticeAnswerGridChoiceWrap>
      <GroupDataView />
    </Box>
  );
};
export default React.memo(AnswerGridChoice);
