import styled, { css } from 'styled-components';


export const SectionListWrap = styled.div``;

export const SectionRowWrap = styled.div`
  padding: 0.575em 0em 0em;
  margin-bottom: 1em;
  border-radius: 0.5em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  box-shadow: 0px 0px 6px -1px ${(props) => props.theme.colorStyled.ColorBoxShadow};
  @media (max-width: 768px) {
    width: 600px;
  }
`;

export const SectionRowContent = styled.div`
  flex: 1;
  text-align: center;
  user-select: none;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  &:first-child {
    text-align: left;
    flex: 0 0 190px;
  }
  ${({ actionType }) =>
    actionType &&
    css`
      flex: 0 0 150px;
      text-align: right;
    `}

  ${({ rightAlign }) =>
    rightAlign &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
  ${({ isOpen }) =>
    isOpen &&
    css`
      Opacity: 20%;
    `}
`;
export const PreviousTestRedirect = styled.div`
  color:${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  text-align: left;
  font: normal normal medium 14px/16px Roboto;
`
export const SectionRowIcon = styled.i`
  margin-right: 1em;
  margin-top: 0.55em;
  color: ${(props) =>
    props.disabled
      ? props.theme.colorStyled.ColorSecond
      : props.theme.colorStyled.ColorPrimaryBlue};
  font-size: ${(props) => props.theme.fontSize.HeadingH4FontSize};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 15px;
    position: relative;
    bottom: 2px;
  }
`;
export const SectionRowIconImg = styled.img`
  margin-left: 0.75em;
  width: 18px;
  height: 18px;
  cursor: pointer;
  opacity: ${(props) => props.opacity};
  span {
    color: ${(props) => props.theme.colorStyled.ColorBgHover};
    font-size: ${(props) => props.theme.fontSize.HeadingH1FontSize};
    transition: color ease 300ms;
  }
  &:hover {
    opacity: ${(props) => Number(props.opacity) - 0.3};
    span {
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    }
  }
  &:after {
    height: 1.375em;
    width: 3px;
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
    position: absolute;
    left: 0px;
    bottom: calc(50% - 1.375em / 2);
    border-radius: 0px 2px 2px 0px;
  }
`;

export const SectionRowItem = styled.div`
  display: flex;
  width: 100%;
  min-height: 45px;
  align-items: center;
  padding: 0em 1em 0.7em 1em;
`;

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    overflow: auto;
  }
`;

export const ResumeIcon = styled.span`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent; 
  border-bottom: 6px solid transparent; 
  border-left: 10px solid ${(props) => props.theme.colorStyled.ColorPrimaryBlue}; 
  margin-right: 6px;
`

export const TestletStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
`;

export const StatusTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusText = styled.span`
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
  padding-bottom: 5px;
  color: ${props => (props.status === 'Complete' ? '#1EC798' : '#000000')};
`;

export const CompleteStatusIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-bottom: 5px;
`;

export const LockedStatusIcon = styled.img`
  width: 13px;
  height: 13px;
  margin-bottom: 5px;
`;

export const StatusDate = styled.span`
  font-size: 0.9em;
  color: grey;
`;
