/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import { styled as muiStyled } from '@mui/material/styles';
import { TooltipProps } from '@mui/material/Tooltip';

export const NavBarListWrap = styled.div`
  position: absolute;
  top: 0em;
  left: 0em;
  width: 20em;
  height: 100vh;
  background: ${(props) => props.theme.colorStyled.ColorWhite};
  transition: all 300ms ease;
  z-index: 1;
  ${({ isClose }) =>
    isClose &&
    css`
      left: -20em;
    `};
  ${({ noAbsolute }) =>
    noAbsolute &&
    css`
      position: unset;
      margin-right: 1em;
      height: 100%;
      background: transparent;
    `}
`;

export const NavBarButton = styled.div`
  padding: 0.75em 1em;
  margin: 0em 1em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    border-radius: 0.325em;
    background-color: #f1f1f1;
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  }
  ${({ isPrimary }) =>
    isPrimary &&
    css`
      color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    `}
`;

export const NavBarTitle = styled(NavBarButton)`
  font-family: 'MontserratSemiBold';
  padding: 0.75em 1em;
  margin: 1em 0 0 0;
  &:hover {
    background-color: transparent;
    color: initial;
  }
  ${({ lightColor }) =>
    lightColor &&
    css`
      color: ${(props) => props.theme.colorStyled.ColorTime};
      font-family: 'MontserratMedium';
      margin-left: 0.25em;
      &:hover {
        background-color: transparent;
        color: ${(props) => props.theme.colorStyled.ColorTime};
      }
    `}
`;

export const NavBarTitlePlus = styled.div`
  font-size: 1.5em;
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
`;

export const NavBarList = styled.div`
  border-radius: 0.25em;
  position: relative;
  &:last-child {
    margin-bottom: 0em;
  }

  ${({ isScroll }) =>
    isScroll &&
    css`
      overflow: auto;
      height: calc(100vh - 110px);
    `}
`;

export const NavBarNumberItem = styled.span`
  border-radius: 5px;
  border: 1px solid #a22325;
  padding: 2px 10px;
  color: #fff;
  font-size: 0.8em;
  background: #a22325;
  margin-left: auto;
  min-width: 3em;
  text-align: center;
  height: 2em;
`;

export const NavBarItem = styled.div`
  flex: 1;
  position: relative;
  padding: 1em;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  background-color: ${(props) => props.theme.colorStyled.ColorBgSilver};
  min-height: 7em;
  &:hover {
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
    box-shadow: 0px 0px 12px -8px #000;
    z-index: 1;
  }
  &:first-child {
    border-top: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${(props) => props.theme.colorStyled.ColorWhite};
      box-shadow: 0px 0px 14px -8px #000;
      z-index: 1;
    `};
`;

export const NavBarItemCustom = styled.div`
  flex: 1;
  position: relative;
  padding: 0.75em 1em 0.75em 0.5em;
  cursor: pointer;
  padding-bottom: 1.5rem;
`;

export const NavBarItemWrap = styled.div`
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  border-left: 0.25em solid transparent;
  background-color: white;
  min-height: 8em;
  font-size: 13px;
  &:hover {
    background-color: #f6f7fb;
    box-shadow: 0px 0px 12px -10px #000;
    z-index: 1;
  }
  ${({ cantClick }) =>
    cantClick &&
    css`
      opacity: 50%;
    `}
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #e5edfe;
      box-shadow: 0px 0px 14px -10px #000;
      border-left: 0.25em solid #4886fc;
      z-index: 1;
      &:hover {
        background-color: #e5edfe;
      }
    `};
`;

export const ExamToolTip = withStyles({
  tooltip: {
    maxWidth: '200px',
    display: 'flex',
    padding: '16px',
    textAlign: 'center',
    background: '#6F6F6F 0% 0% no-repeat padding-box',
    borderRadius: '7px',
    opacity: 1,
    font: 'normal normal normal 12px/14px Roboto',
    letterSpacing: '0px',
  },
})(Tooltip);

type NavBarIconWrapProps = {
  isSingleItem?: boolean;
  isFirstItem?: boolean;
  isLastItem?: boolean;
};

export const NavBarIconWrap = styled.div<NavBarIconWrapProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  .chalktalk-quiz {
    font-size: ${(props) => props.theme.fontSize.HeadingH5FontSize};
    padding: 0.275em;
  }
  &:before {
    content: '';
    width: 2px;
    left: 50%;
    position: absolute;
    background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    height: calc(50% - 10px);
    top: 0px;
    z-index: 0;
  }
  &:after {
    content: '';
    left: 50%;
    width: 2px;
    position: absolute;
    background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    height: calc(50% - 10px);
    bottom: 0px;
    z-index: 0;
  }
  ${({ isSingleItem }) =>
    isSingleItem &&
    css`
      &:before,
      &:after {
        content: none;
      }
    `}
  ${({ isFirstItem }) =>
    isFirstItem &&
    css`
      &:before {
        content: none;
      }
    `}
  ${({ isLastItem }) =>
    isLastItem &&
    css`
      &:after {
        content: none;
      }
    `}
`;

export const NavBarIcon = styled.div`
  font-size: ${(props) => props.theme.fontSize.HeadingH2FontSize};
  padding: 0.175em;
  border-radius: 100%;

  ${({ dashboard }) =>
    dashboard &&
    css`
      img {
        width: ${(props) =>
          props.activity_type == 1 ? '42px' : props.activity_type == 0 ? '32px' : '22px'};
      }
    `}
  ${({ center }) =>
    center &&
    css`
      font-size: 2em !important;
      padding: 0.6em;
      img {
        width: ${(props) =>
          props.activity_type == 1 ? '50px' : props.activity_type == 0 ? '40px' : '30px'};
      }
    `}
`;

export const NavBarItemDateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0a5d3d;
  padding: 0.5em 0.75em;
  flex: 0 0 4em;
  font-size: ${(props) => props.theme.fontSize.SmallFontSize};
`;

export interface NavBarItemDayProps {
  wide?: boolean;
}

export const NavBarItemDay = styled.div<NavBarItemDayProps>`
  margin: 0.25em 0em;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  ${({ wide }) =>
    wide &&
    css`
      width: 4em;
    `}
`;

export const NavBarItemMonth = styled.div`
  text-transform: uppercase;
  text-wrap: nowrap;
`;

export const NavBarAction = styled.div`
  margin: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 0.75em 1em;
    display: flex;
    align-items: center;
    width: 100%;
    i {
      margin-right: 0.5em;
    }
  }
`;

export const BreakLine = styled.div`
  height: 2px;
  padding: 0em 0em;
  margin: 1em;
  background-color: ${(props) => props.theme.colorStyled.ColorBorderInput};
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 1em 0em;
    `}
`;

export const NavBarItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  min-height: 1.5em;
  position: relative;
  > div {
    font-size: ${(props) => props.theme.fontSize.DefaultFonts};
    background: #8d8d8d;
    border-radius: 0.325em;
    padding: 0.125em 0.5em;
    color: ${(props) => props.theme.colorStyled.ColorWhite};
  }
  p {
    font-size: ${(props) => props.theme.fontSize.SmallerFontSize};
    color: ${(props) => props.theme.colorStyled.ColorTime};
    width: 6em;
    text-align: right;
    margin-left: 0.5em;
    position: absolute;
    right: 0em;
  }
`;

export const NavBarHeadText = styled.div`
  margin-left: 0em;
  ${({ primary }) =>
    primary &&
    css`
      margin-left: 0em;
    `}
`;

export const NavBarStatusColumn = styled.div`
  flex: 0 0 2em;
  margin-left: 0.5em;
`;

export const NavBarGroup = styled.div``;

export const NavBarGroupTitle = styled.div`
  margin: 1em;
  color: ${(props) => props.theme.colorStyled.ColorHeaderTable};
`;

export const NavBarName = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};

  i {
    font-size: ${(props) => props.theme.fontSize.HeadingH2FontSize};
  }
`;

export const NavBarStatus = styled.div`
  color: #bababa;
  font-size: 0.75em;
  margin-top: 1em;
`;

export const ProgressBarWrap = styled.div`
  position: absolute;
  bottom: 0em;
  width: calc(100% - 1.5em);
  ${({ variant2 }) =>
    variant2 &&
    css`
      position: inherit;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0 0.75em;
      span {
        padding: 0 5px;
        color: #efa536;
        font-size: 12px;
      }
    `}

  ${({ isUnitView }) =>
    isUnitView &&
    css`
      padding: 0;
      span {
        padding: 0 5px;
        color: #0c0c0c;
        font-size: 12px;
      }
    `}
`;

export const StatusLabel = styled.div`
  color: #bababa;
  font-size: ${(props) => props.theme.fontSize.SmallFontSize};
  margin-bottom: 0.125em;
`;

export const ProgressSegment = styled.div`
  ${({ variant2 }) =>
    variant2 &&
    css`
      max-width: 80%;
      background: rgba(239, 165, 54, 0.1);
      flex: 1;
    `}
`;

export const ProgressSegmentItem = styled.div`
  width: ${(props) => props.rate}%;
  height: 3px;
  border-radius: 7px 7px 0px 0px;
  background: ${(props) => {
    if (props.isNotStarted) {
      return props.theme.colorStyled.ColorLightGrey;
    }
    if (props.isStarted) {
      return props.theme.colorStyled.ColorLightCoral;
    }
    if (props.isInprogress) {
      return props.theme.colorStyled.ColorPeach;
    }
    if (props.isCompleted) {
      return props.theme.colorStyled.ColorPrimaryGreen;
    }
  }};
  ${({ variant2 }) =>
    variant2 &&
    css`
      background: #efa536;
      border-radius: 2px;
    `}
  ${({ isUnitName }) =>
    isUnitName &&
    css`
      background: #cccbcb;
      border-radius: 2px;
    `}
`;

export const NavBarBackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  margin: 0 1em 2em;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  color: ${(props) => props.theme.colorStyled.ColorGrey};
  cursor: pointer;
  position: relative;
  .chalktalk-previous {
    margin-right: 1em;
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  }
  &:after {
    content: '';
    position: absolute;
    bottom: -1em;
    left: 0em;
    height: 2px;
    width: 100%;
    background-color: ${(props) => props.theme.colorStyled.ColorBorderInput};
  }
  &:hover {
    background: ${(props) => props.theme.colorStyled.ColorBtnDark};
    color: ${(props) => props.theme.colorStyled.ColorBlack};
    border-radius: 0.325em;
  }
  ${({ noBorder }) =>
    noBorder &&
    css`
      &:after {
        content: none;
      }
    `}
`;

export const MoveSeesionButton = styled.button`
  width: 100%;
  background-color: #4886ff;
  padding: 0.35em;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 20px;
  font-family: 'MontserratLight';
`;

export const PropertiseBar = styled.div`
  position: absolute;
  bottom: 0em;
  width: calc(100% - 1.5em);
  display: flex;
  justify-content: space-between;
  gap: 3px;

  ${({ hasAllItems }) =>
    hasAllItems &&
    css`
      > div {
        flex-basis: 30%;
      }
    `}

  ${({ hasAllItems, isSession }) =>
    hasAllItems &&
    isSession &&
    css`
      > div {
        > div {
          font-size: 0.7125em;
        }
      }
    `}
`;

export const PropertiseBarWrapper = styled.div`
  flex-basis: 48%;
`;

export const PropertiseBarTitle = styled.div`
  font-size: 0.8125em;
  color: #aeaeae;
  text-align: center;
  margin-right: 5px;
  white-space: nowrap;
`;

export const PropertiseBarProgress = styled.div`
  height: 3px;
  border-radius: 0.325em 0.325em 0em 0em;
  overflow: hidden;
  background: #dfe2e6;
  background: ${(props) => {
    if (props.isFinish) {
      return props.theme.colorStyled.ColorPrimaryGreen;
    }
    return '#dfe2e6';
  }};
`;

export const UnitSubNavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const UnitNameWrapper = styled.div`
  margin: 0em 1em;
  padding: 1em;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  text-shadow: 0px 2px 3px rgba(255, 255, 255, 0.5);
`;

export const UnitContenWrapper = styled.div``;

export const NavBarTabWrap = styled.div`
  display: flex;
  margin: 2em 1em 0em;
  .react-tabs__tab-list {
    display: flex;
    flex: 1;
  }
  .react-tabs__tab {
    flex: 0 0 50%;
    padding: 0.75em 1em;
    text-align: center;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
    &:first-child {
      border-radius: 0.325em 0em 0em 0.325em;
      border-right: none;
    }
    &:last-child {
      border-radius: 0em 0.325em 0.325em 0em;
    }
    &:hover {
      background: #f3f3f3;
    }
  }
  .react-tabs__tab--selected {
    background: #f3f3f3;
  }
`;

export const FilterSubjectsWrap = styled.div`
  display: flex;
  flex: 0 1;
`;

export const SearchButton = styled.div`
  width: 0;
  position: relative;
  top: 7px;
  right: 20px;
  color: #d9dbde;
`;

export const RotateElement = styled.div`
  width: 15px;
  height: 15px;
  background-color: white;
  position: absolute;
  top: 14em;
  left: 16.5em;
  transform: rotate(45deg);
  box-shadow: -1px -1px 4px -2px #000000;
`;
export const DropdownWrap = styled.div`
  position: absolute;
  background: #ffffff;
  margin-top: 0.5em;
  width: max-content;
  border-radius: 12px;
  box-shadow: 0px 0px 4px -2px #000000;
  top: 13.7em;
  left: 15.5em;
  z-index: 9;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  // width: max-content;
  padding: 2px 10px 2px 5px;
  color: ${(props) => props.theme.colorStyled.ColorPrimary};
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${(props) => props.theme.colorStyled.ColorBgContent};
    border-color: ${(props) => props.theme.colorStyled.ColorBgContent};
    &:first-child {
      border-radius: 0.325em 0.325em 0em 0em;
    }
    &:last-child {
      border-radius: 0em 0em 0.325em 0.325em;
    }
  }
  a {
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
`;

export const LabelStudentName = styled.label`
  text-transform: capitalize;
`;

export const FilteredText = styled.label``;

export const SupjectFilteredList = styled.div`
  margin-top: 5px;
  width: 100%;
  height: auto;
`;

export const FilteredBlock = styled.div`
  display: flex;
  width: max-content;
  background-color: ${(props) => props.theme.colorStyled.ColorBgSilver};
  padding: 6px 5px 6px 9px;
  font-size: 10px;
  border-radius: 10px;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
  i {
    width: 15px;
    height: 15px;
    background-color: #6b6b6b;
    border-radius: 50%;
    padding: 2px 0px 0px 1.5px;
    color: white;
    font-size: 12px;
    margin-left: 20px;
  }
`;

export const TrianglReight = styled.div`
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  position: fixed;
  border-left: 51px solid white;
  border-bottom: 25px solid transparent;
  right: 7em;
  top: 8.2em;
`;

export const EditModalTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1.3em;
  ${({ center }) =>
    center &&
    css`
      text-algin: center;
    `}
  ${({ minPopup }) =>
    minPopup &&
    css`
      margin-bottom: 0;
    `}
  ${({ isSubject }) =>
    isSubject &&
    css`
      font-family: 'MontserratLight';
      margin-bottom: 0.8em;
    `}
`;

export const LessonsDate = styled.div`
  font-size: 20px;
  font-family: 'MontserratLight';
  margin-top: 1.5em;
`;

export const RescheduleButton = styled.button`
  height: fit-content;
  padding: 5px 4.3em;
  background: transparent linear-gradient(180deg, #ffffff 0%, #eeeeee 100%) 0% 0%
    no-repeat padding-box;
  border: 1px solid #c7c7c7 !important;
  border-radius: 12px;
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: 5px;
    `}
`;

export const SaveCancelScheduleButtons = styled.button`
  background-color: #ff9d17;
  padding: 9px 3em;
  border: none;
  border-radius: 5px;
  color: white;
  font-family: 'MontserratLight';
  ${({ cancel }) =>
    cancel &&
    css`
      background-color: #cecdcd;
      margin-right: 5px;
    `}
`;

export const ScheduleActions = styled.div`
  display: flex;
  margin-top: 1.5em;
  gap: 10px;
  align-items: center;
  ${({ right }) =>
    right &&
    css`
      float: right;
    `}
  .hintMsg {
    padding: 1em;
    font-family: 'MontserratLight';
  }
`;

export const DatePickerWrap = styled.div`
  width: 100%;
  text-align: center;
  .react-datepicker {
    margin-top: 1em;
  }
  .react-datepicker__month-container {
    width: 36em;
  }
  .react-datepicker__day-name {
    width: 3.2rem;
  }
  .react-datepicker__day {
    width: 4em;
    font-family: 'MontserratLight';
  }
`;

export const ToggleSession = styled.button`
  background-color: #cecdcd;
  padding: 9px 3em;
  border: none;
  border-radius: 5px;
  color: black;
  font-family: 'MontserratLight';
  width: 60%;
  ${({ active }) =>
    active &&
    css`
      background-color: #4886ff;
      color: white;
    `}
  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: 5px;
      width: 40%;
    `}
`;

export const UnitDataInputRow = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  height: 3em;

  ${({ hint }) =>
    hint &&
    css`
      padding-top: 2em;
      height: auto;
      align-items: center;
      justify-content: center;
    `}
`;
export const Text = styled.div`
  width: 85%;
  padding-top: 10px;
  font-size: 13px;
  font-weight: initial;

  ${({ hint }) =>
    hint &&
    css`
      width: auto;
      font-size: 10px;
      padding-top: 3px;
      padding-left: 10px;
    `}
  b {
    font-weight: bold;
  }
`;
export const CheckboxsRow = styled.div`
  display: flex;
  input {
    margin-top: 9px;
  }
`;
export const CheckboxsDate = styled.div`
  display: flex;
  margin-right: 2em;
`;
export const CheckboxsImg = styled.div`
  img {
    width: 30px;
  }
  ${({ hint }) =>
    hint &&
    css`
      margin-left: 2em;
      img {
        width: 20px;
      }
    `}
`;
export const RadioButton = styled.button`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: none;
  margin-top: 8px;
  margin-right: 5px;

  ${(props) =>
    props.checked &&
    css`
      background-color: #ff9e17;
    `}
`;

export const TopNavBar = styled.div`
  display: flex;
  background-color: #9897964d;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  flex: 0 1;
`;

export const TabButton = styled.div`
  padding: 6px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  width: 50%;
  color: #464646;

  ${(props) =>
    props.active &&
    css`
      background-color: #ffffff;
      border-radius: 5px;
      color: #060606;
      border: 1px solid #e0e0df;
    `}
`;
