import React from 'react';
import Modal from 'react-modal';
import { Form, Formik, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { DateInputWrapper, FormLabelStyles } from './ScheduleAddReviewSession.style';
import { ModalHeader } from '../Generals/stylesheets/General.style';
import { formatDate } from '../../utils/func-utils';
import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,
} from '../../reducers/sectionSchedule';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import DatePicker from 'react-datepicker';
import {
  CloseButton,
  BottomButtons,
  SelectButton,
  StyledButton,
  Error,
} from '../ScheduleSection/EditScheduleForm.style';

const validationSchema = Yup.object().shape({
  subject: Yup.string().trim().required('Required'),
  date: Yup.date().required('Required'),
});

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '7px 20px',
    height: 'fit-content',
    minHeight: '485px',
    width: '375px',
    maxWidth: '960px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '5px',
    border: 'none',
    overflow: 'unset'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class ScheduleAddReviewSession extends React.PureComponent {
  static propTypes = {
    sectionReviewAdd: PropTypes.func,
    fetchSectionSubjectList: PropTypes.func,
    subjects: PropTypes.array,
    sectionId: PropTypes.any,
    isAdding: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    sessionDate: PropTypes.any,
    clearSessionDate: PropTypes.func,
    updateScheduleList: PropTypes.func,
  };

  componentDidMount() {
    const { fetchSectionSubjectList, sectionId } = this.props;

    if (sectionId) {
      fetchSectionSubjectList(sectionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionId: prevSectionId } = prevProps;
    const { sectionId, fetchSectionSubjectList } = this.props;
    if (!_isEqual(prevSectionId, sectionId) && sectionId) {
      fetchSectionSubjectList(sectionId);
    }
  }

  onClose = () => {
    const { onClose, clearSessionDate } = this.props;
    onClose();
    clearSessionDate();
  };

  onSubmit = (values) => {
    const { sectionReviewAdd, sectionId, updateScheduleList } = this.props;
    const { subject, date } = values;

    const payload = {
      date: formatDate(date),
      subject: subject,
      activity_type: 0,
      section: sectionId,
      title: `${subject.display_name} Review`,
      type: 'reviews',
    };

    this.props.sectionScheduleUpdateFromCalendar({
      payload,
      type: 'ADD_REVIEW',
    });
    sectionReviewAdd(payload);
    updateScheduleList(payload, 'add');
    this.onClose();
  };

  render() {
    const {
      subjects,
      isAdding,
      isOpen,
      sessionDate,
      onRequestClose,
      onClose,
    } = this.props;
    const filterSubject = subjects.filter(
      (subject) => subject.display_name != 'Introduction' 
                   && subject.display_name != 'Essay',
    );
    const inittialValues = {
      date: '',
      subject: '',
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
        onRequestClose={onRequestClose || onClose}
      >
       <CloseButton
            type="button"
            onClick={onClose || onRequestClose}
          >
            <img
              src={`/images/icon-svg/${'icon-close.svg'}`}
              alt="close"
            ></img>
          </CloseButton>
        <ModalHeader editReview>
          Create Review Session
        </ModalHeader>
        <Formik
          // eslint-disable-next-line no-return-assign
          ref={(ref) => (this.formik = ref)}
          initialValues={inittialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >
          {({ values, setFieldValue }) => (
          <Form style={{minHeight: '365px'}} id="createReview">
          <RadioGroup
            value={values.subject.toString()}
            name="subject"
            row
            onChange={(event) => {
              setFieldValue('subject', event.currentTarget.value)
            }}
            sx={{paddingLeft: '2px'}}
          >
            {filterSubject.map(subject=>(<FormControlLabel  
              sx={FormLabelStyles} 
              name="subject"
              value={subject.id} 
              control={<Radio />} 
              label={subject.display_name} />))}
          </RadioGroup>
          <ErrorMessage
            name="subject"
            render={(msg) => <Error>{msg}</Error>}
          />
          <DateInputWrapper>
            <DatePicker
              selected={values.date}
              onChange={(date) => {
                setFieldValue('date', date)
              }}
              name="date"
              inline
              minDate={new Date()}
            />
          </DateInputWrapper>
          <ErrorMessage
            name="date"
            render={(msg) => <Error>{msg}</Error>}
          />
          </Form>)}
        </Formik>
             <BottomButtons>
              <SelectButton onClick={this.onClose} type="button" isSelected={false}>
                Cancel
              </SelectButton>
              <StyledButton disabled={isAdding} form="createReview" type="submit">Save</StyledButton>
            </BottomButtons>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  sectionScheduleUpdateFromCalendar:
    SectionScheduleActions.sectionScheduleUpdateFromCalendar,
};
export default connect(null, mapDispatchToProps)(ScheduleAddReviewSession);
