import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import LeftSideBarCourseManagement from '../../containers/LeftSideBar/LeftSideBarCourseManagement';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import InviteTeacherTable from '../../containers/CourseManagementTeachers/InviteTeacherTable';
import TeacherInfoItem from './TeacherInfoItem';
import MainContent from '../../containers/MainContent';
import ConfirmModal from '../ConfirmModal';
import {
  UserProfileGroup,
  UserProfileAction,
  UserProfileTitle,
} from '../Profile/Profile.style';
import { Button } from '../Generals/stylesheets/Button.style';
import { PrimaryWrap, DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { TableHeadWrapper, TableContentWrapper } from '../Table/TableComponent.style';
import { TableHeader } from '../Table/TableComponent';
import { ColumnSize } from './ColumnSize';
import firstSort from '../../utils/firstSort';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'User',
  },
  {
    uniqKey: 'isOwner',
    value: 'Owner',
  },
  {
    uniqKey: 'last_login',
    value: 'Last Activity',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class CourseManagementTeachers extends PureComponent {
  static propTypes = {
    fetchCourseTeacherList: PropTypes.func.isRequired,
    courseTeacherAddOwner: PropTypes.func,
    courseTeacherRemoveOwner: PropTypes.func,
    teacherList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    courseId: PropTypes.string,
    courseTeacherRemove: PropTypes.func,
    ownerIds: PropTypes.array,
    courseTeacherInvite: PropTypes.func,
    courseDetail: PropTypes.shape().isRequired,
    isSideBarClose: PropTypes.bool,
    isGettingTeacherList: PropTypes.bool,
    isOwner: PropTypes.bool,
    isOpenInviteTeacher: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isInviteFormOpen: props.isOpenInviteTeacher,
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
      removeTeacherId: null,
      removeTeacherName: null,
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const { isInviteFormOpen } = this.state;
    const { courseId, isOpenInviteTeacher } = this.props;
    const { courseId: prevCourseId, isOpenInviteTeacher: prevIsOpenInviteTeacher } =
      prevProps;

    if (!_isEqual(courseId, prevCourseId)) {
      this.getInitialData();
    }

    if (!prevIsOpenInviteTeacher && isOpenInviteTeacher && !isInviteFormOpen) {
      this.setState({
        // eslint-disable-line
        isInviteFormOpen: true,
      });
    }
  }

  onCloseConfirmModal = () => {
    this.setState({
      removeTeacherId: null,
      removeTeacherName: null,
    });
  };

  onOpenConfirmModal = (teacherId, teacherName) => {
    this.setState({
      removeTeacherId: teacherId,
      removeTeacherName: teacherName,
    });
  };

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  getInitialData = () => {
    const { courseId, fetchCourseTeacherList } = this.props;
    fetchCourseTeacherList(courseId);
  };

  openInviteTeacherForm = () => {
    this.setState({
      isInviteFormOpen: true,
    });
  };

  closeInviteTeacherForm = () => {
    this.setState({
      isInviteFormOpen: false,
    });
  };

  inviteTeacher = (teacherInfo, callback) => {
    const { courseTeacherInvite, courseId } = this.props;

    courseTeacherInvite(courseId, teacherInfo, callback);
  };

  renderEmptyMsg = () => <div>You don&#39;t have any teacher in this course</div>;

  renderTeacherListHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return uniqKey != 'isOwner' ? (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    ) : (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
      />
    );
  };

  renderTeacherListHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderTeacherListHeaderItem)}
      <TableHeader {...ColumnSize[3]} value="Actions" />
    </TableHeadWrapper>
  );

  renderTeacherList = (teacherInfo) => {
    const {
      ownerIds,
      courseId,
      courseTeacherAddOwner,
      courseTeacherRemoveOwner,
      isOwner,
    } = this.props;
    return (
      <TeacherInfoItem
        isUserOwner={isOwner}
        teacherInfo={teacherInfo}
        key={teacherInfo.id}
        ownerIds={ownerIds}
        courseId={courseId}
        courseTeacherAddOwner={courseTeacherAddOwner}
        courseTeacherRemoveOwner={courseTeacherRemoveOwner}
        onOpenConfirmModal={this.onOpenConfirmModal}
      />
    );
  };

  render() {
    const { isInviteFormOpen, orderBy, sortBy, removeTeacherId, removeTeacherName } =
      this.state;
    const {
      teacherList,
      courseDetail,
      isSideBarClose,
      isGettingTeacherList,
      isOwner,
      courseId,
      courseTeacherRemove,
    } = this.props;
    firstSort(teacherList);
    const filterTeacherList = _orderBy(
      teacherList,
      [
        (d) => {
          if (sortBy === 'last_name') return d[sortBy].toUpperCase();
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <PrimaryWrap>
        <LeftSideBarCourseManagement pathName="/management/course" />
        <MainContent>
          <BreadcumbsFilter
            haveSub={isSideBarClose}
            breadcumbSubList={[courseDetail.name]}
            breadcumbList={[courseDetail.name, 'Teachers']}
            mobileTitle="Teachers Management"
            isDisplayFilter={false}
          />

          <ShadowScrollbar autoHide style={scrollStyle}>
            <DefaultContentWrap>
              <UserProfileGroup>
                <UserProfileTitle>
                  <div>
                    <i className="chalktalk-infor-outline" />
                    <p>Teacher List</p>
                  </div>

                  {isOwner && (
                    <UserProfileAction>
                      <Button onClick={this.openInviteTeacherForm}>
                        Invite teachers
                      </Button>
                    </UserProfileAction>
                  )}
                </UserProfileTitle>

                {isGettingTeacherList ? (
                  <TableLoader />
                ) : (
                  <React.Fragment>
                    {!teacherList.length ? (
                      this.renderEmptyMsg()
                    ) : (
                      <React.Fragment>
                        {this.renderTeacherListHeader()}
                        <TableContentWrapper>
                          {filterTeacherList.map(this.renderTeacherList)}
                        </TableContentWrapper>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                {isInviteFormOpen && (
                  <InviteTeacherTable
                    onSubmit={this.inviteTeacher}
                    isOpen={isInviteFormOpen}
                    onClose={this.closeInviteTeacherForm}
                    courseDetail={courseDetail}
                  />
                )}
                <ConfirmModal
                  itemId={removeTeacherId}
                  itemName={removeTeacherName}
                  onClose={this.onCloseConfirmModal}
                  content="Are you sure to delete this teacher?"
                  courseId={courseId}
                  onSubmit={courseTeacherRemove}
                />
              </UserProfileGroup>
            </DefaultContentWrap>
          </ShadowScrollbar>
        </MainContent>
      </PrimaryWrap>
    );
  }
}
