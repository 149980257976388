import axios from 'axios';
import { useQuery } from 'react-query';
import { getFeatureAccess } from '@apis/adminReports';
import { SUBJECT_TO_SYLLABUS_GROUP } from '@components/QuestionGenerationService/constants';
import { SkillRecord } from '@components/Component/Lesson/LessonContainer/Types';
import { Passage } from '@components/QuestionGenerationPassages/types';
import { getSkills } from './skills';

const featureSlug = 'question-generation-service';

interface AccessMetadata {
  message: string;
  feature: {
    id: number;
    name: string;
    slug: string;
  };
  has_access: boolean;
}
interface FeatureFlagAccessResponse {
  level: string;
  user_access: {
    [key: string]: AccessMetadata;
  };
}

const checkUserAccess = (data: FeatureFlagAccessResponse) => {
  if (!data?.user_access) {
    return false;
  }

  const access = Object.values(data.user_access || {})[0] as AccessMetadata;
  if (access?.feature?.slug === featureSlug && access?.has_access) {
    return true;
  }

  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const useQGSAccess = (userId: string, suspense = false) => {
  const result = useQuery<boolean>(
    ['hasQGSAccess', userId],
    async () => {
      const res = (await getFeatureAccess(featureSlug)) as unknown as {
        response: { data: FeatureFlagAccessResponse };
      };
      return checkUserAccess(res?.response?.data as FeatureFlagAccessResponse);
    },
    {
      staleTime: 60 * 60 * 24,
      suspense,
    },
  );
  return result?.data;
};

export const useGetSkillsBySubject = (subjectId: string) => {
  const queryResponse = useQuery(
    ['getSkills', subjectId],
    async () => {
      if (!subjectId) {
        return [];
      }
      const response = await getSkills(subjectId);
      return response || [];
    },
    {
      suspense: true,
      staleTime: 60 * 60 * 24,
      refetchOnMount: 'always',
    },
  );

  return {
    data: queryResponse?.data,
    isLoading: queryResponse.isLoading,
  };
};

export const useGetSkillsByStandard = (subjectId: string, standardIds: string[]) => {
  const queryResponse = useQuery(
    ['getSkills', subjectId, standardIds],
    async () => {
      if (!subjectId || !standardIds.length) {
        return [];
      }
      const response = await getSkills(subjectId, standardIds);
      return response || [];
    },
    {
      suspense: true,
      staleTime: 60 * 60 * 24,
      refetchOnMount: 'always',
    },
  );

  return {
    data: queryResponse?.data,
    isLoading: queryResponse.isLoading,
  };
};

export const postQGSQuestionRequest = (payload: any) =>
  axios.post('/api/qgs/questions', payload).then((response) => ({ response }));

export const submitQuestionsToItemBank = (payload: any) =>
  axios.post('/api/qgs/questions/submit', payload).then((response) => ({ response }));

export const postUserReportIssue = (payload: {
  trace_id: string | null;
  content_type: string;
  content: string;
}) => axios.post('/api/users/v2/report-issue/', payload);

export const getQGSSupportedQuestionTypes = () => axios.get('/api/qgs/question-types');

export const postPassageUpsert = (payload: Passage) =>
  axios.post('/api/qgs/passages/', payload);

export const getUserPassages = (source: string) => {
  const url = `/api/qgs/passages/?source=${source}`;
  return axios.get(url).then((response) => ({ response }));
};

export const generatePassageWithAI = (prompt: string) =>
  axios.post('/api/qgs/passages/generate', { user_prompt: prompt });
