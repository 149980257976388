import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { captureMessage } from '@sentry/react';
import { Button } from '../stylesheets/Button.style';
import { ModalCLose } from '../stylesheets/General.style';
import {
  DropDownIcon,
  DropDownLabel,
  DropDownMenuContainer,
  DropDownOption,
  MessageText,
  ModalContent,
} from './DropDownMenu.style';
import { reviewerList } from '../../../utils/reviewerList';
import { error as errorToast } from '../../../utils/toast';
import GooglePrewarningModal from '../../Modals/GooglePrewarningModal';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: '100%',
    maxWidth: '700px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '18px',
    textAlign: 'center',
    height: '30%',
    minHeight: '200px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const RE_REQUEST_AUTHORIZATION_MESSAGE =
  'You have insufficient access. Please be sure to authorize the application on the next window to continue.';

const EDIT_SLIDES = 'Edit Slides';
const COMPARE_VERSIONS = 'Compare Versions';
const SUBMIT_CHANGES = 'Submit changes to ChalkTalk’s version';

const DropDownMenu = (props) => {
  const {
    lessonName,
    copyPresentationId: propCopyPresentationId,
    setSelectedSlide,
    setCopyPresentationId: propSetCopyPresentationId,
    defaultPresentationId,
    isDropDownVisible,
    setIsDropDownVisible,
    setIsModalOpen,
    userId,
    courseId,
    sectionId,
    lessonNodeId,
    isSubmittingSlide,
    curriculumLessonPostSlide,
    reviewId,
    updateReviewStatus,
    setIsLoading,
    isLoading,
  } = props;

  const [copyPresentationId, setCopyPresentationId] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [showGooglePreWarning, setShowGooglePreWarning] = useState(false);

  useEffect(() => {
    setCopyPresentationId(propCopyPresentationId);
  }, [propCopyPresentationId]);

  // transfer ownership of a drive file within a same domain
  const createWritePermissionForReviewer = async (emailAddress, fileId) => {
    await window.gapi.client.drive.permissions.create({
      fileId,
      resource: {
        role: 'writer',
        type: 'user',
        emailAddress, // TODO: replace with ChalkTalk email address
      },
    });
  };

  // set presentation view permission to public
  const setPresentationVisibility = async (fileId) => {
    await window.gapi.client.drive.permissions.create({
      fileId,
      resource: {
        role: 'reader',
        type: 'anyone',
      },
    });
  };

  const createPresentationCopy = async () => {
    await window.gapi.client.drive.files
      .copy({
        fileId: defaultPresentationId,
        resource: {
          name: `Copy of ${lessonName}`,
        },
      })
      .then((res) => {
        const details = JSON.parse(res.body);

        reviewerList.map((reviewer) =>
          createWritePermissionForReviewer(reviewer, details.id),
        );
        setPresentationVisibility(details.id);

        window.open(`https://docs.google.com/presentation/d/${details.id}`, '_blank');
        propSetCopyPresentationId(details.id);
        setCopyPresentationId(details.id);
        setSelectedSlide(0);
        handleSubmit('InProgress', details.id);
        setIsDropDownVisible();
      })
      .catch(async ({ result }) => {
        const { error } = result;
        const errorReason = error?.errors?.[0].reason;
        const message = error?.message || 'Unable to create copy! Please try again';
        errorToast(message);
        if (errorReason === 'insufficientPermissions' || errorReason === 'authError') {
          setMessageText(RE_REQUEST_AUTHORIZATION_MESSAGE);
          setModalOpen(true);
        }
      });
    setIsLoading(false);
  };

  const handleContinueEdit = async () => {
    setShowGooglePreWarning(false);
    setModalOpen(false);
    setIsLoading(true);
    try {
      await window.gapi.auth2.getAuthInstance().signIn();
      createPresentationCopy();
    } catch ({ message }) {
      errorToast(message);
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    if (!window?.gapi?.auth2?.getAuthInstance()) {
      setIsDropDownVisible();
      captureMessage('Tried to access google api without initialization.');
      return errorToast('Failed to initialize Google API, please try again later');
    }

    if (!copyPresentationId) {
      setIsLoading(true);
      const isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
      setIsDropDownVisible();
      if (!isSignedIn) {
        setShowGooglePreWarning(true);
        setIsLoading(false);
        return null;
      }
      return createPresentationCopy();
    }
    setIsDropDownVisible();
    return window.open(
      `https://docs.google.com/presentation/d/${copyPresentationId}`,
      '_blank',
    );
  };

  const handleSubmit = async (status, g_slide_id) => {
    const slideInfo = {
      g_slide_id,
      teacher: userId,
      course: +courseId,
      section: +sectionId,
    };

    if (status === 'InProgress') {
      curriculumLessonPostSlide(lessonNodeId, slideInfo);
    } else {
      updateReviewStatus(lessonNodeId, reviewId, { status });
    }
    setIsDropDownVisible();
  };

  const handleCompare = () => {
    if (!window.gapi.auth2.getAuthInstance()) {
      setIsDropDownVisible();
      return errorToast('Failed to initialize Google API, please try again later');
    }

    const isSignedIn = window.gapi.auth2.getAuthInstance()?.isSignedIn.get() || false;
    if (!isSignedIn) {
      window.gapi.auth2.getAuthInstance().signIn();
    } else {
      setIsModalOpen(true);
      setIsDropDownVisible();
    }
    return true;
  };

  const renderDropdowOption = (handleOnClick, icon, label, disabled = false) => (
    <DropDownOption
      onClick={() => (disabled || isLoading ? () => {} : handleOnClick())}
      disabled={disabled || isLoading}
    >
      <DropDownIcon src={`/images/${icon}`} />
      <DropDownLabel>{label}</DropDownLabel>
    </DropDownOption>
  );

  return (
    <DropDownMenuContainer isDropDownVisible={isDropDownVisible}>
      <Modal isOpen={isModalOpen} style={modalStyles} ariaHideApp={false}>
        <ModalContent>
          <ModalCLose className="chalktalk-close" onClick={() => setModalOpen(false)} />
          <MessageText>{messageText}</MessageText>
          <Button onClick={handleContinueEdit} submit>
            Continue
          </Button>
        </ModalContent>
      </Modal>
      <GooglePrewarningModal
        handleContinueEdit={handleContinueEdit}
        isModalOpen={showGooglePreWarning}
        setIsModalOpen={setShowGooglePreWarning}
      />
      {renderDropdowOption(handleEdit, 'write.png', EDIT_SLIDES)}
      {renderDropdowOption(
        handleCompare,
        'compare.png',
        COMPARE_VERSIONS,
        !copyPresentationId,
      )}
      {renderDropdowOption(
        () => handleSubmit('Pending', copyPresentationId),
        'submit.png',
        SUBMIT_CHANGES,
        !copyPresentationId || isSubmittingSlide,
      )}
    </DropDownMenuContainer>
  );
};

DropDownMenu.propTypes = {
  lessonName: PropTypes.string,
  copyPresentationId: PropTypes.string,
  setSelectedSlide: PropTypes.func.isRequired,
  setCopyPresentationId: PropTypes.func.isRequired,
  defaultPresentationId: PropTypes.string.isRequired,
  isDropDownVisible: PropTypes.bool.isRequired,
  setIsDropDownVisible: PropTypes.func.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  userId: PropTypes.number,
  courseId: PropTypes.string,
  sectionId: PropTypes.string,
  lessonNodeId: PropTypes.number,
  isSubmittingSlide: PropTypes.bool,
  curriculumLessonPostSlide: PropTypes.func,
  reviewId: PropTypes.number,
  updateReviewStatus: PropTypes.func,
};

export default DropDownMenu;
