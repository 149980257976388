import React from 'react';
import * as PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import Select, { components } from 'react-select';
import * as Yup from 'yup';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _intersectionWith from 'lodash/intersectionWith';
import Radio from '@mui/material/Radio';
import { Skeleton } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ShowIf from '@Atoms/ShowIf';
import { useQueryClient } from 'react-query';
import HorizantalScroller from '../HorizantalScroller/HorizantalScroller';
import { formatDate } from '../../utils/func-utils';
import {
  CloseButton,
  DateParagraph,
  InputAddon,
  BorderInput,
  NonBorderInput,
  StyledCalendarButton,
  ButtonsGroup,
  CheckBox,
  ContentBox,
  DetailText,
  Error,
  FormHeading,
  PopupFormLabel,
  FormStyles,
  ClickAwayListenerStyle,
  HiddenBlock,
  HolidayForm,
  InputGroup,
  Item,
  OptionWithCheckBox,
  PointerImage,
  scrollStyle,
  SectionHours,
  SelectButton,
  SelectStyles,
  StyledSpan,
  TextInput,
  FlexGroup,
  GradeContainer,
  MenuHeaderStyle,
  HolidayName,
  tooltipText,
  BottomButtons,
  StyledButton,
} from './EditScheduleForm.style';
import {
  CLASS_DAY_OPTION as days,
  renderTestDurationOptions,
} from '../../utils/constants';
import {
  generateTestAdministrationData,
  getSubjectPackage,
} from '../../utils/scheduling';
import { renderAlertModal } from '../../utils/userAlert';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import * as SectionAPI from '../../apis/sections';
import * as coursesAPI from '../../apis/courses';
import OverflowTip from '../OverflowTip/OverflowTip';
import PacingGuideSelectorsGroup from './PacingGuideSelectorsGroup';

const SubjectTip = () => {
  const [show, setShow] = React.useState(false);
  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setShow(false)}
        open={show}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <p style={tooltipText}>
            Total score is shown only when the section setup includes all subjects.
          </p>
        }
      >
        <StyledSpan onClick={() => setShow(true)}>i</StyledSpan>
      </Tooltip>
    </ClickAwayListener>
  );
};

class EditScheduleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionName: '',
      selectedGradeLevels: [],
      tooltip: false,
      settingsTooltip: false,
      A_B_Weeks: false,
      selectedSubjects: [],
      selectedFoundational: [],
      startDate: new Date(),
      endDate: moment().add(3, 'month').toDate(),
      selectedDaysA: [],
      selectedDaysB: [],
      classDuration: 90,
      testDuration: { name: 'Full-Length Test (0 minutes)' },
      testAdministration: null,
      holidays: [],
      holiday: {
        date: '',
        name: '',
      },
      pacingGuides: [],
      minimumSittingsCount: 1,
      courseSections: [],
      testAdministrationOptions: [],
      testSessionAction: 1,
      optionChanged: false,
    };
    this.validationSchema = Yup.object().shape({
      selectedSubjects: Yup.array().required('Required field'),
      startDate: Yup.date().typeError('Required field'),
      endDate: Yup.date()
        .typeError('Required field')
        .min(Yup.ref('startDate'), "End date can't be before start date"),
      classDuration: Yup.number()
        .required('Required field')
        .positive()
        .integer()
        .min(35, 'Class duration must be greater than or equal to 35 minutes')
        .max(200, 'Class duration must be less than or equal to 200 minutes')
        .test(
          'mulOf5',
          'Class duration must be multiple of 5',
          (value) => Number(value) % 5 === 0,
        ),
      selectedDaysA: Yup.array().required('Required field'),
    });

    if (this.props.createSection) {
      const createSectionSchemaObject = Yup.object({
        sectionName: Yup.string().required('Required field'),
        selectedGradeLevels: Yup.array().required('Required field'),
      });
      this.validationSchema = this.validationSchema.concat(createSectionSchemaObject);
    }
    if (this.props.curriculumDetail.has_exam) {
      const examSchemaObject = Yup.object({
        testAdministration: Yup.string().typeError('Required field'),
      });
      this.validationSchema = this.validationSchema.concat(examSchemaObject);
    }
  }

  componentDidMount() {
    const {
      courseId,
      getAllSectionsByCourse,
      sectionDetail,
      getAvailablePackages,
      createSection,
      curriculumId,
      curriculumDetail,
    } = this.props;
    const { selectedSubjects, classDuration } = this.state;

    if (courseId) {
      getAllSectionsByCourse(courseId);
      this.getsections(courseId);
    }
    if (curriculumId) {
      getAvailablePackages(curriculumId);
    }
    if (!createSection) {
      this.updateFormValues(sectionDetail);
    } else {
      const holidays = this.getCourseHolidays();
      this.setState({ holidays });
      this.renderTestAdminstrationOptions(selectedSubjects, classDuration);
    }
    if (curriculumDetail.has_exam && !createSection) {
      this.getMininmumSittingsNumber();
    }
    if (sectionDetail.name) {
      this.setState({ sectionName: sectionDetail.name });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedSubjects,
      testAdministrationOptions,
      classDuration,
      testAdministration,
      pacingGuides,
    } = this.state;

    const { curriculumId, curriculumDetail, getAvailablePackages } = this.props;
    const subjectNames = selectedSubjects.map((subject) => subject.name);
    if (prevState.selectedSubjects != selectedSubjects) {
      this.setState({
        testDuration: renderTestDurationOptions(curriculumId, subjectNames),
      });
      this.setState({
        pacingGuides: pacingGuides.filter((guide) =>
          selectedSubjects.some((subject) => subject.id === guide.subject),
        ),
      });
    }
    if (
      !_isEqual(curriculumDetail, prevProps.curriculumDetail) &&
      curriculumDetail.has_exam
    ) {
      const examSchemaObject = Yup.object({
        testAdministration: Yup.object()
          .typeError('Required field')
          .required('Required field'),
      });
      this.validationSchema = this.validationSchema.concat(examSchemaObject);
    }
    if (
      prevState.selectedSubjects != selectedSubjects ||
      prevState.classDuration != classDuration
    ) {
      this.renderTestAdminstrationOptions(selectedSubjects, classDuration);
    }
    if (
      prevState.testAdministrationOptions != testAdministrationOptions &&
      !testAdministrationOptions.some(({ id }) => id === testAdministration?.id)
    ) {
      this.setState({ testAdministration: null });
    }

    if (prevProps.curriculumId != curriculumId && curriculumId) {
      getAvailablePackages(curriculumId);
    }
    if (!selectedSubjects.length && pacingGuides.length > 0) {
      this.setState({
        pacingGuides: [],
      });
    }
  }

  getCourseHolidays = () => {
    const { courseData } = this.props;
    const holidays = [];
    courseData?.holidays.map((item, index) =>
      holidays.push({
        id: index,
        name: Object.keys(item)[0],
        date: item[Object.keys(item)[0]],
      }),
    );
    return holidays;
  };

  handleSelectButton = (value, name) => {
    const selected = this.state[name];
    const selectedNames = selected.map((object) => object.name);
    if (selectedNames.includes(value.name)) {
      this.setState({
        [name]: selected.filter((object) => object.name != value.name),
      });
      if (name === 'selectedSubjects') {
        this.setState({
          selectedFoundational: this.state.selectedFoundational.filter(
            (object) => object.name != value.name,
          ),
        });
      }
    } else {
      this.setState({ [name]: [...selected, value] });
    }
  };

  handleSelectGrade = (value) => {
    const { selectedGradeLevels } = this.state;
    if (selectedGradeLevels.includes(value)) {
      this.setState({
        selectedGradeLevels: selectedGradeLevels.filter((grade) => grade != value),
      });
    } else {
      this.setState({ selectedGradeLevels: [...selectedGradeLevels, value] });
    }
  };

  handleUpdate = (value, name) => {
    this.setState({ [name]: value });
  };

  handleHoliday = (value, name) => {
    this.setState({
      holiday: { ...this.state.holiday, [name]: value },
    });
  };

  renderDaysRows = () => {
    this.setState({ A_B_Weeks: !this.state.A_B_Weeks });
    const growDiv = document.getElementById('grow');
    if (growDiv.clientHeight) {
      growDiv.style.height = 0;
      this.setState({ selectedDaysB: [] });
    } else {
      growDiv.style.height = '45px';
    }
  };

  copySection = (section) => {
    const { sectionDetail, createSection } = this.props;
    let sectionData = {};
    if (section.id) {
      sectionData = section;
    } else if (!createSection) {
      sectionData = sectionDetail;
    } else {
      sectionData = {
        class_days: [],
        class_duration: 90,
        end_date: moment().add(3, 'month').toDate(),
        start_date: new Date(),
        subjects: [],
        holidays: this.getCourseHolidays(),
        grade_levels: [],
      };
    }
    this.updateFormValues(sectionData);
  };

  updateFormValues = (section) => {
    const {
      subjects,
      start_date,
      end_date,
      class_duration,
      class_days,
      holidays,
      grade_levels,
      sittings_number,
      pacing_guides,
    } = section;
    const { subjectsById } = this.props;
    const subjectsObj = Object.values(subjectsById).filter(
      (subject) => subject.name.toLowerCase() != 'introduction',
    );
    const subjectIds = subjectsObj.map((obj) => obj.id);
    const section_subjects = subjects.map(({ display_name: name, ...rest }) => ({
      name,
      ...rest,
    }));
    const selectedDaysA = _intersectionWith(
      days,
      class_days.filter((day) => day.week == 0),
      (a, b) => parseInt(a.day) === b.day,
    );
    const selectedDaysB = _intersectionWith(
      days,
      class_days.filter((day) => day.week == 1),
      (a, b) => parseInt(a.day) === b.day,
    );
    if (selectedDaysB.length > 0 && !this.state.A_B_Weeks) {
      this.renderDaysRows();
    } else if (selectedDaysB.length == 0 && this.state.A_B_Weeks) {
      this.renderDaysRows();
    }
    const foundational_subjects = section_subjects.filter((subject) => {
      if (subject.packages.find((pack) => pack.name.toLowerCase() === 'foundational')) {
        return subject;
      }
    });
    const holidaysList = [];
    holidays.forEach((holiday, index) => {
      holidaysList.push({ id: index, name: holiday.name, date: holiday.date });
    });
    holidays.map((holiday) => {});
    const defaultTestAdministration = this.renderTestAdminstrationOptions(
      section_subjects,
      class_duration,
    ).filter((ele) => ele.id === sittings_number)[0];
    this.setState({
      selectedSubjects: section_subjects.filter(
        (subject) => subject.name != 'Introduction' && subjectIds.includes(subject.id),
      ),
      selectedFoundational: foundational_subjects || [],
      startDate: new Date(start_date),
      endDate: new Date(end_date),
      classDuration: class_duration,
      selectedDaysA: selectedDaysA || [],
      selectedDaysB: selectedDaysB || [],
      holidays: holidaysList,
      selectedGradeLevels: grade_levels.map((grade) => grade.grade_level),
      testAdministration: defaultTestAdministration,
      pacingGuides: pacing_guides,
    });
  };

  addHoliday = () => {
    const { holidays, holiday } = this.state;
    let lastID = 0;
    if (holidays.length > 0) {
      const sortedHolidays = holidays.sort((a, b) => a.id - b.id);
      lastID = sortedHolidays[sortedHolidays.length - 1].id;
    }
    if (holiday.date) {
      holiday.date = moment(holiday.date).format('YYYY-MM-DD');
      this.setState({
        holiday: { id: lastID + 1, date: '', name: '' },
        holidays: [
          ...holidays,
          { ...holiday, name: !holiday.name ? 'Custom' : holiday.name, id: lastID + 1 },
        ],
      });
    }
  };

  resetHolidays = () => {
    this.setState({ holidays: this.getCourseHolidays() });
  };

  removeHoliday = (id) => {
    this.setState({
      holidays: this.state.holidays.filter((holiday) => holiday.id != id),
    });
  };

  handleOpenTooltip = () => {
    this.setState({
      tooltip: !this.state.tooltip,
    });
  };

  handleCloseTooltip = () => {
    if (this.state.tooltip) {
      this.setState({
        tooltip: false,
      });
    }
  };

  getWeeks = () => {
    const { startDate, endDate } = this.state;
    const weeksCount =
      startDate && endDate ? moment(endDate).diff(moment(startDate), 'week') : 0;
    return weeksCount;
  };

  getMininmumSittingsNumber = async () => {
    let placementCount = 0;
    let exitCount = 0;
    let midCount = 0;
    const { sectionId, examSessions } = this.props;
    let scores = [];
    await SectionAPI.getSectionExamsScores(sectionId).then((responseData) => {
      scores = responseData.response && responseData.response.data;
      let j = 0;

      for (j = 0; j < examSessions.length; j++) {
        if ((scores[examSessions[j].id] || []).length > 0) {
          if (examSessions[j].session_type === 0) {
            placementCount++;
          } else if (examSessions[j].session_type === 1) {
            midCount++;
          } else {
            exitCount++;
          }
        }
      }
    });
    this.setState({
      minimumSittingsCount: Math.max(
        Math.max(Math.max(placementCount, exitCount), midCount),
        this.state.minimumSittingsCount,
      ),
    });
  };

  getsections = async (courseId) => {
    let sections = [];
    await coursesAPI.getCourseDetail(courseId).then((response) => {
      sections = response.response.data.sections.filter(({ is_active }) => is_active);
    });
    this.setState({
      courseSections: sections,
    });
  };

  renderTestAdminstrationOptions = (subjects, classDuration) => {
    const { createSection, curriculumDetail, curriculumId } = this.props;
    let subjectsSelected = [];
    subjectsSelected = subjects.map((s) => s.name);
    subjectsSelected = subjectsSelected.filter((s) => s !== 'Introduction') || [];
    let options = generateTestAdministrationData(
      subjectsSelected,
      classDuration,
      curriculumId,
    );

    if (!createSection && curriculumDetail.has_exam) {
      options = options.filter(({ id }) => id >= this.state.minimumSittingsCount);
    }
    this.setState({ testAdministrationOptions: options });
    return options;
  };

  getGrades = () => {
    const gradeOptions = [];
    for (let i = 3; i <= 12; i++) {
      gradeOptions.push(i);
    }
    return gradeOptions;
  };

  openSettingsTooltip = () => {
    this.setState({
      settingsTooltip: !this.state.settingsTooltip,
    });
  };

  closeSettingsTooltip = () => {
    if (this.state.settingsTooltip) {
      this.setState({
        settingsTooltip: false,
      });
    }
  };

  updateSection = () => {
    const {
      classDuration,
      endDate,
      sectionName,
      selectedDaysA,
      selectedDaysB,
      selectedFoundational,
      selectedGradeLevels,
      selectedSubjects,
      startDate,
      testAdministration,
      testDuration,
      pacingGuides,
    } = this.state;
    const {
      courseId,
      createSection,
      curriculumDetail,
      handleNewSectionCreated,
      sectionDetail,
      sectionsCreateNewSection,
      sectionsUpdate,
      packagesPerSubject,
      queryClient,
    } = this.props;
    const sectionId = _get(sectionDetail, 'id', '');
    const foundational_subjects = selectedFoundational.map((subject) => subject.id);
    const subjects = selectedSubjects.map((subject) => {
      const packages = [];
      const foundationalPackage =
        getSubjectPackage(subject.id, 'foundational', packagesPerSubject) || {};
      const corePackage = getSubjectPackage(subject.id, 'core', packagesPerSubject) || {};
      if (foundational_subjects.includes(subject.id) && foundationalPackage.id) {
        packages.push({ id: foundationalPackage.id });
      }
      packages.push({ id: corePackage.id });
      return { id: subject.id, units: [], packages };
    });
    const weekADays = (selectedDaysA || []).map((classDayItem) => ({
      day: classDayItem.day,
      week: 0,
    }));
    const weekBDays = (selectedDaysB || []).map((classDayItem) => ({
      day: classDayItem.day,
      week: 1,
    }));
    const exams = [];

    exams.push({
      session_type: 0,
      start_date: moment('').format('YYYY-MM-DD'),
      end_date: moment('').format('YYYY-MM-DD'),
    });

    exams.push({
      session_type: 2,
      start_date: moment('').format('YYYY-MM-DD'),
      end_date: moment('').format('YYYY-MM-DD'),
    });

    let payload = {
      name: sectionName || sectionDetail.name,
      class_days: weekADays.concat(weekBDays),
      holidays: this.filterHoliday(),
      subjects,
      class_duration: classDuration,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      grade_levels: selectedGradeLevels.map((grade) => ({
        grade_level: grade,
      })),
      sittings: [],
      pacing_guides: pacingGuides,
    };
    if (
      selectedSubjects.length === 1 &&
      (selectedSubjects[0].name === 'Essay') | (selectedSubjects[0].name === 'Writing')
    ) {
      renderAlertModal(`You can’t have only ${selectedSubjects[0].name} in your section`);
    } else {
      if (curriculumDetail.has_exam) {
        payload = {
          ...payload,
          test_duration: testDuration.id,
          sittings_number: testAdministration.id,
          test_administration: testAdministration,
          sittings: testAdministration.sittings.map((sitting, index) => ({
            name: sitting.data,
            day: index.toString(),
          })),
          exams,
        };
      }
      if (!createSection) {
        sectionsUpdate(sectionId, payload);
      } else {
        const creationPayload = {
          ...payload,
          course: courseId,
        };
        sectionsCreateNewSection(creationPayload, handleNewSectionCreated);
      }
    }
    queryClient.invalidateQueries(['getExamDetails']);
  };

  getDates = (startTime, stopTime, daysOff, weekADays, weekBDays) => {
    const dateArray = [];
    const excludedDays = new Set(
      daysOff.map(({ date }) => moment(date).format('YYYY-MM-DD')),
    );
    const weekDays = Array.from(new Set([...weekADays, ...weekBDays])).map(({ day }) =>
      parseInt(day),
    );
    let currentDate = moment(startTime);
    const stopDate = moment(stopTime);
    while (currentDate <= stopDate) {
      if (weekDays.includes(currentDate.isoWeekday() - 1)) {
        dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
      }
      currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray.filter((date) => !excludedDays.has(date));
  };

  handleChangeTestSessionOptions = (value, optSelected) => {
    this.setState({ testSessionAction: value, optionChanged: true });
    this.handleSelectOption(optSelected);
  };

  handleSelectOption = (value) => {
    this.setState({ testAdministration: value, optionChanged: true });
  };

  filterHoliday = () => {
    const holidayFiltered = [];
    const { startDate, endDate, holidays } = this.state;
    holidays.forEach((holiday) => {
      let shouldAddHoliday = true;
      const holidayDate = moment(holiday.date).format('YYYY-MM-DD');
      const start = moment(startDate).format('YYYY-MM-DD');
      const end = moment(endDate).format('YYYY-MM-DD');

      if (startDate) {
        if (holidayDate < start) {
          shouldAddHoliday = false;
        }
      }
      if (endDate) {
        if (holidayDate > end) {
          shouldAddHoliday = false;
        }
      }

      if (shouldAddHoliday) {
        holidayFiltered.push(holiday);
      }
    });
    return holidayFiltered;
  };

  renderHoliday = (holiday) => (
    <Item>
      <DateParagraph>{moment(holiday.date).format('YYYY-MM-DD')}</DateParagraph>
      <HolidayName>
        <OverflowTip>{holiday.name}</OverflowTip>
      </HolidayName>
      <PointerImage
        src="/images/icon-svg/minus-button.svg"
        onClick={() => this.removeHoliday(holiday.id)}
      />
    </Item>
  );

  renderFormFields = (props) => {
    const {
      subjectsById,
      allSections,
      onCloseModal,
      curriculumDetail,
      createSection,
      sectionDetail,
      packagesPerSubject,
    } = this.props;
    const {
      selectedSubjects,
      selectedGradeLevels,
      selectedFoundational,
      selectedDaysA,
      selectedDaysB,
      startDate,
      endDate,
      classDuration,
      testAdministration,
      holidays,
      holiday,
      tooltip,
      testDuration,
      courseSections,
      testAdministrationOptions,
      testSessionAction,
      settingsTooltip,
    } = this.state;
    const subjects = Object.values(subjectsById).filter(
      (subject) => subject.name.toLowerCase() != 'introduction',
    );
    const sections =
      allSections.length > 0
        ? createSection
          ? [{ name: 'None' }, ...allSections]
          : [{ name: 'None' }, ...allSections].filter(({ id }) => id !== sectionDetail.id)
        : [{ name: 'Loading...' }];
    const subjectNames = selectedSubjects.map((subject) => subject.name);
    const FoundationalNames = selectedFoundational.map((subject) => subject.name);
    const totalDays =
      selectedDaysB.length > 0
        ? (selectedDaysA.length + selectedDaysB.length) / 2
        : selectedDaysA.length || 0;

    const numberOfSchdeduledays = this.getDates(
      startDate,
      endDate,
      holidays,
      selectedDaysA,
      selectedDaysB,
    ).length;
    const totalWeeks = this.getWeeks();
    const totalHours = Math.round((classDuration * numberOfSchdeduledays) / 60) || 0;
    const numOfSessions = testAdministration ? testAdministration.id : '';
    const gradeLevels = this.getGrades();
    const sectionNamePlacement = createSection ? 'Name' : sectionDetail.name;

    const sortedHolidays = this.filterHoliday().sort(
      (a, b) => new Date(a.date) - new Date(b.date),
    );
    const settingsDetail =
      numOfSessions == 1
        ? 'One long continuous session mimics real test conditions'
        : 'Some testing sessions might be longer than the class schedule';
    const shouldShowCopyButton = createSection
      ? courseSections.length > 0
      : courseSections.length > 1;

    const shouldShowFoundational = subjects
      .map((subject) => getSubjectPackage(subject.id, 'foundational', packagesPerSubject))
      .some((value) => !!value)
      ? curriculumDetail.has_exam && selectedSubjects.length > 0
      : false;

    const MenuList = (props) => {
      const { hasValue } = props;
      const selectedSection = props.getValue();
      const { name } = hasValue ? selectedSection[0] : '';
      let headerText = 'Copy schedule from:';
      if (hasValue && name !== 'None') {
        headerText = `Copying from ${name}`;
      }
      return (
        <components.MenuList {...props}>
          <MenuHeaderStyle>{headerText}</MenuHeaderStyle>
          {props.children}
        </components.MenuList>
      );
    };

    const testLength = testAdministrationOptions.length;
    const firstTest = testAdministrationOptions[0];
    const lastTest = testAdministrationOptions[testLength - 1];
    if (testLength == 1) {
      this.state.testSessionAction = 1;
      this.state.testAdministration = lastTest;
    }
    return (
      <Form style={FormStyles} id="scheduleCreate">
        <FlexGroup>
          <Item>
            <FormHeading>Section Setup</FormHeading>
          </Item>
          <CloseButton type="button" onClick={onCloseModal}>
            <img src={`/images/icon-svg/${'icon-close.svg'}`} alt="close" />
          </CloseButton>
        </FlexGroup>
        <>
          <InputGroup>
            <PopupFormLabel>Section Name</PopupFormLabel>
            <FlexGroup>
              <BorderInput
                type="text"
                name="sectionName"
                placeholder={sectionNamePlacement}
                onChange={(e) => this.handleUpdate(e.target.value, 'sectionName')}
              />
              {shouldShowCopyButton && (
                <Select
                  components={{
                    MenuList,
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={SelectStyles}
                  options={sections}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={this.copySection}
                  placeholder="Copy Another Section"
                  classNamePrefix="section-select"
                />
              )}
            </FlexGroup>
            <ErrorMessage name="sectionName" render={(msg) => <Error>{msg}</Error>} />
          </InputGroup>
          <InputGroup>
            <PopupFormLabel>Grade Levels</PopupFormLabel>
            <HorizantalScroller show={1}>
              {gradeLevels.map((grade) => (
                <GradeContainer>
                  <SelectButton
                    isGradeLevels
                    type="button"
                    onClick={() => this.handleSelectGrade(grade)}
                    isSelected={selectedGradeLevels.includes(grade)}
                  >
                    {grade}
                  </SelectButton>
                </GradeContainer>
              ))}
            </HorizantalScroller>
            <ErrorMessage
              name="selectedGradeLevels"
              render={(msg) => <Error>{msg}</Error>}
            />
          </InputGroup>
        </>
        {/* -------------------- Subjects --------------------------------- */}
        <InputGroup>
          <OptionWithCheckBox num={shouldShowFoundational ? 2 : 1}>
            <PopupFormLabel paddingLeft>
              Subjects
              <ShowIf If={curriculumDetail.has_exam}>
                <SubjectTip />
              </ShowIf>
            </PopupFormLabel>
            {shouldShowFoundational && (
              <PopupFormLabel>
                Foundational Lessons
                <ClickAwayListener onClickAway={this.handleCloseTooltip}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={this.handleCloseTooltip}
                    open={tooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <p style={tooltipText}>
                        {`These lessons are NOT specific to the ${curriculumDetail.name} curriculum.`}{' '}
                        <p style={{ marginTop: '6px' }}>
                          They include topics like orders of operations for Math, or the
                          difference between a verb and a noun for ELA.
                        </p>
                        <p style={{ marginTop: '6px' }}>
                          We recommend that you skip these lessons unless you are working
                          with intervention or remedial students who would benefit from
                          this additional context.
                        </p>
                      </p>
                    }
                  >
                    <StyledSpan onClick={this.handleOpenTooltip}>i</StyledSpan>
                  </Tooltip>
                </ClickAwayListener>
              </PopupFormLabel>
            )}
          </OptionWithCheckBox>
          <ButtonsGroup>
            <ShowIf If={!!subjects.length}>
              {subjects.map((subject) => (
                <OptionWithCheckBox key={subject.id} num={shouldShowFoundational ? 2 : 1}>
                  <SelectButton
                    type="button"
                    onClick={() => this.handleSelectButton(subject, 'selectedSubjects')}
                    isSelected={subjectNames.includes(subject.name)}
                  >
                    {subject.name}
                  </SelectButton>
                  {shouldShowFoundational &&
                    getSubjectPackage(subject.id, 'foundational', packagesPerSubject) && (
                      <CheckBox
                        checked={FoundationalNames.includes(subject.name)}
                        onClick={() =>
                          this.handleSelectButton(subject, 'selectedFoundational')
                        }
                        type="checkbox"
                        disabled={
                          !subjectNames.includes(subject.name) ||
                          !getSubjectPackage(
                            subject.id,
                            'foundational',
                            packagesPerSubject,
                          )
                        }
                      />
                    )}
                </OptionWithCheckBox>
              ))}
            </ShowIf>
            <ShowIf If={!subjects.length}>
              <Skeleton variant="rounded" width="3rem" height="1rem" />
              <Skeleton variant="rounded" width="3rem" height="1rem" />
              <Skeleton variant="rounded" width="3rem" height="1rem" />
            </ShowIf>
          </ButtonsGroup>
          <ErrorMessage name="selectedSubjects" render={(msg) => <Error>{msg}</Error>} />
        </InputGroup>

        {/* -------------------- Start Date --------------------------------- */}
        <InputGroup>
          <PopupFormLabel>Start Date</PopupFormLabel>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            name="startDate"
            className="calendarInput"
            selected={startDate}
            value={startDate}
            onChange={(date) => this.handleUpdate(date, 'startDate')}
          />
          <ErrorMessage name="startDate" render={(msg) => <Error>{msg}</Error>} />
        </InputGroup>
        {/* -------------------- End Date --------------------------------- */}
        <InputGroup>
          <PopupFormLabel>End Date</PopupFormLabel>
          <DatePicker
            dateFormat="yyyy-MM-dd"
            name="endDate"
            className="calendarInput"
            selected={endDate}
            value={endDate}
            onChange={(date) => this.handleUpdate(date, 'endDate')}
            minDate={new Date(startDate)}
          />
          <ErrorMessage name="endDate" render={(msg) => <Error>{msg}</Error>} />
        </InputGroup>
        {/* -------------------- Class Duration --------------------------------- */}
        <InputGroup>
          <PopupFormLabel>Class Duration</PopupFormLabel>
          <FormControl variant="standard">
            <InputAddon offset={classDuration.toString().length}>
              <BorderInput
                type="number"
                step="5"
                max={200}
                min={35}
                value={classDuration}
                name="classDuration"
                placeholder="0 minutes"
                onBlur={props.handleBlur}
                onChange={(e) => {
                  props.handleChange(e);
                  this.handleUpdate(e.target.value, 'classDuration');
                }}
              />
            </InputAddon>
          </FormControl>
          {props.errors.classDuration && <Error>{props.errors.classDuration}</Error>}
        </InputGroup>
        {/* -------------------- Class Days --------------------------------- */}
        <InputGroup>
          {!this.state.A_B_Weeks ? (
            <PopupFormLabel>Class Days</PopupFormLabel>
          ) : (
            <PopupFormLabel>Week A</PopupFormLabel>
          )}
          <ButtonsGroup>
            {days.map((day) => (
              <SelectButton
                isClassDays
                type="button"
                onClick={() => this.handleSelectButton(day, 'selectedDaysA')}
                isSelected={selectedDaysA.includes(day)}
              >
                {day.name}
              </SelectButton>
            ))}
            {!this.state.A_B_Weeks && (
              <Tooltip
                disableFocusListener
                disableTouchListener
                title="Click to add an alternate week for A/B scheduling"
              >
                <PointerImage
                  onClick={this.renderDaysRows}
                  src="/images/icon-svg/add-button.svg"
                />
              </Tooltip>
            )}
          </ButtonsGroup>
          <ErrorMessage name="selectedDaysA" render={(msg) => <Error>{msg}</Error>} />
        </InputGroup>
        <HiddenBlock id="grow">
          <InputGroup>
            <PopupFormLabel>Week B</PopupFormLabel>
            <ButtonsGroup>
              {days.map((day) => (
                <SelectButton
                  isClassDays
                  type="button"
                  onClick={() => this.handleSelectButton(day, 'selectedDaysB')}
                  isSelected={selectedDaysB.includes(day)}
                >
                  {day.name}
                </SelectButton>
              ))}
              <PointerImage
                onClick={this.renderDaysRows}
                src="/images/icon-svg/minus-button.svg"
              />
            </ButtonsGroup>
          </InputGroup>
        </HiddenBlock>
        {/* -------------------- Days Off --------------------------------- */}
        <InputGroup>
          <FormLabel>Days Off</FormLabel>
          <HolidayForm>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={holiday.date}
              value={holiday.date}
              name="holiday.date"
              placeholderText="yyyy-mm-dd"
              onChange={(date) => this.handleHoliday(date, 'date')}
            />
            <TextInput
              value={holiday.name}
              type="text"
              name="holiday.name"
              placeholder="Name (optional)"
              onChange={(e) => this.handleHoliday(e.target.value, 'name')}
            />
            <StyledCalendarButton type="button" onClick={this.addHoliday}>
              Add
            </StyledCalendarButton>
          </HolidayForm>
        </InputGroup>
        {sortedHolidays.length > 0 && (
          <ContentBox>{sortedHolidays.map(this.renderHoliday)}</ContentBox>
        )}
        {/* --------------------  Reset Holidays Button --------------------------------- */}
        <BottomButtons>
          <StyledButton type="button" onClick={this.resetHolidays}>
            Reset Holidays
          </StyledButton>
        </BottomButtons>

        {/* --------------------  Section Hours Formula --------------------------------- */}
        <SectionHours>
          <div>
            <span>{classDuration || 0}</span>
            minutes
          </div>
          <strong>x</strong>
          <div>
            <span>{totalDays}</span>
            days / week
          </div>
          <strong>x</strong>
          <div>
            <span>{totalWeeks}</span>
            weeks
          </div>
          <strong>=</strong>
          <div>
            <span>{totalHours}</span>
            hours
          </div>
        </SectionHours>
        {/* -------------------- Pacing Guide --------------------------------- */}
        <ShowIf If={selectedSubjects.length > 0}>
          <PacingGuideSelectorsGroup
            totalHours={totalHours}
            defaultPacingGuides={sectionDetail?.pacing_guides}
            selectedSubjects={selectedSubjects}
            onSelect={(pacingGuideId, selectedSubjectId) => {
              if (!pacingGuideId || !selectedSubjectId) {
                this.setState((prevState) => ({
                  pacingGuides: prevState.pacingGuides.filter(
                    (ele) => ele.subject !== selectedSubjectId,
                  ),
                }));
                return;
              }

              this.setState((prevState) => ({
                pacingGuides: [
                  ...prevState.pacingGuides.filter(
                    (ele) =>
                      ele.subject !== selectedSubjectId &&
                      selectedSubjects
                        .map((subject) => subject.id)
                        .includes(ele.subject),
                  ),
                  { id: pacingGuideId, subject: selectedSubjectId },
                ],
              }));
            }}
          />
        </ShowIf>
        {/* --------------------  Test Durations and Options --------------------------------- */}
        {!(subjectNames.length === 1 && subjectNames[0] === 'Writing') &&
          curriculumDetail.has_exam && (
            <>
              <InputGroup>
                <PopupFormLabel>Test Duration</PopupFormLabel>
                <NonBorderInput
                  data-testid="test-duration-input"
                  name="testDuration"
                  disabled
                  value={testDuration.name}
                />
              </InputGroup>
              <InputGroup>
                <PopupFormLabel>
                  Number of Test Days
                  <ClickAwayListener
                    style={ClickAwayListenerStyle}
                    onClickAway={this.closeSettingsTooltip}
                  >
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={this.closeSettingsTooltip}
                      open={settingsTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Placement and Exit Tests may be distributed over multiple days"
                    >
                      <StyledSpan onClick={this.openSettingsTooltip}>i</StyledSpan>
                    </Tooltip>
                  </ClickAwayListener>
                </PopupFormLabel>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="classSchedule"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      checked={testLength == 1 || testSessionAction == 1}
                      onChange={() => this.handleChangeTestSessionOptions(1, lastTest)}
                      value="classSchedule"
                      control={<Radio />}
                      label="Break up test to fit within class schedule"
                    />
                    {lastTest && testSessionAction == 1 && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="radio-buttons-group-subOptions"
                          defaultValue={lastTest.id}
                          name="radio-buttons-group-11"
                          className="subOptions"
                          row
                        >
                          <FormControlLabel
                            checked
                            onChange={() => this.handleSelectOption(lastTest)}
                            value={lastTest.id}
                            control={<Radio />}
                            label={lastTest.id}
                          />
                        </RadioGroup>
                      </FormControl>
                    )}

                    {testLength > 1 && (
                      <FormControlLabel
                        onChange={() => this.handleChangeTestSessionOptions(2, firstTest)}
                        value="longerTesting"
                        control={<Radio />}
                        label="Schedule longer testing days (fewer sessions)"
                      />
                    )}
                    {testLength > 1 && testSessionAction == 2 && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="radio-buttons-group-subOptions"
                          defaultValue={firstTest.id}
                          name="radio-buttons-group-11"
                          className="subOptions"
                          row
                        >
                          {testAdministrationOptions.map(
                            (session, index) =>
                              index < testAdministrationOptions.length - 1 && (
                                <FormControlLabel
                                  onChange={() => this.handleSelectOption(session)}
                                  value={session.id}
                                  control={<Radio />}
                                  label={session.id}
                                />
                              ),
                          )}
                          <br />
                          <DetailText>{settingsDetail}</DetailText>
                        </RadioGroup>
                      </FormControl>
                    )}
                  </RadioGroup>
                </FormControl>
                <ErrorMessage
                  name="testAdministration"
                  render={(msg) => <Error>{msg}</Error>}
                />
              </InputGroup>
              {this.state.testAdministration?.sittings &&
                this.state.testAdministration.sittings.map((ele, index) => (
                  <InputGroup sittings key={`sitting-test-label-wrapper-${index}`}>
                    <PopupFormLabel data-testid={`sitting-test-label-${index}`}>
                      {ele.name}
                    </PopupFormLabel>
                    <p data-testid={`sitting-test-data-${index}`}>{ele && ele.data}.</p>
                  </InputGroup>
                ))}
            </>
          )}
      </Form>
    );
  };

  render() {
    return (
      <ShadowScrollbar autoHide style={scrollStyle}>
        <Formik
          enableReinitialize
          initialValues={this.state}
          render={this.renderFormFields}
          onSubmit={this.updateSection}
          validationSchema={this.validationSchema}
        />
      </ShadowScrollbar>
    );
  }
}
EditScheduleForm.propTypes = {
  onCloseModal: PropTypes.func,
  handleNewSectionCreated: PropTypes.func,
  createSection: PropTypes.bool,
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const queryClient = useQueryClient();

    return <WrappedComponent {...props} queryClient={queryClient} />;
  };
  return ComponentWithData;
};
export default withDataWrapper(EditScheduleForm);
