import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import {
  selectors as UserSelectors,
} from '../../reducers/user';
import MyLibrary from '../../components/MyLibrary';
import {
  selectors as CurriculumLessonSelectors,
  actions as CurriculumLessonsActions,
} from '../../reducers/curriculumLessons';
import { actions as SubjectActions } from "../../reducers/sectionSubjects";

const mapStateToProps = (state) => {
  return {
    user: UserSelectors.getCurrentUser(state),
    reviewRequests: CurriculumLessonSelectors.getCurriculumLessonReviewRequests(state),
    isSubmittingSlide: CurriculumLessonSelectors.isSubmittingSlide(state),
    isGettingReviews: CurriculumLessonSelectors.isGettingReviews(state),
  };
};

const mapDispatchToProps = {
  curriculumLessonGetReviews: CurriculumLessonsActions.curriculumLessonGetReviews,
  fetchSectionSubjectList: SubjectActions.fetchSectionSubjectList,
  updateReviewStatus: CurriculumLessonsActions.curriculumLessonUpdateReviewStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLibrary);
