import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import SubNavBarTeacherTraining from '../../components/SubNavBarTeacherTraining';
import { actions as CurriculumActions, selectors as CurriculumSelectors } from '../../reducers/curriculums';
import { selectors as CourseSelectors } from '../../reducers/courses';

const mapStateToProps = (state, { match }) => {
  const courseIdSelected = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseIdSelected);
  const curriculumId = _get(courseData, 'curriculum', '');

  return ({
    courseId: courseIdSelected,
    curriculumId,
    trainings: CurriculumSelectors.getTeacherTrainingList(state, curriculumId),
    isGettingTrainning: CurriculumSelectors.isGettingTrainning(state),
  });
};

const mapDispatchToProps = {
  selectTraining: CurriculumActions.selectTeacherTraining,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubNavBarTeacherTraining));
