/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _get from 'lodash/get';
import Modal from 'react-modal';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import UsingCodeTab from './UsingCodeTab';
import SendInvitesTab from './SendInvitesTab';
import {
  InviteStudentsItemWrap,
  InviteStudentsItem,
  InviteStudentsTitle,
  TabsInviteStudents,
  LinkSkipThisStepWrapper,
  LinkSkipThisStep,
} from './InviteStudentsModal.style';
import { ModalHeader, ModalCloseCourse } from '../../Generals/stylesheets/General.style';
import MediaQuery from 'react-responsive';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    borderLeft: 'solid 22px #4A85E8',
    width: '541px',
    maxHeight: 'calc(100vh - 30px)'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const mobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    borderLeft: 'solid 22px #4A85E8',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    borderRadius: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class InviteStudentsModal extends PureComponent {
  constructor() {
    super();
    this.state = {
      tabIndex: 0,
    };
  }

  static propTypes = {
    isShowModal: PropTypes.bool,
    onCloseModal: PropTypes.func,
    callbackFunction: PropTypes.func,
    sectionId: PropTypes.number,
    submitInviteStudent: PropTypes.func.isRequired,
    sectionDetail: PropTypes.object,
  }

  selectTab = (tabIndex) => {
    this.setState({
      tabIndex,
    });
  }

  onChangeCustomModalStyle = (matches) => {
    const { tabIndex } = this.state;

    switch (tabIndex) {
      case 0:
        return { ...modalStyles, ...(matches && mobileModalStyles) };
      case 1:
        return { ...modalStyles, ...(matches && mobileModalStyles) };
      default:
        return modalStyles;
    }
  }

  render() {
    const { tabIndex } = this.state;
    const {
      isShowModal,
      onCloseModal,
      sectionId,
      submitInviteStudent,
      callbackFunction,
      sectionDetail,
    } = this.props;
    const studentCode = _get(sectionDetail, 'code[0].code');
    return (
      <MediaQuery maxWidth={768}>
        {mathes => (
          <Modal
            isOpen={isShowModal}
            onRequestClose={onCloseModal}
            style={this.onChangeCustomModalStyle(mathes)}
            ariaHideApp={false}
          >
            <ModalHeader>
              <ModalCloseCourse className="chalktalk-close" onClick={onCloseModal} />
            </ModalHeader>
            <InviteStudentsItemWrap>
              <InviteStudentsItem>
                <InviteStudentsTitle>
                  <h1>Invite Students</h1>
                  <TabsInviteStudents>
                    <Tabs
                      selectedIndex={tabIndex}
                      onSelect={(tabIndexNumber) => this.selectTab(tabIndexNumber)}
                    >
                      <TabList>
                        <Tab>Using Code</Tab>
                        <Tab>Send Invites</Tab>
                      </TabList>
                      <TabPanel>
                        <UsingCodeTab code={studentCode} />
                      </TabPanel>
                      <TabPanel>
                        <SendInvitesTab
                          sectionId={sectionId}
                          submitInviteStudent={submitInviteStudent}
                          callbackFunction={callbackFunction}
                        />
                      </TabPanel>
                    </Tabs>
                  </TabsInviteStudents>
                  <LinkSkipThisStepWrapper>
                    <LinkSkipThisStep onClick={onCloseModal}>
                      Skip and I will do this later
                </LinkSkipThisStep>
                  </LinkSkipThisStepWrapper>
                </InviteStudentsTitle>
              </InviteStudentsItem>
            </InviteStudentsItemWrap>
          </Modal>
        )}
      </MediaQuery>
    );
  }
}
