/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import Select from 'react-select';
import { CourseSelectWrap } from './CourseSelect.style';
import { retrieveCachedCourseAndSection } from '../../utils/cacheCourseAndSection';

const customSelectStyle = {
  dropdownIndicator: (base) => ({
    ...base,
    color: '#FF9E16!important',
  }),
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
};

class CourseSelect extends PureComponent {
  componentDidMount() {
    const { courseId, courseList, selectedCourse } = this.props;
    if (selectedCourse || courseList.length !== 0) {
      this.selectFirstCourse();
    }

    if (selectedCourse || courseId !== null) {
      this.getAllSectionByCourse(courseId || selectedCourse);
    }
  }

  componentDidUpdate(prevProps) {
    const { courseId, courseList } = this.props;
    const { courseId: prevCourseId, courseList: prevCourseList } = prevProps;

    if (!_isEqual(courseId, prevCourseId) && _isNil(courseId)) {
      this.getAllSectionByCourse(courseId);
    }

    if (
      !_isEqual(prevCourseList, courseList) &&
      _isNil(courseId) &&
      courseList.length !== 0
    ) {
      this.selectFirstCourse();
    }
  }

  getAllSectionByCourse = (courseId) => {
    const { getAllSectionByCourse, sectionStudentFilterAddAllOrCancel } = this.props;

    getAllSectionByCourse(courseId);
    sectionStudentFilterAddAllOrCancel([]);
  };

  selectCourseId = (course) => {
    const { history, pathName, selectCourse } = this.props;
    const { has_exam } = course;
    const courseId = course.id;
    selectCourse(courseId);

    const filteredCourses = this.props.coursesById[courseId].sections.filter(
      (item) => new Date(item.end_date).getTime() >= new Date().getTime(),
    );

    if (pathName.startsWith('/coauthor')) {
      history.push(`${pathName}/${courseId}`);
    } else if (!/^\/dashboard/g.test(pathName) && (filteredCourses.length === 0 || !has_exam)) {
      history.replace(`/dashboard/course/${courseId}`);
    } else {
      history.push(`${pathName}/${courseId}`);
    }
  };

  selectFirstCourse() {
    const {
      courseList,
      history,
      pathName,
      selectedCourse,
      selectCourse,
      courseId: selectedCourseId,
    } = this.props;

    const { cachedCourseId, cachedSectionId } = retrieveCachedCourseAndSection();
    if (!courseList.length) {
      return;
    }
    if (courseList.find((course) => course.id == selectedCourseId)) {
      selectCourse(selectedCourseId);
      return;
    }
    if (!courseList.find((course) => course.id == cachedCourseId) && !selectedCourseId) {
      history.push(`${pathName}/${courseList?.[0]?.id}`);
      selectCourse(courseList?.[0]?.id);
      return;
    }
    if (
      (cachedCourseId || (cachedCourseId && cachedSectionId)) &&
      pathName === '/dashboard/course'
    ) {
      history.push(
        `${pathName}/${cachedCourseId}${
          cachedSectionId && `/section/${cachedSectionId}`
        }`,
      );
      selectCourse(cachedCourseId);
      return;
    }

    if (selectedCourseId && selectedCourseId !== null) {
      selectCourse(selectedCourseId);
      return;
    }

    const firstItem =
      courseList.find((course) => course.section && course.section.length) ||
      courseList[0];
    const courseId = selectedCourse || _get(firstItem, 'id');

    history.push(`${pathName}/${courseId}`);
    selectCourse(courseId);
  }

  render() {
    const {
      isLoadingCourses = false,
      courseList = [],
      courseId,
      selectedCourse,
    } = this.props;
    const currentCourse = courseList.find(
      (course) => course.id == courseId || course.id == selectedCourse,
    );

    return (
      <CourseSelectWrap>
        {isLoadingCourses ? (
          <span>Loading</span>
        ) : (
          <Select
            styles={customSelectStyle}
            value={currentCourse}
            onChange={this.selectCourseId}
            className="course-select-container"
            classNamePrefix="course-select"
            options={courseList}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.name}
            isOptionSelected={(option) => courseId == option.id}
          />
        )}
      </CourseSelectWrap>
    );
  }
}

CourseSelect.propTypes = {
  history: PropTypes.any,
  isLoadingCourses: PropTypes.bool,
  courseId: PropTypes.any,
  courseList: PropTypes.array,
  getAllSectionByCourse: PropTypes.func,
  pathName: PropTypes.string,
  selectedCourse: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sectionStudentFilterAddAllOrCancel: PropTypes.func,
  selectCourse: PropTypes.func.isRequired,
};

export default CourseSelect;
