import React from 'react';
import { useNavigateToPreview } from './hooks';
import { Button } from '../Generals/stylesheets/Button.style';
import { useIsTeacher } from '../../reducers/user/hooks';
import useLearnosityQuestionCT from '../../utils/hooks/useLearnosityQuestionCT';

const PreviewButton = ({ lessonDetail }) => {
  const goToPreview = useNavigateToPreview();
  const isTeacher = useIsTeacher();
  const { hasStandardsPractice, hasSpanishQuestions } = useLearnosityQuestionCT({
    lessonDetail,
  });
  if (!isTeacher || !hasStandardsPractice) {
    return null;
  }
  return (
    <Button green onClick={goToPreview}>
      {hasSpanishQuestions
        ? 'Preview English Practice (primary questions)'
        : 'Preview Practice'}
    </Button>
  );
};

export default PreviewButton;
