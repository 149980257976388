export type individualGroupActivityType = {
  student_id: number;
  group_id: number;
  status: number;
  update_all_status?: boolean;
};

export enum activityStatus {
  notStarted = 0,
  started = 1,
  ended = 2,
}

export enum groupOLPStatus {
  notStarted = 0,
  ended = 3,
}
export type GroupActivityInformationType = {
  id: number;
  created: Date;
  duration: number;
  time_activity_started: Date;
  online_activity_status:
    | activityStatus.notStarted
    | activityStatus.started
    | activityStatus.ended;
  unit: number;
  number_of_questions: number;
  lesson_group: number;
  lesson: number;
  section: number;
  online_question: number;
  user: number;
};

export type DocumentsType = {
  name: string;
  doc_url: string;
  account_type: number;
};

export type LessonsType = {
  id: number;
  name: string;
  chalktalk_uid: string;
  can_practice: boolean;
  has_documents: boolean;
  lesson_duration: number;
  instructor_url: string;
  student_url: string;
  documents: Array<DocumentsType>;
  g_slide_id: null;
  g_slide_url: null;
  lesson_reviews: [];
};

export type LessonDetailsType = {
  id: number;
  name: string;
  group_order: number;
  plan_url: string;
  can_practice: boolean;
  has_documents: boolean;
  standards: [];
  lessons: Array<LessonsType>;
};

export type unitDetailsType = {
  id: number;
  name: string;
  slug: string;
  curriculum_subject: number;

  lessons: Array<LessonDetailsType>;
  assets: [
    {
      id: number;
      name: string;
    },
  ];
  meta: {
    lesson_practice: boolean;
    group_activity: boolean;
    unit_practice: boolean;
    lesson_slides: boolean;
  };
};

export type startActivityPayloadType = {
  id: number;
};

export type studentType = {
  id: number;
  student_name: string;
  status: number;
  score: string;
  order: number;
  previous_exam_question_id: number;
  previous_choice_id: number;
  student_id: number;
  online_response_id: number;
  online_section_activity: number;
  group_id: number;
};

export type scoringForActivityGroupType = {
  id: number;
  group_status: number;
  students: Array<studentType>;
  score: string;
  section_activity: number;
  number_of_question: number;
};

export type activityGroupType = {
  id: number;
  group_status: number;
  students: Array<studentType>;
  score: string | null;
  section_activity: number;
};

export type userType = {
  id: number;
  last_login: Date;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  date_joined: Date;
  organizations: [
    {
      id: number;
      name: string;
      district: {
        id: number;
        name: string;
        country: string;
        state: string;
      };
    },
  ];
  role: string;
  accounts: [];
  grade_level: number;
  cell_phone: null;
  id_hash: string;
  is_district_admin: boolean;
};

export type onlineActivityType = {
  course_id: number;
  created: Date;
  curriculum_id: number;
  curriculum_subject_id: number;
  duration: number;
  id: number;
  lesson: number;
  lesson_group: number;
  online_activity_status: number;
  online_question: number;
  section: number;
  time_activity_started: Date;
  unit: number;
  user: number;
};
