import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
  LeftSideBarContentWrapper,
} from './AnswerExplanationLeftSideBar.style';

export default class AnswerExplanationLeftSideBar extends Component {
  static propTypes = {
    isSideBarClose: PropTypes.bool,
    toggleLeftSidebar: PropTypes.func,
    children: PropTypes.any,
  };

  toggleSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  };

  render() {
    const {
      children,
    } = this.props;
    const { isSideBarClose } = this.props;

    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <p>Answer Explanation</p>
            <ActionShowHide
              isRotate={isSideBarClose}
              className="toggle-icon"
              onClick={this.toggleSidebar}
            >
              <span className="chalktalk-close" />
            </ActionShowHide>
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        <LeftSideBarContentWrapper>
          {children}
        </LeftSideBarContentWrapper>
      </LeftSiderBarWrap>
    );
  }
}
