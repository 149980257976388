import React, { useMemo } from 'react';
import { GroupStatusEnum, studentSessionType } from '../types';
import Cell from '../Cell';
import { StyledCell } from '../Cell/Cell.style';
import StudentChip from '../StudentChip';

type Props = {
  members: studentSessionType[];
  rowId: number;
  activityStarted: boolean;
  handleStudentSwap: (
    sourceCellId: number,
    sourceRowId: number,
    targetCellId: number,
    targetRowId: number,
  ) => void;
  columnNumber: number;
};

const GroupCells = ({
  members,
  rowId,
  activityStarted,
  handleStudentSwap,
  columnNumber,
}: Props) => {
  const fullMembersArray = useMemo(
    () => new Array(columnNumber).fill(null).map((_, indx) => members[indx]),
    [columnNumber, members],
  );
  if (!members) {
    return null;
  }

  return fullMembersArray.map((member, indx) => {
    if (!member) {
      return <StyledCell className="table-cell" key={indx} />;
    }
    return (
      <Cell
        key={member.id}
        id={member.id}
        rowId={rowId}
        onDropCell={handleStudentSwap}
        disableDrop={member.status === GroupStatusEnum.FINISHED}
      >
        <StudentChip
          isActivityStarted={activityStarted}
          id={member.id}
          rowId={rowId}
          member={member}
        />
      </Cell>
    );
  });
};

export default GroupCells;
