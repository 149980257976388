import styled, { css } from 'styled-components';

export const MissingReportMessageWrapper = styled.div`
  width: 100%;
  margin-top: calc(25% + 5px);
  transform: translateY(-50%);
  .card{
    display: flex;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #4886FC;
    margin-left: calc(50% + 5px);
    transform: translateX(-50%);
    width: 400px
  }
  .msg-content{
    text-align: left;
    line-height: 25px;
  }
`;

export const MissingReportMessageContent = styled.div`
`;

export const WarningIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  img{
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
`;

export const WarningIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
`;

export const CanvasWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 2em;
  overflow-y: scroll;
  .canvas-embed{
    background-color: #fff;
    padding: 2em;
    border-radius: .5em
  }
`;

export const CanvasTitle = styled.h1`
  font-size: 1.5em;
  padding: 0.5em 0;
  margin-bottom: 1.5em;
`;