export const msgCardWrapperStyle = {
  height: '24.5625rem',
  mt: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 1.5,
  p: 4,
} as const;

export const msgCardImgStyle = {
  width: '14.375rem',
  height: '12.5rem',
  objectFit: 'contain',
} as const;

export const msgCardDescriptionStyle = {
  maxWidth: '19.75rem',
  textAlign: 'center',
} as const;
