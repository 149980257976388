import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  NavBarItemCustome,
  NavBarItemHead,
  NavBarName,
  NavBarStatusColumn,
  NavBarIconWrap,
  NavBarIcon,
  NavBarItemWrap,
} from './SubNavBar.style';

const UnitAssessmentSubNavItem = (props) => {
  const history = useHistory();
  const { unitId, sectionId, subjectId, courseId } = useParams();
  const { isActive, isAssigned, name } = props;
  const maxPathNumber = 7;
  const pathNumber = Array.from(Array(maxPathNumber).keys());

  const redirectToUnitAssessmentPage = () => {
    const assessmentPrefix = name.toLowerCase();

    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/${assessmentPrefix}assessment`,
    );
  };

  return (
    <NavBarItemWrap
      id={isActive ? 'active-lesson' : ''}
      isActive={isActive}
      onClick={() => redirectToUnitAssessmentPage(props)}
    >
      <NavBarStatusColumn>
        <NavBarIconWrap isSingleItem>
          <NavBarIcon className="chalktalk-quiz">
            {pathNumber.map((number, index) => (
              <i className={`path${number}`} key={index} />
            ))}
          </NavBarIcon>
        </NavBarIconWrap>
      </NavBarStatusColumn>
      <NavBarItemCustome>
        <NavBarItemHead />
        <NavBarName>
          {`${name} Assessment`}
          {isAssigned && <i className="chalktalk-error" />}
        </NavBarName>
      </NavBarItemCustome>
    </NavBarItemWrap>
  );
};

export default UnitAssessmentSubNavItem;
