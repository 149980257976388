import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Steps, { Step } from 'rc-steps';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { STANDARD_SESSION_TYPE } from '../../utils/constants';
import {
  RegisterStepItemWrap,
  RegisterStepWrap,
  StepWrap,
} from '../StudentRegister/StudentRegister.style';
import AddLessonSessionStepOne from '../../containers/ScheduleAddLessonSessionForm/AddLessonSessionStepOne';
import AddLessonSessionStepTwo from './AddLessonSessionStepTwo';
import AddLessonSessionStepThree from '../../containers/ScheduleAddLessonSessionForm/AddLessonSessionStepThree';
import AddLessonSessionStepFour from '../../containers/ScheduleAddLessonSessionForm/AddLessonSessionStepFour';
import AddLessonSessionStepFive from './AddLessonSessionStepFive';
import { formatDate } from '../../utils/func-utils';
import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,
} from '../../reducers/sectionSchedule';

const stepName = [
  {
    title: 'Select Subject',
  },
  {
    title: 'Type of Lesson Session',
  },
  {
    title: 'Select Unit',
  },
  {
    title: 'Select Lesson',
  },
  {
    title: 'Review',
  },
];

const Icon = ({ number }) => (
  <p className={`rcicon rcicon-${number}`}>
    {number}
    .
  </p>
);

Icon.propTypes = {
  number: PropTypes.any,
};

class ScheduleAddLessonSessionForm extends PureComponent {
  constructor(props) {
    super(props);
    const { sessionDate } = this.props;
    this.state = {
      step: 0,
      values: {
        date: sessionDate || new Date(),
        sessionType: '',
        subject: '',
        unit: '',
        lesson: '',
        activitySlide: '',
        activityWorksheet: '',
        activityPractice: '',
      },
    };
  }

  goToPrevStep = () => {
    this.setState((prevState) => {
      const { step: currentStep, values } = prevState;
      const { sessionType } = values;
      let nextStep = currentStep - 1;

      if (
        currentStep === 4
        && sessionType.value === STANDARD_SESSION_TYPE.UNIT_PRACTICE
      ) {
        nextStep = currentStep - 2;
      }

      return {
        step: nextStep,
      };
    });
  };

  handleSubmitStep = (values) => {
    this.setState((prevState) => ({
      step: prevState.step + 1,
      values,
    }));
  };

  handleSubmitStepOne = (values) => {
    const { subject } = values;

    this.setState((prevState) => {
      const { subject: prevSubject } = prevState.values;

      if (_isEqual(prevSubject, subject)) {
        return {
          values,
          step: prevState.step + 1,
        };
      }

      return {
        values: {
          ...values,
          subject,
          sessionType: null,
          unit: null,
          lesson: null,
          activitySlide: null,
          activityWorksheet: null,
          activityPractice: null,
        },
        step: prevState.step + 1,
      };
    });
  };

  handleSubmitStepThree = (values) => {
    const { unit, sessionType } = values;

    this.setState((prevState) => {
      const { unit: prevUnit } = prevState.values;

      let nextStep = 3;

      if (sessionType.value === STANDARD_SESSION_TYPE.UNIT_PRACTICE) {
        nextStep = 4;
      }

      if (_isEqual(prevUnit, unit)) {
        return {
          values,
          step: nextStep,
        };
      }

      return {
        values: {
          ...values,
          unit,
          lesson: null,
          activitySlide: null,
          activityWorksheet: null,
          activityPractice: null,
        },
        step: nextStep,
      };
    });
  };

  handleSubmitStepTwo = (values) => {
    const { sessionType } = values;

    this.setState((prevState) => {
      const { sessionType: prevSessionType } = prevState.values;

      if (_isEqual(prevSessionType, sessionType)) {
        return {
          values,
          step: prevState.step + 1,
        };
      }

      return {
        values: {
          ...values,
          sessionType,
          unit: null,
          lesson: null,
          activitySlide: null,
          activityWorksheet: null,
          activityPractice: null,
        },
        step: prevState.step + 1,
      };
    });
  };

  sectionsSessionAdd = () => {
    const { sectionsSessionAdd, sectionId } = this.props;
    const { values } = this.state;
    this.props.onClose();

    const {
      date,
      sessionType,
      unit,
      lesson,
      activitySlide,
      activityWorksheet,
      activityPractice,
      subject,
    } = values;
    const payload = {
      date: formatDate(date),
      activity_type: sessionType.value,
      section: sectionId,
      unit: unit.id,
      subject: subject.id,
      lesson: null,
    };

    if (sessionType.value === STANDARD_SESSION_TYPE.LESSSON) {
      payload.lesson = lesson.id;
      payload.name = lesson.name;
      payload.display_name = subject.display_name;

      payload.activity_json = {
        practice: _get(activityPractice, 'value', 0),
        worksheet: _get(activityWorksheet, 'value', 0),
        slides: _get(activitySlide, 'value', 0),
      };
    }
    this.props.sectionScheduleUpdateFromCalendar({
      payload,
      type: 'ADD_LESSON',
    });
    this.props.onClose();
    // sectionsSessionAdd(payload);
  };

  renderRegisterForm = () => {
    const { step, values } = this.state;

    switch (step) {
      case 1:
        return (
          <AddLessonSessionStepTwo
            initialValues={values}
            handleSubmit={this.handleSubmitStepTwo}
            goToPrevStep={this.goToPrevStep}
          />
        );
      case 2:
        return (
          <AddLessonSessionStepThree
            goToPrevStep={this.goToPrevStep}
            handleSubmit={this.handleSubmitStepThree}
            initialValues={values}
          />
        );
      case 3:
        return (
          <AddLessonSessionStepFour
            goToPrevStep={this.goToPrevStep}
            handleSubmit={this.handleSubmitStep}
            initialValues={values}
          />
        );
      case 4:
        return (
          <AddLessonSessionStepFive
            goToPrevStep={this.goToPrevStep}
            values={values}
            handleSubmit={this.sectionsSessionAdd}
          />
        );
      case 0:
      default:
        return (
          <AddLessonSessionStepOne
            handleSubmit={this.handleSubmitStepOne}
            initialValues={values}
          />
        );
    }
  };

  render() {
    const { step } = this.state;

    return (
      <RegisterStepWrap>
        <StepWrap>
          <Steps current={step}>
            {stepName.map((name, index) => (
              <Step
                key={name.title}
                title={name.title}
                icon={<Icon number={index + 1} />}
              />
            ))}
          </Steps>
        </StepWrap>
        <RegisterStepItemWrap>{this.renderRegisterForm()}</RegisterStepItemWrap>
      </RegisterStepWrap>
    );
  }
}

ScheduleAddLessonSessionForm.propTypes = {
  sectionId: PropTypes.any,
  sectionsSessionAdd: PropTypes.func,
  sessionDate: PropTypes.any,
};
const mapDispatchToProps = {
  sectionScheduleUpdateFromCalendar:
    SectionScheduleActions.sectionScheduleUpdateFromCalendar,
};
export default connect(null, mapDispatchToProps)(ScheduleAddLessonSessionForm);
