import { connect } from 'react-redux';
import SubscriptionForm from '../../components/Modals/SubscriptionForm';
import { selectors as UserSelectors } from '../../reducers/user';
import {
  selectors as AuthSelectors,
} from '../../reducers/auth';
import {
  actions as RegisterActions,
} from '../../reducers/register';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';

const mapStateToProps = (state) => {
  const courseList = CourseSelectors.getCourseList(state);
  const shouldDisplayFreeOption = courseList.length === 0;

  return {
    shouldDisplayFreeOption,
    subscriptionData: UserSelectors.getSubscriptionData(state),
    errorMessage: AuthSelectors.getErrorMessage(state) || CourseSelectors.getRequestInviteError((state)),
    isRequestedInvite: CourseSelectors.isRequestedInvite(state),
  };
};

const mapDispatchToProps = {
  submitSubscriptionCode: RegisterActions.submitSubscriptionCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm);
