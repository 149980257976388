/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import _get from 'lodash/get';
import CourseManagementInviteStudent from '../../components/CourseManagementInviteStudent';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { selectors as SectionSelectors } from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', null);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    courseDetail,
    sectionDetail,
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
  };
};

export default connect(mapStateToProps)(CourseManagementInviteStudent);
