export const customTextStyle = {
  blackText: {
    color: 'black',
  },
  noWrapText: {
    whiteSpace: 'noWrap',
  },
} as const;

export const baseBoxStyle = {
  WrapperFlex: {
    display: 'flex',
    gap: 1,
    flexDirection: 'column',
    alignItems: { xs: 'flex-start', sm: 'flex-end', md: 'flex-end' },
  },
} as const;
