export enum LessonAttendanceActivity {
  Lesson = 'lesson',
  Group = 'group',
}

export enum AttendanceStatusKeys {
  Present = 'P',
  Absent = 'A',
  UnRecorded = '--',
}

export interface LessonAttendanceResponse {
  id: number;
  recorded: string;
  lesson: number;
  activity: LessonAttendanceActivity;
  section: number;
  recorded_by: number;
  student: number;
  present: boolean | null;
}

export interface StudentRecord {
  id: number;
  last_login: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  date_joined: string;
  grade_level: number;
  third_party_id: null;
}

export interface MappedAttendanceRecord extends LessonAttendanceResponse {
  studentFullName: string;
}

export type AttendanceRecords = Array<
  MappedAttendanceRecord | Omit<MappedAttendanceRecord, 'recorded_by' | 'recorded' | 'id'>
>;
