import React from 'react';
import { Formik, Form, Field } from 'formik';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import debounce from 'lodash/debounce';
import {
  ActionGroup,
  Button,
} from '../Generals/stylesheets/Button.style';
import { ModalHeader, ModalCLose } from '../Generals/stylesheets/General.style';
import FormInput from '../Generals/FormInput/FormInput';

const validationSchema = Yup.object().shape({
  country: Yup.string().trim().required('Required'),
  schoolName: Yup.string().trim().required('Required'),
  district: Yup.string().trim().required('Required'),
  courseName: Yup.string().trim()
    .max(50)
    .required('Required'),
});

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '640px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class CourseEditForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.debouncedHandleInputChange = debounce(this.handleInputChange, 500);
  }

  componentDidMount() {
    const { fetchCountryList } = this.props;

    fetchCountryList();
  }

  handleInputChange = (values) => {
    const { fetchDistrictList } = this.props;
    fetchDistrictList(values);
  };

  onSubmit = (values) => {
    this.props.onSubmit(values);
  };

  onClose = (e) => {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
  };

  renderFieldDistrict = (fieldProps) => {
    const {
      form, field, options,
    } = fieldProps;
    const { name: fieldName } = field;
    const { values: { country } } = form;
    const isValue = country !== '';
    return (
      <FormInput
        name={fieldName}
        type="select"
        label="District (US Only)"
        options={options}
        isDisabled={!isValue && fieldName === 'district'}
        getOptionValue={option => option.id}
        getOptionLabel={option => option.name}
        onInputChange={this.debouncedHandleInputChange}
        noOptionsMessage={() => 'Begin typing to see options'}
      />
    );
  };

  renderActionButtons = () => (
    <ActionGroup center marginTop>
      <Button submit type="submit">Save</Button>
      <Button onClick={this.onClose}>Cancel</Button>
    </ActionGroup>
  )

  renderActionDeactive = () => (
    <Button alignRight>Deactive Course</Button>
  )

  renderFormContent = () => {
    const {
      country,
      district,
    } = this.props;
    return (
      <Form>
        <FormInput
          type="text"
          name="courseName"
          label="Course Name"
        />
        <FormInput
          type="text"
          name="schoolName"
          label="School Name"
        />
        <FormInput
          type="select"
          name="country"
          label="Country"
          options={country}
          getOptionValue={option => option.code}
          getOptionLabel={option => option.name}
          isSearchable={false}
        />
        <Field
          component={this.renderFieldDistrict}
          name="district"
          options={district}
          isDistrict
        />
        {this.renderActionButtons()}
      </Form>
    );
  };

  render() {
    const { initialValues, isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Course Edit
          <ModalCLose className="chalktalk-close" onClick={this.onClose} />
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          validationSchema={validationSchema}
        >
          {this.renderFormContent()}
        </Formik>
      </Modal>
    );
  }
}

CourseEditForm.propTypes = {
  fetchCountryList: PropTypes.func,
  initialValues: PropTypes.object,
  fetchDistrictList: PropTypes.func,
  country: PropTypes.array,
  district: PropTypes.array,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CourseEditForm;
