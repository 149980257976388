import { connect } from 'react-redux';
import ForgotEmailForm from '../../../components/ForgotPage/ForgotEmail/ForgotEmailForm';
import {
  selectors as AuthSelectors,
  actions as AuthActions,
} from '../../../reducers/auth';

export const mapStateToProps = state => ({
  errorMessage: AuthSelectors.getFindingEmailError(state) || AuthSelectors.getSentResetPasswordError(state),
  isLoading: AuthSelectors.isFindingEmail(state),
  foundEmail: AuthSelectors.getFoundEmail(state),
  isSendingResetPasswordEmail: AuthSelectors.isSendingResetPasswordEmail(state),
  isSentResetPasswordEmail: AuthSelectors.isSentResetPasswordEmail(state),
});

const mapDispatchToProps = {
  findEmail: AuthActions.findEmail,
  resetPassword: AuthActions.resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotEmailForm);
