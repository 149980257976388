import { useHistory } from 'react-router-dom';
import LoadingIndicator from '@components/LoadingIndicator';
import { PracticeContainer } from '@components/PracticePage/PracticePage.style';
import PracticeTopNavBar from '@components/PracticeTopNavBar';
import { Assessment } from '@learnosityassessment';
import { getCurrentUser } from '@reducers/user';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  useDistrictSelectedQuestions,
  useGetLearnosityAdaptivePracticeQuestions,
  useGetLessonDetail,
} from '@components/Component/Lesson/LessonContainer/hooks/query';
import { completePracticeSession } from '@apis/practice';
import {
  useInitializePracticeSession,
  useNavigateToSessionResults,
} from '@components/StandardsPractice/hooks';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import Box from '@components/Atoms/Box';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import { PRACTICE_TYPE } from '@utils/enums';

const LearnosityAdaptivePracticeAssessment = () => {
  const history = useHistory();
  const [session, setSession] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isAdaptiveLearnosityPracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();

  const currentUser = useSelector(getCurrentUser);
  const { data: lessonDetail } = useGetLessonDetail();
  const initializePractice: any = useInitializePracticeSession();
  const { data: lessonQuestions, remove } = useGetLearnosityAdaptivePracticeQuestions();
  const { data: districtSelectedQuestions } = useDistrictSelectedQuestions();
  const questions = districtSelectedQuestions?.length
    ? districtSelectedQuestions
    : lessonQuestions;
  const goToSessionResults = useNavigateToSessionResults('replace', {
    shouldShowSkillMsg: true,
  });
  const goBack = () => {
    history.goBack();
  };

  const setPracticeSession = React.useCallback(() => {
    initializePractice.mutate(PRACTICE_TYPE.LEARNOSITY_ADAPTIVE_PRACTICE, {
      onSuccess(data) {
        setSession(data?.response.data);
      },
    });
  }, [initializePractice]);

  useEffect(() => {
    if (!session) {
      setPracticeSession();
    }
  }, []);

  const handleSubmit = React.useCallback(async () => {
    setIsSubmitting(true);
    await completePracticeSession(session?.id);
    setPracticeSession();
    setTimeout(() => {
      remove();
      goToSessionResults();
    }, 5000);
  }, [goToSessionResults, remove, session?.id, setPracticeSession]);

  if (!isAdaptiveLearnosityPracticeEnabled) {
    return history.push('/dashboard');
  }

  // render the assessment
  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Back to Lesson" goBack={goBack} />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 64px)',
        }}
      >
        {!isSubmitting && session ? (
          <ScrollWrapper>
            <Assessment
              handleActivityExit={goBack}
              userId={`${currentUser.id}`}
              sessionId={session?.uuid}
              activityId={`${lessonDetail.id}`}
              title={lessonDetail.name}
              name="ChalkTalk Assessment"
              subtitle={`${currentUser.first_name || 'N/A'} ${
                currentUser.last_name || 'N/A'
              }`}
              submissionHandler={handleSubmit}
              questions={questions}
            />
          </ScrollWrapper>
        ) : (
          <LoadingIndicator
            content={!session ? 'Initializing Practice' : 'Submitting Practice'}
          />
        )}
      </Box>
    </PracticeContainer>
  );
};

export default React.memo(LearnosityAdaptivePracticeAssessment);
