import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  INITIALIZE_PRACTICE_SESSION,
  TAKE_PRACTICE_SESSION,
  COMPLETE_PRACTICE_SESSION,
  ANSWER_PRACTICE_QUESTION,
  PRACTICE_GET_SESSION_REPORT,
  INITIALIZE_REVIEW_PRACTICE_SESSION,
  actions as PracticeActions,
} from '../../reducers/practice';
import * as PracticeAPI from '../../apis/practice';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';

function* initializePracticeSession({
  lessonIds,
  sectionId,
  unitId,
  userId,
  assetType,
  practiceType,
}) {
  const { response, error } = yield call(
    PracticeAPI.initializePracticeSession,
    lessonIds,
    sectionId,
    unitId,
    userId,
    assetType,
    practiceType,
  );
  if (response) {
    const { data: practiceData } = response;
    yield put(PracticeActions.initializePracticeSessionSuccess(practiceData));
  } else {
    yield put(PracticeActions.initializePracticeSessionFail(error));
    ToastError(error.message || error);
  }
}

function* initializeReviewPracticeSession({ reviewId, reviewTopicId, callback }) {
  const { response, error } = yield call(
    PracticeAPI.initializeReviewPractice,
    reviewId,
    reviewTopicId,
  );
  if (response) {
    const { data: practiceData } = response;
    const { id: practiceSessionId } = practiceData;
    const transformCallback = `${callback}/${practiceSessionId}`;
    yield put(PracticeActions.initializeReviewPracticeSessionSuccess(practiceData));
    yield put(push(transformCallback));
  } else {
    yield put(PracticeActions.initializeReviewPracticeSessionFail(error));
    ToastError(error.message || error);
  }
}

function* takePracticeSession({ practiceId }) {
  const { response, error } = yield call(PracticeAPI.takePracticeSession, practiceId);
  if (response) {
    const { data } = response;
    yield put(PracticeActions.takePracticeSessionSuccess(data));
  } else {
    yield put(PracticeActions.takePracticeSessionFail(error));
  }
}
function* answerPracticeQuestion({ practiceId, answerInfo }) {
  const serializedAnswer = {
    ...answerInfo,
    value: Array.isArray(answerInfo.value) ? answerInfo.value[0] : answerInfo.value,
  };
  const { response, error } = yield call(
    PracticeAPI.answerPracticeQuestion,
    practiceId,
    serializedAnswer,
  );
  if (response) {
    const { data } = response;
    yield put(PracticeActions.answerPracticeQuestionSuccess(data));
  } else {
    yield put(PracticeActions.answerPracticeQuestionFail(error));
    ToastError(error[0]);
  }
}
function* completePracticeSession({ practiceId }) {
  const { response, error } = yield call(PracticeAPI.completePracticeSession, practiceId);

  if (response) {
    const { data } = response;
    yield put(PracticeActions.completePracticeSessionSuccess(data));
    ToastSuccess('Your answers have been successfully submitted.');
  } else {
    yield put(PracticeActions.completePracticeSessionFail(error));
    ToastError(response);
  }
}

function* practiceSessionReport({ sectionId, practiceId }) {
  const { response, error } = yield call(PracticeAPI.practiceSessionReport, practiceId);
  if (response) {
    const { data } = response;
    yield put(PracticeActions.practiceSessionReportSuccess(data));
  } else {
    yield put(PracticeActions.practiceSessionReportFail(error));
  }
}
export default function* practice() {
  yield takeLeading(INITIALIZE_PRACTICE_SESSION, initializePracticeSession);
  yield takeLeading(INITIALIZE_REVIEW_PRACTICE_SESSION, initializeReviewPracticeSession);
  yield takeLeading(TAKE_PRACTICE_SESSION, takePracticeSession);
  yield takeEvery(ANSWER_PRACTICE_QUESTION, answerPracticeQuestion);
  yield takeLeading(COMPLETE_PRACTICE_SESSION, completePracticeSession);
  yield takeLeading(PRACTICE_GET_SESSION_REPORT, practiceSessionReport);
}
