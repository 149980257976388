import styled, { css } from 'styled-components';

export const Subtitle = styled.p`
    width: 70%;
    font-weight: 200;
    font-size: 11pt;
    line-height: normal;
    font-family: 'Montserrat',Arial;
    color: ${(props) => props.theme.colorStyled.ColorLighterGrey};
`;

export const SectionCreationFormWrapper = styled.div`
    margin-top: 30px;
`;

export const ConfigureSubjectsWrapper = styled.div`
    border: 0.5px dashed black;
    position: relative;
    border-radius: 8px;
    padding: 10px 10px;
    margin-top: 10px;
`;

export const ConfigureSubjectTitle = styled.p`
    color: #999999 !important;
    position: absolute;
    top: -9px;
    left: 15px;
    background-color: white;
    font-size: 10pt !important;
    font-family: 'MontserratRegular',Arial !important;
`;

export const SubjectTitle = styled.div`
    background-color: #FAFAFA;
    color: #82888C;
    padding: 10px 20px;
    width: 70% !important;
`;

export const SubtitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    cursor: pointer;
`;

export const StepButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepButton = styled.div`
  border-radius: 999px;
  border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderSilver};
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.theme.colorStyled.ColorBorderSilver};
  margin: 0 3px;
  cursor: pointer;
  ${({ isActive }) => isActive && css`
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  `}
  &:hover{
    background-color: ${(props) => props.theme.colorStyled.ColorSilverChalice};
    border: 1px solid ${(props) => props.theme.colorStyled.ColorSilverChalice};
  }
`;

export const CreateSectionModalWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 35px;
`;

export const SectionNameFieldWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;

    form {
      flex: 1;
    }

    .freemium-msg-span {
      color: #000000;
      font-family: 'Montserrat',Arial;
      font-size: 9.0pt;
      font-style: normal;
      font-weight: 200;
    }
`;

export const Hint = styled.span`
    color: ${(props) => props.theme.colorStyled.ColorBlack};
    font-style: italic;
    font-size: 8.0pt;
    font-family: 'MontserratLight',Arial;
    ${({ isSectionNameHint }) => isSectionNameHint && css`
      color: #D9D9D9;
    `}
`;

export const ConfigurationScheduleModalHint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GoBackWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    i{
        transform: rotate(180deg);
        font-size: 7.0pt;
        &::before {
            color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
        }
    }

    position: absolute;
    left: 17px;
    top: 15px;
`;

export const GoBackBtn = styled.button`
    border: unset;
    font-size: 8.0pt;
    font-family: 'Montserrat-Regular',Arial;
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    background-color: transparent;
`;

export const GroupTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'MontserratRegular' !important;
  font-size: 12.0pt !important;
  color: ${(props) => props.theme.colorStyled.ColorLighterGrey} !important;
  font-weight: bold;
  ${({ isCreateCourseModal }) => isCreateCourseModal && css`
    padding: 0 20px;
  `}
  ${({ customGroupTitleMarginBottom }) => customGroupTitleMarginBottom && css`
    margin-bottom: 20px; 
  `}
  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const GroupSubtitle = styled.p`
  font-size: 10.0pt !important;
  margin-top: 10px;
  font-family: 'MontserratMedium' !important;
`;
export const SubtitleLength = styled.p`
  font-size: 8.0pt !important;
  font-style: italic;
  margin-bottom: 30px;
`;

export const ButtonWrapperModel = styled.button`
  border: none;
  border-radius: 50%;
  background-color: #acacac;
  width: 20px;
  height: 20px;
  color: white;
  position: absolute;
  top: 25px;
  i {
    position: relative;
    right: 2px;
    top: 1px;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
  ${({ isCloseButton }) => isCloseButton && css`
    right: 25px;
  `}
  ${({ isBackButton }) => isBackButton && css`
    left: 25px;
    i{
      font-size: 17px;
      right: 6px;
      top: 0;
    }
  `}
`;

export const InpuDataRow = styled.div`
    display: flex;
    padding: 0 60px;
    align-items: center;
    min-height: 4em;
    .form-items {
      width: 20em;
      margin-bottom: 0em;
      min-height: 30px;
      .div {
        margin-bottom: 0em;
      }
    }
  ${({ black }) => black && css`
    background-color: #f7f7f7;
  `};
  input, .select__control {
    outline: none;
    border-radius: .325em;
    padding: 0.5em;
    margin-top: .5em;
    background-color: transparent;
    border-bottom: 1px solid #cccccc;
  }

`;
export const TextLabel = styled.text`
  flex: 1;
`;
export const CreateOrJoinTitle = styled.div`
  
`;

export const HeaderModel = styled.div`
  padding: 25px 60px;
  ${({ isFirstStep }) => isFirstStep && css`
    padding: 25px 30px;
  `};
`;

export const Title = styled.div`
  font-size: 15pt;
`;

export const SkipLink = styled.p`
  a{
    font-style: normal;
    color: #ff9e16;
  }
`;

export const SubtitleModel = styled.p`
  width: 85%;
  font-weight: 200;
  font-size: 11pt;
  line-height: normal;
  font-family: 'Montserrat',Arial;
  padding-top: .6em;
  color: ${(props) => props.theme.colorStyled.black};
  ${({ customSubtitle }) => customSubtitle && css`
    padding-left: 60px;
    padding-bottom: 10px;
    font-size: 17px;
    font-weight: bold;
  `};
  
`;
