import React, { Component } from 'react';
import Tooltip from '@mui/material/Tooltip';
import LinesEllipsis from 'react-lines-ellipsis';
import {
    TextOverflow,
} from './DynamicOverflowTip.style';
export default class DynamicOverflowTip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showElipsis: false,
      showToolTip: false,
      checked: false,
    };
    this.cardTextRef = React.createRef(null);
    this.lessonRef = React.createRef(null);
  }

  componentDidMount() {
    if(this.cardTextRef?.current){
      let overFlown = this.cardTextRef?.current?.scrollHeight > this.cardTextRef?.current?.clientHeight;
      this.setState({showElipsis: overFlown, elipsisChecked: true});
    }
  }

  componentDidUpdate() {
    if(this.lessonRef?.current && !this.state.checked ){
      const clamped = this.lessonRef?.current?.clamped;
      this.setState({showToolTip: clamped, checked: true});
    }
  }

  render() {
    const { showElipsis, showToolTip } = this.state;
    const { title, lines, large, subject } = this.props;
    return (
        <Tooltip
            enterDelay={500}
            title={title}
            disableHoverListener={!showToolTip}
        >
        <TextOverflow ref={this.cardTextRef} decreaseFontSize={showElipsis} large={large} subject={subject}>
         {
           showElipsis ? <LinesEllipsis
           ref={this.lessonRef}
           text={title}
           maxLine={lines}
           basedOn="letters"
           /> : title
         }
     </TextOverflow> 
      </Tooltip>
    );
  }
}
