/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableContentItem,
  TableContentGroup,
  TableIcon,
} from '../Table/TableComponent.style';
import {
  UserActionWrap,
  UserActionPopup,
  UserActionItem,
} from '../Profile/Profile.style';
import { TableContent } from '../Table/TableComponent';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import ColumnSize from './ColumnSize';
import StudentResetPasswordForm from './StudentResetPasswordForm';

class StudentInfoItem extends Component {
  state = {
    isOpen: false,
    actionPopupById: null,
  };

  componentDidUpdate(prevProps) {
    const { isChangePasswordSuccess: prevIsChangePasswordSuccess } = prevProps;
    const { isChangePasswordSuccess } = this.props;
    if (
      prevIsChangePasswordSuccess !== isChangePasswordSuccess
      && isChangePasswordSuccess
    ) {
      this.closeResetPasswordForm();
    }
  }

  onCloseActionPopup = () => {
    this.setState({
      actionPopupById: null,
    });
  };

  onOpenActionPopup = (teacherId) => {
    this.setState({
      actionPopupById: teacherId,
    });
  };

  openResetPasswordForm = () => {
    this.setState({ isOpen: true });
  };

  closeResetPasswordForm = () => {
    this.setState({ isOpen: false });
  };

  onSubmitResetPassword = (values) => {
    const { courseId, studentInfo, resetStudentPassword } = this.props;
    const { id, first_name: fName, last_name: lName } = studentInfo;
    const name = fName + lName;
    const { new_password: newPass } = values;

    resetStudentPassword(courseId, id, newPass, name);
  };

  renderActionPopup = () => {
    const { actionPopupById } = this.state;
    const {
      isOwner,
      sendResetPassword,
      onOpenConfirmModal,
      studentInfo,
      courseId,
    } = this.props;
    const studentName = `${studentInfo.first_name} ${studentInfo.last_name}`
    return (
      <UserActionPopup>
        <UserActionItem
          blue
          onClick={() => sendResetPassword(studentInfo.email)}
        >
          Send reset link
        </UserActionItem>
        <UserActionItem red onClick={this.openResetPasswordForm}>
          Reset password
        </UserActionItem>
        {isOwner && (
          <UserActionItem
            onClick={() => onOpenConfirmModal(actionPopupById, studentName, courseId)}
          >
            Remove
          </UserActionItem>
        )}
      </UserActionPopup>
    );
  };

  render() {
    const { studentInfo } = this.props;
    const { last_login: lastLogin } = studentInfo;
    const { isOpen, actionPopupById } = this.state;
    const isActionPopupOpen = actionPopupById === studentInfo.id;
    const lastAcitivity = moment(lastLogin).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const diffDateActivity = moment(today).diff(lastAcitivity, 'days');

    return (
      <React.Fragment>
        <TableContentItem smallSize noBorder key={studentInfo.id}>
          <TableContent {...ColumnSize[0]}>
            <TableContentGroup>
              <p>{`${studentInfo.first_name} ${studentInfo.last_name} (G.${studentInfo.grade_level})`}</p>
              <span>{studentInfo.email}</span>
            </TableContentGroup>
          </TableContent>
          <TableContent {...ColumnSize[1]}>
            {lastLogin
              ? diffDateActivity > 30
                ? '30+ days'
                : moment(lastLogin).fromNow()
              : '--'}
          </TableContent>
          <TableContent {...ColumnSize[2]}>
            <UserActionWrap>
              <TableIcon
                className="chalktalk-more"
                onClick={() => this.onOpenActionPopup(studentInfo.id)}
              />
              {isActionPopupOpen && (
                <React.Fragment>
                  {this.renderActionPopup()}
                  <OverlayWrap
                    isOpen={isActionPopupOpen}
                    onClick={this.onCloseActionPopup}
                  />
                </React.Fragment>
              )}
            </UserActionWrap>
          </TableContent>
        </TableContentItem>
        <StudentResetPasswordForm
          onSubmit={this.onSubmitResetPassword}
          isOpen={isOpen}
          onClose={this.closeResetPasswordForm}
        />
      </React.Fragment>
    );
  }
}

StudentInfoItem.propTypes = {
  courseId: PropTypes.string.isRequired,
  studentInfo: PropTypes.shape(),
  sendResetPassword: PropTypes.func.isRequired,
  resetStudentPassword: PropTypes.func.isRequired,
  isChangePasswordSuccess: PropTypes.bool,
  onOpenConfirmModal: PropTypes.func,
  isOwner: PropTypes.bool,
};

export default StudentInfoItem;
