import React from 'react';
import { useParams } from 'react-router-dom';
import UnitAssessmentSubNavItem from '../UnitAssessmentSubNavItem';

const UnitAssessmentSubNavItemWrapper = ({ name, unit }) => {
  const { unitId } = useParams();
  const isActive = unitId == unit.id; // eslint-disable-line

  return (
    <UnitAssessmentSubNavItem
      isActive={isActive}
      name={name}
      isAssigned={false}
      unitId={unit.id}
      key={`${name}_assessment_${unit.id}`}
      unit={unit}
    />
  );
};

export default UnitAssessmentSubNavItemWrapper;
