/* eslint-disable no-plusplus */
const printPdfBlob = (blob) => {
  const file = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe'); // load content in an iframe to print later
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = file;
  iframe.onload = function () {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
};

export default printPdfBlob;
