import React from 'react';
import { useNavigateToPreview } from './hooks';
import { Button } from '../Generals/stylesheets/Button.style';
import { useIsTeacher } from '../../reducers/user/hooks';
import useLearnosityQuestionCT from '../../utils/hooks/useLearnosityQuestionCT';

const PreviewButton = ({ lessonDetail }) => {
  const goToPreview = useNavigateToPreview('es');
  const isTeacher = useIsTeacher();
  const { hasSpanishQuestions } = useLearnosityQuestionCT({
    lessonDetail,
  });
  if (!isTeacher || !hasSpanishQuestions) {
    return null;
  }
  return (
    <Button color="ColorPrimaryGreen" onClick={goToPreview} outlined>
      Previsualización de preguntas en español (preguntas de apoyo)
    </Button>
  );
};

export default PreviewButton;
