import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _keyBy from 'lodash/keyBy';
import MediaQuery from 'react-responsive';
import ShadowSrollbar from '../Scrollbar/ShadowScrollbars';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import DashboardAttendanceReportTable from '../../containers/DashboardAttendanceReportTable';
import {
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';
import DashboardAttendanceDetailFake from './DashboardAttendanceDetailFake';
import { DATE_FORMAT } from '../../utils/constants';

const scrollStyle = {
  height: 'calc(100vh - 3em)',
  width: '100%',
  padding: '1em 1.5em',
};

const scrollStyleMobile = {
  height: 'calc(100vh - 3em)',
  width: '100%',
  padding: '1em',
};

export default class DashboardAttendanceReport extends Component {
  constructor(props) {
    super(props);
  }

  static propTypes = {
    sectionsAttendanceGet: PropTypes.func.isRequired,
    sectionId: PropTypes.string,
    sectionSessionGet: PropTypes.func.isRequired,
    lessonAttendanceList: PropTypes.arrayOf(PropTypes.any).isRequired,
    groupAttendanceList: PropTypes.arrayOf(PropTypes.any).isRequired,
    sectionsStudentGet: PropTypes.func.isRequired,
    courseDetail: PropTypes.shape(),
    sectionDetail: PropTypes.shape(),
    isSideBarClose: PropTypes.bool,
    studentList: PropTypes.arrayOf(PropTypes.shape()),
  }

  componentDidMount() {
    const { sectionId } = this.props;
    if (sectionId !== null) {
      this.inititalData(sectionId);
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProp, prevState) {
    const { sectionId: prevSectionId } = prevProp;
    const { sectionId } = this.props;

    if (prevSectionId !== sectionId && sectionId !== null) {
      this.inititalData(sectionId);
    }
  }

  inititalData = (sectionId) => {
    const {
      sectionsAttendanceGet, sectionSessionGet, sectionsStudentGet,
    } = this.props;

    sectionsAttendanceGet(sectionId);
    sectionSessionGet(sectionId);
    sectionsStudentGet(sectionId);
  }

  renderCorrectDate = () => {
    const { lessonAttendanceList, groupAttendanceList } = this.props;

    return (
      <React.Fragment>
        <BlockWrap>
          <BlockTitle>
            <span>Student Attendance</span>
          </BlockTitle>
          <p>Summary of student attendance up to this point in time</p>
          <DashboardAttendanceReportTable lessonAttendanceList={lessonAttendanceList} groupActivityAttendanceList={groupAttendanceList} />
        </BlockWrap>
      </React.Fragment>
    );
  };

  renderFakeData = () => <DashboardAttendanceDetailFake />;

  render() {
    const { courseDetail, sectionDetail, isSideBarClose } = this.props;
    const { subscription = {} } = courseDetail;
    const { subscription_type: subType } = subscription;

    return (
      <React.Fragment>
        <BreadcumbsFilter
          haveSub={isSideBarClose}
          breadcumbSubList={[courseDetail.name, sectionDetail.name]}
          breadcumbList={['Dashboard', 'Detail', 'Attendance Detail']}
          mobileTitle="Attendance Detail"
        />

        <MediaQuery minWidth={768}>
          {(matches) => (
            <ShadowSrollbar
              autoHide
              style={matches ? scrollStyle : scrollStyleMobile}
            >
              {subType === 3 ? this.renderFakeData() : this.renderCorrectDate()}
            </ShadowSrollbar>
          )}
        </MediaQuery>
      </React.Fragment>
    );
  }
}
