/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { useGetStandardsBySyllabus } from '@apis/standards';
import { useCurrentSubject } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { SubjectRecord } from '@components/ContentFilters/types';
import { StandardRecord } from '@CT-Types/lessonDomain';
import { useCourseById } from '@reducers/courses/hooks';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

type Node = {
  id: string;
  label: string;
  code?: string;
  description?: string;
  noChip?: boolean;
  noCheckbox?: boolean;
  children: Node[];
};

/**
 * Given an object map, recursively build a tree of nodes
 *
 * @param map
 * @param parentKey - The parent key used to build the id of non-standard nodes
 * @returns Node[]
 */
const getNodeFromMap = (map, parentKey = '') => {
  const nodes: Node[] = [];
  const mapKeys = Object.keys(map);

  // Skip displaying if there's only one node to avoid unnecessary nesting
  if (mapKeys.length === 1 && !map[mapKeys[0]]?.standard) {
    const currentKeyId = parentKey.length ? `${parentKey}.${mapKeys[0]}` : mapKeys[0];
    return getNodeFromMap(map[mapKeys[0]].children, currentKeyId);
  }

  mapKeys.forEach((key) => {
    // we'll use this to reference the current node's id for non-standard nodes
    const currentKeyId = parentKey.length ? `${parentKey}.${key}` : key;

    // if the current map object has 'standard', it's a standard node
    if ('standard' in map[key]) {
      nodes.push({
        ...map[key].standard,
        id: map[key].standard.id.toString(),
        label: `${map[key].standard.code}: ${map[key].standard.description}`,
        noChip: false,
        children: getNodeFromMap(map[key].children, currentKeyId),
      });

      // if it doesn't have 'standard', it's a non-standard node
    } else {
      nodes.push({
        ...map[key],
        id: currentKeyId,
        label: currentKeyId,
        children: getNodeFromMap(map[key].children, currentKeyId),
      });
    }
  });

  return nodes;
};

/**
 * Build a tree map from a list of standards records
 *
 * @param standards
 * @returns Node[]
 */
export const buildTreeMap = (standards: StandardRecord[], currentSubject) => {
  const isELASubject = currentSubject?.slug?.toLowerCase()?.includes('english');
  const treeMap = standards.reduce((acc, standard) => {
    const isELAStandard =
      standard.code.startsWith('ELA') ||
      standard.code.startsWith('LA') ||
      standard.code.startsWith('110');

    if (
      currentSubject &&
      ((isELASubject && !isELAStandard) || (!isELASubject && isELAStandard))
    ) {
      return acc;
    }

    const parts = standard.code.split('.');
    let current = acc;

    parts.forEach((part, index) => {
      if (!current[part]) {
        current[part] = {
          children: {},
          noChip: true, // no chip for non-standard nodes
          noCheckbox: index <= 2, // no checkbox for the first 3 levels
        };
      }

      // add the standard object and treat as standard node if we're at the last part
      if (index === parts.length - 1) {
        current[part].standard = standard;
        current[part].noChip = false;
        current[part].noCheckbox = false;
      }

      current = current[part].children;
    });

    return acc;
  }, {});

  return getNodeFromMap(treeMap);
};

export const useStandardSelector = (subjects?: SubjectRecord) => {
  const { courseId } = useParams();
  const course = useCourseById(courseId);
  const { data: standards } = useGetStandardsBySyllabus(course?.syllabus);
  const currentSubject = useCurrentSubject();
  const subjectToMap = currentSubject || subjects?.[0];

  // create a map of standards for easy lookup
  const standardMap = useMemo(() => {
    if (!standards) {
      return [];
    }
    return standards.reduce((acc, standard) => {
      acc[standard.id] = standard;
      return acc;
    }, {});
  }, [standards]);

  // map to tree for use in MultiSelectTree
  const standardTree = useMemo(
    () => buildTreeMap(standards, subjectToMap),
    [standards, subjectToMap],
  );

  return {
    standardTree,
    standardMap,
  };
};
