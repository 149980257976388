import React, { Reducer, ReducerState, useReducer } from 'react';

const usePersistReducerPrefix = 'UPR_';
const getInitialState = (localStorageKey: string) => {
  const initialState = localStorage.getItem(
    `${usePersistReducerPrefix}${localStorageKey}`,
  );
  return initialState ? JSON.parse(initialState) : undefined;
};
const persistState = (localStorageKey: string, state: any) => {
  localStorage.setItem(
    `${usePersistReducerPrefix}${localStorageKey}`,
    JSON.stringify(state),
  );
};
const cleanCache = (localStorageKey: string) => () =>
  localStorage.removeItem(`${usePersistReducerPrefix}${localStorageKey}`);

function usePersistReducer<R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>,
  localStorageKey: string,
): [React.ReducerState<R>, React.Dispatch<React.ReducerAction<R>>, () => void] {
  const initialStateRef = React.useRef<ReducerState<R>>(getInitialState(localStorageKey));
  const [state, dispatch] = useReducer(reducer, initialStateRef.current || initialState);
  React.useEffect(() => {
    persistState(localStorageKey, state);
  }, [state]);

  return [state, dispatch, cleanCache(localStorageKey)];
}

export default usePersistReducer;
