import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { withStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactTooltip from 'react-tooltip';
import { useGetLessonStandard } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useStandardPublication } from '@utils/hooks/useLearnosityQuestionCT/useLearnosityQuestionCT';
import history from '../../utils/history';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import {
  LessonWithAttendance,
  CheckSpace,
  AccordionSummaryStyles,
  AccordionStyles,
} from './StandardsList.style';

const MuiAccordionSummary = withStyles(AccordionSummaryStyles)(AccordionSummary);

const MuiAccordion = withStyles(AccordionStyles)(Accordion);

const scrollStyle = {
  height: '15em',
  width: '100%',
};

const StandardsList = ({ units, subjects, lessonId, match, attendance }) => {
  const [standardLookup, setStandardLookup] = useState({});
  const stateStandard = useStandardPublication();
  const { data: standards } = useGetLessonStandard();

  useEffect(() => {
    const lookupObject = {};

    units?.forEach((unit) => {
      unit.lessons.forEach((lesson) => {
        lesson.standards.forEach((standard) => {
          lesson.unitId = unit.id;
          lesson.subject = subjects[unit.curriculum_subject];

          if (!(standard.code in lookupObject)) {
            lookupObject[standard.code] = [];
          }

          lookupObject[standard.code].push(lesson);
        });
      });
    });

    setStandardLookup(lookupObject);
  }, [units, subjects]);

  const getLessonsByStandard = (givenStandard) =>
    givenStandard in standardLookup ? standardLookup[givenStandard] : [];

  const lessonHasAttendance = (lesson) => attendance.includes(lesson.id);

  const generateLessonLink = (lesson) => {
    if (lessonId == lesson.id) {
      return `${lesson.subject.display_name}, #${lesson.name}`;
    }

    return (
      <span
        onClick={() =>
          history.push(
            `/lessons/course/${match.params.courseId}/section/${match.params.sectionId}/subject/${lesson.subject.id}/unit/${lesson.unitId}/lesson/${lesson.id}`,
          )
        }
      >
        {lesson.subject.display_name}, #{lesson.name}
      </span>
    );
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!!standards &&
        standards.map((standard, i) => (
          <MuiAccordion key={i}>
            <MuiAccordionSummary expandIcon={<ExpandMoreIcon />}>
              {`${stateStandard}.${standard.code}`}
            </MuiAccordionSummary>
            <ShadowScrollbar autoHide style={scrollStyle}>
              <AccordionDetails>
                <ReactTooltip place="top" effect="solid" id="tooltip-lesson-name" />
                {getLessonsByStandard(standard.code).map((lesson) => (
                  <LessonWithAttendance
                    key={lesson.id}
                    data-tip={`${lesson.subject.display_name}, #${lesson.name}`}
                    data-for="tooltip-lesson-name"
                  >
                    <CheckSpace>
                      {lessonHasAttendance(lesson) && (
                        <img
                          src="/images/icon-svg/icon-check.png"
                          alt="check-attendance-icon"
                        />
                      )}
                    </CheckSpace>
                    {generateLessonLink(lesson)}
                  </LessonWithAttendance>
                ))}
              </AccordionDetails>
            </ShadowScrollbar>
          </MuiAccordion>
        ))}
    </>
  );
};

export default StandardsList;
