import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import {
  ContainerMain,
  ToggleButtonContainer,
  ToggleSlider,
  ButtonLabel,
  ToggleButtonItem,
} from './ToggleButton.style';

const ToggleButton = (props) => {
  const {
    options,
    selectedOption,
    setSelectedOption,
    copyPresentationId,
  } = props;

  return (
    <ToggleButtonContainer>
      {options.map((item, index) => (
        <React.Fragment key={`toggle-${item}`}>
          <ReactTooltip
            place="bottom"
            effect="solid"
            id="tooltip-my-version"
            disable={!(item === 'My Version' && !copyPresentationId)}
          />
          <ContainerMain
            data-tip="Create your own version by clicking the action button and selecting Edit Slides."
            data-for={
              item === 'My Version'
              && !copyPresentationId
              && 'tooltip-my-version'
            }
          >
            <ToggleButtonItem
              key={item}
              onClick={() => setSelectedOption(index)}
              disabled={item === 'My Version' && !copyPresentationId}
            >
              <ButtonLabel>{item}</ButtonLabel>
              <ToggleSlider
                index={index}
                name={item}
                selected={index === selectedOption}
              />
            </ToggleButtonItem>
          </ContainerMain>
        </React.Fragment>
      ))}
    </ToggleButtonContainer>
  );
};

ToggleButton.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.number.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  copyPresentationId: PropTypes.string,
};

export default ToggleButton;
