import styled from 'styled-components';
import { UserProfileInfo } from '../Profile/Profile.style';
export const CourseManagementFlexWrap = styled.div`
  display: flex;
  @media (max-width: 1170px) {
    flex-direction: column;
  }
`;

export const InputFormWrapper = styled.div`
  display: flex;
  align-items: center;
  min-height: 3em;
  .form-items {
    margin-bottom: 0em;
    width: 100%;
    > div {
      margin-bottom: 0em;
    }
  }

  input {
    outline: none;
    border-radius: .325em;
    border: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
    padding: 0.5em;
    margin-bottom: .75em;
  }

  .select__control {
    border: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
    border-radius: .325em;
    margin-bottom: .75em;
  }
  .select__value-container {
    height: 2em;
  }
`;

export const SectionDetailWrap = styled.div`
  flex: 0 0 18em;
  max-width: 18em;
  margin-right: 1em;
  @media (max-width: 1170px) {
    flex: 1;
    max-width: none;
    margin-right: 0em;
    margin-bottom: 2em;
  }
`;

export const SectionDetailViewWrap = styled.div``;

export const SectionActionGroup = styled.div`
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  button {
    margin-right: 1em;
    width: 100%;
    &:last-child {
      margin-right: 0em;
    }
  }
  .chalktalk-edit {
    width: auto;
  }
  @media (max-width: 1170px) {
    max-width: 18em;
    margin-left: auto;
  }
`;

export const SectionDetailView = styled.div`
  width: 100%;
  padding: 1em;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: .325em;
  position: relative;
`;

export const SectionDetailTitle = styled.span`
  font-family: 'MontserratMedium';
  font-size: 0.8em;
  color: #aeaeae;
`;

export const SectionDetailInfo = styled(UserProfileInfo)`
  flex-direction: column;
  align-items: start;
  span {
    margin-top: 5px;
  }
`;

export const SectionDetailLine = styled.span`
  width: 1.5px;
  background-color: ${props => props.theme.colorStyled.ColorXBorder};
  margin-right: 1em;
`;

export const SectionSelectWrap = styled.div`
  margin-bottom: 1em;
  .section-select__control {
    border: none;
    box-shadow: none !important;
  }
`;

export const AddTeacherButton = styled.span`
  cursor: pointer;
  border: none;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  background-color: transparent;
  font-style: italic;
  padding: 0em;
  font-family: 'MontserratSemiBold';
  &:hover {
    opacity: .7;
  }
`;

export const TeacherInviteFormWrap = styled.div`
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: .325em;
  padding: 1em;
  margin-top: 1em;
`;
