import styled, { css } from 'styled-components';

export const ToggleButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${(props) => `${props.theme.colorStyled.ColorBoxShadow}de`};
  border-radius: 0.5em;
  margin-right: 0.5em;
  width: 24.3em;
  padding: 2px 2px;
`;
export const ContainerMain = styled.div``;

export const ToggleButtonItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 2em;
  border-radius: 0.5em;
  cursor: pointer;
  z-index: 1;

  ${({ disabled }) => disabled && css`
    color: ${(props) => props.theme.colorStyled.ColorDoveGray};
    pointer-events: none;
  `}
`;

export const ToggleSlider = styled.div`
position: relative;
  height: 2em;
  margin-top: -21px;
  margin-bottom: -5px;
  width: 12em;
  z-index: 1;
  border-radius: 0.5em;
  cursor: pointer;
  ${({ selected }) => selected && css`
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
    box-shadow: -12px 0px 12px 4px
      ${(props) => `${props.theme.colorStyled.ColorBlack}10`};
    animation: moveToRight 500ms ease-in-out;

    ${({ index }) => index === 0 && css`
      box-shadow: 12px 0px 12px 4px
        ${(props) => `${props.theme.colorStyled.ColorBlack}10`};
      animation: moveToLeft 500ms ease-in-out;
    `};
  `}
  
  @keyframes moveToRight {
    0% {
      transform: translateX(-12em);
    }
    100% {
      transform: translateX(0em);
    }
  }

  @keyframes moveToLeft {
    100% {
      transform: translateX(0);
    }
    0% {
      transform: translateX(12em);
    }
  }
`;

export const ButtonLabel = styled.span`
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  z-index: 2;
  font-family: 'MontserratLight';
  font-weight: 600;
  color: ${(props) => props.theme.colorStyled.ColorBlack};
`;
