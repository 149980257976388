import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

type AbsentStudentDialogProps = {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  dataTestId?: string;
};

const AbsentStudentDialog = ({
  show,
  onCancel,
  onConfirm,
  dataTestId = 'absent-student-dialog',
}: AbsentStudentDialogProps) => (
  <Dialog
    actions={[
      {
        buttonProps: {
          variant: 'outlined',
          dataTestId: 'cancel-absent-dialog',
        },
        label: 'Go Back',
        onClick: onCancel,
      },
      {
        buttonProps: {
          variant: 'contained',
          dataTestId: 'confirm-absent-dialog',
          color: 'play',
        },
        label: 'Start Activity',
        onClick: onConfirm,
      },
    ]}
    open={show}
    onClose={onCancel}
    data-testid={dataTestId}
    title="No Students in the Absent Row"
  >
    <Typography
      sx={{
        textAlign: 'start',
      }}
      variant="body1"
    >
      Drag a student’s name to the absent row to exclude them from the activity. Click
      “Start Activity” if there are no absent students.
    </Typography>
  </Dialog>
);

export default AbsentStudentDialog;
