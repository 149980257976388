import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StandardsAssessment } from '@learnosityassessment';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useStandardPublication } from '@utils/hooks/useLearnosityQuestionCT/useLearnosityQuestionCT';
import { useLessonStandards } from '@components/Component/Lesson/LessonContainer/hooks';
import { PRACTICE_TYPE } from '@utils/enums';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { selectors as UserSelectors } from '../../reducers/user';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { completePracticeSession } from '../../apis/practice';
import LoadingIndicator from '../LoadingIndicator';
import { useInitializePracticeSession, useNavigateToSessionResults } from './hooks';
import { STANDARDS_PRACTICE_QUESTION_COUNT } from '../../utils/constants';
import { useSearchParams } from '../../utils/hooks/useSearchParams';

const scrollStyle = {
  backgroundColor: '#fff',
  height: 'calc(100vh - 5em)',
  width: '100%',
};

const StandardsPracticeAssessment = () => {
  const [session, setSession] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const currentUser = useSelector(UserSelectors.getCurrentUser);
  const { data: lessonDetail } = useGetLessonDetail();
  const standards = useLessonStandards();
  const publication = useStandardPublication();
  const initializePractice = useInitializePracticeSession();
  const goToSessionResults = useNavigateToSessionResults('replace');
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  const { lang } = useSearchParams();

  const setPracticeSession = () => {
    initializePractice.mutate(PRACTICE_TYPE.STANDARD_PRACTICE, {
      onSuccess(data) {
        setSession(data?.response.data);
      },
    });
  };

  useEffect(() => {
    if (!session) {
      setPracticeSession();
    }
  }, []);

  const handleActivityExit = () => {
    goBack();
  };

  const handleSubmit = React.useCallback(async () => {
    setSubmitting(true);
    await completePracticeSession(session?.id);
    setPracticeSession();
    setTimeout(() => {
      goToSessionResults();
    }, 5000);
  }, [goToSessionResults, setPracticeSession]);

  // the redux doesn't have lesson detail and it will not , so redirect at this point.
  if (!lessonDetail?.lessons?.length) {
    return <Redirect to="/dashboard/course" />;
  }

  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Back to Lesson" goBack={goBack} />
      {!submitting && session ? (
        <ShadowScrollbar autoHide style={scrollStyle}>
          <StandardsAssessment
            handleActivityExit={handleActivityExit}
            questionCount={STANDARDS_PRACTICE_QUESTION_COUNT}
            userId={`${currentUser.id}`}
            sessionId={session.uuid}
            activityId={`${lessonDetail.id}`}
            name="ChalkTalk Assessment"
            title={lessonDetail.name}
            subtitle={`${currentUser.first_name || 'N/A'} ${
              currentUser.last_name || 'N/A'
            }`}
            standards={standards}
            publication={publication}
            submissionHandler={handleSubmit}
            offset={session?.finished}
            language={lang}
          />
        </ShadowScrollbar>
      ) : (
        <LoadingIndicator
          content={!session ? 'Initializing Practice' : 'Submitting Practice'}
        />
      )}
    </PracticeContainer>
  );
};

export default React.memo(StandardsPracticeAssessment);
