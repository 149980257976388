import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';
import {
  ModalHeader,
  ModalContent,
  ModalGroupAction,
} from './ConfirmModal.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '500px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1310,
  },
};

export default class ConfirmModal extends PureComponent {
  onSubmitDelete = () => {
    const {
      onSubmit, onClose, itemId, courseId, itemName
    } = this.props;
    if (courseId) {
      onSubmit(itemId, courseId, itemName);
    } else {
      onSubmit(itemId, itemName);
    }

    onClose();
  }

  customStyle = (matches) => {
    const { content } = modalStyles;
    if (matches) {
      return ({
        ...modalStyles,
        content: {
          ...content,
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          borderRadius: '0px',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        },
      });
    }
    return modalStyles;
  }

  render() {
    const {
      content, isOpen, onClose, onConfirm, itemId,
    } = this.props;

    return (
      <MediaQuery maxWidth={768}>
        {matches => (
          <Modal
            isOpen={isOpen || !!itemId}
            onRequestClose={onClose}
            style={this.customStyle(matches)}
            ariaHideApp={false}
          >
            <ModalHeader>Confirm</ModalHeader>
            <ModalContent>
              <p>{content}</p>
              <ModalGroupAction>
                <button onClick={onConfirm || this.onSubmitDelete} type="button">Confirm</button>
                <button onClick={onClose} type="button">Cancel</button>
              </ModalGroupAction>
            </ModalContent>
          </Modal>
        )}
      </MediaQuery>
    );
  }
}

ConfirmModal.propTypes = {
  content: PropTypes.string,
  isOpen: PropTypes.any,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onSubmit: PropTypes.func,
  itemId: PropTypes.any,
  courseId: PropTypes.any,
};
