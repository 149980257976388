import { AUTH_LOGOUT } from '../auth';

export const REGISTER_STUDENT = 'register/REGISTER_STUDENT';
export const REGISTER_STUDENT_SUCCESS = 'register/REGISTER_STUDENT_SUCCESS';
export const REGISTER_STUDENT_FAILED = 'register/REGISTER_STUDENT_FAILED';

export const REGISTER_TEACHER = 'register/REGISTER_TEACHER';
export const REGISTER_TEACHER_SUCCESS = 'register/REGISTER_TEACHER_SUCCESS';
export const REGISTER_TEACHER_FAILED = 'register/REGISTER_TEACHER_FAILED';

export const REGISTER_SUBMIT_SUBSCRIPTION = 'register/REGISTER_SUBMIT_SUBSCRIPTION';
export const REGISTER_SUBMIT_SUBSCRIPTION_SUCCESS = 'register/REGISTER_SUBMIT_SUBSCRIPTION_SUCCESS';
export const REGISTER_SUBMIT_SUBSCRIPTION_FAILED = 'register/REGISTER_SUBMIT_SUBSCRIPTION_FAILED';

export const REGISTER_CREATE_COURSE = 'register/REGISTER_CREATE_COURSE';
export const REGISTER_CREATE_COURSE_SUCCESS = 'register/REGISTER_CREATE_COURSE_SUCCESS';
export const REGISTER_CREATE_COURSE_FAILED = 'register/REGISTER_CREATE_COURSE_FAILED';

export const REGISTER_CREATE_SECTION = 'register/REGISTER_CREATE_SECTION';
export const REGISTER_CREATE_SECTION_SUCCESS = 'register/REGISTER_CREATE_SECTION_SUCCESS';
export const REGISTER_CREATE_SECTION_FAILED = 'register/REGISTER_CREATE_SECTION_FAILED';

export const REGISTER_NEXT_STEP = 'register/REGISTER_NEXT_STEP';
export const REGISTER_RESET_STEP = 'register/REGISTER_RESET_STEP';

export const REGISTER_SELECT_STEP_MANUALLY = 'register/SELECT_STEP_MANUALLY';

// student registration actions

export const registerStudent = ({
  firstName,
  lastName,
  email,
  sectionCode,
  grade,
  password,
  confirmPassword,
}) => ({
  type: REGISTER_STUDENT,
  payload: {
    firstName,
    lastName,
    email,
    sectionCode,
    grade,
    password,
    confirmPassword,
  },
});

export const registerStudentSuccess = () => ({
  type: REGISTER_STUDENT_SUCCESS,
});

export const registerStudentFailed = (errorMsg) => ({
  type: REGISTER_STUDENT_FAILED,
  payload: {
    errorMsg,
  },
});

// teacher registration actions

export const registerTeacher = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  password,
  confirmPassword,
  role,
}) => ({
  type: REGISTER_TEACHER,
  payload: {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmPassword,
    role,
  },
});

export const registerTeacherSuccess = () => ({
  type: REGISTER_TEACHER_SUCCESS,
});

export const registerTeacherFailed = (errorMsg) => ({
  type: REGISTER_TEACHER_FAILED,
  payload: {
    errorMsg,
  },
});

// these actions will help teacher registration component calcualte correct current step

export const submitSubscriptionCode = (free, subscriptionCode, callback) => ({
  type: REGISTER_SUBMIT_SUBSCRIPTION,
  payload: {
    free,
    subscriptionCode,
  },
  callback,
});

export const submitSubscriptionCodeSuccess = (subscriptionData) => ({
  type: REGISTER_SUBMIT_SUBSCRIPTION_SUCCESS,
  payload: {
    subscriptionData,
  },
});

export const submitSubscriptionCodeFailed = (errorMsg) => ({
  type: REGISTER_SUBMIT_SUBSCRIPTION_FAILED,
  payload: {
    errorMsg,
  },
});

// create new course from registration form
export const createRegCourse = (courseInfo) => ({
  type: REGISTER_CREATE_COURSE,
  payload: {
    courseInfo,
  },
});

export const createRegCourseSuccess = () => ({
  type: REGISTER_CREATE_COURSE_SUCCESS,
  payload: {
  },
});

export const createRegCourseFailed = (errorMsg) => ({
  type: REGISTER_CREATE_COURSE_FAILED,
  payload: {
    errorMsg,
  },
});

// create new course from registration form
export const createRegSection = (sectionInfo) => ({
  type: REGISTER_CREATE_SECTION,
  payload: {
    sectionInfo,
  },
});

export const createRegSectionSuccess = () => ({
  type: REGISTER_CREATE_SECTION_SUCCESS,
  payload: {
  },
});

export const createRegSectionFailed = (errorMsg) => ({
  type: REGISTER_CREATE_SECTION_FAILED,
  payload: {
    errorMsg,
  },
});

// misc actions

export const teacherRegisterNextStep = () => ({
  type: REGISTER_NEXT_STEP,
});

export const teacherRegisterReset = () => ({
  type: REGISTER_RESET_STEP,
});

const registerSelectStepManually = (step) => ({
  type: REGISTER_SELECT_STEP_MANUALLY,
  step,
});

export const actions = {
  registerStudent,
  registerStudentSuccess,
  registerStudentFailed,

  registerTeacher,
  registerTeacherSuccess,
  registerTeacherFailed,

  submitSubscriptionCode,
  submitSubscriptionCodeSuccess,
  submitSubscriptionCodeFailed,

  createRegCourse,
  createRegCourseSuccess,
  createRegCourseFailed,

  createRegSection,
  createRegSectionSuccess,
  createRegSectionFailed,

  teacherRegisterNextStep,
  teacherRegisterReset,

  registerSelectStepManually,
};

// selectors
export const getCurrentStep = ({ register }) => register.currentStep;
export const getErrorMessage = ({ register }) => register.errorMsg;
export const isRegistering = ({ register }) => register.isRegistering;
export const isLoadingStep = ({ register }) => register.isLoadingStep;
export const isShowSectionConfirmation = ({ register }) => register.showSectionConfirmation;
export const isShowCourseJoinRequest = ({ register }) => register.showCourseJoinRequest;

export const selectors = {
  getCurrentStep,
  getErrorMessage,
  isRegistering,
  isLoadingStep,
  isShowSectionConfirmation,
  isShowCourseJoinRequest,
};

const initialState = {
  currentStep: 0,
  isLoading: null,
  isRegistering: null,
  isLoadingStep: false,
  errorMsg: null,
  updateError: null,
  showSectionConfirmation: null,
  showCourseJoinRequest: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_NEXT_STEP: {
      const { currentStep } = state;

      return {
        ...state,
        currentStep: (currentStep + 1) % 6,
      };
    }

    case REGISTER_RESET_STEP: {
      return {
        ...state,
        currentStep: 0,
      };
    }

    case REGISTER_TEACHER:
    case REGISTER_STUDENT: {
      return {
        ...state,
        isRegistering: true,
        errorMsg: null,
      };
    }

    case REGISTER_TEACHER_SUCCESS:
    case REGISTER_STUDENT_SUCCESS: {
      return {
        ...state,
        isRegistering: false,
      };
    }

    case REGISTER_TEACHER_FAILED:
    case REGISTER_STUDENT_FAILED: {
      const { errorMsg } = action.payload;

      return {
        ...state,
        isRegistering: false,
        errorMsg,
      };
    }

    case REGISTER_CREATE_COURSE:
    case REGISTER_CREATE_SECTION:
    case REGISTER_SUBMIT_SUBSCRIPTION: {
      return {
        ...state,
        isLoading: true,
        updateError: null,
      };
    }

    case REGISTER_CREATE_COURSE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case REGISTER_SUBMIT_SUBSCRIPTION_SUCCESS: {
      const { subscriptionData } = action.payload;
      const { consumed } = subscriptionData || {};

      return {
        ...state,
        isLoading: false,
        showCourseJoinRequest: !!consumed,
      };
    }

    case REGISTER_CREATE_COURSE_FAILED:
    case REGISTER_CREATE_SECTION_FAILED:
    case REGISTER_SUBMIT_SUBSCRIPTION_FAILED: {
      const { errorMsg: updateError } = action.payload;

      return {
        ...state,
        isLoading: false,
        updateError,
      };
    }

    case REGISTER_CREATE_SECTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        showSectionConfirmation: true,
      };
    }

    case AUTH_LOGOUT:
      return {
        ...initialState,
        isLoadingStep: false,
      };
    case REGISTER_SELECT_STEP_MANUALLY:
      return {
        ...state,
        currentStep: action.step,
      };

    default:
      return state;
  }
}
