import React, { useState } from 'react';
import Typography from '@components/Atoms/Typography';
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  List,
  ListItem,
  Popover,
} from '@mui/material';
import Paper from '@components/Atoms/Paper';
import SearchIcon from '@mui/icons-material/Search';
import {
  useInstantSearch,
  useSearchBox,
  InstantSearch,
  Configure,
} from 'react-instantsearch';
import { cleanText } from '@components/QuestionGenerationService/utils/helpers';
import useDebounce from '@utils/hooks/useDebounce';
import ShowIf from '@components/Atoms/ShowIf';
import client from '../../../apis/algolia';

interface AutocompleteElementProps {
  placeholder: string;
  onSelect?: (result: unknown) => void;
  resultId: string;
  resultTitle: string;
  resultSubtitle?: string;
}

const AutocompleteElement: React.FC<AutocompleteElementProps> = ({
  placeholder,
  onSelect,
  resultId,
  resultTitle,
  resultSubtitle,
}) => {
  const { query, refine } = useSearchBox();
  const { results } = useInstantSearch();
  const [inputValue, setInputValue] = useState<string>(query);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { run, isWaiting } = useDebounce(refine, 300);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInputValue(value);

    // Call debounced refine (run) only if the length is 3 or more
    if (value.length >= 3) {
      run(value);
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Paper
        component="form"
        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder={placeholder}
          inputProps={{
            'aria-label': 'Search',
            endAdornment: (
              <Box sx={{ minWidth: '1rem', display: 'flex', alignItems: 'center' }}>
                <ShowIf If={isWaiting}>
                  <CircularProgress size="0.875rem" />
                </ShowIf>
              </Box>
            ),
          }}
          onChange={handleSearchChange}
          value={inputValue}
          size="small"
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          {isWaiting ? <CircularProgress size="1.5rem" /> : <SearchIcon />}
        </IconButton>
      </Paper>

      {/* Popover for search results */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        disableAutoFocus
        disableEnforceFocus
        sx={{
          maxWidth: '70rem',
          minWidth: '30rem',
        }}
      >
        <List sx={{ maxHeight: 400, overflowY: 'auto' }}>
          {results?.hits?.length === 0 && (
            <ListItem>
              <Typography>No Results</Typography>
            </ListItem>
          )}
          {results?.hits.length > 0 &&
            results?.hits?.map((result) => (
              <ListItem
                key={result[resultId]}
                onClick={() => {
                  setAnchorEl(null);
                  onSelect?.(result);
                  setInputValue('');
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  p: 1,
                  cursor: 'pointer',
                  transition: 'background-color 0.2s ease-in-out',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  },
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 'bold',
                  }}
                >
                  {cleanText(result[resultTitle])}
                </Typography>
                <ShowIf If={!!resultSubtitle && resultSubtitle in result}>
                  <Typography
                    variant="body3"
                    sx={{
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {cleanText(result[resultSubtitle as string])}
                  </Typography>
                </ShowIf>
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  );
};

interface AutocompleteProps extends AutocompleteElementProps {
  index: string;
  filters?: string;
}

const Autocomplete: React.FC<AutocompleteProps> = ({ index, filters, ...props }) => (
  <InstantSearch searchClient={client} indexName={index}>
    <Configure filters={filters} hitsPerPage={10} />
    <AutocompleteElement {...props} />
  </InstantSearch>
);

export default Autocomplete;
