import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import LessonExamConfirmModal from '../ConfirmModal';
import ExamSubStudentTableV2 from '../../containers/ExamSubStudentTableV2';
import CommonUtils from '../../utils/commonUtils';
import ColumnSize from './ColumnSize';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import * as ExamAPI from '../../apis/exam';
import Tooltip from '@mui/material/Tooltip';
import {
  SECTION_STATUS,
  ROLE_TYPE,
  RESULT_STATUS,
  EXAM_TYPE,
} from '../../utils/enums';
import {
  SectionListWrap,
  SectionRowIcon,
  SectionRowWrap,
  SectionRowItem,
  SectionRowContent,
  SectionRowIconImg,
  Wrapper,
  ResumeIcon,
  PreviousTestRedirect,
  TestletStatusContainer,
  StatusText,
  StatusDate,
  CompleteStatusIcon,
  StatusTextContainer,
  LockedStatusIcon,
} from './ExamSectionTableV2.style';
import { BlockWrapNoBorderRadius } from '../Generals/stylesheets/General.style';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  TableHeadWrapper,
  TableContentItem,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { Link } from 'react-router-dom';
import ExamDialog from '../ConfirmModal/ConfirmModalV2';
class ExamSectionTableV2 extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isReset: false,
      isModalOpen: null,
      isSelectedSection: null,
      isSecondDialogOpen: null,
    };
  }

  componentDidMount = () => {
    this.submitCachedAnswer();
    window.addEventListener('online', this.submitCachedAnswer);
  }

  componentWillUnmount = () => {
    window.removeEventListener('online', this.submitCachedAnswer);
  }

  onOpenSection = (sectionId) => {
    const { isSelectedSection } = this.state;
    if (sectionId !== null) {
      if (isSelectedSection === sectionId) {
        this.setState({ isSelectedSection: null });
      } else {
        this.setState({ isSelectedSection: sectionId });
      }
    }
  };

  onOpenModal = (sectionId, isReset = false) => {
    const { isModalOpen } = this.state;
    if (sectionId !== null) {
      if (isModalOpen === sectionId) {
        this.setState({ isModalOpen: null, isReset });
      } else {
        this.setState({ isModalOpen: sectionId, isReset });
      }
    }
  };
  onOpenSecondDialog = (sectionId, isReset = false) => {
    const { isSecondDialogOpen } = this.state;
    
    if (sectionId !== null) {
      if (isSecondDialogOpen === sectionId) {
        this.setState({ isSecondDialogOpen: null, isReset });
      } else {
        this.setState({ isSecondDialogOpen: sectionId, isReset, isModalOpen: null });
      }
    }
  };
  onCloseSecondDialog = () => {
    this.setState({
      isSecondDialogOpen: null,
      isModalOpen: null,
    });
  };

  onCloseModal = () => {
    this.setState({
      isModalOpen: null,
    });
  };

  redirectToReviewPage = (examSectionId) => {
    const {
      history, sectionId, examSessionId, courseId, userId,
    } = this.props;

    history.push(
      `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/review/${examSectionId}/student/${userId}`,
    );
  };

  redirectToStudentReviewPage = (examSectionId) => {
    const {
      history, sectionId, examSessionId, courseId,
    } = this.props;

    history.push(
      `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/review/${examSectionId}`,
    );
  };

  unlockSection = (examSectionId) => {
    const { examDetail, sectionExamUnlock } = this.props;
    const { id: examSessionId } = examDetail;

    sectionExamUnlock(examSessionId, examSectionId);
    this.onCloseModal();
    this.onCloseSecondDialog();
  };

  pauseSection = (examSecionId) => {
    const { examDetail, sectionExamPause } = this.props;
    const { id: examSessionId } = examDetail;
    sectionExamPause(examSessionId, examSecionId);
    this.onCloseModal();
  };

  resetSection = (examSecionId) => {
    const { examDetail, sectionExamReset } = this.props;
    const { id: examSessionId } = examDetail;
    sectionExamReset(examSessionId, examSecionId);
    this.onCloseModal();
  };

  resetExamSection = (examSecionId) => {
    const { examDetail, sectionExamResetExamSection } = this.props;
    const { id: examSessionId } = examDetail;
    // We use exam reset with get updated score here 
    sectionExamResetExamSection(examSessionId, examSecionId);
    this.onCloseModal();
  }

  beginTheTest = (examSectionId) => {
    const {
      examSessionId,
      examDetail,
      examInitializeResponse,
      userId,
      courseId,
      sectionId,
    } = this.props;
    const examId = _get(examDetail, 'exam.id', '');
    const callback = `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/take/${examSectionId}`;

    examInitializeResponse(
      examSessionId,
      examId,
      examSectionId,
      userId,
      callback,
    );
  };

  openPdfFile = (examSectionId) => {
    const {
      courseId, sectionId, examSessionId, history,
    } = this.props;
    const url = `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/pdf-viewer/${examSectionId}/`;

    history.push(url);
  };
  
  getCompletedStudentNames = (sectionId) => {
    const { results, studentList } = this.props;
    const studentIds = results
    .filter(student => student.section_meta.some(section => section.section === sectionId))
    .map(student => student.user);

    const studentFullNames = studentList
    .filter(student => studentIds.includes(student.id))
    .map(student => `(${student.first_name} ${student.last_name})`);

    return studentFullNames.map((name, index) => (
      <span key={index}>
        {name}
        <br />
      </span>
    ));
  }
  renderActionUnlock = (section) => {
    const { isModalOpen, isSecondDialogOpen } = this.state;
    const { duration, name, id } = section;
    const assignWeeks = 2;

    const isOpen = isModalOpen === id;
    const secondDialogOpen = isSecondDialogOpen === id;

    const studentNamesInElements = this.getCompletedStudentNames(id);
    const sectionHasCompletedStudents = studentNamesInElements.length > 0;
    return (
      <React.Fragment>
        <Tooltip title="Assign section for all students" placement="top">
          <Button blue round onClick={() => this.onOpenModal(id)}>
            Assign All
          </Button>
        </Tooltip>
        <ExamDialog
          isOpen={sectionHasCompletedStudents ? isOpen : false}
          onClose={this.onCloseModal}
          onConfirm={() => this.onOpenSecondDialog(id)}
          dataTestId="students-completed-dialog"
          title={'Some Students have completed this testlet'}
          confirmButtonTitle='Continue'
          content={
            <div>
              These students have completed this testlet and <span className='bold'>will not be assigned </span> unless you reset their testlet:
              <br /> <br />
              {studentNamesInElements}
            </div>
          }
        />
        <ExamDialog
          isOpen={sectionHasCompletedStudents ? secondDialogOpen : isOpen}
          onClose={this.onCloseSecondDialog}
          onConfirm={() => this.unlockSection(id)}
          dataTestId="assign-all-dialog"
          title={`Assign ${name} ?`}
          confirmButtonTitle={'Assign All'}
          content={<div>Your students will have <span className='bold'>{assignWeeks} weeks</span> to complete this testlet from the day of assignment. After {assignWeeks} weeks all exams will automatically be submitted and testlet will be closed.
          <br /> <br />
          Your students will have <span className='bold'>{duration} minutes</span> to complete the testlet after beginning. You can do the testlet in one go, or pause the timer to continue the session on another day.</div>}
        />
      </React.Fragment>
    );
  };
  renderActionResume = (sectionId) => {
    const { isModalOpen } = this.state;
    const isOpen = isModalOpen === sectionId;
    return (
      <React.Fragment>
        <Tooltip title="Unlock section for students" placement="top">
          <Button outlined round onClick={() => this.onOpenModal(sectionId)}>
          <ResumeIcon > </ResumeIcon> 
          Resume
          </Button>
        </Tooltip>
        <LessonExamConfirmModal
          isOpen={isOpen}
          onClose={this.onCloseModal}
          onConfirm={() => this.unlockSection(sectionId)}
          content="Are you sure you want to unlock the section? Students will be able to start the section once it is unlocked."
        />
      </React.Fragment>
    );
  };

  renderActionPauseReset = (sectionId) => {
    const { isModalOpen, isReset } = this.state;
    const isOpen = isModalOpen === sectionId;
    return (
      <React.Fragment>
        <Tooltip title="Pause exam" placement="top">
          <SectionRowIcon
            className="chalktalk-pause"
            onClick={() => this.onOpenModal(sectionId)}
          />
        </Tooltip>

        <Tooltip title="Reset exam timer" placement="top">
          <SectionRowIcon
            data-tip="Reset exam timer"
            onClick={() => this.resetSection(sectionId)}
          >
            <img alt="" src="/images/reset-exam-icon.jpeg" />
          </SectionRowIcon>
        </Tooltip>

        <Tooltip title="Reset the exam for all students that have submitted it. Those still working will not be affected" placement="top">
          <SectionRowIcon
            className="chalktalk-reset"
            onClick={() => this.onOpenModal(sectionId, true)}
          />
        </Tooltip>
        <LessonExamConfirmModal
          isOpen={isOpen && !isReset}
          onConfirm={() => this.pauseSection(sectionId)}
          onClose={this.onCloseModal}
          content="Are you sure you want to pause the section timer? Students will be locked out of their section session once it is paused. Timer will pause until it is unlocked again. All responses will be saved."
        />
        <LessonExamConfirmModal
          isOpen={isOpen && isReset}
          onConfirm={() => this.resetExamSection(sectionId)}
          onClose={this.onCloseModal}
          content="Are you sure you want to reset this exam? The exam will be reset and scores will be deleted. All responses will be saved"
        />
      </React.Fragment>
    );
  };

  renderTableHeader = () => (
    <TableHeadWrapper isExamSection>
      <TableHeader {...ColumnSize[0]}>Testlets</TableHeader>
      <TableHeader {...ColumnSize[1]}>Duration</TableHeader>
      <TableHeader {...ColumnSize[1]}>Testlet Status</TableHeader>
      <TableHeader {...ColumnSize[2]}>Student Completion</TableHeader>
      <TableHeader {...ColumnSize[3]}></TableHeader>
    </TableHeadWrapper>
  );

  renderTableHeaderStudent = () => (
    <TableHeadWrapper isExamSection>
      <TableHeader {...ColumnSize[0]}>Testlets</TableHeader>
      <TableHeader {...ColumnSize[1]}>Duration</TableHeader>
      <TableHeader {...ColumnSize[1]}>Testlet Status</TableHeader>
      <TableHeader {...ColumnSize[2]}>Score</TableHeader>
      <TableHeader {...ColumnSize[3]}></TableHeader>
    </TableHeadWrapper>
  );

  renderAssignButton = (section, sectionStatus) => {
    const { id } = section;
    switch (sectionStatus) {
      case SECTION_STATUS.COMPLETED:
        return null;
      case SECTION_STATUS.PAUSED:
        return this.renderActionResume(id);
      case SECTION_STATUS.LOCKED:
        return this.renderActionUnlock(section);
      default:
        return null;
    }
  };
  renderButtonGroup = (section, sectionStatus) => {
    const { id } = section;
    switch (sectionStatus) {
      case SECTION_STATUS.UNLOCKED:
        return this.renderActionPauseReset(id);
      default:
        return null;
    }
  };

  renderExamReviewButton = (section) => {
    const { id } = section;
    const { examDetail } = this.props;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const reviewStatus = _get(sectionMeta, 'can_review', false);
    const enableExamReview = () => {
      const { examDetail, sectionExamReviewUnlock } = this.props;
      const { id: sessionId } = examDetail;
      sectionExamReviewUnlock(sessionId, id);
    };
    const disableExamReview = () => {
      const { examDetail, sectionExamReviewLock } = this.props;
      const { id: sessionId } = examDetail;
      sectionExamReviewLock(sessionId, id);
    };
    if (reviewStatus) {
      return (
        <SectionRowIconImg
          src="/images/reviewAsset.png"
          opacity={1}
          title="Click to hide the answer key from students."
          data-tip="Click to hide the answer key from students."
          data-for="tooltip-students-action"
          onClick={disableExamReview}
        />
      );
    }
    return (
      <SectionRowIconImg
        src="/images/reviewAsset.png"
        opacity={0.5}
        title="Click to show students the answer key."
        data-tip="Click to show students the answer key."
        data-for="tooltip-students-action"
        onClick={enableExamReview}
      />
    );
  };

  renderLockButton = () => (
    <Tooltip title="Locked Exam" placement="top">
      <SectionRowIcon
        className="chalktalk-lock"
      />
    </Tooltip>
  );

  renderBeginButton = (examSectionId, label) => (
    <Button blue onClick={() => this.beginTheTest(examSectionId)}>
      {label}
    </Button>
  );

  renderButtonGroupStudent = (section) => {
    const { examDetail, resultOfStudent } = this.props;
    const { id } = section;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const sectionStatus = _get(
      sectionMeta,
      'section_status',
      SECTION_STATUS.LOCKED,
    );

    const status = _get(resultOfStudent, 'status');
    const sectionMetaOfResult = (resultOfStudent.section_meta || []).find(
      (item) => item.section === id,
    );
    const endedTime = _get(sectionMetaOfResult, 'ended', null);
    const startedTime = _get(sectionMetaOfResult, 'started', null);

    if (status === RESULT_STATUS.PENDING && endedTime) {
      return <Button disabled>Calculating...</Button>;
    }

    switch (sectionStatus) {
      case SECTION_STATUS.PAUSED:
      case SECTION_STATUS.LOCKED:
        return this.renderLockButton();
      case SECTION_STATUS.UNLOCKED: {
        const label = startedTime ? 'Continue Test' : 'Begin test';
        return this.renderBeginButton(id, label);
      }
      case SECTION_STATUS.COMPLETED: {
        if (sectionMetaOfResult && sectionMetaOfResult.ended) {
          return <Button disabled>Completed</Button>;
        }
        else {
          const label = 'Begin test';
          return this.renderBeginButton(id, label);
        }
      }

      default:
        return null;
    }
  };
  getPreviouslyCompletedSections = (previousSession) => {
    const examSections = _get(previousSession, 'session_metadata.sections', []);
    const completedSections = examSections.filter(section => section.is_complete === true); 
    return completedSections
  }
  getPreviousSession = (currentSession) => {
    const {examSessons } = this.props;
    const currentOrder = currentSession.practice_test_order
    if (currentOrder === 0 || currentOrder == null) {
      return null;
    }
    const previousOrder = currentOrder - 1
    const previousSessionIndex = examSessons.findIndex(item => item.practice_test_order === previousOrder);

    // If the previous session is not found, return null
    if (previousSessionIndex === -1) {
      return null;
    }
    return examSessons[previousSessionIndex]

  }
  renderTestletLocked = (url) => (
    <Link to={url}>
      <PreviousTestRedirect>
        Go to previous testlet
      </PreviousTestRedirect>
    </Link>
  );

  renderSectionItem = (section) => {
    const { duration, name, id } = section;
    const { isSelectedSection } = this.state;
    const { courseId, sectionId, studentLength, resultOfSection, examDetail } = this.props;
    const durationStr = CommonUtils.convertMinutesToTestDuration(
      duration,
      true,
    );
    const { isSectionOpen, previousUrl } = this.getIsSectionOpen(examDetail, courseId, sectionId, name);
    const isSectionSelected = isSelectedSection === id;
    const completedStudent = _get(resultOfSection, id, 0);
    const { length_type: examType } = examDetail;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const timestamp = _get(sectionMeta, 'last_modified', 0);
    const sectionModifiedDate = moment.unix(timestamp).format('YYYY-MM-DD');

    const sectionStatus = _get(
      sectionMeta,
      'section_status',
      SECTION_STATUS.LOCKED,
    );
    const sectionComplete = _get(
      sectionMeta,
      'is_complete',
      false,
    )
    const date = _get(sectionMeta, 'section_date', examDetail.start_date);

    return (
      <SectionRowWrap key={section.id}>
        <SectionRowItem>
          <SectionRowContent isOpen={!isSectionOpen}>{name}</SectionRowContent>
          <SectionRowContent isOpen={!isSectionOpen}>{durationStr}</SectionRowContent>
          <SectionRowContent isOpen={!isSectionOpen}>{this.renderSectionStatus(isSectionOpen, sectionStatus, sectionModifiedDate, sectionComplete)}</SectionRowContent>
          <SectionRowContent isOpen={!isSectionOpen}>
            {completedStudent}
            /
            {studentLength}
          </SectionRowContent>
          {isSectionOpen ?
            (<SectionRowContent actionType rightAlign>
              {/* temporarily removed */}
              {/* {this.renderExamReviewButton(section)} */}
              {this.renderButtonGroup(section, sectionStatus)}
              <Tooltip title="Expand to view the list of students and results" placement="top">
                <SectionRowIcon
                  className="chalktalk-list"
                  onClick={() => this.onOpenSection(section.id)}
                />
              </Tooltip>
              {examType === EXAM_TYPE.OFFLINE && (
                <Tooltip title="View the exam PDF" placement="top">
                  <SectionRowIcon
                    className="chalktalk-pdf-file"
                    onClick={() => this.openPdfFile(id)}
                  />
                </Tooltip>
              )}
              {this.renderAssignButton(section, sectionStatus)}
            </SectionRowContent>) : (this.renderTestletLocked(previousUrl))}
        </SectionRowItem>
        <ExamSubStudentTableV2
          isExpanded={isSectionSelected}
          examSectionId={id}
          sectionStatus={sectionStatus}
        />
      </SectionRowWrap>
    );
  };

  TestletStatus = ({ status, date }) => (
    <TestletStatusContainer>
      <StatusTextContainer>
      {status === 'Complete' && (
        <CompleteStatusIcon src="/images/chalktalk-checkmark.svg" alt="Checkmark Icon" />
      )}
      {status === 'Locked' && (
        <LockedStatusIcon src="/images/chalktalk-lock.svg" alt="Lock Icon" />
      )}       
      <StatusText status={status}>{status}</StatusText>
      </StatusTextContainer>
      {date && <StatusDate>{date}</StatusDate>}
    </TestletStatusContainer>
  );

  renderSectionStatus = (isSectionOpen, sectionStatus, sectionModifiedDate, isSectionComplete) => {
    // Handle the cases where the section is complete before entering the switch
    if (isSectionComplete) {
      return <this.TestletStatus status="Complete" date={sectionModifiedDate} />;
    }
  
    let status;
  
    switch (sectionStatus) {
      case SECTION_STATUS.LOCKED:
        status = isSectionOpen ? 'Unassigned' : 'Locked';
        break;
  
      case SECTION_STATUS.UNLOCKED:
        status = 'In Progress';
        break;
  
      case SECTION_STATUS.PAUSED:
        status = 'Paused';
        break;
  
      default:
        status = 'Locked';
    }
  
    return <this.TestletStatus status={status} date={status === 'In Progress' ? sectionModifiedDate : undefined} />;
  };


  renderSectionStatusStudent = (id, isSectionOpen, sectionStatus, sectionModifiedDate) => {
    const { resultOfStudent } = this.props;
    const sectionMetaOfResult = (resultOfStudent.section_meta || []).find(
      (item) => item.section === id,
    );
    const endedTime = _get(sectionMetaOfResult, 'ended', null);
    const startedTime = _get(sectionMetaOfResult, 'started', null);
  
    if (endedTime) {
      return <this.TestletStatus status="Complete" date={sectionModifiedDate} />;
    }
    if (sectionStatus === SECTION_STATUS.UNLOCKED) {
      return startedTime ? (
        <this.TestletStatus status="In Progress" date={sectionModifiedDate} />
      ) : (
        <this.TestletStatus status="Assigned" date={sectionModifiedDate} />
      );
    }
    if (sectionStatus === SECTION_STATUS.LOCKED) {
      return isSectionOpen ? (
        <this.TestletStatus status="Unassigned" />
      ) : (
        <this.TestletStatus status="Locked" />
      );
    }
    if (sectionStatus === SECTION_STATUS.PAUSED) {
      return <this.TestletStatus status="Paused" />;
    }
  
    return <this.TestletStatus status="Unknown" />;
  };


  renderSectionItemStudent = (section) => {
    const { courseId, sectionId, studentSectionReport, examDetail } = this.props;
    const { duration, name, id } = section;

    const durationStr = CommonUtils.convertMinutesToTestDuration(
      duration,
      true,
    );
    const { isSectionOpen, _ } = this.getIsSectionOpen(examDetail, courseId, sectionId, name);
    const sectionReport = (studentSectionReport || []).find(
      (item) => item.section_id === id,
    );
    const autoScore = _get(sectionReport, 'score.auto', null);
    const autoScoreMax = _get(sectionReport, 'score.auto_max', null);
    const shouldDisplayReviewButton = autoScore !== null && autoScoreMax !== null;
    const sectionStatusList = _get(examDetail, 'session_metadata.sections', []);
    const sectionMeta = sectionStatusList.find(
      (item) => item.section_id === id,
    );
    const date = _get(sectionMeta, 'section_date', examDetail.start_date);
    const canReview = _get(sectionMeta, 'can_review', false);
    const timestamp = _get(sectionMeta, 'last_modified', 0);
    const sectionModifiedDate = moment.unix(timestamp).format('YYYY-MM-DD');
    const sectionStatus = _get(
      sectionMeta,
      'section_status',
      SECTION_STATUS.LOCKED,
    );
    
    return (
      <TableContentItem custome key={id} isExamSection>
        <TableContent {...ColumnSize[0]}>{name}</TableContent>
        <TableContent {...ColumnSize[1]}>{durationStr}</TableContent>
        <TableContent {...ColumnSize[1]}>
          {this.renderSectionStatusStudent(
            id,
            isSectionOpen,
            sectionStatus,
            sectionModifiedDate,
          )}
          </TableContent>
          <TableContent {...ColumnSize[2]}>
          {`${autoScore !== null ? autoScore : '--'}/${
            autoScoreMax !== null ? autoScoreMax : '--'
          }`}
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          {shouldDisplayReviewButton ? (
            canReview ? (
              <Button onClick={() => this.redirectToStudentReviewPage(id)}>Review</Button>
            ) : (
              <div
                data-tip="Your teacher hasn’t unlocked the answer key yet."
                title="Your teacher hasn’t unlocked the answer key yet."
                data-for="tooltip-student-review"
              >
                {/* temporarily removed */}
                {/* <Button disabled>Review</Button> */}
              </div>
            )
          ) : (
            this.renderButtonGroupStudent(section)
          )}
        </TableContent>
      </TableContentItem>
    );
  };

  submitCachedAnswer = () => {
    const answerList = JSON.parse(localStorage.getItem('answerList') || '[]');
    const examCompletePayload = JSON.parse(localStorage.getItem('examCompletePayload') || '{}');
    const sessionExamId = localStorage.getItem('sessionExamId');
    const examIsCompleted = localStorage.getItem('examIsCompleted' || false);
    const online = window.navigator.onLine;
    if (!online || sessionExamId == null) return;


    if (answerList.length !== 0 && sessionExamId) {
      localStorage.removeItem('answerList');
      ExamAPI.submitQuestionResponse(sessionExamId, answerList);
    }
    if (examIsCompleted && examCompletePayload && sessionExamId) {
      localStorage.removeItem('examIsCompleted');
      ExamAPI.completeSectionResponse(sessionExamId, examCompletePayload);
    }
    localStorage.removeItem('sessionExamId');
  }

  getIsSectionOpen(examDetail, courseId, sectionId, name) {
    const previousSession = this.getPreviousSession(examDetail);
    const previouslyCompleted = previousSession ? this.getPreviouslyCompletedSections(previousSession) : [];
    const previousUrl = previousSession ? `/test/course/${courseId}/section/${sectionId}/exam/${previousSession.id}` : null;
    const isSectionOpen = !previousSession || previouslyCompleted.some((item) => item.section_name === name);
    return { isSectionOpen, previousUrl };
  }

  renderexamSectionContent() {
    const { examDetail, userRole, isGettingSectionExam } = this.props;
    const sections = _get(examDetail, 'exam.sections', []);
    return (
      <>
        {isGettingSectionExam ? (
          <TableLoader />
        ) : (
          <React.Fragment>
            {userRole === ROLE_TYPE.INSTRUCTOR && (
              <Wrapper>
                {this.renderTableHeader()}
                <SectionListWrap>
                  {sections.map(this.renderSectionItem)}
                </SectionListWrap>
              </Wrapper>
            )}
            {userRole === ROLE_TYPE.STUDENT && (
              <Wrapper>
                {this.renderTableHeaderStudent()}
                <SectionListWrap>
                  {sections.map(this.renderSectionItemStudent)}
                </SectionListWrap>
              </Wrapper>
            )}
          </React.Fragment>
        )}
      </>
    );
  }

  render() {
    return (
      <BlockWrapNoBorderRadius>
        {this.renderexamSectionContent()}
      </BlockWrapNoBorderRadius>
    );
  }
}

ExamSectionTableV2.propTypes = {
  examDetail: PropTypes.object,
  studentLength: PropTypes.any,
  resultOfSection: PropTypes.object,
  userRole: PropTypes.string,
  examSessionId: PropTypes.any,
  examInitializeResponse: PropTypes.func,
  userId: PropTypes.any,
  courseId: PropTypes.any,
  sectionId: PropTypes.any,
  sectionExamUnlock: PropTypes.func.isRequired,
  sectionExamPause: PropTypes.func.isRequired,
  sectionExamReset: PropTypes.func.isRequired,
  sectionExamResetExamSection: PropTypes.func.isRequired,
  studentSectionReport: PropTypes.array,
  history: PropTypes.any,
  resultOfStudent: PropTypes.object,
  isGettingSectionExam: PropTypes.bool,
  examSessons: PropTypes.array,
  results: PropTypes.array,
  studentList: PropTypes.array,
};

export default ExamSectionTableV2;
