import { useEffect, useState, useMemo } from 'react';
import {
  fetchSkillsJSON,
  SKILLS_FILES,
  useJsonSkills,
} from '@components/LearnosityLessonReview/LessonReviewSetup/hooks';
import { useCurrentSubject } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useGetSkillsBySubject } from '@apis/questiongenerationservice';
import { SkillRecord } from '@components/Component/Lesson/LessonContainer/Types';
import { getSkills, getSubjectsSkills } from '@apis/skills';
import { useQueries, useQuery } from 'react-query';

export interface CategorizedSkillRecord extends SkillRecord {
  label?: string;
  subjectName?: string;
  categoryName?: string;
}

export const useCategorizedSkills = (
  subject: { id: number | string; display_name?: string },
  skills: CategorizedSkillRecord[],
): CategorizedSkillRecord[] => {
  const { data: skillsMetadata = [] } = useJsonSkills(subject);
  return useMemo(
    () =>
      skills.map((skill) => {
        const metadata = skillsMetadata.find((m: any) => m.skillName === skill.name);
        const skillCategory = metadata ? metadata.categoryName : 'Others';
        return {
          ...skill,
          categoryName: skillCategory,
          subjectName: subject.display_name || '',
          label: `${skill.name}`,
        };
      }),
    [skills, subject, skillsMetadata],
  );
};

export const useSubjectSkills = (subject: {
  id: number | string;
  display_name?: string;
}): CategorizedSkillRecord[] => {
  const fallbackSubject = useCurrentSubject();
  const currentSubject = subject || fallbackSubject;
  const { data: skills = [] } = useGetSkillsBySubject(currentSubject.id.toString());
  return useCategorizedSkills(currentSubject, skills);
};

export const useGetSubjectsSkills = (subjects: number[]) => {
  const queryResponse = useQuery(
    ['useGetSubjectsSkills', subjects],
    async () => getSubjectsSkills(subjects),
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: subjects.length > 0,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};
