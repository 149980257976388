import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import PracticeTopNavBar from '../PracticeTopNavBar';
import ReviewQuestionContainers from './ReviewQuestionContainers';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ReportErrorModal from '../ReportErrorModal';

class PracticeReviewManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      reportProps: null,
      subscores: [],
      practiceName: '',
      isGetResult: false,
    };
  }

  componentDidMount() {
    const { match, practiceSessionReport } = this.props;
    const { sectionId, practiceSessionId } = match.params;
    practiceSessionReport(sectionId, practiceSessionId);
  }

  componentDidUpdate(prevProps) {
    const { match, practiceSessionReport, report } = this.props;
    const { match: prevMatch, report: prevReport } = prevProps;
    const { practiceSessionId, sectionId } = match.params;
    const { practiceSessionId: prevPracticeSessionId, sectionId: prevSectionId } =
      prevMatch.params;

    const scoreReport = _get(report, 'score.[0].report', {})?.url;
    const url1 = `https://chalktalk-react-app-dev.s3.amazonaws.com/${scoreReport}`;

    if (prevPracticeSessionId !== practiceSessionId || sectionId !== prevSectionId) {
      practiceSessionReport(sectionId, practiceSessionId);
    }

    if (prevReport !== report) {
      this.getAsync(url1);
      if (!this.state.isGetResult)
        return <LoadingIndicator content="Preparing your review" />;
    }
  }

  openReportErrorModal = (contentType, questionId) => {
    this.setState({
      isOpen: true,
      reportProps: {
        contentType,
        questionId,
      },
    });
  };

  async getAsync(url1) {
    const response = await fetch(url1);
    const data = await response.json();

    this.setState({
      subscores: data.subscores,
      practiceName: data.exam.name,
      isGetResult: true,
    });
    return data;
  }

  onCloseModal = () => {
    this.setState({
      isOpen: false,
      reportProps: null,
    });
  };

  submitError = (content) => {
    const { userReportIssue } = this.props;
    const { reportProps } = this.state;

    if (!reportProps) {
      return;
    }

    const { questionId, contentType } = reportProps;

    userReportIssue(questionId, contentType, content, this.onCloseModal);
  };

  redirectToPracticeDetailPage = () => {
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { isOpen } = this.state;
    const { report, isTakeReport, isPracticeNavigation } = this.props;

    if (isTakeReport) return <LoadingIndicator content="Preparing your review" />;

    if (this.state.subscores.length == 0)
      return <LoadingIndicator content="Preparing your review" />;

    if (!report) {
      return <div>Unable to get your review data. Please try again later!</div>;
    }

    return (
      <PracticeContainer>
        <PracticeTopNavBar
          goBackText="Go back"
          practiceName={this.state.practiceName}
          goBack={this.redirectToPracticeDetailPage}
        />
        <ReviewQuestionContainers
          questions={this.state.subscores}
          isPracticeNavigation={isPracticeNavigation}
          openReportErrorModal={this.openReportErrorModal}
        />
        {isOpen && (
          <ReportErrorModal
            isOpen={isOpen}
            onClose={this.onCloseModal}
            onSubmit={this.submitError}
          />
        )}
      </PracticeContainer>
    );
  }
}

PracticeReviewManagement.propTypes = {
  match: PropTypes.shape(),
  report: PropTypes.shape(),
  isTakeReport: PropTypes.bool,
  practiceSessionReport: PropTypes.func.isRequired,
  isPracticeNavigation: PropTypes.bool,
  history: PropTypes.any,
  userReportIssue: PropTypes.func,
};

export default PracticeReviewManagement;
