import { connect } from 'react-redux';
import CourseEditForm from '../../components/CourseDetail/CourseEditForm';
import { getCountryList, actions as CountryActions } from '../../reducers/countries';
import { getDistrictList, fetchDistrictList } from '../../reducers/districts';

const mapStateToProps = state => ({
  country: getCountryList(state),
  district: getDistrictList(state),
});

const mapDispatchToProps = {
  fetchDistrictList,
  fetchCountryList: CountryActions.fetchCountryList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseEditForm);
