import React from 'react';
import Box from '../Box';
import {
  informationAlertHeader,
  informationAlertPaper,
  informationAlertWrapper,
} from './InformationAlert.style';
import Paper from '../Paper';
import Typography from '../Typography';

type InformationAlertProps = {
  children: React.ReactNode | React.ReactNode[];
  title?: string;
};
const InformationAlert = ({ children, title = 'Information' }: InformationAlertProps) => (
  <Box sx={informationAlertWrapper}>
    <Paper sx={informationAlertPaper}>
      <Box sx={informationAlertHeader}>
        <span>!</span>
        <Typography variant="h4" component="p">
          {title}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Paper>
  </Box>
);

export default InformationAlert;
