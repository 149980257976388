/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import * as SessionsAPI from '../../../apis/sessions';
import { actions as SectionScheduleActions } from '../../../reducers/sectionSchedule';
import { weekDays, yearMonths } from '../../../utils/constants';
import Tooltip from '@mui/material/Tooltip';

import {
  EditModalTitle,
  MoveSeesionButton,
  LessonsDate,
  ScheduleActions,
  DatePickerWrap,
  ToggleSession,
  SaveCancelScheduleButtons,
  RescheduleButton,
} from '../../SubNavBarItem/SubNavBar.style';

const EditLessonModal = ({
  scheduleList,
  unitSelected,
  isTeacher,
  sectionScheduleGet,
  isEditModal,
  closeModal,
}) => {
  const { courseId, sectionId } = useParams();
  const history = useHistory();

  const [scheduLessonDate, setScheduLessonDate] = useState('');
  const [miniCalendarIsExpanded, setMiniCalendarIsExpanded] = useState(false);
  const [toggleSessionSelected, setToggleSessionSelected] = useState(1);
  const [itemRemoved, setItemRemoved] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [unitDate, setUnitDate] = useState('');

  const editModalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0px 0 20px 0',
      maxWidth: '980px',
      width: '520px',
      maxHeight: 'calc(100vh - 30px)',
      margin: '0 auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      border: 'none',
      padding: '30px',
      overflow: 'unset',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 999,
    },
  };

  useEffect(() => {
    if (unitSelected && isEditModal) {
      const findUnitFromSchedule = scheduleList.filter(
        (e) => e.lesson == unitSelected.lesson,
      );
      let unitSelectedDate = null;
      if (findUnitFromSchedule[0]) {
        unitSelectedDate = findUnitFromSchedule[0].date;
        setIsScheduled(true);
        setUnitDate(unitSelectedDate);
      }
      setScheduLessonDate(unitSelectedDate ? new Date(unitSelectedDate) : '');
    }
  }, [unitSelected?.lesson, isScheduled, isEditModal]);

  const onChangeDate = (date) => {
    setScheduLessonDate(date);
  };

  const convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? `0${s}` : s;
    }
    const d = new Date(inputFormat);
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
  };

  const onClose = () => {
    closeModal();
    setMiniCalendarIsExpanded(false);
    setItemRemoved(false);
    setToggleSessionSelected(1);
    setIsScheduled(false);
    setScheduLessonDate('');
    setUnitDate('');
  };

  const removeLesson = () => {
    setMiniCalendarIsExpanded(false);
    setItemRemoved(true);
    setScheduLessonDate('');
  };

  const expandedMiniCalendar = () => {
    let defaultDate = scheduLessonDate;
    if (defaultDate === '') defaultDate = new Date();
    setScheduLessonDate(defaultDate);
    setMiniCalendarIsExpanded(true);
    setItemRemoved(false);
  };

  const goToSession = () => {
    const { subject, unit, lesson: id } = unitSelected;
    let sessionUrl = '';
    if (unitSelected.lesson) {
      sessionUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subject}/unit/${unit}/lesson/${id}?tab=${LessonTabs.Lesson}`;
    } else {
      sessionUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subject}/unit/${unit}/practice`;
    }
    history.push(sessionUrl);
  };

  const saveLesson = async () => {
    const objScheduled = scheduleList.find((item) => item.lesson === unitSelected.lesson);
    if (itemRemoved) {
      await SessionsAPI.deleteSessionStandard(objScheduled.id);
    } else {
      const dateConverted = convertDate(scheduLessonDate);
      if (toggleSessionSelected == 2) {
        let days = 1;
        if (isScheduled) {
          const date1 = moment(unitDate);
          const date2 = moment(dateConverted);
          days = date2.diff(date1, 'days');
          const payload = {
            sectionId: sectionId,
            sessionDate: dateConverted,
            dayCount: days,
          };
          const { response, error } = await SessionsAPI.shiftSessions(
            objScheduled.id,
            days,
          );
          if (error) {
            errorToast('Error: Cannot update section schedule. Please try again later');
            return;
          }
        }
      }
      unitSelected.date = dateConverted;
      if (isScheduled) {
        await SessionsAPI.updateSessionStandard(objScheduled.id, unitSelected);
      } else {
        await SessionsAPI.addSessionStandard(unitSelected);
      }
    }
    sectionScheduleGet(sectionId);
    onClose();
  };

  const toggleSession = (actionSelected) => {
    setToggleSessionSelected(actionSelected);
  };

  if (!unitSelected) return null;

  let strDate = scheduLessonDate;
  if (scheduLessonDate != '') {
    strDate = `${weekDays[scheduLessonDate.getDay()]}, ${
      yearMonths[scheduLessonDate.getMonth()]
    } ${scheduLessonDate.getDate()} ${scheduLessonDate.getFullYear()}`;
  }

  return (
    <Modal
      isOpen={isEditModal}
      style={editModalStyles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      {isTeacher ? (
        <>
          <EditModalTitle isSubject>{unitSelected.subjectName}</EditModalTitle>
          <EditModalTitle>{unitSelected.name}</EditModalTitle>
          <MoveSeesionButton onClick={() => goToSession()}>
            Go to Session
          </MoveSeesionButton>
          <LessonsDate>{strDate}</LessonsDate>
          {!itemRemoved && isScheduled && !miniCalendarIsExpanded && (
            <ScheduleActions>
              <RescheduleButton marginRight onClick={() => expandedMiniCalendar()}>
                Reschedule
              </RescheduleButton>
              <RescheduleButton onClick={() => removeLesson()}>
                Remove from Schedule
              </RescheduleButton>
            </ScheduleActions>
          )}
          {(itemRemoved || (!isScheduled && !miniCalendarIsExpanded)) && (
            <ScheduleActions>
              <div className="hintMsg">Not Scheduled</div>
              <RescheduleButton onClick={() => expandedMiniCalendar()}>
                {' '}
                Add to Schedule
              </RescheduleButton>
            </ScheduleActions>
          )}
          {miniCalendarIsExpanded && (
            <>
              <DatePickerWrap>
                <DatePicker
                  selected={scheduLessonDate}
                  onChange={onChangeDate}
                  inline
                  minDate={new Date()}
                />
              </DatePickerWrap>
              {isScheduled && (
                <ScheduleActions>
                  <Tooltip
                    title="Schedule this session without affecting any other sessions"
                    placement="top"
                  >
                    <ToggleSession
                      marginRight
                      active={toggleSessionSelected == 1}
                      onClick={() => toggleSession(1)}
                    >
                      {' '}
                      This Session
                    </ToggleSession>
                  </Tooltip>
                  <Tooltip
                    title="Schedule this session and move following sessions later on the calendar"
                    placement="top"
                  >
                    <ToggleSession
                      active={toggleSessionSelected == 2}
                      onClick={() => toggleSession(2)}
                    >
                      Adjust Following Sessions
                    </ToggleSession>
                  </Tooltip>
                </ScheduleActions>
              )}
            </>
          )}
          <ScheduleActions right>
            <SaveCancelScheduleButtons cancel onClick={onClose}>
              Cancel
            </SaveCancelScheduleButtons>
            <SaveCancelScheduleButtons onClick={() => saveLesson()}>
              Save
            </SaveCancelScheduleButtons>
          </ScheduleActions>
        </>
      ) : (
        <>
          <EditModalTitle isSubject>{unitSelected.subjectName}</EditModalTitle>
          <EditModalTitle>{unitSelected.name}</EditModalTitle>
          <MoveSeesionButton onClick={() => goToSession()}>
            Go to Session
          </MoveSeesionButton>
          <LessonsDate>{strDate}</LessonsDate>
        </>
      )}
    </Modal>
  );
};

const mapDispatchToProps = {
  sectionScheduleGet: SectionScheduleActions.sectionScheduleGet,
};

export default connect(null, mapDispatchToProps)(EditLessonModal);
