import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const LoginContainerWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
`;

export const LoginItemWrap = styled.div`
  width: 100%;
`;

export const LoginItem = styled.div``;

export const LoginBlockLeft = styled.div`
  flex: 0 0 55%;
  background-color: #fff9ed;
  transition: all 300ms ease;
  img {
    height: 100%;
    width: 100%;
    object-position: left center;
  }
  @media (max-width: 960px) {
    flex: 1;
  }
`;

export const LoginBlockRight = styled.div`
  height:100%;
  overflow:auto;
  padding: 100px 60px;
  flex: 45;
  transition: all 300ms ease;
  .react-tabs {
    width: 100%;
    overflow: auto;
  }
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    width: fit-content;
    li {
      width: 135px;
      text-align: center;
      padding: 5px 0px;
      color: #b0aba8;
      border-bottom: 1px solid ${props => props.theme.colorStyled.ColorXBorder};
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colorStyled.ColorPrimary};
        border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBlack};
      }
    }
    .react-tabs__tab--selected {
      color: ${props => props.theme.colorStyled.ColorBgDefault};
      border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBgDefault};
      &:hover {
        color: ${props => props.theme.colorStyled.ColorBgDefaultHover};
        border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBgDefaultHover};
      }
    }
  }
  @media (max-width: 1170px) {
    padding: 80px 50px;
    height:90%;
  }
  @media (max-width: 960px) {
    position: absolute;
    width: calc(100% - 200px);
    margin: 0 auto;
    background: ${props => props.theme.colorStyled.ColorWhite};
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 10px -2px #d9d9d9;
    height:90%;
  }
  @media (max-width: 768px) {
    width: calc(100% - 60px);
    height:90%;
  }
  @media (max-width: 480px) {
    padding: 20px 25px;
    height:90%;
  }
`;

export const LoginTitle = styled.div`
  margin: 10px 0px 40px;
  h1 {
    font-size: 28px;
    line-height: 35px;
    color: ${props => props.theme.colorStyled.ColorPrimary};
    margin: 20px 0px 5px;
  }
  p {
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const ButtonStyled = styled.button`
  width: 100%;
  min-height: 50px;
  padding: 10px 130px;
  border-radius: 8.5px;
  color: black;
  background-color: #ddd;
  font-size: 18px;
  margin-bottom: 15px;
  outline: none;
  cursor: pointer;
  border: none;

  &:hover {
    background: #ccc;
  }

  ${({primary}) => !primary && css`
    background-color: #ff9e16;
    color: #ffffff;
    &:hover {
      background: #dc8800;
    }
  `} @media(max-width: 1440 px) {
  padding: 10px 60px;
}
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const StatusMessage = styled.p`
  width: calc(100% - 120px);
  max-width: 840px;
  margin: 35px auto;
  position: relative;
  background: ${props => props.theme.colorStyled.ColorWhite};
  padding: 10px;
  border: 2px solid ${props => props.theme.colorStyled.ColorSuccess};
  color: #1d1d1d;
  border-radius: 5px;
  font-size: 15px;
  text-align: center;
`;

export const LoginPasswordAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 30px;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }
  float:${props => props.left&&'left'};
  a {
    color: ${props => props.theme.colorStyled.ColorBgDefault};
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBgDefault};
    }
  }
  label {
    color: #938c88;
    position: relative;
    cursor: pointer;
    @media (max-width: 480px) {
      margin-bottom: 1em;
    }
  }
  span {
    font-size: 12px;
    margin-left: 25px;
  }
  .checkmark {
    position: absolute;
    top: 0px;
    left: -25px;
    height: 15px;
    width: 15px;
    background-color: ${props => props.theme.colorStyled.ColorWhite};
    border-radius: 3px;
    border: 1px solid #d3d3d3;
    &:after {
      content: '';
      position: absolute;
      display: none;
      width: 100%;
      height: 100%;
    }
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked {
      & ~ .checkmark {
        height: 15px;
        width: 15px;
        border-color: ${props => props.theme.colorStyled.ColorBgDefault};
        &:after {
          display: block;
          background-color: ${props => props.theme.colorStyled.ColorBgDefault};
        }
      }
    }
    &:hover {
      & > input {
        & ~ .checkmark {
          background-color: #ccc;
        }
      }
    }
  }
  ${({ mright }) => mright && css`
    margin-right: 20px;
  `};
`;

export const LoginLinkGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  a {
    height: 48px;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 15px;
    background-image: ${props => props.is_clever ? 'url(/images/clever.png)' : 'url(/images/classlink-logo.png)'};
    background-size: 6em;
    
    background-color: #bff0ff;
    display: block;
    width: 100%;
    &:hover {
      background-color: #9edaef;
    }
  }
`;


export const LoginErrorMessage = styled.div`
  color: ${props => props.theme.colorStyled.ColorWarning};
  margin-bottom: 20px;
  font-size: 14px;
  animation-name: ${BounceInput};
  animation-duration: 300ms;
  animation-delay: 250ms;
  p {
    margin-bottom: 5px;
  }
`;

export const LoginContentText = styled.div`
  margin-bottom: 30px;
  p {
    margin-bottom: 10px;
    line-height: 22px;
    span {
      margin-left: 5px;
      color: ${props => props.theme.colorStyled.ColorBgDefault};
      font-family: 'MontserratSemiBold';
    }
  }
`;
export const VerifyContentText = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  h2 {
    font-size: 28px;
    font-family: 'MontserratBold';
    line-height: 35px;
    color: ${props => props.theme.colorStyled.ColorPrimary};
    margin: 20px 0px 20px;
  }
  p {
    margin-bottom: 10px;
    line-height: 22px;
    span {
      margin-left: 5px;
      color: ${props => props.theme.colorStyled.ColorBgDefault};
      font-family: 'MontserratSemiBold';
      text-align: center;
    }
  }
`;
export const LoginButtonAction = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

export const LoginAction = styled.div``;

export const ImageWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40em;
  @media (max-width: 1170px) {
    font-size: 30em;
  }
`;

export const Image = styled.div`
  width: calc(100% - 60px);
  margin: 0 30px;
  text-align: center;
`;

export const Loading = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff52;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResetPasswordMessage = styled.p`
  margin-bottom: 35px;
`;

export const HelpText = styled.p`
  color: ${props => props.theme.colorStyled.ColorPrimary};
  padding-top: 20px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  width: calc(100% - 220px);
  padding: 40px;
  min-height: 300px;
  max-width: 680px;
  margin: 0 auto;
  background: #ffffffe8;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%,-50%);
  box-shadow: 0px 0px 10px -2px #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25em;
  border-radius: 10px;
`;
