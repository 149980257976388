/* eslint-disable sort-keys */
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useCourseById } from '@reducers/courses/hooks';
import { useUser } from '@reducers/user/hooks';
import { actions as curriculumLessonActions } from '@reducers/curriculumLessons';
import TooltipDisabledButton from '@components/Generals/TooltipDisabledButton';
import { AuthorHarness } from '@learnosityassessment';
import Box from '@components/Atoms/Box';
import Button from '@components/Atoms/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@components/Atoms/Typography';
import IconWrapper from '@components/Atoms/IconWrapper';
import ShowIf from '@components/Atoms/ShowIf';

import Skeleton from '@components/Atoms/Skeleton';
import { toast } from 'react-toastify';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';

import ScrollWrapper from '@components/Atoms/ScrollWrapper';

import { useLessonSkills } from '@components/Component/Lesson/LessonContainer/hooks';
import { useQueryClient } from 'react-query';
import PreviewQuestions from '@components/LearnosityLessonReview/LessonReviewSetup/PreviewQuestions';

import QuestionSelectorProvider, {
  useSelectedQuestions,
  useAddQuestion,
  useRemoveQuestion,
  useShowPreview,
} from './QuestionSelector.provider';
import {
  useGetLearnosityDistrictQuestions,
  usePostLearnosityDistrictQuestions,
} from './hooks';

export const useQuestionSelectorHelper = ({ widget, showPreview = false }) => {
  const { selectedQuestions } = useSelectedQuestions();
  const addQuestion = useAddQuestion();
  const removeQuestion = useRemoveQuestion();

  useEffect(() => {
    if (showPreview) {
      return;
    }
    const checkSelectedQuestions = async () => {
      const items = document.querySelectorAll('.lrn-list-view-alternative-reference');
      items.forEach((element) => {
        if (!element) {
          return;
        }
        const holder =
          element?.parentElement?.parentElement?.parentElement?.parentElement
            ?.previousElementSibling;
        const checkInput = holder?.querySelectorAll(
          '[data-authorapi-selector="select-item-input"]',
        )[0] as HTMLInputElement;
        const checkWrapper = holder?.getElementsByClassName(
          'lrn-i-tick lrn-check-box-span',
        )[0];
        if (!checkInput || !checkWrapper) {
          return;
        }
        const itemId = element?.textContent?.trim();
        if (!itemId) {
          return;
        }
        if (selectedQuestions.includes(itemId)) {
          checkWrapper.dataset.itemSelected = true;
          checkInput.checked = true;
        } else {
          checkWrapper.dataset.itemSelected = false;
          checkInput.checked = false;
        }
        checkWrapper?.addEventListener('click', () => {
          if (checkWrapper.dataset.itemSelected === 'false') {
            checkWrapper.dataset.itemSelected = true;
            addQuestion(itemId);
          } else {
            checkWrapper.dataset.itemSelected = false;
            removeQuestion(itemId);
          }
        });
      });
    };

    if (widget) {
      const currentWidgetRef = widget;
      currentWidgetRef.on('render:itemlist', checkSelectedQuestions);

      return () => {
        currentWidgetRef.off('render:itemlist', checkSelectedQuestions);
      };
    }
  }, [selectedQuestions, addQuestion, removeQuestion, widget, showPreview]);
};

const QuestionSelector = () => {
  const queryClient = useQueryClient();
  const { courseId, lessonId, sectionId, subjectId, unitId } = useParams();
  const history = useHistory();
  const goToPracticeTab = () =>
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}?tab=${LessonTabs.LearnosityAdaptivePractice}`,
    );
  const [showPreview, setShowPreview] = useShowPreview();
  const dispatch = useDispatch();
  const [widget, setWidget] = useState(null);
  const mutatePostLearnosityDistrictQuestions = usePostLearnosityDistrictQuestions();
  const { selectedQuestions } = useSelectedQuestions();
  const course = useCourseById(courseId);
  const districtId = course?.organization?.district?.id;
  const user = useUser();
  const dataAvailable = !!user && Object.keys(course).length;
  const questionSelectionConditionMet = selectedQuestions.length >= 5;
  const lessonSkills = useLessonSkills();

  const handleAction = () => {
    if (!showPreview) {
      setShowPreview(true);
      return;
    }

    const payload = {
      references: selectedQuestions,
      district: districtId,
      lesson: +lessonId,
    };
    mutatePostLearnosityDistrictQuestions.mutate(payload, {
      onSuccess: () => {
        toast.success('Questions saved successfully');
        queryClient.invalidateQueries([
          'getLearnosityDistrictQuestions',
          lessonId,
          districtId,
        ]);
        // in order to make sure that redirect to the practice tab happen after toast message appear.
        setTimeout(() => {
          goToPracticeTab();
        }, 1000);
      },
      onError: (error: any) => {
        if (error?.response?.status === 403) {
          toast.error("you don't have permission to execute this action");
        } else {
          toast.error('Something went wrong');
        }
      },
    });
  };

  useEffect(() => {
    dispatch(curriculumLessonActions.curriculumLessonsGetDetail(lessonId));
    dispatch(
      curriculumLessonActions.curriculumLessonsGetStandard(lessonId, course.syllabus),
    );
  }, []);

  useQuestionSelectorHelper({ widget, showPreview });

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ScrollWrapper>
        <Typography variant="h3" sx={{ p: '1rem', py: '0' }}>
          Question Selector
        </Typography>
        <Typography variant="subtitle1" sx={{ pb: '1rem', paddingInlineStart: '1.1rem' }}>
          You can add and remove filters from the column header to select any questions
          from this subject
        </Typography>
        <ShowIf If={!showPreview && !!dataAvailable}>
          <AuthorHarness
            user={{
              id: `${user.id}`,
              district_id: `${districtId}`,
            }}
            setWidget={setWidget}
            skills={lessonSkills}
          />
        </ShowIf>
        <ShowIf If={showPreview}>
          <PreviewQuestions />
        </ShowIf>
      </ScrollWrapper>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: 1,
          px: 2.5,
          paddingInlineEnd: 15,
          zIndex: 4,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <IconWrapper size="sm">
            <i
              className={
                questionSelectionConditionMet ? 'chalktalk-correct' : 'chalktalk-error'
              }
            />
          </IconWrapper>
          <Typography>{selectedQuestions.length} Selected Questions</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={1} py={1}>
          <Button
            variant="text"
            onClick={() => {
              if (showPreview) {
                setShowPreview(false);
                return;
              }
              goToPracticeTab();
            }}
          >
            {showPreview ? 'Back' : 'Cancel'}
          </Button>
          <TooltipDisabledButton
            title="Please select at least 5 questions to save."
            disabled={questionSelectionConditionMet}
          >
            <Button
              color="primary"
              variant="contained"
              disabled={!questionSelectionConditionMet}
              endIcon={
                mutatePostLearnosityDistrictQuestions.isLoading ? (
                  <CircularProgress size={20} sx={{ color: 'white' }} />
                ) : null
              }
              onClick={() => handleAction()}
            >
              {showPreview ? 'Save Changes' : 'Preview'}
            </Button>
          </TooltipDisabledButton>
        </Box>
      </Box>
    </Box>
  );
};

const QuerySelectedQuestions = ({ children }) => {
  const { data } = useGetLearnosityDistrictQuestions();
  return children;
};

const QuestionSelectorWithProvider = () => (
  <Suspense
    fallback={
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Skeleton sx={{ transform: 'unset', margin: '1rem' }} height="80%" width="100%" />
      </Box>
    }
  >
    <QuerySelectedQuestions>
      <QuestionSelectorProvider>
        <QuestionSelector />
      </QuestionSelectorProvider>
    </QuerySelectedQuestions>
  </Suspense>
);
export default QuestionSelectorWithProvider;
