/* eslint-disable eqeqeq */
import { captureMessage } from '@sentry/react';
import { downloadBlob, getDownloadBlob } from './downloadFile';
import printPdfBlob from './printPdfBlob';
import { initializeGAPI, iSGAPIInitialized } from './initializeGAPI';

export const isGoogleDomain = (url) => url?.includes('.google.com');
const getId = (url) => url?.match(/[-\w]{25,}/)?.shift();
const tryBinaryExport = async (id) => {
  const data = await window.gapi.client.drive.files
    .get({
      fileId: id,
      alt: 'media',
    })
    .then((res) => {
      if (res.status == 200) {
        return res;
      }
    });
  return data;
};
const tryMediaExport = async (id) => {
  const data = await window.gapi.client.drive.files

    .export({
      fileId: id,
      mimeType: 'application/pdf',
    })
    .then((res) => {
      if (res.status == 200) {
        return res;
      }
    });
  return data;
};
const getFileInfo = async (id) => {
  const responseData = await window.gapi.client.drive.files
    .get({
      fileId: id,
    })
    .then((response) => {
      const res = JSON.parse(response.body);
      return res;
    })
    .catch((err) => {
      console.error('getFileInfo error ->', err);
    });

  return { ...responseData, fileName: responseData?.title || responseData?.name };
};
const getFileData = async (src) => {
  if (!src || !isGoogleDomain(src) || !window?.gapi?.client) {
    captureMessage('Tried to access google api incorrectly.');
    return;
  }

  if (!iSGAPIInitialized()) {
    await initializeGAPI();
  }

  const id = getId(src);
  try {
    const { mimeType: type, fileName } = await getFileInfo(id);
    let data;
    if (type == 'application/pdf') {
      data = await tryBinaryExport(id);
    } else {
      data = await tryMediaExport(id);
    }
    if (data) {
      const blob = getDownloadBlob(data);
      return { blob, fileName };
    }
  } catch (e) {
    console.error(e);
  }
};

export const downloadGoogleFile = async (src) => {
  const { blob, fileName } = await getFileData(src);
  if (blob) {
    downloadBlob(blob, fileName);
  }
};
const printGoogleFile = async (src) => {
  const { blob } = await getFileData(src);
  if (blob) {
    printPdfBlob(blob);
  }
};
export default printGoogleFile;
