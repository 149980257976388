import React from 'react';
import { Option } from '../CustomDropdown';
import { BaseSelector, BaseSelectorProps } from '../BaseSelector';

export type FleschReadabilityProps = Omit<BaseSelectorProps, 'label' | 'options'>;

export const FLESCH_READABILITY_LIST = [
  'Very Easy to Read',
  'Easy to Read',
  'Fairly Easy to Read',
  'Plain English',
  'Fairly Difficult to Read',
  'Difficult to Read',
  'Very Difficult to Read',
];

const FLESCH_READABILITY: Option[] = FLESCH_READABILITY_LIST.map((readability) => ({
  label: readability,
  value: readability,
}));

const optionLabel = (option: Option) => option.label;

const selectedLabel = (selected: Option) => `${selected.label}`;

export const FleschReadability: React.FC<FleschReadabilityProps> = (props) => (
  <BaseSelector
    {...props}
    label="Flesch Readability"
    options={FLESCH_READABILITY}
    optionLabel={optionLabel}
    selectedLabel={selectedLabel}
  />
);

export default FleschReadability;
