import React, { isValidElement, ReactNode, ReactElement } from "react";

/**
 * Checks if a React element has children in its props
 * @param element - The React element to check
 * @returns boolean indicating whether the element has children
 */
export const hasChildren = (element: React.ReactElement): boolean =>
    isValidElement(element) && Boolean((element as { props: { children: unknown } }).props.children);

/**
 * Converts a child element to a string representation
 * @param child - The child element to convert
 * @returns String representation of the child, or empty string for undefined/null/boolean values
 */
export const childToString = (child: any): string => {
    if (
        typeof child === "undefined" ||
        child === null ||
        typeof child === "boolean"
    ) {
        return "";
    }

    if (JSON.stringify(child) === "{}") {
        return "";
    }

    return child.toString();
};

/**
 * Recursively extracts text content from React nodes
 * @param children - React node or array of nodes to process
 * @returns Concatenated string of all text content
 */
export const onlyText = (children: ReactNode): string => {
    if (!(children instanceof Array) && !isValidElement(children)) {
        return childToString(children);
    }

    const reducer = (text: string, child: ReactNode): string => {
        let newText = "";

        if (isValidElement(child) && hasChildren(child as ReactElement)) {
            newText = onlyText((child as ReactElement).props.children);
        } else if (isValidElement(child)) {
            newText = "";
        } else {
            newText = childToString(child);
        }

        return text.concat(newText);
    };

    return React.Children.toArray(children).reduce(reducer, "") as string;
};
