import { connect } from 'react-redux';
import CourseSetupForm from '../../components/TeacherRegisterForm/CourseSetupForm';
import {
  actions as CurriculumActions,
  selectors as CurriculumSelectors,
} from '../../reducers/curriculums';
import { getCurrentUser, getSubscriptionData } from '../../reducers/user';
import { getCountryList, actions as CountryActions } from '../../reducers/countries';
import { getDistrictList, fetchDistrictList } from '../../reducers/districts';
import { createRegCourse } from '../../reducers/register';

const mapStateToProps = state => ({
  country: getCountryList(state),
  district: getDistrictList(state),
  errorMessage: null, // TODO
  teacherDetail: getCurrentUser(state),
  subscriptionData: getSubscriptionData(state),
  standard: CurriculumSelectors.getStandardList(state),
  curriculum: CurriculumSelectors.getAllCurriculums(state),
  placementTypesObj: CurriculumSelectors.getPlacementTypesObj(state),
  exitTypesObj: CurriculumSelectors.getExitTypesObj(state),
});

const mapDispatchToProps = {
  fetchDistrictList,
  createRegCourse,
  fetchCountryList: CountryActions.fetchCountryList,
  getCurriculumList: CurriculumActions.getCurriculumList,
  getCurriculumStandardList: CurriculumActions.getCurriculumStandardList,
  getCurriculumPlacementType: CurriculumActions.getCurriculumPlacementType,
  getCurriculumExitType: CurriculumActions.getCurriculumExitType,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseSetupForm);
