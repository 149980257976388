/*  eslint radix: ["error", "as-needed"] */
/*  eslint camelcase: [0, {properties: "never"}] */
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _forEach from 'lodash/forEach';
import _flatMap from 'lodash/flatMap';
import _reduce from 'lodash/reduce';
import moment from 'moment';
import { SHARED_ASSET_TYPE } from './enums';
import { DATE_FORMAT } from './constants';

const convertMinutesToTestDuration = (minutes, short) => {
  const hours = Math.floor(minutes / 60);
  const remainMinutes = minutes - hours * 60;
  const hourText = short ? 'HR' : hours > 1 ? 'HOURS' : 'HOUR';
  const remainMinuteText = short ? 'MIN' : minutes > 1 ? 'MINUTES' : 'MINUTE';

  if (hours > 0 && remainMinutes > 0) {
    return `${hours} ${hourText} ${remainMinutes} ${remainMinuteText}`;
  }
  if (hours > 0) {
    return `${hours} ${hourText}`;
  }

  return `${remainMinutes} ${remainMinuteText}`;
};

export const convertSecondsToDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainSeconds = seconds - minutes * 60;

  if (remainSeconds >= 10) {
    return `${minutes}:${remainSeconds} min`;
  }
  return `${minutes}:0${remainSeconds} min`;
};

const capitalizeFirstLetter = (sentences) =>
  sentences
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');

export const getStudentNameById = (studentById = {}, id) =>
  (studentById[id] || {}).name || '';

export const getSelectedStudentData = (
  studentData = {},
  selectedStudents,
  studentById = {},
) => {
  const selectedStudentData = {};
  _forEach(selectedStudents, (id) => {
    selectedStudentData[id] = {
      ...(studentData[id] || {}),
      name: getStudentNameById(studentById, id),
    };
  });
  return selectedStudentData;
};

const calculateAverageScoreFromStudentData = (studentData) => {
  const studentDataArray = Object.values(studentData).filter((item) => item !== null);
  const numberOfStudent = studentDataArray.length;
  const totalScore = _reduce(
    studentData,
    (memo, { score, max_score }) => ({
      score: memo.score + score,
      max_score: memo.max_Score + max_score,
      student_count: memo.score != undefined ? memo.count + 1 : memo.count, // eslint-disable-line
    }),
    {
      score: 0,
      max_score: 0,
      count: 1,
    },
  );
  return {
    score: Math.round(totalScore.score / totalScore.student_count),
    max_Score: Math.round(totalScore.max_score / numberOfStudent),
  };
};

const calculateAverage = (
  studentData,
  selectedStudent = [],
  addOverallPrefix = false,
) => {
  const selectedStudentData = getSelectedStudentData(studentData, selectedStudent);
  const studentDataArray = Object.values(selectedStudentData).filter(
    (item) => item !== null && item !== undefined,
  );

  if (studentData) {
    // calculate total score
    const totalScore = _reduce(
      studentDataArray,
      (memo, value = {}) => {
        const tempMemo = { ...memo };
        _forEach(Object.keys(value), (row) => {
          if (row) {
            if (!memo[row]) {
              if (value[row] != undefined) {
                tempMemo[row] = [value[row], 1];
              }
            } else {
              tempMemo[row][0] += value[row];
              tempMemo[row][1] += value[row] != undefined ? 1 : 0;
            }
          }
        });
        return tempMemo;
      },
      {},
    );
    // calculate average score
    _forEach(Object.keys(totalScore), (row) => {
      if (totalScore[row]) {
        totalScore[row] = totalScore[row][0] / totalScore[row][1];
      } else {
        totalScore[row] = 0;
      }
    });
    // add overall_ prefix
    if (addOverallPrefix) {
      const refactorAverageScore = {};
      _forEach(Object.keys(totalScore), (key) => {
        if (key) {
          refactorAverageScore[`overall_${key}`] = totalScore[`${key}`];
        }
      });
      return refactorAverageScore;
    }
    return totalScore;
  }

  return {};
};

const LINE_COUNT_TEMPLATE = (lineCount, isHidden = false) => {
  const newItem = document.createElement('SPAN');
  newItem.classList.add('line-no');
  newItem.setAttribute('data-event-id', 'Asset Line');
  if (isHidden) newItem.setAttribute('hidden', true);
  const textnode = document.createTextNode(lineCount);
  newItem.appendChild(textnode);
  return newItem;
};

export function countLines(element, lineCount) {
  let myLineCount = lineCount;
  let lastNodeText = false;
  let node;

  const nodelist = [];
  for (let i = 0; i < element.childNodes.length; i += 1) {
    nodelist[i] = element.childNodes[i];
  }
  for (let i = 0; i < nodelist.length; i += 1) {
    node = nodelist[i];
    if (node && node instanceof Element) {
      if (node.tagName === 'BR') {
        myLineCount += 1;
        if (myLineCount % 5 === 0) {
          node.parentNode.insertBefore(LINE_COUNT_TEMPLATE(myLineCount), node);
        } else {
          node.parentNode.insertBefore(LINE_COUNT_TEMPLATE(myLineCount, true), node);
        }
      } else {
        myLineCount = countLines(node, myLineCount);
      }
    }
    lastNodeText = node instanceof Text && /\S/.test(node.wholeText);
  }
  const styles = window.getComputedStyle(element);
  const is_block = window.getComputedStyle(element).display === 'block';
  if (is_block && myLineCount > lineCount && lastNodeText) {
    myLineCount += 1;
    if (myLineCount % 5 === 0) {
      element.appendChild(LINE_COUNT_TEMPLATE(myLineCount));
    }
  }

  return myLineCount;
}

function sharedAssetsStandardize(htmlText, type) {
  let html = htmlText;
  if (html) {
    if (
      type.toUpperCase() === SHARED_ASSET_TYPE.GRAPH.toUpperCase() ||
      type.toUpperCase() === SHARED_ASSET_TYPE.TABLE.toUpperCase() ||
      type.toUpperCase() === SHARED_ASSET_TYPE.PASSAGE.toUpperCase() ||
      type.toUpperCase() === SHARED_ASSET_TYPE.INSTRUCTIONS.toUpperCase() ||
      type.toUpperCase() === SHARED_ASSET_TYPE.GRAMMAR_PASSAGE.toUpperCase()
    ) {
      html = html.replace(/<p>/g, "<p data-event-id='Shared Asset Text'>");
    } else if (type.toUpperCase() === SHARED_ASSET_TYPE.PROMPT.toUpperCase()) {
      html = html.replace(/<p>/g, "<p data-event-id='Prompt Asset Text'>");
    } else if (
      type.toUpperCase() === SHARED_ASSET_TYPE.CHOICE.toUpperCase() ||
      type === SHARED_ASSET_TYPE.CORRECT_CHOICE.toUpperCase()
    ) {
      html = html.replace(/<p>/g, "<p data-event-id='Choice Asset Text'>");
    }
  }

  // handling numbering and format within a grammar/writing passage.
  if (type.toUpperCase() === SHARED_ASSET_TYPE.GRAMMAR_PASSAGE.toUpperCase()) {
    html = html.replace(
      /<strong>\[\[\[/g,
      '<strong style="background: gray; padding: 0px 8px 0px 8px; color: white;">',
    );
    html = html.replace(/\]\]\]\&nbsp;<\/strong>/g, '</strong>&nbsp;');
    html = html.replace(/\]\]\]<\/strong>/g, '</strong>');
  }

  return html;
}

export const capitalLetter = (str) => {
  const stringArr = str.replace('  ', ' ').split(' ');
  for (let i = 0, x = stringArr.length; i < x; i += 1) {
    stringArr[i] = stringArr[i][0].toUpperCase() + stringArr[i].substr(1);
  }
  return stringArr.join(' ');
};

export function* handleErrorPage({ statusCode }) {
  yield put(push(`/error/${statusCode || 404}`));
}

export const eventTracking = (event, properties) => {
  if (!DEBUG) {
    const courseTypes = JSON.parse(localStorage.courseType);
    const allProperties = {
      ...properties,
      'Course Type': courseTypes[properties['Course Id']],
    };
    analytics.track(event, allProperties);
  }
};

export const compareFunction = (nameObj1, nameObj2) => {
  if (nameObj1.firstName > nameObj2.firstName) {
    return 1;
  }
  if (nameObj1.firstName < nameObj2.firstName) {
    return -1;
  }
  if (nameObj1.lastName < nameObj1.lastName) {
    return -1;
  }
  if (nameObj1.lastName > nameObj1.lastName) {
    return 1;
  }
  return 0;
};

export const toNameObj = (fullName) => {
  const nameArr = fullName.split(/\s+/);
  const firstName = nameArr.slice(0, -1)[0];
  const lastName = nameArr.pop();
  return { firstName, lastName };
};

export const updateDefaultClassData = (courseId, classId, topicId) => {
  const defaultClassData = localStorage.defaultClassData
    ? JSON.parse(localStorage.defaultClassData)
    : {};
  const updatedDefaultClassData = {
    ...defaultClassData,
    [courseId]: { courseId, classId, topicId },
  };
  localStorage.defaultClassData = JSON.stringify(updatedDefaultClassData);
};

export const convertSeccondToTimeStr = (sec, format) =>
  moment.utc(moment.duration(sec, 's').asMilliseconds()).format(format);

export const getCourseId = (path, isInCourses) => {
  const courseIndex = isInCourses ? path.indexOf('/courses') : path.indexOf('/course');
  const courseIdIndex = isInCourses ? courseIndex + 9 : courseIndex + 8;
  const nextSplashAfterCourseIdIndex = path.indexOf('/', courseIdIndex);
  const courseId =
    nextSplashAfterCourseIdIndex !== -1
      ? path.substring(courseIdIndex, nextSplashAfterCourseIdIndex)
      : path.substring(courseIdIndex, path.length);
  return courseId ? parseInt(courseId) : null;
};

export const isCourses = (path) => path.indexOf('/courses') !== -1;

export const getCustomName = (courseId) => {
  const customNameData = localStorage.customNameData
    ? JSON.parse(localStorage.customNameData)
    : {};
  return customNameData[courseId] || null;
};

export const updateCustomNameData = (customName, courseId) => {
  const customNameData = localStorage.customNameData
    ? JSON.parse(localStorage.customNameData)
    : {};
  const updatedCustomNameData = {
    ...customNameData,
    [courseId]: customName,
  };
  localStorage.customNameData = JSON.stringify(updatedCustomNameData);
};

// ! Need refactor
export const calculateSum = (activityList, students) => {
  const scores = activityList.map((x) => x.score);
  const studentList = [...students];
  const result = studentList.map((student) => {
    let sumAuto = 0;
    let sumAutoMax = 0;
    let lastActivity = 0;
    let statusScore = 0;
    let tot = 0;
    let hasResult = false;

    scores.forEach((score) => {
      const data = score.find((x) => x.user === student.id);
      if (data && data.score) {
        sumAuto += data.score.auto;
        sumAutoMax += data.score.auto_max;
        statusScore = data.score.status;
        hasResult = true;
      }
    });

    if (!hasResult) {
      return { id: student.id };
    }

    const info = activityList.find((x) => x.user === student.id);

    if (info) {
      lastActivity = info.ended
        ? moment(info.ended).format('MM/DD/YYYY HH:mm')
        : moment(info.started).format('MM/DD/YYYY HH:mm');
      tot = info.ended ? moment(info.ended).diff(moment(info.started), 'minute') : 0;
    }

    return {
      first_name: student.first_name,
      id: student.id,
      last_name: student.last_name,
      sumAuto,
      sumAutoMax,
      lastActivity,
      timeOnTask: tot,
      status: statusScore,
    };
  });
  return result;
};

export const calcDurationToMin = (totalSeconds) =>
  moment.utc(totalSeconds * 1000).format('HH:mm');
export const calcDurationToSecond = (totalSeconds) =>
  moment.utc(totalSeconds * 1000).format('HH:mm:ss');

export const getBeginAndEndDate = (date) => ({
  // date is moment obj
  beginDate: date.startOf('week').format(DATE_FORMAT.DATE),
  endDate: date.endOf('week').format(DATE_FORMAT.DATE),
});

export const getStudentSubmissionCount = (scoring = []) => {
  let numberOfSubmissions = 0;
  const students = _flatMap(scoring, (group) => group.students);
  _forEach(students, (student) => {
    if (student.status === 3) {
      numberOfSubmissions++;
    }
  });
  return numberOfSubmissions;
};

export const doesArrayHaveElarSubject = (subjects = []) => {
  return subjects.some((subject) => ['Writing', 'Reading', 'English'].includes(subject));
};

export const getSearchParamsObject = (search) => {
  const urlSearchParams = new URLSearchParams(search);
  const searchParamsObj = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of urlSearchParams.entries()) {
    searchParamsObj[key] = value;
  }
  return searchParamsObj;
};

export const getFormattedDate = (dateStr) => {
  const dateObj = new Date(dateStr);

  // Extract components
  const year = dateObj.getFullYear().toString().slice(-2); // Get last 2 digits of the year
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(dateObj.getDate()).padStart(2, '0');
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  // Format the date and time
  const formattedDateTime = `${month}-${day}-20${year} ${hours}:${minutes}`;
  return formattedDateTime;
};
export default {
  convertMinutesToTestDuration,
  convertSecondsToDuration,
  capitalizeFirstLetter,
  getStudentNameById,
  getSelectedStudentData,
  calculateAverageScoreFromStudentData,
  calculateAverage,
  sharedAssetsStandardize,
  countLines,
  capitalLetter,
  handleErrorPage,
  eventTracking,
  compareFunction,
  calculateSum,
  doesArrayHaveElarSubject,
};
