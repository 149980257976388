/* eslint-disable radix */
import _get from 'lodash/get';

// action definition
export const ADMIN_REPORTS_FETCH_URL = 'adminReports/ADMIN_REPORTS_FETCH_URL';
export const ADMIN_REPORTS_FETCH_URL_SUCCESS = 'adminReports/ADMIN_REPORTS_FETCH_URL_SUCCESS';
export const ADMIN_REPORTS_FETCH_URL_FAILED = 'adminReports/ADMIN_REPORTS_FETCH_URL_FAILED';

// selectors
export const getAdminReportsUrl = ({ adminReports }) => adminReports.adminReportsUrl;

export const selectors = {
    getAdminReportsUrl,
};

// action creators
export const fetchAdminReportsUrl = () => ({
  type: ADMIN_REPORTS_FETCH_URL,
});

export const fetchAdminReportsUrlSuccess = adminReportsUrl => ({
  type: ADMIN_REPORTS_FETCH_URL_SUCCESS,
  payload: {
    adminReportsUrl,
  },
});

export const fetchAdminReportsUrlFailed = errorMsg => ({
  type: ADMIN_REPORTS_FETCH_URL_FAILED,
  payload: {
    errorMsg,
  },
});

export const actions = {
  fetchAdminReportsUrl,
  fetchAdminReportsUrlSuccess,
  fetchAdminReportsUrlFailed,
};

// reducers

const initialState = {
  adminReportsUrl: null,
  isLoading: null,
  errorMsg: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADMIN_REPORTS_FETCH_URL: {
      return {
        ...state,
        isLoading: true,
        errorMsg: false,
      };
    }

    case ADMIN_REPORTS_FETCH_URL_SUCCESS: {
      const { adminReportsUrl } = action.payload;

      return {
        ...state,
        isLoading: false,
        adminReportsUrl,
      };
    }

    case ADMIN_REPORTS_FETCH_URL_FAILED: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMsg,
      };
    }

    default: {
      return state;
    }
  }
}