/**
 * Created by vienpn on 26/12/17.
 */
import styled, { css } from 'styled-components';
import { ActionShowHide } from '../../LeftSideBar/LeftSiderBar.style';

export const ButtonStyled = styled.button``;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  outline: none;
  background: ${(props) => props.theme.colorStyled.ColorBtnDark};
  border-radius: ${(props) => props.theme.borderRadius.borderbtn};
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
  font-family: 'MontserratMedium';
  border: none;
  padding: .575em 1em;
  height: 2.5em;
  min-width: 6em;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background-color:  ${(props) => props.theme.colorStyled.ColorBtnHover};
  };

  ${({ blocked }) => blocked && css`
    cursor: not-allowed;
  `}

  ${({ autoHeight }) => autoHeight && css`
    height: auto;
  `}

  ${({ full }) => full && css`
    width: 100%;
    i {
      margin-left: 0.5em;
    }
  `};

  ${({ small }) => small && css`
    margin: 0 auto;
    i {
      margin-left: 0.5em;
    }
  `};

  ${({ subSmall }) => subSmall && css`
    padding: 0.575em;
  `};
  
  ${({ darkBackground }) => darkBackground && css`
    background: ${(props) => props.theme.colorStyled.ColorBtnDark};
    &:hover {
      background: ${(props) => props.theme.colorStyled.ColorBtnDarkHover}
    }
  `}

  ${({ isDisable }) => isDisable && css`
    opacity: 0.6;
    cursor: not-allowed;
  `}

  ${({ submit }) => submit && css`
    background: ${(props) => props.theme.colorStyled.ColorBgDefault};
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    &:hover {
      background: ${(props) => props.theme.colorStyled.ColorBgDefault};
      opacity: .7;
    }
  `}
  
  ${({ green }) => green && css`
    background: ${(props) => props.theme.colorStyled.ColorPrimaryGreen};
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    &:hover {
      background: ${(props) => props.theme.colorStyled.ColorPrimaryGreen};
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    }
  `}
  
   ${({ blue }) => blue && css`
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    &:hover {
      background: ${(props) => props.theme.colorStyled.ColorPrimaryBlueHover};
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    }
    ${(props) => !props.disabled ? css`
      &:hover {
        background: ${(props) => props.theme.colorStyled.ColorPrimaryBlueHover};
      }
      background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    ` : css`
      &:hover {
        background: ${(props) => props.theme.colorStyled.ColorBtnDark};
      }
      background: ${(props) => props.theme.colorStyled.ColorBtnDark};
      color: ${(props) => props.theme.colorStyled.ColorGray};
    `}
  `}

  ${({ alignRight }) => alignRight && css`
    margin-left: auto;
  `}

  ${({ iconWidth }) => iconWidth && css`
    min-width: unset;
    height: auto;
  `}

  ${({ textUppercase }) => textUppercase && css`
    text-transform: uppercase;
  `}

  ${({ submitForm }) => submitForm && css`
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border: 1px solid ${(props) => props.theme.colorStyled.ColorBgDefault};
    background-color: ${(props) => props.theme.colorStyled.ColorBgDefault};
    box-shadow: 0px 4px 6.5px 0 #ff9e1630;
    font-family: 'MontserratMedium';
    font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
    &:hover {
      color: ${(props) => props.theme.colorStyled.ColorWhite};
      border-color: #a3a0a0;
      background-color: #a3a0a0;
      box-shadow: 0 0 10.5px 0 rgba(0, 0, 0, 0.06);
    }
  `}

  ${({ hide }) => hide && css`
      display: none;
  `}
  
  ${({ hideOnMobile }) => hideOnMobile && css`
    @media (max-width: 768px) {
      display: none;
    }
  `}
  
  ${({ hideOnDesktop }) => hideOnDesktop && css`
    max-width: unset;
    @media (min-width: 768px) {
      display: none;
    }
  `}
  
  ${({ mobile }) => mobile && css`
    border-radius: 0;
    padding: 1em 1.575em;
    height: auto;
    max-height: unset;
  `}

  ${({ mobDesk }) => mobDesk && css`
    @media (max-width: 768px) {
      border-radius: 0;
      padding: 1em 1.575em;
      height: auto;
      max-height: unset;
    }
  `}
  ${({ marginLeft }) => marginLeft && css`
    margin-left: 1em;
  `}
  ${({activity})=> activity==="online-group"&& css`
  span {
    height: 5px;
    width: 5px;
  }
  `}
  ${({ nowrap }) =>
    nowrap &&
    css`
      white-space: nowrap;
      min-width: fit-content;
    `}
  
  ${({ outlined }) =>
    outlined &&
    css`
      background: transparent;
      border: 1px solid
        ${(props) => props.theme.colorStyled[props.color || 'ColorPrimaryBlue']};
      color: ${(props) => props.theme.colorStyled[props.color || 'ColorPrimaryBlue']};
    `}
  ${({round}) =>
  round && 
  css`
    border-radius: 11px;
    min-width: 7.7em;
    `}
`;

export const ButtonCustome = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  outline: none;
  padding: .575em .25em;
  cursor: pointer;
  transition: all 300ms ease;
  background: transparent;
  border-radius: .325em;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  border: 1px solid ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  &:hover {
    opacity: .7;
  }
  ${({ red }) => red && css`
    color: ${(props) => props.theme.colorStyled.ColorRedDel};
  `}
  ${({ darkBackground }) => darkBackground && css`
    font-family: 'MontserratSemiBold';
    color: ${(props) => props.theme.colorStyled.ColorGrey};
    border: none;
    cursor: text;
    box-shadow: rgba(9, 30, 66, 0.4) 0px 0px 2px, rgba(9,30,66,0.35) 0px 0;
    &:hover {
      opacity: 1;
    }
  `}
  ${({ noBorder }) => noBorder && css`
    border: none;
  `}
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.colorStyled.ColorGrey};
  .chalktalk-previous {
    margin-right: .5em;
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize.HeadingH4FontSize};
  }
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorBlack};
  }
`;

export const SubmitButton = styled.button`
  width: 12em;
  margin: .5em 1em;
  padding: .75em 1em;
  border: none;
  background: ${(props) => props.theme.colorStyled.ColorRedGray};
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  font-family: 'MontserratSemiBold';
  border-radius: .5em;
  &:hover {
    opacity: .7;
  }
`;

export const ActionForm = styled.div`
  width: 100%;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  button, i {
    margin-left: 0.5em;
  }
  ${({ center }) => center && css`
    justify-content: center;
  `};

  ${({ alignRight }) => alignRight && css`
    justify-content: flex-end;
  `};

  ${({ marginTop }) => marginTop && css`
    margin-top: 1em;
  `}
  ${({ custome }) => custome && css`
    margin-left: auto;
    margin-top: 1px;
    margin-right: 1px;
  `};
  ${({ absolute }) => absolute && css`
    position: absolute;
    right: 1em;
    top: 1em;
  `};
  &:last-child {
    margin-left: 0em;
  }
`;

export const IconAction = styled.i`
  cursor: pointer;
  padding: 0.25em;
  border: 1px solid ${(props) => props.theme.colorStyled.ColorPoint};
  color: ${(props) => props.theme.colorStyled.ColorPoint};
  font-size: ${(props) => props.theme.fontSize.SmallFontSize};
  border-radius: 0.325em;
  margin-left: 0.5em;
  &:hover {
    border: 1px solid ${(props) => props.theme.colorStyled.ColorBlack};
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
`;

export const WarningIcon = styled.i`
  cursor: pointer;
  margin-left: auto;
  font-size: ${(props) => props.theme.fontSize.HeadingH4FontSize};
  color: ${(props) => props.theme.colorStyled.ColorGrey};
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorBlack};
  }
`;

export const FeedbackIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  pointer-events: none;
`;

export const FeedbackContainer = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

export const ToggleButton = styled(ActionShowHide)`
  opacity: ${(props) => props.hide ? 0 : 1};
  top: 1em;
`;

export const ClearButton = styled.button`
  cursor: pointer;
  border: none;
  border-bottom: 1px solid transparent;
  outline: none;
  background: transparent;
  padding: 0em;
  margin-left: auto;
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  &:hover {
    border-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  }
`;

export const AssignButton = styled.button`
  display: flex;
  border: none;
  ${(props) => !props.disabled ? css`
    color: ${props.theme.colorStyled.ColorPrimaryBlue};
  ` : css`
    color: ${props.theme.colorStyled.ColorGrey};
  `}
  margin-left: auto;
  margin-right: 1em;
  border-radius: .325em;
  padding: .5em 1.5em;
  font-size: 1em;
  font-family: 'MontserratMedium';
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
`;
