/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FieldArray, connect } from 'formik';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _unionBy from 'lodash/unionBy';
import CONSTANTS from '../../../utils/constants';
import FormInput from '../../Generals/FormInput/FormInput';
import {
  AddButtonWrapper,
  AddButton,
  StudentInformationTableWrapper,
  StudentInformationTable,
} from './InviteStudentsModal.style';

const StudentModel = {
  firstName: '',
  middleName: '',
  lastName: '',
  grade: '',
  email: '',
};

class InviteStudentTable extends React.PureComponent {
  componentDidMount() {
    document.addEventListener('paste', this.handlePasteAction);
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.handlePasteAction);
  }

  handlePasteAction = (event) => {
    const clipText = event.clipboardData.getData('Text');
    this.parseClipboard(clipText);
  };

  parseClipboard = (clipText) => {
    const itemList = clipText.split('\n').filter((e) => Boolean(e.trim()));
    const studentList = itemList.map(this.parseSingleItem).filter(Boolean);
    this.setStudentListValue(studentList);
  };

  parseSingleItem = (itemStr) => {
    const itemFieldListComma = itemStr.split(',').map((e) => e.trim());
    const itemFieldListTab = itemStr.split('\t').map((e) => e.trim());
    let itemFieldList = itemFieldListTab;

    if (itemFieldListComma.length > itemFieldListTab.length) {
      itemFieldList = itemFieldListComma;
    }

    if (itemFieldList && itemFieldList.length < 5) {
      return null;
    } // invalid

    const firstName = itemFieldList[0] || '';
    const middleName = itemFieldList[1] || '';
    const lastName = itemFieldList[2] || '';
    const grade = { grade_level: Number(itemFieldList[3]) || 9 };
    const email = itemFieldList[4] || '';

    return {
      firstName,
      middleName,
      lastName,
      grade,
      email,
    };
  };

  setStudentListValue = (studentList) => {
    const { formik } = this.props;
    const currentStudents = _get(formik, 'values.listStudent', []);
    const newStudentList = _unionBy(studentList, currentStudents, 'email');
    formik.setFieldValue('listStudent', newStudentList);
  };

  removeStudentItem = (arrayHelpers) => (index) => {
    arrayHelpers.remove(index);
  };

  renderStudentTable = (arrayHelpers) => {
    const { formik } = this.props;
    const studentsValues = _get(formik, 'values.listStudent', []);

    return (
      <StudentInformationTableWrapper>
        <StudentInformationTable>
          <tbody>
            {studentsValues.map((student, index) => (
              <React.Fragment>
                <tr key={index}>
                  <td>
                    <FormInput
                      type="text"
                      name={`listStudent.${index}.firstName`}
                      placeholder="First Name"
                    />
                  </td>
                  <td>
                    <FormInput
                      type="text"
                      name={`listStudent.${index}.middleName`}
                      placeholder="Middle Name"
                    />
                  </td>
                  <td>
                    <FormInput
                      type="text"
                      name={`listStudent.${index}.lastName`}
                      placeholder="Last Name"
                    />
                  </td>
                  <td>
                    <FormInput
                      type="select"
                      name={`listStudent.${index}.grade`}
                      placeholder="Grade"
                      options={CONSTANTS.GRADE_OPTIONS}
                      getOptionValue={(option) => option.id}
                      getOptionLabel={(option) => option.grade_level}
                    />
                  </td>
                  <td>
                    <FormInput
                      type="text"
                      name={`listStudent.${index}.email`}
                      placeholder="Email"
                    />
                  </td>
                  <td>
                    <span
                      className="chalktalk-icon-delete"
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </StudentInformationTable>
        <AddButtonWrapper>
          <AddButton onClick={() => arrayHelpers.push(StudentModel)}>
            <i className="chalktalk-plus" />
          </AddButton>
        </AddButtonWrapper>
      </StudentInformationTableWrapper>
    );
  };

  render() {
    return <FieldArray name="listStudent" render={this.renderStudentTable} />;
  }
}

InviteStudentTable.propTypes = {
  formik: PropTypes.any,
  allowPaste: PropTypes.bool,
};

export default connect(InviteStudentTable);
