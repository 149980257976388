import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import _isEqual from 'lodash/isEqual';
import md5 from 'md5';
import {
  LeftSiderBarContentGroup,
  ListSiderBar,
  TitleSideBar,

  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
} from './LeftSiderBar.style';
import CourseSelect from '../../containers/CourseSelect';
import SubNavBarTeacherTraining from '../../containers/SubNavBarTeacherTraining';

class LeftSideBarTeacherTraining extends Component {
  static propTypes = {
    match: PropTypes.any,
    pathName: PropTypes.string,
    curriculumId: PropTypes.any,
    getCurriculumTraining: PropTypes.func,
    trainings: PropTypes.array,
    isSideBarClose: PropTypes.bool,
    toggleLeftSidebar: PropTypes.func,
  };

  state = {}

  componentDidMount() {
    const { curriculumId, getCurriculumTraining } = this.props;

    if (!curriculumId) return;

    getCurriculumTraining(curriculumId);
  }

  componentDidUpdate(prevProps) {
    const { curriculumId: prevCurriculumId } = prevProps;
    const { curriculumId, getCurriculumTraining } = this.props;

    if (!_isEqual(prevCurriculumId, curriculumId)) {
      getCurriculumTraining(curriculumId);
    }
  }

  toggleSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  }

  renderSideBarContent() {
    return (
      <LeftSiderBarContentGroup>
        <TitleSideBar>
          Teacher Training
        </TitleSideBar>
        <ListSiderBar>
          <SubNavBarTeacherTraining />
        </ListSiderBar>
      </LeftSiderBarContentGroup>
    );
  }

  render() {
    const {
      pathName, match, trainings,
    } = this.props;
    const { isSideBarClose } = this.props;
    const courseHash = md5(`${match.params.courseId}@chalktalk`);

    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <CourseSelect pathName={pathName} />
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        {this.renderSideBarContent()}
        <ActionShowHide
          className="toggle-icon"
          onClick={this.toggleSidebar}
        >
          <MediaQuery maxWidth={960}>
            {matches => matches
              ? <span className={isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'} />
              : <span className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'} />}
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    );
  }
}

export default LeftSideBarTeacherTraining;
