import React from 'react';
import MathJax from '../../utils/mathjax3-react';
import addImageErrorListener from '../../utils/errorCapture';

export const MathJaxProvider = ({ children }) => (
  <MathJax.Provider
    url="/shared/mathJax/v3.2/mathJaxScript.js"
    options={{
      loader: { load: ['ui/safe'] },
      tex: {
        autoload: { color: [], colorv2: ['color'] },
        inlineMath: [['\\(', '\\)']],
        loader: { load: ['[tex]/colorv2'] },
        packages: { '[+]': ['colorv2'] },
      },
    }}
  >
    {children}
  </MathJax.Provider>
);

const MathJaxItem = ({ data }) => {
  const [show, setShow] = React.useState(false);
  const assetRef = React.useRef(null);

  const addBoldTextClass = () => {
    if (!assetRef || !assetRef?.current) {
      return;
    }

    const bTags = assetRef.current.getElementsByTagName('b');
    for (let b = 0; b < bTags.length; b += 1) {
      if (bTags[b].textContent.includes('Strategy/Rule')) {
        bTags[b].classList.add('boldText');
      }
    }
  };

  React.useEffect(() => {
    if (show && assetRef?.current) {
      addImageErrorListener(assetRef.current);
    }
  }, [data, show]);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShow(true);
      addBoldTextClass();
    }, 0);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [data]);

  return (
    show && (
      <div ref={assetRef}>
        <MathJax.Html html={data} />
      </div>
    )
  );
};

export default React.memo(MathJaxItem);
