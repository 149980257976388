/* eslint-disable no-plusplus */

import { toast } from 'react-toastify';

export const getDownloadBlob = (response) => {
  // Convert binary data to blob.
  const data = response.body;
  const len = data.length;
  const ar = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    ar[i] = data.charCodeAt(i);
  }
  const blob = new Blob([ar], { type: 'application/pdf' });
  return blob;
};

export const downloadBlob = (blob, fileName = 'file.pdf') => {
  const file = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.setAttribute('href', file);
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const openBlobInNewTab = (blob: Blob): void => {
  const blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl, '_blank');
  setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
};

export const fetchBlob = async (url: string): Promise<Blob> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }
  return response.blob();
};

export const handleBlobFromUrl = async (url: string) => {
  try {
    const blob = await fetchBlob(url);
    openBlobInNewTab(blob);
    downloadBlob(blob);
  } catch (error) {
    toast.error('Failed to download file');
    console.error('Error handling blob:', error);
  }
};
