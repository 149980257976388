import { doesArrayHaveElarSubject } from './commonUtils';

export const VIEW_BY_OPTIONS = {
  Curriculum: 1,
  Syllabus: 2,
  ReviewClasses: 3,
  Assignments: 4,
};

const GRADE_OPTIONS = [
  {
    id: '1',
    grade_level: '9',
  },
  {
    id: '2',
    grade_level: '10',
  },
  {
    id: '3',
    grade_level: '11',
  },
  {
    id: '4',
    grade_level: '12',
  },
];

export const CLASS_DAY_OPTION = [
  {
    day: '6',
    name: 'Sun',
  },
  {
    day: '0',
    name: 'Mon',
  },
  {
    day: '1',
    name: 'Tue',
  },
  {
    day: '2',
    name: 'Wed',
  },
  {
    day: '3',
    name: 'Thur',
  },
  {
    day: '4',
    name: 'Fri',
  },
  {
    day: '5',
    name: 'Sat',
  },
];

export const CALENDAR_EVENT_TYPE = {
  EXAMS: 'exams',
  REVIEWS: 'reviews',
  LESSONS: 'lessons',
};

export const SESSION_ACTIVITY_TYPE = {
  IN_CLASS: 0,
  HOME: 1,
  EXTRA: 2,
  title: {
    0: 'In Class',
    1: 'Home',
    2: 'Extra',
  },
  arrays: [
    {
      value: 0,
      label: 'In Class',
    },
    {
      value: 1,
      label: 'Home',
    },
    {
      value: 2,
      label: 'Extra',
    },
  ],
};

export const STANDARD_SESSION_TYPE = {
  LESSSON: 0,
  UNIT_PRACTICE: 1,
  arrays: [
    {
      value: 0,
      label: 'Lesson',
    },
    {
      value: 1,
      label: 'Unit Practice',
    },
  ],
};

export const EXAM_LENGTH_TYPES = {
  RAPID: 0,
  FULL_LENGTH: 1,
  title: {
    0: 'Rapid',
    1: 'Full length',
  },
  arrays: [
    {
      value: 0,
      label: 'Rapid',
    },
    {
      value: 1,
      label: 'Full length',
    },
  ],
};

export const EXAM_MID_TYPES = {
  MID_TEST1: 0,
  MID_TEST2: 1,
  title: {
    0: 'Mid-Test 1',
    1: 'Mid-Test 2',
  },
  arrays: [
    {
      id: 0,
      name: 'Mid-Test 1',
    },
    {
      id: 1,
      name: 'Mid-Test 2',
    },
  ],
};
export const EXAM_MID_TYPES_ACT = {
  MID_TEST1: 0,

  title: {
    0: 'Mid-Test 1',
  },
  arrays: [
    {
      id: 0,
      name: 'Mid-Test 1',
    },
  ],
};

export const EXAM_SESSION_TYPES = {
  PLACEMENT: 0,
  MID: 1,
  EXIT: 2,
  title: {
    0: 'Placement',
    1: 'Mid',
    2: 'Exit',
  },
  relatedKeys: { 0: 'placementTest', 1: 'midTest', 2: 'exitTest' },

  arrays: [
    {
      label: 'Placement Exam',
      value: 0,
    },
    {
      label: 'Mid Exam',
      value: 1,
    },
    {
      label: 'Exit Exam',
      value: 2,
    },
  ],
};

export const SESSION_EXAM_TYPE = {
  2: 'Online',
  3: 'Offline',
  4: 'Offline',
  5: 'Online',
  ONLINE_TYPE_1: 2,
  ONLINE_TYPE_2: 5,
  OFFLINE_TYPE_1: 3,
  OFFLINE_TYPE_2: 4,
};

const CONSTANTS = {
  CLASS_DAY_OPTION,
  GRADE_OPTIONS,
};

export const DATE_FORMAT = {
  DATE: 'YYYY-MM-DD',
  DATE_2: 'MM-DD-YYYY',
  DAY_DATE: 'MM-DD',
  WEEK_DATE: '[Week] W',
  ATTENDANCE_DATE: 'ddd MM/DD',
  HOUR: 'HH:mm',
  FULL: 'MM/DD/YYYY HH:mm',
  FULL_V1: 'YYYY-MM-DD hh:mm',
  FULL_V2: 'YYYY-MM-DD hh:mm a',
};

export const ORDER_LABEL = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const ORDER_SHORTCUT = '1, 2, 3, 4, 5, 6, 7, 8, 9, 0, left, right';

export const MODAL_TYPE = {
  JOIN_AND_CREATE_COURSE: 'JOIN_AND_CREATE_COURSE',
  CREATE_COURSE: 'CREATE_COURSE',
  INVITE_TEACHER: 'INVITE_TEACHER',
  CREATE_SECTION: 'CREATE_SECTION',
  SKIP_DIALOGUE: 'SKIP_DIALOGUE',
  JOIN_SECTION: 'JOIN_SECTION',
  INVITE_STUDENT: 'INVITE_STUDENT',
};

export const RENDER_TEST_DURATION_OPTIONS = (curriculumId, subjects) => {
  let options = [];
  let rapid = '';
  let full_length = '';
  let findOptions = true;

  if (curriculumId == 13) {
    //CCMR
    let minutes = 0;

    if (subjects.includes('Math')) {
      minutes += 35;
    }
    if (subjects && doesArrayHaveElarSubject(subjects)) {
      minutes += 35;
    }

    if (minutes == 0) {
      findOptions = false;
    }
    full_length = `(${minutes} mins)`;
  } else if (curriculumId == 1) {
    if (
      subjects.includes('Reading') &&
      subjects.includes('Writing & Language') &&
      subjects.includes('Math')
    ) {
      full_length = '(3 hours)';
      rapid = '(30 mins)';
    } else if (
      subjects.includes('Reading') &&
      subjects.includes('Writing & Language') &&
      !subjects.includes('Math')
    ) {
      full_length = '(100 mins)';
      rapid = '(18 mins)';
    } else if (
      subjects.includes('Math') &&
      !(subjects.includes('Reading') || subjects.includes('Writing & Language'))
    ) {
      full_length = '(80 mins)';
      rapid = '(12 mins)';
    } else if (
      subjects.includes('Reading') &&
      !(subjects.includes('Math') || subjects.includes('Writing & Language'))
    ) {
      full_length = '(65 mins)';
    } else if (
      subjects.includes('Writing & Language') &&
      !(subjects.includes('Reading') || subjects.includes('Math'))
    ) {
      full_length = '(35 mins)';
    } else if (
      subjects.includes('Math') &&
      subjects.includes('Reading') &&
      !subjects.includes('Writing & Language')
    ) {
      full_length = '(145 mins)';
    } else if (
      subjects.includes('Math') &&
      subjects.includes('Writing & Language') &&
      !subjects.includes('Reading')
    ) {
      full_length = '(115 mins)';
    } else {
      findOptions = false;
    }
  } else if (
    subjects.includes('Reading') &&
    subjects.includes('English') &&
    subjects.includes('Math') &&
    subjects.includes('Science')
  ) {
    full_length = '(3 hours)';
    rapid = '(43 mins)';
  } else if (
    subjects.includes('Reading') &&
    subjects.includes('English') &&
    !(subjects.includes('Math') || subjects.includes('Science'))
  ) {
    full_length = '(80 mins)';
    rapid = '(23 mins)';
  } else if (
    subjects.includes('Reading') &&
    subjects.includes('English') &&
    subjects.includes('Math') &&
    !subjects.includes('Science')
  ) {
    full_length = '(140 mins)';
    rapid = '(35 mins)';
  } else if (
    subjects.includes('Reading') &&
    subjects.includes('English') &&
    subjects.includes('Science') &&
    !subjects.includes('Math')
  ) {
    full_length = '(105 mins)';
    rapid = '(31 mins)';
  } else if (
    subjects.includes('English') &&
    !(
      subjects.includes('Reading') ||
      subjects.includes('Math') ||
      subjects.includes('Science')
    )
  ) {
    full_length = '(45 mins)';
    rapid = '(15 mins)';
  } else if (
    subjects.includes('Reading') &&
    !(
      subjects.includes('English') ||
      subjects.includes('Math') ||
      subjects.includes('Science')
    )
  ) {
    full_length = '(35 mins)';
    rapid = '(8 mins)';
  } else if (
    subjects.includes('Math') &&
    subjects.includes('Science') &&
    !(subjects.includes('Reading') || subjects.includes('English'))
  ) {
    full_length = '(95 mins)';
    rapid = '(20 mins)';
  } else if (
    subjects.includes('Math') &&
    !(
      subjects.includes('English') ||
      subjects.includes('Reading') ||
      subjects.includes('Science')
    )
  ) {
    full_length = '(60 mins)';
    rapid = '(12 mins)';
  } else if (
    subjects.includes('Science') &&
    !(
      subjects.includes('English') ||
      subjects.includes('Reading') ||
      subjects.includes('Math')
    )
  ) {
    full_length = '(35 mins)';
    rapid = '(8 mins)';
  } else if (
    subjects.includes('Math') &&
    subjects.includes('English') &&
    !(subjects.includes('Reading') || subjects.includes('Science'))
  ) {
    full_length = '(105 mins)';
    rapid = '(27 mins)';
  } else if (
    subjects.includes('Science') &&
    subjects.includes('English') &&
    !(subjects.includes('Reading') || subjects.includes('Math'))
  ) {
    full_length = '(80 mins)';
    rapid = '(23 mins)';
  } else if (
    subjects.includes('Math') &&
    subjects.includes('Reading') &&
    !(subjects.includes('English') || subjects.includes('Science'))
  ) {
    full_length = '(95 mins)';
    rapid = '(20 mins)';
  } else if (
    subjects.includes('Science') &&
    subjects.includes('Reading') &&
    !(subjects.includes('English') || subjects.includes('Math'))
  ) {
    full_length = '(70 mins)';
    rapid = '(16 mins)';
  } else if (
    subjects.includes('Science') &&
    subjects.includes('Math') &&
    subjects.includes('English') &&
    !subjects.includes('Reading')
  ) {
    full_length = '(140 mins)';
    rapid = '(35 mins)';
  } else if (
    subjects.includes('Science') &&
    subjects.includes('Math') &&
    subjects.includes('Reading') &&
    !subjects.includes('English')
  ) {
    full_length = '(130 mins)';
    rapid = '(28 mins)';
  } else {
    findOptions = false;
  }

  if (findOptions) {
    options = [
      {
        id: 1,
        name: `Full-Length Test ${full_length}`,
      },
    ];
  } else {
    options = [
      {
        name: `Full-Length Test (0 mins)`,
      },
    ];
  }

  return options;
};

export const getTotalDurationOfActivity = (duration) => {
  if (duration === 14) {
    return {
      PART_ONE_DURATION: 6 * 60,
      PART_TWO_DURATION: 8 * 60,
    };
  }

  return {
    PART_ONE_DURATION: 9 * 60,
    PART_TWO_DURATION: 12 * 60,
  };
};

const SAT_READING_FULL_LENGTH = 65;
const SAT_WRITING_LANGUAGE_FULL_LENGTH = 35;
const SAT_MATH_FULL_LENGTH = 80;

const ACT_READING_FULL_LENGTH = 35;
const ACT_ENGLISH_FULL_LENGTH = 45;
const ACT_MATH_FULL_LENGTH = 60;
const ACT_SCIENCE_FULL_LENGTH = 35;

const CCMR_ELAR_FULL_LENGTH = 45;
const CCMR_MATH_FULL_LENGTH = 45;

export const renderTestDurationOptions = (curriculumId, subjects) => {
  let options = {};
  let full_length = 0;
  let findOptions = true;

  if (curriculumId === 1) {
    if (subjects.includes('Reading')) {
      full_length += SAT_READING_FULL_LENGTH;
      findOptions = true;
    }
    if (subjects.includes('Writing & Language')) {
      full_length += SAT_WRITING_LANGUAGE_FULL_LENGTH;
      findOptions = true;
    }
    if (subjects.includes('Math')) {
      full_length += SAT_MATH_FULL_LENGTH;
      findOptions = true;
    }
  } else if (curriculumId === 2) {
    if (subjects.includes('Reading')) {
      full_length += ACT_READING_FULL_LENGTH;
      findOptions = true;
    }
    if (subjects.includes('English')) {
      full_length += ACT_ENGLISH_FULL_LENGTH;
      findOptions = true;
    }
    if (subjects.includes('Math')) {
      full_length += ACT_MATH_FULL_LENGTH;
      findOptions = true;
    }
    if (subjects.includes('Science')) {
      full_length += ACT_SCIENCE_FULL_LENGTH;
      findOptions = true;
    }
  } else if (curriculumId === 13) {
    if (subjects.includes('Math')) {
      findOptions = true;
      full_length += CCMR_MATH_FULL_LENGTH;
    }
    if (doesArrayHaveElarSubject(subjects)) {
      findOptions = true;
      full_length += CCMR_ELAR_FULL_LENGTH;
    }
  }

  if (findOptions) {
    options = {
      id: 1,
      name: `Full-Length Test (${full_length} minutes)`,
    };
  }
  return options;
};

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
export const yearMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export default CONSTANTS;

export const PRACTICE_SESSION_TYPE = {
  ADAPTIVE: 0,
  OGA: 1,
  STANDARDS: 2,
};

export const GRAMMAR_SUBJECT_IDS = {
  SAT_WRITING: '25',
  ACT_ENGLISH: '18',
};

export const STANDARDS_PRACTICE_QUESTION_COUNT = 5;

export const LESSON_PACKAGES = { FOUNDATIONAL: 'foundational', CORE: 'core' };