import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 2em;
`;

export const EmptyContentWrapper = styled.div`
  width: 100%;
  padding: 2em;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ReviewContentWrapper = styled.div`
  margin-top: 3.5em;
  :first-child {
    margin-top: 0em;
  }
`;
