// action definition
import _get from 'lodash/get';

export const COURSE_STUDENTS_GET_LIST = 'courseStudents/COURSES_GET_LIST';
export const COURSE_STUDENTS_GET_LIST_SUCCESS = 'courseStudents/COURSES_GET_LIST_SUCCESS';
export const COURSE_STUDENTS_GET_LIST_FAIL = 'courseStudents/COURSES_GET_LIST_FAIL';

export const COURSE_STUDENTS_SEND_RESET_PASSWORD = 'courseStudents/SEND_RESET_PASSWORD';
export const COURSE_STUDENTS_SEND_RESET_PASSWORD_SUCCESS = 'courseStudents/SEND_RESET_PASSWORD_SUCCESS';
export const COURSE_STUDENTS_SEND_RESET_PASSWORD_FAIL = 'courseStudents/SEND_RESET_PASSWORD_FAIL';

export const COURSE_STUDENTS_REMOVE_STUDENT = 'courseStudents/REMOVE_STUDENT';
export const COURSE_STUDENTS_REMOVE_STUDENT_SUCCESS = 'courseStudents/REMOVE_STUDENT_SUCCESS';
export const COURSE_STUDENTS_REMOVE_STUDENT_FAIL = 'courseStudents/REMOVE_STUDENT_FAIL';

export const COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD = 'courseStudents/TEACHER_CHANGE_PASSWORD';
export const COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD_SUCCESS = 'courseStudents/TEACHER_CHANGE_PASSWORD_SUCCESS';
export const COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD_FAIL = 'courseStudents/TEACHER_CHANGE_PASSWORD_FAIL';

// action creators
function fetchCourseStudentList(courseId) {
  return {
    type: COURSE_STUDENTS_GET_LIST,
    courseId,
  };
}

export const fetchCourseStudentListSuccess = studentList => ({
  type: COURSE_STUDENTS_GET_LIST_SUCCESS,
  studentList,
});

export const fetchCourseStudentListFail = errorMessage => ({
  type: COURSE_STUDENTS_GET_LIST_FAIL,
  errorMessage,
});

// RESET PASSWORD
const resetPassword = email => ({
  type: COURSE_STUDENTS_SEND_RESET_PASSWORD,
  payload: {
    email,
  },
});

const resetPasswordSuccess = email => ({
  type: COURSE_STUDENTS_SEND_RESET_PASSWORD_SUCCESS,
  payload: {
    email,
  },
});

const resetPasswordFailed = (errorMsg, email) => ({
  type: COURSE_STUDENTS_SEND_RESET_PASSWORD_FAIL,
  payload: {
    errorMsg,
    email,
  },
});

// RESET A STUDENT FROM COURSE

const removeStudent = (studentId, courseId, studentName) => ({
  type: COURSE_STUDENTS_REMOVE_STUDENT,
  payload: {
    courseId,
    studentId,
    studentName,
  },
});

const removeStudentSuccess = studentId => ({
  type: COURSE_STUDENTS_REMOVE_STUDENT_SUCCESS,
  payload: {
    studentId,
  },
});

const removeStudentFailed = (errorMsg, studentId) => ({
  type: COURSE_STUDENTS_REMOVE_STUDENT_FAIL,
  payload: {
    errorMsg,
    studentId,
  },
});

// CHANGE PASSWORD A STUDENT IN WEB

const teacherChangePassword = (courseId, studentId, password, studentName) => ({
  type: COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD,
  payload: {
    courseId,
    studentId,
    password,
    studentName,
  },
});

const teacherChangePasswordSuccess = studentId => ({
  type: COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD_SUCCESS,
  payload: {
    studentId,
  },
});

const teacherChangePasswordFail = (errorMsg, studentId) => ({
  type: COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD_FAIL,
  payload: {
    errorMsg,
    studentId,
  },
});
export const actions = {
  fetchCourseStudentList,
  fetchCourseStudentListSuccess,
  fetchCourseStudentListFail,

  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,

  removeStudent,
  removeStudentSuccess,
  removeStudentFailed,

  teacherChangePassword,
  teacherChangePasswordSuccess,
  teacherChangePasswordFail,

};

// selectors
export const getCourseStudentList = ({ courseStudents }) => _get(courseStudents, 'studentList', []);
export const isChangePassword = ({ courseStudents }) => _get(courseStudents, 'isChangePassword', []);
export const isChangePasswordSuccess = ({ courseStudents }) => _get(courseStudents, 'isChangePasswordSuccess', []);
export const getChangePasswordError = ({ courseStudents }) => _get(courseStudents, 'changePasswordError', []);
const isGettingStudentList = ({ courseStudents }) => courseStudents.isFetchingStudentList;

export const selectors = {
  getCourseStudentList,

  isChangePassword,
  isChangePasswordSuccess,
  getChangePasswordError,

  isGettingStudentList,
};

// reducers

const initialState = {
  studentList: [],
  isFetchingStudentList: false,
  errorMessage: '',
  isSendingResetPassword: [],
  isRemovingStudentList: [],

  isChangePassword: null,
  isChangePasswordSuccess: null,
  changePasswordError: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    // fetch course
    case COURSE_STUDENTS_GET_LIST:
      return {
        ...state,
        isFetchingStudentList: true,
        errorMessage: '',
      };

    case COURSE_STUDENTS_GET_LIST_SUCCESS: {
      const { studentList = [] } = action;
      return {
        ...state,
        studentList,
        isFetchingStudentList: false,
      };
    }

    case COURSE_STUDENTS_GET_LIST_FAIL: {
      const errorMessage = action;

      return {
        ...state,
        isFetchingStudentList: false,
        errorMessage,
      };
    }

    case COURSE_STUDENTS_SEND_RESET_PASSWORD: {
      const { email } = action.payload;

      return {
        ...state,
        isSendingResetPassword: [...state.isSendingResetPassword, email],
      };
    }

    case COURSE_STUDENTS_SEND_RESET_PASSWORD_FAIL:
    case COURSE_STUDENTS_SEND_RESET_PASSWORD_SUCCESS: {
      const { email } = action.payload;

      return {
        ...state,
        isSendingResetPassword: state.isSendingResetPassword.filter(e => e !== email),
      };
    }

    // HANDLE REMOVE STUDENT

    case COURSE_STUDENTS_REMOVE_STUDENT: {
      const { studentId } = action.payload;

      return {
        ...state,
        isRemovingStudentList: [...state.isRemovingStudentList, studentId],
      };
    }

    case COURSE_STUDENTS_REMOVE_STUDENT_FAIL: {
      const { studentId } = action.payload;

      return {
        ...state,
        isRemovingStudentList: state.isRemovingStudentList.filter(id => id !== studentId),
      };
    }

    case COURSE_STUDENTS_REMOVE_STUDENT_SUCCESS: {
      const { studentId } = action.payload;

      return {
        ...state,
        studentList: state.studentList.filter(student => student.id !== studentId),
        isRemovingStudentList: state.isRemovingStudentList.filter(id => id !== studentId),
      };
    }

    // HANDLE CHANGE PASSWORD IN WEB

    case COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangePassword: true,
        isChangePasswordSuccess: false,
        changePasswordError: '',
      };
    }

    case COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD_FAIL: {
      const { errorMsg } = action.payload;

      return {
        ...state,
        isChangePassword: false,
        changePasswordError: errorMsg,
      };
    }

    case COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangePassword: false,
        isChangePasswordSuccess: true,
      };
    }

    default: {
      return state;
    }
  }
}
