import { connect } from 'react-redux';
import _get from 'lodash/get';
import AddLessonSessionStepFour from '../../components/ScheduleAddLessonSessionForm/AddLessonSessionStepFour';

const mapStateToProps = (state, { initialValues }) => ({
  // lessons: _get(initialValues, 'unit', [])
  //   .reduce((acc, u) => acc.concat(u.lessons), []),
  lessons: _get(initialValues, 'unit.lessons', []),
});

export default connect(mapStateToProps)(AddLessonSessionStepFour);
