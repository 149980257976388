import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _isEmpty from 'lodash/isEmpty';
import { PrimaryWrap, DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { Card, CardContent, Grid, Typography } from '@mui/material';

const service_urls = [
  {
    service: 'Chalktalk API',
    url: 'https://api.chalktalk.com/api/auth/check/',
    failure: 'You will be unable to use the chalktalk platform.',
  },
  {
    service: 'Learnosity',
    url: 'https://assessment.svc.chalktalk-api.com/questions?standard=[%22111.7.2a%22]&limit=1&offset=0&environment=prod&seed=1819&publication=TEKS',
    failure: 'You will be unable to use some ChalkTalk features.',
  },
  {
    service: 'Learnosity-CDN',
    url: 'https://cdn-assessment.svc.chalktalk-api.com/questions?standard=[%22111.7.2a%22]&limit=1&offset=0&environment=prod&seed=1819&publication=TEKS',
    failure: 'You will be unable to use some ChalkTalk features.',
  },
  {
    service: 'Learnosity',
    url: 'https://items.learnosity.com/?v2021.3.LTS',
    failure: 'You will be unable to use some ChalkTalk features.',
  },
  {
    service: 'Youtube',
    url: 'https://www.youtube.com',
    failure: 'You will be unable to use some ChalkTalk features.',
  },
  {
    service: 'Geogebra',
    url: 'https://www.geogebra.org',
    failure: 'You will be unable to use some ChalkTalk features.',
  },
  {
    service: 'GoogleAPIs',
    url: 'https://fonts.googleapis.com/css?family=Roboto:400',
    failure: 'You will be unable to use the chalktalk platform.',
  },
  {
    service: 'GoogleDocs',
    url: 'https://docs.google.com/document/u/1/d/1N-Pq-75vMcjUXgAjcxi3Yn2p3_NeoVNjFy9FfCGoNT4/preview',
    failure: 'You will be unable to use the chalktalk platform.',
  },
  {
    service: 'Hubspot',
    url: 'https://js.hs-scripts.com/8096472.js',
    failure: 'You will be unable to use ChalkTalk support functionality.',
  },
  {
    service: 'Segment',
    url: 'https://cdn.segment.com/analytics.js/v1/iXURa7qyGHB3dPRgnzyG9QhRbXAI7MOb/analytics.min.js',
  },
  {
    service: 'Google Analytics',
    url: 'https://www.google-analytics.com/analytics.js',
  },
];

const image_urls = [
  {
    source: 'AWS',
    url: 'https://s3.amazonaws.com/chalktalk-media/exams/assets/2014-08-19/2-2-8.jpg',
    failure: 'You will be unable to use the chalktalk platform.',
  },
  {
    source: 'Codecogs',
    url: 'http://latex.codecogs.com/gif.latex?%5Cdpi%7B100%7D%20%5Cfn_jvn%20%5Csmall%20t%5E%7B3%7D&amp;plus;t%3Dm&amp;plus;m%5E%7B%5Cfrac%7B1%7D%7B3%7D%7D',
    failure: 'You will be unable to use the chalktalk platform.',
  },
  {
    source: 'lh4',
    url: 'https://lh4.googleusercontent.com/7FQqLREpJAgPwzGKS1PjDwRsJNP63yd0B93d9yFvh7jgX6fHas0PWmWB0tr9tODOmbhA9Nl3xZ91rS3X95osLmOWHvdLu5xcUrxJNtfLUoS_BRxKN4p11pbh5Gth_DJAkQ_IDCyN',
    failure: 'You will be unable to use the chalktalk platform.',
  },
  {
    source: 'lh3',
    url: 'https://lh3.googleusercontent.com/yOXww0s9PZgCoE3N7IHusc7_HLN5gItCGkBjcDLs0pKmEINrujGcpG3NAtiUTdrjh4YhUOy1NjLoUeSXe9TJ_1VuikVt9MVEDHI3b2jOaUIBsgqGdmRKdueH8TDSjYawsFF94Kog',
    failure: 'You will be unable to use the chalktalk platform.',
  },
];

const ping = (url, timeout = 6000) => {
  return new Promise((resolve, reject) => {
    const urlRule = new RegExp(
      '(https?|ftp|file)://[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]',
    );
    if (!urlRule.test(url)) reject('invalid url');
    try {
      fetch(url, { mode: 'no-cors' })
        .then(() => resolve(true))
        .catch(() => resolve(false));
      setTimeout(() => {
        resolve(false);
      }, timeout);
    } catch (e) {
      reject(e);
    }
  });
};
const ServiceApiCard = ({ service, url, failure }) => {
  const [testStatus, setTestStatus] = useState(undefined);
  const [testFailure, setTestFailure] = useState(false);
  useEffect(() => {
    ping(url)
      .then((res) => {
        setTestStatus(res ? 'Success' : `Failed. ${failure || ''}`);
        setTestFailure(!res);
      })
      .catch((e) => {
        setTestStatus(e);
        setTestFailure(true);
      });
  }, []);

  return (
    <Grid item xs={3} key={url}>
      <Card
        raised={testFailure}
        sx={{ minHeight: 125, bgcolor: testFailure ? 'pink' : 'lightgreen' }}
      >
        <CardContent>
          <Typography>{`${service} Endpoint`}</Typography>
          <h1>{testStatus}</h1>
        </CardContent>
      </Card>
    </Grid>
  );
};
const ImageCard = ({ source, url, failure }) => {
  const [testFailure, setTestFailure] = useState(false);
  return (
    <Grid item xs={3} key={url}>
      <Card
        raised={testFailure}
        sx={{ minHeight: 125, bgcolor: testFailure ? 'pink' : 'lightgreen' }}
      >
        <CardContent>
          <Typography>{`${source} Endpoint`}</Typography>
          <img
            src={url}
            alt={`Image failed to load. ${failure || ''}`}
            onError={() => setTestFailure(true)}
            width="50"
            height="50"
          />
        </CardContent>
      </Card>
    </Grid>
  );
};
const ExternalServiceChecker = () => {
  useEffect(() => {}, []);

  return (
    <PrimaryWrap>
      <DefaultContentWrap>
        <Grid container spacing={2}>
          {service_urls.map(({ service, url, failure }) => (
            <ServiceApiCard service={service} url={url} failure={failure} key={url} />
          ))}
          {image_urls.map(({ source, url, failure }) => (
            <ImageCard source={source} url={url} failure={failure} />
          ))}
        </Grid>
      </DefaultContentWrap>
    </PrimaryWrap>
  );
};

export default ExternalServiceChecker;
