import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import CreateOrJoinModal from '../Modals/CreateOrJoinModal';
import CourseSetupV2 from '../CourseSetupV2';
import JoinSectionModal from '../../containers/Modals/JoinSectionModal';
import {
  ModalHeader,
  ModalCloseCourse,
} from '../Generals/stylesheets/General.style';

const MODAL_STEP = {
  JOIN_SECTION_OR_CREATE_COURSE: 'JOIN_SECTION_OR_CREATE_COURSE',
  CREATE_COURSE: 'CREATE_COURSE',
  JOIN_SECTION: 'JOIN_SECTION',
};

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0 25px',
    width: '541px',
    maxWidth: '800px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderLeft: 'solid 22px #4A85E8',
    width: '540px',
    maxWidth: '800px',
    maxHeight: 'calc(100vh - 30px)',
    minWidth: '520px',
    borderRadius: '10px',
    padding: '0 25px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const mobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderLeft: 'solid 22px #4A85E8',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    borderRadius: '0px',
    padding: '0px',
    height: '100%'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};
class TeacherCourseSetup extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: MODAL_STEP.JOIN_SECTION_OR_CREATE_COURSE,
    };
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  moveToSubscriptionForm = () => {
    this.setState({
      currentStep: MODAL_STEP.CREATE_COURSE,
    });
  }

  moveToJoinSection = () => {
    this.setState({
      currentStep: MODAL_STEP.JOIN_SECTION,
    });
  }

  renderModalContent() {
    const { currentStep } = this.state;

    switch (currentStep) {
      case MODAL_STEP.JOIN_SECTION_OR_CREATE_COURSE:
        return (
          <CreateOrJoinModal
            moveToSubscriptionForm={this.moveToSubscriptionForm}
            moveToJoinSection={this.moveToJoinSection}
          />
        );
      case MODAL_STEP.CREATE_COURSE:
        return <CourseSetupV2 onClose={this.props.onClose} />;
      case MODAL_STEP.JOIN_SECTION:
        return <JoinSectionModal joinSectionTab onClose={this.props.onClose} />;
      default:
        return null;
    }
  }

  onChangecustomModalStyles = (matches) => {
    const { currentStep } = this.state;
    const { content } = modalStyles;

    switch (currentStep) {
      case MODAL_STEP.JOIN_SECTION_OR_CREATE_COURSE:
        return { ...modalStyles, ...(matches && mobileModalStyles) };
      case MODAL_STEP.CREATE_COURSE:
        return { ...modalStyles, ...(matches && mobileModalStyles) };
      case MODAL_STEP.JOIN_SECTION:
        return { ...modalStyles, ...(matches && mobileModalStyles) };
      default:
        return null;
    }
  }

  render() {
    return (
      <MediaQuery maxWidth={768}>
        {matches => (
          <Modal
            isOpen
            onRequestClose={this.props.onClose}
            style={this.onChangecustomModalStyles(matches)}
            ariaHideApp={false}
          >
            <ModalHeader>
              <ModalCloseCourse className="chalktalk-close" onClick={this.onClose} />
            </ModalHeader>
            {this.renderModalContent()}
          </Modal>
        )}
      </MediaQuery>
    );
  }
}

TeacherCourseSetup.propTypes = {
  onClose: PropTypes.func,
};

export default TeacherCourseSetup;
