import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import { splitUnitChildren, splitLessonTree } from '../../utils/func-utils';

export const SECTION_UNIT_GET = 'sectionUnit/GET';
export const SECTION_UNIT_GET_SUCCESS = 'sectionUnit/GET_SUCCESS';
export const SECTION_UNIT_GET_FAIL = 'sectionUnit/GET_FAIL';

export const SECTION_UNIT_GET_META = 'sectionUnit/GET_META';
export const SECTION_UNIT_GET_META_SUCCESS = 'sectionUnit/GET_META_SUCCESS';
export const SECTION_UNIT_GET_META_FAIL = 'sectionUnit/GET_META_FAIL';

const getUnitTreeBySubjectId = ({ sectionUnit }, subjectId) => _get(sectionUnit, `unitBySubject.${subjectId}`, {});

const getUnitTreeBySubjectIdList = ({ sectionUnit }, subjectIdList) => _pick(_get(sectionUnit, 'unitBySubject', {}), subjectIdList);

const getUnitBySubject = ({ sectionUnit }) => _get(sectionUnit, 'unitBySubject', {});

const getAllUnitOfSubjectId = (state, subjectId) => {
  const unitTreeOfSubject = getUnitTreeBySubjectId(state, subjectId);
  const { units } = unitTreeOfSubject;

  return splitUnitChildren(units, []);
};

const getAllUnitOfSubjectIdList = (state, subjectIdList) => {
  const unitTreeOfSubject = getUnitTreeBySubjectIdList(state, subjectIdList);
  const unitTreeKeys = Object.keys(unitTreeOfSubject);
  const unitList = unitTreeKeys.reduce((acc, key) => {
    const { units } = unitTreeOfSubject[key];
    return acc.concat(units);
  }, []);

  return splitUnitChildren(unitList, []);
};

const getUnitById = ({ sectionUnit }, unitId) => _get(sectionUnit, `unitByIds.${unitId}`, {});
const getUnitByIds = ({ sectionUnit }) => _get(sectionUnit, 'unitByIds', {});
const isGettingSectionUnit = ({ sectionUnit }) => sectionUnit.isGetting;

const getAllLessonOfUnit = (state, unitId) => {
  const unitInfo = getUnitById(state, unitId);

  if (_isEmpty(unitInfo)) {
    return [];
  }

  return splitLessonTree(unitInfo, []);
};

const getUnitObjByUnitIds = ({ sectionUnit }, unitIds) => unitIds.map(id => _get(sectionUnit, `unitByIds.${id}`));

const getUnitsBySubjectId = ({ sectionUnit }) => _get(sectionUnit, 'unitsBySubjectId', {});

const getSubjectHasPractice = (state, subjectIds) => {
  const unitsBySubjectId = getUnitsBySubjectId(state);

  return _filter(subjectIds, (subjectId) => {
    const unitIds = _get(unitsBySubjectId, subjectId, []);
    const unitList = getUnitObjByUnitIds(state, unitIds);

    return unitList.find(item => _get(item, 'meta.lesson_practice', false) || _get(item, 'meta.unit_practice', false));
  });
};

const isGettingLesson = ({ sectionUnit }) => sectionUnit.isGetting;

const getShouldFetch = ({ sectionUnit }, sectionId) => _get(sectionUnit, 'hasFetchedOfSectionId', null) != sectionId; // eslint-disable-line

export const selectors = {
  getAllUnitOfSubjectId,
  getAllUnitOfSubjectIdList,
  getUnitById,
  getUnitTreeBySubjectId,
  getAllLessonOfUnit,
  getUnitByIds,
  isGettingSectionUnit,
  getSubjectHasPractice,
  getUnitsBySubjectId,
  getUnitBySubject,
  getShouldFetch,
  isGettingLesson,
};

// action creator

const sectionUnitGet = (sectionId, subjectId) => ({
  type: SECTION_UNIT_GET,
  sectionId,
  subjectId,
});

const sectionUnitGetSuccess = (unitTreeList, allUnit, sectionId) => ({
  type: SECTION_UNIT_GET_SUCCESS,
  payload: unitTreeList,
  allUnit,
  sectionId,
});

const sectionUnitGetFail = error => ({
  type: SECTION_UNIT_GET_FAIL,
  error,
});

const sectionUnitGetMeta = sectionId => ({
  type: SECTION_UNIT_GET_META,
  sectionId,
});

const sectionUnitGetMetaSuccess = (
  unitTreeList,
  allUnit,
  unitsBySubjectId,
) => ({
  type: SECTION_UNIT_GET_META_SUCCESS,
  payload: unitTreeList,
  allUnit,
  unitsBySubjectId,
});

const sectionUnitGetMetaFail = error => ({
  type: SECTION_UNIT_GET_META_FAIL,
  error,
});

export const actions = {
  sectionUnitGet,
  sectionUnitGetSuccess,
  sectionUnitGetFail,

  sectionUnitGetMeta,
  sectionUnitGetMetaSuccess,
  sectionUnitGetMetaFail,
};

const initialValues = {
  isGetting: false,

  unitByIds: {},
  unitBySubject: {},

  unitsBySubjectId: {}, // unitsBySubjectId keeps the unit id list of subject
  hasFetchedOfSectionId: null,
};

export default function (state = initialValues, action) {
  switch (action.type) {
    case SECTION_UNIT_GET_META:
      return { ...state, isGetting: true };
    case SECTION_UNIT_GET: {
      const { sectionId } = action;
      const shouldFetch = sectionId != state.hasFetchedOfSectionId; // eslint-disable-line

      return { ...state, isGetting: shouldFetch};
    }
    case SECTION_UNIT_GET_META_SUCCESS: {
      const {
        payload, allUnit, unitsBySubjectId,
      } = action;
      const unitBySubject = _keyBy(payload, 'id');
      const unitByIds = _keyBy(allUnit, 'id');

      return {
        ...state,
        unitBySubject,
        unitByIds,
        unitsBySubjectId,
        isGetting: false,
      };
    }
    case SECTION_UNIT_GET_SUCCESS: {
      const { payload, allUnit, sectionId } = action;
      const unitBySubject = _keyBy(payload, 'id');
      const unitByIds = _keyBy(allUnit, 'id');

      return {
        ...state,
        unitBySubject,
        unitByIds,
        isGetting: false,
        hasFetchedOfSectionId: sectionId,
      };
    }
    case SECTION_UNIT_GET_META_FAIL:
    case SECTION_UNIT_GET_FAIL:
      return { ...state, isGetting: false };
    default:
      return state;
  }
}
