import React from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'select'> {
  /** The label content */
  label: MuiTextFieldProps['label'];
  /** If `true`, the label is displayed as required and the `input` element is required. */
  required?: MuiTextFieldProps['required'];
  /** The short hint displayed in the `input` before the user enters a value. */
  placeholder?: MuiTextFieldProps['placeholder'];
  /** Type of the `input` element. It should be [a valid HTML5 input type](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types). */
  type?: MuiTextFieldProps['type'];
  /** The helper text content */
  helperText?: MuiTextFieldProps['helperText'];
  /** If `true`, the component is disabled */
  disabled?: MuiTextFieldProps['disabled'];
  /** If `true`, component is selected and ready to enter text */
  focused?: MuiTextFieldProps['focused'];
  /** The variant to use */
  variant: MuiTextFieldProps['variant'];
  /** If `true`, the label is displayed in an error state. */
  error?: MuiTextFieldProps['error'];
  /** The default value. Use when the component is not controlled. */
  defaultValue?: MuiTextFieldProps['defaultValue'];
  /** If `true`, a `textarea` element is rendered instead of an input. */
  multiline?: MuiTextFieldProps['multiline'];
  /** Maximum number of rows to display when multiline option is set to true. */
  maxRows?: MuiTextFieldProps['maxRows'];
  /** Minimum number of rows to display when multiline option is set to true. */
  minRows?: MuiTextFieldProps['minRows'];
  /** Callback fired when the value is changed. */
  onChange: MuiTextFieldProps['onChange'];
  /** The value of the `input` element, required for a controlled component. */
  value: MuiTextFieldProps['value'];
  /** The id of the `input` element. Use this prop to make label and `helperText` accessible for screen readers. */
  dataTestId?: string;
  /** If `true`, the `input` element takes up the full width of its container. */
  fullWidth?: MuiTextFieldProps['fullWidth'];
}

/**
 * A wrapper component for Material-UI's `TextField`.
 */
const TextField = ({ dataTestId, ...rest }: TextFieldProps) => (
  <MuiTextField data-testid={dataTestId} {...rest} />
);

export default TextField;
