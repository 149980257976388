import React from 'react';
import { ContentFilterProvider } from '@components/ContentFilters/ContentFilterProvider';
import { useParams } from 'react-router-dom';
import MainContent from '@containers/MainContent';
import CourseSelect from '@containers/CourseSelect';
import SectionSelect from '@containers/SectionSelect';
import { useQGSAccess } from '@apis/questiongenerationservice';
import history from '@utils/history';
import QuestionGenerationService from './QuestionGenerationService';
import { useUser } from '@reducers/user/hooks';

const QGSMain = () => {
  const { courseId, sectionId } = useParams();
  const user = useUser();

  // check for permissions
  const hasAuthorAideAccess = useQGSAccess(user.id, true);

  if (!hasAuthorAideAccess) {
    history.push('/dashboard');
    return null;
  }

  // If no sectionId, show a simplified view with just the section selector
  if (!courseId || !sectionId) {
    return (
      <MainContent>
        <CourseSelect pathName="/coauthor/course" />
        {courseId && <SectionSelect pathName="/coauthor/course" />}
      </MainContent>
    );
  }

  return (
    <ContentFilterProvider>
      <QuestionGenerationService />
    </ContentFilterProvider>
  );
};

export default QGSMain;
