import styled from 'styled-components';

export const ExamScoreTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 1em;
  .react-tabs__tab-list {
    margin-bottom: 0em;
    .react-tabs__tab {
      padding: .5em 1em;
      background-color: ${props => props.theme.colorStyled.ColorWhite};
      color: ${props => props.theme.colorStyled.ColorPrimary};
      border: none !important; 
      &:first-child {
        border-radius: .5em 0em 0em .5em;
      }
      &:last-child {
        border-radius: 0em .5em .5em 0em;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .react-tabs__tab--selected {
      background-color: ${props => props.theme.colorStyled.ColorBgDefault};
      color: ${props => props.theme.colorStyled.ColorWhite};
      &:hover {
        color: ${props => props.theme.colorStyled.ColorWhite};
      }
    }
  }
`;

export const ExamScoreSelectWrap = styled.div`
  position: relative;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
`;

export const ExamScoreSelectValue = styled.div`
  padding: .375em 1.5em;
  border-radius: .5em;
  min-width: 8em;
  margin-left: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 0px 3px, rgba(9,30,66,0.31) 0px 0;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    box-shadow: rgba(9, 30, 66, 0.75) 0px 0px 3px, rgba(9, 30, 66, 0.38) 0px 0;
  }
  i {
    margin-left: 1em;
    margin-top: .25em;
    font-size: ${props => props.theme.fontSize.SmallMobileFontSize};
  }
`;

export const ExamScoreFlex = styled.div`
  display: flex;
  padding: 0.5rem;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const ExamScoreSelectGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const ExamScoreTableContent = styled.div`
  flex: 1;
  margin: 1em;
  @media (max-width: 1280px) {
    margin: 0em 1em;
  }
`;

export const ExamScoreUpcomingWrap = styled.div`
  flex: 0 0 18em;
  span {
    margin: 1em;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  }
  @media (max-width: 1280px) {
    flex: 1;
  }
`;

export const ExamScoreSelect = styled.div`
  border-radius: .325em;
  position: absolute;
  top: 3em;
  left: 0em;
  width: 100%;
  z-index: 2;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 0px 3px, rgba(9,30,66,0.31) 0px 0;
`;

export const ExamScoreOption = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: .75em 1em;
  cursor: pointer;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${props => props.theme.colorStyled.ColorBtnDark};
  }
  i {
    margin-left: .5em;
    font-size: ${props => props.theme.fontSize.DefaultFonts};
  }
`;
