import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SessionSubNavItem from '../../components/SubNavBarItem/SessionSubNavItem';
import {
  selectors as sectionUnitSelectors,
} from '../../reducers/sectionUnit';
import {
  selectors as curriculumLessonSelectors,
} from '../../reducers/curriculumLessons';
import {
  selectors as activitySelectors,
  actions as activityActions,
} from '../../reducers/activity';
import {
  selectors as sectionsSelectors,
} from '../../reducers/sections';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as lessonAssignedActivitySelectors } from '../../reducers/lessonAssignedActivities';
import { ROLE_TYPE } from '../../utils/enums';

const mapStateToProps = (state, { sessionItem, match }) => {
  const { unit: unitId, lesson: lessonId, subject: subjectId } = sessionItem;
  const { lessonId: lessonIdFromPath = '', sectionId = 0, unitId: unitIdFromPath } = match.params;
  const isTeacher = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR;
  let isAssigned = false;

  if (lessonId) {
    isAssigned = lessonAssignedActivitySelectors.getAssignStatusByLessonId(state, lessonId);
  } else {
    isAssigned = lessonAssignedActivitySelectors.getAssignStatusByUnitPractice(state, unitId);
  }

  return {
    unitIdFromPath,
    isAssigned,
    subjectId,
    lesson: curriculumLessonSelectors.getLessonByLessonId(state, lessonId),
    unit: sectionUnitSelectors.getUnitById(state, unitId),
    unitId,
    lessonId,
    lessonIdFromPath,
    lessonActivitySummaryById: activitySelectors.getLessonActivitySummaryById(state),
    practiceSummaryByLessonId: sectionsSelectors.getPracticeSummaryByLessonId(state),
    practiceSummaryByUnitId: sectionsSelectors.getPracticeSummaryByUnitId(state),
    studentList: sectionStudentSelectors.getStudentList(state, sectionId),
    userId: UserSelectors.getUserId(state),
    isTeacher,
  };
};

export default withRouter(connect(mapStateToProps)(SessionSubNavItem));
