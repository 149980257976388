import styled, { css, keyframes } from 'styled-components';
import { NavBarListWrap } from '../SubNavBarItem/SubNavBar.style';

export const LeftSiderBarWrap = styled.section`
  position: fixed;
  left: 6.5em;
  width: 20em;
  height: 100vh;
  background: ${(props) => props.theme.colorStyled.ColorWhite};
  transition: all 300ms ease;
  opacity: 1;
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  &:hover {
    .toggle-icon {
      opacity: 1;
    }
  }
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 0.2) 0px,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .show-banner & {
    height: calc(100vh - 50px);
  }
  @media (max-width: 960px) {
    left: -12em;
    .toggle-icon {
      opacity: 1;
    }
    div {
      opacity: 0;
      transition: all 300ms ease;
    }
  }
  ${({ isClose }) =>
    isClose &&
    css`
      left: -12em;
      div {
        opacity: 0;
        transition: all 300ms ease;
      }
      .toggle-icon {
        opacity: 1;
      }
      @media (max-width: 960px) {
        position: fixed;
        top: 0em;
        left: 6.55em;
        width: 20em;
        height: 100vh;
        background: ${(props) => props.theme.colorStyled.ColorWhite};
        transition: all 300ms ease;
        opacity: 1;
        z-index: 3;
        div {
          opacity: 1;
        }
      }
    `};
  ${({ isFixedClose }) =>
    isFixedClose &&
    css`
      position: fixed;
      top: 0;
      left: 4em !important;
      width: 20em;
      height: 100vh;
      background: ${(props) => props.theme.colorStyled.ColorWhite};
      transition: all 300ms ease;
      opacity: 1;
      z-index: 3;
      div {
        opacity: 1;
      }
    `}
`;

export const LeftSiderBarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25em 1.5em 0 1.5em;
  flex: 0 1 auto;
  //overflow-x: hidden;
`;
export const LeftSiderBarHeaderGroup = styled.div`
  width: 100%;
  z-index: 99;
`;

export const LeftSideBarList = styled.div`
  border-top: 1.5px solid #091e4214;
  margin: 0 1em;
  padding: 1em 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ noMargin }) =>
    noMargin &&
    css`
      border: 0;
      margin: 0;
      padding: 0;
    `}
`;

export const LeftSiderBarContentGroup = styled.div`
  margin-top: 0.5em;
  flex: 1 1 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const ListSiderBar = styled.ul``;

export const ItemsSiderBar = styled.div`
  width: 100%;
  padding: 0.75em;
  display: flex;
  align-items: center;
  cursor: pointer;
  max-height: 44px;
  margin: 0.1em 0;
  span {
    cursor: pointer;
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
  a > span {
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  }
  a > i {
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  }
  img {
    cursor: pointer;
    transition: all 300ms ease;
    width: 1.8em;
    height: 1.8em;
    margin-right: 0.875em;
    filter: grayscale(100%);
  }
  i {
    transition: all 300ms ease;
  }
  .chalktalk-icon-add {
    margin-right: 0.5em;
  }
  .chalktalk-icon-dropdown {
  }
  &:hover {
    img {
      filter: grayscale(0%);
    }
    span {
      color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #f1f1f1;
      border-radius: 0.325em;
      opacity: 1;
      span {
        color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      }
      img {
        filter: grayscale(0%);
      }
    `}
  ${({ noLink }) =>
    noLink &&
    css`
      &:hover {
        span {
          color: ${(props) => props.theme.colorStyled.ColorPrimary};
        }
      }
    `}
  &:hover {
    .beta {
      color: #ffffff !important;
    }
  }
`;

export const SearchWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  padding: 0 10px 10px 10px;
  input {
    width: 80%;
    padding: 5px;
    font-family: 'MontserratRegular';
  }
  button {
    width: 20%;
    min-width: unset;
    border-radius: 0 0.325em 0.325em 0;
  }
`;

export const MultiItemsSiderBar = styled(ItemsSiderBar)`
  display: block;
  ${({ noLink }) =>
    noLink &&
    css`
      &:hover {
        span {
          color: ${(props) => props.theme.colorStyled.ColorPrimary};
        }
      }
    `}
`;

export const ActionShowHide = styled.span`
  opacity: 0;
  width: 1.85em;
  height: 1.85em;
  cursor: pointer;
  font-weight: bold;
  fill: rgb(255, 255, 255);
  background: ${(props) => props.theme.colorStyled.ColorWhite};
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -0.85em;
  top: 1.25em;
  border: 1px solid #eaeaea;
  z-index: 999;
  box-shadow: 0 0 12px -5px ${(props) => props.theme.colorStyled.ColorBoxShadow};
  transition: all 300ms ease;
  &:hover {
    background: ${(props) => props.theme.colorStyled.ColorBgDefault};
    span {
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    }
  }
  .chalktalk-icon-bold-up,
  .chalktalk-icon-bold-down {
    font-size: ${(props) => props.theme.fontSize.XSmallMobileFontSize};
    transform: rotate(-90deg);
  }
  .chalktalk-menu {
    font-weight: bold;
  }
`;

export const TitleSideBar = styled.h1`
  font-size: ${(props) => props.theme.fontSize.LargerFontSize};
  font-family: 'MontserratMedium';
  padding: 0.75em 1.5em;
  ${({ variant2 }) =>
    variant2 &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        flex: 1;
        font-size: 12px;
      }
    `}
  i {
    margin-left: 15px;
    font-size: 10px;
    color: black;
    font-weight: normal;
  }
`;

export const Button = styled.div`
  width: 147px;
  height: 25px;
  background: #4886ff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  margin: 15px auto;
  text-align: center;
  font: normal normal normal 12px/16px MontserratLight;
  color: #ffffff;
  span {
    line-height: 25px;
  }
`;
export const SelectStyles = {
  dropdownIndicator: (base) => ({
    ...base,
    color: 'white',
  }),
  container: (base) => ({
    ...base,
    coloor: 'white',
    zIndex: 2,
  }),
  control: (base) => ({
    ...base,
    color: 'white',
    backgroundColor: '#ff9e16',
    border: 0,
    padding: 0,
    borderRadius: '4px',
    caretColor: 'transparent',
    cursor: 'pointer',
    minHeight: '33px',
    height: '33px',
    border: 0,
  }),
};
export const SelectWrap = styled.div`
  margin-bottom: 10px;
  .section-select__single-value {
    color: white;
    overflow: visible;
    font-family: 'MontserratLight';
  }
  .section-select__control--is-focused {
    border: 0;
    box-shadow: none;
  }
`;
export const List = styled.div`
  margin: 0 1em;
  ${({ border }) =>
    border &&
    css`
      border-top: 1.5px solid #091e4214;
    `}
`;

export const SubjectProgress = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  z-index: 1;
  background-color: ${(props) => (props.subject ? `#FFF8DF` : `#E5E5E5`)};
  padding: 7px;
  font: normal normal normal 10px/14px MontserratLight;
  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: ${(props) => props.rate}%;
    height: 100%;
    content: '';
    background-color: ${(props) => (props.unit ? `#CECECE` : `#FF9E16`)};
  }
  p {
    white-space: nowrap;
  }
  ${({ unit }) =>
    unit &&
    css`
      margin-bottom: 5px;
    `}
`;
export const FlexItems = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 16px;
  margin-bottom: 3px;
`;
export const scrollStyle = {
  height: '100%',
  width: '100%',
};
export const DetailsWrapper = styled.div`
  box-shadow: inset 0 -10px 10px -10px #e0dcdc;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    height: calc(100% - 10px);
    bottom: 10px;
    right: 0px;
    width: 6px;
    background: #e5e5e5;
    border-radius: 8px;
  }
`;
export const LessonBlocks = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
`;
export const AccordionSummaryStyles = {
  root: {
    height: '70px',
    padding: '0!important',
  },
  content: {
    flexDirection: 'column',
  },
};

export const AccordionStyles = {
  root: {
    boxShadow: 'none!important',
    '&::before': {
      backgroundColor: 'white!important',
    },
    '&.Mui-expanded': {
      margin: '0!important',
    },
  },
};

export const Details = styled.div`
  z-index: 1;
  position: relative;
  width: 100%;
  max-height: 250px;
  height: 100%;
  overflow-y: overlay;
  font: 0.8rem MontserratLight;
  padding-bottom: 5px;
  ::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    width: 6px;
    background-color: #ff9e16;
    border-right: none;
    border-left: none;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }
  ::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }
`;
export const LightTextParagraph = styled.div`
  font: 0.7rem MontserratLight;
  align-self: center;
`;
export const SideBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  span {
    font-family: 'MontserratSemiBold';
  }
  i {
    font-size: ${(props) => props.theme.fontSize.XSmallMobileFontSize};
  }
  ${({ isRotate }) =>
    isRotate &&
    css`
      i {
        transform: rotate(180deg);
      }
    `}
`;

export const SideBarTip = styled.span`
  font-size: 10px;
  color: #918980 !important;
  margin-left: 5px;
`;

const MoveToOpen = (props) => keyframes`
  from {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  to {
    height: ${props.height}em;
    opacity: 1;
  }
`;

const MoveToClose = (props) => keyframes`
  from {
    height: ${props.height}em;
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
`;

const ToggleOpen = css`
  animation: ${MoveToOpen} 200ms linear;
  opacity: 1;
  height: auto;
`;

const ToggleClose = css`
  animation: ${MoveToClose} 200ms linear;
  height: 0;
  opacity: 0;
  overflow: hidden;
`;

export const CollapseSideBarWrap = styled.div`
  flex: 1;
  overflow: auto;
`;

export const CollapseSideBar = styled.div`
  transition: all 200ms ease;
  ${({ isClose }) => (isClose ? ToggleClose : ToggleOpen)};
  padding: 0em 0.75em;
  height: ${(props) => props.height || '100%'};
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0;
    `};
`;

export const CollapseSideBarSectionsWrapper = styled(CollapseSideBar)`
  display: flex;
  flex-direction: column;
`;

export const CollapseSideBarItem = styled(ItemsSiderBar)`
  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        background-color: #f1f1f1;
        border-radius: 0.325em;
        opacity: 1;
      }
    `}
  ${({ spaceBetween }) =>
    spaceBetween &&
    css`
      justify-content: space-between;
    `}
`;

export const SideBarItemAttention = styled.div`
  border-radius: 5px;
  border: 1px solid #a22325;
  padding: 2px 10px;
  color: #fff !important;
  font-size: 0.8em;
  background: #a22325;
  margin-left: auto;
  min-width: 3em;
  text-align: center;
  height: 2em;
`;

export const BackButton = styled.button`
  i {
    margin-right: 7px;
  }
  width: 100%;
  height: 28px;
  border: none;
  text-align: left;
  padding: 0;
  font-size: 10pt;
  margin-bottom: 10px;
`;
export const UnitDataRow = styled.div`
  display: flex;
  justify-content: left;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 90%;
  margin: auto;
  gap: 3px;
  cursor: pointer;
  .chalktalk-warning {
    color: #ff9e17;
    font-size: 17px;
    margin-right: 6px;
  }
  .hint-msg {
    font-size: 10px;
  }
  ${({ noBorder }) =>
    noBorder &&
    css`
      border-bottom: 0;
    `}
`;
export const UnitDataIcon = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  img {
    width: 2em;
    height: 2em;
    object-fit: contain;
    margin-right: 6px;
  }
  ${({ isYourSection }) =>
    isYourSection &&
    css`
      img {
        height: 1.5em;
        width: 1.5em;
        position: relative;
        top: 5px;
      }
    `}
  ${({ calendar }) =>
    calendar &&
    css`
      img {
        height: 1.5em;
        width: 1.5em;
        position: relative;
        top: 3px;
      }
    `}
  ${({ addReview }) =>
    addReview &&
    css`
      width: 30px;
      height: 30px;
      background-color: #fee3c0;
      text-align: center;
      border-radius: 50%;
      padding-top: 7px;
      margin-right: 10px;
    `}
`;
export const UnitDataText = styled.div`
  padding-top: 5px;
  ${({ isUnitName }) =>
    isUnitName &&
    css`
      padding: 10px;
      font-size: 10pt;
      font-weight: bold;
      background-color: whitesmoke;
      border-radius: 5px;
      margin-top: 5px;
    `}
`;

export const UnitDataContener = styled.div`
  height: calc(100vh - 17.5em);
  width: 100%;
  margin-top: 1em;
  position: relative;
  overflow: scroll;
`;

export const LessonDataContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  padding-bottom: 30px;
`;

export const LessonDataContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  margin-top: 1em;
  position: relative;
  overflow: scroll;
`;

export const ButtonWrapper = styled.div`
  display: felx;
  justify-content: space-evenly;
  width: 450px;
  margin: auto;
  align-items: center;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const SideBarContentTitle = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
  .beta {
    background: #8d8d8d 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: left;
    font: normal normal normal 9px/12px Roboto;
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
    margin-left: 10px;
    padding: 3px 7px;
  }
`;
