import React, { useCallback, useMemo, useState } from 'react';
import EndSessionDialog from '../EndSessionDialog/EndSessionDialog';

type EndSessionContextType = {
  handleEndSession: () => void;
};
const EndSessionContext = React.createContext<EndSessionContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleEndSession: () => {},
});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const EndSessionProvider = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleEndSession = useCallback(() => {
    setOpen(true);
  }, []);

  const value = useMemo(() => ({ handleEndSession }), [handleEndSession]);
  return (
    <EndSessionContext.Provider value={value}>
      {children}
      <EndSessionDialog
        dataTestId="end-session-dialog"
        open={open}
        onClose={() => setOpen(false)}
      />
    </EndSessionContext.Provider>
  );
};

export default React.memo(EndSessionProvider);
export const useEndActivitySession = () => {
  const context = React.useContext(EndSessionContext);
  if (context === undefined) {
    throw new Error('useEndSessionDialog must be used within a EndSessionProvider');
  }
  return context;
};
