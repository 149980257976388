import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Container,
  ContentRow,
  TextDate,
  TextTeacherName,
  TextLessonDetail,
  RequestDetailContainer,
  IconContainer,
  ContentRowSubItem,
} from './MyLibrary.style';
import CompareVersionsModal from '../Modals/CompareVersionsModal';
import GFEButton from '../Generals/GFEButton';
import { DATE_FORMAT } from '../../utils/constants';

const ReviewItem = (props) => {
  const {
    data,
    updateReviewStatus,
    isSubmittingSlide,
    curriculumLessonGetReviews,
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    submitted_at,
    updated_at,
    g_slide_id,
    id: reviewId,
    lesson_node,
    teacher_name,
    curriculum_name,
    unit_name,
    lesson_name,
    subject_name,
    instructor_url,
  } = data;

  const getLessonGoogleDriveIdByUrl = (url) => url?.split('https://drive.google.com/file/d/')[1]?.split('/preview')[0];

  const handleViewChanges = () => {
    setModalOpen(true);
  };

  const handleSubmit = (status) => {
    const reviewInfo = { status };
    updateReviewStatus(lesson_node, reviewId, reviewInfo);
  };

  return (
    <Container>
      <CompareVersionsModal
        isModalOpen={isModalOpen}
        onCloseModal={() => setModalOpen(false)}
        copyPresentationId={g_slide_id}
        defaultPresentationId={getLessonGoogleDriveIdByUrl(instructor_url)}
        updateReviewStatus={(status) => handleSubmit(status)}
        isSubmittingSlide={isSubmittingSlide}
        teacherName={teacher_name}
        isReviewer
      />
      <IconContainer onClick={() => handleSubmit('Rejected')}>
        <i className="chalktalk-close" />
      </IconContainer>
      <RequestDetailContainer>
        <ContentRow>
          <TextTeacherName>{teacher_name}</TextTeacherName>
          <ContentRowSubItem>
            <TextLessonDetail>{curriculum_name}</TextLessonDetail>
            {subject_name && (
              <TextLessonDetail>&nbsp;&nbsp;{'>'}&nbsp;&nbsp;{subject_name}</TextLessonDetail>
            )}
            {unit_name && (
              <TextLessonDetail>&nbsp;&nbsp;{'>'}&nbsp;&nbsp;{unit_name}</TextLessonDetail>
            )}
            {lesson_name && (
              <TextLessonDetail>&nbsp;&nbsp;{'>'}&nbsp;&nbsp;{lesson_name}</TextLessonDetail>
            )}
          </ContentRowSubItem>
        </ContentRow>
        <TextDate>
          {`Updated ${moment(updated_at).format(DATE_FORMAT.DATE)}`}
        </TextDate>
        <TextDate>
          {`Requested ${moment(submitted_at).format(DATE_FORMAT.DATE)}`}
        </TextDate>
        <GFEButton
          backgroundColor="#ff9e16"
          color="white"
          onClick={handleViewChanges}
          label="Review Changes"
        />
      </RequestDetailContainer>
    </Container>
  );
};

export default ReviewItem;

ReviewItem.propTypes = {
  data: PropTypes.object.isRequired,
  g_slide_id: PropTypes.string,
  updateReviewStatus: PropTypes.func.isRequired,
};
