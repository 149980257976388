import { connect } from 'react-redux';
import EmailVerification from '../../components/EmailVerification';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../../reducers/auth';

export const mapStateToProps = state => ({
  isLoading: AuthSelectors.isVerifyingEmail(state),
});

const mapDispatchToProps = {
  verifyEmail: AuthActions.verifyEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
