import React from 'react';
import { useDrop } from 'react-dnd';
import HelpIcon from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';
import { useIsTeacher } from '@reducers/user/hooks';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useStartActivity } from '@components/Component/OLP/tab/Provider/StartActivityProvider';
import { StyledRow } from '../GroupRow/GroupRow.style';
import Cell from '../Cell';
import StudentChip from '../StudentChip';
import { AbsentRowStyle } from './AbsentRow.style';

type Props = {
  studentList: {
    student_name: string;
    id: number;
  }[];
  onDropCell: (sourceId: number, sourceRowId: number, targetRowId: number) => void;
  isActivityStarted: boolean;
};
export const absentRowIndex = -1;
const AbsentRow = ({ studentList, onDropCell, isActivityStarted }: Props) => {
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: 'GROUPNAME',
    drop: ({ sourceId, sourceRowId }) => {
      onDropCell(sourceId, sourceRowId, absentRowIndex);
    },
    canDrop: ({ sourceRowId }) => sourceRowId !== absentRowIndex,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const { absentRowDiv } = useStartActivity();
  const isTeacher = useIsTeacher();
  if (!isTeacher) {
    return null;
  }
  return (
    <div ref={absentRowDiv}>
      <Divider variant="middle" sx={{ borderStyle: 'dashed', pt: 1 }} />
      <StyledRow className="table-row" ref={dropRef} isDropOver={isOver && canDrop}>
        <AbsentRowStyle className="group-number-cell" data-testid="absent-row-header">
          <span data-testid="absent-row-header-text">Absent</span>
          <Tooltip
            title="Drag student names here if they are absent for this activity"
            placement="top"
          >
            <HelpIcon data-testid="absent-help-icon" fontSize="small" />
          </Tooltip>
        </AbsentRowStyle>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            '& .table-cell': {
              flexBasis: `${100 / Math.min(studentList.length || 1, 4)}%`,
              flexGrow: 0,
            },
          }}
        >
          {studentList.map((member) => (
            <Cell key={member.id} id={member.id} rowId={absentRowIndex}>
              <StudentChip
                isActivityStarted={isActivityStarted}
                id={member.id}
                rowId={absentRowIndex}
                member={member}
              />
            </Cell>
          ))}
        </Box>
      </StyledRow>
    </div>
  );
};

export default AbsentRow;
