import styled, { css } from 'styled-components';

export const LoaderWrap = styled.div`
  width: 100%;
  height: 100%;
  .card-loader {
    width: 100%;
    height: 100%;
    > rect {
      width: 100%;
      height: 100%;
      border-radius: 1em;
    }
  }

  .list-loader {
    width: 100%;
    height: 100vh;
    > rect {
      width: 100%;
      height: 100%;
    }
  }

  .profile-loader {
    height: 100vh;
    ${({ transformStyle }) => transformStyle && css`
      transform: translate(0em, -7.5em);
    `}
    > rect {
      width: 100%;
      height: 100%;
    }
  }

  ${({ mobile }) => mobile && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

export const LineWrap = styled.div`
  width: ${({ width }) => width || '100%'};
  ${({ activityButtonsWrapper, width }) => activityButtonsWrapper && css`
    width: 300px;
    @media (min-width: 1280px) {
      width: ${width};
    }
  `}  
`;
