import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  selectors as CurriculumSelectors,
} from '../../reducers/curriculums';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';
import PostCreatedSection from '../../components/Modals/SectionCreationModal/PostCreatedSection';

const mapStateToProps = (state, { sectionDetail = {} }) => {
  const { course: courseId } = sectionDetail;
  const courseData = CourseSelectors.getCourseDetailById(state, courseId);
  const { curriculum: curriculumId } = courseData;
  const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);

  return {
    curriculumDetail,
    courseId,
    courseData,
    curriculumId,
    subjects: CurriculumSelectors.getSubjectList(state, curriculumId),
  };
};

export default withRouter(connect(mapStateToProps)(PostCreatedSection));
