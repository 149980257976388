/* eslint-disable import/prefer-default-export */
import {
  getActivityAbsentGroup,
  getActivityGroups,
  moveActivityStudentToAbsentGroup,
  moveActivityStudentToGroup,
  moveStudentToNewActivityGroup,
  rearrangeGroupsSize,
  resetPracticeForGroup,
  swapActivityStudentGroups,
} from '@apis/groupActivity';
import { useCallback } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { Updater } from 'react-query/types/core/utils';
import { activityGroupType } from '../../types';

const placeholderArray = [];
const placeholderObject = {};
export const useActivityGroups = (groupActivityId) => {
  const response = useQuery<activityGroupType[]>(
    ['getActivityGroups', groupActivityId],
    async () => {
      const res = (await getActivityGroups(groupActivityId)) as unknown as {
        data: activityGroupType[];
      };
      return res?.data || [];
    },
    {
      enabled: !!groupActivityId,
      placeholderData: placeholderArray,
      staleTime: 1000 * 60 * 15,
    },
  );

  return response;
};
export const useActivityAbsentGroup = (groupActivityId) => {
  const response = useQuery<activityGroupType>(
    ['getActivityAbsentGroup', groupActivityId],
    async () => {
      const res = (await getActivityAbsentGroup(groupActivityId)) as unknown as {
        data: activityGroupType;
      };
      return res?.data || [];
    },
    {
      enabled: !!groupActivityId,
      placeholderData: placeholderObject,
      staleTime: 1000 * 60 * 15,
    },
  );

  return response;
};
export const useSwapStudentsMutation = (groupActivityId) => {
  const mutation = useMutation(swapActivityStudentGroups, {
    mutationKey: ['swapActivityStudentGroups', groupActivityId],
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return mutation;
};
export const useMoveStudentToGroupMutation = (groupActivityId) => {
  const mutation = useMutation(moveActivityStudentToGroup, {
    mutationKey: ['moveStudentToGroup', groupActivityId],
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return mutation;
};
export const useMoveStudentToAbsentGroupMutation = (groupActivityId) => {
  const mutation = useMutation(moveActivityStudentToAbsentGroup, {
    mutationKey: ['moveStudentToAbsentGroup', groupActivityId],
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return mutation;
};
export const useMoveStudentToNewGroupMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(moveStudentToNewActivityGroup, {
    mutationKey: ['moveStudentToNewActivityGroup'],
    retry: 3,
    retryDelay: 5 * 1000,
    onSuccess: ({ data }) => {
      const groupActivityId = data?.new_group?.section_activity;
      if (!groupActivityId) {
        return;
      }
      queryClient.invalidateQueries(['getActivityGroups', groupActivityId]);
      queryClient.invalidateQueries(['getActivityAbsentGroup', groupActivityId]);
    },
  });
  return mutation;
};
export const useRearrangeGroupsSizeMutation = (groupActivityId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(rearrangeGroupsSize, {
    mutationKey: ['rearrangeGroupsSize', groupActivityId],
    retry: 3,
    retryDelay: 5 * 1000,
    onSuccess: () => {
      queryClient.invalidateQueries(['getActivityGroups', groupActivityId]);
    },
  });
  return mutation;
};
export const useResetPracticeForGroup = (groupActivityId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(resetPracticeForGroup, {
    mutationKey: ['resetPracticeForGroup', groupActivityId],
    retry: 3,
    retryDelay: 5 * 1000,
    onSuccess: () => {
      queryClient.invalidateQueries(['getActivityGroups', groupActivityId]);
    },
  });
  return mutation;
};
