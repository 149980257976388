import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import DashboardPracticeTimeSeries from '../../components/DashboardPracticeTimeSeries';
import { selectors as curriculumLessonSelectors } from '../../reducers/curriculumLessons';
import { selectors as ActivitySelectors } from '../../reducers/activity';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as SubjectSelectors } from '../../reducers/sectionSubjects';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', null);

  return {
    examsScoreSummary: SectionSelectors.examsScoreSummary(state),
    lessonById: curriculumLessonSelectors.getLessonById(state),
    sectionId,
    sectionPracticeActivityList: ActivitySelectors.sectionPracticeActivityList(state),
    subjectById: SubjectSelectors.getSubjectById(state),
  };
};
const mapDispatchToProps = {};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardPracticeTimeSeries),
);
