import styled from 'styled-components';

export const UnitDetailButtonGroup = styled.div`
  button {
    margin-top: 1em;
  }
`;

export const UnitDetailScore = styled.div`
  font-size: ${props => props.theme.fontSize.LargerFontSize};
  font-weight: bold;
  margin: .5em 0em;
`;
