import { connect } from 'react-redux';
import StudentRegisterForm from '../../components/StudentRegister/StudentRegisterForm';
import {
  getErrorMessage,
  registerStudent,
} from '../../reducers/register';

export const mapStateToProps = state => ({
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = {
  registerStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentRegisterForm);
