import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { actions } from '../../reducers/sectionStudent';

import QuickInviteStudentForm from '../../components/CourseManagementInviteStudent/QuickInviteStudentForm';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');

  return {
    sectionId,
  };
};

const mapDispatchToProps = {
  submitInviteStudent: actions.sectionsStudentInvite,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickInviteStudentForm));
