import {
  all, call, put,
  takeLeading, select,
} from 'redux-saga/effects';
import * as SectionAPIs from '../../apis/sections';
import {
  SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST,
  actions as SectionSubjectActions,
  selectors as SectionSubjectSelectors,
} from '../../reducers/sectionSubjects';

export function* fetchSectionSubjectList({ payload }) {
  const { sectionId } = payload;
  if (sectionId > 0) {
    const shouldFetch = yield select(SectionSubjectSelectors.getShouldFetch, sectionId);

    if (!shouldFetch) {
      return;
    }

    try {
      const { response, error } = yield call(SectionAPIs.getSectionSubjectList, sectionId);
      if (error) throw error;

      const { data: sectionSubjectList } = response;
      yield put(SectionSubjectActions.fetchSectionSubjectListSuccess(sectionSubjectList, sectionId));
    } catch (error) {
      yield put(SectionSubjectActions.fetchSectionSubjectListFailed(error.message || error));
    }
  }
}

export default function* sectionSubjectsSaga() {
  yield all([
    takeLeading(SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST, fetchSectionSubjectList),
  ]);
}
