import Box from '@components/Atoms/Box';
import Select from '@components/Atoms/Select';
import { useGetPacingGuides } from '@components/Component/Lesson/PacingGuide/hooks';
import Skeleton from '@components/Atoms/Skeleton';
import React, { Suspense, useEffect, useState } from 'react';
import Typography from '@components/Atoms/Typography';

interface PacingGuide {
  id: number;
  title: string;
  hours: number;
  created_at: string;
  updated_at: string;
  curriculum: number;
  subject: number;
}

interface PacingGuideSelectorsGroupProps {
  selectedSubjects: {
    id: number;
    name: string;
    public: boolean;
  }[];
  totalHours: number;
  onSelect: (pacingGuideId: string, subjectId: string | number) => void;
  defaultPacingGuides: { id: number; subject: number }[];
}

const getClosestPacingGuide = (
  durationPerSubject: number,
  pacingGuides?: PacingGuide[],
) => {
  const filteredPacingGuides = pacingGuides?.filter((pacingGuide) => {
    const hours = pacingGuide?.hours ?? 0;
    return hours <= durationPerSubject;
  });

  const closestPacingGuide = filteredPacingGuides?.reduce((prev, curr) => {
    const prevDiff = Math.abs(prev.hours - durationPerSubject);
    const currDiff = Math.abs(curr.hours - durationPerSubject);
    return currDiff < prevDiff ? curr : prev;
  }, filteredPacingGuides?.[0]);

  return closestPacingGuide?.id;
};

interface PacingGuideSelectorProps {
  subject: {
    id: number;
    name: string;
    public: boolean;
  };
  onSelect: (pacingGuideId: string, subjectId: string | number) => void;
  durationPerSubject: number;
  selectedSubjects: { id: number; name: string; public: boolean }[];
  defaultPacingGuides: { id: number; subject: number }[];
}

const PacingGuideSelector = ({
  subject,
  onSelect,
  durationPerSubject,
  selectedSubjects,
  defaultPacingGuides,
}: PacingGuideSelectorProps) => {
  const { data: pacingGuides } = useGetPacingGuides(subject.id);
  const [selectedPacingGuideId, setSelectedPacingGuideId] = useState(
    defaultPacingGuides?.find((pacingGuide) => pacingGuide.subject === Number(subject.id))
      ?.id,
  );

  const handlePacingGuideSelection = (value) => {
    setSelectedPacingGuideId(value);
    onSelect(value, subject.id);
  };

  useEffect(() => {
    if (!defaultPacingGuides?.length) {
      const closestPacingGuideId = getClosestPacingGuide(
        durationPerSubject,
        pacingGuides,
      );

      handlePacingGuideSelection(closestPacingGuideId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationPerSubject, selectedSubjects]);

  const list = pacingGuides
    ?.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
    ?.map((pacingGuide) => ({
      label: pacingGuide.title,
      value: pacingGuide.id,
    }));

  return (
    <Select
      data-testid={`${subject.id}-pacing-guide-selector`}
      showEmptyItem
      value={selectedPacingGuideId}
      list={list ?? []}
      label={`${subject.name} Pacing Guide`}
      id={`${subject.id}-pacing-guide`}
      size="small"
      onChangeHandler={handlePacingGuideSelection}
    />
  );
};

const PacingGuideSelectorsGroup = ({
  totalHours,
  selectedSubjects,
  onSelect,
  defaultPacingGuides,
}: PacingGuideSelectorsGroupProps) => {
  const durationPerSubject = Math.floor(totalHours / selectedSubjects.length);

  return (
    <Box sx={{ display: 'flex', gap: 2, my: 2 }}>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          fontSize: 12,
          color: '#010101',
          letterSpacing: 0,
          fontFamily: 'MontserratRegular',
          maxWidth: '16%',
          textAlign: 'right',
        }}
      >
        Default Pacing Guide:
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
        }}
      >
        {selectedSubjects.map((subject) => (
          <Suspense
            key={subject.id}
            fallback={<Skeleton variant="rectangular" height={40} width="100%" />}
          >
            <PacingGuideSelector
              durationPerSubject={durationPerSubject}
              subject={subject}
              onSelect={onSelect}
              selectedSubjects={selectedSubjects}
              defaultPacingGuides={defaultPacingGuides}
            />
          </Suspense>
        ))}
      </Box>
    </Box>
  );
};

export default PacingGuideSelectorsGroup;
