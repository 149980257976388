
import styled, { css } from 'styled-components';

export const VirtualLessonList = styled.div`
  .MuiAccordionDetails-root{
    padding: 0;
    font-size: inherit;
  }

  .MuiPaper-root{
    border-radius: 0px;
    box-shadow: none;
    border-bottom: 1px solid #f6f7fb;
    margin: 10px 0px;
  }
`;
