import React, { Component, Suspense } from 'react';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import * as PropTypes from 'prop-types';
import Skeleton from '@Atoms/Skeleton';
import { ROLE_TYPE } from '../../utils/enums';
import TeacherLessonReviewPage from '../../containers/LessonReviewPage/TeacherLessonReviewPage';
import StudentLessonReviewPage from '../../containers/LessonReviewPage/StudentLessonReviewPage';

export default class LessonReviewPage extends Component {
  static propTypes = {
    userRole: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    getSectionReviewSessionData: PropTypes.func.isRequired,
    getStudentReviewSessionData: PropTypes.func,
    sectionsStudentGet: PropTypes.func,
    sectionSkillsGet: PropTypes.func,
    userId: PropTypes.any,
  };

  componentDidMount() {
    const { match, userRole, sectionsStudentGet, sectionSkillsGet, userId } = this.props;
    const { sectionId } = match.params;

    if (userRole !== ROLE_TYPE.STUDENT) {
      sectionsStudentGet(sectionId);
    } else {
      sectionSkillsGet(sectionId, null, userId);
    }

    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const reviewId = _get(this.props, 'match.params.reviewId', '');
    const prevReviewId = _get(prevProps, 'match.params.reviewId', '');

    if (!_isEqual(reviewId, prevReviewId)) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    const { getStudentReviewSessionData, getSectionReviewSessionData, userRole, match } =
      this.props;

    const { sectionId, reviewId } = match.params;

    if (userRole !== ROLE_TYPE.STUDENT) {
      getSectionReviewSessionData(sectionId, reviewId);
    } else {
      getStudentReviewSessionData(reviewId);
    }
  };

  render() {
    const { userRole } = this.props;

    if (userRole === ROLE_TYPE.STUDENT) {
      return (
        <Suspense
          fallback={
            <Skeleton
              variant="rectangular"
              sx={{
                width: '80%',
                margin: 'auto',
                height: '25.75rem',
                marginTop: '6.25rem',
              }}
            />
          }
        >
          <StudentLessonReviewPage />
        </Suspense>
      );
    }

    return <TeacherLessonReviewPage />;
  }
}
