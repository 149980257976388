import React from 'react';
import IconWrapper from '@components/Atoms/IconWrapper';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Box from '@components/Atoms/Box';
import Typography from '@components/Atoms/Typography';

const SelectQuestionTooltip = () => (
  <Box display="flex" gap={1} p={1}>
    <IconWrapper size="lg">
      <AutoFixHighIcon fontSize="large" />
    </IconWrapper>
    <Typography
      variant="body3"
      sx={{
        flex: 1,
        color: 'inherit',
      }}
    >
      You can now customize this practice by selecting questions!
    </Typography>
  </Box>
);

export default SelectQuestionTooltip;
