/* eslint-disable react/button-has-type */
import React, { useEffect, useMemo, useState } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import MUIButton from '@mui/material/Button';
import styled from 'styled-components';
import {
  BlockTitle,
  BlockWrap,
  DefaultContentWrap,
} from '../Generals/stylesheets/General.style';
import { TableContentItem, TableHeadWrapper } from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { SectionListWrap } from '../ExamSectionTable/ExamSectionTable.style';
import { BreadcumbsFilterWrap } from '../BreadcumbsFilter/BreadcumbsFilter.style';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { iWApi } from './api';
import { useIWGet } from './hooks';
import { useIWContext, IWContextProvider } from './IWContextProvider';
import InstructionalWalkThroughCreate from './InstructionalWalkThroughCreate';
import IWSider from './Components/IWSider';
import { Button } from '../Generals/stylesheets/Button.style';

const TableButton = styled(Button)`
  height: 1.5rem;
  margin-inline: 0.25rem;
`;
const baseurl = '/instructionalwalkThrough';
const scrollStyle = {
  height: 'calc(100vh - 5.5em)',
  width: '100%',
};

const InstructionalWalkThroughLibrary = ({ match, location, history }) => {
  const {
    currentPageIWId: currentIWId,
    setPageIWId: setIWId,
    setIsSiderOpen,
  } = useIWContext();
  const { data: walkThroughs, invalidate } = useIWGet({
    defaultValue: [],
  });

  const handleCreate = () => {
    const newIWId = Date.now();
    setIWId(newIWId);
  };
  if (currentIWId ) {
    return <InstructionalWalkThroughCreate />;
  }
  return (
    <DefaultContentWrap>
      <BreadcumbsFilterWrap>
        <BreadcumbsFilter isDisplayFilter={false} />
        <MUIButton color="primary" onClick={() => history.push(`${baseurl}/report`)}>
          All Instructional Walkthroughs
        </MUIButton>
      </BreadcumbsFilterWrap>
      <ShadowScrollbar autoHide style={scrollStyle}>
        <BlockWrap>
          <BlockTitle>Instructional walkThrough Templates</BlockTitle>
          <TableHeadWrapper>
            <TableHeader>Name</TableHeader>
            <TableHeader>Last Edited</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableHeadWrapper>
          <SectionListWrap>
            {!walkThroughs && <TableLoader />}
            {walkThroughs &&
              walkThroughs.map((walkThrough) => {
                const updatedAt = new Date(
                  walkThrough.updatedAt || 0,
                ).toLocaleDateString();
                return (
                  <TableContentItem key={walkThrough.id}>
                    <TableContent>{walkThrough.name}</TableContent>
                    <TableContent>{updatedAt}</TableContent>
                    <TableContent>
                      <TableButton
                        submit
                        onClick={() => {
                          setIsSiderOpen(true);
                        }}
                      >
                        Perform
                      </TableButton>

                      <TableButton submit onClick={() => setIWId(walkThrough.id)}>
                        Edit
                      </TableButton>

                      <TableButton
                        submit
                        onClick={() => {
                          iWApi.delete(walkThrough.id);
                          invalidate();
                        }}
                      >
                        Delete
                      </TableButton>
                    </TableContent>
                  </TableContentItem>
                );
              })}
          </SectionListWrap>
          <Button blue onClick={handleCreate} alignRight style={{ marginTop: '1rem' }}>
            Create new Template
          </Button>
        </BlockWrap>
      </ShadowScrollbar>
    </DefaultContentWrap>
  );
};

const InstructionalWalkThroughLibraryWithRouter = withRouter(
  InstructionalWalkThroughLibrary,
);

const InstructionalWalkThrough = () => (
  <IWContextProvider>
    <IWSider />
    <InstructionalWalkThroughLibraryWithRouter />
  </IWContextProvider>
);
export default InstructionalWalkThrough;
