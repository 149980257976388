import { connect } from 'react-redux';
import AnswerBubbleGridChoice from '../../components/OfflineTestAnswerSheet/AnswerBubbleGridChoice';
import { selectors, actions } from '../../reducers/exam';

const mapStateToProps = (state, { question }) => {
  const { id } = question;

  return {
    answer: selectors.getAnswerByQuestionId(state, id),
  };
};

const mapDispatchToProps = {
  examChooseAnswer: actions.examChooseAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerBubbleGridChoice);
