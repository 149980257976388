import React, { useEffect, useState } from 'react';
import Typography from '@components/Atoms/Typography';
import PreviewJson from '@learnosityassessment/Components/PreviewJson';
import LoadingIndicator from '@components/LoadingIndicator';
import ShowIf from '@components/Atoms/ShowIf';
import ShadowScrollbars from '@components/Scrollbar';
import FeedbackGroup from './FeedbackGroup';
import {
  QuestionGenerationServiceEditorWelcome,
  QuestionGenerationEditorWrapper,
} from './styles';
import { LearnosityItem } from './types';

const QuestionGenerationEditor: React.FC<{
  item: LearnosityItem | null;
  isLoading: boolean;
  hasTask: boolean;
  isSubmitting: boolean;
  updateItem: (item: LearnosityItem) => void;
  getLoadingMessage: () => string;
}> = ({ item, isLoading, hasTask, isSubmitting, updateItem, getLoadingMessage }) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    if (item) {
      // The PreviewJson component needs to be initialized after the item is set
      // This is a workaround to ensure the component is marked as rendered only after it has loaded
      const timer = setTimeout(() => {
        setIsRendered(true);
      }, 0);
      return () => clearTimeout(timer);
    } else {
      setIsRendered(false);
    }
  }, [item]);

  if (isSubmitting) {
    return <LoadingIndicator noBackground partial content={getLoadingMessage()} />;
  }

  if (!item) {
    if (isLoading || hasTask) {
      return (
        <LoadingIndicator
          noBackground
          partial
          content="Please wait while we generate questions for you"
        />
      );
    }

    return (
      <QuestionGenerationServiceEditorWelcome>
        <img src="/images/edit-content.png" alt="Edit Content" />
        <Typography variant="h3" sx={{ textAlign: 'center', mt: '5rem' }}>
          Click on the generate button to create questions
        </Typography>
      </QuestionGenerationServiceEditorWelcome>
    );
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        sx={{
          py: '0',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '0.5rem',
          px: '1rem',
        }}
      >
        Review, edit and add more questions to the generated questions
        <ShowIf If={isRendered}>
          <FeedbackGroup item={item} updateItem={updateItem} />
        </ShowIf>
      </Typography>
      <QuestionGenerationEditorWrapper>
        <ShadowScrollbars
          style={{
            width: '100%',
            height: 'calc(70vh)',
          }}
        >
          <PreviewJson
            item={item}
            updateItem={updateItem}
            renderCallback={() => setIsRendered(true)}
          />
        </ShadowScrollbars>
      </QuestionGenerationEditorWrapper>
    </>
  );
};

export default QuestionGenerationEditor;
