import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useGroupMembers } from '@components/PracticeV2/Provider/PracticeDataProvider';
import { usePractice } from '@components/PracticeV2/Provider/PracticeProvider';
import { StudentType } from '@components/PracticeV2/types';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';
import UserChipList from '../UserChipList';

const IndividualWaitingDialog = () => {
  const groupMembers = useGroupMembers();
  const { state } = usePractice();

  const users = React.useMemo(
    () =>
      groupMembers.filter((user) =>
        state.studentsFinishedIndividual.includes(user.student_id),
      ),
    [state.studentsFinishedIndividual, groupMembers],
  );
  return (
    <Dialog
      showCloseIcon={false}
      showActionButtons={false}
      title="Individual Practice Submitted!"
      open={state.isIndividualWaitingDialogOpen}
      aria-labelledby="Moving to Group Activity"
    >
      <Typography variant="body1">
        Please wait for the other group members to submit their answers.
      </Typography>
      <Box display="flex" justifyContent="start" pt="1rem">
        <UserChipList
          users={users}
          nameMapper={(user: StudentType) => (user?.student_name || '') as string}
        />
        <Box flex="1" display="flex" px="0.5rem" alignItems="center">
          <CircularProgress size="1rem" />
        </Box>
      </Box>
    </Dialog>
  );
};

export default React.memo(IndividualWaitingDialog);
