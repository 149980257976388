import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _union from 'lodash/union';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';
import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import _difference from 'lodash/difference';
import { selectors as userSelectors } from '../user';
import { ROLE_TYPE } from '../../utils/enums';

export const SECTIONS_STUDENT_REMOVE = 'sectionsStudent/REMOVE';
export const SECTIONS_STUDENT_REMOVE_SUCCESS = 'sectionsStudent/REMOVE_SUCCESS';
export const SECTIONS_STUDENT_REMOVE_FAIL = 'sectionsStudent/REMOVE_FAIL';

export const SECTIONS_STUDENT_GET = 'sectionsStudent/GET';
export const SECTIONS_STUDENT_GET_SUCCESS = 'sectionsStudent/GET_SUCCESS';
export const SECTIONS_STUDENT_GET_FAIL = 'sectionsStudent/GET_FAIL';

export const SECTIONS_STUDENT_INVITE = 'sectionsStudent/INVITE';
export const SECTIONS_STUDENT_INVITE_SUCCESS = 'sectionsStudent/INVITE_SUCCESS';
export const SECTIONS_STUDENT_INVITE_FAIL = 'sectionsStudent/INVITE_FAIL';

export const SECTIONS_STUDENT_FILTER_ADD = 'sectionsStudent/FILTER_ADD';
export const SECTION_STUDENT_FILTER_ADD_ALL_OR_CANCEL = 'sectionStudent/FILTER_ALL_OR_CANCEL';

export const SECTIONS_STUDENT_FILTER_GRADE_LEVEL = 'sectionsStudent/FILTER_GRADE_LEVEL';
export const SECTIONS_STUDENT_SELECT_ALL_OR_CANCEL_GRADE_LEVEL = 'sectionsStudent/SELECT_ALL_OR_CANCEL_GRADE_LEVEL';

export const SECTIONS_STUDENT_MOVE = 'sectionsStudent/MOVE';
export const SECTIONS_STUDENT_MOVE_SUCCESS = 'sectionsStudent/MOVE_SUCCESS';
export const SECTIONS_STUDENT_MOVE_FAIL = 'sectionsStudent/MOVE_FAIL';

const sectionsStudentMove = (payload, sectionId, callback) => ({
  type: SECTIONS_STUDENT_MOVE,
  payload,
  sectionId,
  callback,
});

const sectionsStudentMoveSuccess = (payload, sectionId) => ({
  type: SECTIONS_STUDENT_MOVE_SUCCESS,
  payload,
  sectionId,
});

const sectionsStudentMoveFail = error => ({
  type: SECTIONS_STUDENT_MOVE_FAIL,
  error,
});

const sectionsStudentSelectAllOrCancelGradeLevel = array => ({
  type: SECTIONS_STUDENT_SELECT_ALL_OR_CANCEL_GRADE_LEVEL,
  payload: array,
});

const sectionsStudentFilterGradeLevel = gradeLevel => ({
  type: SECTIONS_STUDENT_FILTER_GRADE_LEVEL,
  payload: gradeLevel,
});

/**
 *
 * @param {String} sectionId : Section Id
 * @param {String} studentId : Array of Student Id to remove
 */
const sectionsStudentRemove = (sectionId, studentId) => ({
  type: SECTIONS_STUDENT_REMOVE,
  sectionId,
  studentId,
});

/**
 * @param {String} studentId
 * @param {String} sectionId
 */
const sectionsStudentRemoveSuccess = (sectionId, studentId) => ({
  type: SECTIONS_STUDENT_REMOVE_SUCCESS,
  studentId,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsStudentRemoveFail = payload => ({
  type: SECTIONS_STUDENT_REMOVE_FAIL,
  payload,
});

const sectionsStudentInvite = (studentInfoList, sectionId, callback) => ({
  type: SECTIONS_STUDENT_INVITE,
  studentInfoList,
  sectionId,
  callback,
});

/**
 * @param {Array} studentList Array Obj
 * @param {String} sectionId
 */
const sectionsStudentInviteSuccess = (studentList, sectionId) => ({
  type: SECTIONS_STUDENT_INVITE_SUCCESS,
  payload: studentList,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsStudentInviteFail = payload => ({
  type: SECTIONS_STUDENT_INVITE_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 */

const sectionsStudentGet = sectionId => ({
  type: SECTIONS_STUDENT_GET,
  sectionId,
});

/**
 * @param {Array} studentList
 * @param {String} sectionId
 */
const sectionsStudentGetSuccess = (studentList, sectionId) => ({
  type: SECTIONS_STUDENT_GET_SUCCESS,
  studentList,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsStudentGetFail = payload => ({
  type: SECTIONS_STUDENT_GET_FAIL,
  payload,
});

const sectionStudentFilterAdd = id => ({
  type: SECTIONS_STUDENT_FILTER_ADD,
  id,
});

const sectionStudentFilterAddAllOrCancel = filterList => ({
  type: SECTION_STUDENT_FILTER_ADD_ALL_OR_CANCEL,
  filterList,
});

// selectors
const getStudentIds = (state, sectionId) => {
  const userRole = userSelectors.getUserRole(state);

  if (userRole === ROLE_TYPE.STUDENT) {
    const userId = userSelectors.getUserId(state);
    return [userId];
  }

  return _get(state.sectionStudent, `students.${sectionId}.Ids`, []);
};
const getStudentByIds = ({ sectionStudent }, sectionId) => _get(sectionStudent, `students.${sectionId}.byIds`, {});
const getStudentCount = (state, sectionId) => {
  const userRole = userSelectors.getUserRole(state);

  if (userRole === ROLE_TYPE.STUDENT) {
    return 1;
  }

  const studentIds = getStudentIds(state, sectionId);

  return studentIds.length;
};

const getStudentList = (state, sectionId) => {
  const userRole = userSelectors.getUserRole(state);
  if (userRole === ROLE_TYPE.STUDENT) {
    const userData = userSelectors.getCurrentUser(state);
    return [userData];
  }
  const byIds = getStudentByIds(state, sectionId);
  const Ids = getStudentIds(state, sectionId);

  return Ids.map(id => byIds[id]);
};

const getGradeLevelFilter = ({ sectionStudent }) => _get(sectionStudent, 'gradeLevelFilterList', []);

const getStudentListFilter = ({ sectionStudent }) => _get(sectionStudent, 'filterList', []);

const getFilter = (state, sectionId) => {
  const studentIdFilterList = _get(state.sectionStudent, 'filterList', []);

  if (studentIdFilterList.length > 0) {
    return studentIdFilterList;
  }

  const gradeLevelFilterList = getGradeLevelFilter(state);
  const studentList = getStudentList(state, sectionId);
  const studentListByGradeLevel = _groupBy(studentList, 'grade_level');

  return gradeLevelFilterList.reduce((prevValue, gradeLevel) => {
    const studentListFilter = _get(studentListByGradeLevel, gradeLevel, []);

    return [...prevValue, ...studentListFilter.map(item => item.id)];
  }, []);
};

const getStudentListWithFilter = (state, sectionId) => {
  const userRole = userSelectors.getUserRole(state);
  const filterList = getFilter(state, sectionId);
  if (userRole === ROLE_TYPE.STUDENT) {
    const userData = userSelectors.getCurrentUser(state);
    return [userData];
  }
  const byIds = getStudentByIds(state, sectionId);

  if (filterList.length) {
    return filterList.map(id => byIds[id]);
  }

  const Ids = getStudentIds(state, sectionId);
  return Ids.map(id => byIds[id]);
};

const isGettingStudentList = ({ sectionStudent }) => sectionStudent.isGetting;

const getShouldFetch = ({ sectionStudent }, sectionId) => !_get(sectionStudent, `students.${sectionId}`, null);
const getStudentSortByFirstName = (state, sectionId) => {
  const studentList = getStudentList(state, sectionId);

  return _orderBy(studentList, [s => s.last_name.toUpperCase()]);
};

const getIsMoving = ({ sectionStudent }) => _get(sectionStudent, 'isMoving', false);
const getMoveError = ({ sectionStudent }) => _get(sectionStudent, 'moveError', '');

export const selectors = {
  getStudentList,
  getStudentIds,
  getStudentByIds,
  getStudentCount,
  isGettingStudentList,
  getShouldFetch,
  getFilter,
  getStudentListWithFilter,
  getStudentSortByFirstName,
  getGradeLevelFilter,
  getStudentListFilter,
  getIsMoving,
  getMoveError,
};

export const actions = {
  // remove students
  sectionsStudentRemove,
  sectionsStudentRemoveSuccess,
  sectionsStudentRemoveFail,
  // invite students
  sectionsStudentInvite,
  sectionsStudentInviteSuccess,
  sectionsStudentInviteFail,
  // get students
  sectionsStudentGet,
  sectionsStudentGetSuccess,
  sectionsStudentGetFail,

  sectionStudentFilterAdd,
  sectionStudentFilterAddAllOrCancel,

  sectionsStudentFilterGradeLevel,
  sectionsStudentSelectAllOrCancelGradeLevel,
  sectionsStudentMove,
  sectionsStudentMoveSuccess,
  sectionsStudentMoveFail,
};

const initialState = {
  isGetting: false, // is geting student
  isRemoving: false, // is remove student
  isInvite: false, // is invite student
  students: {},
  filterList: [],
  gradeLevelFilterList: [],
  isMoving: false,
  moveError: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    // get student
    case SECTIONS_STUDENT_GET: {
      const { sectionId } = action;
      const shouldFetch = !_get(state.students, sectionId, null);

      return { ...state, isGetting: shouldFetch };
    }
    case SECTIONS_STUDENT_GET_SUCCESS: {
      const { sectionId, studentList } = action;
      const Ids = studentList.map(({ id }) => id);
      const byIds = _keyBy(studentList, 'id');

      return {
        ...state,
        isGetting: false,
        students: {
          ...state.students,
          [sectionId]: {
            Ids,
            byIds,
          },
        },
      };
    }
    case SECTIONS_STUDENT_GET_FAIL:
      return { ...state, isGetting: false };
    // remove student
    case SECTIONS_STUDENT_REMOVE:
      return { ...state, isRemoving: true };
    case SECTIONS_STUDENT_REMOVE_SUCCESS: {
      const { sectionId, studentId } = action;
      const currentIds = _get(state, `students.${sectionId}.Ids`, []);
      const currentByIds = _get(state, `students.${sectionId}.byIds`, {});

      const newIds = _filter(currentIds, id => id !== studentId);
      const newByIds = _omit(currentByIds, studentId);

      return {
        ...state,
        students: {
          ...state.students,
          [sectionId]: {
            Ids: [...newIds],
            byIds: { ...newByIds },
          },
        },
      };
    }
    case SECTIONS_STUDENT_REMOVE_FAIL:
      return { ...state, isRemoving: false };
    // invite student
    case SECTIONS_STUDENT_INVITE:
      return { ...state, isInvite: true };
    case SECTIONS_STUDENT_INVITE_SUCCESS: {
      const { payload: studentList, sectionId } = action;
      const addedIds = studentList.map(item => item.id);
      const addedByIds = _keyBy(studentList, 'id');
      const currentIds = _get(state, `students.${sectionId}.Ids`, []);
      const currentByIds = _get(state, `students.${sectionId}.byIds`, {});

      return {
        ...state,
        isInvite: false,
        students: {
          ...state.students,
          [sectionId]: {
            Ids: _union(currentIds, addedIds),
            byIds: {
              ...currentByIds,
              ...addedByIds,
            },
          },
        },
      };
    }
    case SECTIONS_STUDENT_INVITE_FAIL: {
      return {
        ...state,
        isInvite: false,
      };
    }

    case SECTIONS_STUDENT_FILTER_ADD: {
      const { id } = action;
      const { filterList } = state;
      let newStudentFilter = filterList.filter(studentId => studentId !== id);
      if (filterList.indexOf(id) === -1) {
        newStudentFilter = [...filterList, id];
      }
      return {
        ...state,
        filterList: newStudentFilter,
        gradeLevelFilterList: [],
      };
    }
    case SECTION_STUDENT_FILTER_ADD_ALL_OR_CANCEL: {
      const { filterList } = action;
      return {
        ...state,
        filterList,
        gradeLevelFilterList: [],
      };
    }
    case SECTIONS_STUDENT_FILTER_GRADE_LEVEL: {
      const { payload } = action;
      const isInList = state.gradeLevelFilterList.find(item => item === payload);

      if (isInList) {
        return {
          ...state,
          gradeLevelFilterList: [...state.gradeLevelFilterList.filter(item => item !== payload)],
        };
      }

      return {
        ...state,
        gradeLevelFilterList: [...state.gradeLevelFilterList, payload],
        filterList: [],
      };
    }
    case SECTIONS_STUDENT_SELECT_ALL_OR_CANCEL_GRADE_LEVEL: {
      const { payload } = action;

      return {
        ...state,
        gradeLevelFilterList: payload,
        filterList: [],
      };
    }
    case SECTIONS_STUDENT_MOVE: {
      return {
        ...state,
        isMoving: true,
        moveError: '',
      };
    }
    case SECTIONS_STUDENT_MOVE_SUCCESS: {
      const { payload, sectionId } = action;
      const moveIds = payload.map(item => item.user);
      const currentStudentIds = _get(state, `students.${sectionId}.Ids`, []);
      const currentByIds = _get(state, `students.${sectionId}.byIds`, {});

      const newByIds = _omit(currentByIds, moveIds);
      const newIds = _difference(currentStudentIds, moveIds);
      return {
        ...state,
        isMoving: false,
        moveError: '',
        students: {
          // ...state.students,
          [sectionId]: {
            Ids: [...newIds],
            byIds: { ...newByIds },
          },
        },
      };
    }
    case SECTIONS_STUDENT_MOVE_FAIL:
      return {
        ...state,
        isMoving: false,
        moveError: action.error,
      };
    default:
      return state;
  }
}
