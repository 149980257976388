import {
  getLearnosityLessonReviewList,
  postLearnosityLessonReview,
  removeLearnosityLessonReview,
} from '@apis/practice';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

export interface LearnosityLessonReviewRecord {
  id: number;
  name: string;
  lesson: number;
  section: number;
  type: number;
}

export const useGetLearnosityLessonReviewList = () => {
  const { sectionId, subjectId } = useParams();
  const response = useQuery(
    ['getLearnosityLessonReviewList', sectionId, subjectId],
    async () => {
      const res = (await getLearnosityLessonReviewList(
        sectionId,
        subjectId,
      )) as unknown as {
        response: {
          data: Array<LearnosityLessonReviewRecord>;
        };
      };
      return res?.response?.data;
    },
    {
      enabled: !!sectionId && !!subjectId,
      refetchOnMount: 'always',
      staleTime: 1000 * 60,
    },
  );
  return response;
};

export const usePostLearnosityLessonReview = (lessonId) => {
  const { sectionId, subjectId } = useParams();
  const queryClient = useQueryClient();
  const response = useMutation(
    (payload: { name: string; type: number }) =>
      postLearnosityLessonReview(sectionId, lessonId, payload),
    {
      mutationKey: ['postLearnosityLessonReview', sectionId, lessonId],
      onSuccess: (data) => {
        queryClient.invalidateQueries([
          'lessonReview',
          sectionId,
          `${data?.response?.data?.lesson}`,
        ]);

        queryClient.invalidateQueries([
          'getLearnosityLessonReviewList',
          sectionId,
          subjectId,
        ]);
      },
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};

export const useRemoveLearnosityLessonReview = (lessonId) => {
  const { sectionId, subjectId } = useParams();
  const queryClient = useQueryClient();
  const response = useMutation(() => removeLearnosityLessonReview(sectionId, lessonId), {
    mutationKey: ['removeLearnosityLessonReview', sectionId, lessonId],
    onSuccess: () => {
      queryClient.invalidateQueries([
        'getLearnosityLessonReviewList',
        sectionId,
        subjectId,
      ]);
    },
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return response;
};
