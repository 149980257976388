/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';

export const SKILL_LEVEL_INDICATOR = {
  1: '#d8a19d',
  2: '#d8a19d',
  3: '#fee38f',
  4: '#85be86',
  5: '#85be86',
};

export const TableHeadWrapper = styled.div`
  display: flex;
  border: none;
  color: ${(props) => props.theme.colorStyled.ColorPrimary};
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  border-radius: 0.5em;
  margin-bottom: 1em;
  font-weight: 700;
  line-height: 1.4;
  min-height: 35px;
  padding: 0.5em 1em;

  ${({ border }) =>
    border &&
    css`
      border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
    `}
  ${({ noBackground }) =>
    noBackground &&
    css`
      background-color: transparent;
      border: none;
      padding: 0em 1em;
    `}

  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0px;
    `}
  ${({ smallSize }) =>
    smallSize &&
    css`
      min-height: 3em;
      padding: 0em;
      border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
    `}
  ${({ isStudentListHeader }) =>
    isStudentListHeader &&
    css`
      @media (max-width: 768px) {
        width: unset !important;
      }
    `}
  @media (max-width: 768px) {
    width: -webkit-fill-available;
  }
  ${({ isExamSection }) =>
    isExamSection &&
    css`
      @media (max-width: 768px) {
        overflow: hidden;
        width: 600px;
      }
    `}
`;

export const TableContainerWrap = styled.div`
  width: 100%;
  ${({ scrollable }) =>
    scrollable &&
    css`
      display: table;
    `}
`;

export const TableContainerScrollableWrap = styled.div`
  width: 100%;
  overflow: auto;
`;

export const TableContentGroup = styled.div`
  p {
    text-transform: capitalize;
    font-family: 'MontserratSemiBold';
  }
  span {
    text-transform: lowercase;
    font-family: 'MontserratMedium';
    color: ${(props) => props.theme.colorStyled.ColorHeaderTable};
  }
`;

export const TableContentWrapper = styled.div`
  border-top: none;
  color: #263035;
  font-weight: 700;
  line-height: 1.4;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  ${({ border }) =>
    border &&
    css`
      border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
      padding: 0em;
      overflow: hidden;
    `};
  @media (max-width: 768px) {
    width: -webkit-fill-available;
  }
`;

export const TableContentItem = styled.div`
  display: flex;
  flex: 1;
  border: none;
  min-height: 3em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  &:hover {
    background-color: #f7f7f7 !important;
    border-radius: 0.325em;
  }
  ${({ smallSize }) =>
    smallSize &&
    css`
      min-height: 3em;
      border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
      &:last-child {
        border-bottom: none;
      }
    `}
  ${({ custome }) =>
    custome &&
    css`
      padding: 0.5em 1em;
      margin-bottom: 0.5em;
      border-radius: 0.5em;
      box-shadow: 0px 0px 6px -1px ${(props) => props.theme.colorStyled.ColorBoxShadow};
    `}
  ${({ noHover }) =>
    noHover &&
    css`
      &:hover {
        background-color: transparent !important;
      }
    `}
  ${({ isExamSection }) =>
    isExamSection &&
    css`
      @media (max-width: 768px) {
        overflow: hidden;
        width: 600px;
      }
    `}
`;

export const TableHead = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  ${({ isUnitPracticeTable }) =>
    isUnitPracticeTable &&
    css`
      overflow: hidden;
      max-width: 400px;
      transition: 0.5s ease;
    `}
  color: ${(props) => props.theme.colorStyled.ColorHeaderTable};
  ${({ isMinimize }) =>
    isMinimize &&
    css`
      max-width: 0;
      padding: unset !important;
      transition: 0.5s ease;
    `}
  ${({ isExpand }) =>
    isExpand &&
    css`
      max-width: 100%;
      transition: 0.5s ease;
    `}
  ${({ size }) =>
    size &&
    css`
      flex: 0 0 ${size}px;
      @media (max-width: 768px) {
        flex: 10px 0 60px;
      }
    `};
  ${({ percent }) =>
    percent &&
    css`
      flex: 0 0 ${percent}%;
    `};
  ${({ textCenter }) =>
    textCenter &&
    css`
      justify-content: center;
      text-align: center;
    `};
  ${({ textRight }) =>
    textRight &&
    css`
      justify-content: flex-end;
    `};
  ${({ borderRight }) =>
    borderRight &&
    css`
      border-right: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
      &:last-child {
        border-right: none;
      }
    `};
  ${({ borderLeft }) =>
    borderLeft &&
    css`
      border-left: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
    `};
  ${({ smallSize }) =>
    smallSize &&
    css`
      font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
      padding: 0.5em;
      @media (max-width: 768px) {
        width: 90px;
      }
    `};
  ${({ thinStyle }) =>
    thinStyle &&
    css`
      font-family: 'MontserratRegular';
    `};
  ${({ boldStyle }) =>
    boldStyle &&
    css`
      font-family: 'MontserratBold';
    `};
  ${({ isActive }) =>
    isActive &&
    css`
      color: #4886fe;
    `};
  ${({ paddingLarge }) =>
    paddingLarge &&
    css`
      padding: 0.5em 1em;
    `}
  ${({ shouldRender }) =>
    shouldRender &&
    css`
      @media (max-width: 768px) {
        display: none;
      }
    `}
  a {
    color: #586069;
    &:hover {
      color: #ff5402;
    }
  }
  .chalktalk-up-down-arrow {
    cursor: pointer;
    margin-left: 0.5em;
    font-size: ${(props) => props.theme.fontSize.MediumFontSize};
    &:hover {
      opacity: 0.8;
    }
  }

  ${({ borderBottom }) =>
    borderBottom &&
    css`
      border-bottom: 1px solid #091e4214;
    `};
  ${({ haveResponsive }) =>
    haveResponsive &&
    css`
      @media (max-width: 1170px) {
        flex: 1;
        max-width: 20em;
      }
      @media (max-width: 768px) {
        max-width: 15em;
      }
    `}
`;

export const TableRow = styled(TableHead)`
  color: ${(props) => props.theme.colorStyled.ColorPrimary};
  font-style: ${(props) => (props.italic ? 'italic' : 'initial')};
  ${({ isUnitPracticeTable }) =>
    isUnitPracticeTable &&
    css`
      overflow: hidden;
      max-width: 400px;
      transition: 0.5s ease;
    `}
  .icon-dots {
    font-size: 10px;
    color: ${(props) => props.theme.colorStyled.ColorBorderInput};
  }
  ${({ isMinimize }) =>
    isMinimize &&
    css`
      max-width: 0;
      padding: unset !important;
      transition: 0.5s ease;
    `}
  ${({ isExpand }) =>
    isExpand &&
    css`
      max-width: 100%;
      transition: 0.5s ease;
    `}
  ${({ input }) =>
    input &&
    css`
      input {
        width: 100%;
        text-align: center;
        padding: 5px 0px;
        min-height: 30px;
        &:focus {
          border-radius: 3px;
          border: 1px solid #ddd;
          padding: 5px 0px;
        }
      }
    `};
  ${({ boldStyle }) =>
    boldStyle &&
    css`
      font-family: 'MontserratBold';
    `};
  ${({ largeBorder }) =>
    largeBorder &&
    css`
      border-right: 1.5px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
      &:last-child {
        border-right: none;
      }
    `};
  a {
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
  .chalktalk-close {
    &:hover {
      color: ${(props) => props.theme.colorStyled.ColorWarning};
    }
  }

  ${({ paddingLeft }) =>
    paddingLeft &&
    css`
      padding-left: 25px;
    `};
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: #f5f8ff;
    `}
  ${({ textCapitalize }) =>
    textCapitalize &&
    css`
      text-transform: capitalize;
    `}

  ${({ justifyEnd }) =>
    justifyEnd &&
    css`
      justify-content: flex-end;
    `}
  ${({ textLowercase }) =>
    textLowercase &&
    css`
      text-transform: lowercase;
    `}
`;

export const TableCheckboxLabel = styled.label`
  position: relative;
`;

export const TableCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked {
    & ~ .checkmark {
      height: 1em;
      width: 1em;
      border-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      &:after {
        display: block;
        background-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      }
    }
  }
  &:hover {
    & > input {
      & ~ .checkmark {
        background-color: #ccc;
      }
    }
  }
`;

export const TableCheckMark = styled.span`
  position: absolute;
  top: -0.5em;
  height: 1em;
  width: 1em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  border-radius: 0.325em;
  border: 1px solid #d3d3d3;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
  }
`;

export const TableSkillLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  ${({ level }) => {
    if (level == 3) {
      return css`
        background-color: ${SKILL_LEVEL_INDICATOR[level]};
      `;
    }
    if (level >= 4) {
      return css`
        background-color: ${SKILL_LEVEL_INDICATOR[level]};
      `;
    }
    return css`
      background-color: ${SKILL_LEVEL_INDICATOR[level]};
    `;
  }}
`;

export const TableIcon = styled.i`
  margin-right: 0.5em;
  font-weight: bold;
  ${({ large }) =>
    large &&
    css`
      font-size: ${(props) => props.theme.fontSize.HeadingH5FontSize};
    `}
`;

export const TableWarningIcon = styled.i`
  margin: 1em auto 0.2em;
  font-size: 70px;
  color: ${(props) => props.theme.colorStyled.ColorYellowCheckbox};
  object-fit: contain;
`;

export const TableHeadText = styled.div`
  ${({ hasDataTip }) =>
    hasDataTip &&
    css`
      border-bottom: 1px dashed #aeaeae;
    `};
`;

export const TableEmptyContent = styled.div`
  text-align: center;
  padding: 1em;
`;

export const ActionWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1em;
`;

export const DateText = styled.div`
  padding: 0.5em 1em;
`;

export const TableResponsiveContainer = styled.div`
  overflow: auto;
  transition: all 300ms ease;
  margin-bottom: 8em;
`;

export const TableHeadResponsive = styled(TableHeadWrapper)`
  padding: 0.5em 0em;
  transition: all 300ms ease;
  width: auto;
`;

export const TableContentResponsive = styled(TableContentWrapper)`
  background-color: transparent;
  transition: all 300ms ease;
  padding: 0.5em 0em;
  width: auto;
  @media (max-width: 1024px) {
    min-width: 768px;
  }
`;

export const MobileWrapper = styled.div`
  @media (max-width: 480px) {
    width: fill-available;
    overflow: scroll;
  }
`;
