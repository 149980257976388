const WEEKDAY = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const CLASS_STATUS = {
  REQUIRE_ATTENTION: -1,
  LOCKED: 0,
  CURRENT: 1,
  COMPLETE: 2,
};

const CLASS_TYPE = {
  PLACEMENT_TEST: 'PLACEMENT TEST',
  STANDARD_CLASS: 'STANDARD CLASS',
  REVIEW_CLASS: 'REVIEW CLASS',
  EXAM_CLASS: 'EXAM CLASS',
  PRACTICE_CLASS: 'PRACTICE CLASS',
};

const DRIVE_TYPE = {
  STUDENT_DRIVEN: 'Student Driven',
  TEACHET_DRIVEN: 'Teacher Driven',
};

const classType = {
  STANDARD_CLASS: 'STANDARD CLASS',
  REVIEW_CLASS: 'REVIEW CLASS',
  PRACTICE_CLASS: 'PRACTICE CLASS',
  PLACEMENT_TEST: 'PLACEMENT TEST',
  EXAM_CLASS: 'EXAM CLASS',
};

const VIDEO_STATUS = {
  LOCKED: 0,
  CURRENT: 1,
  COMPLETE: 2,
};

const ROLE_TYPE = {
  STUDENT: 'Student',
  INSTRUCTOR: 'Teacher',
  DISTRICT_ADMIN: 'District Admin',
};

const STANDARD_CLASS_STEP = {
  OER: 'Open Educational Resources (OER)',
  LESSON_VIDEO: 'Lessons',
  PRACTICE: 'Practice',
  INTERVENTION: 'Intervention',
  CLASS_PLAN: 'Lesson Plans',
  RECORD_ATTENDANCE: 'Record Attendance',
};

const STANDARD_CLASS_STEP_STATUS = {
  CURRENT: 'current-progress',
  LOCKED: 'locked',
  COMPLETED: 'icon-chalktalk-check',
};

const UNDEFINED_SCORE = '--';

const PRACTICE_TYPE = {
  ADAPTIVE_PRACTICE: 0,
  ONLINE_LESSON_PRACTICE: 1,
  STANDARD_PRACTICE: 2,
  LEARNOSITY_ADAPTIVE_PRACTICE: 3,
  LEARNOSITY_LESSON_REVIEW: 4,
};

const QUESTION_TYPES = {
  SHORT_ANSWER: 0,
  SINGLE_CHOICE: 1,
  MULTIPLE_CHOICES: 2,
  GRID_IN: 4,
};

const QUESTION_END_BTN_TYPES = {
  SUBMIT: 1,
  BACK_TO_CURRENT: 2,
  NEXT_QUESTION: 3,
  EXIT: 4,
  PREV_NEXT_QUESTION: 5,
  PREV_PREV_NEXT_QUESTION: 6,
  NEXT_PREV_NEXT_QUESTION: 7,
};

const QUESTION_END_BTN_TEXT = {
  [QUESTION_END_BTN_TYPES.SUBMIT]: 'submit',
  [QUESTION_END_BTN_TYPES.BACK_TO_CURRENT]: 'back to current',
  [QUESTION_END_BTN_TYPES.NEXT_QUESTION]: 'next question',
  [QUESTION_END_BTN_TYPES.EXIT]: 'exit',
  [QUESTION_END_BTN_TYPES.PREV_PREV_NEXT_QUESTION]: '< Previous',
  [QUESTION_END_BTN_TYPES.NEXT_PREV_NEXT_QUESTION]: 'Next >',
  [QUESTION_END_BTN_TYPES.SHOW_EXPLANATION]: 'Show Explanation',
};

const LOCATION = {
  INSIGHT: 'insight',
  DASHBOARD: 'dashboard',
  CLASSES: 'classes',
  PRACTICE: 'practice',
  EXAM: 'exams',
  REVIEW_ANSWER: 'review-answer',
  ERROR: 'error',
  TESTS: 'tests',
  TRAINING: 'training',
  EMPTY: '',
};

const REPORT_TYPES = {
  NONE: 0,
  SHARED_ASSET: 'Shared Asset',
  QUESTION: 'Question',
  EXPLANATION: 'Answer Explanation',
};

const COMPONENTS = {
  OER: 'Open Educational Resources',
  LESSONS: 'Lessons and Lesson Plans',
  PRACTICE: 'Practice Sessions',
  INTERVENTION: 'Intervention',
  ESTIMATED_SCORE: 'Estimated Scores',
  READING_GRADE_LEVEL: 'Reading Grade Levels',
  EXAM: 'Exam Sessions',
  EXAM_SCORE: 'Exam Scores',
  OTHER: 'Other',
};

const TEST_TYPE = {
  REVIEW: 'TEST_TYPE_REVIEW',
  EXAM: 'TEST_TYPE_EXAM',
  PRACTICE: 'TEST_TYPE_PRACTICE',
};

const QUESTION_RESULT = {
  CORRECT: 'Correct',
  INCORRECT: 'Incorrect',
};

const SHARED_ASSET_TYPE = {
  PROMPT: 'Prompt',
  GRAPH: 'Graph',
  TABLE: 'Table',
  PASSAGE: 'Reading Passage',
  INSTRUCTIONS: 'Instructions',
  ANSWER: 'Answer',
  CHOICE: 'Choice',
  CORRECT_CHOICE: 'Correct Choice',
  EXPLANATION: 'Explanation',
  ANSWER_RANGE: 'Answer Range',
  GRAMMAR_PASSAGE: 'Grammar Passage',
};

const SOCKET_TYPES = {
  PRACTICE_UNLOCKED: 'PRACTICE_UNLOCKED',
  TEST_ECHO: 'TEST_ECHO',
  ADDITIONAL_PRACTICE_ASSIGNED: 'ADDITIONAL_PRACTICE_ASSIGNED',
  LESSON_COMPLETED: 'LESSON_COMPLETED',
  EXAM_UNLOCKED: 'EXAM UNLOCKED',
  UNLOCKED: 'UNLOCKED',
  PAUSE: 'PAUSE',
  RESET: 'RESET',
  EXAM_STARTED: 'EXAM_STARTED',
  EXAM_COMPLETED: 'EXAM_COMPLETED',
  EXAM_SCORED: 'EXAM_SCORED',
  ENABLE_REVIEW: 'ENABLE_REVIEW',
  DISABLE_REVIEW: 'DISABLE_REVIEW',
  UPDATE_STUDENT_ACTIVITY_STATUS: 'UPDATE_STUDENT_ACTIVITY_STATUS',
  UPDATE_STUDENT_GROUPINGS: 'UPDATE_STUDENT_GROUPINGS',
  START_GROUP_ACTIVITY: 'START_GROUP_ACTIVITY',
  UPDATE_STUDENT_INDIVIDUAL_ACTIVITY_ANSWER: 'UPDATE_STUDENT_INDIVIDUAL_ACTIVITY_ANSWER',
  UPDATE_STUDENT_GROUP_ACTIVITY_ANSWER: 'UPDATE_STUDENT_GROUP_ACTIVITY_ANSWER',
  SCORE_CALCULATION_COMPLETED: 'SCORE_CALCULATION_COMPLETED',
  QGS_QUESTION: 'QGS_QUESTION',
};

const CSV_FILE_NAME = {
  EXAM_CLASS: 'Exam-class-score-data.csv',
  STANDARD_CLASS: 'Standard-class-score-data.csv',
  REVIEW_CLASS: 'Review-class-score-data.csv',
  PRACTICE_CLASS: 'Practice-class-score-data.csv',
};

const EXAM_TYPE = {
  ONLINE: 0,
  OFFLINE: 1,
};

const SECTION_STATUS = {
  UNLOCKED: 'Unlocked',
  PAUSED: 'Paused',
  LOCKED: 'Locked',
  COMPLETED: 'Completed',
};
const COURSE_TYPE = {
  NEW_DEMO_BETA: 'New Demo Beta',
  DEMO: 'Demo Course',
  ACTIVE: 'Active Course',
};

const STUDENT_ADD_TYPE = {
  QUICK_ADD: 'quickAdd',
  MANUAL_ADD: 'manualAdd',
};

const SUMMARY_TYPE = {
  DARK: 'dark',
  YELLOW: 'yellow',
};

const RESULT_STATUS = {
  PENDING: 0,
  GRADED: 1,
};

const SUBSCRIPTION_TYPE = [
  'Academic Year',
  'Academic Semester',
  'Other',
  'Free Experience',
  'Booster Program',
];

const CURRICULUM_TYPE = {
  SAT: 1,
  ACT: 2,
  IM: 4,
  EM: 5,
};

export {
  RESULT_STATUS,
  WEEKDAY,
  CLASS_STATUS,
  CLASS_TYPE,
  ROLE_TYPE,
  VIDEO_STATUS,
  STANDARD_CLASS_STEP,
  UNDEFINED_SCORE,
  PRACTICE_TYPE,
  QUESTION_TYPES,
  QUESTION_END_BTN_TYPES,
  QUESTION_END_BTN_TEXT,
  LOCATION,
  REPORT_TYPES,
  STANDARD_CLASS_STEP_STATUS,
  TEST_TYPE,
  QUESTION_RESULT,
  SHARED_ASSET_TYPE,
  SOCKET_TYPES,
  classType,
  DRIVE_TYPE,
  COMPONENTS,
  CSV_FILE_NAME,
  EXAM_TYPE,
  SECTION_STATUS,
  COURSE_TYPE,
  STUDENT_ADD_TYPE,
  SUMMARY_TYPE,
  SUBSCRIPTION_TYPE,
  CURRICULUM_TYPE,
};
