import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { QUESTION_TYPES, REPORT_TYPES } from '../../utils/enums';
import {
  QuestionWrap,
  QuestionHeaderWrap,
  QuestionProgress,
  QuestionContentWrap,
  QuestionAnswer,
  QuestionAction,
  QuestionOrderWrapper,
  QuestionOrderNumber,
  QuestionDesc,
} from '../QuestionItem/QuestionItem.style';
import { Button, WarningIcon } from '../Generals/stylesheets/Button.style';
import MathJaxItem from '../MathJaxContainer';

const scrollStyle = {
  height: 'calc(100vh - 20em)',
  width: '100%',
};

export default class AnswerableQuestionItem extends Component {
  static propTypes = {
    questionSelected: PropTypes.object,
    totalQuestions: PropTypes.number,
    prevQuestion: PropTypes.func,
    nextQuestion: PropTypes.func,
    order: PropTypes.number,
    index: PropTypes.number,
    shouldDisplayGrammarHelper: PropTypes.bool,
    selectAnswer: PropTypes.func.isRequired,
    currentAnswer: PropTypes.any,
    openReportErrorModal: PropTypes.func,
    questionId: PropTypes.any,
  };

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.QUESTION, questionId);
  };

  renderAnswer = () => {
    const { questionSelected, selectAnswer, currentAnswer } = this.props;
    const answerType = _get(
      questionSelected,
      'exam_question.question.question_format',
    );
    const choices = _get(
      questionSelected,
      'exam_question.question.choices',
      [],
    );
    const correctChoice = _get(
      questionSelected,
      'exam_question.question.correct_choices',
      [],
    );

    switch (answerType) {
      case QUESTION_TYPES.GRID_IN: {
        const correctAnswerArr = _get(
          questionSelected,
          'exam_question.question.answers',
          '',
        );
        // const transformCorrectAnswer = transformGrinInAnswer(correctAnswer);
        // const correctAnswerArr = transformCorrectAnswer.split('');
        const score = _get(questionSelected, 'score', {});
        return (
          <AnswerGridChoice
            selectedValue={(currentAnswer || '').split('')}
            correctAnswer={correctAnswerArr}
            score={score}
            shouldDisplayAnswer
            chooseAnswer={selectAnswer}
          />
        );
      }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default:
        return (
          <AnswerMultiChoice
            chooseAnswer={selectAnswer}
            choices={choices}
            correctChoice={correctChoice}
            userChoice={currentAnswer || []}
            shouldDisplayAnswer
          />
        );
    }
  };

  renderButtonGroup = () => {
    const {
      index, totalQuestions, nextQuestion, prevQuestion,
    } = this.props;
    const shouldRenderPrevButton = index > 0;
    const shouldRenderNextButton = index < totalQuestions - 1;

    return (
      <React.Fragment>
        {shouldRenderPrevButton ? (
          <Button blue textUppercase onClick={prevQuestion}>
            {'<  Previous'}
          </Button>
        ) : (
          <div />
        )}
        {shouldRenderNextButton ? (
          <Button blue textUppercase onClick={nextQuestion}>
            {'Next  >'}
          </Button>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  };

  render() {
    const {
      questionSelected,
      totalQuestions,
      order,
      index,
      shouldDisplayGrammarHelper,
    } = this.props;
    const getQuestionContent = _get(
      questionSelected,
      'exam_question.question.prompt.text',
      '',
    );
    return (
      <QuestionWrap>
        <QuestionHeaderWrap>
          <QuestionProgress>
            Question
            <span>{`${index + 1} / ${totalQuestions}`}</span>
          </QuestionProgress>
          <WarningIcon
            className="chalktalk-error-wr"
            onClick={this.openReportErrorModal}
          />
        </QuestionHeaderWrap>
        <QuestionContentWrap practiceQuestion>
          <ShadowScrollbar autoHide style={scrollStyle}>
            {shouldDisplayGrammarHelper && (
              <QuestionOrderWrapper>
                <span>This question refers to </span>
                <QuestionOrderNumber>{order}</QuestionOrderNumber>
              </QuestionOrderWrapper>
            )}
            <QuestionDesc>
              <MathJaxItem data={getQuestionContent} />
            </QuestionDesc>
            <QuestionAnswer>{this.renderAnswer()}</QuestionAnswer>
          </ShadowScrollbar>
          <QuestionAction>{this.renderButtonGroup()}</QuestionAction>
        </QuestionContentWrap>
      </QuestionWrap>
    );
  }
}
