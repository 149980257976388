import React from 'react';
import { Formik, Form } from 'formik';
import Modal from 'react-modal';
import _intersectionWith from 'lodash/intersectionWith';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import { ActionGroup, Button } from '../Generals/stylesheets/Button.style';
import { ModalHeader, ModalCLose } from '../Generals/stylesheets/General.style';
import { CLASS_DAY_OPTION } from '../../utils/constants';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Required'),
  subjects: Yup.string().trim().required('Required'),
  classDuration: Yup.number()
  .required('Required field')
  .positive()
  .integer()
  .min(35, 'Class duration must be greater than or equal to 35 minutes')
  .max(200, 'Class duration must be less than or equal to 200 minutes')
  .test(
    'mulOf5',
    'Class duration must be multiple of 5',
    (value) => Number(value) % 5 === 0,
  ),
});

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '640px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class SectionEditForm extends React.PureComponent {
  renderSubjectOptions = (subject, index) => {
    if (subject.name === 'Introduction') {
      return '';
    }
    return subject;
  };

  renderFormContent = () => {
    const { subjects, onClose } = this.props;
    const filteredSubjects = subjects.filter(this.renderSubjectOptions);
    return (
      <Form>
        <FormInput type="text" name="name" label="Name" />
        <FormInput
          type="select"
          name="subjects"
          label="Subjects"
          options={filteredSubjects}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
          isMulti
        />
        <FormInput
          type="select"
          name="classDay"
          label="Class Day"
          options={CLASS_DAY_OPTION}
          getOptionValue={(option) => option.day}
          getOptionLabel={(option) => option.name}
          isMulti
        />
        <FormInput type="number" name="classDuration" label="Duration" step="5" max={200} min={35} />
        <ActionGroup center>
          <Button submit>Save</Button>
          <Button onClick={onClose}>Cancel</Button>
        </ActionGroup>
      </Form>
    );
  };

  render() {
    const {
      initialValues = {}, isOpen, onClose, onSubmit,
    } = this.props;
    const { classDay, ...rest } = initialValues;
    const mappedClassDay = _intersectionWith(
      CLASS_DAY_OPTION,
      classDay,
      (a, b) => a.day == b.day,
    );

    const newInititalValues = {
      ...rest,
      classDay: mappedClassDay,
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Edit Section
          <ModalCLose className="chalktalk-close" onClick={onClose} />
        </ModalHeader>
        <Formik
          initialValues={newInititalValues}
          onSubmit={onSubmit}
          render={this.renderFormContent}
          validationSchema={validationSchema}
        />
      </Modal>
    );
  }
}

SectionEditForm.propTypes = {
  initialValues: PropTypes.object,
  onCancel: PropTypes.func,
  subjects: PropTypes.array,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SectionEditForm;
