/* eslint-disable consistent-return */
import React, { Component } from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';
import LessonList from './LessonList';
import LessonStudenTable from './LessonStudentTable';
import ListLoader from '../LoadingPlaceholder/ListLoader';
import {
  RecordAttendanceTabWrap,
  RecordAttendanceWrap,
} from '../RecordAttendanceTab/RecordAttendanceTab.style';
import { NoContent } from '../Generals/stylesheets/General.style';

export default class DashboardLessonActivityReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSession: '',
      unitId: '',
      studentList: [],
      isLoading: false,
    };
  }

  static propTypes = {
    examsScoreSummary: PropTypes.array,
    unitTree: PropTypes.object,
    lessonActivityById: PropTypes.object,
    sectionId: PropTypes.any,
    studentList: PropTypes.array,
    lessonAssignmentBulk: PropTypes.func.isRequired,
    skillByLessonId: PropTypes.object,
    lessonActivitySummaryById: PropTypes.object,
    isGettingSectionUnit: PropTypes.bool,
    currentUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    match: PropTypes.shape(),
    courseDetail: PropTypes.object,
  };

  // !Important WARNING
  componentDidUpdate(prevProps, prevState) {
    const {
      sectionId,
      studentList,
      examsScoreSummary,
      skillByLessonId = {},
    } = this.props;
    const {
      studentList: prevStudentList,
      examsScoreSummary: prevExamsScoreSummary,
      sectionId: prevSectionId,
      skillByLessonId: prevSkillByLessonId,
    } = prevProps;
    const { selectedSession, unitId } = this.state;
    const { selectedSession: prevSelectedSession } = prevState;
    if (
      (selectedSession !== prevSelectedSession && selectedSession) ||
      (sectionId !== prevSectionId && sectionId)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoading: true });
    }
    if (
      (examsScoreSummary !== prevExamsScoreSummary ||
        studentList !== prevStudentList ||
        selectedSession !== prevSelectedSession ||
        skillByLessonId !== prevSkillByLessonId) &&
      studentList &&
      selectedSession &&
      examsScoreSummary
    ) {
      this.handleListStudent(
        studentList,
        selectedSession,
        unitId,
        examsScoreSummary,
        skillByLessonId,
      );
    }
  }

  // !Important WARNING
  // eslint-disable-next-line consistent-return
  handleListStudent = (
    studentList,
    selectedSession,
    unitId,
    examsScoreSummary,
    skillByLessonId,
  ) => {
    const result = [];
    if (!studentList.length) return this.setState({ isLoading: false });

    studentList.forEach((student) => {
      const skillLevel = _get(
        skillByLessonId,
        `${selectedSession}.${student.id}.current`,
        0,
      );
      const inititalSkill = _get(
        skillByLessonId,
        `${selectedSession}.${student.id}.initial`,
        0,
      );
      const skillImprovement = Math.round((skillLevel - inititalSkill) * 100) / 100;

      if (!examsScoreSummary.length) {
        return result.push({
          studentName: `${student.first_name} ${student.last_name}`,
          last_name: student.last_name,
          userId: student.id,
          skillLevel: Math.round(skillLevel),
          skillImprovement,
        });
      }
      const arrayExamByUserId = examsScoreSummary
        .filter(
          (x) =>
            (x.lesson
              ? x.lesson === selectedSession && x.unit === unitId
              : x.unit === unitId) && x.user === student.id,
        )
        .sort((A, B) => moment(B.last_activity).diff(moment(A.last_activity)));

      if (!arrayExamByUserId.length) {
        return result.push({
          studentName: `${student.first_name} ${student.last_name}`,
          last_name: student.last_name,
          userId: student.id,
          skillLevel,
          skillImprovement,
        });
      }

      result.push({
        studentName: `${student.first_name} ${student.last_name}`,
        last_name: student.last_name,
        userId: student.id,
        practiceCount: arrayExamByUserId[0].session_count,
        skillLevel,
        score: Math.round(
          ((arrayExamByUserId[0].score || 0) / (arrayExamByUserId[0].max_score || 1)) *
            100,
        ),
        skillImprovement,
        lastActivity: arrayExamByUserId[0].last_activity,
      });
    });
    this.setState({ studentList: result, isLoading: false });
  };

  saveSelectedSession = (selectedSession, unitId) => {
    this.setState({
      selectedSession,
      unitId,
    });
  };

  renderLessonList = (lessonList) => {
    const { selectedSession, activePage } = this.state;
    const { isGettingSectionUnit } = this.props;

    if (isGettingSectionUnit) return <ListLoader />;

    return (
      <LessonList
        lessonListData={lessonList}
        selectedSession={selectedSession}
        saveSelectedSession={this.saveSelectedSession}
        activePage={activePage}
      />
    );
  };

  handleUnitLessons = (unit) => {
    const { courseDetail } = this.props;
    const children = _get(unit, 'children', []);
    let unitLessonList = [];
    const hasUnitPractice = _get(unit, 'meta.unit_practice', false);

    children.forEach((child) => {
      const childLessons = this.handleUnitLessons(child);
      unitLessonList = unitLessonList.concat(childLessons);
    });

    const {
      examsScoreSummary = [],
      studentList = [],
      lessonActivitySummaryById,
      unitTree,
    } = this.props;
    const { lessons = [], name, id } = unit;

    lessons.forEach((item) => {
      const canPractice = _get(item, 'can_practice', false);
      if (!(hasUnitPractice || canPractice || courseDetail?.standard_practice_enabled))
        return;

      let sumScore = 0;
      let sumMaxScore = 0;
      let percent = 0;

      if (examsScoreSummary.length) {
        const listExams = examsScoreSummary.filter((x) =>
          x.lesson ? x.lesson === item.id && x.unit === id : x.unit === id,
        );
        const userHavePractice = listExams.map((x) => x.user);
        const arrUserHavePractice = new Set(userHavePractice);
        if (studentList.length) {
          percent = Math.round((arrUserHavePractice.size / studentList.length) * 100);
        }
        listExams.forEach((exam) => {
          sumScore += exam.score;
          sumMaxScore += exam.max_score;
        });
      }

      const isFinishLesson = _get(
        lessonActivitySummaryById,
        `${item.id}.completed`,
        false,
      );
      unitLessonList.push({
        nameLesson: item.name,
        nameUnit: name,
        id: item.id,
        unitId: id,
        groupOrder: item.group_order,
        score: Math.round((sumScore / (sumMaxScore || 1)) * 100),
        percent,
        isFinishLesson,
      });
    });
    return unitLessonList;
  };

  handleStudentList = () => {
    const { unitTree } = this.props;
    const units = _get(unitTree, 'units', []);
    let lessonList = [];

    units.forEach((unit) => {
      const unitLessons = this.handleUnitLessons(unit);
      lessonList = lessonList.concat(unitLessons);
    });

    const finalLessonList = lessonList.sort((a, b) => {
      // nameLesson return string "2.2 any text"
      const GetLessonNumber = (nameLesson) => {
        const lessonUnitAndNumber = nameLesson?.split(' ')?.[0] ?? '0.0';
        const [unit, lessonNumber] = lessonUnitAndNumber.split('.');
        return [unit, lessonNumber];
      };
      if (GetLessonNumber(a.nameLesson)[0] !== GetLessonNumber(b.nameLesson)[0]) {
        return GetLessonNumber(a.nameLesson)[0] - GetLessonNumber(b.nameLesson)[0];
      }
      return GetLessonNumber(a.nameLesson)[1] - GetLessonNumber(b.nameLesson)[1];
    });
    return finalLessonList;
  };

  handleLessonAssignmentBulk = (selectedUser) => {
    const selectedUserList = Object.values(selectedUser).filter((s) => s.checked);
    const { unitId, selectedSession } = this.state;
    const { currentUserId, lessonAssignmentBulk, match, unitTree } = this.props;
    const { sectionId } = match.params;
    const units = _get(unitTree, 'units', []);
    let lesson = [];
    /*
    1.I checked the dashboard cards for practice which can be assign all the card have a unit practice or lesson practice
    2. The idea to Found the units lessons in selected subject then if it's not a lesson it's a unit practice
    */

    units.forEach((unit) => {
      if (unit.lessons.length > 0) {
        lesson = unit.lessons.filter((x) => x.id === unitId);
      }
    });
    /*
     1. If its not found in the lesson then it's a unit practice
     2. unit practice didn't have a lesson_group id in our system
     3.If it's math or science then the lesson_group same as lesson
     */

    let lesson_group = null;
    if (unitTree.practice_type == 0) {
      lesson_group = selectedSession;
    } else {
      lesson_group = lesson.length === 0 ? null : selectedSession;
    }
    const assignList = selectedUserList.map((student) => ({
      section: +sectionId,
      unit: unitId,
      lesson_group,
      assigned_by: currentUserId,
      assigned_to: student.id,
    }));

    lessonAssignmentBulk(sectionId, assignList);
  };

  render() {
    const { studentList, selectedSession, isLoading } = this.state;
    const { courseDetail } = this.props;
    const finalLessonList = this.handleStudentList();
    return (
      <RecordAttendanceTabWrap>
        {finalLessonList.length > 0 ? (
          <>
            {this.renderLessonList(finalLessonList)}
            <RecordAttendanceWrap>
              <LessonStudenTable
                studentData={studentList}
                selectedSession={selectedSession}
                isLoading={isLoading}
                handleLessonAssignmentBulk={this.handleLessonAssignmentBulk}
                courseDetail={courseDetail}
              />
            </RecordAttendanceWrap>
          </>
        ) : (
          <NoContent>No Lesson</NoContent>
        )}
      </RecordAttendanceTabWrap>
    );
  }
}
