/* eslint-disable camelcase */
import React, { createContext, useContext, useMemo, useEffect, useCallback } from 'react';
import { SkillRecord } from '@CT-Types/lessonDomain';
import { Passage } from '@components/QuestionGenerationPassages/types';
import { StandardRecord } from '@components/Component/Lesson/LessonContainer/Types';
import { useGetSectionSubjectUnitLesson } from 'chalktalk-react/hooks/useGetSectionSubjectUnitLesson';
import useSubjects from './hooks/useGetSubjects';
import ContentFilterElement from './components/ContentFilterElement';
import useStandardReducer from './hooks/useStandardReducer';
import useLessonReducer from './hooks/useLessonReducer';
import useSkillReducer from './hooks/useSkillReducer';
import { LessonRecord, SubjectRecord } from './types';

type ContentFilterContextType = {
  subjects: SubjectRecord[];
  setSubjectOverride: (subjectId: number | undefined) => void;
  contentFilterElement: React.FC<React.ElementType>;
  getLessonsFromStandardId: (standardId: number) => unknown[];
  lessonContext: {
    lessonState: {
      selectedLessons: number[];
      recommendedLessons: {
        [lessonId: number]: {
          standards: string[];
          skills: string[];
        };
      };
      recommendedStandards: Record<number, string[]>;
      recommendedSkills: Record<number, string[]>;
      prioritizedStandards: Record<number, string[]>;
      prioritizedSkills: Record<number, string[]>;
    };
    reverseLessonAlignmentMap: {
      standards: {
        [lessonId: number]: number[];
      };
      skills: {
        [lessonId: number]: number[];
      };
    };
    setSelectedLessons: (lessons: number[]) => void;
    getLessonById: (id: number) => LessonRecord | null;
  };
  standardContext: {
    standardState: {
      selectedStandards: number[];
      priorityStandards: number[];
      removedStandards: number[];
      manualSelectedStandards: number[];
      recommendedStandards: {
        [standardId: number]: {
          lessons: string[];
          skills: string[];
        };
      };
    };
    skillStandardAlignmentMap: {
      [skillId: number]: number[];
    };
    setSelectedStandards: (standards: number[]) => void;
    setRecommendedStandards: (standards: number[]) => void;
    setManuallySelectedStandards: (standards: number[]) => void;
    getStandardById: (id: number) => StandardRecord | null;
    getStandardTree: () => unknown;
    getSelectedStandardObjects: () => StandardRecord[];
    setDisableToast: (disable: boolean) => void;
    setRemovedStandards: (standards: number[]) => void;
  };
  skillContext: {
    skillState: {
      selectedSkills: number[];
      prioritySkills: number[];
      manualSelectedSkills: number[];
      removedSkills: number[];
      recommendedSkills: {
        [skillId: number]: {
          lessons: string[];
          standards: string[];
        };
      };
    };
    setSelectedSkills: (skills: number[]) => void;
    setRecommendedSkills: (skills: number[]) => void;
    setManuallySelectedSkills: (skills: number[]) => void;
    getSkillById: (id: number) => SkillRecord | null;
    getSkillTree: () => unknown;
    getSelectedSkillObjects: () => SkillRecord[];
    setDisableToast: (disable: boolean) => void;
    setRemovedSkills: (skills: number[]) => void;
  };
  selectedBlooms: number | null;
  setSelectedBlooms: (blooms: number | null) => void;
  selectedDok: number | null;
  setSelectedDok: (dok: number | null) => void;
  selectedPassage: Passage | null;
  setSelectedPassage: (passage: Passage | null) => void;
  clearSelection: () => void;
  selectedReadability: string | null;
  selectedGrade: string | null;
  setSelectedReadability: (readability: string | null) => void;
  setSelectedGrade: (grade: string | null) => void;
};

const ContentFilterContext =
  createContext<ContentFilterContextType | undefined>(undefined);

export const ContentFilterProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedBlooms, setSelectedBlooms] = React.useState<number | null>(null);
  const [selectedDok, setSelectedDok] = React.useState<number | null>(null);
  const [selectedReadability, setSelectedReadability] =
    React.useState<string | null>(null);
  const [selectedGrade, setSelectedGrade] = React.useState<string | null>(null);
  const [selectedPassage, setSelectedPassage] = React.useState<Passage | null>(null);
  const [subjectOverride, setSubjectOverride] =
    React.useState<number | undefined>(undefined);
  const { data: allSubjects } = useGetSectionSubjectUnitLesson();
  const subjects = useSubjects(subjectOverride);

  const lessonReducer = useLessonReducer(allSubjects);
  const standardReducer = useStandardReducer(subjects);
  const skillReducer = useSkillReducer(subjects);

  const contentFilterElement = ContentFilterElement;

  /* ******************************************************************************
    HANDLE AUTO-SELECTION ON STANDARD/SKILL (When lesson changes)
  ****************************************************************************** */
  useEffect(() => {
    // Update both priority and recommended standards in a single effect
    standardReducer.setPriorityStandards(lessonReducer.lessonState.priorityStandards);
    skillReducer.setPrioritySkills(lessonReducer.lessonState.prioritySkills);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lessonReducer.getLessonById,
    lessonReducer.lessonState.priorityStandards,
    lessonReducer.lessonState.prioritySkills,
  ]);

  /* ******************************************************************************
    HANDLE LESSON RECOMMENDATION (when selected standards/skills are changed)
  ****************************************************************************** */
  useEffect(() => {
    const standards = standardReducer.standardState.selectedStandards;
    const lessonsToRecommend = {};

    // Get lessons from manually selected standards
    standards.forEach((standardId) => {
      const lessonIds = lessonReducer.reverseLessonAlignmentMap.standards[standardId];
      if (!lessonIds || lessonIds.length === 0) {
        return;
      }
      const standard = standardReducer.getStandardById(standardId);
      if (!standard) {
        return;
      }
      lessonIds.forEach((lessonId) => {
        if (!(lessonId in lessonsToRecommend)) {
          lessonsToRecommend[lessonId] = {
            standards: [],
            skills: [],
          };
        }
        lessonsToRecommend[lessonId].standards.push(
          `${standard.code}: ${standard.description}`,
        );
      });
    });

    // Get lessons from manually selected skills
    const skills = skillReducer.skillState.selectedSkills;
    skills.forEach((skillId) => {
      const lessonIds = lessonReducer.reverseLessonAlignmentMap.skills[skillId];
      if (!lessonIds || lessonIds.length === 0) {
        return;
      }
      const skill = skillReducer.getSkillById(skillId);
      if (!skill) {
        return;
      }
      lessonIds.forEach((lessonId) => {
        if (!(lessonId in lessonsToRecommend)) {
          lessonsToRecommend[lessonId] = {
            standards: [],
            skills: [],
          };
        }
        lessonsToRecommend[lessonId].skills.push(skill.name);
      });
    });

    lessonReducer.setRecommendedLessons(lessonsToRecommend);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lessonReducer.reverseLessonAlignmentMap,
    standardReducer.standardState.selectedStandards,
    skillReducer.skillState.selectedSkills,
  ]);

  /* ******************************************************************************
    HANDLE STANDARD RECOMMENDATION (when selected skills are changed)
  ****************************************************************************** */
  useEffect(() => {
    const standardsToRecommend = {};

    // Get standards from manually selected skills
    skillReducer.skillState.selectedSkills.forEach((skillId) => {
      const skill = skillReducer.getSkillById(skillId);
      if (!skill) {
        return;
      }
      const skillStandards = standardReducer.skillStandardAlignmentMap[skillId];
      if (skillStandards) {
        skillStandards.forEach((standardId) => {
          if (!(standardId in standardsToRecommend)) {
            standardsToRecommend[standardId] = {
              skills: [],
              lessons: [],
            };
          }
          standardsToRecommend[standardId].skills.push(skill.name);
        });
      }
    });

    // Get standards from lesson's recommended standards
    Object.keys(lessonReducer.lessonState.recommendedStandards).forEach((standardId) => {
      if (!(standardId in standardsToRecommend)) {
        standardsToRecommend[standardId] = {
          skills: [],
          lessons: [],
        };
      }
      standardsToRecommend[standardId].lessons =
        lessonReducer.lessonState.recommendedStandards[standardId];
    });

    standardReducer.setRecommendedStandards(standardsToRecommend);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lessonReducer.lessonState.recommendedStandards,
    skillReducer.skillState.selectedSkills,
  ]);

  /* ******************************************************************************
    HANDLE SKILL RECOMMENDATION (when selected standards are changed)
  ****************************************************************************** */
  useEffect(() => {
    const skillsToRecommend = {};

    // Get skills from manually selected standards
    standardReducer.standardState.selectedStandards.forEach((standardId) => {
      const standard = standardReducer.getStandardById(standardId);
      if (standard?.skills) {
        standard.skills.forEach((skillId) => {
          if (!(skillId in skillsToRecommend)) {
            skillsToRecommend[skillId] = {
              lessons: [],
              standards: [],
            };
          }
          skillsToRecommend[skillId].standards.push(
            `${standard.code}: ${standard.description}`,
          );
        });
      }
    });

    // Get skills from lesson's recommended standards
    Object.keys(lessonReducer.lessonState.recommendedSkills).forEach((skillId) => {
      if (!(skillId in skillsToRecommend)) {
        skillsToRecommend[skillId] = {
          lessons: [],
          standards: [],
        };
      }
      skillsToRecommend[skillId].lessons =
        lessonReducer.lessonState.recommendedSkills[skillId];
    });

    skillReducer.setRecommendedSkills(skillsToRecommend);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lessonReducer.lessonState.recommendedSkills,
    standardReducer.standardState.selectedStandards,
  ]);

  const clearSelection = useCallback(() => {
    lessonReducer.setSelectedLessons([]);
    lessonReducer.setRecommendedLessons({});
    standardReducer.setDisableToast(true);
    standardReducer.setSelectedStandards([]);
    standardReducer.setRecommendedStandards([]);
    standardReducer.setManuallySelectedStandards([]);
    standardReducer.setRemovedStandards([]);
    skillReducer.setDisableToast(true);
    skillReducer.setSelectedSkills([]);
    skillReducer.setRecommendedSkills([]);
    skillReducer.setManuallySelectedSkills([]);
    skillReducer.setRemovedSkills([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // if all three are empty, clear the recommended/removed standards/skills
    if (
      lessonReducer.lessonState.selectedLessons.length === 0 &&
      standardReducer.standardState.selectedStandards.length === 0 &&
      skillReducer.skillState.selectedSkills.length === 0
    ) {
      lessonReducer.setRecommendedLessons({});

      standardReducer.setDisableToast(true);
      standardReducer.setRecommendedStandards([]);
      standardReducer.setRemovedStandards([]);

      skillReducer.setDisableToast(true);
      skillReducer.setRecommendedSkills([]);
      skillReducer.setRemovedSkills([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lessonReducer.lessonState.selectedLessons,
    standardReducer.standardState.selectedStandards,
    skillReducer.skillState.selectedSkills,
    clearSelection,
  ]);

  const value = useMemo(
    () => ({
      subjects,
      setSubjectOverride,
      contentFilterElement,
      lessonContext: lessonReducer,
      standardContext: standardReducer,
      skillContext: skillReducer,
      selectedBlooms,
      setSelectedBlooms,
      selectedDok,
      setSelectedDok,
      selectedPassage,
      setSelectedPassage,
      clearSelection,
      selectedReadability,
      selectedGrade,
      setSelectedReadability,
      setSelectedGrade,
    }),
    [
      subjects,
      contentFilterElement,
      lessonReducer,
      standardReducer,
      skillReducer,
      selectedBlooms,
      selectedDok,
      selectedPassage,
      clearSelection,
      selectedReadability,
      selectedGrade,
    ],
  );

  return (
    <ContentFilterContext.Provider value={value}>
      {children}
    </ContentFilterContext.Provider>
  );
};

export const useContentFilterContext = () => {
  const context = useContext(ContentFilterContext);
  if (!context) {
    throw new Error(
      'useContentFilterContext must be used within a ContentFilterProvider',
    );
  }
  return context;
};

export const useContentFilterLessonContext = () => {
  const context = useContext(ContentFilterContext);
  if (!context) {
    throw new Error(
      'useContentFilterLessonContext must be used within a ContentFilterProvider',
    );
  }
  return {
    ...context.lessonContext,
    ...context.lessonContext.lessonState,
  };
};

export const useContentFilterStandardContext = () => {
  const context = useContext(ContentFilterContext);
  if (!context) {
    throw new Error(
      'useContentFilterStandardContext must be used within a ContentFilterProvider',
    );
  }
  return {
    ...context.standardContext,
    ...context.standardContext.standardState,
  };
};

export const useContentFilterSkillContext = () => {
  const context = useContext(ContentFilterContext);
  if (!context) {
    throw new Error(
      'useContentFilterSkillContext must be used within a ContentFilterProvider',
    );
  }
  return {
    ...context.skillContext,
    ...context.skillContext.skillState,
  };
};

export const useContentFilterElement = () => {
  const context = useContext(ContentFilterContext);
  if (!context) {
    throw new Error(
      'useContentFilterElement must be used within a ContentFilterProvider',
    );
  }
  return context.contentFilterElement;
};
