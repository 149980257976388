import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import BreadcumbsFilter from '../../components/BreadcumbsFilter';
import {
  // selectors
  getSideBar,
  // actions
  actions,
} from '../../reducers/leftSideBar';
import { selectors as UserSelectors } from '../../reducers/user';
import { ROLE_TYPE } from '../../utils/enums';
import {
  selectors as SectionStudentSelectors,
  actions as SectionStudentActions,
} from '../../reducers/sectionStudent';
import { selectors as SectionSelectors } from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', null);
  const sectionDetail = SectionSelectors.getSectionById(state, sectionId);
  const gradeLevels = _get(sectionDetail, 'grade_levels');

  return {
    isSideBarClose: getSideBar(state),
    isTeacher: UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR,
    studentList: SectionStudentSelectors.getStudentSortByFirstName(state, sectionId),
    studentFilter: SectionStudentSelectors.getStudentListFilter(state),
    gradeLevelFilterList: SectionStudentSelectors.getGradeLevelFilter(state),
    sectionId,
    gradeLevels,
  };
};

const mapDispatchToProps = {
  toggleSidebar: actions.toggleSidebar,
  sectionStudentFilterAdd: SectionStudentActions.sectionStudentFilterAdd,
  sectionStudentFilterAddAllOrCancel: SectionStudentActions.sectionStudentFilterAddAllOrCancel,
  sectionsStudentFilterGradeLevel: SectionStudentActions.sectionsStudentFilterGradeLevel,
  sectionsStudentSelectAllOrCancelGradeLevel: SectionStudentActions.sectionsStudentSelectAllOrCancelGradeLevel,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BreadcumbsFilter),
);
