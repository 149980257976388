/* eslint-disable react/prefer-stateless-function */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { AssessmentProvider } from '@learnosityassessment';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import ThemeProvider from '@Theme';
import { datadogRum } from '@datadog/browser-rum';
import ReactQueryClientHelper, { queryClient } from '@utils/queryClientHelper';
import history from './utils/history';
import Routes from './routes';
import configureStore from './store/configureStore';
import { MathJaxProvider } from './components/MathJaxContainer';
import { globalVars } from './globals';
import SentryChalkTalk from './utils/SentryChalkTalk';
import { initializeSegment } from './utils/initializeSegment';

import '../public/assets/scss/main.scss';
import '../public/assets/scss/fonts-site.scss';
import ChalkTalkErrorBoundary from './components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';
import { configTokenInterceptor } from '@apis/config';
import { InteractionContextProvider } from '@components/ElementAnalytics/InteractionContext';

export const { store, persistor } = configureStore({}, history);



globalVars.store = store;



if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  SentryChalkTalk.init();
  initializeSegment();
  datadogRum.startSessionReplayRecording();
  datadogRum.init({
    applicationId: 'c189adb9-46ac-42cb-bf08-65e5dacdfa31',
    clientToken: 'pub2e60c0a4094e9bc4793e459f15e852fa',
    site: 'us5.datadoghq.com',
    service: process.env.NODE_ENV === 'production' ? 'chalktalk-prod' : 'chalktalk-staging',
    env: process.env.NODE_ENV === 'production' ? 'prod' : 'staging',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    premiumSampleRate: 50,
    tracesSampleRate: 20,
    sessionReplaySampleRate: 50,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    trackSessionAcrossSubdomains: true,
  });
}



navigator.serviceWorker?.getRegistrations().then((registrations) => {
  console.log(' unregister start');
  for (const registration of registrations) {
    console.log('registration: ', registration);
    registration.unregister();
  }
});
const App = () => (
  <ThemeProvider>
    <ChalkTalkErrorBoundary>
      <InteractionContextProvider interactionArea="ct">
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <DndProvider backend={HTML5Backend}>
                  <ToastContainer
                    style={{ width: '440px', padding: '0px', top: '4em' }}
                    autoClose={5000}
                    closeButton={false}
                  />
                  <MathJaxProvider>
                    <AssessmentProvider
                      serviceURL={{
                        normal: process.env.ASSESSMENT_SERVICE_URL,
                        CDN: process.env.ASSESSMENT_CDN_SERVICE_URL,
                      }}
                      serviceEnvironment={process.env.ASSESSMENT_SERVICE_ENVIRONMENT}
                    >
                      <ReactQueryClientHelper />
                      <Routes history={history} />
                    </AssessmentProvider>
                  </MathJaxProvider>
                </DndProvider>
              </ConnectedRouter>
            </PersistGate>
          </Provider>
        </QueryClientProvider>
      </InteractionContextProvider>
    </ChalkTalkErrorBoundary>
  </ThemeProvider>
);
const AppWithSentry = Sentry.withProfiler(App);
const root = createRoot(document.getElementById('app'));
root.render(<AppWithSentry />);
configTokenInterceptor(store);
