/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import _get from 'lodash/get';
import LessonManagement from '../../components/LessonManagement';
import { actions } from '../../reducers/sectionStudent';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as SectionSelectors } from '../../reducers/sections';
import {
  actions as SideBarAction,
  selectors as LeftSideBarSelectors,
} from '../../reducers/leftSideBar';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import {
  selectors as CurriculumLessonsSelectors,
  actions as CurriculumLessonsActions,
} from '../../reducers/curriculumLessons';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import { actions as sectionSkillsActions } from '../../reducers/sectionSkills';

import {
  actions as ActivityActions,
} from '../../reducers/activity';
import { ROLE_TYPE } from '../../utils/enums';
const lessonDetailRouter = '/lessons/course/:courseId/section/:sectionId/subject/:subjectId/unit/:unitId/lesson/:lessonId';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', null);
  const courseId = _get(match, 'params.courseId', null);
  const currentPath = _get(match, 'path', '');
  const { params } = match;
  const { unitId, lessonId, subjectId } = params;
  const shouldRenderLessonDetail = currentPath === lessonDetailRouter;
  const userRole = UserSelectors.getUserRole(state);
  const shouldDisplayAdd = userRole !== ROLE_TYPE.STUDENT;
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    lessonId,
    userRole,
    shouldRenderLessonDetail,
    shouldDisplayAdd,
    sectionId,
    courseId,
    courseDetail,
    sectionDetail,
    unitTree: SectionUnitSelectors.getUnitTreeBySubjectId(state, subjectId),
    subjectDetail: SectionSubjectSelectors.getSubjectDetail(state, subjectId),
    unitDetail: SectionUnitSelectors.getUnitById(state, unitId),
    lessonDetail: CurriculumLessonsSelectors.getLessonDetailById(state, lessonId),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    isSubNavbarSubjectClose: !subjectId,
  };
};

const mapDispatchToProps = {
  sectionsStudentGet: actions.sectionsStudentGet,
  toggleLessonSessionNavbar: SideBarAction.toggleLessonSessionNavbar,
  toggleReviewSessionNavbar: SideBarAction.toggleReviewSessionNavbar,
  getAllLessonActivity: ActivityActions.getAllLessonActivity,
  sectionSkillsGet: sectionSkillsActions.sectionSkillsGet,
  curriculumLessonsGetDetail: CurriculumLessonsActions.curriculumLessonsGetDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(LessonManagement);
