import React from 'react';
import * as PropTypes from 'prop-types';
import _isNil from 'lodash/isNil';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import PracticeTopNavBar from '../PracticeTopNavBar';
import OnlinePracticeContent from '../../containers/OnlinePracticeContent';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { error as ToastError } from '../../utils/toast';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ReportErrorModal from '../ReportErrorModal';

const reportModalType = 'Report';

class PracticeSheet extends React.PureComponent {
  state = {
    isOpen: false,
    modalType: null,
    reportProps: null,
  };

  componentDidMount() {
    const { takePracticeSession, match } = this.props;
    const { practiceSessionId } = match.params;

    takePracticeSession(practiceSessionId); // get question
  }

  componentDidUpdate(prevProps) {
    const { isCompleteResponseSuccess, history, match, pacingGuideId } = this.props;

    const { courseId, sectionId, unitId, practiceSessionId, lessonId, subjectId } =
      match.params;
    if (
      isCompleteResponseSuccess !== prevProps.isCompleteResponseSuccess &&
      isCompleteResponseSuccess
    ) {
      let mainRoute = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}`;
      if (lessonId) {
        mainRoute += `/lesson/${lessonId}`;
      }
      history.push({
        pathname: `${mainRoute}/practice-review/${practiceSessionId}`,
        search: pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : '',
        state: {
          shouldShowSkillMsg: true,
        },
      });
    }
  }

  submitAnswer = (answer) => {
    // submit question
    const { answerPracticeQuestion, practiceId } = this.props;
    if (!answer || _isNil(answer.value)) {
      ToastError("You haven't completed the question");
      return;
    }

    answerPracticeQuestion(practiceId, answer);
  };

  completePractice = () => {
    const { completePracticeSession, match } = this.props;
    const { practiceSessionId } = match.params;

    completePracticeSession(practiceSessionId);
  };

  onCloseModal = () => {
    this.setState({
      isOpen: false,
      modalType: null,
      reportProps: null,
    });
  };

  openConfirmModal = () => {
    this.setState({
      isOpen: true,
      modalType: 'Confirm',
    });
  };

  openReportErrorModal = (contentType, questionId) => {
    this.setState({
      isOpen: true,
      modalType: reportModalType,
      reportProps: {
        contentType,
        questionId,
      },
    });
  };

  submitError = (content) => {
    const { userReportIssue } = this.props;
    const { reportProps } = this.state;

    if (!reportProps) {
      return;
    }

    const { questionId, contentType } = reportProps;

    userReportIssue(questionId, contentType, content, this.onCloseModal);
  };

  goBack = () => {
    const { history, courseId, sectionId, subjectId, unitId, lessonId, pacingGuideId } =
      this.props;
    let url = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/`;
    if (lessonId) {
      url += `lesson/${lessonId}?tab=${LessonTabs.Practice}${
        pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
      }`;
    } else {
      url += 'practice';
    }
    history.push(url);
  };

  renderModal = () => {
    const { isOpen, modalType } = this.state;

    if (!isOpen) {
      return null;
    }

    if (modalType === reportModalType) {
      return (
        <ReportErrorModal
          isOpen={isOpen}
          onClose={this.onCloseModal}
          onSubmit={this.submitError}
        />
      );
    }

    return (
      <ConfirmModal
        isOpen={isOpen}
        onConfirm={this.completePractice}
        onClose={this.onCloseModal}
        content="Are you sure you want to submit?"
      />
    );
  };

  render() {
    const { match, isTakeQuestion } = this.props;
    const { practiceSessionId } = match.params;

    if (isTakeQuestion) {
      return <LoadingIndicator content="We are assembling the right questions for you" />;
    }

    return (
      <PracticeContainer>
        <ChalkTalkErrorBoundary reset={this.goBack}>
          <PracticeTopNavBar
            practiceName="Test"
            goBack={this.goBack}
            submitAnswer={this.submitAnswer}
            complete={this.completePractice}
          />
          <OnlinePracticeContent
            practiceSessionId={practiceSessionId}
            completePractice={this.completePractice}
            openReportErrorModal={this.openReportErrorModal}
          />
          {this.renderModal()}
        </ChalkTalkErrorBoundary>
      </PracticeContainer>
    );
  }
}
const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const { 'pacing-guide-id': pacingGuideId } = useSearchParams();

    return <WrappedComponent {...props} pacingGuideId={pacingGuideId} />;
  };
  return ComponentWithData;
};
export default withDataWrapper(PracticeSheet);

PracticeSheet.propTypes = {
  takePracticeSession: PropTypes.func.isRequired,
  answerPracticeQuestion: PropTypes.func.isRequired,
  completePracticeSession: PropTypes.func.isRequired,
  practiceId: PropTypes.number,
  match: PropTypes.shape(),
  history: PropTypes.shape(),
  isCompleteResponseSuccess: PropTypes.bool,
  isTakeQuestion: PropTypes.bool,
  userReportIssue: PropTypes.func,
};
