import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  FormLabelItem,
  FormRequired,
} from '../stylesheets/Form.style';

class FormLabel extends PureComponent {
  render() {
    const {
      label, required, icon, dataTip,
    } = this.props;
    return (
      <FormLabelItem>
        <ReactTooltip place="right" className="extraClass" effect="solid" id="tooltip-form-label" />
        {label}
        {required && <FormRequired>*</FormRequired>}
        {icon && <i className={icon} data-tip={dataTip} data-for="tooltip-form-label" />}
      </FormLabelItem>
    );
  }
}

FormLabel.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  dataTip: PropTypes.string,
};

FormLabel.defaultProps = {
  required: false,
  label: '',
  icon: '',
  dataTip: '',
};

export default FormLabel;
