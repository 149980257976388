import React, { Suspense } from 'react';
import LeftSideBarDashboard from '@components/LeftSideBar/LeftSideBarDashboard';
import Skeleton from '@components/Atoms/Skeleton';
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '@containers/routes';
import MainContent from '@containers/MainContent';

import DashboardSummary from '@containers/DashboardSummary';
import DashboardAttendanceReport from '@containers/DashboardAttendanceReport';
import DashboardExamScoreReport from '@containers/DashboardExamScoreReport';
import DashboardPracticeDetail from '@containers/DashboardPracticeDetail';
import { ROLE_TYPE } from '../../utils/enums';

const Dashboard = () => (
  <>
    <LeftSideBarDashboard />
    <MainContent>
      <Suspense fallback={<Skeleton height={400} />}>
        <Switch>
          <AuthenticatedRoute
            exact
            path="/dashboard/course/:courseId/section/:sectionId"
            component={DashboardSummary}
          />
          <AuthenticatedRoute
            exact
            path="/dashboard/course/:courseId/section/:sectionId/summary"
            component={DashboardSummary}
          />
          <AuthenticatedRoute
            exact
            path="/dashboard/course/:courseId/section/:sectionId/attendance-detail"
            component={DashboardAttendanceReport}
            disallowed={[ROLE_TYPE.STUDENT]}
          />
          <AuthenticatedRoute
            exact
            path="/dashboard/course/:courseId/section/:sectionId/scores-detail"
            component={DashboardExamScoreReport}
            disallowed={[ROLE_TYPE.STUDENT]}
          />
          <AuthenticatedRoute
            exact
            path="/dashboard/course/:courseId/section/:sectionId/practice-detail"
            component={DashboardPracticeDetail}
            disallowed={[ROLE_TYPE.STUDENT]}
          />
        </Switch>
      </Suspense>
    </MainContent>
  </>
);

export default Dashboard;
