import Box from '@components/Atoms/Box';
import Select from '@components/Atoms/Select';
import { VIEW_BY_OPTIONS } from '@utils/constants';
import React from 'react';
import { useHistory } from 'react-router-dom';

const SessionTypeSelectButton = ({
  options,
  selectSection,
}: {
  options: { id: number; name: string }[];
  selectSection: (option: { id: number; name: string }) => void;
}) => {
  const history = useHistory();
  const list = options.map((option) => ({
    label: option.name,
    value: option.id,
  }));

  const getDefaultValue = () => {
    const isReviewPage = history.location.pathname.includes('review');
    if (isReviewPage) {
      return VIEW_BY_OPTIONS.ReviewClasses;
    }
    return VIEW_BY_OPTIONS.Curriculum;
  };

  return (
    <Box sx={{ width: '100%', mt: '1rem', px: '0.625rem' }}>
      <Select
        key={getDefaultValue()}
        data-testid="view-by"
        id="view-by"
        sx={{ width: '100%' }}
        list={list}
        label="View by"
        size="small"
        defaultValue={getDefaultValue()}
        onChangeHandler={(value) => {
          selectSection({
            id: Number(value),
            name: list.find((item) => item.value === Number(value))?.label || '',
          });
        }}
      />
    </Box>
  );
};

export default SessionTypeSelectButton;
