import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import PracticeTopNavBar from '../PracticeTopNavBar';
import ReviewQuestionContainers from './ReviewQuestionContainers';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { ROLE_TYPE } from '../../utils/enums';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import ReportErrorModal from '../ReportErrorModal';
import { fetchReport } from '../../apis/exam';
import { handleError } from '../../apis/utils';
class TestReviewManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      reportProps: null,
      fetchReportData: '',
      reportURL: '',
    };
  }

  componentDidMount() {
    const {
      examSessionId, examSectionId, studentId, examReportGetDetail, userRole,
      sectionExamGetDetail, examReportGetOfUser,
    } = this.props;

    if (userRole === ROLE_TYPE.STUDENT) {
      examReportGetOfUser(examSessionId, examSectionId);
    } else {
      examReportGetDetail(examSessionId, examSectionId, studentId);
    }

    sectionExamGetDetail(examSessionId);
  }

  onCloseModal = () => {
    this.setState({
      isOpen: false,
      reportProps: null,
    });
  }

  openReportErrorModal = (contentType, questionId) => {
    this.setState({
      isOpen: true,
      reportProps: {
        contentType,
        questionId,
      },
    });
  }

  submitError = (content) => {
    const { userReportIssue } = this.props;
    const { reportProps } = this.state;

    if (!reportProps) {
      return;
    }

    const { questionId, contentType } = reportProps;

    userReportIssue(questionId, contentType, content, this.onCloseModal);
  }

  redirectToExamDetailPage = () => {
    const {
      history, courseId, sectionId, examSessionId,
    } = this.props;

    history.push(`/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}`);
  }

  componentDidUpdate(prevProps, prevState) { // when state changed will run this function
    if (this.state.reportURL != prevState.reportURL) { // if  state (reportURL ) changed call the api
      fetchReport(this.state.reportURL).then(res => res.json())
        .then((response) => { // put response json in state (fetchReportData)
          this.setState({
            fetchReportData: response,
          });
        },
        () => {
          handleError;
        }).catch(handleError);
    }
  }

  render() {
    const {
      examReportDetail, examDetail, examSectionId, isPracticeNavigation,
    } = this.props;
    const { isOpen } = this.state;
    const report = _get(examReportDetail, 'report', []);

    if (report.length > 0) { // if report contain URL put this URL in state
      this.setState({
        reportURL: report,
      });
    }

    const { fetchReportData } = this.state; // get Json  from state
    let listQuestions = [];
    if (fetchReportData != '') { // if note empty
      listQuestions = fetchReportData.subscores.filter(item => item.exam_question.section == this.props.match.params.examSectionId);
    }

    if (listQuestions.length === 0 || !examDetail) { // if listQuestions Empty show LoadingIndicator component
      return <LoadingIndicator content="Preparing your review" />;
    }

    const sections = _get(examDetail, 'exam.sections', []);
    const sectionSeleted = sections.find(section => section.id == examSectionId); // eslint-disable-line
    const examName = _get(sectionSeleted, 'name', '');

    return (
      <PracticeContainer>
        <PracticeTopNavBar
          name={examName}
          goBack={this.redirectToExamDetailPage}
          displayTimeOut={false}
        />
        <ReviewQuestionContainers
          questions={listQuestions}
          isPracticeNavigation={isPracticeNavigation}
          openReportErrorModal={this.openReportErrorModal}
        />
        {isOpen && (
          <ReportErrorModal
            isOpen={isOpen}
            onClose={this.onCloseModal}
            onSubmit={this.submitError}
          />
        )}
      </PracticeContainer>
    );
  }
}

TestReviewManagement.propTypes = {
  sectionId: PropTypes.any,
  examSessionId: PropTypes.any,
  examSectionId: PropTypes.any,
  studentId: PropTypes.any,
  examReportGetDetail: PropTypes.func,
  examReportDetail: PropTypes.object,
  history: PropTypes.any,
  courseId: PropTypes.any,
  sectionExamGetDetail: PropTypes.func,
  examReportGetOfUser: PropTypes.func,
  examDetail: PropTypes.object,
  userRole: PropTypes.string,
  isPracticeNavigation: PropTypes.bool,
  userReportIssue: PropTypes.func,
};

export default TestReviewManagement;
