import React, { useEffect } from 'react';
import { useMathJax } from '../hooks';
import { offline as errorToast } from '../../toast';

export default function Html({ html, onFinishRender }) {
  const [MathJax, updateMathContent] = useMathJax();

  useEffect(() => {
    if (MathJax) {
      updateMathContent(onFinishRender);
    }
  }, [MathJax, updateMathContent, onFinishRender]);

  if (!MathJax) {
    setTimeout(() => {
      errorToast(
        `
        Failed to load math renderer.
        This could lead to math expressions not displaying correctly.
        Please try refreshing the page.
      `,
        {
          toastId: 'mathjax-failed',
        },
      );
    }, 0);
  }

  // eslint-disable-next-line react/no-danger
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}
