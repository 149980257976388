import React from 'react';
import _isEqual from 'lodash/isEqual';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import ShowIf from '@Atoms/ShowIf';
import ActionCard from '@components/Component/OLP/tab/ActionCard/ActionCard';
import StartActivityProvider from '@components/Component/OLP/tab/Provider/StartActivityProvider';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { GroupBodyContainer } from './GroupActivity.style';
import GroupActivityTable from '../../containers/GroupActivityTable';
import GroupDiscussionModal from '../Modals/GroupDiscussionModal';

class GroupActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSessionStarted: false,
      sessionWasEnded: false,
      isModalOpen: false,
      endAcitivityModal: true,
    };
  }

  // eslint-disable-next-line react/sort-comp
  dismissActivityToast = () => {
    const {
      match,
      activeGroupActivity: { sectionId, courseId, subjectId, unitId, lessonId },
    } = this.props;
    const currentGroupActivityTab = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}?tab=${LessonTabs.Lesson}`;
    if (_isEqual(`${match.url}?tab=${LessonTabs.Lesson}`, currentGroupActivityTab)) {
      toast.dismiss();
    }
  };

  componentDidMount() {
    this.dismissActivityToast();
  }

  componentDidUpdate(prevProps) {
    const {
      lessonId,
      sectionId,
      activityLessonId,
      activityUserId,
      activityUnitId,
      curriculumId,
      isTeacher,
      history,
      location,
      studentList,
    } = this.props;
    const { activityLessonId: prevActivityLessonId, studentList: prevStudentList } =
      prevProps;
    if (
      !_isEqual(prevActivityLessonId, activityLessonId) ||
      (!_isEqual(prevStudentList, studentList) && studentList.length > 0)
    ) {
      this.props.getGroupActivities(
        sectionId,
        lessonId,
        activityLessonId,
        activityUserId,
        activityUnitId,
        curriculumId,
        isTeacher,
        lessonId,
      );
    }
    if ((history.location.state?.showModal || location.state?.showModal) && !isTeacher) {
      if (!this.state.isModalOpen) {
        this.setState({
          isModalOpen: true,
        });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(nextProps, this.props) || !_isEqual(nextState, this.state);
  }

  handelModal = () => {
    const { history, courseId, subjectId, unitId, sectionId, lessonId } = this.props;
    if (this.state.isModalOpen) {
      this.setState({ isModalOpen: false });
    }
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/?tab=${LessonTabs.Lesson}`,
      { showModal: false },
    );
  };

  render() {
    const { maxNumOfGroupStudents, activityLessonId, studentId } = this.props;
    return (
      <StartActivityProvider>
        <GroupDiscussionModal
          isModalOpen={this.state.isModalOpen}
          onCloseModal={this.handelModal}
          endAcitivityModal={this.state.endAcitivityModal}
        />
        <ActionCard />
        <GroupBodyContainer>
          <ShowIf If={activityLessonId}>
            <GroupActivityTable
              studentId={studentId}
              maxNumOfGroupStudents={maxNumOfGroupStudents}
            />
          </ShowIf>
        </GroupBodyContainer>
      </StartActivityProvider>
    );
  }
}

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const { data: lessonDetail } = useGetLessonDetail();
    return (
      <WrappedComponent {...props} activityLessonId={lessonDetail?.lessons?.[0]?.id} />
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(GroupActivity);
