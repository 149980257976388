/* eslint-disable react/jsx-no-useless-fragment */
import React, { Suspense } from 'react';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary';
import { Skeleton } from '@mui/material';
import Box from '@Atoms/Box';
import { useGetCurriculumExamSections } from './hooks';

type ExamSectionsProps = { children: React.ReactNode[] | React.ReactNode };

const ExamSections = ({ children }: ExamSectionsProps) => {
  useGetCurriculumExamSections();

  return <>{children}</>;
};

const ExamSectionsWrapper = ({ children }: ExamSectionsProps) => (
  <ChalkTalkErrorBoundary>
    <Suspense
      fallback={
        <Box display="flex" flexDirection="column" gap="0.5rem" px="0.5rem" pt="2rem">
          <Skeleton variant="rounded" width="100%" height="8rem" />
          <Skeleton variant="rounded" width="100%" height="8rem" />
          <Skeleton variant="rounded" width="100%" height="16rem" />
        </Box>
      }
    >
      <ExamSections>{children}</ExamSections>
    </Suspense>
  </ChalkTalkErrorBoundary>
);

export default ExamSectionsWrapper;
