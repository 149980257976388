/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const VideoPlayer = styled.iframe.attrs({
  allowFullScreen: true,
})`
  width: 100%;
  background-color: #dfe8ee;
  height: 756px;

  ${({ isStudyHall }: any) =>
    isStudyHall &&
    css`
      @media (max-width: 768px) {
        height: calc(100% - 2em);
        width: 100%;
        position: absolute;
      }
    `}

  @media (min-width: 768px) {
    height: 60vh;
  }

  ${({ isFullScreen }: { isFullScreen: boolean }) =>
    isFullScreen &&
    css`
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
      height: 756px !important;
      z-index: 10;
      width: 100% !important;
      transform: scale(1) translateY(calc(100vh - 756px));
    `}
`;
