/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  PostJoinSessionItemWrap,
  PostJoinSessionItem,
  PostJoinSessionTitle,
  MainContentWrapper,
  LeftMainContentWrapper,
  RightMainContentWrapper,
  RightContentTitleWrapper,
  RightContentTextWrapper,
  RightContentItalicTextWrapper,
  WellSessionContext,
  InformationLeftContent,
  TitleEachSubContent,
  ChooseOptionsWrapper,
  CircleIconWrapper,
  OptionsAfterCircleIcon,
  ButtonWrapper,
  ButtonStyled,
  LinkSkipThisStepWrapper,
  LinkSkipThisStep,
  TableWrapper,
  SmallInformationTable,
  Wrapper,
} from '../PostJoinSectionModal/PostJoinSectionModal.style';
import { SUBSCRIPTION_TYPE } from '../../../utils/enums';
import { ButtonWrapperModel } from './SectionCreationModal.style';

class PostCreatedSection extends PureComponent {
  redirectToInviteStudent = () => {
    const { history, sectionDetail, onClose } = this.props;

    const sectionId = _get(sectionDetail, 'id');
    const courseId = _get(sectionDetail, 'course');

    history.push(
      `/management/course/${courseId}/section/${sectionId}?isOpenInviteStudent=1`,
    );

    onClose();
  };

  renderLeftMainContent = () => {
    const { sectionDetail } = this.props;
    const name = _get(sectionDetail, 'name', '');
    const studentCode = _get(sectionDetail, 'code[0].code', '');
    const teacherCode = _get(sectionDetail, 'teacher_code', '');

    return (
      <React.Fragment>
        <WellSessionContext>
          <p>{name}</p>
        </WellSessionContext>
        <InformationLeftContent>
          <TitleEachSubContent>What to do next?</TitleEachSubContent>
          <ChooseOptionsWrapper>
            <CircleIconWrapper className="chalktalk-grey-circle" />
            <OptionsAfterCircleIcon>
              Review and edit the section schedule.
            </OptionsAfterCircleIcon>
          </ChooseOptionsWrapper>
          <ChooseOptionsWrapper>
            <CircleIconWrapper className="chalktalk-grey-circle" />
            <OptionsAfterCircleIcon>
              View the curriculum lessons for each subject.
            </OptionsAfterCircleIcon>
          </ChooseOptionsWrapper>
          <ChooseOptionsWrapper>
            <CircleIconWrapper className="chalktalk-grey-circle" />
            <OptionsAfterCircleIcon>
              Invite teachers and enroll students.
            </OptionsAfterCircleIcon>
          </ChooseOptionsWrapper>
        </InformationLeftContent>
        <InformationLeftContent>
          <TitleEachSubContent>Enrollment Codes</TitleEachSubContent>
          <TableWrapper>
            <SmallInformationTable>
              <tbody>
                <tr>
                  <td>Teacher Code</td>
                </tr>
                <tr>
                  <td>{teacherCode}</td>
                </tr>
              </tbody>
            </SmallInformationTable>
            <SmallInformationTable>
              <tbody>
                <tr>
                  <td>Student Code</td>
                </tr>
                <tr>
                  <td>{studentCode}</td>
                </tr>
              </tbody>
            </SmallInformationTable>
          </TableWrapper>
        </InformationLeftContent>
      </React.Fragment>
    );
  };

  renderRightMainContent = () => {
    const { sectionDetail, courseData, curriculumDetail } = this.props;
    const { subjects = [] } = sectionDetail || {};
    const startDate = sectionDetail.start_date;
    // const placementTestDate = sectionDetail.placement_test_date;
    // const exitTestDate = sectionDetail.exit_test_date;
    const subjectDisplay = subjects
      .map((subject) => subject.display_name)
      .join(', ');
    const { subscription } = courseData;
    const {
      max_student_count: maxStudentCount,
      max_sections: maxSection,
      subscription_type: subscriptionType,
    } = subscription || {};
    const curriculumName = _get(curriculumDetail, 'name', '');

    return (
      <Wrapper>
        <RightContentTitleWrapper>Section Information</RightContentTitleWrapper>
        <br />
        <RightContentTextWrapper>{`Curriculum: ${curriculumName}`}</RightContentTextWrapper>
        <RightContentTextWrapper>{`Subjects: ${subjectDisplay}`}</RightContentTextWrapper>
        <RightContentTextWrapper>{`Sessions begins on ${startDate}`}</RightContentTextWrapper>
        <br />
        {/* <br />
        <RightContentTextWrapper>Placement</RightContentTextWrapper>
        <RightContentTextWrapper>{`Full Length ${placementTestDate}`}</RightContentTextWrapper>
        <RightContentTextWrapper>Exit</RightContentTextWrapper>
        <RightContentTextWrapper>{`Full Length ${exitTestDate}`}</RightContentTextWrapper>
        <br /> */}
        <RightContentItalicTextWrapper>{`License: ${SUBSCRIPTION_TYPE[subscriptionType]}`}</RightContentItalicTextWrapper>
        <RightContentItalicTextWrapper>{`Max ${maxStudentCount} students per course`}</RightContentItalicTextWrapper>
        <RightContentItalicTextWrapper>{`Max ${maxSection} section per course`}</RightContentItalicTextWrapper>
      </Wrapper>
    );
  };

  render() {
    const { onClose } = this.props;
    return (
      <PostJoinSessionItemWrap isCreateSection>
        <PostJoinSessionItem>
          <ButtonWrapperModel isCloseButton onClick={() => onClose()}>
            <i className="chalktalk-close" />
          </ButtonWrapperModel>
          <PostJoinSessionTitle>
            <h1>You successfully joined.</h1>
            <MainContentWrapper>
              <LeftMainContentWrapper>
                {this.renderLeftMainContent()}
              </LeftMainContentWrapper>
              <RightMainContentWrapper>
                {this.renderRightMainContent()}
              </RightMainContentWrapper>
            </MainContentWrapper>
            <ButtonWrapper>
              <ButtonStyled onClick={this.redirectToInviteStudent}>
                Next: Invite Students
              </ButtonStyled>
            </ButtonWrapper>
            <LinkSkipThisStepWrapper>
              <LinkSkipThisStep onClick={this.props.openSkipDialogue}>
                Skip and I will do this later
              </LinkSkipThisStep>
            </LinkSkipThisStepWrapper>
          </PostJoinSessionTitle>
        </PostJoinSessionItem>
      </PostJoinSessionItemWrap>
    );
  }
}

PostCreatedSection.propTypes = {
  sectionDetail: PropTypes.object,
  courseData: PropTypes.object,
  curriculumDetail: PropTypes.object,
  openSkipDialogue: PropTypes.func,
  history: PropTypes.object,
  onClose: PropTypes.func,
};

export default PostCreatedSection;
