import { connect } from 'react-redux';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { withRouter } from 'react-router-dom';
import SectionSelect from '../../components/SectionSelect';
import {
  selectors as SectionSelectors,
  actions as SectionActions,
} from '../../reducers/sections';
import {
  actions as SectionStudentActions,
} from '../../reducers/sectionStudent';
import {
  selectors as userSelectors,
} from '../../reducers/user';
import { setSectionOrCourse } from '../../utils/cacheCourseAndSection';

const mapStateToProps = (state, { match }) => {
  const sectionIdSelected = _get(match, 'params.sectionId', null);
  const courseIdSelected = _get(match, 'params.courseId', null);
  const sectionList = SectionSelectors.getSectionList(state);
  const sectionFilter = _filter(sectionList, item => item.course == courseIdSelected);
  setSectionOrCourse(sectionIdSelected, courseIdSelected);

  return {
    courseIdSelected,
    sectionIdSelected,
    userRole: userSelectors.getUserRole(state),
    selectedSection: SectionSelectors.getSelectedSection(state),
    isSectionGetAll: SectionSelectors.getIsGetAll(state),
    sectionList: sectionFilter,
  };
};

const mapDispatchToProps = {
  sectionsGetDetail: SectionActions.sectionsGetDetail,
  selectSection: SectionActions.selectSection,
  sectionStudentFilterAddAllOrCancel: SectionStudentActions.sectionStudentFilterAddAllOrCancel,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SectionSelect),
);
