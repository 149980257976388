import React, { useCallback } from 'react';
import { Button as MUIButton } from '@mui/material';
import { useCriteriaBySectionId } from '../hooks';
import { criteriaAPI } from '../api';
import CriteriaCard from './CriteriaCard';

const CriteriaList = ({ sectionId, controllable = false }) => {
  const { data: criteriaData, invalidate } = useCriteriaBySectionId({
    sectionId,
    defaultValue: [],
  });
  const addCriteria = () => {
    criteriaAPI.create({
      displayName: 'new Criteria',
      sectionId,
    });
    invalidate();
  };
  const editCriteriaTitle = useCallback((criteria, displayName) => {
    criteriaAPI.put({ ...criteria, displayName });
    invalidate();
  }, []);
  const deleteCriteria = useCallback((criteria) => {
    criteriaAPI.delete(criteria.id);
    invalidate();
  }, []);
  return (
    <>
      {criteriaData?.map((criteria) => (
        <CriteriaCard
          key={criteria.id}
          criteria={criteria}
          editCriteriaTitle={editCriteriaTitle}
          controllable={controllable}
          deleteCriteria={deleteCriteria}
        />
      ))}
      {controllable && (
        <MUIButton color="primary" onClick={addCriteria}>
          <i className="chalktalk-plus" style={{ padding: '0.5rem' }} />
          Add Criteria
        </MUIButton>
      )}
    </>
  );
};

export default CriteriaList;
