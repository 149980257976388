import styled, { css } from 'styled-components';

export const PrimaryLayout = styled.section`
  display: flex;
  background-color: ${(props) => props.theme.colorStyled.ColorBgContent};
  height: 100%;
  &.show-banner {
    margin-top: 50px;
    height: calc(100% - 50px);
  }
`;
