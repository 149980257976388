import {
  takeEvery, put, call, takeLeading, select,
} from 'redux-saga/effects';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import moment from 'moment';
import * as SectionAPI from '../../apis/sections';
import {
  SECTION_REVIEWS_ADD,
  SECTION_REVIEWS_GET_DETAIL,
  SECTION_REVIEWS_UPDATE,
  SECTION_REVIEWS_GET,
  SECTION_REVIEWS_DELETE,
  SECTION_REVIEWS_SECTION_SUMMARY,
  SECTION_USER_REVIEWS_SECTION_SUMMARY,
  actions,
  selectors,
} from '../../reducers/sectionReviews';
import {
  actions as ScheduleModalActions,
} from '../../reducers/scheduleModal';
import * as SessionAPIs from '../../apis/sessions';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';
import { selectors as userSelectors } from '../../reducers/user';

function* sectionReviewAdd({ reviewData }) {
  const { response, error } = yield call(SessionAPIs.addSessionReview, reviewData);

  if (response) {
    const { data } = response;
    yield put(actions.sectionReviewAddSuccess(data));
  } else {
    const errMsg = _get(error, '[0]', error || '');
    yield put(actions.sectionReviewAddFail(errMsg));
    ToastError(errMsg);
  }
}

function* sectionReviewGetDetail({ sectionId, reviewId }) {
  const { response, error } = yield call(SectionAPI.getSectionReviewDetail, sectionId, reviewId);

  if (response) {
    yield put(actions.sectionReviewGetDetailSuccess(response));
  } else {
    yield put(actions.sectionReviewGetDetailFail(error));
  }
}

function* sectionReviewUpdate({ reviewId, reviewData }) {
  const payload = {
    ...reviewData,
    activity_type: reviewData.activity_type.value,
    subject: reviewData.subject.id,
    date: moment(reviewData.date).format('YYYY-MM-DD'),
  };
  const { response, error } = yield call(SessionAPIs.updateSessionReview, reviewId, payload);

  if (response) {
    const newReview = response.data;
    yield put(actions.sectionReviewUpdateSuccess(newReview.id, newReview));
    ToastSuccess('Successfully udpated review session.');
    yield put(ScheduleModalActions.closeFormModal());
  } else {
    yield put(actions.sectionReviewUpdateFail(error));
    ToastError(error.message || error);
  }
}

function* sectionReviewGet({ sectionId, subjectId }) {
  if(sectionId > 0) {
    const shouldFetch = yield select(selectors.getShouldFetch, sectionId);

    if (!shouldFetch) {
      return;
    }

    const { response, error } = yield call(SectionAPI.getSectionReviewSessions, sectionId, subjectId);

    if (response) {
      const { data } = response;
      const transformData = _orderBy(data, 'date', 'asc');

      yield put(actions.sectionReviewGetSuccess(transformData, sectionId));
    } else {
      yield put(actions.sectionReviewGetFail(error));
    }
  }
}

function* sectionReviewDelete({ sectionId }) {
  const { response, error } = yield call(SessionAPIs.deleteSessionReview, sectionId);

  if (response) {
    yield put(actions.sectionReviewDeleteSuccess(sectionId));
    yield put(ScheduleModalActions.closeFormModal());
  } else {
    yield put(actions.sectionReviewDeleteFail(error));
  }
}

function* sectionReviewSectionSummaryGet({ sectionId, subjectId }) {
  if(sectionId > 0) {
    const { response, error } = yield call(SectionAPI.getSectionReviewSectionSummary, sectionId, subjectId);

    if (response) {
      const { data } = response;
      yield put(actions.sectionReviewSectionSummaryGetSuccess(data));
    } else {
      yield put(actions.sectionReviewSectionSummaryGetFail(error));
    }
  }
}

function* sectionStudentReviewSectionSummaryGet({ sectionId, subjectId }) {
  const userId = yield select(userSelectors.getUserId);
  const { response, error } = yield call(SectionAPI.getStudentSectionReviewSectionSummary, sectionId, subjectId);

  if (response) {
    const { data } = response;
    yield put(actions.sectionStudentReviewSectionSummaryGetSuccess(data, userId));
  } else {
    yield put(actions.sectionStudentReviewSectionSummaryGetFail(error));
  }
}

export default function* sectionReviewSaga() {
  yield takeLeading(SECTION_REVIEWS_ADD, sectionReviewAdd);
  yield takeEvery(SECTION_REVIEWS_GET_DETAIL, sectionReviewGetDetail);
  yield takeEvery(SECTION_REVIEWS_UPDATE, sectionReviewUpdate);
  yield takeEvery(SECTION_REVIEWS_GET, sectionReviewGet);
  yield takeEvery(SECTION_REVIEWS_DELETE, sectionReviewDelete);
  yield takeEvery(SECTION_REVIEWS_SECTION_SUMMARY, sectionReviewSectionSummaryGet);
  yield takeEvery(SECTION_USER_REVIEWS_SECTION_SUMMARY, sectionStudentReviewSectionSummaryGet);
}
