import styled, { css } from "styled-components";

export const CompareVersionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const CloseButtonWrapper = styled.button`
  border: none;
  background: none;
  width: 25px;
  height: 25px;
  color: ${(props) => props.theme.colorStyled.ColorBgDefault};
  position: absolute;
  top: 10px;
  right: 10px;
  i {
    position: relative;
    right: 2px;
    top: 1px;
    font-weight: bolder;
  }
`;

export const ModalHeaderWrapper = styled.div`
  width: 100%;
  height: 6em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 3em;
  padding-top: 4em;
  margin-top: 1.5em;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  padding: 1em 0;
  margin-bottom: -1em;
`;

export const ModalHeaderTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  font-family: 'MontserratBold';
`;

export const ModalHeaderSubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ModalHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalHeaderText = styled.span`
  font-size: ${(props) => props.theme.fontSize.SmallerFontSize};
  margin-top: 0.75em;
  font-family: 'MontserratLight';
`;

export const HeaderBottomBorder = styled.hr`
  width: 100%;
  height: 5px;
  color: grey;
  margin-top: 1.5em;
`;

export const SubmitButton = styled.button`
  border: 0;
  border-radius: 0.5em;
  padding: 7px 16px;
  font-size: ${(props) => props.theme.fontSize.MediumFontSize};
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  background-color: ${(props) => props.theme.colorStyled.ColorBgDefault};
  align-self: flex-end;
  font-family: 'MontserratLight';

  ${({ disabled }) => disabled && css`
    background-color: ${(props) => props.theme.colorStyled.ColorSecond};
    pointer-events: none;
  `}
`;

export const ModalBodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 1em;
  overflow-y: hidden;
`;

export const ModalToggleButtonsWrapper = styled.div`
  width: 100%;
  margin-bottom: 1em;
  padding: 0 3em;
`;

export const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.5em;
`;
