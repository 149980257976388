import { useReducer, useCallback, useEffect, Reducer, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useLessonAlignment from './useGetLessonAlignment';
import { SubjectRecord } from '../types';

interface LessonState {
  selectedLessons: number[];
  recommendedLessons: Record<number, string[]>; // lessonId: standardNames[]
  priorityStandards: Record<number, string[]>;
  recommendedStandards: Record<number, string[]>;
  prioritySkills: Record<number, string[]>;
  recommendedSkills: Record<number, string[]>;
}

type LessonAction =
  | { type: 'SET_SELECTED_LESSONS'; payload: number[] }
  | { type: 'SET_RECOMMENDED_LESSONS'; payload: Record<number, string[]> }
  | { type: 'SET_RECOMMENDED_STANDARDS'; payload: Record<number, string[]> }
  | { type: 'SET_PRIORITY_STANDARDS'; payload: Record<number, string[]> }
  | { type: 'SET_PRIORITY_SKILLS'; payload: Record<number, string[]> }
  | { type: 'SET_RECOMMENDED_SKILLS'; payload: Record<number, string[]> };

const initialState: LessonState = {
  selectedLessons: [],
  recommendedLessons: {},
  priorityStandards: {},
  recommendedStandards: {},
  prioritySkills: {},
  recommendedSkills: {},
};

const contentFilterReducer: Reducer<LessonState, LessonAction> = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_LESSONS':
      return { ...state, selectedLessons: action.payload };
    case 'SET_RECOMMENDED_LESSONS':
      return { ...state, recommendedLessons: action.payload };
    case 'SET_RECOMMENDED_STANDARDS':
      return { ...state, recommendedStandards: action.payload };
    case 'SET_PRIORITY_STANDARDS':
      return { ...state, priorityStandards: action.payload };
    case 'SET_PRIORITY_SKILLS':
      return { ...state, prioritySkills: action.payload };
    case 'SET_RECOMMENDED_SKILLS':
      return { ...state, recommendedSkills: action.payload };
    default:
      return state;
  }
};

const useLessonReducer = (subjects: SubjectRecord[]) => {
  const { lessonId: paramLessonId } = useParams();
  const [lessonState, lessonDispatch] = useReducer(contentFilterReducer, initialState);
  const lessonAlignmentMap = useLessonAlignment(subjects.map((subject) => subject.id));

  const setSelectedLessons = (lessons: number[]) => {
    lessonDispatch({ type: 'SET_SELECTED_LESSONS', payload: lessons });
  };

  const setRecommendedLessons = (lessons: Record<number, string[]>) => {
    lessonDispatch({ type: 'SET_RECOMMENDED_LESSONS', payload: lessons });
  };

  const setPriorityStandards = (standards: Record<number, string[]>) => {
    lessonDispatch({ type: 'SET_PRIORITY_STANDARDS', payload: standards });
  };

  const setRecommendedStandards = (standards: Record<number, string[]>) => {
    lessonDispatch({ type: 'SET_RECOMMENDED_STANDARDS', payload: standards });
  };

  const setPrioritySkills = (skills: Record<number, string[]>) => {
    lessonDispatch({ type: 'SET_PRIORITY_SKILLS', payload: skills });
  };

  const setRecommendedSkills = (skills: Record<number, string[]>) => {
    lessonDispatch({ type: 'SET_RECOMMENDED_SKILLS', payload: skills });
  };

  // given a lesson id, return the lesson object or null
  const getLessonById = useCallback(
    (id) => {
      if (id in lessonAlignmentMap) {
        return { id, ...lessonAlignmentMap[id] };
      }
      return null;
    },
    [lessonAlignmentMap],
  );

  // When a lesson exists in the param, set it as the selected lesson by default
  useEffect(() => {
    if (paramLessonId) {
      setSelectedLessons([parseInt(paramLessonId, 10)]);
    }
  }, [paramLessonId]);

  // Whenever the lesson selection changes, get the associated standards and skills
  useEffect(() => {
    const lessons = lessonState.selectedLessons.map(getLessonById).filter(Boolean);

    const updateAccumulator = (
      acc: Record<number, string[]>,
      ids: number[] | undefined,
      lessonName: string,
    ) => {
      ids?.forEach((id) => {
        if (!acc[id]) {
          acc[id] = [];
        }
        if (!acc[id].includes(lessonName)) {
          acc[id].push(lessonName);
        }
      });
    };

    const { priorityStandards, prioritySkills, recommendedStandards, recommendedSkills } =
      lessons.reduce(
        (acc, lesson: NonNullable<ReturnType<typeof getLessonById>>) => {
          updateAccumulator(acc.priorityStandards, lesson.standards, lesson.name);
          updateAccumulator(
            acc.recommendedStandards,
            lesson.skill_standards,
            lesson.name,
          );
          updateAccumulator(acc.prioritySkills, lesson.skills, lesson.name);
          updateAccumulator(acc.recommendedSkills, lesson.skill_standards, lesson.name);
          return acc;
        },
        {
          priorityStandards: {} as Record<number, string[]>,
          recommendedStandards: {} as Record<number, string[]>,
          prioritySkills: {} as Record<number, string[]>,
          recommendedSkills: {} as Record<number, string[]>,
        },
      );

    setPriorityStandards(priorityStandards);
    setRecommendedStandards(recommendedStandards);
    setPrioritySkills(prioritySkills);
    setRecommendedSkills(recommendedSkills);
  }, [getLessonById, lessonState.selectedLessons]);

  const reverseLessonAlignmentMap = useMemo(() => {
    const standardMap = {};
    const skillMap = {};
    Object.values(lessonAlignmentMap).forEach((lesson) => {
      lesson.standards?.forEach((standardId) => {
        if (!(standardId in standardMap)) {
          standardMap[standardId] = [lesson.id];
        } else if (!standardMap[standardId].includes(lesson.id)) {
          standardMap[standardId].push(lesson.id);
        }
      });

      lesson.skill_standards?.forEach((standardId) => {
        if (!(standardId in standardMap)) {
          standardMap[standardId] = [lesson.id];
        } else if (!standardMap[standardId].includes(lesson.id)) {
          standardMap[standardId].push(lesson.id);
        }
      });

      lesson.skills?.forEach((skillId) => {
        if (!(skillId in skillMap)) {
          skillMap[skillId] = [lesson.id];
        } else if (!skillMap[skillId].includes(lesson.id)) {
          skillMap[skillId].push(lesson.id);
        }
      });

      lesson.standard_skills?.forEach((skillId) => {
        if (!(skillId in skillMap)) {
          skillMap[skillId] = [lesson.id];
        } else if (!skillMap[skillId].includes(lesson.id)) {
          skillMap[skillId].push(lesson.id);
        }
      });
    });

    return {
      standards: standardMap,
      skills: skillMap,
    };
  }, [lessonAlignmentMap]);

  return {
    lessonState,
    reverseLessonAlignmentMap,
    lessonDispatch,
    setSelectedLessons,
    setRecommendedLessons,
    setPrioritySkills,
    setRecommendedSkills,
    getLessonById,
  };
};

export default useLessonReducer;
