import { useHistory } from 'react-router-dom';
import LoadingIndicator from '@components/LoadingIndicator';
import { PracticeContainer } from '@components/PracticePage/PracticePage.style';
import PracticeTopNavBar from '@components/PracticeTopNavBar';
import { Assessment } from '@learnosityassessment';
import React, { useEffect, useState } from 'react';

import { completePracticeSession } from '@apis/practice';
import { useInitializePracticeSession } from '@components/StandardsPractice/hooks';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import Box from '@components/Atoms/Box';
import { PRACTICE_TYPE } from '@utils/enums';
import { useUser } from '@reducers/user/hooks';
import { useIsLearnosityLessonReviewEnabled } from '@reducers/curriculums/hooks';
import { useLessonReview, useNavigateToLessonReview } from './hooks';

const LearnosityLessonReviewPractice = () => {
  const history = useHistory();
  const [session, setSession] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: lessonReview, remove } = useLessonReview();
  const user = useUser();
  const initializePractice: any = useInitializePracticeSession();
  const isLearnosityLessonReviewPracticeEnabled = useIsLearnosityLessonReviewEnabled();

  const navigateToLessonReview = useNavigateToLessonReview();
  const goBack = () => {
    history.goBack();
  };

  const setPracticeSession = React.useCallback(() => {
    initializePractice.mutate(PRACTICE_TYPE.LEARNOSITY_LESSON_REVIEW, {
      onSuccess(data) {
        remove();
        setSession(data?.response.data);
      },
    });
  }, []);

  useEffect(() => {
    if (!session) {
      setPracticeSession();
    }
  }, []);

  const handleSubmit = React.useCallback(async () => {
    setIsSubmitting(true);
    await completePracticeSession(session?.id);
    setPracticeSession();
    setTimeout(() => {
      navigateToLessonReview();
    }, 5000);
  }, [session?.id, setPracticeSession]);

  if (!isLearnosityLessonReviewPracticeEnabled) {
    return history.push('/dashboard');
  }

  // render the assessment
  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Back to Lesson" goBack={goBack} />
      <Box
        sx={{
          flex: 1,
          height: 'calc(100vh - 64px)',
        }}
      >
        {!isSubmitting && session ? (
          <ScrollWrapper>
            <Assessment
              handleActivityExit={goBack}
              userId={`${user.id}`}
              sessionId={session?.uuid}
              activityId={`${lessonReview.id}`}
              title={lessonReview.name}
              name="ChalkTalk Assessment"
              subtitle={`${user.first_name || 'N/A'} ${user.last_name || 'N/A'}`}
              submissionHandler={handleSubmit}
              questions={lessonReview.questions}
            />
          </ScrollWrapper>
        ) : (
          <LoadingIndicator
            content={!session ? 'Initializing Practice' : 'Submitting Practice'}
          />
        )}
      </Box>
    </PracticeContainer>
  );
};

export default React.memo(LearnosityLessonReviewPractice);
