/* eslint-disable import/no-cycle */
/* eslint-disable eqeqeq */
// action definition
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _sumBy from 'lodash/sumBy';
import _round from 'lodash/round';
import _groupBy from 'lodash/groupBy';
import _reduce from 'lodash/reduce';
import _union from 'lodash/union';
import _meanBy from 'lodash/meanBy';
import moment from 'moment';
import { createSelector } from 'reselect';
import { REHYDRATE } from 'redux-persist';
import { selectors as sectionStudentSelectors } from '../sectionStudent';
import { getBeginAndEndDate } from '../../utils/commonUtils';
import { selectors as sectionUnitSelectors } from '../sectionUnit';
import { selectors as curriculumLessonSelectors } from '../curriculumLessons';
import { selectors as subjectSelectors } from '../sectionSubjects';
import { selectors as activitySelectors } from '../activity';
import { selectors as sectionSkillsSelectors } from '../sectionSkills';

export const SECTIONS_UPDATE = 'sections/UPDATE';
export const SECTIONS_UPDATE_SUCCESS = 'sections/UPDATE_SUCCESS';
export const SECTIONS_UPDATE_FAIL = 'sections/UPDATE_FAIL';
export const SECTION_UPDATE_COMPLETE = 'sections/UPDATE_COMPLETE';
export const SECTION_UPDATE_UN_COMPLETE = 'sections/UPDATE_UN_COMPLETE';

export const SECTIONS_GET_DETAIL = 'sections/GET_DETAIL';
export const SECTIONS_GET_DETAIL_SUCCESS = 'sections/GET_DETAIL_SUCCESS';
export const SECTIONS_GET_DETAIL_FAIL = 'sections/GET_DETAIL_FAIL';

export const SECTIONS_GET_ALL_BY_COURSE = 'sections/GET_ALL_BY_COURSE';
export const SECTIONS_GET_ALL_BY_COURSE_SUCCESS = 'sections/GET_ALL_BY_COURSE_SUCCESS';
export const SECTIONS_GET_ALL_BY_COURSE_FAIL = 'sections/GET_ALL_BY_COURSE_FAIL';


export const COURSE_GET_ALL_SECTIONS_LIST = 'courses/COURSE_GET_ALL_SECTIONS_LIST';
export const COURSE_GET_ALL_SECTIONS_LIST_SUCCESS = 'courses/COURSE_GET_ALL_SECTIONS_LIST_SUCCESS';
export const COURSE_GET_ALL_SECTIONS_LIST_FAIL = 'courses/COURSE_GET_ALL_SECTIONS_LIST_FAIL';

export const SECTIONS_GET_ON_START = 'sections/GET_ON_START';
export const SECTIONS_GET_ON_START_SUCCESS = 'sections/GET_ON_START_SUCCESS';
export const SECTIONS_GET_ON_START_FAIL = 'sections/GET_ON_START_FAIL';

export const SECTIONS_CREATE_NEW = 'sections/CREATE_NEW';
export const SECTIONS_CREATE_NEW_SUCCESS = 'sections/CREATE_NEW_SUCCESS';
export const SECTIONS_CREATE_NEW_FAIL = 'sections/CREATE_NEW_FAIL';

export const SECTIONS_REQUEST_ENROLLMENT = 'sections/REQUEST_ENROLLMENT';
export const SECTIONS_REQUEST_ENROLLMENT_SUCCESS = 'sections/REQUEST_ENROLLMENT_SUCCESS';
export const SECTIONS_REQUEST_ENROLLMENT_FAIL = 'sections/REQUEST_ENROLLMENT_FAIL';

export const DEACTIVATE_SECTION = 'sections/DEACTIVATE_SECTION';
export const DEACTIVATE_SECTION_SUCCESS = 'sections/DEACTIVATE_SECTION_SUCCESS';
export const DEACTIVATE_SECTION_FAIL = 'section/DEACTIVATE_SECTION_FAIL';

export const SECTIONS_ATTENDANCE_GET = 'sections/SECTIONS_ATTENDANCE_GET';
export const SECTIONS_ATTENDANCE_SUCCESS = 'sections/SECTIONS_ATTENDANCE_SUCCESS';
export const SECTIONS_ATTENDANCE_FAIL = 'sections/SECTIONS_ATTENDANCE_FAIL';

export const SECTIONS_ATTENDANCE_GET_SUMMARY = 'sections/ATTENDANCE_GET_SUMMARY';
export const SECTIONS_ATTENDANCE_GET_SUMMARY_SUCCESS = 'sections/ATTENDANCE_GET_SUMMARY_SUCCESS';
export const SECTIONS_ATTENDANCE_GET_SUMMARY_FAIL = 'sections/ATTENDANCE_GET_SUMMARY_FAIL';

export const SECTIONS_EXAMS_SCORES_GET = 'sections/SECTIONS_EXAMS_SCORES_GET';
export const SECTIONS_EXAMS_SCORES_SUCCESS = 'sections/SECTIONS_EXAMS_SCORES_SUCCESS';
export const SECTIONS_EXAMS_SCORES_FAIL = 'sections/SECTIONS_EXAMS_SCORES_FAIL';

export const SECTIONS_ESTIMATED_GET = 'sections/SECTIONS_ESTIMATED_GET';
export const SECTIONS_ESTIMATED_SUCCESS = 'sections/SECTIONS_ESTIMATED_SUCCESS';
export const SECTIONS_ESTIMATED_FAIL = 'sections/SECTIONS_ESTIMATED_FAIL';

export const SECTIONS_ESTIMATED_STUDENT_GET = 'sections/SECTIONS_ESTIMATED_STUDENT_GET';
export const SECTIONS_ESTIMATED_STUDENT_GET_SUCCESS = 'sections/SECTIONS_ESTIMATED_STUDENT_GET_SUCCESS';
export const SECTIONS_ESTIMATED_STUDENT_GET_FAIL = 'sections/SECTIONS_ESTIMATED_STUDENT_GET_FAIL';

export const SECTIONS_PRACTICE_SUMMARY_GET = 'sections/SECTIONS_PRACTICE_SUMMARY_GET';
export const SECTIONS_PRACTICE_SUMMARY_SUCCESS = 'sections/SECTIONS_PRACTICE_SUMMARY_SUCCESS';
export const SECTIONS_PRACTICE_SUMMARY_FAIL = 'sections/SECTIONS_PRACTICE_SUMMARY_FAIL';

export const SECTIONS_USER_PRACTICE_SUMMARY_GET = 'sections/SECTIONS_USER_PRACTICE_SUMMARY_GET';
export const SECTIONS_USER_PRACTICE_SUMMARY_GET_SUCCESS = 'sections/SECTIONS_USER_PRACTICE_SUMMARY_GET_SUCCESS';
export const SECTIONS_USER_PRACTICE_SUMMARY_GET_FAIL = 'sections/SECTIONS_USER_PRACTICE_SUMMARY_GET_FAIL';

export const SECTION_ATTENDANCE_SUBMIT = 'sections/ATTENDANCE_SUBMISSION';
export const SECTION_ATTENDANCE_SUBMIT_SUCCESS = 'sections/ATTENDANCE_SUBMIT_SUCCESS';
export const SECTION_ATTENDANCE_SUBMIT_FAIL = 'sections/ATTENDANCE_SUBMIT_FAIL';

export const SECTIONS_SELECT_SECTION = 'sections/SELECT_SECTION';

export const SECTION_SET_DEFAULT_ENROLLMENT = 'section/SET_DEFAULT_ENROLLMENT';

export const SECTION_CLEAR_ERROR = 'sections/CLEAR_ERROR';

export const UPDATE_SECTION_UNITS = 'section/UPDATE_SECTION_UNITS';

export const SECTIONS_EDIT_RESET = 'sections/SECTIONS_EDIT_RESET';

export const SECTION_GET_AVAILABLE_PACKAGES = 'sections/SECTION_GET_AVAILABLE_PACKAGES';
export const SECTION_GET_AVAILABLE_PACKAGES_SUCCESS = 'sections/SECTION_GET_AVAILABLE_PACKAGES_SUCCESS';
export const SECTION_GET_AVAILABLE_PACKAGES_FAIL = 'sections/SECTION_GET_AVAILABLE_PACKAGES_FAIL';

// selectors
const getSectionListIds = ({ sections }) => _get(sections, 'sectionListIds', []);
const getSectionListByIdObj = ({ sections }) => _get(sections, 'sectionListById', {});
const getSectionListById = ({ sections }, sectionId) => _get(sections, ['sectionListById', sectionId], {});
const isCreatingNewSection = ({ sections }) => _get(sections, 'isCreatingNew', {});
const isSectionEditSuccessful = ({ sections }) => _get(sections, 'isUpadteSuccessful', null);
const getIsGetAll = ({ sections }) => _get(sections, 'isGetAll', false);
const getCurrentSection = ({ sections }) => _get(sections, 'currentSection', {});
const isRequestingEnrollment = ({ sections }) => _get(sections, 'isRequestingEnrollment', false);
const isRequestedEnrollment = ({ sections }) => _get(sections, 'isRequestedEnrollment', false);

const getSectionAttendanceById = ({ sections }) => _get(sections, 'sectionAttendanceById', {});

const getSectionAttendanceSummaryAllIds = ({ sections }) => _get(sections, 'sectionAttendanceSummaryAllIds', []);
const getSectionAttendanceSummaryById = ({ sections }) => _get(sections, 'sectionAttendanceSummaryById', {});

const getSectionAttendanceSummaryList = createSelector(
  getSectionAttendanceSummaryById,
  (sectionAttendanceSummaryById) => Object.values(sectionAttendanceSummaryById),
);
const getSectionAttendanceSummaryListBySectionId = (state, sectionId) => {
  const sectionAttendanceSummaryList = getSectionAttendanceSummaryList(state);
  return sectionAttendanceSummaryList.filter(
    (attendance) => attendance.section == sectionId,
  );
};

const getErrorMessage = ({ sections }) => sections.errorMsg || '';

const getSectionById = ({ sections }, sectionId) => _get(sections, `sectionListById.${sectionId}`, {});
const examsScoreObject = ({ sections }) => _get(sections, 'examsScore', {});
const getSectionList = createSelector(
  getSectionListIds,
  getSectionListByIdObj,
  (sectionListIds, sectionListById) => sectionListIds.map((sectionId) => sectionListById[sectionId]),
);

const getSectionAttendanceList = createSelector(
  getSectionAttendanceById,
  (sectionAttendanceById) => Object.values(sectionAttendanceById) || [],
);

const getSectionAttendanceListBySectionId = (state, sectionId) => {
  const sectionAttendanceList = getSectionAttendanceList(state);
  return sectionAttendanceList.filter(
    (attendance) => attendance.section == sectionId,
  );
};

const getFirstSection = createSelector(
  getSectionListIds,
  getSectionListByIdObj,
  (sectionListIds, sectionListById) => {
    const firstSectionId = sectionListIds[0];
    return sectionListById[firstSectionId];
  },
);

const getSubjectFromEstimateScore = ({ sections }) => {
  const subjects = _get(sections, 'estimateScore.subjects', []);
  if (!subjects.length) {
    return subjects.map((subject) => ({ id: subject.id, name: subject.name }));
  }
  return subjects;
};
const examsScoreSummary = ({ sections }) => _get(sections, 'scoreSummary', []);
const examsScoreSummaryByUnitAndLesson = ({ sections }, unitId, lessonId) => _get(sections, 'scoreSummary', []).filter(
  (s) => s.unit == unitId && s.lesson == lessonId,
);

const examsScoreSummaryByUnitLessonStudent = (
  { sections },
  unitId,
  lessonId,
  studentId,
) => _get(sections, 'scoreSummary', []).filter(
  (s) => s.unit == unitId && s.lesson == lessonId && s.user == studentId,
);

const examsScoreSummaryByUnitStudent = ({ sections }, unitId, studentId) => _get(sections, 'scoreSummary', []).filter(
  (s) => s.unit == unitId && s.user == studentId,
);
const examsScoreSummaryBySubject = ({ sections }, subjectId) => _get(sections, 'scoreSummary', []).filter((s) => s.subject == subjectId);

const estimateScore = ({ sections }) => _get(sections, 'estimateScore', {});

const getLastWeekIds = ({ sections }) => _get(sections, 'lastWeekIds', []);

const getPercentPresentLastWeekAttendance = (state, sectionId) => {
  const lastWeekIds = getLastWeekIds(state);
  const allAttendanceSummaryById = getSectionAttendanceSummaryById(state);
  const sectionAttendanceById = getSectionAttendanceById(state);

  const lastWeekAttendance = lastWeekIds.map((id) => {
    const attendanceObj = { ...sectionAttendanceById[id] };
    const { records = [] } = attendanceObj;
    return {
      ...allAttendanceSummaryById[id],
      records,
    };
  });
  const totalSessionlength = lastWeekAttendance.length;

  const studentCount = sectionStudentSelectors.getStudentCount(
    state,
    sectionId,
  );
  const filterList = sectionStudentSelectors.getFilter(state, sectionId);

  let totalStudentPresent = 0;
  let percentStudentPresent = 0;

  if (totalSessionlength === 0 || studentCount === 0) {
    return 0;
  }

  if (filterList.length && filterList.length < studentCount) {
    lastWeekAttendance.forEach((attendance) => {
      const { records = [] } = attendance;
      filterList.forEach((studentId) => {
        totalStudentPresent += records.filter(
          (x) => studentId === x.user && x.present,
        ).length;
      });
    });
    percentStudentPresent = (totalStudentPresent / (filterList.length * totalSessionlength)) * 100;
  } else {
    totalStudentPresent = _sumBy(
      lastWeekAttendance,
      (item) => item.present_count,
    );
    percentStudentPresent = (totalStudentPresent / (studentCount * totalSessionlength)) * 100;
  }

  return _round(percentStudentPresent);
};

const getPercentPresentOverallAttendance = (state, sectionId) => {
  const summaryAllIds = getSectionAttendanceSummaryAllIds(state);
  const summaryAllAttendanceById = getSectionAttendanceSummaryById(state);
  const sectionAttendanceById = getSectionAttendanceById(state);

  const allAttendance = summaryAllIds.map((id) => {
    const attendanceObj = { ...sectionAttendanceById[id] };
    const { records = [] } = attendanceObj;
    return {
      ...summaryAllAttendanceById[id],
      records,
    };
  });

  const totalSessionlength = allAttendance.length;

  const studentCount = sectionStudentSelectors.getStudentCount(
    state,
    sectionId,
  );
  const filterList = sectionStudentSelectors.getFilter(state, sectionId);

  if (totalSessionlength === 0 || studentCount === 0) {
    return 0;
  }
  let totalStudentPresent = 0;
  let percentStudentPresent = 0;

  if (filterList.length && filterList.length < studentCount) {
    allAttendance.forEach((attendance) => {
      const { records = [] } = attendance;
      filterList.forEach((studentId) => {
        totalStudentPresent += records.filter(
          (x) => studentId === x.user && x.present,
        ).length;
      });
    });
    percentStudentPresent = (totalStudentPresent / (filterList.length * totalSessionlength)) * 100;
  } else {
    totalStudentPresent = _sumBy(allAttendance, (item) => item.present_count);
    percentStudentPresent = (totalStudentPresent / (studentCount * totalSessionlength)) * 100;
  }

  return _round(percentStudentPresent);
};

const getHighScoreOfEstimateScore = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const totalFilterIds = filterIds.length;
  let totalHighScore = 0;
  if (totalStudent.length === 0) {
    return 0;
  }

  if (!totalFilterIds || totalFilterIds === totalStudent) {
    totalHighScore = _sumBy(studentsIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.high_score`, 0));
    return _round(totalHighScore / totalStudent);
  }

  totalHighScore = _sumBy(filterIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.high_score`, 0));
  return _round(totalHighScore / totalFilterIds);
};

const getLowScoreOfEstimateScore = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const totalFilterIds = filterIds.length;
  let totalLowScore = 0;

  if (totalStudent.length === 0) {
    return 0;
  }
  if (!totalFilterIds || totalFilterIds === totalStudent) {
    totalLowScore = _sumBy(studentsIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.low_score`, 0));
    return _round(totalLowScore / totalStudent);
  }

  totalLowScore = _sumBy(filterIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.low_score`, 0));
  return _round(totalLowScore / totalFilterIds);
};

const getMaxScoreOfEstimateScore = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const totalFilterIds = filterIds.length;
  let totalMaxScore = 0;

  if (totalStudent.length === 0) {
    return 0;
  }

  if (!totalFilterIds || totalFilterIds === totalStudent) {
    totalMaxScore = _sumBy(studentsIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.max_score`, 0));
    return _round(totalMaxScore / totalStudent);
  }

  totalMaxScore = _sumBy(filterIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.max_score`, 0));
  return _round(totalMaxScore / totalFilterIds);
};

const getDeltaOfEstimateScore = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const totalFilterIds = filterIds.length;
  let totalDelta = 0;
  if (totalStudent.length === 0) {
    return 0;
  }

  if (!totalFilterIds || totalFilterIds === totalStudent) {
    totalDelta = _sumBy(studentsIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.delta`, 0));
    return _round(totalDelta / totalStudent);
  }

  totalDelta = _sumBy(filterIds, (studentId) => _get(estimateScoreData, `student_data.${studentId}.delta`, 0));
  return _round(totalDelta / totalFilterIds);
};

const getGradeLevelList = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;

  if (totalStudent.length === 0) {
    return {};
  }

  const gradeLevelValueList = studentsIds.reduce((acc, studentId) => {
    acc[studentId] = _get(estimateScoreData, `grade_level.${studentId}.value`) || 0;
    return acc;
  }, {});

  return gradeLevelValueList;
};

const getGradeLevelValue = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const totalFilterIds = filterIds.length;
  let totalGrade = 0;

  if (totalStudent.length === 0) {
    return 0;
  }
  if (!totalFilterIds || totalFilterIds === totalStudent) {
    totalGrade = _sumBy(studentsIds, (studentId) => _get(estimateScoreData, `grade_level.${studentId}.value`, 0));
    return _round(totalGrade / totalStudent);
  }

  totalGrade = _sumBy(filterIds, (studentId) => _get(estimateScoreData, `grade_level.${studentId}.value`, 0));
  return _round(totalGrade / totalFilterIds);
};

const getGradeLevelDelta = (state, sectionId) => {
  const estimateScoreData = estimateScore(state);
  const studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const totalStudent = studentsIds.length;
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const totalFilterIds = filterIds.length;
  let totalDelta = 0;
  if (totalStudent.length === 0) {
    return 0;
  }
  if (!totalFilterIds || totalFilterIds === totalStudent) {
    totalDelta = _sumBy(studentsIds, (studentId) => _get(estimateScoreData, `grade_level.${studentId}.delta`, 0));
    return _round(totalDelta / totalStudent);
  }

  totalDelta = _sumBy(filterIds, (studentId) => _get(estimateScoreData, `grade_level.${studentId}.delta`, 0));
  return _round(totalDelta / totalFilterIds);
};

const getLexileValue = ({ sections }, value) => _get(sections, `estimateScore.grade_level_map.${value}`, '');

const getPracticeSummaryByUnitId = ({ sections }) => _get(sections, 'practiceSummaryByUnitId', {});

const getPracticeSummaryOfUnit = ({ sections }, unitId) => {
  const practiceSummaryByUnitId = getPracticeSummaryByUnitId({ sections });
  return _get(practiceSummaryByUnitId, unitId, []);
};

const getPracticeSummaryByLessonId = ({ sections }) => _get(sections, 'practiceSummaryByLessonId', {});

const getPracticeActivityBySubjectId = (state, subjectIds, sectionId) => {
  const practiceSummaryByUnitId = getPracticeSummaryByUnitId(state);
  let studentsIds = sectionStudentSelectors.getStudentIds(state, sectionId);
  const filterIds = sectionStudentSelectors.getFilter(state, sectionId);
  const filterIdsLength = filterIds.length;
  let studentLength = studentsIds.length;

  if (studentLength === 0) {
    return {};
  }

  if (filterIdsLength > 0 && filterIdsLength !== studentLength) {
    studentsIds = filterIds;
    studentLength = filterIdsLength;
  }

  const unitBySubjectId = sectionUnitSelectors.getUnitsBySubjectId(state);
  const lessonBySubjectId = curriculumLessonSelectors.getLessonBySubjectId(
    state,
  );
  const lessonById = curriculumLessonSelectors.getLessonById(state);
  const skillByLessonId = sectionSkillsSelectors.getSkillByLessonId(state);
  const userActivityBySubjectId = activitySelectors.getUserActivityBySubjectId(
    state,
  );
  const teacherActivityBySubjectId = activitySelectors.getTeacherActivityBySubjectId(
    state,
  );

  return _reduce(
    subjectIds,
    (prevValue, subjectId) => {
      const unitIds = _get(unitBySubjectId, subjectId, []);
      const unitLength = unitIds.length;
      const userActivityList = _get(userActivityBySubjectId, subjectId, []);
      const teacherActivityList = _get(
        teacherActivityBySubjectId,
        subjectId,
        [],
      );

      const subjectDetail = subjectSelectors.getSubjectDetail(state, subjectId);
      const lessonGroup = _get(subjectDetail, 'summary.lesson_count', 0);

      const countLessonPass = activitySelectors.countLessonPassedWithSubjectId(
        state,
        subjectId,
      );
      if (unitLength === 0) {
        return {
          ...prevValue,
          [subjectId]: {
            avgSessionPerLesson: Math.round(
              (countLessonPass / lessonGroup) * 100,
            ),
            avgSkillPoint: 0,
            practiceCoverage: 0,
            activeStudent: 0,
          },
        };
      }

      const sevenPerOfStudent = studentLength * 0.7;
      const lessonIds = _get(lessonBySubjectId, subjectId, []);
      const lessonIdsCanPractice = lessonIds.filter(
        (lessonId) => (lessonById[lessonId] || {}).can_practice,
      );
      const lessonLength = lessonIds.length;

      const teacherLessonTaught = lessonLength === 0
        ? 0
        : Math.round((teacherActivityList.length / lessonLength) * 100);
      const userLessonTaught = lessonLength === 0
        ? 0
        : Math.round((userActivityList.length / lessonLength) * 100);

      const studentPracticeCount = _sumBy(unitIds, (unitId) => {
        const practiceSummaryOfUnitId = _get(
          practiceSummaryByUnitId,
          unitId,
          [],
        );
        const byLessonId = _groupBy(practiceSummaryOfUnitId, 'lesson');

        // lesson = null mean that is unit practice, if there are more 70% do the unit practice, then all lesson practice will be count
        if (_get(byLessonId, 'null.length', 0) > sevenPerOfStudent) {
          const unitDetail = sectionUnitSelectors.getUnitById(state, unitId);
          const practiceCount = _get(unitDetail, 'practice_count', 0);
          return practiceCount;
        }

        let totalLessonPracticeCoverage = 0;

        Object.keys(byLessonId).forEach((lessonId) => {
          const listByLessonId = _get(byLessonId, lessonId, []);

          if (listByLessonId.length > sevenPerOfStudent) {
            totalLessonPracticeCoverage += 1;
          }
        });

        return totalLessonPracticeCoverage;
      });

      const lessonPracticeLength = lessonIdsCanPractice.length;

      // firstly, calculate mean skill point of all student in one lesson,  then mean of multiple lesson
      const avgSkillPoint = _meanBy(lessonIds, (lessonId) => {
        const meanOfLesson = _meanBy(studentsIds, (studentId) => {
          const { initial = 1, current = 1 } = _get(
            skillByLessonId,
            `${lessonId}.${studentId}`,
            {},
          );

          return current - initial;
        });

        return meanOfLesson;
      });

      return {
        ...prevValue,
        [subjectId]: {
          teacherLessonTaught,
          userLessonTaught,
          studentPracticeCount,
          totalPracticeCount: lessonPracticeLength,
          avgSessionPerLesson: Math.round(
            (countLessonPass / lessonGroup) * 100,
          ),
          avgSkillPoint: _round(avgSkillPoint, 1),
        },
      };
    },
    {},
  );
};

const sectionDetail = ({ sections }) => _get(sections, 'sectionDetail', {});

const shouldFetchSection = ({ sections }, courseId) =>
  _get(sections, "hasFetchedOfCourseId", null) != courseId; // eslint-disable-line

const getSelectedSection = ({ sections }) => _get(sections, 'selectedSection', '');
const isGettingSection = ({ sections }) => sections.isGetting;

const getAllTeachersCourseSection = ({ sections }) => _get(sections, 'allTeachersSections', []);

const AvailablePackages = ({ sections }) => _get(sections, 'availablePackages', []);

export const selectors = {
  getSelectedSection,
  getGradeLevelList,

  getSectionListIds,
  getSectionListById,
  getSectionListByIdObj,
  isSectionEditSuccessful,
  isCreatingNewSection,

  getIsGetAll,
  getCurrentSection,
  getSectionList,
  getFirstSection,
  isRequestingEnrollment,
  isRequestedEnrollment,
  getErrorMessage,
  getSectionById,
  getSectionAttendanceList,
  getSubjectFromEstimateScore,
  getPercentPresentLastWeekAttendance,
  getPercentPresentOverallAttendance,
  getHighScoreOfEstimateScore,
  getLowScoreOfEstimateScore,
  getMaxScoreOfEstimateScore,
  getDeltaOfEstimateScore,
  getGradeLevelValue,
  getGradeLevelDelta,
  getLexileValue,
  getPracticeActivityBySubjectId,
  sectionDetail,
  shouldFetchSection,
  getSectionAttendanceSummaryAllIds,
  getSectionAttendanceSummaryById,
  getSectionAttendanceSummaryList,
  getPracticeSummaryByLessonId,
  getPracticeSummaryByUnitId,
  getPracticeSummaryOfUnit,
  getSectionAttendanceById,
  isGettingSection,

  estimateScore,
  examsScoreObject,
  examsScoreSummary,
  examsScoreSummaryByUnitAndLesson,
  examsScoreSummaryByUnitLessonStudent,
  examsScoreSummaryBySubject,
  examsScoreSummaryByUnitStudent,

  getSectionAttendanceSummaryListBySectionId,
  getSectionAttendanceListBySectionId,
  getAllTeachersCourseSection,

  AvailablePackages
};

// action creators
/**
 *
 * @param {String} courseId
 */
const sectionGetOnStart = (courseId) => ({
  type: SECTIONS_GET_ON_START,
  courseId,
});

const sectionSetDefaultEnrollment = () => ({
  type: SECTION_SET_DEFAULT_ENROLLMENT,
});

const updateSectionUnits = (sectionId) => ({
  type: UPDATE_SECTION_UNITS,
  payload: { sectionId }
});

/**
 *
 * @param {Array} sectionListSortStartDate Array of sections
 */
const sectionGetOnStartSuccess = (sectionListSortStartDate, courseId) => ({
  type: SECTIONS_GET_ON_START_SUCCESS,
  sectionList: sectionListSortStartDate,
  courseId,
});

/**
 *
 * @param {String} errorMsg error message
 */
const sectionGetOnStartFail = (errorMsg) => ({
  type: SECTIONS_GET_ON_START_FAIL,
  errorMsg,
});

/**
 * @param {*} sectionId String
 * @param {*} sectionValues Object: New values of section
 */

const sectionsUpdate = (sectionId, sectionValues) => ({
  type: SECTIONS_UPDATE,
  sectionId,
  sectionValues,
});

/**
 * @param {String} sectionId
 * @param {Object} sectionData
 */
const sectionsUpdateSuccess = (sectionId, sectionData) => ({
  type: SECTIONS_UPDATE_SUCCESS,
  sectionId,
  payload: sectionData,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsUpdateFail = (payload) => ({
  type: SECTIONS_UPDATE_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 */

const sectionsGetDetail = (sectionId, forceUpdate = false) => ({
  type: SECTIONS_GET_DETAIL,
  sectionId,
  forceUpdate,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsGetDetailSuccess = (payload) => ({
  type: SECTIONS_GET_DETAIL_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsGetDetailFail = (error) => ({
  type: SECTIONS_GET_DETAIL_FAIL,
  error,
});

/**
 *
 * @param {String} courseId
 */
const getAllSectionByCourse = (courseId) => ({
  type: SECTIONS_GET_ALL_BY_COURSE,
  courseId,
});

/**
 *
 * @param {Array} sectionListSortStartDate Array of sections
 */
const getAllSectionByCourseSuccess = (sectionListSortStartDate, courseId) => ({
  type: SECTIONS_GET_ALL_BY_COURSE_SUCCESS,
  sectionList: sectionListSortStartDate,
  courseId,
});

/**
 *
 * @param {String} errorMsg error message
 */
const getAllSectionByCourseFail = (errorMsg) => ({
  type: SECTIONS_GET_ALL_BY_COURSE_FAIL,
  errorMsg,
});

/**
 *
 * @param {*} sectionInfo : Obj
 */
export const sectionsCreateNewSection = (sectionInfo, callback) => ({
  type: SECTIONS_CREATE_NEW,
  sectionInfo,
  callback,
});

/**
 * !Required define payload params clearly
 * @param {*} sectionInfo
 */
export const sectionsCreateNewSectionSuccess = (sectionInfo) => ({
  type: SECTIONS_CREATE_NEW_SUCCESS,
  sectionInfo,
});

/**
 * !Required define payload params clearly
 * @param {*} errorMsg
 */
export const sectionsCreateNewSectionFail = (errorMsg) => ({
  type: SECTIONS_CREATE_NEW_FAIL,
  errorMsg,
});

/**
 *
 * @param {String} courseId
 */
const sectionRequestEnrollment = (sectionCode, callback) => ({
  type: SECTIONS_REQUEST_ENROLLMENT,
  sectionCode,
  callback,
});

/**
 *
 * @param {Array} sectionListSortStartDate Array of sections
 */
const sectionRequestEnrollmentSuccess = (newSectionInfo) => ({
  type: SECTIONS_REQUEST_ENROLLMENT_SUCCESS,
  newSectionInfo,
});

/**
 *
 * @param {String} errorMsg error message
 */
const sectionRequestEnrollmentFail = (errorMsg) => ({
  type: SECTIONS_REQUEST_ENROLLMENT_FAIL,
  errorMsg,
});

/**
 *
 * @param {String} sectionId
 */
const deactivateSection = (sectionId) => ({
  type: DEACTIVATE_SECTION,
  sectionId,
});

/**
 *
 * @param {String} sectionId
 */
const deactivateSectionSuccess = (sectionId) => ({
  type: DEACTIVATE_SECTION_SUCCESS,
  sectionId,
});

/**
 *
 * @param {String} errorMsg error message
 */
const deactivateSectionFail = (errorMsg) => ({
  type: DEACTIVATE_SECTION_FAIL,
  errorMsg,
});

const sectionsAttendanceGet = (sectionId) => ({
  type: SECTIONS_ATTENDANCE_GET,
  sectionId,
});

const sectionsAttendanceSuccess = (payload) => ({
  type: SECTIONS_ATTENDANCE_SUCCESS,
  payload,
});

const sectionsAttendanceFail = (error) => ({
  type: SECTIONS_ATTENDANCE_FAIL,
  error,
});

const sectionsAttendanceGetSummary = (sectionId) => ({
  type: SECTIONS_ATTENDANCE_GET_SUMMARY,
  sectionId,
});

const sectionsAttendanceGetSummarySuccess = (payload) => ({
  type: SECTIONS_ATTENDANCE_GET_SUMMARY_SUCCESS,
  payload,
});

const sectionsAttendanceGetSummaryFail = (error) => ({
  type: SECTIONS_ATTENDANCE_GET_SUMMARY_FAIL,
  error,
});

const sectionsExamsScoresGet = (sectionId) => ({
  type: SECTIONS_EXAMS_SCORES_GET,
  sectionId,
});
const sectionsExamsScoresGetSuccess = (payload) => ({
  type: SECTIONS_EXAMS_SCORES_SUCCESS,
  payload,
});
const sectionsExamsScoresGetFail = (error) => ({
  type: SECTIONS_EXAMS_SCORES_FAIL,
  error,
});

const sectionsEstimatedGet = (sectionId) => ({
  type: SECTIONS_ESTIMATED_GET,
  sectionId,
});
const sectionsEstimatedGetSuccess = (payload) => ({
  type: SECTIONS_ESTIMATED_SUCCESS,
  payload,
});
const sectionsEstimatedGetFail = (error) => ({
  type: SECTIONS_ESTIMATED_FAIL,
  error,
});

const sectionsEstimatedStudentGet = (sectionId) => ({
  type: SECTIONS_ESTIMATED_STUDENT_GET,
  sectionId,
});
const sectionsEstimatedStudentGetSuccess = (payload) => ({
  type: SECTIONS_ESTIMATED_STUDENT_GET_SUCCESS,
  payload,
});
const sectionsEstimatedStudentGetFail = (error) => ({
  type: SECTIONS_ESTIMATED_STUDENT_GET_FAIL,
  error,
});

const sectionsPracticeSummaryGet = (
  sectionId,
  unitId,
  lessonId,
  subjectId,
) => ({
  type: SECTIONS_PRACTICE_SUMMARY_GET,
  sectionId,
  unitId,
  lessonId,
  subjectId,
});

const sectionsPracticeSummaryGetSuccess = (payload) => ({
  type: SECTIONS_PRACTICE_SUMMARY_SUCCESS,
  payload,
});

const sectionsPracticeSummaryGetFail = (error) => ({
  type: SECTIONS_PRACTICE_SUMMARY_FAIL,
  error,
});

const sectionsStudentPracticeSummaryGet = (
  sectionId,
  unitId,
  lessonId,
  subjectId,
) => ({
  type: SECTIONS_USER_PRACTICE_SUMMARY_GET,
  sectionId,
  unitId,
  lessonId,
  subjectId,
});

const sectionsStudentPracticeSummaryGetSuccess = (payload) => ({
  type: SECTIONS_USER_PRACTICE_SUMMARY_GET_SUCCESS,
  payload,
});

const sectionsStudentPracticeSummaryGetFail = (error) => ({
  type: SECTIONS_USER_PRACTICE_SUMMARY_GET_FAIL,
  error,
});

// =========================================================

const sectionAttendanceSubmit = (sectionId, sectionAttendanceInfo) => ({
  type: SECTION_ATTENDANCE_SUBMIT,
  sectionAttendanceInfo,
  sectionId,
});

const sectionAttendanceSubmitSuccess = (payload) => ({
  type: SECTION_ATTENDANCE_SUBMIT_SUCCESS,
  payload,
});

const sectionAttendanceSubmitFail = (error) => ({
  type: SECTION_ATTENDANCE_SUBMIT_FAIL,
  error,
});

// =========================================================

const selectSection = (sectionId) => ({
  type: SECTIONS_SELECT_SECTION,
  sectionId,
});

const sectionClearError = () => ({
  type: SECTION_CLEAR_ERROR,
});

const sectionEditReset = () => ({
  type: SECTIONS_EDIT_RESET,
});

const updateSectionComplete = (sectionId, payload) => ({
  type: SECTION_UPDATE_COMPLETE,
  payload: { sectionId, payload }
})

const updateSectionUnComplete = (sectionId, payload) => ({
  type: SECTION_UPDATE_UN_COMPLETE,
  payload: { sectionId, payload }
})

const getAllSectionsByCourse = (courseId) => ({
  type: COURSE_GET_ALL_SECTIONS_LIST,
  courseId,
});

const getAllSectionsByCourseSuccess = (payload) => ({
  type: COURSE_GET_ALL_SECTIONS_LIST_SUCCESS,
  payload,
});

const getAllSectionsByCourseFail = (errorMsg) => ({
  type: COURSE_GET_ALL_SECTIONS_LIST_FAIL,
  errorMsg,
});

const getAvailablePackages = (sectionId) => ({
  type: SECTION_GET_AVAILABLE_PACKAGES,
  payload: { sectionId }
});

const getAvailablePackagesSuccess = (payload) => ({
  type: SECTION_GET_AVAILABLE_PACKAGES_SUCCESS, payload
});

const getAvailablePackagesFail = (errorMsg) => ({
  type: SECTION_GET_AVAILABLE_PACKAGES_FAIL,
  errorMsg,
});
export const actions = {
  selectSection,
  // update section
  sectionsUpdate,
  sectionsUpdateSuccess,
  sectionsUpdateFail,
  updateSectionComplete,
  updateSectionUnComplete,
  sectionEditReset,

  // get detail
  sectionsGetDetail,
  sectionsGetDetailSuccess,
  sectionsGetDetailFail,

  // get all section by course Id
  getAllSectionByCourse,
  getAllSectionByCourseSuccess,
  getAllSectionByCourseFail,

  // get all section by course Id
  sectionsCreateNewSection,
  sectionsCreateNewSectionSuccess,
  sectionsCreateNewSectionFail,

  // get on start page
  sectionGetOnStart,
  sectionGetOnStartSuccess,
  sectionGetOnStartFail,

  // request enrollment
  sectionRequestEnrollment,
  sectionRequestEnrollmentSuccess,
  sectionRequestEnrollmentFail,

  // deactivate section
  deactivateSection,
  deactivateSectionSuccess,
  deactivateSectionFail,

  sectionsAttendanceGet,
  sectionsAttendanceSuccess,
  sectionsAttendanceFail,

  sectionsExamsScoresGet,
  sectionsExamsScoresGetSuccess,
  sectionsExamsScoresGetFail,

  sectionsEstimatedGet,
  sectionsEstimatedGetSuccess,
  sectionsEstimatedGetFail,

  sectionsPracticeSummaryGet,
  sectionsPracticeSummaryGetSuccess,
  sectionsPracticeSummaryGetFail,

  sectionsAttendanceGetSummary,
  sectionsAttendanceGetSummarySuccess,
  sectionsAttendanceGetSummaryFail,

  sectionsEstimatedStudentGet,
  sectionsEstimatedStudentGetSuccess,
  sectionsEstimatedStudentGetFail,

  sectionsStudentPracticeSummaryGet,
  sectionsStudentPracticeSummaryGetSuccess,
  sectionsStudentPracticeSummaryGetFail,

  sectionAttendanceSubmit,
  sectionAttendanceSubmitSuccess,
  sectionAttendanceSubmitFail,

  sectionSetDefaultEnrollment,
  sectionClearError,

  updateSectionUnits,

  getAllSectionsByCourse,
  getAllSectionsByCourseSuccess,
  getAllSectionsByCourseFail,

  getAvailablePackages,
  getAvailablePackagesSuccess,
  getAvailablePackagesFail,
};

// reducers

const initialState = {
  isUpdate: false, // is section update
  isGetDetail: false, // is get detail of section
  isGetAll: false, // is get all sections
  isGetting: false,
  isCreatingNew: false,
  isRequestingEnrollment: false,
  isRequestedEnrollment: false,
  errorMsg: '',
  sectionListIds: [],
  sectionListById: {},
  currentSection: null,
  sectionAttendanceById: {},
  sectionAttendanceAllIds: [],
  errorAttendance: null,
  estimateScore: {},
  errorEstimate: null,
  examsScore: {},
  examsScoreError: null,
  scoreSummary: [],
  errorSummary: null,
  // for dashboard page
  lastWeekIds: [],
  practiceSummaryByUnitId: {},
  practiceSummaryByLessonId: {},
  practiceSummaryByUserId: {},
  // sectionDetail
  sectionDetail: {},
  hasFetchedOfCourseId: null,
  sectionAttendanceSummaryById: {},
  sectionAttendanceSummaryAllIds: [],
  // section attendance
  isSubmittingAttendance: false,
  attendanceSubmitted: false,
  attendanceSubmissionError: '',

  selectedSection: '',
  sectionChanges: [],
  allTeachersSections: [],

  availablePackages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REHYDRATE:
    case SECTION_CLEAR_ERROR:
    case SECTION_SET_DEFAULT_ENROLLMENT: {
      return {
        ...state,
        isRequestedEnrollment: false,
        errorMsg: '',
      };
    }
    case SECTIONS_UPDATE:
      return {
        ...state,
        isUpdate: true,
      };

    case SECTIONS_UPDATE_SUCCESS: {
      const { sectionId, payload } = action;

      return {
        ...state,
        isUpdate: false,
        isUpadteSuccessful: true,
        sectionListById: {
          ...state.sectionListById,
          [sectionId]: payload,
        },
      };
    }
    case SECTIONS_UPDATE_FAIL:
      return {
        ...state,
        isUpdate: false,
        isUpadteSuccessful: false,
      };

    // get section detail
    case SECTIONS_GET_DETAIL:
      return {
        ...state,
        isGetDetail: true,
      };

    case SECTIONS_GET_DETAIL_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        sectionListById: {
          ...state.sectionListById,
          [payload.id]: payload,
        },
        sectionDetail: payload,
        isGetDetail: false,
      };
    }
    case SECTIONS_GET_DETAIL_FAIL:
      return {
        ...state,
        isGetDetail: false,
      };

    // get all by course id
    case SECTIONS_GET_ALL_BY_COURSE: {
      const { courseId } = action;
      const shouldFetch = courseId != state.hasFetchedOfCourseId; // eslint-disable-line

      return {
        ...state,
        isGetAll: shouldFetch,
      };
    }
    case SECTIONS_GET_ON_START_SUCCESS:
    case SECTIONS_GET_ALL_BY_COURSE_SUCCESS: {
      const { sectionList = [], courseId } = action;
      const sectionListIds = sectionList.map(({ id }) => id);
      const sectionListById = _keyBy(sectionList, 'id');

      return {
        ...state,
        isGetAll: false,
        sectionListById,
        sectionListIds,
        hasFetchedOfCourseId: courseId,
      };
    }

    case SECTIONS_GET_ALL_BY_COURSE_FAIL:
      return {
        ...state,
        isGetAll: false,
      };

    // handle new action created
    case SECTIONS_CREATE_NEW: {
      return {
        ...state,
        isCreatingNew: true,
        errorMsg: '',
      };
    }

    case SECTIONS_CREATE_NEW_SUCCESS: {
      const { sectionInfo } = action;
      return {
        ...state,
        isCreatingNew: false,
        currentSection: sectionInfo,
        sectionListIds: [...state.sectionListIds, sectionInfo.id],
        sectionListById: {
          ...state.sectionListById,
          [sectionInfo.id]: sectionInfo,
        },
      };
    }

    case SECTIONS_CREATE_NEW_FAIL: {
      const { errorMsg } = action;
      return {
        ...state,
        isCreatingNew: false,
        errorMsg,
      };
    }

    case SECTIONS_REQUEST_ENROLLMENT: {
      return {
        ...state,
        isRequestedEnrollment: false,
        isRequestingEnrollment: true,
        errorMsg: '',
      };
    }

    case SECTIONS_REQUEST_ENROLLMENT_SUCCESS: {
      const { newSectionInfo } = action;
      return {
        ...state,
        isRequestedEnrollment: true,
        isRequestingEnrollment: false,
        currentSection: newSectionInfo,
        sectionListIds: [...state.sectionListIds, newSectionInfo.id],
        sectionListById: {
          ...state.sectionListById,
          [newSectionInfo.id]: newSectionInfo,
        },
      };
    }

    case SECTIONS_REQUEST_ENROLLMENT_FAIL: {
      const { errorMsg } = action;

      return {
        ...state,
        isRequestingEnrollment: false,
        errorMsg,
      };
    }
    case DEACTIVATE_SECTION_SUCCESS: {
      const { sectionId } = action;

      const currentListIds = state.sectionListIds;
      const currentListById = state.sectionListById;

      delete currentListById[sectionId];

      return {
        ...state,
        sectionListIds: currentListIds.filter((id) => id !== sectionId),
        sectionListById: { ...currentListById },
      };
    }
    // handle deactivate section
    case DEACTIVATE_SECTION_FAIL: {
      const { errorMsg } = action;

      return {
        ...state,
        errorMsg,
      };
    }
    case SECTIONS_ATTENDANCE_GET_SUMMARY_SUCCESS: {
      const { payload = [] } = action;
      const { beginDate, endDate } = getBeginAndEndDate(
        moment().subtract(1, 'weeks'),
      ); // get begin and end date string with format YYYY-MM-DD

      const lastWeekAttendance = _filter(
        payload,
        (item) => item.date >= beginDate && item.date <= endDate,
      );
      const lastWeekIds = lastWeekAttendance.map((item) => item.id);
      const sectionAttendanceSummaryAllIds = payload.map(({ id }) => id);
      const sectionAttendanceSummaryById = _keyBy(payload, 'id') || {};

      return {
        ...state,
        sectionAttendanceSummaryAllIds,
        sectionAttendanceSummaryById,
        errorAttendance: null,
        lastWeekIds,
      };
    }
    case SECTIONS_ATTENDANCE_SUCCESS: {
      const { payload = [] } = action;
      const sectionAttendanceAllIds = payload.map(({ id }) => id);
      const sectionAttendanceById = _keyBy(payload, 'id') || {};
      return {
        ...state,
        sectionAttendanceAllIds,
        sectionAttendanceById,
        errorAttendance: null,
      };
    }
    case SECTIONS_ATTENDANCE_GET_SUMMARY_FAIL:
    case SECTIONS_ATTENDANCE_FAIL: {
      const { error } = action;
      return {
        ...state,
        errorAttendance: error,
      };
    }
    case SECTIONS_ESTIMATED_STUDENT_GET_SUCCESS:
    case SECTIONS_ESTIMATED_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        estimateScore: payload,
      };
    }
    case SECTIONS_ESTIMATED_STUDENT_GET_FAIL:
    case SECTIONS_ESTIMATED_FAIL: {
      const { error } = action;
      return {
        ...state,
        errorEstimate: error,
      };
    }
    case SECTIONS_EXAMS_SCORES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        examsScore: payload,
        examsScoreError: null,
      };
    }
    case SECTIONS_EXAMS_SCORES_FAIL: {
      const { error } = action;
      return {
        ...state,
        examsScoreError: error,
      };
    }
    case SECTIONS_USER_PRACTICE_SUMMARY_GET_SUCCESS:
    case SECTIONS_PRACTICE_SUMMARY_SUCCESS: {
      const { payload } = action;
      const practiceSummaryByUnitId = _groupBy(payload, 'unit');
      const practiceSummaryByLessonId = _groupBy(payload, 'lesson');
      const practiceSummaryByUserId = _groupBy(payload, 'user');

      return {
        ...state,
        scoreSummary: payload,
        errorSummary: null,
        practiceSummaryByUnitId,
        practiceSummaryByLessonId,
        practiceSummaryByUserId,
      };
    }
    case SECTIONS_USER_PRACTICE_SUMMARY_GET_FAIL:
    case SECTIONS_PRACTICE_SUMMARY_FAIL: {
      const { error } = action;
      return {
        ...state,
        errorSummary: error,
      };
    }

    case SECTIONS_SELECT_SECTION: {
      const { sectionId } = action;
      return {
        ...state,
        selectedSection: sectionId,
      };
    }

    // =============================================

    case SECTION_ATTENDANCE_SUBMIT: {
      return {
        ...state,
        isSubmittingAttendance: true,
        attendanceSubmitted: false,
        attendanceSubmissionError: '',
      };
    }

    case SECTION_ATTENDANCE_SUBMIT_SUCCESS: {
      const { payload } = action;
      const {
        sectionAttendanceAllIds: stateSectionAttendanceAllIds,
        sectionAttendanceById: stateSectionAttendanceById,
      } = state;

      const sectionAttendanceAllIds = payload.map(({ id }) => id);
      const sectionAttendanceById = _keyBy(payload, 'id') || {};

      return {
        ...state,
        isSubmittingAttendance: false,
        attendanceSubmitted: true,
        sectionAttendanceAllIds: _union(
          stateSectionAttendanceAllIds,
          sectionAttendanceAllIds,
        ),
        sectionAttendanceById: {
          ...stateSectionAttendanceById,
          ...sectionAttendanceById,
        },
      };
    }

    case SECTION_ATTENDANCE_SUBMIT_FAIL: {
      const { error } = action;
      return {
        ...state,
        isSubmittingAttendance: false,
        attendanceSubmissionError: error,
      };
    }

    case SECTIONS_EDIT_RESET:
      return {
        ...state,
        isUpadteSuccessful: null,
      };

    // =============================================

    case COURSE_GET_ALL_SECTIONS_LIST_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        allTeachersSections: payload,
      };
    }

    case COURSE_GET_ALL_SECTIONS_LIST_FAIL: {
      return {
        ...state,
        allTeachersSections: [],
      };
    }

    // =============================================

    case SECTION_GET_AVAILABLE_PACKAGES_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        availablePackages: payload,
      };
    }

    case SECTION_GET_AVAILABLE_PACKAGES_FAIL: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
}
