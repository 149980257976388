import { useShowPreview } from '@components/QuestionSelector/QuestionSelector.provider';
import React from 'react';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import Typography from '@components/Atoms/Typography';
import ShowIf from '@components/Atoms/ShowIf';
import Box from '@components/Atoms/Box';
import PreviewQuestions from './PreviewQuestions2';
import QuestionSelection from './QuestionSelection';
import GenerateQuestionsWithCoAuthorDialog from './GenerateQuestionsWithCoAuthorDialog';
import { useShouldShowCoAuthorFeature } from '../hooks';
import GenerateQuestionsButton from './GenerateQuestionsButton';
import { useUser } from '@reducers/user/hooks';

const Main = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [keyId, setKeyId] = React.useState<string>('');
  const [showPreview] = useShowPreview();
  const user = useUser();
  const shouldShowCoAuthorFeature = useShouldShowCoAuthorFeature(user.id);

  return (
    <ScrollWrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'start',
          px: '1rem',
          pt: '1rem',
          paddingInlineEnd: '1.25rem',
        }}
      >
        <Box>
          <Typography variant="h3" sx={{ p: '1rem', py: '0' }}>
            Question Selector
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ pb: '1rem', paddingInlineStart: '1.1rem' }}
          >
            You can add and remove filters from the column header to select any questions
            from this subject
          </Typography>
        </Box>
        <GenerateQuestionsButton
          setOpen={setOpen}
          hasAuthorAideAccess={shouldShowCoAuthorFeature && !showPreview}
        />
      </Box>
      <ShowIf If={showPreview}>
        <PreviewQuestions />
      </ShowIf>
      <ShowIf If={!showPreview}>
        <QuestionSelection keyId={keyId} setOpen={setOpen} />
      </ShowIf>
      <GenerateQuestionsWithCoAuthorDialog
        open={open}
        setOpen={setOpen}
        setKeyId={setKeyId}
      />
    </ScrollWrapper>
  );
};

export default Main;
