import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router';
import CourseSelect from '../../components/CourseSelect';
import {
  selectors as CourseSelectors,
  actions as CourseActions,
} from '../../reducers/courses';
import {
  actions as SectionActions,
} from '../../reducers/sections';
import {
  actions as SectionStudentActions,
} from '../../reducers/sectionStudent';
import { checkAndSetSessionHistory } from '../../utils/cacheCourseAndSection';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', null);
  checkAndSetSessionHistory();

  return {
    courseId,
    courseList: CourseSelectors.getCourseList(state),
    selectedCourse: CourseSelectors.getCourseSelected(state),
    isLoadingCourses: CourseSelectors.getIsLoadingCourses(state),
    coursesById: state.courses.courseListById,
  };
};

const mapDispatchToProps = {
  fetchCourseList: CourseActions.fetchCourseList,
  selectCourse: CourseActions.courseSelected,
  getAllSectionByCourse: SectionActions.getAllSectionByCourse,
  sectionStudentFilterAddAllOrCancel: SectionStudentActions.sectionStudentFilterAddAllOrCancel,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CourseSelect));
