import styled, { css } from 'styled-components';

export const TopNavBarVertical = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;  
  flex: 0 0 6.5em;
  max-width: 6.5em;
  background-color: ${props => props.theme.colorStyled.ColorBgDefault};
  height: 100%;
  padding: 1.25em 0;
  position: relative;
  z-index: 5;
  .primary-layout.show-banner & {
    height: 100%;
    padding: 1.55em 0;
  }
`;

export const LogoChalkTalk = styled.div`
  img{
    width: 2.775em;
    height: 2.775em;
    object-fit: contain;
    border-radius: 20px;
  }
`;

export const NavBarGroup = styled.div`  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 3em;
  width: 100%;
  height: inherit;
`;

export const NavBarPrimary = styled.ul`
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
`;

export const NavBarItem = styled.li`
  margin-bottom: 1.7em ;
  position: relative;
  width: 100%;
  text-align: center;  
  cursor: pointer;
  span{
    color: ${props => props.theme.colorStyled.ColorBgHover};
    font-size: ${props => props.theme.fontSize.HeadingH1FontSize};
    transition: color ease 300ms;    
  }
  &:after{
    background-color: #d6810c;
    position: absolute;
    left: 0;
    top: -9px;
    bottom: -5px;
    display: block;
    right: 0;
    z-index: -1;
  }
  &:hover{
    span{
      color: ${props => props.theme.colorStyled.ColorWhite};
    }
  }
  img{
    width: 1.875em;
    object-fit: contain;
    margin: 0 auto;
    border-radius: 20px;
  }
  ${({ isActive }) => isActive && css`
    span {
      color: ${props => props.theme.colorStyled.ColorWhite};
    }
    &:after{
      content: '';
    }
  `};
`;

export const NavBarIcon = styled.img`
  width: 2em !important;
`;

export const UserIconInitials = styled.div`
  background: #ffffff;
  color: #ff9e16;
  opacity: 0.9;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  width: 1.875em;
  height: 1.875em;
  line-height: 1.875em;
  text-align: center;
`;

export const InfoUserGroup = styled.div`
  color : ${props => props.theme.colorStyled.ColorXBorder};
  width: 100%;
`;

export const DropdownWrap = styled.div`
  position: absolute;
  background: ${props => props.theme.colorStyled.ColorWhite};
  margin-top: .5em;
  border-radius: 0.25em;
  box-shadow: 0px 0px 4px -2px ${props => props.theme.colorStyled.ColorBlack};
  top: -2em;
  right: -8em;
  z-index: 9;
  ${({ schedule }) => schedule && css`
    @media (max-width: 1270px) {
      width: calc(100% - 10px);
      > div {
        min-width: unset;
      }
    }
  `}
  ${({ sessionModal }) => sessionModal && css`
    top: calc(50% + 3em);
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    &:after {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid;
      border-bottom-color: #ffffff;
      top: -6px;
      content: '';
      left: 15%;
      position: absolute;
    }
  `}
  ${({ small }) => small && css`
    top: calc(50% + 1em);
  `}
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 120px;
  padding: .75em;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBgContent};
  color: ${props => props.theme.colorStyled.ColorPrimary};
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${props => props.theme.colorStyled.ColorBgContent};
    border-color: ${props => props.theme.colorStyled.ColorBgContent};
    &:first-child {
      border-radius: .325em .325em 0em 0em;
    }
    &:last-child {
      border-radius: 0em 0em .325em .325em;
    }
  }
  a {
    color: ${props => props.theme.colorStyled.ColorPrimary};
  }
`;
