/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  StudentListWrap,
  InstructionContentWrap,
  InstructionText,
} from '../StudentRegister/StudentRegister.style';
import {
  TableHeadWrapper,
  TableContentWrapper,
  TableContentItem,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import ColumnSize from './ColumnSize';
import { studentData } from './DataStudent';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 100px)',
    maxWidth: '840px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#f4f5f7',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class DataExcelExample extends React.Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
  }

  renderDataHeader = () => (
    <TableHeadWrapper>
      <TableHeader {...ColumnSize[0]} value="First Name" />
      <TableHeader {...ColumnSize[1]} value="Middle Name" />
      <TableHeader {...ColumnSize[2]} value="Last Name" />
      <TableHeader {...ColumnSize[3]} value="Email" />
      <TableHeader {...ColumnSize[4]} value="Grade" />
    </TableHeadWrapper>
  )

  renderDataContent = (value, index) => {
    const {
      firstName, middleName, lastName, email, grade,
    } = value;
    return (
      <TableContentItem key={index}>
        <TableContent {...ColumnSize[0]}>
          {firstName}
        </TableContent>
        <TableContent {...ColumnSize[1]}>
          {middleName}
        </TableContent>
        <TableContent {...ColumnSize[2]}>
          {lastName}
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          {email}
        </TableContent>
        <TableContent {...ColumnSize[4]}>
          {grade.grade_level}
        </TableContent>
      </TableContentItem>
    );
  }

  renderInstructionContent = () => {
    const { toggleModal } = this.props;
    return (
      <InstructionContentWrap>
        <InstructionText>
          This is a example data table, you can do it in Excel
          <i className="chalktalk-close" onClick={toggleModal} />
        </InstructionText>
      </InstructionContentWrap>
    );
  }

  render() {
    const { isModalOpen, toggleModal } = this.props;
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        style={modalStyles}
        ariaHideApp={false}
      >
        {this.renderInstructionContent()}
        {this.renderDataHeader()}
        <StudentListWrap>
          <TableContentWrapper>
            {studentData.map(this.renderDataContent)}
          </TableContentWrapper>
        </StudentListWrap>
      </Modal>
    );
  }
}

DataExcelExample.propTypes = {};

export default DataExcelExample;
