import { connect } from 'react-redux';
import ConfirmInvite from '../../components/ConfirmInvite';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../../reducers/auth';

export const mapStateToProps = state => ({
  isLoading: AuthSelectors.isVerifyingStudentInvitation(state),
});

const mapDispatchToProps = {
  confirmStudentInvite: AuthActions.verifyStudentInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmInvite);
