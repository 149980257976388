import React from 'react';
import PropTypes from 'prop-types';
import Hotkeys from 'react-hot-keys';
import {
  PracticeAnswerMultiChoiceWrap,
  AnswerMultiChoiceItem,
  AnswerChoiceLabel,
  AnswerChoiceContent,
  AnswerChoiceCorrect,
  AnswerChoiceWrong,
  AnswerWrapper,
} from './AnswerMultiChoice.style';
import { ORDER_LABEL, ORDER_SHORTCUT } from '../../utils/constants';
import MathJaxItem from '../MathJaxContainer';

const emptyFn = () => null;

class AnswerMultiChoice extends React.Component {
  static propTypes = {
    choices: PropTypes.array,
    correctChoice: PropTypes.any,
    userChoice: PropTypes.array,
    selectAnswer: PropTypes.func,
    shouldDisplayAnswer: PropTypes.bool,
    handlePrevQuestion: PropTypes.func,
    handleNextQuestion: PropTypes.func,
  };

  state = {
    currentChoice: "",
  };


  static defaultProps = {
    selectAnswer: emptyFn,
  };

  chooseAnswer = (value) => {
    const { selectAnswer, groupStatus, groupDiscussionSubmitAnswer } = this.props;
    selectAnswer(value);
    this.setState({ currentChoice: value });
    if (groupStatus === 2) {
      groupDiscussionSubmitAnswer(value, false);
    }
  };

  onKeyUp = (keyName) => {
    const {
      choices,
      selectAnswer,
      handlePrevQuestion,
      handleNextQuestion,
    } = this.props;

    switch (keyName) {
      case 'left': {
        if (handlePrevQuestion) {
          handlePrevQuestion();
        }

        break;
      }
      case 'right': {
        if (handleNextQuestion) {
          handleNextQuestion();
        }

        break;
      }
      default: {
        const choicesSelected = choices.find(
          (item, index) => index + 1 == keyName,
        ); // eslint-disable-line
        if (choicesSelected) {
          selectAnswer(choicesSelected.id);
        }
        break;
      }
    }
  };

  renderResultStatus = (choice) => {
    const { userChoice, correctChoice } = this.props;
    const { id } = choice;
    const isSelected = userChoice.find((choiceItem) => choiceItem === id);
    const isCorrect = Array.isArray(correctChoice) ? correctChoice.find((choiceItem) => choiceItem === id): [];
    const isWrong = isSelected && !isCorrect;
    const shouldDisplayStatus = isSelected || isCorrect;
    if (!shouldDisplayStatus) {
      return null;
    }

    if (isWrong) {
      return <AnswerChoiceWrong className="chalktalk-close" />;
    }

    return <AnswerChoiceCorrect className="chalktalk-correct" />;
  };

  renderChoiceItem = (choice) => {
    const {
      userChoice = [],
      shouldDisplayAnswer,
      groupStatus,
      groupAnswers,
      activity,
      choice_answer
    } = this.props;
    const { id, asset, order } = choice;
    const { text } = asset;
    const label = ORDER_LABEL[order - 1];
    const isSelected = groupStatus=='review'?choice_answer==id:userChoice.find((choiceItem) => parseInt(choiceItem) === id);
    const choiceChosen = groupAnswers?.filter(
      (answer) => answer.text_answer === id,
    );
    const groupDissgussion = activity === "online-group" && groupStatus === 2;
    return (
      <AnswerWrapper right={groupDissgussion} key={`answer-wrapper-${id}`}>
        {groupDissgussion && (
          <div className="answer-text">
                        {choiceChosen.map((choice, index) => (
              <h1 key={`individual-answer-text-${index}`}>
                {choice?.currentUser ? "Your Answer" : choice?.student_name}
              </h1>
            ))}
          </div>
        )}
        {activity === 'online-group' && groupStatus === 'review' && (
          <div className="answer-text">
          {choice_answer==id &&
              <h1>Your Answer</h1>}
          </div>
        )}
        <AnswerMultiChoiceItem
          key={id}
          onClick={() => this.chooseAnswer(id)}
          activity={activity}
        >
          <AnswerChoiceLabel isSelected={isSelected}>{label}</AnswerChoiceLabel>
          <AnswerChoiceContent>
            <MathJaxItem data={text} />
          </AnswerChoiceContent>
          {shouldDisplayAnswer && this.renderResultStatus(choice)}
        </AnswerMultiChoiceItem>
      </AnswerWrapper>
    );
  };

  render() {
    const { choices } = this.props;

    return (
      <PracticeAnswerMultiChoiceWrap>
        <Hotkeys keyName={ORDER_SHORTCUT} onKeyUp={this.onKeyUp}>
          {choices.map(this.renderChoiceItem)}
        </Hotkeys>
      </PracticeAnswerMultiChoiceWrap>
    );
  }
}

export default AnswerMultiChoice;
