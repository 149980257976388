import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  ModalHeader,
  ModalContent,
  ModalGroupAction,
} from '../ConfirmModal/ConfirmModal.style';
import {
  ReportErrorContent,
  ReportErrorButtonGroup,
  ErrorMessage,
} from './ReportErrorModal.style';
import { datadogRum } from '@datadog/browser-rum';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '500px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const getModalPlaceholder = (isErrorReport) => {
  if (isErrorReport) {
    return "Something went wrong. Please tell us what happened with you";
  }
  return "Report a problem or suggest an improvement";
};

const COLORS = {
  error: '#ff4d4f',
  success: '#52c41a',
  warning: '#faad14',
}

export default class ReportErrorModal extends PureComponent {
  state = {
    value: '',
    error: '',
  }

  componentDidMount() {
    datadogRum.startSessionReplayRecording({ force: true });

    if (this.props.initialMessage) {
      this.setState({
        value: this.props.initialMessage
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialMessage !== prevProps.initialMessage && this.props.initialMessage) {
      this.setState({
        value: this.props.initialMessage
      });
    }

    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        value: '',
        error: ''
      });
    }
  }

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      value,
      error: '', // Clear error when user types
    });
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const { value } = this.state;

    if (!value.trim()) {
      this.setState({ error: 'Please describe the issue before submitting' });
      return;
    }

    onSubmit(value);
  }

  render() {
    const { isOpen, onClose, isErrorReport } = this.props;
    const { value, error } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          <i className="chalktalk-warning" />
          <span>Report Error</span>
        </ModalHeader>
        <ModalContent>
          <ReportErrorContent>
            <textarea
              placeholder={getModalPlaceholder(isErrorReport)}
              onChange={this.onChange}
              value={value}
              required
              aria-required="true"
              style={{ borderColor: error ? COLORS.error : undefined }}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </ReportErrorContent>
          <ReportErrorButtonGroup>
            <button onClick={onClose} type="button">Cancel</button>
            <button onClick={this.onSubmit} type="button">Send Error</button>
          </ReportErrorButtonGroup>
        </ModalContent>
      </Modal>
    );
  }
}

ReportErrorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialMessage: PropTypes.string,
  isErrorReport: PropTypes.bool,
};

ReportErrorModal.defaultProps = {
  initialMessage: '',
  isErrorReport: false,
};
