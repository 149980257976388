/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import _get from 'lodash/get';
import CourseManagementSection from '../../components/CourseManagementSection';
import {
  actions as SectionStudentActions,
  selectors as SectionStudentSelectors,
} from '../../reducers/sectionStudent';
import {
  actions as SectionTeacherActions,
  selectors as SectionTeacherSelectors,
} from '../../reducers/sectionTeachers';
import { actions as SectionActions, selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', null);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return ({
    studentList: SectionStudentSelectors.getStudentList(state, sectionId),
    teacherList: SectionTeacherSelectors.getTeacherList(state, sectionId),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    courseDetail,
    sectionDetail,
    isGettingStudentList: SectionStudentSelectors.isGettingStudentList(state),
    isGettingTeacherList: SectionTeacherSelectors.isGettingTeacherList(state),
  });
};

const mapDispatchToProps = {
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
  sectionsTeachersGet: SectionTeacherActions.sectionsTeachersGet,
  sectionsEstimatedGet: SectionActions.sectionsEstimatedGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementSection);
