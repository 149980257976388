import React from 'react';
import { Formik, Form } from 'formik';
import Modal from 'react-modal';
import _intersectionWith from 'lodash/intersectionWith';
import * as PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import MediaQuery from 'react-responsive';
import { SUMMARY_TYPE } from '../../utils/enums';
import { generateTestAdministrationData } from '../../utils/scheduling';
import LoadingIndicator from '../LoadingIndicator';
import 'react-datepicker/dist/react-datepicker.css';
import { ActionGroup, Button } from '../Generals/stylesheets/Button.style';
import {
  MainHeader,
  LogoChalkTalk,
  SectionProfileInfo,
  SectionLabel,
  SectionContent,
  LogoUnit,
  FlowIndex,
  FlowIndexUnit,
  SummaryProgress,
  SummaryProgressBar,
  SummaryProgressPercent,
  SummaryBlockContent,
  SummaryText,
  Text,
  ProgressRow,
  RecommendedText,
  BorderUnit,
  CustomScrollSectionProfileInfo,
} from './Info.style';
import {
  BottomButtons,
  SelectButton,
  StyledButton,
} from '../ScheduleSection/EditScheduleForm.style';
import * as SectionAPI from '../../apis/sections';
import { CLASS_DAY_OPTION, renderTestDurationOptions } from '../../utils/constants';
import EditScheduleForm from '../../containers/ScheduleSection/EditScheduleForm';

const DATE_IN_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    width: '629px',
    maxWidth: '1080px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    border: 'none',
    maxHeight: '84%',
    height: '84%',
    overflow: 'hidden',
    padding: '20px 10px',
    paddingBottom: '60px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  form: {
    overflowY: 'auto',
    maxHeight: 550,
    marginBottom: 50,
    padding: '0 10px',
  },
  actionGroup: {
    padding: 20,
  },
  grey: {
    backgroundColor: '#fafafa',
  },
  sittings: {
    display: 'flex',
    width: '100%',
  },
  sittingsLabel: {
    flex: '0 0 35%',
    marginLeft: '1em',
  },
  button: {
    minWidth: '25%',
    borderRadius: '10px',
  },
  holidayPlus: {
    borderRadius: '50%',
    padding: '0.1em 0.5em 0.1em 0.5em',
    color: 'white',
    backgroundColor: '#ff9e16',
    marginLeft: '1em',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    width: '20px',
    height: '20px',
  },

  holidayDiv: {
    marginLeft: '0.6em',
  },
  holidayRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1em',
  },
};

class SectionInfo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditMode: true,
      isSavingMode: false,
      sectionSubjectsSelected: [],
      startDate: 0,
      selectedClassDuration: 0,
      minimumSittingsCount: 1,
      holidays: [],
      datePickerIsOpen: false,
      display: 'none',
    };
  }

  static oldSubjects = null;

  static oldSubjectsSelected = null;

  static testDuration = null;

  static testAdministration = null;

  static holidays = null;

  static propTypes = {
    sectionDetail: PropTypes.shape().isRequired,
    curriculumId: PropTypes.any,
    getCurriculumSubjectList: PropTypes.func,
    subjectsById: PropTypes.object,
    subjects: PropTypes.array,
    sectionsUpdate: PropTypes.func,
    isLessons: PropTypes.bool,
    isPractice: PropTypes.bool,
    isReview: PropTypes.bool,
    isWorksheet: PropTypes.bool,
    courseData: PropTypes.array,
  };

  componentDidMount() {
    const {
      getCurriculumSubjectList,
      curriculumId,
      sectionDetail,
      courseData,
      courseId,
      getAllSectionsByCourse,
    } = this.props;
    const { start_date, subjects } = sectionDetail;
    let startDate;
    const holidays = [];
    const sectionSubjectsSelected = [];

    if (courseId) {
      getAllSectionsByCourse(courseId);
    }

    if (start_date === 0) {
      startDate = new Date(start_date).getTime();
    }

    courseData?.holidays?.map((item) =>
      holidays.push({ name: Object.keys(item)[0], date: item[Object.keys(item)[0]] }),
    );

    subjects?.map((subject) => sectionSubjectsSelected.push(subject.display_name));

    this.setState({
      sectionSubjectsSelected,
      holidays,
      startDate,
    });

    getCurriculumSubjectList(curriculumId);
  }

  componentDidUpdate(prevProps) {
    const { getCurriculumSubjectList, curriculumId, isSectionEditSuccessful } =
      this.props;
    const { curriculumId: prevCurriculumId } = prevProps;

    if (!_isEqual(curriculumId, prevCurriculumId)) {
      getCurriculumSubjectList(curriculumId);
    }
    if (!this.props.isUpdate && prevProps.isUpdate != this.props.isUpdate) {
      this.saveAndPublish();
    }
    if (
      prevProps.isSectionEditSuccessful !== isSectionEditSuccessful &&
      isSectionEditSuccessful
    ) {
      this.setState({
        isEditMode: false,
        isSavingMode: true,
      });
    }
  }

  onEditModal = () => {
    this.setState((prevState) => ({
      isEditMode: !prevState.isEditMode,
      startDate: 0,
    }));
    this.props.sectionEditReset();
  };

  saveAndPublish = () => {
    this.setState({
      isSavingMode: false,
      isEditMode: false,
    });
    this.closePopup();
    this.props.sectionScheduleGet(this.props.sectionDetail.id);
  };

  closePopup = () => {
    const { onClose, updateSectionUnits, sectionDetail, isSectionEditSuccessful } =
      this.props;
    const sectionId = _get(sectionDetail, 'id', '');
    updateSectionUnits(sectionId);
    if (isSectionEditSuccessful) {
      onClose();
    }
  };

  renderSubjectItem = (subject, index) => {
    const { subjectsById } = this.props;
    const subjectName = _get(subjectsById, `${subject.id}.name`, '');

    if (index > 0 && subjectName !== '') {
      return `, ${subjectName}`;
    }
    return subjectName;
  };

  // This function will be replaced by computations from generateTestAdministrationData() from utils -> scheduling.js
  renderTestAdminstrationOptions = (curriculumDetail, ClassDuration) => {
    const { sectionDetail, curriculumId } = this.props;
    const subjectsSelected = sectionDetail.subjects.map((s) => s.display_name);
    const subjects = subjectsSelected.filter((s) => s !== 'Introduction') || [];
    const option = generateTestAdministrationData(subjects, ClassDuration, curriculumId);

    const newOption = [];
    let i = 0;
    for (i = 0; i < option.length; i++) {
      if (option[i].id >= this.state.minimumSittingsCount) {
        newOption.push(option[i]);
      }
    }
    return newOption;
  };

  renderClassDay = ({ day }, index) => {
    if (index > 0) {
      return `, ${DATE_IN_WEEK[day]}`;
    }
    return DATE_IN_WEEK[day];
  };

  onCloseModalFinal = () => {
    this.setState({
      isSavingMode: false,
      isEditMode: false,
    });
  };

  renderFormContent = () => {
    const {
      sectionDetail,
      isLessons,
      isPractice,
      isReview,
      isWorksheet,
      curriculumId,
      curriculumDetail,
    } = this.props;
    const { class_days: classDays, section_scheduled_subjects: sectionScheduled } =
      sectionDetail;
    const sortedClassDaysA = _orderBy(
      classDays.filter((day) => day.week == 0),
      'day',
      'asc',
    );
    const sortedClassDaysB = _orderBy(
      classDays.filter((day) => day.week == 1),
      'day',
      'asc',
    );
    const { onClose } = this.props;

    if (curriculumDetail.has_exam) {
      this.getMininmumSittingsNumber();
    }

    this.setState({
      selectedClassDuration: sectionDetail.class_duration,
    });

    if (SectionInfo.oldSubjects === null) {
      SectionInfo.oldSubjects = sectionScheduled;
    }

    if (SectionInfo.oldSubjectsSelected === null) {
      SectionInfo.oldSubjectsSelected = sectionDetail.subjects;
    }

    if (this.state.sectionSubjectsSelected.length === 0) {
      const tmp = sectionDetail.subjects.map((s) => s.display_name);
      this.setState({ sectionSubjectsSelected: tmp });
    }

    if (SectionInfo.testDuration === null) {
      SectionInfo.testDuration = sectionDetail.test_duration;
    }

    if (SectionInfo.holidays === null) {
      const holidays = [...this.state.holidays];
      const holidaysName = {};
      for (let i = 0; i < holidays.length; i++) {
        holidaysName[holidays[i].name] = holidays[i].date;
      }

      SectionInfo.holidays = sectionDetail.holidays.sort(
        (a, b) => new Date(a.date) - new Date(b.date),
      );

      sectionDetail.holidays.forEach((holiday) => {
        if (!holidaysName[holiday.name]) {
          holidays.push(holiday);
        }
      });
      this.setState({
        holidays: holidays.sort((a, b) => new Date(a.date) - new Date(b.date)),
      });
    }
    const sectionSubjects = sectionDetail.subjects.map((subject) => subject.display_name);
    const test_duration = renderTestDurationOptions(curriculumId, sectionSubjects);
    const defaultTestAdministration = this.renderTestAdminstrationOptions(
      curriculumDetail,
      sectionDetail.class_duration,
    ).filter((ele) => ele.id === sectionDetail.sittings_number)[0];

    return (
      <MediaQuery maxWidth={768}>
        {(matche) => (
          <Form style={{ ...(modalStyles.form, matche && { marginBottom: 20 }) }}>
            <SectionProfileInfo>
              <SectionLabel>Subjects Covered</SectionLabel>
              <SectionContent data-testid="subject-names">
                {_isEmpty(sectionDetail.subjects) && 'Null'}
                {sectionDetail.subjects.map(this.renderSubjectItem)}
              </SectionContent>
            </SectionProfileInfo>

            <SectionProfileInfo style={modalStyles.grey}>
              <SectionLabel>Start Date</SectionLabel>
              <SectionContent data-testid="start-date">
                {sectionDetail.start_date}
              </SectionContent>
            </SectionProfileInfo>

            <SectionProfileInfo>
              <SectionLabel>End Date</SectionLabel>
              <SectionContent data-testid="end-date">
                {sectionDetail.end_date}
              </SectionContent>
            </SectionProfileInfo>

            <SectionProfileInfo style={modalStyles.grey}>
              <SectionLabel>Class Duration</SectionLabel>
              <SectionContent data-testid="class-duration">
                {sectionDetail.class_duration}
              </SectionContent>
            </SectionProfileInfo>

            <SectionProfileInfo>
              <SectionLabel>
                {sortedClassDaysB.length > 0 ? 'Week A' : 'Class Days'}
              </SectionLabel>
              <SectionContent data-testid="class-days">
                {_isEmpty(classDays) &&
                  'This section is not set up yet. Click “Edit” below.'}
                {sortedClassDaysA.map(this.renderClassDay)}
              </SectionContent>
            </SectionProfileInfo>
            {sortedClassDaysB.length > 0 && (
              <SectionProfileInfo style={modalStyles.grey}>
                <SectionLabel>Week B</SectionLabel>
                <SectionContent data-testid="class-days">
                  {_isEmpty(classDays) &&
                    'This section is not set up yet. Click “Edit” below.'}
                  {sortedClassDaysB.map(this.renderClassDay)}
                </SectionContent>
              </SectionProfileInfo>
            )}

            <SectionProfileInfo>
              <SectionLabel>Excluded Days</SectionLabel>
              <SectionContent>
                <ul>
                  {sectionDetail.holidays.map((holiday, i) => (
                    <div style={modalStyles.holidayRow} key={i}>
                      <li>
                        {holiday.name} ({holiday.date})
                      </li>
                    </div>
                  ))}
                </ul>
              </SectionContent>
            </SectionProfileInfo>
            {curriculumDetail.has_exam && (
              <React.Fragment>
                <SectionProfileInfo style={modalStyles.grey}>
                  <SectionLabel>Test Duration</SectionLabel>
                  <SectionContent data-testid="test-duration">
                    {test_duration.id === sectionDetail.test_duration
                      ? test_duration.name
                      : ''}
                  </SectionContent>
                </SectionProfileInfo>

                <SectionProfileInfo>
                  <SectionLabel>Test Administration</SectionLabel>
                  <SectionContent data-testid="test-admin">
                    {_get(defaultTestAdministration, 'name', '')}
                  </SectionContent>
                </SectionProfileInfo>
              </React.Fragment>
            )}

            <SummaryBlockContent>
              {sectionDetail.subjects.map(this.renderSummaryProgress)}
            </SummaryBlockContent>
            <RecommendedText>
              {this.state.isSavingMode
                ? 'Your recommended implementation was updated to be follows:'
                : 'Recommended implementation flow'}
            </RecommendedText>
            <BorderUnit></BorderUnit>
            <LogoChalkTalk>
              <LogoUnit>
                {isLessons ? (
                  <img src="/images/icon-v2/school.png" alt="School" />
                ) : (
                  <img src="/images/icon-v2/home.png" alt="Home" />
                )}
                <p>Whole Group Instruction (I do)</p>
              </LogoUnit>
              <LogoUnit>
                {isPractice ? (
                  <img src="/images/icon-v2/school.png" alt="School" />
                ) : (
                  <img src="/images/icon-v2/home.png" alt="Home" />
                )}
                <p>Small Group Practice (We do)</p>
              </LogoUnit>
              <LogoUnit>
                {isWorksheet ? (
                  <img src="/images/icon-v2/school.png" alt="School" />
                ) : (
                  <img src="/images/icon-v2/home.png" alt="Home" />
                )}
                <p>Personalized Practice & Intervention (You do)</p>
              </LogoUnit>
              <LogoUnit>
                {isReview ? (
                  <img src="/images/icon-v2/school.png" alt="School" />
                ) : (
                  <img src="/images/icon-v2/home.png" alt="Home" />
                )}
                <p>Review Classes</p>
              </LogoUnit>
            </LogoChalkTalk>

            <FlowIndex>
              <FlowIndexUnit>
                <img src="/images/icon-v2/school-orange.png" alt="School" />
                <p>Complete this within the course schedule</p>
              </FlowIndexUnit>
              <FlowIndexUnit>
                <img src="/images/icon-v2/home-orange.png" alt="Home" />
                <p>Skip or assign for student to complete on their own</p>
              </FlowIndexUnit>
            </FlowIndex>

            {this.state.isSavingMode && (
              <ActionGroup style={modalStyles.actionGroup} center>
                <Button
                  style={modalStyles.button}
                  onClick={this.onCloseModalFinal}
                  darkBackground
                >
                  Back
                </Button>
                <Button style={modalStyles.button} onClick={this.saveAndPublish} submit>
                  Done
                </Button>
              </ActionGroup>
            )}
            {this.state.isSavingMode || (
              <ActionGroup
                style={{
                  ...(modalStyles.actionGroup, matche && { position: 'relative' }),
                }}
                center
              >
                <Button
                  data-testid="edit-button"
                  style={modalStyles.button}
                  onClick={this.onEditModal}
                  darkBackground
                >
                  Edit
                </Button>
                <Button style={modalStyles.button} onClick={onClose} submit>
                  Confirm
                </Button>
              </ActionGroup>
            )}
          </Form>
        )}
      </MediaQuery>
    );
  };

  resetSectionData = () => {
    const { sectionDetail } = this.props;
    const { section_scheduled_subjects: sectionScheduled } = sectionDetail;
    SectionInfo.oldSubjects = sectionScheduled;
    SectionInfo.oldSubjectsSelected = sectionDetail.subjects;
  };

  renderSummaryProgress = (subject, index) => {
    const { type, sectionDetail } = this.props;
    const { section_scheduled_subjects: sectionScheduled } = sectionDetail;

    const oldSubjected = SectionInfo.oldSubjectsSelected.filter(
      (s) => s.id === subject.id,
    );

    let moreText = '';
    const old_scheduled =
      SectionInfo.oldSubjects[subject.id] === undefined
        ? 0
        : SectionInfo.oldSubjects[subject.id].scheduled;
    const new_scheduled =
      sectionScheduled[subject.id] === undefined
        ? 0
        : sectionScheduled[subject.id].scheduled;
    const new_subject_lessons =
      sectionScheduled[subject.id] === undefined
        ? 0
        : sectionScheduled[subject.id].subject_lessons;
    const old_subject_lessons =
      SectionInfo.oldSubjects[subject.id] === undefined
        ? 0
        : SectionInfo.oldSubjects[subject.id].subject_lessons;
    const isBlank =
      subject.id === 7 ||
      (oldSubjected.length === 0 ? false : old_scheduled === new_scheduled);
    if (oldSubjected.length > 0) {
      if (old_scheduled < new_scheduled) {
        moreText = 'more';
      } else if (old_scheduled > new_scheduled) {
        moreText = 'less';
      }
    } else {
      moreText = '';
    }

    if (index + 1 === sectionDetail.subjects.length) {
      this.resetSectionData();
    }

    return isBlank ? (
      <div key={`blank-${index}`} />
    ) : (
      <ProgressRow key={`progress-row-${index}`}>
        <Text>{`You are now covering ${moreText} ${subject.display_name} lessons`}</Text>
        <SummaryProgress>
          <SummaryProgressBar
            dark={type === SUMMARY_TYPE.DARK}
            yellow={type === SUMMARY_TYPE.YELLOW}
            percent={(new_scheduled * 100) / new_subject_lessons}
          />
          <SummaryProgressPercent>
            {`${new_scheduled}/${new_subject_lessons}`}
          </SummaryProgressPercent>
          <SummaryText>
            {oldSubjected.length > 0
              ? `before (${old_scheduled}/${old_subject_lessons})`
              : `before (0/${new_subject_lessons})`}
          </SummaryText>
        </SummaryProgress>
      </ProgressRow>
    );
  };

  getMininmumSittingsNumber = async () => {
    let placementCount = 0;
    let exitCount = 0;
    let midCount = 0;
    const { sectionId, examSessions } = this.props;

    let scores = [];

    await SectionAPI.getSectionExamsScores(sectionId).then((responseData) => {
      scores = responseData.response && responseData.response.data;
      let j = 0;

      for (j = 0; j < examSessions.length; j++) {
        if ((scores[examSessions[j].id] || []).length > 0) {
          if (examSessions[j].session_type === 0) {
            placementCount++;
          } else if (examSessions[j].session_type === 1) {
            midCount++;
          } else {
            exitCount++;
          }
        }
      }
    });
    this.setState((prevState) => ({
      minimumSittingsCount: Math.max(
        Math.max(Math.max(placementCount, exitCount), midCount),
        prevState.minimumSittingsCount,
      ),
    }));
  };

  customStyle = (matches) => {
    const { content } = modalStyles;
    if (matches) {
      return {
        ...modalStyles,
        content: {
          ...content,
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          borderRadius: '0px',
          maxHeight: '100%',
        },
      };
    }
    return modalStyles;
  };

  render() {
    const { isOpen, onClose, onSubmit, sectionDetail, subjectsById, curriculumDetail } =
      this.props;

    const {
      name,
      subjects: subjectValue,
      class_days: classDay,
      class_duration: classDuration,
    } = sectionDetail;

    if (subjectValue === undefined) {
      return <Form></Form>;
    }

    const mappedSubjects = subjectValue.map((subject) => subjectsById[subject.id]);

    const initialSubjects = mappedSubjects.filter((s) =>
      s === undefined ? false : s.id !== 7 && s.id !== 1,
    );

    const initialValues = {
      name,
      subjects: initialSubjects,
      classDay,
    };

    const { has_exam } = curriculumDetail;
    const mappedClassDay = _intersectionWith(
      CLASS_DAY_OPTION,
      classDay,
      (a, b) => parseInt(a.day, 10) === b.day,
    );

    const { ...rest } = initialValues;

    const newInititalValues = {
      ...rest,
      classDay: mappedClassDay.length > 0 ? mappedClassDay : '',
      startDate: new Date(sectionDetail.start_date),
      endDate: new Date(sectionDetail.end_date),
      test_adminstration: '',
      test_duration: '',
      classDuration: sectionDetail.class_duration,
      subjects: initialSubjects,
      copy_from_another_section: { name: 'None' },
    };
    if (this.props.isUpdate) {
      return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          style={modalStyles}
          ariaHideApp={false}
        >
          <MainHeader>Saving</MainHeader>
          <LoadingIndicator partial noBackground />
        </Modal>
      );
    }

    return (
      <MediaQuery maxWidth={768}>
        {(matches) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={this.customStyle(matches)}
            ariaHideApp={false}
            classDuration={classDuration}
          >
            <EditScheduleForm onCloseModal={onClose} />

            <BottomButtons>
              <SelectButton onClick={onClose} type="button" isSelected={false}>
                Cancel
              </SelectButton>
              <StyledButton type="submit" form="scheduleCreate">
                Save
              </StyledButton>
            </BottomButtons>
          </Modal>
        )}
      </MediaQuery>
    );
  }
}

SectionInfo.propTypes = {
  subjects: PropTypes.array,
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  updateSectionUnits: PropTypes.func,
};

export default SectionInfo;
