/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import _set from 'lodash/set';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _isArray from 'lodash/isArray';
import FormInput from '../Generals/FormInput/FormInput';
import { FormValidateWrapper, FormValidateItem } from '../Generals/stylesheets/Form.style';
import {
  LoginItemWrap,
  LoginItem,
  LoginTitle,
  ButtonStyled,
  LoginErrorMessage,
} from '../LoginPage/Login.style';

const confimPasswordError = 'Your password and confirm password is not match';
const passwordPattern = /[0-9]/;

const validationSchema = Yup.object().shape({
  password: Yup.string().trim()
    .notOneOf(['123456', 123456], 'The password is not 123456')
    .min(8, 'Password must be a minimum of 8 characters long')
    .matches(passwordPattern, 'The password contains at least one number')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], confimPasswordError)
    .required('Confirm password is required'),
});

const defaultPasswordError = {
  simpleNumber: true,
  minLength: true,
  oneNumber: true,
  notMatch: true,
};

export default class PasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  static propTypes = {
    submitNewPassword: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.any,
    errorMsg: PropTypes.any,
  }

  submitSetNewPassword = (values) => {
    const { submitNewPassword, match } = this.props;
    const { password, confirmPassword } = values;
    const { params } = match;
    const { uid, token } = params;
    submitNewPassword(password, confirmPassword, uid, token);
  }

  toggleShowPassword = (showPassword) => {
    this.setState({
      showPassword,
    });
  }

  validate = (values) => {
    const errors = {};
    const { password, confirmPassword } = values;

    if (password.length === 0) {
      errors.password = defaultPasswordError;
    } else {
      if (password === '123456') {
        _set(errors, 'password.simpleNumber', true);
      }

      if (password.length <= 8) {
        _set(errors, 'password.minLength', true);
      }

      if (!passwordPattern.test(password)) {
        _set(errors, 'password.oneNumber', true);
      }

      if (password !== confirmPassword) {
        _set(errors, 'password.notMatch', true);
      }
    }
    return errors;
  }

  renderSubmitBtn = () => {
    const { isLoading } = this.props;
    return (
      isLoading
        ? <ButtonStyled primary>Loading ...</ButtonStyled>
        : <ButtonStyled type="submit">Set Password</ButtonStyled>
    );
  }

  renderRequiredText = (passwordError = {}) => {
    const { simpleNumber, minLength, oneNumber } = passwordError;
    return (
      <FormValidateWrapper>
        <FormValidateItem>
          {simpleNumber
            ? <i className="icon-chalktalk-delete" />
            : <i className="icon-checkmark" />}
          <p>The password is not 123456</p>
        </FormValidateItem>
        <FormValidateItem>
          {minLength
            ? <i className="icon-chalktalk-delete" />
            : <i className="icon-checkmark" />}
          <p>Password must be a minimum of 8 characters long</p>
        </FormValidateItem>
        <FormValidateItem>
          {oneNumber
            ? <i className="icon-chalktalk-delete" />
            : <i className="icon-checkmark" />}
          <p>The password contains at least one number</p>
        </FormValidateItem>
      </FormValidateWrapper>
    );
  }

  renderErrorMessage = (error, index) => (
    <p key={index}>{error}</p>
  )

  renderErrorList = (errorList) => {
    if (!errorList) return null;
    if (_isArray(errorList)) {
      return (
        <LoginErrorMessage>
          {errorList.map(this.renderErrorMessage)}
        </LoginErrorMessage>
      );
    }
    return (
      <LoginErrorMessage>
        {this.renderErrorMessage(errorList, 1)}
      </LoginErrorMessage>
    );
  }

  renderFormContent = ({ handleSubmit, errors, values }) => {
    const { errorMsg } = this.props;
    const passwordValue = _get(values, 'password', '');
    const confirmPasswordValue = _get(values, 'confirmPassword', '');
    const { showPassword } = this.state;

    if (passwordValue.length === 0) {
      errors.password = defaultPasswordError;
    }

    if (confirmPasswordValue.length === 0) {
      if (typeof errors.password === undefined) {
        errors.password = defaultPasswordError;
      }
      
      errors.password.notMatch = false;
    }

    return (
      <form onSubmit={handleSubmit}>
        <FormInput
          type={showPassword ? 'text' : 'password'}
          name="password"
          label="Password"
          handleShowPassword={() => this.toggleShowPassword(true)}
          handleHidePassword={() => this.toggleShowPassword(false)}
          showPassword
        />
        <FormInput
          type="password"
          name="confirmPassword"
          label="Re-enter your password"
          error={errors.confirmPassword === confimPasswordError}
        />
        {this.renderRequiredText(errors.password)}
        {errorMsg && this.renderErrorList(errorMsg)}
        {this.renderSubmitBtn()}
      </form>
    );
  }

  render() {
    return (
      <LoginItemWrap>
        <LoginItem>
          <LoginTitle>
            <h1>Set Your Password</h1>
          </LoginTitle>
          <Formik
            ref={(formik) => { this.formik = formik; }}
            initialValues={{ password: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={this.submitSetNewPassword}
            validate={this.validate}
            render={this.renderFormContent}
          />
          <Link to="/login">
            <ButtonStyled primary>Back</ButtonStyled>
          </Link>
        </LoginItem>
      </LoginItemWrap>
    );
  }
}
