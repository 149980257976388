import React, { useEffect, useState } from 'react';
import { LoadingStyled } from '../../stylesheets/Loading.style';

const LoginLoadingIndicator = ({ content }) => {
  const [displayText, setDisplayText] = useState(content);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayText(
        <>
          If you are experiencing issues logging in please visit our{' '}
          <a href="https://helpdesk.chalktalk.com">helpdesk</a> or send an email to{' '}
          <a href="mailto:support@chalktalk.com">support@chalktalk.com</a>.
        </>,
      );
    }, 10 * 1000);

    return () => clearTimeout(timeout);
  });

  return (
    <LoadingStyled>
      <div className="container">
        <div className="cube">
          <div className="sides">
            <div className="top" />
            <div className="right" />
            <div className="bottom" />
            <div className="left" />
            <div className="front" />
            <div className="back" />
          </div>
        </div>
        <div className="text">{displayText}</div>
      </div>
    </LoadingStyled>
  );
};

export default LoginLoadingIndicator;
