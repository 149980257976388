import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  MonthItemComponent,
  TwoWeekItemComponent,
  WeekItemComponent,
} from '../../components/ScheduleCalendar/CalendarItemComponents';
import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,

  selectors as ScheduleSelectors,
  actions as ScheduleActions,
} from '../../reducers/sectionSchedule';

import {
  selectors as SectionsSelectors,
} from '../../reducers/sections';

import {
  actions as ScheduleModalActions,
  selectors as ScheduleModalSelectors,
} from '../../reducers/scheduleModal';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as UserSelectors } from '../../reducers/user';

const mapStateToProps = (state, { match }) => {
  const { params } = match;
  const { courseId, sectionId } = params;
  const sectionDetail = SectionsSelectors.getSectionListById(state, sectionId);
  const { subjects } = sectionDetail;
  const curriculumId = CourseSelectors.getCourseDetailById(state, courseId)
    .curriculum;

  return {
    sessionUpdatingList: ScheduleSelectors.getLoadingSession(state),
    curriculumId,
    courseId,
    userRole: UserSelectors.getUserRole(state),
    sessionSelected: ScheduleModalSelectors.sessionSelected(state),
    scheduleList: SectionScheduleSelectors.getScheduleList(state),
    onEditMode: state.sectionSchedule.onEditMode,
    subjects,
  };
};

const mapDispatchToProps = {
  updateDate: ScheduleActions.sectionScheduleUpdateDate,
  openFormModal: ScheduleModalActions.openFormModal,
  openCreateFormModal: ScheduleModalActions.openCreateFormModal,
  sectionScheduleUpdateFromCalendar:
    SectionScheduleActions.sectionScheduleUpdateFromCalendar,
};

export const MonthItem = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MonthItemComponent),
);
export const TwoWeekItem = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TwoWeekItemComponent),
);