import styled, { css } from 'styled-components';

export const UPRWrapper = styled.div`
  max-width: 40em;
  width: calc(100% - 2em);
  margin: 1em auto;
`;

export const UPRStudentName = styled.div`
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize.HeadingH5FontSize};
  margin-bottom: 1.25em;
  margin-left: 1em;
`;

export const UPRButtonPracticeAgainWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

export const UPRSessionWrapper = styled.div``;

export const UPRSessionTitle = styled.div`
  color: #ccc;
  font-size: ${(props) => props.theme.fontSize.LargerFontSize};
  margin-left: 1em;
`;

export const UPRSessionList = styled.div``;

export const UPRSessionItem = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const UPRSessionItemInfo = styled.div`
  padding-inline-start: 2px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const UPRSessionItemDate = styled.div`
  color: gray;
  display: inline-block;
`;

export const UPRSessionItemDuration = styled.div`
  color: gray;
  display: inline-block;
  margin-left: 1em;
`;

export const UPRSessionItemDetail = styled.div`
  background: ${(props) => props.theme.colorStyled.ColorWhite};
  border-radius: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 6px #00000029;
  width: 100%;
`;

export const UPRSessionItemScore = styled.div`
  border-bottom: 1px solid #ccc;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: right;
  color: #777;
`;

export const UPRSessionItemCard = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 0.75em 0em;
  padding-bottom: 0rem;
  gap: 2rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UPRSessionName = styled.div``;

export const UPRSessionResult = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: space-between;
  max-width: 22rem;
`;

export const UPRSessionResultFrom = styled.div``;

export const UPRSessionResultTo = styled.div``;

export const UPRSessionResultArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UPRSessionReviewAnswer = styled.div`
  border-bottom: 1px solid #ccc;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: right;
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const UPRSkillLevelWrapper = styled.div`
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  ${(props) =>
    props.noMarginRight &&
    css`
      margin-right: 0px;
    `}
`;

export const UPRSkillLevelTitle = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0.35em;
  text-transform: uppercase;
`;

export const UPRSkillLevelNumber = styled.div`
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 0.35em;
`;

export const UPRSkillLevelDot = styled.div`
  display: flex;
`;

export const Dot = styled.div`
  background: #808080;
  width: 0.75em;
  height: 0.75em;
  border-radius: 0.15em;
  margin-right: 0.25em;
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    `}
`;

export const UPRIndividualSessionTitle = styled.div`
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 0.35em;
  padding-top: 0.2em;
  text-align: center;
`;

export const UPRIndividualSessionScore = styled.div`
  font-size: 1em;
  margin-bottom: 0.35em;
  text-align: center;
`;
