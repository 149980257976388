import React from 'react';
import { Tooltip } from '@mui/material';
import Chip from '@components/Atoms/Chip/Chip';

export type Node = {
  id: string;
  label: string;
  children?: Node[];
  noChip?: boolean;
  noCheckbox?: boolean;
  [key: string]: unknown;
};

const getNodeLabel = (node: Node, nodeTag) => (
  <span
    key={node.id}
    style={{
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    }}
  >
    {!!nodeTag && (
      <Chip
        label={nodeTag.name}
        size="small"
        color={nodeTag.color}
        clickable={false}
        variant="outlined"
        sx={{
          fontSize: '0.625rem',
          height: '1rem',
          backgroundColor: 'white',
          cursor: 'pointer',
          '&[role=button]:hover': {
            opacity: 1,
          },
        }}
      />
    )}
    {node.label}
  </span>
);

interface Tag {
  tooltip?: string;
}

interface SelectedChipsProps {
  nodes: Node[];
  tags: Record<string, Tag>;
  disabled: boolean;
  onDelete: (id: string) => void;
  background: Record<string, string>;
}

const SelectedChips: React.FC<SelectedChipsProps> = ({
  nodes,
  tags,
  disabled,
  onDelete,
  background = {},
}) => {
  const defaultTag = 'default' in tags ? tags.default : undefined;

  return nodes.map((node) => {
    const nodeTag = node.id in tags ? tags[node.id] : defaultTag;
    const nodeBackground = background[node.id] ?? 'white';

    return (
      <Tooltip
        title={nodeTag?.tooltip || ''}
        key={node.id}
        followCursor
        placement="bottom-start"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        }}
        slotProps={{
          tooltip: {
            sx: {
              minWidth: '30rem',
              bgcolor: 'white',
              backdropFilter: 'blur(4px)',
              borderRadius: '10px',
              padding: '1rem',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
              border: '1px solid #E0E0E0',
              maxWidth: 'none',
            },
          },
        }}
      >
        <span style={{ display: 'block', maxWidth: '100%' }}>
          <Chip
            key={node.id}
            label={getNodeLabel(node, nodeTag)}
            size="small"
            onDelete={disabled ? undefined : () => onDelete(node.id)}
            color="chipGrayCT"
            clickable={false}
            variant="outlined"
            sx={{
              cursor: 'pointer',
              '&[role=button]:hover': {
                opacity: 1,
              },
              maxWidth: '100%',
              overflow: 'hidden', // Hides overflow text
              textOverflow: 'ellipsis', // Shows "..." for overflowing text
              whiteSpace: 'nowrap', // Prevents wrapping to the next line
              background: nodeBackground,
            }}
          />
        </span>
      </Tooltip>
    );
  });
};

export default SelectedChips;
