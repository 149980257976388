/* eslint-disable eqeqeq */
import React from 'react';
import { useGetExamEstimatedScore } from '@components/Component/Exams/hooks/query';
import TableWithWrapper from '@components/Atoms/Table/TableWithWrapper';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import { useExamScoreRowData } from '@components/Component/Exams/ExamScoreTable/ExamScoreTable.hooks';
import { EXAM_SESSION_TYPES } from '@utils/constants';
import { useGetSectionExamsScores, useExamSessions } from './hooks';
import { OverallObject, SubjectSelectOptionValue } from './SubjectSelect';

const columns = [
  {
    id: 1,
    headerTitle: 'Student',
    relatedKey: 'studentName',
    defaultValue: '',
    isSortable: true,
    style: { textAlign: 'center', minWidth: '10rem' },
  },
  {
    id: 2,
    headerTitle: 'Estimated Score',
    relatedKey: 'estimateScore',
    defaultValue: '--',
    style: { textAlign: 'center' },
  },
  {
    id: 3,
    headerTitle: 'Placement Test',
    relatedKey: 'placementTest',
    style: { textAlign: 'center' },
    defaultValue: '--',
    valueGetter: (param) => !!param && Math.round(param),
  },
  {
    id: 4,
    headerTitle: 'Mid Test',
    relatedKey: 'midTest',
    style: { textAlign: 'center' },
    defaultValue: '--',
    valueGetter: (param) => !!param && Math.round(param),
  },
  {
    id: 5,
    headerTitle: 'Exit Test',
    relatedKey: 'exitTest',
    style: { textAlign: 'center' },
    defaultValue: '--',
    valueGetter: (param) => !!param && Math.round(param),
  },
  {
    id: 6,
    headerTitle: 'Score Improvement',
    relatedKey: 'improvementPercent',
    style: { textAlign: 'center' },
    defaultValue: '--',
  },
];

type EstimatedScoreTableProps = {
  selectedSubject: SubjectSelectOptionValue;
};

const EstimatedScoreTable = ({ selectedSubject }: EstimatedScoreTableProps) => {
  const studentList = useSectionStudentWithFilter();
  const { data: estimatedScoreData } = useGetExamEstimatedScore();
  const { data: SectionExamsScores } = useGetSectionExamsScores();
  const { data: examSessions } = useExamSessions();

  const placementExamDetails = examSessions?.find(
    (examSession) => examSession.session_type == EXAM_SESSION_TYPES.PLACEMENT,
  );
  // TODO: add condition for find to get the earliest mid test
  const midExamDetails = examSessions?.find(
    (examSession) => examSession.session_type == EXAM_SESSION_TYPES.MID,
  );
  const exitExamDetails = examSessions?.find(
    (examSession) => examSession.session_type == EXAM_SESSION_TYPES.EXIT,
  );
  const assessmentNodeId = selectedSubject?.id;
  const placementRowData = useExamScoreRowData(
    placementExamDetails,
    SectionExamsScores?.[placementExamDetails?.id],
  );
  const midRowData = useExamScoreRowData(
    midExamDetails,
    SectionExamsScores?.[midExamDetails?.id],
  );
  const exitRowData = useExamScoreRowData(
    exitExamDetails,
    SectionExamsScores?.[exitExamDetails?.id],
  );
  const rows = React.useMemo(
    () =>
      studentList.map((student) => {
        const studentPlacementScoreObject = placementRowData.find(
          (el) => el.id == student?.id,
        );
        const studentMidScoreObject = midRowData?.find((el) => el.id == student?.id);
        const studentExitScoreObject = exitRowData?.find((el) => el.id == student?.id);
        const estimatedOverAllScore = estimatedScoreData?.student_data?.[student?.id];
        const rangeEstimatedOverAllScore =
          !!estimatedOverAllScore?.low_score &&
          `${Math.round(estimatedOverAllScore?.low_score)} - ${Math.round(
            estimatedOverAllScore?.high_score,
          )}`;
        const estimatedSubjectScore = estimatedScoreData?.subjects?.find(
          (el) => el.assessment_node_id === selectedSubject?.id,
        );
        const studentEstimatedScoreSubject =
          estimatedSubjectScore?.student_data?.[student?.id];
        const rangeEstimatedSubjectScore =
          !!studentEstimatedScoreSubject?.low_score &&
          `${Math.round(studentEstimatedScoreSubject?.low_score)} - ${Math.round(
            studentEstimatedScoreSubject?.high_score,
          )}`;
        const placementTest = Math.round(studentPlacementScoreObject?.[assessmentNodeId]);
        const midTest = Math.round(studentMidScoreObject?.[assessmentNodeId]);
        const exitTest = Math.round(studentExitScoreObject?.[assessmentNodeId]);
        const improvementPercent =
          !!exitTest && !!placementTest ? exitTest - placementTest : undefined;
        return {
          id: student?.id,
          studentName: `${student?.last_name} ${student?.first_name}`,
          placementTest,
          midTest,
          exitTest,
          estimateScore:
            selectedSubject?.id === OverallObject?.id
              ? rangeEstimatedOverAllScore
              : rangeEstimatedSubjectScore,
          improvementPercent,
        };
      }),
    [
      assessmentNodeId,
      estimatedScoreData?.student_data,
      estimatedScoreData?.subjects,
      exitRowData,
      midRowData,
      placementRowData,
      selectedSubject?.id,
      studentList,
    ],
  );

  return <TableWithWrapper columns={columns} rows={rows} hasPagination sx={{ p: 0 }} />;
};

export default EstimatedScoreTable;
