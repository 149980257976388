/* eslint-disable camelcase */
import React, { useEffect, useState, useReducer, useCallback, useMemo } from 'react';
import Box from '@components/Atoms/Box';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import Typography from '@components/Atoms/Typography';
import ShowIf from '@components/Atoms/ShowIf';
import { v4 as uuidv4 } from 'uuid';
import ConfirmModal from '@components/ConfirmModal';
import { useUser } from '@reducers/user/hooks';
import MediaQuery from 'react-responsive';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '@components/Atoms/Button';
import { Passage as PassageType } from '@components/QuestionGenerationPassages/types';
import Passages from '@components/QuestionGenerationPassages';
import QuestionSelectorProvider from '@components/QuestionSelector/QuestionSelector.provider';
import CourseSelect from '@containers/CourseSelect';
import SectionSelect from '@containers/SectionSelect';
import MainContent from '@components/MainContent';
import {
  ActionShowHide,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  LeftSiderBarWrap,
} from '@components/LeftSideBar/LeftSiderBar.style';
import {
  useContentFilterContext,
  useContentFilterSkillContext,
  useContentFilterStandardContext,
} from '@components/ContentFilters/ContentFilterProvider';
import { DOK_MAP } from '@components/Selectors/DokSelector/DokSelector';
import { BLOOMS_MAP } from '@components/Selectors/BloomsSelector/BloomsSelector';
import QuestionGenerationMenu from './QuestionGenerationMenu';
import QuestionGenerationEditor from './QuestionGenerationEditor';
import QuestionGenerationQuestions from './QuestionGenerationQuestions';
import { SubmissionDialog } from './Dialogs';
import SubmissionList from './SubmissionList';
import {
  useGeneratedItem,
  usePostQGSQuestionRequest,
  useSubmitQuestionsToItemBank,
  usePostUserReportIssue,
  useGenerationStatusItems,
} from './hooks';
import {
  StyledBlockWrap,
  QuestionGenerationMenuWrapper,
  EditorWrapper,
  QuestionNavigatorWrapper,
  PassageWrapper,
} from './styles';
import {
  QuestionGenerationMenuType,
  QuestionGenerationServiceMenu,
  MenuObject,
  LearnosityItem,
} from './types';
import ReportErrorModal from '../ReportErrorModal/ReportErrorModal';
import { SERVICE_NAMES } from './constants/services';

const initialState: QuestionGenerationServiceMenu = {
  subject: {},
  questionQuantity: 5,
  includeSlides: false,
  customNeeds: '',
  lessonGroupId: undefined,
  questionType: '',
};

const reducer = (
  state: QuestionGenerationServiceMenu,
  action: { type: QuestionGenerationMenuType; payload: MenuObject },
) => {
  switch (action.type) {
    case QuestionGenerationMenuType.SET_SUBJECT:
      return { ...state, subject: action.payload };
    case QuestionGenerationMenuType.SET_QUESTION_QUANTITY:
      return { ...state, questionQuantity: action.payload };
    case QuestionGenerationMenuType.SET_INCLUDE_SLIDES:
      return { ...state, includeSlides: action.payload };
    case QuestionGenerationMenuType.SET_CUSTOM_NEEDS:
      return { ...state, customNeeds: action.payload };
    case QuestionGenerationMenuType.SET_QUESTION_TYPE:
      return { ...state, questionType: action.payload.type };
    default:
      return state;
  }
};

interface QuestionGenerationServiceProps {
  onSubmitCallback?: () => void;
  inDialog?: boolean;
  onGenerateQuestions?: () => void;
}

const QuestionGenerationService: React.FC<QuestionGenerationServiceProps> = ({
  onSubmitCallback,
  inDialog,
  onGenerateQuestions,
}) => {
  const { subjectId, lessonId } = useParams();

  // fetch the current user
  const currentUser = useUser();

  const [isSideBarClose, setIsSideBarClose] = React.useState(true);

  // handles the question generation request and response will be stored in taskId
  const { mutate: fetchMutate, isLoading } = usePostQGSQuestionRequest();

  // taskId is the "session" id to scope the generated questions to the selected options
  const [taskId, setTaskId] = useState<string | null>(null);

  // prompt when user wants to reset the current session
  const [showResetModal, setShowResetModal] = useState(false);

  // holds the selected question, used to display the question in the editor
  const [selectedItem, setSelectedItem] = useState<LearnosityItem | null>(null);

  // prompt when user wants to submit the generated questions
  const [showSubmitDialog, setShowSubmitDialog] = useState(false);

  // holds the deleted questions
  const [deletedItems, setDeletedItems] = useState<LearnosityItem[]>([]);

  // handles the submission of the questions to the Itembank
  const { mutate: submitMutate, isLoading: isSubmitting } =
    useSubmitQuestionsToItemBank();

  // control error report modal
  const [isReportErrorModalOpen, setIsReportErrorModalOpen] = useState(false);

  // post user report issue
  const { mutate: postUserReportIssue } = usePostUserReportIssue();

  // hold the state of the "generating" questions while waiting for QGS response via websocket
  // null means no request made, true means request made, false means request completed
  const [generating, setGenerating] = useState<boolean | null>(null);

  // state to control the question delete modal
  const [itemToDelete, setItemToDelete] = useState<string | null>(null);

  const [actionType, setActionType] = useState<'submit' | 'reset' | null>(null);

  const [passageMode, setPassageMode] = useState(false);

  const { generationStatuses, clearStatuses } = useGenerationStatusItems(taskId);

  const { selectedStandards } = useContentFilterStandardContext();
  const { selectedSkills } = useContentFilterSkillContext();
  const {
    subjects,
    setSubjectOverride,
    selectedBlooms,
    selectedDok,
    selectedPassage,
    setSelectedPassage,
    clearSelection,
  } = useContentFilterContext();

  // default subject is the first subject in the list
  // if subjectId is provided from the param, it will be used to find the subject
  const defaultSubject = useMemo(() => {
    if (subjectId) {
      const foundSubject = subjects.find((s) => s.id === parseInt(subjectId, 10));
      return foundSubject || subjects[0];
    }

    return subjects[0];
  }, [subjectId, subjects]);

  // holds the menu selection state
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    subject: defaultSubject as unknown as MenuObject,
    lessonGroupId: lessonId ? parseInt(lessonId, 10) : undefined,
  });

  const { items, addItem, removeItem, clearItems, updateItem } = useGeneratedItem(
    taskId,
    setGenerating,
    state.subject,
    selectedBlooms,
    selectedPassage,
  );

  // This is to allow submission of custom questions with no taskId
  const areAllItemsCustom = items.every((i): boolean => i.is_custom);

  // When the user changes any of the menu options, close passage mode
  useEffect(() => {
    setPassageMode(false);
  }, [state]);

  // When the subject changes, update the subject override for the FilterContentProvider
  // to fetch the correct skills and standards
  useEffect(() => {
    setSubjectOverride(state.subject.id);
    if (!inDialog) {
      clearSelection();
    }
  }, [clearSelection, inDialog, setSubjectOverride, state.subject]);

  /**
   * Get the payload to send to the backend for generating questions
   *
   * This will be used to generate the questions and when submitting custom questions
   */
  const getGenerationRequestPayload = useCallback(
    () => ({
      subject: state.subject.id,
      skills: selectedSkills,
      standards: selectedStandards,
      blooms: selectedBlooms ? BLOOMS_MAP[selectedBlooms] : null,
      dok: selectedDok ? DOK_MAP[selectedDok] : null,
      quantity: state.questionQuantity,
      include_slides: state.includeSlides,
      lessongroup: state.lessonGroupId,
      passage: selectedPassage,
      custom_needs: state.customNeeds,
      question_type: state.questionType,
    }),
    [
      state.subject.id,
      state.questionQuantity,
      state.includeSlides,
      state.lessonGroupId,
      state.customNeeds,
      state.questionType,
      selectedSkills,
      selectedStandards,
      selectedBlooms,
      selectedDok,
      selectedPassage,
    ],
  );

  // When error occurs, automatically open report dialog with taskId
  const handleError = (errorTaskId: string | null) => {
    setTaskId(errorTaskId);
    setIsReportErrorModalOpen(true);
  };

  /**
   * Trigger the generation request
   *
   * This will send the request to the API and store the taskId
   */
  const triggerGenerationRequest = () => {
    const payload = getGenerationRequestPayload();

    if (payload.standards.length === 0) {
      toast.error('Please select at least one Standard');
      return;
    }

    if (!payload.dok || !payload.blooms) {
      toast.error('Please select a DOK and Bloom level');
      return;
    }

    fetchMutate(payload, {
      onSuccess: ({ response }: { response: { data: string } }) => {
        setTaskId(response.data);
      },
      onError: () => handleError(taskId),
    });
    onGenerateQuestions?.();
    setGenerating(true);
  };

  /**
   * Add a custom question to the list of generated questions
   *
   * If there is no taskId, it will create and set a new taskId
   * If there is a taskId, it will use the existing taskId
   */
  const addCustomItem = () => {
    if (!taskId) {
      setTaskId(uuidv4());
    }
    addItem({
      response_id: uuidv4(),
      task_id: taskId as string,
      user_id: currentUser.id.toString(),
      is_custom: true,
      is_edited: true,
    });
  };

  /**
   * Check if the item is valid by checking if all questions have a type
   */
  const isItemValid = (item: LearnosityItem): boolean => {
    // For now we check if all questions have a type
    if (item.questions.some((q) => !q.type)) {
      return false;
    }
    return true;
  };

  /**
   * Sets the "selected" item from the list of generated questions
   */
  const selectItem = (referenceId: string) => {
    let currentItem: LearnosityItem | null = null;
    let targetItem: LearnosityItem | null = null;

    items.forEach((i) => {
      if (i.reference === referenceId) {
        targetItem = i as LearnosityItem;
      }
      if (i.reference === selectedItem?.reference) {
        currentItem = i as LearnosityItem;
      }
    });

    if (!currentItem || !targetItem) {
      return;
    }

    if (!isItemValid(currentItem)) {
      toast.error(
        `Cannot move to another question while custom mode is active.
        Please select a question type and save before making changes.`,
      );
      return;
    }

    setSelectedItem(targetItem);
  };

  const clearAllState = useCallback((): void => {
    clearItems();
    setSelectedItem(null);
    setDeletedItems([]);
    setTaskId(null);
    setGenerating(null);
    setActionType(null);
    clearStatuses();
  }, [clearItems, clearStatuses]);

  const submitDeletedItemsAndClear = useCallback(async (): Promise<void> => {
    setActionType('reset');
    // Mark all non-deleted items as deleted
    const itemsToDelete = items.map((item) => ({
      ...item,
      is_deleted: true,
    }));

    // Combine with already deleted items
    const allDeletedItems = [...deletedItems, ...itemsToDelete];

    // Close modal first to prevent UI freeze
    setShowResetModal(false);

    clearAllState();

    if (allDeletedItems.length === 0) {
      clearAllState();
      return;
    }

    const payload = {
      task_id: taskId,
      items: allDeletedItems,
      question_parameters: areAllItemsCustom ? getGenerationRequestPayload() : null,
    };

    try {
      await submitMutate(payload, {
        onSuccess: () => {
          clearAllState();
          // Reset any loading states
          setGenerating(null);
        },
        onError: () => {
          handleError(taskId);
          // Reset loading state even on error
          setGenerating(null);
        },
      });
    } catch (error) {
      // Ensure loading states are cleared even if mutation throws
      setGenerating(null);
      handleError(taskId);
    }
  }, [
    areAllItemsCustom,
    clearAllState,
    deletedItems,
    getGenerationRequestPayload,
    items,
    submitMutate,
    taskId,
  ]);

  // The original resetCallback becomes:
  const resetCallback = useCallback(() => {
    submitDeletedItemsAndClear();
  }, [submitDeletedItemsAndClear]);

  /**
   * Delete an item from the list of generated questions
   *
   * If the item is not a custom question, it will be added to the list of deletedItems
   */
  const triggerDeleteItem = () => {
    const targetItem = items.find((i) => i.reference === itemToDelete);

    if (targetItem) {
      if (!targetItem.is_custom) {
        setDeletedItems((prev) => [
          ...prev,
          { ...(targetItem as LearnosityItem), is_deleted: true },
        ]);
      }

      removeItem(targetItem.reference);

      if (items.length === 1) {
        resetCallback();
      }
    }

    setItemToDelete(null);
  };

  /**
   * Prompt the user if they want to delete the item
   * Checks if the item is valid before deleting
   *
   */
  const deleteItem = (referenceId: string) => {
    const currentItem = items.find((i) => i.reference === selectedItem?.reference);

    if (!currentItem) {
      return;
    }

    if (!isItemValid(currentItem)) {
      toast.error(
        `Cannot delete questions in while custom mode is active.
        Please select a question type and save before making changes.`,
      );
      return;
    }

    setItemToDelete(referenceId);
  };

  /**
   * Validate then submit the items to the Itembank
   */
  const submitItems = () => {
    setActionType('submit');
    const invalidItems = items
      .map((item, index) => (!isItemValid(item) ? index + 1 : null))
      .filter((index) => index !== null) as number[];
    if (invalidItems.length) {
      toast.error(
        <div>
          <p>
            Validation Failed! Questions {invalidItems.join(', ')} need to be completed.
          </p>
        </div>,
      );
      return;
    }

    // Only submit active items
    const payload = {
      task_id: taskId,
      items: items, // Only send active items
      question_parameters: areAllItemsCustom ? getGenerationRequestPayload() : null,
    };

    submitMutate(payload, {
      onSuccess: () => {
        // After successful submission of active items, handle deleted items if any
        if (deletedItems.length > 0) {
          const deletedPayload = {
            task_id: taskId,
            items: deletedItems,
            question_parameters: null,
          };
          // Submit deleted items separately
          submitMutate(deletedPayload);
        }

        if (subjectId) {
          clearAllState();
        } else {
          setShowSubmitDialog(true);
        }
        if (onSubmitCallback) {
          onSubmitCallback();
        }
      },
      onError: () => handleError(taskId),
    });
  };

  /**
   * When items change, this controls the selectedItem state
   */
  useEffect(() => {
    if (!items.length) {
      return;
    }

    // check if selectedItem.reference exists in items
    const selectedItemExists = items.some((i) => i.reference === selectedItem?.reference);

    if (!selectedItemExists) {
      setSelectedItem(items[0]);
    }
  }, [items, selectedItem]);

  // Update the handleSubmitError to include taskId automatically
  const handleSubmitError = (msg: string) => {
    postUserReportIssue(
      {
        trace_id: taskId || 'N/A', // Include the taskId automatically
        content_type: SERVICE_NAMES.CO_AUTHOR,
        content: msg,
      },
      {
        onSuccess: () => {
          toast.success('Report Submitted successfully');
          setIsReportErrorModalOpen(false);
          setTaskId(null); // Clear the taskId after submission
        },
      },
    );
  };

  const getLoadingMessage = () => {
    if (actionType === 'submit') {
      return 'Please wait while we save your questions';
    }
    if (actionType === 'reset') {
      return 'Please wait while we clean up your workspace';
    }
    return 'Please wait...';
  };

  const handlePassageSelection = (passage: PassageType) => {
    setSelectedPassage(passage);
    setPassageMode(false);
  };

  return (
    <>
      <ShowIf If={!inDialog}>
        <LeftSiderBarWrap isClose={isSideBarClose}>
          <LeftSiderBarHeader>
            <LeftSiderBarHeaderGroup>
              <CourseSelect pathName="/coauthor/course" />
              <SectionSelect pathName="/coauthor/course" />
            </LeftSiderBarHeaderGroup>
          </LeftSiderBarHeader>
          <ActionShowHide
            className="toggle-icon"
            onClick={() => setIsSideBarClose(!isSideBarClose)}
          >
            <MediaQuery maxWidth={960}>
              {(matches) =>
                matches ? (
                  <span
                    className={
                      isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'
                    }
                  />
                ) : (
                  <span
                    className={
                      isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'
                    }
                  />
                )
              }
            </MediaQuery>
          </ActionShowHide>
        </LeftSiderBarWrap>
      </ShowIf>
      <MainContent isSideBarClose={isSideBarClose}>
        <SubmissionDialog
          open={showSubmitDialog}
          onClose={() => {
            setShowSubmitDialog(false);
            // Since items are already cleared after submission, just clear remaining state
            clearAllState();
          }}
          submissionList={<SubmissionList state={state} items={items} />}
        />
        <ConfirmModal
          content="Are you sure? You will lose all your progress."
          isOpen={showResetModal}
          onConfirm={resetCallback}
          onClose={() => setShowResetModal(false)}
        />
        <ConfirmModal
          content="Are you sure you want to delete this question?"
          isOpen={!!itemToDelete}
          onConfirm={() => triggerDeleteItem()}
          onClose={() => setItemToDelete(null)}
        />
        <ScrollWrapper
          sx={{
            minHeight: inDialog ? 'calc(80vh)' : 'calc(100vh - 64px)',
          }}
        >
          <ShowIf If={!inDialog}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '1rem',
                pt: '2rem',
              }}
            >
              <Typography variant="h3">ChalkTalk Co-Author v1</Typography>
              <Button
                variant="outlined"
                onClick={() => setIsReportErrorModalOpen(true)}
                startIcon={<i className="chalktalk-warning" />}
                sx={{
                  color: '#453535',
                  borderColor: '#453535',

                  '&:hover': {
                    borderColor: '#453535',
                    backgroundColor: 'rgba(69, 53, 53, 0.04)',
                  },
                }}
              >
                Report Issue
              </Button>
            </Box>
          </ShowIf>
          <StyledBlockWrap inDialog={inDialog}>
            <QuestionSelectorProvider>
              <QuestionGenerationMenuWrapper>
                <QuestionGenerationMenu
                  submitCallback={triggerGenerationRequest}
                  customQuestionCallback={addCustomItem}
                  resetCallback={setShowResetModal}
                  hasTask={!!taskId}
                  isLoading={isLoading}
                  state={state}
                  dispatch={dispatch}
                  isSubmitting={isSubmitting}
                  hasItems={!!items.length}
                  generating={generating}
                  setPassageMode={setPassageMode}
                  inDialog={inDialog}
                />
              </QuestionGenerationMenuWrapper>
            </QuestionSelectorProvider>
            <ShowIf If={passageMode}>
              <PassageWrapper
                style={{
                  width: '75%',
                  height: '100%',
                }}
              >
                <Passages
                  selectPassageCallback={handlePassageSelection}
                  onCloseCallback={() => setPassageMode(false)}
                />
              </PassageWrapper>
            </ShowIf>
            <ShowIf If={!passageMode}>
              <EditorWrapper>
                <QuestionGenerationEditor
                  isLoading={isLoading}
                  hasTask={!!taskId}
                  item={selectedItem}
                  isSubmitting={isSubmitting}
                  updateItem={updateItem}
                  getLoadingMessage={getLoadingMessage}
                />
              </EditorWrapper>
              <ShowIf If={items.length > 0 || generationStatuses.length > 0}>
                <QuestionNavigatorWrapper>
                  <QuestionGenerationQuestions
                    generationStatuses={generationStatuses}
                    items={items}
                    selectedItem={selectedItem}
                    selectItem={selectItem}
                    deleteItem={deleteItem}
                    submitItems={submitItems}
                    isSubmitting={isSubmitting}
                  />
                </QuestionNavigatorWrapper>
              </ShowIf>
            </ShowIf>
          </StyledBlockWrap>
        </ScrollWrapper>
        <ReportErrorModal
          isOpen={isReportErrorModalOpen}
          onClose={() => setIsReportErrorModalOpen(false)}
          onSubmit={handleSubmitError}
          initialMessage={
            taskId
              ? `Issue ID: ${taskId}\n\nPlease describe the issue you encountered:`
              : ''
          }
          isErrorReport={!!taskId}
        />
      </MainContent>
    </>
  );
};

export default QuestionGenerationService;
