import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'react-js-pagination';
import ReactTooltip from 'react-tooltip';
import {
  LessonListContainer,
  LessonListWrap,
  SessionItemWrap,
  SessionContentGroup,
  SessionStatusIcon,
  SessionContent,
  SessionScorePercent,
  SessionActivePercent,
} from '../RecordAttendanceTab/RecordAttendanceTab.style';
import { PaginationWrap } from '../Generals/stylesheets/General.style';
import { ROLE_TYPE } from '../../utils/enums';

export default class LessonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  static propTypes = {
    lessonListData: PropTypes.array,
    saveSelectedSession: PropTypes.func,
    selectedSession: PropTypes.any,
    userRole: PropTypes.string,
  }

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
  }

  handleClick = (id, unitId) => {
    const { saveSelectedSession } = this.props;
    saveSelectedSession(id, unitId);
  }

  renderItem = (lesson, index) => (
    <SessionItemWrap
      key={index}
      isSelected={lesson.id === this.props.selectedSession}
      onClick={() => this.handleClick(lesson.id, lesson.unitId)}
    >
      <SessionContentGroup>
        <SessionStatusIcon className={lesson.isFinishLesson ? 'chalktalk-check-success' : 'chalktalk-in-progress'} />
        <SessionContent>
          <span>{lesson.nameUnit}</span>
          <p>{lesson.nameLesson}</p>
        </SessionContent>
        <SessionScorePercent
          data-tip="Average score of the students."
          data-for="tooltip-lesson-list"
        >
          <p>{`${lesson.score}%`}</p>
          <span>score</span>
        </SessionScorePercent>
        <SessionActivePercent
          data-tip="Percentage of students who have practiced lesson."
          data-for="tooltip-lesson-list"
        >
          <p>{`${lesson.percent}%`}</p>
          <span>active</span>
        </SessionActivePercent>
      </SessionContentGroup>
    </SessionItemWrap>
  )

  renderLessonItem = () => {
    const { lessonListData } = this.props;
    const { activePage } = this.state;
    const first = activePage === 1 ? 1 : (activePage - 1) * 10;
    const end = (first + 10) > lessonListData.length ? lessonListData.length : first + 10;
    const filterData = lessonListData.slice(first - 1, end);
    return filterData.map(this.renderItem);
  }

  render() {
    const { lessonListData = [], userRole } = this.props;
    return (
      <LessonListContainer>
        {userRole !== ROLE_TYPE.STUDENT && (
          <ReactTooltip place="top" effect="solid" id="tooltip-lesson-list" />
        )}
        {!!lessonListData.length
        && (
          <LessonListWrap>
            {this.renderLessonItem()}
            <PaginationWrap>
              <Pagination
                hideDisabled
                activePage={this.state.activePage}
                itemsCountPerPage={10}
                totalItemsCount={lessonListData.length}
                pageRangeDisplayed={3}
                onChange={this.handlePageChange}
              />
            </PaginationWrap>
          </LessonListWrap>
        )}
      </LessonListContainer>
    );
  }
}
