import React, { useCallback, useState } from 'react';
import Divider from '@mui/material/Divider';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@components/Atoms/Typography';
import Box from '@components/Atoms/Box';
import { Collapse } from '@mui/material';
import FleschReadability from '@components/Selectors/FleschReadability/FleschReadability';
import FleschKincaid from '@components/Selectors/KincaidGrade/KincaidGrade';
import Button from '@components/Atoms/Button';
import { Passage } from '@components/QuestionGenerationPassages/types';
import Autocomplete from '@components/Algolia/Autocomplete';
import FilterChips from './FilterChips';
import PassageSearchDialog from './PassageSearchDialog';
import { CHALKTALK } from './ItemBankToggleButtonGroup';

interface FilterChange {
  passage?: Passage | null;
  readability?: string | null;
  grade?: string | null;
}

interface PassageFilterProps {
  onChange: (change: FilterChange) => void;
  author: string;
  defaultReadability?: string | null;
  defaultGrade?: string | null;
}

interface AlgoliaPassage {
  content: string;
  objectID: string;
  title: string;
  metadata: object;
}

const PassageFilter: React.FC<PassageFilterProps> = ({
  onChange,
  author,
  defaultReadability,
  defaultGrade,
}) => {
  const [openPassageDialog, setOpenPassageDialog] = React.useState(false);
  const [filterOpen, setFilterOpen] = useState(false);

  const getPassageFilter = useCallback(() => {
    if (!author) {
      return '';
    }

    if (author === CHALKTALK) {
      return `Author:${author}`;
    }

    return `'Author ID':${author}`;
  }, [author]);

  return (
    <>
      <Collapse in={filterOpen}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
          }}
        >
          <Box
            sx={{
              pb: '1rem',
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5rem',
              justifyContent: 'start',
              alignContent: 'center',
            }}
          >
            <Autocomplete
              index="learnosity_passages"
              filters={getPassageFilter()}
              placeholder="Search for Passage Title or Content"
              resultId="objectID"
              resultTitle="title"
              resultSubtitle="content"
              onSelect={(result: AlgoliaPassage) => {
                onChange?.({
                  passage: {
                    reference: result.objectID,
                    data: {
                      type: 'sharedpassage',
                      heading: result.title,
                      content: result.content,
                      metadata: result.metadata,
                    },
                  },
                });
              }}
            />
            <Box sx={{ mt: '0.25rem' }}>
              <FleschReadability
                width="12rem"
                value={defaultReadability ? [defaultReadability] : []}
                onChange={(selected) => onChange?.({ readability: selected?.[0] })}
                topLabel
              />
            </Box>
            <Box sx={{ mt: '0.25rem' }}>
              <FleschKincaid
                width="11rem"
                value={defaultGrade ? [defaultGrade] : []}
                onChange={(selected) => onChange?.({ grade: selected?.[0] })}
                topLabel
              />
            </Box>
          </Box>
          <Button
            variant="outlined"
            color="chipGrayCT"
            onClick={() => setOpenPassageDialog(true)}
            sx={{
              height: '2.2rem',
              mt: '0.5rem',
            }}
          >
            Open Passage Manager
          </Button>
        </Box>
      </Collapse>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Divider
          sx={{
            my: 0,
            cursor: 'pointer',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => setFilterOpen((prev) => !prev)}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: '0.75rem', display: 'flex', alignItems: 'center', gap: 0.5 }}
          >
            Passage Filters
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                transition: 'transform 0.2s ease',
                transform: filterOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            >
              <KeyboardArrowDown fontSize="small" />
            </Box>
          </Typography>
        </Divider>
        <FilterChips />
      </Box>
      <PassageSearchDialog
        open={openPassageDialog}
        setOpen={setOpenPassageDialog}
        setPassageObject={(passage) => {
          onChange?.({ passage });
        }}
      />
    </>
  );
};

export default PassageFilter;
