import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import PracticeTopNavBar from '../PracticeTopNavBar';
import PracticePdfViewer from '../PracticePdfViewer';
import { PracticeContainer } from '../PracticePage/PracticePage.style';

class TestPdfViewer extends React.PureComponent {
  redirectToExamDetailPage = () => {
    const {
      history, courseId, sectionId, examSessionId,
    } = this.props;

    history.push(`/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}`);
  }

  render() {
    const {
      examDetail, examSectionId,
    } = this.props;

    // return loading when no exam detail and isTakeQuestion
    if (_isEmpty(examDetail)) {
      return null;
    }

    const examSections = _get(examDetail, 'exam.sections', []);

    const sectionSeleted = examSections.find(section => section.id == examSectionId); // eslint-disable-line
    const examName = _get(sectionSeleted, 'name', '');
    const pdfSource = _get(sectionSeleted, 'source_url', '');

    return (
      <PracticeContainer>
        <PracticeTopNavBar
          name={examName}
          displayTimeOut={false}
          goBack={this.redirectToExamDetailPage}
        />
        <PracticePdfViewer pdfSource={pdfSource} isViewer />
      </PracticeContainer>
    );
  }
}

TestPdfViewer.propTypes = {
  sectionId: PropTypes.any,
  examSectionId: PropTypes.any,
  examSessionId: PropTypes.any,
  history: PropTypes.any,
  courseId: PropTypes.any,
  sectionExamGetDetail: PropTypes.func,
  examDetail: PropTypes.object,
};

export default TestPdfViewer;
