import React from 'react';
import Box from '@mui/material/Box';
import UserChip from '../UserChip/UserChip';

type Props<T> = {
  users: T[];
  // eslint-disable-next-line no-unused-vars
  nameMapper: (user: T) => string;
  // eslint-disable-next-line no-unused-vars
  keyMapper?: (user: T) => string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unnecessary-type-constraint
const UserChipList = <T extends object>({ users, nameMapper, keyMapper }: Props<T>) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      justifyContent: 'start',
      maxWidth: '100%',
      width: 'fit-content',
      gap: '0.25rem',
      flex: '0 1',
      minHeight: '1.5rem',
      minWidth: 'fit-content',
    }}
  >
    {users.map((user) => (
      <UserChip
        name={nameMapper(user)}
        key={keyMapper ? `${keyMapper(user)}` : `${nameMapper(user) || ''}`}
      />
    ))}
  </Box>
);

export default React.memo(
  UserChipList,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.users) === JSON.stringify(nextProps.users),
) as typeof UserChipList;
