import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 1em;
  i {
    font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
    margin-right: .5em;
    color: #aeaeae;
  }
`;

export const ModalContent = styled.div`
  line-height: 20px;
  color: #6e6e6e;
  > i {
    position: absolute;
    right: 1.5em;
    top: 2em;
  }
`;

export const ModalGroupAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.25em;
  button {
    margin-left: .5em;
    text-align: center;
    border-radius: .325em;
    font-family: 'MontserratSemiBold';
    cursor: pointer;
    border: none;
    color: #ffffff;
    width: 6em;
    height: 2.5em;
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
    &:first-child {
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    }
    background-color: #848484;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
