import { takeEvery, call, put, select } from 'redux-saga/effects';
import * as CurriculumAPI from '../../apis/curriculum';
import {
  CURRICULUM_LESSONS_GET_DETAIL,
  CURRICULUM_LESSONS_GET_STANDARD,
  CURRICULUM_LESSONS_GET_OER,
  CURRICULUM_LESSON_GET_ATTENDANCE,
  CURRICULUM_LESSON_POST_ATTENDANCE,
  actions,
  selectors as curriculumLessonSelectors,
  CURRICULUM_LESSON_POST_SLIDE,
  CURRICULUM_LESSON_UPDATE_REVIEW_STATUS,
  CURRICULUM_LESSON_GET_REVIEWS,
} from '../../reducers/curriculumLessons';
import { actions as sectionsActions } from '../../reducers/sections';

import { selectors as userSelectors } from '../../reducers/user';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';

function* curriculumLessonsGetDetail({ lessonId }) {
  const { response, error } = yield call(CurriculumAPI.getLessonDetail, lessonId);
  if (response) {
    const { data } = response;
    yield put(actions.curriculumLessonsGetDetailSuccess(data));
  } else {
    yield put(actions.curriculumLessonsGetDetailFail(error));
  }
}

function* curriculumLessonsGetStandard({ lessonId, standardGroupId }) {
  const { response, error } = yield call(
    CurriculumAPI.getLessonStandard,
    lessonId,
    standardGroupId,
  );
  if (response) {
    const { data } = response;
    yield put(actions.curriculumLessonsGetStandardSuccess(data));
  } else {
    yield put(actions.curriculumLessonsGetStandardFail(error));
  }
}

function* curriculumLessonsGetOER({ lessonId }) {
  const { response, error } = yield call(CurriculumAPI.getLessonOER, lessonId);
  if (response) {
    const { data } = response;
    yield put(actions.curriculumLessonsGetOERSuccess(lessonId, data));
  } else {
    yield put(actions.curriculumLessonsGetOERFail(error));
  }
}

function* curriculumLessonGetAttendance({ lessonId, sectionId }) {
  const { response, error } = yield call(
    CurriculumAPI.getLessonAttendance,
    lessonId,
    sectionId,
  );

  if (response) {
    const { data } = response;
    yield put(actions.curriculumLessonGetAttendanceSuccess(data));
  } else {
    yield put(actions.curriculumLessonGetAttendanceFail(error));
  }
}

function* curriculumLessonPostAttendance({ lessonId, lessonAttendanceInfo }) {
  const userId = yield select(userSelectors.getUserId);
  const attendanceData = { ...lessonAttendanceInfo[lessonId], recorded_by: userId };
  const sectionId = parseInt(lessonAttendanceInfo[lessonId]?.section);
  const { response, error } = yield call(
    CurriculumAPI.submitLessonAttendance,
    lessonId,
    attendanceData,
  );
  if (!error && response) {
    const { data } = response;
    yield put(actions.curriculumLessonPostAttendanceSuccess(data));
    yield put(sectionsActions.sectionsAttendanceGet(sectionId));
  } else {
    yield put(actions.curriculumLessonPostAttendanceFail(error));
  }
}

function* curriculumLessonPostSlide({ lessonId, slideInfo }) {
  const { response, error } = yield call(
    CurriculumAPI.submitLessonSlide,
    lessonId,
    slideInfo,
  );
  if (response) {
    const { data } = response;
    yield put(actions.curriculumLessonPostSlideSuccess(data));
  } else {
    yield put(actions.curriculumLessonPostSlideFail(error));
  }
}

function* curriculumLessonUpdateReviewStatus({ lessonId, reviewId, reviewInfo }) {
  const { response, error } = yield call(
    CurriculumAPI.updateReviewStatus,
    lessonId,
    reviewId,
    reviewInfo,
  );
  const isStatusPending = reviewInfo.status === 'Pending';
  if (!error && response) {
    const { data } = response;
    yield put(actions.curriculumLessonUpdateReviewStatusSuccess(data));
    isStatusPending && ToastSuccess('Slide submitted successfully');
  } else {
    yield put(actions.curriculumLessonUpdateReviewStatusFail(error));
    isStatusPending && ToastError('Failed to submit the slide');
  }
}

function* curriculumLessonGetReviews(params) {
  const { response, error } = yield call(
    CurriculumAPI.getCurriculumLessonReviews,
    params,
  );
  if (!error && response) {
    const { data } = response;
    yield put(actions.curriculumLessonGetReviewsSuccess(data));
  } else {
    yield put(actions.curriculumLessonGetReviewsFail(error));
  }
}

export default function* curriculumLessonsSaga() {
  yield takeEvery(CURRICULUM_LESSONS_GET_DETAIL, curriculumLessonsGetDetail);
  yield takeEvery(CURRICULUM_LESSONS_GET_STANDARD, curriculumLessonsGetStandard);
  yield takeEvery(CURRICULUM_LESSONS_GET_OER, curriculumLessonsGetOER);
  yield takeEvery(CURRICULUM_LESSON_POST_ATTENDANCE, curriculumLessonPostAttendance);
  yield takeEvery(CURRICULUM_LESSON_GET_ATTENDANCE, curriculumLessonGetAttendance);
  yield takeEvery(CURRICULUM_LESSON_POST_SLIDE, curriculumLessonPostSlide);
  yield takeEvery(
    CURRICULUM_LESSON_UPDATE_REVIEW_STATUS,
    curriculumLessonUpdateReviewStatus,
  );
  yield takeEvery(CURRICULUM_LESSON_GET_REVIEWS, curriculumLessonGetReviews);
}
