/* eslint-disable eqeqeq */
import React from 'react';
import { useCurrentCourse } from '@reducers/courses/hooks';
import { useIsAssigned } from '@reducers/lessonAssignedActivities/hooks';
import { useSectionStudent } from '@reducers/sectionStudent/hooks';
import { useExamScore } from '@reducers/sections/hooks';
import { useIsDistrictAdmin, useIsTeacher, useUser } from '@reducers/user/hooks';
import { useParams, useHistory } from 'react-router-dom';
import useLearnosityQuestionCT from '@utils/hooks/useLearnosityQuestionCT';
import {
  actions as activityActions,
  selectors as activitySelectors,
} from '@reducers/activity';
import { useDispatch, useSelector } from 'react-redux';
import { actions as groupActivityAction } from '@reducers/groupActivity';
import { actions as CurriculumLessonActions } from '@reducers/curriculumLessons';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import {
  LessonPracticeStatus,
  UnitMetaObject,
  UnitObjectType,
  ScoreObjectType,
} from '../Types';
import {
  useGetEducationalResources,
  useGetLearnosityAdaptivePracticeQuestions,
  useGetLessonDetail,
  useGetSectionUnitMeta,
  useGetStandards,
} from './query';
import {
  checkHasDifference,
  checkLessonCompletion,
  practiceStudentStatusHelper,
  practiceTeacherStatusHelper,
} from '../utils';

const unitMetaObjectPlaceholder = {
  lesson_practice: false,
  group_activity: false,
  unit_practice: false,
  lesson_slides: false,
} as UnitMetaObject;

const unitDetailsPlaceholder = {
  id: 0,
  name: '',
  meta: unitMetaObjectPlaceholder,
} as UnitObjectType;

export const useGetUnitDetails = () => {
  const { unitId, subjectId } = useParams();
  const { data: unitData } = useGetSectionUnitMeta();

  if (!unitData?.length) {
    return unitDetailsPlaceholder;
  }
  const unitsBySubjectId = unitData.find((el) => el.id == subjectId)?.units;

  const unitDetailsObject = unitsBySubjectId?.find((unit) => unit.id == unitId);

  return unitDetailsObject as UnitObjectType;
};

export const useShouldShowStandardPracticeTab = (): boolean => {
  const { data: lessonDetail } = useGetLessonDetail();
  const { hasStandardsPractice } = useLearnosityQuestionCT({ lessonDetail });

  const courseDetails = useCurrentCourse();
  const hasStandardPracticeEnabled = courseDetails?.standard_practice_enabled;

  return hasStandardsPractice && hasStandardPracticeEnabled;
};

export const useShouldShowAdaptivePracticeTab = (): boolean => {
  const { data: lessonDetails } = useGetLessonDetail();
  const unitDetails = useGetUnitDetails();

  const unitHasLessonPractice = unitDetails?.meta?.lesson_practice;
  const lessonCanPractice = lessonDetails?.can_practice;

  return lessonCanPractice && unitHasLessonPractice;
};

export const useShouldShowInterventionTab = (): boolean => {
  const shouldShowStandardPracticeTab = useShouldShowStandardPracticeTab();
  const isTeacher = useIsTeacher();
  const { data: lessonDetails } = useGetLessonDetail();
  const unitDetails = useGetUnitDetails();

  const lessonCanPractice = lessonDetails?.can_practice ?? false;
  const unitHasLessonPractice = unitDetails?.meta?.lesson_practice ?? false;
  const unitHasGroupActivity = unitDetails?.meta?.group_activity ?? false;

  return (
    isTeacher &&
    (shouldShowStandardPracticeTab ||
      (lessonCanPractice && unitHasLessonPractice) ||
      unitHasGroupActivity)
  );
};

export const useShouldShowLearnosityAdaptivePracticeTab = (): boolean => {
  const isAdaptiveLearnosityPracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();
  const isDistrictAdmin = useIsDistrictAdmin();
  const { data: adaptiveLearnosityQuestions } =
    useGetLearnosityAdaptivePracticeQuestions();
  return (
    isAdaptiveLearnosityPracticeEnabled &&
    (!!adaptiveLearnosityQuestions?.length || isDistrictAdmin)
  );
};

export const useShouldShowStudyHallTab = (): boolean => {
  const { data: oers } = useGetEducationalResources();

  return !!oers?.length;
};

const scorePlaceholderObject = { totalScore: 0, totalMaxScore: 0 } as ScoreObjectType;

export const usePracticeTotalScoreCalculator = (): ScoreObjectType => {
  const examScore = useExamScore();

  if (!examScore || !examScore.length) {
    return scorePlaceholderObject;
  }
  const [totalScore, totalMaxScore] = examScore.reduce(
    (acc, { score, max_score: maxScore }) => {
      acc[0] += score;
      acc[1] += maxScore;
      return acc;
    },
    [0, 0],
  );
  return { totalScore: Math.round(totalScore), totalMaxScore: Math.round(totalMaxScore) };
};

export const usePracticeStatus = (): LessonPracticeStatus | null => {
  const isAssigned = useIsAssigned();
  const examScore = useExamScore();
  const isTeacher = useIsTeacher();
  const studentList = useSectionStudent();

  if (isTeacher) {
    return practiceTeacherStatusHelper(examScore, studentList);
  }

  return practiceStudentStatusHelper(examScore, isAssigned);
};

export const useGetActivities = () => {
  const studentList = useSectionStudent();
  const { sectionId, lessonId: lessonGroupId, unitId, courseId } = useParams();
  const dispatch = useDispatch();
  const courseData = useCurrentCourse();
  const getActivities = groupActivityAction.getGroupActivities;
  const isTeacher: boolean = useIsTeacher();
  const user = useUser();
  const { data } = useGetLessonDetail();
  const lessonId = data?.lessons[0]?.id;

  React.useEffect(() => {
    // TODO: will remove studentList condition after changing the way we create OLP activity.
    if (!lessonId || !studentList.length) {
      return;
    }
    dispatch(
      getActivities(
        sectionId,
        lessonGroupId,
        lessonId,
        user?.id,
        unitId,
        courseData?.curriculum,
        isTeacher,
        lessonGroupId,
      ),
    );
  }, [sectionId, lessonId, unitId, courseId, studentList.length, lessonGroupId]);
};

export const useGetUnitMetaObject = () => {
  const { unitId, subjectId } = useParams();
  const { data: unitData } = useGetSectionUnitMeta();
  if (!unitData?.length) {
    return unitMetaObjectPlaceholder;
  }

  const unitsBySubjectId = unitData.find((el) => el.id == subjectId)?.units;

  const unitMetaObject = unitsBySubjectId?.find((unit) => unit.id == unitId)?.meta;

  return unitMetaObject as UnitMetaObject;
};

export const useInsertLessonActivitySummary = () => {
  const { lessonId, unitId, subjectId } = useParams();
  const insertLessonActivitySummary = activityActions?.insertLessonActivitySummary;
  const dispatch = useDispatch();

  return React.useCallback(
    (status) =>
      dispatch(
        insertLessonActivitySummary({
          unit: unitId,
          id: lessonId,
          subject: subjectId,
          completed: status,
        }),
      ),
    [dispatch, insertLessonActivitySummary, lessonId, subjectId, unitId],
  );
};

export const useLessonActivitySummaryList = () => {
  const summaryData = useSelector((state) =>
    activitySelectors.getLessonAcitivitySummaryList(state),
  );
  return summaryData;
};

export const useLessonActivityById = () => {
  const lessonActivityById = useSelector((state: any) =>
    activitySelectors.lessonActivityById(state),
  );
  return lessonActivityById;
};

export const useHandleFinishStepOne = () => {
  const isTeacher = useIsTeacher();
  const { lessonId, subjectId, unitId } = useParams();
  const lessonActivitySummaryList = useLessonActivitySummaryList();
  // TODO: check if we can replace this to get query
  const lessonActivityById = useLessonActivityById();
  const { data: lessonDetail } = useGetLessonDetail();
  const hasLessonCompleted = React.useMemo(
    () => checkLessonCompletion(lessonActivitySummaryList, lessonId, subjectId, unitId),
    [lessonId, subjectId, unitId, lessonActivitySummaryList],
  );
  const insertLessonActivitySummary = useInsertLessonActivitySummary();
  return React.useCallback(
    (subLessonId: number | string, status: boolean) => {
      const hasDifference = checkHasDifference(
        lessonActivityById,
        lessonDetail,
        subLessonId,
      );
      if (hasLessonCompleted || isTeacher || hasDifference) {
        insertLessonActivitySummary(status);
      }
    },
    [isTeacher, lessonActivityById, lessonDetail, hasLessonCompleted],
  );
};

export const useReduxAttendanceDataUpdater = () => {
  const { sectionId, lessonId: lessonGroupId } = useParams();
  const dispatch = useDispatch();
  const getAttendance = CurriculumLessonActions.curriculumLessonGetAttendance;

  React.useEffect(() => {
    dispatch(getAttendance(lessonGroupId, sectionId));
  }, [sectionId, lessonGroupId]);
};

export const useLessonStandards = () => {
  const { data: lessonDetail } = useGetLessonDetail();
  return React.useMemo(
    () => lessonDetail?.standards?.map((standard) => standard.code) || [],
    [lessonDetail?.standards],
  );
};

export const useStandardName = () => {
  const courseData = useCurrentCourse();
  const standardId = courseData?.syllabus ?? 0;
  const { data: standards } = useGetStandards();
  return React.useMemo(
    () => standards?.find((el) => el.id == standardId)?.name,
    [standardId, standards],
  );
};

export const useLessonSkills = () => {
  const { data: lessonDetail } = useGetLessonDetail();
  return React.useMemo(() => lessonDetail?.skills || [], [lessonDetail?.skills]);
};

type NavigateToLessonProps = {
  tabKey?: string;
  pacingGuideId?: string;
};

export const useNavigateToLesson = () => {
  const searchParams = useSearchParams();
  const history = useHistory();

  return ({ tabKey, pacingGuideId }: NavigateToLessonProps = {}) => {
    const finalTabKey = tabKey ?? searchParams.tab;
    const finalPacingGuideId = pacingGuideId ?? searchParams['pacing-guide-id'];
    history.push({
      pathname: history.location.pathname,
      search: `?tab=${finalTabKey}${
        finalPacingGuideId ? `&pacing-guide-id=${finalPacingGuideId}` : ''
      }`,
    });
  };
};
