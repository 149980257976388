import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { PracticeActions } from '../Provider/practiceReducer';
import PracticeEventEmitter, { PracticeEvents } from '../utils/practiceEventEmitter';

const useWebSocketListener = (dispatch) => {
  const client = useQueryClient();
  useEffect(() => {
    const groupQuestionAnswerSubscription = (data: any) => {
      if (!data?.group_answers?.length) {
        return;
      }
      dispatch({ type: PracticeActions.SET_GROUP_ANSWER, payload: data.group_answers });
    };
    const studentFinishedIndividual = (data: any) => {
      if (!data?.students_finished_individual?.length) {
        return;
      }
      dispatch({
        type: PracticeActions.SET_STUDENTS_FINISHED_INDIVIDUAL,
        payload: data.students_finished_individual,
      });
    };
    const questionsGroupLocked = (data: object) => {
      if (!Object.keys(data).length) {
        return;
      }
      dispatch({
        type: PracticeActions.SET_LOCKED_GROUP_QUESTION,
        payload: data,
      });
    };
    const updateGroupData = () => {
      client.invalidateQueries(['getGroupData']);
    };
    PracticeEventEmitter.on(
      PracticeEvents.GROUP_QUESTIONS_ANSWER,
      groupQuestionAnswerSubscription,
    );
    PracticeEventEmitter.on(
      PracticeEvents.STUDENTS_FINISHED_INDIVIDUAL,
      studentFinishedIndividual,
    );
    PracticeEventEmitter.on(PracticeEvents.GROUP_QUESTION_LOCKED, questionsGroupLocked);
    PracticeEventEmitter.on(PracticeEvents.GROUP_MEMBERS_CHANGED, updateGroupData);

    return () => {
      PracticeEventEmitter.off(
        PracticeEvents.GROUP_QUESTIONS_ANSWER,
        groupQuestionAnswerSubscription,
      );
      PracticeEventEmitter.off(
        PracticeEvents.STUDENTS_FINISHED_INDIVIDUAL,
        studentFinishedIndividual,
      );
      PracticeEventEmitter.off(
        PracticeEvents.GROUP_QUESTION_LOCKED,
        questionsGroupLocked,
      );
      PracticeEventEmitter.off(PracticeEvents.GROUP_MEMBERS_CHANGED, updateGroupData);
    };
  }, [dispatch]);
};

export default useWebSocketListener;
