import React from 'react';
import { useCurrentCourse } from '@reducers/courses/hooks';
import ShowIf from '@components/Atoms/ShowIf';
import Box from '@components/Atoms/Box';
import {
  usePracticeTotalScoreCalculator,
  usePracticeStatus,
  useShouldShowAdaptivePracticeTab,
  useShouldShowStandardPracticeTab,
} from '../hooks';
import { LessonPracticeStatus } from '../Types';

const AdaptivePracticeTabContent = () => {
  const shouldShowAdaptivePracticeTab = useShouldShowAdaptivePracticeTab();
  const shouldShowStandardPracticeTab = useShouldShowStandardPracticeTab();
  const courseDetails = useCurrentCourse();
  const practiceStatus = usePracticeStatus();
  const { totalScore, totalMaxScore } = usePracticeTotalScoreCalculator();

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.3rem',
      }}
      data-testid="adaptive-practice"
    >
      <p data-testid="adaptive-title">👤 Adaptive Practice</p>
      <ShowIf If={!shouldShowStandardPracticeTab}>
        <Box sx={{ display: 'flex' }} data-testid="adaptive-practice-score">
          {`${totalScore}/${totalMaxScore}`}
          <ShowIf If={practiceStatus === LessonPracticeStatus.CompletedLessonPractice}>
            <i data-testid="check-success" className="chalktalk-check-success" />
          </ShowIf>
          <ShowIf If={practiceStatus === LessonPracticeStatus.Assigned}>
            <i data-testid="check-assigned" className="chalktalk-error" />
          </ShowIf>
        </Box>
      </ShowIf>
    </Box>
  );
};

export default AdaptivePracticeTabContent;
