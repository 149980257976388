import styled, { css } from 'styled-components';

export const LessonWithAttendance = styled.div`
  color: #4886ff;
  margin: 10px 0px;
  font-family: 'MontserratMedium';
  img {
      height: 2em;
      width: 2em;
      vertical-align: middle;
  }
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
`;

export const CheckSpace = styled.div`
  display: inline-block;
  width: 2em;
  margin-right: 5px;
`;

export const AccordionSummaryStyles = {
    minHeight: 0,
    '&.Mui-expanded': {
        minHeight: 0,
    },
    root: {
        padding: '0!important',
        color: '#4886ff',
        marginBottom: '10px',
        minHeight: 0,
        '&.Mui-expanded': {
            minHeight: 0,
        },
    },
    content: {
        fontFamily: 'MontserratSemiBold',
        color: '#4886ff',
        fontSize: '1.4em',
        marginTop: '6px',
        marginBottom: '0px',
        '&.Mui-expanded': {
            marginTop: '6px',
            marginBottom: '0px',
        },
    },
    expandIconWrapper: {
        color: '#4886ff',
    },
}

export const AccordionStyles = {
    root: {
        boxShadow: 'none!important',
        '&::before': {
            backgroundColor: "white!important"
        },
        '&.Mui-expanded': {
            margin: '0!important',
        },
    },
}
