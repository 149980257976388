import Dialog from '@components/Atoms/Dialog';
import Skeleton from '@components/Atoms/Skeleton';
import QuestionGenerationService from '@components/QuestionGenerationService';
import React, { Suspense } from 'react';

const GenerateQuestionsWithCoAuthorDialog = ({
  open,
  setOpen,
  setKeyId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setKeyId: (keyId: string) => void;
}) => (
  <Dialog
    title="Generate Questions with Co-Author v1"
    maxWidth="xl"
    fullWidth
    open={open}
    onClose={() => {
      setOpen(false);
    }}
    actions={[
      {
        label: 'Close',
        onClick: () => {
          setOpen(false);
        },
        buttonProps: {
          variant: 'contained',
        },
      },
    ]}
  >
    <Suspense
      fallback={
        <Skeleton
          sx={{
            height: '25rem',
            width: '100%',
          }}
        />
      }
    >
      <QuestionGenerationService
        inDialog
        onGenerateQuestions={() => setKeyId(`${new Date().getTime()}`)}
      />
    </Suspense>
  </Dialog>
);

export default GenerateQuestionsWithCoAuthorDialog;
