import { SECTIONS_GET_ALL_BY_COURSE_SUCCESS } from '../sections';

export const SIDE_BAR_TOGGLE = 'leftSideBar/SIDE_BAR_TOGGLE';
export const SIDE_BAR_TOGGLE_SUB_NAV_BAR = 'leftSideBar/SIDE_BAR_TOGGLE_SUB_NAV_BAR';
export const SIDE_BAR_TOGGLE_SESSION_SIDE_BAR = 'leftSideBar/SIDE_BAR_TOGGLE_SESSION_SIDE_BAR';
export const SIDE_BAR_TOGGLE_PRACTICE_NAVIGATION = 'leftSideBar/SIDE_BAR_TOGGLE_PRACTICE_NAVIGATION';

export const SIDE_BAR_TOGGLE_LESSON_SESSION_SUB_NAVBAR = 'leftSideBar/SIDE_BAR_TOGGLE_LESSON_SESSION_SUB_NAVBAR';
export const SIDE_BAR_TOGGLE_REVIEW_SESSION_SUB_NAVBAR = 'leftSideBar/SIDE_BAR_TOGGLE_REVIEW_SESSION_SUB_NAVBAR';
export const SIDE_BAR_TOGGLE_SUBJECT_LESSON_SUB_NAVBAR = 'leftSideBar/SIDE_BAR_TOGGLE_SUBJECT_LESSON_SUB_NAVBAR';
export const BACK_SUBJECT_LESSON_SUB_NAVBAR = 'leftSideBar/BACK_SUBJECT_LESSON_SUB_NAVBAR';
export const SIDE_BAR_TOGGLE_ASSIGN_LIST_SUB_NAVBAR = 'leftSideBar/SIDE_BAR_TOGGLE_ASSIGN_LIST_SUB_NAVBAR';

export const toggleLeftSidebar = () => ({
  type: SIDE_BAR_TOGGLE,
});

export const toggleSubNavBar = subjectId => ({
  type: SIDE_BAR_TOGGLE_SUB_NAV_BAR,
  subjectId,
});

export const toggleSessonSideBar = () => ({
  type: SIDE_BAR_TOGGLE_SESSION_SIDE_BAR,
});

export const togglePracticeNavigation = () => ({
  type: SIDE_BAR_TOGGLE_PRACTICE_NAVIGATION,
});

export const toggleLessonSessionNavbar = () => ({
  type: SIDE_BAR_TOGGLE_LESSON_SESSION_SUB_NAVBAR,
});

export const toggleReviewSessionNavbar = () => ({
  type: SIDE_BAR_TOGGLE_REVIEW_SESSION_SUB_NAVBAR,
});

export const toggleAssignList = () => ({
  type: SIDE_BAR_TOGGLE_ASSIGN_LIST_SUB_NAVBAR,
});

export const toggleSubjectLessonNavbar = () => ({
  type: SIDE_BAR_TOGGLE_SUBJECT_LESSON_SUB_NAVBAR,
});

export const backSubjectLessonNavbar = () => ({
  type: BACK_SUBJECT_LESSON_SUB_NAVBAR,
});

export const actions = {
  toggleLeftSidebar,
  toggleSubNavBar,
  toggleSessonSideBar,
  togglePracticeNavigation,

  toggleLessonSessionNavbar,
  toggleReviewSessionNavbar,
  toggleSubjectLessonNavbar,
  backSubjectLessonNavbar,
  toggleAssignList,
};

// selectors
export const getSideBar = ({ leftSideBar }) => leftSideBar.isSideBarClose;
export const getSessionSideBar = ({ leftSideBar }) => leftSideBar.isSessionSideBarClose;
export const getPracticeNavigation = ({ leftSideBar }) => leftSideBar.isPracticeNavigation;

const isSubNavbarLessonSessionClose = ({ leftSideBar }) => leftSideBar.isSubNavbarLessonSessionClose || false;
const isSubNavbarReviewSessionClose = ({ leftSideBar }) => leftSideBar.isSubNavbarReviewSessionClose || false;
const isSubNavbarSubjectLessonClose = ({ leftSideBar }) => leftSideBar.isSubNavbarSubjectLessonClose || false;
const isSubNavbarAssignListClose = ({ leftSideBar }) => leftSideBar.isSubNavbarAssignListClose || false;
const getSubjectId = ({ leftSideBar }) => leftSideBar.subjectId;

export const selectors = {
  getSideBar,
  getSessonSideBar: getSessionSideBar,
  getSubjectId,
  getPracticeNavigation,

  isSubNavbarLessonSessionClose,
  isSubNavbarReviewSessionClose,
  isSubNavbarSubjectLessonClose,
  isSubNavbarAssignListClose,
};

const initialState = {
  isSideBarClose: false,

  isSubNavbarLessonSessionClose: true,
  isSubNavbarReviewSessionClose: true,
  isSubNavbarSubjectLessonClose: true,
  isSubNavbarAssignListClose: true,

  isSessionSideBarClose: false,
  isPracticeNavigation: false,

  subjectId: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SIDE_BAR_TOGGLE:
      return {
        ...state,
        isSideBarClose: !state.isSideBarClose,
      };

    case SIDE_BAR_TOGGLE_SUB_NAV_BAR:
      return {
        ...state,
        subjectId: action.subjectId,
      };

    case SIDE_BAR_TOGGLE_SESSION_SIDE_BAR:
      return {
        ...state,
        isSessionSideBarClose: !state.isSessionSideBarClose,
      };

    case SIDE_BAR_TOGGLE_PRACTICE_NAVIGATION:
      return {
        ...state,
        isPracticeNavigation: !state.isPracticeNavigation,
      };

    case SIDE_BAR_TOGGLE_LESSON_SESSION_SUB_NAVBAR: {
      return {
        ...state,
        isSubNavbarLessonSessionClose: !state.isSubNavbarLessonSessionClose,
      };
    }

    case SIDE_BAR_TOGGLE_REVIEW_SESSION_SUB_NAVBAR: {
      return {
        ...state,
        isSubNavbarReviewSessionClose: !state.isSubNavbarReviewSessionClose,
      };
    }

    case SIDE_BAR_TOGGLE_SUBJECT_LESSON_SUB_NAVBAR: {
      return {
        ...state,
        isSubNavbarSubjectLessonClose: !state.isSubNavbarSubjectLessonClose,
      };
    }

    case BACK_SUBJECT_LESSON_SUB_NAVBAR: {
      return {
        ...state,
        isSubNavbarSubjectLessonClose: !state.isSubNavbarSubjectLessonClose,
      };
    }

    case SIDE_BAR_TOGGLE_ASSIGN_LIST_SUB_NAVBAR: {
      return {
        ...state,
        isSubNavbarAssignListClose: !state.isSubNavbarAssignListClose,
      };
    }

    case SECTIONS_GET_ALL_BY_COURSE_SUCCESS:
      return {
        ...state,
        isSubNavbarSubjectLessonClose: true, //
        isSubNavbarReviewSessionClose: true, //
        isSubNavbarLessonSessionClose: true, //
        isSubNavbarAssignListClose: true,
      };

    default:
      return state;
  }
}
