import moment from 'moment';

export const calculatePracticeDuration = ({
  questionSelected: { id }, startTime, index, totalQuestions, answer,
}) => {
  const duration = moment.duration(moment().diff(startTime));
  const responseTime = duration.seconds();
  const waitForGroupFinish = index === totalQuestions - 1;
  const calculatedAnswer = {
    value: answer,
    exam_question_id: id,
    count: index + 1,
    response_time: responseTime,
    delete: false,
    waitForGroupFinish,
  };
  return calculatedAnswer;
};
