import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { getSearchParamsObject } from '@utils/commonUtils';

export function useSearchParams() {
  const { search } = useLocation();

  const searchParams = useMemo(() => {
    return getSearchParamsObject(search);
  }, [search]);

  return searchParams;
}
