/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors } from './lessonAssignedActivitiesReducer';

export const useIsAssigned = () => {
  const { lessonId } = useParams();
  if (!lessonId) {
    console.error('lessonId is undefined in useIsAssigned hook');
  }
  const isAssigned = useSelector((state: any) =>
    selectors.getAssignStatusByLessonId(state, lessonId),
  );
  return isAssigned;
};
