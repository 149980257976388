import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _union from 'lodash/union';
import _filter from 'lodash/filter';
import _omit from 'lodash/omit';

export const SECTIONS_TEACHER_REMOVE = 'sectionsTeachers/REMOVE';
export const SECTIONS_TEACHER_REMOVE_SUCCESS = 'sectionsTeachers/REMOVE_SUCCESS';
export const SECTIONS_TEACHER_REMOVE_FAIL = 'sectionsTeachers/REMOVE_FAIL';

export const SECTIONS_TEACHER_GET = 'sectionsTeachers/GET';
export const SECTIONS_TEACHER_GET_SUCCESS = 'sectionsTeachers/GET_SUCCESS';
export const SECTIONS_TEACHER_GET_FAIL = 'sectionsTeachers/GET_FAIL';

export const SECTIONS_TEACHER_INVITE = 'sectionsTeachers/INVITE';
export const SECTIONS_TEACHER_INVITE_SUCCESS = 'sectionsTeachers/INVITE_SUCCESS';
export const SECTIONS_TEACHER_INVITE_FAIL = 'sectionsTeachers/INVITE_FAIL';

/**
 *
 * @param {String} sectionId : Section Id
 * @param {String} teacherId : Array of Teacher Id to remove
 */
const sectionsTeachersRemove = (sectionId, teacherId) => ({
  type: SECTIONS_TEACHER_REMOVE,
  sectionId,
  teacherId,
});

/**
 * @param {String} teacherId
 * @param {String} sectionId
 */
const sectionsTeachersRemoveSuccess = (sectionId, teacherId) => ({
  type: SECTIONS_TEACHER_REMOVE_SUCCESS,
  teacherId,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsTeachersRemoveFail = payload => ({
  type: SECTIONS_TEACHER_REMOVE_FAIL,
  payload,
});

const sectionsTeachersAdd = (teacherId, sectionId, teacherInfo) => ({
  type: SECTIONS_TEACHER_INVITE,
  teacherId,
  sectionId,
  teacherInfo,
});

/**
 * @param {String} teacherId
 * @param {String} sectionId
 */
const sectionsTeachersAddSuccess = (teacherId, sectionId, teacherInfo) => ({
  type: SECTIONS_TEACHER_INVITE_SUCCESS,
  teacherId,
  sectionId,
  teacherInfo,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsTeachersAddFail = payload => ({
  type: SECTIONS_TEACHER_INVITE_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 */

const sectionsTeachersGet = sectionId => ({
  type: SECTIONS_TEACHER_GET,
  sectionId,
});

/**
 * @param {Array} teacherList
 * @param {String} sectionId
 */
const sectionsTeachersGetSuccess = (teacherList, sectionId) => ({
  type: SECTIONS_TEACHER_GET_SUCCESS,
  teacherList,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionsTeachersGetFail = payload => ({
  type: SECTIONS_TEACHER_GET_FAIL,
  payload,
});

// selectors
const getTeacherIds = ({ sectionTeacher }, sectionId) => _get(sectionTeacher, `teachers.${sectionId}.Ids`, []);
const getTeacherByIds = ({ sectionTeacher }, sectionId) => _get(sectionTeacher, `teachers.${sectionId}.byIds`, {});

const getTeacherList = (state, sectionId) => {
  const Ids = getTeacherIds(state, sectionId);
  const byIds = getTeacherByIds(state, sectionId);

  return Ids.map(id => byIds[id]);
};

const isGettingTeacherList = ({ sectionTeacher }) => sectionTeacher.isFetchTeacherList;

export const selectors = {
  getTeacherList,
  getTeacherIds,
  getTeacherByIds,

  isGettingTeacherList,
};

export const actions = {
  // remove teachers
  sectionsTeachersRemove,
  sectionsTeachersRemoveSuccess,
  sectionsTeachersRemoveFail,
  // invite teachers
  sectionsTeachersAdd,
  sectionsTeachersAddSuccess,
  sectionsTeachersAddFail,
  // get teachers
  sectionsTeachersGet,
  sectionsTeachersGetSuccess,
  sectionsTeachersGetFail,
};

const initialState = {
  isFetchTeacherList: false, // is geting teacher
  isRemoving: false, // is remove teacher
  isInvite: false, // is invite teacher
  teachers: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // get teacher
    case SECTIONS_TEACHER_GET:
      return {
        ...state,
        isFetchTeacherList: true,
      };

    case SECTIONS_TEACHER_GET_SUCCESS: {
      const { sectionId, teacherList } = action;
      const Ids = teacherList.map(({ id }) => id);
      const byIds = _keyBy(teacherList, 'id');

      return {
        ...state,
        isFetchTeacherList: false,
        teachers: {
          ...state.teachers,
          [sectionId]: {
            Ids,
            byIds,
          },
        },
      };
    }

    case SECTIONS_TEACHER_GET_FAIL:
      return {
        ...state,
        isFetchTeacherList: false,
      };

    // remove teacher
    case SECTIONS_TEACHER_REMOVE:
      return {
        ...state,
        isRemoving: true,
      };

    case SECTIONS_TEACHER_REMOVE_SUCCESS: {
      const { sectionId, teacherId } = action;
      const currentIds = _get(state, `teachers.${sectionId}.Ids`, []);
      const currentByIds = _get(state, `teachers.${sectionId}.byIds`, {});

      const newIds = _filter(currentIds, id => id !== teacherId);
      const newByIds = _omit(currentByIds, teacherId);

      return {
        ...state,
        teachers: {
          ...state.teachers,
          [sectionId]: {
            Ids: newIds,
            byIds: newByIds,
          },
        },
      };
    }

    case SECTIONS_TEACHER_REMOVE_FAIL:
      return {
        ...state,
        isRemoving: false,
      };

    // invite teacher
    case SECTIONS_TEACHER_INVITE:
      return {
        ...state,
        isInvite: true,
      };

    case SECTIONS_TEACHER_INVITE_SUCCESS: {
      const { teacherId, sectionId, teacherInfo } = action;
      const currentIds = _get(state, `teachers.${sectionId}.Ids`, []);
      const currentByIds = _get(state, `teachers.${sectionId}.byIds`, {});
      const currentTeacher = teacherInfo;

      return {
        ...state,
        isInvite: false,
        teachers: {
          ...state.teachers,
          [sectionId]: {
            Ids: [...currentIds, teacherId],
            byIds: {
              ...currentByIds,
              [teacherId]: currentTeacher,
            },
          },
        },
      };
    }

    case SECTIONS_TEACHER_INVITE_FAIL:
      return {
        ...state,
        isInvite: false,
      };

    default:
      return state;
  }
}
