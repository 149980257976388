import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import PracticeQuestionItem from '../../containers/OnlinePracticeContent/PracticeQuestionItem';
import {
  PracticeContentWrap,
  PracticeContentGroup,
  PBMobilePopup,
  PBMobileCol,
  PracticeFooterBarMobile,
  PBMobileSelectedItem,
  ProgressBarItem,
  ProgressBarCircle,
  ProgressBarLine,
  PracticeFooterBar,
  ProgressBarWrapper,
  PBMobileItem,
  PBMobileItemContent,
  MobileOnly,
  DesktopOnly,
  ContentWrap,
  SubContentWrap,
} from '../PracticePage/PracticePage.style';
import { NavigationHeaderWrap } from '../QuestionAssets/QuestionAssets.style';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import AnswerExplanation from '../../containers/AnswerExplanation/AnswerExplanation';
import AnswerExplanationLeftSideBar from '../AnswerExplanationLeftSideBar';
import PracticeReviewItem from '../PracticeReviewItem';
import QuestionAssets from '../../containers/QuestionAssets';
class OnlinePracticeContentItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isQuestionModalOpen: false,
      isOpenSideBar: false,
    };
  }

  toggleQuestionModal = () => {
    this.setState(({ isQuestionModalOpen }) => ({
      isQuestionModalOpen: !isQuestionModalOpen,
    }));
  };

  toggleExplanationSideBar = () => {
    this.setState(({ isOpenSideBar }) => ({
      isOpenSideBar: !isOpenSideBar,
    }));
  };

  closeQuestionModal = () => {
    this.setState({
      isQuestionModalOpen: false,
    });
  };

  selectQuestion = (index, hasAnswer) => () => {
    const { selectQuestion } = this.props;
    this.closeQuestionModal();
    selectQuestion(index, hasAnswer)();
  };

  renderQuestionAssets = () => {
    const {
      questions,
      questionSelected,
      displayGrammarHelper,
      openReportErrorModal,
    } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(questionSelectedObj, 'question.shared_assets', []);
    const questionId = _get(questionSelectedObj, 'id');

    if (!assets || !assets.length) return <div> </div>;

    return (
      <QuestionAssets
        hasFooter
        assets={assets}
        displayGrammarHelper={displayGrammarHelper}
        questionSelected={questionSelected}
        openReportErrorModal={openReportErrorModal}
        questionId={questionId}
      />
    );
  };

  renderProgressBar = (currentQuestion, index) => {
    const {
      submittedAnswerList, questionSelected, selectQuestion, questions,
    } = this.props;
    const questionId = currentQuestion
      ? currentQuestion.id
      : questions[questionSelected].id;
    const hasAnswer = !_isEmpty(submittedAnswerList[questionId]);
    const numberQuestion = currentQuestion ? questions.length : null;
    const isSelected = currentQuestion ? questionSelected === index : true;

    return (
      <ProgressBarItem
        isActive={hasAnswer}
        isSelected={isSelected}
        widthValue={numberQuestion}
        firstItem={!index}
        key={index}
      >
        {currentQuestion ? (
          <React.Fragment>
            <ProgressBarLine />
            <ProgressBarCircle
              onClick={selectQuestion(index, hasAnswer)}
              disabled={!hasAnswer}
            />
          </React.Fragment>
        ) : (
          <PBMobileSelectedItem onClick={this.toggleQuestionModal}>
            <ProgressBarCircle />
            {`Question ${questionSelected + 1} of ${questions.length}`}
          </PBMobileSelectedItem>
        )}
      </ProgressBarItem>
    );
  };

  renderReviewAnswer = () => {
    const {
      questions,
      practiceSessionId,
      questionSelected,
      submittedAnswerList,
      completePractice,
      nextQuestion,
      prevQuestion,
      shouldDisplayGrammarHelper,
      openReportErrorModal,
      startTime,
    } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const submittedAnswer = submittedAnswerList[questionSelectedObj.id];
    const totalQuestions = questions.length;
    const questionOrder = questionSelectedObj.order || null;
    const explanation = _get(
      submittedAnswer,
      'exam_question.question.explanation',
      {},
    );
    const questionId = _get(questionSelectedObj, 'id');

    if (!submittedAnswer) {
      return (
        <PracticeQuestionItem
          questionSelected={questionSelectedObj}
          totalQuestions={totalQuestions}
          prevQuestion={prevQuestion}
          index={questionSelected}
          order={questionOrder}
          startTime={startTime}
          practiceSessionId={practiceSessionId}
          toggleExplanationSideBar={this.toggleExplanationSideBar}
          shouldDisplayGrammarHelper={shouldDisplayGrammarHelper}
          openReportErrorModal={openReportErrorModal}
          questionId={questionId}
        />
      );
    }

    return (
      <PracticeReviewItemContainer>
        <PracticeReviewItem
          questionSelected={submittedAnswer}
          totalQuestions={totalQuestions}
          nextQuestion={nextQuestion}
          toggleExplanationSideBar={this.toggleExplanationSideBar}
          prevQuestion={prevQuestion}
          index={questionSelected}
          order={questionOrder}
          practiceSessionId={practiceSessionId}
          completePractice={completePractice}
          shouldDisplayGrammarHelper={shouldDisplayGrammarHelper}
          openReportErrorModal={openReportErrorModal}
          questionId={questionId}
        />
        <DesktopOnly>
          <AnswerExplanation
            explanation={explanation}
            openReportErrorModal={openReportErrorModal}
            questionId={questionId}
          />
        </DesktopOnly>
      </PracticeReviewItemContainer>
    );
  };

  renderQuestionModalItem = (colNum) => (question, index) => {
    const { submittedAnswerList, questionSelected } = this.props;
    const hasAnswer = !_isEmpty(submittedAnswerList[question.id]);
    const currentIndex = 6 * colNum + index;

    return (
      <PBMobileItem
        key={question.id}
        isActive={hasAnswer}
        onClick={this.selectQuestion(index, hasAnswer)}
      >
        <ProgressBarCircle
          isSelected={questionSelected === currentIndex}
          disabled={!hasAnswer}
        />
        <PBMobileItemContent>{`Question ${currentIndex + 1}`}</PBMobileItemContent>
      </PBMobileItem>
    );
  };

  renderQuestionModal = () => {
    const { questions } = this.props;
    const { isQuestionModalOpen } = this.state;
    const firstList = questions.slice(0, 6);
    const secondList = questions.slice(6, 12);
    const hasSecondList = secondList.length > 0;

    return (
      <div>
        <PBMobilePopup hasSecondList={hasSecondList}>
          <PBMobileCol>
            {firstList.map(this.renderQuestionModalItem(0))}
          </PBMobileCol>
          {secondList && secondList.length > 0 && (
            <PBMobileCol>
              {secondList.map(this.renderQuestionModalItem(1))}
            </PBMobileCol>
          )}
        </PBMobilePopup>
        <OverlayWrap
          isOpen={isQuestionModalOpen}
          onClick={this.toggleQuestionModal}
        />
      </div>
    );
  };

  render = () => {
    const {
      questions,
      isPracticeNavigation,
      submittedAnswerList,
      questionSelected,
      openReportErrorModal,
      lightBoxImgSrc,
    } = this.props;
    const totalQuestions = questions.length;

    if (totalQuestions === 0) {
      return null;
    }

    const { isOpenSideBar, isQuestionModalOpen } = this.state;

    const questionSelectedObj = questions[questionSelected];
    const submittedAnswer = submittedAnswerList[questionSelectedObj.id];
    const assets = _get(questionSelectedObj, 'question.shared_assets', []);
    const isToggleNavigation = isPracticeNavigation || !assets || !assets.length;
    const questionId = _get(questionSelectedObj, 'id');
    const explanation = _get(
      submittedAnswer,
      'exam_question.question.explanation',
      {},
    );

    return (
      <ContentWrap>
        <SubContentWrap>
          <AnswerExplanationLeftSideBar
            isSideBarClose={isOpenSideBar}
            toggleLeftSidebar={this.toggleExplanationSideBar}
          >
            <AnswerExplanation
              explanation={explanation}
              openReportErrorModal={openReportErrorModal}
              questionId={questionId}
            />
          </AnswerExplanationLeftSideBar>

          <PracticeContentWrap>
            <DesktopOnly>{this.renderQuestionAssets()}</DesktopOnly>
            <MobileOnly>
              {assets.length > 0 && (
                <Tabs defaultIndex={1}>
                  <NavigationHeaderWrap>
                    <TabList>
                      <Tab>Assets</Tab>
                      <Tab>Show Questions</Tab>
                    </TabList>
                  </NavigationHeaderWrap>
                  <TabPanel>{this.renderQuestionAssets()}</TabPanel>
                </Tabs>
              )}
            </MobileOnly>

            <PracticeContentGroup
              block={!submittedAnswer}
              isToggleNavigation={isToggleNavigation}
              assets={assets.length > 0}
            >
              {this.renderReviewAnswer()}
            </PracticeContentGroup>
          </PracticeContentWrap>
          {isQuestionModalOpen && this.renderQuestionModal()}
          <PracticeFooterBar>
            <ProgressBarWrapper>
              {questions.map(this.renderProgressBar)}
            </ProgressBarWrapper>
          </PracticeFooterBar>
          <PracticeFooterBarMobile>
            <ProgressBarWrapper>{this.renderProgressBar()}</ProgressBarWrapper>
          </PracticeFooterBarMobile>
        </SubContentWrap>
        {this.props.isLightBoxOpen && (
          <Lightbox
            mainSrc={lightBoxImgSrc}
            onCloseRequest={this.props.closeLightbox}
          />
        )}
      </ContentWrap>
    );
  };
}

const PracticeReviewItemContainer = styled.div`
  max-width: unset;
  display: flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  height: 100%;
  @media (max-width: 1280px) {
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 90%;
    height: 100%
  }
`;

OnlinePracticeContentItem.propTypes = {
  questions: PropTypes.array,
  practiceSessionId: PropTypes.any,
  isPracticeNavigation: PropTypes.bool,
  submittedAnswerList: PropTypes.shape(),
  completePractice: PropTypes.func.isRequired,
  questionSelected: PropTypes.number,
  startTime: PropTypes.any,
  prevQuestion: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  shouldDisplayGrammarHelper: PropTypes.bool,
  displayGrammarHelper: PropTypes.func.isRequired,
  openReportErrorModal: PropTypes.func,
  lightBoxImgSrc: PropTypes.string,
};

export default OnlinePracticeContentItem;
