import React from 'react';
import { Button } from '../Generals/stylesheets/Button.style';
import { useNavigateToPractice } from './hooks';
import useLearnosityQuestionCT from '../../utils/hooks/useLearnosityQuestionCT';
import { useIsTeacher } from '../../reducers/user/hooks';

const StandardPracticeSpanishStartButton = ({ lessonDetail }) => {
  const isTeacher = useIsTeacher();
  const goToPractice = useNavigateToPractice('es');
  const { hasSpanishQuestions } = useLearnosityQuestionCT({
    lessonDetail,
  });
  if (!hasSpanishQuestions || isTeacher) {
    return null;
  }
  return (
    <Button color="ColorPrimaryGreen" onClick={goToPractice} outlined>
      Práctica en español (preguntas de apoyo)
    </Button>
  );
};

export default StandardPracticeSpanishStartButton;
