import React from 'react';
import MediaQuery from 'react-responsive';
import {
  DashboardLine,
  DashboardPracticeActivityWrap,
  DashboardStatisticGroup,
  DashboardStatisticWrap, DashboardSummaryUpcomingWrap,
  DashboardSummaryWrap,
  SummaryBlockContent,
  SummaryBlockItem,
  SummaryBlockWrap,
  SummaryImage,
  SummaryNumber, SummaryRank, SummaryScore, SummaryScoreGroup, SummarySubText,
  SummaryText,
} from './DashboardSummary.style';
import { BlockTitle, BlockWrap, UpcomingEmpty } from '../Generals/stylesheets/General.style';
import { ROLE_TYPE } from '../../utils/enums';
import DashboardSummaryTable from '../../containers/DashboardSummary/DashboardSummaryTable';
import { NavBarGroup, NavBarGroupTitle, NavBarList } from '../SubNavBarItem/SubNavBar.style';
import ListLoader from '../LoadingPlaceholder/ListLoader';
import SessionSubNavItem from '../../containers/SubNavBarItem/SessionSubNavItem';

const renderScoreDetail = () => (
  <SummaryBlockWrap>
    <span>Scores</span>

    <SummaryBlockItem>
      <SummaryImage src="/images/bg-score.png" />

      <SummaryBlockContent>
        <SummaryText>Estimated Score</SummaryText>
        <SummaryScoreGroup>
          <SummaryScore>
            <SummaryNumber>1 - 5</SummaryNumber>
            <SummarySubText>out of 5</SummarySubText>
          </SummaryScore>
          <SummaryRank>
            <i className="chalktalk-arrow-up" />
            <span>1</span>
          </SummaryRank>
        </SummaryScoreGroup>
      </SummaryBlockContent>

      <SummaryBlockContent>
        <SummaryText>Lexile Level</SummaryText>
        <SummaryScoreGroup>
          <SummaryScore>
            <SummaryNumber>9th</SummaryNumber>
            <SummarySubText>5</SummarySubText>
          </SummaryScore>
          <SummaryRank>
            <i className="chalktalk-arrow-up" />
            <span>1</span>
          </SummaryRank>
        </SummaryScoreGroup>
      </SummaryBlockContent>
    </SummaryBlockItem>
  </SummaryBlockWrap>
);

const renderSessionItem = session => <SessionSubNavItem key={session.id} sessionItem={session} />;

const renderUpcomingItem = () => {
  const upcommingSessions = [];
  const upcommingReviewSessions = [];
  return (
    <NavBarList>
      <NavBarGroup>
        <NavBarGroupTitle>Lessons</NavBarGroupTitle>
        <UpcomingEmpty>No Upcoming Lessons</UpcomingEmpty>
      </NavBarGroup>

      <NavBarGroup>
        <NavBarGroupTitle>Reviews</NavBarGroupTitle>
        <UpcomingEmpty>No Upcoming Reviews</UpcomingEmpty>
      </NavBarGroup>
    </NavBarList>
  );
};

// =============================================

const renderAttendanceDetail = () => (
  <SummaryBlockWrap>
    <span>Attendance</span>
    <SummaryBlockItem>
      <SummaryImage src="/images/bg-attendance.png" />

      <SummaryBlockContent>
        <SummaryText>Last Week</SummaryText>
        <SummaryNumber>
          80%
        </SummaryNumber>
      </SummaryBlockContent>

      <SummaryBlockContent>
        <SummaryText>Overall</SummaryText>
        <SummaryNumber>
          90%
        </SummaryNumber>
      </SummaryBlockContent>
    </SummaryBlockItem>
  </SummaryBlockWrap>
);

const DashboardSummaryFake = ({ userRole }) => (
  <DashboardSummaryWrap>
    <DashboardPracticeActivityWrap>
      <BlockWrap>
        <BlockTitle>
          <span>Summary</span>
        </BlockTitle>
        <DashboardStatisticWrap>
          <DashboardStatisticGroup>
            <SummaryBlockWrap>
              <span>Lesson</span>
              <SummaryBlockItem>
                <SummaryImage src="/images/bg-course-progress.png" />

                <SummaryBlockContent>
                  <SummaryText>Schedule Time Passed</SummaryText>
                  <SummaryNumber>
                    95%
                  </SummaryNumber>
                </SummaryBlockContent>

                <SummaryBlockContent>
                  <SummaryText>Lessons Taught</SummaryText>
                  <SummaryNumber>
                    99%
                  </SummaryNumber>
                </SummaryBlockContent>
              </SummaryBlockItem>
            </SummaryBlockWrap>
            <SummaryBlockWrap>
              <span>Practice</span>
              <SummaryBlockItem>
                <SummaryImage src="/images/bg-practice.png" />

                <SummaryBlockContent>
                  <SummaryText>Practice Coverage</SummaryText>
                  <SummaryNumber>
                    97%
                  </SummaryNumber>
                </SummaryBlockContent>

                <SummaryBlockContent>
                  <SummaryText>Questions Answered</SummaryText>
                  <SummaryScore>
                    <SummaryNumber>120</SummaryNumber>
                    <SummarySubText>Questions</SummarySubText>
                  </SummaryScore>
                </SummaryBlockContent>
              </SummaryBlockItem>
            </SummaryBlockWrap>
          </DashboardStatisticGroup>
          <DashboardStatisticGroup>
            {userRole !== ROLE_TYPE.STUDENT && renderAttendanceDetail()}
            {renderScoreDetail()}
          </DashboardStatisticGroup>
        </DashboardStatisticWrap>
      </BlockWrap>

      <DashboardSummaryTable />
    </DashboardPracticeActivityWrap>

    <MediaQuery minWidth={1170}>
      {matches => matches && <DashboardLine />}
    </MediaQuery>

    <DashboardSummaryUpcomingWrap>
      <span>Upcoming Sessions</span>
      {renderUpcomingItem()}
    </DashboardSummaryUpcomingWrap>
  </DashboardSummaryWrap>
);

export default DashboardSummaryFake;
