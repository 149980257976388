import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isNil from 'lodash/isNil';
import MainContent from '../../components/MainContent';
import { selectors } from '../../reducers/leftSideBar';

export const mapStateToProps = (state) => ({
  isSideBarClose: selectors.getSideBar(state),
});

export default withRouter(connect(mapStateToProps)(MainContent));
