import styled, { css } from 'styled-components';
import {
  DefaultContentWrap,
} from '../Generals/stylesheets/General.style';

export const UserProfileView = styled.div`
  width: 100%;
  padding: 1em;
  border-radius: .325em;
  margin-bottom: 2em;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 0px 2px, rgba(9,30,66,0.31) 0px 0;
  position: relative;
  ${({ custome }) => custome && css`
    margin: 1em 1em 0em 0em;
    border-radius: 0em;
    flex: 0 0 calc(100%/3 - 1em);
    box-shadow: none;
    &:nth-child(3n) {
      flex: 0 0 calc(100%/3);
      margin: 1em 0em 0em 0em;
    }
  `}
  &:last-child {
    margin-bottom: 0em;
  }
`;

export const UserInfoListWrap = styled.div`
  margin-bottom: 2em;
  &:last-child {
    margin-bottom: 0em;
  }
`;

export const UserProfileInfo = styled.div`
  display: flex;
  align-items: center;
  min-height: 3em;
  .form-items {
    width: 100%;
    margin-bottom: 0em;
    > div {
      margin-bottom: 0em;
    }
  }
  ${({ block }) => block && css`
    display: block;
  `};
  input {
    outline: none;
    border-radius: .325em;
    border: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
    padding: 0.5em;
    min-width: 250px;
    margin-top: .5em;
    @media (max-width: 480px) {
      min-width: unset;
    }
  }
`;

export const UserProfileGroup = styled.div`
  flex: 1;
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    width: fit-content;
    li {
      width: 135px;
      text-align: center;
      padding: 5px 0px;
      color: #b0aba8;
      border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorXBorder};
      cursor: pointer;
      &:hover {
        color: ${props => props.theme.colorStyled.ColorPrimary};
        border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBlack};
      }
    }
    .react-tabs__tab--selected {
      color: ${props => props.theme.colorStyled.ColorBgDefault};
      border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBgDefault};
      &:hover {
        color: ${props => props.theme.colorStyled.ColorBgDefaultHover};
        border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBgDefaultHover};
      }
    }
  }
  ${({ flexLeft }) => flexLeft && css`
    flex: 0 0 18em;
    max-width: 18em;
    margin-right: 2em;
    @media (max-width: 960px) {
      flex: 0 0 100%;
      max-width: none;
      margin-right: 0em;
      margin-bottom: 2em;
    }
  `}
`;

export const UserProfileAction = styled.div`
  margin-left: auto;
`;

export const UserOrgazinationItem = styled.div`
  padding: .5em 1em;
  margin-bottom: .75em;
  border-radius: .325em;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 0px 2px, rgba(9,30,66,0.31) 0px 0;
  &:last-child {
    margin-bottom: 0em;
  }
`;

export const UserOrgazinationTitle = styled.div`
  margin-bottom: 1em;
  font-family: 'MontserratBold';
  text-transform: uppercase;
  @media (max-width: 768px) {
    width: fill-available;
    text-align: center;
  }
`;

export const UserLabel = styled.div`
  flex: 0 0 8em;
  font-family: 'MontserratMedium';
`;

export const UserIcon = styled.i`
  flex: 0 0 2em;
  font-weight: bold;
  font-size: ${props => props.theme.fontSize.HeadingH5FontSize};
`;

export const UserContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
`;

export const UserAvatarWrap = styled.div`
  margin-bottom: 2em;
  max-width: 18em;
  margin-top: -6.5em;
  position: relative;
  z-index: 2;
  @media (max-width: 768px) {
    max-width: none;
    display: flex;
    flex-direction: column;
    align-items: center
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: unset;
  }
`;

export const UserAvatar = styled.div`
  width: fit-content;
  height: fit-content;
  padding: .25em;
  background: #fff;
  border-radius: 100%;
  img {
    width: 10em;
    height: auto;
    border-radius: 100%;
    object-fit: contain;
    object-position: left center;
  }
`;

export const UserClassDayWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const UserClassDay = styled.div`
  margin-right: .5em;
  white-space: nowrap;
`;

export const UserDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  p {
    font-family: 'MontserratSemiBold';
    font-size: ${props => props.theme.fontSize.HeadingH6FontSize};
    text-transform: uppercase;
    margin: .5em 0em .25em;
    @media (max-width: 768px) {
      width: fill-available;
      text-align: center;
    }
    @media (max-width: 480px) {
      text-align: center;
      width: 100%;
    }
  }
  span {
    color: ${props => props.theme.colorStyled.ColorTime};
    
  }
  @media (max-width: 480px) {
    width: 100%;
    Button {
      position: absolute;
      right: 0;
    }
  }
`;

export const UserProfileTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  font-family: 'MontserratMedium';
  div:first-child {
    display: flex;
    align-items: center;
  }
  i {
    margin-right: 0.5em;
    font-size: ${props => props.theme.fontSize.HeadingH6FontSize};
    color: ${props => props.theme.colorStyled.ColorBgDefault};
  }

  ${({ isSpaceBetween }) => isSpaceBetween && css`
    justify-content: space-between;
  `}
`;

export const UserActionWrap = styled.div`
  position: relative;
`;

export const UserActionPopup = styled.div`
  position: absolute;
  right: 0em;
  border-radius: .325em;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: 0px 0px 7px -1px #ddd;
  overflow: hidden;
  z-index: 2;
`;

export const UserActionItem = styled.div`
  white-space: nowrap;
  padding: .5em 1em;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  i {
    margin-right: .5em;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #e6e6e6;
  }
  ${({ red }) => red && css`
    color: ${props => props.theme.colorStyled.ColorRedDel};
  `}
  ${({ blue }) => blue && css`
    color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  `}
`;

export const UserActionGroup = styled.div`
  margin-bottom: 1em;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const UserSocialIcon = styled.img`
  height: 1.5em;
  width: auto;
  object-fit: contain;
  margin-right: .5em;
`;

export const UserButonAction = styled.a`
  margin-left: auto;
  font-style: italic;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  border-bottom: 1px solid transparent;
  transition: all 300ms ease;
  &:hover {
    border-bottom: 1px solid ${props => props.theme.colorStyled.ColorPrimaryBlue};
  }
  ${({ connected }) => connected && css`
    color: ${props => props.theme.colorStyled.ColorPrimaryGreen};
    &:hover {
      border-bottom: none;
    }
  `}
`;

export const UserProfileDetailWrap = styled.div`
  display: flex;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const UserProfileGroupWrap = styled.div`
  flex: 1;
`;

export const UserProfileGroupContent = styled.div`
  flex: 1;
  margin-bottom: 2em;
  &:last-child {
    margin-bottom: 0em;
  }
`;

export const UserBackgroundHeaderWrap = styled.div`
  height: 11em;
  max-height: 11em;
  position: relative;
  transition: all 500ms ease;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0em;
    left: 0em;
    background: transparent;
    transition: all 500ms ease;
  }
  ${({ haveImage }) => haveImage && css`
    background: linear-gradient(to right,
      ${props => props.theme.colorStyled.ColorWhite},
      ${props => props.theme.colorStyled.ColorBgDefault});
  `}
  &:hover {
    &:before {
      background: #00000045;
    }
    .text-background, i {
      opacity: 1;
    }
  }
  ${({ isUpload }) => isUpload && css`
    &:before {
      background: #00000045;
    }
    .text-background, i {
      opacity: 1;
    }
  `}
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const UserImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


export const ProfileContentWrapper = styled(DefaultContentWrap)`
  padding-top: 6.25rem !important;
  margin-top: -5.25rem;
`;
