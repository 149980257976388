import React, { Suspense } from 'react';
import { useIsTeacher } from '@reducers/user/hooks';
import Box from '@components/Atoms/Box';
import ShowIf from '@components/Atoms/ShowIf';
import Skeleton from '@components/Atoms/Skeleton';

import BreadcumbsFilter from '@containers/BreadcumbsFilter';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import { BlockTitle, BlockWrap } from '@components/Generals/stylesheets/General.style';
import SetupCard from './SetupCard';
import PracticeCard from './PracticeCard';
import NoActivityCard from './NoActivityCard';
import ScoreReviewCard from './ScoreReviewCard';
import { useLessonReviewBreadcrumb, useGetLearnosityLessonReviewSessions } from './hooks';
import ScoreTable from './ScoreTable';

const LearnosityLessonReview = () => {
  const lessonReviewBreadcrumb = useLessonReviewBreadcrumb();
  const isTeacher = useIsTeacher();
  const { data: lessonReviewSession } = useGetLearnosityLessonReviewSessions();

  return (
    <Suspense
      fallback={
        <Skeleton
          sx={{
            px: 2,
            py: 4,
            height: '11.75rem',
            width: '95%',
            marginInline: '2rem',
            borderRadius: '0.5rem',
          }}
        />
      }
    >
      <BreadcumbsFilter
        haveSub
        breadcumbList={[...lessonReviewBreadcrumb]}
        mobileTitle={lessonReviewBreadcrumb[lessonReviewBreadcrumb.length - 1]}
        isDisplayFilter
      />
      <Box sx={{ px: 2, pb: 4 }} data-testid="learnosity-assessment">
        <ShowIf If={isTeacher}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <SetupCard />
            <ScoreTable />
          </Box>
        </ShowIf>
        <ShowIf If={!isTeacher}>
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
              minWidth: '100%',
            }}
          >
            <PracticeCard />
            <Box sx={{ height: '100%', width: '100%' }}>
              <ShowIf If={!lessonReviewSession?.length}>
                <NoActivityCard />
              </ShowIf>
              <ShowIf If={!!lessonReviewSession?.length}>
                <BlockWrap style={{ background: 'white', paddingTop: '1.5rem' }}>
                  <BlockTitle
                    style={{
                      fontSize: '1rem',
                      color: '#F6A527',
                      fontWeight: '700',
                      fontFamily: 'Montserrat',
                    }}
                  >
                    Assessment Sessions
                  </BlockTitle>
                  <ScrollWrapper
                    sx={{
                      height: 'calc(100vh - 18.75rem)',
                      '& .content': {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem',
                      },
                    }}
                  >
                    {lessonReviewSession
                      ?.sort(
                        (a, b) =>
                          new Date(b.ended).getTime() - new Date(a.ended).getTime(),
                      )
                      ?.map((session, index) => (
                        <ScoreReviewCard
                          key={session.id}
                          session={session}
                          index={lessonReviewSession.length - index}
                        />
                      ))}
                  </ScrollWrapper>
                </BlockWrap>
              </ShowIf>
            </Box>
          </Box>
        </ShowIf>
      </Box>
    </Suspense>
  );
};

export default LearnosityLessonReview;
