import React from 'react';

import {
  Alert as MuiAlert,
  AlertTitle as MuiAlertTitle,
  AlertProps as MuiAlertProps,
  SxProps,
  Theme,
} from '@mui/material';
import ShowIf from '../ShowIf';

export interface CardProps extends MuiAlertProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  title?: string;
}

const Alert = ({ title, children, ...rest }: CardProps) => (
  <MuiAlert {...rest}>
    <ShowIf If={!!title}>
      <MuiAlertTitle>{title}</MuiAlertTitle>
    </ShowIf>
    {children}
  </MuiAlert>
);

export default Alert;
