import { connect } from 'react-redux';
import QuestionAssets from '../../components/QuestionAssets';
import {
  selectors as LeftSideBarSelector,
  actions as LeftSideBarActions,
} from '../../reducers/leftSideBar';

const mapStateToProps = state => ({
  isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
});

const mapDispatchToProps = {
  togglePracticeNavigation: LeftSideBarActions.togglePracticeNavigation,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionAssets);
