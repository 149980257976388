import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const CreateOrJoinItemWrap = styled.div`
  width: 100%;
`;

export const CreateOrJoinItem = styled.div``;

export const CreateOrJoinTitle = styled.div`
  margin: 10px 0px 40px;
  & > h1 {
    font-size: 28px;
    font-family: 'MontserratRegular',Arial;
    line-height: 35px;
    color: #666666;
    margin: 20px 0px 5px;
  }
  & > p {
    font-size: 10.0pt;
    line-height: normal;
    font-family: 'Montserrat',Arial;
    color: #999999;
    font-weight: 200;
  }
  @media (max-width: 480px) {
    text-align: center;
    & > h1 {
      font-size: 24px;
    }
    & > p {
      font-size: 9.0pt;
    }
  }
`;

export const OptionsCreateOrJoinWrapper = styled.div`
  position: relative;
  width: 428px;
  height: 43px;
  margin: 20px 0;
  padding: 0px;
  font-size: 10.0pt;
  font-family: 'Montserrat-Regular',Arial;
  min-height: 20px;
  text-shadow: none;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  cursor: pointer;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const NextIconOptions = styled.i`
  width: 0;
  height: 0;
  font-size: 30px;
  position: absolute;
  right: 40px;
  top: 6px;
  color: #4A85E8;
  @media (max-width: 480px) {
    right: 30px;
  }
`;

export const ContentInsideWellWrapper = styled.div`
  width: 95%;
  height: 100%;
  padding: 5px;
  & > span {
    color: #282828;
    font-family: 'MontserratRegular',Arial;
    font-size: 10.0pt;
  }
  & > p {
    color: #666666;
    font-family: 'Montserrat',Arial;
    font-size: 9.0pt;
    font-style: normal;
    font-weight: 200;
  }
  @media (max-width: 480px) {
    & > p {
      font-size: 7.5pt;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 10px;
    }
  }
`;
