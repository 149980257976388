import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import _groupBy from 'lodash/groupBy';
import _set from 'lodash/set';
import moment from 'moment';
import UnitPracticeStudentDetail from '../../components/UnitPracticeStudentDetail';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as UserSelectord } from '../../reducers/user';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as LAActivitiesSelectors } from '../../reducers/lessonAssignedActivities';
import {
  selectors as SectionskillSelectors,
  actions as SectionSkillsActions,
} from '../../reducers/sectionSkills';
import {
  actions as PracticeActions,
  selectors as PracticeSelectors,
} from '../../reducers/practice';
import {
  actions as UserActivityActions,
  selectors as UserActivitySelectors,
} from '../../reducers/userActivities';
import {
  actions as LessonAssignmentActions,
  selectors as LessonAssignmentSelectors,
} from '../../reducers/lessonAssignment';

const intialValueForUser = {
  score: 0,
  maxScore: 0,
  timeOnTask: 0,
  lastActivity: null,
};

const mapStateToProps = (state, { match }) => {
  const unitId = _get(match, 'params.unitId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const assignedStatus = LessonAssignmentSelectors.getLAByUnitId(state, unitId);
  const assignStatusItemByUnit = LAActivitiesSelectors.getAssignStatusItemByUnit(state, unitId);
  const assignOfUnitByAssetType = _keyBy(assignStatusItemByUnit, 'asset_type');
  const assignedStatusByUserId = _keyBy(assignedStatus, 'assigned_to');
  const practiceSummary = SectionSelectors.getPracticeSummaryOfUnit(state, unitId);
  const practiceSummaryByUserId = {};

  practiceSummary.forEach((summaryItem) => {
    const {
      asset_type: assetType,
      user: userId,
      score,
      max_score: maxScore,
      last_activity: lastActivity,
      time_on_task: timeOnTask,
    } = summaryItem;

    if (typeof assetType === 'undefined') {
      return;
    }

    const currentValueByUserId = _get(practiceSummaryByUserId, [userId, assetType], intialValueForUser);
    const newLastActivity = !currentValueByUserId.lastActivity
      ? lastActivity
      : moment(lastActivity).isAfter(moment(currentValueByUserId.lastActivity))
        ? lastActivity : currentValueByUserId.lastActivity;
    const newValueByUserId = {
      score: currentValueByUserId.score + score,
      maxScore: currentValueByUserId.maxScore + maxScore,
      timeOnTask: currentValueByUserId.timeOnTask + timeOnTask,
      lastActivity: newLastActivity,
    };

    _set(practiceSummaryByUserId, [userId, assetType], newValueByUserId);
  });

  const userPracticeActivities = UserActivitySelectors.getUserUnitPracticeSessionActivities(
    state,
    sectionId,
    unitId,
  );

  const userPracticeActivitiesByAssetType = _groupBy(userPracticeActivities, 'asset_type');

  return {
    userPracticeActivitiesByAssetType,
    practiceSummary,
    practiceSummaryByUserId,
    assignedStatusByUserId,
    assignOfUnitByAssetType,
    lessons: SectionUnitSelectors.getAllLessonOfUnit(state, unitId),
    currentUserId: UserSelectord.getUserId(state),
    userRole: UserSelectord.getUserRole(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    isInitializing: PracticeSelectors.isInitializing(state),
    isInitialized: PracticeSelectors.isInitialized(state),
    unitDetail: SectionUnitSelectors.getUnitById(state, unitId),
    isGettingLesson: SectionUnitSelectors.isGettingLesson(state),
    isGettingSectionUnit: SectionUnitSelectors.isGettingSectionUnit(state),
    isGettingUserPracticeSessionActivities: UserActivitySelectors.isGettingUserPracticeSessionActivities(state),
    skillByLessonId: SectionskillSelectors.getSkillByLessonId(state),
    userPracticeActivities: UserActivitySelectors.getUserUnitPracticeSessionActivities(
      state,
      sectionId,
      unitId,
    ),
  };
};

const mapDispatchToProps = ({
  assignLesson: LessonAssignmentActions.assignLesson,
  unAssignLesson: LessonAssignmentActions.unAssignLesson,
  initializePracticeSession: PracticeActions.initializePracticeSession,
  getAllPracticeSessionActivities: UserActivityActions.getAllPracticeSessionActivities,
  lessonAssignmentDetail: LessonAssignmentActions.lessonAssignmentDetail,
  sectionSkillsGet: SectionSkillsActions.sectionSkillsGet,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnitPracticeStudentDetail));
