/* eslint-disable jsx-a11y/accessible-emoji */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
  RegisterItemWrapper,
  RegisterItem,
  RegisterTitle,
  RegisterWrap,
  ButtonStyled,
  RegisterMessageContent,
  RegisterCodeStyled,
  RegisterAction,
} from '../StudentRegister/StudentRegister.style';

const defaultCurrentSection = {
  name: 'Unknown',
};

export default class RegisterSuccess extends PureComponent {
  static propTypes = {
    currentSection: PropTypes.object,
  };

  static defaultProps = {
    currentSection: defaultCurrentSection,
  };

  goToDashboard = () => {
    push('/dashboard/course');
  };

  renderIntermediaScreen = () => {
    const { currentSection } = this.props;
    return (
      <RegisterWrap isLoading>
        <RegisterMessageContent>
          <h1>&#127881;</h1>
          <p>
            Congratulations!!! You have joined
            <br />
            <RegisterCodeStyled>
              {(currentSection || defaultCurrentSection).name}
            </RegisterCodeStyled>
            Check your email to confirm your account
          </p>
        </RegisterMessageContent>
        <RegisterAction large>
          <Link to="/dashboard/course">
            <ButtonStyled>Go to course</ButtonStyled>
          </Link>
        </RegisterAction>
      </RegisterWrap>
    );
  };

  render() {
    return (
      <RegisterItemWrapper>
        <RegisterItem>
          <RegisterTitle>
            <img src="/images/bg-login.jpg" alt="logo" />
          </RegisterTitle>
          {this.renderIntermediaScreen()}
        </RegisterItem>
      </RegisterItemWrapper>
    );
  }
}
