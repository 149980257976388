import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import TestDetailContentV2 from '../../components/TestDetailContentV2';
import { selectors as CurriculumSelectors } from '../../reducers/curriculums';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import {
  selectors as ExamResutlSelectors,
  actions as ExamResultActions,
  selectors as ExamResultSelectors,
} from '../../reducers/examResult';
import {
  actions as SectionExamActions,
  selectors as SectionExamSelectors,
} from '../../reducers/sectionExam';

const mapStateToProps = (state, { match }) => {
  const examSessionId = _get(match, 'params.examSessionId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const examDetail = SectionExamSelectors.getExamDetailById(state, examSessionId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);
  const subScoreColumns = SectionExamSelectors.getScoreColumns(state, examSessionId);
  const results = ExamResultSelectors.getResults(state);
  return {
    subScoreColumns,
    sectionId,
    examSessionId,
    examDetail,
    courseDetail,
    sectionDetail,
    currentCurriculum: CurriculumSelectors.getCurriculumById(state, curriculumId),
    isGettingExamResult: ExamResutlSelectors.getLoadingExamResultDetail(state),
    shouldFetchExamResult: ExamResultSelectors.shouldFetchExamResult(state),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    averageMaxScore: ExamResultSelectors.getAverageMaxScore(state),
    averageScore: ExamResultSelectors.getAverageOverallScore(state),
    sectionScoreById: ExamResultSelectors.getAverageSubScoreList(state, subScoreColumns, examDetail),
    completedSittings: ExamResultSelectors.getCompletedSittings(results, subScoreColumns),
    subjectExamSections: ExamResultSelectors.getsubjectExamSections(examDetail, subScoreColumns),
    userRole: UserSelectors.getUserRole(state),
    isGettingSectionExam: SectionExamSelectors.isGettingSectionExam(state),
    results,
  };
};

const mapDispatchToProps = {
  sectionExamGetDetail: SectionExamActions.sectionExamGetDetail,
  examResultGetDetail: ExamResultActions.examResultGetDetail,
  examResultStudentGetDetail: ExamResultActions.examResultStudentGetDetail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TestDetailContentV2));
