/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { LearnosityItem } from '@components/QuestionGenerationService/types';
import { CoAuthorAppWrapper } from '@components/QuestionGenerationService/styles';
import { createCoAuthorItemRequest } from '../../AssessmentServiceAPI';
import { useGetSignature } from '../../Hooks/Query';

const containerId = 'learnosity_preview_json_container';

const PreviewJson: React.FC<{
  widgetRef?: any;
  item: LearnosityItem;
  updateItem: (item: any) => void;
  renderCallback: () => void;
}> = ({ widgetRef = undefined, item, updateItem, renderCallback }) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [widgetInstance, setWidgetInstance] = useState<any>(undefined);
  const { data: signature } = useGetSignature(
    createCoAuthorItemRequest(item.user_id),
    'author',
    {
      enabled: !widgetInstance && !!(window as any).LearnosityAuthor,
    },
  );
  const itemRef = useRef(item);

  useImperativeHandle(widgetRef, () => ({ widgetInstance }), [widgetInstance]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mainRef.current!.appendChild(document.createElement('div')).id = containerId;
  }, []);

  // when the item is changed, we need to update the item in the widget
  useEffect(() => {
    if (!widgetInstance) {
      return;
    }

    // update the item reference so closures can access the latest item
    itemRef.current = item;

    // if a custom item is created and a widget hasnt been picked, lets force the user to pick a widget
    if (item.is_custom && (!item.questions || !item.questions[0].type)) {
      widgetInstance.navigate(`items/${item.reference}/widgets/new`);
    } else {
      // force view to the item because at this point the app is still "empty"
      widgetInstance.setItemJson(item);
    }
  }, [item, widgetInstance]);

  // when the user clicks save, we need to update the item in the hook
  const saveChanges = useCallback(
    (app) => {
      const widgetItem = app.getItem();

      const updatedItem = {
        ...itemRef.current,
        item: {
          ...itemRef.current.item,
          definition: widgetItem.item.definition,
        },
        questions: widgetItem.questions,
        features: widgetItem.features,
        is_edited: true,
      };

      updateItem(updatedItem);
    },
    [updateItem],
  );

  const moveNavbarButtons = () => {
    // To save space we are consolidating the navbar and the toolbar
    const learnDom = mainRef?.current;

    if (!learnDom) {
      return;
    }

    const selectors = {
      previewButtonContainer:
        'ul.lrn-qe-toolbar-controls > li > div.lrn-qe-toolbar-toggle',
      saveButton:
        'div[data-authorapi-selector="nav-bar"] button[data-authorapi-selector="save"]',
      backButton: 'div.lrn-author-item-nav > div > button[data-authorapi-selector]',
    };

    const elements = {
      previewButtonContainer: learnDom.querySelector(selectors.previewButtonContainer),
      saveButton: learnDom.querySelector(selectors.saveButton),
      backButton: learnDom.querySelector(selectors.backButton),
    };

    if (
      !elements.previewButtonContainer ||
      !elements.saveButton ||
      !elements.backButton
    ) {
      return;
    }

    const createListItem = (element: Element) => {
      const listItem = document.createElement('li');
      listItem.appendChild(element);
      return listItem;
    };

    const newListItems = [
      createListItem(elements.backButton),
      createListItem(elements.saveButton),
    ];

    newListItems.forEach((listItem) => {
      elements.previewButtonContainer?.parentNode?.parentNode?.appendChild(listItem);
    });
  };

  const hidePassageEditButton = () => {
    // Hide the passage edit button while we dont support synced edits for now...
    const editButton = mainRef.current?.querySelector(
      "button[data-lrn-author-toggle='edit']",
    );
    if (!editButton) {
      return;
    }

    editButton.addEventListener('click', () => {
      const elements = mainRef.current?.querySelectorAll('div.lrn-author-widget-loaded');
      elements?.forEach((element) => {
        const isPassage = element.querySelector('.lrn-widget-drag-name');
        if (isPassage?.textContent === 'Passage') {
          const widgetConfigWrapper = element.querySelector(
            "div[data-authorapi-selector='widget-config-wrapper']",
          );
          if (widgetConfigWrapper) {
            (widgetConfigWrapper as HTMLElement).style.display = 'none';
          }
        }
      });
    });
  };

  useEffect(() => {
    const isElementOnTheDom = !!document.getElementById(containerId);
    if (
      signature &&
      Object.keys(signature!).length &&
      !widgetInstance &&
      isElementOnTheDom
    ) {
      const authorApp = (window as any).LearnosityAuthor?.init(
        signature,
        {
          readyListener: () => {
            setWidgetInstance(authorApp);

            authorApp.on('render:item', () => {
              renderCallback();
              hidePassageEditButton();
            });

            authorApp.on('widgetedit:widget:changed', () => {
              setTimeout(() => {
                moveNavbarButtons();
              }, 100);
            });

            authorApp.on('save', () => {
              setTimeout(() => {
                saveChanges(authorApp);
              }, 100);
            });
          },
          errorListener: (error: any) => {
            console.error('Error initializing Authoring API', error);
          },
        },
        containerId,
      );
    }
    return () => {
      if (widgetInstance && isElementOnTheDom) {
        widgetInstance.destroy(); // TODO this is not correct
        setWidgetInstance(undefined);
      }
    };
  }, [item, renderCallback, saveChanges, signature, widgetInstance]);

  return <CoAuthorAppWrapper ref={mainRef} />;
};

export default PreviewJson;
