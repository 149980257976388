import React from 'react';
import WizardProgress from '@components/Generals/WizardProgress';
import { useQuestion } from '@components/PracticeV2/Provider/PracticeProvider';
import { useQuestions } from '@components/PracticeV2/Provider/PracticeDataProvider';
import Paper from '@mui/material/Paper';

const PracticeWizard = () => {
  const questions = useQuestions();
  const question = useQuestion();

  return (
    <Paper
      elevation={3}
      sx={{
        zIndex: 1,
      }}
    >
      <WizardProgress
        restrictMoveForward
        currentState={question}
        states={questions}
        stateLabel={(q) => `Question ${(q?.index || 0) + 1}`}
        stateIdentifier={(q) => q.id}
      />
    </Paper>
  );
};

export default React.memo(PracticeWizard);
