/* eslint-disable camelcase */
import React, { useCallback } from 'react';
import Select from '@components/Atoms/Select';
import Button from '@components/Atoms/Button';
import TextField from '@components/Atoms/TextField';
import ShowIf from '@components/Atoms/ShowIf';
import Box from '@components/Atoms/Box';
import { CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import Typography from '@components/Atoms/Typography';
import Tooltip from '@components/Atoms/Tooltip';
import ShadowScrollbar from '@components/Scrollbar/ShadowScrollbars';
import {
  useContentFilterContext,
  useContentFilterElement,
} from '@components/ContentFilters/ContentFilterProvider';
import { useParams } from 'react-router-dom';
import { useGetSectionSubjectUnitLesson } from '../../hooks/useGetSectionSubjectUnitLesson';
import { MenuRowWrapper, MenuColumn, MenuTwoRowWrapper } from './styles';
import { QuestionGenerationMenuType } from './types';
import QuestionTypeSelector from './QuestionTypeSelector';
import { cleanText } from './utils/helpers';

const toolTipStyle = {
  tooltip: {
    sx: {
      fontSize: '0.85rem',
      maxWidth: '500px',
    },
  },
};

const questionQuantityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
  value,
  label: value.toString(),
}));

const QuestionGenerationMenu = ({
  submitCallback,
  customQuestionCallback,
  resetCallback,
  hasTask,
  isLoading,
  state,
  dispatch,
  isSubmitting,
  hasItems,
  generating,
  setPassageMode,
  inDialog,
}) => {
  const isDisabled = hasTask || isLoading || hasItems;
  const { subjectId } = useParams();
  const { subjects, selectedPassage, setSelectedPassage } = useContentFilterContext();
  const ContentFilterElement = useContentFilterElement();
  const contentFilterRef = React.useRef<HTMLDivElement>(null);
  const { data: allSubjects } = useGetSectionSubjectUnitLesson();
  const isEnglishSubject = Boolean(
    state.subject?.display_name?.toLowerCase()?.includes('english'),
  );

  const handleChange = useCallback(
    (type: QuestionGenerationMenuType) => (value: unknown) => {
      dispatch({ type, payload: value });
    },
    [dispatch],
  );

  return (
    <ShadowScrollbar
      style={{
        width: '100%',
        height: 'calc(75vh)',
      }}
    >
      <Box
        sx={{
          pr: '1rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: '100%',
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: '0.5rem' }}>
          Fill in the options below to generate a question.
        </Typography>
        <ShowIf If={!subjectId}>
          <Tooltip
            title="Select the subject you want to generate a question for."
            arrow
            placement="right"
            componentsProps={toolTipStyle}
          >
            <MenuRowWrapper>
              <MenuColumn>
                <Select
                  id="select-subject"
                  onChangeHandler={(selectedId: number) => {
                    const selected = allSubjects.find(({ id }) => id === selectedId);
                    if (selected) {
                      handleChange(QuestionGenerationMenuType.SET_SUBJECT)(selected);
                    }
                  }}
                  defaultValue={state.subject.id}
                  value={state.subject.id}
                  fullWidth
                  list={
                    allSubjects?.map(({ id, display_name }) => ({
                      value: id,
                      label: display_name,
                    })) || []
                  }
                  label="Subject"
                  size="small"
                  disabled={isDisabled}
                />
              </MenuColumn>
            </MenuRowWrapper>
          </Tooltip>
        </ShowIf>
        <MenuRowWrapper>
          {ContentFilterElement && (
            <ContentFilterElement
              orientation="vertical"
              disabled={isDisabled}
              defaults={inDialog ? undefined : { bloom: '2', dok: '2' }}
              ref={contentFilterRef}
            />
          )}
        </MenuRowWrapper>
        <MenuRowWrapper>
          <QuestionTypeSelector
            handleChange={handleChange(QuestionGenerationMenuType.SET_QUESTION_TYPE)}
            isDisabled={isDisabled}
            selectedSubject={subjects?.find(({ id }) => id === state.subject.id)}
          />
        </MenuRowWrapper>
        <MenuRowWrapper>
          <ShowIf If={isEnglishSubject}>
            <ShowIf If={selectedPassage?.data}>
              <TextField
                variant="outlined"
                label="Passage"
                multiline
                rows={4}
                value={cleanText(selectedPassage?.data?.content || '')}
                onChange={undefined}
                fullWidth
                disabled
              />
            </ShowIf>
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <Button
                variant="outlined"
                fullWidth
                disabled={isDisabled}
                sx={{ mt: '0.5rem' }}
                onClick={setPassageMode}
              >
                {selectedPassage?.data?.content ? 'Change Passage' : 'Select Passage'}
              </Button>
              <ShowIf If={selectedPassage?.data}>
                <Button
                  variant="outlined"
                  fullWidth
                  disabled={isDisabled}
                  sx={{ mt: '0.5rem' }}
                  onClick={() => setSelectedPassage(null)}
                >
                  Unset Passage
                </Button>
              </ShowIf>
            </Box>
          </ShowIf>
        </MenuRowWrapper>
        <MenuTwoRowWrapper>
          <MenuColumn>
            <Select
              id="select-qcount"
              onChangeHandler={handleChange(
                QuestionGenerationMenuType.SET_QUESTION_QUANTITY,
              )}
              value={state.questionQuantity}
              defaultValue={state.questionQuantity}
              fullWidth
              size="small"
              list={questionQuantityOptions}
              label="Questions to Generate with AI"
              disabled={generating !== null}
            />
          </MenuColumn>
          <MenuColumn>
            <FormControlLabel
              label="Include Lesson Slides"
              labelPlacement="end"
              slotProps={{
                typography: {
                  fontSize: '0.75rem',
                },
              }}
              control={
                <Checkbox
                  disabled={isDisabled}
                  onChange={(e) =>
                    handleChange(QuestionGenerationMenuType.SET_INCLUDE_SLIDES)(
                      e.target.checked,
                    )
                  }
                  inputProps={{
                    'aria-label': 'controlled',
                  }}
                />
              }
            />
          </MenuColumn>
        </MenuTwoRowWrapper>
        <MenuRowWrapper>
          <TextField
            variant="outlined"
            label="Describe Your Needs"
            multiline
            size="small"
            rows={4}
            value={state.customNeeds}
            onChange={(e) => {
              handleChange(QuestionGenerationMenuType.SET_CUSTOM_NEEDS)(e.target.value);
            }}
            fullWidth
            disabled={generating !== null}
            placeholder="Describe any specific requirements or context for the questions you want to generate..."
          />
        </MenuRowWrapper>
        <MenuRowWrapper>
          <ShowIf If={!isSubmitting}>
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <Tooltip
                title="Generate questions using AI based on the selected criteria"
                arrow
                placement="top"
                componentsProps={toolTipStyle}
              >
                <span style={{ flex: 1 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={submitCallback}
                    disabled={generating !== null}
                  >
                    <ShowIf If={!generating}>Generate with AI</ShowIf>
                    <ShowIf If={generating}>
                      Generating...{' '}
                      <CircularProgress sx={{ ml: '1rem' }} color="inherit" size={20} />
                    </ShowIf>
                  </Button>
                </span>
              </Tooltip>
              <Tooltip
                title="Create custom questions related to the selected criteria"
                arrow
                placement="top"
                componentsProps={toolTipStyle}
              >
                <span style={{ flex: 1 }}>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={customQuestionCallback}
                    disabled={isLoading}
                    sx={{ fontSize: '0.85rem' }}
                  >
                    Custom Question
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </ShowIf>
        </MenuRowWrapper>
        <ShowIf If={isDisabled}>
          <MenuRowWrapper>
            <Tooltip
              title="Removes all questions and resets the form to its initial state"
              arrow
              placement="top"
              componentsProps={toolTipStyle}
            >
              <span>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={resetCallback}
                >
                  Reset
                </Button>
              </span>
            </Tooltip>
          </MenuRowWrapper>
        </ShowIf>
      </Box>
    </ShadowScrollbar>
  );
};

export default QuestionGenerationMenu;
