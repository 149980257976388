import {
  takeLeading, put, call, select,
} from 'redux-saga/effects';
import _orderBy from 'lodash/orderBy';
import * as SectionAPI from '../../apis/sections';
import {
  SECTIONS_SESSION_ADD,
  SECTIONS_SESSION_GET_DETAIL,
  SECTIONS_SESSION_UPDATE,
  SECTIONS_SESSION_GET,
  SECTIONS_SESSION_DELETE,
  actions,
  selectors,
} from '../../reducers/sectionSession';
import {
  actions as ScheduleModalActions,
} from '../../reducers/scheduleModal';
import * as SessionAPi from '../../apis/sessions';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';

function* sectionsSessionAdd({ sessionData }) {
  const { response, error } = yield call(SessionAPi.addSessionStandard, sessionData);
  if (response) {
    const { data } = response;
    yield put(actions.sectionsSessionAddSuccess(data));
  } else {
    yield put(actions.sectionsSessionAddFail(error));
    ToastError(error);
  }
}

function* sectionsSessionGetDetail({ sectionId, sessionId }) {
  const { response, error } = yield call(SectionAPI.getSectionSessionDetail, sectionId, sessionId);

  if (response) {
    yield put(actions.sectionsSessionGetDetailSuccess(response));
  } else {
    yield put(actions.sectionsSessionGetDetailFail(error));
  }
}

function* sectionsSessionUpdate({ sessionId, sessionData }) {
  const { response, error } = yield call(SessionAPi.updateSessionStandard, sessionId, sessionData);

  if (response) {
    const newLessonInfo = response.data;
    yield put(actions.sectionsSessionUpdateSuccess(newLessonInfo.id, newLessonInfo));
    ToastSuccess('Lesson detail has been updated successfully!');
    yield put(ScheduleModalActions.closeFormModal());
  } else {
    yield put(actions.sectionsSessionUpdateFail(error));
    ToastError(error.message || error);
  }
}

function* sectionSessionGet({ sectionId, subjectId }) {
  if (sectionId > 0) {
    const shouldFetch = yield select(selectors.getShouldFetch, sectionId);

    if (!shouldFetch) {
      return;
    }

    const { response, error } = yield call(SectionAPI.getSectionLessonSessions, sectionId, subjectId);

    if (response) {
      const { data } = response;
      const transformData = _orderBy(data, 'date', 'asc');

      yield put(actions.sectionSessionGetSuccess(transformData, sectionId));
    } else {
      yield put(actions.sectionSessionGetFail(error));
    }
  }
}
function* sectionSessionDelete({ sectionId }) {
  const { response, error } = yield call(SessionAPi.deleteSessionStandard, sectionId);

  if (response) {
    yield put(actions.sectionSessionDeleteSuccess(sectionId));
    yield put(ScheduleModalActions.closeFormModal());
  } else {
    yield put(actions.sectionSessionDeleteFail(error));
  }
}

export default function* sectionReviewSaga() {
  yield takeLeading(SECTIONS_SESSION_ADD, sectionsSessionAdd);
  yield takeLeading(SECTIONS_SESSION_GET_DETAIL, sectionsSessionGetDetail);
  yield takeLeading(SECTIONS_SESSION_GET, sectionSessionGet);
  yield takeLeading(SECTIONS_SESSION_UPDATE, sectionsSessionUpdate);
  yield takeLeading(SECTIONS_SESSION_DELETE, sectionSessionDelete);
}
