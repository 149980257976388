import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import AnswerExplanation from '../../components/AnswerExplanation';
import {
  selectors as LeftSideBarSelector,
} from '../../reducers/leftSideBar';
import {
  selectors as SectionStudentSelectors,
  actions as SectionStudentActions,
} from '../../reducers/sectionStudent';
import { selectors as userSelectors } from '../../reducers/user';

const mapStateToProps = (state, { match }) => {
  const { sectionId } = match.params;

  return {
    sectionId,
    userRole: userSelectors.getUserRole(state),
    isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
    studentByIds: SectionStudentSelectors.getStudentByIds(state, sectionId),
  };
};

const mapDispatchToProps = {
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AnswerExplanation));
