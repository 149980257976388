import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import ActionCardEventEmitter, {
  ActionCardEvents,
  MessageFnType,
} from '../utils/ActionCardEventEmitter';

const useWebSocketListener = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const updateGroupActivityInfoSubscriber: MessageFnType = () => {
      queryClient.invalidateQueries(['getGroupActivityInfo']);
      queryClient.invalidateQueries(['getActivityGroups']);
    };
    ActionCardEventEmitter.on(
      ActionCardEvents.START_GROUP_ACTIVITY,
      updateGroupActivityInfoSubscriber,
    );
    ActionCardEventEmitter.on(
      ActionCardEvents.RESET_ACTIVITY,
      updateGroupActivityInfoSubscriber,
    );
    return () => {
      ActionCardEventEmitter.off(
        ActionCardEvents.START_GROUP_ACTIVITY,
        updateGroupActivityInfoSubscriber,
      );
      ActionCardEventEmitter.off(
        ActionCardEvents.RESET_ACTIVITY,
        updateGroupActivityInfoSubscriber,
      );
    };
  }, []);
};

export default useWebSocketListener;
