import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginLoadingIndicator from '../LoginPage/LoginLoadingIndicator';

export default class CleverLoadingPage extends Component {
  static propTypes = {
    cleverLogin: PropTypes.func.isRequired,
    connectClever: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    isAuthenticated: PropTypes.bool,
  }

  componentDidMount() {
    const {
      cleverLogin, location,
      isAuthenticated, connectClever,
    } = this.props;
    const searchParams = new URLSearchParams(location.search);

    if (!isAuthenticated) cleverLogin(searchParams.get('code'));
    else connectClever(searchParams.get('code'));
  }

  render() {
    return <LoginLoadingIndicator content="Connecting your account with Clever" />;
  }
}
