import React from 'react';
import ServiceProvider from './LearnosityProvider';
import ReactQueryProvider from './ReactQueryProvider';

const contextProviders = [ServiceProvider, ReactQueryProvider] as unknown as React.FC[];
const ProviderCompose = ({
  children,
  ...props
}: {
  children: React.ReactNode | React.ReactNode[];
  [key: string]: any;
}) =>
  contextProviders.reduceRight(
    (acc, Provider) => React.createElement(Provider, props, acc),
    children,
  );
export default ProviderCompose;
