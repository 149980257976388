import React, { useEffect, useMemo } from 'react';
import { Unit } from '@CT-Types/lessonDomain';
import UnitAccordion from './UnitAccordion';

interface UnitLessonItemProps {
  units: Unit[];
  practiceSummaryByUnitId: Record<string, any>;
  subjectVersion: number;
  isReviewClassEnabled: boolean;
}

const UnitLessonItem = ({
  units,
  practiceSummaryByUnitId,
  subjectVersion,
  isReviewClassEnabled,
}: UnitLessonItemProps) => {
  const unitAssessmentVisibleState = useMemo(
    () =>
      units.reduce((acc, unit) => {
        acc[unit.id] = false;
        return acc;
      }, {}),
    [units],
  );

  useEffect(() => {
    const element = document.getElementById('active-lesson');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [units.length]);

  return units.map((unit) => (
    <UnitAccordion
      item={unit}
      key={unit.id}
      practiceSummaryByUnitId={practiceSummaryByUnitId}
      subjectVersion={subjectVersion}
      unitAssessmentVisibleState={unitAssessmentVisibleState}
      isReviewClassEnabled={isReviewClassEnabled}
    />
  ));
};

export default UnitLessonItem;
