/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import _get from 'lodash/get';
import DashboardPracticeDetail from '../../components/DashboardPracticeDetail';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as UserSelectors } from '../../reducers/user';
import { actions as SectionUnitActions } from '../../reducers/sectionUnit';
import { actions as ActivityActions } from '../../reducers/activity';
import {
  selectors as SectionSelectors,
  actions as SectionsActions,
} from '../../reducers/sections';
import {
  selectors as SectionStudentSelector,
  actions as SectionStudentActions,
} from '../../reducers/sectionStudent';
import {
  selectors as SectionskillSelectors,
  actions as SectionSkillsActions,
} from '../../reducers/sectionSkills';
import {
  actions as SectionSubjectActions,
  selectors as SectionSubjectSelectors,
} from '../../reducers/sectionSubjects';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionId = _get(match, 'params.sectionId', null);
  const curriculumId = _get(courseData, 'curriculum', null);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    sectionId,
    courseDetail,
    sectionDetail,
    curriculumId,
    userRole: UserSelectors.getUserRole(state),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    examsScoreSummary: SectionSelectors.examsScoreSummary(state),
    studentList: SectionStudentSelector.getStudentListWithFilter(
      state,
      sectionId,
    ),
    studentFilter: SectionStudentSelector.getStudentListWithFilter(
      state,
      sectionId,
    ),
    subjects: SectionSubjectSelectors.getSectionSubjectListOrder(state),
    skillByLessonId: SectionskillSelectors.getSkillByLessonId(state),
  };
};

const mapDispatchToProps = {
  sectionSkillsGet: SectionSkillsActions.sectionSkillsGet,
  sectionsPracticeSummaryGet: SectionsActions.sectionsPracticeSummaryGet,
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
  fetchSectionSubjectList: SectionSubjectActions.fetchSectionSubjectList,
  getAllLessonActivity: ActivityActions.getAllLessonActivity,
  getAllLessonActivitySummary: ActivityActions.getAllLessonActivitySummary,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardPracticeDetail);
