// action definition
export const COUNTRIES_FETCH_COUNTRY_LIST = 'countries/COUNTRIES_FETCH_COUNTRY_LIST';
export const COUNTRIES_FETCH_COUNTRY_LIST_SUCCESS = 'countries/COUNTRIES_FETCH_COUNTRY_LIST_SUCCESS';
export const COUNTRIES_FETCH_COUNTRY_LIST_FAIL = 'countries/COUNTRIES_FETCH_COUNTRY_LIST_FAIL';

// selectors
export const getCountryList = ({ countries }) => countries.countryList;

export const selectors = {
  getCountryList,
};

// action creators
export const fetchCountryList = () => ({
  type: COUNTRIES_FETCH_COUNTRY_LIST,
});

export const fetchCountryListSuccess = countryList => ({
  type: COUNTRIES_FETCH_COUNTRY_LIST_SUCCESS,
  payload: {
    countryList,
  },
});

export const fetchCountryListFailed = errorMsg => ({
  type: COUNTRIES_FETCH_COUNTRY_LIST_FAIL,
  payload: {
    errorMsg,
  },
});

export const actions = {
  fetchCountryList,
  fetchCountryListSuccess,
  fetchCountryListFailed,
};

// reducers

const initialState = {
  countryList: [],
  isLoading: null,
  errorMsg: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_FETCH_COUNTRY_LIST: {
      return {
        ...state,
        isLoading: true,
        errorMsg: false,
      };
    }

    case COUNTRIES_FETCH_COUNTRY_LIST_SUCCESS: {
      const { countryList } = action.payload;

      return {
        ...state,
        isLoading: false,
        countryList,
      };
    }

    case COUNTRIES_FETCH_COUNTRY_LIST_FAIL: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMsg,
      };
    }

    default: {
      return state;
    }
  }
}
