import { CriteriaDB } from '../db';

// Criteria

class Criteria {
  API;

  constructor(API) {
    this.API = API;
  }

  get(id) {
    if (id) {
      return this.API.get(id);
    }
    return this.API.getAll();
  }

  create(data) {
    const newData = { id: Date.now(), ...data };
    return this.API.add(newData);
  }

  put(data) {
    return this.API.set(data);
  }

  delete(id) {
    return this.API.delete(id);
  }
}
const criteriaAPI = new Criteria(CriteriaDB);
export default criteriaAPI;
