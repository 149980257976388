import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LeftSideBarAdmin from '../../containers/LeftSideBar/LeftSideBarAdmin';
import MainContent from '../../containers/MainContent';
import AdminReports from '../../containers/AdminReports/AdminReports';
import ReportGeneratorRouter from '../../components/ReportGenerator/ReportGeneratorRouter';


const Admin = () => {
  return (
    <React.Fragment>
      <LeftSideBarAdmin />
      <MainContent>
        <Switch>
          <Route path="/admin/reports/generator/">
            <ReportGeneratorRouter />
          </Route>
          <Route exact path="/admin/reports/data-dashboard">
            <AdminReports />
          </Route>
        </Switch>
      </MainContent>
    </React.Fragment>
  );
}

export default Admin;
