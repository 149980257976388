import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MainLayout from '../../components/MainLayout';
import { selectors as authSelectors } from '../../reducers/auth';
import _get from 'lodash/get';
import { selectors as userSelectors } from '../../reducers/user';
import {
  selectors as sectionScheduleSelectors,
} from '../../reducers/sectionSchedule';
import { selectors as SectionExamSelectors, actions as SectionExamActions } from '../../reducers/sectionExam';

const mapStateToProps = (state, { location }) => {
  const pathname = _get(location, 'pathname', '/');
  const paramsArray = pathname.split('/');
  const courseId = _get(paramsArray, '3', null) && !isNaN(_get(paramsArray, '3', null)) ? _get(paramsArray, '3', null) : null;
  const sectionId = _get(paramsArray, '5', null);
  return {
    isLoading: authSelectors.getIsLoggingIn(state),
    exams: sectionScheduleSelectors.getScheduleList(state).filter((review) => review.type == 'exams'),
    userRole: userSelectors.getUserRole(state),
    courseId,
    sectionId,
    hasExamV2Access: SectionExamSelectors.getExamV2Access(state, courseId, sectionId),
  }
};

const mapDispatchToProps = { fetchExamV2Access: SectionExamActions.fetchExamV2Access, };


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout));