/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { useQGSAccess } from '@apis/questiongenerationservice';
import {
  TopNavBarVertical,
  LogoChalkTalk,
  NavBarGroup,
  NavBarPrimary,
  NavBarItem,
  InfoUserGroup,
  DropdownWrap,
  DropdownItem,
  NavBarIcon,
  UserIconInitials,
} from './TopNavBar.style';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import { ROLE_TYPE } from '../../utils/enums';
import TeacherCourseSetup from '../TeacherCourseSetup';
import { reviewerList } from '../../utils/reviewerList';
import { getExternalLink } from '../../apis/curriculum';

const TeacherNavBar = ({
  state,
  props,
  renderNavBarItem,
  renderUserIcon,
  closeCreateCourseForm,
  openCreateCourseForm,
}) => {
  const { isDropdown, isCreateCourseOpen } = state;
  const { user, curriculumDetail } = props;
  const email = _get(user, 'email', '');
  const isReviewer = reviewerList.includes(email);
  const isReportViewer = _get(user, 'is_report_viewer', false);
  const hasAuthorAideAccess = useQGSAccess(user.id, false);

  return (
    <NavBarGroup>
      <NavBarPrimary>
        {renderNavBarItem('/dashboard/course/', 'chalktalk-icon-home', '', 'Dashboard')}
        {renderNavBarItem('/lessons/course/', 'chalktalk-icon-tech', '', 'Lessons')}
        {curriculumDetail.has_exam &&
          renderNavBarItem('/test/course/', 'chalktalk-icon-note-list', '', 'Tests')}
        {renderNavBarItem('/schedule/course/', 'chalktalk-calendar', '', 'Calendar')}
        {hasAuthorAideAccess &&
          renderNavBarItem(
            '/coauthor/course/',
            'chalktalk-icon-teacher',
            '',
            'Co-Author',
          )}
        {isReportViewer &&
          renderNavBarItem(
            '/admin/reports/generator',
            'chalktalk-icon-reports',
            '',
            'Admin Reports',
            true,
            false,
            false,
            true,
          )}
        {/* // We commented these lines because we've stopped using Firebase for feature visibility.
            // Additionally,we've set the default to be hidden. */}
        {/* {false &&
          renderNavBarItem(
            '/instructionalwalkThrough',
            'chalktalk-icon-eyes',
            '',
            'Intructional walkThrough',
            false,
            false,
          )} */}
      </NavBarPrimary>
      <InfoUserGroup>
        <NavBarPrimary>
          {renderNavBarItem(
            '/teacher/course/',
            'chalktalk-icon-teacher',
            'Teacher Training',
            '',
            true,
            false,
          )}
          {renderNavBarItem(
            'teacher-help-link',
            'chalktalk-icon-questionmark',
            'Help Articles',
            '',
            false,
            false,
            true,
          )}
          {renderNavBarItem(
            '/management/course/',
            'chalktalk-course-management',
            'Course Management',
            '',
            true,
            false,
          )}
          <NavBarItem>
            <span // eslint-disable-line
              className="chalktalk-course-plus"
              data-tip="Join Section or Create Course"
              data-for="tooltip-top-nav-bar"
              onClick={openCreateCourseForm}
            />
            {/* {isCourseDropDown && (
              <React.Fragment>
                <OverlayWrap isOpen={isCourseDropDown} onClick={this.toggleCourseDropdown} />
                {this.renderCourseDropdown()}
              </React.Fragment>
            )} */}
          </NavBarItem>
          {isReviewer &&
            renderNavBarItem(
              '/reviewer/course/',
              'papers.svg',
              'My Library',
              '',
              true,
              false,
            )}
          {renderUserIcon(user, isDropdown)}
          {/* <SectionEnrollModal isOpen={isSectionEnrollOpen} onClose={this.closeSectionEnrollModal} />
          <TeacherCreateCourse
            isOpen={isCreateCourseOpen}
            onClose={this.closeCreateCourseForm}
          /> */}
          {isCreateCourseOpen && <TeacherCourseSetup onClose={closeCreateCourseForm} />}
        </NavBarPrimary>
      </InfoUserGroup>
    </NavBarGroup>
  );
};
class TopNavBar extends PureComponent {
  static propTypes = {
    logout: PropTypes.func.isRequired,
    getCurriculumList: PropTypes.func.isRequired,
    user: PropTypes.shape(),
    history: PropTypes.shape(),
    courseId: PropTypes.any,
    sectionId: PropTypes.any,
    isOpenTeacherCourseSetup: PropTypes.bool,
    courseList: PropTypes.array,
    sectionList: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDropdown: false,
      isCreateCourseOpen: props.isOpenTeacherCourseSetup,
      sectionId: '',
      gettingCurriculums: false,
    };
  }

  componentDidMount() {
    const { curriculumDetail, getCurriculumList } = this.props;
    const { gettingCurriculums } = this.state;

    if (_isEmpty(curriculumDetail) && !gettingCurriculums) {
      this.setState({
        gettingCurriculums: true,
      });
      getCurriculumList();
    }
  }

  componentDidUpdate(prevProps) {
    const { isCreateCourseOpen } = this.state;
    const { isOpenTeacherCourseSetup: prevIsOpenTeacherCourseSetup } = prevProps;
    const { isOpenTeacherCourseSetup } = this.props;

    if (
      !prevIsOpenTeacherCourseSetup &&
      isOpenTeacherCourseSetup &&
      !isCreateCourseOpen
    ) {
      this.setState({
        // eslint-disable-line
        isCreateCourseOpen: true,
      });
    }
    if (this.props.sectionId && this.state.sectionId !== this.props.sectionId) {
      this.setState({ sectionId: this.props.sectionId });
    }
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdown: !prevState.isDropdown,
    }));
  };

  openCreateCourseForm = () => {
    this.setState({
      isCreateCourseOpen: true,
    });
  };

  closeCreateCourseForm = () => {
    this.setState({
      isCreateCourseOpen: false,
    });
  };

  redirectPage = (link) => {
    const { history, courseList, sectionList, user, courseId } = this.props;
    const { role } = user;
    let url = link;

    if (role === ROLE_TYPE.DISTRICT_ADMIN) {
      history.push(url);

      return;
    }

    if (courseList.length === 0) {
      if (role === ROLE_TYPE.STUDENT) {
        history.push('/section-enroll');
      } else {
        this.openCreateCourseForm();
      }

      return;
    }

    if (sectionList.length === 0) {
      if (role === ROLE_TYPE.STUDENT) {
        history.push('/section-enroll');
      } else {
        const selectedCourseId = courseId || _get(courseList, '[0].id', '');
        history.push(
          `/management/course/${selectedCourseId}?isOpenCreateSection=${Math.round(
            Math.random() * 100 + 1,
          )}`,
        );
      }

      return;
    }

    try {
      const section = sectionList.find((ele) => ele.id == this.state.sectionId);
      if (section) {
        const scheduled = Object.keys(section.section_scheduled_subjects);
        if (section.subjects.length <= 1 && scheduled.length <= 1) {
          if (section.section_scheduled_subjects[scheduled[0]].scheduled === 0) {
            if (role === ROLE_TYPE.INSTRUCTOR) {
              const selectedCourseId = courseId || _get(courseList, '[0].id', '');
              url = `/schedule/course/${selectedCourseId}/section/${section.id}/section-info`;
              history.push(url);
            }
            return;
          }
        }
      }
    } catch (err) {
      console.log(`${err} in courseId = ${courseId} -- --`);
    }

    history.push(url);
  };

  redirectExternal = (url) => {
    getExternalLink(url).then((responseData) => {
      const link = responseData.response.data;
      window.open(link.url, '_blank');
    });
  };

  renderDropdown = () => {
    const { logout, courseId } = this.props;
    const profilePath = courseId ? `/profile/course/${courseId}` : '/profile';
    return (
      <DropdownWrap onClick={this.toggleDropdown}>
        <Link to={profilePath}>
          <DropdownItem>Profile</DropdownItem>
        </Link>
        <DropdownItem onClick={logout}>Logout</DropdownItem>
      </DropdownWrap>
    );
  };

  renderNavBarItem = (
    url,
    icon,
    dataTip,
    label,
    transform = true,
    hasSection = true,
    isExternal = false,
    isAdmin = false,
  ) => {
    const { history, courseId, sectionId, courseList } = this.props;
    const pathname = _get(history, 'location.pathname', '/');
    let transformUrl = url;

    if (transform && !isAdmin) {
      if (courseId !== null) {
        transformUrl += `${courseId}`;

        if (sectionId && hasSection) {
          transformUrl += `/section/${sectionId}`;
        }
      } else {
        const firstCourseId = _get(courseList, '[0].id', 1);

        transformUrl += `${firstCourseId}`;
      }
    }

    if (dataTip === 'My Library') {
      transformUrl = `${transformUrl}/library`;
    }

    const isActive = pathname.startsWith(url);

    if (isActive) {
      transformUrl = '#';
    }

    return (
      <NavBarItem
        onClick={() =>
          !isExternal ? this.redirectPage(transformUrl) : this.redirectExternal(url)
        }
        isActive={isActive}
      >
        <div>
          {dataTip === 'My Library' ? (
            <NavBarIcon
              src={`/images/${icon}`}
              data-tip={dataTip}
              data-for="tooltip-top-nav-bar"
            />
          ) : (
            <span className={icon} data-tip={dataTip} data-for="tooltip-top-nav-bar" />
          )}
          <label
            style={{
              display: 'block',
              color: 'white',
              fontSize: '11px',
              cursor: 'pointer',
              margin: '5px',
            }}
          >
            {label}
          </label>
        </div>
      </NavBarItem>
    );
  };

  renderUserIcon = (user, isDropdown) => {
    const initials = this.getInitials(user);
    return (
      <NavBarItem isActive={isDropdown}>
        <UserIconInitials
          initials={initials}
          data-tip="Profile"
          data-for="tooltip-top-nav-bar"
          onClick={this.toggleDropdown}
        >
          <p>{initials}</p>
        </UserIconInitials>
        {isDropdown && (
          <>
            <OverlayWrap isOpen={isDropdown} onClick={this.toggleDropdown} />
            {this.renderDropdown()}
          </>
        )}
      </NavBarItem>
    );
  };

  getInitials = ({ first_name: firstName, last_name: lastName, email }) => {
    const initials = firstName[0] + lastName[0];
    if (!initials) {
      return email[0].toUpperCase();
    }

    return initials;
  };

  renderDistrictAdminNavBar = () => {
    const { isDropdown } = this.state;
    const { user } = this.props;

    return (
      <NavBarGroup>
        <NavBarPrimary>
          {this.renderNavBarItem(
            '/admin/reports/generator',
            'chalktalk-icon-reports',
            '',
            'Admin Reports',
            true,
            false,
            false,
            true,
          )}
        </NavBarPrimary>
        <InfoUserGroup>
          <NavBarPrimary>{this.renderUserIcon(user, isDropdown)}</NavBarPrimary>
        </InfoUserGroup>
      </NavBarGroup>
    );
  };

  renderStudentNavBar = () => {
    const { isDropdown } = this.state;
    const { user, curriculumDetail } = this.props;

    return (
      <NavBarGroup>
        <NavBarPrimary>
          {this.renderNavBarItem(
            '/dashboard/course/',
            'chalktalk-icon-home',
            '',
            'Dashboard',
          )}
          {this.renderNavBarItem(
            '/lessons/course/',
            'chalktalk-icon-tech',
            '',
            'Lessons',
          )}
          {curriculumDetail.has_exam &&
            this.renderNavBarItem(
              '/test/course/',
              'chalktalk-icon-note-list',
              '',
              'Tests',
            )}
          {this.renderNavBarItem(
            '/schedule/course/',
            'chalktalk-calendar',
            '',
            'Calendar',
          )}
        </NavBarPrimary>
        <InfoUserGroup>
          <NavBarPrimary>
            {this.renderNavBarItem(
              'student-help-link',
              'chalktalk-icon-questionmark',
              'Help Articles',
              '',
              false,
              false,
              true,
            )}
            {this.renderNavBarItem(
              '/section-enroll',
              'chalktalk-course-plus',
              'Join section',
              '',
              false,
            )}
            {this.renderUserIcon(user, isDropdown)}
          </NavBarPrimary>
        </InfoUserGroup>
      </NavBarGroup>
    );
  };

  render() {
    const {
      user: { role },
      curriculumDetail,
      getCurriculumList,
    } = this.props;
    const { gettingCurriculums } = this.state;

    if (_isEmpty(curriculumDetail) && !gettingCurriculums) {
      this.setState({
        gettingCurriculums: true,
      });
      getCurriculumList();
    }

    if (!role) {
      return null;
    }

    return (
      <TopNavBarVertical>
        <ReactTooltip place="right" effect="solid" id="tooltip-top-nav-bar" />
        <LogoChalkTalk>
          <img src="/images/icon-v2/logo-chalktalk.png" alt="Logo ChalkTalk" />
        </LogoChalkTalk>
        {role === ROLE_TYPE.STUDENT ? (
          this.renderStudentNavBar()
        ) : role === ROLE_TYPE.DISTRICT_ADMIN ? (
          this.renderDistrictAdminNavBar()
        ) : (
          <TeacherNavBar
            state={this.state}
            props={this.props}
            renderNavBarItem={this.renderNavBarItem}
            renderUserIcon={this.renderUserIcon}
            closeCreateCourseForm={this.closeCreateCourseForm}
            openCreateCourseForm={this.openCreateCourseForm}
          />
        )}
      </TopNavBarVertical>
    );
  }
}

export default TopNavBar;
