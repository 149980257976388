import { connect } from 'react-redux';
import _get from 'lodash/get';
import UnitPracticeOverviewReview from '../../components/UnitPracticeOverviewReview';
import { actions as UserActivityActions, selectors as UserActivitySelectors } from '../../reducers/userActivities';
import { actions as SectionUnitActions, selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import { actions as PracticeActions, selectors as PracticeSelectors } from '../../reducers/practice';
import { selectors as UserSelectors } from '../../reducers/user';

const mapStateToProps = (state, { match, location }) => {
  const { params } = match;
  const { unitId, sectionId } = params;
  const query = new URLSearchParams(_get(location, 'search', ''));
  const assetTypeQuery = query.get('asset_type');
  const reviewId = query.get('review');
  return {
    assetTypeQuery,
    reviewId,
    isInitialized: PracticeSelectors.isInitialized(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    currentUser: UserSelectors.getCurrentUser(state),
    currentUserId: UserSelectors.getUserId(state),
    lessons: sectionUnitSelectors.getAllLessonOfUnit(state, unitId),
    isLoading: UserActivitySelectors.isGettingUserPracticeSessionActivities(state),
    isGettingLesson: sectionUnitSelectors.isGettingLesson(state),
    practiceList: UserActivitySelectors.getUserUnitPracticeSessionActivities(
      state,
      sectionId,
      unitId,
    ),
  };
};

const mapDispatchToProps = {
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  getAllPracticeSessionActivities: UserActivityActions.getAllPracticeSessionActivities,
  initializePracticeSession: PracticeActions.initializePracticeSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPracticeOverviewReview);
