import React from 'react';
import { useQuery } from 'react-query';
import { getSectionUnitMeta } from '@apis/sections';
import { useParams } from 'react-router-dom';
import {
  getLessonDetail,
  getLessonOER,
  getStandard,
  getLessonStandard,
} from '@apis/curriculum';
import {
  getLearnosityAdaptivePracticeQuestions,
  getLearnosityDistrictSelectedQuestions,
} from '@apis/practice';
import { useCourseById, useCurrentCourse } from '@reducers/courses/hooks';
import {
  convertQuestionToCamelCase,
  Question,
} from '@learnosityassessment/AssessmentServiceAPI';

import {
  EducationalResourceResponse,
  LessonDetailsResponseType,
  unitMetaDataResponse,
} from '../../Types';

export const useGetLessonDetail = (lessonGroupId?: string | number) => {
  const params = useParams();
  // Despite being labeled as 'lessonId' in the router params, it actually represents the lessonGroupId.
  const effectiveLessonGroupId = lessonGroupId || params.lessonId;

  const queryResponse = useQuery<LessonDetailsResponseType>(
    ['getLessonDetail', effectiveLessonGroupId, params.sectionId],
    async () => {
      const { response } = (await getLessonDetail(effectiveLessonGroupId)) as unknown as {
        response: { data: LessonDetailsResponseType };
      };

      return response?.data || {};
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!params.sectionId && !!effectiveLessonGroupId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse as { data: LessonDetailsResponseType };
};

export const useGetEducationalResources = () => {
  const { lessonId: lessonGroupId } = useParams();
  const queryResponse = useQuery<EducationalResourceResponse>(
    ['getLessonOER', lessonGroupId],
    async () => {
      const { response } = (await getLessonOER(lessonGroupId)) as unknown as {
        response: { data: EducationalResourceResponse };
      };

      return response?.data || [];
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!lessonGroupId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};

export const useGetStandards = () => {
  const { sectionId } = useParams();

  const queryResponse = useQuery<any>(
    ['getStandards'],
    async () => {
      const { response } = (await getStandard()) as unknown as {
        response: { data: any };
      };

      return response?.data || {};
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!sectionId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};

export const useGetSectionUnitMeta = () => {
  const { sectionId, subjectId } = useParams();

  const queryResponse = useQuery<unitMetaDataResponse>(
    ['getSectionUnitMeta', sectionId, subjectId],
    async () => {
      const { response } = (await getSectionUnitMeta(
        sectionId,
        subjectId,
      )) as unknown as {
        response: { data: unitMetaDataResponse };
      };

      return response?.data || {};
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!sectionId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};

export const useCurrentSubject = () => {
  const { subjectId } = useParams();
  const { data: sectionMeta } = useGetSectionUnitMeta();

  const currentSubject = React.useMemo(() => {
    if (!sectionMeta) {
      return undefined;
    }
    return sectionMeta?.find((subject) => subject.id === parseInt(subjectId, 10));
  }, [sectionMeta, subjectId]);

  return currentSubject;
};

export const useCurrentUnit = () => {
  const { unitId } = useParams();
  const subject = useCurrentSubject();

  const currentUnit = React.useMemo(() => {
    if (!subject) {
      return undefined;
    }
    return subject?.units?.find((unit) => unit.id === parseInt(unitId, 10));
  }, [subject, unitId]);
  return currentUnit;
};

export const useGetLessonStandard = () => {
  const { lessonId, sectionId } = useParams();
  const courseData = useCurrentCourse();
  const standardId = courseData?.syllabus ?? 0;

  const queryResponse = useQuery<any>(
    ['getLessonStandard', lessonId, standardId],
    async () => {
      const { response } = (await getLessonStandard(lessonId, standardId)) as unknown as {
        response: { data: any };
      };

      return response?.data || {};
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!sectionId && !!lessonId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};

export const useGetLearnosityAdaptivePracticeQuestions = (
  lessonId?: string | number,
  options?: {
    [key: string]: any;
  },
) => {
  const params = useParams();

  const effectiveLessonId = lessonId || params.lessonId;

  const queryResponse = useQuery<Array<Question>>(
    ['getLearnosityAdaptivePracticeQuestions', effectiveLessonId],
    async () => {
      const { response } = (await getLearnosityAdaptivePracticeQuestions(
        effectiveLessonId,
      )) as unknown as {
        response: { data: Array<Question> };
      };

      return response?.data?.map(convertQuestionToCamelCase) || [];
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!params.sectionId && !!effectiveLessonId,
      staleTime: 1000 * 60 * 60 * 24,
      ...options,
    },
  );

  return queryResponse;
};

export const useDistrictSelectedQuestions = () => {
  const { lessonId: lessonGroupId, courseId } = useParams();
  const courseDetails = useCourseById(courseId);
  const districtId = courseDetails?.organization?.district?.id;
  const queryResponse = useQuery<Question[]>(
    ['districtSelectedQuestions', lessonGroupId, districtId],
    async () => {
      const { response } = (await getLearnosityDistrictSelectedQuestions(
        districtId,
        lessonGroupId,
      )) as unknown as {
        response: { data: Question[] };
      };

      return response?.data?.map(convertQuestionToCamelCase) || {};
    },
    {
      suspense: true,
      enabled: !!lessonGroupId && !!districtId,
      staleTime: 1000 * 60 * 60,
    },
  );

  return queryResponse || {};
};
