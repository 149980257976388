/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import {
  NavBarItemCustome,
  NavBarName,
  NavBarItemHead,
  NavBarHeadText,
  NavBarStatusColumn,
  NavBarItemWrap,
  NavBarIconWrap,
  NavBarIcon,
  NavBarItemDateWrap,
  NavBarItemDay,
  NavBarItemMonth,
  ProgressBarWrap,
  ProgressSegment,
  ProgressSegmentItem,
  StatusLabel,
} from './SubNavBar.style';
import { EXAM_SESSION_TYPES, EXAM_LENGTH_TYPES, SESSION_EXAM_TYPE } from '../../utils/constants';

class TestSubNavItem extends React.PureComponent {
  goToTestDetail = () => {
    const { match, history, examItem } = this.props;
    const examSessionId = _get(examItem, 'id', '');
    const courseId = _get(match, 'params.courseId', '');
    const sectionId = _get(match, 'params.sectionId', '');

    history.push(`/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}`);
  }

  renderStatus = (isNotStarted, isStarted, rate) => {
    if (isStarted) return <StatusLabel>0% Completed</StatusLabel>;

    if (isNotStarted) return <StatusLabel />;

    return <StatusLabel>{`${rate}% Completed`}</StatusLabel>;
  }

  renderProgressBarItem = () => {
    const isCompled = false;

    return (
      <NavBarStatusColumn>
        <NavBarIconWrap isSingleItem isFirstItem={false} isLastItem={false}>
          {isCompled
            ? <NavBarIcon className="chalktalk-check-success" />
            : <NavBarIcon className="chalktalk-check-incomplete" />}
        </NavBarIconWrap>
      </NavBarStatusColumn>
    );
  }

  renderSessonProgress = (isNotStarted, isStarted, isInprogress, isCompleted, rate) => (
    <ProgressBarWrap>
      {this.renderStatus(isNotStarted, isStarted, rate)}
      {
        !!rate && (
          <ProgressSegment>
            <ProgressSegmentItem
              isNotStarted={isNotStarted}
              isStarted={isStarted}
              isInprogress={isInprogress}
              isCompleted={isCompleted}
              rate={rate}
            />
          </ProgressSegment>
        )
      }
    </ProgressBarWrap>
  )

  renderTestDate = () => {
    const { examItem } = this.props;
    const { start_date, end_date } = examItem;
    const examDate = moment(start_date, 'YYYY-MM-DD');
    const endDate = moment(end_date, 'YYYY-MM-DD');
    return (
      <NavBarItemDateWrap>
        <NavBarItemMonth>
          {`${examDate.format('MMM')}`}
        </NavBarItemMonth>
        <NavBarItemDay wide>
          {`${examDate.format('DD')} - ${endDate.format('DD')}`}
        </NavBarItemDay>
        <NavBarItemMonth>
          {`${examDate.format('YYYY')}`}
        </NavBarItemMonth>
      </NavBarItemDateWrap>
    );
  }

  render() {
    const { examItem, match } = this.props;
    const {
      session_type: sessionType = 0, length_type: lengthType, exam, start_date,
    } = examItem;
    const examDate = moment(start_date, 'YYYY-MM-DD');
    // console.log(examDate > moment());
    const title = `${EXAM_SESSION_TYPES.title[sessionType]} Test`;
    const lengthTittle = EXAM_LENGTH_TYPES.title[lengthType];
    const { exam_type: examType } = exam;
    const examTypeTitle = SESSION_EXAM_TYPE[examType];
    const topTitle = `${lengthTittle} - ${examTypeTitle}`;
    const { params } = match;
    const { examSessionId } = params;
    // console.log(title === 'Exit Test' && (examDate > moment()));
    return (
      <NavBarItemWrap onClick={this.goToTestDetail} isActive={examItem.id == examSessionId}>
        {this.renderProgressBarItem()}
        <NavBarItemCustome>
          <NavBarItemHead>
            <NavBarHeadText>
              {topTitle}
            </NavBarHeadText>
          </NavBarItemHead>
          <NavBarName>{title}</NavBarName>
        </NavBarItemCustome>
        {this.renderTestDate()}
      </NavBarItemWrap>
    );
  }
}

TestSubNavItem.propTypes = {
  examItem: PropTypes.object,
  history: PropTypes.any,
  match: PropTypes.any,
};

export default withRouter(TestSubNavItem);
