const cardHeaderContainerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const cardHeaderContentStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
};

const cardHeaderContentInnerStyle = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const cardHeaderNoContentTextStyle = {
  color: 'primary.main',
  textDecoration: 'underline',
  cursor: 'pointer',
};

export {
  cardHeaderContainerStyle,
  cardHeaderContentStyle,
  cardHeaderContentInnerStyle,
  cardHeaderNoContentTextStyle,
};
