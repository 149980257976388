import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { selectors as LessonAssignedActivitiesSelectors } from '@reducers/lessonAssignedActivities';
import { useSelector } from 'react-redux';
import {
  NavBarItemCustome,
  NavBarItemHead,
  NavBarName,
  NavBarItemWrap,
} from './SubNavBar.style';
import SessionDate from './components/SessionDate';
import ProgressBar from './components/ProgressBar';
import ProgressBarItem from './components/ProgressBarItem';

type UnitPracticeSubNavItemProps = {
  percent: number;
  name: string;
  unitId: string | number;
  subjectId: string | number;
};

const UnitPracticeSubNavItem = ({
  percent,
  name,
  unitId,
  subjectId,
}: UnitPracticeSubNavItemProps) => {
  const { courseId, sectionId, unitId: activeUnitId } = useParams();
  const history = useHistory();
  const redirectToUnitPracticePage = () => {
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice`,
    );
  };
  const isAssigned = useSelector((state) =>
    LessonAssignedActivitiesSelectors.getAssignStatusByUnitPractice(state, unitId),
  );
  const isActive =
    Number(activeUnitId) === unitId && history.location.pathname.includes('practice');

  return (
    <NavBarItemWrap
      id={isActive ? 'active-lesson' : ''}
      isActive={isActive}
      onClick={redirectToUnitPracticePage}
    >
      <ProgressBarItem />
      <NavBarItemCustome>
        <NavBarItemHead />
        <NavBarName>
          {`${name} Practice`}
          {isAssigned && <i className="chalktalk-error" />}
        </NavBarName>
        <ProgressBar rate={percent} />
      </NavBarItemCustome>
    </NavBarItemWrap>
  );
};

export default UnitPracticeSubNavItem;
