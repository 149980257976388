import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import AnswerShortText from '../AnswerShortText';
import { QUESTION_TYPES, REPORT_TYPES } from '../../utils/enums';
import {
  QuestionHeaderWrap,
  QuestionProgress,
  QuestionContentWrap,
  QuestionAnswer,
  QuestionAction,
  QuestionOrderWrapper,
  QuestionOrderNumber,
  Wrapper,
  BottomButtons,
  QuestionDesc,
} from '../QuestionItem/QuestionItem.style';
import { Button, FeedbackContainer, FeedbackIcon } from '../Generals/stylesheets/Button.style';
import { OnlineQuestionWrapper } from './OnlinePracticeContent.style';
import { error as ToastError } from '../../utils/toast';
import MathJaxItem from '../MathJaxContainer';
import { GRAMMAR_SUBJECT_IDS } from '../../utils/constants';

export default class PracticeQuestionItem extends Component {
  static propTypes = {
    questionSelected: PropTypes.object,
    totalQuestions: PropTypes.number,
    order: PropTypes.number,
    index: PropTypes.number,
    answer: PropTypes.object,
    startTime: PropTypes.any,
    isSubmittingAnswer: PropTypes.bool,
    practiceSessionId: PropTypes.any,
    shouldDisplayGrammarHelper: PropTypes.bool,
    chooseAnswer: PropTypes.func.isRequired,
    answerPracticeQuestion: PropTypes.func.isRequired,
    prevQuestion: PropTypes.func,
    openReportErrorModal: PropTypes.func,
    questionId: PropTypes.number,
    subjectId: PropTypes.string,
  };


  selectAnswer = (value) => {
    const { answer, chooseAnswer, questionSelected } = this.props;
    const { id } = questionSelected;
    const currentResponseTime = _get(answer, 'response_time', 0);

    const payload = {
      exam_question_id: id,
      value,
      response_time: currentResponseTime,
      delete: false,
    };

    chooseAnswer(id, payload);
  };

  calculateDuration = () => {
    const {
      answer, chooseAnswer, questionSelected, startTime, index,
    } = this.props;
    const { id } = questionSelected;

    const currentResponseTime = _get(answer, 'response_time', 0);

    const duration = moment.duration(moment().diff(startTime));
    const responseTime = duration.seconds();
    const calculatedAnswer = {
      value: null,
      exam_question_id: id,
      count: index + 1,
      delete: false,
      ...answer,
      response_time: responseTime + currentResponseTime,
    };

    chooseAnswer(id, calculatedAnswer);
    return calculatedAnswer;
  };

  submitAnswer = () => {
    const { answerPracticeQuestion, practiceSessionId } = this.props;
    const answer = this.calculateDuration();

    if (!answer || _isNil(answer.value)) {
      ToastError("You haven't completed the question");
      return;
    }

    answerPracticeQuestion(practiceSessionId, answer);
  };

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.QUESTION, questionId);
  };

  renderAnswer = () => {
    const { questionSelected, answer } = this.props;
    const answerType = _get(questionSelected, 'question.question_format');

    switch (answerType) {
      case QUESTION_TYPES.SHORT_ANSWER: {
        return (
          <AnswerShortText
            selectAnswer={this.selectAnswer}
          />
        )
      }

      case QUESTION_TYPES.GRID_IN: {
        // TODO Refactor code
        const value = _get(answer, 'value', '');
        const transformValue = `${value}____`.substring(0, 4);
        const selectedValueArr = transformValue.split('');
        const score = _get(questionSelected, 'score', {});
        return (
          <AnswerGridChoice
          score={score}
          selectedValue={selectedValueArr}
          selectAnswer={this.selectAnswer}
          />
          );
        }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default: {
        const choices = _get(questionSelected, 'question.choices', []);
        const value = _get(answer, 'value', '');
        const values = Array.isArray(value) ? value : [value];
        const orderChoice = _orderBy(choices, 'order', 'asc');
        console.log({ values, orderChoice });
        return (
          <AnswerMultiChoice
            userChoice={values}
            choices={orderChoice}
            selectAnswer={this.selectAnswer}
          />
        );
      }
    }
  };

  renderButtonGroup = () => {
    const { isSubmittingAnswer, index, prevQuestion } = this.props;
    const shouldRenderPrevButton = index > 0;

    return (
      <React.Fragment>
        <BottomButtons>
          {shouldRenderPrevButton && (
            <React.Fragment>
              <Button blue textUppercase mobDesk onClick={prevQuestion}>
                {'< Previous'}
              </Button>
            </React.Fragment>
          )}
          <Button id="AdaptivePractice-QuestionSubmit"
            blue
            textUppercase
            onClick={this.submitAnswer}
            disabled={isSubmittingAnswer}
            mobDesk
          >
            Submit
          </Button>
        </BottomButtons>
      </React.Fragment>
    );
  };

  shouldDisplayHint = () => {
    const { shouldDisplayGrammarHelper, subjectId } = this.props;
    if (shouldDisplayGrammarHelper && Object.values(GRAMMAR_SUBJECT_IDS).includes(subjectId)) {
      return true;
    } else return false;
  };
  renderHintText = () => {
    let subjectText = '';
    if (this.props.subjectId == GRAMMAR_SUBJECT_IDS.SAT_WRITING) {
        subjectText = 'SAT Writing';
    } else if (this.props.subjectId ==  GRAMMAR_SUBJECT_IDS.ACT_ENGLISH) {
        subjectText = 'ACT English';
    }

    return (
      <QuestionOrderWrapper>
        <span>
          Hint: Most {subjectText} questions can be answered by looking at the referenced
          sentence without reading the whole passage{' '}
        </span>
      </QuestionOrderWrapper>
    );
  };

  render() {
    const {
      questionSelected,
      totalQuestions,
      index,
      order,
      shouldDisplayGrammarHelper,
    } = this.props;
    const getQuestionContent = _get(
      questionSelected,
      'question.prompt.text',
      '',
    );
    const shouldDisplayHint = this.shouldDisplayHint()

    return (
      <OnlineQuestionWrapper>
        {shouldDisplayHint && (this.renderHintText())}
        <QuestionHeaderWrap>
          <QuestionProgress>
            Question
            <span>{`${index + 1} / ${totalQuestions}`}</span>
          </QuestionProgress>
          <FeedbackContainer onClick={this.openReportErrorModal}>
            <FeedbackIcon src="/images/feedback.png" />
          </FeedbackContainer>
        </QuestionHeaderWrap>
        <QuestionContentWrap>
          <Wrapper>
            {shouldDisplayGrammarHelper && (
              <QuestionOrderWrapper>
                <span>This question refers to </span>
                <QuestionOrderNumber>{order}</QuestionOrderNumber>
              </QuestionOrderWrapper>
            )}
            <QuestionDesc>
              <MathJaxItem data={getQuestionContent} />
            </QuestionDesc>
            <QuestionAnswer>{this.renderAnswer()}</QuestionAnswer>
          </Wrapper>
          <QuestionAction>{this.renderButtonGroup()}</QuestionAction>
        </QuestionContentWrap>
      </OnlineQuestionWrapper>
    );
  }
}
