import React from 'react';
import { PracticeTimeWrap } from './PracticeTimer.style';
import { useTimer, useOnTimeOut } from './PracticeTimer.hook';

type Props = {
  startTime: Date;
  duration: number;
  onTimeOut: () => void;
};

const PracticeTimer = ({ startTime, duration, onTimeOut }: Props) => {
  const [isTimeClockClose, setIsTimeClockClose] = React.useState(false);
  const time = useTimer({ startTime, duration });
  useOnTimeOut({ onTimeOut, time }, [time]);
  return (
    <PracticeTimeWrap
      onClick={() => setIsTimeClockClose(!isTimeClockClose)}
      isClose={isTimeClockClose}
    >
      <i className={isTimeClockClose ? 'chalktalk-icon-pre' : 'chalktalk-icon-next'} />
      <i className="chalktalk-clock" />
      <span>{time}</span>
    </PracticeTimeWrap>
  );
};

export default React.memo(PracticeTimer);
