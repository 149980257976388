import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import Box from '@components/Atoms/Box';
import LessonTreeSelector from '@components/LessonTreeSelector';
import {
  useContentFilterContext,
  useContentFilterLessonContext,
  useContentFilterSkillContext,
  useContentFilterStandardContext,
} from '../ContentFilterProvider';
import HighlightSelector from './HighlightSelector';
import { buildTags } from '../utils';

const popOverSlotProps = {
  paper: {
    sx: {
      width: 'auto',
      maxHeight: 400,
      mt: 0.5,
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
  },
};

const LessonPopover = ({ open, anchor, onClose, disabled }) => {
  const { subjects } = useContentFilterContext();
  const { selectedLessons, recommendedLessons, setSelectedLessons, getLessonById } =
    useContentFilterLessonContext();

  const { selectedStandards, getStandardById } = useContentFilterStandardContext();
  const { selectedSkills, getSkillById } = useContentFilterSkillContext();
  const [partialLessons, setPartialLessons] = useState({});

  const getRecommendedLessonObjects = () =>
    Object.keys(recommendedLessons)
      .map(Number)
      .filter((lessonId) => !selectedLessons.includes(lessonId))
      .map(getLessonById)
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name));

  const handleSelectRecommended = (lessonId: number) => {
    setSelectedLessons([...selectedLessons, lessonId]);
  };

  const getLessonTags = useCallback(
    () =>
      buildTags('lesson', {
        recommend: Object.keys(recommendedLessons).reduce((acc, lessonId) => {
          if (!selectedLessons.includes(parseInt(lessonId, 10))) {
            acc[lessonId] = recommendedLessons[lessonId];
          }
          return acc;
        }, {}),
        partial: partialLessons,
      }),
    [partialLessons, recommendedLessons, selectedLessons],
  );

  useEffect(() => {
    if (selectedLessons.length === 0) {
      setPartialLessons({});
      return;
    }
    const partiallySelectedLessons = {};
    const lessons = selectedLessons.map(getLessonById).filter(Boolean);
    // adding a timeout so we wait for the standards and skills to be autoselected
    const timeout = setTimeout(() => {
      lessons.forEach((lesson) => {
        if (!lesson) {
          return;
        }

        const unselectedStandards = lesson.standards
          .filter((standardId) => !selectedStandards.includes(standardId))
          .map(getStandardById)
          .filter(Boolean);

        const unselectedSkills = lesson.skills
          .filter((skillId) => !selectedSkills.includes(skillId))
          .map(getSkillById)
          .filter(Boolean);

        if (
          !(lesson.id in partiallySelectedLessons) &&
          (unselectedStandards.length > 0 || unselectedSkills.length > 0)
        ) {
          partiallySelectedLessons[lesson.id] = {
            skills: [],
            standards: [],
          };
        }

        unselectedStandards.forEach((standard) => {
          if (!partiallySelectedLessons[lesson.id].standards.includes(standard?.code)) {
            partiallySelectedLessons[lesson.id].standards.push(standard?.code);
          }
        });

        unselectedSkills.forEach((skill) => {
          if (!partiallySelectedLessons[lesson.id].skills.includes(skill?.name)) {
            partiallySelectedLessons[lesson.id].skills.push(skill?.name);
          }
        });
      });

      setPartialLessons(partiallySelectedLessons);
    }, 500);

    return () => clearTimeout(timeout);
  }, [
    getLessonById,
    selectedLessons,
    selectedStandards,
    selectedSkills,
    getStandardById,
    getSkillById,
  ]);

  const getNodeBackgrounds = useCallback(() => {
    const backgrounds = {};

    Object.keys(partialLessons)
      .map(Number)
      .forEach((lessonId) => {
        backgrounds[lessonId] = `
                repeating-linear-gradient(
                  -45deg,
                  rgba(255, 255, 255, 1),  /* Light white stripe */
                  rgba(255, 255, 255, 1) 10px,
                  rgba(200, 200, 200, 0.3) 10px,  /* Soft gray stripe */
                  rgba(200, 200, 200, 0.3) 20px
                )
              `;
      });

    return backgrounds;
  }, [partialLessons]);

  const getIndeterminateLessons = useCallback(
    () => Object.keys(partialLessons).map(Number),
    [partialLessons],
  );

  return (
    <Popover
      id="lesson-filter-popover"
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={popOverSlotProps}
    >
      <Box sx={{ p: 1, width: 'auto', minWidth: 'auto', maxWidth: '60rem' }}>
        <Suspense fallback={<Box>Loading...</Box>}>
          <HighlightSelector
            items={getRecommendedLessonObjects()}
            getItemId={(lesson) => lesson.id}
            getItemLabel={(lesson) => lesson.name}
            primaryText="Based on the selected Standards/Skills, here are ChalkTalk's recommended Lessons:"
            secondaryText="Select Lesson(s) to see the recommended Standards and Skills:"
            onSelect={(lesson) => handleSelectRecommended(Number(lesson.id))}
            disabled={disabled}
            tags={getLessonTags()}
          >
            <LessonTreeSelector
              subjectRestriction={subjects.map((subject) => subject.id)}
              selected={selectedLessons}
              onChange={(lessons: number[]) => {
                setSelectedLessons(lessons);
              }}
              onClose={onClose}
              disabled={disabled}
              open={selectedLessons.length === 0 && recommendedLessons.length === 0}
              tags={getLessonTags()}
              background={getNodeBackgrounds()}
              indeterminate={getIndeterminateLessons()}
            />
          </HighlightSelector>
        </Suspense>
      </Box>
    </Popover>
  );
};

export default LessonPopover;
