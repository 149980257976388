import { connect } from 'react-redux';
import {
  actions as CourseStudentActions, selectors as CourseStudentSelectors,
} from '../../reducers/courseStudents';
import StudentInfoItem from '../../components/CourseManagementStudents/StudentInfoItem';

const mapStateToProps = state => ({
  isChangePasswordSuccess: CourseStudentSelectors.isChangePasswordSuccess(state),
});

const mapDispatchToProps = {
  sendResetPassword: CourseStudentActions.resetPassword,
  resetStudentPassword: CourseStudentActions.teacherChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentInfoItem);
