import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { CustomDropdown, Option } from './CustomDropdown';

export interface BaseSelectorProps {
  /** The label to display in the selector */
  label: string;
  /** List of available options */
  options: Option[];
  /** Whether multiple selection is allowed */
  isMulti?: boolean;
  /** Optional list of options to exclude */
  excludeOptions?: string[];
  /** Callback for selection changes */
  onChange?: (selected: string[]) => void;
  /** Whether to show Select All option (only applies when isMulti is true) */
  allowSelectAll?: boolean;
  /** Whether the selector is disabled */
  disabled?: boolean;
  /** The size of the selector */
  size?: 'small' | 'medium';
  /** Initial selected values */
  value?: string[];
  /** Width of the selector button */
  width?: string | number;
  /** Tooltip text to show on hover */
  tooltip?: string;
  /** Function to format the option label */
  optionLabel?: (option: Option) => string;
  /** Function to format the selected label */
  selectedLabel?: (selected: Option) => string;
  /** Whether to show the label on top like a fieldset */
  topLabel?: boolean;
}

export const BaseSelector: React.FC<BaseSelectorProps> = ({
  label,
  options: initialOptions,
  isMulti = false,
  excludeOptions = [],
  onChange,
  allowSelectAll = true,
  disabled = false,
  size = 'medium',
  value: externalValue,
  width = '100%',
  tooltip,
  optionLabel,
  selectedLabel,
  topLabel,
}) => {
  const [selected, setSelected] = useState<string[]>([]);

  // Filter out excluded options
  const options: Option[] = initialOptions.filter(
    (option) => !excludeOptions.includes(option.value),
  );

  const handleChange = (newSelected: string[]) => {
    setSelected(newSelected);
    onChange?.(newSelected);
  };

  useEffect(() => {
    if (externalValue) {
      setSelected(externalValue);
    }
  }, [externalValue]);

  const selector = (
    <Box sx={{ width }}>
      <CustomDropdown
        label={label}
        options={options}
        value={selected}
        onChange={handleChange}
        multiple={isMulti}
        allowSelectAll={allowSelectAll}
        disabled={disabled}
        size={size}
        width={width}
        optionLabel={optionLabel}
        selectedLabel={selectedLabel}
        topLabel={topLabel}
      />
    </Box>
  );

  return tooltip ? (
    <Tooltip
      title={tooltip}
      arrow
      placement="top"
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '0.85rem',
            maxWidth: '300px',
            padding: '8px 12px',
            lineHeight: 1.4,
          },
        },
      }}
    >
      <Box sx={{ width }}>{selector}</Box>
    </Tooltip>
  ) : (
    selector
  );
};
