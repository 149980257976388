import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

export const CHALKTALK = 'chalktalk';
export const MY_QUESTIONS = 'my-questions';
const ItemBankToggleButtonGroup = ({ setItemBankKey }) => {
  const [itemBank, setItemBank] = React.useState(CHALKTALK);

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: string) => {
    setItemBank(value);
    setItemBankKey(value);
  };
  return (
    <ToggleButtonGroup
      color="primary"
      value={itemBank}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      size="small"
      sx={{
        height: '2.5rem',
      }}
    >
      <ToggleButton value={CHALKTALK}>Chalktalk Questions</ToggleButton>
      <ToggleButton value={MY_QUESTIONS}> My Questions</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ItemBankToggleButtonGroup;
