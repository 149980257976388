import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import LessonCompleteDialog from './LessonCompleteDialog';
import { LessonCompleteContainer, LessonCompleteStatus } from './LessonDetailTab.style';

const LessonComplete = ({
  isCompletedLesson,
  updateSectionComplete,
  updateSectionUnComplete,
  payload,
  sectionId,
  onLessonChanged,
}) => {
  const history = useHistory();
  const [timeUp, setTimeUp] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [seconds, setSeconds] = useState(300);
  const newRouteRef = useRef(null);
  const isCompletingInBlock = useRef(false);

  const onUnComplete = () => {
    if (isCompletedLesson) {
      updateSectionUnComplete(sectionId, payload);
      onLessonChanged(payload.lesson, false);
    }
  };

  const onComplete = () => {
    if (!isCompletedLesson) {
      updateSectionComplete(sectionId, payload);
      onLessonChanged(payload.lesson, true);
    }
  };
  const unblockRef = useRef(
    history.block((location) => {
      newRouteRef.current = location.pathname;
      if (location.pathname === '/') {
        unblockRef.current();
        return true;
      }
      if (timeUp && !isCompletedLesson && !isCompletingInBlock.current) {
        isCompletingInBlock.current = true;
        onComplete();

        setShowPrompt(true);
        return false;
      }
      unblockRef.current();
      return true;
    }),
  );

  useEffect(() => {
    let interval = null;
    if (isCompletedLesson) {
      return;
    }
    if (seconds && !isCompletedLesson) {
      interval = setInterval(() => {
        setSeconds((preValSeconds) => preValSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      clearInterval(interval);
      setTimeUp(true);
    }
    return () => clearInterval(interval);
  }, [seconds, isCompletedLesson]);

  useEffect(
    () => () => {
      unblockRef.current();
    },
    [],
  );

  const toggleComplete = () => {
    if (!isCompletedLesson) {
      onComplete();
    } else {
      onUnComplete();
    }
  };

  const onCancel = () => {
    if (unblockRef) {
      unblockRef.current();
      history.push(newRouteRef.current);
    }
    setShowPrompt(false);
  };

  const handleConfirm = () => {
    setShowPrompt(false);
    onUnComplete();
    if (unblockRef) {
      unblockRef.current();
      history.push(newRouteRef.current);
    }
  };

  return (
    <>
      <LessonCompleteContainer onClick={toggleComplete}>
        I taught some of these lesson slides
        <LessonCompleteStatus isCompleted={isCompletedLesson} />
      </LessonCompleteContainer>
      <LessonCompleteDialog
        isOpen={showPrompt}
        onConfirm={handleConfirm}
        onClose={onCancel}
      />
    </>
  );
};

export default LessonComplete;
