import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TooltipWrap,
  TooltipDate,
  TooltipValue,
} from '../DashboardAttendanceReport/DashboardAttendanceReport.style';

export default class CustomTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    payload: PropTypes.array,
    label: PropTypes.string,
    active: PropTypes.bool,
  }

  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      const { value } = payload[0].payload;

      return (
        <TooltipWrap>
          <TooltipDate>{label}</TooltipDate>
          <TooltipValue>{`Value: ${value}%`}</TooltipValue>
        </TooltipWrap>
      );
    }

    return null;
  }
}
