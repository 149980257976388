import styled, { css } from 'styled-components';
import { CALENDAR_EVENT_TYPE } from '../../utils/constants';

export const EventDetail = styled.div`
  padding: 0.5em;
  cursor: pointer;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.type === CALENDAR_EVENT_TYPE.LESSONS && css`
    color: #000;
    ${props => props.math && css`
      background-color: #fff0df;
      border-left: 1em solid #ff9e16;
    `}
    ${props => props.science && css`
      background-color: #fff4cc;
      border-left: 1em solid #ffc700;
    `}
    ${props => props.other && css`
      background-color: #ded7d7;
      border-left: 1em solid #453835;
    `}
    ${props => props.isPractice && css`
      background-color: #ded7d7;
      border-left: 1em solid #453835;
    `}
  `}
  ${props => props.type === CALENDAR_EVENT_TYPE.REVIEWS && css`
    background: #c8d8e1;
    border-left: 1em solid #0fb0cc;
  `}
  ${props => props.type === CALENDAR_EVENT_TYPE.EXAMS && css`
    background: #b3c2ac;
    border-left: 1em solid #68865a;
  `}
`;

export const EventContent = styled(EventDetail)`
  margin-bottom: .5em;
`;

export const EventContentWrap = styled.div`
  position: relative;
`;
export const EventTitle = styled.div`
  font-size: 0.8em;
  color: #000;
`;
export const CalendarWrap = styled.div`
  font-size: ${props => props.theme.fontSize.SmallFontSize};

  .rbc-calendar {
    height: 100vh;
  }

  @media (max-width: 768px) {
    & {
      height: 100%;
      width: 100%;
      position: absolute;
    }

    &>.rbc-calendar {
      display: flex;
    }
  }

  /* header talbe */
  .rbc-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.theme.fontSize.HeadingH5FontSize};
  }
  .rbc-row {
    flex: 0 0 5em;
    min-height: 2.25em !important;
  }

  /* content table */
  .rbc-day-bg {
    &:hover {
      background-color: ${props => props.theme.colorStyled.ColorBtnDark};
    }
  }
  .rbc-timeslot-group {
    position: relative;
    z-index: 1;
    &:hover {
      background-color: ${props => props.theme.colorStyled.ColorBtnDark};
    }
  }
  .rbc-today {
    background-color: ${props => props.theme.colorStyled.ColorBtnDark};
  }

  /* event content */
  .rbc-event {
    background-color: ${props => props.theme.colorStyled.ColorBgDefault};
    border-color: ${props => props.theme.colorStyled.ColorBgDefault};
    outline: none;
    z-index: 2;
  }

  .rbc-date-cell {
    text-align: center;

  }

  .rbc-month-view {
    height: auto;
  }

  /* drop and drap event content */
  .rbc-addons-dnd {
    .rbc-addons-dnd-row-body {
      position: relative;
    }
    .rbc-addons-dnd-drag-row {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }


    .rbc-addons-dnd-over {
      background-color: rgba(
        red(rgba(0,0,0, .10)),
        green(rgba(0,0,0, .10)),
        blue(rgba(0,0,0, .10)),
        .3
      );
    }

    .rbc-event {
      transition: opacity 150ms;
      &:hover {
        .rbc-addons-dnd-resize-ns-icon, .rbc-addons-dnd-resize-ew-icon { display: block; }
      }
    }

    .rbc-addons-dnd-dragged-event {
      opacity: 0;
    }

    &.rbc-addons-dnd-is-dragging .rbc-event:not(.rbc-addons-dnd-dragged-event):not(.rbc-addons-dnd-drag-preview) {
      opacity: .50;
    }

    .rbc-addons-dnd-resizable {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .rbc-addons-dnd-resize-ns-anchor {
      width: 100%;
      text-align: center;
      position: absolute;
      &:first-child {
        top: 0;
      }
      &:last-child {
        bottom: 0;
      }
      .rbc-addons-dnd-resize-ns-icon {
        display: none;
        border-top: 3px double;
        margin: 0 auto;
        width: 10px;
        cursor: ns-resize;
      }
    }

    .rbc-addons-dnd-resize-ew-anchor {
      position: absolute;
      top: 4px;
      bottom: 0;
      &:first-child {
        left: 0;
      }
      &:last-child {
        right: 0;
      }
      .rbc-addons-dnd-resize-ew-icon {
        display: none;
        border-left: 3px double;
        margin-top: auto;
        margin-bottom: auto;
        height: 10px;
        cursor: ew-resize;
      }
    }
  }
`;

export const CustomToolbarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.25em 0;
  padding-right: 2rem;
  padding-left: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2.25em 1em;
    & > div:nth-child(2) {
      padding: 1em 0;
    }
  }

  @media (min-width: 960px) and (max-width: 1024px ) {
    flex-direction: column;
    & > div:nth-child(2) {
      padding: 1em 0;
    }
  }

  background-color: #ffffff;
`;

export const CustomToolbarLeft = styled.div`
    align-items: center;
    display: flex;
    justify-content: start;
    margin: 0 0.15em;
    ${({ variant2 }) => variant2 && css`
      border-radius: 3em;
    `}
  `;

export const CustomToolbarRight = styled.div`
  display: flex;
  justify-content: center;
`;

export const CustomToolbarWrapMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f7fb;
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
  padding: 2rem 1rem;
  
  @media (max-width: 768px) {
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 480px) {
    justify-content: center;
    align-items: center;
  }
`;

export const CustomToolbarButton = styled.button`
  background: ${props => props.theme.colorStyled.ColorWhite};
  border: 0px solid #ebebeb;
  font-size: ${props => props.theme.fontSize.LargerFontSize};
  padding: 0.65em 1.5em;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  font-weight: bold;
  ${({ right }) => right && css`
    border-top-right-radius: 0.325em;
    border-bottom-right-radius: 0.325em;
  `}
  ${({ left }) => left && css`
    border-top-left-radius: 0.325em;
    border-bottom-left-radius: 0.325em;
  `}
  ${({ icon }) => icon && css`
    border: none;
    background: transparent;
    font-weight: bold;
    font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
    i {
      padding: 0 5px;
    }
  `}
  ${({ variant2 }) => variant2 && css`
      background-color: #ffe3c0;
      border-radius: 2.25em;
      margin: 0 0.15em;
    `}
  ${({ noPadding }) => noPadding && css`
    padding: 0px;
  `}

   ${({ active }) => active && css`
    background: #ffe3c0;
  `}
  ${({ circle }) => circle && css`
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 0;
    background: #ffe3c0;
  `}
  ${({ leftSquare }) => leftSquare && css`
    border-top-left-radius:20%;
    border-bottom-left-radius: 20%;
    width: 20px;
    height: 30px;
    margin-left: 2px;
    padding: 0;
    background: #ffe3c0;
    text-align: end;
  `}
  ${({ rightSquare }) => rightSquare && css`
    border-top-right-radius:20%;
    border-bottom-right-radius: 20%;
    width: 20px;
    height: 30px;
    padding: 0;
    text-align: start;
    background: #ffe3c0;
  `}

  ${({ noBorder }) => noBorder && css`
  border-radius: 0px;
`}
  ${({ leftSideBarSchedule }) => leftSideBarSchedule && css`
    background: transparent;
    width: 100%;
    text-align: left;
    padding: 0;
    font-weight: normal;
    &:hover {
      color : ${props => props.theme.colorStyled.ColorPrimaryBlue};
    }
  `}
  ${({ filterbutton }) => filterbutton && css`
    height: 30px;
    width: 30px;
    padding: 7px 30px 3px 16px;
    img {
      width: 15px;
    }
    &:hover {
      color : ${props => props.theme.colorStyled.ColorPrimaryBlue};
    }

   
  `}

  ${({ rescheduleButton }) => rescheduleButton && css`
    font-weight: normal;
    font-size: 12px;
    i {
      margin-right: 6px;
    }
  `}


  ${({ editModeButton }) => editModeButton && css`
    padding: 0px 14px 0px 14px;
    height: 29px;
    font-weight: normal;
    font-size: 12px;
    img {
      width: 10px;
      margin-right: 6px;
    }
  `}
`;

export const CustomToolbarTitle = styled.div`
  font-size: ${props => props.theme.fontSize.HeadingH3FontSize};
  font-family: ${props => props.theme.fontFamily.MontserratSemiBold};
  white-space: nowrap;
  margin: 0 1em;
  line-height: 1.2em;
  small {
    display: block;
    clear: both;
    font-size: 10px;
    text-align: center;
  }
  ${({ lableText }) => lableText && css`
    height: 30px;
    background-color: #ffe3c0;
    width: 7em;
    margin: 0 3px;
    text-align: center;
    padding-top: 7px;
    border-radius: 1em;
  `}
  @media (max-width: 768px) {
    & {
      white-space: break-spaces;
      margin: 0;
      text-align: center;
    }
  }
`;

export const CustomToolbarMainTitle = styled.div`
  font-size: ${props => props.theme.fontSize.HeadingH3FontSize};
  font-family: 'MontserratSemiBold';
  white-space: nowrap;
  position: absolute;
  left: 1rem;
  @media (max-width: 768px) {
    position: relative;
    padding: 1em;
    left: 0;
  }
`;

// LONG HOANG'S PROPERTY
// DO NOT TOUCH

// A F*CKING MONTH
// TWO WEEK

export const MonthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-width: fit-content;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 1px 1px, rgba(9,30,66,0.31) 0px 0;
  padding-bottom: 50px;
`;

export const MonthRow = styled.div`
  display: flex;
  flex-basis: 50%;
  height: 50%;
  overflow: hidden;
  &:first-child {
    border-top: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
`;

export const MonthItem = styled.div`
  flex: 0 0 calc(100% / 7);
  border-right: none;
  border-top: none;
  min-width: 80px;
  &:last-child {
    border-right: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
  ${props => props.disabled && css`
    background: #eee;
  `}
`;

export const MonthItemHeader = styled.div`
  text-align: center;
  height: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
`;

export const MonthItemHeaderDate = styled.div`
  margin: .25em 0em;
  padding: .25em .5em;
  border-radius: .325em;
  font-size: ${props => props.theme.fontSize.HeadingH6FontSize};
  font-family: 'MontserratSemiBold';
  &:hover {
    background: #f1f3f4;
  }
  ${props => props.isToday && css`
    background: ${props.theme.colorStyled.ColorPrimaryBlue};
    color: ${props.theme.colorStyled.ColorWhite};
    &:hover {
      background: ${props.theme.colorStyled.ColorBgDefault};
    }
  `}
`;

export const MonthItemContent = styled.div`
  padding: 0em;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 2px;

  ::-webkit-scrollbar {
    width: 15px;
    padding: 0 2px;
    margin-left: 2px;
  }

  ::-webkit-scrollbar-track {
    border: 2px solid #e0e0e0;
    border-radius: 15px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  & > div:last-child > div {
    margin-bottom: 0;
  }
`;

export const MonthEventContent = styled(EventDetail)`
  margin-bottom: .5em;
  min-height: 50px;
  max-height: 70px;
`;

export const InfoModalWrap = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
`;

export const InfoModalItem = styled.div`
  position: absolute;
  // bottom: -18em;
  left: 50%;
  border: none;
  background: rgb(255, 255, 255);
  color: ${props => props.theme.colorStyled.ColorPrimary};
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 15px 30px 20px;
  width: calc(100% - 30px);
  max-width: 480px;
  margin: 0px auto;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 15px 0px #dbdbdb;
  z-index: 1;
  .chalktalk-close {
    font-size: ${props => props.theme.fontSize.LargerFontSize};
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    margin-left: auto;
    &:hover {
      opacity: .7;
    }
  }
`;

export const InfoEventName = styled.input`
  font-size: ${props => props.theme.fontSize.HeadingH1FontSize};
  outline: none;
  width: 100%;
  padding: .25em 0em;
  border: 1px solid transparent;
  margin-top: .5em;
  margin-bottom: .25em;
  transition: all 300ms ease;
  &:focus {
    padding: .25em .5em;
    border-radius: .325em;
    border: 1px solid #ccc;
  }
`;

export const InfoEventFormWrapper = styled.div`
  margin-bottom: 1.071em;
  @media (max-width: 768px) {
    margin-top: 5em;
  }
`;

export const InfoEventDate = styled.div`
  font-family: 'MontserratRegular';
  font-size: ${props => props.theme.fontSize.LargerFontSize};
`;

export const CalendarViewWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

export const MonthHeaderRow = styled.div`
  display: flex;
  overflow: hidden;
  border-top: 1px solid #ccc;
  background-color: white;
`;

export const MonthHeaderItem = styled.div`
  flex: 0 0 calc(100% / 7);
  border-right: none;
  border-top: none;
  &:last-child {
    border-right: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
`;

export const MonthHeaderItemContent = styled.div`
  padding: 1em;
  text-align: center;
  font-size: 12px;
  font-family: 'MontserratLight';

`;

// TWO WEEK

export const TwoWeekWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-width: fit-content;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 1px 1px, rgba(9,30,66,0.31) 0px 0;
  padding-bottom: 85px;
`;

export const TwoWeekRow = styled.div`
  display: flex;
  flex-basis: 50%;
  height: 50%;
  overflow: hidden;
  &:first-child {
    border-top: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
`;

export const TwoWeekItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 80px;
  border-right: none;
  border-top: none;
  &:last-child {
    border-right: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
  ${props => props.disabled && css`
    background: #eee;
  `}
`;

export const TwoWeekItemHeader = styled.div`
  text-align: center;
  height: 6em;
  min-height: 6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  cursor: pointer;
`;

export const TwoWeekItemHeaderDate = styled.div`
  margin: .25em 0em;
  padding: .25em .5em;
  border-radius: .325em;
  font-size: ${props => props.theme.fontSize.HeadingH6FontSize};
  font-family: 'MontserratSemiBold';
  color: #999;
  ${props => props.isToday && css`
    background: ${props.theme.colorStyled.ColorPrimaryBlue};
    color: ${props.theme.colorStyled.ColorWhite};
  `}
`;

export const TwoWeekItemHeaderDay = styled.div`
  text-transform: uppercase;
  color: #000000;

`;

export const TwoWeekItemContent = styled.div`
  padding: 0em;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-right: 2px;

  ::-webkit-scrollbar {
    width: 15px;
    padding: 0 2px;
    margin-left: 2px;
  }

  ::-webkit-scrollbar-track {
    border: 2px solid #e0e0e0;
    border-radius: 15px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  & > div:last-child > div {
    margin-bottom: 0;
  }
`;

// ONE WEEK
export const WeekWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 0px 1px, rgba(9,30,66,0.31) 0px 0;
  height: 90vh !important;
  padding-bottom: 50px;
`;

export const WeekRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  &:first-child {
    border-top: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
`;

export const WeekItem = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  border-bottom: none;
  &:last-child {
    border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  }
  ${props => props.disabled && css`
    background: #eee;
  `}
`;

export const WeekItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 6em;
  padding: .75em;
  border-right: 1px solid #ccc;
  cursor: pointer;
`;

export const WeekItemHeaderMonth = styled.div`
  text-transform: uppercase;
`;

export const WeekItemHeaderDay = styled.div`
  border-radius: 100%;
  text-transform: uppercase;
  width: fit-content;
  margin: .5em 0em;
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${props => props.theme.fontSize.HeadingH1FontSize};
  ${props => props.isToday && css`
    width: 2em;
    height: 2em;
    background: ${props.theme.colorStyled.ColorPrimaryBlue};
    color: ${props.theme.colorStyled.ColorWhite};
  `}
`;

export const WeekItemContent = styled.div`
  flex: 1;
  padding: .75em 1em;
  position: relative;
`;

export const ModalActionGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0em;
    &:first-child {
      margin-right: .5em;
    }
    &:last-child {
      margin-left: .5em;
    }
  }
`;

// ================================
export const ScheduleCheckListWrapper = styled.div`
  flex: 0 0 18em;
`;

export const CheckboxItemWrapper = styled.div`
  margin-top: 1em
`;

export const CheckboxItem = styled.div`
  margin-bottom: 1em;
`;

export const CheckboxItemText = styled.div`
`;

export const CheckboxItemCheckbox = styled.input`
position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + div {
    position: relative;
    cursor: unset;
    padding: 0;
  }

  // Box.
  & + div:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }

  // Box hover
  &:hover + div:before {
    background: ${props => props.theme.colorStyled.ColorPrimaryBlueHover};
  }

  // Box focus
  &:focus + div:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + div:before {
    background: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  }

  // Disabled state div.
  &:disabled + div {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + div:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + div:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      2px 0 0 white,
      4px 0 0 white,
      4px -2px 0 white,
      4px -4px 0 white,
      4px -6px 0 white,
      4px -8px 0 white;
    transform: rotate(45deg);
  }
`;

export const FollowWrapper = styled.div`
  width: 3em;
  height: 3em;
  background-color: #453835;
  border-radius: 50%;
  color: white;
  text-align: center;
  padding-top: 3px;
  font-size: 12px;
  opacity: 0.6;
  margin: 5px 5px;
  span {
    display: inherit;
  }

  ${props => props.active && css`
    opacity: 1;
  `}

  ${props => props.nodisplay && css`
    opacity: 0;
  `}

`;

export const FollowRow = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 90%;
  align-items: center;
  justify-content: center;
`;
export const CardDetails = styled.div`
  position: absolute;
  top: 10px;
`;

export const ModalTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;
`;
export const ModalMsg = styled.div`
  padding-top: 3px;
`;
export const ButtonsWrap = styled.div`
  margin-top: 40px;
`;

export const Modalbutton = styled.button`
  width: 9em;
  padding: 5px;
  margin-left: 12px;
  border-radius: 8px;
  border: none;
  background-color: #453535;
  color: white;
  height: 30px;

  ${props => props.isSaveAndExit && css`
    background-color: #ff9e16;
  `}

  ${props => props.autoWidth && css`
    width: auto;
    padding: 0 15px;
  `}

  ${props => props.pullRight && css`
    float: right;
  `}
`;

export const EditModeActions = styled.div`
  position: absolute;
  right: 15px;
  top: 9em;
`;
