/* eslint-disable @typescript-eslint/no-shadow */
import {
  startGroupActivity,
  endGroupActivity,
  restartGroupActivity,
  updateIndividualGroupActivity,
  calculateScoreGroupActivity,
  resetOnlinePracticeActivity,
} from '@apis/groupActivity';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions } from '@reducers/groupActivity';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { useCurrentCourse } from '@reducers/courses/hooks';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { individualGroupActivityType } from '../../types';

export const useStartGroupActivity = (groupActivityId) => {
  const queryClient = useQueryClient();
  const response = useMutation(
    (groupActivityId: number) => startGroupActivity(groupActivityId),
    {
      mutationKey: ['startActivity', groupActivityId],
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroupActivityInfo', groupActivityId]);
        queryClient.invalidateQueries(['inProgressOLP']);
      },
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};

export const useEndGroupActivity = (groupActivityId) => {
  const queryClient = useQueryClient();
  const response = useMutation(
    (groupActivityId: number) => endGroupActivity(groupActivityId),
    {
      mutationKey: ['endActivity', groupActivityId],
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroupActivityInfo', groupActivityId]);
        queryClient.invalidateQueries(['inProgressOLP']);
      },
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};
export const useRestartGroupActivity = (groupActivityId) => {
  const queryClient = useQueryClient();
  const response = useMutation(
    (groupActivityId: number) => restartGroupActivity(groupActivityId),
    {
      mutationKey: ['restartActivity', groupActivityId],
      onSuccess: () => {
        queryClient.invalidateQueries(['getGroupActivityInfo', groupActivityId]);
      },
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};
export const useUpdateIndividualGroupActivity = () => {
  const response = useMutation(
    (payload: individualGroupActivityType) => updateIndividualGroupActivity(payload),
    {
      mutationKey: ['updateIndividualActivity'],

      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};

export const useCalculateScore = () => {
  const response = useMutation(
    (groupActivityId: number) => calculateScoreGroupActivity(groupActivityId),
    {
      mutationKey: ['calculateScore'],
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );

  return response;
};

export const useResetOnlinePracticeActivity = () => {
  const dispatch = useDispatch();
  const isTeacher: boolean = useIsTeacher();
  const user = useUser();
  const { sectionId, lessonId: lessonGroupId, unitId } = useParams();
  const { data } = useGetLessonDetail();
  const lessonId = data?.lessons[0]?.id;

  const getActivities = actions.getGroupActivities;
  const courseData = useCurrentCourse();
  const response = useMutation<any>(
    () => resetOnlinePracticeActivity(sectionId, lessonGroupId),

    {
      onSuccess: () => {
        dispatch(
          getActivities(
            sectionId,
            lessonGroupId,
            lessonId,
            user.id,
            unitId,
            courseData.curriculum,
            isTeacher,
            lessonGroupId,
          ),
        );
      },
      mutationKey: ['reset-activity'],
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );

  return response;
};
