/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
function random(seed: number) {
  // eslint-disable-next-line no-param-reassign
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}
const shuffle = (array: Array<any>, seed: number = Math.floor(Math.random() * 10000)) => {
  let m = array.length;
  let t;
  let i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
};
export default shuffle;
