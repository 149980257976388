export const studentData = [
  {
    id: '1',
    firstName: 'Jonas',
    middleName: 'Ray Black',
    lastName: 'Fisher',
    email: 'monum@mailinator.com',
    grade: {
      grade_level: 12,
    },
  },
  {
    id: '2',
    firstName: 'Laith',
    middleName: 'Ethan Rice',
    lastName: 'Dean',
    email: 'vadameguci@mailinator.com',
    grade: {
      grade_level: 11,
    },
  },
  {
    id: '3',
    firstName: 'Hannah',
    middleName: 'Rhoda Underwood',
    lastName: 'Tran',
    email: 'huvoj@mailinator.net',
    grade: {
      grade_level: 12,
    },
  },
  {
    id: '4',
    firstName: 'Isaac',
    middleName: 'Jasmine',
    lastName: 'Holder',
    email: 'sutetise@mailinator.net',
    grade: {
      grade_level: 10,
    },
  },
  {
    id: '5',
    firstName: 'Uta',
    middleName: 'Celeste',
    lastName: 'Kerr',
    email: 'mimemyg@mailinator.net',
    grade: {
      grade_level: 12,
    },
  },
  {
    id: '1',
    firstName: 'Fatima',
    middleName: 'Brown',
    lastName: 'Cleveland',
    email: 'gigosyheby@mailinator.net',
    grade: {
      grade_level: 10,
    },
  },
];
