import axios from 'axios';
import { handleError } from './utils';
import { pauseSessionExam } from './exam';

// api calls

export function getAdminReportsUrl() {
  const url = '/api/adminreports/';
  return axios.get(url)
    .then(response => ({ response }))
    .catch(handleError);
}

export const getAvailableReports = () => {
  const url = '/api/reports/available_reports/';
  return axios.get(url)
  .then(response => ({ response }))
  .catch(handleError);
}

export const getUserPastReports = (page) => {
  const url = `/api/reports/user_past_reports/?page=${page}`;
  return axios.get(url)
  .then(response => ({ response }))
  .catch(handleError);
}

export const generateReports = (payload) => {
  const url = '/api/reports/generate_reports/';
  return axios.post(url, payload)
  .then(response => ({ response }))
  .catch(handleError);
}

export const getFeatureAccess = (feature) => {
  const url = `/api/features/${feature}/check-access/`;
  return axios.get(url)
  .then(response => ({ response }))
  .catch(handleError);
}
