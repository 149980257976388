import React from 'react';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';

export default class ResponsiveModal extends React.PureComponent {
  onChangecustomModalStyles = (matches, modalStyles) => {
    const { content } = modalStyles;

    return { ...modalStyles, content: { ...content, width: matches ? '100%' : 'calc(100% - 30px)' } };
  }
  render() {
    const {children, style, ...props} = this.props;
    return(
      <MediaQuery maxWidth={480}>
        {matches => (
        <Modal style={this.onChangecustomModalStyles(matches, style)} {...props}>
          {children}
        </Modal>
      )}
      </MediaQuery>
    )
  }
}