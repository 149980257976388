import styled, { css } from 'styled-components';

export const DashboardSummaryWrap = styled.div`
  display: flex;
  transition: all 400ms ease;
  @media (max-width: 1170px) {
    flex-direction: column;
  }
`;

export const DashboardPracticeActivityWrap = styled.div`
  flex: 1;
  margin: 0 1.5em;
  @media (max-width: 1170px) {
    margin: 0em 1em;
  }
`;

export const DashboardLine = styled.span`
  width: 1.5px;
  height: calc(100vh - 4em);
  background-color: ${props => props.theme.colorStyled.ColorXBorder};
`;

export const DashboardSummaryUpcomingWrap = styled.div`
  flex: 0 0 18em;
  span {
    margin: 1em;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  }
  @media (max-width: 1170px) {
    flex: 0 0 100%;
  }
`;

export const DashboardStatisticWrap = styled.div``;

export const DashboardStatisticGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
  &:last-child {
    margin-bottom: 0em;
  }
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;
// summary table

export const SummaryTableWrap = styled.div`
  padding: 0em 1.5em;
  margin-bottom: 1.5em;
`;

export const SummaryTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
  .react-tabs__tab-list {
    margin-bottom: 0em;
    display: flex;
    justify-content: flex-end;
    flex: 1;
    .react-tabs__tab {
      padding: .5em 1em;
      background-color: ${props => props.theme.colorStyled.ColorWhite};
      color: ${props => props.theme.colorStyled.ColorPrimary};
      border: none !important;
      cursor: pointer;
      &:first-child {
        border-radius: .5em 0em 0em .5em;
      }
      &:last-child {
        border-radius: 0em .5em .5em 0em;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .react-tabs__tab--selected {
      background-color: ${props => props.theme.colorStyled.ColorBgDefault};
      color: ${props => props.theme.colorStyled.ColorWhite};
      &:hover {
        color: ${props => props.theme.colorStyled.ColorWhite};
      }
    }
  }
  @media (max-width: 840px) {
    flex-direction: column;
    align-items: flex-start;
    .react-tabs__tab-list {
      width: 100%;
    }
  }
`;

export const SummaryTitle = styled.div`
  font-family: 'MontserratSemiBold';
  flex: 1;
  @media (max-width: 840px) {
    margin-bottom: 1em;
  }
`;

export const SummaryBlockWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 1em;
  padding: .5em 1em;
  border-radius: .5em;
  transition: all 400ms ease;
  position: relative;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  box-shadow: rgba(9,30,66,0.25) 0px 0px 1px, rgba(9,30,66,0.31) 0px 0;
  &:last-child {
    margin-right: 0em;
  }
  span {
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
    font-family: 'MontserratSemiBold';
  }
  @media (max-width: 1280px) {
    margin-right: 0em;
    margin-bottom: 1em;
    &:last-child {
      margin-bottom: 0em;
    }
  }
  @media (max-width: 560px) {
    padding: 1em;
  }
`;

export const SummaryBlockContent = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1.5px solid ${props => props.theme.colorStyled.ColorBorderInput};
  padding: .5em;
  &:last-child {
    border-right: none;
  }
  div[data-tip] {
    border-bottom: 1px dashed ${props => props.theme.colorStyled.ColorTime};
    width: fit-content;
    margin: 0 auto;
  }
`;

export const SummaryBlockItem = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  @media (max-width: 560px) {
    flex-wrap: wrap;
  }
`;

export const SummaryScoreGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SummaryAttendanceGroup = styled(SummaryScoreGroup)`
  margin-top: .75em;
  p {
    font-size: ${props => props.theme.fontSize.HeadingH3FontSize};
  }
`;

export const SummaryImage = styled.img`
  margin: 1em .25em;
  width: 6em;
  height: 5em;
  object-fit: contain;
  @media (max-width: 1440px) {
    width: 3.5em;
    height: 3.5em;
  }
  @media (max-width: 560px) {
    flex: 0 0 100%;
    margin: 1em auto;
  }
`;

export const SummaryScore = styled.div``;

export const SummaryText = styled.div`
  white-space: nowrap;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
`;

export const SummaryNumber = styled.div`
  margin-top: .5em;
  font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
`;

export const SummarySubText = styled.div`
  font-size: ${props => props.theme.fontSize.SmallFontSize};
  color: ${props => props.theme.colorStyled.ColorPoint};
  white-space: nowrap;
`;

export const SummaryRank = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .5em;
  .chalktalk-arrow-up {
    font-weight: bold;
    color: ${props => props.theme.colorStyled.ColorSuccessN};
  }
`;

export const SummaryTableContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 2.5em;
  height: 2.5em;
  border-radius: 100%;
  background-color: #85be86;
  ${({ reduce }) => reduce && css`
    background-color: #d8a19d;
  `}
`;

export const NotApplicationText = styled.div`
  color: ${props => props.theme.colorStyled.ColorTime};
  text-align: center;
  width: 100%;
  ${({ small }) => small && css`
    font-size: ${props => props.theme.fontSize.SmallFontSize};
  `}
`;

export const TextAdditionInfo = styled.span`
  font-size: ${props => props.theme.fontSize.SmallerFontSize} !important;
  margin-left: 5px;
  font-style: italic;
  font-family: 'MontserratMedium' !important;
`;

export const DashboardScheduleWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 2em 0;
`;
export const DashboardTitle = styled.div`
  text-align: center;
  p{
    display: inline;
    font-family: 'MontserratSemiBold';
    font-size: 1rem;
  }
`;
export const CardsWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${({ complete }) => complete && css`
    gap: 0;
  `}
`;
export const NumberIcon = styled.div`
  border-radius: 50%;
  background-color: #A22325;
  color: white;
  padding: 0.55em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 3px;
  span{
    overflow: visible;
    height: 1px;
    margin-top: -1.2em;
  }
  &:after{
    content: '';
    display: block;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
  }
`;
export const SummaryContainer = styled.div`
  ${({ gradient }) => gradient && css`
    background: linear-gradient(270deg, rgba(226,236,255,1) 0%, rgba(246,247,251,1) 50%, rgba(226,236,255,1) 100%);
  `}
  height: 100%;
`;
