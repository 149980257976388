import styled, { css } from 'styled-components';

export const StudentGroupInviteFormWrap = styled.div`
  margin-right: 1em;
  flex: 1;
  @media (max-width: 1280px) {
    margin-right: 0em;
  }
`;

export const StudentListTableWrap = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 0 10.5px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
  padding: 25px;
`;

export const StudentInviteTitle = styled.div`
  font-size: 16px;
  font-family: 'MontserratBold';
  color: #000;
  margin-bottom: 25px;
  ${({ large }) => large && css`
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 25px;
  `};
  ${({ custome }) => custome && css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
`;

export const StudentFormContent = styled.div`
  font-family: 'MontserratSemiBold';
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  line-height: 20px;
  color: #ff9e16;
  min-height: 24em;
  max-height: 24em;
`;

export const IconStyled = styled.i`
  font-size: ${props => props.theme.fontSize.HeadingH5FontSize};
`;

export const StudentTableInput = styled.input``;
