import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const FallbackComponentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: #f6f7fb;
  min-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  flex-shrink: 1;
  padding-inline: 1rem;
  gap: 1rem;
  img {
    margin: 2em;
    max-height: 20em;
    padding-inline: 1rem;
    max-width: 100%;
  }

  h1 {
    font-size: 1.5em;
    font-weight: bold;
  }
  h3 {
    color: #8d8d8d;
    word-break: break-word;
  }
  p {
    color: #4a4845;
    padding-inline: 1rem;
    width: 100%;
    max-width: min(100%, 37.5rem);
    margin-bottom: 1em;
  }
`;
