import { connect } from 'react-redux';
import _get from 'lodash/get';
import TestReviewManagement from '../../components/TestReviewManagement';
import {
  actions as examReportActions,
  selectors,
} from '../../reducers/examReport';
import {
  actions as sectionExamActions,
  selectors as sectionExamSelectors,
} from '../../reducers/sectionExam';
import {
  selectors as LeftSideBarSelector,
} from '../../reducers/leftSideBar';
import { selectors as userSelectors, actions as userActions } from '../../reducers/user';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const examSessionId = _get(match, 'params.examSessionId', '');
  const examSectionId = _get(match, 'params.examSectionId', '');
  const studentId = _get(match, 'params.studentId', '');
  const courseId = _get(match, 'params.courseId', '');

  return {
    courseId,
    sectionId,
    examSessionId,
    examSectionId,
    studentId,
    examDetail: sectionExamSelectors.getExamDetailById(state, examSessionId),
    examReportDetail: selectors.getExamReportDetail(state),
    userRole: userSelectors.getUserRole(state),
    isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
  };
};

const mapDispatchToProps = {
  examReportGetDetail: examReportActions.examReportGetDetail,
  sectionExamGetDetail: sectionExamActions.sectionExamGetDetail,
  examReportGetOfUser: examReportActions.examReportGetOfUser,
  userReportIssue: userActions.userReportIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestReviewManagement);
