import React, { useState } from 'react';
import {
  ExamScoreSelect,
  ExamScoreOption,
  ExamScoreSelectWrap,
  ExamScoreSelectValue,
  ExamScoreSelectGroup,
} from '../../DashboardExamScoreReport/DashboardExamScoreReport.style';
import {
  OverlayWrap,
} from '../../Generals/stylesheets/General.style';
function DropDownMenu({
  title,
  list,
  onSelect,
  selected,
  displayName,
  notSelectedFallback = 'Not Selected',
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  return (
    <ExamScoreSelectWrap>
      <ExamScoreSelectGroup>
        <p>
          {title}
          :
          {' '}
        </p>
        <ExamScoreSelectValue onClick={() => setIsDropdownOpen(true)}>
          {displayName?.(selected) || notSelectedFallback}

          <i className="chalktalk-icon-bold-down" />
        </ExamScoreSelectValue>
      </ExamScoreSelectGroup>
      {isDropdownOpen && (
        <React.Fragment>
          <ExamScoreSelect>
            {list?.map((item) => (
              <ExamScoreOption
                key={item.id}
                onClick={() => {
                  onSelect(item);
                  setIsDropdownOpen(false);
                }}
              >
                {displayName?.(item) || ''}
                {item.id === selected?.id && <i className="chalktalk-check-success" />}
              </ExamScoreOption>
            ))}
          </ExamScoreSelect>
          <OverlayWrap isOpen={isDropdownOpen} onClick={() => setIsDropdownOpen(false)} />
        </React.Fragment>
      )}
    </ExamScoreSelectWrap>
  );
}

export default DropDownMenu;
