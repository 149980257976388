/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _round from 'lodash/round';
import _maxBy from 'lodash/maxBy';
import moment from 'moment';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  UnitPracticeWrap,
  UnitQuestionTypeWrap,
  UnitQuestionLogo,
  UnitQuestionDescription,
  UnitQuestionTopic,
  UnitQuestionTopicCover,
  UnitPracticeDetailWrap,
  UnitPracticeItemWrap,
  UnitPracticeItem,
  UnitName,
  UnitScore,
  UnitIcon,
  UnitLastActivity,
  UnitActionButton,
  UnitPracticeTitle,
  UnitSkillLevelWrap,
  UnitSkillLevel,
  LevelSkill,
  Dot,
  UnitActionGroup,
  Wrapper,
  PracticeCoverWrapper,
} from './UnitPracticeStudentDetail.style';
import { PRACTICE_TYPE, ROLE_TYPE } from '../../utils/enums';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';

const scrollStyle = {
  height: 'calc(100vh - 23em)',
  width: '100%',
};

const arrayOf5 = [...new Array(5)];

class UnitPracticeStudentDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      assetTypeSelected: null,
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const { match, history, practiceId, isInitialized } = this.props;
    const prevUnitId = _get(prevProps, 'match.params.unitId');

    const { sectionId, subjectId, unitId, courseId } = match.params;

    if (prevProps.isInitialized !== isInitialized && isInitialized) {
      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice/${practiceId}`,
      );
    }

    if (prevUnitId !== unitId && unitId) {
      this.getInitialData();
    }
  }

  viewOverallReview = (assetType) => {
    const { history, match } = this.props;
    const { sectionId, unitId, courseId, subjectId } = match.params;

    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice-review?asset_type=${assetType}`,
    );
  };

  initializePractice = (assetType) => {
    const { match, currentUserId, initializePracticeSession } = this.props;
    const { sectionId, lessonId, unitId } = match.params;
    initializePracticeSession(
      lessonId,
      sectionId,
      unitId,
      currentUserId,
      assetType,
      PRACTICE_TYPE.ADAPTIVE_PRACTICE,
    );
    this.setState({
      assetTypeSelected: assetType,
    });
  };

  getInitialData = () => {
    const { userRole, match, getAllPracticeSessionActivities, sectionSkillsGet } =
      this.props;
    const { sectionId, unitId } = match.params;

    if (userRole === ROLE_TYPE.STUDENT) {
      getAllPracticeSessionActivities(sectionId, unitId);
    }
    sectionSkillsGet(sectionId);
  };

  calculateButtonText = (assetType) => {
    const { userPracticeActivitiesByAssetType } = this.props;
    const userPracticeActivityList = _get(
      userPracticeActivitiesByAssetType,
      assetType,
      [],
    );
    if (!userPracticeActivityList || !userPracticeActivityList.length)
      return ['Practice', false, null];

    const isContinuePractice = userPracticeActivityList.some(
      (activity) => !activity.ended,
    );
    const shouldShowViewResult = userPracticeActivityList.some(
      (activity) => activity.ended,
    );
    // const lastActivityItem = _maxBy(userPracticeActivityList, (item) => new Date(item.started).getTime());

    if (isContinuePractice) return ['Continue Practice', shouldShowViewResult];

    return ['Practice', shouldShowViewResult];
  };

  renderPracticeCoverItem = (lesson) => {
    const { id, name } = lesson;
    return (
      <PracticeCoverWrapper key={id}>
        <p>{name}</p>
      </PracticeCoverWrapper>
    );
  };

  renderQuestionType = () => {
    const {
      lessons,
      unitDetail: { name },
    } = this.props;
    return (
      <UnitQuestionTypeWrap>
        <UnitQuestionLogo>
          <img src="/images/bg-practice.png" alt="" />
        </UnitQuestionLogo>

        <UnitQuestionDescription>
          <h3>{name}</h3>
          <p>
            Practice the topics you have learned on different passage types and contexts.
          </p>
        </UnitQuestionDescription>

        <UnitQuestionTopic>
          <p>Topics covered in this practice</p>
          <UnitQuestionTopicCover>
            {lessons.map(this.renderPracticeCoverItem)}
          </UnitQuestionTopicCover>
        </UnitQuestionTopic>
      </UnitQuestionTypeWrap>
    );
  };

  renderAssetTypeItem = (item) => {
    const {
      isInitializing,
      currentUserId,
      assignOfUnitByAssetType,
      practiceSummaryByUserId,
    } = this.props;
    const { assetTypeSelected } = this.state;
    const { id, name } = item;
    const [buttonText, shouldShowViewResult] = this.calculateButtonText(id);
    const report = _get(practiceSummaryByUserId, [currentUserId, id], null);

    const lastAssigned = _get(assignOfUnitByAssetType, [id, 'last_assigned'], null);
    const newAssigned = _get(assignOfUnitByAssetType, [id, 'assigned'], null);
    const isAssigned =
      newAssigned ||
      (lastAssigned && !report) ||
      (lastAssigned && moment(lastAssigned).isAfter(moment(report.lastActivity)));
    const scoreDisplay = report ? `${report.score}/${report.maxScore}` : '--';
    const shouldChangeText = isInitializing && assetTypeSelected === item.id;

    return (
      <UnitPracticeItem key={id}>
        <UnitName>{name}</UnitName>
        <UnitScore>{scoreDisplay}</UnitScore>
        <UnitIcon className={isAssigned ? 'chalktalk-error' : ''} />
        <UnitLastActivity>
          <span>{report ? moment(report.lastActivity).fromNow() : '--'}</span>
          <span>last activity</span>
        </UnitLastActivity>
        <UnitActionGroup>
          {shouldShowViewResult && (
            <UnitActionButton
              green
              onClick={() => this.viewOverallReview(id)}
              inStudentPage
            >
              View Results
            </UnitActionButton>
          )}
          <UnitActionButton
            onClick={() => this.initializePractice(item.id)}
            disabled={isInitializing}
            inStudentPage
          >
            <span>{shouldChangeText ? 'Initializing' : buttonText}</span>
            <i className="chalktalk-icon-next" />
          </UnitActionButton>
        </UnitActionGroup>
      </UnitPracticeItem>
    );
  };

  renderLevelBlock = (currentSkill) => {
    const dotArray = arrayOf5.map((_, i) => i + 1 <= _round(currentSkill));
    return (
      <UnitSkillLevel>
        <span>SKILL LEVEL</span>
        <span>{currentSkill}</span>
        <LevelSkill>
          {dotArray.map((isActive, index) => (
            <Dot key={index.toString()} isActive={isActive} />
          ))}
        </LevelSkill>
      </UnitSkillLevel>
    );
  };

  renderSkillLevelItem = (lesson) => {
    const { skillByLessonId, currentUserId } = this.props;
    const { id: lessonId, name: lessonName } = lesson;
    const currentSkill = _get(
      (skillByLessonId[lessonId] || {})[currentUserId],
      'current',
      'N/A',
    );

    return (
      <UnitPracticeItem key={lessonId}>
        <UnitName>{lessonName}</UnitName>
        {this.renderLevelBlock(Math.round(currentSkill))}
      </UnitPracticeItem>
    );
  };

  renderUnitPracticeDetail = () => {
    const { unitDetail, lessons } = this.props;
    const { assets = [] } = unitDetail;

    return (
      <UnitPracticeDetailWrap>
        <Wrapper>
          <UnitPracticeItemWrap>
            <UnitPracticeTitle>
              <h1>Practice by Passage</h1>
            </UnitPracticeTitle>

            {assets.map(this.renderAssetTypeItem)}
          </UnitPracticeItemWrap>

          <UnitSkillLevelWrap>
            <ShadowScrollbar autoHide style={scrollStyle}>
              <UnitPracticeTitle>
                <h1>Your Skill Levels</h1>
              </UnitPracticeTitle>

              {lessons.map(this.renderSkillLevelItem)}
            </ShadowScrollbar>
          </UnitSkillLevelWrap>
        </Wrapper>
      </UnitPracticeDetailWrap>
    );
  };

  render() {
    const {
      isGettingSectionUnit,
      isGettingUserPracticeSessionActivities,
      isGettingLesson,
    } = this.props;

    if (
      isGettingSectionUnit ||
      isGettingUserPracticeSessionActivities ||
      isGettingLesson
    ) {
      return <LoadingIndicator content="Loading" />;
    }
    return (
      <DefaultContentWrap>
        <UnitPracticeWrap>
          {this.renderQuestionType()}
          {this.renderUnitPracticeDetail()}
        </UnitPracticeWrap>
      </DefaultContentWrap>
    );
  }
}

UnitPracticeStudentDetail.propTypes = {
  lessons: PropTypes.array,
  match: PropTypes.objectOf(PropTypes.any),
  userRole: PropTypes.string,
  history: PropTypes.shape(),
  practiceId: PropTypes.number,
  isInitialized: PropTypes.bool,
  isInitializing: PropTypes.bool,
  initializePracticeSession: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  getAllPracticeSessionActivities: PropTypes.func.isRequired,
  // userPracticeActivities: PropTypes.array,
  unitDetail: PropTypes.object,
  isGettingSectionUnit: PropTypes.bool,
  isGettingUserPracticeSessionActivities: PropTypes.bool,
  isGettingLesson: PropTypes.bool,
  // practiceSummary: PropTypes.array,
  practiceSummaryByUserId: PropTypes.object,
  userPracticeActivitiesByAssetType: PropTypes.object,
  sectionSkillsGet: PropTypes.func,
  skillByLessonId: PropTypes.object,
  assignOfUnitByAssetType: PropTypes.object,
};

export default UnitPracticeStudentDetail;
