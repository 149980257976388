import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import { FormGroup } from '../Generals/stylesheets/Form.style';
import {
  RegisterFormWrapper,
  RegisterFormTitle,
  RegisterFormInput,
  RegisterAction,
  RegisterErrorMessage,
  ButtonStyled,
} from '../StudentRegister/StudentRegister.style';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().email('Invalid Email').trim().required('Required'),
  phoneNumber: Yup.string().trim().required('Invalid Phone Number'),
  password: Yup.string()
    .notOneOf(['123456', 123456], 'The password should not be a simple sequence.')
    .min(8, 'The password should be greater than 8 characters')
    .matches(/[0-9]/, 'The password should contain at least one number.')
    .matches(/[A-Z]/, 'The password should contain at least one upper case character.')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
    .required('Required'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
};

export default class PersonalInformationForm extends Component {
  static propTypes = {
    registerTeacher: PropTypes.func.isRequired,
    errorMessage: PropTypes.any,
  }

  state = {
    isShowPassword: false,
  };

  toggleShowPassword = (isShowPassword) => {
    this.setState({
      isShowPassword,
    });
  }

  handleSubmit = (values) => {
    const { registerTeacher } = this.props;
    registerTeacher(values);
  }

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>

  render() {
    const {
      errorMessage,
    } = this.props;
    const { isShowPassword } = this.state;
    return (
      <RegisterFormWrapper>
        <RegisterFormTitle>
          <h1>Personal Information</h1>
          <h3>Create your ChalkTalk account.</h3>
        </RegisterFormTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          <RegisterFormInput noValidate>
            <FormGroup>
              <FormInput
                type="text"
                name="firstName"
                label="First Name"
              />
              <FormInput
                type="text"
                name="lastName"
                label="Last Name"
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                type="email"
                name="email"
                label="Email"
              />
              <FormInput
                type="phoneNumber"
                name="phoneNumber"
                label="123 456 78"
                defaultCountry="us"
              />
            </FormGroup>
            <FormGroup>
              <FormInput
                type={isShowPassword ? 'text' : 'password'}
                name="password"
                label="Password"
                handleShowPassword={() => this.toggleShowPassword(true)}
                handleHidePassword={() => this.toggleShowPassword(false)}
                showPassword
              />
              <FormInput
                type="password"
                name="confirmPassword"
                label="Confirm password"
              />
            </FormGroup>
            {errorMessage
                && (
                  <RegisterErrorMessage>
                    {errorMessage.map(this.renderErrorMessage)}
                  </RegisterErrorMessage>
                )}
            <RegisterAction>
              <ButtonStyled type="submit">Create account</ButtonStyled>
            </RegisterAction>
          </RegisterFormInput>
        </Formik>
      </RegisterFormWrapper>
    );
  }
}
