/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, connect, getIn } from 'formik';
import _get from 'lodash/get';
import _unionBy from 'lodash/unionBy';
import _intersectionBy from 'lodash/intersectionBy';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import ColumnSize from './ColumnSize';
import StudentGroupInviteForm from './StudentGroupInviteForm';
import DataExcelExample from './DataExcelExample';
import ErrorModal from './ErrorModal';
import { StudentFormContent } from '../StudentListTable/StudentListTable.style';
import { TableHeader } from '../Table/TableComponent';
import {
  TableHeadWrapper,
  TableContentWrapper,
} from '../Table/TableComponent.style';
import {
  StudentListWrap,
  DulicatedMessage,
  LearnMoreButton,
} from '../StudentRegister/StudentRegister.style';

const scrollStyle = {
  height: 'calc(100vh - 110px)',
  minHeight: '250px',
  maxHeight: '250px',
  borderBottom: '1.5px solid #ff9e16',
  marginBottom: '15px',
};

class StudentGroupInviteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dublicateList: new Set(),
      studentList: null,
      isModalOpen: false,
      isErrorModalOpen: false,
    };
    this.objStudent = {};
  }

  static propTypes = {
    name: PropTypes.string,
    formik: PropTypes.object,
    studentGroup: PropTypes.array,
    allowPaste: PropTypes.bool,
  };

  componentDidMount() {
    document.addEventListener('paste', this.handlePasteAction);
  }

  componentWillUnmount() {
    document.removeEventListener('paste', this.handlePasteAction);
  }

  handleErorModal = () => {
    this.setState((prevState) => ({
      isErrorModalOpen: !prevState.isErrorModalOpen,
    }));
  };

  handlePasteAction = (event) => {
    const { allowPaste } = this.props;
    if (!allowPaste) return;

    const clipText = event.clipboardData.getData('Text');
    this.parseClipboard(clipText);
  };

  parseClipboard = (clipText) => {
    const itemList = clipText.split('\n').filter((e) => Boolean(e.trim()));
    const studentList = itemList.map(this.parseSingleItem).filter(Boolean);
    this.setState({
      studentList,
    });
    this.setStudentListValue(studentList);
  };

  parseSingleItem = (itemStr) => {
    const itemFieldListComma = itemStr.split(',').map((e) => e.trim());
    const itemFieldListTab = itemStr.split('\t').map((e) => e.trim());
    let itemFieldList = itemFieldListTab;

    if (itemFieldListComma.length > itemFieldListTab.length) {
      itemFieldList = itemFieldListComma;
    }

    if (itemFieldList && itemFieldList.length < 5) {
      this.handleErorModal();
      return null;
    } // invalid

    const firstName = itemFieldList[0] || '';
    const middleName = itemFieldList[1] || '';
    const lastName = itemFieldList[2] || '';
    const email = itemFieldList[3] || '';
    const grade = { grade_level: Number(itemFieldList[4]) || 9 };

    return {
      firstName,
      middleName,
      lastName,
      email,
      grade,
    };
  };

  setStudentListValue = (studentList) => {
    const { formik, name } = this.props;
    const currentStudents = _get(formik, `values.${name}`, []);
    const newStudentList = _unionBy(studentList, currentStudents, 'email');
    const newDublicateList = _intersectionBy(
      studentList,
      currentStudents,
      'email',
    );
    this.setState({
      dublicateList: newDublicateList,
    });
    formik.setFieldValue(name, newStudentList);
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  onChange = (name, value) => {
    const { formik } = this.props;
    formik.setFieldValue(name, value);
  };

  onBlur = (name) => {
    const { formik } = this.props;
    formik.setFieldTouched(name, true);
  };

  removeStudentItem = (arrayHelpers) => (index) => {
    arrayHelpers.remove(index);
    const { studentList } = this.state;
    const { formik, name } = this.props;
    const currentStudents = _get(formik, `values.${name}`, []);
    const newStudentList = currentStudents.filter((_, i) => i !== index);
    const newDublicateList = _intersectionBy(
      studentList,
      newStudentList,
      'email',
    );
    this.setState({
      dublicateList: newDublicateList,
    });
  };

  renderStudentListHeader = () => (
    <TableHeadWrapper border>
      <TableHeader {...ColumnSize[0]} value="First Name" />
      <TableHeader {...ColumnSize[1]} value="Middle Name" />
      <TableHeader {...ColumnSize[2]} value="Last Name" />
      <TableHeader {...ColumnSize[3]} value="Email" />
      <TableHeader {...ColumnSize[4]} value="Grade" />
      <TableHeader {...ColumnSize[5]} value="Actions" />
    </TableHeadWrapper>
  );

  renderStudentTable = (arrayHelpers) => {
    const { name, formik } = this.props;
    const { dublicateList, isModalOpen, isErrorModalOpen } = this.state;
    const arrDublicateList = [...dublicateList];
    const countDublicate = arrDublicateList.length > 0;
    const studentsValues = _get(formik, `values.${name}`, []);
    const errorMessage = _get(formik, `errors.${name}`, []);
    const noStudent = studentsValues.length === 0;
    return noStudent ? (
      <StudentFormContent>
        Copy and paste students’ First, Middle, Last, Email, Grade Level
        (Optional) from a spreadsheet.
        <LearnMoreButton type="button" onClick={this.toggleModal}>
          Learn more.
        </LearnMoreButton>
        <DataExcelExample
          isModalOpen={isModalOpen}
          toggleModal={this.toggleModal}
        />
        <ErrorModal isOpen={isErrorModalOpen} onClose={this.handleErorModal} />
      </StudentFormContent>
    ) : (
      <React.Fragment>
        {this.renderStudentListHeader()}
        <ShadowScrollbar style={scrollStyle}>
          <StudentListWrap>
            <TableContentWrapper border>
              {studentsValues.map((student, index) => {
                const fieldName = `${name}.${index}`;
                console.log('TCL fieldName', fieldName);
                return (
                  <StudentGroupInviteForm
                    key={index}
                    index={index}
                    student={student}
                    deleteStudent={this.removeStudentItem(arrayHelpers)}
                    fieldName={fieldName}
                    errorMessage={errorMessage[index]}
                  />
                );
              })}
            </TableContentWrapper>
          </StudentListWrap>
        </ShadowScrollbar>
        <DulicatedMessage>
          {countDublicate && (
            <p>
              The email is duplicate.
              <br />
              {`Number of duplicates: ${arrDublicateList.length}`}
            </p>
          )}
        </DulicatedMessage>
        <ErrorModal isOpen={isErrorModalOpen} onClose={this.handleErorModal} />
      </React.Fragment>
    );
  };

  render() {
    const { name } = this.props;
    return <FieldArray name={name} render={this.renderStudentTable} />;
  }
}

export default connect(StudentGroupInviteTable);
