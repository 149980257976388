/* eslint-disable eqeqeq */
import React from 'react';
import { ORDER_SHORTCUT, ORDER_LABEL } from '@utils/constants';
import useHotkey from '@utils/hooks/useHotkey';
import { questionChoice } from '@components/PracticeV2/types';
import {
  useIsQuestionLocked,
  usePractice,
  useQuestion,
} from '@components/PracticeV2/Provider/PracticeProvider';
import ChoiceItem from './ChoiceItem';
import { PracticeAnswersMultiChoiceWrap } from './AnswerMultiChoice.style';

const questionsDefaultValue = {
  choices: [] as questionChoice[],
};
// remove spaces and split by comma
const allowedKeys = ORDER_SHORTCUT.replace(/\s/g, '').split(',');
const AnswerMultiChoice = () => {
  const question = useQuestion();

  const questionData = question?.question || questionsDefaultValue;
  const { handleAnswerChange, currentAnswer } = usePractice();
  const isQuestionLocked = useIsQuestionLocked();
  const onKeyUp = React.useCallback(
    (keyName) => {
      switch (keyName) {
        default: {
          const choicesSelected = questionData.choices.find(
            (item) => item.order == keyName,
          );
          if (choicesSelected) {
            handleAnswerChange(choicesSelected.id);
          }
          break;
        }
      }
    },
    [handleAnswerChange, questionData],
  );
  useHotkey({
    allowedKeys,
    onKeyUp,
  });
  return (
    <PracticeAnswersMultiChoiceWrap data-testid="answer-multi-choice">
      {(questionData?.choices || []).map((choice) => (
        <ChoiceItem
          key={choice.id}
          text={choice.asset?.text || ''}
          isSelected={currentAnswer == choice.id}
          onSelect={() => handleAnswerChange(choice.id)}
          displayResult={false}
          isCorrect={false}
          label={ORDER_LABEL[choice.order - 1]}
          choice_answer={undefined}
          choiceChosen={undefined}
          id={choice.id}
          disabled={isQuestionLocked}
        />
      ))}
    </PracticeAnswersMultiChoiceWrap>
  );
};

export default AnswerMultiChoice;
