import { REHYDRATE } from 'redux-persist';
import { LOCATION_CHANGE } from 'connected-react-router';

export const AUTH_NO_TYPE_LOGIN = 'auth/AUTH_NO_TYPE_LOGIN';
export const AUTH_STUDENT_LOGIN = 'auth/AUTH_STUDENT_LOGIN';
export const AUTH_TEACHER_LOGIN = 'auth/AUTH_TEACHER_LOGIN';
export const AUTH_CLEVER_LOGIN = 'auth/AUTH_CLEVER_LOGIN';
export const AUTH_SCHOOLOGY_LOGIN = 'auth/AUTH_SCHOOLOGY_LOGIN';
export const AUTH_LOGIN_SUCCESS = 'auth/AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILED = 'auth/AUTH_LOGIN_FAILED';
export const AUTH_CLASSLINK_LOGIN = 'auth/AUTH_CLASSLINK_LOGIN';

export const AUTH_LOGIN_REQUIRED = 'auth/AUTH_LOGIN_REQUIRED';
export const AUTH_REFRESH_TOKEN = 'auth/AUTH_REFRESH_TOKEN';
export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT';

export const AUTH_VERIFY_EMAIL = 'auth/AUTH_VERIFY_EMAIL';
export const AUTH_VERIFY_EMAIL_SUCCESS = 'auth/AUTH_VERIFY_EMAIL_SUCCESS';
export const AUTH_VERIFY_EMAIL_FAILED = 'auth/AUTH_VERIFY_EMAIL_FAILED';

export const AUTH_CHANGE_EMAIL = 'auth/AUTH_CHANGE_EMAIL';
export const AUTH_CHANGE_EMAIL_SUCCESS = 'auth/AUTH_CHANGE_EMAIL_SUCCESS';

export const AUTH_RESEND_VERIFICATION_EMAIL = 'auth/AUTH_RESEND_VERIFICATION_EMAIL';
export const AUTH_VERIFICATION_EMAIL = 'auth/AUTH_VERIFICATION_EMAIL';
export const UPDATE_USER_VERIFICATION = 'auth/UPDATE_USER_VERIFICATION';

export const AUTH_VERIFY_STUDENT_INVITE = 'auth/AUTH_VERIFY_STUDENT_INVITE';
export const AUTH_VERIFY_STUDENT_INVITE_SUCCESS =
  'auth/AUTH_VERIFY_STUDENT_INVITE_SUCCESS';
export const AUTH_VERIFY_STUDENT_INVITE_FAILED =
  'auth/AUTH_VERIFY_STUDENT_INVITE_FAILED';

export const AUTH_STUDENT_SET_PASSWORD = 'auth/AUTH_STUDENT_SET_PASSWORD';
export const AUTH_STUDENT_SET_PASSWORD_SUCCESS =
  'auth/AUTH_STUDENT_SET_PASSWORD_SUCCESS';
export const AUTH_STUDENT_SET_PASSWORD_FAILED =
  'auth/AUTH_STUDENT_SET_PASSWORD_FAILED';

export const AUTH_FIND_EMAIL = 'auth/AUTH_FIND_EMAIL';
export const AUTH_FIND_EMAIL_SUCCESS = 'auth/AUTH_FIND_EMAIL_SUCCESS';
export const AUTH_FIND_EMAIL_FAILED = 'auth/AUTH_FIND_EMAIL_FAILED';

export const AUTH_RESET_PASSWORD = 'auth/AUTH_RESET_PASSWORD';
export const AUTH_RESET_PASSWORD_SUCCESS = 'auth/AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILED = 'auth/AUTH_RESET_PASSWORD_FAILED';

export const AUTH_CONNECT_CLEVER = 'auth/AUTH_CONNECT_CLEVER';
export const AUTH_CONNECT_CLEVER_SUCCESS = 'auth/AUTH_CONNECT_CLEVER_SUCCESS';
export const AUTH_CONNECT_CLEVER_FAILED = 'auth/AUTH_CONNECT_CLEVER_FAILED';

export const AUTH_CONNECT_CLASSLINK = 'auth/AUTH_CONNECT_CLASSLINK';
export const AUTH_CONNECT_CLASSLINK_SUCCESS = 'auth/AUTH_CONNECT_CLASSLINK_SUCCESS';
export const AUTH_CONNECT_CLASSLINK_FAILED = 'auth/AUTH_CONNECT_CLASSLINK_FAILED';

export const AUTH_CONNECT_SCHOOLOGY = 'auth/AUTH_CONNECT_SCHOOLOGY';
export const AUTH_CONNECT_SCHOOLOGY_SUCCESS = 'auth/AUTH_CONNECT_SCHOOLOGY_SUCCESS';
export const AUTH_CONNECT_SCHOOLOGY_FAILED = 'auth/AUTH_CONNECT_SCHOOLOGY_FAILED';

export const AUTH_APPROVE_JOIN_COURSE_REQUEST =
  'auth/AUTH_APPROVE_JOIN_COURSE_REQUEST';
export const AUTH_APPROVE_JOIN_COURSE_REQUEST_SUCCESS =
  'auth/AUTH_APPROVE_JOIN_COURSE_REQUEST_SUCCESS';
export const AUTH_APPROVE_JOIN_COURSE_REQUEST_FAILED =
  'auth/AUTH_APPROVE_JOIN_COURSE_REQUEST_FAILED';

export const AUTH_CLOSE_WEBSOCKET = 'auth/CLOSE_WEBSOCKET';
export const AUTH_OPEN_WEBSOCKET = 'auth/OPEN_WEBSOCKET';

export const AUTH_GET_NEW_TOKEN = 'auth/GET_NEW_TOKEN';

// action creators

const authGetNewToken = () => ({
  type: AUTH_GET_NEW_TOKEN,
});

const authOpenWebsocket = () => ({
  type: AUTH_OPEN_WEBSOCKET,
});

const authCloseWebsocket = () => ({
  type: AUTH_CLOSE_WEBSOCKET,
});

const loginRequiredAction = (callbackUrl, cleverEmail) => ({
  type: AUTH_LOGIN_REQUIRED,
  callbackUrl,
  cleverEmail,
});

const cleverLogin = (code) => ({
  type: AUTH_CLEVER_LOGIN,
  payload: {
    code,
  },
});

const schoologyLogin = (code) => ({
  type: AUTH_SCHOOLOGY_LOGIN,
  payload: {
    code,
  },
});

const classlinkLogin = (code) => ({
  type: AUTH_CLASSLINK_LOGIN,
  payload: {
    code,
  },
});

const noTypeLogin = (email, password) => ({
  type: AUTH_NO_TYPE_LOGIN,
  payload: {
    email,
    password,
  },
});
const studentLogin = (email, password) => ({
  type: AUTH_STUDENT_LOGIN,
  payload: {
    email,
    password,
  },
});

const teacherLogin = (email, password) => ({
  type: AUTH_TEACHER_LOGIN,
  payload: {
    email,
    password,
  },
});

const loginSuccess = (token, refreshToken, user,isEmailConfirmed) => ({
  type: AUTH_LOGIN_SUCCESS,
  payload: {
    token,
    refreshToken,
    user,
    isEmailConfirmed,
  },
});

const loginFailed = (errorMsg) => ({
  type: AUTH_LOGIN_FAILED,
  payload: {
    errorMsg,
  },
});

const logout = (callbackUrl='') => ({
  type: AUTH_LOGOUT,
  payload: {
    callbackUrl,
  },
});

const refreshLoginToken = (token, refreshToken) => ({
  type: AUTH_REFRESH_TOKEN,
  payload: {
    token,
    refreshToken,
  },
});

const changeEmail = (email, uid) => ({
  type: AUTH_CHANGE_EMAIL,
  payload: {
    email,
    uid,
  },
});
const resendVerificationEmail = (email) => ({
  type: AUTH_RESEND_VERIFICATION_EMAIL,
  payload: {
    email,
  },
});

const verificationEmail = (token) => ({
  type: AUTH_VERIFICATION_EMAIL,
  payload: {
    token,
  },
});

const updateUserVerification = (vrfie) => ({
  type: UPDATE_USER_VERIFICATION,
  payload: {
    vrfie,
  },
})

const changeEmailSuccess = () => ({
  type: AUTH_CHANGE_EMAIL_SUCCESS,
});

// VERIFY TEACHER AND STUDENT EMAIL
const verifyEmail = (token) => ({
  type: AUTH_VERIFY_EMAIL,
  payload: {
    token,
  },
});

const verifyEmailSuccess = () => ({
  type: AUTH_VERIFY_EMAIL_SUCCESS,
});

const verifyEmailFailed = (errorMsg) => ({
  type: AUTH_VERIFY_EMAIL_FAILED,
  payload: {
    errorMsg,
  },
});

// VERIFY STUDENT INVITATION
const verifyStudentInvitation = (uid, token) => ({
  type: AUTH_VERIFY_STUDENT_INVITE,
  payload: {
    uid,
    token,
  },
});

const verifyStudentInvitationSuccess = () => ({
  type: AUTH_VERIFY_STUDENT_INVITE_SUCCESS,
});

const verifyStudentInvitationFailed = (errorMsg) => ({
  type: AUTH_VERIFY_STUDENT_INVITE_FAILED,
  payload: {
    errorMsg,
  },
});

// SET STUDENT PASSWORD
const studentSetPassword = (password, confirmPassword, uid, token) => ({
  type: AUTH_STUDENT_SET_PASSWORD,
  payload: {
    password,
    confirmPassword,
    uid,
    token,
  },
});

const studentSetPasswordSuccess = () => ({
  type: AUTH_STUDENT_SET_PASSWORD_SUCCESS,
});

const studentSetPasswordFailed = (errorMsg) => ({
  type: AUTH_STUDENT_SET_PASSWORD_FAILED,
  payload: {
    errorMsg,
  },
});

// FIND EMAIL
// findEmailData { firstName, lastName, sectionCode }
const findEmail = (findEmailData) => ({
  type: AUTH_FIND_EMAIL,
  payload: {
    findEmailData,
  },
});

const findEmailSuccess = (foundEmail) => ({
  type: AUTH_FIND_EMAIL_SUCCESS,
  payload: {
    foundEmail,
  },
});

const findEmailFailed = (errorMsg) => ({
  type: AUTH_FIND_EMAIL_FAILED,
  payload: {
    errorMsg,
  },
});

// RESET PASSWORD
const resetPassword = (email) => ({
  type: AUTH_RESET_PASSWORD,
  payload: {
    email,
  },
});

const resetPasswordSuccess = () => ({
  type: AUTH_RESET_PASSWORD_SUCCESS,
});

const resetPasswordFailed = (errorMsg) => ({
  type: AUTH_RESET_PASSWORD_FAILED,
  payload: {
    errorMsg,
  },
});

// CONNECT CLEVER
const connectClever = (code) => ({
  type: AUTH_CONNECT_CLEVER,
  payload: {
    code,
  },
});

const connectCleverSuccess = () => ({
  type: AUTH_CONNECT_CLEVER_SUCCESS,
});

const connectCleverFailed = (errorMsg) => ({
  type: AUTH_CONNECT_CLEVER_FAILED,
  payload: {
    errorMsg,
  },
});

// CONNECT Classlink
const connectClasslink = (code) => ({
  type: AUTH_CONNECT_CLASSLINK,
  payload: {
    code,
  },
});

const connectClasslinkSuccess = () => ({
  type: AUTH_CONNECT_CLASSLINK_SUCCESS,
});

const connectClasslinkFailed = (errorMsg) => ({
  type: AUTH_CONNECT_CLASSLINK_FAILED,
  payload: {
    errorMsg,
  },
});

// CONNECT Schoology
const connectSchoology = (code) => ({
  type: AUTH_CONNECT_SCHOOLOGY,
  payload: {
    code,
  },
});

const connectSchoologySuccess = () => ({
  type: AUTH_CONNECT_SCHOOLOGY_SUCCESS,
});

const connectSchoologyFailed = (errorMsg) => ({
  type: AUTH_CONNECT_SCHOOLOGY_FAILED,
  payload: {
    errorMsg,
  },
});
// APPROVE REQUEST
const approveJoinCourseRequest = (uid, token) => ({
  type: AUTH_APPROVE_JOIN_COURSE_REQUEST,
  payload: {
    uid,
    token,
  },
});

const approveJoinCourseRequestSuccess = () => ({
  type: AUTH_APPROVE_JOIN_COURSE_REQUEST_SUCCESS,
});

const approveJoinCourseRequestFailed = (errorMsg) => ({
  type: AUTH_APPROVE_JOIN_COURSE_REQUEST_FAILED,
  payload: {
    errorMsg,
  },
});

export const actions = {
  loginRequiredAction,
  cleverLogin,
  classlinkLogin,
  schoologyLogin,
  noTypeLogin,
  studentLogin,
  teacherLogin,
  loginFailed,
  loginSuccess,
  refreshLoginToken,
  logout,

  changeEmail,
  changeEmailSuccess,

  resendVerificationEmail,
  verificationEmail,
  updateUserVerification,
  
  verifyEmail,
  verifyEmailSuccess,
  verifyEmailFailed,

  verifyStudentInvitation,
  verifyStudentInvitationSuccess,
  verifyStudentInvitationFailed,

  studentSetPassword,
  studentSetPasswordSuccess,
  studentSetPasswordFailed,

  findEmail,
  findEmailSuccess,
  findEmailFailed,

  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailed,

  connectClever,
  connectCleverSuccess,
  connectCleverFailed,

  connectClasslink,
  connectClasslinkSuccess,
  connectClasslinkFailed,

  connectSchoology,
  connectSchoologySuccess,
  connectSchoologyFailed,

  approveJoinCourseRequest,
  approveJoinCourseRequestSuccess,
  approveJoinCourseRequestFailed,

  authCloseWebsocket,
  authOpenWebsocket,
  authGetNewToken,
};

// selectors
export const getToken = ({ auth }) => auth.token;
const getRefreshToken = ({ auth }) => auth.refreshToken;
const getErrorMessage = ({ auth }) => auth.errorMsg
  || auth.verifyEmailError
  || auth.studentInvitationEmailError
  || auth.setupPasswordError;

const getIsLoggingIn = ({ auth }) => auth.isLoggingIn;
const getConfirmationStatus= ({ auth }) => !!auth.isEmailConfirmed;
const getAuthenticatedStatus = ({ auth }) => !!auth.authenticated;
const getStatusMessage = ({ auth }) => auth.statusMessage || '';

const isVerifyingEmail = ({ auth }) => auth.isVerifyingEmail;
const getVerifyEmailError = ({ auth }) => auth.verifyEmailError;

const isVerifyingStudentInvitation = ({ auth }) => auth.isVerifyingStudentInvitation;
const getVerifyStudentInvitationError = ({ auth }) => auth.studentInvitationEmailError;

const isSetupPassword = ({ auth }) => auth.isSetupPassword;
const getSetupPasswordError = ({ auth }) => auth.setupPasswordError;

const isFindingEmail = ({ auth }) => auth.isFindingEmail;
const getFindingEmailError = ({ auth }) => auth.findingEmailError;
const getFoundEmail = ({ auth }) => auth.foundEmail;

const isSendingResetPasswordEmail = ({ auth }) => auth.isSendingResetPasswordEmail;
const isSentResetPasswordEmail = ({ auth }) => auth.isSentResetPasswordEmail;
const getSentResetPasswordError = ({ auth }) => auth.sentResetPasswordError;

const isConnectingClever = ({ auth }) => auth.isConnectingClever;
const isConnectedClever = ({ auth }) => auth.isConnectedClever;
const getConnectingCleverError = ({ auth }) => auth.connectingCleverError;

const isConnectingClasslink = ({ auth }) => auth.isConnectingClasslink;
const isConnectedClasslink = ({ auth }) => auth.isConnectedClasslink;
const getConnectingClasslinkError = ({ auth }) => auth.connectingClasslinkError;

const getCallbackURL = ({ auth }) => auth.callbackUrl;
const getCleverEmail = ({ auth }) => auth.cleverEmail;
const getApproveJoinCourseRequestError = ({ auth }) => auth.approveJoinCourseRequestError;

export const selectors = {
  getToken,
  getRefreshToken,
  getErrorMessage,

  getIsLoggingIn,
  getAuthenticatedStatus,
  getConfirmationStatus,
  getStatusMessage,

  isVerifyingEmail,
  getVerifyEmailError,

  isVerifyingStudentInvitation,
  getVerifyStudentInvitationError,

  isSetupPassword,
  getSetupPasswordError,

  isFindingEmail,
  getFindingEmailError,
  getFoundEmail,

  isSendingResetPasswordEmail,
  isSentResetPasswordEmail,
  getSentResetPasswordError,

  isConnectingClever,
  isConnectedClever,
  getConnectingCleverError,

  isConnectingClasslink,
  isConnectedClasslink,
  getConnectingClasslinkError,

  getCallbackURL,
  getCleverEmail,
  getApproveJoinCourseRequestError,
};

// states and reducers

const initialState = {
  isVerifyingEmail: null,
  verifyEmailError: null,

  isVerifyingStudentInvitation: null,
  studentInvitationEmailError: null,

  isSetupPassword: null,
  setupPasswordError: null,

  isFindingEmail: null,
  findingEmailError: null,
  foundEmail: null,

  isSendingResetPasswordEmail: null,
  isSentResetPasswordEmail: null,
  sentResetPasswordError: null,

  isConnectingClever: null,
  isConnectedClever: null,
  connectingCleverError: null,

  isConnectingClasslink: null,
  isConnectedClasslink: null,
  connectingClasslinkError: null,

  approveJoinCourseRequestError: null,

  isLoggingIn: null,
  errorMsg: null,
  email: null,
  token: null,
  refreshToken: null,
  authenticated: null,
  statusMessage: null,
  callbackUrl: null,
  cleverEmail: null,
  isEmailConfirmed:false 
  // change initial value as required 
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      // clear old state
      return {
        ...state,
        ...((action.payload || {}).auth || {}),
        callbackUrl: null,
        cleverEmail: null,

        isVerifyingEmail: null,
        verifyEmailError: null,

        isVerifyingStudentInvitation: null,
        studentInvitationEmailError: null,

        isSetupPassword: null,
        setupPasswordError: null,

        isFindingEmail: null,
        findingEmailError: null,
        foundEmail: null,

        isSendingResetPasswordEmail: null,
        isSentResetPasswordEmail: null,
        sentResetPasswordError: null,

        isLoggingIn: null,
        errorMsg: null,

        isConnectingClever: null,
        isConnectedClever: null,
        connectingCleverError: null,

        isConnectingClasslink: null,
        isConnectedClasslink: null,
        connectingClasslinkError: null,
      };
    }

    case AUTH_NO_TYPE_LOGIN:
    case AUTH_TEACHER_LOGIN:
    case AUTH_STUDENT_LOGIN: {
      const { email } = action.payload;
      return {
        ...initialState,
        callbackUrl: state.callbackUrl,
        cleverEmail: state.cleverEmail,
        email,
      };
    }

    case UPDATE_USER_VERIFICATION:{
      const { vrfie } = action.payload;
      return{
        ...state,
        vrfie,
      }
    }

    case AUTH_REFRESH_TOKEN: {
      const { token, refreshToken } = action.payload;
      return {
        ...state,
        token,
        refreshToken,
      };
    }

    case AUTH_LOGIN_SUCCESS: {
      const { token, refreshToken,isEmailConfirmed } = action.payload;
      return {
        ...state,
        token,
        refreshToken,
        isLoggingIn: false,
        authenticated: true,
        isEmailConfirmed
      };
    }

    case AUTH_LOGIN_FAILED: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        errorMsg,
        isLoggingIn: false,
        authenticated: false,
      };
    }

    case AUTH_LOGOUT: {
      return initialState;
    }

    case AUTH_VERIFY_EMAIL: {
      return {
        ...state,
        isVerifyingEmail: true,
        verifyEmailError: null,
      };
    }

    case AUTH_VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        isVerifyingEmail: false,
        statusMessage: 'Your account has been verified successfully!',
      };
    }

    case AUTH_VERIFY_EMAIL_FAILED: {
      const { errorMsg: verifyEmailError } = action.payload;
      return {
        ...state,
        isVerifyingEmail: false,
        verifyEmailError,
      };
    }

    // student verify that they want to join a section

    case AUTH_VERIFY_STUDENT_INVITE: {
      return {
        ...state,
        isVerifyingStudentInvitation: true,
        studentInvitationEmailError: null,
      };
    }

    case AUTH_VERIFY_STUDENT_INVITE_SUCCESS: {
      return {
        ...state,
        isVerifyingStudentInvitation: false,
        callbackUrl: '',
      };
    }

    case AUTH_VERIFY_STUDENT_INVITE_FAILED: {
      const { errorMsg: studentInvitationEmailError } = action.payload;
      return {
        ...state,
        isVerifyingStudentInvitation: false,
        studentInvitationEmailError,
      };
    }

    // set up new password

    case AUTH_STUDENT_SET_PASSWORD: {
      return {
        ...state,
        isSetupPassword: true,
        setupPasswordError: null,
      };
    }

    case AUTH_STUDENT_SET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isSetupPassword: false,
        statusMessage:
          'You have successfully set your password! Login to join your section.',
      };
    }

    case AUTH_STUDENT_SET_PASSWORD_FAILED: {
      const { errorMsg: setupPasswordError } = action.payload;
      return {
        ...state,
        isSetupPassword: false,
        setupPasswordError,
      };
    }

    // ==========================

    case AUTH_FIND_EMAIL: {
      return {
        ...state,
        foundEmail: '',
        isFindingEmail: true,
      };
    }

    case AUTH_FIND_EMAIL_SUCCESS: {
      const { foundEmail } = action.payload;

      return {
        ...state,
        isFindingEmail: false,
        foundEmail,
      };
    }

    case AUTH_FIND_EMAIL_FAILED: {
      const { errorMsg: findingEmailError } = action.payload;

      return {
        ...state,
        isFindingEmail: false,
        findingEmailError,
      };
    }

    // ==========================

    case AUTH_RESET_PASSWORD: {
      return {
        ...state,
        foundEmail: '',
        isSendingResetPasswordEmail: true,
        isSentResetPasswordEmail: false,
        sentResetPasswordError: null,
      };
    }

    case AUTH_RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        isSendingResetPasswordEmail: false,
        isSentResetPasswordEmail: true,
      };
    }

    case AUTH_RESET_PASSWORD_FAILED: {
      const { errorMsg: sentResetPasswordError } = action.payload;

      return {
        ...state,
        isSendingResetPasswordEmail: false,
        sentResetPasswordError,
      };
    }

    // ==========================

    case AUTH_CONNECT_CLEVER: {
      return {
        ...state,
        isConnectingClever: true,
        isConnectedClever: false,
        connectingCleverError: null,
      };
    }

    case AUTH_CONNECT_CLEVER_SUCCESS: {
      return {
        ...state,
        isConnectingClever: false,
        isConnectedClever: true,
        callbackUrl: null,
      };
    }

    case AUTH_CONNECT_CLEVER_FAILED: {
      const { errorMsg: connectingCleverError } = action.payload;

      return {
        ...state,
        isConnectingClever: false,
        connectingCleverError,
      };
    }

    case AUTH_CONNECT_CLASSLINK: {
      return {
        ...state,
        isConnectingClasslink: true,
        isConnectedClasslink: false,
        connectingClasslinkError: null,
      };
    }

    case AUTH_CONNECT_CLASSLINK_SUCCESS: {
      return {
        ...state,
        isConnectingClasslink: false,
        isConnectedClasslink: true,
        callbackUrl: null,
      };
    }

    case AUTH_CONNECT_CLASSLINK_FAILED: {
      const { errorMsg: connectingClasslinkError } = action.payload;

      return {
        ...state,
        isConnectingClasslink: false,
        connectingClasslinkError,
      };
    }



    case AUTH_CONNECT_SCHOOLOGY: {
      return {
        ...state,
        isConnectingClever: true,
        isConnectedClever: false,
        connectingCleverError: null,
      };
    }

    case AUTH_CONNECT_SCHOOLOGY_SUCCESS: {
      return {
        ...state,
        isConnectingClever: false,
        isConnectedClever: true,
        callbackUrl: null,
      };
    }

    case AUTH_CONNECT_SCHOOLOGY_FAILED: {
      const { errorMsg: connectingCleverError } = action.payload;

      return {
        ...state,
        isConnectingClever: false,
        connectingCleverError,
      };
    }
    // ======================

    case AUTH_APPROVE_JOIN_COURSE_REQUEST: {
      return state;
    }

    case AUTH_APPROVE_JOIN_COURSE_REQUEST_SUCCESS: {
      return {
        ...state,
        callbackUrl: null,
        cleverEmail: null,
      };
    }

    case AUTH_LOGIN_REQUIRED: {
      const { callbackUrl, cleverEmail } = action;

      return {
        ...state,
        errorMsg: 'You need to login first.',
        callbackUrl,
        cleverEmail,
      };
    }

    case AUTH_APPROVE_JOIN_COURSE_REQUEST_FAILED: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        approveJoinCourseRequestError: errorMsg,
      };
    }

    case LOCATION_CHANGE:
      return {
        ...state,
        errorMsg: null,
        verifyEmailError: null,
        studentInvitationEmailError: null,
        setupPasswordError: null,
      };

    default:
      return state;
  }
}
