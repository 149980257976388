import React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';

const StyledSelect = styled(Select)`
  & .MuiOutlinedInput-input {
    padding: 0.6563rem 0.875rem;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colorStyled.Action} !important;
  }
`;

const StyledLabel = styled(InputLabel)`
  &.MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
  }
  &.Mui-focused {
    color: ${(props) => props.theme.colorStyled.Action} !important;
  }
`;
const CKSelect = ({ label, options, onChange, value }) => (
  <FormControl variant="outlined" color="warning">
    <StyledLabel>{label}</StyledLabel>
    <StyledSelect value={value} onChange={onChange} label={label}>
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {options.map(({ value: opValue, displayName }) => (
        <MenuItem key={opValue} value={opValue}>
          {displayName}
        </MenuItem>
      ))}
    </StyledSelect>
  </FormControl>
);
export default React.memo(CKSelect);
