// action definition
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import { createSelector } from 'reselect';
import { REHYDRATE } from 'redux-persist';

export const COURSES_GET_LIST = 'courses/COURSES_GET_LIST';
export const COURSES_GET_LIST_SUCCESS = 'courses/COURSES_GET_LIST_SUCCESS';
export const COURSES_GET_LIST_FAIL = 'courses/COURSES_GET_LIST_FAIL';

export const COURSES_CREATE_NEW = 'courses/COURSES_CREATE_NEW';
export const COURSES_CREATE_NEW_SUCCESS = 'courses/COURSES_CREATE_NEW_SUCCESS';
export const COURSES_CREATE_NEW_FAIL = 'courses/COURSES_CREATE_NEW_FAIL';

export const COURSES_UPDATE = 'courses/UPDATE';
export const COURSES_UPDATE_SUCCESS = 'courses/UPDATE_SUCCESS';
export const COURSES_UPDATE_FAIL = 'courses/UPDATE_FAIL';

export const COURSES_FETCH_DETAIL = 'courses/COURSES_FETCH_DETAIL';
export const COURSES_FETCH_DETAIL_SUCCESS = 'courses/COURSES_FETCH_DETAIL_SUCCESS';
export const COURSES_FETCH_DETAIL_FAIL = 'courses/COURSES_FETCH_DETAIL_FAIL';

export const COURSE_FETCH_ON_START = 'courses/FETCH_ON_START';
export const COURSE_FETCH_ON_START_SUCCESS = 'courses/FETCH_ON_START_SUCCESS';
export const COURSE_FETCH_ON_START_FAIL = 'courses/FETCH_ON_START_FAIL';

export const COURSE_REQUEST_INVITATION = 'courses/COURSE_REQUEST_INVITATION';
export const COURSE_REQUEST_INVITATION_SUCCESS = 'courses/COURSE_REQUEST_INVITATION_SUCCESS';
export const COURSE_REQUEST_INVITATION_FAIL = 'courses/COURSE_REQUEST_INVITATION_FAIL';

export const COURSES_SELECT_COURSE = 'courses/COURSES_SELECT_COURSE';

export const COURSE_DEACTIVATE = 'courses/DEACTIVATE';
export const COURSE_DEACTIVATE_SUCCESS = 'courses/DEACTIVATE_SUCCESS';
export const COURSE_DEACTIVATE_FAIL = 'courses/DEACTIVATE_FAIL';

// action creators
// deactivate course

const courseDeactivate = courseId => ({
  type: COURSE_DEACTIVATE,
  courseId,
});

const courseDeactivateSuccess = courseId => ({
  type: COURSE_DEACTIVATE_SUCCESS,
  courseId,
});

const courseDeactivateFail = errorMsg => ({
  type: COURSE_DEACTIVATE_FAIL,
  errorMsg,
});

// create course actions

const courseUpdate = (courseId, courseInfo) => ({
  type: COURSES_UPDATE,
  courseId,
  courseInfo,
});

const courseUpdateSuccess = (courseId, courseInfo) => ({
  type: COURSES_UPDATE_SUCCESS,
  courseInfo,
  courseId,
});

const courseUpdateFail = errorMsg => ({
  type: COURSES_UPDATE_FAIL,
  errorMsg,
});

const courseFetchOnStart = () => ({
  type: COURSE_FETCH_ON_START,
});

const courseFetchOnStartSuccess = courseList => ({
  type: COURSE_FETCH_ON_START_SUCCESS,
  courseList,
});

const courseFetchOnStartFail = errorMsg => ({
  type: COURSE_FETCH_ON_START_FAIL,
  errorMsg,
});

function fetchCourseList() {
  return {
    type: COURSES_GET_LIST,
  };
}

export const fetchCourseListSuccess = courseList => ({
  type: COURSES_GET_LIST_SUCCESS,
  courseList,
});

export const fetchCourseListFail = errorMessage => ({
  type: COURSES_GET_LIST_FAIL,
  errorMessage,
});

// create new course actions
export const createCourse = (courseInfo, callback) => ({
  type: COURSES_CREATE_NEW,
  payload: {
    courseInfo,
  },
  callback,
});

export const createCourseSuccess = courseInfo => ({
  type: COURSES_CREATE_NEW_SUCCESS,
  payload: {
    courseInfo,
  },
});

export const createCourseFail = errorMsg => ({
  type: COURSES_CREATE_NEW_FAIL,
  payload: {
    errorMsg,
  },
});

// request an invite to course
const requestInvitation = code => ({
  type: COURSE_REQUEST_INVITATION,
  payload: {
    code,
  },
});
const requestInvitationSuccess = () => ({
  type: COURSE_REQUEST_INVITATION_SUCCESS,
});

const requestInvitationFail = errorMsg => ({
  type: COURSE_REQUEST_INVITATION_FAIL,
  payload: {
    errorMsg,
  },
});

// fetch single course detail
const fetchCourseDetail = courseId => ({
  type: COURSES_FETCH_DETAIL,
  payload: {
    courseId,
  },
});
const fetchCourseDetailSuccess = () => ({
  type: COURSES_FETCH_DETAIL_SUCCESS,
});

const fetchCourseDetailFail = errorMsg => ({
  type: COURSES_FETCH_DETAIL_FAIL,
  payload: {
    errorMsg,
  },
});

// misc
export const courseSelected = courseId => ({
  type: COURSES_SELECT_COURSE,
  payload: {
    courseId,
  },
});

export const actions = {
  courseUpdate,
  courseUpdateSuccess,
  courseUpdateFail,

  fetchCourseList,
  fetchCourseListSuccess,
  fetchCourseListFail,

  createCourse,
  createCourseSuccess,
  createCourseFail,

  courseSelected,

  courseFetchOnStart,
  courseFetchOnStartSuccess,
  courseFetchOnStartFail,

  requestInvitation,
  requestInvitationSuccess,
  requestInvitationFail,

  fetchCourseDetail,
  fetchCourseDetailSuccess,
  fetchCourseDetailFail,

  courseDeactivate,
  courseDeactivateSuccess,
  courseDeactivateFail,
};

// selectors
export const getCourseListIds = ({ courses }) => _get(courses, 'courseListIds', []);
export const getCourseListById = ({ courses }) => _get(courses, 'courseListById', {});
export const getIsLoadingCourses = ({ courses }) => _get(courses, 'isLoadingCourses', false);
export const getCourseById = ({ courses }, courseId) => _get(courses, ['courseListById', courseId], null);
export const getCourseSelected = ({ courses }) => _get(courses, 'courseSelected', '');
export const getCourseList = createSelector(
  getCourseListIds,
  getCourseListById,
  (courseListIds, courseListById) => courseListIds.map(courseId => courseListById[courseId]),
);

const getCourseDetailById = ({ courses }, courseId) => _get(courses, `courseListById.${courseId}`, {});
const getGHEStatus = ({ courses }, courseId) => _get(courses, `courseListById.${courseId}.allow_ghe`, false);

export const getFirstCourseData = createSelector(
  getCourseListIds,
  getCourseListById,
  (courseListIds, courseListById) => {
    const firstCourseId = courseListIds[0];

    return courseListById[firstCourseId];
  },
);

const isRequestedInvite = ({ courses }) => _get(courses, 'isRequestedInvite', false);
const isCreatingCourse = ({ courses }) => _get(courses, 'isCreatingCourse', false);
const isCourseCreated = ({ courses }) => _get(courses, 'isCourseCreated', false);
const isRequestingInvite = ({ courses }) => _get(courses, 'isRequestingInvite', false);
const getRequestInviteError = ({ courses }) => _get(courses, 'requestInviteError', '');
const getCreatingCourseErrorMsg = ({ courses }) => _get(courses, 'creatingCourseErrorMsg', '');

export const selectors = {
  getCourseListIds,
  getCourseListById,
  getIsLoadingCourses,
  getCourseById,
  getCourseSelected,
  getCourseList,
  getCourseDetailById,
  getFirstCourseData,
  isCourseCreated,

  isRequestedInvite,
  isCreatingCourse,
  isRequestingInvite,
  getRequestInviteError,
  getCreatingCourseErrorMsg,
  getGHEStatus,
};

// reducers

const initialState = {
  errorMessage: null,
  isLoadingCourses: false,
  isLoadingCourseDetail: false,

  isCreatingCourse: false,
  isCourseCreated: false,
  creatingCourseErrorMsg: null,

  isRequestedInvite: false,
  isRequestingInvite: false,
  requestInviteError: null,

  courseListIds: [],
  courseListById: {},
  courseSelected: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...state,
        errorMessage: null,
        isLoadingCourses: false,
        isLoadingCourseDetail: false,

        isCreatingCourse: false,
        isCourseCreated: false,
        creatingCourseErrorMsg: null,

        isRequestedInvite: false,
        isRequestingInvite: false,
        requestInviteError: null,
      };
    }
    // fetch course
    case COURSES_GET_LIST:
      return {
        ...state,
        errorMessage: '',
        isLoadingCourses: true,
        courseListIds: [],
        courseListById: {},
      };

    case COURSE_FETCH_ON_START_SUCCESS:
    case COURSES_GET_LIST_SUCCESS: {
      const { courseList = [] } = action;
      const courseListIds = courseList.map(({ id }) => id);
      const courseListById = _keyBy(courseList, 'id');

      return {
        ...state,
        courseListIds,
        courseListById,
        errorMessage: '',
        isLoadingCourses: false,
      };
    }

    case COURSES_GET_LIST_FAIL: {
      return {
        ...state,
        courseListIds: [],
        courseListById: {},
        isLoadingCourses: false,
        errorMessage: action.errorMessage,
      };
    }

    case COURSES_CREATE_NEW: {
      return {
        ...state,
        isCreatingCourse: true,
        isCourseCreated: false,
        creatingCourseErrorMsg: null,
      };
    }

    case COURSES_CREATE_NEW_SUCCESS: {
      const { courseInfo } = action.payload;

      return {
        ...state,
        isCreatingCourse: false,
        isCourseCreated: true,
        courseListIds: [
          ...state.courseListIds,
          courseInfo.id,
        ],
        courseListById: {
          ...state.courseListById,
          [courseInfo.id]: courseInfo,
        },
      };
    }

    case COURSES_CREATE_NEW_FAIL: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isCreatingCourse: false,
        creatingCourseErrorMsg: errorMsg,
      };
    }

    case COURSES_SELECT_COURSE: {
      const { courseId } = action.payload;
      return {
        ...state,
        courseSelected: courseId,
      };
    }

    case COURSE_REQUEST_INVITATION: {
      return {
        ...state,
        isRequestingInvite: true,
        isRequestedInvite: false,
        requestInviteError: '',
      };
    }

    case COURSE_REQUEST_INVITATION_SUCCESS: {
      return {
        ...state,
        isRequestingInvite: false,
        isRequestedInvite: true,
      };
    }

    case COURSE_REQUEST_INVITATION_FAIL: {
      const { errorMsg } = action.payload;

      return {
        ...state,
        isRequestingInvite: false,
        requestInviteError: errorMsg,
      };
    }

    case COURSES_FETCH_DETAIL: {
      return {
        ...state,
        isLoadingCourseDetail: true,
      };
    }

    case COURSES_FETCH_DETAIL_SUCCESS: {
      const { courseDetail } = action;
      return {
        ...state,
        isLoadingCourseDetail: false,
        courseListIds: [...new Set(
          state.courseListById.concat(courseDetail.id),
        )],
        courseListById: {
          ...state.courseListById,
          [courseDetail.id]: courseDetail,
        },
      };
    }

    case COURSES_FETCH_DETAIL_FAIL: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isLoadingCourseDetail: false,
        errorMessage: errorMsg,
      };
    }
    case COURSES_UPDATE:
      return {
        ...state,
        isUpdating: true,
      };
    case COURSES_UPDATE_SUCCESS: {
      const { courseInfo, courseId } = action;

      return {
        ...state,
        isUpdating: false,
        courseListById: {
          ...state.courseListById,
          [courseId]: courseInfo,
        },
      }; }
    case COURSES_UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
      };

    default: {
      return state;
    }
  }
}
