import React from 'react';
import UserChip from '@components/PracticeV2/Component/UserChip/UserChip';
import { GroupAnswerCombined, PracticeState } from '@components/PracticeV2/types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataView from '../../DataView';
import {
  useGroupQuestionAnswers,
  usePractice,
} from '../../../Provider/PracticeProvider';

type UserDataViewProps = {
  // id + name from StudentType
  user: GroupAnswerCombined;
  data: string | number;
};
const UserDataView = ({ user, data }: UserDataViewProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'start',
      flexWrap: 'nowrap',
      gap: '0.5rem',
      paddingInlineStart: '0.5rem',
    }}
  >
    <UserChip name={user?.student_name || ''} key={data} />
    <DataView data={`${data}`} />
  </Box>
);

const GroupDataView = () => {
  const membersAnswer = useGroupQuestionAnswers();
  const { state } = usePractice();
  if (state.practiceState === PracticeState.Individual || !membersAnswer.length) {
    return null;
  }
  return (
    <Box display="flex" flexDirection="column" pt="2rem" pb="1rem" gap="0.5rem">
      <Typography>Group Answer </Typography>
      {membersAnswer?.map(
        (item) =>
          item.answer && (
            <UserDataView key={item.student_id} user={item} data={item.answer} />
          ),
      )}
    </Box>
  );
};

export default GroupDataView;
