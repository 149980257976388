import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TeacherRegisterStep from '../../containers/TeacherRegister/TeacherRegisterStep';
import {
  RegisterItemWrapper,
  RegisterItem,
  RegisterTitle,
  RegisterButtonRight,
  ButtonStyled,
} from '../StudentRegister/StudentRegister.style';

export default class TeacherRegister extends PureComponent {
  static propTypes = {
    shouldLogout: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    isLoadingStep: PropTypes.bool.isRequired,
  };

  renderLoginButton = () => {
    const { shouldLogout, logout } = this.props;
    if (shouldLogout) {
      return (
        <ButtonStyled small onClick={logout}>
          Logout
        </ButtonStyled>
      );
    }
    return (
      <Link to="/login">
        <ButtonStyled small>Login</ButtonStyled>
      </Link>
    );
  };

  render() {
    const { isLoadingStep, shouldLogout } = this.props;

    if (isLoadingStep) {
      return <div>Getting things ready for you!</div>;
    }

    return (
      <RegisterItemWrapper>
        <RegisterItem>
          <RegisterTitle>
            <img src="/images/bg-login.jpg" alt="bg-login" />
            <RegisterButtonRight>
              {!shouldLogout && <p>Already have an account ?</p>}
              {this.renderLoginButton()}
            </RegisterButtonRight>
          </RegisterTitle>
          <TeacherRegisterStep />
        </RegisterItem>
      </RegisterItemWrapper>
    );
  }
}
