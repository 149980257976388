/* eslint-disable import/prefer-default-export */

import React from 'react';
import {
  useGetExamDetail,
  useGetExamResult,
  useGetExamScaledScore,
} from '@components/Component/Exams/hooks/query';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import Tooltip from '@components/Atoms/Tooltip';
import {
  filterCompletedExamSectionsSubScore,
  getAverageOverAllScore,
  groupExamSectionsByAssessmentNode,
  useHasSectionAllCourseExams,
} from '../hooks/helpers';
import {
  ExamDetailsObject,
  ExamSectionObject,
  IExamResults,
} from '../ExamScoreCard/types';

const TotalScoreHeader = () => (
  <Tooltip title="Total score will be displayed once the student completes all exam sections.">
    <span>Total Score</span>
  </Tooltip>
);
export const nameColumn = {
  id: 'name',
  headerTitle: 'Name',
  relatedKey: 'name',
  defaultValue: '--',
  isSortable: true,
  style: {
    minWidth: '8rem',
  },
};
export const totalScoreColumn = {
  id: 'totalScore',
  headerTitle: <TotalScoreHeader />,
  relatedKey: 'overAllScaledScore',
  defaultValue: '--',
  style: {
    textAlign: 'center',
    width: '10rem',
  },
};
export const scoreNodeColumnBase = {
  defaultValue: '--',
  headerTitle: '',
  style: {
    textAlign: 'center',
    width: '10rem',
  },
};

interface IRow {
  id: number;
  name: string;
  overAllScaledScore: number | null;
  [key: string]: any;
}

export const useExamScoreRowData = (
  examDetails?: ExamDetailsObject,
  usersResult?: IExamResults[],
) => {
  const examSectionsList: Array<ExamSectionObject> | undefined =
    examDetails?.exam?.sections;
  const examSectionsCount: number = examSectionsList?.length ?? -1;
  const sectionStudents = useSectionStudentWithFilter();
  const groupedExamSections = React.useMemo(
    () => groupExamSectionsByAssessmentNode(examSectionsList),
    [examSectionsList],
  );

  const rows: IRow[] = React.useMemo(() => {
    if (!usersResult) {
      return [];
    }
    const updatedStudentRows = sectionStudents.map((student) => {
      const defaultRow = {
        id: student.id,
        name: `${student.last_name} ${student.first_name}`,
        overAllScaledScore: null,
      } as unknown as IRow;
      const userResult = usersResult.find((usr) => usr.user === student.id);
      if (!userResult) {
        return defaultRow;
      }
      const filteredScoreObject = filterCompletedExamSectionsSubScore(
        groupedExamSections,
        [userResult],
      );
      if (!filteredScoreObject) {
        return defaultRow;
      }
      const flatFilteredScoreObject = Object.entries(filteredScoreObject).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value?.[0]?.score?.scaled_score,
        }),
        {},
      );
      const overAllScaledScore = getAverageOverAllScore([userResult], examSectionsCount);
      const row = {
        id: student.id,
        name: `${student.last_name} ${student.first_name}`,
        ...flatFilteredScoreObject,
        overAllScaledScore,
      };
      return row;
    });

    return updatedStudentRows;
  }, [usersResult, sectionStudents, groupedExamSections]);
  return rows;
};

export const useExamScoreColumns = () => {
  const { data: examScaledScore } = useGetExamScaledScore();
  const hasSectionAllCourseExams = useHasSectionAllCourseExams();

  const columns = React.useMemo(() => {
    if (!examScaledScore?.subscores && hasSectionAllCourseExams) {
      return [nameColumn, totalScoreColumn];
    }
    const scoringColumns =
      examScaledScore?.subscores.map((ele: any) => {
        const scoreObject = { ...scoreNodeColumnBase, ...ele };
        if (scoreObject.score) {
          delete scoreObject.score;
        }
        scoreObject.headerTitle = scoreObject.name;
        scoreObject.relatedKey = scoreObject.id;
        scoreObject.defaultValue = '--';
        return scoreObject;
      }) || [];
    if (!hasSectionAllCourseExams) {
      return [nameColumn, ...scoringColumns];
    }
    return [nameColumn, ...scoringColumns, totalScoreColumn];
  }, [examScaledScore]);
  return columns;
};

export const useExamScoreRows = () => {
  const { data: usersResult } = useGetExamResult();
  const { data: examDetails } = useGetExamDetail();
  const rows = useExamScoreRowData(examDetails, usersResult);

  return rows;
};
