/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  SkipDialogueItemWrap,
  SkipDialogueItem,
  SkipDialogueTitle,
  MainContext,
  ImageWrapper,
  CourseManagementImageWrapper,
  SectionCodeImageWrapper,
  ButtonWrapper,
  BoldText,
} from './SkipDialogueModal.style';
import {
  ButtonStyled,
} from '../SetupYourCourse/SetupYourCourse.style';

const SkipDialogueModal = (props) => (
  <SkipDialogueItemWrap>
    <SkipDialogueItem>
      <SkipDialogueTitle>
        <h1>You can always come back to finish your setup</h1>
        <MainContext>
          You do need a section to begin teaching or you can join another teacher&apos;s existing section.
        </MainContext>
        <MainContext isLeft>
          Create a new section and Invite teachers and students in the
          <BoldText>&nbsp;Course Management&nbsp;</BoldText>
          page.
        </MainContext>
        <ImageWrapper>
          <CourseManagementImageWrapper alt="" src="/images/119119fd-93cf-428e-9e62-c273f86a32af.png" />
        </ImageWrapper>
        <MainContext isLeft>
          Or join another teacher&apos;s section with a
          <BoldText>&nbsp;section code</BoldText>
          .
        </MainContext>
        <ImageWrapper>
          <SectionCodeImageWrapper alt="" src="/images/5948e029-598b-4429-9767-5196e00015f3.png" />
        </ImageWrapper>

        <ButtonWrapper isSectionCreationModal>
          <ButtonStyled
            marginTop
            isSectionCreationModal
            isNotMargin
            isContinueModal
            onClick={props.onClose}
          >
            Got It.
          </ButtonStyled>
        </ButtonWrapper>
      </SkipDialogueTitle>
    </SkipDialogueItem>
  </SkipDialogueItemWrap>
);

SkipDialogueModal.propTypes = {
  onClose: PropTypes.func,
};

export default SkipDialogueModal;
