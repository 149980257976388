import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '../Box';

const SortIconWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  '& .MuiSvgIcon-root': {
    fontSize: '1.75rem',
    margin: '-0.41em',
    paddingInlineEnd: '0.375em',
    cursor: 'pointer',
  },
};

type SortIconProps = {
  isAscending: boolean;
  isDescending: boolean;
};

const SortIcon = ({ isAscending, isDescending }: SortIconProps) => (
  <Box sx={SortIconWrapperStyle} component="span">
    <ArrowDropUpIcon sx={{ color: isAscending ? 'black' : 'gray' }} />
    <ArrowDropDownIcon sx={{ color: isDescending ? 'black' : 'gray' }} />
  </Box>
);

export default SortIcon;
