import axios from 'axios';
import { handleError } from './utils';

export function noTypeLogin(email, password) {
  const url = '/api/auth/token/obtain/';
  return axios
    .post(url, {
      email,
      password,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getTeacherToken(email, password) {
  const url = '/api/auth/teacher/token/obtain/';
  return axios
    .post(url, {
      email,
      password,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getStudentToken(email, password) {
  const url = '/api/auth/student/token/obtain/';
  return axios
    .post(url, {
      email,
      password,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function handleRefreshToken(refresh) {
  const url = '/api/auth/token/refresh/';
  return axios
    .post(url, { refresh })
    .then((response) => ({ response }))
    .catch(handleError);
}

export const verifyEmail = (key) => {
  const url = '/api/auth/verify-email/';
  return axios
    .post(url, { key })
    .then((response) => ({ response }))
    .catch(handleError);
};

export const approveJoinCourseRequest = (uid, key) => {
  const url = '/api/auth/approve-invite/';
  return axios
    .post(url, { uid, key })
    .then((response) => ({ response }))
    .catch(handleError);
};

export function confirmInviteStudent(uid, key) {
  const url = '/api/auth/verify-invite/';
  return axios
    .post(url, { uid, key })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function forgotEmail(forgotEmailData) {
  const url = '/api/auth/forgot-email/';
  return axios
    .post(url, {
      first_name: forgotEmailData.firstName,
      last_name: forgotEmailData.lastName,
      section_code: forgotEmailData.sectionCode,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function newPassword(passwordData) {
  const url = '/api/auth/password-reset-confirm/';
  return axios
    .post(url, {
      new_password1: passwordData.password,
      new_password2: passwordData.confirmPassword,
      uid: passwordData.uid,
      token: passwordData.token,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function resetPassword(email) {
  const url = '/api/auth/reset-password/';
  return axios
    .post(url, {
      email,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function cleverLogin(code) {
  const url = '/api/social/clever/login/';
  return axios
    .post(url, {
      code,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function schoologyLogin(code) {
  const url = '/api/social/schoology/login/';
  return axios
    .post(url, {
      code,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function classlinkLogin(code) {
  const url = '/api/social/classlink/login/';
  return axios
    .post(url, {
      code,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function cleverConnect(code) {
  const url = '/api/social/clever/connect/';
  return axios
    .post(url, {
      code,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function classlinkConnect(code) {
  const url = '/api/social/classlink/connect/';
  return axios
    .post(url, {
      code,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function SchoologyConnect(code) {
  const url = '/api/social/schoology/login/';
  return axios
    .post(url, {
      code,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function resendVerificationEmail(email) {
  const url = "/api/auth/resend-confirmation-email/";
  return axios
    .post(url, {
      email,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function verificationEmail(token) {
  const url = "/api/auth/verification-email/";
  return axios
    .post(url, {
      token,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function changeEmail(email, uid) {
  const url = "/api/auth/update-email/";
  return axios
    .post(url, {
      email,
      uid,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}
export function getHubSpotToken() {
  const url = "/api/auth/hubspot/";
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
