import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import SectionInfo from '../../components/SectionInformation/SectionInfo';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import { selectors as userSelectors } from '../../reducers/user';

import {
  selectors as CourseTeacherSelectors,
} from '../../reducers/courseTeachers';

import {
  actions as scheduleActions,
} from '../../reducers/sectionSchedule';
import { selectors as SectionExamSelectors } from '../../reducers/sectionExam';

import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';

export const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);
  const ownerIds = CourseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find(id => id === userId);
  const sectionId = _get(match, 'params.sectionId', null);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);
  const implementationFlow = _get(sectionDetail, 'implementation_flow', '');
  const isUpdate = _get(state, 'sections.isUpdate', false);
  const examSessions = SectionExamSelectors.getExamSessionList(state, sectionId);


  const isSectionEditSuccessful = SectionSelectors.isSectionEditSuccessful(state);

  return {
    courseId,
    allSections: SectionSelectors.getAllTeachersCourseSection(state),
    isOwner,
    curriculumId,
    subjectsById: CurriculumSelectors.getSubjectListById(state, curriculumId),
    subjects: CurriculumSelectors.getSubjectList(state, curriculumId),
    sectionDetail,
    sectionId,
    isLessons: implementationFlow.lessons,
    isPractice: implementationFlow.practice,
    isReview: implementationFlow.review,
    isWorksheet: implementationFlow.worksheet,
    subjectList: SectionSubjectSelectors.getSectionSubjectList(state),
    examSessions,
    isUpdate,
    courseData,
    curriculumDetail,
    isSectionEditSuccessful,
  };
};

export const mapDispatchToProps = {
  getAllSectionsByCourse: SectionActions.getAllSectionsByCourse,
  getCurriculumSubjectList: CurriculumActions.getCurriculumSubjectList,
  sectionsUpdate: SectionActions.sectionsUpdate,
  deactivateSection: SectionActions.deactivateSection,
  sectionScheduleGet: scheduleActions.sectionScheduleGet,
  updateSectionUnits: SectionActions.updateSectionUnits,
  sectionEditReset: SectionActions.sectionEditReset,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionInfo));
