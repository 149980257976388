/* eslint-disable camelcase */
import React from 'react';
import { QUESTION_TYPES } from '@utils/enums';
import AnswerMultiChoice from './AnswerMultiChoice';
import AnswerShortText from './AnswerShortText';
import AnswerGridChoice from './AnswerGridChoice';

type Props = {
  question_format: string | number;
};

const factoryOptions = {
  [QUESTION_TYPES.SHORT_ANSWER]: AnswerShortText,
  [QUESTION_TYPES.MULTIPLE_CHOICES]: AnswerMultiChoice,
  [QUESTION_TYPES.GRID_IN]: AnswerGridChoice,
};

const AnswerFactory = ({ question_format }: Props) => {
  const Component = React.useMemo(
    () => factoryOptions[question_format],
    [question_format],
  );
  return <Component />;
};

export default React.memo(AnswerFactory);
