/* eslint-disable react/display-name */

import React, { Component, ErrorInfo } from 'react';

type ErrorBoundaryProps = {};

type ErrorBoundaryStates = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};
const withErrorBoundary = <P extends ErrorBoundaryProps>(
  WrappedComponent: React.ComponentType<P>,
  errorMessage: string,
) =>
  class extends Component<P, ErrorBoundaryStates> {
    constructor(props: any) {
      super(props);
      // eslint-disable-next-line react/no-unused-state
      this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      this.setState({
        error,
        // eslint-disable-next-line react/no-unused-state
        errorInfo,
      });
    }

    render(): any {
      const { error } = this.state;
      if (error !== null) {
        return <h2>{errorMessage}</h2>;
      }
      // Normally, just render children
      return <WrappedComponent {...this.props} />;
    }
  };
export default withErrorBoundary;
