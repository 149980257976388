import styled, { css } from 'styled-components';

export const AttendanceTableWrap = styled.div`
  padding: 0em 1.5em;
  h1 {
    font-family: 'MontserratSemiBold';
  }
`;

export const AttendanceCountWrap = styled.div`
  display: flex;
  align-items: center;
  padding: .5em 1em;
  border-radius: .5em;
  margin-right: 1em;
  background-color: ${props => props.theme.colorStyled.ColorLightOrange};
  box-shadow: rgba(156, 115, 12, 0.42) 0px 0px 3px, rgba(210, 149, 14, 0.52) 0px 0;
  font-size: ${props => props.theme.fontSize.SmallerFontSize};
  span {
    margin-left: .5em;
  }
  &:hover {
    opacity: 0.6;
  }
`;

export const AttendanceStatus = styled.div`
  padding: 1em;
  margin: 0 .5em;
  border-radius: 100%;
  width: 2.5em;
  height: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colorStyled.ColorWhite};
  background-color: ${props => props.theme.colorStyled.ColorSkilLevelOne};
  position: relative;
  cursor: pointer;
  ${({ attended }) => attended && css`
    background-color: ${props => props.theme.colorStyled.ColorSkilLevelTwo};
  `}
  ${({ defaultType }) => defaultType && css`
    background-color: ${props => props.theme.colorStyled.ColorSecond};
  `}
`;

export const AttendancePreviousIcon = styled.i`
  font-weight: bold;
  font-size: ${props => props.theme.fontSize.HeadingH1FontSize};
`;

export const AttendancePopupWrap = styled.div`
  position: absolute;
  left: -4.25em;
  top: 4em;
  border-radius: 1em;
  z-index: 2;
  width: 10em;
  text-align: left;
  color: ${props => props.theme.colorStyled.ColorBlack};
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  &:after {
    content: '';
    position: absolute;
    right: calc(50% - 1.5em);
    top: -1em;
    width: 0em;
    height: 0em;
    border-left: 1em solid transparent;
    border-right: 1em solid transparent;
    border-bottom: 1em solid ${props => props.theme.colorStyled.ColorWhite};
  }
`;

export const AttendancePopupItem = styled.div`
  padding: .75em 1em;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  display: block;
  cursor: pointer;
  &:first-child {
    border-radius: 1em 1em 0em 0em;
  }
  &:last-child {
    border-bottom: none;
    border-radius: 0em 0em 1em 1em;
  }
  &:hover {
    background-color: ${props => props.theme.colorStyled.ColorBtnDark};
  }
`;

export const AttendanceReportRechartWrap = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    width: fill-available;
  }
`;

export const SelectDayChartWrap = styled.div`
  display: flex;
  .react-datepicker-wrapper {
    input {
      width: 9em;
      background: ${props => props.theme.colorStyled.ColorWhite};
      color: ${props => props.theme.colorStyled.ColorPrimary};
      font-size: ${props => props.theme.fontSize.MediumFontSize};
      border: 1px solid #7d7d7d;
      padding: 0.5em 1em;
      border-radius: 0.325em;
      margin-right: 1em;
      cursor: pointer;
      outline: none;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
      margin-bottom: .5em;
    }
    > div {
      &:last-child {
        .react-datepicker__triangle {
          left: auto;
          right: 50px;
        }
      }
    }
    .react-datepicker-wrapper {
      flex: 1;
      &:first-child {
        margin-right: 1em;
      }
      div {
        width: 100%;
      }
      input {
        width: 100%;
        margin-right: 0;
      }
    }
    margin-bottom: .5em;
  }
`;

export const ChartActionWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0em;
  div:last-child {
    margin-right: 0
  }
  @media (max-width: 768px) {
    & {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
`;

export const WeeklyButton = styled.div`
  display: flex;
  align-items: center;
  padding: .575em;
  opacity: 1;
  background: ${props => props.theme.colorStyled.ColorWhite};
  color: ${props => props.theme.colorStyled.ColorPrimary};
  font-size: ${props => props.theme.fontSize.MediumFontSize};
  border: 1px solid #7d7d7d;
  padding: 0.375em 1em;
  border-radius: 0.325em;
  margin-right: 1em;
  cursor: pointer;
  &:hover {
    opacity: .7;
  }
  @media (max-width: 768px) {
    & {
      flex: 1;
    }
  }
`;

export const DailyButton = styled(WeeklyButton)``;

export const TooltipWrap = styled.div`
  border-radius: .5em;
  box-shadow: rgba(156, 115, 12, 0.42) 0px 0px 1px, rgba(210, 149, 14, 0.52) 0px 0;
  padding: 1em;
  background: #fff;
`;

export const TooltipDate = styled.div`
  margin-bottom: .5em;
`;

export const TooltipValue = styled.div`
  color: #4d72bb;
`;
