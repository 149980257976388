/* eslint-disable arrow-body-style */
import React from 'react';
import Box from '@components/Atoms/Box';
import Skeleton from '@components/Atoms/Skeleton';

const ActivityFactorySkeleton = () => {
  return (
    <div data-testid="Activity-Factory-skelton">
      <Box
        component="div"
        sx={{
          padding: '2rem',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '4rem',
          width: '100%',
          '& span': {
            transform: 'none',
          },
        }}
      >
        <Skeleton height="9rem" width="100%" />
        <Box
          sx={{
            height: '24.5rem',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '0.75rem',
          }}
        >
          <Box sx={{ alignSelf: 'flex-end', mb: 1 }}>
            <Skeleton height="2rem" width="4rem" />
          </Box>
          <Skeleton height="3rem" width="100%" />
          <Skeleton height="20rem" width="100%" />
        </Box>
      </Box>
    </div>
  );
};

export default ActivityFactorySkeleton;
