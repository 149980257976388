import styled, { css } from 'styled-components';

export const DropDownMenuContainer = styled.div`
  position: absolute;
  width: max-content;
  background: ${props => props.theme.colorStyled.ColorWhite};
  margin-top: 0.75em;
  border-radius: 0.5em;
  box-shadow: 2px 2px 12px #a3adba80;
  border: 1px solid ${props => props.theme.colorStyled.ColorBoxShadow};
  z-index: 9;
  max-height: 20em;
  overflow: auto;
  right: 0;
  > :first-child {
    margin-top: 16px;
  }
  > :last-child {
    margin-bottom: 20px;
  }

  ${({ isDropDownVisible }) => !isDropDownVisible && css`
    display: none;
  `}
`;

export const DropDownOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 30px 4px 12px;
  cursor: pointer;
  > i {
    margin-right: 4px;
  }
  > :first-child {
    margin-left: 0.5em;
  }
  &:hover {
    background-color: ${props => props.theme.colorStyled.ColorBgContent};
    border-color: ${props => props.theme.colorStyled.ColorBgContent};
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    &:hover {
      background-color: unset;
    }
  `}
`;

export const DropDownIcon = styled.img`
  color: ${props => props.theme.colorStyled.ColorOrange};
  margin-right: 6px;
  height: 15px;
  width: 15px;
`;

export const DropDownLabel = styled.span`
  font-family: 'MontserratLight';
  font-weight: 600;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  > i {
    color: ${props => props.theme.colorStyled.ColorBgDefault};
    position: absolute;
    top: 15px;
    right: 10px;
  }

  > button {
    font-size: ${props => props.theme.fontSize.SmallFontSize};
    width: fit-content;
  }
`;

export const MessageText = styled.span`
  font-family: 'MontserratRegular';
  font-size: ${props => props.theme.fontSize.SmallFontSize};
  line-height: 1.5em;
  margin-top: 1em;
`;
