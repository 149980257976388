import React from 'react';
import Box from '@components/Atoms/Box';
import { useMappedAttendance } from './hooks/helpers';
import AttendanceCardHeader from './AttendanceCardHeader';
import AttendanceListWrapper from './AttendanceListWrapper';

interface LessonAttendanceProps {
  onLessonChange?: (lessonId: string | number, status) => void;
}

const LessonAttendance = ({ onLessonChange }: LessonAttendanceProps) => {
  const lessonAttendanceList = useMappedAttendance();

  return (
    <Box
      data-testid="lesson-attendance"
      sx={{
        height: '100%',
        backgroundColor: '#ffffff',
        marginInline: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AttendanceCardHeader
        studentsList={lessonAttendanceList}
        onLessonChange={onLessonChange}
      />
      <AttendanceListWrapper
        studentsList={lessonAttendanceList}
        onLessonChange={onLessonChange}
      />
    </Box>
  );
};

export default LessonAttendance;
