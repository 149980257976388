export interface questionChoice {
  id: number;
  order: number;
  asset: {
    text: string;
    image: string;
  };
}
export enum QuestionFormat {
  ShortText = 0,
  MultiChoice = 2,
  GridIn = 4,
}

type AssetType = {
  id: number;
  asset_type: string;
  asset_type_code: number;
  text: string;
  image: string | null;
};

export interface questionType {
  id: number;
  section?: number | null;
  index: number;
  question: {
    question_format: number;
    prompt: {
      text: string;
      image: string | null;
    };
    choices: questionChoice[];
    correct_choices: string | string[];
    shared_assets: AssetType[];
  };
  response?: string | null;
}
export interface answerType {
  value: string | number;
  exam_question_id: number;
  response_time: number;
}

export interface StudentType {
  id: number;
  student_name: string;
  status: number;
  score: string;
  previous_exam_question_id: number | null;
  previous_choice_id: number | null;
  student_id: number;
  online_response_id: number;
  online_section_activity: number;
  group_id: number;
}

export interface GroupAnswerType {
  question_id: number;
  answer?: string | number;
  group_id: number | string;
  student_id: number;
}
export interface GroupAnswerCombined extends GroupAnswerType {
  student_name?: string;
  [key: string]: any;
}

export enum PracticeState {
  NotStarted = 0,
  Individual = 1,
  Group = 2,
}
export type PracticeStateType = PracticeState;
export type lockedQuestionType = {
  [id: number]: {
    question_id: string;
    answer_id: string;
  };
};

export type GroupActivityType = {
  id: number;
  group_status: number;
  students: StudentType[];
  groups_session_status: number;
  time_activity_started: Date;
  duration: number;
  locked_questions: lockedQuestionType;
  group_answers: GroupAnswerType[];
};
export type GroupActivityStatusType = {
  id: number;
  score: string;
  section_activity: number;
  student: StudentType;
  student_ids: number[];
};
export type ReportModalState = {
  questionId: number;
  type: 'individual' | 'group';
};

export type stateType = {
  currentQuestionOrder: number;
  practiceAnswers: {
    [key: string]: answerType;
  };
  lastTime: number;
  groupAnswers: Array<GroupAnswerType>;
  studentsFinishedIndividual: Array<number | string>;
  practiceState: PracticeState;
  isIndividualWaitingDialogOpen: boolean;
  isGroupInstructionDialogOpen: boolean;
  lockedQuestions: lockedQuestionType;
};
export type createGroupAnswersType = {
  groupMembers: StudentType[];
  questions: questionType[];
  userId: string;
  groupId: string;
  groupAnswers: GroupAnswerType[];
  individualAnswers: answerType[];
};
export type GroupSubmissionAnswerType = {
  student_id: string;
  text_answer: string;
  locked: true;
};
export type GroupQuestionsAnswersType = {
  [questionId: string]: {
    students_answers: GroupSubmissionAnswerType[];
    isCorrect: boolean;
    text_answer: string;
  };
};
export type olpSubmitScoreType = {
  group_id: string;
  group_questions_answers: GroupQuestionsAnswersType;
  group_score: number;
  student_score: {
    score: number;
    student_id: string;
  };
};
