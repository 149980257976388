/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { FieldArray, Form } from 'formik';
import FormInput from '../../Generals/FormInput/FormInput';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
} from '../SubscriptionForm/SubscriptionForm.style';
import {
  FreeExperienceWrapper,
  ButtonWrapper,
  ButtonStyled,
  InviteTeachersText,
} from '../SetupYourCourse/SetupYourCourse.style';
import {
  AddTeacherWrapper,
  AddTeacherBtn,
  FieldWrapper,
  ErrorText,
} from './TeacherInvitationModal.style';
import { SubtitleWrapper } from '../SectionCreationModal/SectionCreationModal.style';
import { SUBSCRIPTION_TYPE } from '../../../utils/enums';

class TeacherInvitationModal extends Component {
  render() {
    const {
      teacherList,
      courseDetail,
      subscriptionData,
      isInRegisterFlow,
      onClose,
      openCreateSectionModal,
      formikError,
    } = this.props;
    const { name: courseName, subscription = subscriptionData } = courseDetail;
    const {
      max_student_count: maxStudentCount,
      max_sections: maxSection,
      subscription_type: subscriptionType,
    } = subscription || {};
    const hasBeenAddedTeacher = Array.isArray(teacherList) && teacherList.length > 0;
    return (
      <Form>
        <CreateOrJoinItemWrap>
          <CreateOrJoinItem>
            <CreateOrJoinTitle>
              <h1>Invite Teachers</h1>
              <p>Invite teachers to course to create their own sections.</p>
              <FreeExperienceWrapper>
                {courseName}
                <br />
                Full Length Exams
                <br />
                {SUBSCRIPTION_TYPE[subscriptionType]}
                <br />
                {`Max ${maxStudentCount} students`}
                <br />
                {`Max ${maxSection} section`}
              </FreeExperienceWrapper>
              <AddTeacherWrapper hasBeenAddedTeacher={hasBeenAddedTeacher}>
                <FieldArray
                  name="teachers"
                  render={(arrayHelpers) => (
                    <React.Fragment>
                      {teacherList !== []
                        ? teacherList.map((teacher, index) => (
                          <FieldWrapper key={index}>
                            <FormInput
                              type="text"
                              name={`teachers.${index}.first_name`}
                              placeholder="First Name"
                            />
                            <FormInput
                              type="text"
                              name={`teachers.${index}.last_name`}
                              placeholder="Last Name"
                            />
                            <FormInput
                              type="text"
                              name={`teachers.${index}.email`}
                              placeholder="Email"
                            />
                            <i
                              className="chalktalk-close"
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </FieldWrapper>
                        ))
                        : ''}
                      <AddTeacherBtn
                        onClick={() => arrayHelpers.push({
                          first_name: '',
                          last_name: '',
                          email: '',
                        })}
                      >
                        <i className="chalktalk-plus" />
                        <span>Add Teacher</span>
                      </AddTeacherBtn>
                    </React.Fragment>
                  )}
                />
              </AddTeacherWrapper>
              <ButtonWrapper>
                <ButtonStyled isSubmittedBtnInModal type="submit">
                  Send invites
                  {isInRegisterFlow && (
                    <InviteTeachersText>
                      Next: Create Sections
                    </InviteTeachersText>
                  )}
                </ButtonStyled>
                {!hasBeenAddedTeacher && (
                  <ErrorText>{formikError.teachers}</ErrorText>
                )}
                {isInRegisterFlow && (
                  <SubtitleWrapper onClick={openCreateSectionModal}>
                    <InviteTeachersText>
                      <a>Skip and I will do this later</a>
                    </InviteTeachersText>
                  </SubtitleWrapper>
                )}
              </ButtonWrapper>
            </CreateOrJoinTitle>
          </CreateOrJoinItem>
        </CreateOrJoinItemWrap>
      </Form>
    );
  }
}

export default TeacherInvitationModal;
