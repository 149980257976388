import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { useGetStandards } from '@apis/standards';
import { useCurrentSubject } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useCourseById } from '@reducers/courses/hooks';
import {
  useGetLessonDetail,
  useGetLessonStandard,
} from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useStandardName } from '@components/Component/Lesson/LessonContainer/hooks';
import Accordion from '@components/Atoms/Accordion';
import Typography from '@components/Atoms/Typography';
import Box from '@components/Atoms/Box';
import ShowIf from '@components/Atoms/ShowIf';
import { useParams } from 'react-router-dom';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import PacingGuideInfoCard from '@components/Component/Lesson/PacingGuide/PacingGuideInfoCard';
import {
  LessonManagementLeftWrap,
  LessonManagementRightWrap,
  LessonVideoTitlte,
  LessonVideoContent,
  LessonSubjectItem,
  LessonTitle,
  LessonList,
  LessonItem,
  LessonName,
} from '../LessonManagement/LessonManagement.style';
import Iframe from '../common/CustomIframe';

const SkillDetails = ({ skills }) => (
  <Box
    sx={{
      mb: '1rem',
    }}
  >
    <Accordion
      defaultExpanded
      title={
        <Typography
          variant="h3"
          sx={{ fontSize: '1rem', color: (theme) => theme.palette.primary.main }}
        >
          Skills Details
        </Typography>
      }
      accordionSx={{
        boxShadow: '#091e4240 0px 1px 1px, #091e424f 0px 0',
        border: '1px solid #f1f1f1',
      }}
      summarySx={{ fontSize: '1rem', color: 'black' }}
      content={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
          }}
        >
          {skills?.map((item, index) => (
            <Box
              key={item?.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '0.5rem',
                width: '100%',
              }}
            >
              <Typography variant="subtitle1">
                {`${index + 1}.  ${item?.name}`}
              </Typography>
            </Box>
          ))}
        </Box>
      }
    />
  </Box>
);

class PlanAndStandardTab extends Component {
  static propTypes = {
    lessonDetail: PropTypes.object.isRequired,
    courseStandard: PropTypes.object,
  };

  renderIframeLeft = () => {
    const { lessonDetail } = this.props;
    const pdfUrl = lessonDetail?.plan_url ?? '';
    return (
      <LessonManagementRightWrap>
        <LessonVideoTitlte>Lesson Plan</LessonVideoTitlte>
        <LessonVideoContent>
          <Iframe src={pdfUrl} showExtraLink />
        </LessonVideoContent>
      </LessonManagementRightWrap>
    );
  };

  renderLessonStandardItem = (item) => {
    const { code, description } = item;

    return (
      <LessonItem key={code}>
        <LessonName>{`${code} - ${description}`}</LessonName>
      </LessonItem>
    );
  };

  renderUnitItem = () => {
    const { standards, standardName } = this.props;
    if (!standards.length) {
      return null;
    }

    return (
      <LessonSubjectItem>
        <LessonTitle>
          <p>{standardName}</p>
        </LessonTitle>
        <LessonList>{standards?.map(this.renderLessonStandardItem)}</LessonList>
      </LessonSubjectItem>
    );
  };

  render() {
    const { lessonDetail, pacingGuideLesson } = this.props;
    return (
      <Box
        sx={{
          height: 'calc(100vh - 6.0625rem)',
          overflow: 'hidden',
          '@media (max-width: 1280px)': {
            overflow: 'auto',
          },
          py: '1.5rem',
          width: '100%',
          paddingInline: '1rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            '@media (max-width: 1280px)': {
              flexDirection: 'column-reverse',
              gap: '2rem',
            },
          }}
        >
          <LessonManagementLeftWrap>
            <PacingGuideInfoCard
              guideInfoText={pacingGuideLesson?.instruction}
              taskDeliveryText={pacingGuideLesson?.lesson_delivery}
            />
            <ShowIf If={!!lessonDetail?.skills?.length}>
              <SkillDetails skills={lessonDetail?.skills} />
            </ShowIf>
            {this.renderUnitItem()}
          </LessonManagementLeftWrap>
          {this.renderIframeLeft()}
        </Box>
      </Box>
    );
  }
}

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const standardName = useStandardName();
    const { courseId, lessonId } = useParams();
    const { data: LessonDetail } = useGetLessonDetail();
    const course = useCourseById(courseId);
    const currentSubject = useCurrentSubject();

    const { data } = useGetPacingGuideLessons(lessonId);
    const pacingGuideLesson = data?.[0];
    const { data: lessonStandards } = useGetLessonStandard();
    let standards = lessonStandards;
    if (!lessonStandards?.length) {
      const { data: lessonSkillStandards } = useGetStandards(course.syllabus, LessonDetail.skills, []);
      standards = lessonSkillStandards;
    }
    return (
      <WrappedComponent
        {...props}
        lessonDetail={LessonDetail}
        standards={standards}
        standardName={standardName}
        pacingGuideLesson={pacingGuideLesson}
      />
    );
  };
  return ComponentWithData;
};

export default withDataWrapper(PlanAndStandardTab);
