import styled, { css } from 'styled-components';

export const LeftSiderBarWrap = styled.section`
  position: fixed;
  top: 0em;
  left: -31.5em;
  width: 60%;
  height: 100vh;
  background: ${props => props.theme.colorStyled.ColorWhite};
  transition: all 300ms ease;
  opacity: 1;
  z-index: 3;
  padding: 1em 2em;
  overflow-y: auto;
  &:after {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    top: 0em;
    right: 0em;
    z-index: 1;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
    background: linear-gradient(to left,
      rgba(0, 0, 0, 0) 0px,
      rgba(0, 0, 0, 0.2) 0px,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0) 100%);
  }
  ${({ isClose }) => isClose && css`
    left: 6em;
  `};
  
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 480px) {
    width: 100%;
    padding-right: 7.5em;
`;

export const LeftSiderBarHeader = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0em;
`;
export const LeftSiderBarHeaderGroup = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 1.15em;
  text-align: center;
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
    width: 100%;
  }
`;

export const ActionShowHide = styled.span`
  width: 1.85em;
  height: 1.85em;
  cursor: pointer;
  font-weight: bold;
  fill: rgb(255, 255, 255);
  background:  #e1e1e1; // ${props => props.theme.colorStyled.ColorWhite};
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid #eaeaea;
  z-index: 999;
  box-shadow: 0px 0px 12px -5px ${props => props.theme.colorStyled.ColorBoxShadow};
  
  span {
    color: #737373;
    font-size: 1em;
  }
`;

export const LeftSideBarContentWrapper = styled.div``;
