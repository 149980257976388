/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import LessonContainer from '@components/Component/Lesson/LessonContainer';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { getSearchParamsObject } from '@utils/commonUtils';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import LessonBar from '../../containers/LessonBar';
import { ROLE_TYPE } from '../../utils/enums';
import {
  LessonEmptyWrap,
  LessonEmptyIcon,
  LessonEmptyData,
  LessonEmptyAction,
} from './LessonManagement.style';
import IWSider from '../InstructionalWalkThroughs/Components/IWSider';
import { WithIW } from '../InstructionalWalkThroughs/IWContextProvider';

const EmptyLesson = ({ isSubNavbarSubjectClose }) => {
  const pathNumber = Array.from(Array(3).keys());
  if (!isSubNavbarSubjectClose) {
    return null;
  }

  return (
    <LessonEmptyWrap>
      <LessonEmptyIcon className="chalktalk-book-stack">
        {pathNumber.map((number, index) => (
          <i className={`path${number}`} key={index} />
        ))}
      </LessonEmptyIcon>
      <LessonEmptyData>
        Select a subject to view the subject lesson and sections
      </LessonEmptyData>
    </LessonEmptyWrap>
  );
};
class LessonManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionIdSelected: null,
      tabIndex: LessonTabs.Lesson,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_isEqual(this.props, nextProps) || !_isEqual(this.state, nextState);
  }

  componentDidMount() {
    const { sectionId } = this.props;

    if (sectionId !== null) {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { sectionId, lessonId } = this.props;
    const { sectionId: prevSectionId, lessonId: prevLessonId } = prevProps;
    const { sessionIdSelected } = this.state;
    const { sessionIdSelected: prevSessionIdSelected } = prevState;

    if (
      (sectionId !== null && !_isEqual(sectionId, prevSectionId)) ||
      (lessonId !== prevLessonId && !!lessonId)
    ) {
      this.getInitialData();
    }
    if (sessionIdSelected !== prevSessionIdSelected) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ sessionIdSelected });
    }
  }

  // ======================================================

  getInitialData = () => {
    const {
      sectionId,
      sectionsStudentGet,
      getAllLessonActivity,
      userRole,
      sectionSkillsGet,
      history,
      lessonId,
      curriculumLessonsGetDetail,
    } = this.props;

    const queryObject = getSearchParamsObject(history.location.search);
    const tabIndex = queryObject.tab ?? LessonTabs.Lesson;
    this.setState({ tabIndex });

    if (userRole !== ROLE_TYPE.STUDENT) {
      sectionsStudentGet(sectionId);
    }
    if (lessonId) {
      curriculumLessonsGetDetail(lessonId);
    }
    sectionSkillsGet(sectionId);
    getAllLessonActivity(sectionId);
  };

  handleNavigationToLesson = (tabIndex) => {
    const { unitTree } = this.props;
    const { courseId, sectionId, subjectId } = this.props.match.params;
    const { units = [] } = unitTree;
    const unit = units[0] || {};
    const { lessons = [] } = unit;
    const lesson = lessons[0];
    return this.props.history.replace(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unit.id}/lesson/${lesson.id}?tab=${tabIndex}`,
    );
  };

  updateSessionId = (id) => {
    this.setState({
      sessionIdSelected: id,
      tabIndex: LessonTabs.Lesson,
    });
  };

  renderEmptySession = (tabIndex) => {
    const { history, match, isSubNavbarSubjectClose } = this.props;
    const { params } = match;
    const { courseId, sectionId } = params;
    const pathNumber = Array.from(Array(23).keys());

    if (isSubNavbarSubjectClose) {
      return null;
    }

    return (
      <LessonEmptyWrap>
        <LessonEmptyIcon className="chalktalk-schedule-calendar">
          {pathNumber.map((number, index) => (
            <i className={`path${number}`} key={index} />
          ))}
        </LessonEmptyIcon>
        <LessonEmptyData>
          <ul>
            <li>
              <i className="chalktalk-check-success" />
              Click&nbsp;
              <LessonEmptyAction
                type="button"
                onClick={() => this.handleNavigationToLesson(tabIndex)}
              >
                All Sessions
              </LessonEmptyAction>{' '}
              to view the curriculum and the full syllabus.
            </li>
            <li>
              <i className="chalktalk-check-success" />
              OR select an Upcoming Session
            </li>
            <li>
              <i className="chalktalk-check-success" />
              OR select&nbsp;
              <LessonEmptyAction
                type="button"
                onClick={this.props.toggleReviewSessionNavbar}
              >
                All Reviews
              </LessonEmptyAction>{' '}
              or an Upcoming Review
            </li>
            <li>
              <i className="chalktalk-check-success" />
              OR&nbsp;
              <LessonEmptyAction
                type="button"
                onClick={() =>
                  history.push(`/schedule/course/${courseId}/section/${sectionId}`)
                }
              >
                Go to schedule
              </LessonEmptyAction>
              &nbsp;to add a lesson or review sessions
            </li>
          </ul>
        </LessonEmptyData>
      </LessonEmptyWrap>
    );
  };

  render() {
    const {
      shouldRenderLessonDetail,
      unitDetail,
      lessonDetail,
      subjectDetail,
      isSideBarClose,
      courseDetail,
      sectionDetail,
      history,
      isSubNavbarSubjectClose,
    } = this.props;
    const breadcumbList = ['Lessons'];

    if (!_isEmpty(subjectDetail)) {
      breadcumbList.push(subjectDetail.display_name);
    }

    if (!_isEmpty(unitDetail)) {
      breadcumbList.push(unitDetail.name);
    }

    if (!_isEmpty(lessonDetail)) {
      breadcumbList.push(lessonDetail.name);
    }

    const queryObject = getSearchParamsObject(history.location.search);
    const tabIndex = queryObject.tab ?? LessonTabs.Lesson;

    return (
      <>
        <BreadcumbsFilter
          haveSub={isSideBarClose}
          breadcumbSubList={[courseDetail.name, sectionDetail.name]}
          breadcumbList={breadcumbList}
          mobileTitle="Lesson Management"
          isForIw
        />
        <IWSider />
        {shouldRenderLessonDetail && (
          <>
            <LessonBar updateSessionId={this.updateSessionId} />
            <LessonContainer />
          </>
        )}
        {!shouldRenderLessonDetail && (
          <EmptyLesson isSubNavbarSubjectClose={isSubNavbarSubjectClose} />
        )}
        {!shouldRenderLessonDetail && this.renderEmptySession(tabIndex)}
      </>
    );
  }
}

LessonManagement.propTypes = {
  sectionsStudentGet: PropTypes.func,
  shouldRenderLessonDetail: PropTypes.bool,
  subjectDetail: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
  match: PropTypes.shape().isRequired,
  unitDetail: PropTypes.shape(),
  lessonDetail: PropTypes.shape(),
  getAllLessonActivity: PropTypes.func,
  userRole: PropTypes.string,
  sectionId: PropTypes.any,
  courseId: PropTypes.any,
  isSideBarClose: PropTypes.bool,
  courseDetail: PropTypes.object,
  sectionDetail: PropTypes.any,
  isSubNavbarSubjectClose: PropTypes.bool,
  sectionSkillsGet: PropTypes.func,
};

export default WithIW(LessonManagement);
