/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormInput from '../../Generals/FormInput/FormInput';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
  OptionsCreateOrJoinWrapper,
  NextIconOptions,
  ContentInsideWellWrapper,
  LinkInsideWellWrapper,
} from './SubscriptionForm.style';
import { RegisterErrorMessage } from '../../StudentRegister/StudentRegister.style';

const initialValues = {
  subscriptionCode: '',
};

const validationSchema = Yup.object().shape({
  subscriptionCode: Yup.string().trim().required('Required'),
});

class SubscriptionForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
    };
  }

  handleCallback = (type, subscriptionData) => {
    const { openRequestInviteModal, openCreateCourseForm } = this.props;

    if (type === 'consume') {
      openRequestInviteModal(subscriptionData);
    } else if (type === 'error') {
      this.setState({
        errorMessage: subscriptionData,
      });
    } else {
      openCreateCourseForm();
    }
  }

  handleFreeExperience = () => {
    const { submitSubscriptionCode } = this.props;

    submitSubscriptionCode(true, null, this.handleCallback);
  }

  handleSubmit = (values) => {
    const { submitSubscriptionCode } = this.props;

    submitSubscriptionCode(
      false,
      values.subscriptionCode,
      this.handleCallback,
    );
  }

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>

  renderFormContent = (formProps) => {
    const { shouldDisplayFreeOption } = this.props;
    const { errorMessage } = this.state;
    return (
      <CreateOrJoinItemWrap>
        <CreateOrJoinItem>
          <CreateOrJoinTitle>
            <h1>Create or Join Course</h1>
            <p>Create or join using a school subscription code.</p>
            <p>Or try out the free experience and begin course today.</p>
            <OptionsCreateOrJoinWrapper>
              <ContentInsideWellWrapper>
                <FormInput
                  type="subscriptionCode"
                  name="subscriptionCode"
                  placeholder="Enter Subscription Code"
                />
              </ContentInsideWellWrapper>
              <NextIconOptions className="chalktalk-icon-next" onClick={formProps.handleSubmit} />
            </OptionsCreateOrJoinWrapper>
            {
              errorMessage && (
                <RegisterErrorMessage>
                  {errorMessage}
                </RegisterErrorMessage>
              )
            }
            {shouldDisplayFreeOption && (
              <span>
                Want to just check it out? Choose our&nbsp;
                <LinkInsideWellWrapper href="https://schools.chalktalk.com/meetings/gavin-laughlin/chalktalkmath?hsLang=en" target="_blank">Free Experience.</LinkInsideWellWrapper>
              </span>
            )}
          </CreateOrJoinTitle>
        </CreateOrJoinItem>
      </CreateOrJoinItemWrap>
    );
  }

  render() {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={this.renderFormContent}
      >
        <Form noValidate>
        </Form>
      </Formik>
    );
  }
}

SubscriptionForm.propTypes = {
  submitSubscriptionCode: PropTypes.func,
  openRequestInviteModal: PropTypes.func,
  openCreateCourseForm: PropTypes.func,
  shouldDisplayFreeOption: PropTypes.bool,
};

export default SubscriptionForm;
