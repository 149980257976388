import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { IframeWrapper, StyledIframe } from './CustomIframe.styles';
import printGoogleFile, { isGoogleDomain } from '../../../utils/printGoogleFile';
import { initializeGAPI } from '../../../utils/initializeGAPI';

const CustomIframe = (props) => {
  const [printLoading, setPrintLoading] = React.useState(false);
  const { src, showExtraLink, frameRef } = props;
  useEffect(() => {
    // TODO: Investigate why this is called here
    if (false) {
      initializeGAPI();
    }
  }, []);
  const handleClickPrint = React.useCallback(async () => {
    setPrintLoading(true);
    await printGoogleFile(src);
    setPrintLoading(false);
  }, [src]);
  return (
    <IframeWrapper>
      {showExtraLink && isGoogleDomain(src) && (
        <Button
          label="print"
          onClick={handleClickPrint}
          disabled={printLoading}
          variant="contained"
          color="primary"
        >
          {printLoading ? <span>Loading...</span> : <i className="chalktalk-printing" />}
        </Button>
      )}
      <StyledIframe {...props} ref={frameRef} />
    </IframeWrapper>
  );
};
//   loading={printLoading}

export default React.memo(CustomIframe);
