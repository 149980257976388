import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _maxBy from 'lodash/maxBy';
import _groupBy from 'lodash/groupBy';
import moment from 'moment';
import { selectors as sectionUnitSelector } from '../sectionUnit';
import { selectors as sectionSelector } from '../sections';
import { selectors as sectionSubjectSelector } from '../sectionSubjects';

// action definition
export const LESSON_ASSIGNED_ACTIVITIES_GET_ALL = 'lessonAssignedActivities/GET_ALL';
export const LESSON_ASSIGNED_ACTIVITIES_GET_ALL_SUCCESS = 'lessonAssignedActivities/GET_ALL_SUCCESS';
export const LESSON_ASSIGNED_ACTIVITIES_GET_ALL_FAIL = 'lessonAssignedActivities/GET_ALL_FAIL';

// selectors
const getLessonAssignedActivityList = ({ lessonAssignedActivities }) => lessonAssignedActivities.lessonAssignedActivityList;
const getAssignStatusByLessonId = ({ lessonAssignedActivities }, lessonId) => {
  const userAssigned = _get(lessonAssignedActivities, `assignedStatusByLessonId.${lessonId}`, null);
  const lastCompleted = _get(userAssigned, 'last_completed', null);
  const lastAssigned = _get(userAssigned, 'last_assigned', null);
  const newAssigned = _get(userAssigned, 'assigned', null);
  return newAssigned || (!lastCompleted && lastAssigned) || moment(lastAssigned).isAfter(moment(lastCompleted));
};

const getAssignStatusItemByUnit = ({ lessonAssignedActivities }, unitId) => _get(lessonAssignedActivities, `assignedStatusUnitPracticeByUnitId.${unitId}`, []);

const getAssignStatusByUnitPractice = ({ lessonAssignedActivities, ...restState }, unitId) => {
  const unitDetail = sectionUnitSelector.getUnitById(restState, unitId);
  const subjectId = _get(unitDetail, 'curriculum_subject', null);
  const subjectInfo = sectionSubjectSelector.getSubjectDetail(restState, subjectId);
  const subjectVersion = _get(subjectInfo, 'version', 1);
  const userAssignedList = _get(lessonAssignedActivities, `assignedStatusUnitPracticeByUnitId.${unitId}`, []);

  if (userAssignedList.length === 0) {
    return false;
  }

  const userAssigned = _maxBy(userAssignedList, item => new Date(item.last_assigned).getTime());

  const lastAssigned = _get(userAssigned, 'last_assigned', null);
  const newAssigned = _get(userAssigned, 'assigned', null);
  const lastCompleted = _get(userAssigned, 'last_completed', null);

  const assetsOfUnit = _get(unitDetail, 'assets', []) || [];
  const assetLength = assetsOfUnit.length;

  if (subjectVersion === 1) { // for old version
    return newAssigned || (!lastCompleted && lastAssigned) || moment(lastAssigned).isAfter(moment(lastCompleted));
  }
  // for new version

  const practiceSummaryOfUnit = sectionSelector.getPracticeSummaryOfUnit(restState, unitId);
  const practiceSummaryLength = practiceSummaryOfUnit.length;

  if (practiceSummaryLength < assetLength) {
    return true;
  }

  return newAssigned || practiceSummaryOfUnit.some((item) => moment(lastAssigned).isAfter(moment(item.last_activity)));
};

const getAssignStatusBySubjectId = ({ lessonAssignedActivities }, subjectId) => _get(lessonAssignedActivities, `assignedStatusBySubjectId.${subjectId}`, []);

const getAssignStatusBySubject = (state, subjectId) => {
  const assign = [];
  const assignStatusList = getAssignStatusBySubjectId(state, subjectId);
  const subjectInfo = sectionSubjectSelector.getSubjectDetail(state, subjectId);
  const subjectVersion = _get(subjectInfo, 'version', 1);
  const assignUnit = {};

  assignStatusList.forEach((item) => {
    const { unit: unitId, lesson_group: lessonGroup } = item;
    const unitDetail = sectionUnitSelector.getUnitById(state, unitId);
    const assetsOfUnit = _get(unitDetail, 'assets', []) || [];
    const assetLength = assetsOfUnit.length;
    const lastAssigned = _get(item, 'last_assigned', null);
    const newAssigned = _get(item, 'assigned', null);
    const lastCompleted = _get(item, 'last_completed', null);

    if (!assignUnit[unitId] && (lessonGroup || subjectVersion === 1)) {
      if (newAssigned || (!lastCompleted && lastAssigned) || moment(lastAssigned).isAfter(moment(lastCompleted))) {
        assign.push(item);
        if(!lessonGroup){
          assignUnit[unitId] = true;
        }
      }
    } else {
      const practiceSummaryOfUnit = sectionSelector.getPracticeSummaryOfUnit(state, unitId);
      const practiceSummaryLength = practiceSummaryOfUnit.length;

      if (!assignUnit[unitId]
        && (practiceSummaryLength < assetLength
        || (newAssigned
        || practiceSummaryOfUnit.some((reportItem) => moment(lastAssigned).isAfter(moment(reportItem.last_activity)))))
      ) {
        assign.push(item);
        if(!lessonGroup){
          assignUnit[unitId] = true;
        }
      }
    }
  });

  return assign;
};

const getAssignStatus= (state) => {
  const incomplete = [];
  const complete = [];
  const assignStatusList = getLessonAssignedActivityList(state);
  assignStatusList.forEach((item) => {
    const lastAssigned = _get(item, 'last_assigned', null);
    const newAssigned = _get(item, 'assigned', null);
    const lastCompleted = _get(item, 'last_completed', null);
      if (newAssigned || (!lastCompleted && lastAssigned) || moment(lastAssigned).isAfter(moment(lastCompleted))) {
        incomplete.push(item);
      }else{
        complete.push(item);
      }
  });
  return {complete: complete, incomplete: incomplete};
};

const getAssignCountBySubject = (state, subjectIds) => subjectIds.reduce((prevValue, subjectId) => {
  let count = 0;
  const assignStatusList = getAssignStatusBySubjectId(state, subjectId);
  const subjectInfo = sectionSubjectSelector.getSubjectDetail(state, subjectId);
  const subjectVersion = _get(subjectInfo, 'version', 1);
  const assignUnit = {};

  assignStatusList.forEach((item) => {
    const { unit: unitId, lesson_group: lessonGroup } = item;
    const unitDetail = sectionUnitSelector.getUnitById(state, unitId);
    const assetsOfUnit = _get(unitDetail, 'assets', []) || [];
    const assetLength = assetsOfUnit.length;
    const lastAssigned = _get(item, 'last_assigned', null);
    const newAssigned = _get(item, 'assigned', null);
    const lastCompleted = _get(item, 'last_completed', null);

    if (!assignUnit[unitId] && (lessonGroup || subjectVersion === 1)) {
      if (newAssigned || (!lastCompleted && lastAssigned) || moment(lastAssigned).isAfter(moment(lastCompleted))) {
        count += 1;
        if(!lessonGroup){
          assignUnit[unitId] = true; // flag that already count this unit
        }
      }
    } else {
      const practiceSummaryOfUnit = sectionSelector.getPracticeSummaryOfUnit(state, unitId);
      const practiceSummaryLength = practiceSummaryOfUnit.length;

      if (!assignUnit[unitId]
        && (practiceSummaryLength < assetLength // if the practice s
        || (newAssigned
        || practiceSummaryOfUnit.some((reportItem) => moment(lastAssigned).isAfter(moment(reportItem.last_activity)))))) {
        count += 1;
        if(!lessonGroup){
          assignUnit[unitId] = true; // flag that already count this unit
        }
      }
    }
  });

  return {
    ...prevValue,
    [subjectId]: count,
  };
}, {});

export const selectors = {
  getLessonAssignedActivityList,
  getAssignStatusByLessonId,
  getAssignStatusByUnitPractice,
  getAssignStatusItemByUnit,
  getAssignCountBySubject,
  getAssignStatusBySubjectId,
  getAssignStatusBySubject,
  getAssignStatus
};

// action creators
export const fetchLessonAssignedActivityList = (sectionId, lessonId) => ({
  type: LESSON_ASSIGNED_ACTIVITIES_GET_ALL,
  payload: {
    sectionId,
    lessonId,
  },
});

export const fetchLessonAssignedActivityListSuccess = (lessonAssignedActivityList) => ({
  type: LESSON_ASSIGNED_ACTIVITIES_GET_ALL_SUCCESS,
  payload: {
    lessonAssignedActivityList,
  },
});

export const fetchLessonAssignedActivityListFailed = (errorMsg) => ({
  type: LESSON_ASSIGNED_ACTIVITIES_GET_ALL_FAIL,
  payload: {
    errorMsg,
  },
});

export const actions = {
  fetchLessonAssignedActivityList,
  fetchLessonAssignedActivityListSuccess,
  fetchLessonAssignedActivityListFailed,
};

// reducers

const initialState = {
  lessonAssignedActivityList: [],
  isLoading: null,
  errorMsg: null,
  assignedStatusByLessonId: {},
  assignedStatusUnitPracticeByUnitId: {},
  assignedStatusBySubjectId: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LESSON_ASSIGNED_ACTIVITIES_GET_ALL: {
      return {
        ...state,
        isLoading: true,
        errorMsg: false,
      };
    }

    case LESSON_ASSIGNED_ACTIVITIES_GET_ALL_SUCCESS: {
      const { lessonAssignedActivityList } = action.payload;
      const newLessonAssignedActivityList = Array.isArray(lessonAssignedActivityList) ? lessonAssignedActivityList : [lessonAssignedActivityList];
      const assignedStatusByLessonId = _keyBy(newLessonAssignedActivityList, 'lesson_group');
      const assignedStatusBySubjectId = _groupBy(newLessonAssignedActivityList, 'subject');
      const assignedStatusUnitPracticeByUnitId = newLessonAssignedActivityList.reduce((prevValue, item) => {
        const { unit } = item;

        if (item.lesson_group) {
          return prevValue;
        }

        return {
          ...prevValue,
          [unit]: [
            ..._get(prevValue, unit, []),
            item,
          ],
        };
      }, {});

      return {
        ...state,
        isLoading: false,
        lessonAssignedActivityList: newLessonAssignedActivityList,
        assignedStatusByLessonId,
        assignedStatusUnitPracticeByUnitId,
        assignedStatusBySubjectId,
      };
    }

    case LESSON_ASSIGNED_ACTIVITIES_GET_ALL_FAIL: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMsg,
      };
    }

    default: {
      return state;
    }
  }
}
