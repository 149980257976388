import React from 'react';
import ShowIf from '@components/Atoms/ShowIf';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Chip from '@components/Atoms/Chip';

const HighlightSelector: React.FC<{
  items: unknown[];
  getItemId: (item: unknown) => string;
  getItemLabel: (item: unknown) => string;
  primaryText: string;
  secondaryText: string;
  onSelect?: (item: unknown) => void;
  children: React.ReactNode;
  disabled?: boolean;
  tags?: any;
}> = ({
  items,
  getItemId,
  getItemLabel,
  primaryText,
  secondaryText,
  onSelect,
  children,
  disabled = false,
  tags,
}) => {
  const getItemTag = (id: number) => {
    if (!tags) {
      return null;
    }

    if (!(id in tags)) {
      return tags.default ? tags.default : null;
    }

    return tags[id];
  };

  const renderTagChip = (tag) => {
    if (!tag) {
      return null;
    }

    return (
      <Chip
        label={tag.name}
        color={tag.color}
        clickable={false}
        size="small"
        variant="outlined"
        sx={{
          mx: '0.25rem',
          fontSize: '0.625rem',
          height: '1rem',
        }}
      />
    );
  };

  return (
    <>
      <ShowIf If={items && items?.length > 0 && !disabled}>
        <Typography variant="h6">{primaryText}</Typography>
        <List sx={{ width: '100%' }}>
          {items?.map((item) => {
            const tag = getItemTag(item.id);
            return (
              <ListItem key={getItemId(item)} disablePadding sx={{ p: 0 }}>
                <ListItemButton role={undefined} onClick={undefined} dense sx={{ p: 0 }}>
                  <Tooltip
                    title={tag.tooltip}
                    key={tag.name}
                    followCursor
                    placement="bottom-start"
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    slotProps={{
                      tooltip: {
                        sx: {
                          minWidth: '25rem',
                          maxWidth: 'none',
                          padding: '1rem',
                          bgcolor: 'white',
                          backdropFilter: 'blur(4px)',
                          borderRadius: '10px',
                          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                          border: '1px solid #E0E0E0',
                        },
                      },
                    }}
                  >
                    <span
                      style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                    >
                      <ListItemIcon
                        sx={{ minWidth: '32px', display: 'flex', alignItems: 'center' }}
                      >
                        <AddCircleIcon
                          fontSize="small"
                          onClick={() => onSelect?.(item)}
                        />
                        {renderTagChip(tag)}
                      </ListItemIcon>
                      <ListItemText
                        id={getItemId(item)}
                        primary={getItemLabel(item)}
                        primaryTypographyProps={{ sx: { fontSize: '0.75rem' } }}
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '95%',
                        }}
                      />
                    </span>
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Divider sx={{ mb: '1rem' }} />
      </ShowIf>
      <Typography variant="h6">{secondaryText}</Typography>
      {children}
    </>
  );
};

export default HighlightSelector;
