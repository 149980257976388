import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import {
  LessonSubjectItem,
  LessonList,
  LessonItem,
  LessonGroupContent,
  LessonName,
  LessonPlayIcon,
  LessonDownloadIcon,
  LessonStatusIcon,
} from '../LessonManagement/LessonManagement.style';

class LessonDetailItem extends React.PureComponent {
  state = {
    isDocumentOpen: false,
  };

  componentDidMount = () => {
    if (this.props.index === 0) this.openUrlFromLesson();
  };

  toggleDocumentList = () => {
    this.setState((prevState) => ({
      isDocumentOpen: !prevState.isDocumentOpen,
    }));
  };

  openUrlFromLesson = () => {
    const { lessonDetailItem, openUrl, isTeacher } = this.props;
    const instructorUrl = _get(lessonDetailItem, 'instructor_url', '');
    const studentUrl = _get(lessonDetailItem, 'student_url', '');
    const name = _get(lessonDetailItem, 'name', '');
    const id = _get(lessonDetailItem, 'id', '');
    openUrl(isTeacher ? instructorUrl : studentUrl || instructorUrl, id);
  };

  onDownloadLink = (document) => {
    const { doc_url: docUrl } = document;
    window.open(docUrl, '_blank');
  };

  renderDocumentItem = (document, index) => {
    const { name } = document;

    return (
      <LessonItem key={index}>
        <LessonGroupContent>
          <LessonName>{name}</LessonName>
        </LessonGroupContent>
      </LessonItem>
    );
  };

  renderLessonStatusIcon = (status) => {
    switch (status) {
      case 'completed':
        return <LessonStatusIcon className="chalktalk-check-success" />;
      case 'inProgress':
        return <LessonStatusIcon className="chalktalk-in-progress" />;
      default:
        return <LessonStatusIcon className="chalktalk-grey-circle" />;
    }
  };

  render() {
    const { isDocumentOpen } = this.state;
    const { lessonDetailItem, checked, isTeacher } = this.props;
    const documents = _get(lessonDetailItem, 'documents', []);
    const filteredDocuments = isTeacher
      ? documents
      : documents.filter((doc) => doc.account_type === 1);

    return (
      <LessonSubjectItem>
        <LessonItem onClick={this.openUrlFromLesson}>
          <LessonGroupContent>
            <LessonPlayIcon className="chalktalk-doc" />
            <LessonName>{lessonDetailItem.name}</LessonName>
          </LessonGroupContent>
          {!isTeacher ? this.renderLessonStatusIcon(checked) : ''}
        </LessonItem>
        {isDocumentOpen && (
          <LessonList>{filteredDocuments.map(this.renderDocumentItem)}</LessonList>
        )}
      </LessonSubjectItem>
    );
  }
}

LessonDetailItem.propTypes = {
  lessonDetailItem: PropTypes.object,
  openUrl: PropTypes.func,
  checked: PropTypes.string,
  isTeacher: PropTypes.bool,
};

export default LessonDetailItem;
