/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import _get from 'lodash/get';
import DashboardUpNextWidget from '../../components/DashboardSummary/DashboardUpNextWidget';
import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,
} from '../../reducers/sectionSchedule';
import {
  selectors as SectionReviewsSelectors,
  actions as SectionReviewsActions
} from '../../reducers/sectionReviews';
import { selectors as UserSelectors } from '../../reducers/user';
import {
  selectors as SectionsSelectors,
  actions as SectionsActions
} from '../../reducers/sections';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import {actions as PracticeReportActions} from '../../reducers/practiceReport';
import { actions as PracticeActions, selectors as PracticeSelectors } from '../../reducers/practice';

const mapStateToProps = (state, { match }) => {
  const subjectIds = SectionSubjectSelectors.getSubjectIds(state);
  const scheduleList = SectionScheduleSelectors.getScheduleList(state);
  const isGetting = SectionScheduleSelectors.isGettingSectionSchedule(state)
  const sectionId = _get(match, 'params.sectionId', null);
  const sectionDetail = SectionsSelectors.getSectionListById(state, sectionId);
  const sessionList = scheduleList.filter(({ subject, activity_type }) => (
    subject && subjectIds.includes(subject) && activity_type !== 2
  ));
  const sessionExam = scheduleList.filter(({ type }) => type === 'exams');
  const totalReviews = SectionReviewsSelectors.getTotalReview(state);


  const examList = [];
  sessionExam.map(event => {
    const title = event.title;
    const type = event.type;

    if (event.session_sittings_dates.length > 0) {
      const exam_sections = event.session_metadata?.sections || [];
      if (exam_sections.length > 0) {
        exam_sections.map(section => {
          const section_exam = {
            id: section.section_id,
            date: section.section_date,
            section_name: section.section_name,
            exam_id: event.id,
            title,
            type
          };
          const examById = [...new Set(examList.map(exam => exam.id))];
          if(!examById.includes(section.section_id)) examList.push(section_exam);
        })
      }
    } else {
      const exam_sections = event.exam?.sections || [];
      if (exam_sections.length > 0) {
        exam_sections.map(section => {
          const section_exam = {
            id: section.id,
            date: event.date,
            section_name: section.name,
            exam_id: event.id,
            title,
            type
          };
          const examById = [...new Set(examList.map(exam => exam.id))];
          if(!examById.includes(section.section_id)) examList.push(section_exam);
        })
      }
    }
  });

  const completedPracticeUnitIds = [];
  const allPracticeSession = sessionList.filter(session => {
    if (session.type === 'lessons' && session.lesson === null) return session;
  });

  allPracticeSession.map(practice => {
    const { unit: unitId } = practice
    const practiceSummary = SectionsSelectors.getPracticeSummaryOfUnit(state, unitId);
    const unitDetail = SectionUnitSelectors.getUnitById(state, unitId);
    const { assets = [] } = unitDetail;
    const initialValueForReport = {
      count: 0,
    };
    const practiceReport = {};

    practiceSummary.forEach((summaryItem) => {
      const { asset_type } = summaryItem;
      if (typeof asset_type === 'undefined')  return;  
  
      const currentValueOfReport = _get(practiceReport, asset_type, initialValueForReport);
      if (currentValueOfReport.count > 0) return;
      practiceReport[asset_type] = {
        count: currentValueOfReport.count + 1,
      };
    
    });
    if (practiceReport[null]) {
      delete practiceReport[null]
    }
    if (Object.values(practiceReport).length === assets.length) {
      completedPracticeUnitIds.push(unitId);
    }
  });

  return {
    sectionDetail,
    totalReviews,
    sessionList,
    examList,
    isGetting,
    subjectById: SectionSubjectSelectors.getSubjectById(state),
    userRole: UserSelectors.getUserRole(state),
    examsScore: SectionsSelectors.examsScoreObject(state),
    allPracticeSession,
    completedPracticeUnitIds,
    practiceId: PracticeSelectors.getPracticeId(state),
    isInitialized: PracticeSelectors.isInitialized(state),
    unitBySubject: SectionUnitSelectors.getUnitBySubject(state),
  };
};

const mapDispatchToProps = {
  sectionScheduleGet: SectionScheduleActions.sectionScheduleGet,
  sectionReviewSummary: SectionReviewsActions.sectionReviewSectionSummaryGet,
  sectionStudentReviewSummary: SectionReviewsActions.sectionStudentReviewSectionSummaryGet,
  sectionsExamsScoresGet: SectionsActions.sectionsExamsScoresGet,
  practiceReportGetDetail: PracticeReportActions.practiceReportGetDetail,
  initializePracticeSession: PracticeActions.initializePracticeSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardUpNextWidget);
