import { connect } from 'react-redux';
import OnlinePracticeContent from '../../components/OnlinePracticeContent';
import { selectors as PracticeSelectors } from '../../reducers/practice';
import { selectors as LeftSideBarSelector } from '../../reducers/leftSideBar';

const mapStateToProps = (state) => ({
  questions: PracticeSelectors.getQuestions(state), // get question
  submittedAnswerList: PracticeSelectors.getSubmittedAnswerList(state), // get question
  isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlinePracticeContent);
