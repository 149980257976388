import React from 'react';

import {
  ActionButton,
  EndSessionModalContent,
  EndSessionModalWrapper,
} from '@components/Modals/EndSessionModal/EndSessionModal.style';
import { useIsTeacher } from '@reducers/user/hooks';
import { useGroupActivityId } from '../hooks/queryStore';
import { useCalculateScore, useEndGroupActivity } from '../hooks/mutation';

const EndSessionDialog = ({ open, onClose, dataTestId = 'end-session-dialog' }) => {
  const isTeacher: boolean = useIsTeacher();
  const groupActivityId = useGroupActivityId();
  const calculateScoreMutation = useCalculateScore();
  const endGroupActivityMutation = useEndGroupActivity(groupActivityId);

  const endActivityHandle = () => {
    if (groupActivityId) {
      endGroupActivityMutation.mutate(groupActivityId);
      calculateScoreMutation.mutate(groupActivityId);
    }
    onClose(false);
  };
  if (!isTeacher) {
    return null;
  }
  return (
    <div data-testid={dataTestId}>
      <EndSessionModalWrapper show={open} onClick={() => onClose(false)}>
        <EndSessionModalContent onClick={(event) => event.stopPropagation()}>
          <span>Time is Up !</span>
          <span>
            If you are done please click on “End Session”. If you still need more time you
            can end the session later
          </span>
          <div className="action-stack">
            <ActionButton onClick={() => onClose(false)} bgColor="#453635">
              End Later
            </ActionButton>
            <div id="h-space" style={{ width: 20 }} />
            <ActionButton onClick={endActivityHandle}>End Session</ActionButton>
          </div>
        </EndSessionModalContent>
      </EndSessionModalWrapper>
    </div>
  );
};
export default EndSessionDialog;
