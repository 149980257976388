import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import { TabProvider, Tab, TabPanel, TabList } from '@components/Shared/TabsDefaultSkin';

import MediaQuery from 'react-responsive';
import { NavigationWrap, NavigationHeaderWrap } from './QuestionAssets.style';
import {
  ToggleButton,
  FeedbackContainer,
  FeedbackIcon,
} from '../Generals/stylesheets/Button.style';
import AssetTypeNormal from './AssetTypeNormal';
import AssetTypeSpecial from './AssetTypeSpecial';
import { SHARED_ASSET_TYPE, REPORT_TYPES } from '../../utils/enums';

export default class QuestionAssets extends Component {
  static propTypes = {
    togglePracticeNavigation: PropTypes.func,
    isPracticeNavigation: PropTypes.bool,
    assets: PropTypes.array,
    displayGrammarHelper: PropTypes.func,
    questionSelected: PropTypes.any,
    hasFooter: PropTypes.bool,
    openReportErrorModal: PropTypes.func,
    questionId: PropTypes.number,
  };

  componentDidMount() {
    const { assets, displayGrammarHelper } = this.props;

    if (displayGrammarHelper) {
      const shouldDisplayGrammarHelper = assets.find(
        (asset) => asset.asset_type === SHARED_ASSET_TYPE.GRAMMAR_PASSAGE,
      );
      displayGrammarHelper(!!shouldDisplayGrammarHelper);
    }
  }

  componentDidUpdate(prevProps) {
    const { questionSelected, displayGrammarHelper, assets } = this.props;
    const { questionSelected: prevQuestionSelected } = prevProps;

    if (displayGrammarHelper && !_isEqual(prevQuestionSelected, questionSelected)) {
      const shouldDisplayGrammarHelper = assets.find(
        (asset) => asset.asset_type === SHARED_ASSET_TYPE.GRAMMAR_PASSAGE,
      );
      displayGrammarHelper(!!shouldDisplayGrammarHelper);
    }
  }

  toggleNagivation = () => {
    const { togglePracticeNavigation } = this.props;
    togglePracticeNavigation();
  };

  openReportErrorModal = () => {
    const { questionId, openReportErrorModal } = this.props;

    openReportErrorModal(REPORT_TYPES.SHARED_ASSET, questionId);
  };

  renderTabHeaderItem = (asset, index) => {
    const { asset_type: assetType } = asset;

    return (
      <Tab key={index} tabKey={asset.id}>
        {assetType}
      </Tab>
    );
  };

  renderTabContentItem = (asset) => {
    const { asset_type: assetType, id } = asset;
    const { hasFooter } = this.props;

    switch (assetType) {
      case SHARED_ASSET_TYPE.PASSAGE:
      case SHARED_ASSET_TYPE.ANSWER:
        return (
          <TabPanel key={id} tabKey={id}>
            <AssetTypeSpecial asset={asset} hasFooter={hasFooter} />
          </TabPanel>
        );
      // other type
      default:
        return (
          <TabPanel key={id} tabKey={id}>
            <AssetTypeNormal asset={asset} hasFooter={hasFooter} />
          </TabPanel>
        );
    }
  };

  render() {
    const { assets, isPracticeNavigation, hasFooter } = this.props;
    const isClose = isPracticeNavigation || !assets || !assets.length;

    return (
      <NavigationWrap isClose={isClose} hasFooter={hasFooter}>
        <TabProvider defaultTab={assets?.[0]?.id || 0} key={assets?.[0]?.id || 0}>
          <NavigationHeaderWrap>
            <TabList>{assets.map(this.renderTabHeaderItem)}</TabList>
            <FeedbackContainer onClick={this.openReportErrorModal}>
              <FeedbackIcon src="/images/feedback.png" />
            </FeedbackContainer>
          </NavigationHeaderWrap>
          {assets.map(this.renderTabContentItem)}
        </TabProvider>
        <MediaQuery minWidth={768}>
          {(matches) =>
            matches && (
              <ToggleButton className="toggle-icon" onClick={this.toggleNagivation}>
                <span
                  className={
                    isPracticeNavigation
                      ? 'chalktalk-icon-bold-down'
                      : 'chalktalk-icon-bold-up'
                  }
                />
              </ToggleButton>
            )
          }
        </MediaQuery>
      </NavigationWrap>
    );
  }
}
