/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import {
  LeftSiderBarContentGroup,
  LeftSideBarList,
  ItemsSiderBar,
  TitleSideBar,
  SideBarContent,
  LeftSiderBarWrap,
  ActionShowHide,
  SideBarContentTitle,
} from './LeftSiderBar.style';

import { useIsDistrictAdmin } from '@reducers/user/hooks';

export default class LeftSideBarAdmin extends Component {
  static propTypes = {
    match: PropTypes.any,
    pathName: PropTypes.string,
    toggleLeftSidebar: PropTypes.func,
    isSideBarClose: PropTypes.bool,
    roleName: PropTypes.string,
    user: PropTypes.shape(),
  };

  toggleLeftSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  };

  render() {
    const { isSideBarClose } = this.props;

    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <AdminSideBarContent/>
        <ActionShowHide className="toggle-icon" onClick={this.toggleLeftSidebar}>
          <MediaQuery maxWidth={960}>
            {(matches) =>
              matches ? (
                <span
                  className={isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'}
                />
              ) : (
                <span
                  className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'}
                />
              )
            }
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    );
  }
}

const AdminSideBarContent = () => {
  const location = useLocation();
  const defaulUrl = `/admin/reports/generator`;
  const isDistrictAdmin = useIsDistrictAdmin()

  return (
    <LeftSiderBarContentGroup>
      <TitleSideBar>Admin Reports</TitleSideBar>
      <LeftSideBarList>
        <>
            <Link to={defaulUrl}>
              <ItemsSiderBar
                isActive={location.pathname.startsWith('/admin/reports/generator')}
              >
                <SideBarContent>
                  <SideBarContentTitle>
                    <img src="/images/report-generator-icon.svg" />
                    <span>Report Generator</span>
                  </SideBarContentTitle>
                </SideBarContent>
              </ItemsSiderBar>
            </Link>
            <Link to={`/admin/reports/data-dashboard`}>
              <ItemsSiderBar
                isActive={'/admin/reports/data-dashboard' == location.pathname}
              >
                <SideBarContent>
                  <SideBarContentTitle>
                    <img src="/images/profit-report.svg" />
                    <span>Data Dashboard</span>
                    <span className='beta'>BETA</span>
                  </SideBarContentTitle>
                </SideBarContent>
              </ItemsSiderBar>
            </Link>
        </>
      </LeftSideBarList>
    </LeftSiderBarContentGroup>
  );
};