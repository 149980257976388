import styled from 'styled-components';

interface BreadCrumbsSubItemsProps {
  current: boolean;
}

export const BreadcrumbsSubWrap = styled.div``;
export const BreadcrumbsSubGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.25em;
  font-size: ${(props) => props.theme.fontSize.MediumFontSize};
  color: ${(props) => props.theme.colorStyled.ColorSecond};
  .break-line {
    margin: 0em 0.5em;
  }
`;

export const BreadcrumbsSubItems = styled.div<BreadCrumbsSubItemsProps>``;
