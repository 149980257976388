import React from 'react';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { activityGroupType, studentType, userType } from '../../types';
import {
  useActivityGroups,
  useGetGroupActivityInfo,
  useOnlineActivities,
} from '../query';

export const useGroupActivityId = () => {
  const { data } = useGetLessonDetail();
  const lessonId = data?.lessons[0]?.id;
  const groupActivityId = useOnlineActivities(lessonId);
  return groupActivityId;
};
export const useCurrentGroup = () => {
  const currentUser: userType = useUser();
  const groupActivityId = useGroupActivityId();
  const { data: groups } = useActivityGroups(groupActivityId);
  const [currentGroup] = groups!.reduce((acc, _group, index) => {
    const user = _group?.students?.find(
      (_student) => _student.student_id === currentUser.id,
    );
    if (user) {
      return [_group, index];
    }
    return acc;
  }, []);
  return currentGroup || ({} as activityGroupType);
};
export const useGroupsScoreAndSubmissions = () => {
  let numberOfSubmission = 0;
  let totalCorrectAnswersCount = 0;
  let totalQuestionsCount = 0;
  const groupActivityId = useGroupActivityId();
  const { data: groups } = useActivityGroups(groupActivityId);
  groups?.forEach((group) => {
    if (group.score) {
      totalCorrectAnswersCount += +group.score[0];
      totalQuestionsCount += +group.score[group.score.length - 1];
    }
    group.students.forEach((student) => {
      if (student.status === 3) {
        numberOfSubmission += 1;
      }
    });
  });
  if (totalQuestionsCount === 0) {
    totalQuestionsCount = 1;
  }
  const percentile = Math.round(totalCorrectAnswersCount / totalQuestionsCount) * 100;
  return { numberOfSubmission, percentile };
};
export const useCurrentStudent = (currentGroup) => {
  const isTeacher = useIsTeacher();
  const currentUser: userType = useUser();
  const currentStudent = React.useMemo<studentType>(() => {
    if (isTeacher) {
      return { student_id: '' };
    }
    if (!currentGroup?.students) {
      return { student_id: '' };
    }
    return (
      currentGroup.students.find((student) => student?.student_id === currentUser.id) ||
      {}
    );
  }, [currentGroup, currentUser.id]);

  return currentStudent;
};
export const useIsAllActivityGroupsCompleted = () => {
  const {
    data: { id: groupActivityId },
  } = useGetGroupActivityInfo();
  const { data: groups } = useActivityGroups(groupActivityId);
  if (!groups) {
    return;
  }
  const nonCompletedGroup: object | undefined = groups.find(
    (group) => group.group_status !== 3,
  );
  if (!nonCompletedGroup) {
    return true;
  }
  return false;
};
