/* eslint-disable sort-keys */
import React from 'react';
import LoadingIndicator from '@components/LoadingIndicator';
import {
  GroupAnswerType,
  lockedQuestionType,
  PracticeState,
  questionType,
  StudentType,
} from '../types';
import * as practiceQueries from '../hooks/query';
import usePracticeGoBack from '../hooks/usePracticeGoBack';
import {
  GroupsPracticeSessionStatus,
  GroupStatus,
  PracticeDuration,
} from '../utils/statics';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

type PracticeDataContextType = {
  numberOfQuestions: number;
  questions: questionType[];
  startedDate: Date;
  groupsPracticeSessionStatus?: number;
  groupMembers: StudentType[];
  groupId?: number;
  duration: number;
  initialGroupStatus: number;
  individualDuration: number;
  initialLockedQuestions: lockedQuestionType;
  initialGroupAnswers: GroupAnswerType[];
};
const PracticeDataContext = React.createContext<PracticeDataContextType>({
  numberOfQuestions: 0,
  questions: [],
  startedDate: new Date(),
  groupsPracticeSessionStatus: 0,
  groupMembers: [],
  groupId: 0,
  duration: 14 * 60,
  initialGroupStatus: 0,
  individualDuration: 6 * 60,
  initialLockedQuestions: {},
  initialGroupAnswers: [],
});

const PracticeDataProvider = ({ children }: Props) => {
  const {
    data: groupActivity,
    isLoading: groupActivityLoading,
    isFetching,
  } = practiceQueries.useGroupActivity();
  const { data: groupQuestions, isLoading: groupQuestionsLoading } =
    practiceQueries.useGroupQuestions();
  const goBack = usePracticeGoBack();
  // groupActivity.online_activity_status ==1 means practice is in progress and 0 means practice is not started 2 ended
  // groupActivity.group_status
  // NOT_STARTED = 0
  // IN_PROGRESS_INDIVIDUAL = 1
  // IN_PROGRESS_GROUP = 2
  // COMPLETED = 3

  const value = React.useMemo(
    () => ({
      startedDate: groupActivity?.time_activity_started
        ? new Date(groupActivity?.time_activity_started)
        : new Date(),
      groupsPracticeSessionStatus: groupActivity?.groups_session_status || 0,
      duration: (groupActivity?.duration || 14) * 60,
      individualDuration: PracticeDuration[PracticeState.Individual] * 60, // min to sec
      // ------------------
      groupMembers: groupActivity?.students || [],
      // not sure of the naming here
      groupId: groupActivity?.id,
      numberOfQuestions: groupQuestions?.exam_questions?.length || 0,
      initialGroupStatus: groupActivity?.group_status || 0,
      initialLockedQuestions: groupActivity?.locked_questions || {},
      initialGroupAnswers: groupActivity?.group_answers || [],
      // ------------------
      questions: groupQuestions?.exam_questions || [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupActivity, groupQuestions, isFetching],
  );

  // loading page if any of the query is loading
  if (groupQuestionsLoading || groupActivityLoading || isFetching) {
    return <LoadingIndicator />;
  }
  // if practice is not started then redirect to practice home page
  if (
    [GroupsPracticeSessionStatus.notStarted, GroupsPracticeSessionStatus.ended].includes(
      value.groupsPracticeSessionStatus,
    ) ||
    [GroupStatus.completed].includes(value.initialGroupStatus)
  ) {
    goBack();
    return null;
  }
  return (
    <PracticeDataContext.Provider value={value}>{children}</PracticeDataContext.Provider>
  );
};

export default React.memo(PracticeDataProvider);
export const usePracticeData = () => {
  const context = React.useContext(PracticeDataContext);
  if (!context) {
    throw new Error('usePracticeData must be used within PracticeDataProvider');
  }
  return context;
};
export const useQuestions = () => {
  const context = React.useContext(PracticeDataContext);
  if (!context) {
    throw new Error('useQuestions must be used within PracticeDataProvider');
  }
  return context.questions || [];
};
export const useGroupMembers = () => {
  const context = React.useContext(PracticeDataContext);
  if (!context) {
    throw new Error('useGroupMembers must be used within PracticeDataProvider');
  }
  return context.groupMembers || [];
};
