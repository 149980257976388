/**
    * allowedSections value is an object of which sections shall be displayed
    * @param {array} subjects - subjects array
    * @param {number} curriculumType - the type of the course SAT or ACT
*/
export function allowedSections(subjects, curriculumType) {
  if (curriculumType === 1) {
    const showMath = subjects.some((sub) => sub === 'Math');
    const showLanguage = subjects.some(
      (sub) => sub.includes('Reading') || sub.includes('Writing'),
    );
    return {
      'Evidence-Based Reading and Writing': showLanguage,
      Math: showMath,
      ShowCurriculumBox: showMath & showLanguage,
      ShowLexelLevel: showLanguage,
    };
  } if (curriculumType === 2) {
    const showMath = subjects.some((sub) => sub === 'Math');
    const showReading = subjects.some(
      (sub) => sub.includes('Reading') || sub.includes('English'),
    );
    const showScience = subjects.some((sub) => sub === 'Science');

    return {
      Reading: showReading,
      Science: showScience,
      'Preparing for Higher Math': showMath,
      English: showReading,
      ShowCurriculumBox: showReading,
      ShowLexelLevel: showReading,
    };
  } if (curriculumType === 13) {
    const showMath = subjects.some((sub) => sub === 'Math');
    const showLanguage = subjects.some(
      (sub) => sub.includes('Reading') || sub.includes('Writing') || sub.includes('English'),
    );
    return {
      'ELAR': showLanguage,
      Math: showMath,
      ShowCurriculumBox: showMath & showLanguage,
      ShowLexelLevel: showLanguage,
    };
  }
}
