import axios from 'axios';
import moment from 'moment';
import { handleError } from './utils';

// serialzer

export const studentSerializer = studentData => ({
  first_name: studentData.firstName,
  last_name: studentData.lastName,
  email: studentData.email,
  section_code: studentData.sectionCode,
  grade_level: (studentData.grade || {}).grade_level,
  password1: studentData.password,
  password2: studentData.confirmPassword,
});

export const teacherSerializer = teacherData => ({
  first_name: teacherData.firstName,
  last_name: teacherData.lastName,
  email: teacherData.email,
  password1: teacherData.password,
  password2: teacherData.confirmPassword,
  role: teacherData.role,
  cell_phone: teacherData.phoneNumber,
});

export const updateTeacherRegistrationSerializer = schoolInfo => ({
  country: schoolInfo.country.code,
  school: schoolInfo.schoolName,
  district: schoolInfo.district.id,
});

// api calls

export function teacherRegister(teacherData) {
  const url = '/api/register/teacher/';
  return axios.post(
    url,
    teacherSerializer(teacherData),
  )
    .then(response => ({ response }))
    .catch(handleError);
}

export function studentRegister(studentData) {
  const url = '/api/register/student/';
  return axios.post(
    url,
    studentSerializer(studentData),
  )
    .then(response => ({ response }))
    .catch(handleError);
}

export function updateTeacherRegistration(schoolInfo) {
  const url = '/api/register/teacher/update-school-info/';
  return axios.post(
    url,
    updateTeacherRegistrationSerializer(schoolInfo),
  )
    .then(response => ({ response }))
    .catch(handleError);
}

export function getCountry() {
  const url = '/api/register/available-countries/';
  return axios.get(url)
    .then(response => ({ response }))
    .catch(handleError);
}

export function getDistrict(districtQuery) {
  const url = `/api/districts/list/?name=${districtQuery}`;
  return axios.get(url)
    .then(response => ({ response }))
    .catch(handleError);
}
