import React from 'react';
import Box from '@components/Atoms/Box';
import TextField from '@components/Atoms/TextField';
import { CircularProgress } from '@mui/material';
import ShowIf from '@components/Atoms/ShowIf';
import useDebounce from '@utils/hooks/useDebounce';

const orderTextStyle = {
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1.5em',
  fontSize: '0.8125em',
  flex: '0 0 3em',
  padding: '0.5em',
};

const textFieldStyle = {
  maxWidth: { md: '13.625rem', lg: '8.5rem' },
  '& .MuiInputBase-root': {
    fontSize: { md: '0.875rem', lg: '0.75rem' },
    paddingRight: '0.375rem',
    backgroundColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: (theme) => theme.palette.secondary.light,
    },
    '&.Mui-focused fieldset': {
      borderColor: (theme) => theme.palette.secondary.main,
    },
  },
};
interface AnswerShortTextProps {
  order: number;
  defaultValue: string;
  onChange: (value: string) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const AnswerShortTextField = ({
  order,
  defaultValue,
  onChange,
  onFocus,
}: AnswerShortTextProps) => {
  const [value, setValue] = React.useState(defaultValue);

  const { run, isWaiting } = useDebounce(onChange, 600);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    run(e.target.value);
  };

  return (
    <Box
      component="span"
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={orderTextStyle}>{`${order}.`}</Box>
      <TextField
        label=""
        sx={textFieldStyle}
        placeholder="Enter your answer"
        variant="outlined"
        size="small"
        onChange={handleChange}
        value={value}
        onFocus={onFocus}
        InputProps={{
          endAdornment: (
            <Box sx={{ minWidth: '1rem', display: 'flex', alignItems: 'center' }}>
              <ShowIf If={isWaiting}>
                <CircularProgress size="0.875rem" />
              </ShowIf>
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default AnswerShortTextField;
