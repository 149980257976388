// import {
//   REGISTER_SELECT_STEP_FAILED,
//   REGISTER_SELECT_STEP_SUCCESS,
// } from '../register';
import _get from 'lodash/get';
import { AUTH_LOGIN_SUCCESS, AUTH_LOGOUT } from '../auth';

export const SYSTEM_SET_PAGE_LOADING = 'system/SET_PAGE_LOADING';
export const ONLINE_STATUS_ONLINE = 'system/ONLINE_STATUS_ONLINE';
export const ONLINE_STATUS_OFFLINE = 'system/ONLINE_STATUS_OFFLINE';

// action creator

/**
 *
 * @param {Boolean} value
 */
const systemSetPageLoading = value => ({
  type: SYSTEM_SET_PAGE_LOADING,
  value,
});

export const actions = {
  systemSetPageLoading,
};

// selector
const isPageLoading = ({ system }) => system.isLoading;

const getCurrentPathname = ({ router }) => _get(router, 'location.pathname', '');

export const selectors = {
  isPageLoading,
  getCurrentPathname,
};

const defaultState = {
  isLoading: true,
};

function systemReducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return { ...state, isLoading: true };
    case AUTH_LOGOUT:
      return { ...state, isLoading: false };
    case SYSTEM_SET_PAGE_LOADING:
      return { ...state, isLoading: action.value };

    default:
      return state;
  }
}

export default systemReducer;
