import React from 'react';
import Typography from '@components/Atoms/Typography';
import Box from '@components/Atoms/Box';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import {
  NavBarItemWrap,
  NavBarStatusColumn,
  NavBarIconWrap,
  NavBarItemHead,
} from './SubNavBar.style';
import { usePostLearnosityLessonReview } from './hooks/query';

const LessonReviewGenerateButton = ({ isReviewClassEnabled, isSingleItem, lessonId }) => {
  const { mutate: postLearnosityLessonReview } = usePostLearnosityLessonReview(lessonId);

  return (
    <NavBarItemWrap
      isReviewClassEnabled
      onClick={() => {
        postLearnosityLessonReview({});
      }}
    >
      <NavBarStatusColumn>
        <NavBarIconWrap
          isSingleItem={isSingleItem}
          isFirstItem={false}
          isLastItem={false}
        >
          <QuizTwoToneIcon
            sx={{
              fontSize: '1.125rem',
              fill: (theme) => theme.palette.secondary.main,
              stroke: (theme) => theme.palette.primary.main,
            }}
          />
        </NavBarIconWrap>
      </NavBarStatusColumn>
      <Box
        sx={{
          flex: 1,
          position: 'relative',
          cursor: 'pointer',
          paddingBottom: '1.5rem',
          paddingInline: '1rem',
        }}
      >
        <NavBarItemHead />
        {isReviewClassEnabled && (
          <Box
            sx={{
              border: 'dashed 1px #ccc',
              alignSelf: 'center',
              width: '100%',
              borderRadius: '0.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: '1rem',
              mb: '0.5rem',
              '&:hover': {
                cursor: 'pointer',
                borderColor: (theme) => `${theme.palette.primary.main}`,
                '& p': {
                  color: (theme) => `${theme.palette.primary.main}`,
                },
              },
            }}
          >
            <Typography variant="body1">+ Add Assessment</Typography>
          </Box>
        )}
      </Box>
    </NavBarItemWrap>
  );
};

export default LessonReviewGenerateButton;
