import { useUser } from '@reducers/user/hooks';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Preview } from '@learnosityassessment';
import { useCurrentUnit } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useSelectedQuestions } from '@components/QuestionSelector/QuestionSelector.provider';

const PreviewQuestions = () => {
  const user = useUser();
  const { lessonId } = useParams();
  const unit = useCurrentUnit();
  const { formattedSelectedQuestions } = useSelectedQuestions();
  return (
    <Preview
      userId={`${user?.id}`}
      activityId={lessonId}
      title={unit?.name ?? ''}
      subtitle=""
      questions={formattedSelectedQuestions}
    />
  );
};

export default PreviewQuestions;
