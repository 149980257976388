import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import ReactDOM from 'react-dom';
import AnswerableQuestionItem from '../AnswerableQuestionItem';
import QuestionAssets from '../../containers/QuestionAssets';
import AnswerExplanation from '../../containers/AnswerExplanation';
import {
  PracticeContentWrap,
  PracticeContentGroup,
} from '../PracticePage/PracticePage.style';

class ReviewQuestionContainers extends React.PureComponent {
  state = {
    // questionSelected is diffence with the order of question
    questionSelected: 0,
    currentAnswer: null,
  };

  selectCurrentAnswer = (questionSelected) => {
    if(questionSelected !== 0 && !questionSelected){
      questionSelected = this.state.questionSelected;
    }
    const { questions } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const currentQuestion = questionSelected;
    if (!questions || !questions.length) return;
    const questionSelectedObj = questions[currentQuestion];
    const { question_response: questionRes } = questionSelectedObj;

    const currentChoice = questionRes.choices || null;
    let currentText = questionRes.text || null;

    if (currentText) {
      try {
        currentText = currentText.replace(/'/g, '"');
        currentText = JSON.parse(currentText)[0] || currentText; // eslint-disable-line
      } catch (error) {
        // skip
      }
    }

    const currentAnswer = currentChoice || currentText;

    this.setState({
      currentAnswer,
      questionSelected: currentQuestion,
    });
  }

  componentDidMount() {
    this.selectCurrentAnswer();
  }

  componentDidUpdate(prevProps, prevState) {
    const { questionSelected } = this.state;
    const { questionSelected: prevQuestionSelected } = prevState;

    const { questions } = this.props;
    const { questions: prevQuestions } = prevProps;
    if (!prevQuestions.length && questions.length) {
      this.selectCurrentAnswer();
    }
  }

  nextQuestion = () => {
    const { questionSelected } = this.state;
    const { questions } = this.props;
    if (questionSelected + 1 >= questions.length) return;

    this.selectCurrentAnswer(questionSelected + 1);
  }

  prevQuestion = () => {
    const { questionSelected } = this.state;
    if (questionSelected - 1 < 0) return;

    this.selectCurrentAnswer(questionSelected - 1);
  }

  renderQuestionAssets = () => {
    const { questions, openReportErrorModal } = this.props;
    const { questionSelected } = this.state;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(questionSelectedObj, 'exam_question.question.shared_assets', []);
    const questionId = _get(questionSelectedObj, 'exam_question.id', null);

    const isAssetEmpty = assets.length === 0;

    if (isAssetEmpty) {
      return null;
    }

    return (
      <QuestionAssets assets={assets} openReportErrorModal={openReportErrorModal} questionId={questionId} />
    );
  }

  selectAnswer = (currentAnswer) => {
    if (typeof currentAnswer === 'string') {
      this.setState({
        currentAnswer,
      });
    } else {
      this.setState({
        currentAnswer: [currentAnswer],
      });
    }
  }

  render() {
    const { questionSelected, currentAnswer } = this.state;
    const {
      questions,
      isPracticeNavigation,
      userAnswerList,
      openReportErrorModal,
    } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const questionOrder = _get(questionSelectedObj, 'exam_question.order', null);
    const explanation = _get(questionSelectedObj, 'exam_question.question.explanation', {});
    const questionId = _get(questionSelectedObj, 'exam_question.id', null);
    const totalQuestions = questions.length;
    const studentWithSelectedAnswer = (userAnswerList[questionId] || {})[currentAnswer];
    if (!totalQuestions) {
      return (
        <PracticeContentWrap>
          This practice don&#39;t have anything for you to review
        </PracticeContentWrap>
      );
    }

    return (
      <PracticeContentWrap>
        {this.renderQuestionAssets()}

        <PracticeContentGroup isToggleNavigation={isPracticeNavigation}>
          <AnswerableQuestionItem
            currentAnswer={currentAnswer}
            questionSelected={questionSelectedObj}
            totalQuestions={totalQuestions}
            nextQuestion={this.nextQuestion}
            prevQuestion={this.prevQuestion}
            index={questionSelected}
            order={questionOrder}
            selectAnswer={this.selectAnswer}
            openReportErrorModal={openReportErrorModal}
            questionId={questionId}
          />
          <AnswerExplanation
            explanation={explanation}
            studentWithSelectedAnswer={studentWithSelectedAnswer}
            questionSelected={questionSelectedObj}
            openReportErrorModal={openReportErrorModal}
            questionId={questionId}
          />
        </PracticeContentGroup>
      </PracticeContentWrap>
    );
  }
}

ReviewQuestionContainers.propTypes = {
  questions: PropTypes.array,
  isPracticeNavigation: PropTypes.bool,
  userAnswerList: PropTypes.shape(),
  openReportErrorModal: PropTypes.func,
};

ReviewQuestionContainers.defaultProps = {
  userAnswerList: {},
};

export default ReviewQuestionContainers;
