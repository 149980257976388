/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import moment from 'moment';
import {
  NavBarItemCustome,
  NavBarName,
  NavBarItemHead,
  NavBarHeadText,
  NavBarStatusColumn,
  NavBarItemWrap,
  NavBarIconWrap,
  NavBarIcon,
  NavBarItemDateWrap,
  NavBarItemDay,
  NavBarItemMonth,
  ProgressBarWrap,
  ProgressSegment,
  ProgressSegmentItem,
  StatusLabel,
} from './SubNavBar.style';
import { SESSION_ACTIVITY_TYPE } from '../../utils/constants';

class ReviewSubNavItem extends React.PureComponent {
  renderStatus = (isNotStarted, isStarted, rate) => {
    if (isStarted) return <StatusLabel>0% Completed</StatusLabel>;

    if (isNotStarted) return <StatusLabel />;

    return <StatusLabel>{`${rate}% Completed`}</StatusLabel>;
  }

  renderProgressBarItem = () => {
    const isCompled = false;
    const pathNumber = Array.from(Array(14).keys());
    return (
      <NavBarStatusColumn>
        <NavBarIconWrap isSingleItem isFirstItem={false} isLastItem={false}>
          {isCompled
            ? <NavBarIcon className="chalktalk-check-success" />
            : (
              <NavBarIcon className="chalktalk-neural-network">
                {pathNumber.map((number, index) => <i className={`path${number}`} key={index} />)}
              </NavBarIcon>
            )}
        </NavBarIconWrap>
      </NavBarStatusColumn>
    );
  }

  renderSessonProgress = (isNotStarted, isStarted, isInprogress, isCompleted, rate) => (
    <ProgressBarWrap>
      {this.renderStatus(isNotStarted, isStarted, rate)}
      {
        !!rate && (
          <ProgressSegment>
            <ProgressSegmentItem
              isNotStarted={isNotStarted}
              isStarted={isStarted}
              isInprogress={isInprogress}
              isCompleted={isCompleted}
              rate={rate}
            />
          </ProgressSegment>
        )
      }
    </ProgressBarWrap>
  )

  navigateToReviewPage = () => {
    const { reviewItem, match, history } = this.props;
    const courseId = _get(match, 'params.courseId', '');
    const sectionId = _get(match, 'params.sectionId', '');
    const subjectId = _get(reviewItem, 'subject', '');

    history.push(`/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/review/${reviewItem.id}/`);
  }

  renderReviewDate = () => {
    const { reviewItem } = this.props;
    const { date } = reviewItem;
    const reviewDate = moment(date, 'YYYY-MM-DD');
    return (
      <NavBarItemDateWrap>
        <NavBarItemMonth>
          {`${reviewDate.format('MMM')}`}
        </NavBarItemMonth>
        <NavBarItemDay>
          {`${reviewDate.format('DD')}`}
        </NavBarItemDay>
        <NavBarItemMonth>
          {`${reviewDate.format('YYYY')}`}
        </NavBarItemMonth>
      </NavBarItemDateWrap>
    );
  }

  render() {
    const {
      reviewItem, match,
      reviewAllByReviewId = {},
      subject,
      studentList,
    } = this.props;

    const { id, activity_type: activityType = 0 } = reviewItem;
    const activityName = SESSION_ACTIVITY_TYPE.title[activityType];
    const review = _get(match, 'params.reviewId', '');
    const reviewPercent = _get(reviewAllByReviewId, `${id}`, 0);

    const percent = Math.round((reviewPercent / studentList.length) * 100);
    // const title = isShowSubjectName ? `${subject.display_name} Review` : 'Review';
    const title = (subject && subject.display_name) ? `${subject.display_name} Review` : 'Review';

    return (
      <NavBarItemWrap isActive={review == id} onClick={this.navigateToReviewPage}>
        {this.renderProgressBarItem()}
        <NavBarItemCustome>
          <NavBarItemHead>
            <NavBarHeadText>{activityName}</NavBarHeadText>
          </NavBarItemHead>
          <NavBarName>{title}</NavBarName>
          {this.renderSessonProgress(false, false, false, true, percent)}
        </NavBarItemCustome>
        {this.renderReviewDate()}
      </NavBarItemWrap>
    );
  }
}

ReviewSubNavItem.propTypes = {
  reviewItem: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  reviewAllByReviewId: PropTypes.object,
  subject: PropTypes.shape(),
  studentList: PropTypes.array,
};

export default ReviewSubNavItem;
