import React, { useState, useEffect, Suspense } from 'react';
import ReactTooltip from 'react-tooltip';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import { CircularProgress } from '@mui/material';
import Box from '@Atoms/Box';
import ShowIf from '@Atoms/ShowIf';
import Button from '@Atoms/Button';
import Select from '@Atoms/Select';
import Skeleton from '@components/Atoms/Skeleton';
import { TabProvider, Tab, TabPanel, TabList } from '../Shared/TabsDefaultSkin';
import ShadowSrollbar from '../Scrollbar/ShadowScrollbars';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import DashboardLessonActivityReport from '../../containers/DashboardLessonActivityReport';
import DashboardStudentActivitySummaryReport from '../../containers/DashboardStudentActivitySummaryReport';
import DashboardPracticeTimeSeries from '../../containers/DashboardPracticeTimeSeries';
import { ROLE_TYPE } from '../../utils/enums';
import { BlockWrap, BlockTitle } from '../Generals/stylesheets/General.style';
import { DashboardActivityReportWrap } from './DashboardPracticeDetail.style';
import DashboardStandardsReport from '../DashboardStandardsReport';
import { useDownloadSectionReport } from './hooks';
import DashboardSkillsReport from '../DashboardSkillsReport';

const SectionReportDownloadButton = ({ sectionDetail, canDownload }) => {
  const { mutation, isLoading } = useDownloadSectionReport({ sectionDetail });

  if (!canDownload) {
    return null;
  }

  return (
    <Button
      disabled={isLoading}
      onClick={() => {
        mutation.mutate();
      }}
      variant="outlined"
      startIcon={
        isLoading ? (
          <CircularProgress size="16px" />
        ) : (
          <Box
            component="i"
            className="chalktalk-download"
            sx={{ fontSize: '1rem!important' }}
          />
        )
      }
    >
      <span>Download Skill Level Report </span>
    </Button>
  );
};
const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

export default function DashboardPracticeDetail({
  sectionDetail,
  courseDetail,
  sectionUnitGet,
  sectionId,
  fetchSectionSubjectList,
  sectionsPracticeSummaryGet,
  sectionsStudentGet,
  getAllLessonActivity,
  isSideBarClose,
  getAllLessonActivitySummary,
  userRole,
  sectionSkillsGet,
  studentList = [],
  subjects = [],
}) {
  const [selectedSubject, setSelectedSubject] = useState(
    subjects.filter((subject) => subject.practice_type < 2)?.[0] || {},
  );

  const initializeData = () => {
    sectionUnitGet(sectionId);
    sectionsStudentGet(sectionId);
    sectionsPracticeSummaryGet(sectionId);
    sectionSkillsGet(sectionId);
    getAllLessonActivity(sectionId);
    fetchSectionSubjectList(sectionId);
    getAllLessonActivitySummary(sectionId);
  };

  useEffect(() => {
    if (sectionId !== null) {
      initializeData();
    }
  }, [sectionId]);

  useEffect(() => {
    const havePractice = subjects.filter((subject) => subject.practice_type < 2);
    if (havePractice.length) {
      setSelectedSubject(havePractice[0]);
    }
  }, [subjects]);

  const { id: idSelected = '' } = selectedSubject || {};
  const isAdaptiveLearnosityPracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();

  const canDownload =
    !courseDetail?.standard_practice_enabled || isAdaptiveLearnosityPracticeEnabled;

  return (
    <>
      <BreadcumbsFilter
        haveSub={isSideBarClose}
        breadcumbSubList={[courseDetail.name, sectionDetail.name]}
        breadcumbList={['Dashboard', 'Detail', 'Practice Detail']}
        mobileTilte="Practice Detail"
      />
      <DashboardActivityReportWrap>
        {userRole !== ROLE_TYPE.STUDENT && (
          <ReactTooltip place="top" effect="solid" id="tooltip-activity-tab" />
        )}
        <ShadowSrollbar autoHide style={scrollStyle}>
          <BlockWrap noPadding>
            <BlockTitle>
              <span>Activity Reports</span>
            </BlockTitle>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingInline: '1rem',
                paddingBlock: '1rem',
                paddingBlockStart: '1.25rem',
              }}
            >
              <Select
                key={subjects?.length || ''}
                id="activity-report-subject-select"
                label="Subject"
                size="small"
                list={subjects
                  .filter((subject) => subject.practice_type < 2)
                  .map((subject) => ({
                    label: subject.display_name || '',
                    value: subject.id,
                  }))}
                onChange={(e) => {
                  setSelectedSubject(subjects.find((s) => s.id === e.target.value));
                }}
                defaultValue={
                  selectedSubject.id ||
                  subjects.filter((subject) => subject.practice_type < 2)?.[0]?.id
                }
                value={selectedSubject.id || ''}
                sx={{ minWidth: '8rem' }}
              />
              <SectionReportDownloadButton
                sectionDetail={sectionDetail}
                canDownload={canDownload}
              />
            </div>
            <TabProvider defaultTab={1}>
              <Box display="flex">
                <TabList style={{ height: '3rem', borderRadius: '0.5rem 0.5rem 0 0' }}>
                  <Tab
                    isLessonTab
                    tabKey={1}
                    data-tip="View progress of each lesson and breakdown per student"
                    data-for="tooltip-activity-tab"
                  >
                    Student
                  </Tab>
                  <Tab
                    isLessonTab
                    tabKey={0}
                    data-tip="View progress of each student for each subject"
                    data-for="tooltip-activity-tab"
                  >
                    Lesson
                  </Tab>
                  {courseDetail?.standard_practice_enabled && (
                    <Tab
                      isLessonTab
                      tabKey={2}
                      data-tip="View progress of each student by standard"
                      data-for="tooltip-activity-tab"
                    >
                      Standards
                    </Tab>
                  )}
                  <ShowIf If={isAdaptiveLearnosityPracticeEnabled}>
                    <Tab
                      isLessonTab
                      tabKey={3}
                      data-tip="View progress of each student by skill"
                      data-for="tooltip-activity-tab"
                    >
                      Skills
                    </Tab>
                  </ShowIf>
                </TabList>
              </Box>
              <Box
                sx={{
                  paddingBlockStart: '1rem',
                  backgroundColor: (theme) => theme.palette.background.default,
                  paddingInline: '0.5rem',
                  borderRadius: '0 0 0.5rem 0.5rem',
                }}
              >
                <Suspense fallback={<Skeleton height="20rem" />}>
                  <TabPanel tabKey={0}>
                    <DashboardLessonActivityReport
                      studentList={studentList}
                      subjectId={idSelected}
                      sectionId={sectionId}
                      courseDetail={courseDetail}
                    />
                  </TabPanel>
                  <TabPanel tabKey={1}>
                    <DashboardStudentActivitySummaryReport
                      studentList={studentList}
                      subjectId={idSelected}
                      sectionId={sectionId}
                      courseDetail={courseDetail}
                    />
                  </TabPanel>
                  <TabPanel tabKey={2}>
                    <DashboardStandardsReport subject={selectedSubject} />
                  </TabPanel>
                  <TabPanel tabKey={3}>
                    <DashboardSkillsReport
                      subject={selectedSubject}
                      studentList={studentList}
                      sectionId={sectionId}
                    />
                  </TabPanel>
                </Suspense>
              </Box>
            </TabProvider>
          </BlockWrap>
          <DashboardPracticeTimeSeries studentList={studentList} />
        </ShadowSrollbar>
      </DashboardActivityReportWrap>
    </>
  );
}
