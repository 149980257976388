import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _round from 'lodash/round';
import DashboardSummaryTable from '../../components/DashboardSummary/DashboardSummaryTable';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import { actions as SectionUnitActions } from '../../reducers/sectionUnit';
import {
  actions as SectionActions,
} from '../../reducers/sections';

const mapStateToProps = (state, { match, practiceActivityReport }) => {
  const sectionId = _get(match, 'params.sectionId', null);
  const subjects = SectionSubjectSelectors.getSectionSubjectListOrder(state);
  const practiceActivityReportTransform = subjects.reduce((prevItem, item) => {
    const { id: subjectId } = item;
    const activityReport = _get(practiceActivityReport, subjectId, {});
    const { totalPracticeCount = 0, studentPracticeCount = 0, ...rest } = activityReport;
    const practiceCoverage = totalPracticeCount > 0 ? studentPracticeCount / totalPracticeCount : 0;

    return {
      ...prevItem,
      [subjectId]: {
        ...rest,
        practiceCoverage: _round(practiceCoverage * 100, 1),
      },
    };
  }, {});

  return {
    sectionId,
    subjects,
    practiceActivityReport: practiceActivityReportTransform,
    isLoading: SectionSubjectSelectors.isGettingSectionSubject(state),
    userRole: UserSelectors.getUserRole(state),
  };
};

const mapDispatchToProps = {
  sectionsStudentPracticeSummaryGet: SectionActions.sectionsStudentPracticeSummaryGet,
  sectionUnitGetMeta: SectionUnitActions.sectionUnitGetMeta,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardSummaryTable));
