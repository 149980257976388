import React from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { useServiceContext } from '../../Context/LearnosityProvider';
import {
  getSignature as retrieveSignature,
  getQuestions as retrieveQuestions,
  getSessionQuestions as retrieveSessionQuestions,
  LearnositySignatureRequest,
  LearnositySignature,
  LearnosityAuthoringSignatureRequest,
  LearnosityApiService,
} from '../../AssessmentServiceAPI';

const useGetSessionQuestions = (sessionId: string) => {
  const { serviceURL, serviceEnvironment } = useServiceContext();
  return useQuery(
    ['learnosityService', 'SessionQuestions', sessionId],
    () => retrieveSessionQuestions(serviceURL.normal, serviceEnvironment, sessionId),
    {
      placeholderData: [],
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      // useErrorBoundary: true,
    },
  );
};
type useGetQuestionsProps = {
  standards: string[];
  limit: number;
  lessonCode: string;
  offset?: number;
  publication?: string;
  user: string;
  useErrorBoundary?: boolean;
  language?: string;
};
const useGetQuestions = ({
  standards,
  limit,
  lessonCode,
  offset = 0,
  publication = 'CCSS',
  user,
  useErrorBoundary = false,
  language = 'en',
}: useGetQuestionsProps) => {
  const { serviceURL, serviceEnvironment } = useServiceContext();
  return useQuery(
    [
      'learnosityService',
      'Questions',
      { language, lessonCode, limit, offset, publication, standards },
    ],
    () =>
      retrieveQuestions({
        baseUrl: serviceURL.CDN,
        environment: serviceEnvironment,
        language,
        lessonCode,
        limit,
        offset,
        publication,
        standards,
        user,
      }),
    {
      enabled: standards.length > 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      useErrorBoundary,
    },
  );
};


// check here to remove LearnosityAuthoringSignature
const useGetSignature = (
  signatureRequest: LearnositySignatureRequest | LearnosityAuthoringSignatureRequest,
  api: LearnosityApiService,
  options: Omit<UseQueryOptions<any, any, any>, 'queryKey' | 'queryFn'> = {},
) => {
  const { serviceURL, serviceEnvironment } = useServiceContext();
  return useQuery<LearnositySignature>(
    ['learnosityService', 'Signature', signatureRequest],
    () => retrieveSignature(serviceURL.normal, serviceEnvironment, signatureRequest, api),
    {
      placeholderData: {} as LearnositySignature,
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export { useGetSessionQuestions, useGetQuestions, useGetSignature };
