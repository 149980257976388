import styled from 'styled-components';

export const MainSelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  > div {
    margin: 1em 0.5em !important;
  }
`;

export const QuestionViewerWrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
    margin-left: 2em;
  }
`;

export const AssetViewerWrapper = styled.div`
  width: 40%;
  margin: 0 auto;
`;
