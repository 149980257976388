import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import * as PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import _get from 'lodash/get';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import Box from '@Atoms/Box';
import CourseSelect from '../../containers/CourseSelect';
import SectionSelect from '../../containers/SectionSelect';
import ListLoader from '../LoadingPlaceholder/ListLoader';
import {
  LeftSiderBarContentGroup,
  LeftSideBarList,
  MultiItemsSiderBar,
  TitleSideBar,
  CollapseSideBar,
  CollapseSideBarItem,
  SideBarItemAttention,
  SideBarContent,
  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
} from './LeftSiderBar.style';
import SubNavbarLessonSessions from '../../containers/SubNavbarLessonSessions/SubNavbarLessonSessions';

export default class LeftSideBarLessons extends Component {
  static propTypes = {
    match: PropTypes.any,
    pathName: PropTypes.string,
    shouldDisplaySectionSelect: PropTypes.bool,
    history: PropTypes.shape(),
    subjects: PropTypes.array,
    sectionId: PropTypes.any,
    sectionUnitGet: PropTypes.func,
    sectionReviewGet: PropTypes.func,
    sectionSessionGet: PropTypes.func,
    isSideBarClose: PropTypes.bool,
    toggleLeftSidebar: PropTypes.func,
    fetchSectionSubjectList: PropTypes.func,
    getAllLessonActivitySummary: PropTypes.func,

    isTeacher: PropTypes.bool.isRequired,

    sectionsPracticeSummaryGet: PropTypes.func.isRequired,
    sectionsStudentPracticeSummaryGet: PropTypes.func.isRequired,

    sectionReviewSectionSummaryGet: PropTypes.func.isRequired,
    sectionStudentReviewSectionSummaryGet: PropTypes.func.isRequired,

    sectionsAttendanceGet: PropTypes.func.isRequired,
    isLoadingSubject: PropTypes.bool,
    isLoadingUnit: PropTypes.bool,
    assignCountBySubject: PropTypes.object,
    fetchLessonAssignedActivityList: PropTypes.func,
    unitBySubject: PropTypes.object,
  };

  state = {
    isSubjectDropdown: true,

    subjectVersion: 1,
  };

  componentDidMount() {
    const { sectionId } = this.props;

    if (sectionId !== null) {
      this.getSecionInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionId: prevSectionId } = prevProps;
    const { sectionId } = this.props;

    if (sectionId !== null && !_isEqual(prevSectionId, sectionId)) {
      this.getSecionInitialData();
    }

    ReactTooltip.rebuild();
  }

  toggleLeftSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  };

  getSecionInitialData = () => {
    const {
      sectionId,
      sectionUnitGet,
      sectionReviewGet,
      sectionSessionGet,
      fetchSectionSubjectList,
      getAllLessonActivitySummary,
      sectionsPracticeSummaryGet,
      sectionsStudentPracticeSummaryGet,

      sectionReviewSectionSummaryGet,
      sectionStudentReviewSectionSummaryGet,
      fetchLessonAssignedActivityList,
      sectionScheduleGet,
      sectionsAttendanceGet,
      isTeacher,
    } = this.props;

    sectionUnitGet(sectionId);
    sectionReviewGet(sectionId);
    sectionSessionGet(sectionId);
    fetchSectionSubjectList(sectionId);
    getAllLessonActivitySummary(sectionId);
    sectionsAttendanceGet(sectionId);
    sectionScheduleGet(sectionId);
    if (isTeacher) {
      sectionsPracticeSummaryGet(sectionId);
      sectionReviewSectionSummaryGet(sectionId);
    } else {
      fetchLessonAssignedActivityList(sectionId);
      sectionsStudentPracticeSummaryGet(sectionId);
      sectionStudentReviewSectionSummaryGet(sectionId);
    }
  };

  findUpNextLesson = (subject) => {
    const { completedPracticeUnitIds, lessonActivitySummaryById, lessonList } =
      this.props;
    const filteredSessionList = lessonList.filter(
      (session) => session.activity_type != 2 && session.subject == subject.id,
    );
    filteredSessionList.sort((a, b) => moment(a.date).diff(moment(b.date)));
    filteredSessionList.sort((a, b) => {
      if (moment(a.date).isSame(moment(b.date))) {
        if (a.id < b.id) {
          return -1;
        }
      }
      return 0;
    });
    let upNextSession = filteredSessionList[filteredSessionList.length - 1];
    const inCompleteSessions = filteredSessionList.filter((session) => {
      if (session.activity_type == 1) {
        return !completedPracticeUnitIds.includes(session.unit);
      }
      const isCompleted = _get(
        lessonActivitySummaryById,
        `${session.lesson}.completed`,
        false,
      );
      return !isCompleted;
    });
    if (inCompleteSessions.length > 0) {
      upNextSession = inCompleteSessions[0];
    }
    return upNextSession;
  };

  toggleSubNavBar = (subject) => {
    const { match, history, unitBySubject } = this.props;
    const unit = unitBySubject[subject.id]?.units[0] || {};
    const { lessons = [] } = unit || [];
    const upComingItem = this.findUpNextLesson(subject);
    const { courseId, sectionId } = match.params;
    let unitId = unit.id;
    let lesson = lessons[0].id;
    let isPractice = false;
    if (upComingItem) {
      unitId = upComingItem.unit;
      lesson = upComingItem.lesson;
      isPractice = upComingItem.activity_type == 1 ? true : isPractice;
    }
    if (isPractice) {
      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subject.id}/unit/${unitId}/practice`,
      );
    } else {
      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subject.id}/unit/${unitId}/lesson/${lesson}?tab=${LessonTabs.Lesson}`,
      );
    }
  };

  // Subject item for e.g. Math, English, etc.
  renderSubjectItem = (subject, index) => {
    const { assignCountBySubject } = this.props;
    const { display_name: displayName, practice_type: practiceType, icon, id } = subject;
    const assignCount = _get(assignCountBySubject, id, 0);
    const extraDataText =
      practiceType === 1 ? 'Complete in a unit practice session.' : '';
    return (
      <CollapseSideBarItem key={index} onClick={() => this.toggleSubNavBar(subject)}>
        <img src={icon} alt="" />
        <span>{displayName}</span>
        {assignCount > 0 && (
          <SideBarItemAttention
            data-tip={`${assignCount} ${displayName} topic assigned for practice. ${extraDataText}`}
            data-for="tooltip-subject-assigned"
          >
            {assignCount}
          </SideBarItemAttention>
        )}
      </CollapseSideBarItem>
    );
  };

  renderSideBarContent() {
    const { isSubjectDropdown } = this.state;
    const { subjects = [], isLoadingSubject, isLoadingUnit } = this.props;
    const height = (subjects || []).length * 2.5;
    return (
      <LeftSiderBarContentGroup>
        <TitleSideBar>Lessons</TitleSideBar>
        <LeftSideBarList>
          <MultiItemsSiderBar noLink>
            <SideBarContent isRotate={!isSubjectDropdown}>
              <span>Subjects</span>
            </SideBarContent>
          </MultiItemsSiderBar>
          <CollapseSideBar noIcon height={height} isClose={!isSubjectDropdown}>
            {isLoadingSubject || isLoadingUnit ? (
              <ListLoader />
            ) : (
              subjects.map(this.renderSubjectItem)
            )}
          </CollapseSideBar>
        </LeftSideBarList>
      </LeftSiderBarContentGroup>
    );
  }

  render() {
    const { pathName, shouldDisplaySectionSelect, isSideBarClose, isLoadingSubject } =
      this.props;
    const { subjectVersion } = this.state;

    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <ReactTooltip place="right" effect="solid" id="tooltip-subject-assigned" />
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <CourseSelect pathName={pathName} />
            {shouldDisplaySectionSelect && <SectionSelect pathName={pathName} />}
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        <Box
          sx={{
            position: 'relative',
            flex: 1,
          }}
        >
          {this.renderSideBarContent()}

          {!isLoadingSubject && (
            <SubNavbarLessonSessions subjectVersion={subjectVersion} />
          )}
        </Box>
        <ActionShowHide className="toggle-icon" onClick={this.toggleLeftSidebar}>
          <MediaQuery maxWidth={960}>
            {(matches) =>
              matches ? (
                <span
                  className={isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'}
                />
              ) : (
                <span
                  className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'}
                />
              )
            }
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    );
  }
}
