import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import LeftSideBarSchedule from '../../components/LeftSideBar/LeftSideBarSchedule';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as sectionSessionSelectors } from '../../reducers/sectionSession';
import { selectors as sectionReviewsSelectors } from '../../reducers/sectionReviews';
import { selectors as sectionExamSelectors } from '../../reducers/sectionExam';
import {
  selectors as SectionSubjectSelectors,
  actions as SectionSubjectActions,
  isGettingSectionSubject,
} from '../../reducers/sectionSubjects';
import {
  selectors as LeftSidebarSelectors,
  actions as LeftSidebarActions,
} from '../../reducers/leftSideBar';
import { selectors as CourseSelectors } from '../../reducers/courses';

const lessonSessionPath = '/schedule/course/:courseId/section/:sectionId/create-lesson-session';
const reviewSessionPath = '/schedule/course/:courseId/section/:sectionId/create-review-session';
const examSessionPath = '/schedule/course/:courseId/section/:sectionId/create-exam-session';
const sectionInfoPath = '/schedule/course/:courseId/section/:sectionId/section-info';
const instructionsPath = '/schedule/course/:courseId/section/:sectionId/instructions';

const mapStateToProps = (state, { match, updateScheduleList }) => {
  const currentPath = _get(match, 'path', '/');
  const courseIdSelected = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const shouldDisplaySectionSelect = !!courseIdSelected;
  const courseItem = CourseSelectors.getCourseDetailById(state, courseIdSelected);
  const { subscription = {} } = courseItem;
  const { subscription_type: subType } = subscription;
  const isLimited = subType === 3;
  const isOpenLessonSession = !isLimited && currentPath === lessonSessionPath;
  const isOpenReviewSession = !isLimited && currentPath === reviewSessionPath;
  const isOpenExamSession = !isLimited && currentPath === examSessionPath;
  const isOpenSectionInfo = !isLimited && currentPath === sectionInfoPath;
  const isOpenInstructions = !isLimited && currentPath === instructionsPath;
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');

  return {
    sectionId,
    courseItem,
    isLimited,
    curriculumId,
    isOpenLessonSession,
    isOpenReviewSession,
    isOpenExamSession,
    isOpenSectionInfo,
    isOpenInstructions,
    updateScheduleList,
    courseId: courseIdSelected,
    shouldDisplaySectionSelect,
    userRole: UserSelectors.getUserRole(state),
    isAddLessonSessionForm: sectionSessionSelectors.isAddSuccess(state),
    isAddSectionReviewSuccess: sectionReviewsSelectors.isSubmitSuccessfully(state),
    isAddSectionExamSuccess: sectionExamSelectors.isSubmitSuccessfully(state),
    isSideBarClose: LeftSidebarSelectors.getSideBar(state),
    subjects: SectionSubjectSelectors.getSectionSubjectListOrder(state),
    isLoading: SectionSubjectSelectors.isGettingSectionSubject(state),
  };
};

const mapDispatchToProps = {
  toggleLeftSidebar: LeftSidebarActions.toggleLeftSidebar,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideBarSchedule),
);
