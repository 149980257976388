import { connect } from 'react-redux';
import _get from 'lodash/get';
import AdminReports from '../../components/AdminReports';
import {
  actions as AdminReportsActions,
  selectors as AdminReportsSelector
} from '../../reducers/adminReports';

const mapStateToProps = (state) => {
  const adminReports = AdminReportsSelector.getAdminReportsUrl(state);

  return {
    adminReports: adminReports,
  };
};

const mapDispatchToProps = {
  fetchAdminReportsUrl: AdminReportsActions.fetchAdminReportsUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminReports);