import { connect } from 'react-redux';
import OnlineQuestionItem from '../../components/OnlineTestContent/OnlineQuestionItem';
import { selectors, actions } from '../../reducers/exam';

export const mapStateToProps = (state, { questionSelected }) => {
  const { id } = questionSelected;

  return {
    answer: selectors.getAnswerByQuestionId(state, id),
  };
};

const mapDispatchToProps = {
  examChooseAnswer: actions.examChooseAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineQuestionItem);
