/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSectionStudent } from '@reducers/sectionStudent/hooks';
import { useParams, useHistory } from 'react-router-dom';
import useLearnosityQuestionCT from '@utils/hooks/useLearnosityQuestionCT';
import { useDispatch } from 'react-redux';
import { actions as LessonAssignmentActions } from '@reducers/lessonAssignment';
import { actions as SectionActions } from '@reducers/sections';
import { getSearchParamsObject } from '@utils/commonUtils';
import { useIsMutating } from 'react-query';
import { useGetLessonAttendance } from './query';
import { AttendanceRecords, LessonAttendanceActivity, StudentRecord } from '../types';
import { useGetLessonDetail } from '../../LessonContainer/hooks/query';
import { useGetUnitMetaObject } from '../../LessonContainer/hooks';
import { LessonTabs } from '../../LessonContainer/Types';
// ------------------------------------------------------------  helpers  ------------------------------------------------------------
// Attendance status constants
export const AttendanceStatus = {
  Present: true,
  Absent: false,
  UnRecorded: null,
};

// ------------------------------------------------------------  hooks  ------------------------------------------------------------

// Utility function to get the next attendance status
export const getNextAttendanceStatus = (currentStatus) => {
  switch (currentStatus) {
    case AttendanceStatus.UnRecorded:
      return AttendanceStatus.Present;
    case AttendanceStatus.Present:
      return AttendanceStatus.Absent;
    case AttendanceStatus.Absent:
      return AttendanceStatus.UnRecorded;
    default:
      return currentStatus;
  }
};

export const useActiveAttendanceActivity = () => {
  const history = useHistory();
  const queryObject = getSearchParamsObject(history.location.search);
  const activeTab = queryObject.tab;

  if (activeTab === LessonTabs.Lesson) {
    return LessonAttendanceActivity.Lesson;
  }

  return LessonAttendanceActivity.Group;
};

export const useGetLessonAttendanceByActivityType = ({
  activity,
  lessonGroupId,
}: {
  activity?: LessonAttendanceActivity;
  lessonGroupId?: string;
} = {}) => {
  const DefaultActivity = useActiveAttendanceActivity();
  const { data: attendanceData } = useGetLessonAttendance(lessonGroupId);
  return React.useMemo(
    () =>
      attendanceData?.filter(
        (attendance) => attendance.activity === (activity ?? DefaultActivity),
      ) || [],
    [activity, attendanceData],
  );
};

/**
 * Returns the attendance data for the given activity type.
 * If the attendance record does not exist, it creates a new attendance placeholder for each student.
 * placeholder attendance object:
 * @example
 * {
 *   lesson: 114,
 *   activity: 'lesson',
 *   section: 47900,
 *   student: 123666,
 *   present: null
 * }
 */
export const useMappedAttendance = (): AttendanceRecords => {
  const activity = useActiveAttendanceActivity();
  const attendanceData = useGetLessonAttendanceByActivityType();
  const sectionStudents: Array<StudentRecord> = useSectionStudent();
  const { sectionId, lessonId } = useParams();

  return React.useMemo(
    () =>
      sectionStudents?.map((student) => {
        const studentFullName = `${student.first_name} ${student.last_name}`;
        // Find the attendance object for the student
        const attendanceObject = attendanceData?.find(
          (attendance) => attendance.student === student.id,
        );

        // If the attendance object exists, return it
        if (attendanceObject) {
          return {
            ...attendanceObject,
            studentFullName,
          };
        }
        // If the attendance object does not exist, create a placeholder attendance object
        return {
          lesson: lessonId,
          activity,
          section: sectionId,
          student: student.id,
          present: null,
          studentFullName,
        };
      }),
    [activity, attendanceData, lessonId, sectionId, sectionStudents],
  );
};

export const generateAttendanceRecords = (attendanceList, currentStatus) =>
  attendanceList.map((student) => ({
    student: student.student,
    present: currentStatus,
  }));

export const useAutoAssignPractice = () => {
  const dispatch = useDispatch();
  const { data: lessonDetail } = useGetLessonDetail();
  const unitMeta = useGetUnitMetaObject();
  const hasLessonPractice = unitMeta?.lesson_practice ?? false;
  const hasUnitPractice = unitMeta?.unit_practice ?? false;
  const { hasStandardsPractice } = useLearnosityQuestionCT({ lessonDetail });

  const autoAssignPractice = React.useCallback(
    (payload, newAttendanceStatus) => {
      if (!hasUnitPractice && !hasLessonPractice && !hasStandardsPractice) {
        return;
      }

      // Unit Practice doesn't store lesson_group
      if (hasUnitPractice) {
        // eslint-disable-next-line no-param-reassign
        payload.lesson_group = null;
      }

      // Marking absent forces an assignment
      if (newAttendanceStatus === AttendanceStatus.Absent) {
        dispatch(LessonAssignmentActions.assignLesson(payload.section, payload, false));

        // If deleting attendance or marking present, unassign if recently assigned
      }
      if (
        newAttendanceStatus === AttendanceStatus.UnRecorded ||
        newAttendanceStatus === AttendanceStatus.Present
      ) {
        dispatch(LessonAssignmentActions.unAssignLesson(payload.section, payload, false));
      }
    },
    [hasUnitPractice, hasLessonPractice, hasStandardsPractice, dispatch],
  );

  return { autoAssignPractice };
};

export const useMarkLessonAsCompleted = ({ onLessonChange }) => {
  const activity = useActiveAttendanceActivity();
  const attendanceRecords = useGetLessonAttendanceByActivityType();
  const dispatch = useDispatch();
  const { lessonId, unitId, subjectId, sectionId } = useParams();

  const markLessonAsCompleted = React.useCallback(() => {
    if (activity !== LessonAttendanceActivity.Lesson || !!attendanceRecords.length) {
      return;
    }
    const payload = {
      unit: unitId,
      lesson: lessonId,
      subject: subjectId,
    };
    onLessonChange?.(lessonId, true);
    dispatch(SectionActions.updateSectionComplete(sectionId, payload));
  }, [
    activity,
    attendanceRecords.length,
    dispatch,
    lessonId,
    onLessonChange,
    sectionId,
    subjectId,
    unitId,
  ]);

  return { markLessonAsCompleted };
};

export const useIsMutatingSubmitLessonAttendance = () => {
  const { lessonId } = useParams();
  const isMutatingSubmitLessonAttendance: number = useIsMutating([
    'submitLessonAttendance',
    lessonId,
  ]);

  return isMutatingSubmitLessonAttendance > 0;
};

export const useAttendanceCounts = () => {
  const lessonAttendanceList = useMappedAttendance();
  const totalPresentStudents = lessonAttendanceList.filter(
    (student) => student.present,
  ).length;
  const totalAbsentStudents = lessonAttendanceList.filter(
    (student) => student.present === false,
  ).length;
  const totalUnrecordedStudents = lessonAttendanceList.filter(
    (student) => student.present === null,
  ).length;
  return { totalPresentStudents, totalAbsentStudents, totalUnrecordedStudents };
};
