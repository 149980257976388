import React from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import queryClient from '../utils/queryClient';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  serviceEnvironment?: string;
};

const ReactQueryProviderContext = ({ children, serviceEnvironment }: Props) => (
  <QueryClientProvider client={queryClient} contextSharing>
    {children}
    {serviceEnvironment === 'dev' && (
      <ReactQueryDevtools
        toggleButtonProps={{
          style: {
            display: 'none',
          },
        }}
      />
    )}
  </QueryClientProvider>
);

export default React.memo(ReactQueryProviderContext);
