import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import DatePicker from 'react-datepicker';
import { Field, getIn } from 'formik';
import {
  func, bool, shape, any, string,
} from 'prop-types';
import _ from 'lodash';
import FormLabel from './FormLabel';
import {
  FormItem, FormError, FormInputStyled, FormInputWrapperStyled, FormCheckboxStyled, FormRadiogroupStyled,
} from '../stylesheets/Form.style';
import 'react-datepicker/dist/react-datepicker.css';

export const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  PHONE_NUMBER: 'phoneNumber',
  CHECKBOX: 'checkbox',
  CHECKBOXGROUP: 'checkboxgroup',
  RADIOGROUP: 'radiogroup',
  SELECT: 'select',
  CREATE_SELECT: 'createselect',
  DATE_PICKER: 'date',
  TEXT_AREA: 'textarea',
  SWITCH: 'switch',
};

const FORM_LAYOUT = {
  labelCol: { xs: 24, sm: 24 },
  wrapperCol: { xs: 24, sm: 24 },
};

class FormInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
    };
  }

    static propTypes = {
      refInput: func,
      handleShowPassword: func,
      handleHidePassword: func,
      shouldRenderFeedback: bool,
      formLayout: shape(),
      regular: any,
      showPassword: bool,
      type: string,
      inputTable: bool,
      isInInviteForm: bool,
    }

    static defaultProps = {
      formLayout: FORM_LAYOUT,
      refInput: () => {
      },
      shouldRenderFeedback: true,
    }

    handleOnFocus = () => {
      this.setState({
        isFocus: true,
      });
    }

    handleOnBlur = () => {
      this.setState({
        isFocus: false,
      });
    }

    renderTextInput = ({
      field, // { name, value, onChange, onBlur }
      form: { touched, errors },
      form,
      ...props
    }) => {
      const {
        inputSize,
        label,
        formLayout,
        refInput,
        shouldRenderFeedback,
        type,
        icon,
        dataTip,
        disable,
        inputTable,
        isInInviteForm,
        ...rest
      } = props;

      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';
      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }
      const errorMessageStr = typeof errorMessage === 'string';
      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          disabled={disable}
          inputTable={inputTable}
          isInInviteForm={isInInviteForm}
          {...formLayout}
        >
          <FormLabel label={label} icon={icon} dataTip={dataTip} />
          <FormInputStyled
            ref={this.input}
            {...field}
            {...rest}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            size={inputSize}
            type={type}
            disabled={disable}
            isInInviteForm={isInInviteForm}
          />
          {errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          )}
        </FormInputWrapperStyled>
      );
    };

    renderNumberInput = ({
      field, // { name, value, onChange, onBlur }
      form,
      ...props
    }) => {
      const {
        inputSize,
        label,
        formLayout,
        shouldRenderFeedback,
        icon,
        dataTip,
        type,
        handleChange,
        liveValidation,
        ...rest
      } = props;

      const { touched, errors } = form;

      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';

      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }
      const onChange = (e) => {
        form.setFieldValue(field.name, e.target.value);
        if (typeof handleChange === 'function') {
          handleChange(e.target.value, field.name, form);
        }
      };

      const errorMessageStr = typeof errorMessage === 'string';
      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          {...formLayout}
        >
          <FormLabel label={label} icon={icon} dataTip={dataTip} />
          <FormInputStyled
            {...field}
            ref={this.input}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            size={inputSize}
            type={type}
            onChange={onChange}
            {...rest}
          />
          {liveValidation ? (errors[field.name] && <FormError className="form-error">{errors[field.name]}</FormError>) : (errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          ))}
        </FormInputWrapperStyled>
      );
    };

    renderPhoneInput = ({
      field, // { name, value, onChange, onBlur }
      form: { touched, errors },
      form,
      ...props
    }) => {
      const {
        inputSize,
        label,
        formLayout,
        refInput,
        shouldRenderFeedback,
        type,
        icon,
        dataTip,
        disable,
        country,
        ...rest
      } = props;

      const onChange = (value) => {
        form.setFieldValue(field.name, value);
      };

      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';
      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }

      const errorMessageStr = typeof errorMessage === 'string';
      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          disabled={disable}
          customize={type === 'phoneNumber'}
          {...formLayout}
        >
          <FormLabel label={label} icon={icon} dataTip={dataTip} />
          <ReactPhoneInput
            ref={this.input}
            {...field}
            {...rest}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onChange={onChange}
            disabled={disable}
            placeholder=""
            enableSearchField
          />
          {errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          )}
        </FormInputWrapperStyled>
      );
    };

    renderCheckbox = ({
      field, // { name, value, onChange, onBlur }
      form,
      ...props
    }) => {
      const {
        label,
        inputSize,
        handleChange,
        formLayout,
        shouldRenderFeedback,
        type,
        disable,
      } = props;
      const { touched, errors } = form;

      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';

      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }

      const onChange = (e) => {
        if (typeof handleChange === 'function') {
          handleChange();
        }
        field.onChange(e);
      };

      const errorMessageStr = typeof errorMessage === 'string';
      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          disabled={disable}
          {...formLayout}
        >
          <FormCheckboxStyled>
            <input
              {...field}
              size={inputSize}
              onChange={onChange}
              checked={_.get(form, `values.${field.name}`)}
              type={type}
              disabled={disable}
            />
            <span className="checkmark" />
            <span>{label}</span>
          </FormCheckboxStyled>
          {errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          )}
        </FormInputWrapperStyled>
      );
    };

    renderSelect = ({
      field, // { name, value, onChange, onBlur }
      form,
      formLayout,
      shouldRenderFeedback,
      isDisabled,
      onInputChange,
      selectedValue,
      'data-testid': testId,
      ...props
    }) => {
      const {
        disabled, options, inputSize, icon, dataTip, label, inputTable, handleChange, isMultiSelect, ...rest
      } = props; // required

      const onChange = (value) => {
        form.setFieldValue(field.name, value);
        if (typeof handleChange === 'function') {
          handleChange(value, form);
        }
      };
      const { touched, errors } = form;
      const { value } = field;
      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';

      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }
      const errorMessageStr = typeof errorMessage === 'string';
      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          disabled={isDisabled}
          inputTable={inputTable}
          {...formLayout}
          {...props}
        >
          <FormLabel label={label} icon={icon} dataTip={dataTip} />
          <Select
            {...field}
            data-testid={testId}
            options={options}
            classNamePrefix="select"
            onChange={onChange}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            disabled={disabled}
            size={inputSize}
            ref={this.input}
            onInputChange={onInputChange}
            placeholder=""
            value={value}
            defaultValue={value}
            isMulti={!!isMultiSelect}
            {...rest}
          />
          {errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          )}
        </FormInputWrapperStyled>
      );
    };

    renderCreateSelect = ({
      field, // { name, value, onChange, onBlur }
      form,
      formLayout,
      shouldRenderFeedback,
      value,
      onChange,
      onCreateOption,
      ...props
    }) => {
      const {
        disabled, options, inputSize, icon, dataTip, label, ...rest
      } = props; // required

      const { touched, errors } = form;

      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';

      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }

      const errorMessageStr = typeof errorMessage === 'string';
      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          {...formLayout}
          {...props}
        >
          <FormLabel label={label} icon={icon} dataTip={dataTip} />
          <CreatableSelect
            {...field}
            options={options}
            classNamePrefix="select"
            value={value}
            onChange={onChange}
            onCreateOption={onCreateOption}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            disabled={disabled}
            size={inputSize}
            ref={this.input}
            placeholder=""
            {...rest}
          />
          {errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          )}
        </FormInputWrapperStyled>
      );
    };

    renderDatePicker = ({
      field, // { name, value, onChange, onBlur }
      form,
      formLayout,
      inputSize,
      label,
      shouldRenderFeedback,
      icon,
      dataTip,
      minDate,
      liveValidation,
      ...props
    }) => {
      const { handleChange, ...rest } = props;
      const onChange = (date) => {
        setFieldValue(field.name, date === null ? '' : date);
        setTimeout(() => {
          if (typeof handleChange === 'function') handleChange(date, form);
        }, 100);
      };

      const { touched, errors, setFieldValue } = form;

      const isTouched = getIn(touched, field.name);
      let errorMessage = '';
      let validateStatus = 'success';

      if (isTouched) {
        errorMessage = getIn(errors, field.name);
        if (errorMessage) {
          validateStatus = 'error';
        }
      }
      const errorMessageStr = typeof errorMessage === 'string';

      return (
        <FormInputWrapperStyled
          label={label}
          hasFeedback={shouldRenderFeedback && validateStatus === 'error'}
          validateStatus={validateStatus}
          error={errorMessage}
          {...formLayout}
        >
          <FormLabel label={label} icon={icon} dataTip={dataTip} />
          <DatePicker
            value={field.value}
            size={inputSize}
            selected={field.value}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}
            onChange={onChange}
            format="MM/DD/YYYY"
            ref={this.input}
            minDate={new Date(minDate)}
            disabledKeyboardNavigation
            {...rest}
          />
          {liveValidation ? (errors[field.name] && <FormError className="form-error">{errors[field.name]}</FormError>) : (errorMessage && (
            <FormError className="form-error">{errorMessageStr && errorMessage}</FormError>
          ))}
        </FormInputWrapperStyled>
      );
    };

    renderFormInput = (props) => {
      const { type } = props;
      switch (type) {
        case INPUT_TYPES.TEXT:
          return this.renderTextInput(props);
        case INPUT_TYPES.NUMBER:
          return this.renderNumberInput(props);
        case INPUT_TYPES.PHONE_NUMBER:
          return this.renderPhoneInput(props);
        case INPUT_TYPES.CHECKBOX:
          return this.renderCheckbox(props);
        case INPUT_TYPES.SELECT:
          return this.renderSelect(props);
        case INPUT_TYPES.CREATE_SELECT:
          return this.renderCreateSelect(props);
        case INPUT_TYPES.DATE_PICKER:
          return this.renderDatePicker(props);
        default:
          return this.renderTextInput(props);
      }
    };

    renderShowPasswordIcon = () => {
      const { handleShowPassword, handleHidePassword } = this.props;
      return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <i className="chalktalk-icon-eyes" onMouseUp={handleHidePassword} onMouseDown={handleShowPassword} />
      );
    }

    renderFormWrapper = (fieldProps) => {
      const value = getIn(fieldProps, 'field.value', '');
      const { isFocus } = this.state;
      const { showPassword, inputTable, isInInviteForm } = this.props;
      const shouldIsFocus = !!value || isFocus; // !! pattern change value of field to boolean
      return (
        <FormItem haveValue={shouldIsFocus} inputTable={inputTable} isInInviteForm={isInInviteForm}>
          {this.renderFormInput(fieldProps)}
          {showPassword && shouldIsFocus && this.renderShowPasswordIcon()}
        </FormItem>
      );
    }

    render() {
      const { regular, ...rest } = this.props;
      if (regular) {
        return this.renderFormInput(this.props);
      }
      return (
        <Field
          {...rest}
          component={this.renderFormWrapper}
        />
      );
    }
}

export default FormInput;
