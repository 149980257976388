/* eslint-disable react/no-did-update-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import UnitPracticeDetail from '../../containers/UnitPracticeDetail';
import UnitPracticeStudentDetail from '../../containers/UnitPracticeStudentDetail';
import UnitPracticeTeacherDetail from '../../containers/UnitPracticeTeacherDetail';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import LessonBar from '../../containers/LessonBar';

class UnitPractice extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sessionIdSelected: null,
    };
  }

  componentDidMount() {
    const {
      sectionId, sectionsStudentGet, getAllLessonActivity, isTeacher,
    } = this.props;
    if (sectionId) {
      if (isTeacher) {
        sectionsStudentGet(sectionId);
      }
      getAllLessonActivity(sectionId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      sectionId, sectionsStudentGet, getAllLessonActivity, isTeacher,
    } = this.props;
    const { sectionId: prevSectionId } = prevProps;
    const { sessionIdSelected } = this.state;
    const { sessionIdSelected: prevSessionIdSelecte } = prevState;
    if (sectionId && !_isEqual(sectionId, prevSectionId)) {
      if (isTeacher) {
        sectionsStudentGet(sectionId);
      }
      getAllLessonActivity(sectionId);
    }
    if (sessionIdSelected !== prevSessionIdSelecte) {
      this.setState({ sessionIdSelected });
    }
  }

  updateSessionId = (id) => {
    this.setState({
      sessionIdSelected: id,
    });
  };

  render() {
    const {
      subject,
      unitDetail,
      courseDetail,
      sectionDetail,
      isSideBarClose,
      isTeacher,
    } = this.props;
    const breadcumbList = ['Lessons'];
    if (!_isEmpty(subject)) {
      breadcumbList.push(subject.display_name || subject.name);
    }

    if (!_isEmpty(unitDetail)) {
      breadcumbList.push(unitDetail.name);
      breadcumbList.push('Practice');
    }

    const subjectVersion = _get(subject, 'version', 1);
    const shouldDisplayOldVersion = subjectVersion === 1;

    return (
      <React.Fragment>
        <BreadcumbsFilter
          haveSub={isSideBarClose}
          breadcumbSubList={[courseDetail.name, sectionDetail.name]}
          breadcumbList={breadcumbList}
          mobileTitle="Lessons"
        />

        <LessonBar updateSessionId={this.updateSessionId} />

        {shouldDisplayOldVersion ? (
          <UnitPracticeDetail />
        ) : (
          <React.Fragment>
            {isTeacher ? (
              <UnitPracticeTeacherDetail />
            ) : (
              <UnitPracticeStudentDetail />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

UnitPractice.propTypes = {
  sectionsStudentGet: PropTypes.func,
  sectionId: PropTypes.any,
  subject: PropTypes.shape(),
  unitDetail: PropTypes.shape(),
  getAllLessonActivity: PropTypes.func,
  isTeacher: PropTypes.bool,
  courseDetail: PropTypes.shape(),
  sectionDetail: PropTypes.shape(),
  isSideBarClose: PropTypes.bool,
};

export default UnitPractice;
