import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import {
  selectors as SectionSelectors,
  actions as SectionActions,
} from '../../reducers/sections';
import SectionCreationModal from '../../components/Modals/SectionCreationModal';
import {
  actions as CourseActions,
  selectors as CourseSelectors,
} from '../../reducers/courses';

const defaultInitialValues = {
  sectionName: '',
  grade: '',
  subjects: '',
  startDate: new Date(),
  endDate: moment().add(3, 'month').toDate(),
  classDuration: 90,
  classDay: '',
  placementTestType: '',
  placementTestDate: '',
  exitType: '',
  exitDate: '',
};

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseDetailById(state, courseId);
  const { curriculum: curriculumId } = courseData;
  const sectionName = (courseData || {}).custom_name || (courseData || {}).name;
  const initialValues = {
    ...defaultInitialValues,
    sectionName: `${sectionName}-A`,
  };
  const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);

  return {
    curriculumDetail,
    courseId,
    courseData,
    curriculumId,
    initialValues,
    subjects: CurriculumSelectors.getSubjectList(state, curriculumId),
    placementTypes: CurriculumSelectors.getPlacementTypes(state, curriculumId),
    exitTypes: CurriculumSelectors.getExitTypes(state, curriculumId),
    errorMsg: SectionSelectors.getErrorMessage(state),
    allSections: SectionSelectors.getAllTeachersCourseSection(state),
    isCreatingNewSection: SectionSelectors.isCreatingNewSection(state)
  };
};

const mapDispatchToProps = {
  getAllSectionsByCourse: SectionActions.getAllSectionsByCourse,
  getSubject: CurriculumActions.getCurriculumSubjectList,
  getCurriculumSubjectList: CurriculumActions.getCurriculumSubjectList,
  sectionsCreateNewSection: SectionActions.sectionsCreateNewSection,
  getCurriculumPlacementType: CurriculumActions.getCurriculumPlacementType,
  getCurriculumExitType: CurriculumActions.getCurriculumExitType,
  sectionClearError: SectionActions.sectionClearError,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SectionCreationModal));
