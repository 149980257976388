import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import FormInput from '../Generals/FormInput/FormInput';
import { formatDate, splitUnitChildren } from '../../utils/func-utils';
import {
  EXAM_LENGTH_TYPES,
  EXAM_SESSION_TYPES,
  SESSION_ACTIVITY_TYPE,
  STANDARD_SESSION_TYPE,
} from '../../utils/constants';
import { Button } from '../Generals/stylesheets/Button.style';
import { ModalActionGroup } from './Calendar.style';
import { LoginErrorMessage } from '../LoginPage/Login.style';

// ====================================================

const updateReview = (sectionId, sectionReviewUpdate) => (reviewInfo) => {
  sectionReviewUpdate(sectionId, reviewInfo.id, reviewInfo);
};

const deleteReview = (sectionId, sectionReviewDelete) => (e) => {
  e.preventDefault();
  sectionReviewDelete(sectionId);
};
const reviewValidationSchema = Yup.object().shape({
  subject: Yup.object(),
  date: Yup.date(),
  activityType: Yup.object(),
});

export const ReviewForm = ({
  initialValues,
  fetchSectionSubjectList,
  subjects,
  sectionId,
  sectionReviewUpdate,
  isUpdatingReview,
  errorMsg,
  sectionReviewDelete,
}) => {
  useEffect(() => {
    fetchSectionSubjectList(sectionId);
  }, []);

  const filterSubject = subjects.filter(
    (subject) => subject.review_type === 1 || subject.review_type === 0,
  );

  const init = {
    ...initialValues,
    subject: (subjects || []).find((e) => e.id === initialValues.subject),
    activity_type: SESSION_ACTIVITY_TYPE.arrays.find(
      (e) => e.value === initialValues.activity_type,
    ),
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={init}
        validationSchema={reviewValidationSchema}
        onSubmit={updateReview(sectionId, sectionReviewUpdate)}
      >
        <Form noValidate>
          <FormInput
            type="select"
            name="subject"
            label="Select subject"
            options={filterSubject}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.display_name || subjects.name}
          />
          <FormInput
            type="select"
            name="activity_type"
            label="Select Activity Type"
            options={SESSION_ACTIVITY_TYPE.arrays}
          />
          <FormInput
            type="date"
            name="date"
            label="Session date"
            dateFormat="yyyy/MM/dd"
          />
          {errorMsg && <LoginErrorMessage>{errorMsg}</LoginErrorMessage>}
          <ModalActionGroup>
            <Button submitForm disabled={isUpdatingReview} type="submit">
              {isUpdatingReview ? 'Loading' : 'Save'}
            </Button>
            <Button
              submitForm
              darkBackground
              onClick={deleteReview(init.id, sectionReviewDelete)}
              disabled={isUpdatingReview}
            >
              Delete
            </Button>
          </ModalActionGroup>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

ReviewForm.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  fetchSectionSubjectList: PropTypes.func.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.shape()),
  sectionId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  sectionReviewUpdate: PropTypes.func.isRequired,
  isUpdatingReview: PropTypes.bool,
  errorMsg: PropTypes.string,
  sectionReviewDelete: PropTypes.func.isRequired,
};

// ====================================================

const lessonValidationSchema = Yup.object().shape({
  sessionType: Yup.object().required('Required'),
  date: Yup.date().required('Required'),
  subject: Yup.object().required('Required'),
  unit: Yup.object().required('Required').nullable(),
  lesson: Yup.object()
    .nullable()
    .test({
      name: 'lessonTest',
      message: 'Required',
      test(lesson) {
        const sessionType = this.resolve(Yup.ref('sessionType'));

        if (
          sessionType.value === STANDARD_SESSION_TYPE.LESSSON
          && lesson === null
        ) {
          return false;
        }

        return true;
      },
    }),
});

export class LessonForm extends React.PureComponent {
  deleteLesson = () => {
    const { initialValues, sectionSessionDelete } = this.props;

    sectionSessionDelete(initialValues.id);
  };

  updateLesson = (lessonInfo) => {
    const { sectionId, sectionsSessionUpdate } = this.props;

    const {
      date,
      sessionType,
      unit,
      lesson,
      activitySlide,
      activityWorksheet,
      activityPractice,
      subject,
    } = lessonInfo;
    const payload = {
      date: formatDate(date),
      activity_type: sessionType.value,
      section: sectionId,
      unit: unit.id,
      subject: subject.id,
      lesson: null,
    };

    if (sessionType.value === STANDARD_SESSION_TYPE.LESSSON) {
      payload.lesson = lesson.id;
      payload.activity_json = {
        practice: _get(activityPractice, 'value', 0),
        worksheet: _get(activityWorksheet, 'value', 0),
        slides: _get(activitySlide, 'value', 0),
      };
    }

    sectionsSessionUpdate(sectionId, lessonInfo.id, payload);
  };

  onChangeSubject = (subject, form) => {
    const values = _get(form, 'values', {});

    form.setValues({
      ...values,
      subject,
      unit: null,
      lesson: null,
      activitySlide: null,
      activityWorksheet: null,
      activityPractice: null,
    });
  };

  onChangeUnit = (unit, form) => {
    const values = _get(form, 'values', {});

    form.setValues({
      ...values,
      unit,
      lesson: null,
      activitySlide: null,
      activityWorksheet: null,
      activityPractice: null,
    });
  };

  onChangeLesson = (lesson, form) => {
    const values = _get(form, 'values', {});

    form.setValues({
      ...values,
      lesson,
      activitySlide: SESSION_ACTIVITY_TYPE.arrays[0],
      activityWorksheet: SESSION_ACTIVITY_TYPE.arrays[0],
      activityPractice: SESSION_ACTIVITY_TYPE.arrays[0],
    });
  };

  onChangeSessionType = (session, form) => {
    const values = _get(form, 'values', {});

    form.setValues({
      ...values,
      sessionType: session,
      unit: null,
      lesson: null,
      subject: null,
      activitySlide: null,
      activityWorksheet: null,
      activityPractice: null,
    });
  };

  renderPracticeField = (fieldProps) => {
    const { field, form } = fieldProps;
    const { name, value } = field;
    const hasLessonPractice = _get(
      form,
      'values.unit.meta.lesson_practice',
      false,
    );
    const canPractice = _get(form, 'values.lesson.can_practice', false);
    const lessonSessionType = _get(form, 'values.sessionType.value');
    let isDisable = true;

    if (
      hasLessonPractice
      && canPractice
      && lessonSessionType === STANDARD_SESSION_TYPE.LESSSON
    ) {
      isDisable = false;
    }

    return (
      <FormInput
        type="select"
        name={name}
        value={value}
        label="Pratice"
        options={SESSION_ACTIVITY_TYPE.arrays}
        isDisabled={isDisable}
      />
    );
  };

  renderWorksheetField = (fieldProps) => {
    const { field, form } = fieldProps;
    const { name, value } = field;
    const hasGroupActivity = _get(
      form,
      'values.unit.meta.group_activity',
      false,
    );
    const hasDocument = _get(form, 'values.lesson.has_documents', false);
    const lessonSessionType = _get(form, 'values.sessionType.value');
    let isDisable = true;

    if (
      hasGroupActivity
      && hasDocument
      && lessonSessionType === STANDARD_SESSION_TYPE.LESSSON
    ) {
      isDisable = false;
    }

    return (
      <FormInput
        type="select"
        name={name}
        value={value}
        label="Worksheet"
        options={SESSION_ACTIVITY_TYPE.arrays}
        isDisabled={isDisable}
      />
    );
  };

  renderSlideField = (fieldProps) => {
    const { field, form } = fieldProps;
    const { name, value } = field;
    const lessonSessionType = _get(form, 'values.sessionType.value');
    const lesson = _get(form, 'values.lesson', null);
    let isDisable = true;

    if (
      lessonSessionType === STANDARD_SESSION_TYPE.LESSSON
      && lesson !== null
    ) {
      isDisable = false;
    }

    return (
      <FormInput
        type="select"
        name={name}
        value={value}
        label="Slides"
        options={SESSION_ACTIVITY_TYPE.arrays}
        isDisabled={isDisable}
      />
    );
  };

  renderLessonField = (fieldProps) => {
    const { field, form } = fieldProps;
    const { name, value } = field;
    const lessonSessionType = _get(form, 'values.sessionType.value');
    let isDisable = true;

    if (lessonSessionType === STANDARD_SESSION_TYPE.LESSSON) {
      isDisable = false;
    }
    const lessons = _get(form, 'values.unit.lessons', []);

    return (
      <FormInput
        type="select"
        label="Session lesson"
        name={name}
        value={value}
        options={lessons}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        isSearchable={false}
        isDisabled={isDisable}
        handleChange={this.onChangeLesson}
      />
    );
  };

  renderUnitField = (fieldProps) => {
    const { unitBySubject } = this.props;
    const { field, form } = fieldProps;
    const { name, value } = field;
    const subjectSelected = _get(form, 'values.subject.id');
    const lessonSessionType = _get(form, 'values.sessionType.value');
    const unitArr = _get(unitBySubject, `${subjectSelected}.units`, []);
    const units = splitUnitChildren(unitArr, []);

    let filterUnits = units;

    if (lessonSessionType === STANDARD_SESSION_TYPE.UNIT_PRACTICE) {
      filterUnits = _filter(units, (unit) => _get(unit, 'meta.unit_practice', false));
    }

    return (
      <FormInput
        type="select"
        name={name}
        value={value}
        label="Session unit"
        options={filterUnits}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        handleChange={this.onChangeUnit}
      />
    );
  };

  renderSubjectField = (fieldProps) => {
    const { subjects } = this.props;
    const { form } = fieldProps;
    const lessonSessionType = _get(form, 'values.sessionType.value');

    let filterSubject = subjects;

    if (lessonSessionType === STANDARD_SESSION_TYPE.UNIT_PRACTICE) {
      filterSubject = _filter(subjects, (subject) => subject.review_type === 1);
    }

    return (
      <FormInput
        type="select"
        name="subject"
        label="Session subject"
        options={filterSubject}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name || option.display_name}
        handleChange={this.onChangeSubject}
      />
    );
  };

  renderFormContent = () => {
    const { onlyEditDate } = this.props;

    if (onlyEditDate) {
      return (
        <FormInput
          type="date"
          name="date"
          label="Session date"
          dateFormat="yyyy/MM/dd"
        />
      );
    }

    return (
      <React.Fragment>
        <FormInput
          type="select"
          name="sessionType"
          label="Session type"
          options={STANDARD_SESSION_TYPE.arrays}
          handleChange={this.onChangeSessionType}
        />
        <FormInput
          type="date"
          name="date"
          label="Session date"
          dateFormat="yyyy/MM/dd"
        />
        <Field
          type="select"
          name="subject"
          component={this.renderSubjectField}
        />
        <Field type="select" name="unit" component={this.renderUnitField} />
        <Field type="select" name="lesson" component={this.renderLessonField} />
        <Field
          type="select"
          name="activitySlide"
          component={this.renderSlideField}
        />
        <Field
          type="select"
          name="activityWorksheet"
          component={this.renderWorksheetField}
        />
        <Field
          type="select"
          name="activityPractice"
          component={this.renderPracticeField}
        />
      </React.Fragment>
    );
  };

  render() {
    const {
      initialValues,
      isUpdatingLesson,
      errorMessage,
      initLesson,
      initUnit,
      initSubject,
    } = this.props;

    const init = {
      id: initialValues.id,
      date: initialValues.date,
      subject: initSubject,
      lesson: _isEmpty(initLesson) ? null : initLesson,
      unit: initUnit,
      sessionType: STANDARD_SESSION_TYPE.arrays.find(
        (t) => t.value === initialValues.activity_type,
      ),
      activitySlide: SESSION_ACTIVITY_TYPE.arrays.find(
        (t) => t.value === _get(initialValues, 'activity_json.slides', null),
      ),
      activityWorksheet: SESSION_ACTIVITY_TYPE.arrays.find(
        (t) => t.value === _get(initialValues, 'activity_json.worksheet', null),
      ),
      activityPractice: SESSION_ACTIVITY_TYPE.arrays.find(
        (t) => t.value === _get(initialValues, 'activity_json.practice', null),
      ),
    };

    return (
      <Formik
        initialValues={init}
        validationSchema={lessonValidationSchema}
        onSubmit={this.updateLesson}
      >
        <Form noValidate>
          {this.renderFormContent()}
          {errorMessage && (
            <LoginErrorMessage>{errorMessage}</LoginErrorMessage>
          )}
          <ModalActionGroup>
            <Button submitForm type="submit">
              Save
            </Button>
            <Button
              small
              type="delete"
              onClick={this.deleteLesson}
              disabled={isUpdatingLesson}
            >
              Delete
            </Button>
          </ModalActionGroup>
        </Form>
      </Formik>
    );
  }
}

LessonForm.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  initLesson: PropTypes.object,
  initSubject: PropTypes.object,
  subjects: PropTypes.arrayOf(PropTypes.shape()),
  sectionsSessionUpdate: PropTypes.func.isRequired,
  isUpdatingLesson: PropTypes.bool,
  errorMessage: PropTypes.string,
  sectionId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  sectionSessionDelete: PropTypes.func.isRequired,
  initUnit: PropTypes.object,
  unitBySubject: PropTypes.object,
};

// ====================================================

const examValidationSchema = Yup.object().shape({
  session_type: Yup.string().trim().required('Required'),
  start_date: Yup.date().required('Required'),
  end_date: Yup.date()
    .required('Required')
    .when('start_date', (st, schema) => Yup.date().min(st, 'End date should be greater than start date.')),
  length_type: Yup.string().trim().required('Required'),
});

const updateExam = (sectionId, sectionExamUpdate) => (examInfo) => {
  const payload = {
    ...examInfo,
    exam: examInfo.exam.id,
    length_type: examInfo.length_type.value,
    session_type: examInfo.session_type.value,
    curriculum: examInfo.curriculumId,
    start_date: moment(examInfo.start_date).format('YYYY-MM-DD'),
    end_date: moment(examInfo.end_date).format('YYYY-MM-DD'),
  };

  sectionExamUpdate(sectionId, examInfo.id, payload);
};
const deleteExam = (sectionId, sectionExamDelete) => (e) => {
  e.preventDefault();
  sectionExamDelete(sectionId);
};

export const ExamForm = ({
  initialValues,
  isUpdating,
  errorMsg,
  curriculumId,
  sectionId,
  sectionExamUpdate,
  sectionExamDelete,
}) => {
  const init = {
    ...initialValues,
    curriculumId,
    session_type: EXAM_SESSION_TYPES.arrays.find(
      (s) => s.value === initialValues.session_type,
    ),
    length_type: EXAM_LENGTH_TYPES.arrays.find(
      (a) => a.value === initialValues.length_type,
    ),
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={init}
        validationSchema={examValidationSchema}
        onSubmit={updateExam(sectionId, sectionExamUpdate)}
      >
        <Form noValidate>
          <FormInput
            type="select"
            name="session_type"
            label="Session type"
            options={EXAM_SESSION_TYPES.arrays}
          />
          <FormInput
            type="date"
            name="start_date"
            label="Start date"
            dateFormat="yyyy/MM/dd"
          />
          <FormInput
            type="date"
            name="end_date"
            label="End date"
            dateFormat="yyyy/MM/dd"
          />
          <FormInput
            type="select"
            name="length_type"
            label="Length Type"
            options={EXAM_LENGTH_TYPES.arrays}
          />
          {errorMsg && <LoginErrorMessage>{errorMsg}</LoginErrorMessage>}
          <ModalActionGroup>
            <Button submitForm type="submit" disabled={isUpdating}>
              {isUpdating ? 'Loading' : 'Save'}
            </Button>
            <Button
              onClick={deleteExam(init.id, sectionExamDelete)}
              disabled={isUpdating}
            >
              Delete
            </Button>
          </ModalActionGroup>
        </Form>
      </Formik>
    </React.Fragment>
  );
};

ExamForm.propTypes = {
  initialValues: PropTypes.shape().isRequired,
  isUpdating: PropTypes.bool,
  errorMsg: PropTypes.string,
  curriculumId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  sectionId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  sectionExamUpdate: PropTypes.func.isRequired,
  sectionExamDelete: PropTypes.func.isRequired,
};

// ====================================================
