/* eslint-disable import/prefer-default-export */
import React from 'react';
import Box from '@components/Atoms/Box';
import Typography from '@components/Atoms/Typography';
import ShowIf from '@components/Atoms/ShowIf';

export interface Tag {
  name: string;
  color: string;
  tooltip: string;
}

export interface Map {
  auto?: {
    source: 'Lessons' | 'Standards' | 'Skills';
    data: Record<number, string[]>;
  };
  recommend?: {
    [key: string]: Record<number, string[]> | string[];
  };
  partial?: {
    [lessonId: number]: {
      standards: string[];
      skills: string[];
    };
  };
}

export const buildTags = (module: 'lesson' | 'standard' | 'skill', map: Map) => {
  const tags = {
    default: {
      name: 'Manual',
      color: 'success',
      tooltip: (
        <Box>
          <Typography sx={{ mb: '0.5rem' }} variant="body3">
            This {module} was added by{' '}
            <Typography component="span" sx={{ fontWeight: 'bold' }}>
              user selection
            </Typography>
          </Typography>
        </Box>
      ),
    },
  };

  const uppercaseFirst = (str: string) =>
    str.length === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1);

  if (map && map.partial) {
    Object.keys(map.partial).forEach((lessonId) => {
      const { standards, skills } = map?.partial?.[lessonId] || {};
      tags[lessonId.toString()] = {
        name: 'Manual',
        color: 'success',
        tooltip: (
          <Box>
            <Typography sx={{ mb: '0.5rem' }} variant="body3">
              This {module} was added via{' '}
              <Typography component="span" sx={{ fontWeight: 'bold' }}>
                user selection
              </Typography>
            </Typography>
            <Box sx={{ mt: '0.5rem' }}>
              <Typography variant="body3" sx={{ display: 'block' }}>
                This is
                <Typography
                  sx={{
                    px: '0.5rem',
                    display: 'inline-block',
                    background: `repeating-linear-gradient(
                    -45deg,
                    rgba(255, 255, 255, 1),  /* Light white stripe */
                    rgba(255, 255, 255, 1) 10px,
                    rgba(200, 200, 200, 0.4) 10px,  /* Soft gray stripe */
                    rgba(200, 200, 200, 0.4) 20px
                  )`,
                  }}
                >
                  Partially Selected
                </Typography>
                because the following has been unselected:
              </Typography>
              <ShowIf If={standards?.length > 0}>
                <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                  Standards:
                  {standards?.map((standard) => (
                    <Typography
                      key={standard}
                      sx={{ ml: 2, display: 'block' }}
                      variant="body3"
                    >
                      ▶️ {standard}
                    </Typography>
                  ))}
                </Typography>
              </ShowIf>
              <ShowIf If={skills?.length > 0}>
                <Typography variant="body3" sx={{ fontWeight: 'bold' }}>
                  Skills:
                  {skills?.map((skill) => (
                    <Typography
                      key={skill}
                      sx={{ ml: 2, display: 'block' }}
                      variant="body3"
                    >
                      ▶️ {skill}
                    </Typography>
                  ))}
                </Typography>
              </ShowIf>
            </Box>
          </Box>
        ),
      };
    });
  }

  if (map && map.auto) {
    Object.keys(map.auto.data).forEach((id) => {
      const names = map?.auto?.data?.[id] || [];
      if (names && names.length > 0) {
        tags[id.toString()] = {
          name: 'Auto',
          color: 'primary',
          tooltip: (
            <Box>
              <Typography sx={{ mb: '0.5rem' }} variant="body3">
                This {module} is auto-selected due to being{' '}
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                  directly aligned
                </Typography>{' '}
                to the following:
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                {uppercaseFirst(map?.auto?.source || '')}:
              </Typography>
              {names.map((name) => (
                <Typography key={name} sx={{ ml: 2, display: 'block' }} variant="body3">
                  ▶️ {uppercaseFirst(name)}
                </Typography>
              ))}
            </Box>
          ),
        };
      }
    });
  }

  if (map && Object.keys(map?.recommend || {}).length > 0) {
    Object.keys(map?.recommend || {}).forEach((id) => {
      // skip if already tagged so we don't overwrite auto tags
      if (id.toString() in tags) {
        return;
      }
      const sources = Object.keys(map?.recommend?.[id] || []);
      sources.forEach((source) => {
        const names = map?.recommend?.[id]?.[source] || [];
        if (names && names.length > 0) {
          tags[id.toString()] = {
            name: 'Recommended',
            color: 'secondary',
            tooltip: (
              <Box>
                <Typography sx={{ mb: '0.5rem' }} variant="body3">
                  This {module} is recommended due to being{' '}
                  <Typography component="span" sx={{ fontWeight: 'bold' }}>
                    related via aligned:
                  </Typography>
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {uppercaseFirst(source)}:
                </Typography>
                {names.map((name) => (
                  <Typography key={name} sx={{ ml: 2, display: 'block' }} variant="body3">
                    ▶️ {uppercaseFirst(name)}
                  </Typography>
                ))}
              </Box>
            ),
          };
        }
      });
    });
  }

  return tags;
};

export const buildRemoveToastMessage = (
  removeLabel: string,
  removedName: string,
  associationLabel: string,
  associationNames: string[],
  color = 'black',
) => (
  <Box display="flex" flexDirection="column">
    <Typography sx={{ color }}>
      {`Removed ${removeLabel}: `}
      <Typography component="span" sx={{ fontWeight: 'bold', color }}>
        {removedName}
      </Typography>
      {` via association with ${associationLabel}:`}
    </Typography>
    {associationNames.map((name) => (
      <Typography key={name} sx={{ ml: 4, color }}>
        {name}
      </Typography>
    ))}
  </Box>
);
