import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _range from 'lodash/range';
import _round from 'lodash/round';
import moment from 'moment';
import LessonDetailModal from '@components/LessonDetailModal';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentWrapper,
  TableContentItem,
  TableIcon,
  TableWarningIcon,
  TableEmptyContent,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import {
  LessonSkillLevel,
  LessonViewButton,
  ContentLine,
  LevelSkill,
  LevelSkillDot,
  Dot,
} from '../LessonManagement/LessonManagement.style';
import { ColumnSize } from './ColumnSize';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter/BreadcumbsFilter';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  ContentWrapper,
  EmptyContentWrapper,
  ReviewContentWrapper,
} from './LessonReviewPage.style';
import { Button } from '../Generals/stylesheets/Button.style';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';

const scrollStyle = {
  height: 'calc(100% - 50px)',
  width: '100%',
};

class StudentLessonReviewPage extends Component {
  state = {
    modalIsOpen: false,
    lessonId: null,
    unitId: null,
  };

  openLessonDetailModal = (lessonId, unitId) => {
    this.setState({
      modalIsOpen: true,
      lessonId,
      unitId,
    });
  };

  closeLessonDetailModal = () => {
    this.setState({
      modalIsOpen: false,
      lessonId: null,
      unitId: null,
    });
  };

  initUnitReviewPractice = (reviewTopicId, unitId) => {
    const { initializeReviewPracticeSession, reviewId, courseId, sectionId, subjectId } =
      this.props;
    const callback = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice`;

    initializeReviewPracticeSession(reviewId, reviewTopicId, callback);
  };

  initLessonReviewPractice = (reviewTopicId, unitId, lessonId) => {
    const { initializeReviewPracticeSession, reviewId, courseId, sectionId, subjectId } =
      this.props;
    const callback = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice`;

    initializeReviewPracticeSession(reviewId, reviewTopicId, callback);
  };

  renderEmptyReview = () => {
    return (
      <EmptyContentWrapper>
        <TableWarningIcon className="chalktalk-error-wr" />
        <TableEmptyContent>
          Review lessons will be available after you've completed your first classes and
          practice sets.
        </TableEmptyContent>
      </EmptyContentWrapper>
    );
  };

  renderLessonStepTableHeader = () => (
    <TableHeadWrapper custome>
      <TableHeader {...ColumnSize[0]} />
      <TableHeader {...ColumnSize[1]} />
      <TableHeader {...ColumnSize[2]} value="Step 1" boldStyle />
      <TableHeader {...ColumnSize[3]} value="Step 2" boldStyle />
      <TableHeader {...ColumnSize[4]} value="Score" boldStyle />
    </TableHeadWrapper>
  );

  renderLessonReviewTableHeader = () => (
    <TableHeadWrapper>
      <TableHeader {...ColumnSize[0]} />
      <TableHeader {...ColumnSize[1]} />
      <TableHeader {...ColumnSize[2]} value="Watch Lessons" />
      <TableHeader {...ColumnSize[3]} value="Practice" />
      <TableHeader {...ColumnSize[4]} value="(Click score to view detail)" />
    </TableHeadWrapper>
  );

  renderDotItem = (currentSkill) => (index) =>
    <Dot key={index} isActive={currentSkill >= index + 1} />;

  renderSkillLevel = (currentSkill) => (
    <LessonSkillLevel>
      <ContentLine>SKILL LEVEL</ContentLine>
      <LevelSkill>{_round(currentSkill)}</LevelSkill>
      <LevelSkillDot>{_range(5).map(this.renderDotItem(currentSkill))}</LevelSkillDot>
    </LessonSkillLevel>
  );

  renderLessonItem = (unitId) => (lesson) => {
    const { userId, skillByLessonId } = this.props;
    const { name, id } = lesson;
    const currentSkillLevel = _get(skillByLessonId, `${id}.${userId}.current`, 0);

    return (
      <TableContentItem smallSize key={id}>
        <TableContent {...ColumnSize[0]}>
          {this.renderSkillLevel(currentSkillLevel)}
        </TableContent>
        <TableContent {...ColumnSize[1]}>{name}</TableContent>
        <TableContent {...ColumnSize[2]}>
          <LessonViewButton onClick={() => this.openLessonDetailModal(id, unitId)}>
            View Lesson
          </LessonViewButton>
        </TableContent>
        <TableContent {...ColumnSize[3]} />
        <TableContent {...ColumnSize[4]} />
      </TableContentItem>
    );
  };

  renderUnitBasedReviewItem = (reviewDataItem) => {
    const { courseId, sectionId, subjectId, unitByIds } = this.props;
    const { id } = reviewDataItem;
    const unitName = _get(reviewDataItem, 'unit.name', '');
    const unitId = _get(reviewDataItem, 'unit.id', '');
    const lessonList = _get(unitByIds, [unitId, 'lessons'], []);

    const hasPracticeFinished = _get(reviewDataItem, 'practice_session.ended', null);
    let score = 'N/A';
    const linkPracticePreview = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice-review?review=${id}`;

    if (hasPracticeFinished) {
      const scoreAuto = _get(reviewDataItem, 'practice_session.score[0].score.auto', 0);
      const scoreAutoMax = _get(
        reviewDataItem,
        'practice_session.score[0].score.auto_max',
        0,
      );
      score = `${Math.round(scoreAuto)}/${Math.round(scoreAutoMax)}`;
    }

    const hasPracticeSession = _get(reviewDataItem, 'practice_session', null);
    const shouldDisplayScore = hasPracticeSession && hasPracticeFinished;
    const buttonLabel =
      hasPracticeSession && !hasPracticeFinished ? 'Continue' : 'Practice';

    return (
      <ReviewContentWrapper key={id}>
        <TableContainerWrap>
          {this.renderLessonStepTableHeader()}
          {this.renderLessonReviewTableHeader()}
          <TableContentWrapper>
            <TableContentItem>
              <TableContent {...ColumnSize[1]}>{unitName}</TableContent>
              <TableContent {...ColumnSize[2]} />
              <TableContent {...ColumnSize[3]}>
                {hasPracticeFinished ? (
                  <TableIcon large className="chalktalk-check-success" />
                ) : (
                  <TableIcon large className="chalktalk-grey-circle" />
                )}
              </TableContent>
              <TableContent {...ColumnSize[4]}>
                {shouldDisplayScore ? (
                  <Link to={linkPracticePreview}>{score}</Link>
                ) : (
                  <Button onClick={() => this.initUnitReviewPractice(id, unitId)}>
                    {buttonLabel}
                  </Button>
                )}
              </TableContent>
            </TableContentItem>
            {lessonList.map(this.renderLessonItem(unitId))}
          </TableContentWrapper>
        </TableContainerWrap>
      </ReviewContentWrapper>
    );
  };

  renderLessonBasedReviewItem = (reviewDataItem) => {
    const { lessonById, userId, skillByLessonId, courseId, sectionId, subjectId } =
      this.props;
    const { lesson: lessonId, id } = reviewDataItem;

    const lessonName = _get(lessonById, `${lessonId}.name`, '');
    const hasPracticeFinished = _get(reviewDataItem, 'practice_session.ended', null);
    let score = 'N/A';
    const unitId = _get(reviewDataItem, 'unit.id');

    if (hasPracticeFinished) {
      const scoreAuto = _get(reviewDataItem, 'practice_session.score[0].score.auto', 0);
      const scoreAutoMax = _get(
        reviewDataItem,
        'practice_session.score[0].score.auto_max',
        0,
      );
      score = `${scoreAuto}/${scoreAutoMax}`;
    }

    const hasPracticeSession = _get(reviewDataItem, 'practice_session', null);
    const shouldDisplayScore = hasPracticeSession && hasPracticeFinished;
    const buttonLabel =
      hasPracticeSession && !hasPracticeFinished ? 'Continue' : 'Practice';

    const currentSkillLevel = _get(skillByLessonId, `${lessonId}.${userId}.current`, 0);
    const linkPracticePreview = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice-review?review=${id}`;

    return (
      <TableContentItem smallSize key={id}>
        <TableContent {...ColumnSize[0]}>
          {this.renderSkillLevel(currentSkillLevel)}
        </TableContent>
        <TableContent {...ColumnSize[1]}>{lessonName}</TableContent>
        <TableContent {...ColumnSize[2]}>
          <LessonViewButton onClick={() => this.openLessonDetailModal(lessonId, unitId)}>
            View Lesson
          </LessonViewButton>
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          {hasPracticeFinished ? (
            <TableIcon large className="chalktalk-check-success" />
          ) : (
            <TableIcon large className="chalktalk-grey-circle" />
          )}
        </TableContent>
        <TableContent {...ColumnSize[4]}>
          {shouldDisplayScore ? (
            <Link to={linkPracticePreview}>{score}</Link>
          ) : (
            <Button onClick={() => this.initLessonReviewPractice(id, unitId, lessonId)}>
              {buttonLabel}
            </Button>
          )}
        </TableContent>
      </TableContentItem>
    );
  };

  renderLessonBasedReview = (lessonReviewList) => (
    <ReviewContentWrapper>
      <TableContainerWrap>
        {this.renderLessonStepTableHeader()}
        {this.renderLessonReviewTableHeader()}
        <TableContentWrapper>
          {lessonReviewList.map(this.renderLessonBasedReviewItem)}
        </TableContentWrapper>
      </TableContainerWrap>
    </ReviewContentWrapper>
  );

  render() {
    const {
      isGetSessionData,
      reviewData,
      subject,
      reviewDetail = {},
      isGetSkillData,
      courseDetail,
      sectionDetail,
      isSideBarClose,
    } = this.props;
    const { modalIsOpen, lessonId, unitId } = this.state;
    const breadcumList = ['Lessons'];

    if (!_isEmpty(subject)) {
      const subjectName = subject.display_name;
      breadcumList.push(subjectName);
      breadcumList.push(
        `${subjectName} Review ${moment(reviewDetail.date, 'YYYY-MM-DD').format(
          'MM-DD-YYYY',
        )}`,
      );
    }

    const unitBasedReview = _filter(reviewData, (item) => item.lesson === null);
    const lessonBasedReview = _filter(reviewData, (item) => item.lesson !== null);

    const shouldDisplayReview =
      lessonBasedReview.length > 0 || unitBasedReview.length > 0;
    return (
      <React.Fragment>
        {isGetSessionData || isGetSkillData ? (
          <LoadingIndicator content="Preparing for your data" />
        ) : (
          <React.Fragment>
            <BreadcumbsFilter
              haveSub={isSideBarClose}
              breadcumbSubList={[courseDetail.name, sectionDetail.name]}
              breadcumbList={breadcumList}
              mobileTitle="Lesson"
            />
            {shouldDisplayReview ? (
              <ShadowScrollbar autoHide style={scrollStyle}>
                <ContentWrapper>
                  {unitBasedReview.map(this.renderUnitBasedReviewItem)}
                  {this.renderLessonBasedReview(lessonBasedReview)}
                </ContentWrapper>
              </ShadowScrollbar>
            ) : (
              this.renderEmptyReview()
            )}
          </React.Fragment>
        )}
        <LessonDetailModal
          isOpen={modalIsOpen}
          onClose={this.closeLessonDetailModal}
          lessonId={lessonId}
        />
      </React.Fragment>
    );
  }
}

StudentLessonReviewPage.propTypes = {
  isGetSessionData: PropTypes.bool,
  reviewData: PropTypes.array,
  subject: PropTypes.object,
  reviewId: PropTypes.any,
  lessonById: PropTypes.object,
  unitByIds: PropTypes.object,
  isGetSkillData: PropTypes.bool,
  skillByLessonId: PropTypes.object,
  userId: PropTypes.any,
  courseId: PropTypes.any,
  sectionId: PropTypes.any,
  initializeReviewPracticeSession: PropTypes.func,
  reviewDetail: PropTypes.shape(),
  subjectId: PropTypes.string,
  courseDetail: PropTypes.shape(),
  sectionDetail: PropTypes.shape(),
  isSideBarClose: PropTypes.bool,
};

export default StudentLessonReviewPage;
