import Box from '@components/Atoms/Box';
import IconWrapper from '@components/Atoms/IconWrapper';
import Tooltip from '@components/Atoms/Tooltip';
import usePersistShow from '@utils/hooks/usePersistShow';
import React, { ReactElement } from 'react';
import Typography from '@components/Atoms/Typography';
import IconButton from '@components/Atoms/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  children: ReactElement<any, any>;
};
const toolTipContentStyle = {
  display: 'flex',
  flexDirection: 'row',
  paddingX: 1,
  paddingY: 1,
  gap: 1.5,
  alignItems: 'center',
  color: 'secondary.contrastText',
};

const closeButtonStyle = {
  position: 'absolute',
  top: 1,
  right: 1,
  color: 'inherit',
  fontSize: '1rem',
};

const typographyWrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const typographyStyle = {
  color: 'inherit',
  paddingInlineEnd: 2,
};

const ToolTipContent = ({ onHide }: { onHide: () => void }) => (
  <Box sx={toolTipContentStyle}>
    <IconButton onClick={onHide} sx={closeButtonStyle}>
      <CloseIcon fontSize="inherit" />
    </IconButton>
    <IconWrapper size="lg">
      <img src="/images/click-cursor.svg" alt="click" />
    </IconWrapper>
    <Box sx={typographyWrapperStyle}>
      <Typography variant="body3" sx={typographyStyle}>
        Click anywhere on the content to move to the next slide
      </Typography>
    </Box>
  </Box>
);
const LessonTip = ({ children }: Props) => {
  const { show, hide } = usePersistShow('lessonTip');
  return (
    <Tooltip open={show} arrow title={<ToolTipContent onHide={hide} />}>
      {children}
    </Tooltip>
  );
};

export default LessonTip;
