import _get from 'lodash/get';
import { LOCATION_CHANGE } from 'connected-react-router';

export const EXAM_REPORT_GET_DETAIL = 'examReport/GET_DETAIL';
export const EXAM_REPORT_GET_DETAIL_SUCCESS = 'examReport/GET_DETAIL_SUCCESS';
export const EXAM_REPORT_GET_DETAIL_FAIL = 'examReport/GET_DETAIL_FAIL';

export const EXAM_REPORT_GET_OF_USER = 'examReport/REPORT_GET_OF_USER';

const examReportGetOfUser = (examSessionId, examSectionId) => ({
  type: EXAM_REPORT_GET_OF_USER,
  examSessionId,
  examSectionId,
});

const examReportGetDetail = (examSessionId, examSectionId, studentId) => ({
  type: EXAM_REPORT_GET_DETAIL,
  payload: {
    examSessionId,
    examSectionId,
    studentId,
  },
});

const examReportGetDetailSuccess = payload => ({
  type: EXAM_REPORT_GET_DETAIL_SUCCESS,
  payload,
});

const examReportGetDetailFail = error => ({
  type: EXAM_REPORT_GET_DETAIL_FAIL,
  error,
});

export const actions = {
  examReportGetDetail,
  examReportGetDetailSuccess,
  examReportGetDetailFail,
  examReportGetOfUser,
};

const getExamReportDetail = ({ examReport }) => _get(examReport, 'details', {});

export const selectors = {
  getExamReportDetail,
};

const initialState = {
  isGetDetail: false,
  details: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EXAM_REPORT_GET_DETAIL:
      return { ...state, isGetDetail: true };
    case EXAM_REPORT_GET_DETAIL_SUCCESS: {
      const { payload } = action;

      return { ...state, isGetDetail: false, details: payload };
    }
    case EXAM_REPORT_GET_DETAIL_FAIL:
      return { ...state, isGetDetail: false };
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
}
