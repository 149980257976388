import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import TopNavBarNew from '../../components/TopNavBar';
import { actions as AuthActions } from '../../reducers/auth';
import { getCurrentUser } from '../../reducers/user';
import { selectors as courseSelectors } from '../../reducers/courses';
import { selectors as sectionSelectors } from '../../reducers/sections';
import { selectors as CurriculumSelectors, 
  actions as CurriculumActions } from '../../reducers/curriculums';

export const mapStateToProps = (state, { location }) => {
  const pathname = _get(location, 'pathname', '/');
  const paramsArray = pathname.split('/');
  const courseId = _get(paramsArray, '3', null) && !isNaN(_get(paramsArray, '3', null))?  _get(paramsArray, '3', null) : null;
  const sectionId = _get(paramsArray, '5', null);

  const searchParams = new URLSearchParams(location.search);
  const isOpenTeacherCourseSetup = !!searchParams.get('isOpenTeacherCourseSetup');

  const courseData = courseSelectors.getCourseDetailById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);
  return {
    isOpenTeacherCourseSetup,
    courseId,
    sectionId,
    user: getCurrentUser(state),
    courseList: courseSelectors.getCourseList(state),
    sectionList: sectionSelectors.getSectionList(state),
    courseById: courseSelectors.getCourseListIds(state),
    curriculumDetail
  };
};

const mapDispatchToProps = {
  logout: AuthActions.logout,
  getCurriculumList: CurriculumActions.getCurriculumList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopNavBarNew),
);
