import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PracticeTime from '../PracticeTime';
import {
  PracticeHeaderWrap,
  PracticeHeaderLeft,
  PracticeHeaderRight,
  PracticeExamInfoWrap,
} from './PracticeTopNavBar.style';
import { BackButton } from '../Generals/stylesheets/Button.style';

class PracticeTopNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderExamInfoOnTop = () => {
    const { name } = this.props;
    return (
      <PracticeExamInfoWrap>
        {name}
      </PracticeExamInfoWrap>
    );
  }

  render() {
    const {
      displayTimeOut,
      secondsRemaining,
      complete = () => {},
      shouldResetTimer,
      examResetTimerSuccess,
      isOffline,
      goBackText,
      duration,
      practiceName,
      status,
    } = this.props;
    return (
      <PracticeHeaderWrap>
        <PracticeHeaderLeft>
          <BackButton onClick={this.props.goBack}>
            <div className="chalktalk-previous">
              <i className="path1" />
              <i className="path2" />
            </div>
            {practiceName ? 'Back to Lesson' : goBackText || 'Back to Test'}
          </BackButton>
          {isOffline && this.renderExamInfoOnTop()}
        </PracticeHeaderLeft>

        {displayTimeOut && (
          <PracticeHeaderRight>
            {
              status !== 'review' &&
              <PracticeTime
                secondsRemaining={secondsRemaining}
                complete={complete}
                shouldResetTimer={shouldResetTimer}
                examResetTimerSuccess={examResetTimerSuccess}
                duration={duration}
                status={status}
              />
            }
          </PracticeHeaderRight>
        )}
      </PracticeHeaderWrap>
    );
  }
}

PracticeTopNavBar.propTypes = {
  goBack: PropTypes.func,
  displayTimeOut: PropTypes.bool,
  name: PropTypes.string,
  secondsRemaining: PropTypes.any,
  complete: PropTypes.func,
  shouldResetTimer: PropTypes.bool,
  examResetTimerSuccess: PropTypes.func,
  isOffline: PropTypes.bool,
  goBackText: PropTypes.string,
  duration: PropTypes.number,
};

export default PracticeTopNavBar;
