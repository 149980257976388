import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import _reduce from 'lodash/reduce';
import {
  SECTION_UNIT_GET,
  SECTION_UNIT_GET_META,

  actions as SectionUnitActions,
  selectors as SectionUnitSelectors,
} from '../../reducers/sectionUnit';
import {
  actions as curriculumActions,
} from '../../reducers/curriculumLessons';
import * as SectionAPI from '../../apis/sections';
import { splitLessonChildren, splitUnitChildren } from '../../utils/func-utils';

function* sectionUnitGet({ sectionId, subjectId }) {
  if(sectionId > 0) {
    const shouldFetch = yield select(SectionUnitSelectors.getShouldFetch, sectionId);

    if (!shouldFetch) {
      return;
    }

    const { response, error } = yield call(SectionAPI.getSectionUnit, sectionId, subjectId);
    if (response) {
      const { data: unitTreeList } = response;

      const allUnit = _reduce(unitTreeList, (prevValue, subject) => {
        const { units } = subject;
        const allUnitsOfSubject = splitUnitChildren(units, []);

        return [
          ...prevValue,
          ...allUnitsOfSubject,
        ];
      }, []);

      const allLesson = _reduce(unitTreeList, (prevValue, subject) => {
        const { units } = subject;
        const allLessonOfSubject = splitLessonChildren(units, []);

        return [
          ...prevValue,
          ...allLessonOfSubject,
        ];
      }, []);

      yield put(SectionUnitActions.sectionUnitGetSuccess(unitTreeList, allUnit, sectionId));
      yield put(curriculumActions.curriculumLessonGetWithUnit(allLesson));
    } else {
      yield put(SectionUnitActions.sectionUnitGetFail(error));
    }
  }
}

function* sectionUnitGetMeta({ sectionId }) {
  const { response, error } = yield call(SectionAPI.getSectionUnit, sectionId);
  if (response) {
    const { data: unitTreeList } = response;
    const allUnit = _reduce(unitTreeList, (prevValue, subject) => {
      const { units } = subject;
      const allUnitsOfSubject = splitUnitChildren(units, []);

      return [
        ...prevValue,
        ...allUnitsOfSubject,
      ];
    }, []);

    const allLesson = _reduce(unitTreeList, (prevValue, subject) => {
      const { units } = subject;
      const allLessonOfSubject = splitLessonChildren(units, []);

      return [
        ...prevValue,
        ...allLessonOfSubject,
      ];
    }, []);

    const unitsBySubjectId = _reduce(unitTreeList, (prevValue, subject) => {
      const { units, id } = subject;
      const allUnitsOfSubject = splitUnitChildren(units, []);
      const unitIds = allUnitsOfSubject.map((item) => item.id);

      return {
        ...prevValue,
        [id]: unitIds,
      };
    }, {});

    const lessonBySubjectId = _reduce(unitTreeList, (prevValue, subject) => {
      const { units, id } = subject;
      const allLessonOfSubject = splitLessonChildren(units, []);
      const lessonIds = allLessonOfSubject.map((item) => item.id);

      return {
        ...prevValue,
        [id]: lessonIds,
      };
    }, {});
    yield put(curriculumActions.curriculumLessonGetWithUnitMeta(allLesson, lessonBySubjectId));
    yield put(SectionUnitActions.sectionUnitGetMetaSuccess(
      unitTreeList,
      allUnit,
      unitsBySubjectId,
    ));
  } else {
    yield put(SectionUnitActions.sectionUnitGetMetaFail(error));
  }
}

export default function* sectionUnitSaga() {
  yield takeLatest(SECTION_UNIT_GET, sectionUnitGet);
  yield takeLatest(SECTION_UNIT_GET_META, sectionUnitGetMeta);
}
