import { takeLeading, put, call } from 'redux-saga/effects';
import {
  LESSON_ASSIGNMENT_ASSIGN,
  LESSON_ASSIGNMENT_UNASSIGN,
  LESSON_ASSIGNMENT_ASSIGN_BULK,
  LESSON_ASSIGNMENT_DETAIL,
  actions as LAActions,
} from '../../reducers/lessonAssignment';
import * as SectionAPIs from '../../apis/sections';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';

function* assignLesson({ sectionId, assignData, showMessage }) {
  const { response, error } = yield call(SectionAPIs.assignLesson, sectionId, assignData);

  if (!error) {
    const { data } = response;
    yield put(LAActions.assignLessonSuccess(sectionId, data));
    if (showMessage) {
      ToastSuccess('Successfully assigned');
    }
  } else {
    yield put(LAActions.assignLessonFailed(error));
    ToastError(error.message || error);
  }
}

function* unAssignLesson({ sectionId, assignData, showMessage }) {
  const { error } = yield call(SectionAPIs.unAssignLesson, sectionId, assignData);
  const {
    assigned_to: assginedTo,
    lesson_group: lessonId,
    unit: unitId,
  } = assignData;

  if (!error) {
    yield put(LAActions.unAssignLessonSuccess(assginedTo, lessonId, unitId));
    if (showMessage) {
      ToastSuccess('Successfully unassigned');
    }
  } else {
    yield put(LAActions.unAssignLessonFailed(error));
    ToastError(error.message || error);
  }
}

function* assignLessonBulk({ sectionId, assignData }) {
  const { response, error } = yield call(SectionAPIs.assignLessonBulk, sectionId, assignData);

  if (!error) {
    const { data } = response;
    yield put(LAActions.assignLessonSuccess(sectionId, data));
    ToastSuccess('Successfully assigned');
  } else {
    yield put(LAActions.assignLessonFailed(error));
    ToastError(`
      We’re sorry, but there are no practices currently available for this lesson.
      Please visit this lesson’s page to discover other engaging activities you can use to teach your students.
    `);
  }
}

function* lessonAssignmentDetail({ sectionId, lessonId, unitId }) {
  const { response, error } = yield call(SectionAPIs.lessonAssignmentDetail, sectionId, lessonId, unitId);

  if (response) {
    const { data } = response;

    yield put(LAActions.lessonAssignmentDetailSuccess(data));
  } else {
    yield put(LAActions.lessonAssignmentDetailFail(error));
  }
}

export default function* () {
  yield takeLeading(LESSON_ASSIGNMENT_ASSIGN, assignLesson);
  yield takeLeading(LESSON_ASSIGNMENT_UNASSIGN, unAssignLesson);
  yield takeLeading(LESSON_ASSIGNMENT_ASSIGN_BULK, assignLessonBulk);
  yield takeLeading(LESSON_ASSIGNMENT_DETAIL, lessonAssignmentDetail);
}
