/* eslint-disable eqeqeq */
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _round from 'lodash/round';
import DashboardSummary from '../../components/DashboardSummary';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { actions as SectionSubjectActions, selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import {
  actions as SectionStudentActions, selectors as sectionStudentSelectors,
  selectors as SectionStudentSelectors,
} from '../../reducers/sectionStudent';
import { actions as SectionReviewActions } from '../../reducers/sectionReviews';
import { actions as SectionSessionActions } from '../../reducers/sectionSession';
import {
  actions as ActivityActions,
  selectors as ActivitySelectors,
} from '../../reducers/activity';
import { selectors as CurriculumLessonSelectors } from '../../reducers/curriculumLessons';
import { actions as sectionSkillsActions } from '../../reducers/sectionSkills';
import { selectors as CurriculumSelectors, 
  actions as CurriculumActions } from '../../reducers/curriculums';
import {
  selectors as LessonAssignSelectors,
  actions as LessonAssignedActivityActions,
} from '../../reducers/lessonAssignedActivities';
import {
  selectors as SectionScheduleSelectors,
} from '../../reducers/sectionSchedule';
import { actions as SectionUnitActions } from '../../reducers/sectionUnit';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', null);
  const courseId = _get(match, 'params.courseId', null);
  const gradeLevelValue = SectionSelectors.getGradeLevelValue(state, sectionId);
  const courseItem = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionItem = SectionSelectors.getSectionListById(state, sectionId);
  const studentIds = sectionStudentSelectors.getStudentIds(state, sectionId);

  const courseData = CourseSelectors.getCourseDetailById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);

  const subjectIds = SectionSubjectSelectors.getSubjectIds(state);
  const subjects = SectionSubjectSelectors.getSectionSubjectListOrder(state);
  const practiceActivityReport = SectionSelectors.getPracticeActivityBySubjectId(state, subjectIds, sectionId);

  let totalPracticeCount = 0;
  let studentPracticeCount = 0;
  let totalTeacherLessonTaught = 0;
  let totalUserLessonTaught = 0;
  subjects.forEach((subject) => {
    if (subject.practice_type !== 2) {
      totalPracticeCount += _get(practiceActivityReport, `${subject.id}.totalPracticeCount`, 0);
      studentPracticeCount += _get(practiceActivityReport, `${subject.id}.studentPracticeCount`, 0);
    }

    totalTeacherLessonTaught += _get(practiceActivityReport, `${subject.id}.teacherLessonTaught`, 0);
    totalUserLessonTaught += _get(practiceActivityReport, `${subject.id}.userLessonTaught`, 0);
  });
  const summaryPracticeCoverage = totalPracticeCount > 0 ? studentPracticeCount / totalPracticeCount : 0;
  const summaryTeacherLessonTaught = subjects.length > 0 ? Math.round(totalTeacherLessonTaught / subjects.length) : 0;
  const summaryUserLessonTaught = subjects.length > 0 ? Math.round(totalUserLessonTaught / subjects.length) : 0;
  const assignList = LessonAssignSelectors.getAssignStatus(state);

  let returnData = {
    assignList,
    summaryTeacherLessonTaught,
    summaryUserLessonTaught,
    courseItem,
    sectionItem,
    sectionId,
    gradeLevelValue,
    studentIds,
    summaryPracticeCoverage: _round(summaryPracticeCoverage * 100, 1),
    practiceActivityReport,
    userRole: UserSelectors.getUserRole(state),
    percentLastWeekAttendance: SectionSelectors.getPercentPresentLastWeekAttendance(state, sectionId),
    percentOverallAttendance: SectionSelectors.getPercentPresentOverallAttendance(state, sectionId),
    sectionDetail: SectionSelectors.sectionDetail(state),
    studentList: SectionStudentSelectors.getStudentListWithFilter(state, sectionId),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    percentLessonPassed: ActivitySelectors.percentLessonPassed(state),
    lessonList: CurriculumLessonSelectors.getPracticableLessonList(state),
    examsScoreSummary: SectionSelectors.examsScoreSummary(state),
    curriculumDetail,
    curriculumId,
    scheduleList: SectionScheduleSelectors.getScheduleList(state),
    subjectById: SectionSubjectSelectors.getSubjectById(state),
  };

  if (curriculumDetail.has_exam) {
    returnData = {
      ...returnData,
      estimateHighScore: SectionSelectors.getHighScoreOfEstimateScore(state, sectionId),
      estimateLowScore: SectionSelectors.getLowScoreOfEstimateScore(state, sectionId),
      estimateMaxScore: SectionSelectors.getMaxScoreOfEstimateScore(state, sectionId),
      estimateDelta: SectionSelectors.getDeltaOfEstimateScore(state, sectionId),
      estimateScore: SectionSelectors.estimateScore(state, sectionId),
      gradeLevelDelta: SectionSelectors.getGradeLevelDelta(state, sectionId),
      lexileValue: SectionSelectors.getLexileValue(state, gradeLevelValue),
    };
  }

  return returnData;
};

const mapDispatchToProps = {
  sectionsAttendanceGetSummary: SectionActions.sectionsAttendanceGetSummary,
  sectionsEstimatedGet: SectionActions.sectionsEstimatedGet, // 2
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
  fetchSectionSubjectList: SectionSubjectActions.fetchSectionSubjectList,
  sectionReviewGet: SectionReviewActions.sectionReviewGet,
  sectionSessionGet: SectionSessionActions.sectionSessionGet,
  sectionsEstimatedStudentGet: SectionActions.sectionsEstimatedStudentGet,
  sectionsGetDetail: SectionActions.sectionsGetDetail,
  sectionsPracticeSummaryGet: SectionActions.sectionsPracticeSummaryGet,
  sectionsStudentPracticeSummaryGet: SectionActions.sectionsStudentPracticeSummaryGet,
  sectionsAttendanceGet: SectionActions.sectionsAttendanceGet,
  getAllLessonActivitySummary: ActivityActions.getAllLessonActivitySummary, // for me
  sectionSkillsGet: sectionSkillsActions.sectionSkillsGet,
  activityGetAllTeacherActivitySummary: ActivityActions.activityGetAllTeacherActivitySummary, // for all teacher
  getAvailablePackages: SectionActions.getAvailablePackages,
  fetchLessonAssignedActivityList: LessonAssignedActivityActions.fetchLessonAssignedActivityList,
  sectionUnitGetMeta: SectionUnitActions.sectionUnitGetMeta,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSummary);
