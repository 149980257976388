import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getPacingGuides, getPacingGuideLessons } from '@apis/pacingGuide';
import { useCurrentCourse } from '@reducers/courses/hooks';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import { toast } from 'react-toastify';
import { PacingGuideLessonResponse, PacingGuideResponse } from './types';

export const useGetPacingGuides = (subjectId?: string | number) => {

  const { curriculum: currentCurriculumId, organization } = useCurrentCourse();

  const districtId = organization?.district?.id;
  const { subjectId: currentSubjectId } = useParams();

  const effectiveSubjectId = subjectId || currentSubjectId;

  const res = useQuery(
    ['pacingGuides', currentCurriculumId, effectiveSubjectId, districtId],
    async () => {
      const response = (await getPacingGuides(
        currentCurriculumId,
        effectiveSubjectId,
        districtId,
      )) as unknown as {
        response: {
          data: PacingGuideResponse[];
        };
      };
      return response?.response?.data || ([] as PacingGuideResponse[]);
    },
    {
      staleTime: Infinity,
      suspense: true,
      enabled: !!currentCurriculumId,
      onError: (error: { message: string }) => {
        toast.error(`Something went wrong: ${error.message}`);
        console.error(error.message);
      },
    },
  );
  return res;
};

export const useGetPacingGuideLessons = (lessonId?: string) => {
  const { 'pacing-guide-id': pacingGuideId } = useSearchParams();

  const res = useQuery(
    ['lessonPacingGuides', pacingGuideId, lessonId],
    async () => {
      const response = (await getPacingGuideLessons(
        pacingGuideId,
        lessonId,
      )) as unknown as {
        response: {
          data: PacingGuideLessonResponse[];
        };
      };
      return response?.response?.data || ([] as PacingGuideLessonResponse[]);
    },
    {
      enabled: !!pacingGuideId,
      staleTime: 1000 * 60 * 60 * 24, // 1 day
      onError: (error: { message: string }) => {
        toast.error(`Something went wrong: ${error.message}`);
        console.error(error.message);
      },
    },
  );
  return res;
};
