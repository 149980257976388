import { useSelector } from 'react-redux';
import { getUserRole, getCurrentUser, getIsDistrictAdmin } from './userReducer';
import { ROLE_TYPE } from '../../utils/enums';

export const useUserRole = () => {
  const role = useSelector(getUserRole);
  return role;
};
export const useIsTeacher = () => {
  const role = useUserRole();
  return role === ROLE_TYPE.INSTRUCTOR;
};
export const useIsDistrictAdmin = () => {
  const isDistrictAdmin = useSelector(getIsDistrictAdmin);
  return isDistrictAdmin;
};

export const useUser = () => useSelector(getCurrentUser);
