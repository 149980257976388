import moment from 'moment';
import React from 'react';
import _isEqual from 'lodash/isEqual';
import _isUndefined from 'lodash/isUndefined';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import GroupActivityContent from '../../containers/GroupActivityContent';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { GroupSheetContainer } from './GroupActivity.style';
import { getGroupActivityDetails } from '../../apis/groupActivity';
import { getTotalDurationOfActivity } from '../../utils/constants';

import ReportErrorModal from '../ReportErrorModal';
import { useSearchParams } from '@utils/hooks/useSearchParams';
const reportModalType = 'Report';

class GroupActivitySheet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hideModal: true,
      isOpen: false,
      reportProps: null,
      isUseNextQuestionIdDisabled: false,
    };
  }

  enableUseNextQuestionId = () => this.setState({ isUseNextQuestionIdDisabled: false });

  disableUseNextQuestionId = () => this.setState({ isUseNextQuestionIdDisabled: true });

  hideGroupModal = () => {
    this.setState({ hideModal: true });
  };

  goBack = () => {
    const { history, match, pacingGuideId } = this.props;
    const { courseId, sectionId, subjectId, unitId, lessonId } = match.params;

    history.push({
      pathname: `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}`,
      search: `?tab=${LessonTabs.LessonPractice}${
        pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
      }`,
    });
  };

  handleLostInternetConnection = () => {
    if (this.props.groupActivityInfo) {
      this.setSessionDuration(this.props.groupActivityInfo);
    }
  };

  componentDidMount() {
    const {
      match,
      isTeacher,
      lessonId,
      curriculumLessonsGetDetail,
      groupActivityInfo: { duration },
      activityLessonId,
      lessonDetails,
      location,
    } = this.props;
    const { PART_ONE_DURATION, PART_TWO_DURATION } = getTotalDurationOfActivity(
      duration || 14,
    );

    this.setState((state) => ({
      ...state,
      part1Duration: PART_ONE_DURATION,
      part2Duration: PART_TWO_DURATION,
      totalDuration: duration * 60,
      partOneTotalDuration: PART_ONE_DURATION,
      partTwoTotalDuration: PART_TWO_DURATION,
    }));

    const { groupId, onlineResponseId, userId, status } = match.params;
    const setLocalStorage = location.search.includes('question=0') && status == 1;
    // merge was added !lessonDetails?.id || parseInt(lessonDetails?.id) !== parseInt(lessonId)
    if (
      !lessonDetails?.id ||
      !activityLessonId ||
      parseInt(lessonDetails?.id) !== parseInt(lessonId)
    ) {
      curriculumLessonsGetDetail(lessonId);
    }

    window.addEventListener('online', this.handleLostInternetConnection);
    if (isTeacher) {
      this.props.getOnlineActivityQuestions(groupId, lessonId);
    } else {
      this.props.getStudentOnlineActivityQuestions(
        onlineResponseId,
        userId,
        lessonId,
        setLocalStorage,
      );
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('online', this.handleLostInternetConnection);
  };

  getNextQuestion(groupActivityId, studentId, questions, location, group_status) {
    return getGroupActivityDetails(groupActivityId)
      .then(({ response: { data } }) => {
        const findStudent = ({ students }) =>
          students.find(({ student_id }) => student_id === parseInt(studentId, 10));
        const group = data.find(findStudent);
        const student = findStudent(group);
        return {
          group,
          student,
        };
      })
      .then(({ student, group }) => {
        // const { group_status } = group;
        let questionId;
        if (parseInt(group_status, 10) === 1) {
          questionId = student.previous_exam_question_id;
        } else if (parseInt(group_status, 10) === 2) {
          /**
           * Test whether all group members have the same `previous_exam_question_id`
           * It should be expected for students currently doing part 2 to have the
           * same value for `previous_exam_question_id`
           */
          const allStudentsHaveSameQuestion = group.students.reduce(
            (acc, { previous_exam_question_id }) => ({
              previous_exam_question_id,
              allStudentsHaveSameQuestion:
                acc.previous_exam_question_id === previous_exam_question_id,
            }),
            {
              previous_exam_question_id: undefined,
              allStudentsHaveSameQuestion: true,
            },
          );
          questionId =
            allStudentsHaveSameQuestion.allStudentsHaveSameQuestion &&
            allStudentsHaveSameQuestion.previous_exam_question_id;
        }

        return (
          questionId &&
          questions.some((question, index) => {
            if (question.id === questionId) {
              if (index !== questions.length - 1) {
                // move to part 2 before opening next question if students are on part 2
                if (group_status === 2) this.moveToPart(2);
                this.openQuestion(location, index + 1);
              }
              return true;
            }
            return false;
          })
        );
      })
      .catch(console.error);
  }

  setSessionDuration = (groupActivityInfo, overrideDuration) => {
    const partOneTotalDuration =
      overrideDuration?.partOneTotalDuration || this.state.partOneTotalDuration;
    const { partTwoTotalDuration } = this.state;
    if (![1, 2].includes(parseInt(this.props.match.params.status, 10))) {
      return this.setState({
        part1Duration: 0,
        part2Duration: 0,
      });
    }
    let activityStartTime = JSON.parse(localStorage.getItem('activity_start_time'))
      ? JSON.parse(localStorage.getItem('activity_start_time'))
      : groupActivityInfo.time_activity_started;
    activityStartTime = moment(new Date(activityStartTime));
    const onlineStageTime = moment(
      new Date(JSON.parse(localStorage.getItem('group_stage_start_time'))),
    );
    const currentTime = moment();
    const differenceInTime = currentTime.diff(activityStartTime, 'seconds');
    const groupStageTimeDifference = currentTime.diff(onlineStageTime, 'seconds');
    const studentHistory = JSON.parse(localStorage.getItem('oga_student_history'));

    const studentCount = studentHistory.studentCount;
    const total = 840;
    const stage1time = 360;

    if (studentHistory.status === 2) {
      let partGroupDuration = 0;

      if (stage1time > differenceInTime) {
        partGroupDuration = total - (stage1time + groupStageTimeDifference);
      } else {
        partGroupDuration = total - differenceInTime;
      }
      this.setState({
        part1Duration: 0,
        part2Duration: partGroupDuration,
      });
    } else if (differenceInTime > total) {
      this.setState({
        part1Duration: 0,
        part2Duration: 0,
      });

      this.moveToPart(2);
    } else if (differenceInTime < partOneTotalDuration) {
      this.setState({
        part1Duration:
          studentCount == 1
            ? total - differenceInTime
            : partOneTotalDuration - differenceInTime,
      });
    } else if (differenceInTime > partOneTotalDuration) {
      this.setState({
        part1Duration: 0,
        part2Duration: partTwoTotalDuration - (differenceInTime - partOneTotalDuration),
      });
      this.moveToPart(2);
    }
  };

  componentDidUpdate(prevProps) {
    const { currentGroupStudents, groupActivityInfo } = this.props;
    if (
      prevProps.groupActivityInfo !== this.props.groupActivityInfo &&
      this.props.groupActivityInfo
    ) {
      this.setSessionDuration(
        this.props.groupActivityInfo,
        currentGroupStudents?.length === 1 && {
          partOneTotalDuration: groupActivityInfo.duration * 60,
        },
      );
    }

    // temporary disabling the redirect for individual practice, and enabling just if the group is already on group discussion
    const redirectEnabled = parseInt(this.props.match.params.status, 10) === 2;

    if (
      redirectEnabled &&
      this.props.questions.length &&
      !this.props.isTeacher &&
      !_isEqual(this.props.groupActivityInfo, prevProps.groupActivityInfo) &&
      !this.state.isUseNextQuestionIdDisabled &&
      //added on merge
      this.props.groupActivityInfo.id
    ) {
      this.getNextQuestion(
        this.props.groupActivityInfo.id,
        this.props.match.params.userId,
        this.props.questions,
        this.props.history.location,
        this.props.match.params.status,
      );
    }

    if (
      !_isEqual(prevProps.currentGroupStudents, currentGroupStudents) &&
      currentGroupStudents.length === 1
    ) {
      this.setSessionDuration(groupActivityInfo, {
        partOneTotalDuration: groupActivityInfo.duration * 60,
      });
    }
  }

  openQuestion(location, question) {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('question', question);
    this.props.history.push({
      search: searchParams.toString(),
      currentGroupStudents: [],
    });
  }

  onCloseModal = () => {
    this.setState({
      isOpen: false,
      reportProps: null,
    });
  };

  openReportErrorModal = (contentType, questionId) => {
    this.setState({
      isOpen: true,
      modalType: reportModalType,
      reportProps: {
        contentType,
        questionId,
      },
    });
  };

  moveToPart2Question(differenceInTime) {
    const { partOneTotalDuration, partTwoTotalDuration } = this.state;

    const durationPerQuestion = partTwoTotalDuration / this.props.questions.length;
    const questionNumber = Math.floor(
      (differenceInTime - partOneTotalDuration) / durationPerQuestion,
    );
    this.openQuestion(this.props.history.location, questionNumber);
  }

  moveToPart(part) {
    const {
      courseId,
      sectionId,
      subjectId,
      unitId,
      lessonId,
      currentGroup,
      groupId,
      onlineResponseId,
      userId,
      pacingGuideId,
    } = this.props.match.params;
    if (part === 2) {
      const studentHistory = JSON.parse(localStorage.getItem('oga_student_history'));
      studentHistory.individuaSubmitted = true;
      studentHistory.status = 2;
      localStorage.setItem('oga_student_history', JSON.stringify(studentHistory));
    }
    this.props.history.push({
      pathname: `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/online-activity/question/${currentGroup}/${groupId}/${part}/${onlineResponseId}/${userId}`,
      search: `?question=0${pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''}`,
    });
  }

  completeGroupActivity = () => {
    const { match, history, getOnlineActivityQuestions, pacingGuideId } = this.props;
    if (parseInt(match.params.status, 10) === 1) {
      this.moveToPart(2); // this will trigger a change to part 2
      this.setState({ hideModal: false, isUseNextQuestionIdDisabled: true });
    } else if (parseInt(match.params.status, 10) === 2) {
      const { courseId, sectionId, subjectId, unitId, currentGroup, lessonId } =
        match.params;
      history.push({
        pathname: `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/`,
        showModal: true,
        search: `?tab=${LessonTabs.LessonPractice}${
          pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
        }`,
      });
      getOnlineActivityQuestions(currentGroup, lessonId);
    }
  };

  submitError = (content) => {
    const { userReportIssue } = this.props;
    const { reportProps } = this.state;

    if (!reportProps) {
      return;
    }

    const { questionId, contentType } = reportProps;
    userReportIssue(questionId, contentType, content, this.onCloseModal);
  };

  render() {
    const {
      status,
      courseId,
      sectionId,
      subjectId,
      unitId,
      lessonId,
      currentGroup,
      groupId,
      onlineResponseId,
      userId,
    } = this.props.match.params;
    const { isTeacher, currentGroupStudents } = this.props;

    return (
      <GroupSheetContainer>
        <PracticeTopNavBar
          practiceName="Group Activity"
          goBack={this.goBack}
          goBackText="Back to activity"
          displayTimeOut
          secondsRemaining={
            Number(status) === 1 ? this.state.part1Duration : this.state.part2Duration
          }
          complete={this.completeGroupActivity}
          status={status}
        />
        <GroupActivityContent
          isTeacher={isTeacher}
          history={this.props}
          status={status}
          hideModal={this.state.hideModal}
          hideGroupModal={this.hideGroupModal}
          openReportErrorModal={this.openReportErrorModal}
          totalDuration={this.state.totalDuration}
          {...{
            courseId,
            sectionId,
            subjectId,
            unitId,
            lessonId,
            currentGroup,
            groupId,
            onlineResponseId,
            userId,
            currentGroupStudents,
            enableUseNextQuestionId: this.enableUseNextQuestionId,
            disableUseNextQuestionId: this.disableUseNextQuestionId,
          }}
        />
        {this.state.isOpen && (
          <ReportErrorModal
            isOpen={this.state.isOpen}
            onClose={this.onCloseModal}
            onSubmit={this.submitError}
          />
        )}
      </GroupSheetContainer>
    );
  }
}

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const { 'pacing-guide-id': pacingGuideId } = useSearchParams();
    return <WrappedComponent {...props} pacingGuideId={pacingGuideId} />;
  };
  return ComponentWithData;
};
export default withDataWrapper(GroupActivitySheet);
