/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import _round from 'lodash/round';
import moment from 'moment';
import _get from 'lodash/get';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import DashboardSummaryTable from '../../containers/DashboardSummary/DashboardSummaryTable';
import ShadowSrollbar from '../Scrollbar/ShadowScrollbars';
import DashboardSummaryFake from './DashboardSummaryFake';
import { ROLE_TYPE } from '../../utils/enums';
import {
  DashboardSummaryWrap,
  DashboardPracticeActivityWrap,
  DashboardStatisticWrap,
  DashboardStatisticGroup,
  SummaryBlockWrap,
  SummaryBlockContent,
  SummaryBlockItem,
  SummaryScoreGroup,
  SummaryImage,
  SummaryScore,
  SummaryText,
  SummaryNumber,
  SummarySubText,
  SummaryRank,
  NotApplicationText,
  TextAdditionInfo,
  DashboardScheduleWrap,
  SummaryContainer
} from './DashboardSummary.style';
import {
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';
import { allowedSections } from '../../utils/allowedSections';
import DashboardUpNextWidget from '../../containers/DashboardSummary/DashboardUpNextWidget';

const scrollStyle = {
  height: 'calc(100% - 4em)',
  width: '100%',
};

class DashboardSummary extends Component {
  componentDidMount() {
    const { sectionId } = this.props;

    if (sectionId !== null) {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionId: prevSectionId } = prevProps;
    const { sectionId } = this.props;

    if (sectionId !== null && prevSectionId !== sectionId) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    const {
      sectionId,
      sectionsAttendanceGetSummary,
      sectionsEstimatedGet,
      sectionsStudentGet,
      sectionReviewGet,
      sectionSessionGet,
      sectionsEstimatedStudentGet,
      userRole,
      sectionsPracticeSummaryGet,
      fetchSectionSubjectList,
      sectionsAttendanceGet,
      getAllLessonActivitySummary,
      sectionsStudentPracticeSummaryGet,
      sectionSkillsGet,
      activityGetAllTeacherActivitySummary,
      getAvailablePackages,
      curriculumId,
      fetchLessonAssignedActivityList,
      sectionUnitGetMeta,
      curriculumDetail,
    } = this.props;

    sectionReviewGet(sectionId);
    sectionSessionGet(sectionId);
    fetchSectionSubjectList(sectionId);
    getAllLessonActivitySummary(sectionId);
    sectionSkillsGet(sectionId);
    activityGetAllTeacherActivitySummary(sectionId);

    if (userRole !== ROLE_TYPE.STUDENT) {
      sectionsAttendanceGetSummary(sectionId);
      sectionsAttendanceGet(sectionId);
      sectionsStudentGet(sectionId);
      if (curriculumDetail.has_exam) {
        sectionsEstimatedGet(sectionId);
      }
      sectionsPracticeSummaryGet(sectionId);
      getAvailablePackages(curriculumId);
    } else {
      sectionUnitGetMeta(sectionId);
      fetchLessonAssignedActivityList(sectionId)
      if (curriculumDetail.has_exam) {
        sectionsEstimatedStudentGet(sectionId);
      }
      sectionsStudentPracticeSummaryGet(sectionId);
    }
  };

  renderAttendanceDetail = () => {
    const { percentLastWeekAttendance, percentOverallAttendance } = this.props;

    return (
      <SummaryBlockWrap>
        <span>Attendance</span>
        <SummaryBlockItem>
          <SummaryImage src="/images/bg-attendance.png" />

          <SummaryBlockContent>
            <SummaryText>Last Week</SummaryText>
            <SummaryNumber>
              {`${Math.min(percentLastWeekAttendance, 100)}%`}
            </SummaryNumber>
          </SummaryBlockContent>

          <SummaryBlockContent>
            <SummaryText>Overall</SummaryText>
            <SummaryNumber>
              {`${Math.min(percentOverallAttendance, 100)}%`}
            </SummaryNumber>
          </SummaryBlockContent>
        </SummaryBlockItem>
      </SummaryBlockWrap>
    );
  };

  renderScoreDetail = () => {
    const {
      estimateHighScore,
      estimateLowScore,
      estimateMaxScore,
      estimateDelta,
      gradeLevelValue,
      gradeLevelDelta,
      lexileValue,
      sectionDetail,
      courseItem,
      estimateScore,
      studentIds,
    } = this.props;
    const studentData = _get(estimateScore, 'student_data', []);
    const subjects = (sectionDetail.subjects || []).map(
      (sub) => sub.display_name,
    );
    const curriculumName = estimateScore.overall_score_name;
    const subjectsNoIntroduction = subjects.filter((subject) => !subject.toLowerCase().includes("introduction"));
    const containsReadingOrWriting = (subject) => subject == 'Writing & Language' || subject == 'Reading';
    const shouldDisplayScore = !(curriculumName == 'SAT' && subjectsNoIntroduction.length == 1 && subjectsNoIntroduction.some(containsReadingOrWriting));
    if (Object.keys(courseItem).length === 0) {
      return '';
    }
    const filteredBoxes = allowedSections(subjects, courseItem.curriculum);
    let studentCount = 0;
    let estimatedScoreMax = 0;
    let estimatedScoreMin = 0;
    studentIds.forEach((student) => {
      const lowScore = _get(studentData, `${student}.low_score`, 0);
      const highScore = _get(studentData, `${student}.high_score`, 0);
      if (highScore != null && lowScore != null) {
        estimatedScoreMin += lowScore;
        estimatedScoreMax += highScore;
        studentCount++;
      }
    });

    estimatedScoreMax = Math.round(estimatedScoreMax / studentCount);
    estimatedScoreMin = Math.round(estimatedScoreMin / studentCount);
    return (
      <SummaryBlockWrap>
        <span>Scores</span>
        <SummaryBlockItem>
          <SummaryImage src="/images/bg-score.png" />
          <SummaryBlockContent>
            <SummaryText>Estimated Score</SummaryText>
            <SummaryScoreGroup>
              {shouldDisplayScore ? (
                <SummaryScore>
                  {!isNaN(estimateLowScore) && (
                    <React.Fragment>
                      <SummaryNumber>
                        {`${Math.round(
                          estimatedScoreMin,
                        )} - ${Math.round(estimatedScoreMax)}`}
                      </SummaryNumber>
                      <SummarySubText>
                        {`out of ${Math.round(
                          estimateMaxScore,
                        )}`}
                      </SummarySubText>
                    </React.Fragment>
                  )}
                  {(isNaN(estimateLowScore) || isNaN(estimateHighScore)) && (
                    <NotApplicationText small>
                      Data not available
                    </NotApplicationText>
                  )}
                </SummaryScore>
              )
                : (
                  <SummaryScore>
                    <SummaryNumber>--</SummaryNumber>
                  </SummaryScore>
                )}
              {/*
              Temporarily removing hardcoded delta values until they are calculated correctly
              !!estimateDelta && estimateDelta > 0 && shouldDisplayScore && (
                <SummaryRank>
                  <i className="chalktalk-arrow-up" />
                  <span>
                    {this.props.match.params.courseId == 1098
                      ? 10
                      : this.props.match.params.courseId == 1099
                        ? 1
                        : 200}
                  </span>
                </SummaryRank>
              )*/}
            </SummaryScoreGroup>
          </SummaryBlockContent>
          {(!filteredBoxes || !filteredBoxes.ShowLexelLevel) ? ''
            : (
              <SummaryBlockContent>
                <SummaryText>Lexile Level</SummaryText>
                <SummaryScoreGroup>
                  <SummaryScore>
                    {!isNaN(gradeLevelValue) && (
                      <React.Fragment>
                        <SummaryNumber>{`${gradeLevelValue}th  grade`}</SummaryNumber>
                        <SummarySubText>{lexileValue}</SummarySubText>
                      </React.Fragment>
                    )}
                    {
                      (isNaN(gradeLevelValue)) && (
                        <NotApplicationText small>Data not available</NotApplicationText>
                      )
                    }
                  </SummaryScore>
                  {!!gradeLevelDelta && gradeLevelDelta > 0
                    && (
                      <SummaryRank>
                        <i className="chalktalk-arrow-up" />
                        <span>{gradeLevelDelta}</span>
                      </SummaryRank>
                    )}
                </SummaryScoreGroup>
              </SummaryBlockContent>
            )}
        </SummaryBlockItem>
      </SummaryBlockWrap>
    );
  };

  renderCourseProgress = () => {
    const {
      sectionDetail,
      userRole,
      summaryTeacherLessonTaught,
      summaryUserLessonTaught,
    } = this.props;
    const { start_date: startDay = '', end_date: endDay = '' } = sectionDetail;
    const toDay = moment().format('YYYY-MM-DD');
    const role = userRole !== ROLE_TYPE.STUDENT;
    let percentDate = 0;

    if (startDay && endDay) {
      const timeCourse = moment(endDay, 'YYYY-MM-DD').diff(
        moment(startDay, 'YYYY-MM-DD'),
        'days',
      );
      const timeFromStartToNow = moment(toDay, 'YYYY-MM-DD').diff(
        moment(startDay, 'YYYY-MM-DD'),
        'days',
      );
      percentDate = moment(toDay, 'YYYY-MM-DD').diff(
        moment(endDay, 'YYYY-MM-DD'),
        'days',
      ) >= 0
        ? 100
        : Math.round((timeFromStartToNow / timeCourse) * 100);
    }

    return (
      <SummaryBlockWrap>
        <span>Lesson</span>
        <SummaryBlockItem>
          <SummaryImage src="/images/bg-course-progress.png" />

          <SummaryBlockContent>
            <SummaryText>Schedule Time Passed</SummaryText>
            <SummaryNumber>{`${Math.max(percentDate, 0)}%`}</SummaryNumber>
          </SummaryBlockContent>

          <SummaryBlockContent>
            {role ? (
              <SummaryText
                data-tip="Percentage of lessons taught in this section and (your own activity)."
                data-for="tooltip-dashboard-card"
              >
                Lessons Taught
              </SummaryText>
            ) : (
              <SummaryText>Lessons Taught</SummaryText>
            )}
            <SummaryNumber>
              {`${Math.min(summaryTeacherLessonTaught, 100)}%`}
              <TextAdditionInfo>
                {`(${Math.min(summaryUserLessonTaught, 100)}%)`}
              </TextAdditionInfo>
            </SummaryNumber>
          </SummaryBlockContent>
        </SummaryBlockItem>
      </SummaryBlockWrap>
    );
  };

  renderPractice = () => {
    const { summaryPracticeCoverage, examsScoreSummary, userRole } = this.props;

    const qCount = examsScoreSummary.reduce((acc, summary) => {
      // eslint-disable-next-line no-param-reassign
      acc += summary.question_count;
      return acc;
    }, 0);

    const role = userRole !== ROLE_TYPE.STUDENT;

    return (
      <SummaryBlockWrap>
        <span>Practice</span>
        <SummaryBlockItem>
          <SummaryImage src="/images/bg-practice.png" />

          <SummaryBlockContent>
            {role ? (
              <SummaryText
                data-tip="Percentage of lessons practiced by 70% or more of the students"
                data-for="tooltip-dashboard-card"
              >
                Practice Coverage
              </SummaryText>
            ) : (
              <SummaryText>Practice Coverage</SummaryText>
            )}
            <SummaryNumber>
              {`${_round(summaryPracticeCoverage, 2)}%`}
            </SummaryNumber>
          </SummaryBlockContent>

          <SummaryBlockContent>
            <SummaryText>Questions Answered</SummaryText>
            <SummaryScore>
              <SummaryNumber>{qCount}</SummaryNumber>
              <SummarySubText>Questions</SummarySubText>
            </SummaryScore>
          </SummaryBlockContent>
        </SummaryBlockItem>
      </SummaryBlockWrap>
    );
  };

  renderUpcomingItem = () => (
    <DashboardScheduleWrap>
      <DashboardUpNextWidget {...this.props} />
    </DashboardScheduleWrap>
  );

  renderCorrectData = (userRole) => {
    const { practiceActivityReport, curriculumDetail, courseItem } = this.props;
    return (
      <DashboardSummaryWrap>
        <DashboardPracticeActivityWrap>
          <BlockWrap>
            {userRole !== ROLE_TYPE.STUDENT && (
              <ReactTooltip
                place="top"
                effect="solid"
                id="tooltip-dashboard-card"
              />
            )}
            <BlockTitle>
              <span>Summary</span>
            </BlockTitle>
            <DashboardStatisticWrap>
              <DashboardStatisticGroup>
                {this.renderCourseProgress()}
                {this.renderPractice()}
              </DashboardStatisticGroup>
              <DashboardStatisticGroup>
                {userRole !== ROLE_TYPE.STUDENT
                  && this.renderAttendanceDetail()}
                {curriculumDetail.has_exam && this.renderScoreDetail()}
              </DashboardStatisticGroup>
            </DashboardStatisticWrap>
          </BlockWrap>

          <DashboardSummaryTable
            practiceActivityReport={practiceActivityReport}
            courseDetails={courseItem}
          />
        </DashboardPracticeActivityWrap>
      </DashboardSummaryWrap>
    );
  };

  // if a user is limited
  renderFakeData = (userRole) => <DashboardSummaryFake userRole={userRole} />;
  renderAssignments = () => (
    <DashboardScheduleWrap>
      <DashboardUpNextWidget {...this.props} isAssignments={true} />
    </DashboardScheduleWrap>
  );

  render() {
    const {
      userRole, courseItem, sectionItem, isSideBarClose, sectionId, shouldRenderStudentSummary
    } = this.props;
    const { subscription = {} } = courseItem;
    const { subscription_type: subType } = subscription;
    return (
      <SummaryContainer gradient={!shouldRenderStudentSummary}>
        {(shouldRenderStudentSummary && userRole === ROLE_TYPE.STUDENT) &&
        <BreadcumbsFilter
          haveSub={isSideBarClose}
          breadcumbSubList={[courseItem.name, sectionItem.name]}
          breadcumbList={['Home', 'Activity Summary']}
          mobileTitle="Dashboard"
        />}
        <ShadowSrollbar autoHide style={scrollStyle} hideScroll>
          {!sectionId && (
            <DashboardSummaryWrap>
              <DashboardPracticeActivityWrap>
                Please create a section to continue
              </DashboardPracticeActivityWrap>
            </DashboardSummaryWrap>
          )}
          {!shouldRenderStudentSummary && userRole === ROLE_TYPE.STUDENT && this.renderAssignments()}
          {(!shouldRenderStudentSummary||userRole !== ROLE_TYPE.STUDENT) && sectionId && subType !== 3 && this.renderUpcomingItem()}
          {(userRole !== ROLE_TYPE.STUDENT || shouldRenderStudentSummary) && sectionId && subType !== 3 && this.renderCorrectData(userRole)}
          {sectionId && subType === 3 && this.renderFakeData(userRole)}
        </ShadowSrollbar>
      </SummaryContainer>
    );
  }
}

DashboardSummary.propTypes = {
  sectionId: PropTypes.any,
  sectionsAttendanceGetSummary: PropTypes.func,
  sectionsEstimatedGet: PropTypes.func,
  sectionsStudentGet: PropTypes.func,
  percentLastWeekAttendance: PropTypes.any,
  percentOverallAttendance: PropTypes.any,
  estimateHighScore: PropTypes.any,
  estimateLowScore: PropTypes.any,
  estimateMaxScore: PropTypes.any,
  estimateDelta: PropTypes.any,
  gradeLevelValue: PropTypes.any,
  gradeLevelDelta: PropTypes.any,
  lexileValue: PropTypes.string,
  sectionReviewGet: PropTypes.func,
  sectionSessionGet: PropTypes.func,
  sectionsEstimatedStudentGet: PropTypes.func,
  userRole: PropTypes.string,
  sectionDetail: PropTypes.object,
  sectionsPracticeSummaryGet: PropTypes.func,
  sectionsStudentPracticeSummaryGet: PropTypes.func.isRequired,
  fetchSectionSubjectList: PropTypes.func,
  sectionsAttendanceGet: PropTypes.func,
  courseItem: PropTypes.object,
  sectionItem: PropTypes.any,
  isSideBarClose: PropTypes.bool,
  getAllLessonActivitySummary: PropTypes.func,
  examsScoreSummary: PropTypes.arrayOf(PropTypes.shape()),
  estimateScore: PropTypes.any,
  sectionSkillsGet: PropTypes.func,
  summaryPracticeCoverage: PropTypes.number,
  practiceActivityReport: PropTypes.object,
  activityGetAllTeacherActivitySummary: PropTypes.func,
  summaryTeacherLessonTaught: PropTypes.number,
  summaryUserLessonTaught: PropTypes.number,
};

export default DashboardSummary;
