import React from 'react';
import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  SxProps,
  Theme,
} from '@mui/material';

export interface MenuProps extends MuiMenuProps {
  children: React.ReactNode[];
  sx?: SxProps<Theme>;
}

const Menu = ({ children, ...rest }: MenuProps) => (
  <MuiMenu {...rest}>{children}</MuiMenu>
);
export default Menu;
