import React, { useEffect, useState } from 'react';
import {
  ContainerMain,
  ReviewListContainer,
  Content,
  ReviewListContent,
  TitleReviewList,
  CurriculaContainer,
  CurriculaListContent,
  TitleCurriculaList,
  LoginButtonContainer,
  LabelText,
} from './MyLibrary.style';
import ReviewItem from './ReviewItem';
import CurriculumItem from './CurriculumItem';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { initializeGAPI } from '../../utils/initializeGAPI';
import { SubmitButton } from '../Generals/stylesheets/Button.style';
import { error as errorToast } from '../../utils/toast';
import LoadingIndicator from '../LoadingIndicator';
import GFEButton from '../Generals/GFEButton';

const MyLibrary = (props) => {
  const { curriculumLessonGetReviews, reviewRequests, history, isGettingReviews } = props;

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleInitializeGAPI = async () => {
    setIsLoading(true);
    // TODO: Investigate why this is called here
    if (false) {
      await initializeGAPI();
    }
    const isLoggedIn =
      (await window.gapi.auth2.getAuthInstance()?.isSignedIn.get()) || false;
    setIsSignedIn(isLoggedIn);
    setIsLoading(false);
  };

  const handleSignIn = async () => {
    if (!window?.gapi?.auth2?.getAuthInstance()) {
      captureMessage('Tried to access google api without initialization.');
      return errorToast('Failed to initialize Google API');
    }

    try {
      await window.gapi.auth2.getAuthInstance().signIn();
      const isLoggedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get();
      setIsSignedIn(isLoggedIn);
      return true;
    } catch ({ message }) {
      return false;
    }
  };

  useEffect(() => {
    const params = { status: 'Pending' };
    handleInitializeGAPI();
    curriculumLessonGetReviews(params);
  }, [isSignedIn]);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <ContainerMain>
      <PracticeTopNavBar goBackText="Go Back" goBack={handleGoBack} />
      <ReviewListContainer>
        <CurriculaContainer>
          <TitleCurriculaList>My Curricula Library</TitleCurriculaList>
          <CurriculaListContent>
            <CurriculumItem title="IM" label="Illustrative Mathematics" />
            <CurriculumItem title="SAT" label="ChalkTalk SAT" />
            <CurriculumItem title="KCS" label="Kings County Schools SAT" />
          </CurriculaListContent>
        </CurriculaContainer>
        <TitleReviewList>Review Merge Requests</TitleReviewList>
        {/* check if the reviewer is logged in using one of the reviewer google account */}
        {/* if the reviewer is not logged in then display login button instead of the review lists */}
        {!isSignedIn && (
          <LoginButtonContainer>
            <LabelText>
              Log in with reviewer&apos;s google account to be able to review google
              slides!
            </LabelText>
            <GFEButton
              backgroundColor="#ff9e16"
              color="white"
              onClick={handleSignIn}
              label="Log In"
            />
          </LoginButtonContainer>
        )}
        {!isGettingReviews && isSignedIn && (
          <div>
            <ReviewListContent>
              {reviewRequests.map((item, index) => (
                <ReviewItem key={`request-item-${index}`} data={item} {...props} />
              ))}
            </ReviewListContent>
            <Content>
              {reviewRequests.length === 0 && <span>No Pull Requests to Review!</span>}
            </Content>
          </div>
        )}
      </ReviewListContainer>
    </ContainerMain>
  );
};

export default MyLibrary;
