import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  usePracticeTotalScoreCalculator,
  usePracticeStatus,
} from '@components/Component/Lesson/LessonContainer/hooks';
import ShowIf from '@components/Atoms/ShowIf';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import { useParams } from 'react-router-dom';
import PacingGuideInfoCard from '@components/Component/Lesson/PacingGuide/PacingGuideInfoCard';
import Box from '@components/Atoms/Box';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import {
  PracticeCardItem,
  PracticeCardTitle,
  PracticeCardBlock,
  ContentLine,
  LevelSkill,
  Dot,
  PracticeCardAction,
} from './LessonPracticeCard.style';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  DefaultContentWrap,
  ContentFlexWrap,
} from '../Generals/stylesheets/General.style';
import { PRACTICE_TYPE, ROLE_TYPE } from '../../utils/enums';

const arrayOf5 = [...new Array(5)];

class LessonPracticeCard extends Component {
  componentDidUpdate(prevProps) {
    const { match, history, practiceId, isInitialized, pacingGuideId } = this.props;

    const { sectionId, lessonId, unitId, courseId, subjectId } = match.params;

    if (prevProps.isInitialized !== isInitialized && isInitialized) {
      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice/${practiceId}${
          pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
        }`,
      );
    }
  }

  renderIndicatorIcon = (type) => {
    if (type === 2) {
      return <i className="chalktalk-error" />;
    }

    if (type === 1) {
      return <i className="chalktalk-check-success" />;
    }
    return <i className="chalktalk-in-progress" />;
  };

  initializePractice = () => {
    const { match, currentUserId, initializePracticeSession } = this.props;
    const { sectionId, lessonId, unitId } = match.params;
    initializePracticeSession(
      lessonId,
      sectionId,
      unitId,
      currentUserId,
      _,
      PRACTICE_TYPE.ADAPTIVE_PRACTICE,
    );
  };

  calculateButtonText = () => {
    const { examsScoreSummary } = this.props;

    if (!examsScoreSummary || !examsScoreSummary.length) {
      return ['Practice', false];
    }

    const isContinuePractice = examsScoreSummary.some(
      (activity) => !activity.last_activity,
    );
    const shouldShowViewResult = examsScoreSummary.some(
      (activity) => activity.last_activity,
    );

    if (isContinuePractice) {
      return ['Continue Practice', shouldShowViewResult];
    }

    return ['Additional Practice', true];
  };

  viewOverallReview = () => {
    const { history, match, pacingGuideId } = this.props;
    const { sectionId, unitId, courseId, lessonId, subjectId } = match.params;

    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice-review${
        pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
      }`,
    );
  };

  viewAllResults = () => {
    const { history, match, pacingGuideId } = this.props;
    const { sectionId, unitId, courseId, lessonId, subjectId } = match.params;

    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice-result${
        pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
      }`,
    );
  };

  renderActionBtns = () => {
    const { userRole, isInitializing, examsScoreSummary } = this.props;
    if (userRole === ROLE_TYPE.INSTRUCTOR) {
      // const isPendingResult = examsScoreSummary.every(s => !s.ended);
      const isPendingResult = examsScoreSummary.every((s) => !s.last_activity);

      if (isPendingResult) {
        return (
          <Button green blocked>
            No Submissions Yet
          </Button>
        );
      }

      return (
        <Button submit onClick={this.viewAllResults}>
          Review Answers
        </Button>
      );
    }

    const [buttonText, isViewResult] = this.calculateButtonText();

    return (
      <>
        <Button green onClick={this.initializePractice} disabled={isInitializing}>
          {isInitializing ? 'Initializing' : buttonText}
        </Button>
        {isViewResult && (
          <Button submit blue onClick={this.viewOverallReview}>
            View Results
          </Button>
        )}
      </>
    );
  };

  render() {
    const {
      lessonDetail,
      practiceStatus,
      totalScore,
      totalMaxScore,
      skillLevel,
      pacingGuideLessonData,
    } = this.props;
    const level = arrayOf5.map((_, i) => i + 1 <= skillLevel);

    return (
      <>
        <Box
          sx={{
            px: 2,
            py: 2,
          }}
        >
          <PacingGuideInfoCard
            guideInfoText={pacingGuideLessonData?.adaptive_practice_instruction}
            taskDeliveryText={pacingGuideLessonData?.adaptive_practice_delivery}
          />
        </Box>
        <DefaultContentWrap medium>
          <ContentFlexWrap flexWrap>
            <PracticeCardItem>
              {this.renderIndicatorIcon(practiceStatus)}
              <PracticeCardTitle>{lessonDetail.name}</PracticeCardTitle>
              <PracticeCardBlock>
                <ContentLine>
                  {skillLevel ? 'Skill Level' : 'No Activity Yet'}
                </ContentLine>
                <ShowIf If={!!skillLevel}>
                  <ContentLine>{skillLevel}</ContentLine>
                  <LevelSkill>
                    {level.map((e, index) => (
                      <Dot isActive={e} key={index} /> // eslint-disable-line
                    ))}
                  </LevelSkill>
                </ShowIf>
              </PracticeCardBlock>
              <PracticeCardBlock>{`${totalScore}/${totalMaxScore}`}</PracticeCardBlock>
              <PracticeCardAction>{this.renderActionBtns()}</PracticeCardAction>
            </PracticeCardItem>
          </ContentFlexWrap>
        </DefaultContentWrap>
      </>
    );
  }
}

LessonPracticeCard.propTypes = {
  match: PropTypes.shape().isRequired,
  examsScoreSummary: PropTypes.arrayOf(PropTypes.shape()),
  lessonDetail: PropTypes.shape(),
  userRole: PropTypes.string,
  initializePracticeSession: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  isInitializing: PropTypes.bool,
  isInitialized: PropTypes.bool,
  history: PropTypes.shape(),
  practiceId: PropTypes.number,
  practiceStatus: PropTypes.number,
  totalScore: PropTypes.number,
  totalMaxScore: PropTypes.number,
  skillLevel: PropTypes.number,
  pacingGuideLessonData: PropTypes.shape(),
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const { totalScore, totalMaxScore } = usePracticeTotalScoreCalculator();
    const practiceStatus = usePracticeStatus();
    const { lessonId } = useParams();
    const { data: pacingGuideLesson } = useGetPacingGuideLessons(lessonId);
    const pacingGuideLessonData = pacingGuideLesson?.[0];
    const { 'pacing-guide-id': pacingGuideId } = useSearchParams();
    return (
      <WrappedComponent
        {...props}
        totalScore={totalScore}
        totalMaxScore={totalMaxScore}
        practiceStatus={practiceStatus}
        pacingGuideLessonData={pacingGuideLessonData}
        pacingGuideId={pacingGuideId}
      />
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(LessonPracticeCard);
