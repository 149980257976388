import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import StudentList from '../../components/CourseManagementSection/StudentList';
import {
  actions,
} from '../../reducers/sectionStudent';
import { selectors as userSelectors } from '../../reducers/user';
import {
  selectors as courseTeacherSelectors,
} from '../../reducers/courseTeachers';

import {
  actions as CourseStudentActions, selectors as CourseStudentSelectors,
} from '../../reducers/courseStudents';

const mapStateToProps = (state, { match, location }) => {
  const ownerIds = courseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find((id) => id === userId);
  const courseId = _get(match, 'params.courseId', '');
  const searchParams = new URLSearchParams(location.search);
  const isOpenInviteStudent = !!searchParams.get('isOpenInviteStudent');

  return {
    isOpenInviteStudent,
    courseId,
    isOwner,
    isChangePasswordSuccess: CourseStudentSelectors.isChangePasswordSuccess(state),
  };
};

const mapDispatchToProps = {
  sectionsStudentRemove: actions.sectionsStudentRemove,
  submitInviteStudent: actions.sectionsStudentInvite,
  sendResetPassword: CourseStudentActions.resetPassword,
  resetStudentPassword: CourseStudentActions.teacherChangePassword,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StudentList));
