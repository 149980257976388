/* eslint-disable import/prefer-default-export */
import { SxProps, Theme } from '@mui/system';

export const sxCombine = (...styles: Array<SxProps<Theme> | false>): SxProps<Theme> => {
  // check if there are no styles
  if (styles.length === 0) {
    return {};
  }
  // check if there is only one style
  if (styles.length === 1) {
    if (styles[0] === false) {
      return {};
    }
    return styles[0];
  }
  // merge all the styles by checking the type of each style
  // if it is an array, spread it, otherwise, return the style
  return styles.reduce<SxProps<Theme>[]>((acc, style) => {
    if (Array.isArray(style)) {
      return [...acc, ...style];
    }
    if (!style) {
      return acc;
    }
    return [...acc, style];
  }, [] as SxProps<Theme>[]) as SxProps<Theme>;
};
