import React from 'react';
import { Paper as MuiPaper, PaperProps as MuiPaperProps } from '@mui/material';

export interface PaperProps extends MuiPaperProps {
  children: React.ReactNode;
}

const Paper = ({ children, ...rest }: PaperProps) => (
  <MuiPaper {...rest}>{children}</MuiPaper>
);
export default Paper;
