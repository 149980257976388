import React from 'react';
import ShowIf from '@components/Atoms/ShowIf';
import GroupActivity from '@containers/GroupActivity/GroupActivity';
import AttendanceRecordButton from '@components/Component/Lesson/AttendanceRecordButton/AttendanceRecordButton';
import Button from '@components/Atoms/Button/Button';
import WorksheetsButton from '@components/Component/Lesson/WorksheetsButton/WorksheetsButton';
import InformationCard from '@components/Component/InformationCard/InformationCard';
import { useSectionStudent } from '@reducers/sectionStudent/hooks';
import { useIsTeacher } from '@reducers/user/hooks';
import ShadowScrollbar from '@components/Scrollbar/ShadowScrollbars';
import { useGetActivities } from '@components/Component/Lesson/LessonContainer/hooks';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import { useParams } from 'react-router-dom';
import PacingGuideInfoCard from '@components/Component/Lesson/PacingGuide/PacingGuideInfoCard';
import ActionCard from '../ActionCard/ActionCard';
import { useActivityGroups, useGetGroupActivityInfo } from '../hooks/query';
import useWebSocketListener from '../hooks/useWebSocketListener';
import { useCurrentGroup } from '../hooks/queryStore';
import { activityStatus, groupOLPStatus } from '../types';
import ActivityFactorySkeleton from './ActivityFactory.skeleton';

const notStartedImgUrlImg = '/images/notInitialized.svg';
const offlineActivityUrlImg = '/images/offline.svg';
const completedPracticeUrlImg = '/images/completed-practice.svg';
const absentStudentUrlImg = '/images/absent-student.svg';

const ActivityFactory = () => {
  const { lessonId } = useParams();
  const isTeacher = useIsTeacher();
  const sectionStudentList = useSectionStudent();
  const currentGroupActivity = useCurrentGroup();
  const isStudentAbsent = Object.keys(currentGroupActivity).length === 0;
  // It should be here to check and create groupActivityId.
  //  we still depend on redux- saga to create newGroupActivity records
  useGetActivities();
  const {
    data: {
      id: groupActivityId,
      number_of_questions: numberOfQuestions,
      online_activity_status: onlineActivityStatus = 0,
    },
  } = useGetGroupActivityInfo(undefined, { refetchOnMount: 'always' });
  useActivityGroups(groupActivityId, { refetchOnMount: 'always' });
  const { data: pacingGuideLesson } = useGetPacingGuideLessons(lessonId);
  const pacingGuideLessonData = pacingGuideLesson?.[0];
  useWebSocketListener();
  return (
    <ShadowScrollbar
      autoHide={false}
      style={{
        height: 'calc(100vh - 162px)',
        padding: '1.5em',
      }}
    >
      <PacingGuideInfoCard
        guideInfoText={pacingGuideLessonData?.group_activity_instruction ?? ''}
        taskDeliveryText={pacingGuideLessonData?.group_activity_delivery ?? ''}
      />
      {/* ---------------- Online Activity view --------------------- */}
      <ShowIf
        If={
          !!groupActivityId &&
          !!numberOfQuestions &&
          !(
            onlineActivityStatus === activityStatus.started &&
            currentGroupActivity.group_status === groupOLPStatus.ended
          ) &&
          !(!isTeacher && isStudentAbsent) &&
          !!sectionStudentList.length
        }
      >
        <GroupActivity />
      </ShowIf>
      {/* ---------------- Offline Activity view ---------------------*/}
      <ShowIf
        If={numberOfQuestions === 0 && !!groupActivityId && !!sectionStudentList.length}
      >
        <ActionCard
          dataTestId="action-card-offline"
          actionButtons={
            <>
              <ShowIf If={isTeacher}>
                <AttendanceRecordButton dialogTitle="Group Activity Attendance" />
              </ShowIf>
              <WorksheetsButton />
            </>
          }
          practiceInfo={null}
        />
        <InformationCard
          dataTestId="info-card-offline"
          img={offlineActivityUrlImg}
          title="No Online Practice"
          description="This lesson is designed to be completed offline, so there’s no online practice available. Dive into worksheets to get started!"
        />
      </ShowIf>
      {/* -------------- Activities not started yet by Teacher ---- */}
      <ShowIf If={!isTeacher && !groupActivityId}>
        <ActionCard
          dataTestId="action-card-non-started-activity"
          actionButtons={
            <>
              <Button
                variant="contained"
                color="play"
                data-testid="start-activity-button"
                disabled
              >
                Start Activity
              </Button>
              <WorksheetsButton />
            </>
          }
          practiceInfo={null}
        />
        <InformationCard
          dataTestId="info-card-non-started-activity"
          img={notStartedImgUrlImg}
          title="Online Group Activity Not Ready Yet"
          description="Looks like this practice isn’t ready yet. Your teacher will let you know when it’s time to begin. Check back soon!"
        />
      </ShowIf>
      {/* -------------- No students in the section ---- */}
      <ShowIf If={isTeacher && !sectionStudentList.length}>
        <ActionCard
          dataTestId="action-card-no-student"
          actionButtons={
            <>
              <Button
                variant="contained"
                color="play"
                data-testid="start-activity-button"
                disabled
              >
                Start Activity
              </Button>
              <WorksheetsButton />
            </>
          }
          practiceInfo={null}
        />
        <InformationCard
          dataTestId="info-card-no-student"
          img={notStartedImgUrlImg}
          title="No Students In This Section"
          description="Please add students to this section to get started!"
        />
      </ShowIf>
      {/* -------------- When Students finished their practice and the activity not ended yet ---- */}
      <ShowIf
        If={
          !isTeacher &&
          currentGroupActivity.group_status === groupOLPStatus.ended &&
          onlineActivityStatus === activityStatus.started
        }
      >
        <ActionCard
          dataTestId="action-card-completed-practice"
          actionButtons={<WorksheetsButton />}
          practiceInfo={null}
        />
        <InformationCard
          dataTestId="info-card-completed-practice"
          img={completedPracticeUrlImg}
          title="Group Activity Complete!"
          description="You’ll be able to review your answers once your teachers ends this practice session"
        />
      </ShowIf>
      {/* -------------- the student is absent ---- */}
      <ShowIf
        If={
          !!groupActivityId &&
          !isTeacher &&
          isStudentAbsent &&
          !!sectionStudentList.length
        }
      >
        <ActionCard
          dataTestId="action-card-absent-student"
          actionButtons={<WorksheetsButton />}
          practiceInfo={null}
        />
        <InformationCard
          dataTestId="info-card-absent-student"
          img={absentStudentUrlImg}
          title="Your Teacher Marked You Absent"
          description="Let your teacher know if you are in present in class and ready to take this practice!"
        />
      </ShowIf>
      <ShowIf
        If={
          (!groupActivityId || numberOfQuestions === undefined) &&
          isTeacher &&
          !!sectionStudentList.length
        }
      >
        <ActivityFactorySkeleton />
      </ShowIf>
    </ShadowScrollbar>
  );
};

export default ActivityFactory;
