import EventEmitter from '@utils/eventEmitter';

export enum PracticeEvents {
  GROUP_QUESTIONS_ANSWER = 'UPDATE_STUDENT_GROUP_ACTIVITY_ANSWER_v2',
  STUDENTS_FINISHED_INDIVIDUAL = 'UPDATE_STUDENT_INDIVIDUAL_ACTIVITY_ANSWER_v2',
  GROUP_QUESTION_LOCKED = 'GROUP_QUESTION_LOCK',
  GROUP_MEMBERS_CHANGED = 'GROUP_MEMBERS_CHANGED',
}

type PracticeEventEmitterType = {
  [PracticeEvents.GROUP_QUESTIONS_ANSWER]: (message: any) => void;
  [PracticeEvents.STUDENTS_FINISHED_INDIVIDUAL]: (message: any) => void;
  [PracticeEvents.GROUP_QUESTION_LOCKED]: (message: any) => void;
  [PracticeEvents.GROUP_MEMBERS_CHANGED]: (message: any) => void;
};

const PracticeEventEmitter = new EventEmitter<PracticeEventEmitterType>();
export default PracticeEventEmitter;
