/* eslint-disable import/prefer-default-export */
import { PracticeState } from '../types';

export const PracticeDuration = {
  [PracticeState.Individual]: 6,
  [PracticeState.Group]: 8,
};

export const GroupsPracticeSessionStatus = {
  ended: 2,
  inProgress: 1,
  notStarted: 0,
};

export const GroupStatus = {
  completed: 3,
  inProgressGroup: 2,
  inProgressIndividual: 1,
  notStarted: 0,
};
