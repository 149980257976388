import React from 'react';
import { BackButton } from '@components/Generals/stylesheets/Button.style';
import PracticeTimer from '../PracticeTimer';
import {
  PracticeHeaderWrap,
  PracticeHeaderLeft,
  PracticeHeaderRight,
} from './PracticeTopNavBar.style';

type Props = {
  goBack: () => void;
  onTimeOut: () => void;
  duration: number;
  startTime: Date;
};

const PracticeTopNavBar = ({ goBack, onTimeOut, duration, startTime }: Props) => (
  <PracticeHeaderWrap>
    <PracticeHeaderLeft>
      <BackButton onClick={goBack}>
        <div className="chalktalk-previous">
          <i className="path1" />
          <i className="path2" />
        </div>
        Back to Lesson
      </BackButton>
    </PracticeHeaderLeft>
    <PracticeHeaderRight>
      <PracticeTimer
        startTime={startTime}
        duration={duration}
        onTimeOut={onTimeOut}
        key={duration}
      />
    </PracticeHeaderRight>
  </PracticeHeaderWrap>
);

export default PracticeTopNavBar;
