import {
  all, call, put,
  takeLeading,
} from 'redux-saga/effects';
import * as SectionAPIs from '../../apis/sections';
import {
  GET_ALL_USER_PRACTICE_SESSION_ACTIVITY,
  GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY,
  actions as UserActivityActions,
} from '../../reducers/userActivities';

export function* fetchUserPracticeSessionActivities({
  sectionId, unitId, lessonId, subjectId, reviewId,
}) {
  try {
    const { response, error } = yield call(SectionAPIs.getUserPracticeSessionActivity, sectionId, unitId, lessonId, reviewId);
    if (error) throw error;

    const { data: userPracticeActivityList } = response;
    yield put(UserActivityActions.getAllPracticeSessionActivitiesSuccess(userPracticeActivityList));
  } catch (error) {
    yield put(UserActivityActions.getAllPracticeSessionActivitiesFailed(error.message || error));
  }
}

export function* fetchPracticeSessionReportActivities({ sectionId, unitId, lessonId }) {
  try {
    const { response, error } = yield call(SectionAPIs.getSessionPracticeSessionResponse, sectionId, unitId, lessonId);
    if (error) throw error;

    const { data: userPracticeActivityList } = response;
    yield put(UserActivityActions.getAllPracticeSessionReportActivitiesSuccess(userPracticeActivityList));
  } catch (error) {
    yield put(UserActivityActions.getAllPracticeSessionReportActivitiesFailed(error.message || error));
  }
}

export default function* userActivitiesSaga() {
  yield all([
    takeLeading(GET_ALL_USER_PRACTICE_SESSION_ACTIVITY, fetchUserPracticeSessionActivities),
    takeLeading(GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY, fetchPracticeSessionReportActivities),
  ]);
}
