import { connect } from 'react-redux';
import TeacherUnauthRoute from '../../components/routes/TeacherUnauthRoute';
import {
  selectors as AuthSelectors,
} from '../../reducers/auth';
import {
  getUserRole,
} from '../../reducers/user';
import {
  getCurrentStep,
} from '../../reducers/register';

const mapStateToProps = state => ({
  authenticated: AuthSelectors.getAuthenticatedStatus(state),
  role: getUserRole(state),
  currentStep: getCurrentStep(state),
});

export default connect(mapStateToProps)(TeacherUnauthRoute);
