import React from 'react';
import PropTypes from 'prop-types';
import Hotkeys from 'react-hot-keys';
import _isEmpty from 'lodash/isEmpty';
import {
  PracticeAnswerGridChoiceWrap,
  AnswerGridChoiceWrap,
  AnswerGridChoiceItem,
  AnswerGridChoiceColumn,
  GridItem,
  AnswerGridChoiceColumnSelected,
  ChoiceSelected,
  StudentAnswer,
} from './AnswerGridChoice.style';
import {
  AnswerChoiceCorrect,
  AnswerChoiceWrong,
} from '../AnswerMultiChoice/AnswerMultiChoice.style';
import { colData } from './AnswerChoiceData';

const ORDER_SHORTCUT = 'left, right';

class AnswerGridChoice extends React.Component {
  static propTypes = {
    selectedValue: PropTypes.array,
    shouldDisplayAnswer: PropTypes.bool,
    selectAnswer: PropTypes.func,
    score: PropTypes.object,
    isTeacher: PropTypes.bool,
    handlePrevQuestion: PropTypes.func,
    handleNextQuestion: PropTypes.func,
  }

  chooseAnswer = (columnIndex, value) => {
    const { selectAnswer = () => {}, selectedValue = [] } = this.props;
    const newValues = [...selectedValue];
    if(selectedValue[columnIndex] == value) newValues[columnIndex] = "_";
    else newValues[columnIndex] = value;
    selectAnswer(newValues.join(''));
  }

  onKeyUp = (keyName) => {
    const {
      handlePrevQuestion, handleNextQuestion,
    } = this.props;

    switch (keyName) {
      case 'left': {
        if (handlePrevQuestion) {
          handlePrevQuestion();
        }
        break;
      }
      case 'right': {
        if (handleNextQuestion) {
          handleNextQuestion();
        }
        break;
      }
      default:
        break;
    }
  }

  renderSelectedValueItem = (value, index) => {
    const valueDisplay = value !== '_' ? value : '';

    return (
      <ChoiceSelected key={`${value}${index}`}>
        <p>{valueDisplay}</p>
      </ChoiceSelected>
    );
  }

  renderGridChoiceSelected = () => {
    const { selectedValue = [] } = this.props;

    return (
      <AnswerGridChoiceColumnSelected>
        {selectedValue.map(this.renderSelectedValueItem)}
      </AnswerGridChoiceColumnSelected>
    );
  }

  renderRowItem = (columnIndex) => (row, index) => {
    const { selectedValue } = this.props;
    const { text, value } = row;
    const isNoValue = !text;
    const keyIndex = columnIndex * 12 + index;
    const isSelected = selectedValue? selectedValue[columnIndex] === value && value !== '_' : '____'; // no display empty cell

    return (
      <GridItem
        noValue={isNoValue}
        key={keyIndex}
        isSelected={isSelected}
        onClick={() => this.chooseAnswer(columnIndex, value)}
      >
        <p>{text}</p>
      </GridItem>
    );
  }

  renderColItem = (col, index) => (
    <AnswerGridChoiceColumn key={index}>
      {col.map(this.renderRowItem(index))}
    </AnswerGridChoiceColumn>
  )

  renderAnswerGrid = () => (
    <AnswerGridChoiceItem>
      {colData.map(this.renderColItem)}
    </AnswerGridChoiceItem>
  );

  renderResultStatus = () => {
    const { score, isTeacher } = this.props;
    if(!isTeacher) {
      if (score?.result == "Correct" || score?.auto) 
        return <AnswerChoiceCorrect className="chalktalk-correct" />;

      return <AnswerChoiceWrong className="chalktalk-close" />;
    }
  }

  renderStudentsAnswer = (questionData, studet_id) => {
    console.log(questionData, studet_id)
    const { userId } = this.props;
    if(userId == studet_id) return
    const studentAnswer = questionData[studet_id]
    const studentAnswerText = studentAnswer.text_answer.replaceAll('_', '')
    return(
      <StudentAnswer>
        <div className='name'>  {studentAnswer.studentName}'s answer is </div>
        <div className='answer'> {studentAnswerText} </div>
      </StudentAnswer>
    )
  }
  renderStudentsAnswers = () => {
    const { groupId, questionId } = this.props;
    let groupAnswers = JSON.parse(localStorage.getItem(`oga_practice_${groupId}_group__answers`) || '{}')
    if(!_isEmpty(groupAnswers)){
      const questionData = groupAnswers[groupId][questionId]
      const studentIds = Object.keys(questionData)
      if(studentIds.length > 0){
        return(<>
          {
            studentIds.map((studet_id) => this.renderStudentsAnswer(questionData, studet_id))
          }
        </>)
      }
    }
  }

  render() {
    const { shouldDisplayAnswer, activity} = this.props;

    return (
      <PracticeAnswerGridChoiceWrap>
        <Hotkeys
          keyName={ORDER_SHORTCUT}
          onKeyUp={this.onKeyUp}
        >
          <AnswerGridChoiceWrap>
            {this.renderGridChoiceSelected()}
            {this.renderAnswerGrid()}
          </AnswerGridChoiceWrap>
          {shouldDisplayAnswer && this.renderResultStatus()}
        </Hotkeys>
        {activity == "online-group" && this.renderStudentsAnswers()}
      </PracticeAnswerGridChoiceWrap>
    );
  }
}

export default AnswerGridChoice;
