import React, { Component } from 'react';
import { func, bool } from 'prop-types';
import { ErrorMessage } from '../../components/ReportErrorModal/ReportErrorModal.style';
import TextField from '@components/Atoms/TextField';
import Dialog from '@components/Atoms/Dialog';
import ShowIf from '@components/Atoms/ShowIf';
import Box from '@components/Atoms/Box';

export default class LessonReportModal extends Component {
  state = {
    reportMessage: '',
    slideNumber: '',
    errorMsg: '',
  };

  static propTypes = {
    isOpen: bool,
    onClose: func,
    onSubmit: func,
  };

  onChange = (e) => {
    const { value } = e.target;
    this.setState({
      reportMessage: value,
      errorMsg: '',
    });
  };

  slideOnChange = (e) => {
    const { value } = e.target;
    const numValue = Math.max(0, parseInt(value) || 0);
    this.setState({
      slideNumber: numValue.toString(),
      errorMsg: '',
    });
  };

  onSubmit = () => {
    const { onSubmit, curriculum, lessonTitle } = this.props;
    const { reportMessage, slideNumber } = this.state;
    if (reportMessage.trim() == '' || slideNumber == '') {
      const msg = 'Report message and slide number are required';
      this.setState({ errorMsg: msg });
    } else {
      const contextualizedMessage =
        reportMessage + `\nCurriculum: ${curriculum}\nLesson: ${lessonTitle}`;
      onSubmit(contextualizedMessage, slideNumber);
      this.setDefaultValue();
    }
  };

  setDefaultValue() {
    this.setState({
      reportMessage: '',
      slideNumber: '',
      errorMsg: '',
    });
  }
  onClose = () => {
    const { onClose } = this.props;
    this.setDefaultValue();
    onClose();
  };

  render() {
    const { isOpen } = this.props;
    const { errorMsg, reportMessage, slideNumber } = this.state;
    return (
      <Dialog
        title="Report Error"
        showCloseIcon
        open={isOpen}
        onClose={this.onClose}
        actions={[
          {
            label: 'Cancel',
            onClick: this.onClose,
            buttonProps: {
              color: 'primary',
              variant: 'outlined',
            },
          },
          {
            label: 'Send Error',
            onClick: this.onSubmit,
            buttonProps: {
              color: 'primary',
              variant: 'contained',
            },
          },
        ]}
      >
        <Box sx={{ minWidth: 444, display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Description"
            placeholder="Report a problem or suggest an improvement. Please include the relevant slide number and a brief description of the error"
            onChange={this.onChange}
            value={reportMessage}
            required
            variant="outlined"
            multiline
            minRows={3}
            margin="normal"
          />
          <TextField
            className="slideNumber"
            label="Slide number"
            type="number"
            min={0}
            inputProps={{
              min: 0,
              step: 1,
              onKeyDown: (e) => {
                // Prevent minus sign
                if (e.key === '-') {
                  e.preventDefault();
                }
                // Prevent decrease button when value is 0
                if (e.key === 'ArrowDown' && e.target.value === '0') {
                  e.preventDefault();
                }
              }
            }}
            onChange={this.slideOnChange}
            value={slideNumber}
          />
          <ShowIf If={!!errorMsg}>
            <ErrorMessage>{errorMsg}</ErrorMessage>
          </ShowIf>
        </Box>
      </Dialog>
    );
  }
}
