/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const AbsentRowStyle = styled.div`
  flex-flow: row;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;
`;
