import { useLearnosityQuestion } from '@learnosityassessment';
import { useCurrentCourse } from '@reducers/courses/hooks';
import { useGetStandards } from '@components/Component/Lesson/LessonContainer/hooks/query';
import React from 'react';
import { useLessonStandards } from '../../../reducers/curriculumLessons/hooks';
import { useUser } from '../../../reducers/user/hooks';
import { STANDARDS_PRACTICE_QUESTION_COUNT } from '../../constants';

export const useStandardPublication = () => {
  const courseDetails = useCurrentCourse();
  const { data: standards } = useGetStandards();
  const standardId = courseDetails?.syllabus ?? 0;
  const standardObject = React.useMemo(
    () => standards?.find((el) => el.id == standardId),
    [standardId, standards],
  );

  return standardObject?.publication_key;
};
const useLearnosityQuestionCT = ({ lessonDetail }) => {
  if (!lessonDetail) {
    console.error('lessonDetail is required');
  }
  const standards = useLessonStandards(lessonDetail);

  const currentUser = useUser();

  const standardPublication = useStandardPublication();

  const { data: questionsEn } = useLearnosityQuestion({
    lessonCode: `${lessonDetail?.id}`,
    limit: STANDARDS_PRACTICE_QUESTION_COUNT,
    publication: standardPublication,
    standards,
    user: `${currentUser.id}`,
  });
  const { data: questionsEs } = useLearnosityQuestion({
    lessonCode: `${lessonDetail?.id}`,
    limit: STANDARDS_PRACTICE_QUESTION_COUNT,
    publication: standardPublication,
    standards,
    user: `${currentUser.id}`,
    language: 'es',
  });
  return {
    hasSpanishQuestions: questionsEs?.length > 0,
    hasStandardsPractice: questionsEn?.length > 0 || questionsEs?.length > 0,
    questions: questionsEn,
    questionsEn,
    questionsEs,
  };
};
export default useLearnosityQuestionCT;
