import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router';
import {
  ExamForm as ExamFormComponent,
  LessonForm as LessonFormComponent,
  ReviewForm as ReviewFormComponent,
} from '../../components/ScheduleCalendar/ModalForm';
import { selectors as courseSelectors } from '../../reducers/courses';
import { actions as SectionExamActions, selectors as SectionExamSelectors } from '../../reducers/sectionExam';
import { selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as curriculumLessonSelectors } from '../../reducers/curriculumLessons';
import { actions as SessionActions, selectors as SessionSelectors } from '../../reducers/sectionSession';
import { actions as SectionReviewActions, selectors as SectionReviewSelectors } from '../../reducers/sectionReviews';
import { actions as SectionSubjectActions, selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';

// ===============================================

const examMapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const courseData = courseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');

  return {
    sectionId,
    curriculumId,
    isUpdating: SectionExamSelectors.isUpdating(state),
    isUpdateSuccessfully: SectionExamSelectors.isUpdateSuccessfully(state),
    errorMsg: SectionExamSelectors.getUpdateErrorMsg(state),
  };
};

const examMapDispatchToProps = {
  sectionExamUpdate: SectionExamActions.sectionExamUpdate,
  sectionExamDelete: SectionExamActions.sectionExamDelete,
};

export const ExamForm = withRouter(connect(examMapStateToProps, examMapDispatchToProps)(ExamFormComponent));

// ===============================================

const lessonMapStateToProps = (state, { initialValues, match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const { lesson: lessonId, unit: unitId, subject: subjectId } = initialValues;

  return {
    initLesson: curriculumLessonSelectors.getLessonByLessonId(state, lessonId),
    initUnit: sectionUnitSelectors.getUnitById(state, unitId),
    initSubject: SectionSubjectSelectors.getSubjectDetail(state, subjectId),

    unitBySubject: sectionUnitSelectors.getUnitBySubject(state),
    subjects: SectionSubjectSelectors.getSectionSubjectList(state),
    isUpdatingLesson: SessionSelectors.isUpdatingLesson(state),
    errorMessage: SessionSelectors.getUpdateErrorMessage(state),
    sectionId,
  };
};

const lessonMapDispatchToProps = {
  fetchSectionSubjectList: SectionSubjectActions.fetchSectionSubjectList,
  sectionsSessionUpdate: SessionActions.sectionsSessionUpdate,
  sectionSessionDelete: SessionActions.sectionSessionDelete,

};

export const LessonForm = withRouter(connect(lessonMapStateToProps, lessonMapDispatchToProps)(LessonFormComponent));

// ===============================================

const reviewMapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');

  return {
    sectionId,
    subjects: SectionSubjectSelectors.getSectionSubjectList(state),
    isUpdatingReview: SectionReviewSelectors.isUpdatingReview(state),
    errorMsg: SectionReviewSelectors.getUpdateErrorMessage(state),
  };
};

const reviewMapDispatchToProps = {
  fetchSectionSubjectList: SectionSubjectActions.fetchSectionSubjectList,
  sectionReviewUpdate: SectionReviewActions.sectionReviewUpdate,
  sectionReviewDelete: SectionReviewActions.sectionReviewDelete,
};

export const ReviewForm = withRouter(connect(reviewMapStateToProps, reviewMapDispatchToProps)(ReviewFormComponent));
