import { connect } from 'react-redux';
import _get from 'lodash/get';
import CourseDetail from '../../components/CourseDetail';
import {
  selectors as CourseSelectors,
  actions as CourseActions,
} from '../../reducers/courses';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import { selectors as userSelectors } from '../../reducers/user';
import { selectors as CourseTeacherSelectors } from '../../reducers/courseTeachers';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseDetail = CourseSelectors.getCourseById(state, courseId);
  const standardId = _get(courseDetail, 'syllabus', 0);
  const userId = userSelectors.getUserId(state);
  const ownerIds = CourseTeacherSelectors.getOwnerIds(state);
  const isOwner = !!ownerIds.find(id => id === userId);

  return ({
    isOwner,
    courseDetail: CourseSelectors.getCourseDetailById(state, match.params.courseId),
    curriculum: CurriculumSelectors.getAllCurriculums(state),
    courseStandard: CurriculumSelectors.getStandardByStandardId(state, standardId),
  });
};

const mapDispatchToProps = {
  courseUpdate: CourseActions.courseUpdate,
  courseDeactivate: CourseActions.courseDeactivate,
  getCurriculumList: CurriculumActions.getCurriculumList,
  getCurriculumStandardList: CurriculumActions.getCurriculumStandardList,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
