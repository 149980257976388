import styled, { css } from 'styled-components';

export const PracticeContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: calc(100% - 4em);
    justify-content: space-between;
  }
`;

export const ContentWrap = styled.div`
  height: calc(100% - 5.5em);
  @media (max-width: 780px) {
    height: calc(100% - 2em);
  }
`;

export const SubContentWrap = styled.div`
  height: 100%;
`;

export const PracticeContentWrap = styled.div`
  display: flex;
  position: relative;
  height: calc(100% - 2em);
  @media (max-width: 768px) {
    flex-direction: column;
    height: calc(100% - 2em);
    display: block;
    padding-bottom: 20px;
  }
`;

export const PracticeContentGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2em;
  transition: all 300ms ease;
  margin-left: 32em;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  ${({ groupActivity }) => groupActivity && css`
    overflow: visible;
    margin-left: 41em;
  `}
  @media (max-width: 1369px) {
    margin-left: 30em;
  }
  @media (max-width: 1280px) {
    display: block;
  }

  ${({ isToggleNavigation }) => isToggleNavigation && css`
    @media (max-width: 960px) {
      margin-left: 2em !important;
    }
  `}

  @media (max-width: 768px) {
    display: block;
    margin-left: unset !important;
    margin: auto;
    padding: 2em 2em 0;
  }
  @media (max-width: 500px) {
    margin-top: 1em;
    padding: 0px 2.5em;
    height: calc(100% - 1em);
  }
  ${({ block }) => block && css`
    display: block;
  `}

  ${({ assets }) => assets && css`
    @media (max-width: 768px) {
      height: calc(100% - 2.5em);
    }
`}
`;

export const DesktopOnly = styled.div`
  flex: 1;
  max-width: 36em;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

export const ScrollBarStyle = {
  width: '100%',
  height: 'calc(100vh - 10em)',
};

export const PracticeContentGroupMobile = styled.div`
  margin-top: 1em;
  padding: 0px 2.5em;
  height: calc(100% - 2.5em);
`;

export const PracticeFooterBar = styled.div`
  position: fixed;
  bottom: 0em;
  width: calc(100% - 5em);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${props => props.theme.colorStyled.ColorBgSilver};
  box-shadow: 0px -2px 6px 0px ${props => props.theme.colorStyled.ColorBoxShadow};
  @media (max-width: 960px) {
    width: calc(100% - 4em);
  }
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  width: 100%;
  overflow: auto;
  padding-left: 1.5em;
  @media (max-width: 768px) {
    margin-left: 4em;
  }
`;

export const ProgressBarCircle = styled.div`
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  border: 2px solid #ccc; 
  color: #ccc;
  background-color: ${props => props.theme.colorStyled.ColorBgSilver};
  z-index: 1;
  position: absolute;
  right: 0;
  ${({ disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
  
  ${({ isSelected }) => isSelected && css`
    border-color: ${props => props.theme.colorStyled.ColorWhite} !important;
    box-shadow: 0 0 0 2pt ${props => props.theme.colorStyled.ColorPrimaryBlue};
    transition: 300ms ease;
  `}
  @media (max-width: 768px) {
    position: unset;
    background-color: transparent;
    width: 1.25em;
    height: 1.25em;
  }
`;

export const ProgressBarLine = styled.div`
  background-color: #ccc;
  height: 2px;
  width: 100%;
`;

export const ProgressBarItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 768px) {
    cursor: pointer;
  }
  ${({ widthValue }) => widthValue && css`
    flex: 0 0 calc(100% / ${widthValue});
    &:first-child {
      flex: unset;
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    }
  `}
 
  ${({ isActive }) => isActive && css`
    ${ProgressBarCircle} {
      border-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
      color: #fff;
    }
    ${ProgressBarLine} {
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    }
  `}


  ${({ isSelected }) => isSelected && css`
    ${ProgressBarCircle} {
      border-color: ${props => props.theme.colorStyled.ColorWhite} !important;
      box-shadow: 0 0 0 2pt ${props => props.theme.colorStyled.ColorPrimaryBlue};
      transition: 300ms ease;
    }
    ${ProgressBarLine} {
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    }
  `}  
  
  ${({ isAnswered, isActive }) => isAnswered && css`
    ${ProgressBarCircle} {
      border-color: ${props => isActive ? props.theme.colorStyled.ColorWhite : props.theme.colorStyled.ColorPrimaryBlue } !important;
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue} !important;
      box-shadow: 0 0 0 2pt ${props => props.theme.colorStyled.ColorPrimaryBlue};
      transition: 300ms ease;
    }
  `}
`;

// =================== MOBILE ======================
export const PracticeContentWrapMobile = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4em);
  justify-content: space-between;
  @media (min-width: 768px) {
    & {
        display: none;
    }
  }
`;

export const PracticeFooterBarMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${props => props.theme.colorStyled.ColorBgSilver};
  box-shadow: 0px -2px 6px 0px ${props => props.theme.colorStyled.ColorBoxShadow};
  @media (min-width: 768px) {
    & {
        display: none;
    }
  }
`;

export const PBMobilePopup = styled.div`
  position: fixed;
  bottom: 3.5em;
  background-color: #fff;
  left: calc(50% - 3em);
  padding: 1em;
  display: flex;
  z-index: 2;
  ${({ hasSecondList }) => hasSecondList && css`
    left: calc(50% - 7.5em);
  `}
`;

export const PBMobileCol = styled.div`
  width: 10em;
  cursor: pointer;
`;

export const PBMobileItem = styled.div`
  display: flex;
  padding: 1em;
  justify-content: space-around;
  ${({ isActive }) => isActive && css`
    ${ProgressBarCircle} {
      border-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
      color: #fff;
    }
  `}
`;

export const PBMobileItemContent = styled.div`
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
`;

export const PBMobileSelectedItem = styled.div`
  width: 10em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor:pointer;
`;
