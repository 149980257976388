import React from 'react';
import ReactGoogleSlides from 'react-google-slides';
import { PresentationSlideContainer, SlideOverlay } from './PresentationManagement.style';

const PresentationSlide = (props) => {
  const { presentation, position } = props;

  return (
    <PresentationSlideContainer>
      <SlideOverlay />
      <ReactGoogleSlides
        width={500}
        height={300}
        slidesLink={`https://docs.google.com/presentation/d/${presentation}`}
        position={position}
        showControls={false}
        loop
      />
    </PresentationSlideContainer>
  );
};

export default PresentationSlide;
