/* eslint-disable eqeqeq */
/* eslint-disable sort-keys */
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import usePersistReducer from '@utils/hooks/usePersistReducer';
import practiceReducer, { initialState, PracticeActions } from './practiceReducer';
import { GroupAnswerCombined, PracticeState, questionType, stateType } from '../types';
import {
  useOLPSubmit,
  usePostGroupAnswer,
  usePostIndividualAnswer,
  useMoveGroupAnswer,
} from '../hooks/mutation';
import { useGroupMembers, usePracticeData, useQuestions } from './PracticeDataProvider';
import {
  checkAbilityToMoveToNextQuestion,
  createGroupAnswers,
  getCurrentAnswer,
  getGroupQuestionAnswers,
  margeUserToAnswers,
  createGroupAnswersForGroupOfOneUser,
} from '../utils/helpers';
import useWebSocketListener from '../hooks/useWebSocketListener';
import usePracticeGoBack from '../hooks/usePracticeGoBack';

type PracticeContextType = {
  handleNextButton: () => void;
  isAbleToMoveNext: boolean;

  isReview: boolean;
  isPreview: boolean;
  currentQuestion?: questionType;
  isLastQuestion: boolean;
  // eslint-disable-next-line no-unused-vars
  handleAnswerChange: (data: string | number) => void;
  currentAnswer?: string | number;
  groupQuestionAnswers: Array<GroupAnswerCombined>;
  state: stateType;
  handleGroupInstructionDialogClose: () => void;
  handleTimeOut: () => void;
  isQuestionLocked: boolean;
};

export const PracticeContext = React.createContext<PracticeContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars, no-unused-vars
  handleAnswerChange: (data: string | number) => {},
  isLastQuestion: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleNextButton: () => {},
  isAbleToMoveNext: false,
  isPreview: false,
  isReview: false,
  // currentQuestion: {},
  groupQuestionAnswers: [],
  state: initialState(),
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleGroupInstructionDialogClose: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleTimeOut: () => {},
  isQuestionLocked: false,
});

type Props = {
  children: React.ReactNode | React.ReactNode[];
  isPreview?: boolean;
  isReview?: boolean;
};

const PracticeProvider = ({ children, isPreview = false, isReview = false }: Props) => {
  const { groupId, userId, onlineResponseId } = useParams();
  const { initialGroupStatus, startedDate, initialLockedQuestions, initialGroupAnswers } =
    usePracticeData();
  const [state, dispatch, clearStateCache] = usePersistReducer(
    practiceReducer,
    initialState(),
    `PracticeProvider_${groupId}_${startedDate}`,
  );
  const postGroupAnswer = usePostGroupAnswer();
  const postIndividualAnswer = usePostIndividualAnswer();
  const moveGroupAnswer = useMoveGroupAnswer();
  const submitOLPScore = useOLPSubmit({ groupId, onSuccessfulSubmit: clearStateCache });
  const groupMembers = useGroupMembers();
  const questions = useQuestions();

  const practiceGoBack = usePracticeGoBack();
  // ---------------------------
  const currentQuestion = React.useMemo(
    () => questions.find((question) => question.index === state.currentQuestionOrder),
    [questions, state.currentQuestionOrder],
  );

  const isQuestionLocked = React.useMemo(() => {
    // for sure it is there , just to make TS satisfied
    if (!currentQuestion) {
      return false;
    }
    // return current question if it is locked or not
    return !!state.lockedQuestions[currentQuestion.id];
  }, [currentQuestion, state.lockedQuestions]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAnswerChange = useCallback(
    (val: string | number) => {
      // for sure it is there , just to make TS satisfied
      if (!currentQuestion) {
        return;
      }
      // Do not execute if the question is locked
      if (isQuestionLocked) {
        return;
      }
      if (state.practiceState == PracticeState.Group) {
        postGroupAnswer.mutate({
          question_id: currentQuestion.id,
          answer: val,
          group_id: groupId,
          student_id: userId,
        });
      } else {
        dispatch({
          type: PracticeActions.SET_ANSWER,
          payload: { val, questionId: currentQuestion.id },
        });
      }
    },
    [currentQuestion, state.practiceState, isQuestionLocked],
  );

  const isLastQuestion = React.useMemo(() => {
    if (state.currentQuestionOrder === questions.length - 1) {
      return true;
    }
    return false;
  }, [state.currentQuestionOrder, questions]);

  const currentAnswer = React.useMemo(
    () =>
      getCurrentAnswer({
        currentQuestion,
        state,
        userId,
      }),
    [currentQuestion, state.practiceAnswers, state.groupAnswers, state.practiceState],
  );

  const groupQuestionAnswers = React.useMemo(() => {
    const answers = getGroupQuestionAnswers({
      currentQuestion,
      state,
    });
    const answersWithUser = margeUserToAnswers({
      answers,
      groupMembers,
    });
    return answersWithUser;
  }, [currentQuestion, state.groupAnswers]);

  const isAbleToMoveNext = React.useMemo(
    () =>
      checkAbilityToMoveToNextQuestion({
        currentQuestion,
        state,
        groupQuestionAnswers,
        groupMembers,
      }),
    [
      currentQuestion,
      state.practiceAnswers,
      state.practiceState,
      groupQuestionAnswers,
      groupMembers,
    ],
  );

  const handleGroupInstructionDialogClose = React.useCallback(() => {
    dispatch({ type: PracticeActions.SET_GROUP_INSTRUCTION_STATE, payload: false });
  }, []);

  const handleTimeOut = React.useCallback(() => {
    if (state.practiceState == PracticeState.Individual) {
      postIndividualAnswer.mutate({
        userId,
        groupId,
        onlineResponseId,
        payload: Object.values(state.practiceAnswers),
      });
      // if there is only one user in the group, we don't want group mode
      if (groupMembers.length === 1) {
        const payload = createGroupAnswersForGroupOfOneUser({
          groupMembers,
          questions,
          userId,
          groupId,
          individualAnswers: Object.values(state.practiceAnswers),
        });
        submitOLPScore.mutate(payload);
        practiceGoBack({
          showModal: true,
        });
        return;
      }
      dispatch({
        type: PracticeActions.SET_PRACTICE_STATE,
        payload: PracticeState.Group,
      });
      dispatch({
        type: PracticeActions.SET_QUESTION_ORDER,
        payload: 0,
      });
    } else if (state.practiceState == PracticeState.Group) {
      const payload = createGroupAnswers({
        groupMembers,
        questions,
        userId,
        groupId,
        groupAnswers: state.groupAnswers,
        individualAnswers: Object.values(state.practiceAnswers),
      });
      submitOLPScore.mutate(payload);
      practiceGoBack({
        showModal: true,
      });
    }
  }, [state, groupId]);

  const handleNextButton = React.useCallback(() => {
    if (!isAbleToMoveNext) {
      return;
    }
    // last question in individual mode
    if (
      state.currentQuestionOrder === questions.length - 1 &&
      state.practiceState == PracticeState.Individual
    ) {
      postIndividualAnswer.mutate({
        userId,
        groupId,
        onlineResponseId,
        payload: Object.values(state.practiceAnswers),
      });
      // if there is only one user in the group, we don't want group mode
      if (groupMembers.length == 1) {
        const payload = createGroupAnswersForGroupOfOneUser({
          groupMembers,
          questions,
          userId,
          groupId,
          individualAnswers: Object.values(state.practiceAnswers),
        });
        submitOLPScore.mutate(payload);
        practiceGoBack({
          showModal: true,
        });
        return;
      }
      dispatch({ type: PracticeActions.SET_INDIVIDUAL_WAITING_STATE, payload: true });
      return;
    }

    if (state.practiceState == PracticeState.Group) {
      const groupAnswer = state.groupAnswers.find(
        (q) => q.student_id == userId && q.question_id == currentQuestion?.id,
      );

      if (groupAnswer) {
        moveGroupAnswer.mutate(groupAnswer);
      }

      // last question in group mode
      if (state.currentQuestionOrder === questions.length - 1) {
        const payload = createGroupAnswers({
          groupMembers,
          questions,
          userId,
          groupId,
          groupAnswers: state.groupAnswers,
          individualAnswers: Object.values(state.practiceAnswers),
        });
        submitOLPScore.mutate(payload, groupId);
        practiceGoBack({
          showModal: true,
        });
      }
    }

    // individual next
    if (state.currentQuestionOrder < questions.length) {
      dispatch({ type: PracticeActions.NEXT_QUESTION });
    }
  }, [isAbleToMoveNext, state.currentQuestionOrder, state.practiceState]);

  // ---------------------------
  useWebSocketListener(dispatch);

  useEffect(() => {
    if (initialGroupStatus == PracticeState.Group) {
      dispatch({
        type: PracticeActions.SET_PRACTICE_STATE,
        payload: PracticeState.Group,
      });
    }
  }, [initialGroupStatus]);

  // handle the logic when student finished individual mode
  useEffect(() => {
    if (
      state.studentsFinishedIndividual.length == groupMembers.length &&
      state.practiceState == PracticeState.Individual
    ) {
      dispatch({
        type: PracticeActions.SET_QUESTION_ORDER,
        payload: 0,
      });
      dispatch({
        type: PracticeActions.SET_PRACTICE_STATE,
        payload: PracticeState.Group,
      });
      dispatch({ type: PracticeActions.SET_INDIVIDUAL_WAITING_STATE, payload: false });
    }
  }, [state.studentsFinishedIndividual]);

  useEffect(() => {
    if (state.practiceState == PracticeState.Group) {
      dispatch({ type: PracticeActions.SET_GROUP_INSTRUCTION_STATE, payload: true });
    }
  }, [state.practiceState]);
  useEffect(() => {
    if (Object.keys(initialLockedQuestions).length) {
      dispatch({
        type: PracticeActions.SET_LOCKED_GROUP_QUESTION,
        payload: initialLockedQuestions,
      });
    }
  }, [Object.keys(initialLockedQuestions).length]);
  useEffect(() => {
    if (Object.keys(initialGroupAnswers).length) {
      dispatch({
        type: PracticeActions.SET_GROUP_ANSWER,
        payload: initialGroupAnswers,
      });
    }
  }, [initialGroupAnswers.length]);

  const contextValue = React.useMemo(
    () => ({
      handleNextButton,
      isAbleToMoveNext,
      isReview,
      isPreview,
      currentQuestion,
      handleAnswerChange,
      isLastQuestion,
      state,
      currentAnswer,
      groupQuestionAnswers,
      handleGroupInstructionDialogClose,
      handleTimeOut,
      isQuestionLocked,
    }),
    [
      handleNextButton,
      isAbleToMoveNext,
      isReview,
      isPreview,
      currentQuestion,
      handleAnswerChange,
      isLastQuestion,
      state,
      groupQuestionAnswers,
      currentAnswer,
      handleGroupInstructionDialogClose,
      handleTimeOut,
      isQuestionLocked,
    ],
  );
  return (
    <PracticeContext.Provider value={contextValue}>{children}</PracticeContext.Provider>
  );
};

export default React.memo(PracticeProvider);

export const usePractice = () => {
  const context = React.useContext(PracticeContext);
  if (context === undefined) {
    throw new Error('usePractice must be used within a PracticeContext');
  }
  return context;
};

export const useQuestion = () => {
  const context = React.useContext(PracticeContext);
  if (context === undefined) {
    throw new Error('useQuestion must be used within a QuestionProvider');
  }
  return context.currentQuestion;
};

export const useGroupQuestionAnswers = () => {
  const context = React.useContext(PracticeContext);

  if (context === undefined) {
    throw new Error('useGroupQuestionAnswers must be used within a QuestionProvider');
  }
  return context.groupQuestionAnswers || [];
};

export const useIsQuestionLocked = () => {
  const context = React.useContext(PracticeContext);

  if (context === undefined) {
    throw new Error('useIsQuestionLocked must be used within a QuestionProvider');
  }
  return context.isQuestionLocked || false;
};
