import React from 'react';
import Box from '@components/Atoms/Box';
import Timer from '@components/Atoms/Timer';
import { getFormattedDuration } from '@components/Atoms/Timer/Timer.utils';
import ShowIf from '@components/Atoms/ShowIf';
import PropertiesInfo from '@components/Atoms/PropertiesInfo';
import { useIsTeacher } from '@reducers/user/hooks';
import Button from '@components/Atoms/Button';
import { baseBoxStyle } from './PracticeInfo.style';
import {
  useCurrentGroup,
  useCurrentStudent,
  useGroupsScoreAndSubmissions,
  useIsAllActivityGroupsCompleted,
} from '../hooks/queryStore';
import { activityStatus } from '../types';
import { useGetGroupActivityInfo } from '../hooks/query';
import { useResetActivity } from '../Provider/ResetActivityProvider';
import { useEndActivitySession } from '../Provider/EndSessionProvider';

/**
 * A component that displays information about a practice session, including number of questions,
 * duration, student score, group score, number of submissions, and average score for groups.
 * @return - A box element containing the practice session information.
 */

const PracticeInfo = () => {
  const { handleEndSession } = useEndActivitySession();

  const isTeacher = useIsTeacher();
  const {
    data: {
      duration = 0,
      time_activity_started: timeActivityStarted,
      number_of_questions: numberOfQuestions,
      online_activity_status: onlineActivityStatus = 0,
    },
  } = useGetGroupActivityInfo();
  const currentGroupActivity = useCurrentGroup();
  const currentActivityStudent = useCurrentStudent(currentGroupActivity);
  const durationInSeconds = duration * 60;
  const { numberOfSubmission, percentile } = useGroupsScoreAndSubmissions();
  const { handleResetActivity } = useResetActivity();
  const isAllActivityGroupsCompleted = useIsAllActivityGroupsCompleted();

  return (
    <Box sx={[baseBoxStyle.WrapperFlex, { pt: { xs: 2, sm: 0, md: 0 } }]}>
      {/* ----------------- Number of Questions and Duration view ----------------- */}
      <ShowIf
        If={isTeacher || (!isTeacher && onlineActivityStatus !== activityStatus.ended)}
      >
        <PropertiesInfo
          dataTestId="questions-num"
          property="Number Of Questions"
          value={numberOfQuestions}
          defaultValue="0"
        />
        {/* To check if duration and timeActivityStarted not undefined to be "ts satisfied" */}
        {!!duration && !!timeActivityStarted && (
          <PropertiesInfo
            dataTestId="duration"
            property="Duration"
            value={
              onlineActivityStatus === activityStatus.started ? (
                <Timer
                  duration={durationInSeconds}
                  startTime={timeActivityStarted}
                  onTimeOut={() => handleEndSession()}
                />
              ) : (
                getFormattedDuration(durationInSeconds)
              )
            }
            defaultValue="0"
          />
        )}
      </ShowIf>
      {/* ------------------------- Student Score and Group score / Student View ------------------------- */}
      <ShowIf If={!isTeacher && onlineActivityStatus === activityStatus.ended}>
        <PropertiesInfo
          dataTestId="student-score"
          property="Your Score"
          value={currentActivityStudent?.score}
          defaultValue="0"
        />
        <PropertiesInfo
          dataTestId="group-score"
          property="Group Score"
          value={currentGroupActivity?.score}
          defaultValue="0"
        />
      </ShowIf>
      {/* ------------------------ Submissions Count and Score / Teacher View ----------------------- */}
      <ShowIf If={isTeacher}>
        <PropertiesInfo
          dataTestId="submissions"
          property="Submissions"
          value={numberOfSubmission}
          defaultValue="0"
        />
        {/* -- upon PM request we will hide it for now --- */}
        {/* <PropertiesInfo
          dataTestId="percentile"
          property="Score"
          value={`${percentile}%`}
          defaultValue="0"
        /> */}
      </ShowIf>
      <ShowIf If={isTeacher && isAllActivityGroupsCompleted}>
        <Button data-testid="reset-activity-btn" onClick={() => handleResetActivity()}>
          Reset Activity
        </Button>
      </ShowIf>
    </Box>
  );
};

export default PracticeInfo;
