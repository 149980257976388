import { connect } from 'react-redux';
import ProfileEditForm from '../../components/ProfileEditForm';
import {
  selectors as UserSelectors,
  actions as UserActions,
} from '../../reducers/user';

const mapStateToProps = (state) => ({
  isEditingProfile: UserSelectors.isEditingProfile(state),
  user: UserSelectors.getCurrentUser(state),
});

const mapDispatchToProps = {
  submitUserEdit: UserActions.submitUserEdit,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditForm);
