/* eslint-disable import/prefer-default-export */
import { ROLE_TYPE } from '../../utils/enums';

export const handleExamsBannerLogic = ({
  exams,
  location,
  userRole,
  hasExamV2Access,
  showPaths,
  setShowBanner,
  setBannerText,
  setExamId,
}) => {
  if (!hasExamV2Access) {
    setShowBanner(false);
    return;
  }

  const currentPath = location.pathname;
  const shouldShowBanner = showPaths.some((path) => currentPath.startsWith(path));

  if (!shouldShowBanner) {
    setShowBanner(false);
    return;
  }

  const currentDate = new Date();
  const sortedExams = [...exams].sort(
    (a, b) => new Date(a.practice_test_order) - new Date(b.practice_test_order),
  );

  // This is used to determine which condition is currently matched and whether a condition with higher precedence should be shown
  // instead of the current matched condition. The reason no condition matched is the highest value is to make the conditions easier to read
  // 1: assigned testlet
  // 2: unassigned testlet
  // 3: upcoming exam
  // 4: no condition matched
  const defaultAcc = { bannerConditionPrecedence: 4, bannerText: null, examId: null };
  const { bannerText, examId, bannerConditionPrecedence } = sortedExams.reduce(
    (acc, exam) => {
      const examStartDate = new Date(exam.start_date);
      const daysSinceExam = (currentDate - examStartDate) / (1000 * 3600 * 24);

      if (daysSinceExam > 14) {
        return acc;
      }

      if (userRole === ROLE_TYPE.INSTRUCTOR) {
        const { assignedTestlet, unassignedTestlet, allExamsCompleted } =
          exam.session_metadata.sections.reduce(
            (sectionAcc, section) => {
              if (!section.is_complete) {
                if (section.section_status !== 'Locked') {
                  sectionAcc.assignedTestlet = true;
                } else {
                  sectionAcc.unassignedTestlet = true;
                }
                sectionAcc.allExamsCompleted = false;
              }
              return sectionAcc;
            },
            { assignedTestlet: false, unassignedTestlet: false, allExamsCompleted: true },
          );

        const isUpcomingExam = daysSinceExam >= -3 && daysSinceExam < 0;
        const examHasStarted = daysSinceExam >= 0;

        if (assignedTestlet && acc.bannerConditionPrecedence > 1) {
          return {
            bannerConditionPrecedence: 1,
            bannerText:
              'You have students that have not completed their assigned Testlets. Please make sure all students complete their testlets before continuing to use ChalkTalk',
            examId: exam.id,
          };
        }
        if (examHasStarted && unassignedTestlet && acc.bannerConditionPrecedence > 2) {
          return {
            bannerConditionPrecedence: 2,
            bannerText:
              'Scheduled practice tests have begun and you have unassigned Testlets. Please have your students complete all testlets before continuing to use ChalkTalk',
            examId: exam.id,
          };
        }
        if (isUpcomingExam && !allExamsCompleted && acc.bannerConditionPrecedence > 3) {
          return {
            bannerConditionPrecedence: 3,
            bannerText: 'There are upcoming Practice Tests',
            examId: exam.id,
          };
        }
      }

      if (userRole === ROLE_TYPE.STUDENT && acc.bannerConditionPrecedence > 1) {
        const assignedTestlets = exam.session_metadata.sections.some(
          (section) => section.section_status === 'Unlocked' && !section.is_complete,
        );
        if (assignedTestlets) {
          return {
            bannerConditionPrecedence: 1,
            bannerText:
              'Your Teacher has assigned you Practice Testlets. Please complete all assigned Testlets before continuing to use ChalkTalk',
            examId: exam.id,
          };
        }
      }

      return acc;
    },
    defaultAcc,
  );

  if (bannerConditionPrecedence != 4) {
    setBannerText(bannerText);
    setExamId(examId);
  }

  setShowBanner(bannerConditionPrecedence != 4);
};
