import {
  call, put, takeLeading,
  takeEvery,
} from 'redux-saga/effects';
import * as SectionAPI from '../../apis/sections';

import {
  SECTIONS_TEACHER_REMOVE,
  SECTIONS_TEACHER_GET,
  SECTIONS_TEACHER_INVITE,
  actions as SectionStudentActions,
} from '../../reducers/sectionTeachers';

/**
 * ! Need update callback
 */
function* sectionsTeachersRemove({ sectionId, teacherId }) {
  const { response, error } = yield call(SectionAPI.removeTeacherFromSection, sectionId, teacherId);

  if (response) {
    yield put(SectionStudentActions.sectionsTeachersRemoveSuccess(sectionId, teacherId));
  } else {
    yield put(SectionStudentActions.sectionsTeachersRemoveFail(error));
  }
}

/**
 * ! Need update callback
 * ERROR API  405
 */
function* sectionsTeachersAdd({ teacherId, sectionId, teacherInfo }) {
  try {
    const { error } = yield call(SectionAPI.addTeacherToSection, sectionId, teacherId);
    if (error) throw error;
    yield put(SectionStudentActions.sectionsTeachersAddSuccess(teacherId, sectionId, teacherInfo));
  } catch (error) {
    yield put(SectionStudentActions.sectionsTeachersAddFail(error.message || error));
  }
}

/**
 * ! Need update callback
 */
function* sectionsTeachersGet({ sectionId }) {
  const { response, error } = yield call(SectionAPI.getSectionTeacher, sectionId);

  if (response) {
    const { data = [] } = response;
    yield put(SectionStudentActions.sectionsTeachersGetSuccess(data, sectionId));
  } else {
    yield put(SectionStudentActions.sectionsTeachersGetFail(error));
  }
}

export default function* sectionTeacherSaga() {
  yield takeEvery(SECTIONS_TEACHER_REMOVE, sectionsTeachersRemove);
  yield takeLeading(SECTIONS_TEACHER_GET, sectionsTeachersGet);
  yield takeLeading(SECTIONS_TEACHER_INVITE, sectionsTeachersAdd);
}
