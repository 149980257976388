import React, { useEffect } from 'react';
import EventEmitter from '@utils/eventEmitter';
import { QueryClient, useQueryClient } from 'react-query';

export const queryClient = new QueryClient();

type QueryClientEventEmitterType = {
  invalidateQueries: (message: any) => void;
  getQuery: (key: any) => any;
};

export const QueryClientEmitter = new EventEmitter<QueryClientEventEmitterType>();
const ReactQueryClientHelper = () => {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const queryClient = useQueryClient();
  useEffect(() => {
    const invalidateQueries = (message: any) => {
      queryClient.invalidateQueries(message);
    };
    const getQuery = (key: any) => queryClient.getQueryData(key);
    QueryClientEmitter.on('invalidateQueries', invalidateQueries);
    QueryClientEmitter.on('getQuery', getQuery);
    return () => {
      QueryClientEmitter.off('invalidateQueries', invalidateQueries);
      QueryClientEmitter.off('getQuery', getQuery);
    };
  }, [queryClient]);

  return null;
};
export default ReactQueryClientHelper;
