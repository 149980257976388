/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/constants';
import ColumnSize from './ColumnSize';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import {
  TableContainerScrollableWrap,
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'Student',
  },
  {
    uniqKey: 'lessonAttendancePercent',
    value: 'Lessons Attendance Rate',
  },
  {
    uniqKey: 'groupAttendancePercent',
    value: 'Group Activity Attendance Rate',
  },
  {
    uniqKey: 'lastActive',
    value: 'Last Seen',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class DashboardAttendanceReportTable extends Component {
  static propTypes = {
    lessonAttendanceList: PropTypes.arrayOf(
      PropTypes.shape({
        activity: PropTypes.string,
        lesson: PropTypes.number,
        section: PropTypes.number,
        recorded_by: PropTypes.number,
        recorded: PropTypes.string,
        records: PropTypes.array,
      }),
    ).isRequired,
    groupActivityAttendanceList: PropTypes.arrayOf(
      PropTypes.shape({
        activity: PropTypes.string,
        lesson: PropTypes.number,
        section: PropTypes.number,
        recorded_by: PropTypes.number,
        recorded: PropTypes.string,
        records: PropTypes.array,
      }),
    ).isRequired,
    studentList: PropTypes.array,
    isGettingStudentList: PropTypes.bool,
  };

  state = {
    isPopupOpen: false,
    data: [],
    sortBy: 'last_name',
    orderBy: ORDER_BY.ASC,
  };

  componentDidUpdate(prevProps) {
    const {
      lessonAttendanceList: prevSectionAttendanceList,
      groupActivityAttendanceList: prevGroupActivityAttendanceList,
      studentList: prevStudentList,
    } = prevProps;
    const { lessonAttendanceList, groupActivityAttendanceList, studentList } = this.props;

    if (
      (lessonAttendanceList !== prevSectionAttendanceList ||
        groupActivityAttendanceList !== prevGroupActivityAttendanceList) &&
      (lessonAttendanceList || groupActivityAttendanceList)
    ) {
      this.calculatePercent(
        lessonAttendanceList,
        groupActivityAttendanceList,
        studentList,
      );
    }
    if (studentList !== prevStudentList && studentList) {
      this.calculatePercent(
        lessonAttendanceList,
        groupActivityAttendanceList,
        studentList,
      );
    }
  }

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  calculatePercent = (lessonAttendanceList, groupActivityAttendanceList, studentList) => {
    const data = studentList.map((student) => {
      const { last_name: lastName, first_name: firstName, id } = student;
      let time = '--';
      const { attendancePercent: lessonAttendancePercent, time: lessonTime } =
        this.calculateStudentAttendancePercent(lessonAttendanceList, id);
      const {
        attendancePercent: groupActivityAttendancePercent,
        time: groupActivityTime,
      } = this.calculateStudentAttendancePercent(groupActivityAttendanceList, id);

      if (lessonTime && groupActivityTime) {
        if (moment(lessonTime).isSameOrAfter(groupActivityTime)) {
          time = lessonTime;
        } else {
          time = groupActivityTime;
        }
      } else {
        time = lessonTime || groupActivityTime || time;
      }

      return {
        id,
        studentName: `${firstName} ${lastName}`,
        last_name: lastName,
        lastActive: time,
        lessonAttendancePercent,
        groupAttendancePercent: groupActivityAttendancePercent,
      };
    });
    this.setState({ data });
  };

  calculateStudentAttendancePercent = (attendanceList, studentID) => {
    /**
     * [
    {
        "section": 56940,
        "lesson": 5203,
        "activity": "lesson",
        "recorded_by": 123649,
        "records": [
            {
                "student": 123666,
                "present": true,
                "recorded": "2024-10-30T04:42:26.421573-04:00"
            },
            {
                "student": 124405,
                "present": true,
                "recorded": "2024-10-30T04:42:28.487786-04:00"
            }
        ]
    }
]
     */
    let time;
    let count = 0;
    let absent = 0;
    let attendancePercent = 0;
    let attendanceListSize = attendanceList.length;
    if (attendanceList.length) {
      for (let i = 0; i < attendanceList.length; i++) {
        // check records
        const { records = [] } = attendanceList[i];
        const studentRecords = records.filter((r) => r.student === studentID) || [];
        if (!records.length === 0 || studentRecords.length === 0) {
          attendanceListSize--;
          continue;
        }
        // eslint-disable-next-line no-loop-func
        studentRecords.forEach((attendance) => {
          const { recorded, present } = attendance;
          if (present === null) {
            return;
          }
          const recordedDate = moment(recorded);
          count = present ? count + 1 : count;
          absent = present === false ? absent + 1 : absent;

          if (present && (!time || recordedDate.isAfter(time))) {
            time = recordedDate;
          }
        });
      }
      if (attendanceListSize > 0 && (count > 0 || absent > 0)) {
        attendancePercent = Math.round((count / (count + absent)) * 100);
      } else {
        attendancePercent = 0;
      }
    }
    return { attendancePercent, time: time?.format(DATE_FORMAT.DATE_2) };
  };

  renderAttendanceReportTableHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderAttendanceReportTableHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderAttendanceReportTableHeaderItem)}
    </TableHeadWrapper>
  );

  renderAttendanceReportTableContent = (values, index) => (
    <TableContentItem key={index}>
      <TableContent {...ColumnSize[0]}>{values.studentName}</TableContent>
      <TableContent {...ColumnSize[1]}>
        {`${values.lessonAttendancePercent}%`}
      </TableContent>
      <TableContent {...ColumnSize[2]}>
        {`${values.groupAttendancePercent}%`}
      </TableContent>
      <TableContent {...ColumnSize[3]}>{values.lastActive}</TableContent>
    </TableContentItem>
  );

  render() {
    const { data, orderBy, sortBy } = this.state;
    const { isGettingStudentList } = this.props;
    firstSort(data);
    const filterAttendanceData = _orderBy(
      data,
      [
        (d) => {
          if (sortBy === 'last_name') {
            return d[sortBy].toUpperCase();
          }
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    if (isGettingStudentList) {
      return <TableLoader />;
    }

    return (
      <TableContainerScrollableWrap>
        <TableContainerWrap scrollable>
          {this.renderAttendanceReportTableHeader()}
          <TableContentWrapper>
            {filterAttendanceData.map(this.renderAttendanceReportTableContent)}
          </TableContentWrapper>
        </TableContainerWrap>
      </TableContainerScrollableWrap>
    );
  }
}
