import styled from 'styled-components';

export const PracticeAnswerShortTextWrap = styled.div`
    display: flex;
    align-content: center;
    padding: 1em 0em;
    border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorder};
`;


export const AnswerShortTextContent = styled.div`
    display: flex;
    align-items: center;
    margin-right: .5em;
`;

export const AnswerShortTextLabel = styled.p`
    span {
        font-weight: bold;
        font-style: italic;
        margin-right: 2px;
    }
`;

export const AnswerShortTextCorrect = styled.i`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    font-size: ${props => props.theme.fontSize.XLargeFontSize};
`;

export const StudentAnswer = styled.div`
  display: flex;
  margin-top: 10px;
  .name{
    margin-right: 1em;
  }
  .answer{
    font-size: 15px;
  }
`;

export const AnswerShortTextIncorrect = styled(AnswerShortTextCorrect)`
  color: ${props => props.theme.colorStyled.ColorRedPoint};
`;