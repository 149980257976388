/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import _isArray from 'lodash/isArray';
import * as Yup from 'yup';
import moment from 'moment';
import FormInput from '../../Generals/FormInput/FormInput';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
  ButtonWrapper,
  ButtonStyled,
  InviteTeachersText,
  ExamSessionTypeContext,
  FreeExperienceWrapper,
  ExamFieldWrapper,
} from './SetupYourCourse.style';
import { GroupTitle } from '../SectionCreationModal/SectionCreationModal.style';
import {
  RegisterErrorMessage,
} from '../../StudentRegister/StudentRegister.style';
import { SUBSCRIPTION_TYPE } from '../../../utils/enums';

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const validationSchema = Yup.object().shape({
  country: Yup.object().required('Required'),
  courseId: Yup.string().trim().required('Required'),
  examType: Yup.string().trim().required('Required'),
  schoolName: Yup.string().trim().required('Required'),
  district: Yup.object().test({
    name: 'test-district',
    message: 'Required',
    test(value) {
      const country = this.resolve(Yup.ref('country'));
      const countryCode = _get(country, 'code', null);

      if (countryCode !== 'US') {
        return true;
      }

      return !!value;
    },
  }),
  standardId: Yup.string().trim().required('Required'),
});

const validationSchemaNoExam = Yup.object().shape({
  country: Yup.object().required('Required'),
  courseId: Yup.string().trim().required('Required'),
  schoolName: Yup.string().trim().required('Required'),
  district: Yup.object().test({
    name: 'test-district',
    message: 'Required',
    test(value) {
      const country = this.resolve(Yup.ref('country'));
      const countryCode = _get(country, 'code', null);

      if (countryCode !== 'US') {
        return true;
      }

      return !!value;
    },
  }),
  standardId: Yup.string().trim().required('Required'),
});

class SetupYourCourse extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      hasExam: false,
      curriculumSelected: null
    };

    this.debouncedHandleInputChange = _debounce(this.handleInputChange, 500);
  }

  componentDidMount() {
    const { fetchCountryList, getCurriculumList, getCurriculumStandardList } = this.props;

    fetchCountryList();
    getCurriculumList();
    getCurriculumStandardList();
  }

  handleInputChange = (values) => {
    const { fetchDistrictList } = this.props;
    fetchDistrictList(values);
  }

  handleCurriculumOnChange = (value, formProps) => {
    const curriculum = value
    const curriculumHasExam = curriculum.has_exam
    const { values } = formProps;
    const { examType } = values;
    let examTypeValue = examType
    if(curriculumHasExam){
      const { getCurriculumExitType, getCurriculumPlacementType } = this.props;
      getCurriculumPlacementType(curriculum.id);
      getCurriculumExitType(curriculum.id);
    }else{
      examTypeValue = ""
    }

    const newValues = {
      ...values,
      courseId: value,
      examType: examTypeValue,
    };
    formProps.setValues(newValues);
    
    this.setState({
      hasExam: curriculumHasExam,
      curriculumSelected: curriculum
    })
  }

  handleCountryOnChange = (value, formProps) => {
    const { values } = formProps;
    const newValues = {
      ...values,
      country: value,
      district: '',
    };

    formProps.setValues(newValues);
  }

  renderFieldCountry = (fieldProps) => {
    const {
      field, options, label, form,
    } = fieldProps;
    const { name: fieldName } = field;
    return (
      <FormInput
        name={fieldName}
        type="select"
        placeholder={label}
        options={options}
        getOptionValue={(option) => option.code}
        getOptionLabel={(option) => option.name}
        onChange={(value) => this.handleCountryOnChange(value, form)}
      />
    );
  }

  renderFieldDistrict = (fieldProps) => {
    const {
      form, field, options, label,
    } = fieldProps;
    const { name: fieldName } = field;
    const { values: { country } } = form;
    const isValue = country !== '';
    const countryCode = _get(country, 'code', null);
    const isUSCountry = countryCode === 'US';
    return (
      <FormInput
        name={fieldName}
        type="select"
        disabled={!isUSCountry}
        placeholder={label}
        options={options}
        isDisabled={!isValue && fieldName === 'district'}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => `${option.name} - ${option.state}`}
        onInputChange={this.debouncedHandleInputChange}
        noOptionsMessage={() => 'Begin typing to see options'}
      />
    );
  }

  renderFieldCurriculum = (fieldProps) => {
    const {
      options, form,
    } = fieldProps;
    return (
      <FormInput
        type="select"
        name="courseId"
        placeholder="Select Curriculum"
        options={options}
        menuPlacement="top"
        maxMenuHeight={200}
        getOptionValue={(option) => option.slug}
        getOptionLabel={(option) => option.name}
        onChange={(value) => this.handleCurriculumOnChange(value, form)}
      />
    )

  }

  handleSubmit = (values) => {
    const {
      createCourse, isCreatingCourse, subscriptionData, onClose,
    } = this.props;
    if (isCreatingCourse) return;

    const { curriculum: curriculumId, code } = subscriptionData;

    const {
      placementTestType,
      placementTestDate, ...rest
    } = values;

    const exams = [];

    if (placementTestType) {
      exams.push({
        session_type: 0,
        length_type: (placementTestType || {}).exam_type,
        date: moment(placementTestDate).format('YYYY-MM-DD'),
      });
    }

    const coursePayload = {
      ...rest,
      exams,
      placementTestDate,
      courseName: `${rest.schoolName} - ${rest.courseId.name}`,
      subscriptionCode: `${subscriptionData && code}`,
    };
    createCourse(coursePayload, onClose);
  };

  renderErrorMessage = (error, index) => (
    <p key={index}>{error}</p>
  )

  renderErrorList = (errorList) => {
    if (!errorList) return null;
    if (_isArray(errorList)) {
      return (
        <RegisterErrorMessage>
          {errorList.map(this.renderErrorMessage)}
        </RegisterErrorMessage>
      );
    }
    return (
      <RegisterErrorMessage>
        {this.renderErrorMessage(errorList, 1)}
      </RegisterErrorMessage>
    );
  }

  renderFormContent = ({ values, handleSubmit }) => {
    const {
      standard,
      curriculum,
      country,
      district,
      isCreatingCourse,
      errorMessage,
      placementTypesObj,
      subscriptionData,
    } = this.props;
    const { curriculumSelected, hasExam } = this.state;
    const { max_student_count: maxStudentCount, max_sections: maxSection, subscription_type: subscriptionType } = subscriptionData || {};
    const placementTypes = curriculumSelected ? placementTypesObj[curriculumSelected.id] || [] : [];
    return (
      <CreateOrJoinItemWrap>
        <CreateOrJoinItem>
          <CreateOrJoinTitle isCreateCourseModal>
            <h1>Setup your course</h1>
            <FreeExperienceWrapper>
              {SUBSCRIPTION_TYPE[subscriptionType]}
              <br />
              {`Max ${maxStudentCount} students`}
              <br />
              {`Max ${maxSection} section`}
            </FreeExperienceWrapper>
            <Form>
              <GroupTitle isCreateCourseModal>School Information</GroupTitle>
              <div>
                <FormInput
                  type="text"
                  name="schoolName"
                  placeholder="School Name"
                />
                <Field
                  component={this.renderFieldCountry}
                  name="country"
                  label="Country"
                  options={country}
                />
              </div>
              <div>
                <Field
                  component={this.renderFieldDistrict}
                  name="district"
                  label="District (US Only)"
                  options={district}
                  isDistrict
                />
              </div>
              <GroupTitle isCreateCourseModal>Curriculum</GroupTitle>
              <div>
                <Field
                  component={this.renderFieldCurriculum}
                  name="courseId"
                  label="Curriculum"
                  options={curriculum}
                />
                <FormInput
                  type="select"
                  name="standardId"
                  placeholder="State Standards"
                  menuPlacement="top"
                  maxMenuHeight={200}
                  options={standard}
                  getOptionValue={(option) => option.slug}
                  getOptionLabel={(option) => option.name}
                />
              </div>

              {hasExam&&(
                <div>
                  <ExamFieldWrapper>
                    <FormInput
                      type="select"
                      name="examType"
                      placeholder="Exam Session Type"
                      options={placementTypes}
                      getOptionValue={(option) => option.exam_type}
                      getOptionLabel={(option) => option.name}
                    />
                    <p>
                      Full length are not available in the free experience.
                    </p>
                  </ExamFieldWrapper>
                  <ExamSessionTypeContext>
                    <p>Course includes a Placement test and an Exit test.</p>
                    <br />
                    <p>Select the exam session type that best fits in your schedule.</p>
                  </ExamSessionTypeContext>
                </div>
              )}
            </Form>
            <ButtonWrapper>
              <ButtonStyled isSubmittedBtnInModal onClick={handleSubmit}>
                { isCreatingCourse ? 'Loading' : 'Create Course'}
                <InviteTeachersText>Next: Invite Teachers</InviteTeachersText>
              </ButtonStyled>
              {
                errorMessage
                && this.renderErrorList(errorMessage)
              }
            </ButtonWrapper>
          </CreateOrJoinTitle>
        </CreateOrJoinItem>
      </CreateOrJoinItemWrap>
    );
  }

  render() {
    const { subscriptionData, curriculum } = this.props;
    const { curriculum: curriculumId } = subscriptionData;
    const curriculumDefault = curriculum.find((c) => c.id === curriculumId);
    const { hasExam } = this.state;
    const newInitialValues = {
      courseName: '',
      courseId: '',
      standardId: '',
      country: '',
      schoolName: '',
      district: '',
      examType: '',
    };

    const formValidationSchema = hasExam ? validationSchema : validationSchemaNoExam
    return (
      <Formik
        initialValues={newInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={this.handleSubmit}
        render={this.renderFormContent}
      />
    );
  }
}

SetupYourCourse.propTypes = {
  fetchDistrictList: PropTypes.func.isRequired,
  errorMessage: PropTypes.any,
  createCourse: PropTypes.func,
  country: PropTypes.array,
  district: PropTypes.array,
  standard: PropTypes.array,
  curriculum: PropTypes.array,
  fetchCountryList: PropTypes.func,
  getCurriculumList: PropTypes.func,
  getCurriculumStandardList: PropTypes.func,
  isCreatingCourse: PropTypes.bool.isRequired,
  getCurriculumPlacementType: PropTypes.func.isRequired,
  getCurriculumExitType: PropTypes.func.isRequired,
  placementTypesObj: PropTypes.shape(),
  isCourseCreated: PropTypes.bool,
  subscriptionData: PropTypes.object,
};

export default SetupYourCourse;
