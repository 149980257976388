import React from 'react';
import { useQuestion } from '@components/PracticeV2/Provider/PracticeProvider';
import Box from '@mui/material/Box';
import useIsMobile from '@utils/hooks/useIsMobile';
import { TabProvider, Tab, TabPanel, TabList } from '@components/Shared/TabsDefaultSkin';
import { NavigationHeaderWrap } from '@components/QuestionAssets/QuestionAssets.style';

import PracticeControlButtons from '../PracticeControlButtons';
import Question from '../Question';
import QuestionAssets from '../QuestionAssets';
import QuestionsHeader from '../QuestionsHeader';

// 3 conditions to render PracticeContent
// no assets
// assist desktop
// assist mobile
//  I have to change the component it self to make it work
const QuestionBody = () => (
  <Box
    sx={{
      paddingX: {
        xs: '1rem',
        md: '0rem',
      },
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%',
      overflow: 'hidden',
      width: '100%',
      maxWidth: {
        xs: '100%',
        md: '33%',
      },
      marginInline: 'auto',
    }}
  >
    <QuestionsHeader />
    <Question />
    <PracticeControlButtons />
  </Box>
);
const PracticeContent = () => {
  const question = useQuestion();
  const isMobile = useIsMobile();
  //   no assets
  if (!question?.question?.shared_assets?.length) {
    return <QuestionBody />;
  }
  //   assist desktop
  if (!isMobile) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flex: 1,
          overflow: 'hidden',
          alignItems: 'stretch',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <QuestionAssets />
        <QuestionBody />
      </Box>
    );
  }
  //   assist mobile

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flex: 1,
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <TabProvider defaultTab="ShowQuestions">
        <NavigationHeaderWrap>
          <TabList>
            <Tab tabKey="Assets">Assets</Tab>
            <Tab tabKey="ShowQuestions">Show Questions</Tab>
          </TabList>
        </NavigationHeaderWrap>
        <TabPanel tabKey="Assets">
          <QuestionAssets />
        </TabPanel>
        <TabPanel tabKey="ShowQuestions">
          <QuestionBody />
        </TabPanel>
      </TabProvider>
    </Box>
  );
};

export default PracticeContent;
