import { connect } from 'react-redux';
import ClasslinkLoadingPage from '../../components/ClasslinkLoadingPage';
import { actions as AuthActions, selectors as AuthSelectors } from '../../reducers/auth';

const mapStateToProps = state => ({
  isAuthenticated: AuthSelectors.getAuthenticatedStatus(state),
});

const mapDispatchToProps = {
  classlinkLogin: AuthActions.classlinkLogin,
  logout: AuthActions.logout,
  connectClasslink: AuthActions.connectClasslink,

};

export default connect(mapStateToProps, mapDispatchToProps)(ClasslinkLoadingPage);
