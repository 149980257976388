import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import * as ExamAPI from '../../apis/exam';

import {
  EXAM_RESULT_GET_DETAIL,
  EXAM_RESULT_STUDENT_GET_DETAIL,

  actions as SectionStudentActions,
} from '../../reducers/examResult';

/**
 * ! Need update callback
 */
function* examResultGetDetail({ examSessionId }) {
  const { response, error } = yield call(ExamAPI.getExamResult, examSessionId);

  if (response) {
    const { data: results } = response;
    yield put(SectionStudentActions.examResultGetDetailSuccess(results));
  } else {
    yield put(SectionStudentActions.examResultGetDetailFail(error));
  }
}

function* examResultStudentGetDetail({ examSessionId }) {
  const { response, error } = yield call(ExamAPI.getExamStudentResult, examSessionId);

  if (response) {
    const { data: results } = response;
    yield put(SectionStudentActions.examResultGetDetailSuccess([results]));
  } else {
    yield put(SectionStudentActions.examResultGetDetailFail(error));
  }
}

export default function* examResultSaga() {
  yield takeLatest(EXAM_RESULT_GET_DETAIL, examResultGetDetail);
  yield takeLatest(EXAM_RESULT_STUDENT_GET_DETAIL, examResultStudentGetDetail);
}
