const iconWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& [class*="chalktalk-"]': {
    fontSize: '1em',
  },
  '& img': {
    padding: '0.1875rem',
    height: '1em',
    width: '1em',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1em',
  },
} as const;

export default iconWrapperStyle;
