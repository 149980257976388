import React from 'react';
import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material';

export interface IconButtonProps extends MuiIconButtonProps {
  children: React.ReactNode;
}

const IconButton = ({ children, ...rest }: IconButtonProps) => (
  <MuiIconButton {...rest}>{children}</MuiIconButton>
);

export default IconButton;
