import React, { useEffect, useState } from 'react';
import Box from '@components/Atoms/Box';
import {
  CircularProgress,
  InputAdornment,
  Pagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { QuestionGenerationServiceEditorWelcome } from '@components/QuestionGenerationService/styles';
import ShowIf from '@components/Atoms/ShowIf';
import ShadowScrollbar from '@components/Scrollbar/ShadowScrollbars';
import Button from '@components/Atoms/Button';
import Typography from '@components/Atoms/Typography';
import AddIcon from '@mui/icons-material/Add';
import Select from '@components/Atoms/Select';
import PassageListItem from './PassageListItem';
import { Passage } from './types';
import { usePassages } from './hooks';
import { MODES } from './Passages';

export const PassageSource = {
  MY_PASSAGES: 'my-passages',
  CHALKTALK_PASSAGES: 'chalktalk-passages',
};

const FleschGradeLevels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const LexileOptions = [
  'Very Easy to Read',
  'Easy to Read',
  'Fairly Easy to Read',
  'Plain English',
  'Fairly Difficult to Read',
  'Difficult to Read',
  'Very Difficult to Read',
];

const PassageList: React.FC<{
  switchMode: (toMode: string, passage: Passage | null) => void;
  inDialog?: boolean;
}> = ({ switchMode, inDialog }) => {
  const PAGE_SIZE = 10;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [passageSource, setPassageSource] = useState<string>(
    PassageSource.CHALKTALK_PASSAGES,
  );

  const {
    passages,
    filteredPassages,
    isPassagesLoading,
    searchQuery,
    setSearchQuery,
    lexileQuery,
    setLexileQuery,
    fleschGradeQuery,
    setFleschGradeQuery,
  } = usePassages(passageSource);

  const handlePageChange = (_: React.ChangeEvent<unknown> | null, value: number) => {
    setCurrentPage(value);
  };

  const getPaginatedPassages = () => {
    const start = (currentPage - 1) * PAGE_SIZE;
    return filteredPassages.slice(start, start + PAGE_SIZE);
  };

  useEffect(() => {
    setCurrentPage(1);
    setPageCount(Math.ceil(filteredPassages.length / PAGE_SIZE));
  }, [filteredPassages, setPageCount]);

  const handleToggleChange = (_: React.MouseEvent<HTMLElement>, value: string) => {
    setPassageSource(value);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        {/* TOGGLE PASSAGE SOURCE */}
        <ToggleButtonGroup
          color="primary"
          value={passageSource}
          exclusive
          onChange={handleToggleChange}
          aria-label="Platform"
          sx={{ minWidth: 'fit-content', flex: 0 }}
          size="small"
        >
          <ToggleButton value={PassageSource.CHALKTALK_PASSAGES}>
            Chalktalk Passages
          </ToggleButton>
          <ToggleButton value={PassageSource.MY_PASSAGES}>My Passages</ToggleButton>
        </ToggleButtonGroup>

        {/* SEARCH BAR */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Search Passages"
              variant="outlined"
              size="small"
              value={searchQuery}
              sx={{ width: '50%' }}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Select
              id="select-flesch-grade"
              onChangeHandler={(value) => {
                setFleschGradeQuery(value as string);
              }}
              showEmptyItem
              value={fleschGradeQuery}
              list={FleschGradeLevels.map((value, _) => ({
                value,
                label: `${value}`,
              }))}
              label="Flesch Kincaid Grade"
              size="small"
              sx={{
                mt: '-0.1rem',
                width: '13rem',
              }}
            />
            <Select
              id="select-lexile"
              onChangeHandler={(value) => {
                setLexileQuery(value as string);
              }}
              showEmptyItem
              value={lexileQuery}
              list={LexileOptions.map((value, _) => ({
                value,
                label: `${value}`,
              }))}
              label="Flesch Reading Ease"
              size="small"
              sx={{
                mt: '-0.1rem',
                width: '8.5rem',
              }}
            />
          </Box>

          {/* Buttons Container */}
          <Box sx={{ display: 'flex', gap: '0.5rem' }}>
            {/* CREATE PASSAGE BUTTON */}
            <Button
              variant="contained"
              sx={{ alignItems: 'center', width: 150 }}
              onClick={() => {
                switchMode(MODES.PASSAGE_MODE, null);
              }}
            >
              <Typography variant="caption">Create Passage</Typography>
              <AddIcon sx={{ fontSize: '1rem' }} />
            </Button>

            {/* GENERATE PASSAGE WITH AI BUTTON */}
            <Button
              variant="contained"
              sx={{ alignItems: 'center', width: 180 }}
              onClick={() => switchMode(MODES.PASSAGE_GENERATE_MODE, null)}
            >
              <Typography variant="caption">Generate with AI</Typography>
              <AddIcon sx={{ fontSize: '1rem' }} />
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Loading Indicator */}
      <ShowIf If={!!isPassagesLoading}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: '12rem',
          }}
        >
          <CircularProgress size={170} />
          <Typography position="absolute">Fetching Passages...</Typography>
        </Box>
      </ShowIf>

      {/* No Passages Message */}
      <ShowIf If={!isPassagesLoading && passages.length === 0}>
        <QuestionGenerationServiceEditorWelcome>
          <img src="/images/no-content.png" alt="Edit Content" />
          <Typography variant="subtitle2" sx={{ textAlign: 'center', mt: '0rem' }}>
            You have no passages yet.
            <br />
            Click on the &apos;Create Passage&apos; button to add a new passage.
          </Typography>
        </QuestionGenerationServiceEditorWelcome>
      </ShowIf>

      {/* No Passages Match Search Criteria */}
      <ShowIf
        If={!isPassagesLoading && passages.length > 0 && filteredPassages.length === 0}
      >
        <QuestionGenerationServiceEditorWelcome>
          <img src="/images/no-content.png" alt="Edit Content" />
          <Typography variant="subtitle2" sx={{ textAlign: 'center', mt: '0rem' }}>
            No passages match your search criteria.
          </Typography>
        </QuestionGenerationServiceEditorWelcome>
      </ShowIf>

      {/* Passages List */}
      <ShowIf If={!isPassagesLoading && passages.length > 0}>
        <ShadowScrollbar
          style={{
            height: inDialog ? 'calc(65vh)' : 'calc(56vh)',
            width: '100%',
          }}
        >
          {getPaginatedPassages().map((passage) => (
            <PassageListItem
              key={passage.reference}
              passage={passage}
              switchMode={switchMode}
              source={passageSource}
              inDialog={inDialog}
            />
          ))}
        </ShadowScrollbar>
        <Pagination
          size="small"
          onChange={handlePageChange}
          page={currentPage}
          count={pageCount}
          sx={{
            flex: 0,
            display: 'flex',
            justifyContent: 'center',
            mt: '1rem',
            mb: '3rem',
          }}
        />
      </ShowIf>
    </>
  );
};

export default PassageList;
