import _get from 'lodash/get';
import { LOCATION_CHANGE } from 'connected-react-router';

export const PRACTICE_REPORT_GET_DETAIL = 'practiceReport/GET_DETAIL';
export const PRACTICE_REPORT_GET_DETAIL_SUCCESS = 'practiceReport/GET_DETAIL_SUCCESS';
export const PRACTICE_REPORT_GET_DETAIL_FAIL = 'practiceReport/GET_DETAIL_FAIL';

export const PRACTICE_REPORT_GET_OF_USER = 'practiceReport/REPORT_GET_OF_USER';

const practiceReportGetOfUser = (practiceSessionId, practiceSectionId) => ({
  type: PRACTICE_REPORT_GET_OF_USER,
  practiceSessionId,
  practiceSectionId,
});

const practiceReportGetDetail = (sectionId, practiceSessionId) => ({
  type: PRACTICE_REPORT_GET_DETAIL,
  practiceSessionId,
  sectionId,
});

const practiceReportGetDetailSuccess = payload => ({
  type: PRACTICE_REPORT_GET_DETAIL_SUCCESS,
  payload,
});

const practiceReportGetDetailFail = error => ({
  type: PRACTICE_REPORT_GET_DETAIL_FAIL,
  error,
});

export const actions = {
  practiceReportGetDetail,
  practiceReportGetDetailSuccess,
  practiceReportGetDetailFail,
  practiceReportGetOfUser,
};

const getExamReportDetail = ({ practiceReport }) => _get(practiceReport, 'details', {});

export const selectors = {
  getExamReportDetail,
};

const initialState = {
  isGetDetail: false,
  details: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRACTICE_REPORT_GET_DETAIL:
      return { ...state, isGetDetail: true };
    case PRACTICE_REPORT_GET_DETAIL_SUCCESS: {
      const { payload } = action;

      return { ...state, isGetDetail: false, details: payload };
    }
    case PRACTICE_REPORT_GET_DETAIL_FAIL:
      return { ...state, isGetDetail: false };
    case LOCATION_CHANGE:
      return initialState;
    default:
      return state;
  }
}
