import React from 'react';
import {
  CalendarIcon, PremiumWrapper, PremiumUpgradeButton, PremiumUpgradeText,
} from './PremiumOnlyWarning.style';

const pathNumber = Array.from(Array(23).keys());

const PremiumOnlyWarning = () => (
  <PremiumWrapper>
    <CalendarIcon className="chalktalk-schedule-calendar">
      {pathNumber.map((number, index) => <i className={`path${number}`} key={index} />)}
    </CalendarIcon>
    <PremiumUpgradeButton>PREMIUM ONLY</PremiumUpgradeButton>
    <PremiumUpgradeText>Contact your Account Manager to upgrade today.</PremiumUpgradeText>
  </PremiumWrapper>
);

export default PremiumOnlyWarning;
