import { Skeleton } from '@mui/material';
import React from 'react';

const ExamMessageCardSkeleton = () => (
  <Skeleton
    sx={{
      position: 'absolute',
      top: '25%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
    height="11rem"
    width="24.875rem"
  />
);

export default ExamMessageCardSkeleton;
