const ColumnSize = [
  { smallSize: true },
  {
    smallSize: true, textCenter: true,
  },
  {
    smallSize: true, textCenter: true,
  },
  {
    smallSize: true, textCenter: true,
  },
  {
    smallSize: true, textCenter: true,
  },
];

export default ColumnSize;
