import React from 'react';
import ShowIf from '@components/Atoms/ShowIf';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useUser } from '@reducers/user/hooks';
import { error as errorToast } from '@utils/toast';
import Paper from '@components/Atoms/Paper';
import AttendanceStatusButton from '../AttendanceStatus';
import { useSubmitLessonAttendance } from '../hooks/mutations';
import {
  generateAttendanceRecords,
  getNextAttendanceStatus,
  useAutoAssignPractice,
  useIsMutatingSubmitLessonAttendance,
  useMarkLessonAsCompleted,
} from '../hooks/helpers';

const attendanceItemStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0.75rem 1rem',
  alignItems: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
};

interface AttendanceItemProps {
  studentPresenceStatus: boolean | null;
  studentFullName: string;
  studentId: number;
  activity: string;
  onLessonChange?: (lessonId: string | number, status) => void;
}

const AttendanceItem = ({
  studentFullName,
  studentId,
  studentPresenceStatus,
  activity,
  onLessonChange,
}: AttendanceItemProps) => {
  const { markLessonAsCompleted } = useMarkLessonAsCompleted({
    onLessonChange,
  });
  const currentUser = useUser();
  const { sectionId, unitId, lessonId } = useParams();
  const { mutate, isLoading } = useSubmitLessonAttendance();
  const isMutatingSubmitLessonAttendance = useIsMutatingSubmitLessonAttendance();
  const { autoAssignPractice } = useAutoAssignPractice();

  const setAttendance = (id, status) => {
    const studentObjectList = [
      {
        student: id,
      },
    ];
    const records = generateAttendanceRecords(
      studentObjectList,
      getNextAttendanceStatus(status),
    );

    const assignPracticePayload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUser.id,
      assigned_to: id,
    };

    mutate(
      { activity, records },
      {
        onSuccess: () => {
          autoAssignPractice(assignPracticePayload, getNextAttendanceStatus(status));
          markLessonAsCompleted();
        },
        onError: () => {
          errorToast(
            `Failed to mark attendance for student ${studentFullName}. Please try again.`,
          );
          console.error(`Failed to mark student with id: ${id}`);
        },
      },
    );
  };

  return (
    <Paper
      elevation={3}
      data-testid="attendance-item"
      sx={attendanceItemStyle}
      onClick={() => setAttendance(studentId, studentPresenceStatus)}
    >
      {studentFullName}
      <ShowIf If={!isLoading}>
        <AttendanceStatusButton
          disabled={isMutatingSubmitLessonAttendance}
          presentStatus={studentPresenceStatus}
          // onClick={() => setAttendance(studentId, studentPresenceStatus)}
        />
      </ShowIf>
      <ShowIf If={isLoading}>
        <CircularProgress size={24} />
      </ShowIf>
    </Paper>
  );
};

export default AttendanceItem;
