/* eslint-disable arrow-body-style */
import React from 'react';

import Box from '@components/Atoms/Box';
import TabNavigatorSkeleton from './TabNavigator/TabNavigator.skeleton';
import TabViewContentSkeleton from './TabViewContent/TabViewContent.skeleton';

const LessonContainerSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabNavigatorSkeleton />
      <TabViewContentSkeleton />
    </Box>
  );
};

export default LessonContainerSkeleton;
