import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { PRACTICE_TYPE } from '@utils/enums';
import StandardsPracticeButton from '@components/StandardsPractice/StandardsPracticeButton';
import Box from '@components/Atoms/Box';
import ShowIf from '@components/Atoms/ShowIf';
import Typography from '@components/Atoms/Typography';
import Accordion from '@components/Atoms/Accordion';
import Paper from '@components/Atoms/Paper';
import { useUser } from '@reducers/user/hooks';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import Button from '@components/Atoms/Button';
import FromToBlock from '@Atoms/FromToBlock';
import FiveProgress from '@Atoms/FiveProgressMeter/FiveProgress';
import { useHistory } from 'react-router-dom';
import MsgCard from '@components/Atoms/MsgCard';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import PracticeTopNavBar from '../PracticeTopNavBar';
import {
  UPRWrapper,
  UPRStudentName,
  UPRSessionWrapper,
  UPRSessionTitle,
  UPRSessionList,
  UPRSessionItem,
  UPRSessionItemInfo,
  UPRSessionItemDate,
  UPRSessionItemDetail,
  UPRSessionItemDuration,
  UPRSessionItemCard,
  UPRSessionName,
  UPRButtonPracticeAgainWrapper,
} from './UnitPracticeReview.style';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import { Button as GeneralButton } from '../Generals/stylesheets/Button.style';
import { calcDurationToSecond } from '../../utils/commonUtils';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

class UnitPracticeReview extends Component {
  componentDidMount() {
    const { match, practiceSessionReport } = this.props;
    const { practiceSessionId, sectionId } = match.params;
    practiceSessionReport(sectionId, practiceSessionId);
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      history,
      practiceSessionReport,
      isInitialized,
      practiceId,
      pacingGuideId,
    } = this.props;
    const { match: prevMatch } = prevProps;
    const { practiceSessionId, sectionId, subjectId, unitId, courseId, lessonId } =
      match.params;
    const { practiceSessionId: prevPracticeSessionId, sectionId: prevSectionId } =
      prevMatch.params;

    if (prevPracticeSessionId !== practiceSessionId || sectionId !== prevSectionId) {
      practiceSessionReport(sectionId, practiceSessionId);
    }

    if (prevProps.isInitialized !== isInitialized && isInitialized) {
      if (lessonId) {
        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice/${practiceId}${
            pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
          }`,
        );
      } else {
        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice/${practiceId}${
            pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
          }`,
        );
      }
    }
  }

  handleGoBack = () => {
    const { history, match, pacingGuideId } = this.props;
    const { courseId, sectionId, unitId, lessonId, subjectId } = match.params;
    let rootUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}`;
    if (lessonId) {
      rootUrl += `/lesson/${lessonId}?tab=${LessonTabs.Practice}${
        pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
      }`;
    } else {
      rootUrl += `/practice${pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''}`;
    }

    history.push(rootUrl);
  };

  renderSkill = (skill, lessonId) => {
    const { lessons, currentUser, isGettingLesson, isLoading, lessonSkillsList } =
      this.props;

    const lesson = lessons?.find((l) => l.id == lessonId);
    if (isGettingLesson || isLoading) {
      return <UPRSessionItemCard>Loading...</UPRSessionItemCard>;
    }

    if (!lessons || !currentUser) {
      return <UPRSessionItemCard>N/A</UPRSessionItemCard>;
    }

    const initialSkill = _get(skill, 'initial', 'N/A');
    const currentSkill = _get(skill, 'current', 'N/A');

    return (
      <UPRSessionItemCard key={lessonId}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
            pb: '1.5rem',
          }}
        >
          <Typography variant="h3">{lesson?.name}</Typography>

          <FromToBlock
            from={<FiveProgress level={initialSkill} />}
            to={<FiveProgress level={currentSkill} />}
            title="average Skill Level"
          />
        </Box>
        <ShowIf If={!!skill?.skill_list?.length && !!lessonSkillsList?.length}>
          <Box sx={{ borderTop: 'solid 1px #ccc' }}>
            <Accordion
              title={
                <Typography variant="h3" sx={{ fontSize: '1rem', color: '#2b1a08' }}>
                  Breakdown of Skills
                </Typography>
              }
              accordionSx={{ boxShadow: 'none', border: 'none' }}
              summarySx={{ fontSize: '1rem', color: 'black' }}
              content={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.25rem',
                    width: '100%',
                  }}
                >
                  {skill?.skill_list?.map((item) => {
                    const skillName = lessonSkillsList?.find(
                      (el) => el?.id === item?.assessment_node_id,
                    )?.name;
                    return (
                      <React.Fragment key={item?.id}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            width: '100%',
                          }}
                        >
                          <Typography variant="subtitle1">{skillName}</Typography>
                          <FromToBlock
                            from={<FiveProgress level={initialSkill} />}
                            to={<FiveProgress level={currentSkill} />}
                            title="Skill Level"
                          />
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Box>
              }
            />
          </Box>
        </ShowIf>
      </UPRSessionItemCard>
    );
  };

  navigateToReviewAnswerPage = (practiceSessionId) => () => {
    const { match, history, pacingGuideId } = this.props;

    const { courseId, sectionId, unitId, lessonId, subjectId } = match.params;
    let route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}`;

    if (lessonId) {
      route += `/lesson/${lessonId}`;
    }
    route += `/practice-result/${practiceSessionId}${
      pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
    }`;

    history.push(route);
  };

  renderLessonSkill = (lessonId, skill) => {
    const { studentId } = this.props;

    const skillObject = skill?.[lessonId];
    const skillValue = skillObject?.[studentId] ?? {
      initial: 0,
      current: 0,
      skill_list: [],
    };

    return this.renderSkill(skillValue, lessonId);
  };

  renderSession = (practice) => {
    const { courseData, lessonName } = this.props;
    const time = practice.ended
      ? `Ended: ${moment(practice.ended).format('MM-DD-YYYY hh:mm')}`
      : `Started: ${moment(practice.started).format('MM-DD-YYYY hh:mm')}`;
    const score = _get(practice, 'score.0.score', {}) || {};
    const { auto = 0, auto_max: autoMax = 0 } = score;
    const tot = practice.time_on_task;
    const { skill } = practice;
    const displayTot = calcDurationToSecond(tot);
    const useLearnosity = courseData?.standard_practice_enabled;
    return (
      <UPRSessionItem key={practice?.id}>
        <UPRSessionItemDetail>
          <UPRSessionName>
            <Typography variant="h3">{lessonName} </Typography>
          </UPRSessionName>
          <UPRSessionItemInfo>
            <UPRSessionItemDate>{time}</UPRSessionItemDate>
            <ShowIf If={!useLearnosity}>
              <UPRSessionItemDuration>{displayTot}</UPRSessionItemDuration>
            </ShowIf>
          </UPRSessionItemInfo>
          <Paper
            sx={{
              mt: '1rem',
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '5.375rem',
              height: '5.375rem',
              border: (theme) => `solid 0.125rem ${theme.palette.primary.main}`,
              borderRadius: '1rem',
              padding: '1rem',
              gap: '0.5rem',
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontSize: '1rem',
                textTransform: 'uppercase',
                color: '#2b1a08',
              }}
            >
              Score
            </Typography>
            <Typography variant="h3" sx={{ fontSize: '1rem', color: '#2b1a08' }}>
              {`${auto} / ${autoMax}`}
            </Typography>
          </Paper>
          {Object.keys(skill).map((item) => this.renderLessonSkill(item, skill))}
          <Box
            sx={{
              paddingTop: '1em',
              paddingBottom: '1em',
              display: 'flex',
              justifyContent: 'flex-end',
              textAlign: 'right',
              color: 'primary.main',
              cursor: 'pointer',

              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <ShowIf If={useLearnosity}>
              <StandardsPracticeButton
                practiceSessionId={practice.id}
                state="review"
                text="Review Answer"
              />
            </ShowIf>
            <ShowIf If={!useLearnosity}>
              <Button
                variant="text"
                onClick={this.navigateToReviewAnswerPage(practice.id)}
              >
                Review Answer
              </Button>
            </ShowIf>
          </Box>
        </UPRSessionItemDetail>
      </UPRSessionItem>
    );
  };

  initializePractice = () => {
    const { match, currentUserId, initializePracticeSession, report } = this.props;
    const { sectionId, lessonId, unitId } = match.params;
    const assetType = report?.asset_type ?? null;
    if (assetType) {
      initializePracticeSession(
        lessonId,
        sectionId,
        unitId,
        currentUserId,
        assetType,
        PRACTICE_TYPE.ADAPTIVE_PRACTICE,
      );
    } else {
      initializePracticeSession(
        lessonId,
        sectionId,
        unitId,
        currentUserId,
        _,
        PRACTICE_TYPE.ADAPTIVE_PRACTICE,
      );
    }
  };

  render() {
    const { report, currentUser, shouldShowSkillMsg } = this.props;
    const name = `${currentUser.first_name} ${currentUser.last_name}`;
    return (
      <PracticeContainer>
        <PracticeTopNavBar goBackText="Go Back" goBack={this.handleGoBack} />
        <UPRButtonPracticeAgainWrapper>
          <GeneralButton green onClick={this.initializePractice}>
            Practice Again
          </GeneralButton>
        </UPRButtonPracticeAgainWrapper>
        <ShadowScrollbar autoHide style={scrollStyle}>
          {report ? (
            <UPRWrapper>
              <ShowIf If={shouldShowSkillMsg}>
                <MsgCard
                  extraStyle={{
                    maxWidth: '32.5rem',
                    margin: '0 auto',
                    my: '2rem',
                  }}
                  dataTestId="info-card-motivation"
                  img="/images/offline.svg"
                  title="Keep Practicing!"
                  description="Skill level updates after consistent progress. It may take a few tries to see changes."
                  footerText="Each practice makes you stronger!"
                />
              </ShowIf>
              <UPRStudentName>{name}</UPRStudentName>
              <UPRSessionWrapper>
                <UPRSessionTitle>Session Results</UPRSessionTitle>
                <UPRSessionList>{this.renderSession(report)}</UPRSessionList>
              </UPRSessionWrapper>
            </UPRWrapper>
          ) : (
            <LoadingIndicator isLoading={!report} />
          )}
        </ShadowScrollbar>
      </PracticeContainer>
    );
  }
}

UnitPracticeReview.propTypes = {
  currentUser: PropTypes.shape(),
  currentUserId: PropTypes.number,
  lessons: PropTypes.arrayOf(PropTypes.any).isRequired,
  match: PropTypes.shape(),
  history: PropTypes.shape(),
  practiceSessionReport: PropTypes.func.isRequired,
  initializePracticeSession: PropTypes.func.isRequired,
  report: PropTypes.shape(),
  isInitialized: PropTypes.bool,
  practiceId: PropTypes.number,
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const history = useHistory();
    const shouldShowSkillMsg = history?.location?.state?.shouldShowSkillMsg;
    const { 'pacing-guide-id': pacingGuideId } = useSearchParams();

    const currentUser = useUser();
    const { data: lessonDetails } = useGetLessonDetail();

    return (
      <WrappedComponent
        {...props}
        shouldShowSkillMsg={shouldShowSkillMsg}
        studentId={currentUser?.id}
        lessonSkillsList={lessonDetails?.skills}
        lessonName={lessonDetails?.name}
        pacingGuideId={pacingGuideId}
      />
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(UnitPracticeReview);
