import React from 'react';
import Box from '@Atoms/Box';
import Typography from '@Atoms/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NoSkillSelectedPlaceHolder: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: 3,
      textAlign: 'center',
      paddingTop: '10rem',
      paddingBottom: '10rem',
      gap: '0.5rem',
    }}
  >
    <InfoOutlinedIcon sx={{ fontSize: 48, color: 'text.secondary', marginBottom: 2 }} />
    <Typography variant="h3">No Skill Selected</Typography>
    <Typography variant="body1">
      Please select a skill from the dropdown menu above to view its report.
    </Typography>
  </Box>
);

export default NoSkillSelectedPlaceHolder;
