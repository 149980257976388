import { postLessonSessionActivity } from '@apis/sections';
import { postSlideChange } from '@apis/curriculum';
import { userReportIssue } from '@apis/users';
import { useMutation } from 'react-query';
import { success as ToastSuccess, error as errorToast } from '@utils/toast';
import { useParams } from 'react-router-dom';

export interface LessonSessionActivityBody {
  unit: string;
  lesson: number;
  section: string;
  user: number;
  lesson_group: number;
}
export const usePostLessonSessionActivity = () => {
  const { sectionId } = useParams();
  const response = useMutation(
    (body: LessonSessionActivityBody) => postLessonSessionActivity(sectionId, body),
    {
      mutationKey: ['postLessonSessionActivity', sectionId],
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};

export const usePostUserReportIssue = () => {
  const mutation = useMutation(userReportIssue, {
    mutationKey: ['postUserReportIssue'],
    onSuccess: () => {
      ToastSuccess('Feedback was submitted successfully');
    },
    onError: () => {
      errorToast(
        'An unexpected error has occurred. Please ensure that you enter valid data.',
      );
    },
  });

  return mutation;
};

export interface PostSlideChangeBody {
  lesson_node_id: number;
  section_id: number;
  user_id: number;
  slide_index: number;
  time_spent: number;
  event_type: string;
  step_count: number;
  last_slide_index: number;
  slide_title: string;
  tab: string;
  additionalsources_id: number;
}

export const usePostSlideChange = () => {
  const response = useMutation(
    (body: PostSlideChangeBody) => postSlideChange(body),
    {
      mutationKey: ['postSlideChange'],
    },
  );
  return response;
};
