import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import LeftSideBarLessons from '../../components/LeftSideBar/LeftSideBarLessons';
import {
  selectors as LessonAssignSelectors,
  actions as LessonAssignedActivityActions,
} from '../../reducers/lessonAssignedActivities';
import { selectors as UserSelectors } from '../../reducers/user';
import {
   selectors as sectionUnitSelectors,
   actions as SectionUnitActions 
  } from '../../reducers/sectionUnit';
import { actions as SectionSessionActions } from '../../reducers/sectionSession';
import { actions as ActivityActions } from '../../reducers/activity';
import {
  selectors as LeftSidebarSelectors,
  actions as LeftSidebarActions,
} from '../../reducers/leftSideBar';
import {
  selectors as SectionReviewSelectors,
  actions as SectionReviewActions,
} from '../../reducers/sectionReviews';
import {
  selectors as SectionSubjectSelectors,
  actions as SectionSubjectActions,
} from '../../reducers/sectionSubjects';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import { ROLE_TYPE } from '../../utils/enums';
import { selectors as SectionSessionSelectors } from '../../reducers/sectionSession';
import {
  selectors as activitySelectors,
} from '../../reducers/activity';
import {
  selectors as practiceSelectors,
} from '../../reducers/practice';
import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,
} from '../../reducers/sectionSchedule';

const mapStateToProps = (state, { match }) => {
  const lessonList = SectionScheduleSelectors.getLessonList(state);
  const courseIdSelected = _get(match, 'params.courseId', '');
  const { sectionId = 0, subjectId } = match.params;
  const shouldDisplaySectionSelect = !!courseIdSelected;
  const isTeacher = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR;
  const subjectIds = SectionSubjectSelectors.getSubjectIds(state);
  
  return {
    lessonList,
    completedPracticeUnitIds: practiceSelectors.getCompletedPracticeUnitIds(state),
    sectionId,
    subjectId,
    isTeacher,
    courseId: courseIdSelected,
    shouldDisplaySectionSelect,
    assignCountBySubject: LessonAssignSelectors.getAssignCountBySubject(state, subjectIds),
    isLoadingSubject: SectionSubjectSelectors.isGettingSectionSubject(state),
    subjects: SectionSubjectSelectors.getSectionSubjectListOrder(state),
    isSideBarClose: LeftSidebarSelectors.getSideBar(state),
    isSubNavBarClose: !subjectId,
    examsScoreSummary: SectionSelectors.examsScoreSummary(state, subjectId),
    totalReview: SectionReviewSelectors.getTotalReview(state, subjectId),
    unitBySubject: sectionUnitSelectors.getUnitBySubject(state),
    lessonActivitySummaryById: activitySelectors.getLessonActivitySummaryById(state),
    isLoadingUnit: sectionUnitSelectors.isGettingSectionUnit(state),
  };
};

const mapDispatchToProps = {
  sectionScheduleGet: SectionScheduleActions.sectionScheduleGet,
  toggleLeftSidebar: LeftSidebarActions.toggleLeftSidebar,
  toggleSubNavBar: LeftSidebarActions.toggleSubNavBar,
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  sectionReviewGet: SectionReviewActions.sectionReviewGet,
  sectionSessionGet: SectionSessionActions.sectionSessionGet,
  fetchSectionSubjectList: SectionSubjectActions.fetchSectionSubjectList,
  getAllLessonActivitySummary: ActivityActions.getAllLessonActivitySummary,

  sectionsPracticeSummaryGet: SectionActions.sectionsPracticeSummaryGet,
  sectionsStudentPracticeSummaryGet: SectionActions.sectionsStudentPracticeSummaryGet,

  sectionReviewSectionSummaryGet: SectionReviewActions.sectionReviewSectionSummaryGet,
  sectionStudentReviewSectionSummaryGet: SectionReviewActions.sectionStudentReviewSectionSummaryGet,

  sectionsAttendanceGet: SectionActions.sectionsAttendanceGet,
  fetchLessonAssignedActivityList: LessonAssignedActivityActions.fetchLessonAssignedActivityList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideBarLessons),
);
