import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import {
  actions as ActivityActions,
  selectors as ActivitySelectors,
} from '../../reducers/activity';
import LessonDetailTab from '../../components/LessonDetailTab';
import { selectors as UserSelectors, actions as userActions } from '../../reducers/user';
import { selectors as SectionStudentSelectors } from '../../reducers/sectionStudent';
import {
  selectors as CurriculumLessonSelectors,
  actions as CurriculumLessonsActions,
} from '../../reducers/curriculumLessons';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as CurriculumSelectors } from '../../reducers/curriculums';

const mapStateToProps = (state, { match }) => {
  const { courseId, sectionId, subjectId, unitId, lessonId } = match?.params
    ? match.params
    : undefined;

  const courseData = CourseSelectors.getCourseDetailById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);
  const userId = UserSelectors.getUserId(state);
  const studentList = SectionStudentSelectors.getStudentList(state, sectionId);
  const lessonReviews = CurriculumLessonSelectors.getCurriculumLessonReviews(state);
  const sectionDetail = SectionSelectors.getSectionById(state, sectionId);
  const teacherReview = lessonReviews?.filter((review) => review.teacher === userId);
  const copyPresentationId = teacherReview?.[0]?.g_slide_id;
  const reviewId = teacherReview?.[0]?.id;
  const scheduledLessons =
    sectionDetail?.section_scheduled_subjects?.[subjectId]?.completed_lessons || [];

  return {
    courseId,
    sectionId,
    unitId,
    lessonId,
    subjectId,
    studentList,
    userId,
    user: UserSelectors.getCurrentUser(state),
    userRole: UserSelectors.getUserRole(state),
    lessonActivityById: ActivitySelectors.lessonActivityById(state),
    lessonActivitySummaryById: ActivitySelectors.getLessonActivitySummaryById(state),
    copyPresentationId,
    scheduledLessons,
    reviewId,
    isGHEAllowed: CourseSelectors.getGHEStatus(state, courseId),
    isSubmittingSlide: CurriculumLessonSelectors.isSubmittingSlide(state),
    curriculumDetail,
  };
};

const mapDispatchToProps = {
  postLessonActivity: ActivityActions.postLessonActivity,
  curriculumLessonPostSlide: CurriculumLessonsActions.curriculumLessonPostSlide,
  updateReviewStatus: CurriculumLessonsActions.curriculumLessonUpdateReviewStatus,
  updateSectionComplete: SectionActions.updateSectionComplete,
  updateSectionUnComplete: SectionActions.updateSectionUnComplete,
  userReportIssue: userActions.userReportIssue,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonDetailTab));
