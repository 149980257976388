/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import AutoComplete from '@components/Atoms/AutoComplete';
import TextField from '@components/Atoms/TextField';
import Tooltip from '@components/Atoms/Tooltip';
import { wordsSearch } from './utils/helpers';
import { QuestionType, SupportedQuestionTypes } from './types';
import { useGetQGSSupportedQuestionTypes } from './hooks';

export const getFilteredQuestionTypesBySubject = (
  supportedQuestionTypes: SupportedQuestionTypes | null,
  selectedSubject: { display_name?: string } | null,
) => {
  if (!supportedQuestionTypes || !selectedSubject) return [];

  const subjectName = selectedSubject?.display_name?.toLowerCase() || '';
  const isEnglishSubject = subjectName.includes('english');
  const isMathSubject = ['math', 'algebra'].some((term) => subjectName.includes(term));

  if (isEnglishSubject) {
    return supportedQuestionTypes.english;
  }

  if (isMathSubject) {
    return supportedQuestionTypes.math;
  }

  return [];
};

const QuestionTypeSelector = ({ handleChange, isDisabled, selectedSubject }) => {
  const [supportedQuestionTypes, setSupportedQuestionTypes] =
    useState<SupportedQuestionTypes | null>(null);
  const { mutate: fetchSupportedQuestionTypes } = useGetQGSSupportedQuestionTypes();
  const [selectedQuestionType, setSelectedQuestionType] =
    useState<QuestionType | null>(null);

  useEffect(() => {
    fetchSupportedQuestionTypes(undefined, {
      onSuccess: (data) => {
        setSupportedQuestionTypes(data);
      },
    });
  }, [fetchSupportedQuestionTypes]);

  const filteredQuestionTypes = React.useMemo(
    () => getFilteredQuestionTypesBySubject(supportedQuestionTypes, selectedSubject),
    [supportedQuestionTypes, selectedSubject],
  );

  useEffect(() => {
    if (filteredQuestionTypes?.length && !selectedQuestionType) {
      const firstQuestionType = filteredQuestionTypes[0];
      setSelectedQuestionType(firstQuestionType);
      handleChange(firstQuestionType);
    }
  }, [filteredQuestionTypes, selectedQuestionType, handleChange]);

  const handleQuestionTypeChange = (_, value) => {
    setSelectedQuestionType(value);
    handleChange(value);
  };

  return (
    <AutoComplete
      options={filteredQuestionTypes || []}
      getOptionLabel={(option) => option.name}
      title="Question Types"
      filterOptions={wordsSearch}
      onChange={handleQuestionTypeChange}
      groupBy={(option) => option.categoryName}
      value={selectedQuestionType || null}
      disableClearable
      size="small"
      disabled={isDisabled}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option) => (
        <Tooltip title={option.description} arrow placement="right">
          <li {...props}>{option.name}</li>
        </Tooltip>
      )}
      renderInput={(params) => (
        <TextField
          label="Question Type"
          variant={undefined}
          onChange={undefined}
          value={undefined}
          {...params}
          placeholder="Search for question type..."
          InputProps={{
            ...params.InputProps,
            style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
          }}
        />
      )}
    />
  );
};

export default QuestionTypeSelector;
