import React from 'react';
import PropTypes from 'prop-types';
import PracticeTopNavBar from '../PracticeTopNavBar';
import ReviewQuestionContainers from './ReviewQuestionContainers';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ReportErrorModal from '../ReportErrorModal';

class PracticeTeacherReviewManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      reportProps: null,
    };
  }

  componentDidMount() {
    const { match, getAllPracticeSessionReportActivities } = this.props;
    const { sectionId, unitId, lessonId } = match.params;
    getAllPracticeSessionReportActivities(sectionId, unitId, lessonId);
  }

  componentDidUpdate(prevProps) {
    const { match, getAllPracticeSessionReportActivities } = this.props;
    const { match: prevMatch } = prevProps;
    const { sectionId, unitId, lessonId } = match.params;
    const { sectionId: prevSectionId, unitId: prevUnitId, lessonId: prevLessonId } = prevMatch.params;

    if (sectionId !== prevSectionId
      || prevUnitId !== unitId
      || prevLessonId !== lessonId) {
      getAllPracticeSessionReportActivities(sectionId, unitId, lessonId);
    }
  }

  onCloseModal = () => {
    this.setState({
      isOpen: false,
      reportProps: null,
    });
  };

  openReportErrorModal = (contentType, questionId) => {
    this.setState({
      isOpen: true,
      reportProps: {
        contentType,
        questionId,
      },
    });
  }

  submitError = (content) => {
    const { userReportIssue } = this.props;
    const { reportProps } = this.state;

    if (!reportProps) {
      return;
    }

    const { questionId, contentType } = reportProps;

    userReportIssue(questionId, contentType, content, this.onCloseModal);
  }

  redirectToPracticeDetailPage = () => {
    const {
      history,
    } = this.props;
    history.goBack();
  }

  render() {
    const { isOpen } = this.state;
    const {
      report, isLoading, userAnswerList,
      isPracticeNavigation,
    } = this.props;

    if (isLoading) return <LoadingIndicator content="Preparing your review" />;

    if (!report || !report.length) {
      return (
        <div>
          Unable to get your review data. Please try again later!
        </div>
      );
    }

    return (
      <PracticeContainer>
        <PracticeTopNavBar
          goBackText="Back to Lesson"
          practiceName={null}
          goBack={this.redirectToPracticeDetailPage}
        />
        <ReviewQuestionContainers
          questions={report}
          isPracticeNavigation={isPracticeNavigation}
          userAnswerList={userAnswerList}
          openReportErrorModal={this.openReportErrorModal}
        />
        {isOpen
          && (
            <ReportErrorModal
              isOpen={isOpen}
              onClose={this.onCloseModal}
              onSubmit={this.submitError}
            />
          )}
      </PracticeContainer>
    );
  }
}

PracticeTeacherReviewManagement.propTypes = {
  match: PropTypes.shape(),
  report: PropTypes.array,
  userAnswerList: PropTypes.shape(),
  history: PropTypes.shape(),
  isLoading: PropTypes.bool,
  getAllPracticeSessionReportActivities: PropTypes.func.isRequired,
  isPracticeNavigation: PropTypes.bool,
  userReportIssue: PropTypes.func,
};

export default PracticeTeacherReviewManagement;
