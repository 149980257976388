/* eslint-disable import/prefer-default-export */
export enum LLROrderingType {
  FIXED_ORDERING = 1,
  ADAPTIVE_ORDERING = 2,
}

export interface LLRScoreResponse {
  student_id: number | null;
  auto: number | null;
  auto_max: number | null;
}

export interface SkillLevelDetails {
  initial: number;
  current: number;
  skill_list: {
    initial: number;
    current: string;
    assessment_node_id: number;
  }[];
}

export interface LLRSessionResponse {
  id: number;
  user: number;
  response: number;
  started: Date;
  ended: Date;
  unit: number;
  lesson: number;
  section: number;
  asset_type: number | null;
  score: [
    {
      id: number;
      score: {
        auto: number;
        manual: number;
        auto_max: number;
        manual_max: number;
      };
    },
  ];
  skill: {
    [lessonId: number]: {
      [userId: number]: SkillLevelDetails;
    };
  };
  time_on_task: number;
  type: number;
}
