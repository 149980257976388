import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import { Button } from '../Generals/stylesheets/Button.style';
import { ButtonWrapper } from './ScheduleAddLessonSessionForm.style';

const validationSchema = Yup.object().shape({
  // unit: Yup.array().required('Required'),
  unit: Yup.object().required('Required'),
});

class AddLessonSessionStepThree extends Component {
  handleSubmit = (values) => {
    const { handleSubmit } = this.props;
    handleSubmit(values);
  }

  render() {
    const { initialValues, units } = this.props;

    return (
      <div>
        <Formik
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          initialValues={initialValues}
        >
          <Form noValidate>
            <FormInput
              type="select"
              name="unit"
              label="Session unit"
              options={units}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              isMulti={false}
            />
            <ButtonWrapper>
              <Button submit onClick={this.props.goToPrevStep}>
                Previous step
              </Button>
              <Button submit type="submit">
                Next step
              </Button>
            </ButtonWrapper>
          </Form>
        </Formik>
      </div>
    );
  }
}

AddLessonSessionStepThree.propTypes = {
  units: PropTypes.array,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  goToPrevStep: PropTypes.func,
};

export default AddLessonSessionStepThree;
