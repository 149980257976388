import React from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from 'styled-components';
import SCDefaultThemesV2 from './ThemeObject/SCDefaultThemes.style';
import MUIDefaultTheme from './ThemeObject/MUIDefaultTheme';

import 'react-toastify/dist/ReactToastify.css';

const ThemeProvider = ({ children }) => (
  <MUIThemeProvider theme={MUIDefaultTheme}>
    <SCThemeProvider theme={SCDefaultThemesV2}>{children}</SCThemeProvider>
  </MUIThemeProvider>
);
ThemeProvider.displayName = 'ChalkTalk-ThemeProvider';
export default React.memo(ThemeProvider, () => false);
