/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LoginBlockLeft, ImageWrap, Image } from '../LoginPage/Login.style';

export default class BackgroundLeft extends Component {
  static propTypes = {
    type: PropTypes.string,
  };

  renderVerifyBackground = () => (
    <Image className="chalktalk-email-verify">
      <i className="path1" />
      <i className="path2" />
      <i className="path3" />
      <i className="path4" />
      <i className="path5" />
      <i className="path6" />
      <i className="path7" />
      <i className="path8" />
      <i className="path9" />
      <i className="path10" />
      <i className="path11" />
      <i className="path12" />
    </Image>
  );

  renderForgotBackground = () => (
    <Image className="chalktalk-forgot-password">
      <i className="path1" />
      <i className="path2" />
      <i className="path3" />
      <i className="path4" />
      <i className="path5" />
      <i className="path6" />
      <i className="path7" />
      <i className="path8" />
    </Image>
  );

  renderImage = () => {
    const { type } = this.props;
    switch (type) {
      case 'verifyEmail':
        return this.renderVerifyBackground();
      case 'password':
        return this.renderForgotBackground();
      default:
        return <img src="/images/bg-login.jpg" />;
    }
  };

  render() {
    return (
      <LoginBlockLeft>
        <ImageWrap>{this.renderImage()}</ImageWrap>
      </LoginBlockLeft>
    );
  }
}
