/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import ColumnSize from './ColumnSize';
import {
  TableHeadWrapper,
  TableContentWrapper,
  TableContentItem,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import {
  BlockWrap,
  BlockTitle,
  UpcomingEmpty,
} from '../Generals/stylesheets/General.style';
import { IconStyled } from './StudentListTable.style';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'first_name',
    value: 'First Name',
  },
  {
    uniqKey: 'last_name',
    value: 'Last Name',
  },
  {
    uniqKey: 'email',
    value: 'Email',
  },
  {
    uniqKey: 'status',
    value: 'Status',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class StudentListTable extends Component {
  static propTypes = {
    sectionId: PropTypes.any,
    studentList: PropTypes.array.isRequired,
    sectionsStudentRemove: PropTypes.func,
    sectionsStudentGet: PropTypes.func,
  };

  state = {
    sortBy: 'last_name',
    orderBy: ORDER_BY.ASC,
  };

  componentDidMount() {
    const { sectionId, sectionsStudentGet } = this.props;

    sectionsStudentGet(sectionId);
  }

  componentDidUpdate(prevProps) {
    const { sectionId: prevSectionId } = prevProps;
    const { sectionId, sectionsStudentGet } = this.props;

    if (!_isEqual(sectionId, prevSectionId)) {
      sectionsStudentGet(sectionId);
    }
  }

  handleDeleteStudentGroup = (studentId) => {
    const { sectionsStudentRemove, sectionId } = this.props;

    sectionsStudentRemove(sectionId, studentId);
  };

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  renderStudentListHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderStudentListHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderStudentListHeaderItem)}
      <TableHeader {...ColumnSize[4]} value="Actions" />
    </TableHeadWrapper>
  );

  renderStudentListContent = (student) => {
    const {
      first_name, last_name, email, id,
    } = student;
    return (
      <TableContentItem key={id}>
        <TableContent {...ColumnSize[0]}>{first_name}</TableContent>
        <TableContent {...ColumnSize[1]}>{last_name}</TableContent>
        <TableContent {...ColumnSize[2]}>{email}</TableContent>
        <TableContent {...ColumnSize[3]}>
          <i>Invitation Sent</i>
        </TableContent>
        <TableContent {...ColumnSize[4]}>
          <IconStyled
            className="chalktalk-icon-delete"
            onClick={() => this.handleDeleteStudentGroup(id)}
          />
        </TableContent>
      </TableContentItem>
    );
  };

  render() {
    const { orderBy, sortBy } = this.state;
    const { studentList = [] } = this.props;
    const isEmpty = studentList.length === 0;
    firstSort(studentList);
    const filterStudentList = _orderBy(
      studentList,
      [
        (d) => {
          if (sortBy === 'last_name') return d[sortBy].toUpperCase();
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <BlockWrap>
        <BlockTitle>Students</BlockTitle>
        {isEmpty ? (
          <UpcomingEmpty>Invite students.</UpcomingEmpty>
        ) : (
          <React.Fragment>
            {this.renderStudentListHeader()}
            <TableContentWrapper>
              {filterStudentList.map(this.renderStudentListContent)}
            </TableContentWrapper>
          </React.Fragment>
        )}
      </BlockWrap>
    );
  }
}
