import styled from 'styled-components';

export const SlideDiffContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1em 0;
  border-radius: 0.5em;
`;

export const SlideDiffHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #bbdefb;
  margin-bottom: 1em;
  height: 2.5em;
`;

export const HeaderIconContainer = styled.div`
  padding: 10px;
`;

export const HeaderIcon = styled.i`
  height: 20px;
  width: 20px;
`;

export const HeaderTitle = styled.span`
  font-size: 16px;
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  > div:last-child {
    margin-left: 4em;
  }
`;

export const TrackedDiffContainer = styled.div``;

export const TrackedDiffHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1em;
  align-items: center;
  margin-top: 1em;
`;

export const TrackedDiffHeaderTitle = styled.span`
  padding: 1em;
`;

export const TrackedDiffContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #e1f5fe;
  padding: 10px;
`;

export const PresentationDiffContainer = styled.div`
  width: 100%;
  height: calc(100% - 4em);
  overflow-y: scroll;
  min-width: 85em;
  padding: 0 3em;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colorStyled.ColorHint};
    border-radius: 1px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colorStyled.ColorLevelKill};
    border-radius: 1px;
    background-clip: content-box;
  }
`;

export const PresentationSlideContainer = styled.div`
  height: 300px;
  position: relative;
`;

export const SlideOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
`;

export const SlideDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

export const SlideChangesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  margin: 1em 0 1em;
  width: 500px;
`;

export const SlideNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 50%;
  text-align: center;
  height: 28px;
  flex: 0 0 6%;
  background-color: ${props => props.theme.colorStyled.ColorBtnDark};
  padding: 10px;
  font-family: 'MontserratLight';
`;

export const SlideChangesList = styled.ul`
  flex: 0 0 88%;
  list-style: disc;
  margin-top: 5px;

  > span {
    line-height: 20px;
    margin-left: -10px;
  }
`;

export const SlideChangeItem = styled.li`
  line-height: 20px;
  font-family: 'MontserratLight';
`;

export const EmptySlide = styled.div`
  padding: 1em;
  margin-top: 1em;
  margin-left: 1em;
  height: 300px;
  width: 500px;
  position: relative;
  background-color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
   > i:before {
     font-size: 2em;
     font-weight: bold;
   }
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5em;
`;

export const ErrorTitle = styled.span`
  font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
`;

export const ErrorMessage = styled.span`
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
  font-family: MontserratRegular;
  margin-top: 1em;
`;
