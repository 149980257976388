import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _get from 'lodash/get';
import { selectors as LeftSideBarSelector } from '../../reducers/leftSideBar';
import { selectors as GroupActivitySelector, actions } from '../../reducers/groupActivity';
import GroupActivityContent from '../../components/GroupActivityContent';
import { selectors as UserSelectors } from '../../reducers/user';


const mapStateToProps = (state, { match }) => {
  const lessonId = _get(match, "params.lessonId", "");
  const userId = UserSelectors.getCurrentUser(state).id;
  const questions = GroupActivitySelector.selectGroupQuestions(state, lessonId);
  const scores = GroupActivitySelector.selectStudentsQuestionScore(state, lessonId);
  const { groupActivity } = state;
  const { groupActivities } = groupActivity;
  const groupArray = groupActivities
    .sort((currentItem, nextItem) => currentItem.id - nextItem.id)
    .filter((group) => group.students.length > 0);

  let currentGroup;

  groupActivities?.forEach((group) => {
    const activeGroup = group?.students.filter(
      (student) => student?.student_id === userId,
    );
    if (!activeGroup) {
      // console.log('issue on group activities groupActivityContent', {userId, groupActivities});
    }
    // console.log({activeGroup})
    if (activeGroup?.length > 0) {
      currentGroup = group;
    }
  });

  return {
    lessonId,
    questions,
    scores,
    answers: GroupActivitySelector.selectAnswers(state, lessonId),
    isActivityNavigation: LeftSideBarSelector.getPracticeNavigation,
    userId,
    correctAnswer: GroupActivitySelector.selectReviewCorrectResponse(state),
    groupArray,
    currentGroupStudents: currentGroup?.students || [],
  };
};
const mapDispatchToProps = {
  getOnlineActivityQuestions: actions.getOnlineActivityQuestions,
  getGroupStudentReview: actions.getGroupStudentReview,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupActivityContent));
