import React from 'react';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { TabProvider, TabList, Tab, TabPanel } from '../Shared/FancyTab';
import { ContainerItem, DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { useIWContext } from './IWContextProvider';
import { Button } from '../Generals/stylesheets/Button.style';
import { FormInputStyled } from '../Generals/stylesheets/Form.style';
import { useSectionByIWId } from './hooks';
import { sectionAPI } from './api';
import CriteriaList from './Components/CriteriaList';

const CustomInput = styled(FormInputStyled)`
  border: none !important;
  color: inherit !important;
  min-width: 20vw;
  &:focus {
    border: none !important;
  }
`;
const CustomIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${(props) => props.theme.colorStyled[props.color] || 'inherit'};
    padding: 0.5rem;
  }
`;

const InstructionalWalkThroughBuilder = () => {
  const { currentPageIWId: IWId } = useIWContext();
  const { data: sectionData, invalidate } = useSectionByIWId({ IWId, defaultValue: [] });
  const createSection = () => {
    sectionAPI.create({ displayName: 'new Section', IWId, note: '' });
    invalidate();
  };
  const deleteSection = (id) => {
    sectionAPI.delete(id);
    invalidate();
  };
  const editSectionTitle = (section, displayName) => {
    sectionAPI.put({ ...section, displayName });
    invalidate();
  };

  return (
    <div>
      <ContainerItem>
        <TabProvider defaultTab={sectionData?.[0]?.id || null}>
          <TabList>
            {sectionData?.map((section, index) => (
              <Tab key={section.id} tabKey={section.id}>
                <span style={{ display: 'flex', flexWrap: 'nowrap', flex: 1 }}>
                  <CustomInput
                    label=""
                    type="text"
                    onChange={(event) => editSectionTitle(section, event.target.value)}
                    defaultValue={section.displayName}
                  />
                  <CustomIconButton
                    onClick={() => deleteSection(section.id)}
                    fontSize="small"
                    color="ColorRedDel"
                  >
                    <i className="chalktalk-icon-delete" />
                  </CustomIconButton>
                </span>
              </Tab>
            ))}
            <Button onClick={createSection} autoHeight nowrap full={!sectionData?.length}>
              Add New Section
            </Button>
          </TabList>
          {sectionData?.map((section) => (
            <TabPanel key={section.id} tabKey={section.id}>
              <DefaultContentWrap>
                <CriteriaList sectionId={section.id} controllable />
              </DefaultContentWrap>
            </TabPanel>
          ))}
        </TabProvider>
      </ContainerItem>
    </div>
  );
};

export default React.memo(InstructionalWalkThroughBuilder);
