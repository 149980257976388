import { useMemo } from 'react';
import { useGetSyllabusAlignment } from 'chalktalk-react/hooks/useGetSyllabusAlignment';
import { LessonRecord } from '../types';

interface UnitObject {
  lessons: Record<string, LessonRecord>;
}

interface SubjectObject {
  units: Record<string, UnitObject>;
}

// This hook is used to get the lesson alignment for the given subjects
// It takes an array of subjects and returns a map of lesson id to a set of records
// involving skills and standards directly and indirectly aligned to the lesson
// See /api/syllabus/${syllabusGroupId}/curriculum-alignment/ for more details
const useLessonAlignment = (subjectIds: number[]) => {
  const { data: alignmentMap } = useGetSyllabusAlignment(subjectIds);

  return useMemo(() => {
    const lessonMap: Record<number, LessonRecord> = {};

    // Return empty map if no alignment data is available
    if (!alignmentMap || Object.keys(alignmentMap).length === 0) {
      return lessonMap;
    }

    // Process subjects, units, and lessons using array methods instead of for-of loops
    Object.values(alignmentMap as Record<string, SubjectObject>).forEach(({ units }) => {
      Object.values(units as Record<string, UnitObject>).forEach(({ lessons }) => {
        Object.entries(lessons).forEach(([lessonId, lessonData]) => {
          // Store lesson data in our flat map using the lesson ID as key
          lessonMap[lessonId] = lessonData;
        });
      });
    });

    return lessonMap;
  }, [alignmentMap]);
};

export default useLessonAlignment;
