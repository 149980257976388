import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import * as PracticeAPI from '../../apis/practice';
import * as SectionAPI from '../../apis/sections';

import {
  PRACTICE_REPORT_GET_DETAIL,
  PRACTICE_REPORT_GET_OF_USER,
  actions as practiceReportActions,
} from '../../reducers/practiceReport';
/**
 * ! Need update callback
 */
function* practiceReportGetDetail({ practiceSessionId, sectionId }) {
  const { response, error } = yield call(SectionAPI.getPracticeSessionReport, sectionId, practiceSessionId);

  if (response) {
    const { data = {} } = response;
    yield put(practiceReportActions.practiceReportGetDetailSuccess(data));
  } else {
    yield put(practiceReportActions.practiceReportGetDetailFail(error));
  }
}

function* practiceReportGetOfUser({ sectionId, practiceSessionId }) {
  const { response, error } = yield call(SectionAPI.getPracticeSessionReport, sectionId, practiceSessionId);

  if (response) {
    const { data = {} } = response;
    yield put(practiceReportActions.practiceReportGetDetailSuccess(data));
  } else {
    yield put(practiceReportActions.practiceReportGetDetailFail(error));
  }
}

export default function* practiceReportSaga() {
  yield takeLeading(PRACTICE_REPORT_GET_DETAIL, practiceReportGetDetail);
  yield takeLeading(PRACTICE_REPORT_GET_OF_USER, practiceReportGetOfUser);
}
