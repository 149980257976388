import styled from 'styled-components';

export const OnlineQuestionWrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: ${({ activity }) =>
    activity === "online-group" ? "45em" : "36em"};
  transition: all 300ms ease;
  height: 100%;
  @media (max-width: 768px) {
    height: 100%;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
  }
`;

export const OnlineQuestionWrapperMobile = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 36em;
  transition: all 300ms ease;
  position:relative;
  overflow: hidden;
  
  @media (max-width: 1280px) {
    max-width: unset;
    width: 90%;
    margin: auto;
  }
  
  @media (max-width: 768px) {
    height: calc(100% - 1em)
  }
`;
