import { Box, Divider, Tooltip, Typography } from '@mui/material';
import React from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { cleanText } from '@components/QuestionGenerationService/utils/helpers';
import ShowIf from '@components/Atoms/ShowIf';
import { Passage } from './types';
import { MODES } from './Passages';
import { PassageSource } from './PassageList';

const PassageListItem: React.FC<{
  passage: Passage;
  switchMode: (mode: string, passage: Passage) => void;
  source: string;
  inDialog?: boolean;
}> = ({ passage, switchMode, source, inDialog }) => {
  const passageText = cleanText(passage.data.content) || 'Passage';

  const getPassageHeading = () => {
    const { data } = passage;
    if (data.heading) {
      return data.heading;
    }
    const title = passageText.substring(0, 100);
    return `${title}...`;
  };

  return (
    <>
      <Box
        key={passage.reference}
        sx={{
          display: 'flex',
          py: '1rem',
          alignItems: 'flex-end',
          gap: '1rem',
          pr: '1rem',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              pb: '0.5rem',
            }}
          >
            <Typography variant="h3" sx={{ color: 'black', fontWeight: 'bold' }}>
              {getPassageHeading()}
            </Typography>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip title="View Passage" placement="top">
                <RemoveRedEyeIcon
                  sx={{ color: 'primary.main', cursor: 'pointer' }}
                  onClick={() =>
                    switchMode(MODES.PASSAGE_MODE, {
                      ...passage,
                      chalktalk_passage: source === PassageSource.CHALKTALK_PASSAGES,
                    })
                  }
                />
              </Tooltip>

              <ShowIf If={!inDialog}>
                <Tooltip title="View questions with this passage" placement="top">
                  <ViewStreamIcon
                    sx={{ color: 'primary.main', cursor: 'pointer' }}
                    onClick={() => switchMode(MODES.PASSAGE_ITEMS_MODE, passage)}
                  />
                </Tooltip>
              </ShowIf>

              <Tooltip title="Select this passage" placement="top">
                <AddTaskIcon
                  sx={{ color: 'primary.main', cursor: 'pointer' }}
                  onClick={() => switchMode(MODES.PASSAGE_SELECT_MODE, passage)}
                />
              </Tooltip>
            </Box>
          </Box>
          <Typography
            variant="body1"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: '1.5rem',
              maxHeight: '4.5rem',
            }}
          >
            {passageText}
          </Typography>
        </Box>
      </Box>
      <Divider orientation="horizontal" variant="fullWidth" />
    </>
  );
};

export default PassageListItem;
