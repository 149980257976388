import React from 'react';
import ContentFilters from './ContentFilters';

// Wrapper for ContentFilters component so it can be used as a ref
const ContentFilterElement = React.forwardRef<HTMLDivElement>((props, ref) => (
  <ContentFilters {...props} ref={ref} />
));

ContentFilterElement.displayName = 'ContentFilterElement';

export default ContentFilterElement;
