import React from 'react';
import { useDrop } from 'react-dnd';
import { absentRowIndex } from '../AbsentRow';
import { StyledCell } from './Cell.style';

type Props = {
  id: number;
  rowId: number;
  onDropCell?: (
    sourceId: number,
    sourceRowId: number,
    targetId: number,
    targetRowId: number,
  ) => void;
  children?: React.ReactNode | React.ReactNode[];
  disableDrop?: boolean;
};

const Cell = ({ id, rowId, onDropCell, children, disableDrop = false }: Props) => {
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: 'GROUPNAME',
    // add the constrains to drop
    // we dont want to drop from the same row
    // we dont want to drop from the absent row
    // we dont want to drop if the parent disableDrop is true
    // we want to drop only if the onDropCell is defined

    canDrop: ({ sourceRowId }) =>
      !disableDrop &&
      sourceRowId !== rowId &&
      sourceRowId !== absentRowIndex &&
      !!onDropCell,
    drop: ({ sourceId, sourceRowId }) => {
      onDropCell?.(sourceId, sourceRowId, id, rowId);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: false }),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <StyledCell
      className="table-cell"
      data-testid="cell"
      key={id}
      ref={dropRef}
      isOver={isOver && canDrop}
    >
      {children}
    </StyledCell>
  );
};

export default Cell;
