export const SCHEDULE_LESSON_FORM_SAVE = 'scheduleForms/SAVE';
export const SCHEDULE_LESSON_FORM_NEXT_STEP = 'scheduleForms/NEXT_STEP';
export const SCHEDULE_LESSON_FORM_PREV_STEP = 'scheduleForms/PREV_STEP';

export const SCHEDULE_LESSON_FORM_SUBMIT = 'scheduleForms/FORM_SUBMIT';
export const SCHEDULE_LESSON_FORM_SUBMIT_SUCCESS = 'scheduleForms/FORM_SUBMIT_SUCCESS';
export const SCHEDULE_LESSON_FORM_SUBMIT_FAIL = 'scheduleForms/FORM_SUBMIT_FAIL';

// selectors

const getCurrentStep = ({ scheduleForms }) => scheduleForms.currentStep;
const getFormList = ({ scheduleForms }) => scheduleForms.forms || [];
const getFormByStep = ({ scheduleForms }, step) => (scheduleForms.forms || [])[step] || {};

const isSubmitting = ({ scheduleForms }) => scheduleForms.isSubmitting;
const getSubmitError = ({ scheduleForms }) => scheduleForms.submitError;

export const selectors = {
  getCurrentStep,
  getFormList,
  getFormByStep,

  isSubmitting,
  getSubmitError,
};

// action creators

// MISC

const saveForm = formData => ({
  type: SCHEDULE_LESSON_FORM_SAVE,
  formData,
});

const nextStep = () => ({
  type: SCHEDULE_LESSON_FORM_NEXT_STEP,
});

const prevStep = () => ({
  type: SCHEDULE_LESSON_FORM_PREV_STEP,
});

// SUBMIT FORMS
const submitLessonForm = ({ sectionId }) => ({
  type: SCHEDULE_LESSON_FORM_SUBMIT,
  sectionId,
});

const submitLessonFormSuccess = () => ({
  type: SCHEDULE_LESSON_FORM_SUBMIT_SUCCESS,
});

const submitLessonFormFail = errorMsg => ({
  type: SCHEDULE_LESSON_FORM_SUBMIT_FAIL,
  errorMsg,
});

export const actions = {
  saveForm,
  nextStep,
  prevStep,

  submitLessonForm,
  submitLessonFormSuccess,
  submitLessonFormFail,
};

const initialState = {
  forms: [],
  currentStep: 0,
  isSubmitting: false,
  submitError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_LESSON_FORM_SAVE: {
      const { formData } = action;
      const { currentStep } = state;
      const newForms = [...state.forms];

      newForms[currentStep] = formData;
      return {
        ...state,
        forms: newForms,
      };
    }

    case SCHEDULE_LESSON_FORM_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case SCHEDULE_LESSON_FORM_PREV_STEP: {
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }

    case SCHEDULE_LESSON_FORM_SUBMIT: {
      return {
        ...state,
        isSubmitting: true,
        submitError: null,
      };
    }

    case SCHEDULE_LESSON_FORM_SUBMIT_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        currentStep: 0,
      };
    }

    case SCHEDULE_LESSON_FORM_SUBMIT_FAIL: {
      const { errorMsg: submitError } = action;
      return {
        ...state,
        isSubmitting: false,
        submitError,
      };
    }

    default:
      return state;
  }
}
