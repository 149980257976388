import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';

export const SECTION_ATTENDANCE_GET_STANDARD = 'sectionAttendance/GET_STANDARD';
export const SECTION_ATTENDANCE_GET_STANDARD_SUCCESS = 'sectionAttendance/GET_STANDARD_SUCCESS';
export const SECTION_ATTENDANCE_GET_STANDARD_FAIL = 'sectionAttendance/GET_STANDARD_FAIL';

export const SECTION_ATTENDANCE_SUBMIT = 'sectionAttendance/SECTION_ATTENDANCE_SUBMISSION';
export const SECTION_ATTENDANCE_SUBMIT_SUCCESS = 'sectionAttendance/SECTION_ATTENDANCE_SUBMIT_SUCCESS';
export const SECTION_ATTENDANCE_SUBMIT_FAIL = 'sectionAttendance/SECTION_ATTENDANCE_SUBMIT_FAIL';

const sectionAttendanceGetStandard = sectionId => ({
  type: SECTION_ATTENDANCE_GET_STANDARD,
  sectionId,
});

const sectionAttendanceGetStandardSuccess = attendanceInfo => ({
  type: SECTION_ATTENDANCE_GET_STANDARD_SUCCESS,
  attendanceInfo,
});

const sectionAttendanceGetStandardFail = error => ({
  type: SECTION_ATTENDANCE_GET_STANDARD_FAIL,
  error,
});

const sectionAttendanceSubmit = (sectionId, sectionAttendanceInfo) => ({
  type: SECTION_ATTENDANCE_SUBMIT,
  sectionAttendanceInfo,
  sectionId,
});

const sectionAttendanceSubmitSuccess = (payload) => ({
  type: SECTION_ATTENDANCE_SUBMIT_SUCCESS,
  payload,
});

const sectionAttendanceSubmitFail = error => ({
  type: SECTION_ATTENDANCE_SUBMIT_FAIL,
  error,
});

export const actions = {
  sectionAttendanceGetStandard,
  sectionAttendanceGetStandardSuccess,
  sectionAttendanceGetStandardFail,

  sectionAttendanceSubmit,
  sectionAttendanceSubmitSuccess,
  sectionAttendanceSubmitFail,
};

const getAttendanceBySectionId = ({ sectionAttendance }) => _get(sectionAttendance, 'attendanceBySectionId', {});
const getRecordAttendance = ({ sectionAttendance }, sectionId) => {
  const attendanceInfo = _get(sectionAttendance, ['attendanceBySectionId', sectionId], {});
  const { records = [] } = attendanceInfo;
  return _keyBy(records, 'user');
};
const getTimeSubmit = ({ sectionAttendance }, sectionId) => _get(sectionAttendance, ['attendanceBySectionId', sectionId, 'recorded'], '');

export const selectors = {
  getRecordAttendance,
  getAttendanceBySectionId,
  getTimeSubmit,
};

const initialState = {
  isGetStandard: false,
  attendanceBySectionId: {},
  submitSuccess: false,
  error: null,
};

export default function sectionAttendanceReducer(state = initialState, action) {
  switch (action.type) {
    case SECTION_ATTENDANCE_GET_STANDARD: {
      return {
        ...state,
        isGetStandard: true,
      };
    }

    case SECTION_ATTENDANCE_GET_STANDARD_SUCCESS: {
      const { attendanceInfo } = action;
      const { section } = attendanceInfo;
      const tempArr = section.split('/');
      const sectionId = tempArr[tempArr.length - 2];
      return {
        ...state,
        isGetStandard: false,
        attendanceBySectionId: {
          ...state.attendanceBySectionId,
          [sectionId]: attendanceInfo,
        },
      };
    }

    case SECTION_ATTENDANCE_GET_STANDARD_FAIL: {
      return {
        ...state,
        isGetStandard: false,
      };
    }

    case SECTION_ATTENDANCE_SUBMIT: {
      return {
        ...state,
        submitSuccess: false,
      };
    }

    case SECTION_ATTENDANCE_SUBMIT_SUCCESS: {
      const { payload } = action;
      const { section } = payload;
      const tempArr = section.split('/');
      const sectionId = tempArr[tempArr.length - 2];
      return {
        ...state,
        isGetStandard: false,
        attendanceBySectionId: {
          ...state.attendanceBySectionId,
          [sectionId]: payload,
        },
      };
    }

    case SECTION_ATTENDANCE_SUBMIT_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
        submitSuccess: false,
      };
    }

    default:
      return state;
  }
}
