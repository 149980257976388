/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { LRUCache } from 'lru-cache';
import { handleRefreshToken } from './auth';
import { actions } from '../reducers/auth/authReducer';

const defaultAdapter = axios.defaults.adapter;

const cache = new LRUCache({
  max: 100,
  maxAge: 1000 * 1, // 1 seconds cache , we just need to cache  request
  ttl: 1000 * 1,
});

export const configCacheInterceptors = () => {
  axios.defaults.adapter = function (config) {
    return new Promise((resolve, reject) => {
      const cachedResponse = cache.get(config.url);
      if (cachedResponse && config.method === 'get' && !config.noCache) {
        resolve({ ...cachedResponse, cached: true });
      } else {
        defaultAdapter(config)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  };

  axios.interceptors.response.use((response) => {
    const { url, method } = response.config;
    if (method !== 'get') {
      return response;
    }
    cache.set(url, response);
    return Promise.resolve(response);
  });
};
export function configToken(token) {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // add cache
    configCacheInterceptors();
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
}

let refreshedToken = null;
export const configTokenInterceptor = ({ getState, dispatch }) => {
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (!error.config.url.includes('/api/auth/') && error.response?.status === 401) {
        try {
          refreshedToken =
            refreshedToken ||
            handleRefreshToken(getState().auth.refreshToken).then(({ response }) => {
              if (!(response.data.access && response.data.refresh)) {
                throw new Error('Invalid token');
              }
              configToken(response.data.access);
              dispatch(
                actions.refreshLoginToken(response.data.access, response.data.refresh),
              );
              dispatch(actions.authOpenWebsocket());
              return response.data.access;
            });
          const accessToken = await refreshedToken;

          const config = { ...error.config };
          config.headers.Authorization = `Bearer ${accessToken}`;
          refreshedToken = null;
          return axios.request(config);
        } catch (tokenRefreshError) {
          dispatch(actions.logout('/login?sessionExpired'));
        }
      }
      return Promise.reject(error);
    },
  );
};
