export type MessageFn<E> = <Key extends string & keyof E>(message: E[Key]) => void;
type OnTypeFn<E> = <Key extends string & keyof E>(event: Key, fn: MessageFn<E>) => void;
type EmitTypeFn<E> = <Key extends string & keyof E>(event: Key, message: E[Key]) => void;
type OffTypeFn<E> = <Key extends string & keyof E>(event: Key, fn: MessageFn<E>) => void;

class EventEmitter<E> {
  private subscribers: { [key: string]: MessageFn<E>[] } = {};

  public emit: EmitTypeFn<E> = (event, msg) => {
    const subscribers = this.subscribers?.[event] || [];
    if (subscribers.length === 0) {
      return;
    }
    return this.subscribers?.[event].map((h) => h(msg));
  };

  public on: OnTypeFn<E> = (event, callback) => {
    const list = this.subscribers[event] ?? [];
    list.push(callback);
    this.subscribers[event] = list;
  };

  public off: OffTypeFn<E> = (event, callback) => {
    let list = this.subscribers[event] ?? [];
    list = list.filter((h) => h !== callback);
    this.subscribers[event] = list;
  };
}

export default EventEmitter;
