import { connect } from 'react-redux';
import ReviewerAuthRoute from '../../components/routes/ReviewerAuthRoute';
import {
  selectors as AuthSelectors,
} from '../../reducers/auth';

const mapStateToProps = state => ({
  authenticated: AuthSelectors.getAuthenticatedStatus(state),
});

export default connect(mapStateToProps)(ReviewerAuthRoute);
