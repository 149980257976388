import React from 'react';
import Dialog from '@Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const LessonCompleteDialog = ({ isOpen, onClose, onConfirm }: Props) => (
  <Dialog
    title="Lesson Marked As Started"
    open={isOpen}
    dialogActionsSx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 1,
    }}
    fullWidth
    maxWidth="sm"
    helperText={
      <Typography
        sx={{
          color: 'inherit',
          fontSize: 'inherit',
        }}
      >
        If you were only browsing the lesson and have not yet taught any part of it, you
        can revert its status to&nbsp;
        <Typography
          sx={{
            fontWeight: 'bold',
            display: 'inline',
            fontSize: 'inherit',
            color: 'inherit',
          }}
        >
          Not Started
        </Typography>
        .
      </Typography>
    }
    onClose={onClose}
    actions={[
      {
        label: 'Mark as Not Started',
        onClick: onConfirm,
        buttonProps: {
          variant: 'text',
        },
      },
      {
        label: 'Close',
        onClick: onClose,
        buttonProps: {
          variant: 'contained',
        },
      },
    ]}
  >
    <Typography>
      This lesson has been marked as&nbsp;
      <Typography
        sx={{
          fontWeight: 'bold',
          display: 'inline',
        }}
      >
        Started
      </Typography>
      &nbsp;to help you track your teaching progress.
    </Typography>
  </Dialog>
);

export default LessonCompleteDialog;
