import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _filter from 'lodash/filter';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactDOM from 'react-dom';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import MediaQuery from 'react-responsive';
import AnswerExplanation from '../../containers/AnswerExplanation/AnswerExplanation';
import { SubmitButton } from '../Generals/stylesheets/Button.style';
import {
  PracticeContentWrap,
  PracticeContentGroup,
  PracticeFooterBar,
  ScrollBarStyle,
} from '../PracticePage/PracticePage.style';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { NavigationHeaderWrap } from '../QuestionAssets/QuestionAssets.style';
import QuestionAssets from '../../containers/QuestionAssets';
import OnlineQuestionItem from '../../containers/OnlineTestContent/OnlineQuestionItem';

class OnlineTestContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      questionSelected: props.lastIndex || 0,
      isLightBoxOpen: false,
      shouldDisplayGrammarHelper: false,
      imgSrc: '',
    };
  }

  componentDidMount() {
    const currentNode = ReactDOM.findDOMNode(this); // eslint-disable-line

    if (currentNode) {
      currentNode.addEventListener('click', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    const currentNode = ReactDOM.findDOMNode(this); // eslint-disable-line

    if (currentNode) {
      currentNode.removeEventListener('click', this.handleClick, false);
    }
  }

  openLightBox = (imgSrc) => {
    this.setState({
      isLightBoxOpen: true,
      imgSrc,
    });
  };

  closeLightbox = () => {
    this.setState({
      isLightBoxOpen: false,
      imgSrc: '',
    });
  };

  handleClick = (e) => {
    const { isLightBoxOpen } = this.state;
    const targetTagName = _get(e, 'target.tagName', '');

    if (targetTagName === 'IMG' && !isLightBoxOpen) {
      const src = _get(e, 'target.src', '');
      this.openLightBox(src);
    }
  };

  nextQuestion = () => {
    this.setState((prevState) => ({
      questionSelected: prevState.questionSelected + 1,
      shouldDisplayGrammarHelper: false,
    }));
  };

  prevQuestion = () => {
    this.setState((prevState) => ({
      questionSelected: prevState.questionSelected - 1,
      shouldDisplayGrammarHelper: false,
    }));
  };

  displayGrammarHelper = (shouldDisplayGrammarHelper) => {
    this.setState({
      shouldDisplayGrammarHelper,
    });
  };

  renderQuestionAssets = () => {
    const {
      questions,
      assets,
      openReportErrorModal,
    } = this.props;
    const { questionSelected } = this.state;
    const questionSelectedObj = questions[questionSelected];
    const assetsIds = _get(questionSelectedObj, 'question.shared_assets', []);
    const assetSelected = _filter(assets, (assetItem) => assetsIds.find((id) => id === assetItem.id));

    const isAssetEmpty = assetSelected.length === 0;
    const questionId = _get(questionSelectedObj, 'id');
    if (isAssetEmpty) {
      return null;
    }

    return (
      <QuestionAssets
        hasFooter
        assets={assetSelected}
        displayGrammarHelper={this.displayGrammarHelper}
        questionSelected={questionSelected}
        openReportErrorModal={openReportErrorModal}
        questionId={questionId}
      />
    );
  };

  renderMainContent = () => {
    const {
      openReportErrorModal,
      questions,
      isPracticeNavigation,
    } = this.props;
    const totalQuestions = questions.length;

    const questionSelectedObj = questions[this.state.questionSelected];
    const questionOrder = _get(questionSelectedObj, 'order', null);
    const questionId = _get(questionSelectedObj, 'id');

    return (
      <PracticeContentGroup isToggleNavigation={isPracticeNavigation}>
        <OnlineQuestionItem
          questionSelected={questionSelectedObj}
          totalQuestions={totalQuestions}
          nextQuestion={this.nextQuestion}
          prevQuestion={this.prevQuestion}
          index={this.state.questionSelected}
          order={questionOrder}
          shouldDisplayGrammarHelper={this.state.shouldDisplayGrammarHelper}
          openReportErrorModal={openReportErrorModal}
          questionId={questionId}
        />
      </PracticeContentGroup>
    );
  }

  render() {
    const {
      isLightBoxOpen,
      imgSrc,
      questionSelected,
    } = this.state;
    const {
      questions, completeTest,
    } = this.props;
    const totalQuestions = questions.length;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(questionSelectedObj, 'question.shared_assets', []);

    if (totalQuestions === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <PracticeContentWrap
          ref={(ref) => {
            this.container = ref;
          }}
        >
          <MediaQuery maxWidth={768}>
            {matches => matches ? (
              <div>
                {assets.length > 0
                  && (
                    <Tabs defaultIndex={1}>
                      <NavigationHeaderWrap>
                        <TabList>
                          <Tab>Assets</Tab>
                          <Tab>Show Questions</Tab>
                        </TabList>
                      </NavigationHeaderWrap>
                      <TabPanel>{this.renderQuestionAssets()}</TabPanel>
                    </Tabs>
                  )}
                <ShadowScrollbar
                  autoHide
                  style={ScrollBarStyle}
                >
                  {this.renderMainContent()}
                </ShadowScrollbar>
              </div>
            ) : (
              <div>
                {this.renderQuestionAssets()}
                {this.renderMainContent()}
              </div>
            )}
          </MediaQuery>
        </PracticeContentWrap>
        <PracticeFooterBar>
          <SubmitButton onClick={completeTest}>Submit</SubmitButton>
        </PracticeFooterBar>
        {isLightBoxOpen && <Lightbox mainSrc={imgSrc} onCloseRequest={this.closeLightbox} />}
      </React.Fragment>
    );
  }
}

OnlineTestContent.propTypes = {
  questions: PropTypes.array,
  completeTest: PropTypes.func,
  assets: PropTypes.array,
  lastIndex: PropTypes.any,
  openReportErrorModal: PropTypes.func,
};

export default OnlineTestContent;
