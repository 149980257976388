import React, { createRef, lazy, useCallback, useState } from 'react';
import { Question } from 'chalktalk-react/learnosityassessment/AssessmentServiceAPI';
import withErrorBoundary from '../withErrorBoundary';
import useThrowError from '../../Hooks/useThrowError';
import { AssessmentPlaceholder } from './Assessment.style';

const ItemBankHarness = lazy(() =>
  import('../ItemBankHarness').then((module) => ({ default: module.ItemBankHarness })),
);

interface AssessmentProps {
  sessionId: string;
  name: string;
  title: string;
  subtitle: string;
  submissionHandler?: () => void;
  userId: string;
  activityId: string;
  handleActivityExit?: () => void;
  questions: Question[] | undefined;
}

const validateAttemptedQuestions = (widget: any) => {
  const items = widget.questions();
  Object.values(items).forEach((question: any) => {
    if (question.isAttempted()) {
      question.validate({
        showCorrectAnswers: true,
        showDistractorRationale: {
          perQuestion: 'always',
          perResponse: 'always',
        },
        feedbackAttempts: true,
      });
      question.disable();
    }
  });
};
const Assessment: React.FC<AssessmentProps> = ({
  userId,
  sessionId,
  activityId,
  name,
  title,
  subtitle,
  submissionHandler,
  handleActivityExit,
  questions,
}) => {
  const [submissionComplete, setSubmissionComplete] = useState(false);
  const widgetRef = createRef<any>();
  const throwError = useThrowError();

  const handleSuccess = useCallback(() => {
    submissionHandler?.();
    setSubmissionComplete(true);
  }, [submissionHandler]);

  const renderAssessment = questions && questions.length > 0 && !submissionComplete;
  if (submissionComplete) {
    return (
      <AssessmentPlaceholder>
        <span>Practice Complete!</span>
      </AssessmentPlaceholder>
    );
  }
  if (!questions?.length) {
    return (
      <AssessmentPlaceholder>
        <span>No More Practice Questions Available!</span>
      </AssessmentPlaceholder>
    );
  }
  return (
    <div>
      {renderAssessment && (
        <ItemBankHarness
          ref={widgetRef}
          userId={userId}
          sessionId={sessionId}
          activityId={activityId}
          renderingType="assess"
          type="submit_practice"
          name={name}
          state="initial"
          questionReferences={questions}
          config={{
            configuration: {
              lazyload: false,
              onsave_redirect_url: false,
              onsubmit_redirect_url: false,
            },
            questions_api_init_options: {
              attribute_overrides: {
                instant_feedback: false,
              },
              showCorrectAnswers: true,
              show_distractor_rationale: {
                per_question: 'incorrect',
                per_response: 'always',
              },
            },
            regions: {
              'bottom-right': [
                {
                  options: {
                    label: 'Back',
                    name: 'chalktalk_back',
                  },
                  type: 'custom_button',
                },
                {
                  options: {
                    label: 'Next',
                    name: 'chalktalk_next',
                  },
                  type: 'custom_button',
                },
              ],
              items: [
                {
                  type: 'progress_element',
                },
              ],
              right: [
                {
                  type: 'save_button',
                },
                {
                  type: 'accessibility_button',
                },
                {
                  type: 'verticaltoc_element',
                },
              ],
              'top-left': [
                {
                  type: 'title_element',
                },
              ],
              'top-right': [
                {
                  position: 'right',
                  type: 'pause_button',
                },
                {
                  type: 'timer_element',
                },
                {
                  type: 'itemcount_element',
                },
              ],
            },
            subtitle,
            title,
          }}
          errorCallback={(error) => {
            throwError(error);
          }}
          eventHandlers={[
            {
              callback: (widget) => {
                widget.items().previous();
              },
              name: 'button:chalktalk_back:clicked',
            },
            {
              callback: (widget, eventDetail) => {
                const currentItemIndex = eventDetail[0];
                if (currentItemIndex === 0) {
                  return;
                }
                const currentItem = widget.getCurrentItem();
                const currentResponseId = currentItem.response_ids[0];
                const currentQuestion = widget.question(currentResponseId);
                if (!currentQuestion.isAttempted()) {
                  alert('You must answer all items completely!');
                  widget.items().goto(currentItemIndex);
                  return;
                }
                if (currentQuestion.isEnabled()) {
                  currentQuestion.validate({
                    showCorrectAnswers: true,
                    showDistractorRationale: {
                      perQuestion: 'always',
                      perResponse: 'always',
                    },
                    feedbackAttempts: true,
                  });

                  currentQuestion.disable();
                  widget.save();
                } else {
                  widget
                    .items()
                    .next()
                    .then(null, () => {
                      widget.assessApp().on('button:cancel_submit:clicked', () => {
                        widget.assessApp().dialogs().custom.hide();
                      });

                      widget.assessApp().on('button:submit_my_answers:clicked', () => {
                        widget.submit({
                          // Submit Learnosity Ends, Tell Django to pull scores then redirect to review
                          success() {
                            handleSuccess();
                            widget.reset();
                            // TODO need to unset the app as well?
                          },
                          error(e: any) {
                            console.log('submit has failed', e);
                          },
                          progress(e: any) {
                            console.log('progress', e);
                          },
                          show_submit_confirmation: false, // somehow using the confirmation and ui i cant force the async complete api and redirect
                          show_submit_ui: false,
                        });
                      });

                      widget
                        .assessApp()
                        .dialogs()
                        .custom.show({
                          header: 'Submit Answers?',
                          body: 'Are you sure you want to submit your practice for scoring?',
                          buttons: [
                            {
                              button_id: 'submit_my_answers',
                              label: 'Yes',
                              is_primary: true,
                            },
                            {
                              button_id: 'cancel_submit',
                              label: 'Back',
                              is_primary: false,
                            },
                          ],
                        });
                    });
                }
              },
              name: 'button:chalktalk_next:clicked',
            },
            {
              callback: (widget) => {
                validateAttemptedQuestions(widget);
                setTimeout(() => {
                  widget.items().goto(0);
                }, 100);
              },
              name: 'test:ready',
            },
            {
              callback: () => {
                handleActivityExit?.();
              },
              name: 'test:finished:save',
            },
          ]}
        />
      )}
    </div>
  );
};

const wrappedAssessment = withErrorBoundary<AssessmentProps>(
  React.memo(Assessment) as typeof Assessment,
  'Something went wrong, please contact support.',
);
export { wrappedAssessment as Assessment, AssessmentProps };
