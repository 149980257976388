import styled from 'styled-components';

export const CourseSelectWrap = styled.div`
  font-size: 15px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .course-select__control {
    border: none;  
    cursor: pointer;
  }
  .course-select__control--is-focused {
    box-shadow: none;
  }
  .course-select__indicator-separator {
    display: none;
  }
  .course-select__indicator {
    padding: 4px;
    color: ${props => props.theme.colorStyled.ColorPrimary};
    svg {
      width: 1em !important;
    }
  }
  .course-select__option--is-focused {
    background-color: #bebebe;
    color: ${props => props.theme.colorStyled.ColorWhite};
  }
  .course-select__value-container {
    overflow: visible;
    > div {
      white-space: normal;
      &:last-child {
        margin: 0;
      }
    }
  }
  .course-select__menu {
    top: 20px;
  }
  .course-select__single-value{
    position: relative;
    transform: none;
  }
  .course-select__input {
    color: transparent;
  }
`;
