import EventEmitter, { MessageFn as defaultMessageFnType } from '@utils/eventEmitter';
import { updateGroupStatusPayloadType } from '../types';

export enum OLPGroupsEvents {
  UPDATE_STUDENT_ACTIVITY_STATUS = 'UPDATE_STUDENT_ACTIVITY_STATUS',
}

type OLPGroupsEventEmitterType = {
  [OLPGroupsEvents.UPDATE_STUDENT_ACTIVITY_STATUS]: updateGroupStatusPayloadType;
};
export type MessageFnType = defaultMessageFnType<OLPGroupsEventEmitterType>;

const OLPGroupsEventEmitter = new EventEmitter<OLPGroupsEventEmitterType>();
export default OLPGroupsEventEmitter;
