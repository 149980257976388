/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import ColumnSize from './ColumnSize';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  TableCheckboxLabel,
  TableCheckbox,
  TableCheckMark,
  TableSkillLevel,
  MobileWrapper,
} from '../Table/TableComponent.style';
import { NotApplicationText } from '../DashboardSummary/DashboardSummary.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { NoContent } from '../Generals/stylesheets/General.style';
import { Button } from '../Generals/stylesheets/Button.style';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'Student Name',
  },
  {
    uniqKey: 'practiceCount',
    value: '# Practice Sessions',
  },
  {
    uniqKey: 'score',
    value: 'Score',
  },
  {
    uniqKey: 'skillLevel',
    value: 'Skill Level',
  },
  {
    uniqKey: 'skillImprovement',
    value: 'Skill Improvement',
  },
  {
    uniqKey: 'lastActivity',
    value: 'Last Practice',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

class LessonStudentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
      selectedUser: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedSession } = this.props;
    const { selectedSession: prevSelectedSession } = prevProps;
    if (selectedSession !== prevSelectedSession) {
      this.setState({
        selectedUser: {},
      });
    }
  }

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  handleSelectUser = (userId) => {
    const { selectedUser } = this.state;
    const currentUser = selectedUser[userId] || {
      id: userId,
      checked: false,
    };
    this.setState({
      selectedUser: {
        ...selectedUser,
        [userId]: {
          ...currentUser,
          checked: !currentUser.checked,
        },
      },
    });
  };

  selectAllUser = (checked) => {
    const { studentData } = this.props;
    const selectedUser = studentData.reduce((acc, student) => {
      acc[student.userId] = {
        checked,
        id: student.userId,
      };
      return acc;
    }, {});

    this.setState({ selectedUser });
  };

  renderStudentHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const { courseDetail, isAdaptiveLearnosityPracticeEnabled } = this.props;
    const isFilterChoosen = sortBy === uniqKey;

    if (
      (uniqKey === 'skillLevel' || uniqKey === 'skillImprovement') &&
      isAdaptiveLearnosityPracticeEnabled
    ) {
      return (
        <TableHeader
          {...ColumnSize[index + 1]}
          value={value}
          key={uniqKey}
          haveFilter={isFilterChoosen}
          onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
        />
      );
    }
    if (
      (uniqKey === 'skillLevel' || uniqKey === 'skillImprovement') &&
      courseDetail?.standard_practice_enabled
    ) {
      return;
    }
    return (
      <TableHeader
        {...ColumnSize[index + 1]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderStudentHeader = () => {
    const { studentData } = this.props;
    const { selectedUser } = this.state;
    const selectedUserList = Object.values(selectedUser);
    const checked =
      studentData.length === selectedUserList.length &&
      selectedUserList.every((u) => u.checked);

    return (
      <TableHeadWrapper>
        <TableHeader {...ColumnSize[0]}>
          <TableCheckboxLabel>
            <TableCheckbox
              type="checkbox"
              checked={checked}
              onChange={() => this.selectAllUser(!checked)}
            />
            <TableCheckMark className="checkmark" />
          </TableCheckboxLabel>
        </TableHeader>
        {tableValues.map(this.renderStudentHeaderItem)}
      </TableHeadWrapper>
    );
  };

  renderCheckBoxInput = ({ userId }) => {
    const { selectedUser } = this.state;

    return (
      <TableCheckboxLabel>
        <TableCheckbox
          type="checkbox"
          checked={!!(selectedUser[userId] || {}).checked}
          onChange={() => this.handleSelectUser(userId)}
        />
        <TableCheckMark className="checkmark" />
      </TableCheckboxLabel>
    );
  };

  renderStudentItem = (student, index) => {
    const { courseDetail, isAdaptiveLearnosityPracticeEnabled } = this.props;
    const hideSkill = courseDetail?.standard_practice_enabled;

    return (
      <TableContentItem key={index}>
        <TableContent {...ColumnSize[0]}>
          {this.renderCheckBoxInput(student)}
        </TableContent>
        <TableContent {...ColumnSize[1]}>{student.studentName}</TableContent>
        <TableContent {...ColumnSize[2]}>{student.practiceCount || '-'}</TableContent>
        <TableContent {...ColumnSize[3]}>{`${student.score || '0'}%`}</TableContent>
        {(!hideSkill || isAdaptiveLearnosityPracticeEnabled) && (
          <>
            <TableContent {...ColumnSize[4]}>
              <TableSkillLevel level={student.skillLevel || false}>
                {student.skillLevel || '-'}
              </TableSkillLevel>
            </TableContent>
            <TableContent
              {...ColumnSize[5]}
              pointGaint={student.skillImprovement || false}
            >
              {student.skillImprovement >= 0 ? student.skillImprovement || '-' : '-'}
            </TableContent>
          </>
        )}
        <TableContent {...ColumnSize[6]}>
          {student.lastActivity ? moment(student.lastActivity).fromNow() : '--'}
        </TableContent>
      </TableContentItem>
    );
  };

  render() {
    const { studentData, selectedSession, isLoading, handleLessonAssignmentBulk } =
      this.props;
    const { orderBy, sortBy, selectedUser } = this.state;
    firstSort(studentData);
    const filterStudentData = _orderBy(
      studentData,
      [
        (d) => {
          if (sortBy === 'last_name') {
            return d[sortBy].toUpperCase();
          }
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    const selectedUserList = Object.values(selectedUser).filter((e) => e.checked);
    const assignStyle = {
      marginBottom: '10px',
    };
    if (isLoading) {
      return (
        <TableContainerWrap>
          <TableLoader />
        </TableContainerWrap>
      );
    }
    return (
      <TableContainerWrap>
        {!!studentData.length > 0 && (
          <MobileWrapper>
            <Button
              blue
              isDisable={!selectedUserList.length}
              disabled={!selectedUserList.length}
              onClick={() => handleLessonAssignmentBulk(selectedUser)}
              style={assignStyle}
            >
              {`Assign ${selectedUserList.length ? `(${selectedUserList.length})` : ''}`}
            </Button>
            {this.renderStudentHeader()}
            <TableContentWrapper>
              {filterStudentData.map(this.renderStudentItem)}
            </TableContentWrapper>
          </MobileWrapper>
        )}
        {!studentData.length && !selectedSession && (
          <NoContent>Select a lesson to view the lesson report</NoContent>
        )}
        {!studentData.length && selectedSession && (
          <NoContent>
            <NotApplicationText>Data not available</NotApplicationText>
          </NoContent>
        )}
      </TableContainerWrap>
    );
  }
}
LessonStudentTable.propTypes = {
  studentData: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string.isRequired,
      studentName: PropTypes.string.isRequired,
      practiceCount: PropTypes.number,
      score: PropTypes.number,
      skillLevel: PropTypes.number,
      skillImprovement: PropTypes.number,
      lastActivity: PropTypes.string,
    }),
  ).isRequired,
  selectedSession: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  handleLessonAssignmentBulk: PropTypes.func.isRequired,
  courseDetail: PropTypes.shape({
    standard_practice_enabled: PropTypes.bool,
  }).isRequired,
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const isAdaptiveLearnosityPracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();

    return (
      <WrappedComponent
        {...props}
        isAdaptiveLearnosityPracticeEnabled={isAdaptiveLearnosityPracticeEnabled}
      />
    );
  };
  return ComponentWithData;
};

export default withDataWrapper(LessonStudentTable);
