import styled from 'styled-components';

export const CourseEditFormWrapper = styled.div`
  width: 100%;
  padding: 2em 1em 1em;
  border: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  border-radius: .325em;
  box-shadow: 0px 0px 10px -4px #d9d9d9;
  position: relative;
`;

export const CourseDetailWrap = styled.div`
  flex: 0 0 22em;
  max-width: 22em;
  margin-right: 1.5em;
`;

export const CourseActionGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  button {
    margin-right: 0.5em;
    width: 100%;
    &:last-child {
      margin-right: 0em;
    }
  }
  .chalktalk-edit {
    width: auto;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
