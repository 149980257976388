import styled from 'styled-components';

export const FormLabelStyles = {
  margin: 0,
  whiteSpace: 'nowrap', 
  width: 'fit-content', 
  '& .MuiTypography-root':{
    fontFamily: 'MontserratLight',
    fontSize: '0.9rem' }
}
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const DateInputWrapper = styled.div`
margin-top: 15px;
.react-datepicker__month-container, .react-datepicker {
  width: 100%;
  height: 100%;
}
.react-datepicker, .react-datepicker__header {
  border: 0;
  background-color: rgb(255, 255, 255);
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  line-height: 2rem;
}
.react-datepicker__current-month {
  margin-bottom: 10px;
}
.react-datepicker__month {
  margin: 0;
}
.react-datepicker__navigation {
  background: url(/images/icon-svg/arrow-right.svg) no-repeat;
  border: none;
}
.react-datepicker__navigation--previous {
  transform: rotate(180deg);
}
.react-datepicker__day--selected {
  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;
  background: #4886FF 0% 0% no-repeat padding-box;
  border-radius: 50%;
  line-height: 2rem;
}
`;
