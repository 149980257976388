import { connect } from 'react-redux';
import ForgotPasswordForm from '../../../components/ForgotPage/ForgotPassword/ForgotPasswordForm';
import {
  selectors as AuthSelectors,
  actions as AuthActions,
} from '../../../reducers/auth';

export const mapStateToProps = state => ({
  errorMessage: AuthSelectors.getSentResetPasswordError(state),
  isLoading: AuthSelectors.isSendingResetPasswordEmail(state),
  isSentResetPasswordEmail: AuthSelectors.isSentResetPasswordEmail(state),
});

const mapDispatchToProps = {
  resetPassword: AuthActions.resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
