export const GROUP_ACTIVITY_START_ACTIVITY = 'groupActivity/START_ACTIVITY';
export const SET_GROUP_ACTIVITIES_INFO = 'groupActivity/SET_GROUP_ACTIVITIES_INFO';
export const SET_GROUP_ACTIVITIES = 'groupActivity/SET_GROUP_ACTIVITIES';
export const GROUP_ACTIVITY_START_ACTIVITY_SUCCESS =
  'groupActivity/START_ACTIVITY_SUCCESS';
export const GROUP_ACTIVITY_START_ACTIVITY_FAILED = 'groupActivity/START_ACTIVITY_FAILED';

export const GROUP_ACTIVITY_END_ACTIVITY = 'groupActivity/END_ACTIVITY';
export const GROUP_ACTIVITY_RESTART_ACTIVITY = 'groupActivity/RESTART_ACTIVITY';
export const GROUP_ACTIVITY_END_ACTIVITY_SUCCESS = 'groupActivity/END_ACTIVITY_SUCCESS';
export const GROUP_ACTIVITY_END_ACTIVITY_FAILED = 'groupActivity/END_ACTIVITY_FAILED';

export const GROUP_ACTIVITY_CREATE = 'groupActivity/CREATE';
export const GROUP_ACTIVITY_UPDATE = 'groupActivity/UPDATE';
export const GROUP_ACTIVITY_GET_LIST = 'groupActivity/GET_LIST';
export const GROUP_ACTIVITY_GET_QUESTIONS = 'groupActivity/GET_QUESTIONS';
export const GROUP_ACTIVITY_SET_QUESTIONS = 'groupActivity/SET_QUESTIONS';

export const GROUP_ACTIVITY_SET_STATUS = 'groupActivity/STATUS';
export const GROUP_ACTIVITY_SET_SCORE = 'groupActivity/GROUP_SCORE';
export const GROUP_ACTIVITY_SET_GROUP_STATUS = 'groupActivity/GROUP_STATUS';
export const GROUP_ACTIVITY_NOTIFY_GROUP_STARTED = 'groupActivity/NOTIFY_GROUP_STARTED';
export const GROUP_ACTIVITY_SET_SCORE_DETAIL = 'groupActivity/GROUP_SCORE_DETAIL';
export const GROUP_ACTIVITY_STATUS_INDIVIDUAL_ACTIVITY =
  'groupActivity/INDIVIDUAL_GROUP_STATUS';
export const GROUP_ACTIVITY_QUESTION_ANSWER = 'groupActivity/ANSWER_QUESTION';
export const GROUP_ACTIVITY_GROUP_QUESTION_ANSWER = 'groupActivity/ANSWER_GROUP_QUESTION';
export const GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED =
  'groupActivity/ANSWER_QUESTION_ANSWERED';
export const GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED_API =
  'groupActivity/ANSWER_QUESTION_ANSWERED_API';
export const GROUP_ACTIVITY_GROUP_QUESTION_ANSWERED =
  'groupActivity/ANSWER_QUESTION_GROUP_ANSWERED';
export const GROUP_ACTIVITY_GET_STUDENT_QUESTIONS =
  'groupActivity/GROUP_ACTIVITY_GET_STUDENT_QUESTIONS';
export const GROUP_ACTIVITY_SET_STUDENT_REVIEW =
  'groupActivity/GROUP_ACTIVITY_SET_STUDENT_REVIEW';
export const GROUP_ACTIVITY_GET_STUDENT_REVIEW =
  'groupActivity/GROUP_ACTIVITY_GET_STUDENT_REVIEW';
export const UPDATE_GROUP_ARRAY = 'groupActivity/UPDATE_GROUP_ARRAY';
export const LOADING_GROUP_ACTIVITY = 'groupActivity/LOADING_GROUP_ACTIVITY';
export const LOADED_GROUP_ACTIVITY = 'groupActivity/LOADED_GROUP_ACTIVITY';
export const LOADING_GROUP_SCORE = 'groupActivity/LOADING_GROUP_SCORE';
export const LOADED_GROUP_SCORE = 'groupActivity/LOADED_GROUP_SCORE';
export const QUESTION_ANSWERED = 'groupActivity/QUESTION_ANSWERED';
export const GROUP_ACTIVITY_NOTIFICATION_DETAILS = 'GROUP_ACTIVITY_NOTIFICATION_DETAILS';
export const GROUP_ACTIVITY_FETCH_STUDENT_SCORE =
  'groupActivity/GROUP_ACTIVITY_FETCH_STUDENT_SCORE';
export const FORCE_SUBMITTED = 'groupActivity/FORCE_SUBMITTED';
export const GROUP_ACTIVITY_SET_GROUP_ACTIVITY_STATUS =
  'groupActivity/GROUP_ACTIVITY_STATUS';

export const GROUP_ACTIVITY_SUBMIT_SCORE_SUMMARY = 'groupActivity/SUBMIT_SCORE_SUMMARY';
export const GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP =
  'groupActivity/RESET_PRACTICE_FOR_GROUP';
export const GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP_SUCCESS =
  'groupActivity/RESET_PRACTICE_FOR_GROUP_SUCCESS';
export const GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP_FAIL =
  'groupActivity/RESET_PRACTICE_FOR_GROUP_FAIL';
// ===============================

// ===============================

const selectGroupQuestions = ({ groupActivity }, lessonId) =>
  groupActivity.groupActivityInfo[lessonId]?.exam_questions || [];

const selectStudentsQuestionScore = ({ groupActivity }, lessonId) =>
  groupActivity.groupActivityInfo[lessonId]?.prev_eqs || [];

const selectGroupActivitys = ({ groupActivity }) => groupActivity.groupActivities || [];
const selectGroupActivityId = ({ groupActivity }) =>
  groupActivity.groupActivityInfo.id || '';
const selectGroupActivityInfo = ({ groupActivity }, lessonId) =>
  groupActivity.groupActivityInfo[lessonId];
const selectAnswers = ({ groupActivity }, lessonId) =>
  groupActivity.groupActivityInfo[lessonId]?.studentAnswered;

const selectSubmitStatus = ({ groupActivity }) => ({
  isSubmitted: groupActivity.isSubmitted,
  isSubmitting: groupActivity.isSubmitting,
});

const selectGroupQuestionResponse = ({
  groupActivity: { student_answer, student_answers, questionId },
}) => ({
  student_answer,
  student_answers,
  questionId,
});

const selectReviewCorrectResponse = ({ groupActivity: { correct_answer } }) =>
  correct_answer;
const alreadyAnswered = ({ groupActivity: { questionAnsweredAlready } }) =>
  questionAnsweredAlready;

const individualQuestionSubmitted = ({
  groupActivity: { individualQuestionSubmitted },
}) => individualQuestionSubmitted;

const getGroupingError = ({ groupActivity }) => groupActivity.groupingError;

export const selectors = {
  selectGroupActivityInfo,
  selectGroupQuestions,
  selectSubmitStatus,
  selectAnswers,
  selectGroupQuestionResponse,
  selectReviewCorrectResponse,
  alreadyAnswered,
  individualQuestionSubmitted,
  getGroupingError,
  selectGroupActivityId,
  selectGroupActivitys,
  selectStudentsQuestionScore,
};
const createGroupActivity = (payload, updatePayload, groupActivityId, lessonId) => ({
  type: GROUP_ACTIVITY_CREATE,
  payload,
  updatePayload: {
    ...updatePayload,
    online_section_activity_id: updatePayload.section_activity_id,
  },
  groupActivityId,
  lessonId,
});
const getOnlineActivityQuestions = (groupId, lessonId) => ({
  type: GROUP_ACTIVITY_GET_QUESTIONS,
  groupId,
  lessonId,
});
const getStudentOnlineActivityQuestions = (
  onlineResponseId,
  userId,
  lessonId,
  setLocalStorage,
) => ({
  type: GROUP_ACTIVITY_GET_STUDENT_QUESTIONS,
  onlineResponseId,
  userId,
  lessonId,
  setLocalStorage,
});

const getGroupActivities = (
  sectionId,
  activityLessonGroupId,
  activityLessonId,
  activityUserId,
  activityUnitId,
  curriculumId,
  isTeacher,
  lessonId,
) => ({
  type: GROUP_ACTIVITY_GET_LIST,
  payload: {
    sectionId,
    activityLessonGroupId,
    activityLessonId,
    activityUserId,
    activityUnitId,
    curriculumId,
    isTeacher,
    lessonId,
  },
});

const updateGroupActivity = (payload, groupActivityId, lessonId) => ({
  type: GROUP_ACTIVITY_UPDATE,
  payload: {
    ...payload,
    online_section_activity_id: payload.section_activity_id,
  },
  groupActivityId,
  lessonId,
});

const updateIndividualGroupActivity = (payload) => ({
  type: GROUP_ACTIVITY_STATUS_INDIVIDUAL_ACTIVITY,
  payload,
});

const startGroupActivity = (groupActivityId, lessonId) => ({
  type: GROUP_ACTIVITY_START_ACTIVITY,
  groupActivityId,
  lessonId,
});

const endGroupActivity = (groupActivityId, lessonId) => ({
  type: GROUP_ACTIVITY_END_ACTIVITY,
  groupActivityId,
  lessonId,
});

const updateGroupStatus = (
  id,
  students,
  group_status,
  score,
  section_activity,
  online_activity_status,
  time_activity_started,
  lesson_group,
  section,
  online_question,
  unit,
  subject_id,
  lesson,
  course_id,
  new_grouping,
) => ({
  type: GROUP_ACTIVITY_SET_GROUP_STATUS,
  payload: {
    id,
    students,
    group_status,
    score,
    section_activity,
    online_activity_status,
    time_activity_started,
    lesson_group,
    section,
    online_question,
    unit,
    subject_id,
    lesson,
    course_id,
    new_grouping,
  },
});

const fetchStudentScore = (
  online_activity_status,
  lesson_group,
  id,
  number_of_question,
) => ({
  type: GROUP_ACTIVITY_FETCH_STUDENT_SCORE,
  payload: {
    online_activity_status,
    lesson_group,
    id,
    number_of_question,
  },
});

const notifyGroupStatus = (
  online_activity_status,
  course_id,
  subject_id,
  section,
  unit,
  lesson_group,
) => ({
  type: GROUP_ACTIVITY_NOTIFY_GROUP_STARTED,
  payload: {
    online_activity_status,
    course_id,
    subject_id,
    section,
    unit,
    lesson_group,
  },
});

const restartGroupActivity = (groupActivityId, lessonId) => ({
  type: GROUP_ACTIVITY_RESTART_ACTIVITY,
  groupActivityId,
  lessonId,
});

const answerGroupActivityQuestion = (
  payload,
  userId,
  onlineResponseId,
  groupId,
  waitForGroupFinish,
  questionId,
  locked,
) => ({
  type: GROUP_ACTIVITY_QUESTION_ANSWER,
  payload,
  userId,
  onlineResponseId,
  groupId,
  waitForGroupFinish,
  questionId,
  locked,
});
const answerGroupActivityGroupQuestion = (payload, waitForGroupFinish, locked) => ({
  type: GROUP_ACTIVITY_GROUP_QUESTION_ANSWER,
  payload,
  waitForGroupFinish,
  locked,
});
const updateGroupActivityScoring = (
  students_number,
  student_answer,
  lesson_id,
  section_id,
) => ({
  type: GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED,
  students_number,
  student_answer,
  lesson_id,
  section_id,
});

const updateGroupActivityGroupScoring = (
  student_answers,
  student_answer,
  exam_question,
  isSubmitted,
) => ({
  type: GROUP_ACTIVITY_GROUP_QUESTION_ANSWERED,
  student_answers,
  student_answer,
  isSubmitted,
  exam_question,
});

const getGroupStudentReview = (questionId, groupId, userId) => ({
  type: GROUP_ACTIVITY_GET_STUDENT_REVIEW,
  questionId,
  groupId,
  userId,
});
const updateGroupArray = (groupArray) => ({
  type: UPDATE_GROUP_ARRAY,
  groupArray,
});

const loadGroupActivity = () => ({ type: LOADING_GROUP_ACTIVITY });
const loadedGroupActivity = () => ({ type: LOADED_GROUP_ACTIVITY });
const loadGroupScore = () => ({ type: LOADING_GROUP_SCORE });
const loadedGroupScore = () => ({ type: LOADED_GROUP_SCORE });

const groupActivityIndividualQuestionAnswered = (payload) => ({
  type: GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED_API,
  payload,
});

const forceSubmitted = () => ({
  type: FORCE_SUBMITTED,
});

const submitScoreSummary = (summary, groupActivityId) => ({
  type: GROUP_ACTIVITY_SUBMIT_SCORE_SUMMARY,
  payload: { groupActivityId, summary },
});

const resetPracticeForGroup = (groupId) => ({
  type: GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP,
  groupId,
});

const resetPracticeForGroupSuccess = (payload) => ({
  type: GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP_SUCCESS,
  payload,
});

const resetPracticeForGroupFail = () => ({
  type: GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP_FAIL,
});

const updateGroupActivityStatus = (online_activity_status, lesson_group) => ({
  type: GROUP_ACTIVITY_SET_GROUP_ACTIVITY_STATUS,
  payload: {
    online_activity_status,
    lesson_group,
  },
});
export const actions = {
  createGroupActivity,
  getGroupActivities,
  updateGroupActivity,
  startGroupActivity,
  endGroupActivity,
  updateGroupStatus,
  notifyGroupStatus,
  getOnlineActivityQuestions,
  restartGroupActivity,
  updateIndividualGroupActivity,
  answerGroupActivityQuestion,
  updateGroupActivityScoring,
  answerGroupActivityGroupQuestion,
  updateGroupActivityGroupScoring,
  getStudentOnlineActivityQuestions,
  getGroupStudentReview,
  updateGroupArray,
  loadGroupActivity,
  loadedGroupActivity,
  loadGroupScore,
  loadedGroupScore,
  groupActivityIndividualQuestionAnswered,
  fetchStudentScore,
  forceSubmitted,
  submitScoreSummary,
  updateGroupActivityStatus,
  resetPracticeForGroup,
  resetPracticeForGroupSuccess,
  resetPracticeForGroupFail,
};

// ===============================

const initialState = {
  groupActivities: [],
  hasGroupActivityStarted: false,
  groupActivityInfo: {},
  updateInfo: [],
  isSubmitting: false,
  isSubmitted: false,
  student_answers: [],
  student_answer: {},
  waitForGroupFinish: false,
  userId: null,
  correct_answer: {},
  loadingGroupActivity: false,
  questionAnsweredAlready: false,
  groupActivityId: undefined,
  activeGroupActivity: {},
  lessonId: null,
  individualQuestionSubmitted: false,
  groupingError: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_GROUP_ACTIVITIES: {
      return {
        ...state,
        loadingGroupActivity: false,
        groupActivities: action.groupActivities,
        groupingError: action.error,
      };
    }
    case GROUP_ACTIVITY_GET_LIST: {
      return {
        ...state,
        groupActivities: [],
        groupingError: null,
      };
    }
    case GROUP_ACTIVITY_SET_QUESTIONS: {
      return {
        ...state,
        groupActivityInfo: {
          ...state.groupActivityInfo,
          [action.lessonId]: action.questions,
        },
        lessonId: action.lessonId,
      };
    }
    case GROUP_ACTIVITY_SET_STATUS: {
      return {
        ...state,
        hasGroupActivityStarted: action.hasStarted,
      };
    }
    case GROUP_ACTIVITY_SET_SCORE: {
      return {
        ...state,
        groupActivityInfo: {
          ...state.groupActivityInfo,
          [action.lessonId]: {
            ...state.groupActivityInfo?.[action.lessonId],
            scoring: [...action.scoring],
          },
        },
      };
    }
    case GROUP_ACTIVITY_SET_SCORE_DETAIL: {
      const { percentile, numberOfSubmission, groupActivitiesInfo } = action;
      return {
        ...state,
        groupActivityInfo: {
          ...state.groupActivityInfo,
          ...groupActivitiesInfo,
          [action.lessonId]: {
            ...state.groupActivityInfo?.[action.lessonId],
            percentile,
            numberOfSubmission,
          },
        },
        percentile,
        numberOfSubmission,
      };
    }
    case SET_GROUP_ACTIVITIES_INFO: {
      return {
        ...state,
        groupActivityInfo: {
          ...state.groupActivityInfo,
          ...action.groupActivitiesInfo,
          [action.groupActivitiesInfo.lesson_group]: {
            ...state.groupActivityInfo?.[action.groupActivitiesInfo.lesson_group],
            ...action.groupActivitiesInfo,
          },
        },
      };
    }
    case GROUP_ACTIVITY_SET_GROUP_STATUS: {
      const {
        online_activity_status,
        time_activity_started,
        id,
        students,
        group_status,
        score,
        section_activity,
        lesson_group,
        new_grouping,
        lesson,
        lesson_group: lessonId,
      } = action.payload;

      let shouldUpdateSubmitted = {};
      if (
        !state.updateInfo?.[0] ||
        state.updateInfo?.[0]?.group_status !== group_status
      ) {
        shouldUpdateSubmitted = {
          isSubmitting: false,
          isSubmitted: true,
        };
      }

      return {
        ...state,
        groupActivityInfo: lessonId
          ? {
              ...state.groupActivityInfo,
              [lessonId]: {
                ...state.groupActivityInfo?.[lessonId],
                online_activity_status:
                  online_activity_status !== undefined
                    ? online_activity_status
                    : state.groupActivityInfo[lessonId].online_activity_status,
                time_activity_started:
                  time_activity_started ||
                  state.groupActivityInfo[lessonId].time_activity_started,
                lesson_group:
                  lesson_group || state.groupActivityInfo[lessonId].lesson_group,
              },
            }
          : state.groupActivityInfo,
        updateInfo:
          group_status !== undefined
            ? [
                {
                  id,
                  students,
                  group_status,
                  score,
                  section_activity,
                },
              ]
            : new_grouping !== undefined
            ? new_grouping
            : undefined,
        ...shouldUpdateSubmitted,
      };
    }
    case GROUP_ACTIVITY_QUESTION_ANSWER: {
      const { waitForGroupFinish, userId, questionId } = action;

      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
        individualQuestionSubmitted: false,
        waitForGroupFinish,
        userId,
        questionId,
        questionAnsweredAlready: false,
      };
    }
    case GROUP_ACTIVITY_GROUP_QUESTION_ANSWER: {
      const { waitForGroupFinish, userId, questionId } = action;

      return {
        ...state,
        isSubmitting: true,
        isSubmitted: false,
        individualQuestionSubmitted: false,
        waitForGroupFinish,
        userId,
        questionId,
        questionAnsweredAlready: false,
      };
    }
    case GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED_API: {
      const { waitForGroupFinish, questionId } = state;
      const { exam_question } = action.payload[action.payload.length - 1];

      return waitForGroupFinish
        ? state
        : {
            ...state,
            individualQuestionSubmitted: exam_question.id === questionId,
          };
    }
    case GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED: {
      const { waitForGroupFinish, questionId } = state;
      const { students_number, student_answer } = action;
      let isSubmitted = false;
      let lessonGroup = state.groupActivityInfo.lesson_group;
      if (!lessonGroup)
        lessonGroup = JSON.parse(localStorage.getItem('oga_student_history')).lessonId;

      const studentsThatAnsweredOGA =
        JSON.parse(localStorage.getItem('studentsThatAnsweredOGA')) || {};
      const answeredThisActivity = studentsThatAnsweredOGA?.[lessonGroup] || [];

      student_answer.map((answer) => {
        if (answeredThisActivity.indexOf(answer.user_id) === -1) {
          answeredThisActivity.push(answer.user_id);
        }
      });

      localStorage.setItem(
        'studentsThatAnsweredOGA',
        JSON.stringify({
          ...studentsThatAnsweredOGA,
          [lessonGroup]: answeredThisActivity,
        }),
      );
      if (waitForGroupFinish) {
        const correctQuestion = student_answer.some(
          (answer) => answer.exam_question_id === questionId,
        );
        // now it is checking in localStorage if the number of users saved is matching the number that it is expected from the students_number
        // if they are saved, means that the students answered the individual phase, then isSubmitted should be true;
        isSubmitted = answeredThisActivity.length === students_number && correctQuestion;
      }

      return {
        ...state,
        isSubmitting: !isSubmitted,
        isSubmitted,
      };
    }
    case FORCE_SUBMITTED: {
      return {
        ...state,
        isSubmitting: false,
        isSubmitted: true,
      };
    }
    case GROUP_ACTIVITY_GROUP_QUESTION_ANSWERED: {
      return {
        ...state,
        student_answer: action.student_answer,
        student_answers: action.student_answers,
        isSubmitting: !action.isSubmitted,
        isSubmitted: action.isSubmitted,
        questionId: action.exam_question,
      };
    }
    case GROUP_ACTIVITY_RESTART_ACTIVITY:
    case GROUP_ACTIVITY_START_ACTIVITY:
    case GROUP_ACTIVITY_END_ACTIVITY: {
      return {
        ...state,
        lessonId: action.lessonId,
      };
    }
    case GROUP_ACTIVITY_CREATE:
    case GROUP_ACTIVITY_UPDATE: {
      console.log(action.groupActivityId);
      return { ...state, groupActivityId: action.groupActivityId };
    }
    case GROUP_ACTIVITY_SET_STUDENT_REVIEW: {
      return {
        ...state,
        student_answers: action.studentAnswers,
        correct_answer: action.correct_answer,
      };
    }
    case UPDATE_GROUP_ARRAY: {
      return {
        ...state,
        groupActivities: action.groupArray,
      };
    }
    case LOADING_GROUP_ACTIVITY: {
      return {
        ...state,
        loadingGroupActivity: true,
      };
    }
    case LOADED_GROUP_ACTIVITY: {
      return {
        ...state,
        loadingGroupActivity: false,
      };
    }
    case LOADING_GROUP_SCORE: {
      return {
        ...state,
        loadingGroupScore: true,
      };
    }
    case LOADED_GROUP_SCORE: {
      return {
        ...state,
        loadingGroupScore: false,
      };
    }
    case QUESTION_ANSWERED: {
      return {
        ...state,
        questionAnsweredAlready: action.answered,
      };
    }
    case GROUP_ACTIVITY_NOTIFICATION_DETAILS: {
      return {
        ...state,
        activeGroupActivity: action.activeGroupActivity,
      };
    }
    case GROUP_ACTIVITY_SET_GROUP_ACTIVITY_STATUS: {
      const { online_activity_status, lesson_group } = action.payload;
      return {
        ...state,
        groupActivityInfo: lesson_group
          ? {
              ...state.groupActivityInfo,
              [lesson_group]: {
                ...state.groupActivityInfo[lesson_group],
                online_activity_status,
              },
              online_activity_status,
            }
          : state.groupActivityInfo,
      };
    }
    case GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP_SUCCESS: {
      const { payload } = action;
      const newGroupActivities = [...state.groupActivities].map((group) =>
        group.id === payload.id ? payload : group,
      );
      return {
        ...state,
        groupActivities: newGroupActivities,
      };
    }
    case GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP_FAIL: {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
