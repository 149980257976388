import React from 'react';
import { call, put, takeLeading, select, takeEvery, all } from 'redux-saga/effects';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { QueryClientEmitter } from '@utils/queryClientHelper';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { getSearchParamsObject } from '@utils/commonUtils';
import * as GroupActivityApi from '../../apis/groupActivity';
import { error as errorToast, info as infoToast } from '../../utils/toast';
import {
  GROUP_ACTIVITY_CREATE,
  GROUP_ACTIVITY_END_ACTIVITY,
  GROUP_ACTIVITY_GET_LIST,
  GROUP_ACTIVITY_SET_STATUS,
  GROUP_ACTIVITY_START_ACTIVITY,
  GROUP_ACTIVITY_UPDATE,
  SET_GROUP_ACTIVITIES,
  SET_GROUP_ACTIVITIES_INFO,
  GROUP_ACTIVITY_GET_QUESTIONS,
  GROUP_ACTIVITY_GET_STUDENT_QUESTIONS,
  GROUP_ACTIVITY_SET_QUESTIONS,
  GROUP_ACTIVITY_RESTART_ACTIVITY,
  GROUP_ACTIVITY_SET_SCORE,
  GROUP_ACTIVITY_SET_SCORE_DETAIL,
  GROUP_ACTIVITY_STATUS_INDIVIDUAL_ACTIVITY,
  GROUP_ACTIVITY_QUESTION_ANSWER,
  GROUP_ACTIVITY_GROUP_QUESTION_ANSWER,
  GROUP_ACTIVITY_SET_STUDENT_REVIEW,
  GROUP_ACTIVITY_GET_STUDENT_REVIEW,
  QUESTION_ANSWERED,
  actions,
  GROUP_ACTIVITY_NOTIFY_GROUP_STARTED,
  GROUP_ACTIVITY_NOTIFICATION_DETAILS,
  GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED,
  GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED_API,
  UPDATE_GROUP_ARRAY,
  GROUP_ACTIVITY_FETCH_STUDENT_SCORE,
  GROUP_ACTIVITY_SUBMIT_SCORE_SUMMARY,
  GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP,
} from '../../reducers/groupActivity';
import {
  CURRICULUM_LESSON_GET_ATTENDANCE,
  CURRICULUM_LESSON_RESET_ATTENDANCE,
} from '../../reducers/curriculumLessons';
import { selectors as userSelectors } from '../../reducers/user';
import { ROLE_TYPE } from '../../utils/enums';
import history from '../../utils/history';

function* createSingleGroup(group) {
  try {
    const response = yield call(GroupActivityApi.createGroupActivity, group);
    return { response };
  } catch (err) {
    return { err };
  }
}

function* createGroupActivity({ payload, updatePayload }) {
  let createResponse = yield Array.isArray(payload)
    ? all(payload.map((group) => call(createSingleGroup, group)))
    : call(GroupActivityApi.createGroupActivity, payload);
  createResponse = Array.isArray(createResponse) ? createResponse : [createResponse];
  const hasError = createResponse.filter((response) => response.error).length;
  if (hasError) {
    const message = _get(
      createResponse.error,
      'response.data.detail',
      'Unexpected error',
    );
    errorToast(`${message}`);
    return;
  }

  const new_groups = createResponse.map(({ response }) =>
    response.data ? response.data : response.response.data,
  );

  const { error: updateError } = yield call(
    GroupActivityApi.updateGroupActivity,
    updatePayload,
  );

  if (updateError) {
    const message = _get(updateError, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }
  yield put({
    type: SET_GROUP_ACTIVITIES,
    groupActivities: [...updatePayload.new_grouping, ...new_groups],
  });
}

function* updateGroupActivity({ payload }) {
  const { error } = yield call(GroupActivityApi.updateGroupActivity, payload);

  if (error) {
    const message = _get(error, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }
  const groupActivities = payload.new_grouping.filter(
    ({ students, id }) => students.length || !id,
  );
  yield put({
    groupActivities,
    type: SET_GROUP_ACTIVITIES,
  });
}
function* getOnlineQuestionsStudent({
  onlineResponseId,
  userId,
  lessonId,
  setLocalStorage,
}) {
  const { response: questionResponse, error: questionError } = yield call(
    GroupActivityApi.getStudentQuestions,
    onlineResponseId,
    userId,
  );
  if (questionError) {
    const status = _get(questionError, 'response.status', null);
    const message = _get(questionError, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }
  if (setLocalStorage) {
    localStorage.setItem(
      'exam_questions',
      JSON.stringify(
        questionResponse.data.exam_questions.map((q) => ({
          id: q.id,
          question: q.question,
        })),
      ),
    );
  }
  yield put({
    type: GROUP_ACTIVITY_SET_QUESTIONS,
    questions: {
      exam_questions: questionResponse.data.exam_questions,
      prev_eqs: questionResponse.data.prev_eqs,
    },
    lessonId,
  });
}

function* getOnlineStudentReview({ questionId, groupId, userId }) {
  const { response: reviewResponse, error: reviewError } = yield call(
    GroupActivityApi.studentGroupReview,
    questionId,
    groupId,
  );
  if (reviewError) {
    const status = _get(reviewError, 'response.status', null);
    const message = _get(reviewError, 'response.data.detail', 'Unable to fetch review');
    errorToast(`${message}`);
    return;
  }
  const studentAnswer = reviewResponse.data?.student_answer[questionId]?.filter(
    (student) => student.user_id === userId,
  )[0];
  const groupAnswer = reviewResponse.data?.group_answer;
  const student_id = studentAnswer?.user_id;
  const choice_answer = studentAnswer?.choice
    ? studentAnswer.choice[0].id
    : studentAnswer?.text;
  const studentAnswers = [{ student_id, choice_answer }, { ...groupAnswer }];
  const { correct_answer } = reviewResponse.data;
  yield put({
    type: GROUP_ACTIVITY_SET_STUDENT_REVIEW,
    studentAnswers,
    correct_answer,
  });
}

function* getOnlineGroupActivityQuestion({ groupId, lessonId }) {
  const { response: questionResponse, error: questionError } = yield call(
    GroupActivityApi.getQuestions,
    groupId,
  );
  if (questionError) {
    const status = _get(questionError, 'response.status', null);
    const message = _get(questionError, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }
  const onlineActivityQuestion = questionResponse.data.questions[0];
  const questionChoices = questionResponse.data.correct_answer;
  const studentAnswers = questionResponse.data.student_answer;
  const groupScore = questionResponse.data.group_scores;
  const exam_questions = onlineActivityQuestion.map((question, index) => {
    const choices = questionChoices.filter(
      (choice) => choice.question_Id === question.id,
    )[0];
    const studentAnswered = studentAnswers[question.id] || [];
    const groupAnswered = groupScore[question.id] || [];
    question.question.correct_choices = choices.correct_choices;
    return {
      ...question,
      ...choices,
      studentAnswered,
      groupAnswered,
    };
  });
  yield put({
    type: GROUP_ACTIVITY_SET_QUESTIONS,
    questions: { exam_questions },
    lessonId,
  });
}

function formatScoreResponse(scoreResponse) {
  const studentScore = scoreResponse.data?.filter(
    (score) =>
      score.students.length > 0 &&
      (score.group_status > 0 ||
        (score.score && score.score !== '0/5' && score.score !== '0/3')),
  );
  let achievableScore;
  const sumOfScores = studentScore.reduce((acc, group) => {
    if (!group.score) {
      return acc;
    }
    const score = group.score.split('/');
    achievableScore = parseInt(score[1], 10);
    return acc + parseInt(score[0], 10);
  }, 0);
  const averageScore = (sumOfScores / achievableScore) * (1 / studentScore.length) || 0;

  const percentile = averageScore * 100;

  let numberOfSubmission = 0;
  studentScore.forEach((group) => {
    if (group.group_status === 3) {
      group.students.forEach((student) => {
        numberOfSubmission++;
      });
    }
  });

  return {
    numberOfSubmission,
    scoring: studentScore,
    percentile: `${parseInt(percentile, 10)}%`,
  };
}
/* Emit an event when the groupActivity initialized , Should be removed when we get rid of Redux or move the initialized API to proper place inside hook */
const invalidateOnlineGroupActivity = () => {
  QueryClientEmitter.emit('invalidateQueries', ['getOnlineActivity']);
  QueryClientEmitter.emit('invalidateQueries', ['getGroupActivityInfo']);
};

function* getGroupActivities({
  payload: {
    sectionId,
    activityLessonGroupId,
    activityLessonId,
    activityUserId,
    activityUnitId,
    curriculumId,
    isTeacher,
    lessonId,
  },
}) {
  yield put(actions.loadGroupActivity());
  const { response, error } = yield call(
    GroupActivityApi.getGroupActivities,
    sectionId,
    activityLessonGroupId,
    activityLessonId,
  );

  if (error) {
    const message = _get(error, 'response.data.detail', 'Unexpected error');
    yield put(actions.loadedGroupActivity());
    errorToast(`${message}`);
    return;
  }
  let newActivityId = null;
  if (response.data.length < 1 && isTeacher) {
    const { response: activityResponse, error: activityError } = yield call(
      GroupActivityApi.createOnlineGroupActivity,
      sectionId,
      {
        unit: activityUnitId,
        lesson_group: activityLessonGroupId,
        lesson: activityLessonId,
        user: activityUserId,
        section: sectionId,
        curriculum: curriculumId,
      },
    );
    if (activityError) {
      const message = _get(activityError, 'response.data.detail', 'Unexpected error');
      yield put(actions.loadedGroupActivity());
      errorToast(`${message}`);
      return;
    }
    newActivityId = activityResponse.data.id;
    const { response: groupingResponse, error: groupingError } = yield call(
      GroupActivityApi.getInitialGroupings,
      activityResponse.data.id,
    );
    /* Emit an event when the groupActivity initialized , Should be removed when we get rid of Redux or move the initialized API to proper place inside hook */
    yield QueryClientEmitter.emit('invalidateQueries', ['getOnlineActivity']);

    if (groupingError) {
      const message = groupingError[0] || 'Groupings fetch was not successful';

      yield put({
        type: SET_GROUP_ACTIVITIES,
        groupActivities: [],
        error: message,
      });
      yield put(actions.loadedGroupActivity());
      return;
    }

    yield put({
      type: SET_GROUP_ACTIVITIES,
      groupActivities: groupingResponse.data,
    });

    yield put({
      type: SET_GROUP_ACTIVITIES_INFO,
      groupActivitiesInfo: activityResponse.data,
      lessonId,
    });
    yield put({
      type: CURRICULUM_LESSON_RESET_ATTENDANCE,
    });
  } else if (response.data.length > 0) {
    yield put({
      type: SET_GROUP_ACTIVITIES_INFO,
      groupActivitiesInfo: response.data[0],
      lessonId,
    });
    const { response: groupingResponse, error: groupingError } = yield call(
      GroupActivityApi.getInitialGroupings,
      response.data[0].id,
    );
    if (groupingError) {
      const message = groupingError[0] || 'Groupings fetch was not successful';

      yield put({
        type: SET_GROUP_ACTIVITIES,
        groupActivities: [],
        error: message,
      });
      yield put(actions.loadedGroupActivity());
      return;
    }
    yield put({
      type: SET_GROUP_ACTIVITIES,
      groupActivities: groupingResponse.data,
      error: null,
    });
    if (isTeacher) {
      localStorage.setItem('OGA_groups', JSON.stringify(groupingResponse.data));
    }
  }
  yield put(actions.loadedGroupActivity());
  if (isTeacher) {
    const groups = JSON.parse(localStorage.getItem('OGA_groups'));
    const numberOfQuestion = groups.find((group) => group.id)?.number_of_question;
    const groupActivityId =
      response.data.length > 0 ? response.data[0]?.id : newActivityId;
    if (!groupActivityId || !numberOfQuestion) {
      return;
    }

    const { response: scoreResponse, error: scoreError } = yield call(
      GroupActivityApi.scoreGroupActivity,
      groupActivityId,
      numberOfQuestion,
    );

    if (scoreError) {
      Sentry.captureException(scoreError);
      const message = _get(
        scoreError,
        'response.data.detail',
        'Fetching group score was not successful',
      );
      errorToast(`${message}`);
      return;
    }
    invalidateOnlineGroupActivity();
    yield put({ type: GROUP_ACTIVITY_SET_SCORE, scoring: scoreResponse.data, lessonId });
    yield put({
      type: GROUP_ACTIVITY_SET_SCORE_DETAIL,
      ...formatScoreResponse(scoreResponse),
      lessonId,
    });
  }
}

function* startGroupActivity({ groupActivityId }) {
  const { response, error } = yield call(
    GroupActivityApi.startGroupActivity,
    groupActivityId,
  );

  if (error) {
    const status = _get(error, 'response.status', null);
    const message = _get(error, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }

  yield put({ type: GROUP_ACTIVITY_SET_STATUS, hasStarted: true });
}

function* statusIndividualGroupActivity({ payload }) {
  const { response, error } = yield call(
    GroupActivityApi.updateIndividualGroupActivity,
    payload,
  );

  if (error) {
    const status = _get(error, 'response.status', null);
    const message = _get(error, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }

  yield put({ type: GROUP_ACTIVITY_SET_STATUS, hasStarted: true });
}

function* restartGroupActivity({ groupActivityId }) {
  const { response, error } = yield call(
    GroupActivityApi.restartGroupActivity,
    groupActivityId,
  );

  if (error) {
    const status = _get(error, 'response.status', null);
    const message = _get(error, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }

  yield put({ type: GROUP_ACTIVITY_SET_STATUS, hasStarted: false });
}

function* endGroupActivity({ groupActivityId, lessonId }) {
  yield put(actions.loadGroupScore());
  const { error } = yield call(GroupActivityApi.endGroupActivity, groupActivityId);

  if (error) {
    const status = _get(error, 'response.status', null);
    const message = _get(error, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }
  const { response: scoreResponse, error: scoreError } = yield call(
    GroupActivityApi.calculateScoreGroupActivity,
    groupActivityId,
  );
  if (scoreError) {
    const status = _get(scoreError, 'response.status', null);
    const message = _get(scoreError, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
  }
}
function* answerGroupActivityQuestion({
  payload,
  userId,
  onlineResponseId,
  groupId,
  stage,
  locked,
}) {
  // TODO - response becomes an array
  const { response, error } = yield call(
    GroupActivityApi.submitQuestion,
    payload,
    userId,
    onlineResponseId,
    groupId,
    stage,
  );
  if (error && error.length > 0 && locked) {
    infoToast(`${error[0]}`);
  }
}
function* answerGroupActivityGroupQuestion({ payload, locked }) {
  // TODO - response becomes an array
  const { response, error } = yield call(GroupActivityApi.submitGroupQuestion, payload);
  if (error && error.length > 0 && locked) {
    infoToast(`${error[0]}`);
  }
}

function* notifyGroupActivityStatus({ payload }) {
  const role = yield select(userSelectors.getUserRole);
  if (role === ROLE_TYPE.STUDENT && payload.online_activity_status === 1) {
    const { course_id, subject_id, section, unit, lesson_group } = payload;
    const { tab, 'pacing-guide-id': pacingGuideId } = getSearchParamsObject(
      history.location.search,
    );
    const redirectLink = `/lessons/course/${course_id}/section/${section}/subject/${subject_id}/unit/${unit}/lesson/${lesson_group}?tab=${tab}${
      pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
    }`;
    const msg = (
      <span>
        Your teacher has started the group activity. &nbsp;
        <Link to={redirectLink}>Click here</Link> &nbsp; to join.
      </span>
    );

    const activeGroupActivity = {
      sectionId: section,
      courseId: course_id,
      subjectId: subject_id,
      unitId: unit,
      lessonId: lesson_group,
    };

    yield put({ type: GROUP_ACTIVITY_NOTIFICATION_DETAILS, activeGroupActivity });

    // If current url is same as redirect link, autoClose should be true and otherwise;
    const autoCloseCondition = _isEqual(
      `${window.location.pathname}?tab=${LessonTabs.Lesson}`,
      redirectLink,
    );
    infoToast(msg, { autoClose: autoCloseCondition, closeOnClick: false });
  }
}

function* updateAttendanceFromIndividualResponse({
  students_number,
  student_answer,
  lesson_id,
  section_id,
}) {
  if (lesson_id && section_id) {
    yield put({
      type: CURRICULUM_LESSON_GET_ATTENDANCE,
      lessonId: lesson_id,
      sectionId: section_id,
    });
  }
  // const studentId = student_answer[0].student_id;
  // we need the groupId if we're to use this to update the scores.
}

function* getStudentScoreAtEndOfActivity({ payload }) {
  const { lesson_group, id, number_of_question } = payload;
  yield put(actions.loadGroupScore());
  const { response: scoreResponse, error: scoreError } = yield call(
    GroupActivityApi.scoreGroupActivity,
    id,
    number_of_question,
  );
  if (scoreError) {
    const message = _get(scoreError, 'response.data.detail', 'Unexpected error');
    errorToast(`${message}`);
    return;
  }
  yield put({
    type: GROUP_ACTIVITY_SET_SCORE,
    scoring: scoreResponse.data,
    lessonId: lesson_group,
  });
  yield put({ type: UPDATE_GROUP_ARRAY, groupArray: scoreResponse.data });
  yield put({
    type: GROUP_ACTIVITY_SET_SCORE_DETAIL,
    ...formatScoreResponse(scoreResponse),
    lessonId: lesson_group,
  });
  yield put(actions.loadedGroupScore());
  yield put(actions.loadedGroupActivity());
}

function* submitScoreSummary({ payload }) {
  const { groupActivityId, summary } = payload;
  const { response, error } = yield call(
    GroupActivityApi.submitScoreSummary,
    summary,
    groupActivityId,
  );
  console.log({ response, error });
}
function* resetPracticeForGroup({ groupId }) {
  const { response, error } = yield call(GroupActivityApi.resetPracticeForGroup, groupId);
  if (response) {
    const { data } = response;
    yield put(actions.resetPracticeForGroupSuccess(data));
  } else {
    yield put(actions.resetPracticeForGroupFail());
  }
}

export default function* groupActivitySaga() {
  yield takeLeading(GROUP_ACTIVITY_CREATE, createGroupActivity);
  yield takeLeading(GROUP_ACTIVITY_UPDATE, updateGroupActivity);
  yield takeLeading(GROUP_ACTIVITY_GET_LIST, getGroupActivities);
  yield takeLeading(GROUP_ACTIVITY_GET_QUESTIONS, getOnlineGroupActivityQuestion);
  yield takeLeading(GROUP_ACTIVITY_GET_STUDENT_QUESTIONS, getOnlineQuestionsStudent);
  yield takeLeading(GROUP_ACTIVITY_GET_STUDENT_REVIEW, getOnlineStudentReview);
  yield takeLeading(GROUP_ACTIVITY_START_ACTIVITY, startGroupActivity);
  yield takeLeading(
    GROUP_ACTIVITY_STATUS_INDIVIDUAL_ACTIVITY,
    statusIndividualGroupActivity,
  );
  yield takeLeading(GROUP_ACTIVITY_END_ACTIVITY, endGroupActivity);
  yield takeLeading(GROUP_ACTIVITY_RESTART_ACTIVITY, restartGroupActivity);
  yield takeLeading(GROUP_ACTIVITY_QUESTION_ANSWER, answerGroupActivityQuestion);
  yield takeLeading(
    GROUP_ACTIVITY_GROUP_QUESTION_ANSWER,
    answerGroupActivityGroupQuestion,
  );

  yield takeEvery(GROUP_ACTIVITY_NOTIFY_GROUP_STARTED, notifyGroupActivityStatus);
  yield takeEvery(GROUP_ACTIVITY_FETCH_STUDENT_SCORE, getStudentScoreAtEndOfActivity);
  yield takeEvery(
    GROUP_ACTIVITY_INDIVIDUAL_QUESTION_ANSWERED,
    updateAttendanceFromIndividualResponse,
  );
  yield takeEvery(GROUP_ACTIVITY_SUBMIT_SCORE_SUMMARY, submitScoreSummary);
  yield takeEvery(GROUP_ACTIVITY_RESET_PRACTICE_FOR_GROUP, resetPracticeForGroup);
}
