import React, { createRef, lazy } from 'react';
import { Question } from '../../AssessmentServiceAPI';
import useThrowError from '../../Hooks/useThrowError';
import withErrorBoundary from '../withErrorBoundary';
// lazy load ItemBankHarness
const ItemBankHarness = lazy(() =>
  import('../ItemBankHarness').then((module) => ({ default: module.ItemBankHarness })),
);

interface PreviewProps {
  userId?: string;
  sessionId?: string;
  activityId: string;
  name?: string;
  title: string;
  subtitle: string;
  questions: Question[] | undefined;
}

const Preview: React.FC<PreviewProps> = ({
  userId = '1',
  sessionId = '00000000-0000-4000-8000-000000000000',
  name = 'ChalkTalk Assessment',
  activityId,
  title,
  subtitle,
  questions,
}) => {
  const widgetRef = createRef<unknown>();
  const throwError = useThrowError();

  if (!questions?.length) {
    throwError(new Error('Question count should be a number greater than 0.') as any);
  }

  return (
    <div>
      {questions && questions.length > 0 && (
        <ItemBankHarness
          ref={widgetRef}
          userId={userId}
          sessionId={sessionId}
          activityId={activityId}
          renderingType="assess"
          type="submit_practice"
          name={name}
          state="preview"
          questionReferences={questions}
          config={{
            configuration: {
              lazyload: false,
              onsave_redirect_url: false,
              onsubmit_redirect_url: false,
            },
            questions_api_init_options: {
              attribute_overrides: {
                instant_feedback: false,
              },
              showCorrectAnswers: true,
              show_distractor_rationale: {
                per_question: 'incorrect',
                per_response: 'always',
              },
            },
            regions: {
              'bottom-right': [
                {
                  options: {
                    label: 'Back',
                    name: 'chalktalk_back',
                  },
                  type: 'custom_button',
                },
                {
                  options: {
                    label: 'Next',
                    name: 'chalktalk_next',
                  },
                  type: 'custom_button',
                },
              ],
              items: [
                {
                  type: 'progress_element',
                },
              ],
              right: [
                {
                  type: 'save_button',
                },
                {
                  type: 'accessibility_button',
                },
                {
                  type: 'verticaltoc_element',
                },
              ],
              'top-left': [
                {
                  type: 'title_element',
                },
              ],
              'top-right': [
                {
                  position: 'right',
                  type: 'pause_button',
                },
                {
                  type: 'timer_element',
                },
                {
                  type: 'itemcount_element',
                },
              ],
            },
            subtitle,
            title,
          }}
          eventHandlers={[
            {
              callback: (widget) => {
                setTimeout(() => {
                  widget.items().goto(0);
                }, 100);
              },
              name: 'test:ready',
            },
            {
              callback: (widget) => {
                widget.items().previous();
              },
              name: 'button:chalktalk_back:clicked',
            },
            {
              callback: (widget) => {
                widget.items().next();
              },
              name: 'button:chalktalk_next:clicked',
            },
          ]}
          errorCallback={(error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            throwError(error);
          }}
        />
      )}
    </div>
  );
};

const wrappedAssessment = withErrorBoundary<PreviewProps>(
  React.memo(Preview),
  'Something went wrong, please contact support.',
);
export { wrappedAssessment as Preview, PreviewProps };
