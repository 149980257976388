/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TableContentItem,
} from '../Table/TableComponent.style';
import { TableContent } from '../Table/TableComponent';
import FormInput from '../Generals/FormInput/FormInput';
import ColumnSize from './ColumnSize';

const gradeOption = [
  {
    id: '1',
    grade_level: '9',
  },
  {
    id: '2',
    grade_level: '10',
  },
  {
    id: '3',
    grade_level: '11',
  },
  {
    id: '4',
    grade_level: '12',
  },
];

export default class StudentGroupInviteForm extends Component {
  static propTypes = {
    student: PropTypes.object,
    index: PropTypes.number,
    deleteStudent: PropTypes.func.isRequired,
    fieldName: PropTypes.string,
  }

  deleteStudent = () => {
    const { index, deleteStudent } = this.props;
    deleteStudent(index);
  };

  handleOnchange = (form, fieldName) => (values) => {
    form.setFieldValue(fieldName, values);
  }

  render() {
    const {
      student, fieldName, index, errorMessage = {},
    } = this.props;
    const {
      firstName, middleName, lastName, email, grade,
    } = student;

    return (
      <TableContentItem smallSize key={index}>
        <TableContent {...ColumnSize[0]}>
          <FormInput
            type="text"
            value={firstName}
            name={`${fieldName}.firstName`}
            inputTable
            errorMessage={errorMessage.firstName}
          />
        </TableContent>
        <TableContent {...ColumnSize[1]}>
          <FormInput
            type="text"
            value={middleName}
            name={`${fieldName}.middleName`}
            inputTable
          />
        </TableContent>
        <TableContent {...ColumnSize[2]}>
          <FormInput
            type="text"
            value={lastName}
            name={`${fieldName}.lastName`}
            inputTable
            errorMessage={errorMessage.lastName}
          />
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          <FormInput
            type="email"
            value={email}
            name={`${fieldName}.email`}
            inputTable
            errorMessage={errorMessage.email}
          />
        </TableContent>
        <TableContent {...ColumnSize[4]}>
          <FormInput
            type="select"
            value={grade}
            name={`${fieldName}.grade`}
            options={gradeOption}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.grade_level}
            inputTable
          />
        </TableContent>
        <TableContent
          {...ColumnSize[5]}
          onClick={this.deleteStudent}
        >
          <i className="chalktalk-close" />
        </TableContent>
      </TableContentItem>
    );
  }
}
