/* eslint-disable import/prefer-default-export */
export const generateLessonReviewType = (type: number) => {
  switch (type) {
    case 0:
      return 'not set';
    case 1:
      return 'Fixed-Order';
    case 2:
      return 'Adaptive';
    default:
      return 'not set';
  }
};
export const generateLessonReviewTypeTooltip = (type: number) => {
  switch (type) {
    case 0:
      return 'the assessment has not been set yet !';
    case 1:
      return 'the questions will appear for your students in the order you select them';
    case 2:
      return 'generates a 5-question practice session tailored to each student’s skill level. Each subsequent 5-question session adapts based on their previous answers, using questions from the item pool you select below. For best results, choose at least 20 questions of varying difficulties';
    default:
      return 'the assessment has not been set yet !';
  }
};
