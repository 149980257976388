import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BackgroundLeft from '../BackgroundLeft/BackgroundLeft';
import PasswordForm from '../../containers/PasswordPage/PasswordForm';
import {
  LoginContainerWrap,
  LoginBlockRight,
} from '../LoginPage/Login.style';

export default class PasswordPage extends PureComponent {
  static propTypes = {
    match: PropTypes.any,
  }

  render() {
    const { match } = this.props;
    return (
      <LoginContainerWrap>
        <BackgroundLeft type="password" />
        <LoginBlockRight>
          <PasswordForm match={match} />
        </LoginBlockRight>
      </LoginContainerWrap>
    );
  }
}
