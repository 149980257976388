import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { LoaderWrap } from './LoadingPlaceholder.style';

export default class CardLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading } = this.props;
    return (
      <LoaderWrap>
        <ContentLoader
          className="card-loader"
          height={1000}
          width={400}
          speed={loading ? 2 : 0}
          primaryColor="#ecebee"
          secondaryColor="#dedbe0"
        >
          <rect x="120" y="0" rx="3" ry="3" width="160" height="115" />
          <rect x="60" y="250" rx="3" ry="3" width="280" height="120" />
          <rect x="20" y="600" rx="0" ry="3" width="360" height="100" />
          <rect x="20" y="800" rx="0" ry="3" width="360" height="100" />
        </ContentLoader>
      </LoaderWrap>
    );
  }
}
