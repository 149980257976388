import React, { Suspense, useCallback, useMemo } from 'react';
import { Popover } from '@mui/material';
import Box from '@components/Atoms/Box';
import { SkillRecord } from '@CT-Types/lessonDomain';
import MultiSelectTree from '@components/Atoms/MultiSelectTree';
import HighlightSelector from './HighlightSelector';
import { useContentFilterSkillContext } from '../ContentFilterProvider';
import { buildTags } from '../utils';

const popOverSlotProps = {
  paper: {
    sx: {
      width: 'auto',
      maxHeight: 400,
      mt: 0.5,
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
  },
};

const SkillPopover = ({ open, anchor, onClose, disabled }) => {
  const {
    selectedSkills,
    recommendedSkills,
    manualSelectedSkills,
    prioritySkills,
    removedSkills,
    setRemovedSkills,
    setManuallySelectedSkills,
    setSelectedSkills,
    getSkillTree,
    getSkillById,
  } = useContentFilterSkillContext();

  const getRecommendedSkillObjects = useMemo(
    () => () => {
      const priorityIds = Object.keys(prioritySkills).map(Number);
      const recommendedIds = Object.keys(recommendedSkills).map(Number);
      const skillsToHighlight = [
        ...priorityIds,
        ...recommendedIds.filter((id) => !priorityIds.includes(id)),
      ];
      return skillsToHighlight
        .filter((id) => !selectedSkills.includes(id))
        .map((id) => getSkillById(id))
        .filter(Boolean);
    },
    [getSkillById, prioritySkills, recommendedSkills, selectedSkills],
  );

  // when a user selects a recommended skill, add it to the selected skill
  // and keep track of the manual selections separately
  const handleSelectRecommended = (id: number) => {
    setManuallySelectedSkills([...manualSelectedSkills, id]);
    setSelectedSkills([...selectedSkills, id]);
    setRemovedSkills(removedSkills.filter((removedId) => removedId !== id));
  };

  // when the user changes the selected skills, update the selectedSkills and manualSelectedSkills
  const handleSkillChange = (skills: number[]) => {
    const newSkills = skills.filter(
      (id) => !manualSelectedSkills.includes(id) && !selectedSkills.includes(id),
    );
    const oldSkills = manualSelectedSkills.filter((id) => skills.includes(id));
    const removed = selectedSkills.filter((id) => !skills.includes(id));

    setManuallySelectedSkills([...oldSkills, ...newSkills]);
    setSelectedSkills(skills);

    // only keep record if a single skill was removed at a time
    if (removed.length === 1) {
      setRemovedSkills([...removedSkills, ...removed]);
    }
  };

  const getSkillTags = useCallback(
    () =>
      buildTags('skill', {
        auto: {
          source: 'Lessons',
          data: prioritySkills,
        },
        recommend: recommendedSkills,
      }),
    [prioritySkills, recommendedSkills],
  );

  return (
    <Popover
      id="skill-filter-popover"
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={popOverSlotProps}
    >
      <Box sx={{ p: 1, width: 'auto', minWidth: '20rem', maxWidth: '60rem' }}>
        <Suspense fallback={<Box sx={{ p: 2 }}>Loading...</Box>}>
          <HighlightSelector
            items={getRecommendedSkillObjects()}
            getItemId={(skill: SkillRecord) => skill.id}
            getItemLabel={(skill: SkillRecord) => skill.name}
            primaryText="Based on the selected Lesson(s), here are ChalkTalk's recommended Skills to use:"
            secondaryText="Currently Selected Skills (Click to add more):"
            onSelect={(skill: SkillRecord) => handleSelectRecommended(Number(skill.id))}
            disabled={disabled}
            tags={getSkillTags()}
          >
            <MultiSelectTree
              tree={getSkillTree()}
              placeholder="Click here to select a skill..."
              onChange={handleSkillChange}
              selected={selectedSkills}
              onClose={onClose}
              disabled={disabled}
              tags={getSkillTags()}
            />
          </HighlightSelector>
        </Suspense>
      </Box>
    </Popover>
  );
};

export default SkillPopover;
