import StandardsPracticeOverview from '@components/StandardsPractice/StandardsPracticeOverview';
import LessonPracticeCard from '@containers/LessonPracticeCard';
import React from 'react';
import {
  useShouldShowAdaptivePracticeTab,
  useShouldShowStandardPracticeTab,
} from '../hooks';

const PracticeFactory = () => {
  const shouldShowStandardPracticeTab = useShouldShowStandardPracticeTab();
  const shouldShowAdaptivePracticeTab = useShouldShowAdaptivePracticeTab();
  if (shouldShowStandardPracticeTab) {
    return <StandardsPracticeOverview />;
  }
  if (!shouldShowStandardPracticeTab && shouldShowAdaptivePracticeTab) {
    return <LessonPracticeCard />;
  }
  // to satisfy ts
  return null;
};

export default PracticeFactory;
