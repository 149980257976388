import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import LeftSideBarCourseManagement from '../../containers/LeftSideBar/LeftSideBarCourseManagement';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import SectionDetail from '../../containers/CourseManagementSection/SectionDetail';
import TeacherList from '../../containers/CourseManagementSection/TeacherList';
import StudentList from '../../containers/CourseManagementSection/StudentList';
import MainContent from '../../containers/MainContent';
import {
  PrimaryWrap,
  DefaultContentWrap,
  ContentSubFlexWrap,
} from '../Generals/stylesheets/General.style';
import { CourseManagementFlexWrap } from './CourseManagementSection.style';

const scrollStyle = {
  height: 'calc(100vh - 5.5em)',
  width: '100%',
};

export default class CourseManagementSection extends PureComponent {
  static propTypes = {
    sectionsStudentGet: PropTypes.func.isRequired,
    sectionsTeachersGet: PropTypes.func.isRequired,
    match: PropTypes.shape(),
    sectionDetail: PropTypes.shape().isRequired,
    studentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    teacherList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    courseDetail: PropTypes.shape().isRequired,
    sectionsEstimatedGet: PropTypes.func.isRequired,
    isSideBarClose: PropTypes.bool,
    isGettingStudentList: PropTypes.bool,
    isGettingTeacherList: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);

    const { sectionsStudentGet, sectionsTeachersGet, sectionsEstimatedGet, match, courseDetail } = props;
    const { params } = match;
    const { sectionId } = params;

    sectionsStudentGet(sectionId);
    sectionsTeachersGet(sectionId);

    if (courseDetail.has_exam) {
      sectionsEstimatedGet(sectionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionsStudentGet, sectionsTeachersGet, sectionsEstimatedGet, courseDetail } = this.props;
    const prevSectionId = _get(prevProps, 'match.params.sectionId', '');
    const currentSectionId = _get(this.props, 'match.params.sectionId', '');

    if (!_isEqual(prevSectionId, currentSectionId)) {
      sectionsStudentGet(currentSectionId);
      sectionsTeachersGet(currentSectionId);

      if (courseDetail.has_exam) {
        sectionsEstimatedGet(currentSectionId);
      }
    }
  }

  render() {
    const {
      sectionDetail,
      studentList,
      teacherList,
      match,
      courseDetail,
      isSideBarClose,
      isGettingStudentList,
      isGettingTeacherList,
      history,
    } = this.props;
    const courseId = _get(match, 'params.courseId', '');

    if (_isEmpty(sectionDetail)) {
      history.push(`/management/course/${courseDetail.id}`);
      return null;
    }

    return (
      <PrimaryWrap>
        <LeftSideBarCourseManagement pathName="/management/course" />
        <MainContent>
          <BreadcumbsFilter
            haveSub={isSideBarClose}
            breadcumbSubList={[courseDetail.name]}
            breadcumbList={[courseDetail.name, sectionDetail.name, 'Manage']}
            mobileTitle="Sections Management"
            isDisplayFilter={false}
          />

          <DefaultContentWrap>
            <ShadowScrollbar autoHide style={scrollStyle}>
              <CourseManagementFlexWrap>
                <SectionDetail sectionDetail={sectionDetail} studentList={studentList} />
                <ShadowScrollbar autoHide style={scrollStyle}>
                  <ContentSubFlexWrap>
                    <TeacherList
                      teacherList={teacherList}
                      sectionDetail={sectionDetail}
                      courseId={courseId}
                      isGettingTeacherList={isGettingTeacherList}
                    />
                    <StudentList
                      studentList={studentList}
                      sectionDetail={sectionDetail}
                      isGettingStudentList={isGettingStudentList}
                    />
                  </ContentSubFlexWrap>
                </ShadowScrollbar>
              </CourseManagementFlexWrap>
            </ShadowScrollbar>
          </DefaultContentWrap>
        </MainContent>
      </PrimaryWrap>
    );
  }
}
