import React, { memo } from 'react';
import _get from 'lodash/get';
import { useParams, useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import ShowIf from '@components/Atoms/ShowIf';
import { useGetLearnosityAdaptivePracticeQuestions } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { selectors as ActivitySelectors } from '@reducers/activity';
import { selectors as SectionsSelectors } from '@reducers/sections';
import { selectors as LessonAssignedActivitiesSelectors } from '@reducers/lessonAssignedActivities';
import { useSelector } from 'react-redux';
import { useSectionAttendanceListByActivityType } from '@reducers/sections/hooks';
import { LessonAttendanceActivity } from '@components/Component/Lesson/LessonAttendance/types';
import { useSectionStudent } from '@reducers/sectionStudent/hooks';
import { LessonsType, Unit } from '@CT-Types/lessonDomain';
import {
  NavBarItemCustome,
  NavBarName,
  NavBarItemWrap,
  NavBarStatusColumn,
  NavBarIconWrap,
  NavBarIcon,
  NavBarItemHead,
  PropertiseBarWrapper,
  PropertiseBarTitle,
  PropertiseBarProgress,
  PropertiseBar,
} from './SubNavBar.style';
import { useSearchParams } from '../../utils/hooks/useSearchParams';
import useLearnosityQuestionCT from '../../utils/hooks/useLearnosityQuestionCT';
import { useGetLearnosityLessonReviewList } from './hooks/query';
import LessonReviewNavItem from './LessonReviewNavItem';
import LessonReviewGenerateButton from './LessonReviewGenerateButton';
import { useIsReviewClassActive } from './hooks/helpers';

const pathNumber = Array.from(Array(15).keys());

interface LessonSubNavItemProps {
  lessonItem: LessonsType;
  isLastItem: boolean;
  isFirstItem: boolean;
  unit: Unit;
  isReviewClassEnabled: boolean;
}

const LessonSubNavItem = ({
  lessonItem,
  isLastItem,
  isFirstItem,
  unit,
  isReviewClassEnabled,
}: LessonSubNavItemProps) => {
  const { name } = lessonItem;
  const { data: pacingGuidesLessons } = useGetPacingGuideLessons();
  const { courseId = '', sectionId = '', lessonId: activeLessonId } = useParams();
  const { tab, 'pacing-guide-id': pacingGuideId } = useSearchParams();
  const history = useHistory();
  const isTeacher = useIsTeacher();
  const currentUser = useUser();
  const userId = currentUser.id;
  const lessonId = _get(lessonItem, 'id', '');
  const unitId = _get(unit, 'id', '');
  const subjectId = _get(unit, 'curriculum_subject', '');

  const lessonActivitySummaryById = useSelector(
    ActivitySelectors.getLessonActivitySummaryById,
  );
  const practiceSummaryByLessonId = useSelector(
    SectionsSelectors.getPracticeSummaryByLessonId,
  );
  const groupAttendanceList = useSectionAttendanceListByActivityType(
    LessonAttendanceActivity.Group,
  );
  const isAssign = useSelector((state) =>
    LessonAssignedActivitiesSelectors.getAssignStatusByLessonId(state, lessonId),
  );
  const studentList = useSectionStudent();
  const isSingleItem = isFirstItem && isLastItem;

  const isFinish = React.useMemo(() => {
    const filterArr = _get(lessonActivitySummaryById, `${lessonId}`, {});
    return _get(filterArr, 'completed', false).toString().toLowerCase() === 'true';
  }, [lessonActivitySummaryById, lessonId]);

  const usersPracticeInfo = _get(practiceSummaryByLessonId, `${lessonId}`, []);

  const isGroupAttendance = groupAttendanceList.some(
    (attendance) => attendance.lesson === lessonId,
  );

  const isFinishPractice = React.useMemo<boolean>(() => {
    if (isTeacher) {
      return (usersPracticeInfo.length / studentList.length) * 100 >= 70;
    }
    return usersPracticeInfo.some((x) => x.user === userId);
  }, [isTeacher, usersPracticeInfo, studentList.length, userId]);
  // progress bar
  const isCompleted = false;
  // properties bar
  const lessonCanPractice = _get(lessonItem, 'can_practice', false);
  const lessonHasGroupActivity = _get(lessonItem, 'has_documents', false);
  const unitHasLessonPractice = _get(unit, 'meta.lesson_practice', false);
  const shouldRenderGroupActivity = lessonHasGroupActivity;
  // redirectToLessonManagementPage
  const isReviewClassPage = useIsReviewClassActive();

  const { data } = useGetLearnosityLessonReviewList();
  const hasLessonReview = React.useMemo(
    () => !!data?.find((item) => item.lesson == lessonId),
    [data, lessonId],
  );
  const lessonReview = data?.find((item) => item.lesson == lessonId);

  const isToggledLastItem = isReviewClassEnabled && isLastItem ? !isLastItem : isLastItem;

  const initialTabIndex = LessonTabs.Lesson;
  const tabIndex = tab || initialTabIndex;

  const { hasStandardsPractice } = useLearnosityQuestionCT({
    lessonDetail: lessonItem,
  });
  const isAdaptiveLearnosityPracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();
  const { data: lessonQuestions } = useGetLearnosityAdaptivePracticeQuestions(lessonId, {
    suspense: false,
  });
  const hasLearnosityQuestions =
    isAdaptiveLearnosityPracticeEnabled && lessonQuestions?.length > 0;
  const shouldRenderPracticePropertise =
    (lessonCanPractice && unitHasLessonPractice) || hasStandardsPractice;

  const hasAllItems = shouldRenderGroupActivity && shouldRenderPracticePropertise;
  const isPacingGuideLesson = pacingGuidesLessons?.find(
    (item) => item.lesson === lessonId,
  );
  const isActive = lessonId === Number(activeLessonId);
  const isReviewActive = isActive && isReviewClassPage;

  if (!isPacingGuideLesson && !!pacingGuideId) {
    return null;
  }

  return (
    <>
      <NavBarItemWrap
        id={isActive && !isReviewActive ? 'active-lesson' : ''}
        isActive={isActive && !isReviewActive}
        onClick={() => {
          history.push(
            `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}?tab=${tabIndex}${
              !!pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
            }`,
          );
        }}
      >
        <NavBarStatusColumn>
          <NavBarIconWrap
            isSingleItem={isSingleItem}
            isFirstItem={isFirstItem}
            isLastItem={isToggledLastItem}
          >
            {isCompleted ? (
              <NavBarIcon className="chalktalk-check-success" />
            ) : (
              <NavBarIcon className="chalktalk-book-open">
                {pathNumber.map((number, index) => (
                  <i className={`path${number}`} key={index} />
                ))}
              </NavBarIcon>
            )}
          </NavBarIconWrap>
        </NavBarStatusColumn>
        <NavBarItemCustome>
          <NavBarItemHead />
          <NavBarName>
            {name}
            {isAssign && <i className="chalktalk-error" />}
          </NavBarName>
          <PropertiseBar hasAllItems={hasAllItems}>
            <PropertiseBarWrapper>
              <PropertiseBarTitle>🧑‍🏫 Lesson</PropertiseBarTitle>
              <PropertiseBarProgress isFinish={isFinish} />
            </PropertiseBarWrapper>

            {shouldRenderGroupActivity && (
              <PropertiseBarWrapper>
                <ReactTooltip place="top" effect="solid" id="tooltip-group-activity" />
                <PropertiseBarTitle
                  data-tip="Group Activity"
                  data-for="tooltip-group-activity"
                >
                  👥 Activity
                </PropertiseBarTitle>
                <PropertiseBarProgress isFinish={isGroupAttendance} />
              </PropertiseBarWrapper>
            )}
            {(hasLearnosityQuestions || shouldRenderPracticePropertise) && (
              <PropertiseBarWrapper>
                <PropertiseBarTitle>👤 Practice</PropertiseBarTitle>
                <PropertiseBarProgress isFinish={isFinishPractice} />
              </PropertiseBarWrapper>
            )}
          </PropertiseBar>
        </NavBarItemCustome>
      </NavBarItemWrap>

      <ShowIf If={isReviewClassEnabled && !hasLessonReview}>
        <LessonReviewGenerateButton
          lessonId={lessonId}
          isReviewClassEnabled={isReviewClassEnabled}
          isSingleItem={isSingleItem}
        />
      </ShowIf>

      <ShowIf If={hasLessonReview}>
        <LessonReviewNavItem
          lessonReview={lessonReview}
          unitId={unitId}
          isReviewClassEnabled={isReviewClassEnabled}
          lessonId={lessonId}
          subjectId={subjectId}
          isReviewActive={isReviewActive}
          isSingleItem={isSingleItem}
        />
      </ShowIf>
    </>
  );
};

export default memo(LessonSubNavItem);
