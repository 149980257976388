import styled from 'styled-components';

export const PracticeHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  height: 4em;
  border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
`;

export const PracticeHeaderLeft = styled.div`
  flex: 1;
  margin-left: 1em;
  display: flex;
  align-items: center;
`;

export const PracticeHeaderRight = styled.div`
  flex: 1;
`;

export const PracticeExamInfoWrap = styled.div`
  flex: 1;
  padding: 0.75em;
  margin: 0em 1em;
  background-color: ${(props) => props.theme.colorStyled.ColorBtnDark};
`;
