import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Hotkeys from 'react-hot-keys';
import {
  AnswerBubbleGridChoiceWrap,
  AnswerBubbleGridChoiceItem,
  AnswerBubbleGridChoiceColumn,
  AnswerBubbleGridChoiceSelected,
  BubbleGridItem,
  BubbleChoiceSelected,
  AnswerRowBubbleWrap,
  AnswerRow,
  AnswerRowNumber,
} from './OfflineTestAnswerSheet.style';
import { ClearButton } from '../Generals/stylesheets/Button.style';
import { colData } from '../AnswerGridChoice/AnswerChoiceData';

const ORDER_SHORTCUT = 'tab, shift+tab';

class AnswerBubbleGridChoice extends Component {
  chooseAnswer = (colItem, value) => {
    const currentSelectedValue = this.getSelectedValue();
    const {
      question, examChooseAnswer, selectRow, questionIndex,
    } = this.props;
    const { id } = question;

    currentSelectedValue[colItem] = value;

    const payload = {
      exam_question_id: id,
      value: currentSelectedValue.join(''),
      response_time: 0,
      delete: false,
    };

    selectRow(questionIndex);

    examChooseAnswer(id, payload);
  }

  clearAnswer = () => {
    const {
      question, examChooseAnswer, selectRow, questionIndex,
    } = this.props;
    const { id } = question;

    const payload = {
      exam_question_id: id,
      value: '____',
      response_time: 0,
      delete: true,
    };

    examChooseAnswer(id, payload);
    selectRow(questionIndex);
  }

  getSelectedValue = () => {
    const { answer } = this.props;
    const selectedValue = _get(answer, 'value', '____');
    const transformValue = `${selectedValue}____`.substring(0, 4);

    return transformValue.split('');
  }

  onKeyUp = (keyName) => {
    const { onKeyUpForTab } = this.props;
    onKeyUpForTab(keyName);
  }

  renderSelectedValueItem = (value, index) => {
    const valueDisplay = value !== '_' ? value : '';

    return (
      <BubbleChoiceSelected key={`${value}${index}`}>
        <p>{valueDisplay}</p>
      </BubbleChoiceSelected>
    );
  }

  renderGridChoiceSelected = () => {
    const selectedValue = this.getSelectedValue();

    return (
      <AnswerBubbleGridChoiceSelected>
        {selectedValue.map(this.renderSelectedValueItem)}
      </AnswerBubbleGridChoiceSelected>
    );
  }

  renderRowItem = (columnIndex) => (row, index) => {
    const selectedValue = this.getSelectedValue();
    const { text, value } = row;
    const isNoValue = !text;
    const keyIndex = columnIndex * 12 + index;
    const isSelected = selectedValue[columnIndex] === value && value !== '_'; // no display empty cell

    return (
      <BubbleGridItem
        noValue={isNoValue}
        key={keyIndex}
        isSelected={isSelected}
        onClick={() => this.chooseAnswer(columnIndex, value)}
      >
        <p>{text}</p>
      </BubbleGridItem>
    );
  }

  renderColItem = (col, index) => (
    <AnswerBubbleGridChoiceColumn key={index}>
      {col.map(this.renderRowItem(index))}
    </AnswerBubbleGridChoiceColumn>
  )

  renderContent = () => {
    const { rowSelected, questionIndex } = this.props;

    const isSelected = questionIndex === rowSelected;

    if (isSelected) {
      return (
        <Hotkeys
          keyName={ORDER_SHORTCUT}
          onKeyUp={this.onKeyUp}
        >
          <AnswerBubbleGridChoiceWrap>
            {this.renderGridChoiceSelected()}
            <AnswerBubbleGridChoiceItem>
              {colData.map(this.renderColItem)}
            </AnswerBubbleGridChoiceItem>
          </AnswerBubbleGridChoiceWrap>
        </Hotkeys>
      );
    }

    return (
      <AnswerBubbleGridChoiceWrap>
        {this.renderGridChoiceSelected()}
        <AnswerBubbleGridChoiceItem>
          {colData.map(this.renderColItem)}
        </AnswerBubbleGridChoiceItem>
      </AnswerBubbleGridChoiceWrap>
    );
  }

  render() {
    const {
      question, isAnswerSheetOpen, rowSelected, questionIndex,
    } = this.props;
    const { order } = question;
    const shouldHaveBg = questionIndex === rowSelected;

    return (
      <AnswerRow rowSelected={shouldHaveBg} isAnswerSheetOpen={isAnswerSheetOpen}>
        <AnswerRowNumber>{`${order}.`}</AnswerRowNumber>
        <AnswerRowBubbleWrap>
          {this.renderContent()}
        </AnswerRowBubbleWrap>
        <ClearButton data-index={order} onClick={this.clearAnswer}>
          clear
        </ClearButton>
      </AnswerRow>
    );
  }
}

AnswerBubbleGridChoice.propTypes = {
  question: PropTypes.object,
  answer: PropTypes.object,
  examChooseAnswer: PropTypes.func,
  isAnswerSheetOpen: PropTypes.any,
  rowSelected: PropTypes.number,
  questionIndex: PropTypes.number,
  onKeyUpForTab: PropTypes.func,
  selectRow: PropTypes.func,
};

export default AnswerBubbleGridChoice;
