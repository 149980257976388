import { connect } from 'react-redux';
import PracticeReviewManagement from '../../components/PracticeReviewManagement';
import { actions as PracticeActions, selectors as PracticeSelectors } from '../../reducers/practice';
import {
  selectors as LeftSideBarSelector,
} from '../../reducers/leftSideBar';
import { actions as userActions } from '../../reducers/user';

const mapStateToProps = state => ({
  report: PracticeSelectors.getReport(state),
  isTakeReport: PracticeSelectors.isTakeReport(state),
  isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
});

const mapDispatchToProps = {
  practiceSessionReport: PracticeActions.practiceSessionReport,
  userReportIssue: userActions.userReportIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(PracticeReviewManagement);
