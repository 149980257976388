import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoginLoadingIndicator from '../LoginPage/LoginLoadingIndicator';

export default class ClasslinkLoadingPage extends Component {
  static propTypes = {
    classlinkLogin: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    isAuthenticated: PropTypes.bool,
  }

  componentDidMount() {
    const {
      classlinkLogin, location,
      isAuthenticated, connectClasslink
    } = this.props;
    const searchParams = new URLSearchParams(location.search);
    if (!isAuthenticated) classlinkLogin(searchParams.get('code'));
    else connectClasslink(searchParams.get('code'));
  }

  render() {
    return <LoginLoadingIndicator content="Connecting your account with ClassLink" />;
  }
}
