import { connect } from 'react-redux';
import _get from 'lodash/get';
import TestPDFViewer from '../../components/TestPDFViewer';
import { actions as sectionExamActions, selectors as sectionExamSelectors } from '../../reducers/sectionExam';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const examSessionId = _get(match, 'params.examSessionId', '');
  const examSectionId = _get(match, 'params.examSectionId', '');
  const courseId = _get(match, 'params.courseId', '');

  return {
    courseId,
    sectionId,
    examSessionId,
    examSectionId,
    examDetail: sectionExamSelectors.getExamDetailById(state, examSessionId),
  };
};

const mapDispatchToProps = {
  sectionExamGetDetail: sectionExamActions.sectionExamGetDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPDFViewer);
