import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import MediaQuery from 'react-responsive';
import {
  LeftSiderBarWrap,
  ActionShowHide as ActionShowHideR,
} from '../../LeftSideBar/LeftSiderBar.style';

export const RightSiderBarWrap = styled(LeftSiderBarWrap)`
  right: 0;
  left: auto;
  width: 40rem;
  max-width: calc(100vw - 6.5rem);
  &:after {
    right: auto;
    left: 0;
  }
  @media (max-width: 960px) {
    left: auto;
  }
  div {
    opacity: ${(props) => (props.isClose ? 0 : 1)} !important;
  }
  ${({ isClose }) =>
    isClose &&
    css`
      right: 0;
      left: auto;
      transform: translateX(100%) translateX(0.7rem);
      @media (max-width: 960px) {
        left: auto;
        width: calc(100vw - 5rem);
      }
    `};

`;

export const ActionShowHide = styled(ActionShowHideR)`
  right: auto;
  left: -0.85em;
`;
const RightSiderBar = ({ children, isOpen = undefined, onClose }) => {
  const [isSideBarClose, setIsSideBarClose] = useState(true);
  const handleShowHide = () => {
    if (isOpen === undefined) {
      setIsSideBarClose(!isSideBarClose);
    }
    if (onClose) {
      onClose();
    }
  };
  useEffect(() => {
    if (isOpen !== undefined) {
      setIsSideBarClose(!isOpen);
    }
  }, [isOpen]);
  return (
    <RightSiderBarWrap isClose={isSideBarClose}>
      <ActionShowHide className="toggle-icon" onClick={handleShowHide}>
        <span
          className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-down'}
        />
      </ActionShowHide>
      {children}
    </RightSiderBarWrap>
  );
};
export default React.memo(RightSiderBar);
