import React, { Suspense } from 'react';
import useLessonBreadcrumb from '@components/Component/Lesson/Hooks/useLessonBreadcrumb';
import LessonReviewQuestionSelector from '@components/LearnosityLessonReview/LessonReviewSetup';
import { useCurrentCourse } from '@reducers/courses/hooks';
import BreadcumbsFilter from '@components/BreadcumbsFilter';
import Box from '@components/Atoms/Box';
import Skeleton from '@components/Atoms/Skeleton';

const LessonReviewPage = () => {
  const lessonBreadcrumb = useLessonBreadcrumb();
  const course = useCurrentCourse();
  return (
    <Box display="flex" flexDirection="column" width="100%">
      <BreadcumbsFilter
        haveSub
        breadcumbSubList={[course.name]}
        breadcumbList={['Lesson', 'Assessment']}
        mobileTitle={lessonBreadcrumb[lessonBreadcrumb.length - 1]}
        isDisplayFilter={false}
      />
      <Suspense
        fallback={
          <Box sx={{ display: 'flex', height: '100%' }}>
            <Skeleton
              sx={{ transform: 'unset', margin: '1rem' }}
              height="80%"
              width="100%"
            />
          </Box>
        }
      >
        <LessonReviewQuestionSelector />
      </Suspense>
    </Box>
  );
};

export default LessonReviewPage;
