const ColumnSize = [
  { smallSize: true, haveFilter: true, textCapitalize: true },
  { size: '90', smallSize: true, textCenter: true },
  { size: '90', smallSize: true, textCenter: true },
  { size: '90', smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { size: '110', smallSize: true, textCenter: true },
  { percent: '20', smallSize: true, textCenter: true },
  { size: '90', smallSize: true, textCenter: true },
];

export default ColumnSize;
