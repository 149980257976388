import { connect } from 'react-redux';
import CourseCreatedModal from '../../components/Modals/CourseCreatedModal';
import {
  selectors as CourseSelectors,
  actions as CourseActions,
} from '../../reducers/courses';

const mapStateToProps = (state) => ({
  errorMessage: CourseSelectors.getRequestInviteError((state)),
  isRequestedInvite: CourseSelectors.isRequestedInvite(state),
});

const mapDispatchToProps = {
  requestInviteCourse: CourseActions.requestInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseCreatedModal);
