/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import ShowIf from '@Atoms/ShowIf';
import Typography from '@Atoms/Typography';
import { useGetGroupActivityInfo } from '@components/Component/OLP/tab/hooks/query';
import { activityStatus } from '@components/Component/OLP/tab/types';
import { Box } from '@mui/material';
import Select from '@components/Atoms/Select';
import IconButton from '@components/Atoms/IconButton';
import { useIsTeacher } from '@reducers/user/hooks';
import {
  GroupSection,
  GroupSectionWrapper,
  selectWrapper,
} from './GroupActivityTable.style';
import ListLoader from '../LoadingPlaceholder/ListLoader';
import { SectionRowIcon } from '../ExamSectionTable/ExamSectionTable.style';
import LessonExamConfirmModal from '../ConfirmModal';
import GroupRow from './GroupRow';
import AbsentRow from './AbsentRow';
import {
  useActivityGroups,
  useActivityAbsentGroup,
  useSwapStudentsMutation,
  useMoveStudentToGroupMutation,
  useMoveStudentToAbsentGroupMutation,
  useMoveStudentToNewGroupMutation,
  useRearrangeGroupsSizeMutation,
  useResetPracticeForGroup,
} from './hooks/queries';
import GroupCells from './GroupCells';
import useWebSocketListener from './hooks/useWebSocketListener';
import { GroupStatusEnum } from './types';
import TooltipDisabledButton from '../Generals/TooltipDisabledButton';

export const status = {
  0: 'Not Started',
  1: 'In progress (Individual Stage)',
  2: 'In progress (Group Discussion)',
  3: 'Completed',
};
export const useDragEffect = () => {
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
  }));
  useLayoutEffect(() => {
    if (isDragging) {
      // add class to body dragging
      document.body.classList.add('dragging');
    } else {
      document.body.classList.remove('dragging');
    }
  }, [isDragging]);
};
const renderGroupStatus = (group) => group?.group_status ?? 0;
const studentColumnSize = {
  1: 50,
  2: 40,
  3: 30,
  4: 20,
};

const renderResetIconTooltipsText = (group, activityStage) => {
  if (activityStage === activityStatus.started) {
    return `Score can't be reset during activity`;
  }
  if (group.group_status === GroupStatusEnum.NOT_STARTED) {
    return 'There is no score to reset';
  }
  if (group.group_status !== GroupStatusEnum.NOT_STARTED) {
    return 'Reset the practice and clear student scores so they can take it again';
  }
};

const GroupActivityTable = ({
  studentId,
  loadingGroupActivity,
  maxNumOfGroupStudents,
}) => {
  const isTeacher = useIsTeacher();
  const {
    data: { id: groupActivityId, online_activity_status: onlineActivityStatus = 0 },
  } = useGetGroupActivityInfo();

  const {
    data: groupsQueryData,
    isPlaceholderData,
    isLoading,
    refetch: refetchGroups,
  } = useActivityGroups(groupActivityId);
  const [columnNumber, setColumnNumber] = useState(maxNumOfGroupStudents || 3);
  const [submittingGroupChange, setSubmittingGroupChange] = useState(false);
  const [resetModalOpen, setResetModalOpen] = useState(null);
  const { data: absentGroup, refetch: refetchAbsentGroup } =
    useActivityAbsentGroup(groupActivityId);

  const swapStudentMutation = useSwapStudentsMutation();
  const moveStudentToGroup = useMoveStudentToGroupMutation();
  const moveStudentToAbsentGroupMutation = useMoveStudentToAbsentGroupMutation();
  const moveStudentToNewGroupMutation = useMoveStudentToNewGroupMutation();
  const rearrangeGroupsSizeMutation = useRearrangeGroupsSizeMutation(groupActivityId);
  const resetPracticeForGroupMutation = useResetPracticeForGroup(groupActivityId);

  useDragEffect();
  const currentUserGroup = useMemo(() => {
    if (groupsQueryData.length > 0 && !loadingGroupActivity && !isLoading) {
      const [group, groupIndex] = groupsQueryData.reduce((acc, _group, index) => {
        const user = _group?.students?.find(
          (_student) => _student.student_id === studentId,
        );
        if (user) {
          return [_group, index];
        }
        return acc;
      }, []);

      return { currentGroup: group || {}, index: (groupIndex || 0) + 1 };
    }
    return {
      currentGroup: {},
      index: 1,
    };
  }, [groupsQueryData, loadingGroupActivity, studentId]);

  // split to custom hook with the state
  // find the max number of students in a group to decide the number of columns

  useEffect(() => {
    // find the max number of students in a group to decide the number of columns
    if (groupsQueryData.length > 0 && !isPlaceholderData) {
      const max = groupsQueryData.reduce(
        (prev, current) => Math.max(prev, current.students?.length),
        1,
      );
      setColumnNumber(max > 4 ? 4 : max);
    }
  }, [isPlaceholderData]);

  const renderStudentsColumn = (maxLength) => (
    <>
      {new Array(maxLength).fill(0).map((_, index) => (
        <li key={`student-column-${index}`}>Student {index + 1}</li>
      ))}
    </>
  );
  const handleGroupSwap = (sourceCellId, sourceRowId, targetRowId) => {
    setSubmittingGroupChange(true);
    moveStudentToGroup.mutate(
      {
        group: targetRowId,
        groupActivityId,
        student: sourceCellId,
      },
      {
        onSuccess: () => {
          refetchGroups({
            stale: true,
            active: true,
          });
          refetchAbsentGroup();
          setSubmittingGroupChange(false);
        },
      },
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStudentMoveToAbsentGroup = (sourceCellId, _sourceRowId, _targetRowId) => {
    setSubmittingGroupChange(true);
    moveStudentToAbsentGroupMutation.mutate(
      {
        group: null,
        groupActivityId,
        student: sourceCellId,
      },
      {
        onSuccess: () => {
          refetchGroups();
          refetchAbsentGroup();
          setSubmittingGroupChange(false);
        },
      },
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStudentSwap = (sourceCellId, _sourceRowId, targetCellId, _targetRowId) => {
    setSubmittingGroupChange(true);
    swapStudentMutation.mutate(
      {
        groupActivityId,
        student_1: sourceCellId,
        student_2: targetCellId,
      },
      {
        onSuccess: () => {
          refetchGroups();
          setSubmittingGroupChange(false);
        },
      },
    );
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDropStudentOnNewGroup = (sourceCellId, _sourceRowId, _targetRowId) => {
    setSubmittingGroupChange(true);
    moveStudentToNewGroupMutation.mutate(
      {
        groupActivityId,
        student: sourceCellId,
      },
      {
        onSuccess: () => {
          setSubmittingGroupChange(false);
        },
      },
    );
  };

  const handleSizeChange = (value) => {
    if (columnNumber === value) {
      return value;
    }
    rearrangeGroupsSizeMutation.mutate(
      {
        groupActivityId,
        size: value,
      },
      {
        onSuccess: () => {
          setColumnNumber(value);
        },
      },
    );
  };

  const hasAnyGroupFinished = React.useMemo(
    () =>
      groupsQueryData.some((group) => group.group_status === GroupStatusEnum.FINISHED),
    [groupsQueryData],
  );

  // To reset the group practice
  const resetPractice = (groupId) => {
    resetPracticeForGroupMutation.mutate(groupId);
    setResetModalOpen(null);
  };
  useWebSocketListener();
  // ---------------------render---------------------
  if (isLoading) {
    return (
      <div
        style={{
          height: '20rem',
          paddingTop: '1.5rem',
          overflowY: 'hidden',
        }}
      >
        <ListLoader />
      </div>
    );
  }
  if (!groupsQueryData.length && !absentGroup?.students?.length) {
    return null;
  }

  return (
    <>
      <ShowIf
        If={
          isTeacher &&
          [activityStatus.notStarted, activityStatus.ended].includes(onlineActivityStatus)
        }
      >
        <Box sx={selectWrapper}>
          <TooltipDisabledButton
            disabled={!hasAnyGroupFinished}
            title={
              hasAnyGroupFinished &&
              'You must reset the answers for all groups to resize the groups'
            }
          >
            <Select
              id="group-size-select"
              onChangeHandler={handleSizeChange}
              defaultValue={columnNumber}
              list={[
                { value: 1, label: 1 },
                { value: 2, label: 2 },
                { value: 3, label: 3 },
                { value: 4, label: 4 },
              ]}
              label="Group Size"
              size="small"
              sx={{ width: '5rem' }}
              disabled={hasAnyGroupFinished}
            />
          </TooltipDisabledButton>
        </Box>
      </ShowIf>
      <GroupSectionWrapper $loading={submittingGroupChange}>
        {/* header */}
        <GroupSection flex={studentColumnSize[columnNumber]}>
          <div style={{ display: 'flex' }}>
            <ul>
              <li>Group Number</li>
              {renderStudentsColumn(columnNumber)}
            </ul>
            <ShowIf If={isTeacher}>
              <ul className="group-progress header-progress">
                <li>Progress</li>
                <li>Actions</li>
              </ul>
            </ShowIf>
          </div>
        </GroupSection>
        {/* rows */}
        <GroupSection className="student-names" flex={studentColumnSize[columnNumber]}>
          {/* Teacher view  */}
          <ShowIf If={isTeacher}>
            {groupsQueryData.map((group, index) => (
              <div
                style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                key={`render-oga-table-${group.id}`}
              >
                <GroupRow
                  rowId={group.id}
                  activityStarted={activityStatus.started === onlineActivityStatus}
                  onDropCell={handleGroupSwap}
                  isTeacher={isTeacher}
                  // we want to disable drop for that group row  if the group is full or the group has finished
                  disableDrop={
                    group.students.length >= columnNumber ||
                    group.group_status === GroupStatusEnum.FINISHED
                  }
                >
                  <div className="group-number-cell">{index + 1}</div>
                  <GroupCells
                    members={group?.students}
                    rowId={group.id}
                    activityStarted={activityStatus.started === onlineActivityStatus}
                    columnNumber={columnNumber}
                    handleStudentSwap={handleStudentSwap}
                  />

                  <ul className="group-progress">
                    <li>{status[renderGroupStatus(group)]}</li>
                    <li>
                      <TooltipDisabledButton
                        disabled={false}
                        title={renderResetIconTooltipsText(group, onlineActivityStatus)}
                      >
                        <IconButton
                          disabled={
                            group.group_status === GroupStatusEnum.NOT_STARTED ||
                            onlineActivityStatus === activityStatus.started
                          }
                          onClick={() => setResetModalOpen(group.id)}
                        >
                          <SectionRowIcon
                            disabled={
                              group.group_status === GroupStatusEnum.NOT_STARTED ||
                              onlineActivityStatus === activityStatus.started
                            }
                            className="chalktalk-reset"
                          />
                        </IconButton>
                      </TooltipDisabledButton>
                    </li>
                  </ul>
                </GroupRow>
              </div>
            ))}
          </ShowIf>
          {/* Student view  */}
          <ShowIf If={!isTeacher && currentUserGroup?.currentGroup?.students?.length > 0}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              <GroupRow
                rowId={currentUserGroup?.id}
                activityStarted={activityStatus.started === onlineActivityStatus}
                onDropCell={handleGroupSwap}
                isTeacher={isTeacher}
              >
                <div className="group-number-cell">{currentUserGroup?.index}</div>
                <GroupCells
                  members={currentUserGroup?.currentGroup?.students}
                  rowId={currentUserGroup?.index}
                  activityStarted={[
                    activityStatus.started,
                    activityStatus.ended,
                  ].includes(onlineActivityStatus)}
                  columnNumber={columnNumber}
                  handleStudentSwap={handleStudentSwap}
                />
              </GroupRow>
            </div>
          </ShowIf>
          {/* create new group row */}
          <ShowIf
            If={
              isTeacher &&
              [activityStatus.notStarted, activityStatus.ended].includes(
                onlineActivityStatus,
              )
            }
          >
            <GroupRow
              rowId="create-new-group"
              activityStarted={false}
              onDropCell={handleDropStudentOnNewGroup}
              withBorder
            >
              <Typography
                variant="actionText"
                className="create-new-group"
                align="center"
                sx={{
                  width: '100%',
                }}
              >
                Drop students here to create a new group
              </Typography>
            </GroupRow>
          </ShowIf>

          <AbsentRow
            isActivityStarted={activityStatus.started === onlineActivityStatus}
            onDropCell={handleStudentMoveToAbsentGroup}
            studentList={absentGroup?.students || []}
          />
        </GroupSection>
      </GroupSectionWrapper>
      <LessonExamConfirmModal
        isOpen={resetModalOpen}
        onConfirm={() => resetPractice(resetModalOpen)}
        onClose={() => setResetModalOpen(null)}
        content="Are you sure you want to reset this practice? The practice will be reset and scores will be deleted."
      />
    </>
  );
};

export default GroupActivityTable;
