import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Review } from '@learnosityassessment';
import { useSelector } from 'react-redux';
import LoadingIndicator from '../LoadingIndicator';
import { practiceItems } from '../../apis/practice';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';

const scrollStyle = {
  backgroundColor: 'white',
  height: 'calc(100vh - 5em)',
  width: '100%',
};

const StandardsPracticeReview = () => {
  const history = useHistory();
  const { practiceSessionId, unitId, lessonId } = useParams();
  // @todo on refresh, the lessons is empty
  const lessons = useSelector((state) =>
    sectionUnitSelectors.getAllLessonOfUnit(state, unitId),
  );
  const lesson = lessons.find((l) => l.id == lessonId);
  const { data: practice } = useQuery(
    ['practiceItems', practiceSessionId],
    async () => {
      const data = (await practiceItems(practiceSessionId))?.response?.data;
      return data;
    },
    {
      enabled: !!practiceSessionId,
      staleTime: 24 * 1000 * 60 * 60 /* 24h */,
    },
  );

  const goBack = () => {
    history.goBack();
  };

  if (!practice) {
    return <LoadingIndicator />;
  }
  // the redux doesn't have lesson detail and it will not , so redirect at this point.
  if (!lesson) {
    return <Redirect to="/dashboard/course" />;
  }
  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Go back" practiceName="" goBack={goBack} />
      {practice ? (
        <ScrollWrapper sx={scrollStyle}>
          <Review
            userId={`${practice.user}`}
            sessionId={`${practice.uuid}`}
            activityId={`${practice.lesson}`}
            name="ChalerTalk Assessment"
            title={lesson.name}
            loading={<LoadingIndicator partial content="Pulling Practice Data..." />}
          />
        </ScrollWrapper>
      ) : (
        <LoadingIndicator content="Preparing Your Review" />
      )}
    </PracticeContainer>
  );
};

export default React.memo(StandardsPracticeReview);
