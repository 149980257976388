const ColumnSize = [
  { size: '25', smallSize: true, textCapitalize: true },
  { percent: '22', smallSize: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
];

export default ColumnSize;
