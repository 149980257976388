import React from 'react';
import _get from 'lodash/get';
import _unionBy from 'lodash/unionBy';
import ShowIf from '@components/Atoms/ShowIf';
import { Unit, UnitLessonData } from '@CT-Types/lessonDomain';
import { useParams } from 'react-router-dom';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { useSectionStudent } from '@reducers/sectionStudent/hooks';
import UnitAssessmentSubNavItemWrapper from './UnitAssessmentSubNavItem';
import LessonSubNavItem from '../LessonSubNavItem';
import UnitPracticeSubNavItem from '../UnitPracticeSubNavItem';

interface Props {
  unit: Unit;
  practiceSummaryByUnitId: Record<string, any[]>;
  subjectVersion: number;
  unitAssessmentVisibleState: Record<number, boolean>;
  isReviewClassEnabled: boolean;
}

const calculatePracticePercent = (
  isTeacher: boolean,
  studentList: any[],
  assetLength: number,
  selectionPracticeList: any[],
  userId: number,
  subjectVersion: number,
): number => {
  if (subjectVersion !== 1) {
    if (isTeacher) {
      return studentList.length > 0 && assetLength
        ? Math.min(
            Math.round(
              (selectionPracticeList.length / (studentList.length * assetLength)) * 100,
            ),
            100,
          )
        : 0;
    }
    const practiceStudentList = selectionPracticeList.filter((x) => x.user === userId);
    return practiceStudentList.length && assetLength
      ? Math.min(Math.round((practiceStudentList.length / assetLength) * 100), 100)
      : 0;
  }

  if (isTeacher) {
    const practiceStudentList = _unionBy(selectionPracticeList, 'user');
    return studentList.length > 0
      ? Math.round((practiceStudentList.length / studentList.length) * 100)
      : 0;
  }

  const practiceStudentList = selectionPracticeList.filter((x) => x.user === userId);
  return practiceStudentList.length ? 100 : 0;
};

const UnitItemsWrapper: React.FC<Props> = ({
  unit,
  practiceSummaryByUnitId,
  subjectVersion,
  unitAssessmentVisibleState,
  isReviewClassEnabled,
}) => {
  const isTeacher = useIsTeacher();
  const currentUser = useUser();
  const studentList = useSectionStudent();
  const userId = currentUser.id;

  const { unitId, lessonId } = useParams();
  const unitLessonData = React.useMemo(() => {
    if (!unit) {
      return [];
    }
    const list: UnitLessonData[] = [];

    const getUnitPractice = (unitItem: Unit): void => {
      const unitHasPractice = _get(unitItem.meta, 'unit_practice', false);
      if (!unitHasPractice) {
        return;
      }

      const { name, id, curriculum_subject: subjectId, assets = [] } = unitItem;
      const selectionPracticeList = _get(practiceSummaryByUnitId, `${id}`, []);

      const percent = calculatePracticePercent(
        isTeacher,
        studentList,
        assets.length,
        selectionPracticeList,
        userId,
        subjectVersion,
      );

      const isActive = id === parseInt(unitId, 10) && !lessonId;

      list.push({
        type: 'practice',
        isActive,
        name,
        percent,
        unitId: id,
        subjectId,
      });
    };

    const getLessons = (selectedUnit: Unit): void => {
      const { lessons, children } = selectedUnit;

      if (lessons) {
        const sortedLessons = [...lessons].sort((a, b) => a.group_order - b.group_order);
        sortedLessons.forEach((lesson, index) => {
          list.push({
            type: 'lesson',
            lesson,
            isFirstItem: index === 0,
            isLastItem:
              index === lessons.length - 1 &&
              !_get(selectedUnit.meta, 'unit_practice', false),
            unitItem: selectedUnit,
          });
        });
      }

      if (children) {
        children.forEach((child) => {
          getLessons(child);
          getUnitPractice(child);
        });
      }
    };

    getLessons(unit);
    getUnitPractice(unit);

    return list;
  }, [
    isTeacher,
    lessonId,
    practiceSummaryByUnitId,
    studentList,
    subjectVersion,
    unit,
    unitId,
    userId,
  ]);
  const showUnitAssessment = unitAssessmentVisibleState[unit.id];

  return (
    <>
      <ShowIf If={showUnitAssessment}>
        <UnitAssessmentSubNavItemWrapper name="Pre" unit={unit} />
      </ShowIf>

      {unitLessonData.map((item, index) => {
        if (item.type === 'practice') {
          return (
            <UnitPracticeSubNavItem
              key={item.unitId}
              name={item.name || ''}
              percent={item.percent || 0}
              unitId={item.unitId}
              subjectId={item.subjectId}
            />
          );
        }

        return (
          <LessonSubNavItem
            key={item.lesson.id}
            lessonItem={item.lesson}
            isFirstItem={index === 0}
            isLastItem={index === unitLessonData.length - 1}
            unit={item.unitItem}
            isReviewClassEnabled={isReviewClassEnabled}
          />
        );
      })}

      <ShowIf If={showUnitAssessment}>
        <UnitAssessmentSubNavItemWrapper name="Post" unit={unit} />
      </ShowIf>
    </>
  );
};

export default UnitItemsWrapper;
