import React from 'react';

/**
 * Debounce a function
 * @returns run - trigger Debounced function
 * @returns isWaiting - Boolean indicating if the function is currently debouncing
 * @example
 * const { run , isWaiting } = useDebounce((value: string) => console.log(value), 500);
 * run('Hello World');
 * // 'Hello World' will be logged after 500ms
 */
const useDebounce = <T extends (...args: any[]) => void>(callback: T, delay: number) => {
  const callbackRef = React.useRef(callback);
  const timerRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isWaiting, setIsWaiting] = React.useState(false);

  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debounce = (func: T, delayMs: number, ...args: Parameters<T>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setIsWaiting(true);

    timerRef.current = setTimeout(() => {
      func(...args);
      setIsWaiting(false); // Timer finished, set isLoading to false
    }, delayMs);
  };

  const debouncedFunction = React.useMemo(
    () =>
      (...args: Parameters<T>) =>
        debounce(callbackRef.current, delay, ...args),
    [delay],
  );

  return { run: debouncedFunction, isWaiting };
};

export default useDebounce;
