/* eslint-disable react/no-array-index-key */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import LeftSideBarTest from '../../containers/LeftSideBar/LeftSideBarTest';
import TestDetailContent from '../../containers/TestDetailContent';
import TestDetailContentV2 from '../../containers/TestDetailContentV2';
import MainContent from '../../containers/MainContent';
import { PrimaryWrap } from '../Generals/stylesheets/General.style';
import ExamSections from '@components/Component/ExamSections';
import { ROLE_TYPE } from '../../utils/enums';
import {
  LessonEmptyWrap,
  LessonEmptyIcon,
  LessonEmptyData,
  LessonEmptyAction,
} from '../LessonManagement/LessonManagement.style';
import * as SectionAPI from '../../apis/exam';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
class TestManagement extends PureComponent {
  state = {
    exams: [],
  };

  componentDidMount() {
    const {
      sectionId,
      sectionsStudentGet,
      getCurriculumList,
      userRole,
      lastestExam,
      shouldRenderTestContent,
      courseId,
      fetchExamV2Access,
    } = this.props;
    if (sectionId !== null) {
      if (userRole !== ROLE_TYPE.STUDENT) sectionsStudentGet(sectionId);
      this.renderSessions();
      fetchExamV2Access()
    }

    getCurriculumList();
  }

  componentDidUpdate(prevProps) {
    const { sectionId: prevSectionId } = prevProps;
    const {
      sectionId,
      sectionsStudentGet,
      lastestExam,
      shouldRenderTestContent,
      courseId,
      userRole,
    } = this.props;

    if (sectionId !== null && !_isEqual(sectionId, prevSectionId)) {
      if (userRole !== ROLE_TYPE.STUDENT) sectionsStudentGet(sectionId);
      this.renderSessions();
    }
  }

  renderSessions = async () => {
    const { sectionDetail, history, courseId, match } = this.props;

    const { exams } = sectionDetail;
    if (exams) {
      const examPromises = [];
      for (let i = 0; i < exams.length; i++) {
        const response = SectionAPI.getExamDetail(exams[i]);
        examPromises.push(response);
      }
      const examsArray = (await Promise.all(examPromises).then((data) => data))
        .filter((item) => item.response?.data)
        .map((item) => item.response.data)
        .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

      this.setState({ exams: examsArray });
      let { id: examToRender } = examsArray[0];

      const unmarkedExams = examsArray.filter((exam) => exam.done !== true);

      examToRender = unmarkedExams.length
        ? unmarkedExams[0].id
        : examsArray[exams.length - 1].id;

      if (!match.params.examSessionId) {
        history.push(
          `/test/course/${courseId}/section/${sectionDetail.id}/exam/${examToRender}`,
        );
      }
    }
  };

  renderNoExam = () => {
    const pathNumber = Array.from(Array(23).keys());
    const { history, courseId, sectionId } = this.props;
    return (
      <MainContent>
        <LessonEmptyWrap>
          <LessonEmptyIcon className="chalktalk-schedule-calendar">
            {pathNumber.map((number, index) => (
              <i className={`path${number}`} key={index} />
            ))}
          </LessonEmptyIcon>
          <LessonEmptyData>
            <span>There are no exam created. </span>
            <span>Click </span>
            <LessonEmptyAction
              type="button"
              onClick={() =>
                history.push(
                  `/schedule/course/${courseId}/section/${sectionId}/create-exam-session`,
                )
              }
            >
              + Add new exam session
            </LessonEmptyAction>
          </LessonEmptyData>
        </LessonEmptyWrap>
      </MainContent>
    );
  };

  renderTestContent = () => {
    const { hasExamV2Access } = this.props;
    return (
      hasExamV2Access ? <TestDetailContentV2 /> : <TestDetailContent />
    )
  }

  render() {
    const { shouldRenderTestContent, hasExamV2Access } = this.props;
    return (
      <PrimaryWrap>
        <ExamSections>
          <LeftSideBarTest exams={this.state.exams} pathName="/test/course" hasExamV2Access={hasExamV2Access}/>
          {shouldRenderTestContent ? this.renderTestContent() : this.renderNoExam()}
        </ExamSections>
      </PrimaryWrap>
    );
  }
}

TestManagement.propTypes = {
  sectionId: PropTypes.any,
  sectionsStudentGet: PropTypes.func,
  getCurriculumList: PropTypes.func,
  shouldRenderTestContent: PropTypes.bool,
  lastestExam: PropTypes.any,
  history: PropTypes.any,
  courseId: PropTypes.any,
  userRole: PropTypes.string,
  sectionDetail: PropTypes.object,
};

export default TestManagement;
