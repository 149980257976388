import React from 'react';
import * as PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import MediaQuery from 'react-responsive';

import { InfoEventFormWrapper } from './Calendar.style';
import { ModalCLose, ModalHeader } from '../Generals/stylesheets/General.style';
import {
  CALENDAR_EVENT_TYPE,
} from '../../utils/constants';
import { ExamForm, ReviewForm, LessonForm } from '../../containers/ScheduleCalendar/ModalForm';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%',
    maxWidth: '500px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    overflow: 'unset',
    borderRadius: '10px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const renderModalForm = (modalData, onlyEditDate) => {
  switch (modalData.type) {
    case CALENDAR_EVENT_TYPE.REVIEWS: {
      const newModalData = {
        ...modalData,
        date: moment(modalData.date).toDate(),
      };
      return (
        <ReviewForm initialValues={newModalData} />
      );
    }

    case CALENDAR_EVENT_TYPE.EXAMS: {
      const newModalData = {
        ...modalData,
        start_date: moment(modalData.start_date).toDate(),
        end_date: moment(modalData.end_date).toDate(),
      };
      return (
        <ExamForm initialValues={newModalData} />
      );
    }

    case CALENDAR_EVENT_TYPE.LESSONS: {
      const newModalData = {
        ...modalData,
        date: moment(modalData.date).toDate(),
      };
      return (
        <LessonForm initialValues={newModalData} onlyEditDate={onlyEditDate} />
      );
    }

    default: {
      return (
        <div>
          Invalid form data
        </div>
      );
    }
  }
};

const onChangeCustomModalStyle = (matches) => {
  const { content } = modalStyles;
  if (matches) {
    return {
      ...modalStyles,
      content: {
        ...content, maxWidth: 'unset', height: '100%', borderRadius: '0px',
      },
    };
  }
  return modalStyles;
};

export const InfoModal = ({
  closeForm, isOpen, modalData, onlyEditDate = false,
}) => {
  if (!isOpen) return null;

  return (
    <MediaQuery maxWidth={768}>
      {mathes => (
        <Modal
          isOpen={isOpen}
          contentLabel="Confirm Action"
          onRequestClose={closeForm}
          style={onChangeCustomModalStyle(mathes)}
          ariaHideApp={false}
        >
          <ModalHeader>
            {modalData.title}
            <ModalCLose className="chalktalk-close" onClick={closeForm} />
          </ModalHeader>
          <InfoEventFormWrapper>
            {renderModalForm(modalData, onlyEditDate)}
          </InfoEventFormWrapper>
        </Modal>
      )}
    </MediaQuery>
  );
};

InfoModal.propTypes = {
  closeForm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalData: PropTypes.shape(),
  onlyEditDate: PropTypes.bool,
};

// ====================================================
