import { connect } from 'react-redux';
import PasswordForm from '../../components/PasswordPage/PasswordForm';
import { selectors as AuthSelectors, actions as AuthActions } from '../../reducers/auth';

export const mapStateToProps = state => ({
  isLoading: AuthSelectors.isSetupPassword(state),
  errorMsg: AuthSelectors.getSetupPasswordError(state),
});

const mapDispatchToProps = {
  submitNewPassword: AuthActions.studentSetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
