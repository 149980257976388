import React from 'react';
import { Option } from '../CustomDropdown';
import { BaseSelector, BaseSelectorProps } from '../BaseSelector';

export type FleschKincaidProps = Omit<BaseSelectorProps, 'label' | 'options'>;

export const FLESCH_KINCAID_LIST = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

const FLESCH_KINCAID_OPTIONS: Option[] = FLESCH_KINCAID_LIST.map((grade) => ({
  value: grade,
  label: grade,
}));

const optionLabel = (option: Option) => option.label;

const selectedLabel = (selected: Option) => `${selected.label}`;

export const FleschKincaid: React.FC<FleschKincaidProps> = (props) => (
  <BaseSelector
    {...props}
    label="Flesch Kincaid Grade"
    options={FLESCH_KINCAID_OPTIONS}
    optionLabel={optionLabel}
    selectedLabel={selectedLabel}
  />
);

export default FleschKincaid;
