export const customTextStyle = {
  blackText: {
    color: 'black',
  },
  noWrapText: {
    whiteSpace: 'noWrap',
  },
} as const;

export const baseBoxStyle = {
  flex: {
    display: 'flex',
    gap: 1.25,
  },
} as const;
