import styled, { css } from 'styled-components';

export const LessonManagementWrap = styled.div`
  display: flex;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`;

export const LessonManagementLeftWrap = styled.div`
  overflow: auto;
  padding-bottom: 1.5rem;
  flex: 0 0 30%;
  padding-inline: 1em;
  margin-left: -1em;
  @media (max-width: 1280px) {
    flex: 0 0 50%;
    padding-inline: 0em;
  }
`;

export const LessonManagementRightWrap = styled.div`
  flex: 0 0 70%;
  margin-left: 1em;
  position: relative;
  transition: all 300ms ease;
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: -1em;
    top: 0em;
    background-color: ${(props) => props.theme.colorStyled.ColorBorderInput};
  }
  ${({ full }: { full: boolean }) =>
    full &&
    css`
      flex: 0 0 100%;
      margin-left: 0em;
      &:after {
        content: none;
      }
      iframe {
        height: calc(100vh - 15.5em);
      }
    `}
  @media (max-width: 1280px) {
    margin-left: 0em;
    flex: 0 0 50%;
    &:after {
      content: none;
    }
  }
  .full-screen-outer-button-wrapper {
    margin-top: 10px;
  }
  .full-screen-button-wrapper {
    position: fixed;
    bottom: 0.8em;
    left: 0.5em;
    z-index: 999;
  }
`;

export const LessonVideoTitlte = styled.div``;

export const LessonVideoContent = styled.div`
  min-height: 20em;
  height: calc(100% - 2em);
  background-color: #fff;

  @media (min-width: 768px) {
    height: 60vh;
  }

  ${({ isFullScreen }: { isFullScreen: boolean }) =>
    isFullScreen &&
    css`
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
      height: 100% !important;
      width: 100% !important;
      z-index: 10;
    `}
`;

export const LessonSubjectItem = styled.div`
  box-shadow: #091e4240 0px 1px 1px, #091e424f 0px 0;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 1px solid #f1f1f1;
  border-radius: 0.5em;
  margin: 0.125em 0.125em 0.75em;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0.125em;
  }
`;

export const LessonTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75em;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colorStyled.ColorBgSeashell};
  }
  > * {
    transition: all 300ms ease;
  }
  p {
    margin-right: auto;
  }
  span {
    margin: 0em 1em;
  }
  i {
    font-size: ${(props) => props.theme.fontSize.HeadingH5FontSize};
  }
  ${({ isOpen }: { isOpen: boolean }) =>
    isOpen &&
    css`
      i {
        transform: rotate(180deg);
      }
    `}
`;

export const LessonList = styled.div``;

export const LessonItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75em;
  border-top: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.theme.colorStyled.ColorBgSeashell};
  }
  i {
    font-size: ${(props) => props.theme.fontSize.HeadingH2FontSize};
  }
`;

export const LessonGroupContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const LessonName = styled.div`
  font-size: 13px;
  margin: 0em 0.5em;
`;

export const LessonPlayIcon = styled.i`
  font-size: 1.2em !important;
`;

export const LessonStatusIcon = styled.i``;

export const LessonDownloadIcon = styled.span`
  margin: 0 0.5em;
  color: #cdcdcd;
  font-size: 1.5em !important;
  position: relative;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
  > div {
    position: absolute;
    right: -0.675em;
    bottom: -0.675em;
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-radius: 100%;
    background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1em;
    height: 1em;
    padding: 0.675em;
    z-index: 2;
    font-family: 'MontserratSemiBold';
    font-size: ${(props) => props.theme.fontSize.XSmallMobileFontSize};
    border: 1px solid ${(props) => props.theme.colorStyled.ColorWhite};
  }
`;

export const LessonUnitPracticeWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 1440px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const LessonManagementBlock = styled.div`
  flex: 0 0 30em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1.5em 3em;
  margin-top: 2em;
  margin-right: 1em;
  border-radius: 0.75em;
  position: relative;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  box-shadow: #091e4240 0px 0px 2px, #091e424f 0px 0;
  @media (max-width: 1440px) {
    flex: auto;
    margin-right: 0em;
    margin-bottom: 1em;
  }
`;

export const LessonBlockTitle = styled.div`
  padding: 1.5em 1em;
`;

export const LessonSection = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 40em;
  p {
    padding: 0.5em 1em;
    margin-bottom: 1em;
    margin-right: 1em;
    background: #dfe2e6;
    border-radius: 0.325em;
  }
`;

export const LessonSkillLevel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContentLine = styled.div`
  font-size: ${(props) => props.theme.fontSize.SmallFontSize};
`;

export const LevelSkill = styled.div`
  font-size: ${(props) => props.theme.fontSize.LargeFontSize};
  font-family: 'MontserratBold';
  margin: 0.5em 0em;
`;

export const LevelSkillDot = styled.div`
  display: flex;
  align-items: center;
`;

export const LessonViewButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  padding: 0em;
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  &:hover {
    opacity: 0.6;
  }
`;

export const Dot = styled.span`
  background: #808080;
  width: 0.75em;
  height: 0.75em;
  border-radius: 0.15em;
  margin-right: 0.25em;
  ${({ isActive }: { isActive: boolean }) =>
    isActive &&
    css`
      background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    `}
`;

export const LessonTableWrap = styled.div`
  margin-left: 1em;
  margin-top: 2em;
  flex: 1;
  @media (max-width: 1440px) {
    margin-left: 0em;
    margin-top: 1em;
  }
`;

export const LessonBlockIcon = styled.span`
  position: absolute;
  top: -0.775em;
  left: calc(50% - 0.5em);
  padding: 0.25em;
  border-radius: 100%;
  font-size: 3em;
  border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  @media (max-width: 1440px) {
    left: calc(50% - 0.75em);
  }
`;

export const LessonAssignStatus = styled.div`
  font-family: 'MontserratSemiBold';
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  cursor: pointer;
  transition: all 300ms ease;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  ${({ isAssigned }: { isAssigned: boolean }) =>
    isAssigned &&
    css`
      color: ${(props) => props.theme.colorStyled.ColorPrimaryGreen};
      &:hover {
        border-bottom: none;
      }
    `};
`;

export const UnAssignButton = styled.i`
  margin-left: 0.5em;
  color: #222;
  border-radius: 100%;
  background-color: #ddd;
  padding: 0.5em 0.5em 0.45em 0.45em;
  font-weight: bold;
  font-size: 0.75em;
`;

export const LessonScoreResultWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.75em 0em 3em;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LessonScoreResultLeft = styled.div`
  height: 100%;
  border-radius: 0.5em;
  padding: 0.75em;
  min-width: 6em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  box-shadow: 0px 0px 6px -1px ${(props) => props.theme.colorStyled.ColorBoxShadow};
  @media (max-width: 768px) {
    margin-bottom: 0.3em;
  }
`;

export const LessonScoreResultRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-left: 1em;
  margin-top: 0.3em;
  @media (max-width: 768px) {
    margin-left: 0em;
  }
`;

export const ScoreName = styled.div`
  font-family: 'MontserratSemiBold';
  text-align: center;
  color: ${(props) => props.theme.colorStyled.ColorXLightGrey};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 6em;
`;

export const ScorePointLarge = styled.div`
  text-align: center;
`;

export const ScorePointSmall = styled(ScorePointLarge)``;

export const SubScorePoint = styled.div`
  font-size: ${(props) => props.theme.fontSize.SmallFontSize};
  text-align: center;
  color: ${(props) => props.theme.colorStyled.ColorTime};
  margin-top: 0.175em;
`;

export const ScoreResultItem = styled.div`
  height: 4.5em;
  border-radius: 0.5em;
  flex: 1;
  display: flex;
  align-items: center;
  transition: all 1s;
  margin-bottom: 0.75em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  box-shadow: 0px 0px 6px -1px ${(props) => props.theme.colorStyled.ColorBoxShadow};
  &:last-child {
    margin-bottom: 0em;
  }
`;

export const ScoreResultItemLeft = styled.div`
  max-width: 11em;
  font-size: calc(${(props) => props.theme.fontSize.MediumFontSize} - 0.0625em);
  border-right: 1px solid ${(props) => props.theme.colorStyled.ColorSelectedScoreSeperate};
  padding: 0em 0.5em;
  font-family: 'MontserratSemiBold';
  height: 100%;
  display: flex;
  flex: 1;
  line-height: 1em;
  align-items: center;
  justify-content: center;
  transition: all 1s;
  @media (max-width: 768px) {
    padding: 0em 0.275em;
    max-width: 60%;
    width: 60%;
    line-height: 1.5em;
    ${(props: any) =>
      props.fitWitdh &&
      css`
        font-size: calc(${props.theme.fontSize.MediumFontSize} - 0.125em);
        line-height: 0.75em;
      `}
  }
  text-align: center;
`;

export const ScoreResultItemRight = styled.div`
  height: 100%;
  border-left: 1px solid ${(props) => props.theme.colorStyled.ColorSelectedScoreSeperate};
  display: flex;
  padding: 0em 1em;
  flex: 0 0 5.3125em;
  max-width: 5.3125em;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 480px) {
    flex: unset;
    max-width: 40%;
    width: 40%;
  }
`;

export const LessonEmptyWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
  margin: 1em;
`;

export const LessonEmptyIcon = styled.div`
  font-size: 12em;
`;

export const LessonEmptyData = styled.div`
  text-align: center;
  margin-top: 0.75em;
  line-height: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  & > ul {
    text-align: left;
    margin-top: 0.5em;
    & > li > i {
      margin: 0em 1em 0em 0em;
    }
  }
`;

export const LessonEmptyAction = styled.span`
  cursor: pointer;
  background: transparent;
  font-family: 'MontserratMedium';
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  &:hover {
    opacity: 0.8;
  }
`;

export const TeacherNotificationWrapper = styled.div`
  p {
    margin-top: 1em;
    padding: 0 30px;
    text-align: center;
    strong {
      font-weight: bold;
    }
  }
`;

export const IconWarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  i {
    margin-top: 1em;
    font-size: 70px;
    color: ${(props) => props.theme.colorStyled.ColorRedPoint};
  }
`;

export const CheckedContent = styled.div``;

export const CompletedContent = styled.div`
  box-shadow: #091e4240 0px 1px 1px, #091e424f 0px 0;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 0.5em;
  margin: 1em 0.125em 0.75em;
  overflow: hidden;
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: row;
`;
export const TextContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 13px;
  }
`;

export const AttendanceContent = styled.div`
  box-shadow: ${(props: { noElevation: boolean }) =>
    props.noElevation ? 'unset' : '#091e4240 0px 1px 1px, #091e424f 0px 0'};
  background-color: #ffffff;
  border: ${(props: { noElevation: boolean }) =>
    props.noElevation ? 'unset' : '1px solid #f1f1f1'};
  border-radius: 0.5em;
  margin: 1em 0.125em 0.75em;
  overflow: hidden;
  padding: 5px 0 5px 0;
  display: flex;
  flex-direction: column;
  padding: 2em;
`;

export const AttendanceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.25em;
`;

export const HeaderText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  p {
    font-weight: 900;
    margin-right: 5px;
  }
`;

export const AttendanceStatus = styled.span<{ present: boolean; absent: boolean }>`
  background: ${(props) => props.theme.colorStyled.ColorBgAvartar};
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  border-radius: 100%;
  width: 1.75em;
  height: 1.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 300ms ease;
  cursor: pointer;
  ${({ present }: { present: boolean }) =>
    present &&
    css`
      background: ${(props) => props.theme.colorStyled.ColorActiveCheckbox};
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    `}
  ${({ absent }: { absent: boolean }) =>
    absent &&
    css`
      background: ${(props) => props.theme.colorStyled.ColorRedPoint};
      color: ${(props) => props.theme.colorStyled.ColorWhite};
    `}
`;

export const ToggleFullScreenButton = styled.button`
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  color: white;
  border: solid 0px transparent;
  background-color: #ff9e16;
  border-radius: 6px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StudentListContent = styled.div`
  width: 100%;
`;

export const AttendanceContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.35em 0;
  align-items: center;
`;

export const LessonHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 2.5em;
`;
