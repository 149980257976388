import React from 'react';
import { withChalkTalkErrorBoundary } from '@components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';
import { useIsTeacher } from '@reducers/user/hooks';
import { usePracticeTotalScoreCalculator } from '@components/Component/Lesson/LessonContainer/hooks';
import { useExamScore } from '@reducers/sections/hooks';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { Button } from '../Generals/stylesheets/Button.style';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import StandardsList from '../../containers/StandardsList';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import StandardsPracticeOverViewInformation from './StandardsPracticeOverViewInformation';
import {
  ItemBankCard,
  ItemBankCardHeader,
  ItemBankCardBlock,
  ButtonsWrapper,
} from './StandardsPractice.style';
import SpanishStartButton from './SpanishStartButton';
import EnglishStartButton from './EnglishStartButton';
import EnglishPreviewButton from './EnglishPreviewButton';
import SpanishPreviewButton from './SpanishPreviewButton';
import { useNavigateToSessionResults } from './hooks';

const scrollStyle = {
  height: 'calc(100vh - 12em)',
  width: '100%',
};
const StandardsPracticeOverview = () => {
  const isTeacher = useIsTeacher();
  const { data: lessonDetail } = useGetLessonDetail();
  const { totalScore, totalMaxScore } = usePracticeTotalScoreCalculator();
  const scoreSummary = useExamScore();
  const goToSessionResults = useNavigateToSessionResults();

  const studentHasPracticed = scoreSummary.length > 0 && !isTeacher;
  return (
    <ShadowScrollbar autoHide style={scrollStyle}>
      <DefaultContentWrap medium>
        <StandardsPracticeOverViewInformation />
        <ItemBankCard>
          <ItemBankCardHeader>
            <ButtonsWrapper>
              <EnglishPreviewButton lessonDetail={lessonDetail} />
              <SpanishPreviewButton lessonDetail={lessonDetail} />
              <EnglishStartButton lessonDetail={lessonDetail} />
              <SpanishStartButton lessonDetail={lessonDetail} />
              {studentHasPracticed && (
                <Button blue onClick={goToSessionResults}>
                  Review Scores
                </Button>
              )}
            </ButtonsWrapper>

            <p>Cumulative Score:</p>
            <p>{`${totalScore}/${totalMaxScore}`}</p>
            <p>
              <strong>The practice is on the following completed lessons:</strong>
            </p>
          </ItemBankCardHeader>
          <ItemBankCardBlock>
            <StandardsList lessonId={lessonDetail.id} />
          </ItemBankCardBlock>
        </ItemBankCard>
      </DefaultContentWrap>
    </ShadowScrollbar>
  );
};

export default React.memo(withChalkTalkErrorBoundary(StandardsPracticeOverview));
