import { connect } from 'react-redux';
import _get from 'lodash/get';
import CourseManagementTeachers from '../../components/CourseManagementTeachers';
import {
  actions as CourseTeacherActions,
  selectors as CourseTeacherSelectors,
} from '../../reducers/courseTeachers';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { selectors as userSelectors } from '../../reducers/user';

const mapStateToProps = (state, { match, location }) => {
  const courseId = _get(match, 'params.courseId', '');
  const ownerIds = CourseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find(id => id === userId);
  const searchParams = new URLSearchParams(location.search);
  const isOpenInviteTeacher = !!searchParams.get('isOpenInviteTeacher');

  return {
    isOpenInviteTeacher,
    isOwner,
    courseId,
    ownerIds,
    teacherList: CourseTeacherSelectors.getCourseTeacherList(state),
    courseDetail: CourseSelectors.getCourseDetailById(state, courseId),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    isGettingTeacherList: CourseTeacherSelectors.isGettingTeacherList(state),
  };
};

const mapDispatchToProps = {
  fetchCourseTeacherList: CourseTeacherActions.fetchCourseTeacherList,
  courseTeacherRemove: CourseTeacherActions.courseTeacherRemove,
  courseTeacherAddOwner: CourseTeacherActions.courseTeacherAddOwner,
  courseTeacherRemoveOwner: CourseTeacherActions.courseTeacherRemoveOwner,
  courseTeacherInvite: CourseTeacherActions.courseTeacherInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementTeachers);
