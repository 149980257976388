/* eslint-disable import/no-cycle */
import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import Dashboard from '@components/DashboardRouter';
import AnalyticsSetUp from './components/AnalyticsSetUp';
import { ROLE_TYPE } from './utils/enums';
import { selectors } from './reducers/system/systemReducer';
import { selectors as UserSelectors } from './reducers/user';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';
import LoginPage from './containers/Login';
import PasswordPage from './containers/PasswordPage';
import EmailVerification from './containers/EmailVerification';
import RequestVerification from './containers/RequestVerification';
import ConfirmInvite from './containers/ConfirmInvite';
import ForgotEmail from './containers/ForgotPage/ForgotEmail';
import ForgotPassword from './containers/ForgotPage/ForgotPassword';
import StudentRegister from './containers/StudentRegister';
import SignUpType from './components/LoginPage/SignUpType';
import TeacherRegisterForm from './components/TeacherRegisterModal/TeachersRegisterForm';
import TeacherRegister from './containers/TeacherRegister';
import RegisterSuccess from './containers/intermediaScreen/RegisterSuccess';
import MainLayout from './containers/MainLayout';
import CleverLoadingPage from './containers/CleverLoadingPage';
import SchoologyLoadingPage from './containers/SchoologyLoadingPage';
import ScheduleCalendar from './containers/ScheduleCalendar';
import TeacherTraining from './containers/TeacherTraining';
import SectionEnroll from './containers/SectionEnroll';
import Profile from './containers/Profile';
import NewStudentSectionEnroll from './containers/NewStudentSectionEnroll';

// test
import TestManagement from './containers/TestManagement';
import TestReviewManagement from './containers/TestReviewManagement';
import TestPage from './containers/TestPage';
import TestPdfViewer from './containers/TestPDFViewer';

// course
import CourseDetail from './containers/CourseDetail';
import CourseManagementSection from './containers/CourseManagementSection';
import CourseManagementInviteStudent from './containers/CourseManagementInviteStudent';
import CourseManagementStudents from './containers/CourseManagementStudents';
import CourseManagementTeachers from './containers/CourseManagementTeachers';

// lesson
import LessonManagementRoute from './components/LessonManagementRoute';

import Admin from './components/Admin/Admin';
import QGSMain from './components/QuestionGenerationService/QGSMain';

import InstructionalWalkThroughLibrary from './components/InstructionalWalkThroughs/InstructionalWalkThroughLibrary';
// UnverifiedRoute : to allow the authenticated and not verified only
import {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  TeacherAuthRoute,
  TestRoute,
  UnverifiedRoute,
  ReviewerAuthRoute,
} from './containers/routes';
import { offline as offlineToast, success as onlineToast } from './utils/toast';
import ReviewPresentations from './containers/MyLibrary';

import ClasslinkLoadingPage from './containers/ClasslinkLoadingPage';
import IWReport from './components/InstructionalWalkThroughs/Reports';
import QuestionReviewer from './components/QuestionReviewer';
import ExternalServiceChecker from './components/ExternalServiceChecker';
import LoginLoadingIndicator from './components/LoginPage/LoginLoadingIndicator';
//  we use lazy here to reduce bundle size since we don't use this pages a lot
const RequestEmailConfirmation = lazy(() =>
  import('./containers/RequestEmailConfirmation'),
);
const RequestEmailVerification = lazy(() =>
  import('./containers/RequestEmailVerification'),
);
const ChangeEmail = lazy(() => import('./containers/ChangeEmail'));

const toastId = 'offline-indicator';
const displayOfflineIndicator = () => {
  offlineToast(
    'Your internet connection is offline. Your work has been saved but cannot be submitted until internet access is restored.',
    { toastId },
  );
};
class Router extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isOffline: false,
      intervalId: null,
    };
  }

  componentDidMount() {
    const { isOffline, intervalId } = this.state;
    window.addEventListener('offline', this.handleOffline);
    window.addEventListener('online', this.handleOnline);
    toast.onChange(() => {
      const isActive = toast.isActive(toastId);
      this.setState({ isActive });
      if (!isActive && isOffline) {
        clearInterval(intervalId);
        this.offlineIndicatorSetInterval();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  displayOnlineIndicator = () => {
    const { isActive } = this.state;
    if (isActive) {
      toast.update(toastId, {
        render: 'Your internet connection has been restored',
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
      });
    } else {
      onlineToast('Your internet connection has been restored');
    }
  };

  offlineIndicatorSetInterval = () => {
    const { isActive } = this.state;
    const intervalId = setInterval(() => {
      if (!isActive) {
        displayOfflineIndicator();
      }
    }, 10000);
    this.setState({ intervalId });
  };

  handleOnline = () => {
    const { intervalId } = this.state;
    clearInterval(intervalId);
    this.displayOnlineIndicator();
    this.setState({ intervalId: null, isOffline: false });
  };

  handleOffline = () => {
    this.setState({ isOffline: true });
    displayOfflineIndicator();
    this.offlineIndicatorSetInterval();
  };

  render() {
    const { user, isLoading, history } = this.props;
    const { role } = user;

    const defaultRoute =
      role == ROLE_TYPE.DISTRICT_ADMIN ? '/admin/reports/generator' : '/dashboard/course';

    if (isLoading) {
      if (history.location.pathname === '/login') {
        return <LoginLoadingIndicator />;
      }
      return <LoadingIndicator isLoading={isLoading} />;
    }

    return (
      <Suspense fallback={<LoadingIndicator isLoading={isLoading} />}>
        <AnalyticsSetUp />
        {/* Suspense : to show loader when the component is loading  */}
        <Switch>
          <UnauthenticatedRoute exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/account/confirm-email/:token"
            component={EmailVerification}
          />
          <UnauthenticatedRoute
            exact
            path="/account/confirm-invite/:uid/:token"
            component={ConfirmInvite}
          />
          <UnauthenticatedRoute exact path="/forgot-email" component={ForgotEmail} />
          <UnauthenticatedRoute
            exact
            path="/forgot-password"
            component={ForgotPassword}
          />
          <UnauthenticatedRoute
            exact
            path="/password-reset/:uid/:token"
            component={PasswordPage}
          />
          <UnverifiedRoute
            exact
            path="/account/confirmation"
            component={RequestEmailConfirmation}
          />

          <UnverifiedRoute
            exact
            path="/account/verify"
            component={RequestEmailVerification}
          />
          <UnverifiedRoute exact path="/account/change-email" component={ChangeEmail} />

          <Route
            exact
            path="/account/approve-request/:uid/:token"
            component={RequestVerification}
          />
          <AuthenticatedRoute
            exact
            path="/register/successfully"
            component={RegisterSuccess}
          />
          <Route path="/auth/complete/schoology/:code" component={SchoologyLoadingPage} />
          <Route path="/auth/complete/clever/" component={CleverLoadingPage} />
          <Route path="/auth/complete/classlink/" component={ClasslinkLoadingPage} />
          <Route exact path="/register/teacher" component={TeacherRegister} />
          <Route exact path="/register/student" component={StudentRegister} />
          <Route exact path="/register/type" component={SignUpType} />
          <Route exact path="/register/new-teacher" component={TeacherRegisterForm} />

          <AuthenticatedRoute
            exact
            path="/new-student"
            component={NewStudentSectionEnroll}
          />

          <MainLayout>
            <Switch>
              <AuthenticatedRoute
                exact
                path="/instructionalwalkThrough"
                component={InstructionalWalkThroughLibrary}
              />
              <AuthenticatedRoute
                exact
                path="/instructionalwalkThrough/report"
                component={IWReport}
              />
              <AuthenticatedRoute
                path="/admin/reports"
                component={Admin}
                disallowed={[ROLE_TYPE.STUDENT]}
              />
              <AuthenticatedRoute
                exact
                path="/dashboard/course/:courseId?"
                component={Dashboard}
                disallowed={[ROLE_TYPE.DISTRICT_ADMIN]}
              />
              <AuthenticatedRoute
                path="/dashboard/course/:courseId/section/:sectionId?"
                component={Dashboard}
                disallowed={[ROLE_TYPE.DISTRICT_ADMIN]}
              />

              <AuthenticatedRoute
                path="/lessons/course/:courseId?"
                component={LessonManagementRoute}
              />
              <AuthenticatedRoute
                exact
                path="/test/course/:courseId?"
                component={TestManagement}
              />
              <AuthenticatedRoute
                exact
                path="/test/course/:courseId/section/:sectionId?"
                component={TestManagement}
              />
              <AuthenticatedRoute
                exact
                path="/test/course/:courseId/section/:sectionId/exam/:examSessionId"
                component={TestManagement}
              />
              <AuthenticatedRoute
                exact
                path="/test/course/:courseId/section/:sectionId/exam/:examSessionId/pdf-viewer/:examSectionId"
                component={TestPdfViewer}
              />
              <TeacherAuthRoute
                exact
                path="/test/course/:courseId/section/:sectionId/exam/:examSessionId/review/:examSectionId/student/:studentId"
                component={TestReviewManagement}
              />
              <AuthenticatedRoute
                exact
                path="/test/course/:courseId/section/:sectionId/exam/:examSessionId/review/:examSectionId"
                component={TestReviewManagement}
              />

              <TestRoute
                exact
                path="/test/course/:courseId/section/:sectionId/exam/:examSessionId/take/:examSectionId"
                component={TestPage}
              />

              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId?"
                component={ScheduleCalendar}
              />
              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId/section/:sectionId?"
                component={ScheduleCalendar}
              />
              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId/section/:sectionId/create-lesson-session"
                component={ScheduleCalendar}
                disallowed={[ROLE_TYPE.STUDENT]}
              />
              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId/section/:sectionId/create-review-session"
                component={ScheduleCalendar}
                disallowed={[ROLE_TYPE.STUDENT]}
              />
              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId/section/:sectionId/create-exam-session"
                component={ScheduleCalendar}
                disallowed={[ROLE_TYPE.STUDENT]}
              />
              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId/section/:sectionId/section-info"
                component={ScheduleCalendar}
                disallowed={[ROLE_TYPE.STUDENT]}
              />
              <AuthenticatedRoute
                exact
                path="/schedule/course/:courseId/section/:sectionId/instructions"
                component={ScheduleCalendar}
                disallowed={[ROLE_TYPE.STUDENT]}
              />

              <AuthenticatedRoute
                exact
                path="/teacher/course/:courseId?"
                component={TeacherTraining}
              />

              <TeacherAuthRoute
                exact
                path="/management/course/:courseId?"
                component={CourseDetail}
              />
              <TeacherAuthRoute
                exact
                path="/management/course/:courseId/students"
                component={CourseManagementStudents}
              />
              <TeacherAuthRoute
                exact
                path="/management/course/:courseId/teachers"
                component={CourseManagementTeachers}
              />
              <TeacherAuthRoute
                exact
                path="/management/course/:courseId/section/:sectionId?"
                component={CourseManagementSection}
              />
              <TeacherAuthRoute
                exact
                path="/management/course/:courseId/section/:sectionId/invite-student"
                component={CourseManagementInviteStudent}
              />

              <TeacherAuthRoute
                exact
                path="/debug/questions"
                component={QuestionReviewer}
              />

              <TeacherAuthRoute
                exact
                path="/debug/services"
                component={ExternalServiceChecker}
              />

              <ReviewerAuthRoute
                exact
                path="/reviewer/course/:courseId/library"
                component={ReviewPresentations}
                disallowed={[ROLE_TYPE.STUDENT]}
                userEmail={user?.email}
              />

              <AuthenticatedRoute exact path="/profile" component={Profile} />
              <AuthenticatedRoute
                exact
                path="/profile/course/:courseId"
                component={Profile}
              />
              <AuthenticatedRoute
                exact
                path="/section-enroll"
                component={SectionEnroll}
              />
              <AuthenticatedRoute
                disallowed={[ROLE_TYPE.STUDENT]}
                exact
                path="/coauthor/course/:courseId?"
                component={QGSMain}
              />
              <AuthenticatedRoute
                disallowed={[ROLE_TYPE.STUDENT]}
                exact
                path="/coauthor/course/:courseId/section/:sectionId?"
                component={QGSMain}
              />

              {/* REDIRECT GANG */}
              <Redirect exact from="/" to={defaultRoute} />
              <Redirect exact from="/dashboard" to="/dashboard/course" />
              <Redirect exact from="/management" to="/management/course" />
              <Redirect exact from="/schedule" to="/schedule/course" />
              <Redirect exact from="/training" to="/training/course" />
              <Redirect exact from="/lessons" to="/lessons/course" />
              <Redirect exact from="/test" to="/test/course" />
              <Redirect exact from="/teacher" to="/teacher/course" />
            </Switch>
          </MainLayout>
        </Switch>
      </Suspense>
    );
  }
}
Router.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  user: PropTypes.shape(),
  history: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: selectors.isPageLoading(state),
  user: UserSelectors.getCurrentUser(state),
});

export default connect(mapStateToProps)(Router);
