import React from 'react';
import { Option } from '../CustomDropdown';
import { BaseSelector, BaseSelectorProps } from '../BaseSelector';

export type BloomsSelectorProps = Omit<BaseSelectorProps, 'label' | 'options'>;

export const BLOOMS_MAP = {
  1: 'Remember',
  2: 'Understand',
  3: 'Apply',
  4: 'Analyze',
  5: 'Evaluate',
  6: 'Create',
};

const BLOOMS_LEVELS: Option[] = Object.entries(BLOOMS_MAP).map(([value, label]) => ({
  label,
  value,
}));

const optionLabel = (option: Option) => `${option.value} - ${option.label}`;

const selectedLabel = (selected: Option) =>
  `Blooms: ${selected.value} - ${selected.label}`;

export const BloomsSelector: React.FC<BloomsSelectorProps> = (props) => (
  <BaseSelector
    {...props}
    label="Blooms"
    options={BLOOMS_LEVELS}
    optionLabel={optionLabel}
    selectedLabel={selectedLabel}
  />
);

export default BloomsSelector;
