/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import RightSiderBar from './RightSiderBar';
import InstructionalWalkThroughRenderer from '../InstructionalWalkThroughRenderer';
import SelectionPage from './SelectionPage';
import { useIWContext } from '../IWContextProvider';

const SiderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const SiderHeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;

  overflow: hidden;
  padding: 1.5625rem;
  border-bottom: 1px solid #e2e6ea;
  gap: 0.5rem;
`;
const SiderBodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0.75rem 1.25rem;
`;
const Title = styled.h1`
  color: ${(props) => props.theme.colorStyled.ColorOrange};
  white-space: nowrap;
  min-width: fit-content;
`;
const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding-inline-start: 1rem;
  border-left: 1px solid lightgray;
  P {
    margin-inline-end: 1rem;
  }
`;
const LegendTitle = styled.h2`
  min-width: 100%;
`;
const SpanGray = styled.span`
  margin-inline-end: 0.5rem;
  color: ${(props) => props.theme.colorStyled.ColorPoint};
`;
const SiderContent = () => {
  const { currentSiderIWId: currentIWId, selectedTeacher } = useIWContext();
  return (
    <SiderWrapper>
      <SiderHeaderWrapper>
        <Title>
          Class Walkthrough
          {selectedTeacher && <span>&nbsp;-&nbsp; {selectedTeacher}</span>}
        </Title>
        {selectedTeacher && (
          <Legend>
            <LegendTitle>Legend:</LegendTitle>
            {/* 4 Very Evident; 3 Evident 2 Somewhat Evident; 1 Not Observed */}
            <p>
              <SpanGray>4</SpanGray>
              Very Evident
            </p>
            <p>
              <SpanGray>3</SpanGray>
              Evident
            </p>
            <p>
              <SpanGray>2</SpanGray>
              Somewhat Evident
            </p>
            <p>
              <SpanGray>1</SpanGray>
              Not Observed
            </p>
          </Legend>
        )}
      </SiderHeaderWrapper>
      <SiderBodyWrapper>
        {currentIWId && selectedTeacher ? (
          <InstructionalWalkThroughRenderer />
        ) : (
          <SelectionPage />
        )}
      </SiderBodyWrapper>
    </SiderWrapper>
  );
};
const IWSider = () => {
  const { setIsSiderOpen, isSiderOpen } = useIWContext();

  // We included this line because we've stopped using Firebase for feature visibility.
  // Additionally,we've set the default to be hidden.
  if(true){
    return null;
  }

  return (
    <RightSiderBar onClose={() => setIsSiderOpen(false)} isOpen={isSiderOpen}>
      <SiderContent />
    </RightSiderBar>
  );
};

export default React.memo(IWSider);
