import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import ExamScoreEventEmitter, {
  MessageFnType,
  ExamScoreEvents,
} from '../ExamScoreEventEmitter';

const useWebSocketListener = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const updateExamScoreInfoSubscriber: MessageFnType = () => {
      queryClient.invalidateQueries(['getExamScaledScore']);
      queryClient.invalidateQueries(['getExamStudentResult']);
      queryClient.invalidateQueries(['getExamResult']);
    };
    const updateExamStudentResultSubscriber: MessageFnType = () => {
      queryClient.invalidateQueries(['getExamStudentResult']);
    };
    ExamScoreEventEmitter.on(ExamScoreEvents.EXAM_SCORED, updateExamScoreInfoSubscriber);
    ExamScoreEventEmitter.on(ExamScoreEvents.RESET, updateExamStudentResultSubscriber);
    return () => {
      ExamScoreEventEmitter.off(
        ExamScoreEvents.EXAM_SCORED,
        updateExamScoreInfoSubscriber,
      );
      ExamScoreEventEmitter.off(ExamScoreEvents.RESET, updateExamStudentResultSubscriber);
    };
  }, []);
};

export default useWebSocketListener;
