import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getGroupData, getStudentQuestions } from '@apis/groupActivity';
import { questionType, GroupActivityType } from '../../types';

export const useGroupActivity = () => {
  const { groupId } = useParams();
  const response = useQuery<GroupActivityType>(
    ['getGroupData', groupId],
    async () => {
      const res = (await getGroupData(groupId)) as unknown as {
        data: GroupActivityType;
      };
      return res?.data || {};
    },
    {
      enabled: !!groupId,
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
      suspense: true,
    },
  );
  return response;
};

export const useGroupQuestions = () => {
  const { onlineResponseId, userId } = useParams();
  const response = useQuery<{
    exam_questions: Array<questionType>;
  }>(
    ['groupActivityQuestions', { onlineResponseId, userId }],
    async () => {
      const res = (await getStudentQuestions(onlineResponseId, userId)) as unknown as {
        response: {
          data: {
            exam_questions: Array<questionType>;
          };
        };
      };
      if (!res?.response?.data?.exam_questions?.length) {
        return {
          exam_questions: [],
        };
      }
      // the api return questions in 0 order so we need to sort them to have criteria of order then add the order to them
      // add index to questions so we can use it in the question component
      return {
        exam_questions: res?.response?.data?.exam_questions
          .sort((a, b) => a.id - b.id)
          .map((q, index) => ({ ...q, index })),
      };
    },
    {
      enabled: !!(onlineResponseId && userId),
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
    },
  );
  return response;
};
