import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  LessonBarWrap,
  LessonBarContent,
  LessonBarAction,
  LessonBarButton,
} from './LessonBar.style';
import { CALENDAR_EVENT_TYPE } from '../../utils/constants';
import { InfoModal } from '../../containers/ScheduleCalendar/Modals';

export default class LessonBar extends Component {
  static propTypes = {
    type: PropTypes.string,
    nextSession: PropTypes.objectOf(PropTypes.any),
    updateSessionId: PropTypes.func,
    isTeacher: PropTypes.bool,
    openFormModal: PropTypes.func,
  };

  upDateSessionId = () => {
    const { updateSessionId, nextSession } = this.props;
    const { id } = nextSession;
    updateSessionId(id);
  };

  openEditModal = () => {
    const { nextSession, openFormModal } = this.props;

    openFormModal({
      ...nextSession,
      title: 'Edit',
      type: CALENDAR_EVENT_TYPE.LESSONS,
    });
  };

  renderBarAction = () => {
    const { type } = this.props;
    switch (type) {
      case 'noSession':
        return <LessonBarButton>+ Add to schedule</LessonBarButton>;
      case 'isLesson':
        return <LessonBarButton>Edit</LessonBarButton>;
      default:
        return (
          <React.Fragment>
            <LessonBarButton onClick={this.openEditModal}>Edit</LessonBarButton>
          </React.Fragment>
        );
    }
  };

  render() {
    const { nextSession, isTeacher } = this.props;
    return (
      <React.Fragment>
        {nextSession.date && (
          <LessonBarWrap>
            <LessonBarContent>
              Next Session Date:
              {' '}
              {nextSession.date}
              {' '}
            </LessonBarContent>
            {isTeacher && (
              <LessonBarAction>{this.renderBarAction()}</LessonBarAction>
            )}
            <InfoModal onlyEditDate />
          </LessonBarWrap>
        )}
      </React.Fragment>
    );
  }
}
