import styled, { css } from 'styled-components';

export const UnitPracticeWrap = styled.div``;

export const UnitQuestionTypeWrap = styled.div`
  border-radius: .325em;
  background-color: #f9f9f9;
  box-shadow: rgba(9,30,66,0.42) 0px 0px 1px, rgba(9,30,66,0.35) 0px 0px 6px -1px;
`;

export const UnitQuestionLogo = styled.div`
  padding: 1.5em;
  text-align: center;
  background-color: ${(props) => props.theme.colorStyled.ColorMidBlue};
  border-radius: .325em .325em 0em 0em;
  img {
    width: 4.5em;
    height: auto;
  }
  ${({ isInstructorLogo }) => isInstructorLogo && css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `}
`;

export const QuestionTitle = styled.span`
  font-size: 1.2em;
  color: #ffffff;
  font-family: 'MontserratSemiBold';
  margin-left: 1em;
`;

export const MinimizeBtnWrapper = styled.div`
  position: relative;
`;

export const MinimizeBtn = styled.div`
  position: absolute;
  border-radius: 999px;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 2px solid ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  left: calc(50% - 30px);
  transition: 0.5s;
  i{
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    margin-top: 1px;
  }
  ${({ isMinimize }) => isMinimize && css`
    transform: rotate(180deg);
    margin-top: unset;
  `}
  ${({ isTableBtn }) => isTableBtn && css`
    border: 1px solid #c8c8c8;
    width: 20px;
    height: 20px;
    /* right: 220px; */
    right: 0;
    left: unset;
    /* top: calc(50% - 20px); */
    top: calc(50% - 10px);
    transition: 0.5s ease;
    i{
      color: #c8c8c8;
      font-size: 10px;
      margin-top: 0;
      margin-left: 2px;
    }
  `}
  /* ${({ isColumnMinimize }) => isColumnMinimize && css`
    right: 0;
    transition: 0.5s ease;
  `} */
`;

export const UnitQuestionTypeContentWrap = styled.div`
  display: flex;
  padding: 1.5em;
  max-height: 500px;
  transition: 0.3s ease-in;
  overflow: hidden;

  @media (max-width: 1024px) {
    flex-direction: column;
    max-height: 1000px;
  }
  @media (max-width: 768px) {
    font-size: ${props => props.theme.fontSize.MediumFontSize}
  }
  ${({ isMinimize }) => isMinimize && css`
    max-height: 0;
    transition: 0.3s ease-out;
    padding: 0 1.5em;
    @media (max-width: 1024px) {
      max-height: 0 !important;
    }
  `}
`;

export const UnitQuestionTypeItemWrap = styled.div`
  flex: 0 0 50%;
  div {
    padding: 0em;
    margin-right: .5em;
    &:last-child {
      margin-right: 0em;
    }
  }
  h3 {
    margin-bottom: .75em;
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  }
`;

export const UnitPracticeItemWrap = styled.div`
  margin-bottom: 1em;
  width: 100%;
`;

export const UnitActivityOverviewWrap = styled.div`
  flex: 0 0 calc(50% - 1em);
  margin-left: 1em;
  padding: 1em;
  border: 2px dashed ${(props) => props.theme.colorStyled.ColorBorder};
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  h3 {
    position: absolute;
    background-color: #f9f9f9;
    padding: 0em .5em;
    top: -9px;
    left: 20px;
    z-index: 1;
    transition: 0.3 ease-in;
    ${({ isMinimize }) => isMinimize && css`
      z-index: -1;
      transition: 0.3 ease-out;
    `}
  }
  ${UnitPracticeItemWrap} {
    margin-bottom: 0em;
  }
  @media (max-width: 1024px) {
    margin-top: 1.5em;
    margin-left: 0em;
  }
`;

export const UnitQuestionTypeItem = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const UnitQuestionDescription = styled.div`
  padding: 1.5em;
  h3 {
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
    margin-bottom: 1em;
  }
  p {
    line-height: 1.3;
    color: ${(props) => props.theme.colorStyled.ColorLevelKill};
  }
`;

export const UnitQuestionTopic = styled.div`
  padding: 1.5em;
  max-width: 400px;
  p {
    margin-bottom: 1em;
    font-style: italic;
  }
  span {
    line-height: 1.3;
    color: ${(props) => props.theme.colorStyled.ColorLevelKill};
    @media (max-width: 480px) {
      font-size: ${props => props.theme.fontSize.MediumFontSize}
    }
    display: inline-block;
    margin-bottom: 1em;
  }
  @media (max-width: 768px) {
    &:last-child {
      margin-top: 1em;
    }
  }
  strong {
    font-weight: bold;
  }
`;

export const UnitQuestionTopicCover = styled.div`
  display: flex;
  flex-wrap: wrap;
  p {
    padding: .5em 1em;
    margin-bottom: .5em;
    margin-right: .5em;
    background: #dfe2e6;
    border-radius: .325em;
    font-family: 'MontserratRegular';
    @media (max-width: 480px) {
      font-size: ${props => props.theme.fontSize.MediumFontSize}
    }
  }
`;

export const UnitPracticeDetailWrap = styled.div`
  flex: 1;
`;

export const UnitPracticeItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: .5em;
  box-shadow: rgba(9,30,66,0.42) 0px 0px 1px, rgba(9,30,66,0.35) 0px 0px 2px;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
`;

export const UnitName = styled.div`
  padding: .5em;
  flex: 1;
  @media (max-width: 480px) {
    font-size: ${props => props.theme.fontSize.DefaultFonts};
    overflow-wrap: anywhere;
  }
`;

export const UnitScore = styled(UnitName)`
  flex: 0 0 20%;
  @media (max-width: 1170px) {
    flex: 0 0 55px;
  }
`;

export const UnitIcon = styled.div`
  flex: 0 0 10%;
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  @media (max-width: 1170px) {
    flex: 0 0 45px;
  }
`;

export const UnitLastActivity = styled(UnitName)`
  display: flex;
  flex-direction: column;
  flex: 0 0 80px;
  span {
    line-height: 1.2;
    font-size: ${(props) => props.theme.fontSize.SmallerFontSize};
    &:last-child {
      color: ${(props) => props.theme.colorStyled.ColorLevelKill};
    }
  }
  @media (max-width: 480px) {
    flex: 0 0 40px;
  }
`;

export const UnitScoreActive = styled(UnitLastActivity)`
  text-align: center;
`;

export const UnitActionButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1em;
  cursor: pointer;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  background-color: ${(props) => props.theme.colorStyled.ColorMidBlue};
  &:hover {
    opacity: .8;
  }
  i {
    margin-left: .25em;
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  }
  ${({ isContinuePractice }) => isContinuePractice && css`
    background-color: ${(props) => props.theme.colorStyled.ColorActiveCheckbox};
  `}
`;

export const UnitSkillLevelWrap = styled.div``;

export const UnitSkillLevel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: .5em;
  span {
    line-height: 1.3;
    font-size: ${(props) => props.theme.fontSize.DefaultFonts};
  }
`;

export const LevelSkill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .25em;
`;

export const Dot = styled.span`
  background: #808080;
  width: .75em;
  height: .75em;
  border-radius: .15em;
  margin-right: .25em;
  ${({ isActive }) => isActive && css`
    background: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  `}
`;

export const UnitPracticeTitle = styled.div`
  position: relative;
  margin-bottom: 1em;
  h1 {
    position: relative;
    z-index: 2;
    background: #f4f5f7;
    width: fit-content;
    padding-right: .5em;
    font-size: ${(props) => props.theme.fontSize.HeadingH3FontSize};
  }
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0em;
    top: 50%;
    z-index: 1;
    background-color: ${(props) => props.theme.colorStyled.ColorBorder};
  }
`;

export const UnitPracticeTableWrap = styled.div`
  margin-top: 2em;
  flex: 1;
  @media (max-width: 768px) {
    font-size: ${props => props.theme.fontSize.MediumFontSize}
  }
`;

export const UnitPracticeTableSubTitle = styled.div`
  line-height: 1.3;
  color: ${(props) => props.theme.colorStyled.ColorLevelKill};
  margin-top: -.5em;
  margin-bottom: 1em;
  @media (max-width: 480px) {
    font-size: ${props => props.theme.fontSize.MediumFontSize}
  }
`;

export const StudentContentWrapper = styled.div`
`;
