import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '../Box';
import iconWrapperStyle from './IconWrapper.style';

const ICON_SIZE_MAP = {
  xs: '0.6875rem',
  sm: '1.125rem',
  md: '1.5rem',
  lg: '1.875rem',
};

export interface IconWrapperProps {
  /** @property {'xs' | 'sm' | 'md' | 'lg'} [size='md'] - The Size to be displayed for the `Icon` */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  sx?: SxProps<Theme> | any;
  /** - The Icon color could be displayed only for `SVG`. */
  color?: React.CSSProperties['color'];
  /** - The Icon to be displayed within the `IconWrapper` component. */
  children: React.ReactNode | React.ReactNode[];
}
/** The `IconWrapper` component provides a wrapper for displaying icons or
 *  other graphical elements with customizable size and styles.
 * It is designed to be used in conjunction with other UI components. */
const IconWrapper = ({ size = 'md', children, sx, color }: IconWrapperProps) => (
  <Box
    component="span"
    sx={[iconWrapperStyle, { fontSize: ICON_SIZE_MAP[size], color }, { ...sx }]}
  >
    {children}
  </Box>
);

export default IconWrapper;
