import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ConfirmModal from '../ConfirmModal';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { SESSION_EXAM_TYPE } from '../../utils/constants';
import { SECTION_STATUS } from '../../utils/enums';
import OfflineTestContent from '../../containers/OfflineTestContent';
import OnlineTestContent from '../../containers/OnlineTestContent';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import ReportErrorModal from '../ReportErrorModal';
import {
  info as ToastInfo,
} from '../../utils/toast';
const reportModalType = 'Report';

class TestPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      modalType: null,
      reportProps: null,
    };
  }

  componentDidMount() {
    const {
      examSessionId,
      sectionExamGetDetail,
      examTakeQuestion,
      responseId,
    } = this.props;

    examTakeQuestion(examSessionId, responseId);
    sectionExamGetDetail(examSessionId);
  }

  componentDidUpdate(prevProps) {
    const {
      examDetail, examSectionId, errorStatus, examSubmitQuestion, examSessionId,
      newAnswerIds,
    } = this.props;
    const { errorStatus: prevErrorStatus,newAnswerIds: prevNewAnswerIds } = prevProps;

    if (newAnswerIds !== prevNewAnswerIds) {
      examSubmitQuestion({ sessionId: examSessionId });
    }
    if (!prevErrorStatus && errorStatus && errorStatus === 403) {
      this.redirectToExamDetailPage();
    }

    if (!_isEmpty(examDetail)) {
      const sections = _get(examDetail, 'session_metadata.sections', []);
      const sectionSelected = sections.find(section => section.section_id == examSectionId); // eslint-disable-line
      const sectionStatus = _get(sectionSelected, 'section_status');

      if (sectionStatus !== SECTION_STATUS.UNLOCKED && sectionStatus !== SECTION_STATUS.COMPLETED) {
        this.redirectToExamDetailPage();
      }
    }
  }

  componentWillUnmount() {
    // clear data if componentWillUnmount fired
    const {
      examClearData,
    } = this.props;
    examClearData();
    window.onbeforeunload = undefined;
  }

  onCloseModal = () => {
    this.setState({
      isOpen: false,
      modalType: null,
      reportProps: null,
    });
  }

  openConfirmModal = () => {
    this.setState({
      isOpen: true,
      modalType: 'Confirm',
    });
  }

  openReportErrorModal = (contentType, questionId) => {
    this.setState({
      isOpen: true,
      modalType: reportModalType,
      reportProps: {
        contentType,
        questionId,
      },
    });
  }

  submitError = (content) => {
    const { userReportIssue } = this.props;
    const { reportProps } = this.state;

    if (!reportProps) {
      return;
    }

    const { questionId, contentType } = reportProps;

    userReportIssue(questionId, contentType, content, this.onCloseModal);
  }

  completeTest = () => {
    const {
      examSessionId,
      responseId,
      courseId,
      sectionId,
      examSubmitQuestion,
      newAnswerIds,
    } = this.props;
    const callbackUrl = `/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}`;
    examSubmitQuestion({ sessionId: examSessionId, responseId, callback: callbackUrl });
  }

  redirectToExamDetailPage = () => {
    const {
      history, courseId, sectionId, examSessionId, examSubmitQuestion,
    } = this.props;
    examSubmitQuestion({ sessionId: examSessionId });
    history.push(`/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}`);
  }

  renderOfflineContent = () => {
    const { examDetail, examSectionId } = this.props;
    const sections = _get(examDetail, 'exam.sections', []);
    const sectionSelected = (sections || []).find(item => item.id == examSectionId); // eslint-disable-line
    const pdfSource = _get(sectionSelected, 'source_url', 'https://collegereadiness.collegeboard.org/pdf/sat-practice-test-1.pdf') || 'https://collegereadiness.collegeboard.org/pdf/sat-practice-test-1.pdf';

    return (
      <OfflineTestContent
        pdfSource={pdfSource}
        completeTest={this.openConfirmModal}
        openReportErrorModal={this.openReportErrorModal}
      />
    );
  }

  renderOnlineContent = () => (
    <OnlineTestContent
      completeTest={this.openConfirmModal}
      openReportErrorModal={this.openReportErrorModal}
    />
  );

  renderTestContent = (type) => {
    switch (type) {
      case SESSION_EXAM_TYPE.ONLINE_TYPE_1:
      case SESSION_EXAM_TYPE.ONLINE_TYPE_2:
        return this.renderOnlineContent();
      case SESSION_EXAM_TYPE.OFFLINE_TYPE_1:
      case SESSION_EXAM_TYPE.OFFLINE_TYPE_2:
        return this.renderOfflineContent();
      default:
        return null;
    }
  }

  renderModal = () => {
    const { isOpen, modalType } = this.state;

    if (!isOpen) {
      return null;
    }

    if (modalType === reportModalType) {
      return (
        <ReportErrorModal
          isOpen={isOpen}
          onClose={this.onCloseModal}
          onSubmit={this.submitError}
        />
      );
    }

    return (
      <ConfirmModal
        isOpen={isOpen}
        onConfirm={this.onConfirmModal}
        onClose={this.onCloseModal}
        content="Are you sure you want to submit?"
      />
    );
  }

  onConfirmModal = () => {
    ToastInfo('Your answers have been submitted and your score will now be calculated. Check back here for results shortly.');
    this.completeTest();
  }

  render() {
    const {
      examDetail,
      examSectionId,
      secondsRemaining,
      isTakeQuestion = true,
      shouldResetTimer,
      examResetTimerSuccess,
    } = this.props;

    // return loading when no exam detail and isTakeQuestion
    if (_isEmpty(examDetail) || isTakeQuestion) {
      return <LoadingIndicator content="Preparing your exam session" />;
    }

    const examSections = _get(examDetail, 'exam.sections', []);
    const examType = _get(examDetail, 'exam.exam_type');

    const sectionSeleted = examSections.find(section => section.id == examSectionId); // eslint-disable-line
    const examName = _get(sectionSeleted, 'name', '');
    const duration = _get(sectionSeleted, 'duration', 0);

    return (
      <PracticeContainer>
        <PracticeTopNavBar
          name={examName}
          displayTimeOut
          goBack={this.redirectToExamDetailPage}
          examDetail={examDetail}
          secondsRemaining={secondsRemaining}
          complete={this.completeTest}
          shouldResetTimer={shouldResetTimer}
          examResetTimerSuccess={examResetTimerSuccess}
          duration={duration}
        />
        {this.renderTestContent(examType)}
        {this.renderModal()}
      </PracticeContainer>
    );
  }
}

TestPage.propTypes = {
  sectionId: PropTypes.any,
  examSectionId: PropTypes.any,
  responseId: PropTypes.any,
  examSessionId: PropTypes.any,
  examTakeQuestion: PropTypes.func,
  history: PropTypes.any,
  courseId: PropTypes.any,
  sectionExamGetDetail: PropTypes.func,
  examDetail: PropTypes.object,
  secondsRemaining: PropTypes.any,
  examSubmitQuestion: PropTypes.func,
  examClearData: PropTypes.func,
  isTakeQuestion: PropTypes.bool,
  shouldResetTimer: PropTypes.bool,
  examResetTimerSuccess: PropTypes.func,
  errorStatus: PropTypes.any,
  userReportIssue: PropTypes.func,
};

export default TestPage;
