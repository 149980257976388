export const informationAlertWrapper = {
  p: 0.5,
};
export const informationAlertHeader = {
  '& span': {
    backgroundColor: 'secondary.main',
    borderRadius: '100%',
    color: 'white',
    display: 'inline-block',
    fontSize: '1.1em',
    fontWeight: 'normal',
    height: '1.2em',
    lineHeight: '1.2em',
    marginRight: '5px',
    textAlign: 'center',
    width: '1.2em',
    minWidth: '1.2em',
  },
  '& p': {
    color: 'secondary.main',
    fontSize: '1rem',
  },
  fontWeight: 'bold',
  display: 'flex',
  gap: 0.25,
  alignItems: 'center',
};

export const informationAlertPaper = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
};
