import { SxProps, Theme } from '@mui/material';

export const dialogWrapperStyle = {
  '& .MuiDialog-paper': { borderRadius: (theme) => theme.spacing(2) },
};

export const closeIconWrapper = {
  position: 'absolute',
  right: (theme) => theme.spacing(2),
  top: (theme) => theme.spacing(3),
  color: (theme) => theme.palette.grey[500],
  p: 0,
};

export const closeIcon = { fontSize: '1.75rem', margin: '-0.25rem' };

export const dialogContentWrapper = {
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '1.5rem',
  pb: 2,
  pt: 3,
  overflow: 'hidden',
} as SxProps<Theme> | any;

export const dialogImageStyle = {
  my: 4,
  objectFit: 'contain',
};

export const dialogHeaderStyle = { alignSelf: 'start', p: 0, pb: '0.5rem' };

export const dialogTitleStyle = { alignSelf: 'start' };

export const dialogSubtitleStyle = {
  alignSelf: 'start',
  paddingInlineStart: (theme) => theme.spacing(0.25),
};

export const dialogChildrenWrapper = {
  pt: 2.5,
  pb: 0.5,
  paddingInlineStart: (theme) => theme.spacing(0.125),
};
