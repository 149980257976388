/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import MediaQuery from 'react-responsive';
import { Link, useParams, useLocation } from 'react-router-dom';
import ExamSectionsWrapper from '@components/Component/ExamSections';
import _isEmpty from 'lodash/isEmpty';
import Button from '@components/Atoms/Button';
import { useIsTeacher } from '@reducers/user/hooks';
import Box from '@components/Atoms/Box';
import TooltipDisabledButton from '@components/Generals/TooltipDisabledButton';
import { useSectionStudent } from '@reducers/sectionStudent/hooks';
import { useCurriculumDetails } from '@reducers/curriculums/hooks';
import ShowIf from '@components/Atoms/ShowIf';
import { selectors, actions } from '@reducers/leftSideBar';
import { useDispatch, useSelector } from 'react-redux';
import { actions as CurriculumActions } from '../../reducers/curriculums';
import CourseSelect from '../../containers/CourseSelect';
import SectionSelect from '../../containers/SectionSelect';
import {
  LeftSiderBarContentGroup,
  LeftSideBarList,
  ItemsSiderBar,
  MultiItemsSiderBar,
  TitleSideBar,
  SideBarContent,
  CollapseSideBar,
  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
} from './LeftSiderBar.style';
import ListLoader from '../LoadingPlaceholder/ListLoader';

const reportData = [
  {
    title: 'Practice Details',
    icon: 'icon-setting-blue.svg',
    type: 'practice-detail',
    disableMessage: 'Add students to your section to view Practice Details.',
  },
  {
    title: 'Scores Details',
    icon: 'icon-star-blue.svg',
    type: 'scores-detail',
    disableMessage: 'Add students to your section to view Scores Details.',
  },
  {
    title: 'Attendance Details',
    icon: 'icon-copy-blue.svg',
    type: 'attendance-detail',
    disableMessage: 'Add students to your section to view Attendance Details.',
  },
  // {
  //   title: 'Skill Level Report',
  //   icon: 'icon-chart.svg',
  //   type: 'skill-level-report',
  // },
];
const ReportItem = React.memo(({ title, icon, type, disableMessage, url }) => {
  const { has_exam: hasExam } = useCurriculumDetails();
  const sectionStudents = useSectionStudent();
  const location = useLocation();
  if (!hasExam && type === 'scores-detail') {
    return null;
  }

    const isActive = url == location.pathname; // eslint-disable-line

  const shouldDisable = !sectionStudents.length;

  return (
    <TooltipDisabledButton title={disableMessage} disabled={!shouldDisable}>
      <Button
        component={Link}
        variant="text"
        disabled={shouldDisable}
        fullWidth
        to={url}
        sx={[
          {
            justifyContent: 'start',
            color: 'black',
            fontWeight: '400',
            px: '1.25rem',
            py: '0.5rem',
            '& .MuiButton-startIcon': {
              filter: 'grayscale(100%)',
            },
          },
          (theme) => {
            if (isActive) {
              return {
                backgroundColor: theme.nav.activeLinkBackGround,
                '&:hover': {
                  backgroundColor: theme.nav.activeLinkBackGround,
                },
              };
            }
          },
        ]}
        startIcon={<img src={`/images/icon-v2/${icon}`} alt="chalk-talk" width="16px" />}
      >
        <span>{title}</span>
      </Button>
    </TooltipDisabledButton>
  );
});
ReportItem.displayName = 'ReportItem';
const height = reportData.length * 2.5;
const DashBoardSideBarContent = React.memo(
  () => {
    const { courseId, sectionId } = useParams();
    const location = useLocation();
    const isTeacher = useIsTeacher();
    const urls = React.useMemo(() => {
      // generate all the urls
      const baseURL = `/dashboard/course/${courseId}/section/${sectionId}`;
      const fallBackURL = `/dashboard/course/${courseId}/#`;
      const shouldUseFallBack = !sectionId;
      return {
        base: shouldUseFallBack ? fallBackURL : baseURL,
        summary: shouldUseFallBack ? fallBackURL : `${baseURL}/summary`,
        'practice-detail': shouldUseFallBack ? fallBackURL : `${baseURL}/practice-detail`,
        'scores-detail': shouldUseFallBack ? fallBackURL : `${baseURL}/scores-detail`,
        'attendance-detail': shouldUseFallBack
          ? fallBackURL
          : `${baseURL}/attendance-detail`,
      };
    }, [courseId, sectionId]);

    return (
      <LeftSiderBarContentGroup>
        <TitleSideBar>Home</TitleSideBar>
        <LeftSideBarList>
          <ShowIf If={!isTeacher}>
            <Link to={urls.base}>
              <ItemsSiderBar isActive={urls.base == location.pathname}>
                <SideBarContent>
                  <span>Dashboard</span>
                </SideBarContent>
              </ItemsSiderBar>
            </Link>
          </ShowIf>
          <Link to={urls.summary}>
            <ItemsSiderBar isActive={urls.summary == location.pathname}>
              <SideBarContent>
                <span>Activity Summary</span>
              </SideBarContent>
            </ItemsSiderBar>
          </Link>

          <ShowIf If={isTeacher}>
            <MultiItemsSiderBar noLink>
              <SideBarContent>
                <span>Details</span>
              </SideBarContent>
            </MultiItemsSiderBar>
            <CollapseSideBar height={height} noPadding>
              <Suspense fallback={<ListLoader />}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
                  {reportData.map((ele) => (
                    <ReportItem key={ele.type} {...ele} url={urls[ele.type]} />
                  ))}
                </Box>
              </Suspense>
            </CollapseSideBar>
          </ShowIf>
        </LeftSideBarList>
      </LeftSiderBarContentGroup>
    );
  },
  () => true,
);
DashBoardSideBarContent.displayName = 'DashBoardSideBarContent';
const pathName = '/dashboard/course';
const LeftSideBarDashboard = () => {
  // State hooks
  const curriculumDetail = useCurriculumDetails();
  const [gettingCurriculums, setGettingCurriculums] = useState(!!curriculumDetail);
  const { courseId } = useParams();
  const isSideBarClose = useSelector(selectors.getSideBar);
  const dispatch = useDispatch();
  const toggleLeftSidebar = () => dispatch(actions.toggleLeftSidebar());
  // Equivalent of componentDidMount
  useEffect(() => {
    if (_isEmpty(curriculumDetail) && !gettingCurriculums) {
      setGettingCurriculums(true);
      dispatch(CurriculumActions.getCurriculumList());
    }
  }, [curriculumDetail, gettingCurriculums, dispatch]); // Ensure dependencies are included
  const shouldDisplaySectionSelect = !!courseId;
  return (
    <ExamSectionsWrapper>
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <CourseSelect pathName={pathName} />
            {shouldDisplaySectionSelect && <SectionSelect pathName={pathName} />}
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        <DashBoardSideBarContent />
        {/* Action to show/hide the sidebar */}
        <ActionShowHide className="toggle-icon" onClick={toggleLeftSidebar}>
          <MediaQuery maxWidth={960}>
            {(matches) =>
              matches ? (
                <span
                  className={isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'}
                />
              ) : (
                <span
                  className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'}
                />
              )
            }
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    </ExamSectionsWrapper>
  );
};
export default React.memo(LeftSideBarDashboard, () => true);
