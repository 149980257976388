import { connect } from 'react-redux';
import StudentListTable from '../../components/StudentListTable/StudentListTable';
import { selectors, actions as SectionStudentActions } from '../../reducers/sectionStudent';
import { selectors as SectionSelectors } from '../../reducers/sections';

const mapStateToProps = (state) => {
  const sectionData = SectionSelectors.getFirstSection(state);
  const { id: sectionId } = sectionData;

  return {
    sectionId,
    studentList: selectors.getStudentList(state, sectionId),
  };
};

const mapDispatchToProps = {
  sectionsStudentRemove: SectionStudentActions.sectionsStudentRemove,
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentListTable);
