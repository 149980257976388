import React from 'react';
import MUIButton from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import {
  BlockWrap,
  BlockTitle,
  DefaultContentWrap,
} from '../Generals/stylesheets/General.style';
import {
  RecordAttendanceTabWrap as DashboardReportWrapper,
  RecordAttendanceWrap as ActiveRecordWrapper,
  LessonListContainer as ListContainer,
  LessonListWrap as ListsWrap,
  SessionItemWrap as ItemWrapper,
  SessionContent,
  RecordAttendanceTitle,
  SessionStatusIcon,
} from '../RecordAttendanceTab/RecordAttendanceTab.style';
import { DashboardActivityReportTabWrap } from '../DashboardPracticeDetail/DashboardPracticeDetail.style';
import { useIWGet } from './hooks';
import DropDownMenu from '../Shared/DropDownMenu/DropDownMenu';
import Table from './Components/Table';

const baseurl = '/instructionalwalkThrough';
const Reports = () => {
  const [selectedLesson, setSelectedLesson] = React.useState(null);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const listOptions = React.useMemo(() => [], []);
  const history = useHistory();
  const { data: walkThroughs } = useIWGet({
    defaultValue: [],
  });
  return (
    <DefaultContentWrap>
      <MUIButton color="primary" onClick={() => history.push(`${baseurl}`)}>
        Back to Templates
      </MUIButton>
      <BlockWrap fullWidth>
        <BlockTitle>
          <span>Instructional Reports</span>
        </BlockTitle>
        <DashboardActivityReportTabWrap>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <DropDownMenu
              title="Template"
              onSelect={(item) => {
                setSelectedTemplate(item);
              }}
              selected={selectedTemplate}
              list={walkThroughs}
              displayName={(item) => item?.name || ''}
              notSelectedFallback="No template"
            />
          </div>
        </DashboardActivityReportTabWrap>
        <DashboardReportWrapper>
          {!selectedTemplate ? (
            <p>Please select a template from the dropdown menu above to view the</p>
          ) : (
            <>
              {/* <ListContainer>
                <ListsWrap>
                  {listOptions.map((item, index) => (
                    <ItemWrapper key={item.id} onClick={() => setSelectedLesson(item)}>
                      {item.displayName}
                    </ItemWrapper>
                  ))}
                </ListsWrap>
              </ListContainer> */}
              <ActiveRecordWrapper>
                <Table IWId={selectedTemplate.id} />
              </ActiveRecordWrapper>
            </>
          )}
        </DashboardReportWrapper>
      </BlockWrap>
    </DefaultContentWrap>
  );
};

export default Reports;
