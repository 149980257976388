import React from 'react';
import ShowIf from '@Atoms/ShowIf';
import {
  ProgressBarWrap,
  ProgressSegment,
  ProgressSegmentItem,
  StatusLabel,
} from '../SubNavBar.style';

const ProgressBar = ({ rate }) => (
  <ProgressBarWrap>
    <StatusLabel>{`${rate}% Completed`}</StatusLabel>
    <ShowIf If={!!rate}>
      <ProgressSegment>
        <ProgressSegmentItem isCompleted rate={rate} />
      </ProgressSegment>
    </ShowIf>
  </ProgressBarWrap>
);

export default ProgressBar;
