import { connect } from 'react-redux';
import TeacherList from '../../components/CourseManagementSection/TeacherList';
import {
  actions as sectionTeachersAction,
} from '../../reducers/sectionTeachers';
import {
  selectors as courseTeacherSelectors,
  actions as courseTeacherActions,
} from '../../reducers/courseTeachers';
import { selectors as userSelectors } from '../../reducers/user';

const mapStateToProps = (state) => {
  const ownerIds = courseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find(id => id === userId);

  return {
    isOwner,
    teacherListModal: courseTeacherSelectors.getCourseTeacherList(state),
  };
};

const mapDispatchToProps = {
  sectionsTeachersRemove: sectionTeachersAction.sectionsTeachersRemove,
  sectionsTeachersAdd: sectionTeachersAction.sectionsTeachersAdd,
  fetchCourseTeacherList: courseTeacherActions.fetchCourseTeacherList,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherList);
