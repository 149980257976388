import React from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import { Canvas } from "canvas-embed";
import { PrimaryWrap } from '../Generals/stylesheets/General.style';
import {
  MissingReportMessageWrapper,
  MissingReportMessageContent,
  WarningIconWrapper,
  WarningIcon,
  CanvasWrapper,
  CanvasTitle
} from './AdminReports.style.js';

class AdminReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    courseDetail: PropTypes.object,
  }

  componentDidMount() {
    const { fetchAdminReportsUrl } = this.props;

    if (fetchAdminReportsUrl) {
      fetchAdminReportsUrl();
    }
  }

  renderMissingReportMessage = () => {
    return (
      <MissingReportMessageWrapper className="missing-report-container">
        <div className="card">
          <WarningIconWrapper>
            <WarningIcon src="/images/icon-svg/chalktalk-warming.svg" />
          </WarningIconWrapper>
          <div className="msg-content">
            <MissingReportMessageContent>
              Coming Soon!
            </MissingReportMessageContent>
          </div>
        </div>
      </MissingReportMessageWrapper>
    );
  };

  renderReportContent = (adminReports) => {
    return (
      <Iframe
        className="redash-reports-container"
        url={adminReports.report_url}
        width="100%"
        height="100%"
        id="AdminReports"
        display="initial"
        position="relative"
        allowFullScreen
      />
    );
  };

  renderCanvasEmbed = (adminReports) => {
    return (
      <CanvasWrapper className="canvas-reports-container">
        <CanvasTitle>Data Dashboard</CanvasTitle>
        <Canvas
          canvasId={adminReports.canvas_id}
          authToken={adminReports.canvas_auth_token}
          host='https://canvasapp.chalktalk.com'
        />
      </CanvasWrapper>
    )
  }

  render() {
    const { adminReports } = this.props;
    const renderCanvas = adminReports && adminReports.hasOwnProperty('canvas_id') && adminReports.hasOwnProperty('canvas_auth_token')
    const renderRedash = adminReports && adminReports.hasOwnProperty('report_url')
    return (
      <>
        <PrimaryWrap fullHeight>
          {renderCanvas && this.renderCanvasEmbed(adminReports)}
          {(!renderCanvas && renderRedash) && this.renderReportContent(adminReports)}
          {(!renderCanvas && !renderRedash) && this.renderMissingReportMessage()}
        </PrimaryWrap>
      </>
    );
  }
}

export default AdminReports;