import React, { Component } from 'react';
import Modal from 'react-modal';
import { Form, Formik, yupToFormErrors } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import MediaQuery from 'react-responsive';
import { ModalHeader, ModalCLose } from '../Generals/stylesheets/General.style';
import FormInput from '../Generals/FormInput/FormInput';
import { Button } from '../Generals/stylesheets/Button.style';
import { formatDate } from '../../utils/func-utils';
import * as SectionAPI from '../../apis/sections';
import { renderAlertModal } from '../../utils/userAlert';
import { ButtonWrapper } from './LeftSiderBar.style';
import { generateTestAdministrationData } from '@utils/scheduling';


const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '600px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    overflow: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class LeftSideAddOrEditTestV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examType: '',
      rapid: 0,
      setPlacement: 0,
      examSelected: null,
      dateSelected: '',
      durrationSelected: 1,
      sittingsSelected: 0,
    };
  }

  static propTypes = {
    sectionId: PropTypes.any,
    curriculum_Id: PropTypes.any,
    isAdding: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    isSubmitSuccessfully: PropTypes.bool,
    sectionExamGet: PropTypes.func,
    sectionExamAdd: PropTypes.func,
    sectionExamDelete: PropTypes.func,
    sectionExamUpdate: PropTypes.func,
    examSessons: PropTypes.array,
  };

  renderSectionExam = () => {
    const { examSessons } = this.props;
    const exams = examSessons.map((ex) => {
      return { id: ex.id, name: `Practice Test ${ex.practice_test_order + 1}` };
    });
    return exams;
  };

  onClose = () => {
    const { onClose, onSave } = this.props;
    onClose();
    onSave();
  };

  onSubmitForm = async () => {
    const {
      examSelected, dateSelected, durrationSelected, sittingsSelected,
    } = this.state;
    const {
      sectionId,
      curriculumId,
      sectionExamAdd,
      sectionExamUpdate,
      actionName,
      examSessons,
      sectionExamGet
    } = this.props;

    const placementExams = examSessons.filter((ex) => ex.session_type == 0);
    const midExams = examSessons.filter((ex) => ex.session_type == 1);
    const exitExams = examSessons.filter((ex) => ex.session_type == 2);
    if (actionName === 'Add') {
      const payload = {
        start_date: formatDate(dateSelected),
        end_date: formatDate(dateSelected),
        session_type: 1,
        published: false,
        length_type: durrationSelected,
        section: sectionId,
        curriculum: curriculumId,
        sittings_number: sittingsSelected,
      };

      await SectionAPI.updateSectionSettings(sectionId, { sittings_number: sittingsSelected });
      await sectionExamAdd(payload);
      this.onClose();

    } else {
      let scores = [];

      const response = await SectionAPI.getSectionExamsScores(sectionId).then(
        (responseData) => {
          scores = responseData.response.data;
        },
      );

      const isTaking = scores[examSelected.id]
        ? scores[examSelected.id].length > 0
        : true;
      if (isTaking) {
        renderAlertModal(
          "Some students are taking this exam now.You can't apply any changes or delete this exam",
        );
      } else {
          const payload = {
            start_date: formatDate(dateSelected),
            end_date: formatDate(dateSelected),
            session_type: examSelected.session_type,
            published: examSelected.published,
            length_type: durrationSelected,
            section: examSelected.section,
            curriculum: curriculumId,
          };
          await SectionAPI.updateSectionSettings(sectionId, { sittings_number: sittingsSelected });
          await sectionExamUpdate(examSelected.section, examSelected.id, payload);
          this.onClose();
      }
    }
    await new Promise((resolve) => {
      setTimeout(async () => {
        await sectionExamGet(sectionId, true);
        resolve()
    }, 1000)
  })
  };

  deleteExam = () => {
    const { sectionExamDelete } = this.props;
    const { examSelected } = this.state;
    sectionExamDelete(examSelected.id);
    this.onClose();

  };

  handleDate = (e) => {
    this.setState({ dateSelected: e });
  };

  handleTestSittings = (e) => {
    this.setState({ sittingsSelected: e.id });
  };

  handleSelectExam = (e) => {
    const { actionName } = this.props;
    if (actionName != 'Add') {
      const exam = this.props.examSessons.find((ex) => ex.id == e.id);
      this.setState({
        examSelected: exam,
        durrationSelected: exam.length_type,
        dateSelected: exam.start_date,
      });
    }
  };

  renderForm = () => {
    const { curriculumId, sectionDetail, actionName, examSessons } = this.props;
    const { examSelected } = this.state;
    let subjects = [];
    if (sectionDetail) {
      subjects = sectionDetail.subjects.map((s) => s.display_name);
    }
    let disabled = true;
    const lastExamOrder = Math.max(...examSessons.map(exam => exam.practice_test_order))
    if (examSelected) {
      if (examSelected.practice_test_order == lastExamOrder && examSelected.practice_test_order > 1) disabled = false;
    }
    return (
      <Form>
        {actionName != 'Add' &&
          <FormInput
            type="select"
            name="examType"
            label="Practice Test"
            options={this.renderSectionExam()}
            handleChange={this.handleSelectExam}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.name}
          />
        }
        <FormInput
          type="select"
          name="testAdminstration"
          label="Test Adminstration"
          options={generateTestAdministrationData(subjects, sectionDetail.class_duration, curriculumId)}
          handleChange={this.handleTestSittings}
          getOptionValue={(option) => option.id}
          getOptionLabel={(option) => option.name}
        />

        <FormInput
          type="date"
          name="startDate"
          label="Start Date"
          dateFormat="yyyy-MM-dd"
          minDate={new Date()}
          handleChange={this.handleDate}
        />

        <ButtonWrapper>
          <Button
            onClick={this.deleteExam}
            style={{
              background: '#453535',
              color: '#fff',
              borderRadius: '7px',
              display: disabled ? 'none' : 'flex',
              margin: '10px',
            }}
          >
            Delete Practice Test
          </Button>
          <Button
            type="submit"
            style={{
              background: '#f99d1e',
              color: '#fff',
              borderRadius: '7px',
              margin: '10px',
            }}
            submit
          >
            Submit Practice Test
          </Button>
        </ButtonWrapper>
      </Form>
    );
  };

  customStyle = (matches) => {
    const { content } = modalStyles;
    if (matches) {
      return ({
        ...modalStyles,
        content: {
          ...content,
          width: '100%',
          height: '100%',
          maxWidth: 'none',
          borderRadius: '0px',
          overflow: 'hidden',
        },
      });
    }
    return modalStyles;
  }

  render() {
    const { isOpen, sectionDetail, actionName } = this.props;
    const { examSelected } = this.state;
    const validationSchema = Yup.object().shape({
      examType: actionName !== "Add" ? Yup.mixed().required('Required') : null,
      testAdminstration: Yup.mixed().required('Required'),
      startDate: Yup.date().required('Required'),
    });
    let subjects = [];
    if (sectionDetail) {
      subjects = sectionDetail.subjects.map((s) => s.display_name);
    }
    const newInitialValues = {};
    if (this.props.actionName != 'Add' && examSelected) {
      newInitialValues.startDate = new Date(examSelected.start_date);
      newInitialValues.examType = this.renderSectionExam().find(
        (ex) => ex.id == examSelected.id,)
      newInitialValues.testAdminstration = '';
    } else {
      newInitialValues.testDurration = '';
      newInitialValues.startDate = '';
      newInitialValues.examType = '';
      newInitialValues.testAdminstration = '';
    }
    return (
      <MediaQuery maxWidth={768}>
        {matches => (
          <Modal
            isOpen={isOpen}
            onRequestClose={this.onClose}
            style={this.customStyle(matches)}
            ariaHideApp={false}
          >
            <ModalHeader
              style={{
                marginBottom: '2em',
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              {`${actionName} Practice Test`}
              <ModalCLose className="chalktalk-close" onClick={this.onClose} />
            </ModalHeader>
            <Formik
              initialValues={newInitialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={this.onSubmitForm}
              render={this.renderForm}
            />
          </Modal>
        )}
      </MediaQuery>
    );
  }
}
