import React, { forwardRef, useEffect } from 'react';
import { Box as MuiBox } from '@mui/material';
import Box from '@components/Atoms/Box';
import Paper from '@components/Atoms/Paper';
import BloomsSelector from '@components/Selectors/BloomsSelector/BloomsSelector';
import DokSelector from '@components/Selectors/DokSelector/DokSelector';
import ContentGroupButton from './ContentGroupButton';
import {
  useContentFilterContext,
  useContentFilterLessonContext,
  useContentFilterSkillContext,
  useContentFilterStandardContext,
} from '../ContentFilterProvider';
import StandardPopover from './StandardPopover';
import SkillPopover from './SkillPopover';
import LessonPopover from './LessonPopover';

export type FilterType = 'lessons' | 'standards' | 'skills' | null;

const MyMuiBox = React.forwardRef<HTMLDivElement, any>((props, ref) => (
  <MuiBox ref={ref} {...props} />
));

MyMuiBox.displayName = 'Box';

export interface ContentFiltersProps {
  orientation?: 'horizontal' | 'vertical';
  disabled?: boolean;
  defaults?: {
    dok: string;
    bloom: string;
  };
}

const ContentFilters = forwardRef<HTMLDivElement, ContentFiltersProps>((props, ref) => {
  const { orientation = 'horizontal', disabled = false, defaults } = props;
  const [openFilterType, setOpenFilterType] = React.useState<FilterType>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { selectedBlooms, setSelectedBlooms, selectedDok, setSelectedDok } =
    useContentFilterContext();
  const { selectedLessons } = useContentFilterLessonContext();
  const { selectedStandards } = useContentFilterStandardContext();
  const { selectedSkills } = useContentFilterSkillContext();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openFilter = (type: FilterType, element: HTMLElement) => {
    setOpenFilterType(type);
    setAnchorEl(element);
  };

  // set any defaults on mount
  useEffect(() => {
    if (defaults?.bloom) {
      setSelectedBlooms(Number(defaults.bloom));
    }
    if (defaults?.dok) {
      setSelectedDok(Number(defaults.dok));
    }
  }, [defaults?.bloom, defaults?.dok, setSelectedBlooms, setSelectedDok]);

  const isVerticalOrientation = orientation.toLowerCase() === 'vertical';

  return (
    <MyMuiBox
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: isVerticalOrientation ? 'column' : 'row',
        gap: 1,
      }}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '0.5rem',
          p: 1,
          mb: isVerticalOrientation ? '0.25rem' : '1rem',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <ContentGroupButton
          type="lessons"
          count={selectedLessons.length}
          onClick={openFilter}
          color="#1bd18a"
        />

        <ContentGroupButton
          type="standards"
          count={selectedStandards.length}
          onClick={openFilter}
          color="#3778f7"
        />

        <ContentGroupButton
          type="skills"
          count={selectedSkills.length}
          onClick={openFilter}
          color="#ff9e16"
        />
      </Paper>

      <Box
        sx={{
          mt: '0.4rem',
        }}
      >
        <BloomsSelector
          value={selectedBlooms ? [selectedBlooms] : []}
          onChange={(values) => {
            setSelectedBlooms(values.length > 0 ? Number(values[0]) : null);
          }}
          disabled={disabled}
        />
      </Box>

      <Box
        sx={{
          mt: '0.4rem',
        }}
      >
        <DokSelector
          value={selectedDok ? [selectedDok] : []}
          onChange={(values) => {
            setSelectedDok(values.length > 0 ? Number(values[0]) : null);
          }}
          disabled={disabled}
        />
      </Box>

      {/* Popover for Lesson Filter */}
      <LessonPopover
        open={openFilterType === 'lessons' && Boolean(anchorEl)}
        anchor={anchorEl}
        onClose={handleClose}
        disabled={disabled}
      />

      {/* Popover for Standard Filter */}
      <StandardPopover
        open={openFilterType === 'standards' && Boolean(anchorEl)}
        anchor={anchorEl}
        onClose={handleClose}
        disabled={disabled}
      />

      {/* Popover for Skill Filter */}
      <SkillPopover
        open={openFilterType === 'skills' && Boolean(anchorEl)}
        anchor={anchorEl}
        onClose={handleClose}
        disabled={disabled}
      />
    </MyMuiBox>
  );
});

// Add a display name for the component
ContentFilters.displayName = 'ContentFilters';

export default ContentFilters;
