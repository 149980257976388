import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import * as ExamAPI from '../../apis/exam';

import {
  EXAM_REPORT_GET_DETAIL,
  EXAM_REPORT_GET_OF_USER,

  actions as examReportActions,
} from '../../reducers/examReport';
/**
 * ! Need update callback
 */
function* examReportGetDetail({ payload }) {
  const {
    examSessionId, examSectionId, studentId,
  } = payload;
  const { response, error } = yield call(ExamAPI.getStudentSectionReport, examSessionId, examSectionId, studentId);

  if (response) {
    const { data = {} } = response;
    yield put(examReportActions.examReportGetDetailSuccess(data));
  } else {
    yield put(examReportActions.examReportGetDetailFail(error));
  }
}

function* examReportGetOfUser({ examSessionId, examSectionId }) {
  const { response, error } = yield call(ExamAPI.getUserSectionScoreReport, examSessionId, examSectionId);

  if (response) {
    const { data = {} } = response;
    yield put(examReportActions.examReportGetDetailSuccess(data));
  } else {
    yield put(examReportActions.examReportGetDetailFail(error));
  }
}

export default function* examReportSaga() {
  yield takeLeading(EXAM_REPORT_GET_DETAIL, examReportGetDetail);
  yield takeLeading(EXAM_REPORT_GET_OF_USER, examReportGetOfUser);
}
