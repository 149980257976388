/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import DashboardAttendanceReportTable from '../../containers/DashboardAttendanceReportTable/DashboardAttendanceReportTable';
import { BlockTitle, BlockWrap } from '../Generals/stylesheets/General.style';
import { DATE_FORMAT } from '../../utils/constants';

const randomBool = () => Boolean(parseInt(Math.random() * 100000000, 10) % 2);
const thisWeek = moment().startOf('week').subtract(2, 'week');
const fakeLessonData = [...new Array(21)].map((_, i) => ({
  id: 1,
  date: thisWeek.clone().add(i, 'day').format(DATE_FORMAT.DATE),
  lesson: 5,
  activity: 'lesson',
  recorded_by: 13,
  recorded: moment().toISOString(),
  records: [
    {
      user: 11,
      present: randomBool(),
    },
    {
      user: 12,
      present: randomBool(),
    },
    {
      user: 14,
      present: randomBool(),
    },
    {
      user: 15,
      present: randomBool(),
    },
    {
      user: 16,
      present: randomBool(),
    },
  ],
}));

const fakeActivityData = [...new Array(21)].map((_, i) => ({
  id: 1,
  date: thisWeek.clone().add(i, 'day').format(DATE_FORMAT.DATE),
  lesson: 5,
  activity: 'activity',
  recorded_by: 13,
  recorded: moment().toISOString(),
  records: [
    {
      user: 11,
      present: randomBool(),
    },
    {
      user: 12,
      present: randomBool(),
    },
    {
      user: 14,
      present: randomBool(),
    },
    {
      user: 15,
      present: randomBool(),
    },
    {
      user: 16,
      present: randomBool(),
    },
  ],
}));

class DashboardAttendanceDetailFake extends Component {
  render() {
    return (
      <BlockWrap>
        <BlockTitle>
          <span>Student Attendance</span>
        </BlockTitle>
        <p>Summary of student attendance up to this points of time</p>
        <DashboardAttendanceReportTable lessonAttendanceList={fakeLessonData} groupActivityAttendanceList={fakeActivityData} />
      </BlockWrap>
    );
  }
}

export default DashboardAttendanceDetailFake;
