import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';
import { reviewerList } from '../../utils/reviewerList';

const ReviewerAuthRoute = ({
  authenticated,
  location,
  match,
  userEmail,
  component: Component,
  path,
  ...rest
}) => (
  <Route
    path={path}
    {...rest}
    render={(props) => {
      if (reviewerList.includes(userEmail) && authenticated) {
        return (
          <ChalkTalkErrorBoundary key={path}>
            <Component {...props} location={location} />;
          </ChalkTalkErrorBoundary>
        );
      }

      const toObj = {
        pathname: '/',
        state: { from: location },
      };

      return <Redirect to={toObj} />;
    }}
  />
);

ReviewerAuthRoute.propTypes = {
  component: PropTypes.object,
  location: PropTypes.any,
  authenticated: PropTypes.bool,
  match: PropTypes.any,
  userEmail: PropTypes.string,
};

ReviewerAuthRoute.defaultProps = {
  authenticated: false,
  component: () => null,
};

export default ReviewerAuthRoute;
