import React from 'react';
import PropTypes from 'prop-types';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
  ButtonWrapper,
  ButtonStyled,
} from '../CourseCreatedModal/CourseCreatedModal.style';
import {
  PostStudentsInviteItemWrap,
  PostStudentsInviteItem,
  PostStudentsInviteTitle,
  ButtonStyled as GreenButtonStyled,
} from '../PostStudentsInviteModal/PostStudentsInviteModal.style';
import { SkipWrapper } from './PostTeacherInvitationModal.style';

const PostTeacherInvitationModal = (props) => {
  const { isInRegisterFlow } = props;

  if (!isInRegisterFlow) {
    return (
      <PostStudentsInviteItemWrap>
        <PostStudentsInviteItem>
          <PostStudentsInviteTitle>
            <h1>Invites are sent successfully</h1>
            <p>Teachers will receive an invite link in their email.</p>
            <ButtonWrapper>
              <GreenButtonStyled onClick={props.onClose}>GOT IT</GreenButtonStyled>
            </ButtonWrapper>
          </PostStudentsInviteTitle>
        </PostStudentsInviteItem>
      </PostStudentsInviteItemWrap>
    );
  }

  return (
    <CreateOrJoinItemWrap>
      <CreateOrJoinItem>
        <CreateOrJoinTitle>
          <h1>
            Invites are sent successfully.
          </h1>
          <p>
            Teachers are sent an email to join course.
          </p>
          <p>
            After they sign up, they can begin creating their sections in the course.
          </p>
          <ButtonWrapper>
            <ButtonStyled onClick={props.openCreateSectionModal}>Next: Create Section</ButtonStyled>
          </ButtonWrapper>
          <SkipWrapper onClick={props.openSkipModal}>
            <a>
              Skip and I will do this later
            </a>
          </SkipWrapper>
        </CreateOrJoinTitle>
      </CreateOrJoinItem>
    </CreateOrJoinItemWrap>
  );
};

PostTeacherInvitationModal.propTypes = {
  openCreateSectionModal: PropTypes.func,
  onClose: PropTypes.func,
  isInRegisterFlow: PropTypes.bool,
  openSkipModal: PropTypes.func,
};

export default PostTeacherInvitationModal;
