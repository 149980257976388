/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import _round from 'lodash/round';
import FormInput from '../Generals/FormInput/FormInput';
import { FormGroup } from '../Generals/stylesheets/Form.style';
import { Button } from '../Generals/stylesheets/Button.style';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  RegisterFormWrapper,
  RegisterFormTitle,
  RegisterFormInput,
  RegisterAction,
  RegisterMessageContent,
  ButtonStyled,
  RegisterErrorMessage,
  RegisterSubFormWrap,
  RegisterCodeStyled,
  RegistrationTimeContainer,
  RegistrationTimeList,
  RegistrationTimeItem,
  RegistrationTotalTimeItem,
  RegistrationTimeItemDataWrapper,
  RegistrationTimeItemData,
  RegistrationTimeItemUnit,
  RegistrationTimeItemOps,
  RegistrationTimeItemNote,
} from '../StudentRegister/StudentRegister.style';

const validationSchema = Yup.object().shape({
  sectionName: Yup.string().trim().required('Required'),
  grade: Yup.string().trim().required('Required'),
  subjects: Yup.string().trim().required('Required'),
  startDate: Yup.date().required('Required'),
  endDate: Yup.date().required('Required'),
  placementStartDate: Yup.date().required('Required'),
  placementEndDate: Yup.date().required('Required'),
  exitStartDate: Yup.date().required('Required'),
  exitEndDate: Yup.date().required('Required'),
});

const gradeOption = [
  {
    id: '1',
    grade_level: '9',
  },
  {
    id: '2',
    grade_level: '10',
  },
  {
    id: '3',
    grade_level: '11',
  },
  {
    id: '4',
    grade_level: '12',
  },
];

const classDayOption = [
  {
    day: '0',
    name: 'Monday',
  },
  {
    day: '1',
    name: 'Tuesday',
  },
  {
    day: '2',
    name: 'Wednesday',
  },
  {
    day: '3',
    name: 'Thursday',
  },
  {
    day: '4',
    name: 'Friday',
  },
  {
    day: '5',
    name: 'Saturday',
  },
  {
    day: '6',
    name: 'Sunday',
  },
];

export default class SectionCodeForm extends Component {
  static propTypes = {
    submitSectionSetup: PropTypes.func,
    subject: PropTypes.array,
    sectionData: PropTypes.object,
    courseData: PropTypes.object,
    isShowSectionConfirmation: PropTypes.bool,
    handleNextStep: PropTypes.func,
    errorMessage: PropTypes.any,
    getCurriculumSubjectList: PropTypes.func,
    curriculumId: PropTypes.any,
    courseId: PropTypes.any,
  };

  componentDidMount() {
    const { curriculumId, getCurriculumSubjectList } = this.props;
    getCurriculumSubjectList(curriculumId);
  }

  handleSubmit = (values) => {
    const { submitSectionSetup, courseId } = this.props;
    const {
      placementStartDate,
      placementEndDate,
      exitStartDate,
      exitEndDate,
      ...rest
    } = values;

    const exams = [];

    exams.push({
      session_type: 0,
      start_date: moment(placementStartDate).format('YYYY-MM-DD'),
      end_date: moment(placementEndDate).format('YYYY-MM-DD'),
    });

    exams.push({
      session_type: 2,
      start_date: moment(exitStartDate).format('YYYY-MM-DD'),
      end_date: moment(exitEndDate).format('YYYY-MM-DD'),
    });

    const sectionPayload = {
      ...rest,
      course: courseId,
      exams,
    };
    submitSectionSetup(sectionPayload);
  };

  handleNextStep = () => this.props.handleNextStep();

  renderSectionConfirmationScreen = () => {
    const {
      sectionData: { code, name },
    } = this.props;
    const sectionCode = (code || []).map((e) => e.code);
    if (!sectionCode) {
      return <LoadingIndicator isLoading={sectionCode} />;
    }
    return (
      <RegisterFormInput isLoading>
        <RegisterMessageContent>
          <h1>&#127881;</h1>
          <p>
            Congratulations, you created your first section,
            {name}
            . Your section code is
          </p>
          <RegisterCodeStyled>{sectionCode}</RegisterCodeStyled>
          <p>You can invite your students on the next page</p>
        </RegisterMessageContent>
        <RegisterAction large>
          <Link to="/dashboard/course">
            <ButtonStyled type="button">Go to course</ButtonStyled>
          </Link>
          <ButtonStyled type="button" onClick={this.handleNextStep}>
            Invite students
          </ButtonStyled>
        </RegisterAction>
      </RegisterFormInput>
    );
  };

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>;

  disableInput = (e) => {
    e.preventDefault();
  };

  renderTimeCalculation = (values) => {
    const {
      classDuration = 0, startDate, endDate, classDay = [],
    } = values;
    const weeksCount = moment(endDate).diff(moment(startDate), 'week');
    const dayCount = classDay.length || 0;
    const total = (classDuration * dayCount * weeksCount) / 60;
    const formattedTime = total >= 0 ? _round(total, 1) : 0;
    const isRed = formattedTime < 20;

    return (
      <RegistrationTimeContainer>
        <RegistrationTimeList>
          <RegistrationTimeItem>
            <RegistrationTimeItemDataWrapper>
              <RegistrationTimeItemData>
                {classDuration}
              </RegistrationTimeItemData>
              <RegistrationTimeItemUnit>mins</RegistrationTimeItemUnit>
            </RegistrationTimeItemDataWrapper>
            <RegistrationTimeItemOps>X</RegistrationTimeItemOps>
          </RegistrationTimeItem>
          <RegistrationTimeItem>
            <RegistrationTimeItemDataWrapper>
              <RegistrationTimeItemData>{dayCount}</RegistrationTimeItemData>
              <RegistrationTimeItemUnit>days</RegistrationTimeItemUnit>
            </RegistrationTimeItemDataWrapper>
            <RegistrationTimeItemOps>X</RegistrationTimeItemOps>
          </RegistrationTimeItem>
          <RegistrationTimeItem>
            <RegistrationTimeItemDataWrapper>
              <RegistrationTimeItemData>{weeksCount}</RegistrationTimeItemData>
              <RegistrationTimeItemUnit>weeks</RegistrationTimeItemUnit>
            </RegistrationTimeItemDataWrapper>
            <RegistrationTimeItemOps>=</RegistrationTimeItemOps>
          </RegistrationTimeItem>
          <RegistrationTotalTimeItem red={isRed}>
            <RegistrationTimeItemDataWrapper>
              <RegistrationTimeItemData>
                {formattedTime}
              </RegistrationTimeItemData>
              <RegistrationTimeItemUnit>hours</RegistrationTimeItemUnit>
            </RegistrationTimeItemDataWrapper>
          </RegistrationTotalTimeItem>
        </RegistrationTimeList>
        <RegistrationTimeItemNote>(Min. 20 hours)</RegistrationTimeItemNote>
      </RegistrationTimeContainer>
    );
  };

  renderCreateBtn = (values) => {
    const {
      classDuration = 0, startDate, endDate, classDay = [],
    } = values;
    const weeksCount = moment(endDate).diff(moment(startDate), 'week');
    const dayCount = (classDay || []).length || 0;
    const total = (classDuration * dayCount * weeksCount) / 60;
    const formattedTime = total >= 0 ? _round(total, 1) : 0;
    const isDisabled = formattedTime < 20;

    return (
      <RegisterAction>
        <Button type="submit" isDisable={isDisabled} disabled={isDisabled}>
          Create section
        </Button>
      </RegisterAction>
    );
  };

  renderSectionCodeForm = () => {
    const { subject, errorMessage, courseData } = this.props;
    const sectionName = (courseData || {}).custom_name || (courseData || {}).name;
    const initialValues = {
      sectionName: `${sectionName}-A`,
      grade: '',
      subjects: '',
      startDate: new Date(),
      endDate: moment().add(30, 'day').toDate(),
      classDuration: 90,
      classDay: '',
      placementEndDate: '',
      placementStartDate: '',
      exitEndDate: '',
      exitStartDate: '',
    };
    // eslint-disable-next-line eqeqeq
    const filteredSubjects = subject.filter((sub) => sub.public == true);
    return (
      <React.Fragment>
        <RegisterFormTitle>
          <h1>Create your first section</h1>
          <ul>
            <li>You can create multiple sections in the same course.</li>
            <li>
              Once you create your section, you will be able to invite your
              students or share the code that allows them to register and
              enroll.
            </li>
          </ul>
        </RegisterFormTitle>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
        >
          {({ handleSubmit, values }) => (
            <RegisterFormInput onSubmit={handleSubmit}>
              <FormGroup>
                <FormInput
                  type="text"
                  name="sectionName"
                  label="Section Name"
                />
                <FormInput
                  type="select"
                  name="grade"
                  label="Select Grade Levels"
                  options={gradeOption}
                  getOptionValue={(option) => option.id}
                  getOptionLabel={(option) => option.grade_level}
                  isMulti
                />
              </FormGroup>
              <FormInput
                type="select"
                name="subjects"
                label="Select Subjects"
                options={filteredSubjects}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                isMulti
              />
              <RegisterSubFormWrap>
                <h1>Class Schedule</h1>
                <h3>
                  This information is used to generate a recommended schedule
                  for this section. Our program requires a minimum commitment of
                  20 hours.
                </h3>
                <FormGroup>
                  <FormInput
                    type="date"
                    name="startDate"
                    label="Section Start Date"
                    dateFormat="yyyy/MM/dd"
                  />
                  <FormInput
                    type="date"
                    name="endDate"
                    label="Section End Date"
                    dateFormat="yyyy/MM/dd"
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    type="number"
                    min={20}
                    max={200}
                    step={5}
                    name="classDuration"
                    label="Class Duration (in minutes)"
                    onKeyDown={this.disableInput}
                  />
                  <FormInput
                    type="select"
                    name="classDay"
                    label="Select Class Days"
                    options={classDayOption}
                    getOptionValue={(option) => option.day}
                    getOptionLabel={(option) => option.name}
                    isMulti
                  />
                </FormGroup>
                {this.renderTimeCalculation(values)}
              </RegisterSubFormWrap>
              <RegisterSubFormWrap>
                <h1>Exams Schedule</h1>
                <h3>
                  Set the Placement and Exit tests&#39; dates for this section.
                  This can be changed.
                </h3>
                <FormGroup>
                  <label>Placement Session</label>
                  <FormInput
                    type="date"
                    name="placementStartDate"
                    label="Start Date"
                    dateFormat="yyyy/MM/dd"
                  />
                  <FormInput
                    type="date"
                    name="placementEndDate"
                    label="End Date"
                    dateFormat="yyyy/MM/dd"
                  />
                </FormGroup>
                <FormGroup>
                  <label>Exit Session</label>
                  <FormInput
                    type="date"
                    name="exitStartDate"
                    label="Start Date"
                    dateFormat="yyyy/MM/dd"
                  />
                  <FormInput
                    type="date"
                    name="exitEndDate"
                    label="End Date"
                    dateFormat="yyyy/MM/dd"
                  />
                </FormGroup>
              </RegisterSubFormWrap>
              {errorMessage && (
                <RegisterErrorMessage>
                  {errorMessage.map(this.renderErrorMessage)}
                </RegisterErrorMessage>
              )}
              {this.renderCreateBtn(values)}
            </RegisterFormInput>
          )}
        </Formik>
      </React.Fragment>
    );
  };

  render() {
    const { isShowSectionConfirmation } = this.props;
    return (
      <RegisterFormWrapper>
        {isShowSectionConfirmation
          ? this.renderSectionConfirmationScreen()
          : this.renderSectionCodeForm()}
      </RegisterFormWrapper>
    );
  }
}
