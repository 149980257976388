import React, { createContext, useMemo } from 'react';
interface AssessmentProviderProps {
  serviceURL?: {
    normal: string;
    CDN: string;
  };
  serviceEnvironment: string;
  children: React.ReactNode | React.ReactNode[];
}

interface AssessmentContextInterface {
  serviceURL: {
    normal: string;
    CDN: string;
  };
  serviceEnvironment: string;
}
const AssessmentContext: React.Context<AssessmentContextInterface> =
  createContext<AssessmentContextInterface>({
    serviceEnvironment: 'production',
    serviceURL: {
      CDN: '//cdn-assessment.svc.chalktalk-api.com',
      normal: '//assessment.svc.chalktalk-api.com',
    },
  });

const AssessmentProvider: React.FC<AssessmentProviderProps> = ({
  serviceURL = {
    CDN: '//cdn-assessment.svc.chalktalk-api.com',
    normal: '//assessment.svc.chalktalk-api.com',
  },
  serviceEnvironment = 'production',
  children,
}) => {
  const value = useMemo(
    () => ({
      serviceEnvironment,
      serviceURL,
    }),
    [serviceURL, serviceEnvironment],
  );
  return (
    <AssessmentContext.Provider value={value}>{children}</AssessmentContext.Provider>
  );
};
export default React.memo(AssessmentProvider);
// export default AssessmentProvider;
export {
  AssessmentProvider,
  AssessmentProviderProps,
  AssessmentContext,
  AssessmentContextInterface,
};
