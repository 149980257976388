import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import { useIWContext } from '../IWContextProvider';
import RatingGroup from './RatingGroup';

const CustomIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    color: ${(props) => props.theme.colorStyled[props.color] || 'inherit'};
    padding: 0.5rem;
  }
`;
const CriteriaCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0rem;
`;
const CriteriaCardControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
`;
const CriteriaCardControlBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  flex: 1;
`;
export const FancyTextField = styled(TextField)`
  flex: 1;
  & .MuiInput-underline:before {
    border-bottom: none !important;
  }
  & .MuiInput-underline:after {
    display: none;
  }
  & .MuiInputBase-root.Mui-disabled {
    color: inherit;
  }
`;
const CriteriaCard = ({
  criteria,
  editCriteriaTitle,
  controllable = false,
  deleteCriteria,
}) => {
  const textareaRef = React.useRef(null);
  const [value, setValue] = useState(criteria?.displayName || '');
  const [isEditMode, setIsEditMode] = useState(!criteria?.displayName);
  const { values, answerCriteria } = useIWContext();
  useEffect(() => {
    if (criteria?.displayName === 'new Criteria') {
      setIsEditMode(true);
    }
  }, []);
  useEffect(() => {
    if (isEditMode && textareaRef.current) {
      textareaRef.current.querySelector('textarea')?.focus();
    }
  }, [isEditMode]);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  if (!criteria || (!controllable && !criteria.displayName)) {
    return <CriteriaCardWrapper />;
  }
  return (
    <CriteriaCardWrapper>
      <CriteriaCardControlBody>
        <FancyTextField
          ref={textareaRef}
          label=""
          multiline
          rows={1}
          maxRows={4}
          value={value}
          onChange={handleChange}
          disabled={!isEditMode}
          placeholder="Enter Criteria ..."
          variant="standard"
        />
        {!controllable && (
          <RatingGroup
            selected={values.criteria[criteria.id]}
            displayLabel={criteria.displayName}
            onChangeValue={(NewValue) => answerCriteria(criteria.id, NewValue)}
            defaultValue="N/A"
          />
        )}
      </CriteriaCardControlBody>
      {controllable && (
        <CriteriaCardControlWrapper>
          {isEditMode ? (
            <>
              <CustomIconButton
                fontSize="small"
                onClick={() => {
                  editCriteriaTitle?.(criteria, value);
                  setIsEditMode(false);
                }}
                color="ColorPrimaryBlue"
              >
                <i className="chalktalk-correct" />
              </CustomIconButton>
              <CustomIconButton
                fontSize="small"
                color="ColorRedDel"
                onClick={() => {
                  setIsEditMode(false);
                  setValue(criteria.displayName);
                }}
              >
                <i className="chalktalk-cancel" />
              </CustomIconButton>
            </>
          ) : (
            <>
              <CustomIconButton
                fontSize="small"
                onClick={() => setIsEditMode(true)}
                color="ColorPrimaryBlue"
              >
                <i className="chalktalk-edit" />
              </CustomIconButton>
              <CustomIconButton
                fontSize="small"
                color="ColorRedDel"
                onClick={() => deleteCriteria(criteria)}
              >
                <i className="chalktalk-icon-delete" />
              </CustomIconButton>
            </>
          )}
        </CriteriaCardControlWrapper>
      )}
    </CriteriaCardWrapper>
  );
};

export default CriteriaCard;
