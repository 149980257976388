import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// new reducer
import { dispatch } from 'd3';
import auth, { AUTH_LOGOUT } from './auth';
import courses from './courses';
import sections from './sections';
import system from './system';
import user from './user';
import register from './register';
import districts from './districts';
import countries from './countries';
import curriculums from './curriculums';
import curriculumLessons from './curriculumLessons';
import leftSideBar from './leftSideBar';

import sectionReviews from './sectionReviews';
import sectionStudent from './sectionStudent';
import sectionTeacher from './sectionTeachers';
import sectionSchedule from './sectionSchedule';
import sectionExam from './sectionExam';
import sectionSession from './sectionSession';
import sectionUnit from './sectionUnit';
import sectionAttendance from './sectionAttendance';
import scheduleForms from './scheduleForms';

import courseStudents from './courseStudents';
import courseTeachers from './courseTeachers';

import examResult from './examResult';
import examReport from './examReport';
import exam from './exam';

import activity from './activity';
import userActivities from './userActivities';

import scheduleModal from './scheduleModal';
import lessonAssignment from './lessonAssignment';
import practice from './practice';
import practiceReport from './practiceReport';

import sectionSkills from './sectionSkills';
import sectionSubjects from './sectionSubjects';
import lessonAssignedActivities from './lessonAssignedActivities';

import adminReports from './adminReports';

import { success, error as errorToast, error } from '../utils/toast';

import groupActivity from './groupActivity'

const rootReducer = history => {
  const appReducer = combineReducers({
    // new reducers
    router: connectRouter(history),
    auth,
    courses,
    sections,
    sectionReviews,
    system,
    user,
    register,
    districts,
    countries,
    curriculums,
    curriculumLessons,
    leftSideBar,
    sectionStudent,
    sectionSchedule,
    scheduleForms,
    courseStudents,
    courseTeachers,
    sectionTeacher,
    sectionExam,
    sectionSession,
    sectionUnit,
    sectionAttendance,

    examResult,
    examReport,
    exam,

    userActivities,
    activity,

    scheduleModal,
    lessonAssignment,
    practice,
    practiceReport,
    sectionSkills,
    sectionSubjects,
    lessonAssignedActivities,

    groupActivity,

    adminReports

  });

  const rootReducerModified = (state, action) => {
    let newState = state;
    if (action.type === AUTH_LOGOUT) {
      newState = undefined;
    }

    if (action.type.match(/_FAIL/)) {
      if (action.hasOwnProperty('payload')) {
        if (action.payload.length > 0) {
          if (action.payload[0].match(/permission/)) {
            errorToast(action.payload[0], 'permissionError');
          }
        }
      } else if (action.hasOwnProperty('error')) {
        if (action.error.length) {
          if (action.error[0].match(/permission/)) {
            errorToast(action.error[0],'permissionError');
          }
        }
      }
    }

    return appReducer(newState, action);
  };

  return rootReducerModified;
};

export default rootReducer;
