/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import useIsMobile from '@utils/hooks/useIsMobile';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
} & Omit<React.ComponentProps<typeof Tooltip>, 'children'>;

const TooltipDisabledButton = ({
  children,
  disabled,
  title,
  placement,
  ...props
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMobile();

  const handleClickOnMobile = React.useCallback(() => {
    if (isMobile && !disabled) {
      setOpen(true);
    }
  }, [isMobile, disabled]);
  return (
    <Tooltip
      arrow
      title={title}
      placement={placement}
      onOpen={(e) => {
        if (!disabled) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      open={open}
      {...props}
    >
      <span onClick={handleClickOnMobile}>{children}</span>
    </Tooltip>
  );
};

export default TooltipDisabledButton;
