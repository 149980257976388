import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BannerWrap, BannerText, BannerButton } from './Banner.style';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { bannerTypes } from './bannerTypes';


const Banner = ({ bannerText, bannerUrl, bannerAction, hideButton, bannerType }) => {
  const currentType = bannerTypes[bannerType];
  const history = useHistory();
  const getIcon = () => {
    switch (currentType.icon) {
      case 'ErrorRoundedIcon':
        return <ErrorRoundedIcon />;
      default:
        return null;
    }
  };
  return (
    <BannerWrap bannerType={currentType}>
      <BannerText bannerType={currentType}>
        {getIcon()}
        {bannerText}
      </BannerText>
      <BannerButton bannerType={currentType} hideButton={hideButton} onClick={() => history.push(bannerUrl)}>{bannerAction}</BannerButton>
    </BannerWrap>
  );
};

export default Banner;