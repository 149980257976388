/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import {
  CustomToolbarWrap,
  CustomToolbarWrapMain,
  CustomToolbarLeft,
  CustomToolbarRight,
  CustomToolbarButton,
  CustomToolbarTitle,
  CustomToolbarMainTitle,
} from './Calendar.style';
import { ROLE_TYPE } from '../../utils/enums';
import { selectors as UserSelectors } from '../../reducers/user';

const endOfYearRegExp = new RegExp(/(December|Dec).*(January|Jan)/i);

class CustomToolbar extends Toolbar {
  onNavigate = (action) => {
    const { onNavigate } = this.props;
    onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  render() {
    const {
      label,
      date,
      view,
      isTeacher,
      editMode,
      goToEditMode,
      exitEditMode,
      isLoadingData,
    } = this.props;

    const formatedDate = moment(date).day(0);
    const year = formatedDate.format('Y');
    const week = formatedDate.locale('us').week();
    const nextWeek = formatedDate.add(7, 'd').locale('us').week();
    const renderDate = () => {
      if (view === 'two-week' || view === 'week') {
        const splitLabel = label.split(' – ', 2);
        if (endOfYearRegExp.test(label)) {
          const nextYear = Number(year) + 1;
          return `${splitLabel[0]} ${year} – ${splitLabel[1]} ${nextYear}`;
        }
        return `${splitLabel[0]} – ${splitLabel[1]} ${year}`;
      }
      return label;
    };

    return (
      <React.Fragment>
        <CustomToolbarWrapMain>
          <CustomToolbarMainTitle>Calendar</CustomToolbarMainTitle>
          <CustomToolbarRight>
            <CustomToolbarButton
              active={view === 'month'}
              left
              onClick={this.view.bind(null, 'month')}
            >
              Month
            </CustomToolbarButton>
            <CustomToolbarButton
              active={view === 'two-week'}
              onClick={this.view.bind(null, 'two-week')}
            >
              Two Weeks
            </CustomToolbarButton>
            <CustomToolbarButton
              active={view === 'week'}
              right
              onClick={this.view.bind(null, 'week')}
            >
              Week
            </CustomToolbarButton>
          </CustomToolbarRight>
        </CustomToolbarWrapMain>
        <CustomToolbarWrap>
          <CustomToolbarLeft variant2>
            <CustomToolbarButton
              rescheduleButton
              variant2
              onClick={() => this.onNavigate('TODAY')}
            >
              Today
            </CustomToolbarButton>
            <Tooltip title="Jump to the first scheduled session" placement="top">
              <CustomToolbarButton
                className="chalktalk-icon-fwdend"
                left
                icon
                leftSquare
                onClick={() => this.onNavigate('START')}
              />
            </Tooltip>
            <Tooltip title="Previous period" placement="top">
              <CustomToolbarButton
                className="chalktalk-icon-pre"
                right
                icon
                rightSquare
                noBorder
                onClick={() => this.onNavigate('PREV')}
              />
            </Tooltip>
            <Tooltip title="Next period" placement="top">
              <CustomToolbarButton
                className="chalktalk-icon-next"
                left
                icon
                leftSquare
                noBorder
                onClick={() => this.onNavigate('NEXT')}
              />
            </Tooltip>
            <Tooltip title="Jump to the last scheduled session" placement="top">
              <CustomToolbarButton
                className="chalktalk-icon-backend"
                right
                icon
                rightSquare
                onClick={() => this.onNavigate('END')}
              />
            </Tooltip>
          </CustomToolbarLeft>
          <CustomToolbarWrap>
            <CustomToolbarTitle>
              <CustomToolbarTitle>
                <p>{renderDate()}</p>
                {view === 'week' && (
                  <small>
                    {'Week '}
                    {week}
                  </small>
                )}
                {view === 'two-week' && (
                  <small>
                    Weeks {week}, {nextWeek}
                  </small>
                )}
              </CustomToolbarTitle>
            </CustomToolbarTitle>
          </CustomToolbarWrap>
          <CustomToolbarRight></CustomToolbarRight>
        </CustomToolbarWrap>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  isTeacher: UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR,
});

export default connect(mapStateToProps)(CustomToolbar);
