import React, { useCallback, useMemo, useState } from 'react';
import ResetActivityDialog from '../ResetActivityDialog';
import { useResetOnlinePracticeActivity } from '../hooks/mutation';

type ResetActivityContextType = {
  handleResetActivity: () => void;
  isResetActivityLoading: boolean;
};
const ResetActivityContext = React.createContext<ResetActivityContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleResetActivity: () => {},
  isResetActivityLoading: false,
});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const ResetActivityProvider = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { mutate: resetOnlinePracticeActivity, isLoading } =
    useResetOnlinePracticeActivity();
  const handleResetActivity = useCallback(() => {
    setOpen(true);
  }, []);

  const handleConfirmDialog = () => {
    resetOnlinePracticeActivity();
    setOpen(false);
  };

  const value = useMemo(
    () => ({ handleResetActivity, isResetActivityLoading: isLoading }),
    [handleResetActivity, isLoading],
  );
  return (
    <ResetActivityContext.Provider value={value}>
      {children}
      <ResetActivityDialog
        dataTestId="reset-activity-dialog"
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={handleConfirmDialog}
      />
    </ResetActivityContext.Provider>
  );
};

export default React.memo(ResetActivityProvider);
export const useResetActivity = () => {
  const context = React.useContext(ResetActivityContext);
  if (context === undefined) {
    throw new Error('useResetActivity must be used within a ResetActivityProvider');
  }
  return context;
};
