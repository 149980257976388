/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getStandardScore } from '../../apis/sections';

export const useStandardScore = () => {
  const { sectionId } = useParams();
  const queryData = useQuery(
    ['standardScore', sectionId],
    async () => (await getStandardScore(sectionId))?.response?.data,
    {
      enabled: !!sectionId,
      placeholderData: {},
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 60,
    },
  );
  return queryData;
};
