import axios from 'axios';
import { handleError } from './utils';

export function getStandard() {
  const url = '/api/curriculum/available-standards/';
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getCurriculumList() {
  const url = '/api/curriculum/available-curriculum/';
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSubject(curriculumId) {
  const url = `/api/curriculum/${curriculumId}/available-subjects/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getTrainings(curriculumId) {
  const url = `/api/curriculum/${curriculumId}/trainings/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getTeacherTraining(curriculumId) {
  const url = `/api/curriculum/${curriculumId}/trainings/?type=training`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getAvailableExamSessions(curriculumId, params) {
  const url = `/api/curriculum/${curriculumId}/exams/`;
  return axios
    .get(url, { params: { ...params } })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getLessonDetail(lessonId) {
  const url = `/api/curriculum/lesson/${lessonId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getLessonStandard(lessonId, standardGroupId) {
  const url = `/api/curriculum/lesson/${lessonId}/standards/${standardGroupId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getLessonOER(lessonId) {
  const url = `/api/curriculum/lesson/${lessonId}/oer/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSystemRequirements() {
  const url = '/api/curriculum/external-links/system-requirement';
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getExternalLink(linkSlug) {
  const url = `/api/curriculum/external-links/${linkSlug}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getLessonAttendance(lessonId, sectionId) {
  const payload = { section: sectionId };
  const url = `/api/curriculum/lesson/${lessonId}/attendance/`;
  return axios
    .get(url, { params: payload })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function submitLessonAttendance(lessonId, lessonAttendanceInfo) {
  const url = `/api/curriculum/lesson/${lessonId}/attendance/`;
  return axios.post(url, lessonAttendanceInfo).then((response) => ({ response }));
}

export function submitLessonSlide(lessonId, slideInfo) {
  const url = `/api/curriculum/lesson/${lessonId}/review/`;
  return axios
    .post(url, slideInfo)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateReviewStatus(lessonId, reviewId, reviewInfo) {
  const url = `/api/curriculum/lesson/${lessonId}/review/${reviewId}/`;
  return axios
    .put(url, reviewInfo)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getCurriculumLessonReviews({ params }) {
  const url = `/api/curriculum/lesson/review/`;
  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getCurriculumExamSections(curriculumId) {
  const url = `/api/curriculum/${curriculumId}/exam_sections/`;
  return axios.get(url).then((response) => response);
}

export function postSlideChange(body) {
  const url = `/api/curriculum/lesson-slide-events/`;
  return axios
    .post(url, body)
    .then((response) => response.data)
    .catch(handleError);
}
