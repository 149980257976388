/* eslint-disable import/prefer-default-export */
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors } from './sectionStudent';

export const useSectionStudent = () => {
  const { sectionId } = useParams();
  const studentList = useSelector((state) => selectors.getStudentList(state, sectionId));
  return studentList;
};

export type Student = {
  id: number;
  last_login: string;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  date_joined: string;
  grade_level: number;
  third_party_id: string | null;
};
export const useSectionStudentWithFilter = (): Student[] => {
  const { sectionId } = useParams();
  const filteredStudentList = useSelector((state) =>
    selectors.getStudentListWithFilter(state, sectionId),
  );
  return filteredStudentList;
};
