import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

type StartOLPModalProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  dataTestId?: string;
};

const ResetActivityDialog = ({
  open,
  onCancel,
  onConfirm,
  dataTestId,
}: StartOLPModalProps) => (
  <Dialog
    title="Reset Activity?"
    sx={{ '& .MuiPaper-root': { p: '1rem', pt: '0.75rem' } }}
    actions={[
      {
        label: 'No',
        onClick: onCancel,
        buttonProps: { variant: 'outlined', dataTestId: 'cancel-reset-olp' },
      },
      {
        label: 'Yes',
        onClick: onConfirm,
        buttonProps: { variant: 'contained', dataTestId: 'confirm-reset-olp' },
      },
    ]}
    open={open}
    onClose={onCancel}
    data-testid={dataTestId}
  >
    <Typography variant="body1" sx={{ textAlign: 'start' }}>
      Resetting this activity will create new default groups and reset All group progress.
      Do you want to continue?
    </Typography>
  </Dialog>
);

export default ResetActivityDialog;
