import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import AddLessonSessionStepThree from '../../components/ScheduleAddLessonSessionForm/AddLessonSessionStepThree';
import {
  selectors as sectionUnitSelectors,
} from '../../reducers/sectionUnit';
import { STANDARD_SESSION_TYPE } from '../../utils/constants';

const mapStateToProps = (state, { initialValues }) => {
  // const subjectIdList = _get(initialValues, 'subject', []).map(s => s.id);
  // const units = sectionUnitSelectors.getAllUnitOfSubjectIdList(state, subjectIdList);
  const subjectId = _get(initialValues, 'subject', '').id;
  const units = sectionUnitSelectors.getAllUnitOfSubjectId(state, subjectId);
  const { sessionType } = initialValues;
  let filterUnits = units;

  if (sessionType.value === STANDARD_SESSION_TYPE.UNIT_PRACTICE) {
    filterUnits = _filter(units, unit => _get(unit, 'meta.unit_practice', false));
  }

  return {
    units: filterUnits,
  };
};

export default withRouter(connect(mapStateToProps)(AddLessonSessionStepThree));
