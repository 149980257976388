import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { ROLE_TYPE } from '../../utils/enums';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';

const TeacherAuthRoute = ({
  role,
  authenticated,
  location,
  match,
  component: Component,
  path,
  ...rest
}) => (
  <Route
    path={path}
    {...rest}
    render={(props) => {
      if (role === ROLE_TYPE.INSTRUCTOR && authenticated) {
        return (
          <ChalkTalkErrorBoundary key={path}>
            <Component {...props} location={location} />
          </ChalkTalkErrorBoundary>
        );
      }

      const toObj = {
        pathname: '/',
        state: { from: location },
      };

      return <Redirect to={toObj} />;
    }}
  />
);

TeacherAuthRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
  role: PropTypes.string,
  location: PropTypes.any,
  authenticated: PropTypes.bool,
  match: PropTypes.any,
};

TeacherAuthRoute.defaultProps = {
  role: null,
  authenticated: false,
  component: null,
};

export default TeacherAuthRoute;
