import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Box from '@components/Atoms/Box';
import ShowIf from '@components/Atoms/ShowIf';
import Typography from '@components/Atoms/Typography';

import {
  cardHeaderContainerStyle,
  cardHeaderNoContentTextStyle,
} from './AttendanceCardHeader.style';
import { AttendanceRecords } from '../types';
import CardHeader from './CardHeader';

interface AttendanceCardHeaderProps {
  studentsList: AttendanceRecords;
  onLessonChange?: (lessonId: string | number, status) => void;
}

const AttendanceCardHeader = ({
  studentsList,
  onLessonChange,
}: AttendanceCardHeaderProps) => {
  const totalStudents = studentsList.length;
  const { courseId, sectionId } = useParams();
  const managementCoursePage = `/management/course/${courseId}/section/${sectionId}/`;

  return (
    <Box data-testid="attendance-card-header" sx={cardHeaderContainerStyle}>
      <CardHeader studentsList={studentsList} onLessonChange={onLessonChange} />

      <ShowIf If={totalStudents === 0}>
        <div>
          No students are enrolled.
          <Link to={managementCoursePage}>
            <Typography sx={cardHeaderNoContentTextStyle}>
              Visit your Course Management page to add them
            </Typography>
          </Link>
        </div>
      </ShowIf>
    </Box>
  );
};
export default AttendanceCardHeader;
