import { connect } from 'react-redux';
import _get from 'lodash/get';
import UnitPracticeReview from '../../components/UnitPracticeReview';
import {
  actions as PracticeActions,
  selectors as PracticeSelectors,
} from '../../reducers/practice';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';

const mapStateToProps = (state, { match, location }) => {
  const { params } = match;
  const { unitId } = params;
  const query = new URLSearchParams(_get(location, 'search', ''));
  const assetTypeQuery = query.get('asset_type');
  return {
    assetTypeQuery,
    report: PracticeSelectors.getReport(state),
    isInitialized: PracticeSelectors.isInitialized(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    currentUser: UserSelectors.getCurrentUser(state),
    currentUserId: UserSelectors.getUserId(state),
    lessons: SectionUnitSelectors.getAllLessonOfUnit(state, unitId),
  };
};

const mapDispatchToProps = {
  practiceSessionReport: PracticeActions.practiceSessionReport,
  initializePracticeSession: PracticeActions.initializePracticeSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPracticeReview);
