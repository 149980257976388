import React from 'react';
import PropTypes from 'prop-types';
import { OverlayWrap } from '../stylesheets/General.style';
import {
  ActionButtonContainer,
  ActionButtonContent,
  ActionButtonCircle,
} from './ActionButton.style';

const ActionButton = ({ isDropDownVisible, setIsDropDownVisible }) => (
  <ActionButtonContainer
    isDropDownVisible={isDropDownVisible}
    onClick={() => isDropDownVisible ? () => {} : setIsDropDownVisible()}
  >
    <ActionButtonContent>
      <ActionButtonCircle />
      <ActionButtonCircle />
      <ActionButtonCircle />
    </ActionButtonContent>
    <OverlayWrap isOpen={isDropDownVisible} onClick={setIsDropDownVisible} />
  </ActionButtonContainer>
);

ActionButton.propTypes = {
  isDropDownVisible: PropTypes.bool.isRequired,
  setIsDropDownVisible: PropTypes.func.isRequired,
};

export default ActionButton;
