import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import ExamSubStudentTable from '../../components/ExamSubStudentTable';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as examResultSelectors } from '../../reducers/examResult';
import { actions as sectionExamActions } from '../../reducers/sectionExam';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const examSessionId = _get(match, 'params.examSessionId', '');
  const courseId = _get(match, 'params.courseId', '');

  return {
    courseId,
    examSessionId,
    sectionId,
    students: sectionStudentSelectors.getStudentListWithFilter(state, sectionId),
    resultByUserId: examResultSelectors.getResultByUserId(state),
  };
};

const mapDispatchToProps = {
  sectionExamReset: sectionExamActions.sectionExamReset,
  sectionExamResetExamSection: sectionExamActions.sectionExamResetExamSection,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamSubStudentTable));
