import styled, { css } from 'styled-components';

export const scrollStyle = {
  height: '100%',
  width: '100%',
};

export const ClickAwayListenerStyle = {
    padding: '1px 5px',
};

export const FormStyles = {
  padding: '0 10px 10px 10px',
};
export const SelectStyles = {
  option: (provided, state) => ({
    ...provided,
    font: '12px MontserratLight',
    cursor: 'pointer',
    backgroundColor: state.isSelected ? '#ff9e16' : '#fffff',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    font: '12px MontserratLight',
  }),
  container: base => ({
    ...base,
    width: '154px',
    height: '25px',
    /* UI Properties */
    background: ' #E5E5E5 0% 0% no-repeat padding-box',
    border: '1px solid #7A8599',
    borderRadius: '4px',
    opacity: 1,
  }),
  control: (provided) => ({
    color: 'white',
    border: 0,
    borderRadius: '8px',
    caretColor: 'transparent',
    cursor: 'pointer',
    height: 25,
    lineHeight: 0,
    padding: '4px',
  }),
  placeholder: () => ({
    font: '12px MontserratLight',
    color: ' #010101',
  }),
  valueContainer: () => ({
    textAlign: 'center',
  }),
};

export const FormHeading = styled.p`
    display: inline;
    font-size: 1rem;
    font-weight: bold;
    font-family: MontserratLight;
    margin-bottom:8px;
`;
export const FormSubHeading = styled.p`
    font-size: 0.6rem;
    margin-top: 15px;
    font-family: MontserratLight;
    font-style: italic;
`;
export const InputGroup = styled.div`
    margin-top: 8px;
    margin-bottom: 8px;
    padding-top: 8px;
    display: grid;
    grid-template-columns: 18% 1fr;
    ${({ sittings }) => sittings && css`
        margin: 0px;
    `}
    .calendarInput {
        width: 316px;
        height: 25px;
        background-image: url('/images/calendarIcon.png');
        background-repeat: no-repeat;
        background-position: center right 5px;;
        border: 1px solid #7A8599;
        border-radius: 4px;
        padding: 5px;
    }
    .subOptions{
        margin-left: 28px;
        margin-top: -11px;
    }
`;
export const PopupFormLabel = styled.label`
    color: #010101;
    letter-spacing: 0px;
    font-size: 12px;
    align-self: center;
    text-align: right;
    font-family: MontserratRegular;
    margin-right: 15px;
    ${({ rightAlign }) => rightAlign && css`
    position: relative;
    left: 40px;
    `}
    ${({ paddingLeft }) => paddingLeft && css`
    padding-left: 20px;
    `}
`;
export const ButtonsGroup = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;

`;

export const GradeContainer = styled.div`
    margin: 0 5px;

`;

export const SelectButton = styled.button`
    font-family: MontserratLight;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 4px;
    height: fit-content;
    border:0;
    font-size: 0.7rem;
    padding: 5px 10px;
    color: ${props => (props.isSelected ? 'white' : 'black')};
    background-color: ${props => (props.isSelected ? '#4886FF' : '#E5E5E5')};
    justify-self: center;
    width : ${props => (props.isClassDays ? '56px' : props.isGradeLevels ? '88px' : 'auto')};
    align-self: center;
`;

export const CheckBox = styled.input`
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid #7A8599;
    appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
    cursor: pointer;
    justify-self: center;
    align-self: center;
    :checked {
        display:inline-block;
        background-image: url('/images/icon-svg/checkbox.svg');
        background-repeat: no-repeat;
        background-size: 1.3em;
        border: 1px solid #4886FF;
        background-position: center;
    }
    :disabled {
        border: 2px solid #7a859940;
    }
`;
export const BorderInput = styled.input`
  width: 316px;
  height: 25px;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  border: 1px solid #7a8599;
  border-radius: 4px;
  opacity: 1;
  padding: 5px;

`;

export const InputAddon = styled.div`
&:after {
  content: 'minutes';
  font-family: sans-serif;
  width: 1em;
  height: 1em;
  position: absolute;
  top: 42%;
  left: ${({ offset }) => `${(15 + offset * 6)}px`};
  display:  ${({ offset }) => offset ? 'block' : 'none'};
  transform: translateY(-50%);
}
`;

export const NonBorderInput = styled.input`
    width: 280px;
    height: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5px;
    border: none;
`;
export const DateParagraph = styled.p`
width: 20%;
`;
export const SectionHours = styled.div`
    position: sticky;
    left: 18%;
    width: 82%;
    height: 40px;
    display: flex;
    justify-content: center;
    gap: 22px;
    margin-top: 15px;
    background-color: #FFF8DF;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.7rem;
    text-align: center;
    padding: 12px 0;
    font-family: MontserratBold;
    left: 775px;
    div span {
        font-size: 1rem;
        margin-right: 3px;
    }
    strong {
        font-size: 0.9rem;
    }
`;
export const StyledSpan = styled.span`
    font: 0.6rem MontserratLight;
    padding: 0 5px;
    border-radius: 50%;
    color: white;
    background-color: #ff9e16;
    cursor: pointer; 
    margin: 5px;
`;
export const HolidayForm = styled.div`
    display: flex;
    height: 30px;
    border: 1px solid grey;
    padding: 3px 6px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    align-items: center;
    *{
        outline: none;
    }
    .react-datepicker-wrapper *{
        border: none;
    }
`;
export const TextInput = styled.input`
    border: 0;
    width: 60%
    
`;

export const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid grey;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 150px;
    position: relative;
    left: 18%;
    width: 82%;
    padding: 10px;
    margin-top: -3px;
    overflow-y: scroll;
    font: 0.8rem MontserratLight;
    ::-webkit-scrollbar {
        width: 14px;
    }
    ::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 8px;
        background-color: #ff9e16;
    }
`;
export const StyledButton = styled.button`
    font-family: MontserratLight;
    color: white;
    background-color: #ff9e16;
    border: 0;
    padding: 5px 15px;
    border-radius: 4px;
`;

export const StyledCalendarButton = styled.button`
    font-family: MontserratLight;
    color: white;
    background-color: #ff9e16;
    border: 0;
    width: 40px;
    height: 90%;
    background: #FF9E16 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    text-align: center;
    font: 12px MontserratLight;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
`;

export const OptionWithCheckBox = styled.div`
    width: fit-content;
    display: grid;
    grid-template-rows: ${props => (`repeat(${props.num}, 33px)`)};
    gap: 10px;
`;
export const Item = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    justify-content: space-between;

    .section-select__placeholder{
        color: white;
        font-size: 0.7rem;
        font-family: MontserratLight;
    }
    .holidayName{
        min-width: 46%
    }
    .section-select__single-value{
        color: white;
        font-size: 0.7rem;
        font-family: MontserratLight;
        overflow: visible;
    }
    .p{
        width:20%;
    }
`;

export const HolidayName = styled.div`
    width: 40%;
`;

export const BottomButtons = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
`;
export const Error = styled.div`
    color: red;
    display: inline;
    width: max-content;
`;
export const HiddenBlock = styled.div`
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 0;
    overflow: hidden;
`;
export const DetailText = styled.div`
    font-size: 0.6rem;
    font-family: MontserratLight;
    font-style: italic;
    position: relative;
    width: 78%;
    margin-top: 2px;
`;
export const PointerImage = styled.img`
    cursor: pointer;
`;

export const FlexGroup = styled.div`
    display: flex;
    gap: 10px;
`;

export const MenuHeaderStyle = styled.div`
    margin: 0;
    border: 0;
    font: 12px MontserratMedium;
    vertical-align: baseline;
    outline: none;
    color: inherit;
    display: block;
    padding: 8px 12px;
    width: 100%;
    font-weight: bold;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
`;

export const CloseButton = styled.button`
    position: absolute;
    right: 2%;
    background-color: white;
    border: 0px;
`;
export const tooltipText = {
    textAlign: 'left',
}
