import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { withRouter } from 'react-router-dom';
import LessonExamConfirmModal from '../ConfirmModal';
import Tooltip from '@mui/material/Tooltip';
import {
  StudentListHeaderWrap,
  StudentListWrap,
  StudentTableWrap,
  StudentListItemWrap,
  StudentListHead,
  StudentListItem,
  SortBtnWrap,
  StudentListButton,
} from './ExamSubStudentTableV2.style';
import { RESULT_STATUS, SECTION_STATUS } from '../../utils/enums';
import firstSort from '../../utils/firstSort';
import MediaQuery from 'react-responsive';

const AscendingIcon = (
  <i className="chalktalk-up-down-arrow" />
);

const DescendingIcon = (
  <i className="chalktalk-up-down-arrow" />
);

const headers = [
  {
    key: 'last_name',
    label: "Student's Name",
    isSortKey: true,
  },
  {
    key: 'startDate',
    label: 'Started',
    isSortKey: false,
  },
  {
    key: 'endDate',
    label: 'Submitted',
    isSortKey: false,
  },
  {
    key: 'answeredCount',
    label: 'Answered',
    isSortKey: true,
  },
  {
    key: 'result',
    label: 'Result',
    isSortKey: true,
  },
  {
    key: 'actions',
    label: '',
    isSortKey: false,
  },
];

class ExamSubStudentTableV2 extends PureComponent {
  state = {
    sortKey: 'last_name',
    isInc: true,
    isExamConfirmOpen: false,
    studentSelected: null,
  };

  static propTypes = {
    students: PropTypes.array,
    isExpanded: PropTypes.bool,
    resultByUserId: PropTypes.object,
    examSectionId: PropTypes.any,
    history: PropTypes.any,
    sectionId: PropTypes.any,
    examSessionId: PropTypes.any,
    courseId: PropTypes.any,
    sectionExamReset: PropTypes.func,
    sectionExamResetExamSection: PropTypes.func,
  };

  resetExamStudent = (studentId) => {
    const { sectionExamReset, examSessionId, examSectionId } = this.props;
    sectionExamReset(examSessionId, examSectionId, studentId);
  }

  resetStudentExamSection = (studentId) => {
    const { sectionExamResetExamSection, examSessionId, examSectionId } = this.props;
     // We use exam reset with get updated score here for the student view.
    sectionExamResetExamSection(examSessionId, examSectionId, studentId);
    this.onCloseModal();
  }

  redirectToReviewPage = (studentId) => {
    const {
      history, sectionId, examSessionId, courseId, examSectionId,
    } = this.props;

    history.push(`/test/course/${courseId}/section/${sectionId}/exam/${examSessionId}/review/${examSectionId}/student/${studentId}`);
  }

  onUpdateSort = (key) => {
    if (key !== null) {
      const { sortKey } = this.state;
      if (sortKey !== key) {
        this.setState({ sortKey: key, isInc: true });
      } else {
        this.setState(prevState => ({ isInc: !prevState.isInc }));
      }
    }
  }

  customOrderBy = (student) => {
    const { resultByUserId, examSectionId } = this.props;
    const { id, first_name: firstName, last_name: lastName } = student;
    const { sortKey } = this.state;

    if (sortKey === 'last_name') {
      return student.last_name.toUpperCase();
    }

    const sectionReportsOfStudent = _get(resultByUserId, `${id}.section_report`, []);

    const sectionReport = (sectionReportsOfStudent || []).find(({ section_id: sectionId }) => sectionId === examSectionId);

    if (sortKey === 'result') {
      const autoScore = _get(sectionReport, 'score.auto', 0);

      return autoScore;
    }
  }

  renderSortBtn = (key) => {
    const { sortKey, isInc } = this.state;
    const isCurrentSort = sortKey === key;

    return (
      <SortBtnWrap>
        {(isCurrentSort && isInc) && AscendingIcon}
        {(isCurrentSort && !isInc) && DescendingIcon}
        {!isCurrentSort && <i className="icon-default" />}
      </SortBtnWrap>
    );
  };

  renderHeaderItem = (item, index) => {
    const { key, label, isSortKey } = item;
    return (
      (key != 'answeredCount' && key != 'endDate' && key != 'startDate')
        ? (
          <StudentListHead
            key={key}
            onClick={() => this.onUpdateSort(key)}
            isSortKey={isSortKey}
            textAlign={index !== 0}
          >
            <span>{label}</span>
            {isSortKey && this.renderSortBtn(key)}
          </StudentListHead>
        )
        : (
          <StudentListHead
            key={key}
            isSortKey={isSortKey}
            textAlign={index !== 0}
          >
            <span>{label}</span>
            {isSortKey && this.renderSortBtn(key)}
          </StudentListHead>
        )
    );
  };

  renderStudentListHead = () => (
    <StudentListHeaderWrap>
      {headers.map(this.renderHeaderItem)}
    </StudentListHeaderWrap>
  );

  onCloseModal = () => {
    this.setState({
      isExamConfirmOpen: false,
      studentSelected: null,
    });
  }

  showConfirmModal(student_id) {
    this.setState({
      isExamConfirmOpen: true,
      studentSelected: student_id,
    });
  }

  renderStudentItem = (student) => {
    const { resultByUserId, examSectionId, examSessionId } = this.props;
    const {
      id,
      first_name: firstName,
      last_name: lastName,
    } = student;
    const studentName = `${firstName} ${lastName}`;

    const sectionReportsOfStudent = _get(resultByUserId, `${id}.section_report`, []);
    const getSectionMetaByUserId = _get(resultByUserId, `${id}.section_meta`, []);
    const sectionReport = (sectionReportsOfStudent || []).find(({ section_id: sectionId }) => sectionId == examSectionId);

    let scoreDisplay = '-/-';
    let shouldDisplayReviewButton = false;
    let shouldDisplayresetButton = false;
    let metaDataFlag = false;

    const status = _get(resultByUserId, `${id}.status`, '');
    const sectionMetaOfResult = _get(resultByUserId, `${id}.section_meta`, []).find(item => item.section === examSectionId);
    const endedTime = _get(sectionMetaOfResult, 'ended', null);
    const startedTime = _get(sectionMetaOfResult, 'started', null);

    if (sectionReport) {
      const { score: { auto, auto_max: autoMax } } = sectionReport;
      scoreDisplay = `${Math.round(auto)}/${Math.round(autoMax)}`;
      shouldDisplayReviewButton = true;
      shouldDisplayresetButton = true;
    }

    if (endedTime) {
      shouldDisplayresetButton = true;
    }

    const ended = moment(endedTime).format('YYYY-MM-DD hh:mm');
    let endedMob = moment(endedTime).format('YYYY-M-D h:mm');
    let started = moment(startedTime).format('YYYY-MM-DD hh:mm');
    let startedMob = moment(startedTime).format('YYYY-M-D h:mm');
    let answered = _get(sectionMetaOfResult, 'answered', '-');

    const shouldDisplayPendingButton = !shouldDisplayReviewButton && status === RESULT_STATUS.PENDING && endedTime;
    let shouldDisplayInProgressButton = !shouldDisplayReviewButton && startedTime && !endedTime;

    if (shouldDisplayPendingButton) scoreDisplay = "Calculating..."
    else if (shouldDisplayInProgressButton) scoreDisplay = "In Progress"
    else if (this.props.sectionStatus != SECTION_STATUS.LOCKED && !startedTime) scoreDisplay = "Not Started"

    if (answered == 0 && !endedTime) {
      shouldDisplayInProgressButton = false;
      answered = '-';
      started = '-';
      scoreDisplay = '-/-';
    }
    let resetMsg = 'Reset the exam and delete the score for this student. Their answers will be saved for when they start again';
    if (!shouldDisplayReviewButton) {
      resetMsg = 'The exam cannot be reset until it has been submitted by the student';
    }
    if (metaDataFlag) {
      resetMsg = 'This student score cannot be reset from this section';
    }

    let canResetTime = true;
    if (this.props.sectionStatus === SECTION_STATUS.PAUSED || this.props.sectionStatus === SECTION_STATUS.LOCKED) {
      canResetTime = false;
    }

    return (
      <MediaQuery key={`student-list-wrapper-${id}`} maxWidth={768}>
        {matches => (
          <StudentListItemWrap>
            <StudentListItem>{studentName}</StudentListItem>
            <StudentListItem>
              {startedTime
                ? matches ? startedMob : started : '-'}
            </StudentListItem>
            <StudentListItem>
              {endedTime
                ? matches ? endedMob : ended : '-'}
            </StudentListItem>
            <StudentListItem>{answered}</StudentListItem>
            <StudentListItem>{scoreDisplay}</StudentListItem>
            <StudentListItem groupAction>
              {shouldDisplayReviewButton && (
                <Tooltip title="Review this student’s answers" placement="top">
                  <StudentListButton
                    smallest
                    outlined
                    onClick={() => this.redirectToReviewPage(id)}
                  >
                    Review
                  </StudentListButton>
                </Tooltip>
              )}
              <Tooltip title={endedTime ? 'This student has already submitted their exam and cannot receive extra time' : 'Reset this student’s exam timer. Their answers will be saved'} placement="top">
                <StudentListButton
                  onClick={() => !endedTime && canResetTime && this.resetExamStudent(id)}
                  smallest
                  noBackground
                  outlined
                  hide={!!endedTime || !canResetTime || !startedTime}
                >
                  Extend Timer
                </StudentListButton>
              </Tooltip>
              <Tooltip title={resetMsg} placement="top">
                <StudentListButton
                  hide={!shouldDisplayresetButton}
                  onClick={() => shouldDisplayresetButton && this.showConfirmModal(id)}
                  smallest
                  noBackground
                  outlined
                >
                  Reset
                </StudentListButton>
              </Tooltip>
            </StudentListItem>
          </StudentListItemWrap>
        )}
      </MediaQuery>
    );
  };

  renderExamConfirmModal() {
    const { studentSelected, isExamConfirmOpen } = this.state;
    return (
      <LessonExamConfirmModal
        isOpen={isExamConfirmOpen}
        onClose={this.onCloseModal}
        onConfirm={() => this.resetStudentExamSection(studentSelected)}
        content="Are you sure you want to reset this student’s exam? The exam will be reset and their score will be deleted. (All responses will be saved.)"
      />
    );
  }

  render() {
    const {
      students, isExpanded,
    } = this.props;
    const { isInc } = this.state;
    firstSort(students);

    const studentsFilter = _orderBy(students, this.customOrderBy, isInc ? 'asc' : 'desc');

    return (
      <StudentTableWrap isExpanded={isExpanded}>
        {this.renderStudentListHead()}
        {this.renderExamConfirmModal()}
        <StudentListWrap>
          {studentsFilter.map(this.renderStudentItem)}
        </StudentListWrap>
      </StudentTableWrap>
    );
  }
}

export default withRouter(ExamSubStudentTableV2);
