import styled, { css } from 'styled-components';

export const RecordAttendanceTabWrap = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RecordAttendanceWrap = styled.div`
  flex: 1;
`;

export const RecordAttendanceTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ m }) => m || '1rem 1rem 2rem'};
  font-family: 'MontserratSemiBold';
  border-radius: 0.375rem;
  background: ${({ background }) => background || 'transparent'};
  padding: 0.5rem;
  i {
    margin-right: 0.5em;
    font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
    color: ${props => (props.yellow ? props.theme.colorStyled.ColorYellow : 'inherit')};
  }
`;

export const RecordAttendanceTableWrap = styled.div`
  padding: 1em;
`;

export const LessonListContainer = styled.div`
  flex: 0 0 30%;
  max-width: 22em;
  margin-right: 1.5em;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  transition: all 300ms ease;
  @media (max-width: 1170px) {
    flex: 0 0 12em;
  }
  @media (max-width: 768px) {
    overflow: scroll;
  }
`;

export const LessonListWrap = styled.div`
  border-radius: .325em;
`;

// session

export const SessionItemWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 1em;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorderInput};
  border-left: 1.5px solid ${props => props.theme.colorStyled.ColorBgDefault};
  background-color: #f3f3f3;
  cursor: pointer;
  &:last-child { 
    border-bottom: none;
  }
  &:hover {
    background-color: ${props => props.theme.colorStyled.ColorWhite};
  }
  ${({ isSelected }) => isSelected && css`
    background-color: ${props => props.theme.colorStyled.ColorWhite};
    border-left: 3px solid ${props => props.theme.colorStyled.ColorPrimaryBlue};
  `}
`;

export const SessionStatus = styled.div`
  font-size: ${props => props.theme.fontSize.HeadingH2FontSize};
  background-color: #ddd;
  border-radius: 100%;
  margin-right: 1em;
`;

export const SessionContentGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: auto;
  div[data-tip] {
    border-bottom: 1px dashed ${props => props.theme.colorStyled.ColorTime};
  }
`;

export const SessionStatusIcon = styled.div`
  margin-right: .5em;
  font-size: ${props => props.theme.fontSize.HeadingH1FontSize};
`;

export const SessionContent = styled.div`
  span {
    color: ${props => props.theme.colorStyled.ColorTime};
    font-size: ${props => props.theme.fontSize.MediumFontSize};
  }
`;

export const SessionScorePercent = styled.div`
  margin-left: auto;
  text-align: center;
  span {
    color: ${props => props.theme.colorStyled.ColorTime};
    font-size: ${props => props.theme.fontSize.MediumFontSize};
  }
`;

export const SessionActivePercent = styled(SessionScorePercent)`
  margin-left: .75em;
`;

export const SessionDate = styled.div``;

export const SaveRecordButton = styled.button`
  border: none;
  background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  color:${props => props.theme.colorStyled.ColorWhite};
  border-radius: .325em;
  padding: .5em 1.5em;
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  font-family: 'MontserratMedium';
  cursor: pointer;
  &:hover {
    opacity: .6;
  }
`;
