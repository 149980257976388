import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import QuestionItem from '../QuestionItem';
import QuestionAssets from '../../containers/QuestionAssets';
import AnswerExplanation from '../../containers/AnswerExplanation';
import {
  PracticeContentWrap,
  PracticeContentGroup,
  PracticeFooterBar,
  ProgressBarWrapper,
  ProgressBarItem,
  ProgressBarLine,
  ProgressBarCircle,
} from '../PracticePage/PracticePage.style';

class ReviewQuestionContainersDesktop extends React.PureComponent {
  renderQuestionAssets = () => {
    const { questions, questionSelected, openReportErrorModal } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(
      questionSelectedObj,
      'exam_question.question.shared_assets',
      [],
    );
    const questionId = _get(questionSelectedObj, 'exam_question.id');

    const isAssetEmpty = assets.length === 0;
    if (isAssetEmpty) {
      return null;
    }

    return (
      <QuestionAssets
        hasFooter
        assets={assets}
        openReportErrorModal={openReportErrorModal}
        questionId={questionId}
      />
    );
  };

  renderProgressBar = (currentQuestion, index) => {
    const { questionSelected, selectQuestion, questions } = this.props;
    const numberQuestion = questions.length;
    return (
      <ProgressBarItem
        isActive
        isSelected={questionSelected === index}
        widthValue={numberQuestion}
        firstItem={!index}
        key={index}
      >
        <ProgressBarLine />
        <ProgressBarCircle onClick={selectQuestion(index, true)} />
      </ProgressBarItem>
    );
  };

  render() {
    const {
      questions,
      isPracticeNavigation,
      questionSelected,
      nextQuestion,
      prevQuestion,
      openReportErrorModal,
    } = this.props;
    const questionSelectedObj = questions[questionSelected];

    const questionOrder = _get(
      questionSelectedObj,
      'exam_question.order',
      null,
    );
    const explanation = _get(
      questionSelectedObj,
      'exam_question.question.explanation',
      {},
    );
    const questionId = _get(questionSelectedObj, 'exam_question.id');

    const totalQuestions = questions.length;

    if (!totalQuestions) {
      return (
        <PracticeContentWrap>
          This practice don&#39;t have anything for you to review
        </PracticeContentWrap>
      );
    }

    return (
      <React.Fragment>
        <PracticeContentWrap>
          {this.renderQuestionAssets()}
          <PracticeContentGroup isToggleNavigation={isPracticeNavigation}>
            <QuestionItem
              questionSelected={questionSelectedObj}
              totalQuestions={totalQuestions}
              nextQuestion={nextQuestion}
              prevQuestion={prevQuestion}
              index={questionSelected}
              order={questionOrder}
              openReportErrorModal={openReportErrorModal}
              questionId={questionId}
            />
            <AnswerExplanation
              explanation={explanation}
              questionId={questionId}
              openReportErrorModal={openReportErrorModal}
            />
          </PracticeContentGroup>
        </PracticeContentWrap>

        <PracticeFooterBar>
          <ProgressBarWrapper>
            {questions.map(this.renderProgressBar)}
          </ProgressBarWrapper>
        </PracticeFooterBar>
      </React.Fragment>
    );
  }
}

ReviewQuestionContainersDesktop.propTypes = {
  questions: PropTypes.array,
  isPracticeNavigation: PropTypes.bool,
  questionSelected: PropTypes.number,
  nextQuestion: PropTypes.func.isRequired,
  prevQuestion: PropTypes.func.isRequired,
  selectQuestion: PropTypes.func.isRequired,
  openReportErrorModal: PropTypes.func,
};

export default ReviewQuestionContainersDesktop;
