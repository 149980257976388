import React from 'react';
import TableWithWrapper from '@Atoms/Table/TableWithWrapper';

import { BlockTitle, BlockWrap } from '@components/Generals/stylesheets/General.style';
import { useExamScoreColumns, useExamScoreRows } from './ExamScoreTable.hooks';

const ExamScoreTable = () => {
  const columns = useExamScoreColumns();
  const rows = useExamScoreRows();

  if (!rows.length) {
    return null;
  }

  return (
    <BlockWrap noPadding>
      <BlockTitle>Test Scores</BlockTitle>
      <TableWithWrapper columns={columns} rows={rows} hasPagination />
    </BlockWrap>
  );
};

export default ExamScoreTable;
