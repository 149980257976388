interface Option {
  categoryName: string;
  label: string;
  name: string;
}

interface Params {
  inputValue: string;
}

export const wordsSearch = (options: Option[], params: Params): Option[] => {
  const words = params.inputValue
    .trim()
    .replace(/[^a-zA-Z0-9.\s]/g, '')
    .split(/\s+/);

  const regexPattern = words.map((word) => `(?=.*${word})`).join('');
  const regex = new RegExp(regexPattern, 'i');

  return options.filter((option) => {
    const searchText = option.label || option.name || '';
    return regex.test(searchText);
  });
};

// remove html, unicode, and other special characters
export const cleanText = (text: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  const cleaned = doc.documentElement.textContent;
  return cleaned
    ?.replace(/\r?\n|\r/g, '')
    ?.replace(/ {3,}/g, '  ')
    ?.trim();
};
