/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MediaQuery from "react-responsive";
import BreadcrumbsSub from "../../containers/BreadcrumbsSub";
import {
  BreadcumbsFilterWrap,
  BreadcumbsWrap,
  BreadcumbsGroup,
  BreadcumbsItems,
  FilterGroup,
  FilterDropDownGroup,
  FilterLabel,
  FilterIcon,
  FilterOptionGroup,
  FilterOptionItems,
  FilterOptionItemCustomize,
  FilterOptionStudentWrap,
  FilterOptionGradeLevelWrap,
  LabelStudentName,
} from "./BreadcumbsFilter.style";
import { CheckboxCustomize } from "../../stylesheets/CheckBoxCustomize.style";
import {
  OverlayWrap,
  TitlePageWrap,
} from "../Generals/stylesheets/General.style";
import OpenSider from '../InstructionalWalkThroughs/Components/OpenSider';

export default class BreadcumbsFilter extends PureComponent {
  static propTypes = {
    breadcumbList: PropTypes.arrayOf(PropTypes.string),
    breadcumbSubList: PropTypes.arrayOf(PropTypes.string),
    isTeacher: PropTypes.bool,
    studentList: PropTypes.arrayOf(PropTypes.any),
    studentFilter: PropTypes.arrayOf(PropTypes.any),
    sectionStudentFilterAdd: PropTypes.func,
    sectionStudentFilterAddAllOrCancel: PropTypes.func,
    haveSub: PropTypes.bool,
    mobileTitle: PropTypes.string,
    gradeLevels: PropTypes.array,
    sectionsStudentFilterGradeLevel: PropTypes.func,
    sectionsStudentSelectAllOrCancelGradeLevel: PropTypes.func,
    gradeLevelFilterList: PropTypes.array,
  };

  static defaultProps = {
    breadcumbList: [],
  };

  state = {
    isDropdownOpen: false,
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  renderBreadcumbs = () => {
    const { breadcumbList } = this.props;
    return (
      <BreadcumbsGroup>
        {breadcumbList.map((breadcumb, index) => (
          <BreadcumbsItems
            current={index + 1 === breadcumbList.length}
            key={index}
          >
            <span>{breadcumb}</span>
            {!(index + 1 === breadcumbList.length) && (
              <span className="chalktalk-icon-next"></span>
            )}
          </BreadcumbsItems>
        ))}
      </BreadcumbsGroup>
    );
  };

  selectStudentToFilter = (id) => {
    const { sectionStudentFilterAdd } = this.props;
    sectionStudentFilterAdd(id);
  };

  selectAllOrCancelAllStudentToFilter = (array) => {
    const { sectionStudentFilterAddAllOrCancel } = this.props;
    sectionStudentFilterAddAllOrCancel(array);
  };

  selectGradeLevel = (gradeLevel) => {
    const { sectionsStudentFilterGradeLevel } = this.props;

    sectionsStudentFilterGradeLevel(gradeLevel);
  };

  sectionsStudentSelectAllOrCancelGradeLevel = (array) => {
    const { sectionsStudentSelectAllOrCancelGradeLevel } = this.props;
    sectionsStudentSelectAllOrCancelGradeLevel(array);
  };

  ordinalSuffixOf = (i) => {
    const j = i % 10;
    const k = i % 100;
    if (j == 1 && k != 11) {
      return `${i}st`;
    }
    if (j == 2 && k != 12) {
      return `${i}nd`;
    }
    if (j == 3 && k != 13) {
      return `${i}rd`;
    }
    return `${i}th`;
  };

  renderStudentList = (values, index) => {
    const { studentFilter } = this.props;
    const { last_name: lastName, first_name: firstName, id } = values;
    const studentName = `${firstName} ${lastName}`;
    const checked = studentFilter.indexOf(id);
    return (
      <FilterOptionItems key={index}>
        <CheckboxCustomize>
          <input
            type="checkbox"
            checked={checked !== -1}
            id={id}
            onClick={() => this.selectStudentToFilter(id)}
          />
          <LabelStudentName htmlFor={id}>{studentName}</LabelStudentName>
        </CheckboxCustomize>
      </FilterOptionItems>
    );
  };

  renderGradeLevelItem = (item, index) => {
    const { gradeLevelFilterList = [] } = this.props;
    const { grade_level: gradeLevel } = item;
    const isChecked = !!gradeLevelFilterList.find(
      (level) => level === gradeLevel
    );
    const suffixGradeLevel = this.ordinalSuffixOf(item.grade_level);

    return (
      <FilterOptionItems key={index}>
        <CheckboxCustomize>
          <input
            type="checkbox"
            checked={isChecked}
            id={gradeLevel}
            onClick={() => this.selectGradeLevel(gradeLevel)}
          />
          <LabelStudentName htmlFor={gradeLevel}>
            {`${suffixGradeLevel} Grade`}
          </LabelStudentName>
        </CheckboxCustomize>
      </FilterOptionItems>
    );
  };

  renderFilterAction = (ids) => (
    <FilterOptionItemCustomize>
      <CheckboxCustomize
        onClick={() => this.selectAllOrCancelAllStudentToFilter(ids)}
      >
        Select all
      </CheckboxCustomize>
      <CheckboxCustomize
        onClick={() => this.selectAllOrCancelAllStudentToFilter([])}
      >
        Clear
      </CheckboxCustomize>
    </FilterOptionItemCustomize>
  );

  renderFilterGradeLevel = () => {
    const { gradeLevels } = this.props;
    const gradeLevelValue = gradeLevels.map((item) => item.grade_level);

    return (
      <FilterOptionGradeLevelWrap>
        {this.renderGradeFilterAction(gradeLevelValue)}
        {gradeLevels.map(this.renderGradeLevelItem)}
      </FilterOptionGradeLevelWrap>
    );
  };

  renderGradeFilterAction = (gradeLevels) => (
    <FilterOptionItemCustomize>
      <CheckboxCustomize
        onClick={() =>
          this.sectionsStudentSelectAllOrCancelGradeLevel(gradeLevels)
        }
      >
        Select all
      </CheckboxCustomize>
      <CheckboxCustomize
        onClick={() => this.sectionsStudentSelectAllOrCancelGradeLevel([])}
      >
        Clear
      </CheckboxCustomize>
    </FilterOptionItemCustomize>
  );

  renderFilterStudent = () => {
    const { studentList = [] } = this.props;
    const ids = studentList.map((x) => x.id);

    return (
      <FilterOptionStudentWrap>
        {this.renderFilterAction(ids)}
        {studentList.map(this.renderStudentList)}
      </FilterOptionStudentWrap>
    );
  };

  renderFilterTab = () => (
    <Tabs>
      <TabList>
        <Tab>Grade Level</Tab>
        <Tab>Students</Tab>
      </TabList>

      <TabPanel>{this.renderFilterGradeLevel()}</TabPanel>
      <TabPanel>{this.renderFilterStudent()}</TabPanel>
    </Tabs>
  );

  renderFilter = () => {
    const { isDropdownOpen } = this.state;
    const { studentFilter, gradeLevelFilterList } = this.props;

    return (
      <FilterGroup>
        <FilterDropDownGroup>
          <FilterLabel onClick={this.toggleDropdown}>
            <FilterIcon src="/images/filter.svg" />
            <span>Filter Students </span>
            <div>
              {`(${studentFilter.length || gradeLevelFilterList.length})`}
            </div>
          </FilterLabel>
          {isDropdownOpen
            && (
              <React.Fragment>
                <FilterOptionGroup>
                  {this.renderFilterTab()}
                </FilterOptionGroup>
                <OverlayWrap isOpen={isDropdownOpen} onClick={this.toggleDropdown} />
              </React.Fragment>
            )}
        </FilterDropDownGroup>
      </FilterGroup>
    );
  };

  render() {
    const {
      isTeacher,
      breadcumbSubList,
      haveSub,
      mobileTitle,
      isDisplayFilter = true,
      isForIw = false,
    } = this.props;

    return (
      <BreadcumbsFilterWrap>
        <MediaQuery minWidth={960}>
          {(matches) =>
            matches ? (
              <BreadcumbsWrap>
                {haveSub && (
                  <BreadcrumbsSub breadcumbSubList={breadcumbSubList || []} />
                )}
                {this.renderBreadcumbs()}
              </BreadcumbsWrap>
            ) : (
              <TitlePageWrap>{mobileTitle}</TitlePageWrap>
            )
          }
        </MediaQuery>
        {isTeacher && isDisplayFilter && this.renderFilter()}
        {isForIw && <OpenSider />}
      </BreadcumbsFilterWrap>
    );
  }
}
