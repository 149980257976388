import { connect } from 'react-redux';
import _get from 'lodash/get';
import UnitPractice from '../../components/UnitPractice';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as SectionSelectors, actions as sectionActions } from '../../reducers/sections';
import { actions as SectionStudentActions } from '../../reducers/sectionStudent';
import { actions as ActivityActions } from '../../reducers/activity';
import {
  actions as LeftSideBarAction,
  selectors as LeftSideBarSelectors,
} from '../../reducers/leftSideBar';
import { ROLE_TYPE } from '../../utils/enums';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', null);
  const unitId = _get(match, 'params.unitId', null);
  const subjectId = _get(match, 'params.subjectId', null);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);
  const shouldDisplayAdd = UserSelectors.getUserRole(state) !== ROLE_TYPE.STUDENT;
  const isTeacher = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR;

  return {
    sectionId,
    unitId,
    isTeacher,
    shouldDisplayAdd,
    courseDetail,
    sectionDetail,
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    subject: SectionSubjectSelectors.getSubjectDetail(state, subjectId),
    unitDetail: SectionUnitSelectors.getUnitById(state, unitId),
  };
};

const mapDispatchToProps = {
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
  toggleLessonSessionNavbar: LeftSideBarAction.toggleLessonSessionNavbar,
  getAllLessonActivity: ActivityActions.getAllLessonActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPractice);
