/* eslint-disable eqeqeq */
import _uniqBy from 'lodash/uniqBy';
import { createSelector } from 'reselect';

export const GET_ALL_USER_PRACTICE_SESSION_ACTIVITY = 'userActivities/GET_ALL_USER_PRACTICE_SESSION_ACTIVITY';
export const GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_SUCCESS = 'userActivities/GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_SUCCESS';
export const GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_FAIL = 'userActivities/GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_FAIL';

export const GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY = 'userActivities/GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY';
export const GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_SUCCESS = 'userActivities/GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_SUCCESS';
export const GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_FAIL = 'userActivities/GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_FAIL';

const getAllPracticeSessionActivities = (sectionId, unitId, lessonId, subjectId, reviewId) => ({
  type: GET_ALL_USER_PRACTICE_SESSION_ACTIVITY,
  sectionId,
  unitId,
  lessonId,
  subjectId,
  reviewId,
});

const getAllPracticeSessionActivitiesSuccess = userActivities => ({
  type: GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_SUCCESS,
  userActivities,
});

const getAllPracticeSessionActivitiesFailed = errorMsg => ({
  type: GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_FAIL,
  errorMsg,
});

// =========================================

const getAllPracticeSessionReportActivities = (sectionId, unitId, lessonId) => ({
  type: GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY,
  sectionId,
  unitId,
  lessonId,
});

const getAllPracticeSessionReportActivitiesSuccess = practiceReportActivities => ({
  type: GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_SUCCESS,
  practiceReportActivities,
});

const getAllPracticeSessionReportActivitiesFailed = errorMsg => ({
  type: GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_FAIL,
  errorMsg,
});

export const actions = {
  getAllPracticeSessionActivities,
  getAllPracticeSessionActivitiesSuccess,
  getAllPracticeSessionActivitiesFailed,

  getAllPracticeSessionReportActivities,
  getAllPracticeSessionReportActivitiesSuccess,
  getAllPracticeSessionReportActivitiesFailed,
};

// =========================================

const getUserPracticeSessionActivities = ({ userActivities }) => userActivities.userPracticeSessionActivities;
const getUserPracticeSessionActivitiesWithUnitId = ({ userActivities }, sectionId, unitId) => (
  (userActivities.userPracticeSessionActivities || [])
    .filter(
      activity => (
        activity.unit == unitId
        && activity.section == sectionId
      ),
    )
);
const getUserUnitPracticeSessionActivities = (state, sectionId, unitId) => (
  getUserPracticeSessionActivitiesWithUnitId(state, sectionId, unitId).filter(activity => !activity.lesson)
);
const getUserPracticeSessionActivitiesWithData = ({ userActivities }, sectionId, unitId, lessonId) => (
  userActivities.userPracticeSessionActivities
    .filter(
      activity => (
        activity.unit == unitId
    && activity.lesson == lessonId
    && activity.section == sectionId
      ),
    )
);

// =========================================

const getPracticeReportActivities = ({ userActivities }) => userActivities.practiceReportActivities;
const getUniquePracticeReportActivities = createSelector(
  getPracticeReportActivities,
  reportList => _uniqBy(reportList, 'exam_question.id'),
);
const getPracticeReportUserAnswerList = createSelector(
  getPracticeReportActivities,
  reportList => reportList.reduce((acc, report) => {
    const { user, exam_question: examQuestion, question_response: questionRes } = report;
    const examId = examQuestion.id;
    const currentChoice = questionRes.choices ? questionRes.choices[0] : null;
    let currentText = questionRes.text ? questionRes.text : null;

    if (currentText) {
      try {
        currentText = currentText.replace(/'/g, '"');
        currentText = JSON.parse(currentText)[0] || currentText; // eslint-disable-line
      } catch (error) {
        // skip
      }
    }

    const currentAnswer = currentChoice || currentText;

    if (!acc[examId]) acc[examId] = {};
    if (!acc[examId][currentAnswer]) acc[examId][currentAnswer] = [];
    acc[examId][currentAnswer].push(user);

    return acc;
  }, {}),
);

// =========================================

const isGettingUserPracticeSessionActivities = ({ userActivities }) => userActivities.isGetting;
const isGettingPracticeSessionReportActivities = ({ userActivities }) => userActivities.isGettingReport;

export const selectors = {
  getUserPracticeSessionActivities,
  getUserUnitPracticeSessionActivities,
  getUserPracticeSessionActivitiesWithUnitId,
  getUserPracticeSessionActivitiesWithData,

  getPracticeReportActivities,
  getUniquePracticeReportActivities,
  getPracticeReportUserAnswerList,

  isGettingPracticeSessionReportActivities,
  isGettingUserPracticeSessionActivities,
};

const initialState = {
  userPracticeSessionActivities: [],
  practiceReportActivities: [],
  isGetting: false,
  errorMsg: null,

  isGettingReport: false,
  reportErrorMsg: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_USER_PRACTICE_SESSION_ACTIVITY: {
      return {
        ...state,
        isGetting: true,
        errorMsg: null,
      };
    }

    case GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_SUCCESS: {
      const { userActivities } = action;

      return {
        ...state,
        isGetting: false,
        userPracticeSessionActivities: userActivities,
      };
    }

    case GET_ALL_USER_PRACTICE_SESSION_ACTIVITY_FAIL: {
      const { errorMsg } = action;

      return {
        ...state,
        isGetting: false,
        errorMsg,
      };
    }

    // ============================================

    case GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY: {
      return {
        ...state,
        isGettingReport: true,
        reportErrorMsg: null,
      };
    }

    case GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_SUCCESS: {
      const { practiceReportActivities } = action;

      return {
        ...state,
        isGettingReport: false,
        practiceReportActivities,
      };
    }

    case GET_ALL_PRACTICE_SESSION_REPORT_ACTIVITY_FAIL: {
      const { errorMsg } = action;

      return {
        ...state,
        isGettingReport: false,
        reportErrorMsg: errorMsg,
      };
    }

    default: return state;
  }
}
