/* eslint-disable import/prefer-default-export */
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getSyllabusAlignment } from '@apis/syllabus';
import { useCourseById } from '@reducers/courses/hooks';

export const useGetSyllabusAlignment = (curriculumsubjectId: number[]) => {
  const { courseId } = useParams();
  const courseDetails = useCourseById(courseId);

  const queryResponse = useQuery(
    ['useGetSyllabusAlignment', courseDetails.syllabus, curriculumsubjectId],
    async () => getSyllabusAlignment(courseDetails.syllabus, curriculumsubjectId),
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!courseId,
      staleTime: 1000 * 60 * 60 * 24,
    },
  );

  return queryResponse;
};
