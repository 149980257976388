import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import debounce from 'lodash/debounce';
import _get from 'lodash/get';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import { FormGroup } from '../Generals/stylesheets/Form.style';
import {
  RegisterFormWrapper,
  RegisterFormTitle,
  RegisterFormInput,
  RegisterAction,
  RegisterErrorMessage,
  ButtonStyled,
  RegisterFormDetail,
  DetailText,
  RegisterSubFormWrap,
  ExamFormFieldsWrapper,
  ExamFormFieldsDesc,
  ExamFormFieldsSection,
} from '../StudentRegister/StudentRegister.style';

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const roleOption = [
  createOption('ELA Teacher'),
  createOption('STEM Teacher'),
  createOption('Head of ELA Department'),
  createOption('Head of STEM Department'),
  createOption('School Administrator'),
  createOption('District Administrator'),
];

const validationSchema = Yup.object().shape({
  role: Yup.string().trim().required('Required'),
  country: Yup.object().required('Required'),
  schoolName: Yup.string().trim().required('Required'),
  district: Yup.object().test({
    name: 'test-district',
    message: 'Required',
    test(value) {
      const country = this.resolve(Yup.ref('country'));
      const countryCode = _get(country, 'code', null);

      if (countryCode !== 'US') {
        return true;
      }

      return !!value;
    },
  }),
  courseName: Yup.string().trim()
    .max(50)
    .required('Required'),
  courseId: Yup.string().trim().required('Required'),
  standardId: Yup.string().trim().required('Required'),
  examType: Yup.string().trim().required('Required'),
});

export default class CourseSetupForm extends Component {
  static propTypes = {
    fetchDistrictList: PropTypes.func.isRequired,
    teacherDetail: PropTypes.object,
    errorMessage: PropTypes.any,
    createRegCourse: PropTypes.func,
    country: PropTypes.array,
    district: PropTypes.array,
    standard: PropTypes.array,
    curriculum: PropTypes.array,
    subscriptionData: PropTypes.object,
    fetchCountryList: PropTypes.func,
    getCurriculumList: PropTypes.func,
    getCurriculumStandardList: PropTypes.func,
    getCurriculumPlacementType: PropTypes.func.isRequired,
    placementTypesObj: PropTypes.shape(),
  }

  static defaultProps = {
    country: [],
    district: [],
    standard: [],
    curriculum: [],
    subscriptionData: {},
    placementTypesObj: {},
  }

  constructor(props) {
    super(props);
    this.debouncedHandleInputChange = debounce(this.handleInputChange, 500);
  }

  state = {
    options: roleOption,
    value: '',
    currId: null,
  }

  componentDidMount() {
    const { fetchCountryList, getCurriculumList, getCurriculumStandardList } = this.props;

    fetchCountryList();
    getCurriculumList();
    getCurriculumStandardList();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currId } = this.state;
    const { currId: prevCurrId } = prevState;
    const { getCurriculumPlacementType } = this.props;

    if (currId !== prevCurrId) {
      getCurriculumPlacementType(currId);
    }
  }

  handleRoleChange = (form, fieldName) => (newValue) => {
    this.setState({ value: newValue });
    form.setFieldValue(fieldName, newValue);
  };

  handleCreate = (inputValue) => {
    setTimeout(() => {
      const { options } = this.state;
      const newOption = createOption(inputValue);
      this.setState({
        options: [...options, newOption],
        value: newOption,
      });
    }, 1000);
  };

  handleInputChange = (values) => {
    const { fetchDistrictList } = this.props;
    fetchDistrictList(values);
  }

  handleCountryOnChange = (value, formProps) => {
    const { values } = formProps;
    const newValues = {
      ...values,
      country: value,
      district: '',
    };

    formProps.setValues(newValues);
  }

  renderFieldRole = (fieldProps) => {
    const {
      form, field, label,
    } = fieldProps;
    const { name: fieldName } = field;
    const { options, value } = this.state;
    return (
      <FormInput
        name={fieldName}
        type="createselect"
        label={label}
        options={options}
        isClearable
        onChange={this.handleRoleChange(form, fieldName)}
        value={value}
        onCreateOption={this.handleCreate}
        formatCreateLabel={(valueInput) => `No option. Create ${valueInput}`}
      />
    );
  }

  renderFieldCountry = (fieldProps) => {
    const {
      field, options, label, form,
    } = fieldProps;
    const { name: fieldName } = field;
    return (
      <FormInput
        name={fieldName}
        type="select"
        label={label}
        options={options}
        getOptionValue={(option) => option.code}
        getOptionLabel={(option) => option.name}
        onChange={(value) => this.handleCountryOnChange(value, form)}
      />
    );
  }

  renderFieldDistrict = (fieldProps) => {
    const {
      form, field, options, label,
    } = fieldProps;
    const { name: fieldName } = field;
    const { values: { country } } = form;
    const isValue = country !== '';
    const countryCode = _get(country, 'code', null);
    const isUSCountry = countryCode === 'US';

    return (
      <FormInput
        name={fieldName}
        disabled={!isUSCountry}
        type="select"
        label={label}
        options={options}
        isDisabled={!isValue && fieldName === 'district'}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => `${option.name} - ${option.state}`}
        onInputChange={this.debouncedHandleInputChange}
        noOptionsMessage={() => 'Begin typing to see options'}
      />
    );
  }

  handleSubmit = (values) => {
    const { createRegCourse } = this.props;

    createRegCourse(values);
  }

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>

  renderFormContent = ({ handleSubmit, values }) => {
    const {
      standard, curriculum, errorMessage,
      subscriptionData, country, district,
      placementTypesObj,
    } = this.props;
    const { currId } = this.state;
    const { max_student_count: maxStudent } = subscriptionData;
    const { courseId = {} } = values;
    const { id: curId } = courseId;
    const placementTypes = placementTypesObj[curId] || [];

    if (curId !== currId) {
      this.setState({
        currId: curId,
      });
    }

    return (
      <RegisterFormInput onSubmit={handleSubmit}>
        <FormGroup textCenter>
          <FormInput
            type="text"
            name="courseName"
            label="Course Name"
          />
          <RegisterFormDetail>
            <DetailText code>
              { subscriptionData && subscriptionData.code }
            </DetailText>
            <DetailText>
              Max students :
              {
                maxStudent || 100
              }
            </DetailText>
          </RegisterFormDetail>
        </FormGroup>
        <RegisterSubFormWrap>
          <h1>Curriculum Information</h1>
          <FormInput
            type="select"
            name="courseId"
            label="Select Course"
            options={curriculum}
            getOptionValue={(option) => option.slug}
            getOptionLabel={(option) => option.name}
          />
          <FormInput
            type="select"
            name="standardId"
            label="Select Standards"
            options={standard}
            getOptionValue={(option) => option.slug}
            getOptionLabel={(option) => option.name}
          />
          {/* <FormInput
            type="text"
            name="subscriptionCode"
            label="Subscription Code"
            value={subscriptionData.code}
            disabled
            hidden
          /> */}
        </RegisterSubFormWrap>
        <RegisterSubFormWrap>
          <h1>Additional Information</h1>
          <FormGroup>
            <Field
              component={this.renderFieldRole}
              name="role"
              label="Type role or select from list"
              options={roleOption}
            />
            <Field
              component={this.renderFieldCountry}
              name="country"
              label="Country"
              options={country}
            />
          </FormGroup>
          <FormGroup>
            <FormInput
              type="text"
              name="schoolName"
              label="School Name"
            />
            <Field
              component={this.renderFieldDistrict}
              name="district"
              label="District (US Only)"
              options={district}
              isDistrict
            />
          </FormGroup>
        </RegisterSubFormWrap>
        <RegisterSubFormWrap>
          <h1>Exams</h1>
          <h3>The course will include a Placement test and an Exit test. Select the exam session type that best fits in your schedule.</h3>
          <ExamFormFieldsWrapper>
            <ExamFormFieldsSection>
              <FormGroup>
                <FormInput
                  type="select"
                  name="examType"
                  label="Exam Session Type"
                  options={placementTypes}
                  getOptionValue={(option) => option.exam_type}
                  getOptionLabel={(option) => option.name}
                />
              </FormGroup>
            </ExamFormFieldsSection>
            <ExamFormFieldsDesc>
              <ul>
                <li>You can add additional mid test sessions.</li>
                <li>If you selected Full-length then you will be able to select Rapid or Full-length for Mid-tests.</li>
                <li>If you selected Rapid, then you will be able to select only Rapid for Mid-tests.</li>
              </ul>
            </ExamFormFieldsDesc>
          </ExamFormFieldsWrapper>
        </RegisterSubFormWrap>
        {errorMessage
          && (
            <RegisterErrorMessage>
              {errorMessage.map(this.renderErrorMessage)}
            </RegisterErrorMessage>
          )}
        <RegisterAction>
          <ButtonStyled type="submit">Confirm & Create Course</ButtonStyled>
        </RegisterAction>
      </RegisterFormInput>
    );
  }

  render() {
    const {
      teacherDetail, curriculum,
      subscriptionData,
    } = this.props;
    const { curriculum: curriculumId, code } = subscriptionData;
    const curriculumDefault = curriculum.find((c) => c.id === curriculumId);
    const { last_name: teacherLastName } = teacherDetail;

    const newInitialValues = {
      courseName: `${teacherLastName}-C1`,
      courseId: subscriptionData && curriculumDefault,
      standardId: '',
      subscriptionCode: `${subscriptionData && code}`,
      role: '',
      country: '',
      schoolName: '',
      district: '',
      examType: '',
    };

    return (
      <RegisterFormWrapper>
        <RegisterFormTitle>
          <h1>Setup your course</h1>
          <h3>
            We have confirmed your subscription. Now you can configure and create your course.
            A school should have one course per curriculum. You can add multiple sections within a course.
          </h3>
        </RegisterFormTitle>
        <Formik
          initialValues={newInitialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSubmit}
          render={this.renderFormContent}
        />
      </RegisterFormWrapper>
    );
  }
}
