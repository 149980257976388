import _get from 'lodash/get';
import _values from 'lodash/values';
import _orderBy from 'lodash/orderBy';
import { selectors as SectionsSelectors } from '../../reducers/sections';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as SectionSessionSelectors } from '../../reducers/sectionSession';

export const INITIALIZE_PRACTICE_SESSION = 'practice/INIT';
export const INITIALIZE_PRACTICE_SESSION_SUCCESS = 'practice/INIT_SUCCESS';
export const INITIALIZE_PRACTICE_SESSION_FAIL = 'practice/INIT_FAIL';

export const INITIALIZE_REVIEW_PRACTICE_SESSION = 'practice/INIT_REVIEW';
export const INITIALIZE_REVIEW_PRACTICE_SESSION_SUCCESS = 'practice/INIT_REVIEW_SUCCESS';
export const INITIALIZE_REVIEW_PRACTICE_SESSION_FAIL = 'practice/INIT_REVIEW_FAIL';

export const TAKE_PRACTICE_SESSION = 'practice/TAKE';
export const TAKE_PRACTICE_SESSION_SUCCESS = 'practice/TAKE_SUCCESS';
export const TAKE_PRACTICE_SESSION_FAIL = 'practice/TAKE_FAIL';

export const COMPLETE_PRACTICE_SESSION = 'practice/COMPLETE_PRACTICE_SESSION';
export const COMPLETE_PRACTICE_SESSION_SUCCESS =
  'practice/COMPLETE_PRACTICE_SESSION_SUCCESS';
export const COMPLETE_PRACTICE_SESSION_FAIL = 'practice/COMPLETE_PRACTICE_SESSION_FAIL';

export const ANSWER_PRACTICE_QUESTION = 'practice/ANSWER_PRACTICE_QUESTION';
export const ANSWER_PRACTICE_QUESTION_SUCCESS =
  'practice/ANSWER_PRACTICE_QUESTION_SUCCESS';
export const ANSWER_PRACTICE_QUESTION_FAIL = 'practice/ANSWER_PRACTICE_QUESTION_FAIL';

export const PRACTICE_GET_SESSION_REPORT = 'practice/PRACTICE_GET_SESSION_REPORT';
export const PRACTICE_GET_SESSION_REPORT_SUCCESS =
  'practice/PRACTICE_GET_SESSION_REPORT_SUCCESS';
export const PRACTICE_GET_SESSION_REPORT_FAIL =
  'practice/PRACTICE_GET_SESSION_REPORT_FAIL';

export const PRACTICE_CHOOSE_ANSWER = 'practice/CHOOSE_ANSWER';
export const RESET_ANSWERS = 'practice/RESET_ANSWERS';

// action

const initializeReviewPracticeSession = (reviewId, reviewTopicId, callback) => ({
  type: INITIALIZE_REVIEW_PRACTICE_SESSION,
  reviewId,
  reviewTopicId,
  callback,
});

const initializeReviewPracticeSessionSuccess = (practiceData) => ({
  type: INITIALIZE_REVIEW_PRACTICE_SESSION_SUCCESS,
  practiceData,
});

const initializeReviewPracticeSessionFail = (error) => ({
  type: INITIALIZE_REVIEW_PRACTICE_SESSION_FAIL,
  error,
});

const initializePracticeSession = (
  lessonIds,
  sectionId,
  unitId,
  userId,
  assetType,
  practiceType,
) => ({
  type: INITIALIZE_PRACTICE_SESSION,
  lessonIds,
  sectionId,
  unitId,
  userId,
  assetType,
  practiceType,
});

const initializePracticeSessionSuccess = (practiceData) => ({
  type: INITIALIZE_PRACTICE_SESSION_SUCCESS,
  practiceData,
});

const initializePracticeSessionFail = (error) => ({
  type: INITIALIZE_PRACTICE_SESSION_FAIL,
  error,
});

const takePracticeSession = (practiceId) => ({
  type: TAKE_PRACTICE_SESSION,
  practiceId,
});

const takePracticeSessionSuccess = (questionsData) => ({
  type: TAKE_PRACTICE_SESSION_SUCCESS,
  questionsData,
});

const takePracticeSessionFail = (error) => ({
  type: TAKE_PRACTICE_SESSION_FAIL,
  error,
});

const answerPracticeQuestion = (practiceId, answerInfo) => ({
  type: ANSWER_PRACTICE_QUESTION,
  practiceId,
  answerInfo,
});

const answerPracticeQuestionSuccess = (answerObject) => ({
  type: ANSWER_PRACTICE_QUESTION_SUCCESS,
  answerObject,
});

const answerPracticeQuestionFail = (error) => ({
  type: ANSWER_PRACTICE_QUESTION_FAIL,
  error,
});

const completePracticeSession = (practiceId) => ({
  type: COMPLETE_PRACTICE_SESSION,
  practiceId,
});

const completePracticeSessionSuccess = (payload) => ({
  type: COMPLETE_PRACTICE_SESSION_SUCCESS,
  payload,
});

const completePracticeSessionFail = (payload) => ({
  type: COMPLETE_PRACTICE_SESSION_FAIL,
  payload,
});

const practiceSessionReport = (sectionId, practiceId) => ({
  type: PRACTICE_GET_SESSION_REPORT,
  sectionId,
  practiceId,
});

const practiceSessionReportSuccess = (report) => ({
  type: PRACTICE_GET_SESSION_REPORT_SUCCESS,
  report,
});

const practiceSessionReportFail = (error) => ({
  type: PRACTICE_GET_SESSION_REPORT_FAIL,
  error,
});

const chooseAnswer = (questionId, payload) => ({
  type: PRACTICE_CHOOSE_ANSWER,
  questionId,
  payload,
});

const resetAnswers = () => ({
  type: RESET_ANSWERS,
});

export const actions = {
  initializePracticeSession,
  initializePracticeSessionSuccess,
  initializePracticeSessionFail,

  takePracticeSession,
  takePracticeSessionSuccess,
  takePracticeSessionFail,

  answerPracticeQuestion,
  answerPracticeQuestionSuccess,
  answerPracticeQuestionFail,

  completePracticeSession,
  completePracticeSessionSuccess,
  completePracticeSessionFail,

  practiceSessionReport,
  practiceSessionReportSuccess,
  practiceSessionReportFail,

  chooseAnswer,
  resetAnswers,

  initializeReviewPracticeSession,
  initializeReviewPracticeSessionSuccess,
  initializeReviewPracticeSessionFail,
};

const getCompletedPracticeUnitIds = (state) => {
  const sessionList = SectionSessionSelectors.getSessionList(state);
  const completedPracticeUnitIds = [];
  const allPracticeSession = sessionList.filter((session) => {
    if (session.activity_type == 1) return session;
  });
  allPracticeSession.map((practice) => {
    const { unit: unitId } = practice;
    const practiceSummary = SectionsSelectors.getPracticeSummaryOfUnit(state, unitId);
    const unitDetail = SectionUnitSelectors.getUnitById(state, unitId);
    const { assets = [] } = unitDetail;
    const initialValueForReport = {
      count: 0,
    };
    const practiceReport = {};
    practiceSummary.forEach((summaryItem) => {
      const { asset_type } = summaryItem;
      if (!asset_type) return;
      const currentValueOfReport = _get(
        practiceReport,
        asset_type,
        initialValueForReport,
      );
      if (currentValueOfReport.count > 0) return;
      practiceReport[asset_type] = {
        count: currentValueOfReport.count + 1,
      };
    });
    if (practiceReport[null]) {
      delete practiceReport[null];
    }
    if (Object.values(practiceReport).length === assets.length) {
      completedPracticeUnitIds.push(unitId);
    }
  });
  return completedPracticeUnitIds;
};
const getPracticeId = ({ practice }) => practice.practiceData.id;
const getQuestions = ({ practice }) => practice.questions || [];
const getAnswerByQuestionId = ({ practice }, questionId) =>
  _get(practice, ['answers', questionId], {});
const getAnswerObj = ({ practice }) => practice.answers || {};
const getSubmittedAnswerList = ({ practice }) => practice.submittedAnswers || {};
const getSubmittedAnswerByQuestionId = ({ practice }, questionId) =>
  _get(practice, ['submittedAnswers', questionId], {});
const getAnswerList = ({ practice }) => {
  const getAnswerById = _get(practice, 'answers', {});

  return _values(getAnswerById);
};
const getReport = ({ practice }) => practice.report;

const getQuestionAsset = ({ practice }) => _get(practice, 'assets', []);
const getIsTakeQuestion = ({ practice }) => _get(practice, 'isTakeQuestion', true);

const isInitializing = ({ practice }) => practice.isInitializing;
const isInitialized = ({ practice }) => practice.isInitialized;

const isCompleteResponse = ({ practice }) => practice.isCompleteResponse;
const isCompleteResponseSuccess = ({ practice }) => practice.isCompleteResponseSuccess;

const isSubmittingAnswer = ({ practice }) => practice.isSubmittingAnswer;
const isSubmittedAnswer = ({ practice }) => practice.isSubmittedAnswer;

const isTakeReport = ({ practice }) => practice.isTakeReport;

export const selectors = {
  getCompletedPracticeUnitIds,
  getPracticeId,
  getQuestions,
  getAnswerByQuestionId,
  getSubmittedAnswerList,
  getSubmittedAnswerByQuestionId,
  getAnswerList,
  getQuestionAsset,
  getIsTakeQuestion,
  getAnswerObj,
  getReport,

  isInitializing,
  isInitialized,

  isCompleteResponse,
  isCompleteResponseSuccess,

  isSubmittingAnswer,
  isSubmittedAnswer,

  isTakeReport,
};

const initialState = {
  practiceData: {},
  isInitializing: false,
  isInitialized: false,
  initError: null,

  questions: [],
  isTakeQuestion: false,
  getQuestionError: null,

  answers: {},
  submittedAnswers: {},
  report: null,
  isTakeReport: false,
  getReportError: null,

  isSubmittingAnswer: false,
  isSubmittedAnswer: false,

  isCompleteResponse: false,
  isCompleteResponseSuccess: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_REVIEW_PRACTICE_SESSION:
    case INITIALIZE_PRACTICE_SESSION: {
      return {
        ...state,
        isInitializing: true,
        isInitialized: false,
        initError: null,
      };
    }
    case INITIALIZE_REVIEW_PRACTICE_SESSION_SUCCESS:
    case INITIALIZE_PRACTICE_SESSION_SUCCESS: {
      const { practiceData } = action;

      return {
        ...initialState,
        isInitialized: true,
        isInitializing: false,
        practiceData,
      };
    }
    case INITIALIZE_PRACTICE_SESSION_FAIL: {
      const { error } = action;
      return {
        ...state,
        initError: error,
        isInitializing: false,
      };
    }

    // =================================

    case TAKE_PRACTICE_SESSION: {
      return {
        ...state,
        isTakeQuestion: true,
        getQuestionError: null,
      };
    }
    case TAKE_PRACTICE_SESSION_SUCCESS: {
      const { questionsData } = action;
      const { exam_questions: examQuestions = [], prev_eqs: prevEqs = [] } =
        questionsData;
      const orderedQuestionList = _orderBy(examQuestions, 'order', 'asc');

      const answers = prevEqs
        .map((prevQuestion) => {
          const { exam_question: examQuestion, question_response: questionResponse } =
            prevQuestion;
          let value = questionResponse.choices;
          if (examQuestion.question_type === 2) [value] = value; // value = value[0]

          return {
            exam_question_id: examQuestion.id,
            value,
            response_time: questionResponse.response_time,
            delete: false,
          };
        })
        .reduce((acc, question) => {
          acc[question.exam_question_id] = question;
          return acc;
        }, {});

      const submittedAnswers = prevEqs.reduce((acc, answer) => {
        const { exam_question: examQuestion } = answer;
        acc[examQuestion.id] = answer;
        return acc;
      }, {});

      return {
        ...state,
        isTakeQuestion: false,
        questions: orderedQuestionList,
        answers,
        submittedAnswers,
      };
    }
    case TAKE_PRACTICE_SESSION_FAIL: {
      const { error } = action;
      return {
        ...state,
        isTakeQuestion: false,
        getQuestionError: error,
      };
    }

    // =================================

    case COMPLETE_PRACTICE_SESSION:
      return {
        ...state,
        isCompleteResponse: true,
        isCompleteResponseSuccess: false,
      };
    case COMPLETE_PRACTICE_SESSION_SUCCESS: {
      return {
        ...state,
        isCompleteResponse: true,
        isCompleteResponseSuccess: true,
      };
    }
    case COMPLETE_PRACTICE_SESSION_FAIL:
      return {
        ...state,
        isCompleteResponse: false,
      };

    // =================================

    case ANSWER_PRACTICE_QUESTION: {
      return {
        ...state,
        isSubmittingAnswer: true,
        isSubmittedAnswer: false,
      };
    }

    case ANSWER_PRACTICE_QUESTION_SUCCESS: {
      const { answerObject } = action;
      const questionId = _get(answerObject, 'exam_question.id');

      return {
        ...state,
        isSubmittingAnswer: false,
        isSubmittedAnswer: true,
        submittedAnswers: {
          ...state.submittedAnswers,
          [questionId]: answerObject,
        },
      };
    }

    case ANSWER_PRACTICE_QUESTION_FAIL: {
      return {
        ...state,
        isSubmittingAnswer: false,
      };
    }

    // =================================

    case PRACTICE_GET_SESSION_REPORT: {
      return {
        ...state,
        isTakeReport: true,
        getReportError: null,
      };
    }

    case PRACTICE_GET_SESSION_REPORT_SUCCESS: {
      const { report } = action;
      return {
        ...state,
        isTakeReport: false,
        report,
      };
    }

    case PRACTICE_GET_SESSION_REPORT_FAIL: {
      const { error } = action;
      return {
        ...state,
        isTakeReport: false,
        getReportError: error,
      };
    }

    // =================================

    case PRACTICE_CHOOSE_ANSWER: {
      const { payload, questionId } = action;
      return {
        ...state,
        answers: {
          ...state.answers,
          [questionId]: {
            ...payload,
          },
        },
      };
    }

    case RESET_ANSWERS: {
      return {
        ...state,
        answers: {},
      };
    }

    default:
      return state;
  }
}
