import { connect } from 'react-redux';
import Profile from '../../components/Profile';
import {
  selectors as UserSelectors,
} from '../../reducers/user';

const mapStateToProps = (state) => ({
  user: UserSelectors.getCurrentUser(state),
  isChangedPassword: UserSelectors.isChangedPassword(state),
  isEditedProfile: UserSelectors.isEditedProfile(state),
});

export default connect(mapStateToProps)(Profile);
