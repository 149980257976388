import { connect } from 'react-redux';
import PersonalInformationForm from '../../components/TeacherRegisterForm/PersonalInformationForm';
import {
  getErrorMessage,
  isRegistering,
  registerTeacher,
} from '../../reducers/register';

const mapStateToProps = state => ({
  errorMessage: getErrorMessage(state),
  isRegistering: isRegistering(state),
});

const mapDispatchToProps = {
  registerTeacher,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformationForm);
