import React from 'react';
import { GFEButtonContainer } from './GFEButton.style';

const GFEButton = (props) => {
  const { label, onClick, color } = props;
  return (
    <GFEButtonContainer
      onClick={onClick}
      color={color}
      {...props}
    >
      {label}
    </GFEButtonContainer>
  );
};

export default GFEButton;
