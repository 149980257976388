import { connect } from 'react-redux';
import TeacherAuthRoute from '../../components/routes/TeacherAuthRoute';
import {
  selectors as AuthSelectors,
} from '../../reducers/auth';
import {
  getUserRole,
} from '../../reducers/user';

const mapStateToProps = state => ({
  authenticated: AuthSelectors.getAuthenticatedStatus(state),
  role: getUserRole(state),
});

export default connect(mapStateToProps)(TeacherAuthRoute);
