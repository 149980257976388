import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

const QuestionSelectorDialog = ({
  open,
  onClose,
  onConfirm,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}) => (
  <Dialog
    title="Customize This Practice"
    subtitle="Select 5+ questions for this practice"
    open={open}
    onClose={onClose}
    actions={[
      {
        label: 'cancel',
        onClick: onClose,
      },
      {
        label: 'Continue',
        onClick: onConfirm,
        buttonProps: {
          variant: 'contained',
        },
      },
    ]}
  >
    <Typography>
      You can add and remove filters from the column header to select any questions from
      this subject
    </Typography>
  </Dialog>
);

export default QuestionSelectorDialog;
