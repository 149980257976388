// Dialogs.tsx
import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

const ErrorDialog = ({ open, onClose, taskId }) => (
  <Dialog
    title="Ooops!!"
    actions={[
      {
        label: 'Close',
        onClick: onClose,
        buttonProps: {
          variant: 'contained',
          color: 'primary',
        },
      },
    ]}
    open={open}
    onClose={onClose}
  >
    <Typography variant="body1" sx={{ textAlign: 'start' }}>
      Something went wrong. Please try again.
      <br />
      If the issue persists, please contact support with the following:
      <br />
      <br />
      Issue ID: {taskId || 'N/A'}
    </Typography>
  </Dialog>
);

const SubmissionDialog = ({ open, onClose, submissionList }) => (
  <Dialog
    title="Question Submitted!"
    subtitle="Your generated and custom questions have been saved in our practice itembank"
    actions={[
      {
        label: 'Close',
        onClick: onClose,
        buttonProps: {
          variant: 'contained',
          color: 'primary',
        },
      },
    ]}
    open={open}
    fullWidth
    onClose={onClose}
  >
    <Typography variant="body1" sx={{ textAlign: 'start' }}>
      The generated and custom questions have been submitted under the selected options:
    </Typography>
    {submissionList}
  </Dialog>
);

export { ErrorDialog, SubmissionDialog };
