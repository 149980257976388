import styled, { css } from 'styled-components';

export const QuestionWrap = styled.div`
  flex: 1;
  max-width: 36em;
  width: 100%;
  @media (max-width: 1280px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    max-width: unset;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const BottomButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: ${props=> props.activity === "online-group" ? "20% !important" : "unset"};
  @media (max-width: 768px) {
    position: relative;
    display: block;
  }
  ${({ center }) => center && css`
    justify-content: center !important;
  `}

  ${({ isGroupActivity }) => isGroupActivity && css`
    justify-content: space-between !important;
    button {
      &:last-child {
        margin-left: unset !important;
      }
    }
  `}
`;

export const QuestionHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: .75em;
  border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBorderInput};
  margin-bottom: 1em;
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  @media (max-width: 900px){
    height: 40px;
  }
`;

export const QuestionProgress = styled.div`
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  span {
    margin-left: .5em;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  
  @media (max-width: 768px) {
    height: 100%;
  }
`;
export const QuestionContentWrap = styled.div`
  padding-top: 2em;
  height: calc(100% - 5em);
  ${props => props.practiceQuestion && 'height:unset;'}
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    height: unset;
    flex: 1;
  }

  @media (min-width: 1500px){
    height: unset;
  }
`;

export const QuestionDesc = styled.div`
  font-family: 'MontserratRegular';
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  table, th, td {
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
  }
  p {
    img {
      max-width: 100%;
      @media (max-width: 768px) {
        object-fit: scale-down;
        height: unset!important;
      }
    }
  }
`;

export const QuestionAnswer = styled.div`
  font-family: 'MontserratRegular';
  margin: 2em 0em;
`;

export const QuestionAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  button {
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
    flex: 0 0 calc(50% - 1em);
    &:last-child {
      margin-left: 1em;
    }
    bottom: 0;
  }
  @media (max-width: 768px) {
    &>div:first-child {
      width: 100%;
    }
    justify-content: center;
    position: sticky;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: ${(props) => props.theme.colorStyled.ColorBgContent};
    button {
      font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
      width: 100%;
      margin-top: 1px;
      &:last-child {
        margin-left: unset !important;
      }
    }
  }
  @media (min-width: 1500px){
    ${props => props.practiceReviewQuestion ? 'margin-top: 0px;' : 'margin-top: 50px;'}  
  }
`;
export const QuestionOrderWrapper = styled.p`
  color: ${props => props.theme.colorStyled.ColorDarkGrey};
  line-height: 18px;
  margin-bottom: 10px;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  font-family: 'MontserratRegular';
`;

export const QuestionOrderNumber = styled.strong`
  background: gray; padding: 4px 8px; color: white;
  margin-left: 10px;
`;
