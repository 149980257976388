/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo } from 'react';
import { useIsTeacher } from '@reducers/user/hooks';
import {
  getAverageOverAllScore,
  calculateAverageSubScoreForEachExamScoringAssessmentNode,
  filterCompletedExamSectionsSubScore,
  groupExamSectionsByAssessmentNode,
  mapExamSubScoreWithAverageSubScoreScaledScore,
  filterScheduledExamSectionSubScores,
} from './helpers';
import {
  useGetExamDetail,
  useGetExamResult,
  useGetExamScaledScore,
  useGetExamStudentResult,
} from './query';
import { ExamSectionObject, ScaledScoreData } from '../ExamScoreCard/types';

const useExamScaledScore = (): ScaledScoreData => {
  const { data: scaledScore } = useGetExamScaledScore();
  const { data: examDetails } = useGetExamDetail();
  const { data: usersResult } = useGetExamResult();
  const { data: studentResult } = useGetExamStudentResult();
  const isTeacher: boolean = useIsTeacher();

  const examSectionsList: Array<ExamSectionObject> | undefined =
    examDetails?.exam?.sections;
  // fallback -1 to break the condition when examSectionsList is undefined
  const examSectionsCount: number = examSectionsList?.length ?? -1;

  const groupedExamSections = useMemo(
    () => groupExamSectionsByAssessmentNode(examSectionsList),
    [examSectionsList],
  );

  const averageScores = useMemo(() => {
    const _usersResult = isTeacher ? usersResult : [studentResult!];
    const filteredScoreObject = filterCompletedExamSectionsSubScore(
      groupedExamSections,
      _usersResult,
    );

    const averageSubScoreList =
      calculateAverageSubScoreForEachExamScoringAssessmentNode(filteredScoreObject);
    const updatedSubScoresWithAverage = mapExamSubScoreWithAverageSubScoreScaledScore(
      scaledScore?.subscores,
      averageSubScoreList,
    );
    const scheduledExamSectionsSubScores = filterScheduledExamSectionSubScores(
      examDetails,
      updatedSubScoresWithAverage,
    );
    const averageOverAllScore = getAverageOverAllScore(_usersResult, examSectionsCount);
    return {
      overall_scaled_score: {
        ...scaledScore?.overall_scaled_score,
        score: averageOverAllScore,
      },
      subscores: scheduledExamSectionsSubScores,
    } as ScaledScoreData;
  }, [
    isTeacher,
    usersResult,
    studentResult,
    groupedExamSections,
    scaledScore?.subscores,
    scaledScore?.overall_scaled_score,
    examDetails,
    examSectionsCount,
  ]);

  return averageScores;
};

export default useExamScaledScore;
