import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DashboardAttendanceReportTable from '../../components/DashboardAttendanceReport/DashboardAttendanceReportTable';
import { selectors as SectionStudent } from '../../reducers/sectionStudent';

const mapStateToProps = (state, { match }) => {
  const { sectionId = 0 } = match.params;
  return {
    studentList: SectionStudent.getStudentListWithFilter(state, sectionId),
    isGettingStudentList: SectionStudent.isGettingStudentList(state),
  };
};
const mapDispatchToProps = {};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardAttendanceReportTable));
