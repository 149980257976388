/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  UsingCodeContentWrapper,
  TextInUsingCodeTab,
  TextCodeWrapper,
} from './InviteStudentsModal.style';

class UsingCodeTab extends PureComponent {
  render() {
    const { code } = this.props;
    return (
      <UsingCodeContentWrapper>
        <TextInUsingCodeTab>
          Provide the following code to students. Using this code students can sign up and enroll in section.
        </TextInUsingCodeTab>
        <br />
        <br />
        <br />
        <br />
        <TextCodeWrapper>
          {code}
        </TextCodeWrapper>
      </UsingCodeContentWrapper>
    );
  }
}

UsingCodeTab.propTypes = {
  code: PropTypes.string,
};

export default UsingCodeTab;
