import { connect } from 'react-redux';
import TeacherRegister from '../../components/TeacherRegister/TeacherRegister';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../../reducers/auth';
import { isLoadingStep } from '../../reducers/register';

const mapStateToProps = state => ({
  isIntermediateScreen: false,
  isLoadingStep: isLoadingStep(state),
  shouldLogout: AuthSelectors.getAuthenticatedStatus(state),
});

const mapDispatchToProps = {
  logout: AuthActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherRegister);
