import * as Sentry from '@sentry/react';
import { useMathJaxContext } from '../providers/Provider';

// eslint-disable-next-line import/prefer-default-export
export const useMathJax = () => {
  const MathJax = useMathJaxContext();

  function updateMathContent(onFinish) {
    if (!MathJax || typeof MathJax.typesetPromise !== 'function') {
      Sentry.withScope((scope) => {
        scope.setTag('exception', 'Invalid MathJax instance.');
        Sentry.captureMessage("Can't load MathJax.");
      });
      return;
    }

    MathJax.typesetPromise()
      .then(onFinish)
      .catch((e) => {
        Sentry.withScope((scope) => {
          scope.setTag('exception', 'Mathjax TypeError.');
          Sentry.captureException(e);
        });
      });
  }

  return [MathJax, updateMathContent];
};
