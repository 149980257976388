/* eslint-disable import/prefer-default-export */
import { captureException } from '@sentry/react';
import { error as errorToast } from './toast';

export const initializeGAPI = async () => {
  // Client ID and API key from the Developer Console
  const CLIENT_ID = process.env.GOOGLE_SLIDES_CLIENT_ID;
  const API_KEY = process.env.GOOGLE_SLIDES_API_KEY;
  if (!CLIENT_ID || !API_KEY) {
    console.error('Missing Google Slides Client ID or API Key');
    return;
  }
  // Array of API discovery doc URLs for APIs used by the quickstart
  const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  const SCOPES = 'https://www.googleapis.com/auth/drive';

  const initClient = async () => {
    await window.gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .catch((error) => {
        captureException(error);
        errorToast('Failed to initialize Google API, please try again later');
      });
  };

  return window.gapi?.load('client:auth2', await initClient());
};

export const iSGAPIInitialized = () =>
  window.gapi?.auth2?.getAuthInstance()?.isSignedIn.get();
