import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import LeftSideBarTeacherTraining from '../../components/LeftSideBar/LeftSideBarTeacherTraining';
import { selectors as CourseSelectors } from '../../reducers/courses';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import {
  selectors as LeftSidebarSelectors,
  actions as LeftSidebarActions,
} from '../../reducers/leftSideBar';

const mapStateToProps = (state, { match }) => {
  const courseIdSelected = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseIdSelected);
  const curriculumId = _get(courseData, 'curriculum', '');

  return ({
    courseId: courseIdSelected,
    curriculumId,
    trainings: CurriculumSelectors.getTeacherTrainingList(state, curriculumId),
    isSideBarClose: LeftSidebarSelectors.getSideBar(state),
  });
};

const mapDispatchToProps = {
  getCurriculumTraining: CurriculumActions.getCurriculumTeacherTraining,
  toggleLeftSidebar: LeftSidebarActions.toggleLeftSidebar,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideBarTeacherTraining),
);
