/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDrag } from 'react-dnd';
import React, { useState } from 'react';
import _get from 'lodash/get';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { CALENDAR_EVENT_TYPE } from '../../utils/constants';
import { ROLE_TYPE } from '../../utils/enums';
import {
  EventContentWrap,
  EventContent,
  EventTitle,
  MonthEventContent,
  FollowWrapper,
  FollowRow,
  CardDetails,
} from './Calendar.style';
import { DropdownWrap, DropdownItem } from '../TopNavBar/TopNavBar.style';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import history from '../../utils/history';
import {
  actions as LeftSidebarActions,
} from '../../reducers/leftSideBar';
import EditLessonModal from "../../components/Modals/EditLessonModal";


const mapDispatchToProps = {
  toggleSubNavBar: LeftSidebarActions.toggleSubNavBar,
  toggleLessonSessionNavbar: LeftSidebarActions.toggleLessonSessionNavbar,
};

const WeekOption = connect(state => (
  {
    isSubNavbarLessonSessionClose: state.leftSideBar.isSubNavbarLessonSessionClose,
  }),
mapDispatchToProps)(
  ({
    event,
    courseId,
    userRole,
    toggleSubNavBar = () => {}, // added default value since none of the components on this page pass this prop
    isSubNavbarLessonSessionClose,
    toggleLessonSessionNavbar = () => {}, // added default value since none of the components on this page pass this prop
  }) => {
    const goToSession = (courseId, sessionItem) => {
      const { type, id, section: sectionId } = sessionItem;
      const subjectId = _get(sessionItem, 'subject', '');
      const examId = _get(sessionItem, 'id', '');
      const unitId = _get(sessionItem, 'unit', '');
      const lessonId = _get(sessionItem, 'lesson', '');

      let sessionUrl = '';
      if (type === CALENDAR_EVENT_TYPE.REVIEWS) {
        sessionUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/review/${id}`;
      } else if (type === CALENDAR_EVENT_TYPE.EXAMS) {
        sessionUrl = `/test/course/${courseId}/section/${sectionId}/exam/${examId}`;
      } else if (lessonId) {
        sessionUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}?tab=${LessonTabs.Lesson}`;
      } else {
        sessionUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice`;
      }

      if (lessonId || type !== CALENDAR_EVENT_TYPE.REVIEWS && type !== CALENDAR_EVENT_TYPE.EXAMS) {
      // this two line to make left side bar open all lesson
        toggleSubNavBar(subjectId);
        isSubNavbarLessonSessionClose && toggleLessonSessionNavbar();
      }

      return history.push(sessionUrl);
    };
    return (
      <React.Fragment>
        <DropdownWrap
          schedule
          sessionModal
          small={userRole === ROLE_TYPE.STUDENT}
        >
          <DropdownItem onClick={() => goToSession(courseId, event)}>
            Go to session
          </DropdownItem>
        </DropdownWrap>
      </React.Fragment>
    );
  },
);

WeekOption.propTypes = {
  event: PropTypes.shape(),
  openEditForm: PropTypes.func,
  courseId: PropTypes.string,
  userRole: PropTypes.string,
};

// this for card week and two week
export const WeekEvent = (props) => {
  const {
    event,
    openEditForm,
    courseId,
    userRole,
    scheduleList,
    subjects,
  } = props;

  const [isOptionOpen, setIsOptionOpen] = useState(false);

  /* var data = [];
  if (event.activity_type == 0) {
    data = scheduleList.filter(function (item) {
      && item.unit == event.unit;
    });
  } */
  let data = [];
  data = scheduleList.filter((item) => {
    item.unit == event.unit;
  });

  const [, drag] = useDrag({
    type: 'ITEM',
    item: event,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  /* if (event.activity_type == 2) {
    return <React.Fragment></React.Fragment>;
  } */
  if (event.type !== CALENDAR_EVENT_TYPE.LESSONS) {
    return (
      <EventContentWrap>
        <EventContent
          key={event.id}
          type={event.type}
          ref={drag}
          onClick={() => setIsOptionOpen(!isOptionOpen)}
        >
          {event.title}
        </EventContent>

        {isOptionOpen && (
          <WeekOption
            event={event}
            openEditForm={openEditForm}
            courseId={courseId}
            userRole={userRole}
          />
        )}
      </EventContentWrap>
    );
  }

  let subjectName = ""
  if(subjects){
    const filteredSubjectName = subjects.filter(subject => subject.id == event.subject)
    if(filteredSubjectName.length != 0){
      subjectName = filteredSubjectName[0].display_name
    }
  }
  const isMath = subjectName.match(/math/i) || false;
  const isScience = subjectName.match(/science/i) || false;
  const isOther = !isMath && !isScience;

  const isPractice = (event.name && event.name.match(/practice/i)) || false;

  const ela = ['Reading', 'Essay', 'Writing & Language', 'Writing', 'English'];
  return (
    <EventContentWrap>
      {event.name ? (
        <EventContent
          type={event.type}
          activity={event.activity_type}
          ref={drag}
          key={event.id}
          onClick={() => setIsOptionOpen(!isOptionOpen)}
          math={isMath && event.activity_type !== 1}
          science={isScience && event.activity_type !== 1}
          other={isOther && event.activity_type !== 1}
          name={event.name}
          isPractice={isPractice}
        >
          <CardDetails>
            <EventTitle>
              {subjectName}
            </EventTitle>
            {event.name && (
              <div>
                {event.name !== undefined
                  ? `${event.name} ${event.indicator || ' '}`
                  : event.title}
                {event.lessonStatusIndicator
                  && `[${event.lessonStatusIndicator}]`}
              </div>
            )}
          </CardDetails>
          {/* {renderDo(event)} */}
        </EventContent>
      ) : null}
      {isOptionOpen && (
        <EditLessonModal 
        unitSelected={event}
        scheduleList={scheduleList}
        isEditModal={isOptionOpen}
        closeModal={()=> setIsOptionOpen(false)}
        isTeacher={userRole === 'Teacher'}
        />
      )}
    </EventContentWrap>
  );
};

WeekEvent.propTypes = {
  event: PropTypes.shape(),
  openEditForm: PropTypes.func,
  courseId: PropTypes.string,
  userRole: PropTypes.string,
};

export const MonthEvent = ({
  event,
  openEditForm,
  courseId,
  userRole,
  subjects,
  scheduleList
}) => {
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [, drag] = useDrag({
    type: 'ITEM',
    item: event,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (event.type !== CALENDAR_EVENT_TYPE.LESSONS) {
    return (
      <EventContentWrap>
        <MonthEventContent
          type={event.type}
          activity={event.activity_type}
          ref={drag}
          key={event.id}
          onClick={() => setIsOptionOpen(!isOptionOpen)}
        >
          {event.title}
        </MonthEventContent>

        {isOptionOpen && (
          <WeekOption
            event={event}
            isOpen={isOptionOpen}
            openEditForm={openEditForm}
            courseId={courseId}
            userRole={userRole}
          />
        )}
      </EventContentWrap>
    );
  }

  const filteredSubjectName = subjects.filter(subject => subject.id == event.subject)
  let subjectName = ""
  if(filteredSubjectName.length != 0){
    subjectName = filteredSubjectName[0].display_name
  }
  const isMath = subjectName.match(/math/i);
  const isScience = subjectName.match(/science/i);
  const isOther = !isMath && !isScience;
  const isPractice = (event.name && event.name.match(/practice/i)) || false;
  return (
    <EventContentWrap>
      {event.name || true ? (
        <MonthEventContent
          type={event.type}
          activity={event.activity_type}
          ref={drag}
          key={event.id}
          onClick={() => setIsOptionOpen(!isOptionOpen)}
          math={isMath && event.activity_type !== 1}
          science={isScience && event.activity_type !== 1}
          other={isOther && event.activity_type !== 1}
          isPractice={isPractice}
        >
          <EventTitle>
            {subjectName}
          </EventTitle>

          {event.name && (
            <div>
              {event.name}
              {event.lessonStatusIndicator
                && `[${event.lessonStatusIndicator}]`}
            </div>
          )}                                                
        </MonthEventContent>
      ) : null}

       {isOptionOpen && (
        <EditLessonModal 
        unitSelected={event}
        scheduleList={scheduleList}
        isEditModal={isOptionOpen}
        closeModal={()=> setIsOptionOpen(false)}
        isTeacher={userRole === 'Teacher'}
        />
      )}
    </EventContentWrap>
  );
};

MonthEvent.propTypes = {
  event: PropTypes.shape(),
  openEditForm: PropTypes.func,
  courseId: PropTypes.string,
  userRole: PropTypes.string,
};
