import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import moment from 'moment';
import { selectors as sectionSubjectSelectors } from '../sectionSubjects';
import {
  CALENDAR_EVENT_TYPE,
  DATE_FORMAT,
  EXAM_LENGTH_TYPES,
  EXAM_SESSION_TYPES,
} from '../../utils/constants';
import { selectors as SectionUnitSelectors } from '../sectionUnit';
import {
  SECTION_EXAMS_UPDATE_SUCCESS,
  SECTION_EXAMS_DELETE_SUCCESS,
  SECTION_EXAMS_ADD_SUCCESS,
} from '../sectionExam';
import {
  SECTION_REVIEWS_UPDATE_SUCCESS,
  SECTION_REVIEWS_DELETE_SUCCESS,
  SECTION_REVIEWS_ADD_SUCCESS,
} from '../sectionReviews';
import {
  SECTIONS_SESSION_UPDATE_SUCCESS,
  SECTIONS_SESSION_DELETE_SUCCESS,
  SECTIONS_SESSION_ADD_SUCCESS,
} from '../sectionSession';

export const SECTIONS_SCHEDULE_GET_LIST = 'sectionSchedule/GET_LIST';
export const SECTIONS_SCHEDULE_GET_LIST_SUCCESS = 'sectionSchedule/GET_LIST_SUCCESS';
export const SECTIONS_SCHEDULE_GET_LIST_FAIL = 'sectionSchedule/GET_LIST_FAIL';

export const SECTIONS_SCHEDULE_UPDATE_DATE = 'sectionSchedule/UPDATE_DATE';
export const SECTIONS_SCHEDULE_UPDATE_DATE_SUCCESS = 'sectionSchedule/UPDATE_DATE_SUCCESS';
export const SECTIONS_SCHEDULE_UPDATE_DATE_FAIL = 'sectionSchedule/UPDATE_DATE_FAIL';

export const SECTIONS_SCHEDULE_UPDATE_FROM_CALENDAR = 'sectionSchedule/UPDATE_FROM_CALENDAR';

export const CHECK_IS_ON_EDIT_MODE = 'sectionSchedule/CHECK_IS_ON_EDIT_MODE';

export const REFRESH_DATA_FROM_CALENDAR = 'sectionSchedule/REFRESH_DATA_FROM_CALENDAR';
/**
 * @param {*} sectionId String
 */

const editModeCheck = (status) => ({
  type: CHECK_IS_ON_EDIT_MODE,
  status,
});

const refreshDataFromCalendar = () => ({
  type: REFRESH_DATA_FROM_CALENDAR,
});

const sectionScheduleGet = (sectionId) => ({
  type: SECTIONS_SCHEDULE_GET_LIST,
  sectionId,
});

const sectionScheduleUpdateFromCalendar = (data) => ({
  type: SECTIONS_SCHEDULE_UPDATE_FROM_CALENDAR,
  data,
});

/**
 * @param {Array} scheduleList
 * @param {String} sectionId
 */
const sectionScheduleGetSuccess = (scheduleList, sectionId) => ({
  type: SECTIONS_SCHEDULE_GET_LIST_SUCCESS,
  scheduleList,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionScheduleGetFail = (errorMsg) => ({
  type: SECTIONS_SCHEDULE_GET_LIST_FAIL,
  errorMsg,
});

/**
 * @param {*} sectionId String
 */

const sectionScheduleUpdateDate = (sessionDetail) => ({
  type: SECTIONS_SCHEDULE_UPDATE_DATE,
  sessionDetail,
});

/**
 * @param {Array} scheduleList
 * @param {String} sectionId
 */
const sectionScheduleUpdateDateSuccess = (
  sessionId,
  sessionType,
  sessionDetail,
) => ({
  type: SECTIONS_SCHEDULE_UPDATE_DATE_SUCCESS,
  sessionId,
  sessionType,
  sessionDetail,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionScheduleUpdateDateFail = (sessionId, errorMsg) => ({
  type: SECTIONS_SCHEDULE_UPDATE_DATE_FAIL,
  errorMsg,
});

// selectors

const getExamIds = ({ sectionSchedule }) => _get(sectionSchedule, 'examIds', []);
const getExamById = ({ sectionSchedule }) => _get(sectionSchedule, 'examById', {});

const getReviewIds = ({ sectionSchedule }) => _get(sectionSchedule, 'reviewIds', []);
const getReviewById = ({ sectionSchedule }) => _get(sectionSchedule, 'reviewById', {});

const getLessonIds = ({ sectionSchedule }) => _get(sectionSchedule, 'lessonIds', []);
const getLessonById = ({ sectionSchedule }) => _get(sectionSchedule, 'lessonById', {});

const getExamList = createSelector(getExamIds, getExamById, (ids, byIds) => ids.map((id) => {
  const examObj = byIds[id];
  const { session_type: sessionType, length_type: lengthType } = examObj;
  const title = `${EXAM_LENGTH_TYPES.title[lengthType]} ${EXAM_SESSION_TYPES.title[sessionType]} Test`;
  return {
    ...examObj,
    type: CALENDAR_EVENT_TYPE.EXAMS,
    title,
  };
}));

const getReviewList = createSelector(
  getReviewIds,
  getReviewById,
  sectionSubjectSelectors.getSubjectById,
  (ids, byIds, subjectById) => ids.map((id) => {
    const reviewObj = byIds[id];
    const { subject: subjectId } = reviewObj;
    const subjectName = _get(subjectById, `${subjectId}.display_name`, '');
    const title = `${subjectName} Review`;
    return {
      ...reviewObj,
      title,
      type: CALENDAR_EVENT_TYPE.REVIEWS,
    };
  }),
);

const getLessonList = createSelector(
  getLessonIds,
  getLessonById,
  SectionUnitSelectors.getUnitByIds,
  sectionSubjectSelectors.getSubjectById,
  (ids, byIds, unitByIds, subjectById) => ids.map((id) => {
    const lessonObj = byIds[id];
    const {
      unit: unitId,
      lesson: lessonId,
      subject: subjectId,
      activity_type: activityType,
    } = lessonObj;
    const unitDetail = unitByIds[unitId] || {};
    const lessonDetail = (unitDetail.lessons || []).find((e) => e.id === lessonId) || {};
    const subjectName = _get(subjectById, `${subjectId}.display_name`, '');

    const title = unitDetail.name || 'N/A';
    let lessonName = lessonDetail.name;
    const { activity_json: activityJson } = lessonObj;
    let lessonStatusIndicator = '';

    // adding name practice to card 2
    if (!lessonId && activityType == 1) {
      lessonName = lessonObj.name;
      lessonStatusIndicator = '';
    }

    return {
      ...lessonObj,
      title,
      subjectName,
      lessonName,
      lessonStatusIndicator,
      type: CALENDAR_EVENT_TYPE.LESSONS,
    };
  }),
);

//  getScheduleList to get data for schedule
// lessonList is modified
const getScheduleList = createSelector(
  getExamList,
  getReviewList,
  getLessonList,
  (examSchedule, reviewSchedule, lessonSchedule) => {
    const disablableExamScheduleItem = examSchedule.reduce((acc, exam) => {
      const startDate = moment(exam.start_date, DATE_FORMAT.DATE);
      const endDate = moment(exam.end_date, DATE_FORMAT.DATE);
      const examScheduleList = [];
      while (startDate <= endDate) {
        examScheduleList.push({
          ...exam,
          date: startDate.format(DATE_FORMAT.DATE),
        });
        startDate.add(1, 'day');
      }
      return acc.concat(examScheduleList);
    }, []);
    return [
      ...disablableExamScheduleItem,
      ...reviewSchedule,
      ...lessonSchedule,
    ];
  },
);

const getLoadingSession = ({ sectionSchedule }) => _get(sectionSchedule, 'isUpdating', []);

const getShouldFetch = ({ sectionSchedule }, sectionId) => _get(sectionSchedule, 'hasFetchedOfSectionId', null) != sectionId;

const isGettingSectionSchedule = ({ sectionSchedule }) => sectionSchedule.isGet;

export const selectors = {
  getLessonList, 
  getScheduleList,
  getLoadingSession,
  getShouldFetch,
  isGettingSectionSchedule,
};

export const actions = {
  // get schedules
  sectionScheduleGet,
  sectionScheduleUpdateFromCalendar,
  sectionScheduleGetSuccess,
  sectionScheduleGetFail,
  sectionScheduleUpdateDate,
  sectionScheduleUpdateDateSuccess,
  sectionScheduleUpdateDateFail,
  editModeCheck,
  refreshDataFromCalendar,
};

const initialState = {
  isGet: false, // is getting schedule
  errorMsg: '',

  examById: {},
  examIds: [],

  reviewById: {},
  reviewIds: [],

  lessonById: {},
  lessonIds: [],

  isUpdating: [],
  hasFetchedOfSectionId: null,

  dataFromCalendar: [],

  onEditMode: false,
};
// reducer
export default function (state = initialState, action) {
  switch (action.type) {
    // get schedule
    case SECTIONS_SCHEDULE_GET_LIST: {
      const { sectionId } = action;
      const shouldFetch = true; // state.hasFetchedOfSectionId != sectionId;

      return {
        ...state,
        isGet: shouldFetch,
        errorMsg: '',
      };
    }
    case CHECK_IS_ON_EDIT_MODE: {
      const { status } = action;
      return {
        ...state,
        onEditMode: status,
      };
    }
    case SECTIONS_SCHEDULE_UPDATE_FROM_CALENDAR: {
      let { dataFromCalendar } = state;
      dataFromCalendar = dataFromCalendar.filter(item => {
        item = JSON.parse(item);
        switch (item.payload.type) {
          case 'exams':
            return item.payload.title !== action.data.payload.title || item.payload.id !== action.data.payload.id;
          default:
            return item.payload.id !== action.data.payload.id;
        }
      });
      // we stringify the obj because the date in obj called by reference some times when we move review card then we move exam card
      return {
        ...state,
        dataFromCalendar: [...dataFromCalendar, JSON.stringify(action.data)],
      };
    }

    case REFRESH_DATA_FROM_CALENDAR: {
      return {
        ...state,
        dataFromCalendar: [],
      };
    }
    // set for schedule
    case SECTIONS_SCHEDULE_GET_LIST_SUCCESS: {
      const { scheduleList, sectionId } = action;
      const { exams = [], reviews = [], sessions = [] } = scheduleList;

      const examIds = exams.map(({ id }) => id);
      const examById = _keyBy(exams, 'id');

      const reviewIds = reviews.map(({ id }) => id);
      const reviewById = _keyBy(reviews, 'id');

      const lessonIds = sessions.map(({ id }) => id);
      const lessonById = _keyBy(sessions, 'id');

      return {
        ...state,
        isGet: false,

        examIds,
        examById,

        reviewIds,
        reviewById,

        lessonIds,
        lessonById,

        hasFetchedOfSectionId: sectionId,
      };
    }

    case SECTIONS_SCHEDULE_GET_LIST_FAIL: {
      const { errorMsg } = action;

      return {
        ...state,
        isGet: false,
        errorMsg,
      };
    }

    case SECTIONS_SCHEDULE_UPDATE_DATE: {
      const { sessionDetail } = action;
      const { isUpdating } = state;
      return {
        ...state,
        isUpdating: [...isUpdating, sessionDetail],
      };
    }

    case SECTIONS_SCHEDULE_UPDATE_DATE_SUCCESS: {
      const { sessionId, sessionType, sessionDetail } = action;
      let { examById, lessonById, reviewById } = state;
      if (CALENDAR_EVENT_TYPE.REVIEWS === sessionType) {
        reviewById = {
          ...reviewById,
          [sessionId]: sessionDetail,
        };
      }
      if (CALENDAR_EVENT_TYPE.LESSONS === sessionType) {
        lessonById = {
          ...lessonById,
          [sessionId]: sessionDetail,
        };
      }
      if (CALENDAR_EVENT_TYPE.EXAMS === sessionType) {
        examById = {
          ...examById,
          [sessionId]: sessionDetail,
        };
      }

      return {
        ...state,
        examById,
        lessonById,
        reviewById,
        isUpdating: state.isUpdating.filter((id) => id !== sessionId),
      };
    }

    case SECTIONS_SCHEDULE_UPDATE_DATE_FAIL: {
      const { sessionId } = action;

      return {
        ...state,
        isUpdating: state.isUpdating.filter((id) => id !== sessionId),
      };
    }

    case SECTION_EXAMS_UPDATE_SUCCESS: {
      const { examId, examInfo } = action;

      return {
        ...state,
        examById: {
          ...state.examById,
          [examId]: examInfo,
        },
      };
    }

    case SECTION_EXAMS_DELETE_SUCCESS: {
      const { id } = action;
      const examById = _get(state, 'examById', {});
      const examIds = state.examIds.filter((x) => x !== id);
      delete examById[id];
      return {
        ...state,
        examById,
        examIds,
      };
    }

    case SECTION_REVIEWS_UPDATE_SUCCESS: {
      const { reviewId, reviewInfo } = action;

      return {
        ...state,
        reviewById: {
          ...state.reviewById,
          [reviewId]: reviewInfo,
        },
      };
    }

    case SECTION_REVIEWS_DELETE_SUCCESS: {
      const { id } = action;
      const reviewById = _get(state, 'reviewById', {});
      const reviewIds = state.reviewIds.filter((x) => x !== id);
      delete reviewById[id];
      return {
        ...state,
        reviewById,
        reviewIds,
      };
    }

    case SECTIONS_SESSION_UPDATE_SUCCESS: {
      const { sessionId, sessionData } = action;

      return {
        ...state,
        lessonById: {
          ...state.lessonById,
          [sessionId]: sessionData,
        },
      };
    }

    case SECTIONS_SESSION_DELETE_SUCCESS: {
      const { id } = action;
      const lessonById = _get(state, 'lessonById', {});
      const lessonIds = state.lessonIds.filter((x) => x !== id);
      delete lessonById[id];

      return {
        ...state,
        lessonById,
        lessonIds,
      };
    }

    case SECTIONS_SESSION_ADD_SUCCESS: {
      const { payload: sessionObj } = action;
      const { id } = sessionObj;
      const newLessonids = [...state.lessonIds, id];

      return {
        ...state,
        lessonIds: newLessonids,
        lessonById: {
          ...state.lessonById,
          [id]: sessionObj,
        },
      };
    }

    case SECTION_EXAMS_ADD_SUCCESS: {
      const { examInfo } = action;
      const { id } = examInfo;

      const newExamIds = [...state.examIds, id];

      return {
        ...state,
        examIds: newExamIds,
        examById: {
          ...state.examById,
          [id]: examInfo,
        },
      };
    }
    case SECTION_REVIEWS_ADD_SUCCESS: {
      const { payload: reviewObj } = action;
      const { id } = reviewObj;

      const newReviewIds = [...state.reviewIds, id];

      return {
        ...state,
        reviewIds: newReviewIds,
        reviewById: {
          ...state.reviewById,
          [id]: reviewObj,
        },
      };
    }
    default:
      return state;
  }
}
