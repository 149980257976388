import React from 'react';
import TableWithWrapper from '@components/Atoms/Table/TableWithWrapper';
import { actions as SectionActions } from '@reducers/sectionStudent';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { BlockTitle, BlockWrap } from '@components/Generals/stylesheets/General.style';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import { TableSkillLevel } from '@components/Table/TableComponent.style';
import { TableHeaderTypes } from '@components/Atoms/Table';
import { useGetLearnosityLessonReviewScoresList } from './hooks';

// split the header title by underscore and Capitalize the first letter of each word
const reshapeHeaderTitle = (headerTitle: string) =>
  headerTitle
    .split('_')
    .join(' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

const generateColumns = (data): Array<TableHeaderTypes> => {
  const headerKeys = Object.keys(data || {});

  const columns = headerKeys?.map((headerKey, index) => ({
    id: index, // Update the id property to be of type number
    headerTitle: reshapeHeaderTitle(headerKey),
    relatedKey: headerKey,
    defaultValue: '--',
    // To display the skill level in a custom component
    valueGetter: (rowValue) => {
      if (headerKey === 'skill_level') {
        // we are using js file in ts file, so we need to ignore the error
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <TableSkillLevel data-testid="skill-level-indicator" level={Number(rowValue)}>
            {rowValue}
          </TableSkillLevel>
        );
      }
      return rowValue;
    },
    style: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
  }));

  return columns || [];
};

const getRows = (data, activeStudents) => {
  if (!data || activeStudents.length === 0) {
    return [];
  }
  const rows = data?.map((item) => {
    const activeStudent = activeStudents?.find(
      (student: any) => student?.id === item.student_id,
    );
    if (!activeStudent) {
      return;
    }

    // add extra data to the row
    return {
      student_name: `${activeStudent.first_name} ${activeStudent.last_name}`,
      score: `${item.auto ?? '--'}/${item.auto_max ?? '--'}`,
      skill_level: item.avg_skill_level ?? '-',
    };
  });
  return rows.filter((row) => row);
};
const ScoreTable = () => {
  const { sectionId } = useParams();
  const dispatch = useDispatch();
  const filteredSectionStudents = useSectionStudentWithFilter();

  // This effect to dispatch the action to get the section students.
  React.useEffect(() => {
    if (sectionId && filteredSectionStudents?.length === 0) {
      dispatch(SectionActions.sectionsStudentGet(sectionId));
    }
  }, [filteredSectionStudents?.length, dispatch, sectionId]);

  const { data } = useGetLearnosityLessonReviewScoresList();

  const rows = getRows(data, filteredSectionStudents);

  const columns = generateColumns(rows?.[0]);

  return (
    <BlockWrap>
      <BlockTitle>Assessment Score</BlockTitle>
      <TableWithWrapper
        sx={{ p: 0, overflow: 'auto', height: 'calc(100vh - 19.5rem)' }}
        columns={columns || []}
        rows={rows}
        hasPagination={false}
        headerSx={{
          background: 'white',
          position: 'sticky',
          top: 0,
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
        }}
      />
    </BlockWrap>
  );
};

export default ScoreTable;
