import Button from '@components/Atoms/Button';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const StandardsPracticeButton = ({ state, text, practiceSessionId = undefined }) => {
  const history = useHistory();
  const params = useParams();

  const navigateToStandardsPractice = () => {
    const { courseId, sectionId, unitId, lessonId, subjectId } = params;
    let route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/standards-practice`;

    if (state === 'review') {
      route += `/${practiceSessionId}/review`;
    } else if (state === 'preview') {
      route += '/preview';
    }

    history.push(route);
  };

  return (
    <Button variant="text" onClick={navigateToStandardsPractice}>
      {text}
    </Button>
  );
};

export default React.memo(StandardsPracticeButton);
