import React from 'react';
import styled from 'styled-components';
import { Button } from '../../Generals/stylesheets/Button.style';
import Select from './Select';
import { useIWContext } from '../IWContextProvider';
import { useIWGet } from '../hooks';

const SelectionPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 20rem;
  gap: 1.5rem;
  align-self: center;
`;

const SelectionImg = styled.img`
  width: 100%;
  object-fit: contain;
`;
export const teacherOption = [
  { value: 'Joseph Frankiln', displayName: 'Joseph Frankiln' },
  { value: 'Luis Vera', displayName: 'Luis Vera' },
  { value: 'Rodrigo Garcia', displayName: 'Rodrigo Garcia' },
  { value: 'Diego Solana', displayName: 'Diego Solana' },
];

const SelectionPage = () => {
  const [selectedTeacher, setSelectedTeacher] = React.useState('');
  const [selectedTemplate, setSelectedTemplate] = React.useState('');
  const { data: walkThroughs, invalidate } = useIWGet({
    defaultValue: [],
  });
  const { setSiderIWId: setIWId, setSelectedTeacher: setSelectedTeacherCTX } =
    useIWContext();
  const templateOptions = React.useMemo(
    () =>
      walkThroughs.map(({ id, name }) => ({
        value: id,
        displayName: name,
      })),
    [walkThroughs],
  );

  const handleStart = () => {
    setSelectedTeacherCTX(selectedTeacher);
    setIWId(selectedTemplate);
  };

  return (
    <SelectionPageWrapper>
      <SelectionImg src="/images/teachers.svg" />

      <p>Select a teacher for a class walkThrough</p>
      <Select
        label="Teacher"
        options={teacherOption}
        onChange={(e) => {
          setSelectedTeacher(e.target.value);
        }}
        value={selectedTeacher}
      />
      <p>Select a class walkThrough template”</p>
      <Select
        label="Template"
        options={templateOptions}
        onChange={(e) => {
          setSelectedTemplate(e.target.value);
        }}
        value={selectedTemplate}
      />
      <Button
        full
        blue
        disabled={!selectedTeacher || !selectedTemplate}
        isDisable={selectedTeacher === '' || selectedTemplate === ''}
        onClick={handleStart}
      >
        Start walkThrough
      </Button>
    </SelectionPageWrapper>
  );
};

export default SelectionPage;
