import React from 'react';
import { useLocation, Link } from 'react-router-dom'
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { BlockTitle, BlockWrap, PrimaryWrap } from '../Generals/stylesheets/General.style';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { GoBackBtn, ReportLink } from './GeneratedReports.style';





const GeneratedReports = () => {
  const { state } = useLocation()
  const { generatedReports } = state
  return (
    <>
      <GoBackBtn>
        <Link to="/admin/reports/generator">
          <ArrowCircleLeftIcon />
          <span>Back to Report Generator</span>
        </Link>
      </GoBackBtn>
      <PrimaryWrap fullHeight>
        <BlockWrap fullWidth report>
          <BlockTitle>
            <span>Generated Reports</span>
          </BlockTitle>
          <ul className="report-list">
            {
              generatedReports.map((report) =>
                <li key={report.id}>
                  <ReportLink>
                    <Link to={{ pathname: `/admin/reports/generator/${report.id}`, state: { pdf: report.path, from: "/admin/reports/generator/list", generatedReports } }}>
                      <img src="/images/pdf-file.svg" />
                      <span>{report.name}</span>
                    </Link>
                  </ReportLink>
                </li>
              )}

          </ul>
        </BlockWrap>
      </PrimaryWrap>
    </>
  )
}

export default GeneratedReports;
