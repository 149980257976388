import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import { createSelector } from 'reselect';

// action definition
export const CURRICULUM_LESSONS_GET_DETAIL = 'curriculumLessons/GET_DETAIL';
export const CURRICULUM_LESSONS_GET_DETAIL_SUCCESS =
  'curriculumLessons/GET_DETAIL_SUCCESS';
export const CURRICULUM_LESSONS_GET_DETAIL_FAIL = 'curriculumLessons/GET_DETAIL_FAIL';

export const CURRICULUM_LESSONS_GET_STANDARD = 'curriculumLessons/GET_STANDARD';
export const CURRICULUM_LESSONS_GET_STANDARD_SUCCESS =
  'curriculumLessons/GET_STANDARD_SUCCESS';
export const CURRICULUM_LESSONS_GET_STANDARD_FAIL = 'curriculumLessons/GET_STANDARD_FAIL';

export const CURRICULUM_LESSONS_GET_OER = 'curriculumLessons/GET_OER';
export const CURRICULUM_LESSONS_GET_OER_SUCCESS = 'curriculumLessons/GET_OER_SUCCESS';
export const CURRICULUM_LESSONS_GET_OER_FAIL = 'curriculumLessons/GET_OER_FAIL';

export const CURRICULUM_LESSON_GET_ATTENDANCE = 'lessons/GET_ATTENDANCE';
export const CURRICULUM_LESSON_GET_ATTENDANCE_SUCCESS = 'lessons/GET_ATTENDANCE_SUCCESS';
export const CURRICULUM_LESSON_GET_ATTENDANCE_FAIL = 'lessons/GET_ATTENDANCE_FAIL';

export const CURRICULUM_LESSON_POST_ATTENDANCE = 'lessons/ATTENDANCE_SUBMISSION';
export const CURRICULUM_LESSON_POST_ATTENDANCE_SUCCESS =
  'lessons/ATTENDANCE_SUBMIT_SUCCESS';
export const CURRICULUM_LESSON_POST_ATTENDANCE_FAIL = 'lessons/ATTENDANCE_SUBMIT_FAIL';

export const CURRICULUM_LESSON_GET_WITH_UNIT = 'curriculumLessons/GET_WITH_UNIT';
export const CURRICULUM_LESSON_GET_WITH_REVIEW = 'curriculumLessons/GET_WITH_REVIEW';

export const CURRICULUM_LESSON_GET_WITH_UNIT_META =
  'curriculumLessons/GET_WITH_UNIT_META';

export const CURRICULUM_LESSON_POST_SLIDE = 'curriculumLessons/POST_SLIDE';
export const CURRICULUM_LESSON_POST_SLIDE_SUCCESS =
  'curriculumLessons/POST_SLIDE_SUCCESS';
export const CURRICULUM_LESSON_POST_SLIDE_FAIL = 'curriculumLessons/POST_SLIDE_FAIL';

export const CURRICULUM_LESSON_UPDATE_REVIEW_STATUS =
  'curriculumLessons/UPDATE_REVIEW_STATUS';
export const CURRICULUM_LESSON_UPDATE_REVIEW_STATUS_SUCCESS =
  'curriculumLessons/UPDATE_REVIEW_STATUS_SUCCESS';
export const CURRICULUM_LESSON_UPDATE_REVIEW_STATUS_FAIL =
  'curriculumLessons/UPDATE_REVIEW_STATUS_FAIL';

export const CURRICULUM_LESSON_GET_REVIEWS = 'curriculumLessons/GET_REVIEWS';
export const CURRICULUM_LESSON_GET_REVIEWS_SUCCESS =
  'curriculumLessons/GET_REVIEWS_SUCCESS';
export const CURRICULUM_LESSON_GET_REVIEWS_FAIL = 'curriculumLessons/GET_REVIEWS_FAIL';

export const CURRICULUM_LESSON_RESET_ATTENDANCE =
  'curriculumLessons/CURRICULUM_LESSON_RESET_ATTENDANCE';
//  selector
const getLessonByLessonId = ({ curriculumLessons }, lessonId) =>
  _get(curriculumLessons, `lessonByIds.${lessonId}`, {});
const getLessonDetailById = ({ curriculumLessons }, lessonId) =>
  _get(curriculumLessons, `lessonDetail.${lessonId}`, {});
const getLessonStandard = ({ curriculumLessons }) =>
  curriculumLessons.standardGroupData || [];
// const getLessonReviewsById = ({ curriculumLessons }, lessonId) =>

const getOerByLessonId = ({ curriculumLessons }, lessonId) =>
  _get(curriculumLessons, `oerByLessonId.${lessonId}`, []);
const getLessonById = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'lessonByIds', {});
const getLessonList = (state) => Object.values(getLessonById(state)) || [];
// const getPracticableLessonList = state => (Object.values(getLessonById(state)) || []).filter(lesson => lesson.can_practice);
const getPracticableLessonList = createSelector(getLessonById, (lessonById) =>
  (Object.values(lessonById) || []).filter((lesson) => lesson.can_practice),
);
const getLessonBySubjectId = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'lessonBySubjectId', {});
const isGettingLessonDetail = ({ curriculumLessons }) => curriculumLessons.isGetDetail;

const getLessonAttendanceById = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'lessonAttendanceById', {});
const getLessonAttendanceList = createSelector(
  getLessonAttendanceById,
  (lessonAttendanceById) => Object.values(lessonAttendanceById) || [],
);

const getOerCategories = createSelector(getOerByLessonId, (oers) => [
  ...new Set(
    oers
      .sort(
        (a, b) =>
          (a.category_order === null) - (b.category_order === null) ||
          +(a.category_order > b.category_order) ||
          -(a.category_order < b.category_order),
      )
      .map(({ source_category: category }) => category),
  ),
]);

const getCategorizedOers = createSelector(
  getOerCategories,
  getOerByLessonId,
  (categories, oers) =>
    categories.map((category) => ({
      category,
      oers: oers.filter((o) => o.source_category === category),
    })),
);

const getLessonAttendanceListByLessonId = (state, lessonId) => {
  const lessonAttendanceList = getLessonAttendanceList(state);
  return lessonAttendanceList.filter((attendance) => attendance.lesson === lessonId);
};

// get the attendance list for OLP.
const getGroupAttendanceById = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'groupAttendanceById', {});

const getGroupAttendanceList = createSelector(
  getGroupAttendanceById,
  (groupAttendanceById) => Object.values(groupAttendanceById) || [],
);
const getGroupAttendanceListByLessonId = (state, lessonId) => {
  const groupAttendanceList = getGroupAttendanceList(state);
  return groupAttendanceList.filter((attendance) => attendance.lesson === lessonId);
};

function filterAttendance(attendanceListArr, activityType) {
  return attendanceListArr.filter(({ activity }) => activity === activityType);
}

const isSubmittingSlide = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'isSubmittingSlide', false);
const getCurriculumLessonReviewRequests = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'reviewRequests', []);
const isGettingReviews = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'isGettingReviews', false);
const getCurriculumLessonReviews = ({ curriculumLessons }) =>
  _get(curriculumLessons, 'lessonReviews', []);

export const selectors = {
  getLessonByLessonId,
  getLessonStandard,
  getOerByLessonId,
  getOerCategories,
  getCategorizedOers,
  getLessonById,
  getLessonBySubjectId,
  isGettingLessonDetail,
  getLessonList,
  getPracticableLessonList,
  getLessonDetailById,
  getLessonAttendanceById,
  getLessonAttendanceList,
  getLessonAttendanceListByLessonId,
  getGroupAttendanceById,
  getGroupAttendanceList,
  getGroupAttendanceListByLessonId,
  isSubmittingSlide,
  getCurriculumLessonReviewRequests,
  isGettingReviews,
  getCurriculumLessonReviews,
};
// action creators

const curriculumLessonGetWithReview = (lessonList) => ({
  type: CURRICULUM_LESSON_GET_WITH_REVIEW,
  lessonList,
});

const curriculumLessonGetWithUnit = (lessonList) => ({
  type: CURRICULUM_LESSON_GET_WITH_UNIT,
  lessonList,
});

const curriculumLessonGetWithUnitMeta = (lessonList, lessonBySubjectId) => ({
  type: CURRICULUM_LESSON_GET_WITH_UNIT_META,
  lessonList,
  lessonBySubjectId,
});

/**
 * @param {String} sectionId
 */

const curriculumLessonsGetDetail = (lessonId) => ({
  type: CURRICULUM_LESSONS_GET_DETAIL,
  lessonId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonsGetDetailSuccess = (lessonInfo) => ({
  type: CURRICULUM_LESSONS_GET_DETAIL_SUCCESS,
  lessonInfo,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonsGetDetailFail = (error) => ({
  type: CURRICULUM_LESSONS_GET_DETAIL_FAIL,
  error,
});

/**
 * @param {String} sectionId
 * @param {String} standardGroupId
 */

const curriculumLessonsGetStandard = (lessonId, standardGroupId) => ({
  type: CURRICULUM_LESSONS_GET_STANDARD,
  lessonId,
  standardGroupId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonsGetStandardSuccess = (payload) => ({
  type: CURRICULUM_LESSONS_GET_STANDARD_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonsGetStandardFail = (payload) => ({
  type: CURRICULUM_LESSONS_GET_STANDARD_FAIL,
  payload,
});

/**
 * @param {String} sectionId
 */

const curriculumLessonsGetOER = (lessonId) => ({
  type: CURRICULUM_LESSONS_GET_OER,
  lessonId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonsGetOERSuccess = (lessonId, payload) => ({
  type: CURRICULUM_LESSONS_GET_OER_SUCCESS,
  payload,
  lessonId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonsGetOERFail = (payload) => ({
  type: CURRICULUM_LESSONS_GET_OER_FAIL,
  payload,
});

/**
 * @param {number} lessonId
 * @param {String} sectionId
 */
const curriculumLessonGetAttendance = (lessonId, sectionId) => ({
  type: CURRICULUM_LESSON_GET_ATTENDANCE,
  lessonId,
  sectionId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonGetAttendanceSuccess = (payload) => ({
  type: CURRICULUM_LESSON_GET_ATTENDANCE_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonGetAttendanceFail = (payload) => ({
  type: CURRICULUM_LESSON_GET_ATTENDANCE_FAIL,
  payload,
});

/**
 * @param {String} lessonId
 * @param {String} lessonAttendanceInfo
 */
const curriculumLessonPostAttendance = (lessonId, lessonAttendanceInfo) => ({
  type: CURRICULUM_LESSON_POST_ATTENDANCE,
  lessonAttendanceInfo,
  lessonId,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonPostAttendanceSuccess = (payload) => ({
  type: CURRICULUM_LESSON_POST_ATTENDANCE_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const curriculumLessonPostAttendanceFail = (payload) => ({
  type: CURRICULUM_LESSON_POST_ATTENDANCE_FAIL,
  payload,
});

const curriculumLessonPostSlide = (lessonId, slideInfo) => ({
  type: CURRICULUM_LESSON_POST_SLIDE,
  lessonId,
  slideInfo,
});

const curriculumLessonPostSlideSuccess = (payload) => ({
  type: CURRICULUM_LESSON_POST_SLIDE_SUCCESS,
  payload,
});

const curriculumLessonPostSlideFail = (payload) => ({
  type: CURRICULUM_LESSON_POST_SLIDE_FAIL,
  payload,
});

const curriculumLessonUpdateReviewStatus = (lessonId, reviewId, reviewInfo) => ({
  type: CURRICULUM_LESSON_UPDATE_REVIEW_STATUS,
  lessonId,
  reviewId,
  reviewInfo,
});

const curriculumLessonUpdateReviewStatusSuccess = (payload) => ({
  type: CURRICULUM_LESSON_UPDATE_REVIEW_STATUS_SUCCESS,
  payload,
});

const curriculumLessonUpdateReviewStatusFail = (payload) => ({
  type: CURRICULUM_LESSON_UPDATE_REVIEW_STATUS_FAIL,
  payload,
});

const curriculumLessonGetReviews = (params) => ({
  type: CURRICULUM_LESSON_GET_REVIEWS,
  params,
});

const curriculumLessonGetReviewsSuccess = (payload) => ({
  type: CURRICULUM_LESSON_GET_REVIEWS_SUCCESS,
  payload,
});

const curriculumLessonGetReviewsFail = (payload) => ({
  type: CURRICULUM_LESSON_GET_REVIEWS_FAIL,
  payload,
});

export const actions = {
  // get detail
  curriculumLessonsGetDetail,
  curriculumLessonsGetDetailSuccess,
  curriculumLessonsGetDetailFail,
  // get standard
  curriculumLessonsGetStandard,
  curriculumLessonsGetStandardSuccess,
  curriculumLessonsGetStandardFail,
  // get oer
  curriculumLessonsGetOER,
  curriculumLessonsGetOERSuccess,
  curriculumLessonsGetOERFail,
  // get lesson with unit
  curriculumLessonGetWithUnit,

  curriculumLessonGetWithUnitMeta,

  curriculumLessonGetWithReview,

  // get lesson attendance
  curriculumLessonGetAttendance,
  curriculumLessonGetAttendanceSuccess,
  curriculumLessonGetAttendanceFail,

  // submit lesson attendance
  curriculumLessonPostAttendance,
  curriculumLessonPostAttendanceSuccess,
  curriculumLessonPostAttendanceFail,

  // submit lesson slide
  curriculumLessonPostSlide,
  curriculumLessonPostSlideSuccess,
  curriculumLessonPostSlideFail,

  // update slide review
  curriculumLessonUpdateReviewStatus,
  curriculumLessonUpdateReviewStatusSuccess,
  curriculumLessonUpdateReviewStatusFail,

  // get review list
  curriculumLessonGetReviews,
  curriculumLessonGetReviewsSuccess,
  curriculumLessonGetReviewsFail,
};

// reducers

const initialState = {
  isGetDetail: false, // is get get lesson detail of curriculum
  isGetStandard: false, // get standard of curriculum
  isGetOER: false, // get OER of curriculum
  lessonByIds: {},
  lessonIds: [],
  standardGroupData: [],
  error: null,
  oerByLessonId: {},
  lessonBySubjectId: {},
  lessonDetail: null,
  lessonAttendanceById: {},
  groupAttendanceById: {},
  errorAttendance: null,
  isSubmittingAttendance: false,
  attendanceSubmitted: false,
  attendanceSubmissionError: '',
  isSubmittingSlide: false,
  slideSubmissionError: '',
  isGettingReviews: false,
  reviewRequests: [],
  isFetchingReviewRequests: false,
  reviewFetchingError: '',
  lessonReviews: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    // get detail
    case CURRICULUM_LESSONS_GET_DETAIL:
      return { ...state, isGetDetail: true, lessonDetail: null, lessonReviews: [] };
    case CURRICULUM_LESSONS_GET_DETAIL_SUCCESS: {
      const { lessonInfo } = action;
      const { id, lessons } = lessonInfo;

      return {
        ...state,
        isGetDetail: false,
        lessonDetail: {
          [id]: lessonInfo,
        },
        // lessons[0] is not always true, lessons can be empty array
        lessonReviews: lessons?.[0]?.lesson_reviews,
      };
    }
    case CURRICULUM_LESSONS_GET_DETAIL_FAIL: {
      const { error } = action;
      return {
        ...state,
        isGetDetail: false,
        error,
      };
    }
    // get standard
    case CURRICULUM_LESSONS_GET_STANDARD:
      return { ...state, isGetStandard: true };

    case CURRICULUM_LESSONS_GET_STANDARD_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        standardGroupData: payload,
        isGetStandard: false,
      };
    }

    case CURRICULUM_LESSONS_GET_STANDARD_FAIL:
      return { ...state, isGetStandard: false };

    case CURRICULUM_LESSONS_GET_OER:
      return { ...state, isGetOER: true };
    case CURRICULUM_LESSONS_GET_OER_SUCCESS: {
      const { payload, lessonId } = action;
      return {
        ...state,
        isGetOER: false,
        oerByLessonId: {
          ...state.oerByLessonId,
          [lessonId]: payload,
        },
      };
    }
    case CURRICULUM_LESSONS_GET_OER_FAIL:
      return { ...state, isGetOER: false };
    case CURRICULUM_LESSON_GET_WITH_REVIEW:
    case CURRICULUM_LESSON_GET_WITH_UNIT: {
      const { lessonList } = action;
      const lessonByIds = _keyBy(lessonList, 'id');
      const lessonIds = lessonList.map(({ id }) => id);

      return {
        ...state,
        lessonByIds: { ...state.lessonByIds, ...lessonByIds },
        lessonIds,
      };
    }
    case CURRICULUM_LESSON_GET_WITH_UNIT_META: {
      const { lessonList, lessonBySubjectId } = action;
      const lessonByIds = _keyBy(lessonList, 'id');
      const lessonIds = lessonList.map(({ id }) => id);

      return {
        ...state,
        lessonByIds: { ...state.lessonByIds, ...lessonByIds },
        lessonIds,
        lessonBySubjectId,
      };
    }

    case CURRICULUM_LESSON_GET_ATTENDANCE_SUCCESS: {
      const { payload = [] } = action;
      const lessonAttendanceArr = filterAttendance(payload, 'lesson');
      const groupAttendanceArr = filterAttendance(payload, 'group');
      const lessonAttendanceById =
        lessonAttendanceArr.length > 0
          ? { [lessonAttendanceArr[0].lesson]: lessonAttendanceArr }
          : {};
      const groupAttendanceById =
        groupAttendanceArr.length > 0
          ? { [groupAttendanceArr[0].lesson]: groupAttendanceArr }
          : {};

      return {
        ...state,
        lessonAttendanceById: {
          ...state.lessonAttendanceById,
          ...lessonAttendanceById,
        },
        groupAttendanceById: {
          ...state.groupAttendanceById,
          ...groupAttendanceById,
        },
        errorAttendance: null,
      };
    }

    case CURRICULUM_LESSON_GET_ATTENDANCE_FAIL: {
      const { error } = action;
      return {
        ...state,
        errorAttendance: error,
      };
    }

    case CURRICULUM_LESSON_POST_ATTENDANCE: {
      return {
        ...state,
        isSubmittingAttendance: true,
        attendanceSubmitted: false,
        attendanceSubmissionError: '',
      };
    }
    case CURRICULUM_LESSON_POST_ATTENDANCE_SUCCESS: {
      const { payload } = action;
      const {
        lessonAttendanceById: stateLessonAttendanceById,
        groupAttendanceById: stateGroupAttendanceById,
      } = state;

      const newLessonAttendanceById = { ...stateLessonAttendanceById };
      const newGroupAttendanceById = { ...stateGroupAttendanceById };

      payload.forEach((attendanceItem) => {
        const { id, student, activity, present, lesson } = attendanceItem;
        const isLesson = activity === 'lesson';
        const attendanceByIdListToUpdate = isLesson
          ? newLessonAttendanceById
          : newGroupAttendanceById;
        const newLessonAttendance = newLessonAttendanceById[lesson] || [];
        const newGroupAttendance = newGroupAttendanceById[lesson] || [];
        const listToSearch = isLesson ? newLessonAttendance : newGroupAttendance;
        const found = listToSearch.findIndex(
          ({ student: studentId }) => studentId === student,
        );
        // if found and id is null, remove it from list
        // this happens when the teacher unsets the attendance
        if (found > -1) {
          // if found and id is not null, update it
          listToSearch[found] = {
            ...listToSearch[found],
            present: id === null ? null : present,
            id,
          };
        } else {
          // if not found, add it
          listToSearch.push(attendanceItem);
        }
        attendanceByIdListToUpdate[lesson] = listToSearch;
      });

      return {
        ...state,
        isSubmittingAttendance: false,
        attendanceSubmitted: true,
        lessonAttendanceById: newLessonAttendanceById,
        groupAttendanceById: newGroupAttendanceById,
      };
    }

    case CURRICULUM_LESSON_POST_ATTENDANCE_FAIL: {
      const { error } = action;
      return {
        ...state,
        isSubmittingAttendance: false,
        attendanceSubmissionError: error,
      };
    }
    case CURRICULUM_LESSON_RESET_ATTENDANCE: {
      return {
        ...state,
        groupAttendanceById: {},
      };
    }
    case CURRICULUM_LESSON_POST_SLIDE:
    case CURRICULUM_LESSON_UPDATE_REVIEW_STATUS: {
      return {
        ...state,
        isSubmittingSlide: true,
        slideSubmissionError: '',
      };
    }

    case CURRICULUM_LESSON_POST_SLIDE_SUCCESS:
    case CURRICULUM_LESSON_UPDATE_REVIEW_STATUS_SUCCESS: {
      const { payload } = action;
      let { reviewRequests } = state;
      if (payload.status === 'Rejected' || payload.status === 'Approved') {
        reviewRequests = reviewRequests.filter(({ id }) => id !== payload.id);
      }
      return {
        ...state,
        isSubmittingSlide: false,
        slideSubmissionError: '',
        lessonReviews: [payload],
        reviewRequests,
      };
    }

    case CURRICULUM_LESSON_POST_SLIDE_FAIL:
    case CURRICULUM_LESSON_UPDATE_REVIEW_STATUS_FAIL: {
      const { error } = action;
      return {
        ...state,
        isSubmittingSlide: false,
        slideSubmissionError: error,
      };
    }

    case CURRICULUM_LESSON_GET_REVIEWS: {
      return {
        ...state,
        isGettingReviews: true,
        reviewRequests: [],
        reviewFetchingError: '',
      };
    }

    case CURRICULUM_LESSON_GET_REVIEWS_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        isGettingReviews: false,
        reviewRequests: payload,
        reviewFetchingError: '',
      };
    }

    case CURRICULUM_LESSON_GET_REVIEWS_FAIL: {
      const { error } = action;
      return {
        ...state,
        isGettingReviews: false,
        reviewRequests: [],
        reviewFetchingError: error,
      };
    }

    default: {
      return { ...state };
    }
  }
}
