/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _isArray from 'lodash/isArray';
import FormInput from '../../Generals/FormInput/FormInput';
import {
  LoginItemWrap,
  LoginItem,
  LoginTitle,
  ButtonStyled,
  LoginPasswordAction,
  ResetPasswordMessage, LoginErrorMessage,
} from '../../LoginPage/Login.style';
import { Title, Paragraph } from '../../LoginPage/SignUpType.style';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').trim().required('Invalid email'),
});

export default class ForgotPasswordForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isSentResetPasswordEmail: PropTypes.bool.isRequired,
    errorMessage: PropTypes.any,
  }

  static defaultProps = {
    errorMessage: '',
  }

  handleInputChanged = fieldName => ({ target }) => {
    this.setState({
      [fieldName]: target.value,
    });
  }

  submitFotgotPassword = (values) => {
    const { email } = values;
    const { resetPassword } = this.props;
    resetPassword(email);
  }

  renderEmailAction = () => (
    <LoginPasswordAction>
      <Link to="/forgot-email">Forgot your email address?</Link>
    </LoginPasswordAction>
  );

  renderVerifyBtn = () => {
    const { isLoading } = this.props;
    return (
      isLoading
        ? <ButtonStyled primary>Loading ...</ButtonStyled>
        : <ButtonStyled type="submit">Reset Password</ButtonStyled>
    );
  }

  renderResetPasswordSuccess = () => (
    <ResetPasswordMessage>Password reset e-mail has been sent</ResetPasswordMessage>
  )

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>

  renderErrorList = (errorList) => {
    if (!errorList) return null;
    if (_isArray(errorList)) {
      return (
        <LoginErrorMessage>
          {errorList.map(this.renderErrorMessage)}
        </LoginErrorMessage>
      );
    }
    return (
      <LoginErrorMessage>
        {this.renderErrorMessage(errorList, 1)}
      </LoginErrorMessage>
    );
  }

  render() {
    const { isSentResetPasswordEmail, errorMessage } = this.props;
    return (
      <LoginItemWrap>
        <LoginItem>
          <Title>
            Forgot your password ?
          </Title>
          <Paragraph>Accident happens , but the fun never stops !</Paragraph>
          { isSentResetPasswordEmail
            ? this.renderResetPasswordSuccess()
            : (
              <Formik
                initialValues={{ email: '' }}
                validationSchema={validationSchema}
                onSubmit={this.submitFotgotPassword}
              >
                <Form noValidate>
                  <FormInput
                    type="email"
                    name="email"
                    label="Email Address"
                  />
                  {this.renderEmailAction()}
                  {this.renderErrorList(errorMessage)}
                  {this.renderVerifyBtn()}
                </Form>
              </Formik>
            )}
          <Link to="/login">
            <ButtonStyled primary>{isSentResetPasswordEmail ? 'Go to login' : 'Back'}</ButtonStyled>
          </Link>
        </LoginItem>
      </LoginItemWrap>
    );
  }
}
