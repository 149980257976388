import { toast } from 'react-toastify';

export const success = msg => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const warning = msg => {
  toast.warn(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const error = (msg,id) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    toastId:id,
  });
};

export const info = (msg, options) => {
  toast.info(msg, {
    position: toast.POSITION.TOP_RIGHT,
    ...options,
  });
};

export const defaultToast = msg => {
  toast(msg, {
    position: toast.POSITION.TOP_RIGHT,
    className: 'toast-dark',
  });
};

export const failed = msg => {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const accepted = msg => {
  toast.success(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const diconnected = msg => {
  toast.error(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: false,
  });
};

export const offline = (msg, options) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
    ...options
  });
};

const Toast = {
  success,
  warning,
  error,
  info,
  failed,
  accepted,
  defaultToast,
  diconnected,
  offline,
};

export default Toast;
