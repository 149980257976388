import styled, { css } from 'styled-components';

export const ExamIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
`;
export const ExamMessageWrapper = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateY(-50%);
  .card {
    display: flex;
    border-radius: 5px;
    padding: 20px;
    border: 1px solid #4886fc;
    transform: translateX(-50%);
    width: 400px;
  }
  .msg-content {
    text-align: left;
    line-height: 25px;
  }
`;
export const ExamMessageContent = styled.div``;
export const StrongContent = styled.div`
  font-size: 25px;
  ${({ md }: { md?: boolean }) =>
    md &&
    css`
      font-size: 20px;
    `}
`;
export const IconWarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }
`;
