import React, { useEffect, useState } from 'react';
import Box from '@components/Atoms/Box';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';
import TextField from '@components/Atoms/TextField';
import { LearnosityItem } from './types';

interface FeedbackUpdate {
  reference: string;
  feedback: {
    rating: number;
    text: string;
  };
}

const FeedbackGroup: React.FC<{
  item: LearnosityItem;
  updateItem: (item: FeedbackUpdate) => void;
}> = ({ item, updateItem }) => {
  const [openFeedback, setOpenFeedback] = useState(false);
  const [currentRating, setCurrentRating] = useState(item?.feedback?.rating || 0);
  const [feedbackText, setFeedbackText] = useState(item?.feedback?.text || '');
  const [tempFeedbackText, setTempFeedbackText] = useState(feedbackText);

  useEffect(() => {
    updateItem({
      reference: item.reference,
      feedback: {
        rating: currentRating,
        text: feedbackText,
      },
    });
  }, [currentRating, feedbackText, item, updateItem]);

  const setUnsetRating = (rating: number) => {
    setCurrentRating(currentRating === rating ? 0 : rating);
  };

  const handleOpenDialog = () => {
    setTempFeedbackText(feedbackText);
    setOpenFeedback(true);
  };

  const handleSaveFeedback = () => {
    setFeedbackText(tempFeedbackText);
    setOpenFeedback(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      color="black"
      sx={{ paddingInlineEnd: '0.625rem' }}
    >
      <span>Rate this question:</span>
      <ThumbUpOutlinedIcon
        sx={{ transform: 'scaleX(-1)', ml: '0.5rem', cursor: 'pointer' }}
        color={currentRating === 1 ? 'primary' : 'inherit'}
        onClick={() => setUnsetRating(1)}
      />
      <ThumbDownOutlinedIcon
        sx={{ transform: 'scaleX(-1)', ml: '0.5rem', cursor: 'pointer' }}
        color={currentRating === -1 ? 'primary' : 'inherit'}
        onClick={() => setUnsetRating(-1)}
      />
      <RateReviewOutlinedIcon
        sx={{ ml: '0.5rem', cursor: 'pointer' }}
        onClick={() => handleOpenDialog()}
      />
      <Dialog
        title="Tell us what you think about this question..."
        sx={{ mt: -150 }}
        open={openFeedback}
        maxWidth="sm"
        onClose={() => setOpenFeedback(false)}
        actions={[
          {
            label: 'cancel',
            onClick: () => setOpenFeedback(false),
          },
          {
            label: 'Save',
            onClick: () => handleSaveFeedback(),
            buttonProps: {
              variant: 'contained',
            },
          },
        ]}
      >
        <Typography>
          The more feedback you give, the better our AI can learn your preferences and
          deliver more accurate suggestions in the future - saving you time!
        </Typography>
        <Typography
          sx={{
            mt: '1rem',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Rate this question:
          <ThumbUpOutlinedIcon
            sx={{ transform: 'scaleX(-1)', margin: '5px', cursor: 'pointer' }}
            color={currentRating === 1 ? 'primary' : 'inherit'}
            onClick={() => setUnsetRating(1)}
          />
          <ThumbDownOutlinedIcon
            sx={{ transform: 'scaleX(-1)', margin: '5px', cursor: 'pointer' }}
            color={currentRating === -1 ? 'primary' : 'inherit'}
            onClick={() => setUnsetRating(-1)}
          />
        </Typography>
        <TextField
          variant="outlined"
          label="Provide your feedback for this question..."
          multiline
          sx={{ mt: '1rem' }}
          rows={4}
          value={tempFeedbackText}
          onChange={(e) => setTempFeedbackText(e.target.value)}
          fullWidth
        />
      </Dialog>
    </Box>
  );
};

export default FeedbackGroup;
