import { useSelector } from 'react-redux';
import { getCourseSelected, selectors } from './courseReducer';

// eslint-disable-next-line import/prefer-default-export
export const useCurrentCourse = () => {
  const currentCourseId = useSelector(getCourseSelected);
  const currentCourse = useSelector((state) =>
    selectors.getCourseDetailById(
      state as {
        courses: any;
      },
      currentCourseId,
    ),
  );
  return currentCourse;
};
export const useCourseById = (id: number) => {
  const currentCourse = useSelector((state) =>
    selectors.getCourseDetailById(
      state as {
        courses: any;
      },
      id,
    ),
  );
  return currentCourse;
};
