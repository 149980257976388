import { connect } from 'react-redux';
import StudentGroupInviteItem from '../../components/StudentGroupInviteItem';
import { selectors } from '../../reducers/sections';
import {
  actions as SectionStudentActions,
  selectors as SectionStudentSelectors,
} from '../../reducers/sectionStudent';

const mapStateToProps = (state) => {
  const sectionData = selectors.getFirstSection(state);
  const { id: sectionId } = sectionData;

  return {
    sectionId,
  };
};

const mapDispatchToProps = {
  submitInviteStudent: SectionStudentActions.sectionsStudentInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentGroupInviteItem);
