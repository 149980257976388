import React from 'react';
import LoadingIndicator from '@components/LoadingIndicator';
import { useGetQuestions } from '../../Hooks/Query';
import { Preview, PreviewProps } from './Preview';

interface StandardsPreviewProps extends Omit<PreviewProps, 'questions'> {
  publication: string;
  standards: string[];
  submissionHandler?: () => void;
  questionCount: number;
  offset: number;
  language?: string;
}

const StandardsPreview = (props: StandardsPreviewProps) => {
  const { language, activityId, questionCount, publication, standards, userId } = props;

  const { data: questions, isLoading } = useGetQuestions({
    language,
    lessonCode: activityId,
    limit: questionCount,
    publication,
    standards,
    useErrorBoundary: true,
    user: userId,
  });

  if (isLoading) {
    return <LoadingIndicator content="We are assembling the right questions for you" />;
  }

  return <Preview questions={questions} {...props} />;
};

export { StandardsPreview, StandardsPreviewProps };
