import { connect } from 'react-redux';
import RequestVerification from '../../components/RequestVerification';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../../reducers/auth';

export const mapStateToProps = state => ({
  verifyError: AuthSelectors.getApproveJoinCourseRequestError(state),
});

const mapDispatchToProps = {
  approveJoinCourseRequest: AuthActions.approveJoinCourseRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestVerification);
