import React, { Component } from 'react';
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import MediaQuery from 'react-responsive';
import ShowIf from '@components/Atoms/ShowIf';
import { CreateSectionModalWrapper } from './SectionCreationModal.style';
import PostCreatedSection from '../../../containers/Modals/PostCreatedSection';
import SkipDialogueModal from '../SkipDialogueModal';
import JoinSectionModal from '../../../containers/Modals/JoinSectionModal';
import EditScheduleForm from '../../../containers/ScheduleSection/EditScheduleForm';
import LoadingIndicator from '../../LoadingIndicator';
import {
  BottomButtons,
  SelectButton,
  StyledButton,
} from '../../ScheduleSection/EditScheduleForm.style';

const createOrJoinModalStyles = (isStep1) => ({
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    width: '629px',
    maxWidth: '1080px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '4px',
    border: 'none',
    maxHeight: '80%',
    height: '80%',
    padding: '20px 10px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
});

const createOrJoinMobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px 0 20px 0',
    maxWidth: '980px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    width: '100%',
    minWidth: '100%',
    height: '100%',
    maxHeight: '100%',
    borderRadius: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class SectionCreationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      newSectionCreated: null,
      isOpenSkipModal: false,
      isJoinModalOpen: false,
    };
  }

  componentDidMount() {
    const { curriculumId, courseId, getAllSectionsByCourse } = this.props;

    if (curriculumId) {
      this.fetchInitialData();
    }
    if (courseId) {
      getAllSectionsByCourse(courseId);
    }
  }

  componentDidUpdate(prevProps) {
    const { curriculumId } = this.props;
    const { curriculumId: prevCurriculumId } = prevProps;

    if (!_isEqual(curriculumId, prevCurriculumId) && curriculumId) {
      this.fetchInitialData();
    }
  }

  componentWillUnmount() {
    const { sectionClearError } = this.props;
    sectionClearError();
  }

  onChangeModalStyle = (matches) => ({
    ...createOrJoinModalStyles(this.state.activePage === 1),
    ...(matches && createOrJoinMobileModalStyles),
  });

  fetchInitialData = () => {
    const {
      curriculumId,
      getCurriculumSubjectList,
      getCurriculumPlacementType,
      getCurriculumExitType,
    } = this.props;

    getCurriculumSubjectList(curriculumId);
    getCurriculumPlacementType(curriculumId);
    getCurriculumExitType(curriculumId);
  };

  handleNewSectionCreated = (sectionDetail) => {
    this.setState({
      newSectionCreated: sectionDetail,
    });
  };

  openSkipDialogue = () => {
    this.setState({
      isOpenSkipModal: true,
      newSectionCreated: null,
    });
  };

  openJoinSectionModal = () => {
    this.setState({
      isJoinModalOpen: true,
    });
  };

  renderNewSectionCreated = () => {
    const { newSectionCreated } = this.state;

    return (
      <PostCreatedSection
        sectionDetail={newSectionCreated}
        openSkipDialogue={this.openSkipDialogue}
        onClose={this.props.onClose}
      />
    );
  };

  renderSkipModal = () => {
    const { onClose } = this.props;

    return <SkipDialogueModal onClose={onClose} />;
  };

  renderModalContent = () => {
    const { newSectionCreated, isOpenSkipModal, isJoinModalOpen } = this.state;

    if (isJoinModalOpen) {
      return <JoinSectionModal joinSectionTab={false} onClose={this.props.onClose} />;
    }

    if (isOpenSkipModal) {
      return this.renderSkipModal();
    }

    if (newSectionCreated) {
      return this.renderNewSectionCreated();
    }

    return (
      <CreateSectionModalWrapper>
        <EditScheduleForm
          onCloseModal={this.props.onClose}
          createSection
          handleNewSectionCreated={this.handleNewSectionCreated}
        />

        <BottomButtons>
          <SelectButton onClick={this.props.onClose} type="button" isSelected={false}>
            Cancel
          </SelectButton>
          <StyledButton type="submit" form="scheduleCreate">
            Save
          </StyledButton>
        </BottomButtons>
      </CreateSectionModalWrapper>
    );
  };

  render() {
    const { onClose, isCreatingNewSection } = this.props;
    return (
      <MediaQuery maxWidth={768}>
        {(matches) => (
          <Modal
            isOpen
            onRequestClose={onClose}
            style={this.onChangeModalStyle(matches)}
            ariaHideApp={false}
          >
            <ShowIf If={isCreatingNewSection}>
              <LoadingIndicator noBackground height100 />
            </ShowIf>
            <ShowIf If={!isCreatingNewSection}>{this.renderModalContent()}</ShowIf>
          </Modal>
        )}
      </MediaQuery>
    );
  }
}

SectionCreationModal.propTypes = {
  curriculumId: PropTypes.any,
  courseId: PropTypes.string,
  getCurriculumSubjectList: PropTypes.func,
  subjects: PropTypes.array,
  sectionsCreateNewSection: PropTypes.func,
  getCurriculumPlacementType: PropTypes.func,
  getCurriculumExitType: PropTypes.func,
  onClose: PropTypes.func,
  sectionClearError: PropTypes.func,
  courseData: PropTypes.object,
};

export default SectionCreationModal;
