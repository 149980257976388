import React from 'react';
import { Option } from '../CustomDropdown';
import { BaseSelector, BaseSelectorProps } from '../BaseSelector';

export type DokSelectorProps = Omit<BaseSelectorProps, 'label' | 'options'>;

export const DOK_MAP = {
  1: 'Recall',
  2: 'Skills and Concepts',
  3: 'Strategic Thinking',
  4: 'Extended Thinking',
};

const DOK_LEVELS: Option[] = Object.entries(DOK_MAP).map(([value, label]) => ({
  label,
  value,
}));

const DOK_TOOLTIP = 'Depth of Knowledge';

const optionLabel = (option: Option) => `${option.value} - ${option.label}`;

const selectedLabel = (selected: Option) => `DOK: ${selected.value} - ${selected.label}`;

export const DokSelector: React.FC<DokSelectorProps> = (props) => (
  <BaseSelector
    {...props}
    label="DOK"
    options={DOK_LEVELS}
    tooltip={DOK_TOOLTIP}
    optionLabel={optionLabel}
    selectedLabel={selectedLabel}
  />
);

export default DokSelector;
