import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import ShowIf from '@Atoms/ShowIf';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useHandleFinishStepOne } from '@components/Component/Lesson/LessonContainer/hooks';
import ShadowScrollbar from '@components/Scrollbar/ShadowScrollbars';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { useParams } from 'react-router-dom';
import { useCurrentCourse } from '@reducers/courses/hooks';
import {
  usePostLessonSessionActivity,
  usePostUserReportIssue,
} from '@components/Component/Lesson/LessonContainer/hooks/mutations';
import AttendanceCard from '@components/Component/Lesson/AttendanceRecordButton/AttendanceCard';
import PacingGuideInfoCard from '@components/Component/Lesson/PacingGuide/PacingGuideInfoCard';
import { useGetPacingGuideLessons } from '@components/Component/Lesson/PacingGuide/hooks';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import LessonReportModal from './LessonReportModal';
import {
  LessonManagementWrap,
  LessonManagementLeftWrap,
  LessonManagementRightWrap,
  LessonVideoTitlte,
  LessonHeaderContainer,
  ButtonsContainer,
} from '../LessonManagement/LessonManagement.style';
import LessonDetailItem from './LessonDetailItem';
import LessonComplete from './LessonComplete';
import ListLoader from '../LoadingPlaceholder/ListLoader';
import ToggleButton from '../Generals/ToggleButton';
import ActionButton from '../Generals/ActionButton/ActionButton';
import { initializeGAPI } from '../../utils/initializeGAPI';
import CompareVersionsModal from '../Modals/CompareVersionsModal';
import DropDownMenu from '../Generals/DropDownMenu';
import { ActionButtonGroup } from '../Generals/ActionButton/ActionButton.style';
import { FeedbackContainer, FeedbackIcon } from './LessonDetailTab.style';
import LoadingIndicator from '../LoadingIndicator';
import LessonSlidesViewer from './LessonSlidesViewer';

const scrollStyle = {
  height: 'calc(100vh - 13.5em)',
  width: '100%',
  minWidth: '18em',
};

const scrollStyleMobile = {
  height: 'calc(50vh - 10em)',
  width: '100%',
  marginTop: '1em',
  order: 1,
};

class LessonDetailTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUrl: '',
      selectedLessonNodeId: '',
      selectedSlide: 1,
      defaultPresentationId: '',
      copyPresentationId: '',
      currentPresentation: '',
      isModalOpen: false,
      isDropDownVisible: false,
      isOpen: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { lessonDetail, isGHEAllowed, isTeacher } = this.props;
    const subLessons = _get(lessonDetail, 'lessons', []);

    // Open the first lesson if there is only one lesson
    if (subLessons?.length === 1) {
      const expectedUrl = isTeacher
        ? subLessons[0]?.instructor_url
        : subLessons[0]?.student_url;

      this.openUrl(expectedUrl, subLessons[0]?.id);
    }
    // TODO: Clear this when we start working GFE
    if (isGHEAllowed) {
      initializeGAPI();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      isOpen,
      isLoading,
      selectedUrl,
      selectedLessonNodeId,
      currentPresentation,
      isDropDownVisible,
      isModalOpen,
      copyPresentationId,
      selectedSlide,
    } = this.state;

    if (
      _isEqual(nextProps, this.props) &&
      _isEqual(nextState.isOpen, isOpen) &&
      _isEqual(nextState.isLoading, isLoading) &&
      _isEqual(nextState.selectedUrl, selectedUrl) &&
      _isEqual(nextState.selectedLessonNodeId, selectedLessonNodeId) &&
      _isEqual(nextState.currentPresentation, currentPresentation) &&
      _isEqual(nextState.isDropDownVisible, isDropDownVisible) &&
      _isEqual(nextState.isModalOpen, isModalOpen) &&
      _isEqual(nextState.copyPresentationId, copyPresentationId) &&
      _isEqual(nextState.selectedSlide, selectedSlide)
    ) {
      return false;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      lessonId,
      copyPresentationId: propCopyPresentationId,
      lessonDetail,
      isTeacher,
    } = this.props;

    const { lessonId: prevLessonId } = prevProps;

    const { selectedUrl } = this.state;
    const { selectedSlide, defaultPresentationId, copyPresentationId } = this.state;
    const { selectedSlide: prevSelectedSlide } = prevState;
    const subLessons = _get(lessonDetail, 'lessons', []);
    if (lessonId !== prevLessonId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedUrl: '',
        selectedLessonNodeId: '',
        defaultPresentationId: '',
        currentPresentation: '',
      });
    }

    if (selectedSlide !== prevSelectedSlide) {
      if (selectedSlide === 1) {
        this.setState({ currentPresentation: defaultPresentationId });
      } else {
        this.setState({ currentPresentation: copyPresentationId });
      }
    }

    // this was removed to make the toggling between versions on reload
    // if (propCopyPresentationId !== prevCopyPresentationId) {
    this.setState({
      copyPresentationId: propCopyPresentationId,
    });
    if (propCopyPresentationId === undefined) {
      this.setState({ selectedSlide: 1 });
    }
    // }
    // the logic for select the lesson on the lesson buttons
    // it is not correct , and we have removed the render for that component
    // when there is only one lesson
    // so we have added this logic to select the first lesson
    if (selectedUrl === '' && subLessons?.length === 1) {
      this.setState({ selectedLessonNodeId: subLessons[0]?.id });
      const expectedUrl = isTeacher
        ? subLessons[0]?.instructor_url
        : subLessons[0]?.student_url;
      this.openUrl(expectedUrl || subLessons[0]?.instructor_url, subLessons[0]?.id);
    }
  }

  getLessonGoogleDriveIdByUrl = (url) => url?.match(/[-\w]{25,}/)?.shift();

  openUrl = (url, lessonId) => {
    const googleDrivePresentationId = this.getLessonGoogleDriveIdByUrl(url);
    this.setState({
      selectedUrl: url,
      selectedLessonNodeId: lessonId,
      defaultPresentationId: googleDrivePresentationId,
      currentPresentation: googleDrivePresentationId,
    });
    const {
      sectionId,
      unitId,
      postLessonActivity,
      userId,
      lessonDetail,
      handleIsFinishStepOne,
      isTeacher,
    } = this.props;
    const body = {
      unit: unitId,
      lesson: lessonId,
      section: sectionId,
      user: userId,
      lesson_group: lessonDetail.id,
    };
    if (!isTeacher && handleIsFinishStepOne) {
      handleIsFinishStepOne(lessonId, true);
    }
    // set to ChalkTalk Version tab when switching between lesson slides
    this.setState({ selectedSlide: 1 });
    postLessonActivity(body);
  };

  setCopyPresentationId = (presentationId) => {
    this.setState({ copyPresentationId: presentationId });
  };

  setSelectedSlide = (selectedSlide) => {
    this.setState({ selectedSlide });
  };

  setIsModalOpen = (isModalOpen) => {
    this.setState({ isModalOpen });
  };

  setIsDropDownVisible = () => {
    this.setState(({ isDropDownVisible }) => ({ isDropDownVisible: !isDropDownVisible }));
  };

  renderVideoRight = () => {
    const {
      selectedUrl,
      selectedLessonNodeId,
      selectedSlide,
      defaultPresentationId,
      copyPresentationId,
      isDropDownVisible,
      isLoading,
      currentPresentation,
    } = this.state;
    const { lessonDetail, isTeacher, isGHEAllowed } = this.props;

    const currentPresentationUrl = currentPresentation
      ? `https://drive.google.com/file/d/${currentPresentation}/preview`
      : selectedUrl;

    const subLessons = _get(lessonDetail, 'lessons', []);

    return (
      <LessonManagementRightWrap full={!subLessons.length}>
        <LessonHeaderContainer>
          <LessonVideoTitlte>
            <span>{lessonDetail?.name}</span>
            <ShowIf If={lessonDetail.id}>
              <FeedbackContainer onClick={this.openModal}>
                <FeedbackIcon src="/images/feedback.png" />
              </FeedbackContainer>
            </ShowIf>
          </LessonVideoTitlte>
          <ShowIf If={isTeacher && isGHEAllowed}>
            <ButtonsContainer>
              <ToggleButton
                options={['My Version', 'ChalkTalk Version']}
                selectedOption={selectedSlide}
                setSelectedOption={this.setSelectedSlide}
                copyPresentationId={copyPresentationId}
              />
              <ActionButtonGroup>
                <ActionButton
                  isDropDownVisible={isDropDownVisible}
                  setIsDropDownVisible={this.setIsDropDownVisible}
                />
                <DropDownMenu
                  isDropDownVisible={isDropDownVisible}
                  setIsDropDownVisible={this.setIsDropDownVisible}
                  lessonName={lessonDetail && lessonDetail.name}
                  defaultPresentationId={defaultPresentationId}
                  copyPresentationId={copyPresentationId}
                  setSelectedSlide={this.setSelectedSlide}
                  setCopyPresentationId={this.setCopyPresentationId}
                  setIsModalOpen={this.setIsModalOpen}
                  lessonNodeId={selectedLessonNodeId}
                  setIsLoading={(value) => this.setState({ isLoading: value })}
                  isLoading={isLoading}
                  {...this.props}
                />
              </ActionButtonGroup>
            </ButtonsContainer>
          </ShowIf>
        </LessonHeaderContainer>
        <ShowIf If={isLoading}>
          <LoadingIndicator />
        </ShowIf>
        <ShowIf If={!isLoading}>
          <LessonSlidesViewer
            currentPresentationUrl={currentPresentationUrl}
            lessonDetails={lessonDetail}
            selectedLessonNodeId={selectedLessonNodeId}
          />
        </ShowIf>
      </LessonManagementRightWrap>
    );
  };

  renderSubLessonItem = (lesson, index) => {
    const { lessonActivityById, isTeacher } = this.props;
    const data = _get(lessonActivityById, `${lesson.id}`, null);
    const checked = data ? 'completed' : 'incompleted';
    return (
      <LessonDetailItem
        index={index}
        lessonDetailItem={lesson}
        key={lesson.id}
        openUrl={this.openUrl}
        checked={checked}
        isTeacher={isTeacher}
      />
    );
  };

  onLessonChanged = (lessonId, status) => {
    const {
      sectionId,
      unitId,
      postLessonActivity,
      userId,
      lessonDetail,
      handleIsFinishStepOne,
    } = this.props;

    const subLessons = _get(lessonDetail, 'lessons', []);

    const body = {
      unit: unitId,
      lesson: subLessons[0].id,
      section: sectionId,
      user: userId,
      lesson_group: lessonId,
    };
    handleIsFinishStepOne(subLessons[0].id, status);
    postLessonActivity(body);
  };

  renderCompleteLessonItem = () => {
    const {
      lessonActivitySummaryById,
      lessonId,
      unitId,
      subjectId,
      updateSectionComplete,
      updateSectionUnComplete,
      sectionId,
    } = this.props;

    const payload = {
      unit: unitId,
      lesson: lessonId,
      subject: subjectId,
    };
    const isCompletedLesson = _get(
      lessonActivitySummaryById,
      `${lessonId}.completed`,
      false,
    );
    return (
      <LessonComplete
        key={lessonId}
        isCompletedLesson={isCompletedLesson}
        updateSectionComplete={updateSectionComplete}
        updateSectionUnComplete={updateSectionUnComplete}
        payload={payload}
        sectionId={sectionId}
        onLessonChanged={this.onLessonChanged}
      />
    );
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
    });
  };

  submitModal = (msg, slideNumber) => {
    const { userReportIssue } = this.props;
    const { mutate, isSuccess } = userReportIssue;
    mutate({
      content_object_id: +slideNumber,
      content_type: 'lesson',
      content: msg,
    });
    if (isSuccess) {
      this.setState({
        isOpen: false,
      });
    }
  };

  renderCompareVersionsModal = () => {
    const { isModalOpen, copyPresentationId, defaultPresentationId } = this.state;
    const { lessonDetail, isSubmittingSlide, updateReviewStatus, teacherName, reviewId } =
      this.props;
    const lessonNodeId = lessonDetail?.lessons?.[0]?.id;
    return (
      <CompareVersionsModal
        isModalOpen={isModalOpen}
        onCloseModal={() => this.setIsModalOpen(false)}
        copyPresentationId={copyPresentationId}
        defaultPresentationId={defaultPresentationId}
        lessonNodeId={lessonNodeId}
        isSubmittingSlide={isSubmittingSlide}
        isReviewer={false}
        updateReviewStatus={updateReviewStatus}
        teacherName={teacherName}
        reviewId={reviewId}
      />
    );
  };

  render() {
    const { isOpen } = this.state;

    const {
      lessonDetail,
      isGettingLessonDetail,
      isTeacher,
      curriculumDetail,
      pacingGuideLessonData,
    } = this.props;

    const subLessons = _get(lessonDetail, 'lessons', []);

    return (
      <DefaultContentWrap>
        {this.renderCompareVersionsModal()}
        <LessonReportModal
          isOpen={isOpen}
          onClose={() => this.closeModal()}
          onSubmit={this.submitModal}
          curriculum={curriculumDetail?.name}
          lessonTitle={lessonDetail?.name}
        />
        <LessonManagementWrap>
          {isGettingLessonDetail ? (
            <ListLoader />
          ) : (
            subLessons.length > 0 && (
              <MediaQuery maxWidth={1280}>
                {(matches) => (
                  <div style={matches ? scrollStyleMobile : scrollStyle}>
                    <ShowIf If={subLessons.length > 1}>
                      <LessonManagementLeftWrap>
                        {subLessons.map(this.renderSubLessonItem)}
                      </LessonManagementLeftWrap>
                    </ShowIf>

                    <ShowIf If={isTeacher}>
                      <LessonManagementLeftWrap>
                        {this.renderCompleteLessonItem()}
                      </LessonManagementLeftWrap>
                      <LessonManagementLeftWrap>
                        <AttendanceCard onLessonChange={this.onLessonChanged} />
                      </LessonManagementLeftWrap>
                    </ShowIf>
                    <LessonManagementLeftWrap>
                      <PacingGuideInfoCard
                        guideInfoText={pacingGuideLessonData?.lesson_slides_instruction}
                        taskDeliveryText={pacingGuideLessonData?.lesson_delivery}
                      />
                    </LessonManagementLeftWrap>
                  </div>
                )}
              </MediaQuery>
            )
          )}
          {this.renderVideoRight()}
        </LessonManagementWrap>
      </DefaultContentWrap>
    );
  }
}

LessonDetailTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lessonDetail: PropTypes.objectOf(PropTypes.any).isRequired,
  postLessonActivity: PropTypes.func.isRequired,
  userReportIssue: PropTypes.func.isRequired,
  courseId: PropTypes.string,
  sectionId: PropTypes.string.isRequired,
  subjectId: PropTypes.string,
  unitId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  lessonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  lessonActivityById: PropTypes.object,
  isTeacher: PropTypes.bool,
  handleIsFinishStepOne: PropTypes.func,
  isGettingLessonDetail: PropTypes.bool,
  copyPresentationId: PropTypes.string,
  isSubmittingSlide: PropTypes.bool,
  isGHEAllowed: PropTypes.bool,
  // practiceMeta: PropTypes.shape(),
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const handleIsFinishStepOne = useHandleFinishStepOne();
    const { lessonId, sectionId, unitId } = useParams();
    const courseData = useCurrentCourse();
    const isGHEAllowed = courseData?.allow_ghe;
    const user = useUser();
    const isTeacher = useIsTeacher();
    const { data: lessonDetail, isLoading } = useGetLessonDetail();
    const { mutate: postLessonSessionActivity } = usePostLessonSessionActivity();
    const mutationPostUserReportIssue = usePostUserReportIssue();
    const { data: pacingGuideLesson } = useGetPacingGuideLessons(lessonId);
    const pacingGuideLessonData = pacingGuideLesson?.[0];

    return (
      <ShadowScrollbar
        autoHide={false}
        style={{
          height: 'calc(100vh - 162px)',
          margin: 0,
        }}
      >
        <WrappedComponent
          {...props}
          lessonId={lessonId}
          lessonDetail={lessonDetail}
          isTeacher={isTeacher}
          isGettingLessonDetail={isLoading}
          isGHEAllowed={isGHEAllowed}
          postLessonActivity={postLessonSessionActivity}
          userId={user?.id}
          sectionId={sectionId}
          unitId={unitId}
          handleIsFinishStepOne={handleIsFinishStepOne}
          userReportIssue={mutationPostUserReportIssue}
          pacingGuideLessonData={pacingGuideLessonData}
        />
      </ShadowScrollbar>
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(LessonDetailTab);
