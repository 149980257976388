/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const PracticeAnswersMultiChoiceWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  overflow: visible;
`;
