import React from 'react';
import { useDrop } from 'react-dnd';

import { StyledRow } from './GroupRow.style';

type Props = {
  rowId: number;
  onDropCell: (
    sourceId: number,
    sourceRowId: number,
    targetRowId: number,
    monitor: any,
  ) => void;
  children: React.ReactNode | React.ReactNode[];
  disableDrop: boolean;
  withBorder?: boolean;
};

const GroupRow = ({ children, rowId, onDropCell, disableDrop, withBorder }: Props) => {
  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: 'GROUPNAME',
    drop: ({ sourceId, sourceRowId }, monitor) => {
      onDropCell(sourceId, sourceRowId, rowId, monitor);
    },
    // eslint-disable-next-line @typescript-eslint/no-shadow
    canDrop: ({ sourceRowId }, monitor) =>
      sourceRowId !== rowId && !disableDrop && monitor.isOver({ shallow: true }),
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <StyledRow
      data-testid="group-row"
      className="table-row"
      ref={dropRef}
      isDropOver={isOver && canDrop}
      withBorder={withBorder}
    >
      {children}
    </StyledRow>
  );
};

export default GroupRow;
