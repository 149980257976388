import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { ContainerItem, DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { IWContextProvider, useIWContext } from './IWContextProvider';
import InstructionalWalkThroughBuilder from './InstructionalWalkThroughBuilder';
import InstructionalWalkThroughRenderer from './InstructionalWalkThroughRenderer';
import { Button } from '../Generals/stylesheets/Button.style';
import { FancyTextField } from './Components/CriteriaCard';
import { iWApi } from './api';
import { useIWGet } from './hooks';

const InstructionalWalkThroughCreate = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { currentPageIWId: currentIWId, setPageIWId: setIWId } = useIWContext();
  const { data: walkThrough, invalidate } = useIWGet({ id: currentIWId });
  const [iWName, setIWName] = useState(walkThrough?.name || 'New walkThrough');
  const handleSave = () => {
    iWApi.put({ id: currentIWId, name: iWName });
    invalidate();
    setIWId(null);
  };
  return (
    <DefaultContentWrap>
      <ContainerItem>
        <FancyTextField
          label="Template"
          defaultValue={iWName}
          onChange={(e) => {
            setIWName(e.target.value);
          }}
          placeholder="Enter Template name ..."
        />
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab>Build</Tab>
            <Tab>Preview</Tab>
          </TabList>
          <TabPanel>
            <InstructionalWalkThroughBuilder />
            <div
              style={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'flex-end',
                marginTop: '1rem',
                width: '100%',
              }}
            >
              <Button
                blue
                onClick={() => setTabIndex(1)}
                // alignRight
                // style={{ marginTop: '1rem', marginRight: '1rem' }}
              >
                Preview
              </Button>
              <Button
                onClick={() => setIWId(null)}
                // alignRight
                // style={{ marginTop: '1rem', marginRight: '1rem' }}
              >
                Cancel
              </Button>
              <Button
                submit
                onClick={handleSave}
                // alignRight
                // style={{ marginTop: '1rem', marginRight: '1rem' }}
              >
                Save and Back
              </Button>
            </div>
          </TabPanel>
          <TabPanel>
            <InstructionalWalkThroughRenderer />
          </TabPanel>
        </Tabs>
      </ContainerItem>
    </DefaultContentWrap>
  );
};

export default InstructionalWalkThroughCreate;
