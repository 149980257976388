import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import * as Yup from 'yup';
import {
  Formik,
} from 'formik';
import MediaQuery from 'react-responsive';
import TeacherInvitationModal from '../Modals/TeacherInvitationModal';
import PostTeacherInvitationModal from '../Modals/PostTeacherInvitationModal';
import SkipDialogueModal from '../Modals/SkipDialogueModal';

const invitationModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    minWidth: '520px',
    maxWidth: '800px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    borderLeft: 'solid 22px #4a85e8',
    maxHeight: 'calc(100vh - 30px)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const invitationMobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0px',
    border: 'none',
    borderLeft: 'solid 22px #4a85e8',
    width: '100%',
    height: '100%',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const validationSchema = Yup.object().shape({
  teachers: Yup.array().of(
    Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().required('Required').email('Invalid Email'),
    }),
  ).min(1, 'You should add at least 1 teacher to continue to Send Invites'),
});

const initialValues = {
  teachers: [],
};

export default class InviteTeacherTable extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    sectionList: PropTypes.array,
    onSubmit: PropTypes.func,
    history: PropTypes.any,
    location: PropTypes.any,
    isInRegisterFlow: PropTypes.bool,
    subscriptionData: PropTypes.any,
    courseDetail: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSuccessInviteModal: false,
      isSkipModalOpen: false,
    };
  }

  openSuccessInviteModal = () => {
    this.setState({
      isSuccessInviteModal: true,
    });
  }

  openSkipModal = () => {
    this.setState({
      isSkipModalOpen: true,
    });
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  openCreateSectionModal = () => {
    const { location, history } = this.props;

    const pathname = _get(location, 'pathname', '/');
    history.push(`${pathname}?isOpenCreateSection=1`);
    this.onClose();
  }

  onSubmit = (values) => {
    const { teachers } = values;
    const { onSubmit } = this.props;
    const payload = {
      teachers,
    };

    onSubmit(payload, this.openSuccessInviteModal);
  }

  renderTeacherInvitationModal = (formProps) => {
    const teacherList = _get(formProps, 'values.teachers', []);
    const { courseDetail, subscriptionData, isInRegisterFlow } = this.props;
    const { errors } = formProps;
    return (
      <TeacherInvitationModal
        teacherList={teacherList}
        courseDetail={courseDetail}
        subscriptionData={subscriptionData}
        isInRegisterFlow={isInRegisterFlow}
        onClose={this.onClose}
        openCreateSectionModal={this.openCreateSectionModal}
        formikError={errors}
      />
    );
  }

  renderInviteTeacherSuccess = () => {
    const { isInRegisterFlow } = this.props;

    return (
      <PostTeacherInvitationModal
        onClose={this.onClose}
        openCreateSectionModal={this.openCreateSectionModal}
        isInRegisterFlow={isInRegisterFlow}
        openSkipModal={this.openSkipModal}
      />
    );
  }

  renderSkipModal = () => {
    const { onClose } = this.props;

    return <SkipDialogueModal onClose={onClose} />;
  }

  renderFormContent = () => {
    const { isSkipModalOpen, isSuccessInviteModal } = this.state;

    if (isSkipModalOpen) {
      return this.renderSkipModal();
    }

    if (isSuccessInviteModal) {
      return this.renderInviteTeacherSuccess();
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onSubmit}
        render={this.renderTeacherInvitationModal}
        validationSchema={validationSchema}
      />
    );
  }

  render() {
    const { isSuccessInviteModal } = this.state;
    const { isOpen } = this.props;

    return (
      <MediaQuery maxWidth={768}>
        {matches => (
          <Modal
            isOpen={isOpen}
            onRequestClose={this.onClose}
            style={matches ? invitationMobileModalStyles : invitationModalStyles}
            ariaHideApp={false}
          >
            {this.renderFormContent()}
          </Modal>
        )}
      </MediaQuery>
    );
  }
}
