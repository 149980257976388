import React from 'react';

import Card from '@components/Atoms/Card';
import Typography from '@components/Atoms/Typography';
import { CardMedia } from '@mui/material';
import {
  informationCardDescriptionStyle,
  informationCardImgStyle,
  informationCardWrapperStyle,
} from './InformationCard.style';

type InformationCardProps = {
  /** information card image */
  img: string;
  /** information card title */
  title: string;
  /** information card description */
  description: string;
  /** Optional data test id for the information Card */
  dataTestId?: string;
  /** Optional extra style for the information Card */
  extraStyle?: React.CSSProperties;
};

const InformationCard = ({
  img,
  title,
  description,
  dataTestId = 'info-card',
  extraStyle = {},
}: InformationCardProps) => (
  <Card sx={[informationCardWrapperStyle, extraStyle]} data-testid={dataTestId}>
    <CardMedia
      component="img"
      image={img}
      alt="information Card img"
      sx={informationCardImgStyle}
    />
    <Typography variant="h3" sx={{ color: 'black' }}>
      {title}
    </Typography>
    <Typography sx={informationCardDescriptionStyle} variant="body1">
      {description}
    </Typography>
  </Card>
);

export default InformationCard;
