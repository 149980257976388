import React from 'react';
import { useParams, Link } from 'react-router-dom';
import _get from 'lodash/get';
import _range from 'lodash/range';
import _isEmpty from 'lodash/isEmpty';
import _sumBy from 'lodash/sumBy';
import moment from 'moment';
import ShowIf from '@Atoms/ShowIf';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter/BreadcumbsFilter';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentWrapper,
  TableContentItem,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { TeacherColumnSize } from './ColumnSize';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { calcDurationToSecond } from '../../utils/commonUtils';

const scrollStyle = {
  height: 'calc(100vh - 7em)',
  width: '100%',
};

const StudentSessionReview = ({ reviewItem, lessonById }) => {
  const { sectionId, courseId, subjectId } = useParams();
  const { id, user: studentId } = reviewItem;
  const isLessonBasedReview = reviewItem.lesson !== null;
  let name = _get(reviewItem, 'unit.name');
  let score = '--/--';
  const unitId = _get(reviewItem, 'unit.id');
  let reviewLink = null;

  if (isLessonBasedReview) {
    const { lesson: lessonId } = reviewItem;
    name = _get(lessonById, `${lessonId}.name`, '');
  }

  if (_get(reviewItem, 'practice_session.ended', null)) {
    const scoreAuto = _get(reviewItem, 'practice_session.score[0].score.auto', 0);
    const scoreAutoMax = _get(reviewItem, 'practice_session.score[0].score.auto_max', 0);

    score = `${scoreAuto}/${scoreAutoMax}`;
    reviewLink = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice-review/student/${studentId}?review=${id}`;

    if (isLessonBasedReview) {
      const { lesson: lessonId } = reviewItem;
      reviewLink = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice-review/student/${studentId}?review=${id}`;
    }
  }

  return (
    <React.Fragment key={id}>
      <TableContent {...TeacherColumnSize[1]}>{name}</TableContent>
      <TableContent {...TeacherColumnSize[1]}>
        {reviewLink ? <Link to={reviewLink}>{score}</Link> : score}
      </TableContent>
    </React.Fragment>
  );
};
const ReviewItem = ({ reviewsOfStudent, lessonById, maxColumns }) => (
  <>
    {reviewsOfStudent.map((reviewItem) => (
      <StudentSessionReview
        reviewItem={reviewItem}
        key={reviewItem?.id}
        lessonById={lessonById}
      />
    ))}
    {Array.from(
      { length: maxColumns - reviewsOfStudent.length },
      (_, index) => index + 1,
    ).map((index) => (
      <React.Fragment key={maxColumns - index}>
        <TableContent {...TeacherColumnSize[1]} />
        <TableContent {...TeacherColumnSize[1]} />
      </React.Fragment>
    ))}
  </>
);
const ReviewTableContent = ({ student, reviewByUserId, lessonById, maxColumns }) => {
  const { id: studentId } = student;
  const name = `${student.first_name} ${student.last_name}`;
  const reviewsOfStudent = _get(reviewByUserId, studentId, []);
  let hasTimeOnTask = false;

  const totalTimeOnTask = _sumBy(reviewsOfStudent, (item) => {
    const timeOnTask = _get(item, 'practice_session.time_on_task', null);

    if (timeOnTask !== null) {
      hasTimeOnTask = true;

      return timeOnTask;
    }

    return 0;
  });

  let displayTimeOnTask = 'N/A';

  if (hasTimeOnTask) {
    displayTimeOnTask = calcDurationToSecond(totalTimeOnTask);
  }

  return (
    <TableContentItem key={studentId}>
      <TableContent {...TeacherColumnSize[0]}>{name}</TableContent>
      <ReviewItem
        reviewsOfStudent={reviewsOfStudent}
        lessonById={lessonById}
        maxColumns={maxColumns}
      />
      <TableContent {...TeacherColumnSize[1]}>{displayTimeOnTask}</TableContent>
    </TableContentItem>
  );
};
const BreadcrumbFilterWrapper = ({
  subject,
  reviewDetail,
  sectionDetail,
  isSideBarClose,
  courseDetail,
}) => {
  const breadcumList = ['Lessons'];

  if (!_isEmpty(subject)) {
    const subjectName = _get(subject, 'display_name', '');
    breadcumList.push(subjectName);
    breadcumList.push(
      `${subjectName} Review ${moment(reviewDetail.date, 'YYYY-MM-DD').format(
        'MM-DD-YYYY',
      )}`,
    );
  }

  return (
    <BreadcumbsFilter
      haveSub={isSideBarClose}
      breadcumbSubList={[courseDetail.name, sectionDetail.name]}
      breadcumbList={breadcumList}
      mobileTitle="Lesson Review"
    />
  );
};
const ReviewList = ({ students, maxColumns, lessonById, reviewByUserId }) => {
  const totalHeaderColumns = maxColumns * 2; // because each item has 2 columns

  return (
    <DefaultContentWrap>
      <TableContainerWrap>
        <ShadowScrollbar style={scrollStyle}>
          <TableHeadWrapper>
            <TableHeader {...TeacherColumnSize[0]} value="Student Name" />
            {_range(totalHeaderColumns).map((index) => (
              <TableHeader {...TeacherColumnSize[1]} key={index} />
            ))}
            <TableHeader {...TeacherColumnSize[7]} value="Time on Task" />
          </TableHeadWrapper>
          <TableContentWrapper style={{ overflow: 'auto' }}>
            {students.map((student) => (
              <ReviewTableContent
                student={student}
                key={student.id}
                lessonById={lessonById}
                reviewByUserId={reviewByUserId}
                maxColumns={maxColumns}
              />
            ))}
          </TableContentWrapper>
        </ShadowScrollbar>
      </TableContainerWrap>
    </DefaultContentWrap>
  );
};

const TeacherLessonReviewPage = ({
  subject,
  isGetSessionData,
  courseDetail,
  sectionDetail,
  isSideBarClose,
  reviewDetail,
  students,
  maxColumns,
  lessonById,
  reviewByUserId,
}) => (
  <>
    <ShowIf If={!!isGetSessionData}>
      <LoadingIndicator content="Preparing for your data" />
    </ShowIf>
    <ShowIf If={!isGetSessionData}>
      <BreadcrumbFilterWrapper
        subject={subject}
        courseDetail={courseDetail}
        sectionDetail={sectionDetail}
        reviewDetail={reviewDetail}
        isSideBarClose={isSideBarClose}
      />
      <ReviewList
        students={students}
        maxColumns={maxColumns}
        lessonById={lessonById}
        reviewByUserId={reviewByUserId}
      />
    </ShowIf>
  </>
);

export default TeacherLessonReviewPage;
