import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { actions } from '../../reducers/sectionStudent';
import GroupInviteStudentItem from '../../components/StudentGroupInviteItem';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');

  return {
    isInManagementpage: true,
    sectionId,
  };
};

const mapDispatchToProps = {
  submitInviteStudent: actions.sectionsStudentInvite,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupInviteStudentItem));
