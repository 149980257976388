import _differenceBy from 'lodash/differenceBy';
import { LessonPracticeStatus } from './Types';

export const practiceStudentStatusHelper = (
  examScore,
  isAssigned,
): LessonPracticeStatus | null => {
  if (isAssigned) {
    return LessonPracticeStatus.Assigned;
  }

  if (examScore.some((activity) => activity.last_activity)) {
    return LessonPracticeStatus.CompletedLessonPractice;
  }
  return null;
};

export const practiceTeacherStatusHelper = (
  examScore,
  studentList,
): LessonPracticeStatus | null => {
  const THRESHOLD_RATIO = 70;
  if (!studentList || !studentList.length) {
    return null;
  }
  if (!examScore || !examScore.length) {
    return null;
  }
  const practiceStudentList = examScore.reduce((acc, obj) => {
    acc[obj.user] = obj;
    return acc;
  }, {});
  const isPracticeCompletionAboveThreshold =
    Math.round((Object.keys(practiceStudentList).length / studentList.length) * 100) >=
    THRESHOLD_RATIO;

  if (isPracticeCompletionAboveThreshold) {
    return LessonPracticeStatus.CompletedLessonPractice;
  }
  return null;
};

export const checkLessonCompletion = (
  lessonActivitySummaryList,
  lessonId,
  subjectId,
  unitId,
): boolean => {
  const filterArr = lessonActivitySummaryList.filter(
    (lessonActivity) =>
      lessonActivity.unit == unitId &&
      lessonActivity.subject == subjectId &&
      lessonActivity.lesson_group == lessonId,
  );
  return filterArr.length ? filterArr[0].completed : false;
};

export const checkHasDifference = (
  lessonActivityById,
  lessonDetail,
  subLessonId,
): boolean => {
  const subLessons = lessonDetail?.lessons;
  const subLessonsAllId = subLessons?.map((subLesson) => subLesson.id);
  const newLessonActivityById = [...Object.keys(lessonActivityById), subLessonId];
  const diff = _differenceBy(subLessonsAllId, newLessonActivityById, parseInt);

  return !diff.length;
};
