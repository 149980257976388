import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import 'react-image-lightbox/style.css';
import {
  Tab, TabList, TabPanel, Tabs,
} from 'react-tabs';
import QuestionItem from '../QuestionItem';
import QuestionAssets from '../../containers/QuestionAssets';
import AnswerExplanation from '../../containers/AnswerExplanation';
import {
  PracticeContentGroup,
  PracticeContentWrap,
} from '../PracticePage/PracticePage.style';
import AnswerExplanationLeftSideBar from '../AnswerExplanationLeftSideBar';
import { NavigationHeaderWrap } from '../QuestionAssets/QuestionAssets.style';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import MediaQuery from 'react-responsive';
class ReviewQuestion extends React.PureComponent {
  state = {
    isOpenSideBar: false,
  }

  toggleExplanationSideBar = () => {
    this.setState(({ isOpenSideBar }) => ({
      isOpenSideBar: !isOpenSideBar,
    }));
  }

  renderQuestionAssets = () => {
    const {
      questions,
      questionSelected,
      displayGrammarHelper,
      openReportErrorModal,
    } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(questionSelectedObj, 'exam_question.question.shared_assets', []);
    const questionId = _get(questionSelectedObj, 'exam_question.id');

    const isAssetEmpty = assets.length === 0;

    if (isAssetEmpty) {
      return null;
    }

    return (
      <QuestionAssets
        assets={assets}
        questionId={questionId}
        displayGrammarHelper={displayGrammarHelper}
        questionSelected={questionSelected}
        openReportErrorModal={openReportErrorModal}
      />
    );
  }

  renderMainContent = () => {
    const {
      questions, questionSelected,
      prevQuestion, nextQuestion,
      shouldDisplayGrammarHelper, openReportErrorModal,
    } = this.props;
    const totalQuestions = questions.length;

    if (totalQuestions === 0) {
      return null;
    }

    const questionSelectedObj = questions[questionSelected];
    const questionOrder = _get(questionSelectedObj, 'exam_question.order', null);
    const questionId = _get(questionSelectedObj, 'exam_question.id');
    const explanation = _get(questionSelectedObj, 'exam_question.question.explanation', {});

    return (
      <>
        <QuestionItem
          questionSelected={questionSelectedObj}
          totalQuestions={totalQuestions}
          nextQuestion={nextQuestion}
          prevQuestion={prevQuestion}
          index={questionSelected}
          order={questionOrder}
          shouldDisplayGrammarHelper={shouldDisplayGrammarHelper}
          openReportErrorModal={openReportErrorModal}
          toggleExplanationSideBar={this.toggleExplanationSideBar}
          questionId={questionId}
        />
        <AnswerExplanation
          questionSelected={questionSelectedObj}
          explanation={explanation}
          openReportErrorModal={openReportErrorModal}
          questionId={questionId}
        />
      </>
    );
  }

  render() {
    const { isOpenSideBar } = this.state;
    const { isPracticeNavigation } = this.props;
    const { questionSelected, questions, openReportErrorModal } = this.props;
    const questionSelectedObj = questions[questionSelected];
    const questionId = _get(questionSelectedObj, "exam_question.id");
    const explanation = _get(
      questionSelectedObj,
      "exam_question.question.explanation",
      {}
    );
    return (
      <MediaQuery maxWidth={768}>
        {matches => matches ? (
          <>
            <AnswerExplanationLeftSideBar
              isSideBarClose={isOpenSideBar}
              toggleLeftSidebar={this.toggleExplanationSideBar}
            >
              <AnswerExplanation
                explanation={explanation}
                questionSelected={questionSelectedObj}
                openReportErrorModal={openReportErrorModal}
                questionId={questionId}
              />
            </AnswerExplanationLeftSideBar>
            <PracticeContentWrap>
              <Tabs defaultIndex={1}>
                <NavigationHeaderWrap>
                  <TabList>
                    <Tab>Assets</Tab>
                    <Tab>Show Questions</Tab>
                  </TabList>
                </NavigationHeaderWrap>
                <TabPanel>{this.renderQuestionAssets()}</TabPanel>
                <TabPanel>
                  <ShadowScrollbar
                    autoHide
                    style={{
                      width: "100%",
                      height: "calc(100vh - 10em)",
                    }}
                  >
                    <PracticeContentGroup>
                      {this.renderMainContent()}
                    </PracticeContentGroup>
                  </ShadowScrollbar>
                </TabPanel>
              </Tabs>
            </PracticeContentWrap>
          </>
        ) :
          <PracticeContentWrap>
            {this.renderQuestionAssets()}
            <PracticeContentGroup isToggleNavigation={isPracticeNavigation}>
              {this.renderMainContent()}
            </PracticeContentGroup>
          </PracticeContentWrap>
        }
      </MediaQuery>
    )
  }
}

ReviewQuestion.propTypes = {
  questions: PropTypes.array,
  isPracticeNavigation: PropTypes.bool,
  questionSelected: PropTypes.number,
  shouldDisplayGrammarHelper: PropTypes.bool,
  nextQuestion: PropTypes.func.isRequired,
  prevQuestion: PropTypes.func.isRequired,
  displayGrammarHelper: PropTypes.func.isRequired,
  openReportErrorModal: PropTypes.func.isRequired,
};

export default ReviewQuestion;