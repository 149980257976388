import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  LoginContainerWrap,
  Image,
  ImageWrap,
  Loading,
  ErrorMessage,
} from '../LoginPage/Login.style';

export default class RequestVerification extends Component {
  static propTypes = {
    approveJoinCourseRequest: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.any,
    verifyError: PropTypes.string,
  }

  componentDidMount = () => {
    const { approveJoinCourseRequest, match } = this.props;
    const { params } = match;
    const { uid, token } = params;

    approveJoinCourseRequest(uid, token);
  }

  renderMessage = () => {
    const { verifyError } = this.props;
    if (_isEmpty(verifyError)) return null;

    return (
      <ErrorMessage>{verifyError}</ErrorMessage>
    );
  }

  render() {
    const { isLoading, verifyError } = this.props;
    return (
      <LoginContainerWrap verify>
        {/* <ImageWrap> */}
        {/*  <Image className="chalktalk-email-verify"> */}
        {/*    <i className="path1" /> */}
        {/*    <i className="path2" /> */}
        {/*    <i className="path3" /> */}
        {/*    <i className="path4" /> */}
        {/*    <i className="path5" /> */}
        {/*    <i className="path6" /> */}
        {/*    <i className="path7" /> */}
        {/*    <i className="path8" /> */}
        {/*    <i className="path9" /> */}
        {/*    <i className="path10" /> */}
        {/*    <i className="path11" /> */}
        {/*    <i className="path12" /> */}
        {/*  </Image> */}
        {/* </ImageWrap> */}
        {
          verifyError ? this.renderMessage() : (
            <Loading>
              <LoadingIndicator isLoading={isLoading} />
            </Loading>
          )
        }
      </LoginContainerWrap>
    );
  }
}
