import Button from '@components/Atoms/Button';
import ShowIf from '@components/Atoms/ShowIf';
import { Badge } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';

const GenerateQuestionsButton = ({ setOpen, hasAuthorAideAccess }) => (
  <ShowIf If={hasAuthorAideAccess}>
    <Badge
      badgeContent={
        <StarIcon
          sx={{
            color: 'white',
            fontSize: '0.75rem',
          }}
        />
      }
      color="secondary"
    >
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        Generate Questions with CoAuthor
      </Button>
    </Badge>
  </ShowIf>
);

export default GenerateQuestionsButton;
