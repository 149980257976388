import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import LessonSlidesViewer from '@components/LessonDetailTab/LessonSlidesViewer';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import {
  LessonHeaderContainer,
  LessonManagementRightWrap,
} from '@components/LessonManagement/LessonManagement.style';
import {
  FeedbackContainer,
  FeedbackIcon,
} from '@components/LessonDetailTab/LessonDetailTab.style';
import LessonReportModal from '@components/LessonDetailTab/LessonReportModal';
import { useCurriculumDetails } from '@reducers/curriculums/hooks';
import { usePostUserReportIssue } from '@components/Component/Lesson/LessonContainer/hooks/mutations';

const LessonDetailModal = ({ lessonId, isOpen, onClose }) => {
  const [show, setShow] = React.useState(false);
  const { data: lessonDetail } = useGetLessonDetail(lessonId);
  const curriculumDetail = useCurriculumDetails();
  const mutationPostUserReportIssue = usePostUserReportIssue();

  const submitHandler = (msg: string, slideNumber: string | number) => {
    const { mutate, isSuccess } = mutationPostUserReportIssue;
    mutate({
      content_object_id: +slideNumber,
      content_type: 'lesson',
      content: msg,
    });
    if (isSuccess) {
      setShow(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      title={lessonDetail?.name}
      open={isOpen}
      onClose={onClose}
    >
      {/* TODO: move this in separate component when we refactor LessonDetailTab 
      chalktalk-react/components/LessonDetailTab/LessonDetailTab.js */}
      <LessonManagementRightWrap full={!lessonDetail?.lessons?.length}>
        <LessonHeaderContainer>
          <FeedbackContainer onClick={() => setShow(true)}>
            <FeedbackIcon src="/images/feedback.png" />
          </FeedbackContainer>
        </LessonHeaderContainer>
        <LessonSlidesViewer lessonDetails={lessonDetail} />
      </LessonManagementRightWrap>
      <LessonReportModal
        isOpen={show}
        onClose={() => setShow(false)}
        onSubmit={submitHandler}
        curriculum={curriculumDetail?.name}
        lessonTitle={lessonDetail?.name}
      />
    </Dialog>
  );
};

export default LessonDetailModal;
