import { connect } from 'react-redux';
import _get from 'lodash/get';
import TeacherTraining from '../../components/TeacherTraining';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);

  return {
    courseDetail,
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
  };
};

export default connect(mapStateToProps)(TeacherTraining);
