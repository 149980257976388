import styled from 'styled-components';
import {Tab as TabBasic, TabPanel as TabPanelBasic } from '../tabs';
export const  TabList = styled.div`
  display: flex;
  flex-direction: row;
    justify-content: stretch;
    align-items: stretch;

    width: 100%;
    border: 0.0625rem solid #D3D3D3;
    border-radius: 0.3125rem;
    overflow-y: hidden;
    max-width: 100%;
    overflow-y: auto;

  `;

export const  Tab = styled(TabBasic)`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    color: #4A4845;
    font: 'normal normal normal 12px/19px Roboto';
    ${TabList} &.react-tabs__tab--selected {
      background: #4A4845 0% 0% no-repeat padding-box;
      color: #FFFFFF;
      font: 'normal normal medium 12px/19px Roboto';
      border:none;
      border-radius: 0.25rem;
    }
`;

export const  TabPanel = styled(TabPanelBasic)`
`;