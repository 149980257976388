import React from 'react';
import BackgroundLeft from '../../BackgroundLeft';
import ForgotEmailForm from '../../../containers/ForgotPage/ForgotEmail/ForgotEmailForm';
import {
  LoginContainerWrap,
  LoginBlockRight,
} from '../../LoginPage/Login.style';

const ForgotEmail = () => (
  <LoginContainerWrap>
    <BackgroundLeft type="password" />
    <LoginBlockRight>
      <ForgotEmailForm />
    </LoginBlockRight>
  </LoginContainerWrap>
);

export default ForgotEmail;
