/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useUser } from '@reducers/user/hooks';
import { Passage } from '@components/QuestionGenerationPassages/types';
import { Box } from '@mui/material';
import ShadowScrollbar from '@components/Scrollbar/ShadowScrollbars';
import { createPassageItemsListRequest } from '../../AssessmentServiceAPI';
import { useGetSignature } from '../../Hooks/Query';
import { PassageListItemWrapper } from './styles';

const containerId = 'learnosity_preview_json_container';

const PreviewPassageItems: React.FC<{
  widgetRef?: any;
  passage: Passage;
}> = ({ widgetRef = undefined, passage }) => {
  const currentUser = useUser();
  const mainRef = useRef<HTMLDivElement>(null);
  const [widgetInstance, setWidgetInstance] = useState<any>(undefined);
  const { data: signature } = useGetSignature(
    createPassageItemsListRequest(passage.reference, currentUser.id.toString()),
    'author',
    {
      enabled: !widgetInstance && !!(window as any).LearnosityAuthor,
    },
  );

  useImperativeHandle(widgetRef, () => ({ widgetInstance }), [widgetInstance]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    mainRef.current!.appendChild(document.createElement('div')).id = containerId;
  }, []);

  useEffect(() => {
    const isElementOnTheDom = !!document.getElementById(containerId);
    if (
      signature &&
      Object.keys(signature!).length &&
      !widgetInstance &&
      isElementOnTheDom
    ) {
      const authorApp = (window as any).LearnosityAuthor?.init(
        signature,
        {
          readyListener: () => {
            setWidgetInstance(authorApp);
          },
        },
        containerId,
      );
    }
    return () => {
      if (widgetInstance && isElementOnTheDom) {
        widgetInstance.destroy(); // TODO this is not correct
        setWidgetInstance(undefined);
      }
    };
  }, [signature, widgetInstance]);

  return (
    <ShadowScrollbar
      style={{
        height: 'calc(60vh)',
        width: '100%',
      }}
    >
      <PassageListItemWrapper ref={mainRef} />
    </ShadowScrollbar>
  );
};

export default PreviewPassageItems;
