import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  data: string;
  suffix?: React.ReactNode;
  postfix?: React.ReactNode;
};

const DataView = ({ data, suffix, postfix }: Props) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start',
      justifyContent: 'start',
      width: '100%',
      backgroundColor: '#f8f8f9',
      border: '0.0625rem solid #eee',
      borderRadius: '0.25rem',
      padding: '0.25rem 0.5rem',
      overflow: 'hidden',
    }}
  >
    {!!suffix && (
      <Box
        sx={{
          paddingInline: 0.5,
        }}
      >
        {suffix}
      </Box>
    )}
    <Typography
      variant="body1"
      gutterBottom
      sx={{
        flex: 1,
        mb: 0,
      }}
    >
      {data}
    </Typography>
    {!!postfix && (
      <Box
        sx={{
          paddingInline: 0.5,
        }}
      >
        {postfix}
      </Box>
    )}
  </Box>
);

export default React.memo(DataView);
