import React from 'react';
import {
  SkillRecord,
  StandardRecord,
} from '@components/Component/Lesson/LessonContainer/Types';
import { LLROrderingType } from '@components/LearnosityLessonReview/types';
import { useFormatSelectedQuestions } from './hooks';

const QuestionSelectorContext =
  React.createContext<
    | {
        selectedQuestions: Array<any>;
        setSelectedQuestions: React.Dispatch<React.SetStateAction<string[]>>;
        formattedSelectedQuestions: Array<any>;
        selectedSkills: Array<SkillRecord>;
        setSelectedSkills: React.Dispatch<React.SetStateAction<SkillRecord[]>>;
        type: LLROrderingType;
        setType: React.Dispatch<React.SetStateAction<LLROrderingType>>;
        showPreview: boolean;
        setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
        reorderQuestions: (sourceIndex: number, destinationIndex: number) => void;
        selectedStandards: Array<StandardRecord>;
        setSelectedStandards: React.Dispatch<React.SetStateAction<StandardRecord[]>>;
      }
    | undefined
  >(undefined);

type QuestionSelectorProviderProps = {
  children: React.ReactNode;
  initialType?: LLROrderingType;
  initialSelectedQuestions?: string[];
};

const QuestionSelectorProvider = ({
  children,
  initialType = LLROrderingType.FIXED_ORDERING,
  initialSelectedQuestions = [],
}: QuestionSelectorProviderProps) => {
  const [selectedQuestions, setSelectedQuestions] = React.useState<string[]>(
    initialSelectedQuestions,
  );
  const [selectedSkills, setSelectedSkills] = React.useState<SkillRecord[]>([]);
  const [selectedStandards, setSelectedStandards] = React.useState<StandardRecord[]>([]);
  const [type, setType] = React.useState<LLROrderingType>(initialType);
  const [showPreview, setShowPreview] = React.useState<boolean>(false);

  const formattedSelectedQuestions = useFormatSelectedQuestions(selectedQuestions);
  const reorderQuestions = React.useCallback(
    (sourceIndex: number, destinationIndex: number) => {
      setSelectedQuestions((prevSelectedQuestions) => {
        const newSelectedQuestions = [...prevSelectedQuestions];
        const [removed] = newSelectedQuestions.splice(sourceIndex, 1);
        newSelectedQuestions.splice(destinationIndex, 0, removed);
        return newSelectedQuestions;
      });
    },
    [],
  );

  const value = React.useMemo(
    () => ({
      selectedQuestions,
      setSelectedQuestions,

      selectedSkills,
      setSelectedSkills,

      formattedSelectedQuestions,
      reorderQuestions,

      type,
      setType,

      showPreview,
      setShowPreview,

      selectedStandards,
      setSelectedStandards,
    }),
    [
      selectedQuestions,
      selectedSkills,
      formattedSelectedQuestions,
      reorderQuestions,
      type,
      showPreview,
      selectedStandards,
    ],
  );
  return (
    <QuestionSelectorContext.Provider value={value}>
      {children}
    </QuestionSelectorContext.Provider>
  );
};

export const useSelectedQuestions = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedQuestions must be used within a QuestionSelectorProvider',
    );
  }
  return context;
};

export const useAddQuestion = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error('useAddQuestion must be used within a QuestionSelectorProvider');
  }
  return React.useCallback(
    (questionId: string) => {
      context?.setSelectedQuestions((prevSelectedQuestions: string[]) => [
        ...prevSelectedQuestions,
        questionId,
      ]);
    },
    [context],
  );
};

export const useRemoveQuestion = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error('useRemoveQuestion must be used within a QuestionSelectorProvider');
  }
  return React.useCallback(
    (questionId: string) => {
      context?.setSelectedQuestions((prevSelectedQuestions: string[]) =>
        prevSelectedQuestions.filter((q) => q !== questionId),
      );
    },
    [context],
  );
};
export const useSelectedSkills: () => [
  SkillRecord[],
  React.Dispatch<React.SetStateAction<SkillRecord[]>>,
] = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error('useSelectedSkills must be used within a QuestionSelectorProvider');
  }
  return [context.selectedSkills, context.setSelectedSkills];
};
export const useSelectedStandards: () => [
  StandardRecord[],
  React.Dispatch<React.SetStateAction<StandardRecord[]>>,
] = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedStandards must be used within a QuestionSelectorProvider',
    );
  }
  return [context.selectedStandards, context.setSelectedStandards];
};
export const useSelectedType: () => [
  LLROrderingType,
  React.Dispatch<React.SetStateAction<LLROrderingType>>,
] = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error('useSelectedType must be used within a QuestionSelectorProvider');
  }
  return [context.type, context.setType];
};

export const useShowPreview: () => [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
] = () => {
  const context = React.useContext(QuestionSelectorContext);
  if (context === undefined) {
    throw new Error('useShowPreview must be used within a QuestionSelectorProvider');
  }
  return [context.showPreview, context.setShowPreview];
};

export default QuestionSelectorProvider;
