/* eslint-disable import/prefer-default-export */
import { QueryClientEmitter } from './queryClientHelper';

const getExamSectionsObject = (subjects, curriculumId) => {
  const queryClientEmitter = QueryClientEmitter;
  if (curriculumId === -1) {
    return {};
  }
  const [data] = queryClientEmitter.emit('getQuery', ['getExamSections', curriculumId]);

  if (!data) {
    console.error(
      `data is undefined in getExamSectionsObject method in scheduling.js file`,
    );
  }

  const examSectionsObject = {};

  data
    ?.filter((subject) => subjects.includes(subject.display_name))
    .forEach((subjectData) => {
      subjectData.exam_sections?.forEach((examSection) => {
        const examSectionName = examSection.name;
        const fullLength = examSection.duration;

        examSectionsObject[examSectionName] = {
          full_length: fullLength,
        };
      });
    });

  return examSectionsObject;
};
const generateBuiltInOptions = (examSectionsObject, duration) => {
  /**
   * Based on the exam sections provided and duration, this function will generate options on how many days the exam can be scheduled.
   * Each day fits only as many examsections that can fit the provided class duration.
   * @param {Object} examSectionsObject - All the exams that are available for the exam.
   * @param {Number} duration - Class Duration (Set by the user in the Modal)
   * @returns {Object} - Returns an object with the following structure (assuming duration is 45):
      {
          "sittings": [
            [
              { name: "English Sitting 1", full_length: 26 },
              { name: "English Sitting 2", full_length: 19 },  // 26+19 = 45, they fit one class duration!
            ],
            [
              { name: "Math Sitting 1", full_length: 30 },    // 30 fits, but the next one doesn't fit in the remaining time
            ],
            [
              { name: "Math Sitting 2", full_length: 30 },
            ]
          ]
          "isBuiltIn": true // Informs user that the time is "built-in" to fit the class duration schedule
      }
   */
  let durationLeft = duration;
  const sittings = [];
  let day = [];

  Object.keys(examSectionsObject).forEach((subject) => {
    // if i add one more subject to the day, will it exceed the duration?
    // if so, save the current day as a full sitting and start a new one
    if (examSectionsObject[subject].full_length > durationLeft) {
      sittings.push(day);
      day = [];
      durationLeft = duration;
    }

    // add the exam to the day
    day.push({ name: subject, ...examSectionsObject[subject] });
    durationLeft -= examSectionsObject[subject].full_length;
  });
  sittings.push(day);

  return { sittings, isBuiltIn: true };
};

const generateNonBuiltInOptions = (examSectionsObject, numberOfDays) =>
  Array(numberOfDays - 1)
    .fill(0)
    .map((_, index) => {
      /**
       * This function tries to generate a test schedule that doesnt need to fit the class duration
       * Number of Days are calculated by 1 less than the number of days that the built-in schedule has
       * @param {Object} examSectionsObject - All the exams that are available for the exam.
       * @param {Number} numberOfDays - Number of days (minus 1) that the built-in schedule has
       * @returns {Array} - Returns an object with the following structure
       * [
       *  [ //first index means all subjects in '1' day
       *    { name: "English Sitting 1", full_length: 26 },
       *    { name: "English Sitting 2", full_length: 19 },
       *    { name: "Math Sitting 1", full_length: 30 },
       *    { name: "Math Sitting 2", full_length: 30 },
       *  ],
       *  [ // second index means, all subjects divided into 2 days
       *    [
       *      { name: "English Sitting 1", full_length: 26 },
       *      { name: "English Sitting 2", full_length: 19 },
       *    ],
       *    [
       *      { name: "Math Sitting 1", full_length: 30 },
       *      { name: "Math Sitting 2", full_length: 30 },
       *    ],
       *  ]
       *  [ // third index means, all subjects divided into 3 days
       *    // Formula is broken for more than 3 days and ends up piling the bulk of subjects into the last day
       *    [
       *      { name: "English Sitting 1", full_length: 26 },
       *    ],
       *    [
       *      { name: "English Sitting 2", full_length: 30 },
       *    ],
       *    [
       *      { name: "Math Sitting 1", full_length: 30 },
       *      { name: "Math Sitting 2", full_length: 30 },
       *      { name: "Reading", full_length: 19 },
       *    ],
       *  ]
       * ]
       *
       */
      const curriculumSubjects = Object.keys(examSectionsObject)?.reduce(
        (acc, sub) => [...acc, { [sub]: examSectionsObject[sub] }],
        [],
      );
      const days = index + 1;
      const curriculumSubjectsPerDay = Math.floor(curriculumSubjects.length / days);
      const sittings = [];
      let day = [];
      let subjectIndex = 0;

      for (let i = 0; i < days; i += 1) {
        day = [];

        for (let j = 0; j < curriculumSubjectsPerDay; j += 1) {
          const sub = curriculumSubjects[subjectIndex];
          day.push(sub);
          subjectIndex += 1;
        }

        for (
          let j = subjectIndex;
          i === days - 1 && j < curriculumSubjects.length;
          j += 1
        ) {
          day.push(curriculumSubjects[j]);
        }

        sittings.push(
          day.reduce((acc, item) => {
            if (!item) {
              return [];
            }

            return [...acc, { name: Object.keys(item)[0], ...Object.values(item)[0] }];
          }, []),
        );
      }

      return { sittings };
    });

const generateAllOptions = (subjects, duration, curriculumId) => {
  /**
   * This function will generate two options for the user to choose from
   * 1. Built-in schedule (based on class duration)
   * 2. Non-built-in schedule (based on number of days)

   * @param {Number} duration - Class Duration (Set by the user in the Modal)
   * @returns {Array} - Returns an array of objects with the following structure
    [
        {
            "sittings": [...list of exams divided by days]
            "isBuiltIn": true // this is what differentiates the built-in schedule from the non-built-in schedule
        },
        {
            "sittings": [...list of exams for day 1]
        },
        {
            "sittings": [...list of exams for day 2]
        }
    ]
   */
  const examSectionsObject = getExamSectionsObject(subjects, curriculumId);

  const builtInOptions = generateBuiltInOptions(examSectionsObject, duration);

  const allOptions = generateNonBuiltInOptions(
    examSectionsObject,
    builtInOptions.sittings.length, // Total number of days
  );

  return [builtInOptions, ...allOptions];
};

const generateTestAdministrationData = (subjects, duration, curriculumId = -1) => {
  /**
   * Fetches the scheduling options from generateAllOptions and formats it to be used by the UI
   * @param {Number} duration - Class Duration (Set by the user in the Modal)
   * @returns {Array} - Returns an array of objects with the following structure
   *  [{
            "id": 1,
            "name": "1 sitting (some testing sessions longer than schedule)",
            "sittings": [
              ["name" : "Day 1", "data": "(45 mins) English Sitting 1, English Sitting 2"]
            ],
        },
        {
            "id": 2,
            "name": "2 sittings (some testing sessions longer than schedule)",
            "sittings": [
              ["name" : "Day 1", "data": "(25 mins) English Sitting 1"],
              ["name" : "Day 2", "data": "(20 mins) English Sitting 2"]
            ]
        },
        {
            "id": 3,
            "name": "3 sittings (time is built-in schedule)",
            "sittings": [
                { "name": "Day 1", "data": "(45 mins) English Sitting 1, English Sitting 2"},
                { "name": "Day 2", "data": "(30 mins) Math Sitting 1"},
                { "name": "Day 3", "data": "(30 mins) Math Sitting 2"}
            ]
        }]
   *
   */

  const options = generateAllOptions(subjects, duration, curriculumId).sort(
    (option1, option2) => option1.sittings.length - option2.sittings.length,
  );

  const nonBuiltInTitle = '(some testing sessions longer than schedule)';
  const builtInTitle = '(time is built-in schedule)';

  const sittingOptions = options.map((day, index) => ({
    id: index + 1,
    name: `${index + 1} sitting${index > 0 ? 's' : ''} ${
      day.isBuiltIn ? builtInTitle : nonBuiltInTitle
    }`,
    sittings: day.sittings.map((daySitting, daySittingIndex) => {
      const mins = Object.values(daySitting).reduce(
        (acc, items) => items.full_length + acc,
        0,
      );

      return {
        name: `Day ${daySittingIndex + 1}`,
        data: `(${mins} mins) ${daySitting.map((s) => s.name).join(', ')}`,
      };
    }),
  }));

  return sittingOptions;
};

const getSubjectPackage = (subjectId, packageName, packagesPerSubject) => {
  if (packagesPerSubject.length > 0) {
    const subjectPackages =
      packagesPerSubject.find((sub) => sub.id == subjectId)?.packages || [];
    return subjectPackages.find(
      ({ name }) => name.toLowerCase() === packageName,
    );
  }
  return null
};

export { generateTestAdministrationData, getSubjectPackage };
