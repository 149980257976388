import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import ScheduleAddExamSession from '../../components/ScheduleAddExamSession';
import { selectors as courseSelectors } from '../../reducers/courses';
import { actions as SectionExamActions, selectors as SectionExamSelectors } from '../../reducers/sectionExam';
import { actions as SchedulteModalActions, selectors as ScheduleModalSelectors } from '../../reducers/scheduleModal';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const courseData = courseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');

  return {
    sectionId,
    curriculumId,
    isAdding: SectionExamSelectors.isAdding(state),
    isSubmitSuccessfully: SectionExamSelectors.isSubmitSuccessfully(state),
    sessionDate: ScheduleModalSelectors.getSessionDate(state),
  };
};

const mapDispatchToProps = {
  sectionExamAdd: SectionExamActions.sectionExamAdd,
  clearSessionDate: SchedulteModalActions.clearSessionDate,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleAddExamSession));
