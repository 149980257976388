import React from 'react';
import Box from '@components/Atoms/Box';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import AttendanceItem from './AttendanceItem';
import { AttendanceRecords } from '../types';

interface AttendanceListWrapperProps {
  studentsList: AttendanceRecords;
  onLessonChange?: (lessonId: string | number, status) => void;
}

const AttendanceListWrapper = ({
  studentsList,
  onLessonChange,
}: AttendanceListWrapperProps) => (
  <ScrollWrapper
    sx={{
      height: 'calc(min(100vh) - 360px)',
    }}
  >
    <Box
      sx={{
        margin: 0,
        px: '0.5rem',
        py: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
      }}
    >
      {studentsList.map((studentInfo) => (
        <AttendanceItem
          onLessonChange={onLessonChange}
          key={studentInfo.student}
          activity={studentInfo.activity}
          studentFullName={studentInfo.studentFullName}
          studentId={studentInfo.student}
          studentPresenceStatus={studentInfo?.present}
        />
      ))}
    </Box>
  </ScrollWrapper>
);

export default AttendanceListWrapper;
