/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
const RATE_LIMIT = 5;
let callsOut = 0;
const delay = (waitMS: number) =>
  // eslint-disable-next-line no-new
  new Promise<void>((resolve) => {
    setTimeout(resolve, waitMS);
  });

const rateLimit = async (callback: () => Promise<any[]>) => {
  try {
    while (true) {
      if (callsOut < RATE_LIMIT) {
        callsOut++;
        return await callback();
      }
      await delay(250);
    }
  } finally {
    callsOut--;
  }
};
export default rateLimit;
