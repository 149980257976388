import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import type { SystemStyleObject } from '@mui/system';
import { Theme } from '@mui/material/styles';

export type SxProps =
  | SystemStyleObject<Theme>
  | ((theme: Theme) => SystemStyleObject<Theme>);
type Props = {
  children: React.ReactNode;
  sx?: SxProps;
};

const ScrollWrapper = ({ children, sx }: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [outOff, setOutOff] = React.useState({
    top: true,
    left: true,
    right: true,
    bottom: true,
  });
  useEffect(() => {
    const ele = ref.current?.querySelector('.content');
    if (!ele) {
      return;
    }
    const handleScroll = () => {
      if (ele) {
        const {
          scrollTop,
          scrollLeft,
          scrollHeight,
          scrollWidth,
          clientHeight,
          clientWidth,
        } = ele;
        setOutOff({
          top: scrollTop === 0,
          left: scrollLeft === 0,
          right: scrollLeft + clientWidth === scrollWidth,
          bottom: Math.ceil(scrollTop + clientHeight) === scrollHeight,
        });
      }
    };
    if (ele) {
      ele.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    return () => {
      if (ele) {
        ele.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <Box
      ref={ref}
      sx={[
        {
          width: '100%',
          height: '100%',
          maxHeight: '100%',
          maxWidth: '100%',
          position: 'relative',
          transform: 'translateZ(0)',
          overflow: 'clip',
          inset: '0px',
        },
        {
          '& > .left': {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            width: 16,
            background:
              'linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
            pointerEvents: 'none',
            opacity: 0,
            transition: 'opacity 0.5s',
            zIndex: 10,
          },
          '& > .right': {
            position: 'fixed',
            top: 0,
            bottom: 0,
            right: 0,
            width: 16,
            background:
              'linear-gradient(270deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
            pointerEvents: 'none',
            opacity: 0,
            transition: 'opacity 0.5s',
            zIndex: 10,
          },
          '& > .bottom': {
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            height: 16,
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
            pointerEvents: 'none',
            opacity: 0,
            transition: 'opacity 0.5s',
            zIndex: 10,
          },
          '& > .top': {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            height: 16,
            background:
              'linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
            pointerEvents: 'none',
            opacity: 0,
            transition: 'opacity 0.5s',
            zIndex: 10,
          },
          '& > .content': {
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: '100%',
            overflow: 'auto',
            inset: 0,
          },
        },
        !outOff.top
          ? {
              '& .top': {
                opacity: 1,
              },
            }
          : {},
        !outOff.left
          ? {
              '& .left': {
                opacity: 1,
              },
            }
          : {},
        !outOff.right
          ? {
              '& .right': {
                opacity: 1,
              },
            }
          : {},
        !outOff.bottom
          ? {
              '& .bottom': {
                opacity: 1,
              },
            }
          : {},
        sx !== undefined && sx, // Fix: Ensure that sx is not undefined
      ]}
    >
      <Box className="top" component="span" />
      <Box className="left" component="span" />
      <Box className="content">{children}</Box>
      <Box className="right" component="span" />
      <Box className="bottom" component="span" />
    </Box>
  );
};

export default ScrollWrapper;
