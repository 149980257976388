/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
import React from 'react';
import {
  AnswerMultiChoiceItem,
  AnswerChoiceLabel,
  AnswerChoiceContent,
  AnswerWrapper,
} from './ChoiceItem.style';
import MathJaxItem from '../../../../../MathJaxContainer';
import UserChipList from '../../../UserChipList/UserChipList';
import GroupData from '../GroupData';

type Props = {
  isSelected: boolean;
  label: string | number;
  text: string;
  id: string | number;
  displayResult?: boolean;
  isCorrect: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelect: () => void;
  choice_answer?: string | number;
  choiceChosen?: Record<string, unknown>[];
  disabled?: boolean;
};
const ChoiceItem = ({
  isSelected,
  label,
  id,
  displayResult,
  isCorrect,
  text,
  onSelect,
  choice_answer,
  choiceChosen,
  disabled = false,
}: Props) => (
  <AnswerWrapper isCorrect={!!(displayResult && isCorrect)}>
    {choice_answer == id && <h1>Your Answer</h1>}
    <AnswerMultiChoiceItem key={id} onClick={onSelect}>
      <AnswerChoiceLabel
        data-testid="answer-choice-label"
        isSelected={isSelected}
        data-selected={isSelected}
        disabled={disabled}
      >
        {label}
      </AnswerChoiceLabel>
      <AnswerChoiceContent>
        <MathJaxItem data={text} />
      </AnswerChoiceContent>
    </AnswerMultiChoiceItem>
    <GroupData answerId={id} />
  </AnswerWrapper>
);

export default React.memo(ChoiceItem);
