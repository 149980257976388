import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _keys from 'lodash/keys';
import _meanBy from 'lodash/meanBy';
import _reduce from 'lodash/reduce';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';

export const EXAM_RESULT_GET_DETAIL = 'examResult/GET_DETAIL';
export const EXAM_RESULT_GET_DETAIL_SUCCESS = 'examResult/GET_DETAIL_SUCCESS';
export const EXAM_RESULT_GET_DETAIL_FAIL = 'examResult/GET_DETAIL_FAIL';

export const EXAM_RESULT_STUDENT_GET_DETAIL = 'examResult/STUDENT_GET_DETAIL';
export const EXAM_RESULT_STUDENT_GET_DETAIL_SUCCESS = 'examResult/STUDENT_GET_DETAIL_SUCCESS';
export const EXAM_RESULT_STUDENT_GET_DETAIL_FAIL = 'examResult/STUDENT_GET_DETAIL_FAIL';

export const SECTION_EXAMS_UPDATE_SCORE = 'sectionExam/UPDATE_SCORE';

const examResultStudentGetDetail = examSessionId => ({
  type: EXAM_RESULT_STUDENT_GET_DETAIL,
  examSessionId,
});

const examResultGetDetail = examSessionId => ({
  type: EXAM_RESULT_GET_DETAIL,
  examSessionId,
});

const examResultGetDetailSuccess = results => ({
  type: EXAM_RESULT_GET_DETAIL_SUCCESS,
  payload: results,
});

const examResultGetDetailFail = error => ({
  type: EXAM_RESULT_GET_DETAIL_FAIL,
  error,
});

const examUpdateScore = () => ({
  type: SECTION_EXAMS_UPDATE_SCORE,
});

export const actions = {
  examResultGetDetail,
  examResultGetDetailSuccess,
  examResultGetDetailFail,
  examResultStudentGetDetail,
  examUpdateScore,
};

const getResultByUserId = ({ examResult }) => _get(examResult, 'resultByUserId', {});
const getSubScoreList = ({ examResult }) => {
  const subscoreObj = _get(examResult, 'results[0].score.subscores', {});

  return _keys(subscoreObj);
};

const getResults = ({ examResult }) => _get(examResult, 'results', []);

const getAverageOverallScore = (state) => {
  const results = getResults(state);
  const isResultEmpty = results.length === 0;
  if (isResultEmpty) {
    return 0;
  }

  const averageScore = _meanBy(results, result => _get(result, 'score.overall_scaled_score.score', 0));

  return Math.round(averageScore);
};

const getAverageMaxScore = (state) => {
  const results = getResults(state);
  return _get(results, '[0].score.overall_scaled_score.max_score', 0);
};

const getsubjectExamSections = (examDetail, scorelist) => {
  const subjectExamSections = {};
  const sections = _get(examDetail, 'exam.sections', []);
  scorelist.forEach(subject => {
    let sectionCount = 0;
    sections.forEach(section => {
      if (subject.name.includes(section.name.split(' ')[0])) {
        sectionCount++;
      }
    })
    subjectExamSections[subject.name] = sectionCount;
  })
  return subjectExamSections;
}

const getCompletedSittings = (results, listSubscore) => {
  let completed_sittings = []
  _forEach(results, (item) => {
    let section_report = item.section_report || [];
    let userSittings = {};
    userSittings['user'] = item.user;
    userSittings['score'] = item.score;
    _forEach(listSubscore, (score) => {
      let taken_sections = 0;
      if (section_report) {
        _forEach(section_report, (report) => {
          let section_name = report.name.split(' ')[2];
          if (score.name.includes(section_name)) {
            taken_sections++;
          }
        })
        userSittings[score.name] = taken_sections;
      }
    })
    completed_sittings.push(userSittings);
  })
  return completed_sittings;
}
// TODO: refactor
const getAverageSubScoreList = (state, listSubscore, examDetail) => {
  const subjectExamSections = getsubjectExamSections(examDetail, listSubscore);
  const results = getResults(state);
  const completed_sittings = getCompletedSittings(results, listSubscore);
  const isResultIsEmpty = results.length === 0;
  if (isResultIsEmpty) {
    return {};
  }
  return _reduce(listSubscore, (prevValue, subscoreItem) => {
    const { is_subscore: isSubscore, name, score_id: scoreId } = subscoreItem;
    if (isSubscore) {
      return prevValue;
    }
    const allSittingsResults = completed_sittings.filter(result => {
      if (result[name] == subjectExamSections[name]) {
        return result
      }
    });
    const resultHasSubscoreItem = _filter(allSittingsResults, (result) => {
      const valid = _get(result, `score.subscores.${name}`, false);

      return valid;
    });
    if (resultHasSubscoreItem.length === 0) {
      return prevValue;
    }
    const averageScore = _meanBy(resultHasSubscoreItem, result => _get(result, `score.subscores.${name}.score.scaled_score`, 0));
    const maxScore = _get(resultHasSubscoreItem, `[0].score.subscores.${name}.score.max_scaled_score`, 0);
    return {
      ...prevValue,
      [scoreId]: {
        score: Math.round(averageScore),
        maxScore: Math.round(maxScore),
      },
    };
  }, {});
};
// TODO: refactor
const calcTotalResultOfSection = (state) => {
  const results = getResults(state);

  const isResultIsEmpty = results.length === 0;
  if (isResultIsEmpty) {
    return {};
  }

  return _reduce(results, (prevValue, item) => {
    const { section_report: sectionReport } = item;
    _forEach(sectionReport, (section) => {
      const { section_id: sectionId } = section;

      prevValue[sectionId] = _get(prevValue, sectionId, 0) + 1; // eslint-disable-line
    });

    return prevValue;
  }, {});
};

const getSectionReportByUserId = ({ examResult }, studentId) => _get(examResult, `resultByUserId.${studentId}.section_report`, []);
const getResultOfUser = ({ examResult }, studentId) => _get(examResult, `resultByUserId.${studentId}`, {});
const getLoadingExamResultDetail = ({ examResult }) => examResult.isGetDetail;
const shouldFetchExamResult = ({ examResult }) => examResult.shouldFetchExamResult;

export const selectors = {
  getsubjectExamSections,
  getCompletedSittings,
  getResultByUserId,
  getSubScoreList,
  getResults,
  getAverageOverallScore,
  getAverageMaxScore,
  getAverageSubScoreList,
  calcTotalResultOfSection,
  getSectionReportByUserId,
  getResultOfUser,
  getLoadingExamResultDetail,
  shouldFetchExamResult,
};

const initialState = {
  isGetDetail: false,
  resultByUserId: {},
  results: [],
  shouldFetchExamResult: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EXAM_RESULT_STUDENT_GET_DETAIL:
    case EXAM_RESULT_GET_DETAIL:
      if (state.shouldFetchExamResult) {
        return { ...state };
      }
      return {
        ...state, isGetDetail: true, resultByUserId: {}, results: [],
      };
    case EXAM_RESULT_GET_DETAIL_SUCCESS: {
      const { payload: results } = action;
      const resultByUserId = _keyBy(results, 'user');
      return {
        ...state, shouldFetchExamResult: false, isGetDetail: false, resultByUserId, results,
      };
    }
    case EXAM_RESULT_GET_DETAIL_FAIL:
      return { ...state, shouldFetchExamResult: false, isGetDetail: false };
    case SECTION_EXAMS_UPDATE_SCORE: {
      return {
        ...state,
        shouldFetchExamResult: true,
      };
    }
    default:
      return state;
  }
}
