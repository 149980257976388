import styled, { css } from 'styled-components';

export const AnswerSheetWrap = styled.div`
  width: 30em;
  margin-left: auto;
  padding: 1em;
  transition: all 500ms ease;
  ${({ isOpen }) => isOpen && css`
    width: 100% !important;
  `}
  @media (max-width: 1170px) {
    width: 20em;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: .5em;
  }
`;

export const AnswerSheetListWrap = styled.div`
  ${({ flexWrap }) => flexWrap && css`
    display: flex;
    flex-wrap: wrap;
  `}
  @media (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const AnswerSheetHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: .75em;
  border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorBorderInput};
  margin-bottom: 1em;
`;

export const AnswerSheetNumber = styled.div`
  flex: 1;
  text-align: center;
`;

export const IconToggleSheet = styled.i`
  margin-right: .5em;
  font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
  color: ${props => props.theme.colorStyled.ColorGrey};
  cursor: pointer;
  transition: all 500ms ease;
  &:hover {
    color: ${props => props.theme.colorStyled.ColorBlack};
  }
  ${({ isRotate }) => isRotate && css`
    transform: rotate(180deg);
  `}
`;

export const AnswerRow = styled.div`
  display: flex;
  align-items: center;
  padding: .325em 0em;
  margin: .325em 0em;
  padding-right: 30px;
  ${({ isSelecting }) => isSelecting && css`
    background-color: ${props => props.theme.colorStyled.ColorYellow};
  `}
  ${({ isAnswerSheetOpen }) => isAnswerSheetOpen && css`
    flex: 0 0 25%;
    @media (max-width: 1170px) {
      flex: 0 0 calc(100% / 3);
      padding-right: 15px;
    }
  `}
  ${({ rowSelected }) => rowSelected && css`
    background-color: #ffff5c;
  `}
  @media (max-width: 768px) {
    flex: 0 0 50%; 
  }
  @media (max-width: 560px) {
    flex: 0 0 100%;
  }
`;

export const AnswerRowNumber = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5em;
  font-size: ${props => props.theme.fontSize.SmallFontSize};
  flex: 0 0 3em;
  padding: .5em;
`;

export const AnswerRowBubbleWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  & > div {
    margin-right: 5px;
  }
`;

export const AnswerLabel = styled.div`
  flex: 0 0 2.25em;
  height: 2.25em;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 100%;
  cursor: pointer;
  font-family: 'MontserratSemiBold';
  border: 2px solid ${props => props.theme.colorStyled.ColorOrange};
  color: ${props => props.theme.colorStyled.ColorOrange};
  background: ${props => props.theme.colorStyled.ColorWhite};
  ${({ isSelected }) => isSelected && css`
    color: ${props => props.theme.colorStyled.ColorWhite};
    background: ${props => props.theme.colorStyled.ColorOrange};
  `}
`;

// bubble grid choice
export const AnswerBubbleGridChoiceWrap = styled.div``;

export const AnswerBubbleGridChoiceItem = styled.div`
  display: flex;
  border: 1.5px solid ${props => props.theme.colorStyled.ColorOrange};
`;

export const AnswerBubbleGridChoiceColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1.5px solid ${props => props.theme.colorStyled.ColorOrange};
  &:last-child {
    border-right: none;
  }
`;

export const AnswerBubbleGridChoiceSelected = styled.div`
  display: flex;
  border: 1.5px solid ${props => props.theme.colorStyled.ColorOrange};
  border-bottom: none;
`;

export const BubbleGridItem = styled.div`
  flex: 1;
  padding: 0em .375em .375em;
  p {
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'MontserratSemiBold';
    border: 1.5px solid ${props => props.theme.colorStyled.ColorOrange};
    color: ${props => props.theme.colorStyled.ColorOrange};
  }
  &:first-child {
    padding-top: .375em;
  }
  &:last-child {
    padding-bottom: .375em;
  }
  &:nth-child(2) {
    margin-bottom: .375em;
    border-bottom: 1.5px solid ${props => props.theme.colorStyled.ColorOrange};
  }
  ${({ noValue }) => noValue && css`
    p {
      border: none;
    }
  `}
  ${({ isSelected }) => isSelected && css`
    p {
      background-color: ${props => props.theme.colorStyled.ColorOrange};
      color: ${props => props.theme.colorStyled.ColorWhite};
    }
  `}
`;

export const BubbleChoiceSelected = styled.div`
  border-right: 1.5px solid ${props => props.theme.colorStyled.ColorOrange};
  padding: 0em .375em;
  &:last-child {
    border-right: none;
  }
  p {
    width: 2.25em;
    height: 2.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid transparent;
    font-family: 'MontserratSemiBold';
    color: ${props => props.theme.colorStyled.ColorOrange};
  }
`;

export const AnswerTestGroupAction = styled.div`
  button {
    width: 100%;
    height: 4em;
    border-radius: 0em;
    margin-top: 1em;
    text-transform: uppercase;
    font-family: 'MontserratSemiBold';
    background-color: ${props => props.theme.colorStyled.ColorRedGray};
    color: ${props => props.theme.colorStyled.ColorWhite};
  }
`;
