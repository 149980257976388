import styled from 'styled-components';

export const CalendarIcon = styled.div`
 font-size: 4em ;
`;

export const PremiumWrapper = styled.div`
  position: absolute;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 2;
  text-align: center;
  align-items: unset;
  padding-top: 10em;
`;

export const PremiumUpgradeButton = styled.div`
  border-radius: 5px;
  background: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  color: #fff;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
  width: fit-content;
  margin: 16px auto;
`;

export const PremiumUpgradeText = styled.div`
  font-size: 1em;
  color: #fff;
`;
