/* eslint-disable sort-keys */
import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Autocomplete, TextField, Button, Skeleton } from '@mui/material';
import {
  ReportGenoratorMain,
  ReportGenoratorContainer,
  ReportGenoratorHeader,
  ReportGenoratorImg,
} from './ReportGenerator.style';
import { PrimaryWrap } from '../Generals/stylesheets/General.style';
import { useGenerateReportsMutation, useAvailableReportsQuery } from './ReportQueries';
import PastReportsList from './PastReportsList';

const ReportGenerator = () => {
  const history = useHistory();
  const [category, setCategory] = useState({});
  const [orgs, setOrgs] = useState([]);
  const [orgValue, setOrgValue] = useState([]);

  const { isFetching, data: availableReportsList } = useAvailableReportsQuery();
  const generateReportsMutation = useGenerateReportsMutation();
  const categories = availableReportsList?.map((report) => report.categories).flat();
  const uniqueCategories = [
    ...Array.from(new Map(categories?.map((item) => [item.id, item])).values()),
  ];

  const availableReportsBasedOnCat = React.useMemo(
    () =>
      availableReportsList.filter((report) =>
        report.categories.some((c) => c.id === category.id),
      ),
    [availableReportsList, category],
  );
  const onCategoryChange = (e, value) => {
    setCategory(value || {})
    setOrgValue([]);
  }

  const handleGenerateReport = () => {
    const payload = {};
    const districtIds = [];
    const courseIds = [];
    orgs.forEach((org) => {
      if (org.level == 'district') districtIds.push(org.id);
      else if (org.level == 'course') courseIds.push(org.id);
    });
    if (districtIds.length > 0)
      payload.district = { category: category.id, ids: districtIds };
    if (courseIds.length > 0) payload.course = { category: category.id, ids: courseIds };
    generateReportsMutation.mutate(payload, {
      onSuccess: ({ response }) => {
        history.push({
          pathname: 'generator/list',
          state: { generatedReports: response.data, from: '/admin/reports/generator' },
        });
      },
    });
  };
  if (isFetching) {
    return (
      <Skeleton
        style={{ margin: '5rem' }}
        variant="rectangular"
        width="80%"
        height={800}
      />
    );
  }

  return (
    <PrimaryWrap fullHeight>
      <ReportGenoratorMain>
        <ReportGenoratorContainer>
          <ReportGenoratorHeader data-testid="generator-header">
            <h1>Report Generator</h1>
          </ReportGenoratorHeader>
          <ReportGenoratorImg src="/images/report-generator.png" alt="Report Generator" />
          <p>
            Select your query from the dropdown menus below and <br /> hit “GENERATE
            REPORT(S)”
          </p>
          <Autocomplete
            value={category}
            onChange={onCategoryChange}
            id="controllable-states-demo"
            data-testid="reportCategories"
            options={uniqueCategories}
            style={{ width: '40%', backgroundColor: '#fff' }}
            getOptionLabel={(option) => option.type  || ""}
            autoHighlight
            renderInput={(params) => <TextField {...params} label="Report Type" />}
            isOptionEqualToValue={(option, value) => option.name === value.name}
          />
          <Autocomplete
            value={orgValue}
            onChange={(event, newValue) => {
              setOrgs(newValue);
              setOrgValue(newValue);
            }}
            multiple
            data-testid="organizations"
            style={{ width: '40%', backgroundColor: '#fff' }}
            options={availableReportsBasedOnCat || []}
            filterSelectedOptions
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="School" />}
            isOptionEqualToValue={(option, value) => option.name === value.name}
          />
          <Button
            variant="contained"
            style={{ width: '40%' }}
            onClick={handleGenerateReport}
            data-testid="generateBtn"
          >
            GENERATE REPORT(S)
          </Button>
        </ReportGenoratorContainer>
        <PastReportsList />
      </ReportGenoratorMain>
    </PrimaryWrap>
  );
};

export default ReportGenerator;
