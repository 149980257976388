/* eslint-disable eqeqeq */
import React from 'react';
import { useGetExamEstimatedScore } from '@components/Component/Exams/hooks/query';
import TableWithWrapper from '@components/Atoms/Table/TableWithWrapper';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import { useExamScoreRowData } from '@components/Component/Exams/ExamScoreTable/ExamScoreTable.hooks';
import { EXAM_SESSION_TYPES } from '@utils/constants';
import { useGetSectionExamsScores, useExamSessions } from './hooks';
import { OverallObject, SubjectSelectOptionValue } from './SubjectSelect';

const columns = [
  {
    id: 1,
    headerTitle: 'Student',
    relatedKey: 'studentName',
    defaultValue: '',
    isSortable: true,
    style: { textAlign: 'center', minWidth: '10rem' },
  },
  {
    id: 2,
    headerTitle: 'Estimated Score',
    relatedKey: 'estimateScore',
    defaultValue: '--',
    style: { textAlign: 'center' },
  },
  {
    id: 3,
    headerTitle: 'Practice Test 1',
    relatedKey: 'practiceTest1',
    style: { textAlign: 'center' },
    defaultValue: '--',
    valueGetter: (param) => !!param && Math.round(param),
  },
  {
    id: 4,
    headerTitle: 'Practice Test 2',
    relatedKey: 'practiceTest2',
    style: { textAlign: 'center' },
    defaultValue: '--',
    valueGetter: (param) => !!param && Math.round(param),
  },
  {
    id: 5,
    headerTitle: 'Practice Test 3',
    relatedKey: 'practiceTest3',
    style: { textAlign: 'center' },
    defaultValue: '--',
    valueGetter: (param) => !!param && Math.round(param),
  },
  {
    id: 6,
    headerTitle: 'Score Improvement',
    relatedKey: 'improvement',
    style: { textAlign: 'center' },
    defaultValue: '--',
  },
];

type EstimatedScoreTableProps = {
  selectedSubject: SubjectSelectOptionValue;
};

const EstimatedScoreTableV2 = ({ selectedSubject }: EstimatedScoreTableProps) => {
  const studentList = useSectionStudentWithFilter();
  const { data: estimatedScoreData } = useGetExamEstimatedScore();
  const { data: SectionExamsScores } = useGetSectionExamsScores();
  const { data: examSessions } = useExamSessions();

  const firstPracticeTestDetails = examSessions?.find(
    (examSession) => examSession.practice_test_order == 0,
  );
  const secondPracticeTestDetails = examSessions?.find(
    (examSession) => examSession.practice_test_order == 1,
  );
  // TODO: add condition for find to get the earliest mid test
  const thirdPracticeTestDetails = examSessions?.find(
    (examSession) => examSession.practice_test_order == 2,
  );
  const assessmentNodeId = selectedSubject?.id;
  const firstPacticeTestRowData = useExamScoreRowData(
    firstPracticeTestDetails,
    SectionExamsScores?.[firstPracticeTestDetails?.id],
  );
  const secondPracticeTestRowData = useExamScoreRowData(
    secondPracticeTestDetails,
    SectionExamsScores?.[secondPracticeTestDetails?.id],
  );
  const thirdPracticeTestRowData = useExamScoreRowData(
    thirdPracticeTestDetails,
    SectionExamsScores?.[thirdPracticeTestDetails?.id],
  );
  const rows = React.useMemo(
    () =>
      studentList.map((student) => {
        const studentFirstPracticeTestScoreObject = firstPacticeTestRowData.find(
          (el) => el.id == student?.id,
        );
        const studentSecondPracticeTestScoreObject = secondPracticeTestRowData?.find((el) => el.id == student?.id);
        const studentThirdPracticeTestScoreObject = thirdPracticeTestRowData?.find((el) => el.id == student?.id);
        const estimatedOverAllScore = estimatedScoreData?.student_data?.[student?.id];
        const rangeEstimatedOverAllScore =
          !!estimatedOverAllScore?.low_score &&
          `${Math.round(estimatedOverAllScore?.low_score)} - ${Math.round(
            estimatedOverAllScore?.high_score,
          )}`;
        const estimatedSubjectScore = estimatedScoreData?.subjects?.find(
          (el) => el.assessment_node_id === selectedSubject?.id,
        );
        const studentEstimatedScoreSubject =
          estimatedSubjectScore?.student_data?.[student?.id];
        const rangeEstimatedSubjectScore =
          !!studentEstimatedScoreSubject?.low_score &&
          `${Math.round(studentEstimatedScoreSubject?.low_score)} - ${Math.round(
            studentEstimatedScoreSubject?.high_score,
          )}`;
        const practiceTest1 = Math.round(studentFirstPracticeTestScoreObject?.[assessmentNodeId]);
        const practiceTest2 = Math.round(studentSecondPracticeTestScoreObject?.[assessmentNodeId]);
        const practiceTest3 = Math.round(studentThirdPracticeTestScoreObject?.[assessmentNodeId]);
        let improvement: number | undefined = undefined;
        if (!!practiceTest3 && !!practiceTest1) {
          improvement = practiceTest3 - practiceTest1;
        } else if (!!practiceTest2 && !!practiceTest1) {
          improvement = practiceTest2 - practiceTest1;
        }
        return {
          id: student?.id,
          studentName: `${student?.last_name} ${student?.first_name}`,
          practiceTest1,
          practiceTest2,
          practiceTest3,
          estimateScore:
            selectedSubject?.id === OverallObject?.id
              ? rangeEstimatedOverAllScore
              : rangeEstimatedSubjectScore,
          improvement,
        };
      }),
    [
      assessmentNodeId,
      estimatedScoreData?.student_data,
      estimatedScoreData?.subjects,
      secondPracticeTestRowData,
      thirdPracticeTestRowData,
      firstPacticeTestRowData,
      selectedSubject?.id,
      studentList,
    ],
  );

  return <TableWithWrapper columns={columns} rows={rows} hasPagination sx={{ p: 0 }} />;
};

export default EstimatedScoreTableV2;
