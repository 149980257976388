import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LessonBar from '../../components/LessonBar';
import {
  selectors as sectionSessionSelector,
} from '../../reducers/sectionSession';
import { selectors as UserSelectors } from '../../reducers/user';
import { ROLE_TYPE } from '../../utils/enums';
import { actions as ScheduleModalActions } from '../../reducers/scheduleModal';

const mapStateToProps = (state, { match }) => {
  const { params } = match;
  const { lessonId } = params;
  const isTeacher = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR;
  return {
    nextSession: sectionSessionSelector.getNextSession(state, lessonId),
    isTeacher,
  };
};

const mapDispachToProps = {
  openFormModal: ScheduleModalActions.openFormModal,
};

export default withRouter(connect(mapStateToProps, mapDispachToProps)(LessonBar));
