import { selectors as CourseSelectors } from '../reducers/courses/courseReducer';
import _get from 'lodash/get';

export const getFeatureFlagAccess = (accessData, courseId, sectionId, state) => {
    const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
    const level = accessData.level;
    if (level === 'course') {
      return _get(accessData.user_access, courseId, {})?.has_access;
    }
    if (level === 'section') {
      return _get(accessData.user_access, sectionId, {})?.has_access;
    }
    if (level === 'organization') {
      return _get(accessData.user_access, courseDetail?.organization?.id, {})?.has_access;
    }
    if (level === 'district') {
      return _get(accessData.user_access, courseDetail?.organization?.district?.id, {})?.has_access;
    }
    if (level === 'user') {
      return _get(accessData.user_access, state.user.userData.id, {})?.has_access;
    }
  };
