/* eslint-disable eqeqeq */

import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _forOwn from 'lodash/forOwn';
import moment from 'moment';
import { selectors as sectionSubjectSelectors } from '../sectionSubjects';

export const GET_LESSON_ACTIVITY = 'activity/GET_LESSON_ACTIVITY';
export const GET_LESSON_ACTIVITY_SUCCESS = 'activity/GET_LESSON_ACTIVITY_SUCCESS';
export const GET_LESSON_ACTIVITY_FAIL = 'activity/GET_LESSON_ACTIVITY_FAIL';

export const POST_LESSON_ACTIVITY = 'activity/POST_LESSON_ACTIVITY';
export const POST_LESSON_ACTIVITY_SUCCESS = 'activity/POST_LESSON_ACTIVITY_SUCCESS';
export const POST_LESSON_ACTIVITY_FAIL = 'activity/POST_LESSON_ACTIVITY_FAIL';

export const GET_ALL_LESSON_ACTIVITY = 'activity/GET_ALL_LESSON_ACTIVITY';
export const GET_ALL_LESSON_ACTIVITY_SUCCESS = 'activity/GET_ALL_LESSON_ACTIVITY_SUCCESS';
export const GET_ALL_LESSON_ACTIVITY_FAIL = 'activity/GET_ALL_LESSON_ACTIVITY_FAIL';

export const GET_SECTION_PRACTICE_SESSION_ACTIVITY = 'activity/GET_SECTION_PRACTICE_SESSION_ACTIVITY';
export const GET_SECTION_PRACTICE_SESSION_ACTIVITY_SUCCESS = 'activity/GET_SECTION_PRACTICE_SESSION_ACTIVITY_SUCCESS';
export const GET_SECTION_PRACTICE_SESSION_ACTIVITY_FAIL = 'activity/GET_SECTION_PRACTICE_SESSION_ACTIVITY_FAIL';

export const GET_SECTION_REVIEW_SESSION_DATA = 'activity/GET_SECTION_REVIEW_SESSION_DATA';
export const GET_SECTION_REVIEW_SESSION_DATA_SUCCESS = 'activity/GET_SECTION_REVIEW_SESSION_DATA_SUCCESS';
export const GET_SECTION_REVIEW_SESSION_DATA_FAIL = 'activity/GET_SECTION_REVIEW_SESSION_DATA_FAIL';

export const GET_STUDENT_REVIEW_SESSION_DATA = 'activity/GET_STUDENT_REVIEW_SESSION_DATA';
export const GET_STUDENT_REVIEW_SESSION_DATA_SUCCESS = 'activity/GET_STUDENT_REVIEW_SESSION_DATA_SUCCESS';
export const GET_STUDENT_REVIEW_SESSION_DATA_FAIL = 'activity/GET_STUDENT_REVIEW_SESSION_DATA_FAIL';

export const GET_ALL_LESSON_ACTIVITY_SUMMARY = 'activity/GET_ALL_LESSON_ACTIVITY_SUMMARY';
export const GET_ALL_LESSON_ACTIVITY_SUMMARY_SUCCESS = 'activity/GET_ALL_LESSON_ACTIVITY_SUMMARY_SUCCESS';
export const GET_ALL_LESSON_ACTIVITY_SUMMARY_FAIL = 'activity/GET_ALL_LESSON_ACTIVITY_SUMMARY_FAIL';

export const ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY = 'activity/ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY';
export const ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_SUCCESS = 'activity/ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_SUCCESS';
export const ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_FAIL = 'activity/ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_FAIL';

export const INSERT_LESSON_ACTIVITY_SUMMARY = 'activity/INSERT_LESSON_ACTIVITY_SUMMARY';
export const INSERT_GROUP_ACTIVITY_SUMMARY = 'activity/INSERT_GROUP_ACTIVITY_SUMMARY';


export const lessonActivityList = ({ activity }) => (activity.allLessionActivityAllIds.map(id => activity.allLessionActivityById[id])) || [];
export const lessonActivityById = ({ activity }) => _get(activity, 'allLessionActivityById', {});
export const lessonActivityAllId = ({ activity }) => _get(activity, 'allLessionActivityAllIds', []);
export const sectionPracticeActivityList = ({ activity }) => (
  (Object.values(activity.sectionPracticeActivityById) || [])
    .sort((A, B) => moment(B.started).diff(moment(A.started)))
);

export const sectionPracticeActivityListByUserId = (state, userId) => {
  const list = sectionPracticeActivityList(state);
  return list.filter(x => x.user == userId);
};

export const sectionUnitPracticeActivityListByUserId = (state, userId, unitId) => {
  const list = sectionPracticeActivityListByUserId(state, userId);
  return list.filter(x => x.unit == unitId && !x.lesson);
};

export const sectionLessonPracticeActivityListByUserId = (state, userId, unitId, lessonId) => {
  const list = sectionPracticeActivityListByUserId(state, userId);
  return list.filter(x => x.unit == unitId && x.lesson == lessonId);
};
export const reviewList = ({ activity }) => _get(activity, 'reviewData', []);

const getReviewByUserId = ({ activity }) => _get(activity, 'reviewByUserId', {});
const getMaxColumns = ({ activity }) => _get(activity, 'maxColumn', 0);
const getIsGetSessionData = ({ activity }) => _get(activity, 'isGetSessionData', false);
const getReviewDataByUserId = ({ activity }, userId) => _get(activity, `reviewByUserId.${userId}`, []);
const isGetSectionPractice = ({ activity }) => activity.isGetSectionPractice;

const getLessonActivitySummaryById = ({ activity }) => _get(activity, 'lessonActivitySummaryById', {});
const getLessonActivitySummaryAllIds = ({ activity }) => _get(activity, 'lessonActivitySummaryAllIds', []);
const getLessonAcitivitySummaryList = (state) => {
  const lessonActivitySummaryById = getLessonActivitySummaryById(state);
  const lessonActivitySummaryAllIds = getLessonActivitySummaryAllIds(state);
  return lessonActivitySummaryAllIds
    .map(id => lessonActivitySummaryById[id]);
};

const getGroupActivitySummaryById = ({ activity }) => _get(activity, 'groupActivitySummaryById', {});

const percentLessonPassed = (state) => {
  const lessonActivitySummaryById = getLessonActivitySummaryById(state);
  const lessonActivitySummaryAllIds = getLessonActivitySummaryAllIds(state);
  const countLessonPassed = lessonActivitySummaryAllIds.map(id => lessonActivitySummaryById[id].completed).length;
  const countAllLessonGroupInAllSubject = sectionSubjectSelectors.countAllLessonGroupInAllSubject(state);
  return (Math.round(countLessonPassed / countAllLessonGroupInAllSubject * 100)) || 0;
};

const countLessonPassedWithSubjectId = (state, subjectId) => {
  const lessonActivitySummaryById = getLessonActivitySummaryById(state);
  const lessonActivitySummaryAllIds = getLessonActivitySummaryAllIds(state);
  const countLessonPassed = lessonActivitySummaryAllIds
    .map(id => lessonActivitySummaryById[id])
    .filter(lesson => lesson.completed && lesson.subject === subjectId)
    .length;
  return countLessonPassed;
};

const lessonPassedWithSubject = (state, subjectId) => {
  const lessonActivitySummaryById = getLessonActivitySummaryById(state);
  const lessonActivitySummaryAllIds = getLessonActivitySummaryAllIds(state);
  return lessonActivitySummaryAllIds
    .map(id => lessonActivitySummaryById[id])
    .filter(lesson => lesson.completed && lesson.subject === subjectId);
};

const getUserActivityBySubjectId = ({ activity }) => _get(activity, 'userActivityBySubjectId', {});

const getTeacherActivityBySubjectId = ({ activity }) => _get(activity, 'teacherActivityBySubjectId', {});
const selectLessonsDetail = ({activity}) => activity.allLessons;
export const selectors = {
  lessonActivityList,
  lessonActivityById,
  lessonActivityAllId,
  sectionPracticeActivityList,
  sectionPracticeActivityListByUserId,

  sectionUnitPracticeActivityListByUserId,
  sectionLessonPracticeActivityListByUserId,
  reviewList,
  getReviewByUserId,
  getMaxColumns,
  getIsGetSessionData,
  getReviewDataByUserId,
  isGetSectionPractice,

  getLessonActivitySummaryById,
  getLessonActivitySummaryAllIds,
  getLessonAcitivitySummaryList,
  percentLessonPassed,
  countLessonPassedWithSubjectId,
  lessonPassedWithSubject,

  getGroupActivitySummaryById,

  getUserActivityBySubjectId,
  getTeacherActivityBySubjectId,
  selectLessonsDetail,
};

const activityGetAllTeacherActivitySummary = (sectionId, subjectId, unitId, lessonId, slideId) => ({ // for all teacher
  type: ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY,
  sectionId,
  unitId,
  lessonId,
  slideId,
  subjectId,
});

const activityGetAllTeacherActivitySummarySuccess = activityList => ({
  type: ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_SUCCESS,
  payload: activityList,
});

const activityGetAllTeacherActivitySummaryFail = error => ({
  type: ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_FAIL,
  error,
});

// actions
const getLessonActivity = (sectionId, lessonId) => ({
  type: GET_LESSON_ACTIVITY,
  sectionId,
  lessonId,
});

const getLessonActivitySuccess = payload => ({
  type: GET_LESSON_ACTIVITY_SUCCESS,
  payload,
});

const getLessonActivityFail = error => ({
  type: GET_LESSON_ACTIVITY_FAIL,
  error,
});

const postLessonActivity = (sectionId, body) => ({
  type: POST_LESSON_ACTIVITY,
  sectionId,
  body,
});

const postLessonActivitySuccess = payload => ({
  type: POST_LESSON_ACTIVITY_SUCCESS,
  payload,
});

const postLessonActivityFail = error => ({
  type: POST_LESSON_ACTIVITY_FAIL,
  error,
});

const getAllLessonActivity = sectionId => ({
  type: GET_ALL_LESSON_ACTIVITY,
  sectionId,
});

const getAllLessonActivitySuccess = payload => ({
  type: GET_ALL_LESSON_ACTIVITY_SUCCESS,
  payload,
});

const getAllLessonActivityFail = error => ({
  type: GET_ALL_LESSON_ACTIVITY_FAIL,
  error,
});

const getSessionPracticeSessionActivity = (sectionId, unitId, lessonId, studentId, reviewId) => ({
  type: GET_SECTION_PRACTICE_SESSION_ACTIVITY,
  sectionId,
  unitId,
  lessonId,
  studentId,
  reviewId,
});

const getSessionPracticeSessionActivitySuccess = practiceSessionActivityList => ({
  type: GET_SECTION_PRACTICE_SESSION_ACTIVITY_SUCCESS,
  practiceSessionActivityList,
});
const getSessionPracticeSessionActivityFail = error => ({
  type: GET_SECTION_PRACTICE_SESSION_ACTIVITY_FAIL,
  error,
});

const getSectionReviewSessionData = (sectionId, reviewId) => ({ // for teacher
  type: GET_SECTION_REVIEW_SESSION_DATA,
  sectionId,
  reviewId,
});
const getSectionReviewSessionDataSuccess = payload => ({
  type: GET_SECTION_REVIEW_SESSION_DATA_SUCCESS,
  payload,
});

const getSectionReviewSessionDataFail = error => ({
  type: GET_SECTION_REVIEW_SESSION_DATA_FAIL,
  error,
});

const getStudentReviewSessionData = reviewId => ({ // for student
  type: GET_STUDENT_REVIEW_SESSION_DATA,
  reviewId,
});

const getStudentReviewSessionDataSuccess = payload => ({
  type: GET_STUDENT_REVIEW_SESSION_DATA_SUCCESS,
  payload,
});

const getStudentReviewSessionDataFail = error => ({
  type: GET_STUDENT_REVIEW_SESSION_DATA_FAIL,
  error,
});

const getAllLessonActivitySummary = (sectionId, subjectId, unitId, lessonId, slideId) => ({ // for current user
  type: GET_ALL_LESSON_ACTIVITY_SUMMARY,
  sectionId,
  subjectId,
  unitId,
  lessonId,
  slideId,
});
const getAllLessonActivitySummarySuccess = lessonList => ({
  type: GET_ALL_LESSON_ACTIVITY_SUMMARY_SUCCESS,
  lessonList,
});
const getAllLessonActivitySummaryFail = error => ({
  type: GET_ALL_LESSON_ACTIVITY_SUMMARY_FAIL,
  error,
});

const insertLessonActivitySummary = lesson => ({
  type: INSERT_LESSON_ACTIVITY_SUMMARY,
  lesson,
});

const insertGroupActivitySummary = lesson => ({
  type: INSERT_GROUP_ACTIVITY_SUMMARY,
  lesson,
});

const initialState = {
  allLessionActivityById: {},
  allLessionActivityAllIds: [],
  sectionPracticeActivityById: {},
  sectionPracticeActivityAllIds: [],
  isGetting: false,
  error: null,
  success: false,
  isPosting: false,
  postSuccess: false,
  sectionPracticeSuccess: false,
  isGetSectionPractice: false,
  reviewError: null,

  reviewData: [],
  reviewIds: [],
  reviewById: {},
  reviewByUserId: {},
  maxColumn: 0,
  isGetSessionData: false,

  lessonActivitySummaryById: {},
  lessonActivitySummaryAllIds: [],
  isGetActivitySummary: true,
  activitySummaryError: null,

  groupActivitySummaryById: {},
  groupActivitySummaryAllIds: [],

  teacherActivityBySubjectId: {},
  userActivityBySubjectId: {},
  allLessons: []
};

export const actions = {
  getLessonActivity,
  getLessonActivitySuccess,
  getLessonActivityFail,
  postLessonActivity,
  postLessonActivitySuccess,
  postLessonActivityFail,
  getAllLessonActivity,
  getAllLessonActivitySuccess,
  getAllLessonActivityFail,
  getSessionPracticeSessionActivity,
  getSessionPracticeSessionActivitySuccess,
  getSessionPracticeSessionActivityFail,
  getSectionReviewSessionData,
  getSectionReviewSessionDataSuccess,
  getSectionReviewSessionDataFail,

  getStudentReviewSessionData,
  getStudentReviewSessionDataSuccess,
  getStudentReviewSessionDataFail,

  getAllLessonActivitySummary,
  getAllLessonActivitySummarySuccess,
  getAllLessonActivitySummaryFail,

  insertLessonActivitySummary,
  insertGroupActivitySummary,

  activityGetAllTeacherActivitySummary,
  activityGetAllTeacherActivitySummarySuccess,
  activityGetAllTeacherActivitySummaryFail,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LESSON_ACTIVITY_SUCCESS: {
      const { payload } = action;
      const allLessionActivityById = _keyBy(payload, 'lesson');
      const allLessionActivityAllIds = payload.map(x => x.lesson);
      return {
        ...state,
        allLessionActivityById,
        allLessionActivityAllIds,
        success: true,
        error: null,
        isGetting: false,
        allLessons: payload,
      };
    }
    case GET_ALL_LESSON_ACTIVITY_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
        success: false,
        isGetting: false,
      };
    }
    case GET_LESSON_ACTIVITY_SUCCESS: {
      const { payload } = action;
      const id = state.allLessionActivityAllIds.find(x => x == payload.id);
      const allLessionActivityAllIds = id ? [...state.allLessionActivityAllIds] : [...state.allLessionActivityAllIds, payload.id];
      return {
        ...state,
        allLessionActivityById: {
          ...state.allLessionActivityById,
          [payload.id]: payload,
        },
        allLessionActivityAllIds,
        error: null,
        isGetting: false,
      };
    }

    case GET_LESSON_ACTIVITY_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
        isGetting: false,
        success: false,
      };
    }

    case POST_LESSON_ACTIVITY_SUCCESS: {
      const { payload } = action;
      const { lesson } = payload;
      const id = state.allLessionActivityAllIds.find(x => x == lesson);
      const allLessionActivityAllIds = id ? [...state.allLessionActivityAllIds] : [...state.allLessionActivityAllIds, payload.lesson];
      return {
        ...state,
        allLessionActivityById: {
          ...state.allLessionActivityById,
          [lesson]: payload,
        },
        allLessionActivityAllIds,
        error: null,
        postSuccess: true,
        isPosting: false,
      };
    }

    case POST_LESSON_ACTIVITY_FAIL: {
      const { error } = action;
      return {
        ...state,
        isPosting: false,
        error,
        success: false,
      };
    }
    case GET_SECTION_PRACTICE_SESSION_ACTIVITY:
      return {
        ...state,
        error: null,
        sectionPracticeSuccess: false,
        isGetSectionPractice: true,
      };
    case GET_SECTION_PRACTICE_SESSION_ACTIVITY_SUCCESS: {
      const { practiceSessionActivityList } = action;
      const sectionPracticeActivityById = _keyBy(practiceSessionActivityList, 'id');
      const sectionPracticeActivityAllIds = practiceSessionActivityList.map(x => x.id);

      return {
        ...state,
        sectionPracticeActivityById,
        sectionPracticeActivityAllIds,
        error: null,
        sectionPracticeSuccess: true,
        isGetSectionPractice: false,
      };
    }

    case GET_SECTION_PRACTICE_SESSION_ACTIVITY_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
        sectionPracticeSuccess: false,
        isGetSectionPractice: false,
      };
    }

    case GET_STUDENT_REVIEW_SESSION_DATA:
    case GET_SECTION_REVIEW_SESSION_DATA:
      return {
        ...state,
        isGetSessionData: true,
      };

    case GET_STUDENT_REVIEW_SESSION_DATA_SUCCESS:
    case GET_SECTION_REVIEW_SESSION_DATA_SUCCESS: {
      const { payload: reviewActiviyList } = action;
      const Ids = reviewActiviyList.map(item => item.id);
      const ByIds = _keyBy(reviewActiviyList, 'id');
      const reviewByUserId = _groupBy(reviewActiviyList, 'user');
      let maxColumn = 0;

      _forOwn(reviewByUserId, (reviewsList = []) => {
        if (reviewsList.length > maxColumn) {
          maxColumn = reviewsList.length;
        }
      });

      return {
        ...state,
        reviewIds: Ids,
        reviewById: ByIds,
        reviewByUserId,
        maxColumn,
        reviewError: null,
        isGetSessionData: false,
      };
    }

    case GET_STUDENT_REVIEW_SESSION_DATA_FAIL:
    case GET_SECTION_REVIEW_SESSION_DATA_FAIL: {
      const { error } = action;
      return {
        ...state,
        reviewError: error,
        isGetSessionData: false,
      };
    }
    case GET_ALL_LESSON_ACTIVITY_SUMMARY_SUCCESS: {
      const { lessonList } = action;
      const lessonActivitySummaryById = _keyBy(lessonList, 'lesson_group');
      const lessonActivitySummaryAllIds = lessonList.map(x => x.lesson_group);
      const userActivityBySubjectId = _groupBy(lessonList, 'subject');

      return {
        ...state,
        lessonActivitySummaryAllIds,
        lessonActivitySummaryById,
        isGetActivitySummary: false,
        userActivityBySubjectId,
      };
    }
    case GET_ALL_LESSON_ACTIVITY_SUMMARY_FAIL: {
      const { error } = action;
      return {
        ...state,
        activitySummaryError: error,
        isGetActivitySummary: false,
      };
    }

    case INSERT_LESSON_ACTIVITY_SUMMARY: {
      const { lesson } = action;
      const { lessonActivitySummaryAllIds } = state;
      const {
        id, subject,
        unit, completed,
      } = lesson;
      const allIds = lessonActivitySummaryAllIds.indexOf(id) === -1 ? [...lessonActivitySummaryAllIds, id] : [...lessonActivitySummaryAllIds];
      return {
        ...state,
        lessonActivitySummaryAllIds: allIds,
        lessonActivitySummaryById: {
          ...state.lessonActivitySummaryById,
          [id]: {
            lesson_group: id,
            subject,
            unit,
            completed,
          },
        },
      };
    }

    case INSERT_GROUP_ACTIVITY_SUMMARY: {
      const { lesson } = action;
      const { groupActivitySummaryAllIds } = state;
      const {
        id, subject,
        unit, completed,
      } = lesson;
      const allIds = groupActivitySummaryAllIds.indexOf(id) === -1 ? [...groupActivitySummaryAllIds, id] : [...groupActivitySummaryAllIds];
      return {
        ...state,
        groupActivitySummaryAllIds: allIds,
        groupActivitySummaryById: {
          ...state.groupActivitySummaryById,
          [id]: {
            lesson_group: id,
            subject,
            unit,
            completed,
          },
        },
      };
    }
    case ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY:
      return state;
    case ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_SUCCESS: {
      const { payload } = action;
      const teacherActivityBySubjectId = _groupBy(payload, 'subject');

      return {
        ...state,
        teacherActivityBySubjectId,
      };
    }
    case ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY_FAIL:
      return state;
    default:
      return state;
  }
}
