import {
  all, call, put,
  takeLeading,
} from 'redux-saga/effects';
import { getDistrict } from '../../apis/register';
import {
  DISTRICTS_FETCH_DISTRICT_LIST,
  actions as DistrictActions,
} from '../../reducers/districts';

export function* fetchDistricts({ payload }) {
  const { districtQuery } = payload;
  if (!districtQuery || !districtQuery.trim()) {
    yield put(DistrictActions.fetchDistrictListSuccess([]));
    return;
  }
  try {
    const { response, error } = yield call(getDistrict, districtQuery);
    if (error) throw error;

    const { data: districtList } = response;

    yield put(DistrictActions.fetchDistrictListSuccess(districtList.slice(0, 50)));
  } catch (error) {
    yield put(DistrictActions.fetchDistrictListFailed(error.message || error));
  }
}

export default function* districtsSaga() {
  yield all([
    takeLeading(DISTRICTS_FETCH_DISTRICT_LIST, fetchDistricts),
  ]);
}
