import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import DashboardExamScoreReportTable from '../../containers/DashboardExamScoreReportTable';
import DashboardExamScoreReportFake from '../DashboardExamScoreReportFake';

export default class DashboardExamScoreReport extends Component {
  static propTypes = {
    sectionsStudentGet: PropTypes.func.isRequired,
    sectionExamGet: PropTypes.func.isRequired,
    sectionsEstimatedGet: PropTypes.func.isRequired,
    sectionId: PropTypes.any,
    sectionsExamsScoresGet: PropTypes.func.isRequired,
    courseDetail: PropTypes.shape(),
    sectionDetail: PropTypes.shape(),
    isSideBarClose: PropTypes.bool,
  };

  componentDidMount() {
    const { sectionId } = this.props;

    if (sectionId !== null) {
      this.initialData(sectionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionId } = this.props;
    const { sectionId: prevSectionId } = prevProps;

    if (sectionId !== prevSectionId && sectionId !== null) {
      this.initialData(sectionId);
    }
  }

  initialData = (sectionId) => {
    const {
      sectionsEstimatedGet,
      sectionExamGet,
      sectionsStudentGet,
      sectionsExamsScoresGet,
    } = this.props;

    sectionsExamsScoresGet(sectionId);
    sectionsStudentGet(sectionId);
    sectionsEstimatedGet(sectionId);
    sectionExamGet(sectionId);
  };

  render() {
    const { courseDetail, sectionDetail, isSideBarClose } = this.props;
    const { subscription = {} } = courseDetail;
    const { subscription_type: subType } = subscription;

    return (
      <React.Fragment>
        <BreadcumbsFilter
          haveSub={isSideBarClose}
          breadcumbSubList={[courseDetail.name, sectionDetail.name]}
          breadcumbList={['Dashboard', 'Detail', 'Scores Detail']}
          mobileTitle="Exam Score"
        />

        {subType === 3 ? (
          <DashboardExamScoreReportFake />
        ) : (
          <DashboardExamScoreReportTable />
        )}
      </React.Fragment>
    );
  }
}
