export const ColumnSize = [
  { smallSize: true },
  { size: '80', smallSize: true, textCenter: true },
  {
    size: '150', smallSize: true, textCenter: true, textLowercase: true,
  },
  { size: '125', smallSize: true, textRight: true },
];

export const ColumnSizeTeacherInvited = [
  { smallSize: true },
  { smallSize: true },
  { smallSize: true },
  {
    size: '90', smallSize: true, textRight: true,
  },
];
