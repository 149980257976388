import { connect } from 'react-redux';
import _get from 'lodash/get';
import UnitPracticeOverviewReview from '../../components/UnitPracticeOverviewReview';
import { actions as UserActivityActions, selectors as UserActivitySelectors } from '../../reducers/userActivities';
import { actions as SectionUnitActions, selectors as sectionUnitSelectors } from '../../reducers/sectionUnit';
import { actions as PracticeActions, selectors as PracticeSelectors } from '../../reducers/practice';
import { selectors as UserSelectors } from '../../reducers/user';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';

const mapStateToProps = (state, { match, location }) => {
  const { params } = match;
  const { unitId, sectionId, lessonId } = params;
  const query = new URLSearchParams(_get(location, 'search', ''));
  const reviewId = query.get('review');
  const practiceList = UserActivitySelectors.getUserPracticeSessionActivitiesWithData(
    state,
    sectionId,
    unitId,
    lessonId,
  );
  const practiceListFilter = practiceList.filter((item) => item.ended);

  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');

  return {
    courseData,
    reviewId,
    curriculumId,
    isInitialized: PracticeSelectors.isInitialized(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    currentUser: UserSelectors.getCurrentUser(state),
    currentUserId: UserSelectors.getUserId(state),
    lessons: sectionUnitSelectors.getAllLessonOfUnit(state, unitId),
    isLoading: UserActivitySelectors.isGettingUserPracticeSessionActivities(state),
    isGettingLesson: sectionUnitSelectors.isGettingLesson(state),
    practiceList: practiceListFilter,
  };
};

const mapDispatchToProps = {
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  getAllPracticeSessionActivities: UserActivityActions.getAllPracticeSessionActivities,
  initializePracticeSession: PracticeActions.initializePracticeSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPracticeOverviewReview);
