import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Box from '../Box';
import Table from './Table';
import { TableHeaderTypes, rowObjectType } from './Types';

const tableWrapperStyle = { overflow: 'auto', width: '100%', p: 2 };
const tableStyle = {
  overflow: 'visible',
  minWidth: '100%',
  width: { xs: '62.5rem', sm: '100%', md: '100%' },
};

type TableProps<T> = {
  rows: T[];
  columns: Array<TableHeaderTypes>;
  sx?: SxProps<Theme> | any;
  hasPagination: boolean;
  headerSx?: SxProps<Theme>;
};
const TableWithWrapper = <T extends rowObjectType>({
  columns,
  rows,
  sx,
  hasPagination,
  headerSx,
}: TableProps<T>) => (
  <Box sx={[tableWrapperStyle, { ...sx }]}>
    <Table
      columns={columns}
      rows={rows}
      HeaderSx={headerSx}
      sx={tableStyle}
      hasPagination={hasPagination}
    />
  </Box>
);

export default TableWithWrapper;
