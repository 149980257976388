import { connect } from 'react-redux';
import SectionCodeForm from '../../components/TeacherRegisterForm/SectionCodeForm';
import { selectors as AuthSelectors } from '../../reducers/auth';
import { getFirstCourseData } from '../../reducers/courses';
import { selectors as SectionSelectors } from '../../reducers/sections';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import {
  createRegSection,
  isShowSectionConfirmation,
  teacherRegisterNextStep,
} from '../../reducers/register';

const mapStateToProps = (state) => {
  const courseData = getFirstCourseData(state);
  const { id: courseId, curriculum: curriculumId } = courseData;

  return {
    courseId,
    courseData,
    curriculumId,
    sectionData: SectionSelectors.getFirstSection(state),
    placementTypes: CurriculumSelectors.getPlacementTypes(state, curriculumId),
    exitTypes: CurriculumSelectors.getExitTypes(state, curriculumId),
    subject: CurriculumSelectors.getSubjectList(state, curriculumId),
    errorMessage: AuthSelectors.getErrorMessage(state),
    isShowSectionConfirmation: isShowSectionConfirmation(state), // TODO
  };
};

const mapDispatchToProps = {
  submitSectionSetup: createRegSection,
  handleNextStep: teacherRegisterNextStep,
  getCurriculumSubjectList: CurriculumActions.getCurriculumSubjectList,
  getCurriculumPlacementType: CurriculumActions.getCurriculumPlacementType,
  getCurriculumExitType: CurriculumActions.getCurriculumExitType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionCodeForm);
