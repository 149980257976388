import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

export interface Option {
  label: string;
  value: string;
  count?: number;
}

interface DropdownProps {
  /** The label for the dropdown */
  label: string;
  /** Available options */
  options: Option[];
  /** Selected values */
  value: string[];
  /** Callback when selection changes */
  onChange: (selected: string[]) => void;
  /** Whether multiple selection is allowed */
  multiple?: boolean;
  /** Whether to show Select All option */
  allowSelectAll?: boolean;
  /** Whether the dropdown is disabled */
  disabled?: boolean;
  /** The size of the dropdown */
  size?: 'small' | 'medium';
  /** Custom class names */
  className?: string;
  /** Whether to show the search box */
  showSearch?: boolean;
  /** Width of the dropdown button */
  width?: string | number;
  /** Function to format the option label */
  optionLabel?: (option: Option) => string;
  /** Function to format the selected label */
  selectedLabel?: (selected: Option) => string;
  /** Whether to show the label on top like a fieldset */
  topLabel?: boolean;
}

const DropdownParent = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: string | number }>(({ theme, width }) => ({
  width: width || '100%',
  position: 'relative',
  display: 'inline-block',
  '&:hover > span': {
    background: `linear-gradient(to bottom, white 50%, ${theme.palette.grey[200]} 50%)`,
  },
  '&:hover > button': {
    background: theme.palette.grey[200],
    borderColor: theme.palette.divider,
  },
}));

const DropdownButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{ width?: string | number }>(({ theme, width }) => ({
  minWidth: 'unset',
  maxWidth: 'none',
  width: width || 'auto',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1, 2),
  textTransform: 'none',
  color: 'black',
  fontWeight: 'normal',
  border: `1px solid ${theme.palette.divider}`,
}));

const DropdownLabel = styled('span')({
  position: 'absolute',
  top: '-0.5rem',
  left: '0.75rem',
  backgroundColor: 'white',
  padding: '0 4px',
  fontSize: '0.625rem',
  zIndex: 1,
});

const ButtonWrapper = styled('div')({
  display: 'block',
  width: '100%',
});

const SelectedCount = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  borderRadius: '12px',
  padding: '2px 8px',
  fontSize: '0.75rem',
  marginLeft: '8px',
}));

const SearchBox = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1),
  width: 'calc(100% - 16px)',
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
  },
}));

const OptionChip = styled(Chip)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0.5, 0),
  '& .MuiChip-label': {
    flexGrow: 1,
    textAlign: 'left',
    fontSize: '0.875rem',
  },
}));

const StyledPopover = styled(Popover)(({ theme }) => ({
  '& .MuiPopover-paper': {
    minWidth: 200,
    width: 'auto !important',
    maxWidth: '250px',
  },
}));

export const CustomDropdown: React.FC<DropdownProps> = ({
  label,
  options,
  value,
  onChange,
  multiple = false,
  allowSelectAll = true,
  disabled = false,
  size = 'medium',
  className,
  showSearch = false,
  width,
  optionLabel,
  selectedLabel,
  topLabel,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Filter options based on search
  const filteredOptions = options.filter((option) =>
    showSearch ? option.label.toLowerCase().includes(searchQuery.toLowerCase()) : true,
  );

  const getSelectedLabel = () => {
    if (multiple) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <span>{label}</span>
          {value.length > 0 && <SelectedCount>{value.length}</SelectedCount>}
        </Box>
      );
    }
    if (value.length === 0) {
      return label;
    }
    const selectedOption = options.find(
      (opt) => opt.value.toString() === value[0].toString(),
    );
    if (selectedLabel && selectedOption) {
      return selectedLabel(selectedOption);
    }
    return `${label}: ${selectedOption?.label || ''}`;
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery('');
  };

  const isOptionSelected = (optionValue: string) =>
    value.map((v) => v.toString()).includes(optionValue);

  const handleOptionClick = (optionValue: string) => {
    const isExisting = isOptionSelected(optionValue);
    let newSelected: string[];
    if (multiple) {
      newSelected = isExisting
        ? value.filter((v) => v !== optionValue)
        : [...value, optionValue];
    } else {
      newSelected = isExisting ? [] : [optionValue];
      handleClose();
    }
    onChange(newSelected);
  };

  const handleSelectAll = () => {
    const allValues = filteredOptions.map((opt) => opt.value);
    onChange(value.length === filteredOptions.length ? [] : allValues);
  };

  const getOptionLabel = (option) => {
    if (!optionLabel) {
      return option.label;
    }

    return optionLabel(option);
  };

  const open = Boolean(anchorEl);

  return (
    <ButtonWrapper className={className}>
      <DropdownParent width={width}>
        <DropdownButton
          onClick={handleClick}
          variant="outlined"
          disabled={disabled}
          size={size}
          width={width}
        >
          {getSelectedLabel()}
        </DropdownButton>
        {topLabel && value.length > 0 && <DropdownLabel>{label}</DropdownLabel>}
      </DropdownParent>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: { p: 2 },
          },
        }}
      >
        {showSearch && (
          <SearchBox
            placeholder="Search..."
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
        {multiple && allowSelectAll && (
          <OptionChip
            label="Select All"
            onClick={handleSelectAll}
            variant={value.length === filteredOptions.length ? 'filled' : 'outlined'}
          />
        )}
        {filteredOptions.map((option) => (
          <OptionChip
            key={option.value}
            label={getOptionLabel(option)}
            onClick={() => handleOptionClick(option.value)}
            variant={isOptionSelected(option.value) ? 'filled' : 'outlined'}
            avatar={
              option.count !== undefined ? (
                <Box
                  sx={{
                    width: 24,
                    height: 24,
                    borderRadius: '50%',
                    bgcolor: 'grey.300',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                  }}
                >
                  {option.value}
                </Box>
              ) : undefined
            }
          />
        ))}
      </StyledPopover>
    </ButtonWrapper>
  );
};
