import React, { useState } from 'react';
import Box from '@components/Atoms/Box';
import Select from '@components/Atoms/Select';

import { useHistory, useParams } from 'react-router-dom';
import { useCurrentSection } from '@reducers/sections/hooks';
import { useGetPacingGuides } from '../hooks';
import { useNavigateToLesson } from '../../LessonContainer/hooks';

const PACING_GUIDE_EMPTY_ITEM_LABEL = 'All Content';

const PacingGuideSelect = () => {
  const { subjectId } = useParams();
  const navigateToLesson = useNavigateToLesson();
  const history = useHistory();
  const { data: pacingGuides } = useGetPacingGuides();
  const [selectedPacingGuideId, setSelectedPacingGuideId] =
    useState<string | undefined>(undefined);
  const [showMoreItems, setShowMoreItems] = useState(false);

  const currentSection = useCurrentSection();

  const defaultPacingGuideId = currentSection?.pacing_guides?.find(
    (pacingGuide) => pacingGuide.subject === Number(subjectId),
  )?.id;

  const list = pacingGuides
    ?.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: true }))
    ?.map((pacingGuide) => ({
      label: pacingGuide.title,
      value: pacingGuide.id,
    }))
    .filter((pacingGuide) => {
      // If no default guide is set, show all guides
      if (defaultPacingGuideId === undefined) {
        return true;
      }

      // If showMoreItems is true, show all guides
      if (showMoreItems) {
        return true;
      }

      // Otherwise, only show the default guide
      return pacingGuide.value === defaultPacingGuideId;
    });

  const handleChange = (value: string) => {
    setSelectedPacingGuideId(value ?? '');
    navigateToLesson({
      pacingGuideId: value,
    });
  };

  React.useEffect(() => {
    const path = history.location.pathname;
    const isSubjectPage = path.includes('/subject/');

    if (!isSubjectPage) {
      setSelectedPacingGuideId(undefined);
      return;
    }

    if (defaultPacingGuideId && selectedPacingGuideId === undefined) {
      handleChange(defaultPacingGuideId);
    }
  }, [history.location.pathname]);

  // Reset showMoreItems when subjectId changes
  React.useEffect(() => {
    setShowMoreItems(false);
  }, [subjectId]);

  return (
    <Box sx={{ width: '100%', mt: '1rem', px: '0.625rem' }}>
      <Select
        key={subjectId}
        showEmptyItem
        emptyItemLabel={PACING_GUIDE_EMPTY_ITEM_LABEL}
        displayEmpty
        id="pacing-guide"
        data-testid="pacing-guide-select"
        sx={{ width: '100%' }}
        list={list ?? []}
        value={selectedPacingGuideId}
        onMoreItemsButtonClick={() => {
          setShowMoreItems(true);
        }}
        showMoreItemsButton={!showMoreItems && defaultPacingGuideId !== undefined}
        label="Content by Pacing Guide"
        size="small"
        onChangeHandler={handleChange}
      />
    </Box>
  );
};

export default PacingGuideSelect;
