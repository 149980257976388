import { liteClient as algoliasearch } from 'algoliasearch/lite';

const config = {};

if (process.env.NODE_ENV === 'production') {
  config['hosts'] = [
    {
      url: 'api.chalktalk.com/algolia',
      accept: 'read',
      protocol: 'https',
    },
  ];
}

const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID ?? '',
  process.env.REACT_APP_ALGOLIA_API_KEY ?? '',
  config,
);

export default client;
