import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import _get from 'lodash/get';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import {
  RegisterFormWrapper,
  RegisterFormTitle,
  RegisterFormInput,
  RegisterAction,
  ButtonStyled,
  RegisterMessageContent,
  RegisterSuccessNotification,
  RegisterErrorMessage,
} from '../StudentRegister/StudentRegister.style';

let isFree = false;
let isSubscription = false;

const validationSchema = Yup.object().shape({
  subscriptionCode: isFree && Yup.string().trim().required('Required'),
  free: isSubscription && Yup.string().trim().required('Required'),
});

const initialValues = {
  subscriptionCode: '',
  free: false,
};

export default class SubscriptionForm extends Component {
  static propTypes = {
    submitSubscriptionCode: PropTypes.func.isRequired,
    requestInviteCourse: PropTypes.func.isRequired,
    subscriptionData: PropTypes.object,
    logout: PropTypes.func.isRequired,
    errorMessage: PropTypes.any,
    isRequestedInvite: PropTypes.bool,
  };

  handleSubmit = (values) => {
    const { submitSubscriptionCode } = this.props;
    submitSubscriptionCode(values.free, values.subscriptionCode);
  };

  handleRequestInviteCourse = (e) => {
    e.preventDefault();
    const { requestInviteCourse, subscriptionData } = this.props;
    const { code } = subscriptionData || {};
    requestInviteCourse(code);
  };

  renderRequestSuccessMessage = () => {
    const { logout } = this.props;
    return (
      <React.Fragment>
        <RegisterMessageContent>
          <p>
            Request was sent successfully. You will receive an email when
            request has been approved
          </p>
        </RegisterMessageContent>
        <RegisterAction>
          <ButtonStyled onClick={logout}>
            Logout and wait for approval
          </ButtonStyled>
        </RegisterAction>
      </React.Fragment>
    );
  };

  renderCourseCreatedMessage = () => (
    <React.Fragment>
      <RegisterMessageContent>
        <p>
          Course has been created with this subscription code. You can request
          an invite to the course.
        </p>
      </RegisterMessageContent>
      <RegisterAction>
        <ButtonStyled onClick={this.handleRequestInviteCourse}>
          Request Invite
        </ButtonStyled>
      </RegisterAction>
    </React.Fragment>
  );

  renderIntermediaScreen = () => {
    const { isRequestedInvite } = this.props;

    return (
      <RegisterFormInput>
        {isRequestedInvite
          ? this.renderRequestSuccessMessage()
          : this.renderCourseCreatedMessage()}
      </RegisterFormInput>
    );
  };

  renderErrorMessage = (error, index) => <p key={index}>{error}</p>;

  renderFormContent = ({ handleSubmit, values }) => {
    const scriptionValue = _get(values, 'subscriptionCode', '');
    const freeValue = _get(values, 'free', '');
    const { errorMessage } = this.props;
    if (scriptionValue) {
      isSubscription = true;
    } else {
      isSubscription = false;
    }

    if (freeValue) {
      isFree = true;
    } else {
      isFree = false;
    }

    return (
      <RegisterFormInput onSubmit={handleSubmit}>
        <FormInput
          type="text"
          name="subscriptionCode"
          label="Subscription Code"
          icon="icon-help-circle"
          dataTip="The subscription code is a code that was provided to your school. If you are having issues with your code, please contact us here"
          disable={isFree}
        />
        <FormInput
          type="checkbox"
          name="free"
          label="Free Experience"
          disable={isSubscription}
        />
        {errorMessage && (
          <RegisterErrorMessage>
            {errorMessage.map(this.renderErrorMessage)}
          </RegisterErrorMessage>
        )}
        <RegisterAction>
          <ButtonStyled type="submit">Submit</ButtonStyled>
        </RegisterAction>
      </RegisterFormInput>
    );
  };

  renderRegistedSuccessMessage = () => (
    <RegisterSuccessNotification>
      Your account was created successfully. An email was sent to you to
      confirm.
    </RegisterSuccessNotification>
  );

  render() {
    const { subscriptionData } = this.props;
    const { consumed } = subscriptionData || {};
    return (
      <RegisterFormWrapper>
        {this.renderRegistedSuccessMessage()}
        <RegisterFormTitle>
          <h1>Select subscription</h1>
          <h3>
            If you a have a subscription code, you can use it here or you can
            select the Free Experience.
          </h3>
        </RegisterFormTitle>
        {consumed ? (
          this.renderIntermediaScreen()
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
            render={this.renderFormContent}
          />
        )}
      </RegisterFormWrapper>
    );
  }
}
