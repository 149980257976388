import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import Iframe from 'react-iframe';
import {
  TrainingVideoWrap,
  TraningVideoName,
  ToggleFullScreenButton,
  VideoContent,
} from './TeacherTrainingVideo.style';

export default class TeacherTrainingVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
    };
  }

  renderToggleFullScreenButton = (className) => {
    const { isFullScreen } = this.state;
    return (
      <div className={className}>
        <ToggleFullScreenButton
          onClick={(event) => {
            event.stopPropagation();
            this.setState(({ isFullScreen }) => ({
              isFullScreen: !isFullScreen,
            }));
          }}
        >
          <span>{isFullScreen ? 'Minimize' : 'Full screen'}</span>
        </ToggleFullScreenButton>
      </div>
    );
  };

  render() {
    const { training } = this.props;

    return (
      <MediaQuery maxWidth={768}>
        {matches => (
          <React.Fragment>
            <TrainingVideoWrap>
              {training && <TraningVideoName>{training.name}</TraningVideoName>}
              {((training && this.state.isFullScreen) || (!matches && training))
                && (
                  <VideoContent isFullScreen={this.state.isFullScreen}>
                    <Iframe
                      url={training.url}
                      width="100%"
                      height="100%"
                      id="TeacherTrainingVideo"
                      display="initial"
                      position="relative"
                      allowFullScreen
                    />
                  </VideoContent>
                )}
              {this.state.isFullScreen
                && matches
                && this.renderToggleFullScreenButton('full-screen-button-wrapper')}
              {!this.state.isFullScreen
                && training?.url.length > 0
                && matches
                && (
                  <div>
                    Slides are supported only at fullscreen for mobile
                    {this.renderToggleFullScreenButton(
                      'full-screen-outer-button-wrapper',
                    )}
                  </div>
                )}
            </TrainingVideoWrap>
          </React.Fragment>
        )}
      </MediaQuery>
    );
  }
}

TeacherTrainingVideo.propTypes = {
  training: PropTypes.shape(),
};
