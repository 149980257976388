import styled from 'styled-components';

export const LessonBarWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5em 1.5em;
  height: 2.5em;
  background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  @media (max-width: 768px) {
    font-size: ${props => props.theme.fontSize.MediumFontSize}
  }
`;

export const LessonBarContent = styled.div`
  font-family: 'MontserratMedium';
  color: ${props => props.theme.colorStyled.ColorWhite};
`;

export const LessonBarAction = styled.div``;

export const LessonBarButton = styled.button`
  border: none;
  background: transparent;
  font-family: 'MontserratMedium';
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  color: ${props => props.theme.colorStyled.ColorWhite};
  margin-left: 1em;
  &:hover {
    opacity: .7;
  } 
`;
