import styled, { css } from 'styled-components';

export const BreadcumbsFilterWrap = styled.section`
  width: 100%;
  display: flex;
  padding: 1em 1.5em;
  align-items: center;
  @media (max-width: 768px) {
    padding: 1em;
  }
`;

export const LabelStudentName = styled.label`
  text-transform: capitalize;
`;

export const BreadcumbsWrap = styled.div``;

export const BreadcumbsGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const BreadcumbsItems = styled.div`
  display: flex;
  align-items: center;
  span {
    font-size: ${props => props.theme.fontSize.DefaultFontSize};
    max-width: 10em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &.chalktalk-icon-next{
      font-weight: normal;
      font-size: ${props => props.theme.fontSize.MediumFontSize};
      margin: 0 0.5em;
    }
  }
  .chalktalk-menu {
    font-size: 1.5em;
    margin-right: 15px;
  }
  i {
    cursor: pointer;
  }
  ${props => props.current && css`
    span {
      font-family: 'MontserratSemiBold';
      font-size: ${props.theme.fontSize.DefaultFontSize};
    }
  `}
`;
export const FilterGroup = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const FilterDropDownGroup = styled.div`
  position: relative;
  margin-left: 1em;
  @media (max-width: 768px) {
    margin-left: .5em;
  }
`;

export const FilterLabel = styled.div`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0.5em 1em;
  background: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: 0.25em;
  box-shadow: 0px 0px 3px -2px ${props => props.theme.colorStyled.ColorBlack};
  text-align: center;
  > div {
    margin-left: .2em;
  }
  @media (max-width: 1170px) {
    width: 12em;
  }
  @media (max-width: 768px) {
    justify-content: center;
    min-width: 100%;
    span, div {
      flex: unset
    }
  }
  @media (max-width: 480px) {
    width: 5em;
    justify-content: center;
    span {
        display: none;
      }
    }
  }
`;

export const FilterIcon = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 5px;
  display: none; 
  @media (max-width: 480px) {
    display: block;
  }
`;

export const FilterOptionGroup = styled.div`
  position: absolute;
  width: 100%;
  background: ${props => props.theme.colorStyled.ColorWhite};
  margin-top: .5em;
  border-radius: 0.25em;
  box-shadow: 0px 0px 3px -2px ${props => props.theme.colorStyled.ColorBlack};
  overflow: hidden;
  z-index: 9;
  max-height: 20em;
  overflow: auto;
  right: 0;
  min-width: 16em;
  .react-tabs__tab-list {
    display: flex;
  }
  .react-tabs__tab {
    border-bottom: 1px solid transparent;
    padding: .5em 1em;
    text-align: center;
    flex: 0 0 50%;
    opacity: .7;
    cursor: pointer;
    &:hover {
      border-color: ${props => props.theme.colorStyled.ColorBgDefault};
      color: ${props => props.theme.colorStyled.ColorBgDefault};
      opacity: 1; 
    }
  }
  .react-tabs__tab.react-tabs__tab--selected {
    border-color: ${props => props.theme.colorStyled.ColorBgDefault};
    color: ${props => props.theme.colorStyled.ColorBgDefault};
    opacity: 1;
  }
  @media (max-width: 480px) {
    right: 0;
    width: 16em;
  }
`;

export const FilterOptionItems = styled.div`
  display: flex;
  align-content: space-evenly;
  justify-content: space-between;
  a {
    color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    font-size: 0.8715em;
    padding: 0em 0.5em;
  }
`;

export const FilterOptionItemCustomize = styled.div`
  display: inline-flex;
  width: 100%;
  color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
`;

export const FilterChooseGroup = styled.div`
  display: flex;
`;

export const ItemsFilterChoose = styled.button`
  cursor: pointer;
  align-items: center;
  display: flex;
  padding: 0.5em .5em 0.5em 1em;
  background: ${props => props.theme.colorStyled.ColorGrey};
  border-radius: 0.25em;
  border: none;
  box-shadow: 0px 0px 3px -2px ${props => props.theme.colorStyled.ColorBlack};
  width: 12.5em;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  margin-right: .75em;
  color: ${props => props.theme.colorStyled.ColorWhite};
  .chalktalk-icon-remove {
    flex: 1;
    text-align: right;
    &:hover{
      color: ${props => props.theme.colorStyled.ColorRedDel};;
    }
  }
`;

export const FilterOptionStudentWrap = styled.div``;

export const FilterOptionGradeLevelWrap = styled.div``;
