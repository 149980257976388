import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import _isEqual from 'lodash/isEqual';
import md5 from 'md5';
import CourseSelect from '../../containers/CourseSelect';
import SectionSelect from '../../containers/SectionSelect';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import TestSubNavItem from '../SubNavBarItem/TestSubNavItem';
import TestSubNavItemV2 from '../SubNavBarItem/TestSubNavItemV2';
import LeftSideAddOrEditTest from './LeftSideAddOrEditTest';
import LeftSideAddOrEditTestV2 from './LeftSideAddOrEditTestV2';
import * as SectionAPI from '../../apis/exam';
import {
  LeftSiderBarContentGroup,
  ListSiderBar,
  TitleSideBar,
  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
  UnitDataIcon,
  UnitDataText,
} from './LeftSiderBar.style';
import { NavBarList } from '../SubNavBarItem/SubNavBar.style';
import ListLoader from '../LoadingPlaceholder/ListLoader';

const scrollStyle = {
  height: 'calc(100vh - 10em)',
  width: '100%',
  overflow: 'auto',
};

export default class LeftSideBarTest extends Component {
  static propTypes = {
    match: PropTypes.any,
    pathName: PropTypes.string,
    shouldDisplaySectionSelect: PropTypes.bool,
    sectionId: PropTypes.any,
    sectionExamGet: PropTypes.func,
    sectionExamAdd: PropTypes.func,
    sectionExamDelete: PropTypes.func,
    sectionExamUpdate: PropTypes.func,
    isGettingSectionExam: PropTypes.bool,
    isSideBarClose: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    toggleLeftSidebar: PropTypes.func,
    examSessons: PropTypes.array,
    isTeacher: PropTypes.bool,
    exams: PropTypes.array,
    hasExamV2Access: PropTypes.bool
  };

  state = {
    popupIsOpen: false,
    actionName: 'Add',
    saving: false,
  };

  componentDidMount() {
    const { sectionId, sectionExamGet } = this.props;

    if (sectionId) {
      sectionExamGet(sectionId);
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionId: prevSectionId } = prevProps;
    const { sectionId, sectionExamGet } = this.props;

    if (sectionId && !_isEqual(prevSectionId, sectionId)) {
      sectionExamGet(sectionId);
    }
  }

  onSave = () => {
    this.setState({
      saving: true,
    });
  };

  toggleSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  };

  renderExamItem = (exam, previousExam) => {
    const { hasExamV2Access } = this.props;
    if (exam.id == undefined) return;
    return hasExamV2Access ? <TestSubNavItemV2 key={exam.id} examItem={exam}  previousExam={previousExam}/> : <TestSubNavItem key={exam.id} examItem={exam} />;
  }

  openPopUp = (name) => {
    this.setState({
      popupIsOpen: true,
      actionName: name,
    });
  };

  renderButton = () => {
    const { isTeacher } = this.props;

    if (isTeacher) {
      return (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '15px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                margin: '20px 0',
                width: '180px',
                alignItems: 'baseline',
                cursor: 'pointer',
              }}
              onClick={() => this.openPopUp('Edit')}
            >
              <UnitDataIcon>
                <span
                  style={{
                    background: '#ffe3c0',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    marginRight: '15px',
                    padding: '8px',
                    fontSize: '16px',
                    fontWeight: '900',
                  }}
                >
                  <i className="chalktalk-edit" />
                </span>
              </UnitDataIcon>
              <UnitDataText>Edit Practice Test</UnitDataText>
            </div>

            <br></br>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '180px',
                alignItems: 'baseline',
                cursor: 'pointer',
              }}
              onClick={() => this.openPopUp('Add')}
            >
              <UnitDataIcon>
                <span
                  style={{
                    background: '#ffe3c0',
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    marginRight: '10px',
                    padding: '8px 12px',
                    fontSize: '16px',
                    fontWeight: '900',
                  }}
                >
                  <span>+</span>
                </span>
              </UnitDataIcon>
              <UnitDataText>Add Practice Test</UnitDataText>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  popupIsClose = () => {
    this.setState({
      popupIsOpen: false,
    });
  };

  compareExams(exam1, exam2) {
    if (exam1.session_type < exam2.session_type) {
      return -1;
    }
    if (exam1.session_type >= exam2.session_type) {
      if (exam1.start_date >= exam2.start_date) {
        return 1;
      }

      return -1;
    }
    return 0;
  }
  compareExamsV2(exam1, exam2) {
    if (exam1.practice_test_order == null || exam2.practice_test_order == null) {
      return this.compareExams(exam1, exam2)
    }
    if (exam1.practice_test_order < exam2.practice_test_order) {
      return -1;
    }
    if (exam1.practice_test_order > exam2.practice_test_order) {
      return 1;
    }
    return 0;
  }

  renderSideBarContent() {
    const {
      isGettingSectionExam,
      sectionDetail,
      curriculumName,
      curriculumId,
      sectionId,
      sectionExamAdd,
      sectionExamGet,
      sectionExamDelete,
      sectionExamUpdate,
      examSessons,
      exams,
      hasExamV2Access,
    } = this.props;
    let currentExams = [];
    if (examSessons.length === 0) {
      currentExams = exams;
    } else {
      currentExams = examSessons;
    }
    currentExams = currentExams.sort((exam1, exam2) => hasExamV2Access ? this.compareExamsV2(exam1, exam2) : this.compareExams(exam1, exam2));

    return (
      <React.Fragment>
        {this.state.popupIsOpen ? (
          hasExamV2Access ? <LeftSideAddOrEditTestV2
            curriculumId={curriculumId}
            curriculumName={curriculumName}
            sectionDetail={sectionDetail}
            sectionId={sectionId}
            isOpen={this.state.popupIsOpen}
            onClose={this.popupIsClose}
            actionName={this.state.actionName}
            sectionExamGet={sectionExamGet}
            sectionExamAdd={sectionExamAdd}
            sectionExamDelete={sectionExamDelete}
            sectionExamUpdate={sectionExamUpdate}
            examSessons={examSessons}
            onSave={this.onSave}
          /> :
            <LeftSideAddOrEditTest
              curriculumId={curriculumId}
              curriculumName={curriculumName}
              sectionDetail={sectionDetail}
              sectionId={sectionId}
              isOpen={this.state.popupIsOpen}
              onClose={this.popupIsClose}
              actionName={this.state.actionName}
              sectionExamGet={sectionExamGet}
              sectionExamAdd={sectionExamAdd}
              sectionExamDelete={sectionExamDelete}
              sectionExamUpdate={sectionExamUpdate}
              examSessons={examSessons}
              onSave={this.onSave}
            />
        ) : (
          ''
        )}

        <LeftSiderBarContentGroup>
          <TitleSideBar> {hasExamV2Access ? 'Practice Tests' : 'Test Sessions'} </TitleSideBar>
          <ShadowScrollbar autoHide style={scrollStyle}>
            <ListSiderBar>
              <NavBarList hasExtraPadding>
                {currentExams.map((exam, index) => {
                  const previousExam = index > 0 ? currentExams[index - 1] : {};
                  return this.renderExamItem(exam, previousExam);
                })}
                {this.renderButton()}
              </NavBarList>
            </ListSiderBar>
          </ShadowScrollbar>
        </LeftSiderBarContentGroup>
      </React.Fragment>
    );
  }

  render() {
    const { pathName, match, shouldDisplaySectionSelect } = this.props;
    const { isSideBarClose } = this.props;
    const courseHash = md5(`${match.params.courseId}@chalktalk`);

    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <CourseSelect pathName={pathName} />
            {shouldDisplaySectionSelect && (
              <SectionSelect pathName={pathName} />
            )}
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        {this.renderSideBarContent()}
        <ActionShowHide
          isRotate={isSideBarClose}
          className="toggle-icon"
          onClick={this.toggleSidebar}
        >
          <MediaQuery maxWidth={960}>
            {(matches) => matches ? (
              <span
                className={
                  isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'
                }
              />
            ) : (
              <span
                className={
                  isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'
                }
              />
            )}
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    );
  }
}
