import styled, { css } from "styled-components";

export const GroupSheetContainer = styled.div`
  width: 100%;
  height: 100%;
  transition: all 300ms ease;
  position: relative;
  height: calc(100vh - 4em);
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: calc(100% - 4em);
    justify-content: space-between;
  }
`;
