import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _set from 'lodash/set';
import moment from 'moment';
import UnitPracticeTeacherDetail from '../../components/UnitPracticeTeacherDetail';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as SectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as UserSelectord } from '../../reducers/user';
import { selectors as PracticeSelectors } from '../../reducers/practice';
import {
  actions as UserActivityActions,
  selectors as UserActivitySelectors,
} from '../../reducers/userActivities';
import {
  actions as LessonAssignmentActions,
  selectors as LessonAssignmentSelectors,
} from '../../reducers/lessonAssignment';

const initialValueForReport = {
  score: 0,
  maxScore: 0,
  count: 0,
};

const intialValueForUser = {
  score: 0,
  maxScore: 0,
  timeOnTask: 0,
  lastActivity: null,
};

const mapStateToProps = (state, { match }) => {
  const unitId = _get(match, 'params.unitId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const practiceSummary = SectionSelectors.getPracticeSummaryOfUnit(state, unitId);
  const students = SectionStudentSelectors.getStudentListWithFilter(state, sectionId);
  const assignedStatus = LessonAssignmentSelectors.getLAByUnitId(state, unitId);
  const practiceReport = {};

  const practiceSummaryByUserId = {};
  const assignedByUserId = {};
  const practiceSummaryCountByUserId = {};

  practiceSummary.forEach((summaryItem) => {
    const {
      asset_type: assetType,
      user: userId,
      score,
      max_score: maxScore,
      last_activity: lastActivity,
      time_on_task: timeOnTask,
    } = summaryItem;

    if (typeof assetType === 'undefined') {
      return;
    }

    const currentValueOfReport = _get(practiceReport, assetType, initialValueForReport);
    practiceReport[assetType] = {
      score: currentValueOfReport.score + score,
      maxScore: currentValueOfReport.maxScore + maxScore,
      count: currentValueOfReport.count + 1,
    };

    practiceSummaryCountByUserId[userId] = _get(practiceSummaryCountByUserId, userId, 0) + 1;

    const currentValueByUserId = _get(practiceSummaryByUserId, [userId, assetType], intialValueForUser);
    const newLastActivity = !currentValueByUserId.lastActivity
      ? lastActivity
      : moment(lastActivity).isAfter(moment(currentValueByUserId.lastActivity))
        ? lastActivity : currentValueByUserId.lastActivity;
    const newValueByUserId = {
      score: currentValueByUserId.score + score,
      maxScore: currentValueByUserId.maxScore + maxScore,
      timeOnTask: currentValueByUserId.timeOnTask + timeOnTask,
      lastActivity,
    };

    _set(practiceSummaryByUserId, [userId, assetType], newValueByUserId);
    _set(practiceSummaryByUserId, [userId, 'lastActivity'], newLastActivity);
  });

  assignedStatus.forEach((item) => {
    const { assigned_to: userId, asset_type: assetType } = item;
    const currentIsAssign = _get(assignedByUserId, userId, false);
    let newIsAssiged = currentIsAssign;

    if (!currentIsAssign) {
      const lastActivity = _get(practiceSummaryByUserId, [userId, assetType, 'lastActivity'], null);
      const newAssigned = _get(item, 'assigned', null);
      const lastAssigned = _get(item, 'last_assigned', null);

      newIsAssiged = newAssigned || (lastAssigned && !lastActivity) || moment(lastAssigned).isAfter(moment(lastActivity));
      _set(assignedByUserId, userId, newIsAssiged);
    }
  });

  return {
    practiceReport,
    assignedByUserId,
    practiceSummary,
    practiceSummaryByUserId,
    students,
    lessons: SectionUnitSelectors.getAllLessonOfUnit(state, unitId),
    isGettingSectionUnit: SectionUnitSelectors.isGettingSectionUnit(state),
    currentUserId: UserSelectord.getUserId(state),
    userRole: UserSelectord.getUserRole(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    isInitialized: PracticeSelectors.isInitialized(state),
    unitDetail: SectionUnitSelectors.getUnitById(state, unitId),
    isGettingUserPracticeSessionActivities: UserActivitySelectors.isGettingUserPracticeSessionActivities(state),
    userPracticeActivities: UserActivitySelectors.getUserUnitPracticeSessionActivities(
      state,
      sectionId,
      unitId,
    ),
  };
};

const mapDispatchToProps = ({
  assignLesson: LessonAssignmentActions.assignLesson,
  unAssignLesson: LessonAssignmentActions.unAssignLesson,
  getAllPracticeSessionActivities: UserActivityActions.getAllPracticeSessionActivities,
  lessonAssignmentDetail: LessonAssignmentActions.lessonAssignmentDetail,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnitPracticeTeacherDetail));
