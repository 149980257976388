import { connect } from 'react-redux';
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { withRouter } from 'react-router-dom';
import InterventionTab from '../../components/InterventionTab';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as UserSelectord } from '../../reducers/user';
import {
  selectors as LessonAssignmentSelectors,
  actions as LessonAssignmentActions,
} from '../../reducers/lessonAssignment';
import {
  selectors as sectionAttendanceSelectors,
} from '../../reducers/sectionAttendance';
import { selectors as sectionSession } from '../../reducers/sectionSession';
import {
  selectors as ActivitySelectors,
} from '../../reducers/activity';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as SectionSkillsSelectors } from '../../reducers/sectionSkills';

import {
  selectors as CurriculumLessonSelectors,
  actions as CurriculumLessonActions,
} from '../../reducers/curriculumLessons';

import {
  selectors as GroupSelectors,
} from '../../reducers/groupActivity/groupActivityReducer';


const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const lessonId = _get(match, 'params.lessonId', '');
  const unitId = _get(match, 'params.unitId', '');

  const sessionsList = sectionSession.getSessionByUnitAndLession(state, unitId, lessonId, sectionId);
  const attendanceList = sessionsList.map((session) => sectionAttendanceSelectors.getRecordAttendance(state, session.id));
  const assignDetails = LessonAssignmentSelectors.getLAByLessonId(state, lessonId);
  const assignDetailByUserId = _keyBy(assignDetails, 'assigned_to');



  const lessonAttendanceListArr = CurriculumLessonSelectors.getLessonAttendanceById(state)[lessonId] || [];
  const groupAttendanceListArr = CurriculumLessonSelectors.getGroupAttendanceById(state)[lessonId] || [];

  const lessonAttendanceList = filterAttendance(lessonAttendanceListArr, 'lesson');
  const groupAttendanceList = filterAttendance(groupAttendanceListArr, 'group');

  function filterAttendance(attendanceListArr, activityType) {
    let attendancerecords = [];
    const attendanceArr = attendanceListArr.filter(({ id }) => id !== null);

    if (attendanceArr.length) {
      attendancerecords = _keyBy(attendanceArr.map((attendance) => ({
        student: attendance.student,
        present: attendance.present,
      })), 'student');
    }

    const attendanceList = [{
      lesson: parseInt(lessonId, 10),
      section: parseInt(sectionId, 10),
      activity: activityType,
      records: attendancerecords,
    }];

    return _keyBy(attendanceList, 'lesson');
  }

  return {
    assignDetailByUserId,
    lessonId,
    sectionId,
    studentList: sectionStudentSelectors.getStudentListWithFilter(state, sectionId),
    isAssigning: LessonAssignmentSelectors.isAssigning(state),
    assignErrorMsg: LessonAssignmentSelectors.assignErrorMsg(state),
    currentUserId: UserSelectord.getUserId(state),
    lessonActivityList: ActivitySelectors.lessonActivityList(state),
    attendanceList, // TODO ATTENDNACE
    sectionPracticeList: ActivitySelectors.sectionPracticeActivityList(state),
    examsScoreSummary: SectionSelectors.examsScoreSummaryByUnitAndLesson(state, unitId, lessonId),
    skillData: SectionSkillsSelectors.getSkillsDataOfLesson(state, lessonId),
    lessonAttendanceList,
    groupAttendanceList,
    groupActivitys: GroupSelectors.selectGroupActivitys(state)
  };
};

const mapDispatchToProps = {
  assignLesson: LessonAssignmentActions.assignLesson,
  unAssignLesson: LessonAssignmentActions.unAssignLesson,
  lessonAssignmentDetail: LessonAssignmentActions.lessonAssignmentDetail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterventionTab));
