export const ColumnSizeTeacher = [
  { smallSize: true },
  {
    size: '120', smallSize: true, textCenter: true, textLowercase: true,
  },
  {
    size: '60', smallSize: true, textRight: true,
  },
];

export const ColumnSizeTeacherInvite = [
  { smallSize: true },
  {
    size: '125', smallSize: true, textCenter: true,
  },
  {
    size: '150', smallSize: true, textCenter: true,
  },
  {
    size: '90', smallSize: true, textRight: true,
  },
];

export const ColumnSizeStudent = [
  { size: '25', smallSize: true, textCapitalize: true },
  { smallSize: true },
  {
    size: '120', smallSize: true, textCenter: true, textLowercase: true,
  },
  {
    size: '60', smallSize: true, textRight: true,
  },
];

export const ColumnSizeStudentSelected = [
  {
    size: '110', smallSize: true, textCapitalize: true, textCenter: true,
  },
  { smallSize: true },
  { smallSize: true },
  {
    size: '90', smallSize: true, textRight: true,
  },
];
