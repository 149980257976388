import { useState, useMemo } from 'react';

const useThrowError = () => {
  const [error, throwError] = useState<any>(null);
  useMemo(() => {
    if (error) {
      console.error(error);
      throw new Error(error as any);
    }
  }, [error]);
  return throwError;
};

export default useThrowError;
