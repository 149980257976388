import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import print from 'print-js';
import MediaQuery from 'react-responsive';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { withScope, captureException } from '@sentry/react';
import {
  PDFView,
  PDFPagination,
  PDFGroupAction,
  PDFZoomAction,
  PDFIcon,
} from './PracticePdfViewer.style';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();
const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  isEvalSupported: false,
};

const scrollStyle = {
  height: 'calc(100vh - 10em)',
  width: '100%',
  background: '#aeaeae',
};

const scrollStyleMobile = {
  height: '50vh',
  width: '100%',
  background: '#aeaeae',
};

class PracticePdfViewer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalPage: null,
      pageNumber: 1,
      zoomNumber: 70,
      renderAnnotationLayer: false,
      error: false,
    };
    this.pdfFileRef = React.createRef();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ totalPage: numPages, error: false });
  };

  goToPrevPage = () => {
    this.setState((state) => ({
      pageNumber: state.pageNumber === 1 ? 1 : state.pageNumber - 1,
    }));
  };

  goToNextPage = () => {
    const { totalPage } = this.state;
    this.setState((state) => ({
      pageNumber: state.pageNumber === totalPage ? totalPage : state.pageNumber + 1,
    }));
  };

  handleZoomIn = () => {
    this.setState((state) => ({
      zoomNumber: state.zoomNumber + 15,
    }));
  };

  handleZoomOut = () => {
    this.setState((state) => ({
      zoomNumber: state.zoomNumber - 15,
    }));
  };

  handleError = (error) => {
    withScope((scope) => {
      const { name, message, status } = error;
      scope.setContext('Error Information', {
        name,
        message,
        status,
      });
      scope.setTag('exception', 'PDF failed to load.');
      scope.setTag('failedPDFURL', this.props.pdfSource);
      captureException(error);
    });
    this.setState({ error: true });
  };

  render() {
    const { pageNumber, totalPage, zoomNumber, renderAnnotationLayer } = this.state;
    const { pdfSource, isAnswerSheetOpen, isViewer } = this.props;
    const isTeacher = true;

    return (
      <PDFView
        zoomNumber={zoomNumber}
        isAnswerSheetOpen={isAnswerSheetOpen}
        isViewer={isViewer}
      >
        <div className="pdf-file-wrapper">
          <MediaQuery maxWidth={768}>
            {(matches) => (
              <ShadowScrollbar autoHide style={matches ? scrollStyleMobile : scrollStyle}>
                <Document
                  file={pdfSource}
                  onLoadSuccess={this.onDocumentLoadSuccess}
                  onLoadError={this.handleError}
                  options={options}
                  ref={this.pdfFileRef}
                >
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={renderAnnotationLayer}
                    scale={2}
                  />
                </Document>
              </ShadowScrollbar>
            )}
          </MediaQuery>

          <PDFZoomAction>
            {isTeacher && (
              <PDFIcon onClick={() => print(pdfSource)} className="chalktalk-printing" />
            )}
            <PDFIcon onClick={this.handleZoomIn} className="chalktalk-plus" />
            <PDFIcon onClick={this.handleZoomOut} className="chalktalk-minus" />
          </PDFZoomAction>
          <PDFGroupAction>
            <PDFIcon className="chalktalk-icon-pre" onClick={this.goToPrevPage} />
            <PDFPagination>{`Page ${pageNumber} of ${totalPage}`}</PDFPagination>
            <PDFIcon className="chalktalk-icon-next" onClick={this.goToNextPage} />
          </PDFGroupAction>
        </div>
      </PDFView>
    );
  }
}

PracticePdfViewer.propTypes = {
  pdfSource: PropTypes.string,
  isAnswerSheetOpen: PropTypes.bool,
  isViewer: PropTypes.bool,
};

export default PracticePdfViewer;
