import { connect } from 'react-redux';
import _get from 'lodash/get';
import ScheduleCalendar from '../../components/ScheduleCalendar';
import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,
} from '../../reducers/sectionSchedule';
import {
  actions as SectionUnitActions,
} from '../../reducers/sectionUnit';
import { selectors as UserSelectors } from '../../reducers/user';
import { ROLE_TYPE } from '../../utils/enums';
import { actions as ScheduleModalActions, selectors as ScheduleModalSelectors } from '../../reducers/scheduleModal';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { actions as SectionSubject } from '../../reducers/sectionSubjects';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const { courseId } = match.params;
  const sectionId = _get(match, 'params.sectionId', null);
  const courseItem = CourseSelectors.getCourseDetailById(state, courseId);
  const { subscription = {} } = courseItem;
  const { subscription_type: subType } = subscription;
  const isLimited = subType === 3;
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);
  const sectionChangesList = state.sections.state ? state.sections.state.sectionChanges : [];
  return ({
    sectionDetail,
    courseItem,
    isLimited,
    isTeacher: UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR,
    scheduleList: SectionScheduleSelectors.getScheduleList(state),
    isCreateFormOpen: (UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR && !isLimited) ? ScheduleModalSelectors.isCreateFormOpen(state) : false,
    sectionChangesList,
  });
};

const mapDispatchToProps = {
  sectionsGetDetail: SectionActions.sectionsGetDetail,
  sectionScheduleGet: SectionScheduleActions.sectionScheduleGet,
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  fetchSectionSubjectList: SectionSubject.fetchSectionSubjectList,
  closeCreateFormModal: ScheduleModalActions.closeCreateFormModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalendar);
