/* eslint-disable import/prefer-default-export */
import styled, { keyframes } from 'styled-components';

const shake = keyframes`
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;
export const GroupSheetContainer = styled.div`
  width: 100%;
  transition: all 300ms ease;
  position: relative;
  height: 100vh;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  /* shake transition */
  & .shake {
    animation: ${shake} 0.5s;
  }
`;
