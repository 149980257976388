import React from 'react';
import Box from '../Box';

const tableCellStyle = {
  flexBasis: '3rem',
  flex: 1,
  py: 3,
  px: 1,
  minWidth: '3rem',
  minHeight: '100%',
  overflowWrap: 'anywhere',
  fontSize: '12px',
  fontFamily: 'roboto',
  // header Cells style
  '.table-header &': {
    py: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    // typography style
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
      color: '#8D8D8D',
      fontWeight: 'bold',
    },
    // IconButton style
    '& .MuiButtonBase-root': {
      p: 0,
    },
  },
};

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode | React.ReactNode[];
  id?: string;
  sx?: any;
}

const Cell = ({ sx, id, children, ...rest }: CellProps) => (
  <Box className="no-highlight" sx={[tableCellStyle, { ...sx }]} id={id} {...rest}>
    {children}
  </Box>
);
export default Cell;
