import _get from "lodash/get";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GroupActivitySheet from "../../components/GroupActivitySheet";
import { selectors as CourseSelectors } from "../../reducers/courses";
import { selectors as CurriculumLessonsSelectors } from '../../reducers/curriculumLessons';
import { actions, selectors as GroupActivitySelector } from "../../reducers/groupActivity";
import {
  selectors as UserSelectors,
  actions as UserActions,
} from "../../reducers/user";
import { ROLE_TYPE } from "../../utils/enums";
import {
  actions as ActivityActions,
} from '../../reducers/activity';
import {
  actions as CurriculumLessonActions,
} from '../../reducers/curriculumLessons';

const mapStateToProps = (state, { match }) => {
  const user = UserSelectors.getCurrentUser(state);
  const activeUserId = user.id;
  const {
    groupActivity: {
      groupActivities,
      groupActivityInfo,
    },
  } = state;

  const currentGroup = groupActivities?.find(
    (group) => group?.students?.find(
      (student) => student?.student_id === activeUserId,
    ),
  );

  const lessonId = _get(match, 'params.lessonId', '');
  const lessonDetails = CurriculumLessonsSelectors.getLessonDetailById(
    state,
    lessonId,
  );
  const unitId = _get(match, 'params.unitId', '');
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const questions = GroupActivitySelector.selectGroupQuestions(state, lessonId);
  const numberOfQuestions = questions.length || 3;

  return {
    lessonId,
    lessonDetails,
    groupActivityInfo,
    curriculumId,
    activityUnitId: unitId,
    activityUserId: user.id,
    activityLessonId: lessonDetails?.lessons?.[0]?.id,
    activityLessonGroupId: lessonDetails.id,
    sectionId: _get(match, 'params.sectionId', ''),
    isTeacher: UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR,
    questions,
    currentGroupStudents: currentGroup?.students || [],
    numberOfQuestions,
  };
};

const mapDispatchToProps = {
  getGroupActivities: actions.getGroupActivities,
  getAllLessonActivity: ActivityActions.getAllLessonActivity,
  getOnlineActivityQuestions: actions.getOnlineActivityQuestions,
  getStudentOnlineActivityQuestions: actions.getStudentOnlineActivityQuestions,
  userReportIssue: UserActions.userReportIssue,
  curriculumLessonsGetDetail: CurriculumLessonActions.curriculumLessonsGetDetail,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupActivitySheet));
