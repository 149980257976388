import { connect } from 'react-redux';
import RegisterSuccess from '../../components/IntermediaScreen/RegisterSuccess';
import { selectors as SectionSelectors } from '../../reducers/sections';

export const mapStateToProps = state => ({
  currentSection: SectionSelectors.getCurrentSection(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSuccess);
