import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReportGenerator from '../../components/ReportGenerator/ReportGenerator';
import GeneratedReports from '../../components/GeneratedReports/GeneratedReports';
import ReportPDFViewer from '../../components/ReportPDFViewer/ReportPDFViewer';


const ReportGeneratorRouter = () => {
  return (   
    <Switch>
      <Route exact path={"/admin/reports/generator"}>
        <ReportGenerator />
      </Route>
      <Route exact path={`/admin/reports/generator/list`}>
        <GeneratedReports />
      </Route>
      <Route exact path={`/admin/reports/generator/:reportId`}>
        <ReportPDFViewer />
      </Route>
    </Switch>
  );
}

export default ReportGeneratorRouter;
