import React from 'react';
import Select from '@components/Atoms/Select';
export const OverallObject = {
  value: { name: 'Overall', id: 'overAllScaledScore' },
  label: 'Overall',
  id: 'overAllScaledScore',
  name: 'Overall',
};

export type SubjectSelectOptionValue = {
  id: string | number;
  name: string;
};

export const subjectSelectListGenerator = (subjects, showOverall = true) => {
  if (!subjects || !Array.isArray(subjects)) {
    return [];
  }
  const subjectList = subjects.map((subject) => ({
    value: subject,
    label: subject.name,
    id: subject.assessment_node_id,
  }));
  if (!showOverall) {
    return subjectList;
  }
  const list = [OverallObject, ...subjectList];

  return list;
};

const SubjectSelect = ({ selectedSubject, setSelectedSubject, list }) => (
  <Select
    id="subject-select"
    onChangeHandler={(value: SubjectSelectOptionValue) => {
      setSelectedSubject(list.find((ele) => ele.value === value));
    }}
    defaultValue={selectedSubject?.value}
    value={selectedSubject?.value}
    list={list}
    label="Subject"
    size="small"
    sx={{ minWidth: '6rem', width: 'max-content' }}
  />
);

export default SubjectSelect;
