import axios from 'axios';
import { handleError } from './utils';

const createPracticeSerializer = (
  lessonIds,
  sectionId,
  unitId,
  userId,
  assetType,
  type,
) => {
  const result = {
    lesson: lessonIds,
    section: sectionId,
    unit: unitId,
    user: userId,
  };

  if (typeof assetType !== 'undefined') {
    result.asset_type = assetType;
  }
  if (typeof type !== 'undefined') {
    result.type = type;
  }

  return result;
};

export const initializePracticeSession = (
  lessonIds,
  sectionId,
  unitId,
  userId,
  assetType,
  type,
) => {
  const url = '/api/practice/initialize/';
  return axios
    .post(
      url,
      createPracticeSerializer(lessonIds, sectionId, unitId, userId, assetType, type),
    )
    .then((response) => ({ response }))
    .catch(handleError);
};

export const takePracticeSession = (practiceId) => {
  const url = `/api/practice/${practiceId}/take/`;
  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const answerPracticeQuestion = (practiceId, answerInfo) => {
  const url = `/api/practice/${practiceId}/answer/`;
  return axios
    .post(url, answerInfo)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const completePracticeSession = (practiceId) => {
  const url = `/api/practice/${practiceId}/complete/`;
  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const practiceSessionReport = (practiceId) => {
  const url = `/api/practice/${practiceId}/report/me/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const initializeReviewPractice = (reviewId, reviewTopicId) => {
  const url = `/api/sessions/review/${reviewId}/practice/${reviewTopicId}/initialize/`;
  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const practiceItems = (practiceId) => {
  const url = `/api/practice/${practiceId}/review/items`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export function fetchContent(type, content_id) {
  const url = `/api/practice/${type}/${content_id}/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export const getLearnosityAdaptivePracticeQuestions = (lessonId) => {
  const url = `/api/practice/lesson/${lessonId}/learnosity-adaptive-practice/questions`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
};

export const getLearnosityDistrictQuestions = (districtId, lessonId) => {
  const url = `/api/practice/questions/district/${districtId}/lesson/${lessonId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch((error) => {
      if (error.response.status === 404) {
        return { response: { data: [] } };
      }
      throw new Error(error);
    });
};
export const getLearnosityDistrictSelectedQuestions = (districtId, lessonId) => {
  const url = `/api/practice/questions/district/${districtId}/lesson/${lessonId}/items/`;
  return axios.get(url).then((response) => ({ response }));
};

export const postLearnosityDistrictQuestions = (districtId, lessonId, body) => {
  const url = `/api/practice/questions/district/${districtId}/lesson/${lessonId}/`;
  return axios.post(url, body).then((response) => ({ response }));
};

export const postLearnosityLessonReview = (sectionId, lessonId, body) => {
  const url = `/api/practice/lesson_review/section/${sectionId}/lesson/${lessonId}/`;
  return axios.post(url, body).then((response) => ({ response }));
};

export const getLearnosityLessonReview = (sectionId, lessonId) => {
  const url = `/api/practice/lesson_review/section/${sectionId}/lesson/${lessonId}/`;
  return axios.get(url).then((response) => ({ response }));
};

export const removeLearnosityLessonReview = (sectionId, lessonId) => {
  const url = `/api/practice/lesson_review/section/${sectionId}/lesson/${lessonId}/`;
  return axios.delete(url).then((response) => ({ response }));
};

export const getLearnosityLessonReviewList = (sectionId, subjectId) => {
  const url = `/api/practice/lesson_review/section/${sectionId}/subject/${subjectId}/`;
  return axios.get(url).then((response) => ({ response }));
};

export const getLearnositySkills = (subjectId) => {
  const url = `/api/practice/learnosity/skills/`;
  return axios
    .get(url, {
      params: {
        subject: subjectId,
      },
    })
    .then((response) => ({ response }));
};

export const getLearnosityLessonReviewScoresList = (sectionId, lessonId) => {
  const url = `/api/practice/lesson_review/section/${sectionId}/lesson/${lessonId}/scores/`;
  return axios.get(url).then((response) => ({ response }));
};

export const getLearnosityLessonReviewSessions = (sectionId, lessonId) => {
  const url = `/api/practice/lesson_review/section/${sectionId}/lesson/${lessonId}/sessions/`;
  return axios.get(url).then((response) => ({ response }));
};
