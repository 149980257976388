/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { LoadingStyled } from '../../stylesheets/Loading.style';

class LoadingIndicator extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    partial: PropTypes.bool,
    noBackground: PropTypes.bool,
  }

  static defaultProps = {
    content: '',
  }

  render() {
    const { content, partial, noBackground, height100 } = this.props;
    return (
      <LoadingStyled partial={partial} noBackground={noBackground} height100={height100}>
        <div className="container">
          <div className="cube">
            <div className="sides">
              <div className="top" />
              <div className="right" />
              <div className="bottom" />
              <div className="left" />
              <div className="front" />
              <div className="back" />
            </div>
          </div>
          <div className="text">{content}</div>
        </div>
      </LoadingStyled>
    );
  }
}

export default LoadingIndicator;
