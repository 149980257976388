/* eslint-disable jsx-a11y/accessible-emoji */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import BackgroundLeft from '../BackgroundLeft';
import { LoginContainerWrap, LoginBlockRight } from './Login.style';
import {
  Container,
  TypeContainer,
  Type,
  TypeImg,
  TitleImg,
  Arrow,
  Title,
  Paragraph,
} from './SignUpType.style';

export default class SignUpType extends PureComponent {
  render() {
    return (
      <LoginContainerWrap>
        <BackgroundLeft />
        <LoginBlockRight>
          <Container>
            <Link to="/login">
              <Arrow src="/images/left.png" />
            </Link>
            <Title>Welcome to ChalkTalk</Title>
            <Paragraph>Choose your account type</Paragraph>
            <TypeContainer>
              <Type>
                <Link to="/register/new-teacher">
                  <TypeImg src="/images/teachers.svg" />
                  <TitleImg>I'm a teacher</TitleImg>
                </Link>
              </Type>
              <Type>
                <Link to="/register/student">
                  <TypeImg src="/images/students.svg" />
                  <TitleImg>I'm a student</TitleImg>
                </Link>
              </Type>
            </TypeContainer>
          </Container>
        </LoginBlockRight>
      </LoginContainerWrap>
    );
  }
}
