import styled from 'styled-components';

export const CheckboxCustomize = styled.div`
  display: flex;
  align-items: center;
  padding: .5em;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBgContent};
  cursor: pointer;
  flex: 1;
  label {
    display:inline-block;
    position: relative;
    padding-left: 1.5em;
  }

  input[type="checkbox"] { 
    display: none; 
  }

  label:before {
    content: '';
    position: absolute;
    top: 0.1em;
    left: 0;
    width: 1em;
    height: 1em;;
    background-color: ${props => props.theme.colorStyled.ColorBgContent};
    border-radius: .25em;
  }

  label {
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label:before {
   background-color: ${props => props.theme.colorStyled.ColorYellowCheckbox};
  }
  &:hover {
    background-color: ${props => props.theme.colorStyled.ColorBgContent};
    border-color: ${props => props.theme.colorStyled.ColorBgContent};
  }
`;
