import { useQuery } from 'react-query';
import { getSectionExam, getSectionExamsScores } from '@apis/sections';
import { useParams } from 'react-router-dom';
import { useGetExamEstimatedScore } from '@components/Component/Exams/hooks/query';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import { selectors } from '@reducers/sections';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

type Section = {
  can_review: boolean;
  section_id: number;
  section_date: string;
  section_name: string;
  last_modified: number;
  section_status: string;
  section_is_reseted: string;
  is_complete: boolean;
};

type SessionMetadata = {
  sections: Section[];
};

export type ExamItem = {
  session_type: number;
  length_type: number;
  exam: {
    exam_type: number;
  };
  start_date: string;
  end_date: string;
  id: number;
  session_metadata: SessionMetadata,
  practice_test_order: number,
};

export const useExamSessions = () => {
  const { sectionId = '' } = useParams();
  const res = useQuery(
    ['sectionExams', sectionId],
    async () => {
      const response = (await getSectionExam(sectionId)) as unknown as {
        response: {
          data: Array<ExamItem>;
        };
      };
      return response?.response?.data || ([] as Array<ExamItem>);
    },
    {
      enabled: !!sectionId,
      staleTime: 1000 * 60 * 60,
      suspense: true,
      refetchOnMount: 'always',
    },
  );
  return res;
};

export const useGetSectionExamsScores = () => {
  const { sectionId } = useParams();
  const res = useQuery(
    ['SectionExamsScores', sectionId],
    async () => {
      const response = (await getSectionExamsScores(sectionId)) as unknown as {
        response: {
          data: any;
        };
      };
      return response?.response?.data || ([] as any);
    },
    {
      enabled: !!sectionId,
      staleTime: 1000 * 60 * 60,
      suspense: true,
      refetchOnMount: 'always',
    },
  );
  return res;
};

/**
 * Custom hook to retrieve the estimated score for a specific section.
 *
 * @param sectionId - The ID of the section.
 * @returns An object containing the section's delta, maximum score, high score, and low score.
 */
export const useSectionEstimatedScoreReport = (sectionId: string) => {
  const {
    getDeltaOfEstimateScore,
    getMaxScoreOfEstimateScore,
    getHighScoreOfEstimateScore,
    getLowScoreOfEstimateScore,
  } = selectors;

  const sectionDelta = useSelector((state) => getDeltaOfEstimateScore(state, sectionId));
  const sectionMaxScore = useSelector((state) =>
    getMaxScoreOfEstimateScore(state, sectionId),
  );
  const sectionHighScore = useSelector((state) =>
    getHighScoreOfEstimateScore(state, sectionId),
  );
  const sectionLowScore = useSelector((state) =>
    getLowScoreOfEstimateScore(state, sectionId),
  );

  return {
    sectionDelta,
    sectionMaxScore,
    sectionScoreRange: `${sectionLowScore} - ${sectionHighScore}`,
  };
};

/**
 * Custom hook that retrieves the section's lexile level report.
 *
 * @param sectionId - The ID of the section.
 * @returns An object containing the grade level delta, grade level value, and lexile value.
 */
export const useSectionLexileLevelReport = (sectionId: string) => {
  const { getGradeLevelDelta, getGradeLevelValue, getLexileValue } = selectors;

  const gradeLevelValue = useSelector((state) => getGradeLevelValue(state, sectionId));
  const lexileValue = useSelector((state) => getLexileValue(state, gradeLevelValue));
  const gradeLevelDelta = useSelector((state) => getGradeLevelDelta(state, sectionId));

  return { gradeLevelDelta, gradeLevelValue, lexileValue };
};

export type EstimatedScore = {
  id: string | number;
  name: string;
  maxScore: number;
  scoreRange: string;
};

/**
 * Custom hook that calculates the estimated score report for all subjects in the current curriculum.
 * It retrieves the estimated score data and the student list, and calculates the estimated score report
 * based on the low and high scores of each student.
 *
 * @returns The estimated score report for each subject.
 */
export const useSubjectEstimatedScoreReport = () => {
  const { data: estimatedScoreData } = useGetExamEstimatedScore();
  const studentList = useSectionStudentWithFilter();

  const allSubjectsReport: EstimatedScore[] = useMemo(() => {
    const subjects = estimatedScoreData?.subjects || [];
    const subjectEstimatedScore: EstimatedScore[] = [];
    subjects.forEach((subject) => {
      const studentData = subject?.student_data || {};
      let sumOfLowScore = 0;
      let sumOfHighScore = 0;
      let subjectMaxScore = 0;

      studentList.forEach((student) => {
        const { id } = student;
        const lowScore = studentData[id]?.low_score || 0;
        const highScore = studentData[id]?.high_score || 0;
        const maxScore = studentData[id]?.max_score;
        sumOfLowScore += lowScore;
        sumOfHighScore += highScore;
        if (maxScore && !subjectMaxScore) {
          subjectMaxScore = maxScore;
        }
      });

      const subjectHighScore = Math.round(sumOfHighScore / studentList.length);
      const subjectLowScore = Math.round(sumOfLowScore / studentList.length);
      subjectEstimatedScore.push({
        id: subject.assessment_node_id,
        maxScore: subjectMaxScore,
        name:
          subject.name === 'Preparing for Higher Math'
            ? 'Math'
            : (subject.name as string),
        scoreRange: `${subjectLowScore} - ${subjectHighScore}`,
      });
    });

    return subjectEstimatedScore;
  }, [estimatedScoreData, studentList]);

  return allSubjectsReport;
};
