import useSkillTreeSelector from '@components/GenericSkillSelector/hooks';
import { useReducer, useCallback, Reducer, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SubjectRecord } from '../types';
import { buildRemoveToastMessage } from '../utils';

interface SkillState {
  selectedSkills: number[];
  manualSelectedSkills: number[];
  removedSkills: number[];
  prioritySkills: object;
  recommendedSkills: object;
}

type SkillAction =
  | { type: 'SET_SELECTED_SKILLS'; payload: number[] }
  | { type: 'SET_MANUAL_SELECTED_SKILLS'; payload: number[] }
  | { type: 'SET_REMOVED_SKILLS'; payload: number[] }
  | { type: 'SET_PRIORITY_SKILLS'; payload: object }
  | { type: 'SET_RECOMMENDED_SKILLS'; payload: object };

const initialState = {
  selectedSkills: [],
  manualSelectedSkills: [],
  removedSkills: [],
  recommendedSkills: {},
  prioritySkills: {},
};

const contentFilterReducer: Reducer<SkillState, SkillAction> = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_SKILLS':
      return { ...state, selectedSkills: action.payload };
    case 'SET_RECOMMENDED_SKILLS':
      return { ...state, recommendedSkills: action.payload };
    case 'SET_MANUAL_SELECTED_SKILLS':
      return { ...state, manualSelectedSkills: action.payload };
    case 'SET_PRIORITY_SKILLS':
      return { ...state, prioritySkills: action.payload };
    case 'SET_REMOVED_SKILLS':
      return { ...state, removedSkills: action.payload };
    default:
      return state;
  }
};

const useSkillReducer = (subjects: SubjectRecord[]) => {
  const [skillState, skillDispatch] = useReducer(contentFilterReducer, initialState);
  const { skillTree, skillMap } = useSkillTreeSelector(subjects);
  const [prevPrioSkills, setPrevPrioSkills] = useState({});
  // disable the next time a toast is shown, for when doing a full reset
  const [disableToast, setDisableToast] = useState(false);

  const setSelectedSkills = (skills: number[]) => {
    skillDispatch({ type: 'SET_SELECTED_SKILLS', payload: skills });
  };

  const setManuallySelectedSkills = (skills: number[]) => {
    skillDispatch({ type: 'SET_MANUAL_SELECTED_SKILLS', payload: skills });
  };

  const setRecommendedSkills = (skills: object) => {
    skillDispatch({ type: 'SET_RECOMMENDED_SKILLS', payload: skills });
  };

  const setPrioritySkills = (skills: object) => {
    skillDispatch({ type: 'SET_PRIORITY_SKILLS', payload: skills });
  };

  const setRemovedSkills = (skills: number[]) => {
    skillDispatch({ type: 'SET_REMOVED_SKILLS', payload: skills });
  };

  const getSkillById = useCallback(
    (id) => {
      if (id in skillMap) {
        return skillMap[id];
      }
      return null;
    },
    [skillMap],
  );

  const getSkillTree = () => skillTree;

  const getSelectedSkillObjects = () =>
    skillState.selectedSkills.map((id) => getSkillById(id)).filter(Boolean);

  // when priority skills change, update the selected skills
  useEffect(() => {
    const previousPrioIds = Object.keys(prevPrioSkills).map(Number);
    const newPrioIds = Object.keys(skillState.prioritySkills).map(Number);
    const removedIds = previousPrioIds.filter(
      (id) => !newPrioIds.includes(id) && !skillState.manualSelectedSkills.includes(id),
    );

    if (removedIds.length && !disableToast) {
      const removedObjects = removedIds.map((id) => getSkillById(id)).filter(Boolean);
      removedObjects.forEach((skill, index) => {
        setTimeout(() => {
          toast.warn(
            buildRemoveToastMessage(
              'Skill',
              skill.name,
              'Lessons',
              prevPrioSkills[skill.id],
            ),
            {
              autoClose: 5000,
            },
          );
        }, index * 100); // Small delay to prevent suppression
      });
    }

    if (disableToast) {
      setDisableToast(false);
    }

    setPrevPrioSkills(skillState.prioritySkills);
    const unselectedManualSkills = skillState.manualSelectedSkills.filter(
      (id) => !newPrioIds.includes(id),
    );
    // we're tracking removed skills, so they dont get re-selected
    const unRemovedPrioritySkills = newPrioIds.filter(
      (id) => !skillState.removedSkills.includes(id),
    );
    setSelectedSkills([...unRemovedPrioritySkills, ...unselectedManualSkills]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillState.prioritySkills]);

  return {
    skillState,
    skillDispatch,
    setSelectedSkills,
    setRecommendedSkills,
    setManuallySelectedSkills,
    getSkillById,
    getSkillTree,
    getSelectedSkillObjects,
    setPrioritySkills,
    setDisableToast,
    setRemovedSkills,
  };
};

export default useSkillReducer;
