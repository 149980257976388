import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { QUESTION_TYPES, REPORT_TYPES } from '../../utils/enums';
import {
  QuestionHeaderWrap,
  QuestionProgress,
  QuestionContentWrap,
  QuestionAnswer,
  QuestionAction,
  QuestionOrderWrapper,
  QuestionOrderNumber,
  QuestionDesc,
} from '../QuestionItem/QuestionItem.style';
import { Button, WarningIcon } from '../Generals/stylesheets/Button.style';
import { OnlineQuestionWrapper } from '../OnlinePracticeContent/OnlinePracticeContent.style';
import MathJaxItem from '../MathJaxContainer';

const scrollStyle = {
  height: 'calc(100vh - 20em)',
  width: '100%',
};

export default class OnlineQuestionItem extends Component {
  static propTypes = {
    questionSelected: PropTypes.object,
    totalQuestions: PropTypes.number,
    prevQuestion: PropTypes.func,
    nextQuestion: PropTypes.func,
    order: PropTypes.number,
    index: PropTypes.number,
    answer: PropTypes.object,
    examChooseAnswer: PropTypes.func,
    shouldDisplayGrammarHelper: PropTypes.bool,
    openReportErrorModal: PropTypes.func,
    questionId: PropTypes.number,
  };

  timeStart = moment();

  componentDidUpdate(prevProps) {
    const { questionSelected: prevQuestionSelected } = prevProps;
    const { questionSelected } = this.props;

    if (!_isEqual(questionSelected, prevQuestionSelected)) {
      this.timeStart = moment();
    }
  }

  chooseAnswer = (value) => {
    const { examChooseAnswer, questionSelected, answer } = this.props;
    const { id } = questionSelected;
    const currentResponseTime = _get(answer, 'response_time', 0);
    const duration = moment.duration(moment().diff(this.timeStart));
    const responseTime = duration.seconds();

    const payload = {
      exam_question_id: id,
      value,
      response_time: responseTime + currentResponseTime,
      delete: false,
    };

    examChooseAnswer(id, payload);
    // reset time
    this.timeStart = moment();
  };

  calculateDuration = () => {
    const { answer, examChooseAnswer, questionSelected } = this.props;
    const { id } = questionSelected;
    const currentResponseTime = _get(answer, 'response_time', 0);

    const duration = moment.duration(moment().diff(this.timeStart));
    const responseTime = duration.seconds();

    examChooseAnswer(id, {
      value: null,
      exam_question_id: id,
      delete: false,
      ...answer,
      response_time: responseTime + currentResponseTime,
    });
  };

  nextQuestion = () => {
    const { nextQuestion } = this.props;
    this.calculateDuration();
    nextQuestion();
  };

  prevQuestion = () => {
    const { prevQuestion } = this.props;
    this.calculateDuration();
    prevQuestion();
  };

  handleNextQuestion = () => {
    const { index, totalQuestions } = this.props;

    if (index < totalQuestions - 1) {
      this.nextQuestion();
    }
  };

  handlePrevQuestion = () => {
    const { index } = this.props;

    if (index > 0) {
      this.prevQuestion();
    }
  };

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.QUESTION, questionId);
  };

  renderAnswer = () => {
    const { questionSelected, answer } = this.props;
    const answerType = _get(questionSelected, 'question.question_format');

    const value = _get(answer, 'value', '');
    switch (answerType) {
      case QUESTION_TYPES.GRID_IN: {
        const transformValue = `${value}____`.substring(0, 4);
        const selectedValueArr = transformValue.split('');
        const score = _get(questionSelected, 'score', {});
        return (
          <AnswerGridChoice
            score={score}
            selectedValue={selectedValueArr}
            chooseAnswer={this.chooseAnswer}
            handlePrevQuestion={this.handlePrevQuestion}
            handleNextQuestion={this.handleNextQuestion}
          />
        );
      }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default: {
        const choices = _get(questionSelected, 'question.choices', []);
        const values = Array.isArray(value) ? value : [value];
        const orderChoice = _orderBy(choices, 'order', 'asc');
        return (
          <AnswerMultiChoice
            userChoice={values}
            choices={orderChoice}
            chooseAnswer={this.chooseAnswer}
            handlePrevQuestion={this.handlePrevQuestion}
            handleNextQuestion={this.handleNextQuestion}
          />
        );
      }
    }
  };

  renderButtonGroup = () => {
    const { index, totalQuestions } = this.props;
    const shouldRenderPrevButton = index > 0;
    const shouldRenderNextButton = index < totalQuestions - 1;

    return (
      <React.Fragment>
        {shouldRenderPrevButton ? (
          <Button blue mobDesk textUppercase onClick={this.prevQuestion}>
            {'<  Previous'}
          </Button>
        ) : (
          <div />
        )}
        {shouldRenderNextButton ? (
          <Button blue mobDesk textUppercase onClick={this.nextQuestion}>
            {'Next  >'}
          </Button>
        ) : (
          <div />
        )}
      </React.Fragment>
    );
  };

  render() {
    const {
      questionSelected,
      totalQuestions,
      index,
      order,
      shouldDisplayGrammarHelper = false,
    } = this.props;
    const getQuestionContent = _get(
      questionSelected,
      'question.prompt.text',
      '',
    );

    return (
      <OnlineQuestionWrapper>
        <QuestionHeaderWrap>
          <QuestionProgress>
            Question
            <span>{`${index + 1} / ${totalQuestions}`}</span>
          </QuestionProgress>
          <WarningIcon
            className="chalktalk-error-wr"
            onClick={this.openReportErrorModal}
          />
        </QuestionHeaderWrap>
        <QuestionContentWrap practiceQuestion>
          <ShadowScrollbar autoHide style={scrollStyle}>
            {shouldDisplayGrammarHelper && (
              <QuestionOrderWrapper>
                <span>This question refers to </span>
                <QuestionOrderNumber>{order}</QuestionOrderNumber>
              </QuestionOrderWrapper>
            )}
            <QuestionDesc>
              <MathJaxItem data={getQuestionContent} />
            </QuestionDesc>
            <QuestionAnswer>{this.renderAnswer()}</QuestionAnswer>
          </ShadowScrollbar>
          <QuestionAction>{this.renderButtonGroup()}</QuestionAction>
        </QuestionContentWrap>
      </OnlineQuestionWrapper>
    );
  }
}
