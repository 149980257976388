import {
  all, select, call, put,
  takeLeading,
} from 'redux-saga/effects';
import _orderBy from 'lodash/orderBy';
import { push } from 'connected-react-router';
import * as CourseApis from '../../apis/courses';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';
import { updateTeacherRegistration } from '../../apis/register';
import {
  getUserId,
  updateSchoolInfo,
} from '../../reducers/user';
import {
  COURSES_CREATE_NEW,
  COURSES_GET_LIST,
  COURSE_FETCH_ON_START,
  COURSE_REQUEST_INVITATION,
  COURSES_UPDATE,
  COURSE_DEACTIVATE,

  actions as CourseAction,
  getCourseList,
} from '../../reducers/courses';
import {
  actions as systemActions,
} from '../../reducers/system';

const DEFAULT_ERROR_MESSAGE = 'Unexpected error has occurred';

export function* fetchCourseList() {
  try {
    const userId = yield select(getUserId);
    if (!userId) {
      yield put(CourseAction.fetchCourseListFail([DEFAULT_ERROR_MESSAGE]));
      return;
    }

    const { response, error } = yield call(CourseApis.getUserCourseList, userId);
    if (error) throw error;

    const { data = [] } = response;

    const sortedData = _orderBy(data, 'start_date', 'desc');
    yield put(CourseAction.fetchCourseListSuccess(sortedData));
  } catch (error) {
    yield put(CourseAction.fetchCourseListFail(error));
  }
}

export function* handleCreateNewCourse({ payload, callback }) {
  const { courseInfo } = payload;
  try {
    const { response: schoolRes, error: schoolError } = yield call(updateTeacherRegistration, courseInfo);
    if (schoolError) throw schoolError;

    const { data: schoolData } = schoolRes;
    const { response: courseRes, error: courseError } = yield call(
      CourseApis.createCourse,
      courseInfo,
      schoolData.id, // organization
    );

    if (courseError) throw courseError;

    const { data: courseData } = courseRes;
    const { id } = courseData;

    yield put(CourseAction.createCourseSuccess(courseData));
    yield put(updateSchoolInfo(schoolData));
    yield put(CourseAction.fetchCourseList());
    yield put(push(`/management/course/${id}/teachers?isOpenInviteTeacher=1`));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield put(CourseAction.createCourseFail(error.message || error));
  }
}

function* courseFetchOnStart() {
  try {
    const userId = yield select(getUserId);
    if (!userId) {
      yield put(CourseAction.fetchCourseListFail([DEFAULT_ERROR_MESSAGE]));
      return;
    }

    const { response, error } = yield call(CourseApis.getUserCourseList, userId);
    if (error) throw error;

    const { data = [] } = response;

    const sortedData = _orderBy(data, 'start_date', 'desc');
    yield put(CourseAction.courseFetchOnStartSuccess(sortedData));
    // yield put(push('/dashboard/course'));
  } catch (error) {
    yield put(CourseAction.courseFetchOnStartFail(error));
  }
}

function* handleRequestInvite({ payload }) {
  const { code } = payload;

  try {
    const { error, response } = yield call(CourseApis.requestInviteCourse, code);
    if (error) throw error;
    const { data } = response;

    yield put(CourseAction.requestInvitationSuccess(data));
  } catch (error) {
    yield put(CourseAction.requestInvitationFail(error));
  }
}

function* courseUpdate({ courseId, courseInfo }) {
  const { response, error } = yield call(CourseApis.updateCourseDetail, courseId, courseInfo);

  if (!error) {
    const { data } = response;

    yield put(CourseAction.courseUpdateSuccess(courseId, data));
  } else {
    yield put(CourseAction.courseUpdateFail());
  }
}

function* courseDeactivate({ courseId }) {
  const { error } = yield call(CourseApis.deactivateCourse, courseId);
  if (!error) {
    yield put(systemActions.systemSetPageLoading(true));
    yield put(CourseAction.courseSelected(null));
    yield put(push('/management/course'));
    yield put(CourseAction.courseFetchOnStart());
    ToastSuccess('Successfully deactivated!');
  } else {
    ToastError('Unexpected error occured when we were trying to deactive this course!');
  }
}

export default function* courseSaga() {
  yield all([
    takeLeading(COURSES_CREATE_NEW, handleCreateNewCourse),
    takeLeading(COURSES_GET_LIST, fetchCourseList),
    takeLeading(COURSE_FETCH_ON_START, courseFetchOnStart),
    takeLeading(COURSE_REQUEST_INVITATION, handleRequestInvite),
    takeLeading(COURSES_UPDATE, courseUpdate),
    takeLeading(COURSE_DEACTIVATE, courseDeactivate),
  ]);
}
