/* eslint-disable import/prefer-default-export */
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@reducers/user';
import { initializePracticeSession } from '../../apis/practice';

export const useNavigateToQuestionSelector = () => {
  const history = useHistory();
  const params = useParams();
  const { courseId, lessonId, sectionId, subjectId, unitId } = params;

  const route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/question-selector`;
  return React.useCallback(() => {
    history.push(route);
  }, [history, route]);
};

export const useNavigateToQuestionSelectorPreview = () => {
  const history = useHistory();
  const params = useParams();
  const { courseId, lessonId, sectionId, subjectId, unitId } = params;

  const route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/question-selector/preview`;
  return React.useCallback(() => {
    history.push(route);
  }, [history, route]);
};

export const useNavigateToPractice = (lang = 'en', type = 'standards') => {
  const history = useHistory();
  const params = useParams();
  const { courseId, sectionId, unitId, lessonId, subjectId } = params;
  const route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/${type}-practice`;

  return React.useCallback(() => {
    history.push({
      pathname: route,
      search: type === 'standards' ? `?lang=${lang || 'en'}` : '',
    });
  }, [history, route, type, lang]);
};

export const useNavigateToSessionResults = (action = 'push', state = {}) => {
  const history = useHistory();
  const callable = action === 'push' ? history.push : history.replace;
  const params = useParams();
  const { courseId, sectionId, unitId, lessonId, subjectId } = params;
  const route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice-review`;

  return React.useCallback(() => {
    callable({
      pathname: route,
      state,
    });
  }, [callable, route, state]);
};

export const useNavigateToReview = (practiceSessionId) => {
  const history = useHistory();
  const params = useParams();
  const { courseId, sectionId, unitId, lessonId, subjectId } = params;
  const route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/standards-practice/${practiceSessionId}/review`;

  return React.useCallback(() => {
    history.push({
      pathname: route,
    });
  }, [history, route]);
};

export const useNavigateToPreview = (lang = 'en') => {
  const history = useHistory();
  const params = useParams();
  const { courseId, sectionId, unitId, lessonId, subjectId } = params;
  const isAdaptive = useIsAdaptiveLearnosityPracticeEnabled();
  const practiceType = isAdaptive ? 'adaptive' : 'standards';

  const route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/${practiceType}-practice/preview`;

  return React.useCallback(() => {
    history.push({
      pathname: route,
      search: isAdaptive ? '' : `?lang=${lang || 'en'}`,
    });
  }, [history, isAdaptive, lang, route]);
};

export const useInitializePracticeSession = () => {
  const QueryClient = useQueryClient();
  const currentUser = useSelector(getCurrentUser);
  const { sectionId, unitId, lessonId } = useParams();

  const response = useMutation(
    (type) =>
      initializePracticeSession(lessonId, sectionId, unitId, currentUser.id, _, type),
    {
      mutationKey: ['initializePractice'],
      retry: 3,
      retryDelay: 5 * 1000,
      onSuccess: () => {
        QueryClient.invalidateQueries([
          'learnosityLessonReviewSessions',
          sectionId,
          lessonId,
        ]);
      },
    },
  );
  return response;
};
