import Box from '@components/Atoms/Box';
import Button from '@components/Atoms/Button';
import Typography from '@components/Atoms/Typography';
import React from 'react';
import ShowIf from '@components/Atoms/ShowIf';
import { Badge, CircularProgress } from '@mui/material';
import {
  AttendanceStatus,
  useAttendanceCounts,
  useIsMutatingSubmitLessonAttendance,
} from '../../hooks/helpers';

const ActionButton = ({ isLoading, status, label, onClick, color, count }) => (
  <Badge badgeContent={`${count}`} color={color} sx={{ flex: 1 }}>
    <Button
      disabled={isLoading}
      variant="outlined"
      size="medium"
      sx={{ flex: 1 }}
      color={color}
      onClick={() => onClick(status)}
    >
      {label}
    </Button>
  </Badge>
);

const AttendanceActions = ({ isLoading, onToggleAttendance }) => {
  const isMutatingSubmitLessonAttendance = useIsMutatingSubmitLessonAttendance();
  const { totalPresentStudents, totalAbsentStudents, totalUnrecordedStudents } =
    useAttendanceCounts();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        alignItems: 'center',
        paddingInlineStart: '0.5rem',
        mb: '0.5rem',
      }}
    >
      <Typography variant="subtitle1">Mark All Students As:</Typography>
      <Box sx={{ display: 'flex', gap: '1rem', flex: 1, alignItems: 'center' }}>
        <ActionButton
          count={totalPresentStudents}
          isLoading={isLoading || isMutatingSubmitLessonAttendance}
          status={AttendanceStatus.Present}
          label="Present"
          onClick={onToggleAttendance}
          color="play"
        />
        <ActionButton
          count={totalAbsentStudents}
          isLoading={isLoading || isMutatingSubmitLessonAttendance}
          status={AttendanceStatus.Absent}
          label="Absent"
          onClick={onToggleAttendance}
          color="error"
        />
        <ActionButton
          count={totalUnrecordedStudents}
          isLoading={isLoading || isMutatingSubmitLessonAttendance}
          status={AttendanceStatus.UnRecorded}
          label="Unrecorded"
          onClick={onToggleAttendance}
          color="chipGrayCT"
        />
        <Box sx={{ minWidth: '20px' }}>
          <ShowIf If={isLoading}>
            <CircularProgress size={20} sx={{ color: 'black' }} />
          </ShowIf>
        </Box>
      </Box>
    </Box>
  );
};

export default AttendanceActions;
