import React from 'react';
import { CurriculumItemContainer, CurriculumLabel, CurriculumTitle } from './MyLibrary.style';

const CurriculumItem = ({ title, label }) => (
  <CurriculumItemContainer>
    <CurriculumTitle>{title}</CurriculumTitle>
    <CurriculumLabel>{label}</CurriculumLabel>
  </CurriculumItemContainer>
);

export default CurriculumItem;
