/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import md5 from 'md5';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import ProfileEditForm from '../../containers/ProfileEditForm';
import ProfileChangePassword from '../../containers/ProfileChangePassword';
import {
  UserProfileView,
  UserProfileInfo,
  UserProfileGroup,
  UserProfileTitle,
  UserOrgazinationItem,
  UserOrgazinationTitle,
  UserProfileDetailWrap,
  UserProfileGroupWrap,
  UserProfileGroupContent,
  UserIcon,
  UserLabel,
  UserContent,
  UserAvatarWrap,
  UserAvatar,
  UserDescription,
  UserBackgroundHeaderWrap,
  UserImage,
  UserActionGroup,
  UserSocialIcon,
  UserButonAction,
  ProfileContentWrapper,
} from './Profile.style';
import { Button } from '../Generals/stylesheets/Button.style';

import { formatDateByTime } from '../../utils/func-utils';

const CLIENT_ID = process.env.CLIENT_ID;

const REDIRECT_URI = `${window.location.origin}/auth/complete/clever/`;
const CLEVER_LOGIN_URL = `https://clever.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;

const scrollStyle = {
  height: '100vh',
  width: '100%',
};

class Profile extends PureComponent {
  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    isChangedPassword: PropTypes.bool,
    isEditedProfile: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      isProfileEditOpen: false,
      isUpload: false,
      isChangePasswordOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isEditedProfile, isChangedPassword } = this.props;
    if (isEditedProfile && prevProps.isEditedProfile === false) {
      this.onEditClose();
    }
    if (isChangedPassword && prevProps.isChangedPassword === false) {
      this.onChangePasswordClose();
    }
  }

  onEditOpen = () => {
    this.setState({
      isProfileEditOpen: true,
    });
  }

  onEditClose = () => {
    this.setState({
      isProfileEditOpen: false,
    });
  }

  onChangePasswordOpen = () => {
    this.setState({
      isChangePasswordOpen: true,
    });
  }

  onChangePasswordClose = () => {
    this.setState({
      isChangePasswordOpen: false,
    });
  }

  onToggleUpload = () => {
    const { isUpload } = this.state;
    this.setState({
      isUpload: !isUpload,
    });
  }

  renderCleverAction = () => {
    const { user } = this.props;
    const { accounts = [] } = user;
    return (
      <UserOrgazinationItem>
        <UserProfileInfo>
          <UserSocialIcon src="/images/bg-clever.png" />
          {accounts && accounts.length > 0
            ? <UserButonAction connected>Connected</UserButonAction>
            : <UserButonAction href={CLEVER_LOGIN_URL}>Connect</UserButonAction>}
        </UserProfileInfo>
      </UserOrgazinationItem>
    );
  }

  renderOrgazinationItem = (values, index) => {
    const { name, district } = values;
    return (
      <UserOrgazinationItem key={index}>
        <UserProfileInfo>
          <UserLabel>Name</UserLabel>
          <UserContent>{name}</UserContent>
        </UserProfileInfo>

        <UserProfileInfo>
          <UserLabel>District</UserLabel>
          <UserContent>{_isEmpty(district) ? 'N/A' : `${district.name}, ${district.state}`}</UserContent>
        </UserProfileInfo>
      </UserOrgazinationItem>
    );
  }

  renderProfileView = () => {
    const { user } = this.props;
    return (
      <UserProfileGroup flexLeft>
        <UserProfileView>
          <UserProfileTitle>About</UserProfileTitle>
          <UserProfileInfo>
            <UserIcon className="chalktalk-icon-user" />
            <UserContent>{`${user.first_name} ${user.last_name}`}</UserContent>
          </UserProfileInfo>
          <UserProfileInfo>
            <UserIcon className="chalktalk-email" />
            <UserContent>{user.email}</UserContent>
          </UserProfileInfo>

          <UserProfileInfo>
            <UserIcon className="chalktalk-role" />
            <UserContent>{user.role}</UserContent>
          </UserProfileInfo>

          <UserProfileInfo>
            <UserIcon className="chalktalk-last-login" />
            <UserContent>{formatDateByTime(user.last_login)}</UserContent>
          </UserProfileInfo>
        </UserProfileView>
      </UserProfileGroup>
    );
  }

  renderOrgazination = () => {
    const { user: { organizations } } = this.props;
    return organizations.map(this.renderOrgazinationItem);
  }

  renderUserAvatar = () => {
    const { user } = this.props;
    const { isChangePasswordOpen, isProfileEditOpen } = this.state;
    const {
      first_name, last_name, email,
    } = user;
    const userHash = md5(email);
    return (
      <UserAvatarWrap>
        <UserAvatar>
          <img src={`https://secure.gravatar.com/avatar/${userHash}?d=retro&s=200&r=pg`} />
        </UserAvatar>
        <UserDescription>
          <p>{`${first_name} ${last_name}`}</p>
          <Button iconWidth className="chalktalk-edit" onClick={this.onEditOpen} />
        </UserDescription>

        <UserActionGroup>
          <Button full onClick={this.onChangePasswordOpen}>Change Password</Button>
        </UserActionGroup>

        <ProfileEditForm
          isOpen={isProfileEditOpen}
          onClose={this.onEditClose}
        />

        <ProfileChangePassword
          isOpen={isChangePasswordOpen}
          onClose={this.onChangePasswordClose}
        />
      </UserAvatarWrap>
    );
  }

  renderHeaderBackground = () => {
    const imageUrl = false;
    const { isUpload } = this.state;
    return (
      <UserBackgroundHeaderWrap
        haveImage={!imageUrl}
        isUpload={isUpload}
      >
        {imageUrl && <UserImage src="" />}
      </UserBackgroundHeaderWrap>
    );
  }

  render() {
    return (
      <ShadowScrollbar autoHide style={scrollStyle}>
        {this.renderHeaderBackground()}
        <ProfileContentWrapper medium>
          {this.renderUserAvatar()}

          <UserProfileDetailWrap>
            {this.renderProfileView()}

            <UserProfileGroupWrap>
              <UserProfileGroupContent>
                <UserOrgazinationTitle>CONNECT</UserOrgazinationTitle>
                {this.renderCleverAction()}
              </UserProfileGroupContent>
              <UserProfileGroupContent>
                <UserOrgazinationTitle>ORGANIZATION INFO</UserOrgazinationTitle>
                {this.renderOrgazination()}
              </UserProfileGroupContent>
            </UserProfileGroupWrap>

          </UserProfileDetailWrap>
        </ProfileContentWrapper>
      </ShadowScrollbar>
    );
  }
}

export default Profile;
