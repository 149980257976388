import { connect } from 'react-redux';
import _get from 'lodash/get';
import ScheduleAddLessonSession from '../../components/ScheduleAddLessonSession';
import { actions as SectionUnitActions } from '../../reducers/sectionUnit';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { actions as SchedulteModalActions } from '../../reducers/scheduleModal';
import { actions as sectionSubjectActions } from '../../reducers/sectionSubjects';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const sectionId = _get(match, 'params.sectionId', '');
  return {
    sectionId,
    curriculumId,
  };
};

const mapDispatchToProps = {
  sectionUnitGet: SectionUnitActions.sectionUnitGet,
  fetchSectionSubjectList: sectionSubjectActions.fetchSectionSubjectList,
  clearSessionDate: SchedulteModalActions.clearSessionDate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleAddLessonSession);
