import {
  call, put,
  takeLatest, select,
} from 'redux-saga/effects';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import { push } from 'connected-react-router';
import * as CourseApis from '../../apis/courses';
import * as SectionAPI from '../../apis/sections';
import _reduce from 'lodash/reduce';
import { splitLessonChildren, splitUnitChildren } from '../../utils/func-utils';
import { success, error as errorToast } from '../../utils/toast';
import {
  SECTIONS_UPDATE,
  SECTIONS_GET_DETAIL,
  SECTIONS_GET_ALL_BY_COURSE,
  SECTIONS_CREATE_NEW,
  SECTIONS_GET_ON_START,
  DEACTIVATE_SECTION,
  SECTIONS_ATTENDANCE_GET,
  SECTIONS_EXAMS_SCORES_GET,
  SECTIONS_ESTIMATED_GET,
  SECTIONS_PRACTICE_SUMMARY_GET,
  SECTIONS_ATTENDANCE_GET_SUMMARY,
  SECTIONS_ESTIMATED_STUDENT_GET,
  SECTIONS_USER_PRACTICE_SUMMARY_GET,
  SECTION_ATTENDANCE_SUBMIT,
  SECTIONS_REQUEST_ENROLLMENT,
  SECTION_UPDATE_COMPLETE,
  SECTION_UPDATE_UN_COMPLETE,
  UPDATE_SECTION_UNITS,
  COURSE_GET_ALL_SECTIONS_LIST,
  SECTION_GET_AVAILABLE_PACKAGES,
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import {
  COURSES_SELECT_COURSE,

  actions as courseActions,
} from '../../reducers/courses';
import {
  actions as SectionUnitActions
} from '../../reducers/sectionUnit'
import {
  actions as curriculumActions,
} from '../../reducers/curriculumLessons';
import { actions as sectionAction } from "../../reducers/activity"
import { selectors as userSelectors } from '../../reducers/user';

function* fetchSectionFromCourseId({ payload }) {
  const { courseId } = payload;
  try {
    const { response, error } = yield call(CourseApis.getSectionListByCourseId, courseId);
    if (error) throw error;

    const { data: sectionList } = response;

    yield put(SectionActions.getAllSectionByCourseSuccess(sectionList, courseId));
  } catch (error) {
    yield put(SectionActions.getAllSectionByCourseFail(error));
  }
}

/**
 * ! Need update callback
 */
function* sectionsUpdate({ sectionValues, sectionId }) {
  // delete sectionValues["name"]
  const { response, error } = yield call(SectionAPI.updateSection, sectionId, sectionValues);

  delete sectionValues.name;

  if (response && !error) {
    const { data } = response;
    yield put(SectionActions.sectionsUpdateSuccess(sectionId, data));
  } else {
    yield put(SectionActions.sectionsUpdateFail(error));
    errorToast('Error: Cannot update section schedule. Please try again later');
  }
}

/**
 * ! Need update callback
 */
function* sectionsGetDetail({ sectionId, forceUpdate = false }) {
  const currentSectionDetail = yield select(SectionSelectors.sectionDetail);
  if (!forceUpdate && (!_isEmpty(currentSectionDetail) && currentSectionDetail.id == sectionId)) { // eslint-disable-line
    return;
  }

  const { response, error } = yield call(SectionAPI.getSectionDetail, sectionId);

  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsGetDetailSuccess(data));
  } else {
    yield put(SectionActions.sectionsGetDetailFail(error));
  }
}

function* getAllSectionByCourse({ courseId }) {
  const shouldFetch = yield select(SectionSelectors.shouldFetchSection, courseId);

  if (!shouldFetch) {
    return;
  }

  const { response } = yield call(CourseApis.getSectionListByCourseId, courseId);

  if (response) {
    const { data = [] } = response;
    yield put(SectionActions.getAllSectionByCourseSuccess(data, courseId));
  }
}

function* getAllSectionsByCourse({ courseId }) {
  const { response, error } = yield call(CourseApis.getAllSectionsListByCourseId, courseId);

  if (response) {
    const { data = [] } = response;
    yield put(SectionActions.getAllSectionsByCourseSuccess(data));
  }else{
    yield put(SectionActions.getAllSectionsByCourseFail(error));
  }
}

function* handleCreateNewSection({ sectionInfo, callback }) {
  try {
    const { course: courseId } = sectionInfo;
    const { response, error, errorStatus } = yield call(CourseApis.createSection, sectionInfo, courseId);
    if (error) throw { error, errorStatus };

    if (response) {
      const { data: newSectionInfo = {} } = response;

      yield put(SectionActions.sectionsCreateNewSectionSuccess(newSectionInfo));
      success('Create new section successfully');

      if (callback) {
        callback(newSectionInfo);
      }
    }
  } catch (err) {
    const message = _get(err.error, 0, 'Unexpected error');
    if (err.errorStatus == 400)
      errorToast('Section could not be created. A section with that name already exists.');
    yield put(SectionActions.sectionsCreateNewSectionFail(message));
  }
}

function* sectionGetOnStart({ courseId }) {
  const { response } = yield call(CourseApis.getSectionListByCourseId, courseId);

  if (response) {
    const { data = [] } = response;
    yield put(SectionActions.sectionGetOnStartSuccess(data, courseId));
  }
}

function* handleSectionEnrollment({ sectionCode }) {
  try {
    const { response, error } = yield call(SectionAPI.requestSectionEnroll, sectionCode);
    if (error) throw error;

    const { data: newSectionInfo = {} } = response;
    yield put(courseActions.fetchCourseList());
    yield put(SectionActions.sectionRequestEnrollmentSuccess(newSectionInfo));
  } catch (error) {
    yield put(SectionActions.sectionRequestEnrollmentFail(error));
  }
}

function* handleDeactivateSection({ sectionId }) {
  try {
    const { error } = yield call(SectionAPI.deactiveSections, sectionId);
    if (error) throw error;

    const sectionList = yield select(SectionSelectors.getSectionList);
    const newSectionList = sectionList.filter(item => item.id != sectionId); // eslint-disable-line

    yield put(SectionActions.deactivateSectionSuccess(sectionId));

    if (newSectionList.length === 0) {
      yield put(push('/register/teacher'));
    } else {
      const firstSectionId = newSectionList[0];
      const { course: courseId, id } = firstSectionId;
      yield put(push(`/dashboard/course/${courseId}/section/${id}/summary`));
    }
    success('Deactivate Section Successfully');
  } catch (error) {
    const message = Array.isArray(error) ? _get(error, '[0]', 'Unexpected error') : error;
    errorToast(message);
    yield put(SectionActions.deactivateSectionFail(error));
  }
}

function* sectionsAttendanceGet({ sectionId }) {
  const { response, error } = yield call(SectionAPI.getSectionAttendance, sectionId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsAttendanceSuccess(data));
  } else {
    yield put(SectionActions.sectionsAttendanceFail(error));
  }
}

function* sectionsAttendanceGetSummary({ sectionId }) {
  const { response, error } = yield call(SectionAPI.getSectionAttendanceSummary, sectionId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsAttendanceGetSummarySuccess(data));
  } else {
    yield put(SectionActions.sectionsAttendanceGetSummaryFail(error));
  }
}

function* getSectionEstimatedScores({ sectionId }) {
  const { response, error } = yield call(SectionAPI.getSectionEstimatedScores, sectionId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsEstimatedGetSuccess(data));
  } else {
    yield put(SectionActions.sectionsEstimatedGetFail(error));
  }
}

function* sectionsEstimatedStudentGet({ sectionId }) {
  const userId = yield select(userSelectors.getUserId);
  const { response, error } = yield call(SectionAPI.getSectionStudentEstimatedScores, sectionId, userId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsEstimatedStudentGetSuccess(data));
  } else {
    yield put(SectionActions.sectionsEstimatedStudentGetFail(error));
  }
}

function* getSectionExamsScores({ sectionId }) {
  const { response, error } = yield call(SectionAPI.getSectionExamsScores, sectionId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsExamsScoresGetSuccess(data));
  } else {
    yield put(SectionActions.sectionsExamsScoresGetFail(error));
  }
}

function* getSectionPracticeSummary({
  sectionId, unitId, lessonId, subjectId,
}) {
  if(sectionId > 0) {
    const { response, error } = yield call(SectionAPI.getSectionPracticeSumary, sectionId, unitId, lessonId, subjectId);
    if (response) {
      const { data } = response;
      yield put(SectionActions.sectionsPracticeSummaryGetSuccess(data));
    } else {
      yield put(SectionActions.sectionsPracticeSummaryGetFail(error));
    }
  }
}

function* sectionsStudentPracticeSummaryGet({
  sectionId, unitId, lessonId, subjectId,
}) {
  const { response, error } = yield call(SectionAPI.getUserSectionPracticeSumary, sectionId, lessonId, unitId, subjectId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.sectionsStudentPracticeSummaryGetSuccess(data));
  } else {
    yield put(SectionActions.sectionsStudentPracticeSummaryGetFail(error));
  }
}

function* sectionAttendanceSubmit({ sectionId, sectionAttendanceInfo }) {
  const userId = yield select(userSelectors.getUserId);
  const attendanceArrays = Object.values(sectionAttendanceInfo)
    .map((attendance) => ({
      ...attendance,
      section: sectionId,
      recorded_by: userId,
      records: Object.values(attendance.records),
    }));
  const { response, error } = yield call(SectionAPI.submitSectionAttendance, sectionId, attendanceArrays);
  if (!error) {
    const { data } = response;
    yield put(SectionActions.sectionAttendanceSubmitSuccess(data));
    yield put(SectionActions.sectionsAttendanceGetSummary(sectionId));
  } else {
    yield put(SectionActions.sectionAttendanceSubmitFail(error));
  }
}

function* updateSectionComplete({ payload: { sectionId, payload } }) {
  const { error } = yield call(
    SectionAPI.updateSectionsComplete,
    sectionId,
    payload
  );
  if (error) {
    if (Array.isArray(error)) {
      errorToast(error[0]);
      return
    }
    const message = _get(error, "response.data.detail", "Unexpected error");
    errorToast(`${message}`);
    return;
  }
}

function* updateSectionUnComplete({ payload: { sectionId, payload } }) {
  const { error } = yield call(
    SectionAPI.updateSectionsUnComplete,
    sectionId,
    payload
  );
  if (error) {
    if (Array.isArray(error)) {
      errorToast(error[0]);
      return
    }
    const message = _get(error, "response.data.detail", "Unexpected error");
    errorToast(`${message}`);
    return;
  }
}

function* updateSectionUnits({ payload: { sectionId } }) {
  const { response } = yield call(SectionAPI.getSectionUnit, sectionId);
  if (response) {
    const { data: unitTreeList } = response;

    const allUnit = _reduce(unitTreeList, (prevValue, subject) => {
      const { units } = subject;
      const allUnitsOfSubject = splitUnitChildren(units, []);

      return [
        ...prevValue,
        ...allUnitsOfSubject,
      ];
    }, []);

    const allLesson = _reduce(unitTreeList, (prevValue, subject) => {
      const { units } = subject;
      const allLessonOfSubject = splitLessonChildren(units, []);

      return [
        ...prevValue,
        ...allLessonOfSubject,
      ];
    }, []);

    yield put(SectionUnitActions.sectionUnitGetSuccess(unitTreeList, allUnit, sectionId));
    yield put(curriculumActions.curriculumLessonGetWithUnit(allLesson));
  }
}

function* getAvailablePackages({payload}) {
  const {sectionId} = payload;
  const { response, error } = yield call(SectionAPI.getAvailablePackages, sectionId);
  if (response) {
    const { data } = response;
    yield put(SectionActions.getAvailablePackagesSuccess(data));
  } else {
    yield put(SectionActions.getAvailablePackagesFail(error));
  }
}
export default function* sectionsSaga() {
  yield takeLatest(SECTIONS_UPDATE, sectionsUpdate);
  yield takeLatest(SECTIONS_GET_DETAIL, sectionsGetDetail);
  yield takeLatest(SECTIONS_GET_ALL_BY_COURSE, getAllSectionByCourse);
  yield takeLatest(COURSES_SELECT_COURSE, fetchSectionFromCourseId);
  yield takeLatest(SECTIONS_CREATE_NEW, handleCreateNewSection);
  yield takeLatest(SECTIONS_GET_ON_START, sectionGetOnStart);
  yield takeLatest(SECTIONS_REQUEST_ENROLLMENT, handleSectionEnrollment);
  yield takeLatest(DEACTIVATE_SECTION, handleDeactivateSection);
  yield takeLatest(SECTIONS_ATTENDANCE_GET, sectionsAttendanceGet);
  yield takeLatest(SECTIONS_ATTENDANCE_GET_SUMMARY, sectionsAttendanceGetSummary);
  yield takeLatest(SECTIONS_ESTIMATED_GET, getSectionEstimatedScores);
  yield takeLatest(SECTIONS_ESTIMATED_STUDENT_GET, sectionsEstimatedStudentGet);
  yield takeLatest(SECTIONS_EXAMS_SCORES_GET, getSectionExamsScores);
  yield takeLatest(SECTIONS_PRACTICE_SUMMARY_GET, getSectionPracticeSummary);
  yield takeLatest(SECTIONS_USER_PRACTICE_SUMMARY_GET, sectionsStudentPracticeSummaryGet);
  yield takeLatest(SECTION_ATTENDANCE_SUBMIT, sectionAttendanceSubmit);
  yield takeLatest(SECTION_UPDATE_COMPLETE, updateSectionComplete);
  yield takeLatest(SECTION_UPDATE_UN_COMPLETE, updateSectionUnComplete);
  yield takeLatest(UPDATE_SECTION_UNITS, updateSectionUnits);
  yield takeLatest(COURSE_GET_ALL_SECTIONS_LIST, getAllSectionsByCourse);
  yield takeLatest(SECTION_GET_AVAILABLE_PACKAGES, getAvailablePackages);
}
