import React from 'react';
import PropTypes from 'prop-types';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import PracticePdfViewer from '../PracticePdfViewer';
import OfflineTestAnswerSheet from '../OfflineTestAnswerSheet';
import { OfflineTestWrapper } from './OfflineTestContent.style';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

class OfflineTestContent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAnswerSheetOpen: false,
    };
  }

  toggleAnswerSheet = () => {
    this.setState((prevState) => ({
      isAnswerSheetOpen: !prevState.isAnswerSheetOpen,
    }));
  };

  render() {
    const { pdfSource, questions = [], completeTest, examChooseAnswer } = this.props;
    const { isAnswerSheetOpen } = this.state;
    return (
      <ShadowScrollbar autoHide style={scrollStyle}>
        <OfflineTestWrapper>
          <PracticePdfViewer
            pdfSource={pdfSource}
            isAnswerSheetOpen={isAnswerSheetOpen}
          />
          <OfflineTestAnswerSheet
            examChooseAnswer={examChooseAnswer}
            questions={questions}
            completeTest={completeTest}
            toggleAnswerSheet={this.toggleAnswerSheet}
            isAnswerSheetOpen={isAnswerSheetOpen}
          />
        </OfflineTestWrapper>
      </ShadowScrollbar>
    );
  }
}

OfflineTestContent.propTypes = {
  pdfSource: PropTypes.string,
  questions: PropTypes.array,
  completeTest: PropTypes.func,
};

export default OfflineTestContent;
