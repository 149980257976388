import React, { Suspense, useCallback, useMemo } from 'react';
import { Popover } from '@mui/material';
import Box from '@components/Atoms/Box';
import { StandardRecord } from '@CT-Types/lessonDomain';
import MultiSelectTree from '@components/Atoms/MultiSelectTree';
import HighlightSelector from './HighlightSelector';
import { useContentFilterStandardContext } from '../ContentFilterProvider';
import { buildTags, Map } from '../utils';

const popOverSlotProps = {
  paper: {
    sx: {
      width: 'auto',
      maxHeight: 400,
      mt: 0.5,
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
  },
};

const StandardPopover = ({ open, anchor, onClose, disabled }) => {
  const {
    selectedStandards,
    priorityStandards,
    recommendedStandards,
    manualSelectedStandards,
    removedStandards,
    setRemovedStandards,
    setSelectedStandards,
    setManuallySelectedStandards,
    getStandardTree,
    getStandardById,
  } = useContentFilterStandardContext();

  const getRecommendedStandardObjects = useMemo(
    () => () => {
      const priorityIds = Object.keys(priorityStandards).map(Number);
      const recommendedIds = Object.keys(recommendedStandards).map(Number);
      const standardsToHighlight = [
        ...priorityIds,
        ...recommendedIds.filter((id) => !priorityIds.includes(id)),
      ];
      return standardsToHighlight
        .filter((id) => !selectedStandards.includes(id))
        .map((id) => getStandardById(id))
        .filter(Boolean);
    },
    [getStandardById, priorityStandards, recommendedStandards, selectedStandards],
  );

  // when a user selects a recommended standard, add it to the selected standards
  // and keep track of the manual selections separately
  const handleSelectRecommended = (id: number) => {
    setManuallySelectedStandards([...manualSelectedStandards, id]);
    setSelectedStandards([...selectedStandards, id]);
    setRemovedStandards(removedStandards.filter((removedId) => removedId !== id));
  };

  // when the user changes the selected standards, update the selectedStandards and manualSelectedStandards
  const handleStandardChange = (standards: number[]) => {
    const newStandards = standards.filter(
      (id) => !manualSelectedStandards.includes(id) && !selectedStandards.includes(id),
    );

    const existingManual = manualSelectedStandards.filter((id) => standards.includes(id));
    const removed = selectedStandards.filter((id) => !standards.includes(id));

    setManuallySelectedStandards([...existingManual, ...newStandards]);
    setSelectedStandards(standards);

    // only keep record if a single standard was removed at a time
    if (removed.length === 1) {
      setRemovedStandards([...removedStandards, ...removed]);
    }
  };

  const getStandardTags = useCallback(
    () =>
      buildTags('standard', {
        auto: {
          source: 'Lessons',
          data: priorityStandards,
        },
        recommend: recommendedStandards,
      }),
    [priorityStandards, recommendedStandards],
  );

  return (
    <Popover
      id="standard-filter-popover"
      open={open}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      slotProps={popOverSlotProps}
    >
      <Box sx={{ p: 1, width: 'auto', minWidth: 'auto', maxWidth: '60rem' }}>
        <Suspense fallback={<Box sx={{ p: 2 }}>Loading...</Box>}>
          <HighlightSelector
            items={getRecommendedStandardObjects()}
            getItemId={(standard: StandardRecord) => standard.id}
            getItemLabel={(standard: StandardRecord) =>
              `${standard.code}: ${standard.description}`
            }
            primaryText="Based on the selected Lesson(s), here are ChalkTalk's recommended Standards:"
            secondaryText="Currently Selected Standards (Click to add more):"
            onSelect={(standard: StandardRecord) =>
              handleSelectRecommended(Number(standard.id))
            }
            disabled={disabled}
            tags={getStandardTags()}
          >
            <MultiSelectTree
              tree={getStandardTree()}
              onChange={handleStandardChange}
              placeholder="Select Standards..."
              selected={selectedStandards}
              onClose={onClose}
              disabled={disabled}
              tags={getStandardTags()}
            />
          </HighlightSelector>
        </Suspense>
      </Box>
    </Popover>
  );
};

export default StandardPopover;
