import {
  call, put, takeLeading,
  takeEvery, select,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { error as errorToast, success } from '../../utils/toast';
import * as SectionAPI from '../../apis/sections';

import {
  SECTIONS_STUDENT_REMOVE,
  SECTIONS_STUDENT_GET,
  SECTIONS_STUDENT_INVITE,
  SECTIONS_STUDENT_MOVE,

  actions as SectionStudentActions,
  selectors as SectionStudentSelectors,
} from '../../reducers/sectionStudent';

/**
 * ! Need update callback
 */
function* sectionsStudentRemove({ sectionId, studentId }) {
  const { response, error } = yield call(SectionAPI.removeStudentFromSection, sectionId, studentId);

  if (response) {
    yield put(SectionStudentActions.sectionsStudentRemoveSuccess(sectionId, studentId));
  } else {
    yield put(SectionStudentActions.sectionsStudentRemoveFail(error));
  }
}

/**
 * ! Need update callback
 */
function* sectionsStudentInvite({ studentInfoList, sectionId, callback }) {
  try {
    const { response, error } = yield call(SectionAPI.inviteStudent, studentInfoList, sectionId);
    if (error) throw error;

    const { data } = response;
    success('Invite student success');
    yield put(SectionStudentActions.sectionsStudentInviteSuccess(data, sectionId));

    if (callback) {
      callback();
    }
  } catch (error) {
    errorToast('You have reached the maximum limit of students in this course');
    yield put(SectionStudentActions.sectionsStudentInviteFail(error.message || error));
  }
}

/**
 * ! Need update callback
 */
function* sectionsStudentGet({ sectionId }) {
  const shouldFetch = yield select(SectionStudentSelectors.getShouldFetch, sectionId);
  if (!shouldFetch) {
    return;
  }

  const { response, error } = yield call(SectionAPI.getSectionStudent, sectionId);

  if (response) {
    const { data = [] } = response;
    const sortStudentList = _orderBy(data, [(s) => s.first_name.toUpperCase()]);

    yield put(SectionStudentActions.sectionsStudentGetSuccess(sortStudentList, sectionId));
  } else {
    yield put(SectionStudentActions.sectionsStudentGetFail(error));
  }
}

function* sectionsStudentMove({ sectionId, payload, callback }) {
  const { error } = yield call(SectionAPI.moveStudentToSection, sectionId, payload);

  if (error) {
    const message = Array.isArray(error) ? _get(error, '[0]', 'Unexpected error') : error;
    yield put(SectionStudentActions.sectionsStudentMoveFail(message));
  } else {
    yield put(SectionStudentActions.sectionsStudentMoveSuccess(payload, sectionId));
    success('Move students successfully');

    if (callback) {
      callback();
    }
  }
}

export default function* sectionStudentSaga() {
  yield takeEvery(SECTIONS_STUDENT_REMOVE, sectionsStudentRemove);
  yield takeLeading(SECTIONS_STUDENT_GET, sectionsStudentGet);
  yield takeEvery(SECTIONS_STUDENT_INVITE, sectionsStudentInvite);
  yield takeEvery(SECTIONS_STUDENT_MOVE, sectionsStudentMove);
}
