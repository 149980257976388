import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import ReactDOM from 'react-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReviewQuestion from './ReviewQuestion';

class ReviewQuestionContainers extends React.PureComponent {
  state = {
    questionSelected: 0,
    shouldDisplayGrammarHelper: false,
    isLightBoxOpen: false,
    imgSrc: '',
  };

  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener(
      'click',
      this.handleClick,
      false,
    ); // eslint-disable-line
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener(
      'click',
      this.handleClick,
      false,
    ); // eslint-disable-line
  }

  openLightBox = (imgSrc) => {
    this.setState({
      isLightBoxOpen: true,
      imgSrc,
    });
  };

  closeLightbox = () => {
    this.setState({
      isLightBoxOpen: false,
      imgSrc: '',
    });
  };

  handleClick = (e) => {
    const { isLightBoxOpen } = this.state;
    const targetTagName = _get(e, 'target.tagName', '');

    if (targetTagName === 'IMG' && !isLightBoxOpen) {
      const src = _get(e, 'target.src', '');
      this.openLightBox(src);
    }
  };

  displayGrammarHelper = (shouldDisplayGrammarHelper) => {
    this.setState({
      shouldDisplayGrammarHelper,
    });
  };

  nextQuestion = () => {
    this.setState((prevState) => ({
      questionSelected: prevState.questionSelected + 1,
    }));
  };

  prevQuestion = () => {
    this.setState((prevState) => ({
      questionSelected: prevState.questionSelected - 1,
    }));
  };

  render() {
    const {
      questionSelected,
      shouldDisplayGrammarHelper,
      isLightBoxOpen,
      imgSrc,
    } = this.state;
    const { questions } = this.props;
    const totalQuestions = questions.length;

    if (!totalQuestions) {
      return null;
    }

    const allProps = {
      ...this.props,
      questionSelected,
      shouldDisplayGrammarHelper,
      displayGrammarHelper: this.displayGrammarHelper,
      nextQuestion: this.nextQuestion,
      prevQuestion: this.prevQuestion,
    };

    return (
      <React.Fragment>
        <ReviewQuestion {...allProps} />
        {isLightBoxOpen && <Lightbox mainSrc={imgSrc} onCloseRequest={this.closeLightbox} />}
      </React.Fragment>
    );
  }
}

ReviewQuestionContainers.propTypes = {
  questions: PropTypes.array,
  isPracticeNavigation: PropTypes.bool,
  openReportErrorModal: PropTypes.func,
};

export default ReviewQuestionContainers;
