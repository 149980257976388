import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import _isEqual from 'lodash/isEqual';
import { Calendar, momentLocalizer, Navigate } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import ExamSectionsWrapper from '@components/Component/ExamSections';
import CustomToolbar from './CustomToolbar';
import MainContent from '../../containers/MainContent';
import * as SectionAPI from '../../apis/sections';
import * as SessionsAPI from '../../apis/sessions';
import * as ExamAPI from '../../apis/exam';
import { actions as SectionScheduleActions } from '../../reducers/sectionSchedule';
import { CalendarWrap } from './Calendar.style';
import CombinedCalendar from './CombinedCalendar';
import { PrimaryWrap } from '../Generals/stylesheets/General.style';
import LeftSideBarSchedule from '../../containers/LeftSideBar/LeftSideBarSchedule';
import { InfoModal } from '../../containers/ScheduleCalendar/Modals';
import PremiumOnlyWarning from '../PremiumOnlyWarning';
import history from '../../utils/history';

const localizer = momentLocalizer(moment);

const DEFAULT_VIEW = 'two-week';

const calendarViewNavigate = (date, action, activeView, startDate, endDate) => {
  const subNumber = activeView === 'two-week' ? 2 : 1;
  const subDate = activeView === 'week' || activeView === 'two-week' ? 'week' : 'month';

  switch (action) {
    case Navigate.PREVIOUS:
      return moment(date).subtract(subNumber, subDate).toDate();

    case Navigate.NEXT:
      return moment(date).add(subNumber, subDate).toDate();

    case 'START':
      return moment(startDate).toDate();

    case 'END':
      return moment(endDate).toDate();

    default:
      return date;
  }
};

let mobileDayRangeFormat = ({ start, end }, culture, local) =>
  `${local.format(start, 'MMM DD', culture)} – ${local.format(
    end,
    moment(start).isSame(end, 'month') ? 'DD' : 'MMM DD',
    culture,
  )}`;

const calendarViewRange = (date, activeView) => {
  let start;
  let end;
  if (activeView === 'week' || activeView === 'two-week') {
    start = moment(date).startOf('week');
    end = moment(date).endOf('week');
  } else if (activeView === 'month') {
    start = moment(date).startOf('month').startOf('week');
    end = moment(date).endOf('month').endOf('week');
  }

  const days = [];
  const current = start;

  if (activeView === 'two-week') {
    end.add(1, 'week');
  }

  while (current.isSameOrBefore(end)) {
    days.push(current.toDate());
    current.add(1, 'day');
  }

  return days;
};

const calendarViewTitle = (date, { localizer }, activeView) => {
  const [start, ...rest] = calendarViewRange(date, activeView);
  return activeView === 'month'
    ? localizer.format(date, 'monthHeaderFormat')
    : localizer.format({ start, end: rest.pop() }, 'dayRangeHeaderFormat');
};

const calendarViewTitleMobile = (date, { localizer }, activeView) => {
  const [start, ...rest] = calendarViewRange(date, activeView);
  return activeView === 'month'
    ? localizer.format(date, 'monthHeaderFormat')
    : localizer.format({ start, end: rest.pop() }, mobileDayRangeFormat);
};

export const withCalendarType = (
  props,
  CustomComponent,
  titleGenerator = calendarViewTitle,
) => {
  const innerFunc = (calendarProps) => <CustomComponent {...props} {...calendarProps} />;
  innerFunc.title = (date, { localizer }) =>
    titleGenerator(date, { localizer }, props.activeView);
  innerFunc.navigate = (date, action) =>
    calendarViewNavigate(date, action, props.activeView, props.startDate, props.endDate);
  innerFunc.range = (date) => calendarViewRange(date, props.activeView);
  return innerFunc;
};

// main page for schedule
class ScheduleCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: null,
      editScheduleList: [],
      updatedScheduleList: [],
      deleteScheduleList: [],
      allScheduleList: this.props.scheduleList || [],
      updating: false,
      lessonsUpdates: null,
      new_events: [],
      startRenderExamCards: false,
      selectedSubjectId: null,
    };
  }

  componentDidMount() {
    const sectionId = this.props?.match?.params?.sectionId || '';

    if (sectionId) {
      this.getInitialData(sectionId);
    }
    this.props.changeEditMode(true);
  }

  async componentDidUpdate(prevProps) {
    const sectionId = this.props?.match?.params?.sectionId || '';
    const prevSectionId = prevProps?.match?.params?.sectionId || '';

    if (!_isEqual(sectionId, prevSectionId) && sectionId) {
      this.getInitialData(sectionId);
    }
    if (!_isEqual(prevProps.scheduleList, this.props.scheduleList)) {
      this.setState({
        allScheduleList: this.props.scheduleList,
      });
    }
    if (
      this.props.dataFromCalendar.length &&
      !_isEqual(this.props.dataFromCalendar, prevProps.dataFromCalendar)
    ) {
      let lessonsUpdates;
      this.props.dataFromCalendar.map((item) => {
        if (item.payload.type === 'lessons') {
          item.payload.actionType = 'edit';
          lessonsUpdates = this.addToUpdatesList(item.payload, true);
        } else {
          lessonsUpdates = item;
        }
      });
      let item = lessonsUpdates;
      if (item.type === 'lessons') {
        switch (item.actionType) {
          case 'edit':
            await SessionsAPI.updateSessionStandard(item.session, item);
            break;
          case 'add':
            await SessionsAPI.addSessionStandard(item);
            break;
          case 'delete':
            await SessionsAPI.deleteSessionStandard(item.session);
            break;
        }
      } else if (item.type === 'EDIT_EXAMS') {
        item.payload.start_date = item.payload.date;
        item.payload.end_date = item.payload.date;
        await ExamAPI.updateSectionOfExam(
          item.payload.id,
          item.payload.sectionExamId,
          item.payload,
        );
      } else if (item.type === 'EDIT_REVIEW') {
        await SectionAPI.updateReviewSessionData(item.payload.id, item.payload);
      }
    }
  }
  getInitialData(sectionId) {
    this.props.sectionScheduleGet(sectionId);
    this.props.fetchSectionSubjectList(sectionId);
    this.props.sectionUnitGet(sectionId);
  }

  renderCorrectContent = (scheduleList, isLoadingData) => {
    const date = history.location.state?.date;
    if (date) {
      let state = { ...history.location.state };
      delete state.date;
      history.replace({ ...history.location, state });
    }

    if (this.state.updating) {
      this.setState({ updating: false });
    }
    const filteredScheduleList = scheduleList.filter(
      (session) => session.activity_type !== 2,
    );
    filteredScheduleList.sort((a, b) => moment(a.date).diff(moment(b.date)));
    filteredScheduleList.sort((a, b) => {
      if (moment(a.date).isSame(moment(b.date))) {
        if (a.id < b.id) {
          return -1;
        }
      }
      return 0;
    });
    const startDate = !isLoadingData ? filteredScheduleList[0].date : new Date();
    const endDate = !isLoadingData
      ? filteredScheduleList[filteredScheduleList.length - 1].date
      : new Date();
    const CustomToolbarWrap = (props) => (
      <CustomToolbar
        {...props}
        editMode={this.state.editMode}
        isLoadingData={isLoadingData}
      />
    );
    return (
      <MediaQuery maxWidth={768}>
        {(matches) => (
          <CalendarWrap>
            <Calendar
              views={{
                month: withCalendarType(
                  { activeView: 'month', startDate, endDate },
                  CombinedCalendar,
                ),
                week: withCalendarType(
                  { activeView: 'week', startDate, endDate },
                  CombinedCalendar,
                  matches ? calendarViewTitleMobile : calendarViewTitle,
                ),
                'two-week': withCalendarType(
                  { activeView: 'two-week', startDate, endDate },
                  CombinedCalendar,
                  matches ? calendarViewTitleMobile : calendarViewTitle,
                ),
              }}
              onView={(activeView) => {
                this.setState({
                  activeView,
                });
              }}
              editMode={this.state.editMode}
              resizable
              selectable
              showMultiDayTimes
              defaultView={DEFAULT_VIEW}
              defaultDate={date || new Date()}
              events={filteredScheduleList}
              localizer={localizer}
              components={{ toolbar: CustomToolbarWrap }}
            />
          </CalendarWrap>
        )}
      </MediaQuery>
    );
  };

  renderFakeContent = (scheduleList) => (
    <MediaQuery maxWidth={768}>
      {(matches) => (
        <CalendarWrap>
          <Calendar
            views={{
              month: withCalendarType({ activeView: 'month' }, CombinedCalendar),
              week: withCalendarType(
                { activeView: 'week' },
                CombinedCalendar,
                matches ? calendarViewTitleMobile : calendarViewTitle,
              ),
              'two-week': withCalendarType(
                { activeView: 'two-week' },
                CombinedCalendar,
                matches ? calendarViewTitleMobile : calendarViewTitle,
              ),
            }}
            onView={(activeView) => {
              this.setState({
                activeView,
              });
            }}
            resizable
            selectable
            showMultiDayTimes
            defaultView={DEFAULT_VIEW}
            defaultDate={new Date()}
            events={scheduleList}
            localizer={localizer}
            components={{ toolbar: CustomToolbar }}
            tempScheduleList
          />
        </CalendarWrap>
      )}
    </MediaQuery>
  );

  updateScheduleList = (item, action) => {
    const stateUpdate = {};
    const sectionId = this.props.match?.params?.sectionId || '';
    if (action === 'edit') {
      stateUpdate.editScheduleList = [...this.state.editScheduleList, item];
    } else if (action === 'delete') {
      stateUpdate.deleteScheduleList = [...this.state.deleteScheduleList, item];
      stateUpdate.allScheduleList = this.state.allScheduleList.filter(
        (v) => v.id !== item.id,
      );
    } else if (action === 'add') {
      stateUpdate.updatedScheduleList = [...this.state.updatedScheduleList, item];
      stateUpdate.allScheduleList = [...this.state.allScheduleList, item];
      this.props.sectionScheduleGet(sectionId);
    }
    this.setState(stateUpdate);
  };

  /*
    # That's For Lesson's
  */

  addToUpdatesList(scheduleItem, fromCalendar) {
    if (fromCalendar) {
      scheduleItem.session = scheduleItem.id;
    }
    return scheduleItem;
  }

  render() {
    const { allScheduleList } = this.state;
    const { sectionScheduleGet } = this.props;
    const sectionId = this.props.match?.params?.sectionId || '';
    let activeList = allScheduleList;
    const completedLessons = activeList.map((ele) => ele.lesson);
    let newList = activeList;

    if (!this.state.startRenderExamCards) {
      const names = [];
      newList = activeList.reduce((list, event) => {
        let new_event = {};
        let name = '';
        let names_together = '';
        let test_name = 'Full length Practice Test'
        if (event.type === 'exams') {
          if (event.session_sittings_dates.length > 0) {
            event.session_metadata.sections.forEach((section) => {
              new_event = { ...event };
              new_event.sectionExamId = section.section_id;
              new_event.start_date = section.section_date;
              new_event.end_date = section.section_date;
              new_event.date = section.section_date;
              name = `${test_name}-${section.section_name}`;
              names_together = `${event.id}${section.section_id}`;
              if (!names.includes(names_together)) {
                new_event.title = name;
                names.push(names_together);
                list.push(new_event);
              }
            });
          } else {
            event.exam.sections.forEach((section) => {
              new_event = { ...event };
              new_event.sectionExamId = section.id;
              new_event.start_date = event.date;
              new_event.end_date = event.date;
              new_event.date = event.date;
              name = `${test_name}-${section.name}`;
              names_together = `${event.id}${section.id}`;
              if (!names.includes(names_together)) {
                new_event.title = name;
                names.push(names_together);
                list.push(new_event);
              }
            });
          }
        } else {
          list.push(event);
        }
        return list;
      }, []);
    }
    this.props.dataFromCalendar.map((item) => {
      item.payload.actionType = 'edit';
      if (item.payload.type === 'lessons') this.addToUpdatesList(item.payload, true);
      newList = newList.map((v) => {
        if (
          item.payload.type === 'exams' &&
          v.title === item.payload.title &&
          v.id === item.payload.id
        ) {
          return item.payload;
        } else if (item.payload.type !== 'exams' && v.id === item.payload.id) {
          return item.payload;
        } else {
          return v;
        }
      });
    });

    if (!sectionId) {
      return (
        <PrimaryWrap>
          <LeftSideBarSchedule
            scheduleList={newList}
            curriculumId={this.props.curriculumId}
            sectionDetail={this.props.sectionDetail}
            pathName="/schedule/course"
            isTeacher={this.props.isTeacher}
            updateScheduleList={this.updateScheduleList}
          />
          <MainContent>Please create a section to continue</MainContent>
        </PrimaryWrap>
      );
    }
    const isLoadingData = newList.length === 0;
    return (
      <React.Fragment>
        <PrimaryWrap>
          <ExamSectionsWrapper>
            <LeftSideBarSchedule
              scheduleList={newList}
              updateScheduleList={this.updateScheduleList}
              curriculumId={this.props.curriculumId}
              sectionDetail={this.props.sectionDetail}
              pathName="/schedule/course"
              isTeacher={this.props.isTeacher}
              completedLessons={completedLessons}
              selectedSubject={this.state.selectedSubjectId}
              sectionScheduleGet={sectionScheduleGet}
              onSelectSubject={(selectedSubjectId) => {
                this.setState({ selectedSubjectId });
              }}
              editMode={true}
            />
          </ExamSectionsWrapper>
          <MainContent>
            {this.props.isLimited && <PremiumOnlyWarning />}
            {!this.props.isLimited && this.renderCorrectContent(newList, isLoadingData)}
            {this.props.isLimited && this.renderFakeContent(newList)}
          </MainContent>
          <InfoModal />
        </PrimaryWrap>
      </React.Fragment>
    );
  }
}

ScheduleCalendar.propTypes = {
  sectionScheduleGet: PropTypes.func.isRequired,
  match: PropTypes.shape(),
  scheduleList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  closeCreateFormModal: PropTypes.func,
  isCreateFormOpen: PropTypes.bool,
  history: PropTypes.any,
  fetchSectionSubjectList: PropTypes.func,
  isLimited: PropTypes.any,
  btata: PropTypes.string,
  isTeacher: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  // the data stored as string so we parse it here
  dataFromCalendar: state.sectionSchedule.dataFromCalendar.map((item) =>
    JSON.parse(item),
  ),
});
const mapDispatchToProps = {
  changeEditMode: SectionScheduleActions.editModeCheck,
  refreshDataFromCalendar: SectionScheduleActions.refreshDataFromCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalendar);
