/* eslint-disable camelcase */
import React from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import Typography from '@components/Atoms/Typography';
import {
  QuestionGenerationServiceMenu,
  LearnosityItem,
} from '@components/QuestionGenerationService/types';
import {
  useContentFilterContext,
  useContentFilterSkillContext,
  useContentFilterStandardContext,
} from '@components/ContentFilters/ContentFilterProvider';
import { BLOOMS_MAP } from '@components/Selectors/BloomsSelector/BloomsSelector';
import { DOK_MAP } from '@components/Selectors/DokSelector/DokSelector';

interface SubmissionListProps {
  state: QuestionGenerationServiceMenu;
  items: LearnosityItem[];
}

const SubmissionList: React.FC<SubmissionListProps> = ({ state, items }) => {
  const { selectedBlooms, selectedDok } = useContentFilterContext();
  const { getSelectedStandardObjects } = useContentFilterStandardContext();
  const { getSelectedSkillObjects } = useContentFilterSkillContext();

  const standards = getSelectedStandardObjects();
  const skills = getSelectedSkillObjects();

  return (
    <List>
      <ListItem key="subject">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Subject</Typography>
              <Typography variant="body2">
                {state.subject.display_name as string}
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem key="standard">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Standards</Typography>
              <Typography variant="body2">
                {standards.map((s) => s.code).join(', ')}
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem key="skill">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Skills</Typography>
              <Typography variant="body2">
                {skills.map((s) => s.name).join(', ')}
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem key="blooms">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Blooms</Typography>
              <Typography variant="body2">
                {selectedBlooms ? BLOOMS_MAP[selectedBlooms] : ''}
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem key="dok">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Depth of Knowledge</Typography>
              <Typography variant="body2">
                {selectedDok ? DOK_MAP[selectedDok] : ''}
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem key="generated">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Added Generated Questions</Typography>
              <Typography variant="body2">
                {items.filter((i) => !i.is_custom).length}
              </Typography>
            </>
          }
        />
      </ListItem>
      <ListItem key="custom">
        <ListItemAvatar>
          <Avatar>
            <FolderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <>
              <Typography variant="subtitle1">Added Custom Questions</Typography>
              <Typography variant="body2">
                {items.filter((i) => i.is_custom).length}
              </Typography>
            </>
          }
        />
      </ListItem>
    </List>
  );
};

export default SubmissionList;
