import React from 'react';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  SxProps,
  Theme,
} from '@mui/material';
import { sxCombine } from '../utiles';
import { useInteractionContext } from '@components/ElementAnalytics/InteractionContext';

export interface ButtonProps {
  /** - Children for the Button component. */
  children: React.ReactNode;
  /** - UpperCase flag to show the text in UpperCase */
  uppercase?: boolean;
  /** - Variant prop to show the button in different style */
  variant?: MuiButtonProps['variant'];
  /** - Color prop to show the button in different colors from MUI Theme */
  color?: MuiButtonProps['color'] | 'play';
  /** - Size prop to show the button in different Sizes */
  size?: MuiButtonProps['size'];
  /** - fullWidth flag prop give button component full width */
  fullWidth?: MuiButtonProps['fullWidth'];
  /** - Additional CSS properties to apply to the `Button` component. */
  sx?: SxProps<Theme> | any;
  /** - Disabled flag to apply disabled state to the `Button` component. */
  disabled?: MuiButtonProps['disabled'];
  /** - endIcon prop to add `Icon` after the `Button` component. */
  endIcon?: MuiButtonProps['endIcon'];
  /** - startIcon prop to add `Icon` before the `Button` component. */
  startIcon?: MuiButtonProps['startIcon'];
  /** - Onclick handler function for the `Button` component. */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** - test Id prop for the `Button` component. */
  dataTestId?: string;
}

/**
 * A wrapper component for Material-UI's `Button` with additional customization options.
 * @returns {React.ReactNode} The rendered `Button` component.
 */

const Button = ({
  children,
  uppercase,
  dataTestId,
  sx = {} as SxProps<Theme> | any,
  ...rest
}: ButtonProps) => {
  const { getTestId } = useInteractionContext();
  const testId = dataTestId || getTestId(children);
  return (
    <MuiButton
      data-testid={testId}
      sx={sxCombine(
        !!uppercase && {
          textTransform: 'uppercase',
        },
        sx,
      )}
      {...rest}
    >
      {children}
    </MuiButton>
  )
};

export default Button;
