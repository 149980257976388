import styled from 'styled-components';

export const GroupSectionWrapper = styled.div`
  width: 100%;
  opacity: ${(props) => (props.loading ? 0.5 : 1)};
  min-width: fit-content;

  @media (max-width: 400px) {
    width: min-content;
  }
  .student-names {
    padding-top: unset;
    padding-bottom: unset;
    margin-top: 10px;
    ul {
      width: 100%;
      height: 56px;
      border-bottom: 1px solid #ebeced;
      &:last-child {
        border-bottom: unset;
      }
    }
  }
`;

export const GroupSection = styled.div`
  padding: 14px 26px;
  border-radius: 0.5rem;
  width: 100%;
  min-width: fit-content;
  background: #ffffff;
  box-shadow: 0px 0px 8px #0000000d;
  display: flex;
  flex-direction: column;
  .student-name:hover {
    cursor: move;
  }
  .group-progress {
    width: 25% !important;
    align-items: center;
    justify-content: center;
    flex: ${({ flex }) => flex}%;
    li {
      flex-basis: 100% !important;
    }
  }
  .header-progress {
    border-bottom: unset !important;
  }
  ul {
    display: flex;
    width: 100%;
    li {
      /* flex-basis: 25%; */
      &:first-child {
        flex-basis: 10%;
        flex-grow: 0;
        flex-shrink: 1;
      }
      p {
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      cursor: inherit;
      font-size: 1em;
      flex-basis: ${({ flex }) => flex}%;
      flex-grow: 1;
      flex-shrink: unset;
      border-right: unset;
    }
  }
  .group-number-cell {
    display: flex;
    flex-basis: 10%;
    justify-content: center;
    align-items: center;
  }
  .table-cell {
    display: flex;
    flex-basis: ${({ flex }) => flex}%;
    flex-grow: 1;
  }
`;

export const GroupActivityContainer = styled.div`
  height: calc(100% - 3.5em);
  overflow-y: auto;
`;

export const selectWrapper = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginInlineEnd: { xs: 'unset', sm: 'unset', md: '3.75rem' },
  width: '100%',
  flexDirection: { xs: 'column', sm: 'column', md: 'row' },
  mb: '1rem',
};
