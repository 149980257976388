import EventEmitter, { MessageFn as defaultMessageFnType } from '@utils/eventEmitter';

export enum QuestionGenerationServiceEvents {
  ADD_QGS_QUESTION = 'QGS_QUESTION',
}

type QuestionGenerationServiceEventEmitterType = {
  [QuestionGenerationServiceEvents.ADD_QGS_QUESTION]: (message: any) => void;
};

export type MessageFnType = defaultMessageFnType<QuestionGenerationServiceEventEmitterType>;

const QuestionGenerationServiceEventEmitter = new EventEmitter<QuestionGenerationServiceEventEmitterType>();
export default QuestionGenerationServiceEventEmitter;
