import React from 'react';
import { useCurrentTab, useSetTab } from '../context/TabsContext';

type Props = {
  tabKey: number | string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode | string;
  dataTestId?: string;
};
const Tab = ({
  tabKey,
  onClick,
  children,
  className,
  disabled = false,
  dataTestId = 'tab-testId',
}: Props) => {
  const currentTab = useCurrentTab();
  const setTab = useSetTab(tabKey);
  return (
    // eslint-disable-next-line jsx-a11y/interactive-supports-focus
    <li
      data-testid={dataTestId}
      onClick={() => {
        if (!disabled) {
          setTab();
          onClick?.();
        }
      }}
      className={`react-tabs__tab ${
        currentTab === tabKey ? 'react-tabs__tab--selected' : ''
      } ${className || ''}`}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
      role="button"
    >
      {children}
    </li>
  );
};

export default Tab;
