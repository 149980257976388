import styled, { css } from 'styled-components';

export const SectionCourseContainer = styled.section`
  padding: 2em 0em;
  .select__dropdown-indicator{
    position: relative;
    right: -1em;
  }
`;

export const FormGroupSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  .form-items {
    flex: 0 0 100%;
  }
  ${({ typeThree }) => typeThree && css`
    .form-items {
      &:first-child {
        flex: 0 0 40%;
        margin-left: 0em;
      }
      flex: 0 0 calc(30% - 1em);
      margin-left: 1em;
    }
  `};
  ${({ typeTwo }) => typeTwo && css`
    .form-items {
      &:first-child {
        flex: 0 0 50%;
        margin-left: 0em;
      }
      flex: 0 0 calc(50% - 1em);
      margin-left: 1em;
    }
  `};
`;
