import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import QuizTwoToneIcon from '@mui/icons-material/QuizTwoTone';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import CheckCircleOutlineTwoToneIcon from '@mui/icons-material/CheckCircleOutlineTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ShowIf from '@components/Atoms/ShowIf';
import TextField from '@components/Atoms/TextField';
import Box from '@components/Atoms/Box';
import { success as ToastSuccess, error as errorToast } from '@utils/toast';
import { useIsTeacher } from '@reducers/user/hooks';
import {
  NavBarItemCustome,
  NavBarItemWrap,
  NavBarStatusColumn,
  NavBarIconWrap,
} from './SubNavBar.style';
import { usePostLearnosityLessonReview } from './hooks/query';
import LessonReviewDeleteButton from './LessonReviewDeleteButton';

const inputLessonNameWrapper = {
  display: 'flex',
  gap: '0.5rem',
  paddingInlineEnd: '0.5rem',
  mt: '1.875rem',
};

const EditableLessonReviewName = ({
  lessonReview,
  setHasEditModeEnabled,
  hasEditModeEnabled,
  lessonId,
  isReviewClassEnabled,
}) => {
  const [value, setValue] = React.useState(lessonReview?.name);
  const { mutate: postLearnosityLessonReview } = usePostLearnosityLessonReview(lessonId);
  const isTeacher = useIsTeacher();

  return (
    <>
      <LessonReviewDeleteButton
        isReviewClassEnabled={isReviewClassEnabled}
        hasEditModeEnabled={hasEditModeEnabled}
        lessonId={lessonId}
        name={lessonReview?.name}
      />
      <ShowIf If={!hasEditModeEnabled}>
        <Box
          sx={[
            inputLessonNameWrapper,
            isReviewClassEnabled && {
              mt: '0.5rem',
            },
          ]}
        >
          {lessonReview?.name}
          <ShowIf If={isTeacher && isReviewClassEnabled}>
            <ModeEditTwoToneIcon
              onClick={(e) => {
                e.stopPropagation();
                setHasEditModeEnabled(true);
              }}
              sx={{
                fontSize: '1.125rem',
                fill: (theme) => theme.palette.primary.main,
              }}
            />
          </ShowIf>
        </Box>
      </ShowIf>
      <ShowIf If={hasEditModeEnabled}>
        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            mt: '1rem',
            alignItems: 'center',
          }}
        >
          <TextField
            type="text"
            value={value}
            onChange={(e) => {
              e.stopPropagation();
              setValue(e.target.value);
            }}
          />
          <Box>
            <CheckCircleOutlineTwoToneIcon
              onClick={(e) => {
                e.stopPropagation();
                if (value === lessonReview?.name) {
                  setHasEditModeEnabled(false);
                  return;
                }

                postLearnosityLessonReview(
                  {
                    name: value,
                  },
                  {
                    onSuccess: () => {
                      ToastSuccess('Assessment name updated successfully.');
                    },
                    onError: () => {
                      errorToast('Error updating lesson assessment.');
                    },
                  },
                );
                setHasEditModeEnabled(false);
              }}
              sx={{
                fontSize: '1.125rem',
                fill: (theme) => theme.palette.play.main,
              }}
            />
            <CloseTwoToneIcon
              onClick={(e) => {
                e.stopPropagation();
                setValue(lessonReview?.name);
                setHasEditModeEnabled(false);
              }}
              sx={{
                fontSize: '1.125rem',
                color: (theme) => theme.palette.error.main,
              }}
            />
          </Box>
        </Box>
      </ShowIf>
    </>
  );
};

const LessonReviewNavItem = ({
  isReviewActive,
  isSingleItem,
  lessonId,
  subjectId,
  lessonReview,
  unitId,
  isReviewClassEnabled,
}) => {
  const [hasEditModeEnabled, setHasEditModeEnabled] = React.useState(false);

  const { courseId = '', sectionId = '' } = useParams();

  const history = useHistory();

  return (
    <NavBarItemWrap
      isEditMode={hasEditModeEnabled}
      id={isReviewActive ? 'active-lesson' : ''}
      isActive={isReviewActive}
      onClick={() => {
        if (hasEditModeEnabled) {
          return;
        }
        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/assessment`,
        );
      }}
    >
      <NavBarStatusColumn>
        <NavBarIconWrap
          isSingleItem={isSingleItem}
          isFirstItem={false}
          isLastItem={false}
        >
          <QuizTwoToneIcon
            sx={{
              fontSize: '1.125rem',
              fill: (theme) => theme.palette.secondary.main,
              stroke: (theme) => theme.palette.primary.main,
              zIndex: 10,
              backgroundColor: 'inherit',
            }}
          />
        </NavBarIconWrap>
      </NavBarStatusColumn>
      <NavBarItemCustome>
        <EditableLessonReviewName
          isReviewClassEnabled={isReviewClassEnabled}
          lessonReview={lessonReview}
          setHasEditModeEnabled={setHasEditModeEnabled}
          hasEditModeEnabled={hasEditModeEnabled}
          lessonId={lessonId}
        />
      </NavBarItemCustome>
    </NavBarItemWrap>
  );
};

export default LessonReviewNavItem;
