import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import * as _ from 'lodash';
import GroupActivity from '../../components/GroupActivity';
import {
  selectors as GroupActivitySelectors,
  actions,
} from '../../reducers/groupActivity';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as CurriculumLessonsSelectors } from '../../reducers/curriculumLessons';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as SectionStudentSelectors } from '../../reducers/sectionStudent';
import { ROLE_TYPE } from '@utils/enums';

const OGA_QUESTION_ERROR = 'There is no question for this online group activity';

const mapStateToProps = (state, { match, handleIsFinishStepTwo }) => {
  const {
    groupActivity: { groupActivities, loadingGroupActivity, activeGroupActivity },
  } = state;
  const courseId = _get(match, 'params.courseId', '');
  const courseData = CourseSelectors.getCourseById(state, courseId);
  const curriculumId = _get(courseData, 'curriculum', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const unitId = _get(match, 'params.unitId', '');
  const lessonId = _get(match, 'params.lessonId', '');
  const subjectId = _get(match, 'params.subjectId', '');
  const isTeacher = UserSelectors.getUserRole(state) !== ROLE_TYPE.STUDENT;
  const user = UserSelectors.getCurrentUser(state);
  const lessonDetails = CurriculumLessonsSelectors.getLessonDetailById(state, lessonId);

  const activityLessonId = lessonDetails.lessons?.[0]?.id;
  const activityLessonGroupId = lessonDetails.id;
  const activityUnitId = unitId;
  const activityUserId = user.id;

  const allStudents = _.flatMap(groupActivities, ({ students }) => students);

  //TODO students being undefined and breaking the app sometimes
  const groupActivityWithMostStudents = _.maxBy(
    groupActivities,
    ({ students }) => students?.length,
  );
  const maxNumOfGroupStudents = groupActivityWithMostStudents?.students?.length || 0;

  const groupingError = GroupActivitySelectors.getGroupingError(state);
  const hasNoQuestions = groupingError === OGA_QUESTION_ERROR;

  return {
    studentList: SectionStudentSelectors.getStudentList(state, sectionId),
    curriculumId,
    sectionId,
    unitId,
    isTeacher,
    courseId,
    lessonId,
    subjectId,
    activityLessonId,
    activityLessonGroupId,
    activityUnitId,
    activityUserId,
    studentId: activityUserId,
    loadingGroupActivity,
    activeGroupActivity,
    allStudents,
    maxNumOfGroupStudents,
    hasNoQuestions,
    handleIsFinishStepTwo,
  };
};

const mapDispatchToProps = {
  getGroupActivities: actions.getGroupActivities,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupActivity));
