import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import history from '../../utils/history';
import Card from '../common/card/Card';
import {
  ScheduleWidgetWrap,
  CardListWrap,
  CardWrapper,
  CardText,
  ScheduleButtonWrap,
} from '../common/card/Card.style';
import { Button } from '../Generals/stylesheets/Button.style';
import { ROLE_TYPE } from '../../utils/enums';
import { DashboardTitle, CardsWithTitle, NumberIcon } from './DashboardSummary.style';

export default class DashboardUpNextWidget extends Component {
  scheduleDate = new Date();
  state = {
    upcomingSession: null,
  };

  componentDidMount = () => {
    const {
      sectionId,
      sectionScheduleGet,
      sectionReviewSummary,
      userRole,
      sectionsExamsScoresGet,
      curriculumDetail,
    } = this.props;

    if (sectionId) sectionScheduleGet(sectionId);
    if (userRole === ROLE_TYPE.INSTRUCTOR) {
      sectionReviewSummary(sectionId);
      if (curriculumDetail.has_exam) {
        sectionsExamsScoresGet(sectionId);
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      sectionId,
      sectionScheduleGet,
      sectionReviewSummary,
      userRole,
      sectionsExamsScoresGet,
      curriculumDetail,
    } = this.props;
    const { sectionId: prevSectionId } = prevProps;

    if (sectionId && sectionId !== prevSectionId) {
      sectionScheduleGet(sectionId);
      if (userRole === ROLE_TYPE.INSTRUCTOR) {
        sectionReviewSummary(sectionId);
        if (curriculumDetail.has_exam) {
          sectionsExamsScoresGet(sectionId);
        }
      }
    }
  };

  filterReviewSession = () => {
    const { sessionList, totalReviews = [] } = this.props;
    const completedReviewSessions = [];
    const inCompleteReviewSessions = [];
    let completedReviewIds = [];
    const allReviewSession = sessionList.filter((session) => {
      if (session.type === 'reviews') return session;
    });

    if (totalReviews.length > 0) {
      const completedReviews = totalReviews.filter((review) => {
        if (review.count === review.completed) return review.id;
      });
      completedReviewIds = completedReviews.map(({ id }) => id);
    }

    allReviewSession.map((review) => {
      if (completedReviewIds.includes(review.id)) {
        completedReviewSessions.push(review);
      } else {
        inCompleteReviewSessions.push(review);
      }
    });

    return { completedReviewSessions, inCompleteReviewSessions };
  };

  filterPracticeSession = () => {
    const { allPracticeSession, completedPracticeUnitIds } = this.props;
    const completedPracticeSessions = [];
    const incompletePracticeSessions = [];

    allPracticeSession.map((practice) => {
      if (completedPracticeUnitIds.includes(practice.unit)) {
        completedPracticeSessions.push(practice);
      } else {
        incompletePracticeSessions.push(practice);
      }
    });

    return { completedPracticeSessions, incompletePracticeSessions };
  };

  filterExamSession = () => {
    const { examList, examsScore } = this.props;
    const completedExamSessions = [];
    const incompleteExamSessions = [];
    const examSessionsScore = Object.values(examsScore);
    let completedExamIds = [];

    if (examSessionsScore.length > 0) {
      examSessionsScore.map((exam) => {
        if (exam.length > 0) {
          exam.map((item) => {
            const section_report = item?.section_report;
            if (section_report && section_report.length > 0) {
              section_report.map((item) => {
                if (item.ended !== null) {
                  completedExamIds.push(item.section_id);
                }
              });
            }
          });
        }
      });
    }

    completedExamIds = [...new Set(completedExamIds)];

    examList.map((exam) => {
      if (completedExamIds.includes(exam.id)) {
        completedExamSessions.push(exam);
      } else {
        incompleteExamSessions.push(exam);
      }
    });

    return { completedExamSessions, incompleteExamSessions };
  };

  renderCompletedSession = (sessionList) => {
    let completed_session = [];
    let recentTwoSessions = [{ empty: true }, { empty: true }];

    sessionList.map((session) => {
      if (session.lesson) {
        if (session.completed !== null) {
          completed_session = [...completed_session, session];
        }
      }
    });

    const { completedReviewSessions } = this.filterReviewSession();
    if (completedReviewSessions.length > 0) {
      completedReviewSessions.map((review) => {
        completed_session = [...completed_session, review];
      });
    }

    const { completedExamSessions } = this.filterExamSession();
    if (completedExamSessions.length > 0) {
      completedExamSessions.map((exam) => {
        completed_session = [...completed_session, exam];
      });
    }

    const { completedPracticeSessions } = this.filterPracticeSession();
    if (completedPracticeSessions.length > 0) {
      completedPracticeSessions.map((practice) => {
        completed_session = [...completed_session, practice];
      });
    }
    if (completed_session.length > 0) {
      let sortedSession = completed_session.sort((a, b) =>
        moment(b.date).diff(moment(a.date)),
      );
      sortedSession = sortedSession.sort((a, b) => {
        if (moment(a.date).isSame(moment(b.date))) {
          if (a.id > b.id) {
            return -1;
          }
        }
        return 0;
      });
      recentTwoSessions = [sortedSession[1], sortedSession[0]];
    }

    return recentTwoSessions.map((session, index) => (
      <Card key={index} session={session} status="complete" {...this.props} />
    ));
  };

  findItemNested = (arr, itemId, nestingKey) =>
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.id === itemId) return item;
      if (item[nestingKey])
        return this.findItemNested(item[nestingKey], itemId, nestingKey);
    }, null);

  getAssignmentSession = (assignment) => {
    const { unitBySubject } = this.props;
    let activity_type = assignment.lesson_group ? 0 : 1;
    let units = unitBySubject[assignment.subject].units;
    const unit = this.findItemNested(units, assignment.unit, 'children');
    const lessonName = unit?.lessons?.find(
      (lesson) => lesson.id == assignment.lesson_group,
    )?.name;
    let name = lessonName || unit?.name || '';
    return {
      ...assignment,
      type: 'lessons',
      lesson: assignment.lesson_group,
      activity_type,
      name,
    };
  };

  renderInCompletedSession = (sessionList) => {
    const { isAssignments, assignList, unitBySubject } = this.props;
    let incomplete_session = [];
    let upcomingSession = [{ empty: true }, { empty: true }, { empty: true }];

    sessionList.map((session) => {
      if (session.lesson) {
        if (session.completed === null) {
          incomplete_session = [...incomplete_session, session];
        }
      }
    });

    const { inCompleteReviewSessions } = this.filterReviewSession();
    if (inCompleteReviewSessions.length > 0) {
      inCompleteReviewSessions.map((review) => {
        incomplete_session = [...incomplete_session, review];
      });
    }

    const { incompleteExamSessions } = this.filterExamSession();
    if (incompleteExamSessions.length > 0) {
      incompleteExamSessions.map((exam) => {
        incomplete_session = [...incomplete_session, exam];
      });
    }

    const { incompletePracticeSessions } = this.filterPracticeSession();
    if (incompletePracticeSessions.length > 0) {
      incompletePracticeSessions.map((practice) => {
        incomplete_session = [...incomplete_session, practice];
      });
    }
    if (isAssignments) {
      if (assignList?.incomplete.length) {
        upcomingSession = [...upcomingSession, { empty: true }, { empty: true }];
        let sortedSession = assignList?.incomplete.sort((a, b) =>
          moment(a.last_assigned).diff(moment(b.last_assigned)),
        );
        upcomingSession = upcomingSession.map((session, index) => {
          let assignment = sortedSession[index];
          if (assignment && unitBySubject[assignment.subject]) {
            return this.getAssignmentSession(assignment);
          } else {
            return session;
          }
        });
        if (!_isEqual(this.state.upcomingSession, upcomingSession[0])) {
          this.setState({
            upcomingSession: upcomingSession[0],
          });
        }
      }
    } else if (incomplete_session.length > 0) {
      let sortedSession = incomplete_session.sort((a, b) =>
        moment(a.date).diff(moment(b.date)),
      );
      sortedSession = sortedSession.sort((a, b) => {
        if (moment(a.date).isSame(moment(b.date))) {
          if (a.id < b.id) {
            return -1;
          }
        }
        return 0;
      });
      upcomingSession = [sortedSession[0], sortedSession[1], sortedSession[2]];
      this.scheduleDate = upcomingSession[0]?.date || new Date();
    }

    return upcomingSession.map((session, index) => (
      <Card key={index} center={index === 0} session={session} {...this.props} />
    ));
  };

  renderEmptySessionList = () => {
    const { courseItem, sectionId, isAssignments } = this.props;
    const baseUrl = `/schedule/course/${courseItem.id}/section/${sectionId}`;

    return (
      <CardListWrap>
        <Card />
        <Card />
        <Link to={`${baseUrl}/section-info`}>
          <CardWrapper isAssignments={isAssignments}>
            <Card hasEmptySchedule />
            <CardText selected> {isAssignments ? 'Start Practice' : 'Up Next'}</CardText>
          </CardWrapper>
        </Link>
        <Card />
        <Card />
      </CardListWrap>
    );
  };

  handleGoToCalendar = () => {
    const { courseItem, sectionId } = this.props;
    const calendarUrl = `/schedule/course/${courseItem.id}/section/${sectionId}`;
    return history.push({ pathname: calendarUrl, state: { date: this.scheduleDate } });
  };

  handleGoToAssignments = () => {
    const { courseItem, sectionId, assignList } = this.props;

    let sameSubject = assignList?.incomplete.every(
      (val, i, arr) => val.subject === arr[0].subject,
    );
    let assignmentsUrl;
    if (sameSubject) {
      const {
        upcomingSession: { subject, unit, lesson, activity_type },
      } = this.state;

      if (activity_type === 0) {
        assignmentsUrl = `/lessons/course/${courseItem.id}/section/${sectionId}/subject/${subject}/unit/${unit}/lesson/${lesson}`;
      } else {
        assignmentsUrl = `/lessons/course/${courseItem.id}/section/${sectionId}/subject/${subject}/unit/${unit}/practice`;
      }
      return history.push({
        pathname: assignmentsUrl,
        state: { assignments: assignList?.incomplete?.length },
      });
    } else {
      assignmentsUrl = `/lessons/course/${courseItem.id}/section/${sectionId}`;
      return history.push(assignmentsUrl);
    }
  };

  render() {
    const {
      isGetting: loading,
      sessionList,
      isAssignments,
      assignList,
      unitBySubject,
      userRole,
    } = this.props;

    const incompleteAssignments =
      assignList?.incomplete?.filter((assignment) => unitBySubject[assignment.subject])
        ?.length || 0;
    if (loading) {
      return (
        <ScheduleWidgetWrap>
          <CardListWrap>
            {Array(5)
              .fill()
              .map((item, index) => (
                <Card key={index} index={index} loading={loading} />
              ))}
          </CardListWrap>
        </ScheduleWidgetWrap>
      );
    }
    if (sessionList.length === 0) {
      return this.renderEmptySessionList();
    }

    return (
      <CardsWithTitle complete={!incompleteAssignments && isAssignments}>
        {userRole === ROLE_TYPE.STUDENT && (
          <DashboardTitle>
            {isAssignments ? (
              <>
                <NumberIcon>
                  <span>{incompleteAssignments}</span>
                </NumberIcon>
                <p>Assignments</p>
              </>
            ) : (
              <p>Lessons</p>
            )}
          </DashboardTitle>
        )}
        <ScheduleWidgetWrap>
          <CardListWrap>
            {!isAssignments && this.renderCompletedSession(sessionList)}
            {this.renderInCompletedSession(sessionList)}
          </CardListWrap>
          {isAssignments ? (
            <ScheduleButtonWrap grey={!incompleteAssignments}>
              <Button
                isDisable={!incompleteAssignments}
                disabled={!incompleteAssignments}
                onClick={this.handleGoToAssignments}
              >
                Go to Assignments
              </Button>
            </ScheduleButtonWrap>
          ) : (
            <ScheduleButtonWrap>
              <Button onClick={this.handleGoToCalendar}>Go to Calendar</Button>
            </ScheduleButtonWrap>
          )}
        </ScheduleWidgetWrap>
      </CardsWithTitle>
    );
  }
}
