import ShowIf from '@components/Atoms/ShowIf';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  LessonVideoContent,
  ToggleFullScreenButton,
} from '@components/LessonManagement/LessonManagement.style';
import { VideoPlayer } from '@components/common/videoPlayer/VideoPlayer.style';
import { useIsTeacher, useUser } from '@reducers/user/hooks';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LessonTip from './LessonTip';
import useSlideEventListeners from './useSlideEventListeners';
import useIspringPresentationConnector from './hooks/useIspringPresentationConnector';
import usePresentationEventHandlers from './hooks/usePresentationEventHandlers';
import { v4 as uuidv4 } from 'uuid';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';

interface FullScreenButtonProps {
  className: string;
  toggleFullScreenStatus: () => void;
  isFullScreen: boolean;
}
const FullScreenButton = ({
  className,
  toggleFullScreenStatus,
  isFullScreen,
}: FullScreenButtonProps) => (
  <div className={className}>
    <ToggleFullScreenButton
      onClick={(event) => {
        event.stopPropagation();
        toggleFullScreenStatus();
      }}
    >
      <span>{isFullScreen ? 'Minimize' : 'Full screen'}</span>
    </ToggleFullScreenButton>
  </div>
);

const FullScreenButtonWrapper = ({
  isFullScreen,
  isSmall,
  selectedUrl,
  setIsFullScreen,
}: any) => {
  return (
    <>
      <ShowIf If={isFullScreen && isSmall}>
        <FullScreenButton
          className="full-screen-button-wrapper"
          toggleFullScreenStatus={() => setIsFullScreen((s) => !s)}
          isFullScreen={isFullScreen}
        />
      </ShowIf>
      <ShowIf If={!isFullScreen && !!selectedUrl && isSmall}>
        <span>Slides are supported only at fullscreen for mobile</span>
        <FullScreenButton
          className="full-screen-outer-button-wrapper"
          toggleFullScreenStatus={() => setIsFullScreen((s) => !s)}
          isFullScreen={isFullScreen}
        />
      </ShowIf>
    </>
  );
};

const LessonSlidesViewer = ({
  currentPresentationUrl,
  lessonDetails,
  selectedLessonNodeId,
}: {
  currentPresentationUrl?: string;
  lessonDetails: any;
  selectedLessonNodeId: number;
}) => {
  const { sectionId } = useParams();
  const { id: userId } = useUser();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);
  const isTeacher = useIsTeacher();
  const slidesUrl = isTeacher
    ? lessonDetails?.lessons?.[0]?.instructor_url
    : lessonDetails?.lessons?.[0]?.student_url;
  let url = slidesUrl;

  if (currentPresentationUrl) {
    url = currentPresentationUrl;
  }

  const iframeRef = useRef<HTMLIFrameElement>(null);
  useSlideEventListeners();
  const sessionUUID = uuidv4();
  // Use the custom hooks
  const controller = useIspringPresentationConnector(url, iframeRef);
  usePresentationEventHandlers(
    controller,
    sessionUUID,
    userId,
    sectionId,
    selectedLessonNodeId,
    LessonTabs.Lesson,
  );

  return (
    <>
      <ShowIf If={(isSmall && isFullScreen) || !isSmall}>
        <LessonTip>
          <LessonVideoContent isFullScreen={isFullScreen}>
            <VideoPlayer
              key={slidesUrl}
              src={url}
              id="slides"
              isFullScreen={isFullScreen}
              ref={iframeRef}
            />
          </LessonVideoContent>
        </LessonTip>
      </ShowIf>
      <FullScreenButtonWrapper
        isFullScreen={isFullScreen}
        isSmall={isSmall}
        selectedUrl={slidesUrl}
        setIsFullScreen={setIsFullScreen}
      />
    </>
  );
};

export default LessonSlidesViewer;
