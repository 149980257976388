import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';
import { useSelector } from 'react-redux';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import Box from '@Atoms/Box';
import { ExamScoreSelectWrap } from '../DashboardExamScoreReport/DashboardExamScoreReport.style';
import {
  RecordAttendanceTabWrap as DashboardStandardsReportWrapper,
  RecordAttendanceWrap as ActiveRecordWrapper,
  LessonListContainer as StandardsListContainer,
  LessonListWrap as StandardListsWrap,
  SessionItemWrap as StandardItemWrapper,
  SessionContent,
  RecordAttendanceTitle,
  SessionStatusIcon,
} from '../RecordAttendanceTab/RecordAttendanceTab.style';
import { selectors as SectionSelectors } from '../../reducers/sections';
import StandardsTable from './StandardsTable';
import {
  LessonCoveredTitleWrapper,
  LessonCoveredTitle,
  LessonCoveredStanders,
  LessonListWrapper,
  LessonRow,
  LightTooltip,
} from './DashboardStandardsReport.style';
import DropDownMenu from '../Shared/DropDownMenu/DropDownMenu';
import { useUnits } from '../../reducers/sectionUnit';

const useStandardsList = (Unit) =>
  React.useMemo(() => {
    const standards = {};
    if (!Unit) {
      return Object.keys(standards);
    }
    Unit?.lessons?.forEach((lesson) => {
      lesson?.standards?.forEach((standard) => {
        standards[standard.code] = undefined;
      });
    });
    return Object.keys(standards);
  }, [Unit]);
const getLessonRelatedToStandard = (selectedUnit, selectedStandard) => {
  const lessonList = selectedUnit?.lessons?.filter((lesson) =>
    lesson.standards.find((standard) => standard.code === selectedStandard),
  );
  return lessonList || [];
};
const useCoveredStandards = (selectedUnit) => {
  const attendanceList = useSelector((state) =>
    SectionSelectors.getSectionAttendanceList(state),
  );
  const standardsList = useStandardsList(selectedUnit);
  return React.useMemo(() => {
    const completedStandards = [];
    standardsList.forEach((standard) => {
      const LessonRelatedToStandard = getLessonRelatedToStandard(selectedUnit, standard);
      const lessonUncovered = [...LessonRelatedToStandard].filter((lesson) => {
        const attended = attendanceList.find(
          (attendanceItem) => attendanceItem.lesson === lesson.id,
        );
        return !attended;
      });
      if (lessonUncovered.length === 0) {
        completedStandards.push(standard);
      }
    });
    return completedStandards;
  }, [attendanceList, standardsList, selectedUnit]);
};
const StandardsList = ({ selectedUnit, setSelectedStandard, selectedStandard }) => {
  const standardsList = useStandardsList(selectedUnit);
  const coveredStandards = useCoveredStandards(selectedUnit);
  useEffect(() => {
    setSelectedStandard(standardsList[0] || '');
  }, [standardsList]);

  return (
    <StandardsListContainer>
      <StandardListsWrap>
        {standardsList.map((standard, index) => (
          <StandardItemWrapper
            key={index}
            isSelected={selectedStandard === standard}
            onClick={() => setSelectedStandard(standard)}
          >
            <SessionStatusIcon
              className={
                coveredStandards.includes(standard)
                  ? 'chalktalk-check-success'
                  : 'chalktalk-in-progress'
              }
            />
            <SessionContent>
              <p>{standard}</p>
            </SessionContent>
          </StandardItemWrapper>
        ))}
      </StandardListsWrap>
    </StandardsListContainer>
  );
};

const LessonStandardsInfo = ({ selectedStandard, selectedUnit, subject }) => {
  const lessonList = React.useMemo(
    () => getLessonRelatedToStandard(selectedUnit, selectedStandard),
    [selectedUnit, selectedStandard],
  );
  const { courseId, sectionId } = useParams();
  const attendanceList = useSelector((state) =>
    SectionSelectors.getSectionAttendanceList(state),
  );
  return (
    <LightTooltip
      leaveDelay={500}
      TransitionComponent={Zoom}
      interactive
      title={
        <>
          <LessonCoveredTitleWrapper>
            <LessonCoveredStanders>{selectedStandard}</LessonCoveredStanders>
            <LessonCoveredTitle>Lessons covered</LessonCoveredTitle>
          </LessonCoveredTitleWrapper>
          <LessonListWrapper>
            {lessonList.map((lesson, index) => (
              <Link
                key={lesson?.id || index}
                to={`/lessons/course/${courseId}/section/${sectionId}/subject/${subject?.id}/unit/${selectedUnit?.id}/lesson/${lesson?.id}?tab=${LessonTabs.Lesson}`}
              >
                <LessonRow
                  active={!!attendanceList.find((item) => item.lesson === lesson.id)}
                >
                  <i className="chalktalk-check-success" />
                  {subject?.display_name || ''}
                  ,#
                  {lesson?.name || ''}
                </LessonRow>
              </Link>
            ))}
          </LessonListWrapper>
        </>
      }
    >
      <ExamScoreSelectWrap>
        <RecordAttendanceTitle
          background="#4786FC2F 0% 0% no-repeat padding-box"
          m="0 0 1rem 0"
          yellow
        >
          <i className="chalktalk-info" />
          To view all lessons you have covered from this standard hover here.
        </RecordAttendanceTitle>
      </ExamScoreSelectWrap>
    </LightTooltip>
  );
};
const DashboardStandardsReport = ({ subject }) => {
  const [selectedStandard, setSelectedStandard] = React.useState('');
  const [selectedUnit, setSelectedUnit] = useState({});
  const units = useUnits(subject);

  useEffect(() => {
    if (units[0]) {
      setSelectedUnit(units[0]);
    }
  }, [subject, units]);

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      <DropDownMenu
        title="Units"
        list={units}
        onSelect={(item) => {
          setSelectedUnit(item);
        }}
        selected={selectedUnit}
        displayName={(item) => item?.name || ''}
        notSelectedFallback="No Unit"
      />
      <Box display="flex">
        <StandardsList
          selectedUnit={selectedUnit}
          setSelectedStandard={setSelectedStandard}
          selectedStandard={selectedStandard}
        />
        <ActiveRecordWrapper>
          <LessonStandardsInfo
            selectedStandard={selectedStandard}
            selectedUnit={selectedUnit}
            subject={subject}
          />
          <StandardsTable selectedStandard={selectedStandard} />
        </ActiveRecordWrapper>
      </Box>
    </Box>
  );
};

export default DashboardStandardsReport;
