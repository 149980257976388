import React from 'react';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import { StandardsPreview } from '@learnosityassessment';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useStandardPublication } from '@utils/hooks/useLearnosityQuestionCT/useLearnosityQuestionCT';
import { useLessonStandards } from '@components/Component/Lesson/LessonContainer/hooks';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import PracticeTopNavBar from '../PracticeTopNavBar';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { STANDARDS_PRACTICE_QUESTION_COUNT } from '../../utils/constants';
import { useSearchParams } from '../../utils/hooks/useSearchParams';

const scrollStyle = {
  backgroundColor: '#fff',
  height: 'calc(100vh - 5em)',
  width: '100%',
};

const StandardsPracticePreview = () => {
  const history = useHistory();
  const { lessonId } = useParams();

  const { data: lessonDetail } = useGetLessonDetail();
  const standards = useLessonStandards();
  const publication = useStandardPublication();
  const { lang } = useSearchParams();

  const goBack = () => {
    history.goBack();
  };

  // the redux doesn't have lesson detail and it will not , so redirect at this point.
  if (!lessonDetail?.lessons?.length) {
    return <Redirect to="/dashboard/course" />;
  }

  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Go back" goBack={goBack} />
      <ShadowScrollbar autoHide style={scrollStyle}>
        <StandardsPreview
          questionCount={STANDARDS_PRACTICE_QUESTION_COUNT}
          activityId={lessonId}
          title={lessonDetail?.name}
          standards={standards}
          publication={publication}
          language={lang}
        />
      </ShadowScrollbar>
    </PracticeContainer>
  );
};

export default React.memo(StandardsPracticePreview);
