import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary/ChalkTalkErrorBoundary';

const AuthenticatedRoute = ({
  authenticated,
  component: Component,
  disallowed = [],
  userRole,
  isEmailConfirmed,
  path,
  ...rest
}) => (
  <Route
    path={path}
    {...rest}
    render={(props) => {
      const isAllowed = !disallowed.find((role) => role === userRole);
      // to redirect to  confirm page if the user is not allowed
      const url = window.location;
      const token = new URLSearchParams(url.search).get('token');
      if (authenticated && isAllowed) {
        if (!isEmailConfirmed && path == '/account/verify') {
          const toObj = {
            pathname: `/account/verify?token=${token}`,
            state: { from: path },
          };
          return <Redirect to={toObj} />;
        }

        // if (!isEmailConfirmed) {
        //   const toObj = {
        //     pathname: '/account/confirmation',
        //     state: { from: path },
        //   };
        //   return <Redirect to={toObj} />;
        // }

        return (
          <ChalkTalkErrorBoundary key={path}>
            <Component {...props} authenticated />
          </ChalkTalkErrorBoundary>
        );
      }

      if (!authenticated) {
        const toObj = {
          pathname: '/login',
          state: { from: path },
        };
        return <Redirect to={toObj} />;
      }

      if (!isAllowed) {
        const toObj = {
          pathname: '/',
        };
        return <Redirect to={toObj} />;
      }

      return <Redirect to="/error/404" />;
    }}
  />
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType,
  authenticated: PropTypes.bool,
  disallowed: PropTypes.arrayOf(PropTypes.any),
  location: PropTypes.any,
  userRole: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
  component: () => null,
  disallowed: [],
};

export default AuthenticatedRoute;
