import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import StudentGroupInviteItem from '../../containers/StudentGroupInviteItem';
import StudentQuickInviteForm from '../../containers/StudentQuickInviteForm';
import StudentListTable from '../../containers/StudentListTable';
import {
  RegisterFormWrapper,
  RegisterFormTitle,
  RegisterHeaderTitle,
  RegisterGroupItem,
  ButtonStyled,
} from '../StudentRegister/StudentRegister.style';
import {
  InviteStudentWrap,
  StudentTableListWrap,
} from '../CourseManagementInviteStudent/CourseManagementInviteStudent.style';

export default class InviteStudentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <RegisterFormWrapper>
        <RegisterFormTitle large>
          <RegisterHeaderTitle>
            <h1>Add Students</h1>
            <h3>Invite your students to your section. Copy/Paste your roster to invite a group at once.</h3>
          </RegisterHeaderTitle>
          <Link to="/">
            <ButtonStyled medium>Go to section</ButtonStyled>
          </Link>
        </RegisterFormTitle>

        <RegisterGroupItem>
          <InviteStudentWrap>
            <StudentGroupInviteItem />
            <StudentQuickInviteForm />
          </InviteStudentWrap>

          <StudentTableListWrap>
            <StudentListTable />
          </StudentTableListWrap>
        </RegisterGroupItem>
      </RegisterFormWrapper>
    );
  }
}
