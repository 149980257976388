import { useEffect, useRef } from 'react';
import { usePostSlideChange } from '@components/Component/Lesson/LessonContainer/hooks/mutations';
import PresentationEventEmitter, {
  MessageFnType,
  PresentationEvents,
} from './LessonSlidesEventEmmiter';

const useSlideEventListeners = () => {
  const startTime = useRef<number | null>(null);
  const step = useRef<number | null>(null);
  const slideViewed = useRef<number | null>(null);
  const { mutate: postSlideChange } = usePostSlideChange();
  useEffect(() => {
    const startTimer = () => {
      if (!startTime.current) {
        startTime.current = Date.now();
      }
    };

    const stopTimer = () => {
      if (startTime.current) {
        const timeElapsed = (Date.now() - startTime.current) / 1000; // Time in seconds
        startTime.current = null;
        return timeElapsed;
      }
      return 0;
    };

    const handleSlideChanged = (data) => {
      const isFirstSlide = data.previousSlideIndex === -1;
      const timeElapsed = stopTimer();
      if (!isFirstSlide) {
        const body = {
          lesson_node_id: data.lessonNodeId,
          section_id: data.sectionId,
          user_id: data.userId,
          slide_index: slideViewed.current ?? 0,
          time_spent: timeElapsed,
          session_uuid: data.sessionUUID,
          event_type: 'slide_change',
          step_count: step.current ?? 0,
          last_slide_index: data.lastSlideIndex,
          slide_title: data.title,
          tab: data.tab,
          additionalsources_id: data.additionalSourceId,
        };
        postSlideChange(body);
      }
      slideViewed.current = data.slideIndex;
      step.current = 0;
      startTimer();
    };

    const handleStepChanged = () => {
      if (step.current === null) {
        step.current = 0;
      }
      step.current += 1;
    };

    const handlePlaybackCompleted = () => {
      console.log('Playback completed');
    };

    // Subscribe to events
    PresentationEventEmitter.on(
      PresentationEvents.SLIDE_CHANGED,
      handleSlideChanged as MessageFnType,
    );
    PresentationEventEmitter.on(
      PresentationEvents.STEP_CHANGED,
      handleStepChanged as MessageFnType,
    );
    PresentationEventEmitter.on(
      PresentationEvents.PLAYBACK_COMPLETED,
      handlePlaybackCompleted as MessageFnType,
    );

    // Cleanup on unmount
    return () => {
      PresentationEventEmitter.off(
        PresentationEvents.SLIDE_CHANGED,
        handleSlideChanged as MessageFnType,
      );
      PresentationEventEmitter.off(
        PresentationEvents.STEP_CHANGED,
        handleStepChanged as MessageFnType,
      );
      PresentationEventEmitter.off(
        PresentationEvents.PLAYBACK_COMPLETED,
        handlePlaybackCompleted,
      );
    };
  }, []);
};

export default useSlideEventListeners;
