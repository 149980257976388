import React from 'react';
import Box from '@components/Atoms/Box';
import Chip from '@components/Atoms/Chip';
import {
  useContentFilterContext,
  useContentFilterSkillContext,
  useContentFilterStandardContext,
} from '@components/ContentFilters/ContentFilterProvider';
import { cleanText } from '@components/QuestionGenerationService/utils/helpers';
import SubjectIcon from '@mui/icons-material/Subject';
import { BLOOMS_MAP } from '@components/Selectors/BloomsSelector/BloomsSelector';
import { DOK_MAP } from '@components/Selectors/DokSelector/DokSelector';
import ShowIf from '@components/Atoms/ShowIf';

const FilterChips = () => {
  const {
    selectedBlooms,
    selectedDok,
    selectedPassage,
    selectedReadability,
    selectedGrade,
    setSelectedDok,
    setSelectedBlooms,
    setSelectedPassage,
    setSelectedGrade,
    setSelectedReadability,
  } = useContentFilterContext();

  const { getSelectedStandardObjects, setSelectedStandards } =
    useContentFilterStandardContext();

  const { setSelectedSkills, getSelectedSkillObjects } = useContentFilterSkillContext();

  const selectedStandards = getSelectedStandardObjects();
  const selectedSkills = getSelectedSkillObjects();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '0.5rem',
        justifyContent: 'flex-end',
        flexGrow: 1,
      }}
    >
      {selectedStandards?.length > 0 && (
        <Chip
          key="standard-chip"
          label={`${selectedStandards.length} Standard(s)`}
          clickable={false}
          title={selectedStandards.map((s) => `${s.code}: ${s.description}`).join('\n')}
          color="default"
          size="small"
          variant={undefined}
          onDelete={() => {
            setSelectedStandards([]);
          }}
        />
      )}
      {selectedSkills?.length > 0 && (
        <Chip
          key="skill-chip"
          label={`${selectedSkills.length} Skill(s)`}
          clickable={false}
          color="default"
          size="small"
          title={selectedSkills.map((s) => s.name).join('\n')}
          variant={undefined}
          onDelete={() => {
            setSelectedSkills([]);
          }}
        />
      )}
      {selectedBlooms && (
        <Chip
          key="bloom-chip"
          label={`Blooms: ${BLOOMS_MAP[selectedBlooms]}`}
          clickable={false}
          color="default"
          size="small"
          variant={undefined}
          onDelete={() => {
            setSelectedBlooms(null);
          }}
        />
      )}
      {selectedDok && (
        <Chip
          key="dok-chip"
          label={`DOK: ${DOK_MAP[selectedDok]}`}
          clickable={false}
          color="default"
          size="small"
          variant={undefined}
          onDelete={() => {
            setSelectedDok(null);
          }}
        />
      )}
      {selectedPassage && (
        <Chip
          key="passage-chip"
          label={
            selectedPassage?.data?.heading
              ? cleanText(selectedPassage?.data?.heading)
              : cleanText(selectedPassage?.data?.content || '')
          }
          icon={<SubjectIcon />}
          color="default"
          title="A filter for this passage is applied"
          size="small"
          clickable={false}
          sx={{
            maxWidth: '20rem',
          }}
          onDelete={() => {
            setSelectedPassage(null);
          }}
        />
      )}
      {selectedReadability && (
        <Chip
          key="readability-chip"
          label={`Passage: ${selectedReadability}`}
          clickable={false}
          color="default"
          size="small"
          variant={undefined}
          onDelete={() => {
            setSelectedReadability(null);
          }}
        />
      )}
      {selectedGrade && (
        <Chip
          key="grade-chip"
          label={`Grade: ${selectedGrade}`}
          clickable={false}
          color="default"
          size="small"
          variant={undefined}
          onDelete={() => {
            setSelectedGrade(null);
          }}
        />
      )}
      <ShowIf
        If={
          !!selectedStandards.length ||
          !!selectedSkills.length ||
          !!selectedBlooms ||
          !!selectedDok ||
          !!selectedPassage
        }
      >
        <Chip
          key="clear-chip"
          label="Clear All"
          clickable
          color="default"
          size="small"
          variant="outlined"
          onClick={() => {
            setSelectedStandards([]);
            setSelectedSkills([]);
            setSelectedBlooms(null);
            setSelectedDok(null);
            setSelectedPassage(null);
            setSelectedReadability(null);
            setSelectedGrade(null);
          }}
        />
      </ShowIf>
    </Box>
  );
};

export default FilterChips;
