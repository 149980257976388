import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import styled from 'styled-components';
import _get from 'lodash/get';
import ShowIf from '@Atoms/ShowIf';
import {
  PracticeContentWrap,
  PracticeContentGroup,
  PracticeFooterBarMobile,
  PBMobileSelectedItem,
  ProgressBarItem,
  ProgressBarCircle,
  ProgressBarLine,
  PracticeFooterBar,
  ProgressBarWrapper,
  MobileOnly,
  DesktopOnly,
} from '../PracticePage/PracticePage.style';
import { NavigationHeaderWrap } from '../QuestionAssets/QuestionAssets.style';

import QuestionAssets from '../../containers/QuestionAssets';
import PracticeReviewItem from '../PracticeReviewItem';
import AnswerExplanation from '../../containers/AnswerExplanation/AnswerExplanation';
import GroupActivityQuestionItem from '../../containers/GroupActivityContent/GroupActivityQuestionItem';
import ReviewAnswered from './ReviewAnswered';

class GroupActivityContentItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      answeredQuestions: {},
    };
  }

  renderQuestionAssets = () => {
    const { questions, questionSelected, displayGrammarHelper, openReportErrorModal } =
      this.props;
    const questionSelectedObj = questions[questionSelected];
    const assets = _get(questionSelectedObj, 'question.shared_assets', []);
    const questionId = _get(questionSelectedObj, 'id');

    if (!assets || !assets.length) return <div> </div>;

    return (
      <QuestionAssets
        hasFooter
        assets={assets}
        displayGrammarHelper={displayGrammarHelper}
        questionSelected={questionSelected}
        openReportErrorModal={openReportErrorModal}
        questionId={questionId}
      />
    );
  };

  renderProgressBar = (currentQuestion, index) => {
    const { questionSelected, selectQuestion, questions, status } = this.props;
    const numberQuestion = currentQuestion ? questions.length : null;
    const isSelected = currentQuestion ? questionSelected >= index : true;
    const isActive = currentQuestion ? questionSelected > index : true;
    const isQuestionAnswered =
      Boolean(this.state.answeredQuestions[questions[index]?.id]) &&
      questionSelected >= index;
    const isProgressBarCircleDisabled = [1, 2].some((value) => value === +status);

    return (
      <ProgressBarItem
        isActive={isActive}
        isAnswered={isQuestionAnswered}
        isSelected={isSelected}
        widthValue={numberQuestion}
        firstItem={!index}
        key={index}
      >
        {currentQuestion ? (
          <>
            <ProgressBarLine />
            <ProgressBarCircle
              onClick={
                isProgressBarCircleDisabled ? undefined : selectQuestion(index, true)
              }
              disabled={isProgressBarCircleDisabled}
            />
          </>
        ) : (
          <PBMobileSelectedItem onClick={this.toggleQuestionModal}>
            <ProgressBarCircle />
            {`Question ${questionSelected + 1}`}
          </PBMobileSelectedItem>
        )}
      </ProgressBarItem>
    );
  };

  renderStudentQuestion = () => {
    const {
      questions,
      scores,
      questionSelected,
      nextQuestion,
      prevQuestion,
      openReportErrorModal,
      status,
      startTime,
      answers,
      history,
      nextStage,
      courseId,
      sectionId,
      subjectId,
      unitId,
      lessonId,
      currentGroup,
      correctAnswer,
      groupId,
      onlineResponseId,
      userId,
      isTeacher,
      currentGroupStudents,
      hideModal,
      totalDuration,
      setSelectedQuestion,
    } = this.props;

    const questionSelectedObj = questions[questionSelected];
    const totalQuestions = questions.length;
    const questionOrder = questionSelectedObj.order || null;
    const explanation = _get(correctAnswer, 'explanation', {});
    const questionId = _get(questionSelectedObj, 'id');
    const score = scores.find((score) => score.exam_question.id == questionId)?.score;
    const groupStatus = status === 'review' ? status : parseInt(status);
    return (
      <OnlineReviewItemContainer>
        <GroupActivityQuestionItem
          answeredQuestions={this.state.answeredQuestions}
          questionSelected={questionSelectedObj}
          totalQuestions={totalQuestions}
          prevQuestion={prevQuestion}
          index={questionSelected}
          order={questionOrder}
          startTime={startTime}
          toggleExplanationSideBar={this.toggleExplanationSideBar}
          openReportErrorModal={openReportErrorModal}
          questionId={questionId}
          nextQuestion={nextQuestion}
          setSelectedQuestion={setSelectedQuestion}
          answers={answers}
          totalDuration={totalDuration}
          status={groupStatus}
          history={history}
          nextStage={nextStage}
          correctChoice={correctAnswer?.correct_choices}
          isTeacher={isTeacher}
          hideModal={hideModal}
          score={score}
          onAnswerQuestion={({ questionSelected, answer }) => {
            if (status !== 'review') {
              this.setState({
                answeredQuestions: {
                  ...this.state.answeredQuestions,
                  [questionSelected.id]: answer,
                },
              });
            }
          }}
          isGroupActivity
          questions={questions}
          numberOfQuestions={questions.length || 3}
          {...{
            courseId,
            sectionId,
            subjectId,
            unitId,
            lessonId,
            currentGroup,
            groupId,
            onlineResponseId,
            userId,
            currentGroupStudents,
          }}
        />
        {status === 'review' && (
          <DesktopOnly>
            <AnswerExplanation
              explanation={explanation}
              openReportErrorModal={openReportErrorModal}
              questionId={questionId}
              unSetHeight
            />
          </DesktopOnly>
        )}
      </OnlineReviewItemContainer>
    );
  };

  renderReviewAnswer = () => {
    const {
      questions,
      questionSelected,
      nextQuestion,
      prevQuestion,
      openReportErrorModal,
      status,
      groupArray,
      isTeacher,
    } = this.props;
    const groups = !_isEmpty(groupArray)
      ? groupArray
      : JSON.parse(localStorage.getItem('OGA_groups'));
    const questionSelectedObj = questions[questionSelected];
    const totalQuestions = questions.length;
    const questionOrder = questionSelectedObj.order || null;
    const explanation = _get(questionSelectedObj, 'explanation', {});
    const questionId = _get(questionSelectedObj, 'id');
    const answeredByStudent = questionSelectedObj.studentAnswered;
    const answeredByGroup = questionSelectedObj.groupAnswered;

    return (
      <OnlineReviewItemContainer>
        <PracticeReviewItem
          questionSelected={{
            ...questionSelectedObj,
            exam_question: { question: questionSelectedObj.question },
          }}
          totalQuestions={totalQuestions}
          nextQuestion={nextQuestion}
          toggleExplanationSideBar={this.toggleExplanationSideBar}
          prevQuestion={prevQuestion}
          index={questionSelected}
          order={questionOrder}
          openReportErrorModal={openReportErrorModal}
          questionId={questionId}
          isTeacher={isTeacher}
          onlineQuestion
          isGroupActivity
        />
        <DesktopOnly>
          <AnswerExplanation
            explanation={explanation}
            openReportErrorModal={openReportErrorModal}
            questionId={questionId}
            unSetHeight
          />

          <ShowIf If={status === 'review'}>
            <ReviewAnswered
              answeredGroups={answeredByGroup}
              answeredStudents={answeredByStudent}
            />
          </ShowIf>
        </DesktopOnly>
      </OnlineReviewItemContainer>
    );
  };

  render() {
    const { questions, isActivityNavigation, questionSelected, isTeacher } = this.props;
    const totalQuestions = questions.length;

    if (totalQuestions === 0) {
      return null;
    }

    const questionSelectedObj = questions[questionSelected];
    const assets = _get(questionSelectedObj, 'question.shared_assets', []);
    const isToggleNavigation = isActivityNavigation || !assets || !assets.length;
    return (
      <>
        <PracticeContentWrap>
          <DesktopOnly>{this.renderQuestionAssets()}</DesktopOnly>
          <MobileOnly>
            {assets.length > 0 && (
              <Tabs defaultIndex={1}>
                <NavigationHeaderWrap>
                  <TabList>
                    <Tab>Assets</Tab>
                    <Tab>Show Questions</Tab>
                  </TabList>
                </NavigationHeaderWrap>
                <TabPanel>{this.renderQuestionAssets()}</TabPanel>
              </Tabs>
            )}
          </MobileOnly>

          <PracticeContentGroup
            block={false}
            isToggleNavigation={isToggleNavigation}
            assets={assets.length > 0}
            groupActivity
          >
            {isTeacher ? this.renderReviewAnswer() : this.renderStudentQuestion()}
          </PracticeContentGroup>
        </PracticeContentWrap>
        <PracticeFooterBar>
          <ProgressBarWrapper>{questions.map(this.renderProgressBar)}</ProgressBarWrapper>
          <PracticeFooterBarMobile>
            <ProgressBarWrapper>{this.renderProgressBar()}</ProgressBarWrapper>
          </PracticeFooterBarMobile>
        </PracticeFooterBar>
      </>
    );
  }
}
const OnlineReviewItemContainer = styled.div`
  max-width: unset;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  height: 90%;
  @media (max-width: 1280px) {
    max-width: unset;
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 90%;
    height: 100%;
  }
`;
export default GroupActivityContentItem;
