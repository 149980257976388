import {
  call, put, takeLeading,
} from 'redux-saga/effects';
import * as CourseAPI from '../../apis/courses';
import {
  COURSE_TEACHERS_GET_LIST,
  COURSE_TEACHERS_REMOVE,
  COURSE_TEACHERS_ADD_OWNER,
  COURSE_TEACHERS_REMOVE_OWNER,
  COURSE_TEACHERS_GET_OWNER,
  COURSE_TEACHERS_INVITE,

  actions as CourseTeacherActions,
} from '../../reducers/courseTeachers';
import { success as ToastSuccess, error as ToastError } from '../../utils/toast';

function* handleFetchTeacherListFromCourse({ courseId }) {
  try {
    const { response, error } = yield call(CourseAPI.getCourseTeachers, courseId);
    if (error) throw error;

    const { data } = response;
    yield put(CourseTeacherActions.fetchCourseTeacherListSuccess(data));
  } catch (error) {
    yield put(CourseTeacherActions.fetchCourseTeacherListFail(error));
  }
}

function* courseTeacherRemove({ teacherId, courseId, teacherName }) {
  const { error } = yield call(CourseAPI.removeTeacherFromCourse, teacherId, courseId);

  if (!error) {
    yield put(CourseTeacherActions.courseTeacherRemoveSuccess(teacherId));
    ToastSuccess(`Teacher ${teacherName} has been removed!`);
  } else {
    yield put(CourseTeacherActions.courseTeacherRemoveFail(error));
  }
}

function* courseTeacherAddOwner({ courseId, teacherId }) {
  const { error } = yield call(CourseAPI.addOwnerToCourse, courseId, teacherId);

  if (!error) {
    yield put(CourseTeacherActions.courseTeacherAddOwnerSuccess(teacherId));
  } else {
    yield put(CourseTeacherActions.courseTeacherAddOwnerFail(error));
  }
}

function* courseTeacherRemoveOwner({ courseId, teacherId }) {
  const { error } = yield call(CourseAPI.removeOwnerFromCourse, courseId, teacherId);

  if (!error) {
    yield put(CourseTeacherActions.courseTeacherRemoveOwnerSuccess(teacherId));
  } else {
    yield put(CourseTeacherActions.courseTeacherRemoveOwnerFail(error));
  }
}

function* courseTeacherGetOwner({ courseId }) {
  try {
    const { response, error } = yield call(CourseAPI.getCourseOwners, courseId);
    if (error) throw error;

    const { data } = response;
    yield put(CourseTeacherActions.courseTeacherGetOwnerSuccess(data));
  } catch (error) {
    yield put(CourseTeacherActions.courseTeacherGetOwnerFail(error));
  }
}

function* courseTeacherInvite({ courseId, teacherInfo, callback }) {
  const { response, error } = yield call(CourseAPI.inviteTeacherToCourse, courseId, teacherInfo);

  if (response) {
    const { data } = response;

    ToastSuccess('New teacher has been successfully invited to course');
    yield put(CourseTeacherActions.courseTeacherInviteSuccess(data));

    if (callback) {
      callback();
    }
  } else {
    ToastError(error.join``);
    yield put(CourseTeacherActions.courseTeacherInviteFail(error));
  }
}

export default function* courseTeachersSaga() {
  yield takeLeading(COURSE_TEACHERS_GET_LIST, handleFetchTeacherListFromCourse);
  yield takeLeading(COURSE_TEACHERS_REMOVE, courseTeacherRemove);
  yield takeLeading(COURSE_TEACHERS_ADD_OWNER, courseTeacherAddOwner);
  yield takeLeading(COURSE_TEACHERS_REMOVE_OWNER, courseTeacherRemoveOwner);
  yield takeLeading(COURSE_TEACHERS_GET_OWNER, courseTeacherGetOwner);
  yield takeLeading(COURSE_TEACHERS_INVITE, courseTeacherInvite);
}
