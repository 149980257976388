import {
  all, call, put, take,
  select, takeLeading, takeEvery,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import { success as ToastSuccess } from '../../utils/toast';
import * as RegisterAPIs from '../../apis/register';
import * as CourseAPIs from '../../apis/courses';
import {
  REGISTER_TEACHER,
  REGISTER_STUDENT,
  REGISTER_SUBMIT_SUBSCRIPTION,
  REGISTER_CREATE_COURSE,
  actions as RegisterActions, REGISTER_CREATE_SECTION,
} from '../../reducers/register';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from '../../reducers/auth';
import {
  COURSES_CREATE_NEW_FAIL,
  COURSES_CREATE_NEW_SUCCESS,
  // selector
  getCourseListIds,
  // actions
  createCourse,
} from '../../reducers/courses';
import {
  SECTIONS_CREATE_NEW_SUCCESS,
  SECTIONS_CREATE_NEW_FAIL,
  // selector
  selectors as SectionSelectors,
  // actions
  sectionsCreateNewSection,
  sectionsCreateNewSectionSuccess,
} from '../../reducers/sections';
import { configToken } from '../../apis/config';

export function* handleTeacherRegistration({ payload: teacherData }) {
  try {
    const { response, error } = yield call(RegisterAPIs.teacherRegister, teacherData);
    if (error) throw error;

    const { data } = response;
    const { user, tokens, message, success } = data;
    const is_confirmed = true;
    if (success) {
      const { access: token, refresh: refreshToken } = tokens;
      ToastSuccess('Welcome to ChalkTalk, Please verify your email');
      configToken(token);
      yield put(RegisterActions.registerTeacherSuccess());
      // yield put(RegisterActions.teacherRegisterNextStep());
      yield put(AuthActions.loginSuccess(token, refreshToken, user, is_confirmed));
      if (is_confirmed) {
        yield put(push('/dashboard/'));
      } else {
        yield put(push('/account/confirmation'));
      }
    } else {
      yield put(RegisterActions.registerStudentFailed(message || error));
    }
  } catch (error) {
    yield put(RegisterActions.registerStudentFailed(error.message || error));
  }
}

export function* handleStudentRegistration({ payload: studentData }) {
  try {
    const { response, error } = yield call(RegisterAPIs.studentRegister, studentData);

    if (error) throw error;

    const { data } = response;
    const {
      user, tokens, message, section, success
    } = data;
    const is_confirmed = true;
    if (success) {
      const { access: token, refresh: refreshToken } = tokens;

      configToken(token);
      yield put(push('/account/confirmation'));
      yield put(RegisterActions.registerStudentSuccess());
      yield put(AuthActions.loginSuccess(token, refreshToken, user, is_confirmed));
      yield put(sectionsCreateNewSectionSuccess(section));
      // yield put(push('/register/successfully'));
      ToastSuccess('Welcome to ChalkTalk, Please verify your email');
    } else {
      yield put(RegisterActions.registerStudentFailed(message || error));
    }
  } catch (error) {
    yield put(RegisterActions.registerStudentFailed(error.message || error));
  }
}

export function* handleSubmitSubsription({ payload: submissionData, callback }) {
  try {
    const { response, error } = yield call(CourseAPIs.createSubscriptionInfo, submissionData);

    if (error) throw error;

    const { data } = response;

    yield put(RegisterActions.submitSubscriptionCodeSuccess(data));

    if (callback) {
      callback();
    }
  } catch (error) {
    const errorMsg = _get(error, 'response.data.detail', error.message);
    const errorStatus = _get(error, 'response.status');

    if (callback && errorStatus === 403) {
      callback('consume', submissionData);
    } else {
      callback('error', errorMsg);
    }
    yield put(RegisterActions.submitSubscriptionCodeFailed(errorMsg));
  }
}

export function* handleRegNewCourse({ payload }) {
  const { courseInfo } = payload;

  yield put(createCourse(courseInfo));

  const { payload: createCoursePayload } = yield take([
    COURSES_CREATE_NEW_SUCCESS,
    COURSES_CREATE_NEW_FAIL,
  ]);
  const { errorMsg } = createCoursePayload;

  if (errorMsg) {
    yield put(RegisterActions.createRegCourseFailed(errorMsg));
  } else {
    yield put(RegisterActions.createRegCourseSuccess());
    yield put(RegisterActions.teacherRegisterNextStep());
  }
}

export function* handleRegNewSection({ payload }) {
  const { sectionInfo } = payload;

  yield put(sectionsCreateNewSection(sectionInfo));

  const { errorMsg } = yield take([
    SECTIONS_CREATE_NEW_SUCCESS,
    SECTIONS_CREATE_NEW_FAIL,
  ]);

  if (errorMsg) {
    yield put(RegisterActions.createRegSectionFailed(errorMsg));
  } else {
    yield put(RegisterActions.createRegSectionSuccess());
  }
}

function* handleConditionRegisterpage(action) {
  const currentPathname = _get(action, 'payload.location.pathname', '');
  const isInRegisterPage = currentPathname === '/register/teacher';

  if (!isInRegisterPage) {
    return;
  }
  let step = 0;
  const isAuthenticated = yield select(AuthSelectors.getAuthenticatedStatus);

  if (!isAuthenticated) {
    yield put(RegisterActions.registerSelectStepManually(step));
    return;
  }

  step = 1;

  const courseList = yield select(getCourseListIds);
  const sectionList = yield select(SectionSelectors.getSectionListIds);

  if (!(courseList.length > 0)) {
    yield put(RegisterActions.registerSelectStepManually(step));
    return;
  }

  step = 3;

  if (sectionList.length > 0) {
    step = -1;
    yield put(push('/dashboard/course'));
  }

  if (step !== -1) {
    yield put(RegisterActions.registerSelectStepManually(step));
  }
}

export default function* registerSaga() {
  yield all([
    // takeLeading(AUTH_LOGIN_SUCCESS, calculateCurrentStep),
    takeLeading(REGISTER_TEACHER, handleTeacherRegistration),
    takeLeading(REGISTER_STUDENT, handleStudentRegistration),
    takeLeading(REGISTER_SUBMIT_SUBSCRIPTION, handleSubmitSubsription),
    takeLeading(REGISTER_CREATE_COURSE, handleRegNewCourse),
    takeLeading(REGISTER_CREATE_SECTION, handleRegNewSection),
    takeEvery(LOCATION_CHANGE, handleConditionRegisterpage),
  ]);
}
