import {
  all, call, put,
  takeLeading,
} from 'redux-saga/effects';
import { getAdminReportsUrl } from '../../apis/adminReports';
import { ADMIN_REPORTS_FETCH_URL, actions as AdminReportsActions } from '../../reducers/adminReports';

export function* fetchAdminReportsUrl() {
  try {
    const { response, error } = yield call(getAdminReportsUrl);
    if (error) throw error;

    yield put(AdminReportsActions.fetchAdminReportsUrlSuccess(response.data));
  } catch (error) {
    yield put(AdminReportsActions.fetchAdminReportsUrlFailed(error.message || error));
  }
}

export default function* adminReportsSaga() {
  yield all([
    takeLeading(ADMIN_REPORTS_FETCH_URL, fetchAdminReportsUrl),
  ]);
}