/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
  LinkInsideWellWrapper,
} from '../SubscriptionForm/SubscriptionForm.style';
import FormInput from '../../Generals/FormInput/FormInput';
import {
  ButtonWrapper,
  ButtonStyled,
} from '../SetupYourCourse/SetupYourCourse.style';
import {
  Subtitle,
  SectionNameFieldWrapper,
} from '../SectionCreationModal/SectionCreationModal.style';
import { FieldWrapper } from '../TeacherInvitationModal/TeacherInvitationModal.style';
import PostCreatedSection from '../../../containers/Modals/PostCreatedSection';

import SkipDialogueModal from '../SkipDialogueModal';

const newInitialValues = {
  sectionCode: '',
};

const validationSchema = Yup.object().shape({
  sectionCode: Yup.string().trim().required('Required'),
});

class JoinSectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenSkipModal: false,
    };
  }

  componentWillUnmount() {
    const { sectionSetDefaultEnrollment } = this.props;
    sectionSetDefaultEnrollment();
  }

  openSkipDialogue = () => {
    this.setState({
      isOpenSkipModal: true,
    });
  };

  handleSubmit = (values) => {
    const { sectionRequestEnrollment } = this.props;

    sectionRequestEnrollment(values.sectionCode);
  };

  renderPostJoinSectionModal = () => {
    const { sectionData } = this.props;

    return (
      <PostCreatedSection
        sectionDetail={sectionData}
        onClose={this.props.onClose}
        openSkipDialogue={this.openSkipDialogue}
      />
    );
  };

  // update validation msg
  renderJoinSectionForm() {
    const {
      isRequestingEnrollment, errorMsg, onClose, joinSectionTab, shouldDisplayFreeOption
    } = this.props;
    return (
      <CreateOrJoinItemWrap>
        <CreateOrJoinTitle>
          <h1>Join section</h1>
          <Subtitle>
            Use the section code provided to you to join section.
          </Subtitle>
          <SectionNameFieldWrapper>
            <Formik
              initialValues={newInitialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
            >
              <Form noValidate>
                <FieldWrapper isSectionNameModal>
                  <FormInput
                    type="text"
                    name="sectionCode"
                    placeholder="Type section code"
                  />
                </FieldWrapper>
                {errorMsg && (
                  <p style={{ textAlign: 'center', color: 'red' }}>
                    Invalid section code. Please, check your code and try again
                  </p>
                )}

                {joinSectionTab ? (
                  <React.Fragment>
                    <ButtonWrapper isJoinSectionModal>
                      <ButtonStyled
                        isSubmittedBtnInModal
                        isJoinSectionModal
                        disabled={isRequestingEnrollment}
                        type="submit"
                        disabled={isRequestingEnrollment}
                      >
                        Join
                      </ButtonStyled>
                    </ButtonWrapper>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <ButtonWrapper isSectionCreationModal>
                      <ButtonStyled isSectionCreationModal onClick={onClose}>
                        Cancel
                      </ButtonStyled>

                      <ButtonStyled
                        isSectionCreationModal
                        isContinueModal
                        type="submit"
                        disabled={isRequestingEnrollment}
                      >
                        Join Section
                      </ButtonStyled>
                    </ButtonWrapper>
                  </React.Fragment>
                )}
              {shouldDisplayFreeOption && (
                <span className='freemium-msg-span'>
                  Want to just check it out? Choose our&nbsp;
                  <LinkInsideWellWrapper href="https://schools.chalktalk.com/meetings/gavin-laughlin/chalktalkmath?hsLang=en" target="_blank">Free Experience.</LinkInsideWellWrapper>
                </span>
              )}
              </Form>
            </Formik>
          </SectionNameFieldWrapper>
        </CreateOrJoinTitle>
      </CreateOrJoinItemWrap>
    );
  }

  renderSkipModal = () => {
    const { onClose } = this.props;

    return <SkipDialogueModal onClose={onClose} />;
  };

  render() {
    const { isRequestedEnrollment } = this.props;
    const { isOpenSkipModal } = this.state;

    if (isOpenSkipModal) {
      return this.renderSkipModal();
    }

    if (isRequestedEnrollment) {
      return this.renderPostJoinSectionModal();
    }

    return this.renderJoinSectionForm();
  }
}

JoinSectionModal.propTypes = {
  sectionRequestEnrollment: PropTypes.func.isRequired,
  isRequestedEnrollment: PropTypes.bool.isRequired,
  isRequestingEnrollment: PropTypes.bool.isRequired,
  errorMsg: PropTypes.array,
  sectionData: PropTypes.any,
  sectionSetDefaultEnrollment: PropTypes.func,
  onClose: PropTypes.func,
  shouldDisplayFreeOption: PropTypes.bool,
};

export default JoinSectionModal;
