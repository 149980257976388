/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { getUserPassages, generatePassageWithAI } from '@apis/questiongenerationservice';
import { useQuery, useMutation } from 'react-query';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Passage } from './types';
import { error as showError } from '@utils/toast';

export const useGetUserPassages = (source: string) => {
  const queryResponse = useQuery<Passage[], Error>(
    ['getUserPassages', source],
    async () => {
      const response = (await getUserPassages(source)) as unknown as {
        response: { data: Passage[] };
      };
      return response.response.data || [];
    },
    {
      retry: 3,
      retryDelay: 5 * 1000,
      staleTime: 60 * 60 * 24 * 1000,
      onError: () => {
        toast.error('Failed to fetch passages. Please try again later.');
      },
      useErrorBoundary: true,
    },
  );

  return { passages: queryResponse?.data ?? [], isLoading: queryResponse.isLoading } as {
    passages: Passage[];
    isLoading: boolean;
  };
};

export const useGeneratePassage = () =>
  useMutation(
    async (prompt: string) => {
      const response = await generatePassageWithAI(prompt);
      return response.data;
    },
    {
      mutationKey: ['generatePassageWithAI'],
      retry: 1,
      onError: (err: any) => {
        const errorMessage = err?.message || 'Failed to generate passage. Please try again.';
        showError(errorMessage);
      },
    }
  );

export const usePassages = (source: string) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPassages, setFilteredPassages] = useState<Passage[]>([]);
  const { passages, isLoading: isPassagesLoading } = useGetUserPassages(source);
  const [lexileQuery, setLexileQuery] = useState<string>('');
  const [fleschGradeQuery, setFleschGradeQuery] = useState<string>('');

  useEffect(() => {
    if (!passages.length) {
      return;
    }
    setFilteredPassages(
      passages.filter((passage: Passage) => {
        const { content, heading } = passage.data;
        const { flesch_kincaid, lexile } = passage.data.metadata || {};

        const contentMatches = searchQuery
          ? content.toLowerCase().includes(searchQuery.toLowerCase())
          : true;

        const headingMatches = searchQuery
          ? heading?.toLowerCase().includes(searchQuery.toLowerCase()) || false
          : true;

        const lexileMatches = lexileQuery ? lexile?.toString() === lexileQuery : true;

        const fleschGradeMatches = fleschGradeQuery
          ? flesch_kincaid?.toString() === fleschGradeQuery
          : true;

        return (contentMatches || headingMatches) && lexileMatches && fleschGradeMatches;
      }),
    );
  }, [fleschGradeQuery, lexileQuery, passages, searchQuery]);

  return {
    passages,
    filteredPassages,
    isPassagesLoading,
    searchQuery,
    setSearchQuery,
    lexileQuery,
    setLexileQuery,
    fleschGradeQuery,
    setFleschGradeQuery,
  };
};
