import styled, { css } from 'styled-components';

export const SummaryProgressBar = styled.div`
  
  height: .25em;
  border-radius: 1em;
  position: relative;
  background-color: #fddba2;
  width: 10em;
  margin-right: 5px;
  &:before {
    content: '';
    position: absolute;
    border-radius: 1em;
    height: .25em;
    left: 0em;
    top: 0em;
    background-color: ${props => props.theme.colorStyled.ColorBgDefault};
    ${({ percent }) => percent && css`
      width: ${percent}%;
    `}
  }
  ${({ dark }) => dark && css`
    background-color: ${props => props.theme.colorStyled.ColorLightRedGray};
    &:before {
      background-color: ${props => props.theme.colorStyled.ColorRedGray};
    }
  `}
  ${({ yellow }) => yellow && css`
    background-color: ${props => props.theme.colorStyled.ColorLightYellow};
    &:before {
      background-color: ${props => props.theme.colorStyled.ColorYellow};
    }
  `}
`;

export const SummaryProgressPercent = styled.div`
  position: relative;
  bottom: 8px;
  width: 10%;
`;

export const SummaryProgress = styled.div`
  width: 40%;
  padding-top: 9px;
  display: flex;
  @media (max-width: 768px) {
    margin-top: 0em;
  }
`;

export const SummaryBlockContent = styled.div`
  margin-left: 2em;;
  margin-top: 2em;
  ${({ flexWrap }) => flexWrap && css`
    display: flex;
    @media (max-width: 960px) {
      flex-direction: column;
    }
  `}
`;

export const SummaryText = styled.div`
  position: relative;
  top: 6px;
  right: 5.5em;
  font-size: 10px;
`;

export const Text = styled.div`
  font-size: .85em;
  font-weight: bold;
  padding-right: 18px;
  width: 60%;
`;

export const ProgressRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin-top: 10px;
`;

export const RecommendedText = styled.div`
  font-size: 1.2em;
  margin-top: 2em;
  text-align: center;
`;

export const BorderUnit = styled.div`
  width: 49%;
  border: 1px dashed #fddba0;
  position: relative;
  top: 52px;
  margin-left: 15%;
`;

export const MainHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 1em;
  margin-top: 1.2em;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const SectionInfoStyle = styled.p`
  width: 85%;
  font-weight: 200;
  font-size: 11pt;
  font-family: 'Montserrat',Arial;
  padding-bottom: .6em;
`;

export const CustomScrollSectionProfileInfo = styled.div`
  height: 100%;
  overflow: auto;
  padding-bottom: 1em;
  width: 100%;
  
  ::-webkit-scrollbar {
    width: 15px;
    padding: 0 2px;
    margin-left: 2px;
  }
  ::-webkit-scrollbar-track {
    border: 2px solid #e0e0e0;
    border-radius: 15px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
  }
`;

export const SectionProfileInfo = styled.div`
  display: flex;
  align-items: center;
  min-height: 3em;
  .FormInput{
    width: 19em;
  }
  .form-items {
    width: 100%;
    margin-bottom: 0em;
  .div {
      margin-bottom: 0em;
    }
  }
  ${({ block }) => block && css`
    display: block;
  `};
  input, .select__control {
    outline: none;
    border-radius: .325em;
    padding: 0.5em;
    margin-top: .5em;
  }
`;

export const SectionLabel = styled.div`
  font-size: 1em;
  font-weight: bold;
  margin-left: 1em;
  flex: 0 0 35%;
  font-family: 'MontserratMedium';
`;

export const SectionContent = styled.div`
  font-size: 1em;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const LogoChalkTalk = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoUnit = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 2em 2em 0em 0em;
  text-align: center;
  z-index: 1;
  p{
    font-size: .85em;
  }
  img{
    border: 10px solid #fddba1;
    border-radius: 50%;
    width: 3.8em;
    height: 3.8em;
    background-color: #f69e35;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    min-height: 110px;
    margin: 2em 1em 0em 0em;
  }
`;

export const FlowIndex = styled.div`
  display: inline-block;
  align-items: center;
  justify-content: center;
  margin: 1.3em;
  margin-left: 6em;
  p {
    font-size: 0.60em;
  }
  @media (max-width: 768px) {
    margin-left: 3em;
  }
`;

export const FlowIndexUnit = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0.8em;
  img{
    width: 2em;
    height: 2em;
    object-fit: contain;
  }
  p{
    font-size: 0.65em;
    font-weight: bold;
  }
`;
