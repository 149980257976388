/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from "yup";
import {
  UserProfileInfo,
  UserLabel,
} from '../Profile/Profile.style';
import {
  ActionGroup,
  Button,
} from '../Generals/stylesheets/Button.style';
import FormInput from '../Generals/FormInput/FormInput';
import { ModalHeader, ModalCLose } from '../Generals/stylesheets/General.style';
import ResponsiveModal from '../common/responsiveModal/ResponsiveModal';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '640px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class ProfileEditForm extends PureComponent {
  static propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    submitUserEdit: PropTypes.func,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
  }

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  handleSubmit = (values) => {
    const { submitUserEdit } = this.props;
    submitUserEdit(values);
  }

  render() {
    const { user } = this.props;
    const { isOpen } = this.props;
    const { first_name, last_name, email } = user;
    const initialValues = {
      first_name,
      last_name,
      email,
    };
    const ProfileSchema = Yup.object().shape({
      first_name: Yup.string().required('Required'),
      last_name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
    });
    
    return (
      <ResponsiveModal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          <p>Edit Profile</p>
          <ModalCLose className="chalktalk-close" onClick={this.onClose} />
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          onSubmit={this.handleSubmit}
          validationSchema={ProfileSchema}
        >
          <Form>
            <UserProfileInfo>
              <UserLabel>First name</UserLabel>
              <FormInput
                type="text"
                name="first_name"
              />
            </UserProfileInfo>

            <UserProfileInfo>
              <UserLabel>Last name</UserLabel>
              <FormInput
                type="text"
                name="last_name"
              />
            </UserProfileInfo>

            <UserProfileInfo>
              <UserLabel>Email</UserLabel>
              <FormInput
                type="text"
                name="email"
              />
            </UserProfileInfo>

            <ActionGroup center marginTop>
              <Button submit type="submit">Submit</Button>
              <Button onClick={this.onClose}>Cancel</Button>
            </ActionGroup>
          </Form>
        </Formik>
      </ResponsiveModal>
    );
  }
}

export default ProfileEditForm;
