import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import { QuickInviteContainer } from '../CourseManagementInviteStudent/CourseManagementInviteStudent.style';
import {
  FormStyled,
  RegisterAction,
  ButtonStyled,
} from '../StudentRegister/StudentRegister.style';
import {
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().email('Invalid Email').trim().required('Required'),
  grade: Yup.string().trim().required('Required'),
});

const initialValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  grade: '',
};

const gradeOption = [
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 11,
    label: 11,
  },
  {
    value: 12,
    label: 12,
  },
];

export default class StudentQuickInviteForm extends Component {
  static propTypes = {
    submitInviteStudent: PropTypes.func.isRequired,
    sectionId: PropTypes.string,
  }

  handleSubmit = (values, { resetForm }) => {
    const { submitInviteStudent, sectionId } = this.props;
    submitInviteStudent([values], sectionId);
    resetForm();
  }

  render() {
    return (
      <QuickInviteContainer>
        <BlockWrap>
          <BlockTitle>Quick Add</BlockTitle>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            <FormStyled>
              <FormInput
                type="text"
                name="firstName"
                label="First Name"
              />
              <FormInput
                type="text"
                name="middleName"
                label="Middle Name"
              />
              <FormInput
                type="text"
                name="lastName"
                label="Last Name"
              />
              <FormInput
                type="email"
                name="email"
                label="Email"
              />
              <FormInput
                type="select"
                name="grade"
                label="Grade Level (optional)"
                options={gradeOption}
              />
              <RegisterAction>
                <ButtonStyled medium type="submit">Invite</ButtonStyled>
              </RegisterAction>
            </FormStyled>
          </Formik>
        </BlockWrap>
      </QuickInviteContainer>

    );
  }
}
