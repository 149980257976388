import React from 'react';
import {
  Card as MuiCard,
  CardProps as MuiCardProps,
  SxProps,
  Theme,
} from '@mui/material';

export interface CardProps extends MuiCardProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const Card = ({ children, ...rest }: CardProps) => (
  <MuiCard {...rest}>{children}</MuiCard>
);
export default Card;
