import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as SectionskillSelectors } from '../../reducers/sectionSkills';
import { selectors as curriculumLessonSelectors } from '../../reducers/curriculumLessons';
import {
  actions as ActivityActions,
  selectors as ActivitySelectors,
} from '../../reducers/activity';
import {
  selectors as SectionsSelectors,
  actions as SectionsActions,
} from '../../reducers/sections';
import {
  selectors as UserSelectors,
} from '../../reducers/user';
import {
  actions as LessonAssignmentActions,
} from '../../reducers/lessonAssignment';
import DashboardLessonActivityReport from '../../components/DashboardLessonActivityReport';

const mapStateToProps = (state, { subjectId }) => ({
  currentUserId: UserSelectors.getUserId(state),
  examsScoreSummary: SectionsSelectors.examsScoreSummary(state),
  sectionPracticeActivityList: ActivitySelectors.sectionPracticeActivityList(state),
  unitTree: SectionUnitSelectors.getUnitTreeBySubjectId(state, subjectId),
  skillByLessonId: SectionskillSelectors.getSkillByLessonId(state),
  lessonActivityById: ActivitySelectors.lessonActivityById(state),
  lessonById: curriculumLessonSelectors.getLessonById(state),
  lessonActivitySummaryById: ActivitySelectors.getLessonActivitySummaryById(state),
  isGettingSectionUnit: SectionUnitSelectors.isGettingSectionUnit(state),
});

const mapDispatchToProps = {
  sectionsPracticeSummaryGet: SectionsActions.sectionsPracticeSummaryGet,
  lessonAssignmentBulk: LessonAssignmentActions.lessonAssignmentBulk,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DashboardLessonActivityReport));
