import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import LessonPracticeCard from '../../components/LessonPracticeCard';
import { selectors as UserSelectors } from '../../reducers/user';
import {
  actions as PracticeActions,
  selectors as PracticeSelectors,
} from '../../reducers/practice';
import { selectors as ActivitySelectors } from '../../reducers/activity';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { selectors as sectionSkillsSelectors } from '../../reducers/sectionSkills';
import { selectors as CurriculumLessonsSelectors } from '../../reducers/curriculumLessons';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';

const mapStateToProps = (state, { match }) => {
  const lessonId = _get(match, 'params.lessonId', '');
  const unitId = _get(match, 'params.unitId', '');
  const sectionId = _get(match, 'params.sectionId', '');
  const lessonDetail = CurriculumLessonsSelectors.getLessonDetailById(state, lessonId);

  return {
    currentUserId: UserSelectors.getUserId(state),
    lessonActivityList: ActivitySelectors.lessonActivityList(state),
    userRole: UserSelectors.getUserRole(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    isInitializing: PracticeSelectors.isInitializing(state),
    isInitialized: PracticeSelectors.isInitialized(state),
    examsScoreSummary: SectionSelectors.examsScoreSummaryByUnitAndLesson(
      state,
      unitId,
      lessonId,
    ),
    skillLevel: sectionSkillsSelectors.getMeanOfCurrentLessonSkillData(
      state,
      sectionId,
      lessonId,
    ),
    lessonDetail,
    unitDetail: SectionUnitSelectors.getUnitById(state, unitId),
  };
};

const mapDispatchToProps = {
  initializePracticeSession: PracticeActions.initializePracticeSession,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LessonPracticeCard),
);
