import { connect } from 'react-redux';
import LoginPage from '../../components/LoginPage';
import { selectors as AuthSelectors } from '../../reducers/auth';

export const mapStateToProps = state => ({
  statusMessage: state.auth.statusMessage,
  cleverEmail: AuthSelectors.getCleverEmail(state),
});

export default connect(mapStateToProps)(LoginPage);
