import { connect } from 'react-redux';
import _get from 'lodash/get';
import TestPage from '../../components/TestPage';
import { actions as examActions, selectors as examSelectors } from '../../reducers/exam';
import { selectors as sectionExamSelectors, actions as sectionExamActions } from '../../reducers/sectionExam';
import { actions as userActions } from '../../reducers/user';

const mapStateToProps = (state, { match }) => {
  const examSessionId = _get(match, 'params.examSessionId', '');
  const examSectionId = _get(match, 'params.examSectionId', '');
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', '');

  return {
    courseId,
    sectionId,
    examSectionId,
    examSessionId,
    examDetail: sectionExamSelectors.getExamDetailById(state, examSessionId),
    responseId: examSelectors.getResponseId(state),
    secondsRemaining: examSelectors.getSecondsRemaining(state),
    isTakeQuestion: examSelectors.getIsTakeQuestion(state),
    shouldResetTimer: examSelectors.getShouldResetTimer(state),
    errorStatus: examSelectors.getErrorStatus(state),
    newAnswerIds: examSelectors.getExamChooseQuestion(state),
  };
};

const mapDispatchToProps = {
  examTakeQuestion: examActions.examTakeQuestion,
  sectionExamGetDetail: sectionExamActions.sectionExamGetDetail,
  examSubmitQuestion: examActions.examSubmitQuestion,
  examClearData: examActions.examClearData,
  examResetTimerSuccess: examActions.examResetTimerSuccess,
  userReportIssue: userActions.userReportIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
