import { connect } from 'react-redux';
import _get from 'lodash/get';
import TestManagement from '../../components/TestManagement';
import { actions as SectionStudentActions } from '../../reducers/sectionStudent';
import { actions as CurriculumActions } from '../../reducers/curriculums';
import { selectors as SectionExamSelectors, actions as SectionExamActions} from '../../reducers/sectionExam';
import { selectors as UserSelectors } from '../../reducers/user';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', null);
  const courseId = _get(match, 'params.courseId', null);
  const examSessionId = _get(match, 'params.examSessionId', null);
  const shouldRenderTestContent = !!examSessionId || !!sectionId;
  
  return {
    courseId,
    shouldRenderTestContent,
    sectionId,
    examSessionId,
    sectionDetail: SectionSelectors.getSectionListById(state, sectionId),
    userRole: UserSelectors.getUserRole(state),
    lastestExam: SectionExamSelectors.getLastestExam(state),
    hasExamV2Access: SectionExamSelectors.getExamV2Access(state, courseId, sectionId),
  };
};

const mapDispatchToProps = {
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
  getCurriculumList: CurriculumActions.getCurriculumList,
  fetchExamV2Access : SectionExamActions.fetchExamV2Access,
};

export default connect(mapStateToProps, mapDispatchToProps)(TestManagement);
