import { connect } from 'react-redux';
import _get from 'lodash/get';
import DashboardStudentActivitySummaryReport from '../../components/DashboardStudentActivitySummaryReport';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';
import { selectors as SectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as ActivitySelectors } from '../../reducers/activity';
import {
  selectors as SectionskillSelectors,
  actions as SectionSkillsActions,
} from '../../reducers/sectionSkills';
import {
  selectors as SectionsSelectors,
  actions as SectionsActions,
} from '../../reducers/sections';

const getLessonsFromUnits = (units) => {
  const lessonList = [];
  const queue = [...units];
  while (queue.length > 0) {
    const { lessons = [], children = [] } = queue.shift();
    lessons.forEach((item) => {
      const canPractice = _get(item, 'can_practice', false);
      if (canPractice) {
        lessonList.push(item.id);
      }
    });
    children.forEach((item) => {
      queue.push(item);
    });
  }
  return lessonList;
};

const mapStateToProps = (state, { subjectId }) => {
  const unitTree = SectionUnitSelectors.getUnitTreeBySubjectId(state, subjectId);
  const units = _get(unitTree, 'units', []);
  const lessonList = getLessonsFromUnits(units);

  return {
    examsScoreSummary: SectionsSelectors.examsScoreSummary(state),
    sectionPracticeActivityList: ActivitySelectors.sectionPracticeActivityList(state),
    lessonList,
    skillByLessonId: SectionskillSelectors.getSkillByLessonId(state),
    isGettingStudentList: SectionStudentSelectors.isGettingStudentList(state),
  };
};

const mapDispatchToProps = {
  sectionsPracticeSummaryGet: SectionsActions.sectionsPracticeSummaryGet,
  sectionSkillsGet: SectionSkillsActions.sectionSkillsGet,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardStudentActivitySummaryReport);
