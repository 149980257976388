import styled, { css } from 'styled-components';

export const PracticeAnswerGridChoiceWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const AnswerGridChoiceWrap = styled.div``;

export const AnswerGridChoiceItem = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colorStyled.ColorXLightGrey};
`;

export const AnswerGridChoiceColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${props => props.theme.colorStyled.ColorXLightGrey};
  &:last-child {
    border-right: none;
  }
`;

export const StudentAnswer = styled.div`
  display: flex;
  padding-left: 1em;
  margin-top: 10px;
  .name{
    margin-right: 1em;
  }
  .answer{
    font-size: 15px;
  }
`;

export const GridItem = styled.div`
  flex: 1;
  padding-bottom: .375em;
  p {
    width: 2.25em;
    height: 2.25em;
    font-family: 'MontserratRegular';
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${props => props.theme.colorStyled.ColorLightGrey};
    font-size: ${props => props.theme.fontSize.SmallFontSize};
  }
  &:first-child {
    padding-top: .375em;
  }
  &:last-child {
    padding-bottom: .375em;
  }
  &:nth-child(2) {
    margin-bottom: .375em;
    border-bottom: 1px solid ${props => props.theme.colorStyled.ColorXLightGrey};
  }
  ${({ noValue }) => noValue && css`
    p {
      border: none;
    }
  `}
  ${({ isSelected }) => isSelected && css`
    p {
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
      border-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
      color: ${props => props.theme.colorStyled.ColorWhite};
    }
  `}
`;

export const AnswerGridChoiceColumnSelected = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.colorStyled.ColorXLightGrey};
  border-bottom: none;
`;

export const ChoiceSelected = styled.div`
  border-right: 1px solid ${props => props.theme.colorStyled.ColorXLightGrey};
  &:last-child {
    border-right: none;
  }
  p {
    width: 2.25em;
    height: 2.25em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    font-family: 'MontserratSemiBold';
    font-size: ${props => props.theme.fontSize.SmallFontSize};
  }
`;
