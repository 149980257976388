import styled, { css } from 'styled-components';

export const GFEButtonContainer = styled.button`
  color: ${props => props.color};
  background-color: ${props => props.backgroundColor};
  border-radius: 0.5em;
  border: none;
  cursor: pointer;
  height: 2.5em;
  width: 10.5em;
  line-height: 1;
  font-size: ${props => props.theme.fontSize.MediumFontSize};
  font-family: 'MontserratLight';
  font-weight: 100;

  ${({ primary }) => primary && css`
    background-color: ${props => props.theme.colorStyled.ColorPeach};
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    pointer-events: none;
    background-color: ${props => props.theme.colorStyled.ColorAlto};
    color: ${props => props.theme.colorStyled.ColorPrimary};
  `}
`;
