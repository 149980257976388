import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const UnverifiedRoute = ({
  authenticated,
  component: Component,
  disallowed = [],
  userRole,
  isEmailConfirmed,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (authenticated && !isEmailConfirmed) {
        return <Component {...props} authenticated />;
      }

      if (!authenticated) {
        const toObj = {
          pathname: "/login",
          state: { from: path },
        };
        return <Redirect to={toObj} />;
      }

      return <Redirect to="/" />;
    }}
  />
);

UnverifiedRoute.propTypes = {
  component: PropTypes.func,
  authenticated: PropTypes.bool,
  disallowed: PropTypes.arrayOf(PropTypes.any),
  location: PropTypes.any,
  userRole: PropTypes.string,
};

UnverifiedRoute.defaultProps = {
  component: () => null,
  disallowed: [],
};

export default UnverifiedRoute;
