import { connect } from 'react-redux';
import PracticeTeacherReviewManagement from '../../components/PracticeTeacherReviewManagement';
import {
  selectors as LeftSideBarSelector,
} from '../../reducers/leftSideBar';
import { actions as UserActivitiesActions, selectors as UserActivitiesSelectors } from '../../reducers/userActivities';
import { actions as userActions } from '../../reducers/user';

const mapStateToProps = state => ({
  isLoading: UserActivitiesSelectors.isGettingPracticeSessionReportActivities(state),
  isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
  report: UserActivitiesSelectors.getUniquePracticeReportActivities(state),
  userAnswerList: UserActivitiesSelectors.getPracticeReportUserAnswerList(state),
});

const mapDispatchToProps = {
  getAllPracticeSessionReportActivities: UserActivitiesActions.getAllPracticeSessionReportActivities,
  userReportIssue: userActions.userReportIssue,
};

export default connect(mapStateToProps, mapDispatchToProps)(PracticeTeacherReviewManagement);
