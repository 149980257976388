import {
  postLearnosityDistrictQuestions,
  getLearnosityDistrictQuestions,
  getLearnosityDistrictSelectedQuestions,
} from '@apis/practice';
import { useCourseById } from '@reducers/courses/hooks';
import {
  convertQuestionToCamelCase,
  Question,
} from '@learnosityassessment/AssessmentServiceAPI';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export const useGetLearnosityDistrictQuestions = () => {
  const { lessonId: lessonGroupId, courseId } = useParams();
  const courseDetails = useCourseById(courseId);
  const districtId = courseDetails?.organization?.district?.id;
  const queryResponse = useQuery<any>(
    ['getLearnosityDistrictQuestions', lessonGroupId, districtId],
    async () => {
      const { response } = (await getLearnosityDistrictQuestions(
        districtId,
        lessonGroupId,
      )) as unknown as {
        response: { data: any };
      };

      return response.data || {};
    },
    {
      suspense: true,
      enabled: !!lessonGroupId,
      staleTime: 1000 * 60 * 60,
    },
  );

  return queryResponse || {};
};

export const usePostLearnosityDistrictQuestions = () => {
  const { lessonId: lessonGroupId, courseId } = useParams();
  const courseDetails = useCourseById(courseId);
  const districtId = courseDetails?.organization?.district?.id;
  const response = useMutation(
    (payload: any) => postLearnosityDistrictQuestions(districtId, lessonGroupId, payload),
    {
      mutationKey: ['postLearnosityDistrictQuestions', districtId, lessonGroupId],
      retry: 2,
      retryDelay: 2 * 1000,
    },
  );
  return response;
};

export const useFormatSelectedQuestions = (questionReferences) =>
  useMemo(
    () =>
      questionReferences?.map((reference) => {
        // if is an object
        if (typeof reference === 'object') {
          return {
            ...reference,
          };
        }
        return {
          reference,
          id: reference,
          organisation_id: Number(
            process.env.REACT_APP_LEARNOSITY_ADAPTIVE_ORGANISATION_ID || 653, // chalktalk-prod
          ),
        };
      }),
    [questionReferences],
  );
