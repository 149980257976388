import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, Popover, TextField } from '@mui/material';
import IconButton from '@components/Atoms/IconButton/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import ShowIf from '@components/Atoms/ShowIf/ShowIf';
import TreeViewSearch from './TreeViewSearch';
import SelectedChips from './SelectedChips';

export type Node = {
  id: string;
  label: string;
  children?: Node[];
  noChip?: boolean;
  noCheckbox?: boolean;
  [key: string]: unknown;
};

export interface MultiSelectTreeProps {
  /** Array of objects with { id, label, children: []} */
  tree: Node[];
  /** Whether the `MultiSelectTree` is disabled */
  disabled?: boolean;
  /** The placeholder text */
  placeholder?: string;
  /** Callback fired when the selection changes */
  onChange?: (ids: number[]) => void;
  /** Whether the Popover is open */
  open?: boolean;
  /** Preselected IDs */
  selected?: number[];
  /** Whether multiple selections are allowed */
  multiple?: boolean;
  /** Callback fired when the Popover is closed */
  onClose?: () => void;
  /** Tags for the Nodes */
  tags?: any;
  /** Background color */
  background: Record<string, string>;
  /** Array of ids to indicate the selection is indeterminate */
  indeterminate?: number[];
}

/**
 * A `MultiSelectTree` component
 * @example
    <MultiSelectTree
    tree={[
      {
        id: '1',
        label: 'Parent 1',
        children: [
          { id: '1-1', label: 'Child 1' },
          { id: '1-2', label: 'Child 2' },
        ],
      },
      {
        id: '2',
        label: 'Parent 2',
        children: [
          { id: '2-1', label: 'Child 1' },
          { id: '2-2', label: 'Child 2' },
        ],
      },
    ]}
    onChange={(node) => console.log(node)}
  />

  @returns {React.ReactElement} A `MultiSelectTree` element
 */
const MultiSelectTree = ({
  tree,
  disabled = false,
  placeholder = 'Click to select items...',
  onChange = undefined,
  open = false,
  selected = [],
  multiple = true,
  onClose,
  tags = {},
  background,
  indeterminate,
}: MultiSelectTreeProps) => {
  const textFieldRef = React.useRef<HTMLInputElement>(null);

  // Anchor Element for the Popover
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  // Search Text
  const [searchText, setSearchText] = useState<string>('');

  // we make a string version because the tree only works with string ids
  const selectedIds = selected.map((id) => id.toString());

  // Click to Open Popover
  const handleClick = (event) => {
    event.stopPropagation();
    if (!disabled) {
      setAnchorEl(event.currentTarget as HTMLDivElement);
    }
  };

  // Close the Popover
  const handleClose = (event) => {
    event?.stopPropagation();
    setAnchorEl(null);
    onClose?.();
  };

  // Clear the Selected Nodes
  const clearSelection = (event) => {
    event.stopPropagation();
    onChange?.([]);
  };

  const cleanAndReturn = (stringIds: string[]) => {
    const ids = stringIds.map((id) => parseInt(id, 10)).filter((id) => !Number.isNaN(id));
    onChange?.(ids);
  };

  // Remove a Node from the Selected Nodes
  const unselectId = (nodeId: string) => {
    cleanAndReturn(selectedIds.filter((id) => id !== nodeId));
  };

  // combine the selected nodes with the newly selected nodes but make sure they are unique
  const setNodesUnique = (nodes: string[]) => {
    const selectedNodeIds: string[] = [];

    const findNode = (node: Node) => {
      if (nodes.includes(node.id)) {
        selectedNodeIds.push(node.id);
      }
      if (node.children) {
        node.children.forEach((child) => findNode(child));
      }
    };

    tree.forEach((node) => findNode(node));

    cleanAndReturn(selectedNodeIds);
    if (!multiple) {
      handleClose(new Event('click'));
    }
  };

  // Get the Selected Node Objects from the Selected Node IDs
  const getSelectedNodes = (): Node[] => {
    const selectedNodes: Node[] = [];

    const findNode = (node: Node) => {
      if (!node?.noChip && selectedIds.includes(node.id)) {
        selectedNodes.push(node);
      }
      if (node.children) {
        node.children.forEach((child) => findNode(child));
      }
    };

    tree.forEach((node) => findNode(node));

    return selectedNodes;
  };

  // Set the Anchor Element when the Popover is open
  useEffect(() => {
    // Small delay to ensure parent popover has finished positioning
    if (open) {
      const timer = setTimeout(() => {
        setAnchorEl(textFieldRef.current);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [open]);

  return (
    <Box>
      <TextField
        ref={textFieldRef}
        fullWidth
        disabled={disabled}
        variant="outlined"
        placeholder={selectedIds.length === 0 ? placeholder : ''}
        onClick={handleClick}
        value={!multiple && selectedIds.length > 0 ? getSelectedNodes()?.[0]?.label : ''}
        InputProps={{
          className: 'MuiAutocomplete-inputRoot',
          startAdornment: (
            <ShowIf If={selectedIds.length > 0 && multiple}>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '0.5rem',
                  maxWidth: 'calc(100% - 60px)',
                  py: '0.5rem',
                }}
              >
                <SelectedChips
                  nodes={getSelectedNodes()}
                  tags={tags}
                  disabled={disabled}
                  onDelete={unselectId}
                  background={background}
                />
              </Box>
            </ShowIf>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Box className="MuiAutocomplete-endAdornment">
                <ShowIf If={selected.length > 0 && !disabled}>
                  <IconButton onClick={clearSelection} size="small">
                    <CloseIcon />
                  </IconButton>
                </ShowIf>
                <IconButton onClick={handleClick} size="small">
                  <ArrowDropDownIcon />
                </IconButton>
              </Box>
            </InputAdornment>
          ),
          readOnly: true,
        }}
      />
      <Popover
        id="tree-select-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              width: 'auto',
              maxHeight: 400,
              mt: 0.5,
              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
            },
          },
        }}
      >
        <Box
          sx={{
            p: 1,
            width: 'auto',
            minWidth: textFieldRef.current?.offsetWidth || 'auto',
            maxWidth: '60rem',
          }}
        >
          <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{ mb: 1 }}
            autoFocus
          />
          <Box sx={{ overflow: 'auto' }}>
            <TreeViewSearch
              tree={tree}
              searchText={searchText}
              selected={selectedIds}
              setSelectedNodes={setNodesUnique}
              multiple={multiple}
              indeterminate={indeterminate?.map((id) => id.toString())}
            />
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default MultiSelectTree;
