/* eslint-disable eqeqeq */
import React from 'react';
import useIsMobile from '@utils/hooks/useIsMobile';
import {
  ProgressBarWrapper,
  PBMobileSelectedItem,
  ProgressBarItem,
  ProgressBarCircle,
  ProgressBarLine,
} from './WizardProgress.style';

type WizardProgressProps<T> = {
  currentState?: T;
  states: T[];
  stateIdentifier: (state: T) => string | number;
  stateLabel: (state: T) => string;
  stateOnClick?: (state: T) => void;
  restrictMoveForward?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const WizardProgress = <T extends unknown>({
  currentState,
  states,
  stateIdentifier = (state: T) => (state as unknown as { id: string })?.id as string,
  stateLabel,
  stateOnClick,
  restrictMoveForward,
}: WizardProgressProps<T>): JSX.Element => {
  const isMobile = useIsMobile();
  const currentStateIndex = React.useMemo(
    () =>
      states?.findIndex(
        (state) => stateIdentifier(state) == stateIdentifier(currentState || states[0]),
      ) || 0,
    [currentState, states, stateIdentifier],
  );
  const handleClick = React.useCallback(
    (state: T) => {
      if (restrictMoveForward && currentStateIndex < states.indexOf(state)) {
        return;
      }
      stateOnClick?.(state);
    },
    [states],
  );
  if (isMobile) {
    return (
      <ProgressBarWrapper>
        <PBMobileSelectedItem>
          <ProgressBarCircle isSelected />
          {stateLabel(states[currentStateIndex])}
        </PBMobileSelectedItem>
      </ProgressBarWrapper>
    );
  }
  return (
    <ProgressBarWrapper>
      {states?.map((state, index) => (
        <ProgressBarItem
          isActive={index < currentStateIndex}
          isSelected={index <= currentStateIndex}
          widthValue={100 / states.length}
          firstItem={!index}
          key={stateIdentifier(state)}
        >
          <ProgressBarLine />
          <ProgressBarCircle
            onClick={() => handleClick(state)}
            disabled={index > currentStateIndex}
          />
        </ProgressBarItem>
      ))}
    </ProgressBarWrapper>
  );
};

export default WizardProgress;
