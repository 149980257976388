import React from 'react';
import Box from '../Box';
import Typography from '../Typography';

type Props = {
  level: number;
};
const Dot = ({ isActive }: { isActive: boolean }) => (
  <Box
    component="span"
    sx={{
      width: '0.75em',
      height: '0.75em',
      borderRadius: '0.15em',
      marginRight: '0.25em',
      flex: 1,
      backgroundColor: (theme) =>
        (isActive ? theme.palette.secondary.main : '#808080') as string,
    }}
  />
);
const FiveProgress = ({ level }: Props) => {
  const dotArray = [...new Array(5)].map((_, i) => i + 1 <= level);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: '1rem',
        paddingInlineStart: '0.75rem',
      }}
    >
      <Typography variant="h4" sx={{ fontSize: '1.5rem' }}>
        {level}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-evenly',
        }}
      >
        {dotArray.map((isActive, index) => (
          <Dot key={index} isActive={isActive} />
        ))}
      </Box>
    </Box>
  );
};

export default FiveProgress;
