import React from 'react';
import { CardMedia } from '@mui/material';
import Card from '../Card';
import Typography from '../Typography';
import {
  msgCardDescriptionStyle,
  msgCardImgStyle,
  msgCardWrapperStyle,
} from './MsgCard.style';
import ShowIf from '../ShowIf';

const MsgCard = ({
  img,
  title,
  description,
  dataTestId = 'info-card',
  extraStyle = {},
  footerText,
}) => (
  <Card sx={[msgCardWrapperStyle, extraStyle]} data-testid={dataTestId}>
    <CardMedia component="img" image={img} alt="message Card img" sx={msgCardImgStyle} />
    <Typography
      variant="h3"
      sx={{
        fontSize: '1.25rem',
        fontWeight: 700,
        color: 'black',
        pt: '1rem',
      }}
    >
      {title}
    </Typography>
    <ShowIf If={!!description}>
      <Typography sx={msgCardDescriptionStyle} variant="body1">
        {description}
      </Typography>
    </ShowIf>
    <ShowIf If={!!footerText}>
      <Typography sx={msgCardDescriptionStyle} variant="body1">
        {footerText}
      </Typography>
    </ShowIf>
  </Card>
);

export default MsgCard;
