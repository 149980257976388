import EventEmitter, { MessageFn as defaultMessageFnType } from '@utils/eventEmitter';
import { startActivityPayloadType } from '../types';

export enum ActionCardEvents {
  START_GROUP_ACTIVITY = 'START_GROUP_ACTIVITY',
  RESET_ACTIVITY = 'RESET_ACTIVITY',
}

type ActionCardEventEmitterType = {
  [ActionCardEvents.START_GROUP_ACTIVITY]: startActivityPayloadType;
  [ActionCardEvents.RESET_ACTIVITY]: void;
};
export type MessageFnType = defaultMessageFnType<ActionCardEventEmitterType>;

const ActionCardEventEmitter = new EventEmitter<ActionCardEventEmitterType>();
export default ActionCardEventEmitter;
