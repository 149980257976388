import axios from 'axios';
import moment from 'moment';
import { handleError } from './utils';
import { DATE_FORMAT } from '../utils/constants';

// serialiers
function sessionStandardSerializer(sessionStandardInfo) {
  const { activity_type: activityType } = sessionStandardInfo;

  if (activityType === 1) {
    return {
      ...sessionStandardInfo,
      activity_json: undefined,
      lesson: null,
    };
  }
  return sessionStandardInfo;
}

function sessionReviewSerializer(sessionReviewInfo) {
  return sessionReviewInfo;
}

function sessionExamSerializer(sessionExamInfo) {
  const { date, ...newSessionExamInfo } = sessionExamInfo;
  const startDate = moment(sessionExamInfo.start_date, DATE_FORMAT.DATE);
  const endDate = moment(sessionExamInfo.end_date, DATE_FORMAT.DATE);
  const diff = endDate.diff(startDate, 'day') || 0;
  const newDate = moment(date, DATE_FORMAT.DATE);

  newSessionExamInfo.start_date = startDate.format(DATE_FORMAT.DATE);
  newSessionExamInfo.end_date = endDate.format(DATE_FORMAT.DATE);
  return newSessionExamInfo;
}

// APIs

// standard APIs for session

export function addSessionStandard(sessionStandardInfo) {
  const url = '/api/sessions/standard/';
  return axios
    .post(url, sessionStandardSerializer(sessionStandardInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSessionStandard(sessionId, sessionStandardInfo) {
  const url = `/api/sessions/standard/${sessionId}/`;
  return axios
    .put(url, sessionStandardSerializer(sessionStandardInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function shiftSessions(sessionId, dayCount) {
  const url = `/api/sessions/standard/${sessionId}/shift_sessions/`;
  return axios
    .post(url, {'dayCount': dayCount})
    .then((response) => ({ response }))
    .catch(handleError);
}

export function deleteSessionStandard(sessionId) {
  const url = `/api/sessions/standard/${sessionId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// review APIs for session

export function addSessionReview(sessionReviewInfo) {
  const url = '/api/sessions/review/';
  return axios
    .post(url, sessionReviewSerializer(sessionReviewInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSessionReview(sessionId, sessionReviewInfo) {
  const url = `/api/sessions/review/${sessionId}/`;
  return axios
    .put(url, sessionReviewSerializer(sessionReviewInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function deleteSessionReview(sessionId) {
  const url = `/api/sessions/review/${sessionId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function addSessionExam(sessionExamInfo) {
  const url = '/api/sessions/exams/';
  return axios
    .post(url, sessionExamSerializer(sessionExamInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSessionExam(sessionId, sessionExamInfo) {
  const url = `/api/sessions/exams/${sessionId}/`;
  return axios
    .put(url, sessionExamSerializer(sessionExamInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function deleteSessionExam(sessionId) {
  const url = `/api/sessions/exams/${sessionId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
// attendance APIs for session

export function getStandardSessionAttendance(sessionId) {
  const url = `/api/sessions/standard/${sessionId}/attendance/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getReviewSessionAttendance(sessionId) {
  const url = `/api/sessions/review/${sessionId}/attendance/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
