import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSectionStudentWithFilter } from '@reducers/sectionStudent/hooks';
import ColumnSize from './ColumnSize';

import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  MobileWrapper,
  TableSkillLevel,
} from '../Table/TableComponent.style';
import { NotApplicationText } from '../DashboardSummary/DashboardSummary.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { NoContent } from '../Generals/stylesheets/General.style';
import { selectors as SectionStudentSelector } from '../../reducers/sectionStudent';
import { useStandardScore } from '../../sagas/sections/hooks';
import TableLoader from '../LoadingPlaceholder/TableLoader';

const tableValues = [
  {
    uniqKey: 'Student Name',
    value: 'Student Name',
  },
  {
    uniqKey: 'Score',
    value: 'Score',
  },
  {
    uniqKey: 'Time On Task',
    value: 'Time On Task',
  },
  {
    uniqKey: 'Last Practice',
    value: 'Last Practice',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

const secondsToTime = (seconds) => {
  // 65 > 00:01:05
  if (!seconds) return '--';
  return moment.utc(seconds * 1000).format('HH:mm:ss');
};
const dateToDaysFromNow = (date) => {
  // 2022-12-18T10:28:35.548704Z > 2 days ago
  if (!date) return '--';
  return moment(date).fromNow();
};
const StudentTableRaw = ({
  last_name,
  first_name,
  total_score,
  total_max_score,
  time_on_task,
  last_practice,
}) => (
  <TableContentItem>
    <TableContent {...ColumnSize[0]}>
      {last_name} {first_name}
    </TableContent>
    <TableContent {...ColumnSize[1]}>
      {total_score || '-'}/{total_max_score || '-'}
    </TableContent>
    <TableContent {...ColumnSize[2]}>{secondsToTime(time_on_task)}</TableContent>

    <TableContent {...ColumnSize[3]}>{dateToDaysFromNow(last_practice)}</TableContent>
  </TableContentItem>
);
const StudentTableHeader = () => (
  <TableHeadWrapper>
    {tableValues.map((item, index) => {
      const { value, uniqKey } = item;
      return <TableHeader {...ColumnSize[index]} value={value} key={uniqKey} />;
    })}
  </TableHeadWrapper>
);
const selectedStandardScoreDefault = {};
const StandardsTable = ({ selectedStandard }) => {
  const studentData = useSectionStudentWithFilter();
  const { data: standardScoreData, isFetching } = useStandardScore();
  const selectedStandardScore =
    standardScoreData?.[selectedStandard] || selectedStandardScoreDefault;
  if (isFetching) {
    return (
      <TableContainerWrap>
        <TableLoader />
      </TableContainerWrap>
    );
  }
  return (
    <TableContainerWrap>
      {!!studentData.length > 0 ? (
        <MobileWrapper>
          <StudentTableHeader />
          <TableContentWrapper>
            {studentData?.map((student) => (
              <StudentTableRaw
                key={student.id}
                {...student}
                {...(selectedStandardScore?.[student.id] || {})}
              />
            ))}
          </TableContentWrapper>
        </MobileWrapper>
      ) : (
        <NoContent>
          <NotApplicationText>Data not available</NotApplicationText>
        </NoContent>
      )}
    </TableContainerWrap>
  );
};

export default StandardsTable;
