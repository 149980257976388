/* eslint-disable sort-keys */
import React from 'react';
import Tooltip from '@components/Atoms/Tooltip';
import { useCurriculumDetails } from '@reducers/curriculums/hooks';
import {
  LessonScoreResultLeft,
  LessonScoreResultRight,
  LessonScoreResultWrap,
  ScoreName,
  ScorePointLarge,
  ScorePointSmall,
  ScoreResultItem,
  ScoreResultItemLeft,
  ScoreResultItemRight,
  SubScorePoint,
} from '@components/LessonManagement/LessonManagement.style';
import ShowIf from '@components/Atoms/ShowIf';
import useWebSocketListener from '../hooks/useWebSocketListener';
import { ScoreObject } from './types';
import useExamScaledScore from '../hooks/useAverageExamScore';
import { useHasSectionAllCourseExams } from '../hooks/helpers';

const toolTipTitleHelper = (curriculumName: string, maxScore: number | string): string =>
  `The ${curriculumName} scaled score has a maximum value of ${maxScore}`;

type SubScoreCardProps = {
  name: string;
  score: ScoreObject | any;
};

export const TotalScoreCard = ({ curriculumName, averageOverAllScore, maxScore }) => (
  <div data-testid="overall-score-card">
    <LessonScoreResultLeft>
      <ScoreName>{curriculumName}</ScoreName>
      <ScorePointLarge>
        <p>{averageOverAllScore !== null ? Math.round(averageOverAllScore) : '--'}</p>

        <SubScorePoint>{maxScore !== null ? `out of ${maxScore}` : '--'}</SubScorePoint>
      </ScorePointLarge>
    </LessonScoreResultLeft>
  </div>
);

export const SubScoreCard = ({ name, score }: SubScoreCardProps) => (
  <div data-testid="sub-score-card">
    <ScoreResultItem>
      <ScoreResultItemLeft>{name}</ScoreResultItemLeft>
      <ScoreResultItemRight>
        <ScorePointSmall>
          {score?.scaled_score ? (
            <p>{Math.round(score?.scaled_score)}</p>
          ) : (
            <p style={{ width: '41px' }}>--</p>
          )}
          <SubScorePoint>
            {score?.max_scaled_score
              ? `out of ${Math.round(score?.max_scaled_score)}`
              : '--'}
          </SubScorePoint>
        </ScorePointSmall>
      </ScoreResultItemRight>
    </ScoreResultItem>
  </div>
);

const ExamScoreCard = () => {
  const { overall_scaled_score: overAllScaledScore, subscores } = useExamScaledScore();

  const HasAllExamSectionsScheduled = useHasSectionAllCourseExams();

  const curriculumDetails = useCurriculumDetails();
  //  we should move it upwards when it is possible
  useWebSocketListener();

  const toolTipTitle = toolTipTitleHelper(
    curriculumDetails?.name,
    overAllScaledScore?.max_score ?? '--',
  );

  return (
    <div data-testid="score-card">
      <Tooltip title={toolTipTitle} placement="top">
        <LessonScoreResultWrap>
          <ShowIf If={HasAllExamSectionsScheduled}>
            <TotalScoreCard
              curriculumName={curriculumDetails.name}
              averageOverAllScore={overAllScaledScore?.score}
              maxScore={overAllScaledScore?.max_score}
            />
          </ShowIf>
          <LessonScoreResultRight>
            {subscores?.map((subScoreItem) => (
              <SubScoreCard key={subScoreItem.id} {...subScoreItem} />
            ))}
          </LessonScoreResultRight>
        </LessonScoreResultWrap>
      </Tooltip>
    </div>
  );
};

export default ExamScoreCard;
