import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';
import LeftSideBarCourseManagement from '../../containers/LeftSideBar/LeftSideBarCourseManagement';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import StudentInfoItem from '../../containers/CourseManagementStudents/StudentInfoItem';
import MainContent from '../../containers/MainContent';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import ConfirmModal from '../ConfirmModal';
import { UserProfileGroup, UserProfileTitle } from '../Profile/Profile.style';
import {
  PrimaryWrap,
  DefaultContentWrap,
} from '../Generals/stylesheets/General.style';
import {
  TableHeadWrapper,
  TableContentWrapper,
} from '../Table/TableComponent.style';
import { TableHeader } from '../Table/TableComponent';
import ColumnSize from './ColumnSize';
import firstSort from '../../utils/firstSort';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'User',
  },
  {
    uniqKey: 'last_login',
    value: 'Last Activity',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class CourseManagementStudents extends PureComponent {
  static propTypes = {
    fetchCourseStudentList: PropTypes.func.isRequired,
    match: PropTypes.shape(),
    studentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    courseDetail: PropTypes.shape(),
    isSideBarClose: PropTypes.bool,
    isGettingStudentList: PropTypes.bool,
    removeStudent: PropTypes.func.isRequired,
    isOwner: PropTypes.bool,
  };

  constructor(props, context) {
    super(props, context);
    const { fetchCourseStudentList, match } = props;
    const { params } = match;
    this.state = {
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
      removeStudentId: null,
      removeStudentName: null,
    };

    fetchCourseStudentList(params.courseId);
  }

  onCloseConfirmModal = () => {
    this.setState({
      removeStudentId: null,
      removeStudentName: null,
    });
  };

  onOpenConfirmModal = (studentId, studentName) => {
    this.setState({
      removeStudentId: studentId,
      removeStudentName: studentName,
    });
  };

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  renderStudentInfo = (studentInfo) => {
    const { match, isOwner } = this.props;
    const { params } = match;
    return (
      <StudentInfoItem
        isOwner={isOwner}
        studentInfo={studentInfo}
        courseId={params.courseId}
        key={studentInfo.id}
        onOpenConfirmModal={this.onOpenConfirmModal}
      />
    );
  };

  renderEmptyMsg = () => (
    <div>You don&#39;t have any student in this course</div>
  );

  renderStudentListHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderStudentListHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderStudentListHeaderItem)}
      <TableHeader {...ColumnSize[2]} value="Actions" />
    </TableHeadWrapper>
  );

  render() {
    const { orderBy, sortBy, removeStudentId, removeStudentName } = this.state;
    const {
      studentList,
      courseDetail,
      isSideBarClose,
      isGettingStudentList,
      match,
      removeStudent,
    } = this.props;
    const { params } = match;
    firstSort(studentList);
    const filterStudentList = _orderBy(
      studentList,
      [
        (d) => {
          if (sortBy === 'last_name') return d[sortBy].toUpperCase();
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <PrimaryWrap>
        <LeftSideBarCourseManagement pathName="/management/course" />
        <MainContent>
          <BreadcumbsFilter
            haveSub={isSideBarClose}
            breadcumbSubList={[courseDetail.name]}
            breadcumbList={[courseDetail.name, 'Students']}
            mobileTitle="Students Management"
            isDisplayFilter={false}
          />

          <ShadowScrollbar autoHide style={scrollStyle}>
            <DefaultContentWrap>
              <UserProfileGroup>
                <UserProfileTitle>
                  <div>
                    <i className="chalktalk-infor-outline" />
                    <p>Student List</p>
                  </div>
                </UserProfileTitle>

                {isGettingStudentList ? (
                  <TableLoader />
                ) : (
                  <React.Fragment>
                    {!studentList.length ? (
                      this.renderEmptyMsg()
                    ) : (
                      <React.Fragment>
                        {this.renderStudentListHeader()}
                        <TableContentWrapper>
                          {filterStudentList.map(this.renderStudentInfo)}
                        </TableContentWrapper>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}

                <ConfirmModal
                  itemId={removeStudentId}
                  itemName={removeStudentName}
                  onClose={this.onCloseConfirmModal}
                  content="Are you sure to delete this student?"
                  courseId={params.courseId}
                  onSubmit={removeStudent}
                />
              </UserProfileGroup>
            </DefaultContentWrap>
          </ShadowScrollbar>
        </MainContent>
      </PrimaryWrap>
    );
  }
}
