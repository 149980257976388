import { connect } from 'react-redux';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { ExamSectionTable, ExamSectionTableV2 } from '../../components/ExamSectionTable';
import {
  selectors as SectionExamSelectors,
  actions as sectionExamActions,
} from '../../reducers/sectionExam';
import { selectors as examResultSelectors } from '../../reducers/examResult';
import { selectors as sectionStudentSelectors } from '../../reducers/sectionStudent';
import { selectors as userSelectors } from '../../reducers/user';
import { actions as examActions } from '../../reducers/exam';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');
  const examSessionId = _get(match, 'params.examSessionId', '');
  const courseId = _get(match, 'params.courseId', '');
  const students = sectionStudentSelectors.getStudentListWithFilter(state, sectionId);
  const userId = userSelectors.getUserId(state);

  return {
    userId,
    sectionId,
    courseId,
    examSessionId,
    students,
    studentLength: students.length,
    resultOfSection: examResultSelectors.calcTotalResultOfSection(state),
    userRole: userSelectors.getUserRole(state),
    studentSectionReport: examResultSelectors.getSectionReportByUserId(state, userId),
    resultOfStudent: examResultSelectors.getResultOfUser(state, userId),
    results: examResultSelectors.getResults(state),
    examSessons: SectionExamSelectors.getExamSessionList(state),
    studentList: sectionStudentSelectors.getStudentListWithFilter(state, sectionId)
  };
};

const mapDispatchToProps = {
  sectionExamReviewLock: sectionExamActions.sectionExamReviewLock,
  sectionExamReviewUnlock: sectionExamActions.sectionExamReviewUnlock,
  sectionExamUnlock: sectionExamActions.sectionExamUnlock,
  sectionExamPause: sectionExamActions.sectionExamPause,
  sectionExamReset: sectionExamActions.sectionExamReset,
  sectionExamResetExamSection: sectionExamActions.sectionExamResetExamSection,
  examInitializeResponse: examActions.examInitializeResponse,
};

const ConnectedExamSectionTable = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamSectionTable));
const ConnectedExamSectionTableV2 = withRouter(connect(mapStateToProps, mapDispatchToProps)(ExamSectionTableV2));

export { ConnectedExamSectionTable, ConnectedExamSectionTableV2 };

