/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable eqeqeq */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import {
  useShouldShowAdaptivePracticeTab,
  useShouldShowLearnosityAdaptivePracticeTab,
} from '@components/Component/Lesson/LessonContainer/hooks';
import { useCurrentCourse } from '@reducers/courses/hooks';
import {
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  TableIcon,
  TableSkillLevel,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import {
  RecordAttendanceWrap,
  RecordAttendanceTitle,
} from '../RecordAttendanceTab/RecordAttendanceTab.style';
import {
  LessonAssignStatus,
  UnAssignButton,
} from '../LessonManagement/LessonManagement.style';
import {
  DefaultContentWrap,
  ContentFlexWrap,
} from '../Generals/stylesheets/General.style';
import ColumnSize from './ColumnSize';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { calcDurationToSecond } from '../../utils/commonUtils';
import firstSort from '../../utils/firstSort';
import { TableContainer } from './InterventionTab.styles';

const scrollStyle = {
  height: 'calc(100vh - 14em)',
  width: '100%',
};

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'Name',
  },
  {
    uniqKey: 'lesson',
    value: 'Lesson',
    tooltipMsg: 'Student attendance for this lesson',
  },
  {
    uniqKey: 'individual',
    value: 'Group Activity (individual)',
    tooltipMsg: 'Student Scores for the Individual Stage of Group Activity',
  },
  {
    uniqKey: 'group',
    value: 'Group Activity (group)',
    tooltipMsg: 'Student Scores for the Group Stage of Group Activity',
  },
  {
    uniqKey: 'score',
    value: 'Adaptive Practice',
    tooltipMsg: 'Student adaptive practice scores',
  },
  {
    uniqKey: 'level',
    value: 'Skill Level',
  },
  {
    uniqKey: 'finalTot',
    value: 'Time On Task',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

class InterventionTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
    };
  }

  componentDidMount() {
    this.updateAssignmentDetail();
  }

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  updateAssignmentDetail = () => {
    const { match, lessonAssignmentDetail } = this.props;
    const { sectionId, lessonId, unitId } = match.params;

    lessonAssignmentDetail(sectionId, lessonId, unitId);
  };

  renderInterventionHeaderItem = (item, index) => {
    const { hasStandardPractice, hasAdaptivePractice } = this.props;
    const { value, uniqKey, tooltipMsg } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === 'last_name';

    if (hasAdaptivePractice && uniqKey === 'level') {
      return (
        <TableHeader
          {...ColumnSize[index]}
          value={value}
          key={uniqKey}
          dataTip={tooltipMsg}
          onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
        />
      );
    }

    if (hasStandardPractice && ['finalTot', 'level'].includes(uniqKey)) {
      return;
    }

    if (
      !(hasAdaptivePractice || hasStandardPractice) &&
      ['finalTot', 'score'].includes(uniqKey)
    ) {
      return;
    }

    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        dataTip={tooltipMsg}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderInterventionHeader = () => {
    const { hasStandardPractice, hasAdaptivePractice } = this.props;

    return (
      <TableHeadWrapper>
        {tableValues.map(this.renderInterventionHeaderItem)}
        {(hasStandardPractice || hasAdaptivePractice) && (
          <TableHeader {...ColumnSize[7]} value="Actions" />
        )}
      </TableHeadWrapper>
    );
  };

  assignStudent = (student) => () => {
    const { assignLesson, match, currentUserId } = this.props;
    const { params } = match;
    const { sectionId, unitId, lessonId } = params;
    const payload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUserId,
      assigned_to: student.id,
    };

    assignLesson(sectionId, payload);
  };

  unAssignStudent = (student) => () => {
    const { unAssignLesson, match, currentUserId } = this.props;
    const { params } = match;
    const { sectionId, unitId, lessonId } = params;
    const payload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUserId,
      assigned_to: student.id,
    };

    unAssignLesson(sectionId, payload);
  };

  calculateTotalScore = (student, practiceList) => {
    if (!practiceList.length) {
      return [0, 0, null];
    }
    const practiceDataList = practiceList.filter(
      (practice) => practice.user === student.id,
    );

    if (!practiceDataList.length) {
      return [0, 0, null];
    }
    const calculatedList = practiceDataList.map(
      ({ time_on_task: tot, score, max_score: maxScore }) => [score, maxScore, tot],
    );

    return calculatedList.reduce(
      (acc, data) => {
        acc[0] += data[0];
        acc[1] += data[1];
        acc[2] += data[2];
        return acc;
      },
      [0, 0, 0],
    );
  };

  renderStudentItem = (student, index) => {
    const {
      lessonAttendanceList,
      examsScoreSummary,
      match,
      hasStandardPractice,
      assignDetailByUserId,
      skillData,
      groupActivitys,
      hasAdaptivePractice,
    } = this.props;

    let groupScore = '--';
    let studentGroupScore = '--';
    let groupStatus = 0;
    groupActivitys.forEach((groupItem) => {
      const studentData = groupItem.students.find(
        (studentItem) => studentItem.student_id == student.id,
      );
      if (studentData) {
        groupStatus = groupItem.group_status;
        if (groupStatus == 3) {
          groupScore = groupItem.score;
        }
        if (studentData.status == 3) {
          studentGroupScore = studentData.score;
        }
      }
    });

    const { sectionId, unitId, courseId, lessonId, subjectId } = match.params;
    const { first_name: firstName, last_name: lastName, id: studentId } = student;
    const studentName = `${firstName} ${lastName}`;
    const lessonAttendanceRecords = lessonAttendanceList[lessonId].records;

    const isLessonTaken = !_isEmpty(lessonAttendanceRecords[student.id]);

    const [totalScore, maxScore, tot] = this.calculateTotalScore(
      student,
      examsScoreSummary,
    );
    const maxScoreStr = maxScore !== null ? maxScore : '--';
    const totalScoreStr = totalScore !== null ? totalScore : '--';
    const reviewUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/practice-review/student/${studentId}`;
    const skillLevel = _get(skillData, `${studentId}.current`, '-');
    const userAssigned = _get(assignDetailByUserId, studentId, null);
    const lastCompleted = _get(userAssigned, 'last_completed', null);
    const lastAssigned = _get(userAssigned, 'last_assigned', null);
    const newAssigned = _get(userAssigned, 'assigned', null);
    const isAssigned =
      newAssigned ||
      (!lastCompleted && lastAssigned) ||
      moment(lastAssigned).isAfter(moment(lastCompleted));
    const finalTot = tot !== null ? calcDurationToSecond(tot) : null;

    let attendanceLessonClassName;
    if (isLessonTaken) {
      if (lessonAttendanceRecords[student.id].present === null) {
        attendanceLessonClassName = 'chalktalk-grey-circle';
      } else if (lessonAttendanceRecords[student.id].present === true) {
        attendanceLessonClassName = 'chalktalk-check-success';
      } else {
        attendanceLessonClassName = 'chalktalk-not-checked';
      }
    } else {
      attendanceLessonClassName = 'chalktalk-grey-circle';
    }

    return (
      <TableContentItem key={index}>
        {/* NAME */}
        <TableContent {...ColumnSize[0]}>{studentName}</TableContent>

        {/* ATTENDANCE */}
        <TableContent {...ColumnSize[1]} largeBorder>
          <TableIcon large className={attendanceLessonClassName} />
        </TableContent>

        {/* ONLINE GROUP ACTIVITY */}
        <TableContent {...ColumnSize[2]} largeBorder>
          {studentGroupScore}
        </TableContent>
        <TableContent {...ColumnSize[3]} largeBorder>
          {groupScore}
        </TableContent>

        {/* ADAPTIVE / STANDARDS PRACTICE */}
        {(hasAdaptivePractice || hasStandardPractice) && (
          <TableContent {...ColumnSize[4]}>
            <Link
              to={reviewUrl}
              style={maxScoreStr == null ? { pointerEvents: 'none' } : null}
            >
              {`${totalScoreStr}/${maxScoreStr}`}
            </Link>
          </TableContent>
        )}

        {/* SKILL LEVEL */}
        {(!hasStandardPractice || hasAdaptivePractice) && (
          <TableContent {...ColumnSize[5]}>
            <TableSkillLevel level={skillLevel}>{skillLevel}</TableSkillLevel>
          </TableContent>
        )}

        {/* TIME ON TASK */}
        {hasAdaptivePractice && !hasStandardPractice && (
          <TableContent {...ColumnSize[6]}>
            {finalTot !== null ? finalTot : '--'}
          </TableContent>
        )}

        {/* ASSIGN */}
        {(hasAdaptivePractice || hasStandardPractice) && (
          <TableContent {...ColumnSize[7]} largeBorder>
            {isAssigned ? (
              <LessonAssignStatus isAssigned>
                Assigned
                <UnAssignButton
                  className="chalktalk-close"
                  onClick={this.unAssignStudent(student)}
                />
              </LessonAssignStatus>
            ) : (
              <LessonAssignStatus onClick={this.assignStudent(student)}>
                Assign
              </LessonAssignStatus>
            )}
          </TableContent>
        )}
      </TableContentItem>
    );
  };

  renderInterventionTable = () => {
    const { studentList } = this.props;
    const { orderBy, sortBy } = this.state;
    firstSort(studentList);
    const filterStudentList = _orderBy(
      studentList,
      [
        (d) => {
          if (sortBy === 'last_name') {
            return d[sortBy].toUpperCase();
          }
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <TableContainer>
        {this.renderInterventionHeader()}
        <TableContentWrapper>
          {filterStudentList.map(this.renderStudentItem)}
        </TableContentWrapper>
      </TableContainer>
    );
  };

  render() {
    return (
      <DefaultContentWrap>
        <ContentFlexWrap>
          <RecordAttendanceWrap>
            <ShadowScrollbar autoHide style={scrollStyle}>
              <RecordAttendanceTitle>
                <i className="chalktalk-info" />
                Click on topic score to view the student&#39;s practice sessions&#39;
                reports and review their answers.
              </RecordAttendanceTitle>

              {this.renderInterventionTable()}
            </ShadowScrollbar>
          </RecordAttendanceWrap>
        </ContentFlexWrap>
      </DefaultContentWrap>
    );
  }
}

InterventionTab.propTypes = {
  studentList: PropTypes.array,
  assignLesson: PropTypes.func.isRequired,
  unAssignLesson: PropTypes.func.isRequired,
  isAssigning: PropTypes.bool,
  assignErrorMsg: PropTypes.string,
  match: PropTypes.shape().isRequired,
  currentUserId: PropTypes.number,
  lessonActivityList: PropTypes.array,
  examsScoreSummary: PropTypes.array,
  lessonAssignmentDetail: PropTypes.func,
  assignDetailByUserId: PropTypes.object,
  skillData: PropTypes.object,
  hasStandardPractice: PropTypes.bool,
  groupActivitys: PropTypes.array,
  hasAdaptivePractice: PropTypes.bool,
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const courseDetails = useCurrentCourse();
    const hasStandardPracticeEnabled = courseDetails?.standard_practice_enabled;
    const shouldShowAdaptivePracticeTab = useShouldShowAdaptivePracticeTab();
    const shouldShowLearnosityAdaptivePracticeTab =
      useShouldShowLearnosityAdaptivePracticeTab();

    return (
      <WrappedComponent
        {...props}
        hasStandardPractice={hasStandardPracticeEnabled}
        hasAdaptivePractice={
          shouldShowAdaptivePracticeTab || shouldShowLearnosityAdaptivePracticeTab
        }
      />
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(InterventionTab);
