import _groupBy from 'lodash/groupBy';
import _get from 'lodash/get';

export const LESSON_ASSIGNMENT_ASSIGN = 'lessonAssignment/ASSIGN';
export const LESSON_ASSIGNMENT_ASSIGN_SUCCESS = 'lessonAssignment/ASSIGN_SUCCESS';
export const LESSON_ASSIGNMENT_ASSIGN_FAILED = 'lessonAssignment/ASSIGN_FAILED';

export const LESSON_ASSIGNMENT_UNASSIGN = 'lessonAssignment/UNASSIGN';
export const LESSON_ASSIGNMENT_UNASSIGN_SUCCESS = 'lessonAssignment/UNASSIGN_SUCCESS';
export const LESSON_ASSIGNMENT_UNASSIGN_FAILED = 'lessonAssignment/UNASSIGN_FAILED';

export const LESSON_ASSIGNMENT_ASSIGN_BULK = 'lessonAssignment/ASSIGN_BULK';
export const LESSON_ASSIGNMENT_ASSIGN_BULK_SUCCESS = 'lessonAssignment/ASSIGN_BULK_SUCCESS';
export const LESSON_ASSIGNMENT_ASSIGN_BULK_FAILED = 'lessonAssignment/ASSIGN_BULK_FAILED';

export const LESSON_ASSIGNMENT_DETAIL = 'lessonAssignment/ASSIGNMENT_DETAIL';
export const LESSON_ASSIGNMENT_DETAIL_SUCCESS = 'lessonAssignment/ASSIGNMENT_DETAIL_SUCCESS';
export const LESSON_ASSIGNMENT_DETAIL_FAILED = 'lessonAssignment/ASSIGNMENT_DETAIL_FAILED';

// ===============================
const isAssigning = ({ lessonAssignment }) => lessonAssignment.isAssigning;
const assignErrorMsg = ({ lessonAssignment }) => lessonAssignment.assignErrorMsg;

const getLAByLessonId = ({ lessonAssignment }, lessonId) => _get(lessonAssignment, `assignmentByLessonId.${lessonId}`, []);
const getLAByUnitId = ({ lessonAssignment }, unitId) => _get(lessonAssignment, `assignmentByUnitId.${unitId}`, []);

export const selectors = {
  isAssigning,
  assignErrorMsg,
  getLAByLessonId,
  getLAByUnitId,
};

// ===============================

const lessonAssignmentDetail = (sectionId, lessonId, unitId) => ({
  type: LESSON_ASSIGNMENT_DETAIL,
  sectionId,
  lessonId,
  unitId,
});

const lessonAssignmentDetailSuccess = (detailList) => ({
  type: LESSON_ASSIGNMENT_DETAIL_SUCCESS,
  payload: detailList,
});

const lessonAssignmentDetailFail = (error) => ({
  type: LESSON_ASSIGNMENT_DETAIL_FAILED,
  error,
});

const lessonAssignmentBulk = (sectionId, assignData) => ({
  type: LESSON_ASSIGNMENT_ASSIGN_BULK,
  sectionId,
  assignData,
});

const lessonAssignmentBulkSuccess = (detailList) => ({
  type: LESSON_ASSIGNMENT_ASSIGN_BULK_SUCCESS,
  payload: detailList,
});

const lessonAssignmentBulkFail = (error) => ({
  type: LESSON_ASSIGNMENT_ASSIGN_BULK_FAILED,
  error,
});

/**
 *
 * @param sectionId
 * @param assignData
 * {
    "section": 1,
    "unit": 1,
    "lesson_group": 473,
    "assigned_by": 26,
    "assigned_to": 8,
   }
 * @returns {{assignData: *, sessionId: *, type: *}}
 */
const assignLesson = (sectionId, assignData, showMessage = true) => ({
  type: LESSON_ASSIGNMENT_ASSIGN,
  sectionId,
  assignData,
  showMessage,
});

const assignLessonSuccess = (sessionId, assignData) => ({
  type: LESSON_ASSIGNMENT_ASSIGN_SUCCESS,
  sessionId,
  assignData,
});

const assignLessonFailed = (errorMsg) => ({
  type: LESSON_ASSIGNMENT_ASSIGN_FAILED,
  errorMsg,
});

const unAssignLesson = (sectionId, assignData, showMessage = true) => ({
  type: LESSON_ASSIGNMENT_UNASSIGN,
  sectionId,
  assignData,
  showMessage,
});

const unAssignLessonSuccess = (assginedTo, lessonId, unitId) => ({
  type: LESSON_ASSIGNMENT_UNASSIGN_SUCCESS,
  assginedTo,
  lessonId,
  unitId,
});

const unAssignLessonFailed = (errorMsg) => ({
  type: LESSON_ASSIGNMENT_UNASSIGN_FAILED,
  errorMsg,
});

export const actions = {
  assignLesson,
  assignLessonSuccess,
  assignLessonFailed,

  unAssignLesson,
  unAssignLessonSuccess,
  unAssignLessonFailed,

  lessonAssignmentBulk,
  lessonAssignmentBulkSuccess,
  lessonAssignmentBulkFail,

  lessonAssignmentDetail,
  lessonAssignmentDetailSuccess,
  lessonAssignmentDetailFail,
};

// ===============================

const initialState = {
  isAssigning: false,
  assignErrorMsg: '',
  assignmentByLessonId: {},
  assignmentByUnitId: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LESSON_ASSIGNMENT_ASSIGN: {
      return {
        ...state,
        isAssigning: true,
        assignErrorMsg: '',
      };
    }

    case LESSON_ASSIGNMENT_ASSIGN_SUCCESS: {
      const { assignData } = action;
      const { lesson_group: lessonId, unit: unitId } = assignData;
      const currentAssignByLessonId = _get(state, `assignmentByLessonId.${lessonId}`, []);
      const currentAssignByUnitId = _get(state, `assignmentByUnitId.${unitId}`, []);

      return {
        ...state,
        isAssigning: false,
        assignmentByLessonId: {
          ...state.assignmentByLessonId,
          [lessonId]: [...currentAssignByLessonId, assignData],
        },
        assignmentByUnitId: {
          ...state.assignmentByUnitId,
          [unitId]: [...currentAssignByUnitId, assignData],
        },
      };
    }

    case LESSON_ASSIGNMENT_ASSIGN_FAILED: {
      const { errorMsg } = action;
      return {
        ...state,
        isAssigning: false,
        assignErrorMsg: errorMsg,
      };
    }

    case LESSON_ASSIGNMENT_UNASSIGN: {
      return {
        ...state,
        isAssigning: true,
        assignErrorMsg: '',
      };
    }

    case LESSON_ASSIGNMENT_UNASSIGN_SUCCESS: {
      const { assginedTo, lessonId, unitId } = action;
      const currentAssignByLessonId = _get(state, `assignmentByLessonId.${lessonId}`, []);
      const currentAssignByUnitId = _get(state, `assignmentByUnitId.${unitId}`, []);
      const assignmentByLessonList = currentAssignByLessonId.filter((assign) => assign.assigned_to !== assginedTo);
      const assignmentByUnitList = currentAssignByUnitId.filter((assign) => assign.assigned_to !== assginedTo);

      return {
        ...state,
        isAssigning: false,
        assignmentByLessonId: {
          ...state.assignmentByLessonId,
          [lessonId]: [
            ...assignmentByLessonList,
          ],
        },
        assignmentByUnitId: {
          ...state.assignmentByUnitId,
          [unitId]: [
            ...assignmentByUnitList,
          ],
        },
      };
    }

    case LESSON_ASSIGNMENT_UNASSIGN_FAILED: {
      const { errorMsg } = action;
      return {
        ...state,
        isAssigning: false,
        assignErrorMsg: errorMsg,
      };
    }

    case LESSON_ASSIGNMENT_DETAIL_SUCCESS: {
      const { payload = [] } = action;
      const assignmentByLessonId = _groupBy(payload, 'lesson_group');
      const assignmentByUnitId = _groupBy(payload, 'unit');

      return {
        ...state,
        assignmentByLessonId,
        assignmentByUnitId,
      };
    }
    default: {
      return state;
    }
  }
}
