/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  TableContentItem,
  TableContentGroup,
  TableIcon,
} from '../Table/TableComponent.style';
import {
  UserActionWrap,
  UserActionPopup,
  UserActionItem,
} from '../Profile/Profile.style';
import { TableContent } from '../Table/TableComponent';
import { ColumnSize } from './ColumnSize';
import { OverlayWrap } from '../Generals/stylesheets/General.style';

class TeacherInfoItem extends Component {
  state = {
    actionPopupById: null,
  };

  onCloseActionPopup = () => {
    this.setState({
      actionPopupById: null,
    });
  };

  onOpenActionPopup = (teacherId) => {
    this.setState({
      actionPopupById: teacherId,
    });
  };

  addOwner = (teacherId) => () => {
    const { courseTeacherAddOwner, courseId } = this.props;
    courseTeacherAddOwner(courseId, teacherId);
  };

  removeOwner = (teacherId) => () => {
    const { courseTeacherRemoveOwner, courseId } = this.props;
    courseTeacherRemoveOwner(courseId, teacherId);
  };

  renderActionPopup = () => {
    const { actionPopupById } = this.state;
    const { ownerIds, onOpenConfirmModal, teacherInfo } = this.props;
    const isOwner = !!ownerIds.find((itemId) => itemId === actionPopupById);
    const teacherName = `${teacherInfo.first_name} ${teacherInfo.last_name}`
    return (
      <UserActionPopup>
        {isOwner ? (
          <UserActionItem red onClick={this.removeOwner(actionPopupById)}>
            Remove Owner
          </UserActionItem>
        ) : (
          <UserActionItem blue onClick={this.addOwner(actionPopupById)}>
            Make Owner
          </UserActionItem>
        )}
        <UserActionItem onClick={() => onOpenConfirmModal(actionPopupById, teacherName)}>
          Remove
        </UserActionItem>
      </UserActionPopup>
    );
  };

  render() {
    const { teacherInfo, ownerIds, isUserOwner } = this.props;
    const { actionPopupById } = this.state;
    const { id, last_login: lastLogin } = teacherInfo;
    const isOwner = !!ownerIds.find((itemId) => itemId === id);
    const isActionPopupOpen = actionPopupById === id;
    const lastAcitivity = moment(lastLogin).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const diffDateActivity = moment(today).diff(lastAcitivity, 'days');

    return (
      <TableContentItem smallSize noBorder key={teacherInfo.id}>
        <TableContent {...ColumnSize[0]}>
          <TableContentGroup>
            <p>{`${teacherInfo.first_name} ${teacherInfo.last_name}`}</p>
            <span>{teacherInfo.email}</span>
          </TableContentGroup>
        </TableContent>
        <TableContent {...ColumnSize[1]}>{isOwner.toString()}</TableContent>
        <TableContent {...ColumnSize[2]}>
          {lastLogin
            ? diffDateActivity > 30
              ? '30+ days'
              : moment(lastLogin).fromNow()
            : '--'}
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          {isUserOwner && (
            <UserActionWrap>
              <TableIcon
                className="chalktalk-more"
                onClick={() => this.onOpenActionPopup(id)}
              />
              {isActionPopupOpen && (
                <React.Fragment>
                  {this.renderActionPopup()}
                  <OverlayWrap
                    isOpen={isActionPopupOpen}
                    onClick={this.onCloseActionPopup}
                  />
                </React.Fragment>
              )}
            </UserActionWrap>
          )}
        </TableContent>
      </TableContentItem>
    );
  }
}

TeacherInfoItem.propTypes = {
  courseId: PropTypes.string.isRequired,
  teacherInfo: PropTypes.shape(),
  ownerIds: PropTypes.array,
  courseTeacherAddOwner: PropTypes.func,
  courseTeacherRemoveOwner: PropTypes.func,
  isUserOwner: PropTypes.bool,
  onOpenConfirmModal: PropTypes.func,
};

export default TeacherInfoItem;
