/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import {
  LoginItemWrap,
  LoginItem,
  LoginPasswordAction,
  ButtonStyled,
  LoginErrorMessage,
} from '../LoginPage/Login.style';
import { Arrow, Paragraph, Title } from '../LoginPage/SignUpType.style';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().email('Invalid Email').trim().required('Required'),
  sectionCode: Yup.string().trim().required('Invalid section code'),
  grade: Yup.string().ensure().required('Section Grade is required!'),
  password: Yup.string()
    .trim()
    .notOneOf(['123456', 123456], 'Your password is too simple')
    .min(8, 'Password must be a minimum of 8 characters long')
    .matches(/[0-9]/, 'The password contains at least one number')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Your password and confirm password is not match',
    )
    .required('Required'),
});

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  sectionCode: '',
  password: '',
  confirmPassword: '',
  grade: '',
};

const gradeOption = [];
for (let i = 3; i <= 12; i++) {
  gradeOption.push(
    {
      id: `${i-2}`,
      grade_level: `${i}`,
    }
  );
}

export default class StudentRegisterForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
      gitMessage: false,
    };
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    registerStudent: PropTypes.func.isRequired,
    errorMessage: PropTypes.any,
  };

  state = {
    isShowPassword: false,
    gitMessage: false,
  };

  toggleShowPassword = (isShowPassword) => {
    this.setState({
      isShowPassword,
    });
  };

  registerStudent = (values) => {
    const { registerStudent } = this.props;
    registerStudent(values);
    this.setState({ gitMessage: true });
  };

  renderPasswordAction = () => (
    <LoginPasswordAction>
      <label>
        <input type="checkbox" />
        <span className="checkmark" />
        <span>Remember me</span>
      </label>
    </LoginPasswordAction>
  );

  renderLoginBtn = () => {
    const { isLoading } = this.props;
    return isLoading ? (
      <ButtonStyled primary>Loading ...</ButtonStyled>
    ) : (
      <ButtonStyled type="submit">Create account</ButtonStyled>
    );
  };

  renderErrorMessage = (error, index) => {
    if (this.state.gitMessage) {
      return (<p key={index}>{error}</p>);
    }
  }



  componentDidMount() {
    this.setState({ gitMessage: false });
  }

  render() {
    const { errorMessage } = this.props;
    const { isShowPassword } = this.state;

    return (
      <LoginItemWrap>
        <LoginItem>
          <Link to="/login">
            <Arrow src="/images/left.png" />
          </Link>
          <Title>Welcome to ChalkTalk</Title>
          <Paragraph>
            <br />
            ChalkTalk will help you to boost your grades!
          </Paragraph>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={this.registerStudent}
          >
            <Form noValidate>
              <FormInput type="text" name="firstName" label="First Name" />
              <FormInput type="text" name="lastName" label="Last Name" />
              <FormInput type="email" name="email" label="Email" />
              <FormInput type="text" name="sectionCode" label="Section Code" />
              <FormInput
                type="select"
                name="grade"
                label="Section Grade"
                options={gradeOption}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.grade_level}
              />
              <FormInput
                type={isShowPassword ? 'text' : 'password'}
                name="password"
                label="Password"
              />
              <FormInput
                type="password"
                name="confirmPassword"
                label="Confirm Password"
              />
              {this.renderPasswordAction()}
              {errorMessage && (
                <LoginErrorMessage>
                  {errorMessage.map(this.renderErrorMessage)}
                </LoginErrorMessage>
              )}
              {this.renderLoginBtn()}
            </Form>
          </Formik>
        </LoginItem>
      </LoginItemWrap>
    );
  }
}
