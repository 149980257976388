import { useQuery } from 'react-query';
import {
  getActivityGroups,
  getGroupActivities,
  getGroupActivityInfo,
  getInProgressOnlineLessonPractice,
} from '@apis/groupActivity';
import { useParams } from 'react-router-dom';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import {
  GroupActivityInformationType,
  activityGroupType,
  onlineActivityType,
} from '../../types';

const placeholderArray = [];

export const useOnlineActivities = (lessonId: number | undefined) => {
  const { sectionId, lessonId: lessonGroupId } = useParams();
  const queryResponse = useQuery<onlineActivityType[]>(
    ['getOnlineActivity', lessonId, lessonGroupId],
    async () => {
      const { response } = (await getGroupActivities(
        sectionId,
        lessonGroupId,
        lessonId,
      )) as unknown as {
        response: { data: onlineActivityType[] };
      };

      return response.data || [];
    },
    {
      enabled: !!lessonGroupId && !!lessonId,
      placeholderData: placeholderArray as onlineActivityType[],
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
    },
  );

  return queryResponse?.data?.[0]?.id;
};
const placeholderObject = {};
export const useGetGroupActivityInfo = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _ = undefined,
  { refetchOnMount }: { refetchOnMount: boolean | 'always' } = {
    refetchOnMount: true,
  },
) => {
  const { sectionId } = useParams();
  const { data } = useGetLessonDetail();
  const lessonId = data?.lessons[0]?.id;
  const groupActivityId = useOnlineActivities(lessonId);
  const response = useQuery(
    ['getGroupActivityInfo', groupActivityId],
    async () => {
      const res = (await getGroupActivityInfo(groupActivityId, sectionId)) as {
        data: GroupActivityInformationType;
      };
      return res.data || ({} as GroupActivityInformationType);
    },
    {
      enabled: !!groupActivityId,
      placeholderData: placeholderObject as GroupActivityInformationType,
      useErrorBoundary: true,
      refetchOnMount,
      staleTime: 1000 * 60 * 15,
      onError: (error: any) => {
        console.error(error.message);
      },
    },
  );
  if (!response.isSuccess) {
    return {
      ...response,
      data: placeholderObject as GroupActivityInformationType,
    };
  }
  return response;
};

export const useActivityGroups = (
  groupActivityId,
  { refetchOnMount }: { refetchOnMount: boolean | 'always' } = {
    refetchOnMount: true,
  },
) => {
  const response = useQuery<activityGroupType[]>(
    ['getActivityGroups', groupActivityId],
    async () => {
      const res = (await getActivityGroups(groupActivityId)) as unknown as {
        data: activityGroupType[];
      };
      return res?.data || [];
    },
    {
      enabled: !!groupActivityId,
      refetchOnMount,
      placeholderData: placeholderArray as activityGroupType[],
      staleTime: 1000 * 60 * 15,
    },
  );

  return response;
};

export const queryKey = 'inProgressOLP';

type InProgressOLP = {
  id: null | number;
};

export const useGetInProgressOLPId = () => {
  const { sectionId } = useParams();
  const { data: inProgressOLP } = useQuery<InProgressOLP>(
    queryKey,
    async () => {
      const res = (await getInProgressOnlineLessonPractice(sectionId)) as unknown as {
        response: {
          data: InProgressOLP;
        };
      };
      return res?.response?.data;
    },
    {
      enabled: !!sectionId,
      refetchOnMount: 'always',
      staleTime: 1000 * 60,
    },
  );

  return inProgressOLP?.id;
};
