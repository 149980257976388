import styled, { css } from 'styled-components';

export const PDFView = styled.div`
  position: absolute;
  width: calc(100% - 30em);
  height: 100%;
  left: 0em;
  display: flex;
  justify-content: center;
  padding: 2em;
  transition: all 500ms ease;
  box-shadow: 0px 5px 6px 0px ${props => props.theme.colorStyled.ColorBoxShadow};  
  .pdf-file-wrapper {
    flex: 1;
    background: ${props => props.theme.colorStyled.ColorWhite};
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    position: relative;
    .react-pdf__Document {
      flex: 1;
      user-select: none;
      height: 100%;
      display: flex;
      height: 100%;
      justify-content: center;
    }
    .react-pdf__Page__canvas {
      width: ${({ zoomNumber }) => zoomNumber && zoomNumber}% !important;
      height: auto !important;
      margin: 0 auto;
    }
    .react-pdf__Page__annotations {
      max-height: 1.5em;
    }
    .react-pdf__message {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .react-pdf__Page__textContent {
      span {
        transform: perspective(1px) translate3d(0,0,0) !important;
      }
    }
  }
  ${({ isAnswerSheetOpen }) => isAnswerSheetOpen && css`
    left: -100em;
  `}
  ${({ isViewer }) => isViewer && css`
    width: 100% !important;
  `}
  @media (max-width: 1170px) {
    padding: 1.5em;
    width: calc(100% - 20em);
  }
  @media (max-width: 768px) {
    position: unset;
    width: 100%;
    height: 50vh;
    box-shadow: 0px 0px 3px 0px ${props => props.theme.colorStyled.ColorBoxShadow};
    .pdf-file-wrapper {
      .react-pdf__Document {
        flex: 1;
        user-select: none;
      }
    }
  }
`;

export const PDFPagination = styled.p`
  margin: 0 .75em;
`;

export const PDFGroupAction = styled.div`
  flex: 0 0 3.5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colorStyled.ColorGrey};
  i {
    font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
    &:hover {
      color: ${props => props.theme.colorStyled.ColorBlack};
    }
  }
`;

export const PDFZoomAction = styled.div`
  position: absolute;
  right: 1.5em;
  top: 1.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  i {
    background: #717574;
    border-radius: 100%;
    margin-bottom: .5em;
    color: ${props => props.theme.colorStyled.ColorWhite};
    width: 2.5em;
    height: 2.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.8;
    }
  }
`;

export const PDFIcon = styled.i`
  cursor: pointer;
`;
