/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import ShadowSrollbar from '../Scrollbar/ShadowScrollbars';
import { EXAM_SESSION_TYPES, EXAM_LENGTH_TYPES, SESSION_EXAM_TYPE } from '../../utils/constants';
import {
  ExamScoreTableHeader,
  ExamScoreSelectWrap,
  ExamScoreSelectValue,
  ExamScoreFlex,
  ExamScoreUpcomingWrap,
  ExamScoreSelectGroup,
  ExamScoreTableContent,
} from '../DashboardExamScoreReport/DashboardExamScoreReport.style';
import ColumnSize from './ColumnSize';
import {
  NavBarList,
  NavBarItemWrap,
  NavBarItemCustome,
  NavBarItemHead,
  NavBarHeadText,
  NavBarName,
  NavBarStatusColumn,
  NavBarIconWrap,
  NavBarIcon,
  NavBarItemDateWrap,
  NavBarItemDay,
  NavBarItemMonth,
} from '../SubNavBarItem/SubNavBar.style';
import {
  DashboardStatisticWrap,
  DashboardStatisticGroup,
  DashboardLine,
  SummaryBlockWrap,
  SummaryBlockContent,
  SummaryBlockItem,
  SummaryScoreGroup,
  SummaryImage,
  SummaryScore,
  SummaryText,
  SummaryNumber,
  SummarySubText,
  SummaryRank,
} from '../DashboardSummary/DashboardSummary.style';
import {
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { BlockWrap, BlockTitle } from '../Generals/stylesheets/General.style';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

const tableValues = [
  {
    uniqKey: 'studentName',
    value: 'Student',
  },
  {
    uniqKey: 'estimateScore',
    value: 'Estimate Score',
  },
  {
    uniqKey: 'placementTest',
    value: 'Placement Test',
  },
  {
    uniqKey: 'midTest',
    value: 'Mid Test',
  },
  {
    uniqKey: 'exitTest',
    value: 'Exit Test',
  },
  {
    uniqKey: 'improvementPercent',
    value: 'Score Improvement',
  },
];

const examScoreData = [
  {
    estimateScore: '1285-1445',
    exitTest: '--',
    improvementPercent: '--',
    midTest: '--',
    placementTest: '--',
    studentName: 'Jake Hung',
  },
  {
    estimateScore: '717-877',
    exitTest: '--',
    improvementPercent: '--',
    midTest: '--',
    placementTest: '--',
    studentName: 'Kylie Hanscom',
  },
  {
    estimateScore: '1097-1257',
    exitTest: '--',
    improvementPercent: '--',
    midTest: '--',
    placementTest: '--',
    studentName: 'Matt Smith',
  },
  {
    estimateScore: '625-785',
    exitTest: '--',
    improvementPercent: '--',
    midTest: '--',
    placementTest: '--',
    studentName: 'Maya Lee',
  },
  {
    estimateScore: '830-990',
    exitTest: '--',
    improvementPercent: '--',
    midTest: '--',
    placementTest: '--',
    studentName: 'Nael Musleh',
  },

];

export default class DashboardExamScoreReportFake extends Component {
  renderExamScoreTableHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
      />
    );
  }

  renderExamScoreTableHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderExamScoreTableHeaderItem)}
    </TableHeadWrapper>
  );

  renderSumaryTableContent = (values, index) => (
    <TableContentItem key={index}>
      <TableContent {...ColumnSize[0]}>
        {values.studentName}
      </TableContent>
      <TableContent {...ColumnSize[1]}>
        {values.estimateScore}
      </TableContent>
      <TableContent {...ColumnSize[2]}>
        {values.placementTest}
      </TableContent>
      <TableContent {...ColumnSize[3]}>
        {values.midTest}
      </TableContent>
      <TableContent {...ColumnSize[4]}>
        {values.exitTest}
      </TableContent>
      <TableContent {...ColumnSize[5]}>
        {values.improvementPercent}
      </TableContent>
    </TableContentItem>
  )

  renderScoreTypeSelect = () => (
    <ExamScoreSelectWrap>
      <ExamScoreSelectGroup>
        <p>Subject: </p>
        <ExamScoreSelectValue>Overall</ExamScoreSelectValue>
      </ExamScoreSelectGroup>
    </ExamScoreSelectWrap>
  )

  renderProgressBarItem = () => {
    const isCompled = false;
    return (
      <NavBarStatusColumn>
        <NavBarIconWrap isSingleItem isFirstItem={false} isLastItem={false}>
          {isCompled
            ? <NavBarIcon className="chalktalk-check-success" />
            : <NavBarIcon className="chalktalk-in-progress" />}
        </NavBarIconWrap>
      </NavBarStatusColumn>
    );
  }

  renderExamDate = (startDate, endDate) => {
    const examDate = moment(startDate, 'YYYY-MM-DD');
    const newEndDate = moment(endDate, 'YYYY-MM-DD');
    return (
      <NavBarItemDateWrap>
        <NavBarItemMonth>
          {`${examDate.format('MMM')}`}
        </NavBarItemMonth>
        <NavBarItemDay wide>
          {`${examDate.format('DD')} - ${newEndDate.format('DD')}`}
        </NavBarItemDay>
        <NavBarItemMonth>
          {`${examDate.format('YYYY')}`}
        </NavBarItemMonth>
      </NavBarItemDateWrap>
    );
  }

  renderUpcomingItem = (examItem) => {
    const {
      session_type: sessionType = 0, length_type: lengthType, exam,
      start_date: startDate, end_date: endDate, id,
    } = examItem;
    const title = `${EXAM_SESSION_TYPES.title[sessionType]} Test`;
    const lengthTittle = EXAM_LENGTH_TYPES.title[lengthType];
    const { exam_type: examType } = exam;
    const examTypeTitle = SESSION_EXAM_TYPE[examType];
    const topTitle = `${lengthTittle} - ${examTypeTitle}`;

    return (
      <NavBarList key={id.toString()}>
        <NavBarItemWrap>
          {this.renderProgressBarItem()}
          <NavBarItemCustome>
            <NavBarItemHead>
              <NavBarHeadText>
                {topTitle}
              </NavBarHeadText>
            </NavBarItemHead>
            <NavBarName onClick={() => this.navigateToExamPage(id)}>{title}</NavBarName>
            {/* {this.renderPropertiesBar()} */}
          </NavBarItemCustome>
          {this.renderExamDate(startDate, endDate)}
        </NavBarItemWrap>
      </NavBarList>
    );
  }

  renderScoreImprovement = () => (
    <SummaryBlockWrap>
      <span>Estimated Scores</span>

      <SummaryBlockItem>
        <SummaryImage src="/images/bg-score.png" />

        <SummaryBlockContent>
          <SummaryText>Estimated Score</SummaryText>
          <SummaryScoreGroup>
            <SummaryScore>
              <SummaryNumber>911 - 1071</SummaryNumber>
              <SummarySubText>out of 1600</SummarySubText>
            </SummaryScore>
          </SummaryScoreGroup>
        </SummaryBlockContent>

        <SummaryBlockContent>
          <SummaryText>Lexile Level</SummaryText>
          <SummaryScoreGroup>
            <SummaryScore>
              <SummaryNumber>9th</SummaryNumber>
              <SummarySubText>1050L - 1260L</SummarySubText>
            </SummaryScore>
            <SummaryRank>
              <i className="chalktalk-arrow-up" />
              <span>1</span>
            </SummaryRank>
          </SummaryScoreGroup>
        </SummaryBlockContent>
      </SummaryBlockItem>
    </SummaryBlockWrap>
  )

  renderScoreDetail = () => (
    <SummaryBlockWrap>
      <span>Estimated Subject Scores</span>

      <SummaryBlockItem>
        <SummaryBlockContent>
          <SummaryText>Math</SummaryText>
          <SummaryScoreGroup>
            <SummaryScore>
              <SummaryNumber>680 - 760</SummaryNumber>
              <SummarySubText>out of 800</SummarySubText>
            </SummaryScore>
          </SummaryScoreGroup>
        </SummaryBlockContent>

        <SummaryBlockContent>
          <SummaryText>English</SummaryText>
          <SummaryScoreGroup>
            <SummaryScore>
              <SummaryNumber>650 - 730</SummaryNumber>
              <SummarySubText>out of 800</SummarySubText>
            </SummaryScore>
          </SummaryScoreGroup>
        </SummaryBlockContent>
      </SummaryBlockItem>
    </SummaryBlockWrap>
  )

  render() {
    return (
      <ShadowSrollbar autoHide style={scrollStyle}>
        <ExamScoreFlex>
          <ExamScoreTableContent>
            <BlockWrap>
              <BlockTitle>
                <span>Summary</span>
              </BlockTitle>
              <DashboardStatisticWrap>
                <DashboardStatisticGroup>
                  {this.renderScoreImprovement()}
                  {this.renderScoreDetail()}
                </DashboardStatisticGroup>
              </DashboardStatisticWrap>
            </BlockWrap>

            <BlockWrap>
              <BlockTitle>
                <span>Score Per Student</span>
              </BlockTitle>

              <ExamScoreTableHeader>
                {this.renderScoreTypeSelect()}
              </ExamScoreTableHeader>

              {this.renderExamScoreTableHeader()}
              <TableContentWrapper>
                {examScoreData.map(this.renderSumaryTableContent)}
              </TableContentWrapper>
            </BlockWrap>
          </ExamScoreTableContent>

          <MediaQuery minWidth={1280}>
            {matches => matches && <DashboardLine />}
          </MediaQuery>

          <ExamScoreUpcomingWrap>
            <span>Upcoming Exams</span>
            {/* {isUpcomingExam.map(this.renderUpcomingItem)} */}
          </ExamScoreUpcomingWrap>
        </ExamScoreFlex>
      </ShadowSrollbar>
    );
  }
}
