import { useEffect, useState } from 'react';

function useIspringPresentationConnector(
  url: string,
  iframeRef: React.RefObject<HTMLIFrameElement>,
) {
  const [playbackController, setPlaybackController] = useState<any>(null);

  useEffect(() => {
    if (!iframeRef?.current) {
      return;
    }

    const ispringPresentationConnector = {
      register(player: any) {
        const newController = player.view().playbackController();
        console.log('Player initialized');
        setPlaybackController(newController);
      },
    };

    // Assign the connector to the window
    (window as any).ispringPresentationConnector = ispringPresentationConnector;
    console.log('ispringPresentationConnector set on parent window');

    // Cleanup function
    return () => {
      delete (window as any).ispringPresentationConnector;
    };
  }, [url, iframeRef?.current]);

  return playbackController;
}

export default useIspringPresentationConnector;
