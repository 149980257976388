import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  ModalHeader,
  ModalCLose,
} from '../Generals/stylesheets/General.style';
import ResponsiveModal from '../common/responsiveModal/ResponsiveModal';
import FormInput from '../Generals/FormInput/FormInput';
import { ActionGroup, Button } from '../Generals/stylesheets/Button.style';
import { LoginErrorMessage } from '../LoginPage/Login.style';


const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '560px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    overflow: 'unset',
    textAlign: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class ProfileChangePassword extends React.PureComponent {
  static propTypes = {
    changePassword: PropTypes.func,
    errorMsg: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    isChangingPassword: PropTypes.bool,
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose();
  }

  onSubmit = (values) => {
    const {
      changePassword,
    } = this.props;

    const { currentPassword: oldPass, newPassword: newPass1, confirmPassword: newPass2 } = values;
    changePassword(oldPass, newPass1, newPass2);
  }

  render() {
    const { isOpen, errorMsg, isChangingPassword } = this.props;
    const initialValues = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };

    const passwordSchema = Yup.object().shape({
      currentPassword: Yup.string().trim().required('Required'),
      newPassword: Yup.string().trim()
        .notOneOf(['123456', 123456], 'Your password is too simple')
        .min(8, 'Password must be a minimum of 8 characters long')
        .matches(/[0-9]/, 'The password must contain at least one number')
        .required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Your new password and confirm password do not match')
        .required('Required'),
    });

    return (
      <ResponsiveModal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          <p>Change Password</p>
          <ModalCLose className="chalktalk-close" onClick={this.onClose} />
        </ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={passwordSchema}
          onSubmit={this.onSubmit}
        >
          <Form>
            <FormInput
              type="password"
              name="currentPassword"
              label="Current Password"
            />
            <FormInput
              type="password"
              name="newPassword"
              label="New Password"
            />
            <FormInput
              type="password"
              name="confirmPassword"
              label="Confirm Password"
            />
            {errorMsg && (
              <LoginErrorMessage>
                {errorMsg}
              </LoginErrorMessage>
            )}
            <ActionGroup center>
              <Button
                submit
                type="submit"
              >
                {isChangingPassword ? 'Loading' : 'Submit'}
              </Button>
            </ActionGroup>
          </Form>
        </Formik>
      </ResponsiveModal>

    );
  }
}

export default ProfileChangePassword;
