import {
  all, call, put,
  takeLeading,
} from 'redux-saga/effects';
import { getCountry } from '../../apis/register';
import { COUNTRIES_FETCH_COUNTRY_LIST, actions as CountryActions } from '../../reducers/countries';

export function* fetchCountries() {
  try {
    const { response, error } = yield call(getCountry);
    if (error) throw error;

    const { data: countryList } = response;
    yield put(CountryActions.fetchCountryListSuccess(countryList));
  } catch (error) {
    yield put(CountryActions.fetchCountryListFailed(error.message || error));
  }
}

export default function* countriesSaga() {
  yield all([
    takeLeading(COUNTRIES_FETCH_COUNTRY_LIST, fetchCountries),
  ]);
}
