import { connect } from 'react-redux';
import NewStudentSectionEnroll from '../../components/NewStudentSectionEnroll';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import {
  actions as AuthActions,
} from '../../reducers/auth';

const mapStateToProps = state => ({
  isRequestedEnrollment: SectionSelectors.isRequestedEnrollment(state),
  isRequestingEnrollment: SectionSelectors.isRequestingEnrollment(state),
  errorMsg: SectionSelectors.getErrorMessage(state),
  sectionData: SectionSelectors.getCurrentSection(state),
});

const mapDispatchToProps = {
  sectionRequestEnrollment: SectionActions.sectionRequestEnrollment,
  logout: AuthActions.logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewStudentSectionEnroll);
