import styled, { css, keyframes } from 'styled-components';

export const StampAnimationKeyFrames = keyframes`
  0%{
    opacity: 0;
  }
  10%{
    opacity:.50;
    transform-origin: 50% 50%;
    transform: rotate(-2deg) scale(3);
    transition: all .3s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  100%{
    opacity:1;
    transform: rotate(0) scale(1);
  }
`;

export const StampAnimation = css`
  animation-name: ${StampAnimationKeyFrames};
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
`;

export const PrimaryWrap = styled.div`
  display: flex;
  width: 100%;
  position: relative;

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}

  .blanck-space {
    width: 50px;
    height: 106px;
    background-color: #ea272700;
    position: fixed;
    z-index: 9999;
    bottom: 15px;
    left: 7px;
    cursor: pointer;
  }

  .blanck-space-course {
    width: 175px;
    height: 45px;
    position: fixed;
    z-index: 9999;
    top: 20px;
    left: 130px;
    cursor: pointer;
    background: transparent;
  }
`;

export const TextTeacherTraining = styled.div`
  padding: 20px;
  font-weight: bold;
`;

export const ContainerWrap = styled.div`
  width: 100%;
  height: 100%;
  margin-left: 20em;
  transition: all 300ms ease;
  position: relative;
  min-width: 100px;
  @media (max-width: 960px) {
    margin-left: 1.5em !important;
    // background-color: #f6f7fb;
    min-width: min-content;
  }
  ${({ isSideBarClose }) =>
    isSideBarClose &&
    css`
      margin-left: 1.5em;
    `}
`;

export const ContainerItem = styled.div`
  height: 100%;
  .react-tabs {
    width: 100%;
    height: 100%;
  }
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    height: 5.5em;
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-top: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
    @media (max-width: 768px) {
      width: calc(100vw - 8em);
      overflow-x: scroll;
    }
    border-left: none;
    border-right: none;
  }
  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    height: 100%;
    padding: 0.5em;
    font-size: 15px;
    border-right: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
    border-bottom: 3px solid transparent;
    cursor: pointer;
    @media (max-width: 480px) {
      flex: 1 0 35%;
    }
    span {
      font-family: 'MontserratRegular';
      font-size: ${(props) => props.theme.fontSize.MediumFontSize};
      margin-bottom: 0.5em;
    }
    i {
      margin: 0 0.5em;
      font-size: 16px;
    }
  }
  .tab-small {
    flex: 0 0 10%;
    width: fit-content;
    @media (max-width: 768px) {
      flex: 0.2 0 15%;
    }
    @media (max-width: 480px) {
      flex: 0.5 0 25%;
    }
  }
  .tab-medium {
    flex: 0 0 15%;
    @media (max-width: 768px) {
      flex: 0 0 20%;
    }
    @media (max-width: 480px) {
      flex: 0 0 35%;
    }
  }
  .tab-noborder {
    border-right: none;
  }
  .react-tabs__tab--selected {
    border-bottom: 3px solid #ff9e16;
    color: #ff9e16;
  }
`;

export const ScoreTab = styled.div`
  display: flex;
  align-items: center;
  font-family: 'MontserratBold';
`;

export const CustomText = styled.p`
  margin-top: 0.5em;
`;

export const DefaultContentWrap = styled.div`
  height: auto;
  width: 100%;
  padding: 1em 1.5em;
  margin: 0 auto;
  overflow-x: hidden;
  padding-bottom: 5rem;
  .react-tabs {
    width: 100%;
    height: 100%;
  }
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    li {
      flex: 0 0 calc(100% / 3);
      text-align: center;
      padding: 1em 0em;
      color: #b0aba8;
      font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
      border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorXBorder};
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colorStyled.ColorPrimary};
        border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorBlack};
      }
    }
    .react-tabs__tab--selected {
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
      border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorBgDefault};
      &:hover {
        color: ${(props) => props.theme.colorStyled.ColorBgDefaultHover};
        border-bottom: 1.5px solid
          ${(props) => props.theme.colorStyled.ColorBgDefaultHover};
      }
    }
  }
  ${({ large }) =>
    large &&
    css`
      max-width: 1240px;
    `};
  ${({ medium }) =>
    medium &&
    css`
      max-width: 840px;
    `}
  ${({ noPaddingTop }) =>
    noPaddingTop &&
    css`
      padding-top: 0em;
    `}
  @media (max-width: 1170px) {
    padding: 1em;
    padding-bottom: 5rem;
  }
`;

export const OverlayWrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
  display: none;
  ${({ overlay }) =>
    overlay &&
    css`
      background-color: #00000069;
    `};
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`;

export const IconStyled = styled.i``;

export const ContentFlexWrap = styled.div`
  display: flex;
  justify-content: center;
  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: wrap;
    `}
`;

export const ContentSubFlexWrap = styled.div`
  flex: 1;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 2em;
  ${({ isTeacherInvitationModal }) =>
    isTeacherInvitationModal &&
    css`
      justify-content: flex-end;
      margin-bottom: unset;
    `}
  ${({ editReview }) =>
    editReview &&
    css`
      padding: 0px 10px 15px;
      font-weight: bold;
      font-size: 18px;
      text-transform: none;
      margin-bottom: 0;
      margin-top: 1em;
    `}
  @media (max-width: 768px) {
    p {
      width: fill-available;
      text-align: center;
    }
  }
`;

export const ModalCloseCourse = styled.i`
  margin-right: 0.5em;
  color: ${(props) => props.theme.colorStyled.ColorTime};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
  font-size: 15px;
  position: absolute;
  top: 10px;
  right: 5px;
  @media (max-width: 768px) {
    top: 20px;
    right: 20px;
  }
`;

export const ModalSubHeader = styled.p`
  font-size: 0.9285em;
  color: #6e7079;
  margin-bottom: 1em;
`;
export const ModalSubText = styled.p``;

export const ModalSubTextList = styled.ul`
  flex-direction: column;
  display: flex;
  width: 100%;
  list-style: disc inside;
  margin-top: 0.5em;
`;

export const ModalCLose = styled.i`
  margin-right: 0.5em;
  color: #aeaeae;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
  }
  ${({ isTeacherRegistrationModal }) =>
    isTeacherRegistrationModal &&
    css`
      font-size: 15px;
      position: absolute;
      top: 15px;
      right: 20px;
    `}
`;

export const BlockWrap = styled.div<{
  fullWidth?: boolean;
  noPadding?: boolean;
  report?: boolean;
}>`
  padding: 1em 1.5em;
  margin: 1em 0.125em 2em;
  border-radius: 0.5em;
  transition: all 400ms ease;
  position: relative;
  box-shadow: rgba(9, 30, 66, 0.42) 0px 0px 1px, rgba(9, 30, 66, 0.35) 0px 0;
  > p {
    font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
    color: ${(props) => props.theme.colorStyled.ColorGrey};
    margin-bottom: 1em;
  }
  &:last-child {
    margin: 1em 0.125em;
  }
  @media (max-width: 1440px) {
    padding: 1em;
    ${({ noPadding }) =>
      noPadding &&
      css`
        padding: 0rem;
      `}
  }

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      flex: 1;
    `}

  ${({ report }) =>
    report &&
    css`
      height: fit-content;
      margin: 10px 20px !important;
    `}
  ${({ noPadding }) =>
    noPadding &&
    css`
      padding: 0rem;
    `}
`;

export const BlockWrapNoBorderRadius = styled(BlockWrap)`
  border-radius: 0;
  box-shadow: none;
`;

export const BlockTitle = styled.div`
  position: absolute;
  top: -0.75em;
  left: 1em;
  z-index: 1;
  padding: 0.125em 0.675em;
  border-radius: 0.325em;
  font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
  // background-color: ${(props) => props.theme.colorStyled.ColorBgContent};
  span {
    position: relative;
    z-index: 1;
  }
  ${({ haveBackground }) =>
    haveBackground &&
    css`
      &:after {
        content: '';
        position: absolute;
        left: 0em;
        bottom: 0em;
        width: 100%;
        height: 9px;
        box-shadow: 0px -0.5px 1px 0px ${(props) => props.theme.colorStyled.ColorWhite};
        background-color: ${(props) => props.theme.colorStyled.ColorWhite};
      }
    `}
`;

export const NoContent = styled.div`
  min-height: 8em;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  border-radius: 0.5em;
  box-shadow: rgba(9, 30, 66, 0.42) 0px 0px 2px, rgba(9, 30, 66, 0.35) 0px 0;
`;

export const PaginationWrap = styled.div`
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5em;
      height: 2.5em;
      border: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
      border-right: none;
      background-color: #f3f3f3;
      cursor: pointer;
      a {
        color: ${(props) => props.theme.colorStyled.ColorPrimary};
      }
      &:first-child {
        border-radius: 0.325em 0em 0em 0.325em;
      }
      &:last-child {
        border-right: 1px solid ${(props) => props.theme.colorStyled.ColorBorderInput};
        border-radius: 0em 0.325em 0.325em 0em;
      }
      &:hover {
        background-color: ${(props) => props.theme.colorStyled.ColorWhite};
        opacity: 0.7;
      }
    }
    .active {
      background-color: ${(props) => props.theme.colorStyled.ColorWhite};
      a {
        color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      }
    }
  }
`;

export const UpcomingEmpty = styled.div`
  padding: 1em;
  margin: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  box-shadow: rgba(9, 30, 66, 0.64) 0px 0px 1px, rgba(9, 30, 66, 0.35) 0px 0;
  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0em;
    `}
`;

export const TitlePageWrap = styled.div`
  text-transform: uppercase;
  font-family: 'MontserratSemiBold';
  @media (max-width: 768px) {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
`;

export const OverlayLoading = styled.div`
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
`;
