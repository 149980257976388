import React, { ReactElement } from 'react';
import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  SxProps,
  Theme,
} from '@mui/material';

/**
 * Props for the `Chip` component
 */
export interface ChipProps {
  /** Avatar for the `Chip` */
  avatar?: MuiChipProps['avatar'];
  /** Label for the `Chip` */
  label: React.ReactNode | string;
  /** Color of the `Chip` */
  color: MuiChipProps['color'] | 'chipGrayCT';
  /** Whether the `Chip` is clickable */
  clickable: boolean;
  /** Whether the `Chip` is disabled */
  disabled?: MuiChipProps['disabled'];
  /** Icon for the `Chip` */
  icon?: ReactElement<any, string>;
  /** Size of the `Chip` */
  size: MuiChipProps['size'];
  /** Variant of the `Chip` */
  variant: MuiChipProps['variant'];
  /** Whether to skip focusing the `Chip` when it's disabled */
  skipFocusWhenDisabled?: MuiChipProps['skipFocusWhenDisabled'];
  /** Component to use for rendering the `Chip` */
  component?: React.ElementType | any;
  /** Custom styling */
  sx?: SxProps<Theme> | any;
  /** Callback fired when the `Chip` is deleted */
  onDelete?: MuiChipProps['onDelete'];
  /** Override the default delete icon element. Shown only if `onDelete` is set. */
  deleteIcon?: ReactElement<any, string>;
  /** Data test ID for testing purposes */
  dataTestId?: string;
}

/**
 * A `Chip` component
 * @param {ChipProps} props - Props for the `Chip`
 * @returns {React.ReactElement} A `Chip` element
 */
const Chip = ({ dataTestId = 'chip-testid', ...rest }: ChipProps) => (
  <MuiChip data-testid={dataTestId} {...rest} />
);
export default Chip;
