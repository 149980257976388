/* eslint-disable no-bitwise */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import generateUUID from './generateUUID';
// const axios = require('axios');

class SentryChalkTalk {
  constructor() {
    this.transactionId = `${Date.now()}-${generateUUID()}`;
  }

  init() {
    Sentry.init({
      dsn: 'https://59d8253bccee437a8d9e5f1d035ad00f@o515125.ingest.sentry.io/5619379',
      environment: process.env.NODE_ENV,
      tracesSampleRate: 0.2,
      replaysOnErrorSampleRate: 1.0,
      profilesSampleRate: 0.2,
      ignoreErrors: ['ResizeObserver loop limit exceeded'],

      integrations: [
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
        new Sentry.BrowserProfilingIntegration(),
      ],
    
    });
    this.setTransaction();
  }

  setTransaction() {
    const { transactionId } = this;
    Sentry.configureScope((scope) => {
      scope.setTag('transaction_id', transactionId);
    });
    axios.defaults.headers.common['X-Transaction-ID'] = `${transactionId}`;
  }
}
export default new SentryChalkTalk();
