import styled, { css } from 'styled-components';

export const ProgressBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  width: 100%;
  overflow: hidden;
  padding-inline: 1.5em;
`;

export const ProgressBarLine = styled.div`
  background-color: #ccc;
  height: 2px;
  width: 100%;
`;
export const ProgressBarCircle = styled.div<{
  isSelected?: boolean;
  disabled?: boolean;
}>`
  width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
  border: 2px solid #ccc;
  color: #ccc;
  background-color: ${(props) => props.theme.colorStyled.ColorBgSilver};
  z-index: 1;
  position: absolute;
  right: 0;
  ${({ disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${(props) => props.theme.colorStyled.ColorWhite} !important;
      box-shadow: 0 0 0 2pt ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      transition: 300ms ease;
    `}
  @media (max-width: 768px) {
    position: unset;
    background-color: transparent;
    width: 1.25em;
    height: 1.25em;
  }
`;
export const PBMobileSelectedItem = styled.div`
  width: 10em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
`;
export const ProgressBarItem = styled.div<{
  isActive: boolean;
  isSelected: boolean;
  widthValue: number;
  firstItem: boolean;
  isAnswered?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  @media (max-width: 768px) {
    cursor: pointer;
  }
  &:first-child {
    flex: 0 1 2rem;
    & ${ProgressBarLine} {
      display: none;
    }
  }
  ${({ widthValue }) =>
    widthValue &&
    css`
      flex: 1 0 calc(100% / ${widthValue});
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      ${ProgressBarCircle} {
        border-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
        background-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
        color: #fff;
      }
      ${ProgressBarLine} {
        background-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      }
    `}


  ${({ isSelected }) =>
    isSelected &&
    css`
      ${ProgressBarCircle} {
        border-color: ${(props) => props.theme.colorStyled.ColorWhite} !important;
        box-shadow: 0 0 0 2pt ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
        transition: 300ms ease;
      }
      ${ProgressBarLine} {
        background-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
      }
    `}  

  ${({ isAnswered, isActive }) =>
    isAnswered &&
    css`
      ${ProgressBarCircle} {
        border-color: ${(props) =>
          isActive
            ? props.theme.colorStyled.ColorWhite
            : props.theme.colorStyled.ColorPrimaryBlue} !important;
        background-color: ${(props) =>
          props.theme.colorStyled.ColorPrimaryBlue} !important;
        box-shadow: 0 0 0 2pt ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
        transition: 300ms ease;
      }
    `}
`;
