import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react';

import { useActivityAbsentGroup } from '@components/GroupActivityTable/hooks/queries';

import AbsentStudentsDialog from '../AbsentStudentsDialog';
import StartOnlineLessonPracticeDialog from '../StartOnlineLessonPracticeDialog';

import { useGetGroupActivityInfo, useGetInProgressOLPId } from '../hooks/query';
import { useEndGroupActivity, useStartGroupActivity } from '../hooks/mutation';

type StartActivityContextType = {
  handleStartActivity: () => void;
  absentRowDiv: RefObject<HTMLDivElement | null> | any;
};
const StartActivityContext = React.createContext<StartActivityContextType>({
  absentRowDiv: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleStartActivity: () => {},
});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const StartActivityProvider = ({ children }: Props) => {
  const absentRowDiv = useRef<HTMLDivElement | null>(null);
  const [showEndActiveSessionDialog, setShowEndActiveSessionDialog] =
    useState<boolean>(false);
  const [showAbsenceDialog, setShowAbsenceDialog] = useState<boolean>(false);

  const inProgressOLPId = useGetInProgressOLPId();
  const {
    data: { id: groupActivityId },
  } = useGetGroupActivityInfo();

  const startActivityMutate = useStartGroupActivity(groupActivityId);
  const endActivityMutate = useEndGroupActivity(groupActivityId);

  const {
    data: { students: absentStudents },
  } = useActivityAbsentGroup(groupActivityId);
  // ---------------------------------------------------------------------
  const noAbsences = absentStudents?.length === 0;

  const onEndActiveSessionCancel = () => {
    setShowEndActiveSessionDialog(false);
  };
  const onAbsentCancel = () => {
    setShowAbsenceDialog(false);
    absentRowDiv.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      inline: 'nearest',
    });
  };
  const onEndActiveSessionConfirm = () => {
    // to satisfy TS
    if (!inProgressOLPId) {
      return;
    }
    endActivityMutate.mutate(inProgressOLPId);
    if (noAbsences) {
      setShowAbsenceDialog(true);
      setShowEndActiveSessionDialog(false);
      return;
    }
    startActivityMutate.mutate(groupActivityId);
    setShowEndActiveSessionDialog(false);
  };
  const onAbsentConfirm = () => {
    startActivityMutate.mutate(groupActivityId);
    setShowAbsenceDialog(false);
  };

  const handleStartActivity = useCallback(() => {
    // Is there any other activities working
    if (inProgressOLPId) {
      setShowEndActiveSessionDialog(true);
      return;
    }
    // Absent Logic -----------
    if (noAbsences) {
      setShowAbsenceDialog(true);
      return;
    }
    startActivityMutate.mutate(groupActivityId);
  }, [inProgressOLPId, startActivityMutate, groupActivityId]);

  const value = useMemo(
    () => ({ handleStartActivity, absentRowDiv }),
    [handleStartActivity, absentRowDiv],
  );
  return (
    <StartActivityContext.Provider value={value}>
      {children}
      <StartOnlineLessonPracticeDialog
        show={showEndActiveSessionDialog}
        onCancel={onEndActiveSessionCancel}
        onConfirm={onEndActiveSessionConfirm}
        dataTestId="start-olp-modal"
      />
      <AbsentStudentsDialog
        show={showAbsenceDialog}
        onCancel={onAbsentCancel}
        onConfirm={onAbsentConfirm}
        dataTestId="absent-student-dialog"
      />
    </StartActivityContext.Provider>
  );
};

export default React.memo(StartActivityProvider);
export const useStartActivity = () => {
  const context = React.useContext(StartActivityContext);
  if (context === undefined) {
    throw new Error('useStartActivity must be used within a StartActivityProvider');
  }
  return context;
};
