import {
  call, put, takeLeading,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _findLastIndex from 'lodash/findLastIndex';
import * as ExamAPI from '../../apis/exam';
import { transformGrinInAnswer } from '../../utils/func-utils';
import {
  EXAM_INITIALLIZE_RESPONSE,
  EXAM_TAKE_QUESTION,
  EXAM_SUBMIT_QUESTION,
  EXAM_COMPLETE_RESPONSE,
  selectors as examSelectors,
  actions as examActions,
} from '../../reducers/exam';
import { SECTION_EXAMS_RESET_SUCCESS } from '../../reducers/sectionExam';
import { error as errorToast, offline as stickyErrorToast } from '../../utils/toast';
import { captureException, withScope } from '@sentry/react';

function* examInitializeResponse({ sessionId, payload, callback }) {
  const { response, error } = yield call(ExamAPI.initializeSectionResponse, sessionId, payload);

  if (response) {
    const { data } = response;
    yield put(examActions.examInitializeResponseSuccess(data));
    yield put(push(callback));
  } else {
    const message = Array.isArray(error) ? _get(error, '[0]', 'Unexpected error') : error;
    yield put(examActions.examInitializeResponseFail(message));
  }
}

function* examTakeQuestion({ sessionId, payload }) {
  const { response, error } = yield call(ExamAPI.takeSectionResponse, sessionId, payload);

  if (response) {
    // on 403, error is actually undefined
    if (response.status === 403) {
      const message = _get(response, 'data.detail', 'Unexpected error');
      errorToast(message);
      yield put(examActions.examTakeQuestionFail(message));
    } else {
      const { data } = response;
      const { id: responseId } = data;
      const examQuestions = _get(data, 'exam_questions', []);
      // item.value for offlline test and item.response for online test
      const userAnswers = _filter(examQuestions, item => item.value || item.response).map((item) => {
        const { id, value, response: answers } = item;

        // offline test
        if (value) {
          const currentValue = Array.isArray(value) ? value[0] : transformGrinInAnswer(value);

          return {
            response_id: responseId,
            exam_question_id: id,
            value: currentValue,
            response_time: 0,
          };
        }
        // online test

        const { choices, response_time: responseTime, text } = answers;

        if (text) {
          return {
            response_id: responseId,
            exam_question_id: id,
            value: text,
            response_time: responseTime,
          };
        }

        return {
          response_id: responseId,
          exam_question_id: id,
          value: choices[0],
          response_time: responseTime,
        };
      });
      const lastQuestionIndex = _findLastIndex(examQuestions, question => question.response);

      if (lastQuestionIndex !== -1) {
        yield put(examActions.examLastQuestionIndex(lastQuestionIndex));
      }

      yield put(examActions.examChoosePreviousAnswer(userAnswers));
      yield put(examActions.examTakeQuestionSuccess(data));
    }
  }
}

function* examSubmitQuestion({ sessionId, responseId, callback }) {
  const answerList = yield select(examSelectors.getNewAnswerList);
  const online = window.navigator.onLine;

  if (answerList.length > 0) {
    if (!online) {
      // Offline mode
      localStorage.setItem('answerList', JSON.stringify(answerList));
      if (sessionId) {
        localStorage.setItem('sessionExamId', sessionId);
      }
    } else {
      const { response, error } = yield call(
        ExamAPI.submitQuestionResponse,
        sessionId,
        answerList,
      );

      if (!response) {
        const status = _get(error, 'response.status', null);

        if (status === 403) {
          yield put(examActions.examSetErrorCode(403));
        }

        let toastMessage = _get(
          error,
          'response.data.detail',
          'Failed to submit your answer for that question. Please try again in a few seconds.',
        );

        withScope((scope) => {
          const { name, message } = error;
          scope.setContext('Error Information', {
            name,
            message,
            sessionId,
            responseId,
            answerList,
          });
          scope.setTag('exception', 'Failed to submit exam answers.');
          const eventId = captureException(error);
          toastMessage += ` EventID: ${eventId}`;
        });

        stickyErrorToast(toastMessage, { closeButton: true });
        yield put(examActions.examSubmitQuestionFail(toastMessage));
      } else {
        yield put(examActions.examSubmitQuestionSuccess(response?.data || []));
      }
    }
  }
  if (responseId && callback) {
    yield put(examActions.examCompleteResponse(sessionId, responseId, callback));
  }
}

function* examCompleteResponse({ sessionId, callback, payload }) {
  console.log('Setting exam state to complete...');
  const online = window.navigator.onLine;
  if (!online) {
    localStorage.setItem('examIsCompleted', true);
    localStorage.setItem('examCompletePayload', JSON.stringify(payload));
    if (sessionId) localStorage.setItem('sessionExamId', sessionId);
    yield put(push(callback));
  } else {
    const { response, error } = yield call(ExamAPI.completeSectionResponse, sessionId, payload);
    if (response) {
      const { data } = response;
      yield put(examActions.examCompleteResponseSuccess(data));
      yield put(push(callback));
    } else {
      const status = _get(error, 'response.status', null);

      if (status === 403) {
        yield put(examActions.examSetErrorCode(403));
      }

      const message = _get(error, 'response.data.detail', 'Unexpected error');
      errorToast(`${message}`);
      yield put(examActions.examCompleteResponseFail(error));
    }
  }
}

function* resetTimer({ examSectionId }) {
  // we will check for the current path if it includes the same exam session id
  // then we will let it reset the timer otherwise the timer won't be reseted
  const path = window.location.href;
  if (path.includes(`/take/${examSectionId}`)) {
    yield put(examActions.examResetTimer());
  }
}


export default function* examSaga() {
  yield takeLeading(EXAM_INITIALLIZE_RESPONSE, examInitializeResponse);
  yield takeLeading(EXAM_TAKE_QUESTION, examTakeQuestion);
  yield takeLeading(EXAM_SUBMIT_QUESTION, examSubmitQuestion);
  yield takeLeading(EXAM_COMPLETE_RESPONSE, examCompleteResponse);
  yield takeLeading(SECTION_EXAMS_RESET_SUCCESS, resetTimer);
}
