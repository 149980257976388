import React, { useCallback, useMemo, useState } from 'react';
import ReportErrorModal from '@components/ReportErrorModal';
import { useQuestion } from '@components/PracticeV2/Provider/PracticeProvider';
import { useDispatch } from 'react-redux';
import { actions as UserActions } from '../../../reducers/user';

type PracticeReportContextType = {
  handleOpenReportModal: (type: string) => void;
};
const PracticeReportContext = React.createContext<PracticeReportContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleOpenReportModal: (type: string) => {},
});
type Props = {
  children: React.ReactNode | React.ReactNode[];
};
const PracticeReportProvider = ({ children }: Props) => {
  const [state, setState] = useState<string | null>(null);
  const question = useQuestion();
  const dispatch = useDispatch();
  const handleOpenReportModal = useCallback((type: string) => {
    setState(type);
  }, []);
  const handleSubmit = useCallback(
    (content: string) => {
      if (question) {
        dispatch(
          UserActions.userReportIssue(question.id, state, content, () => setState(null)),
        );
      } else {
        setState(null);
      }
    },
    [question, dispatch, state],
  );
  const value = useMemo(() => ({ handleOpenReportModal }), [handleOpenReportModal]);
  return (
    <PracticeReportContext.Provider value={value}>
      {children}
      <ReportErrorModal
        isOpen={!!state}
        onClose={() => setState(null)}
        onSubmit={handleSubmit}
      />
    </PracticeReportContext.Provider>
  );
};

export default React.memo(PracticeReportProvider);
export const useReportErrorModal = () => {
  const context = React.useContext(PracticeReportContext);
  if (context === undefined) {
    throw new Error('useReportErrorModal must be used within a PracticeReportProvider');
  }
  return context;
};
