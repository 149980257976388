import React from 'react';
import { useIsTeacher } from '@reducers/user/hooks';
import { Tab, TabList } from '@components/Shared/TabsDefaultSkin';
import ShowIf from '@components/Atoms/ShowIf';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import { LessonTabs } from '../Types';
import {
  useNavigateToLesson,
  useShouldShowAdaptivePracticeTab,
  useShouldShowInterventionTab,
  useShouldShowLearnosityAdaptivePracticeTab,
  useShouldShowStandardPracticeTab,
  useShouldShowStudyHallTab,
} from '../hooks';
import AdaptivePracticeTabContent from './AdaptivePracticeTabContent';
import { useGetLessonDetail } from '../hooks/query';

const tabLists = [
  {
    id: 1,
    content: '🧑‍🏫 Lesson Plan',
    tabKey: LessonTabs.LessonPlan,
  },
  {
    id: 2,
    content: '🧑‍🏫 Lesson',
    tabKey: LessonTabs.Lesson,
  },
  {
    id: 3,
    content: '👥 Group Activity',
    tabKey: LessonTabs.LessonPractice,
  },
  {
    id: 4,
    content: <AdaptivePracticeTabContent />,
    tabKey: LessonTabs.Practice,
  },
  {
    id: 5,
    content: '👤 Adaptive Practice',
    tabKey: LessonTabs.LearnosityAdaptivePractice,
  },
  {
    id: 6,
    content: '🎯 Intervention',
    tabKey: LessonTabs.Intervention,
  },
  {
    id: 7,
    content: '📚 Study Hall',
    tabKey: LessonTabs.StudyHall,
  },
];

const TabNavigator = () => {
  const navigateToLesson = useNavigateToLesson();
  const { tab } = useSearchParams();
  const { data: lessonDetail } = useGetLessonDetail();
  const isTeacher: boolean = useIsTeacher();
  const shouldShowStandardPracticeTab = useShouldShowStandardPracticeTab();
  const shouldShowAdaptivePracticeTab = useShouldShowAdaptivePracticeTab();
  const shouldShowInterventionTab = useShouldShowInterventionTab();
  const shouldShowStudyHallTab = useShouldShowStudyHallTab();
  const shouldShowAdaptiveLearnosityPracticeTab =
    useShouldShowLearnosityAdaptivePracticeTab();

  const conditions = React.useMemo(
    () => ({
      [LessonTabs.LessonPlan]: isTeacher,
      [LessonTabs.Lesson]: true,
      [LessonTabs.LessonPractice]: lessonDetail?.has_documents,
      [LessonTabs.Practice]:
        (shouldShowStandardPracticeTab || shouldShowAdaptivePracticeTab) &&
        !shouldShowAdaptiveLearnosityPracticeTab,
      [LessonTabs.Intervention]: shouldShowInterventionTab,
      [LessonTabs.StudyHall]: shouldShowStudyHallTab,
      [LessonTabs.LearnosityAdaptivePractice]: shouldShowAdaptiveLearnosityPracticeTab,
    }),
    [
      shouldShowAdaptiveLearnosityPracticeTab,
      isTeacher,
      lessonDetail?.has_documents,
      shouldShowAdaptivePracticeTab,
      shouldShowInterventionTab,
      shouldShowStandardPracticeTab,
      shouldShowStudyHallTab,
    ],
  );

  React.useEffect(() => {
    if (!conditions[tab]) {
      navigateToLesson({
        tabKey: LessonTabs.Lesson,
      });
    }
  }, [conditions]);

  return (
    <TabList style={{ height: '4rem' }}>
      {tabLists.map((tabInfo) => (
        <ShowIf key={tabInfo.id} If={conditions[tabInfo.tabKey]}>
          <Tab
            // flag for editing style
            isLessonTab
            dataTestId={`tab-${tabInfo.tabKey}`}
            tabKey={tabInfo.tabKey}
            onClick={() =>
              navigateToLesson({
                tabKey: tabInfo.tabKey,
              })
            }
          >
            {tabInfo.content}
          </Tab>
        </ShowIf>
      ))}
    </TabList>
  );
};

export default TabNavigator;
