/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { MenuColumn } from '@components/QuestionGenerationService/styles';
import { useUser } from '@reducers/user/hooks';
import {
  Box,
  CircularProgress,
  TextField,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import { error, success } from '@utils/toast';
import { Passage } from '@components/QuestionGenerationPassages/types';
import ShadowScrollbar from '@components/Scrollbar/ShadowScrollbars';
import ShowIf from '@components/Atoms/ShowIf';
import { MODES } from '@components/QuestionGenerationPassages/Passages';
import { useQueryClient } from 'react-query';
import { useGeneratePassage } from '@components/QuestionGenerationPassages/hooks';
import { createPassageRequest } from '../../AssessmentServiceAPI';
import { useGetSignature } from '../../Hooks/Query';
import { usePassageUpsert } from './hooks';

const containerId = 'learnosity_passage_create_container';

// template provided by learnosity to display passage form
const PASSAGE_FORM_TEMPLATE = 'd5d43bd6-d02a-4969-a79a-e10b344549a8';

const PassageCreation: React.FC<{
  widgetRef?: any;
  passage?: Passage | null;
  switchMode: (toMode: string, passage: Passage | null) => void;
  isGenerateMode?: boolean;
}> = ({ widgetRef = undefined, passage, switchMode, isGenerateMode = false }) => {
  const [isInputMode, setIsInputMode] = useState(!passage && isGenerateMode);
  const [prompt, setPrompt] = useState('');
  const { mutate: generatePassage, isLoading: isGenerating } = useGeneratePassage();
  const queryClient = useQueryClient();
  const currentUser = useUser();
  const mainRef = useRef<HTMLDivElement>(null);
  const [widgetInstance, setWidgetInstance] = useState<any>(undefined);
  const defaultMode = passage ? 'preview' : 'edit';
  const { mutate: upsertPassage, isLoading: isSaving } = usePassageUpsert();
  const { data: signature } = useGetSignature(
    createPassageRequest(currentUser.id.toString(), defaultMode),
    'author',
    {
      enabled: !widgetInstance && !!(window as any).LearnosityAuthor,
    },
  );
  const [indexingStrategy, setIndexingStrategy] = useState('none');
  const [originalContent, setOriginalContent] = useState('');

  useImperativeHandle(widgetRef, () => ({ widgetInstance }), [widgetInstance]);

  useEffect(() => {
    if (!isInputMode && mainRef.current) {
      mainRef.current.appendChild(document.createElement('div')).id = containerId;
    }
  }, [isInputMode]);

  useEffect(() => {
    if (!widgetInstance) {
      return;
    }

    const widget = passage
      ? { widgetJson: passage.data }
      : {
          widgetTemplate: { template_reference: PASSAGE_FORM_TEMPLATE },
        };

    const encodedWidget = encodeURIComponent(JSON.stringify(widget));
    widgetInstance.navigate(`items/new/widgets/new/${encodedWidget}`);
  }, [passage, widgetInstance]);

  const passageValid = (updatedPassage: Passage) => {
    const { content } = updatedPassage.data;
    if (!content) {
      return false;
    }
    return true;
  };

  const applyIndexing = useCallback(
    (content: string): string => {
      if (!content) {
        return '';
      }

      // Extract text content from any existing table structure
      const extractTableContent = (html: string) => {
        // If there's no table, return the original content
        if (!html.includes('<table>')) {
          return html;
        }

        const div = document.createElement('div');
        div.innerHTML = html;

        // Find all table cells with our specific classes
        const tableCells = div.querySelectorAll(
          '.ilogic-poetry_line, .ilogic-block_text',
        );

        // If we found formatted cells, extract their content
        if (tableCells.length) {
          return Array.from(tableCells)
            .map((cell) => cell.textContent || '')
            .filter((text) => text.trim()) // Remove empty lines
            .join('\n');
        }

        // If we have a table but no cells with our classes, try to get any content
        const allCells = div.querySelectorAll('td');
        if (allCells.length) {
          return Array.from(allCells)
            .map((cell) => cell.textContent || '')
            .filter((text) => text.trim()) // Remove empty lines
            .join('\n');
        }

        // Remove the empty table if we couldn't find any content
        return html.replace(/<table[^>]*>.*?<\/table>/gs, '').trim();
      };

      // Clean up the content first
      let cleanContent = extractTableContent(content)
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with spaces
        .replace(/\[([¶\d]+)\]\s*/g, '') // Remove any existing index markers
        .replace(/<br\s*\/?>/g, '\n') // Convert <br> to newlines
        .split('\n')
        .filter((line) => line.trim()) // Remove empty lines
        .join('\n');

      // If we end up with no content, return the original
      if (!cleanContent.trim()) {
        return content;
      }

      const lineCounterModulo = 5;
      switch (indexingStrategy) {
        case 'line': {
          const lines = cleanContent.split('\n');
          let lineNumber = 0;
          const tableRows = lines
            .filter((line) => line.trim())
            .map((line) => {
              lineNumber++;
              return `<tr>
              <td class="ilogic-poetry_number">${
                lineNumber % lineCounterModulo === 0 ? lineNumber : '&nbsp;'
              }</td>
              <td class="ilogic-poetry_line">${line}</td>
            </tr>`;
            });

          return `<table>
          <tbody>
            ${tableRows.join('\n')}
          </tbody>
        </table>`;
        }

        case 'paragraph': {
          const paragraphs = cleanContent.split(/(\n)+/).filter((para) => para.trim());

          const tableRows = paragraphs.map(
            (para, idx) => `<tr>
            <td class="ilogic-block_label">¶${idx + 1}</td>
            <td class="ilogic-block_text">${para.trim()}</td>
          </tr>`,
          );

          return `<table>
          <tbody>
            ${tableRows.join('\n')}
          </tbody>
        </table>`;
        }

        default:
          return cleanContent.replace(/\n/g, '<br>');
      }
    },
    [indexingStrategy],
  );

  const savePassage = useCallback(
    (updatedPassage: Passage) => {
      const transformedPassage = {
        ...updatedPassage,
        data: {
          ...updatedPassage.data,
          content: applyIndexing(updatedPassage.data.content),
        },
        reference: passage?.reference || updatedPassage.reference,
      };

      if (!passageValid(transformedPassage)) {
        error('Passage is missing required fields');
        return;
      }

      // append author information
      transformedPassage.data.metadata = {
        ...transformedPassage.data.metadata,
        author: 'CT-User',
        author_id: currentUser.id,
      };

      upsertPassage(transformedPassage, {
        onSuccess: () => {
          queryClient.invalidateQueries(['getUserPassages']);
          success('Passage saved successfully');
          switchMode(MODES.LIST_MODE, null);
        },
        onError: () => {
          error('Failed to save passage. Please review the passage and try again');
        },
      });
    },
    [
      applyIndexing,
      passage?.reference,
      queryClient,
      switchMode,
      upsertPassage,
      currentUser.id,
    ],
  );

  const previewMode = () => {
    // switch to preview mode if passage is already created
    const previewButton = mainRef.current?.querySelector(
      'button[data-lrn-qe-toggle="preview"]',
    ) as HTMLElement | null;

    if (previewButton) {
      previewButton.click();
    }
  };

  const bindBackButton = useCallback(() => {
    // hijack back button to switch mode instead of going back to item list
    const backButton = mainRef.current?.querySelector(
      'button[data-authorapi-selector="back"]',
    ) as HTMLElement | null;

    if (backButton) {
      backButton.addEventListener('click', () => {
        switchMode(MODES.LIST_MODE, null);
      });
    }
  }, [switchMode]);

  const filterSaves = useCallback(() => {
    // removes the save button if the passage is chalktalk's
    if (!passage || !passage?.chalktalk_passage) {
      return;
    }

    const saveBar = mainRef.current?.querySelector(
      'div[data-authorapi-selector="save-group"]',
    ) as HTMLElement | null;

    if (saveBar) {
      saveBar.style.display = 'none';
    }
  }, [passage]);

  const handleGenerate = () => {
    generatePassage(prompt, {
      onSuccess: (response) => {
        setPrompt(''); // Reset prompt
        setIsInputMode(false);
        const newPassage = {
          reference: response.reference,
          data: {
            content: response.data.content,
            heading: response.data.heading,
            type: 'sharedpassage',
            metadata: {},
          },
          chalktalk_passage: false,
        };
        // Update the passage prop which will trigger the editor to load
        switchMode(MODES.PASSAGE_MODE, newPassage);
      },
    });
  };

  const renderInputArea = () => (
    <Box sx={{ p: 3, height: '100%' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Generate Passage with AI
      </Typography>
      <TextField
        autoFocus
        multiline
        rows={4}
        fullWidth
        label="Describe the passage you want generated"
        placeholder="For example: An excerpt from Romeo and Juliet showing conflict"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        disabled={isGenerating}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
        <Button onClick={() => switchMode(MODES.LIST_MODE, null)} disabled={isGenerating}>
          Cancel
        </Button>
        <Button
          onClick={handleGenerate}
          variant="contained"
          disabled={!prompt.trim() || isGenerating}
        >
          {isGenerating ? (
            <>
              Generating
              <CircularProgress size={16} sx={{ ml: 1, color: 'inherit' }} />
            </>
          ) : (
            'Generate'
          )}
        </Button>
      </Box>
    </Box>
  );

  const renderIndexingControls = () => (
    <FormControl component="fieldset" sx={{ mt: 2, mb: 2 }}>
      <FormLabel component="legend">Indexing Strategy</FormLabel>
      <RadioGroup
        row
        value={indexingStrategy}
        onChange={(e) => setIndexingStrategy(e.target.value)}
      >
        <FormControlLabel value="none" control={<Radio />} label="None" />
        <FormControlLabel value="line" control={<Radio />} label="By Line" />
        <FormControlLabel value="paragraph" control={<Radio />} label="By Paragraph" />
      </RadioGroup>
    </FormControl>
  );

  useEffect(() => {
    const isElementOnTheDom = !!document.getElementById(containerId);
    if (
      signature &&
      Object.keys(signature!).length &&
      !widgetInstance &&
      isElementOnTheDom
    ) {
      const authorApp = (window as any).LearnosityAuthor?.init(
        signature,
        {
          readyListener: () => {
            setWidgetInstance(authorApp);

            // start instance in preview mode
            authorApp.on('widgetedit:editor:ready', () => {
              setTimeout(() => {
                if (passage) {
                  previewMode();
                  filterSaves();
                }
                bindBackButton();
              }, 100);
            });

            authorApp.on('save', (event) => {
              event.preventDefault();
              const passageList = event.data.features.filter(
                ({ type }) => type === 'sharedpassage',
              );

              if (passageList.length) {
                savePassage(passageList[0]);
              }
            });
          },
        },
        containerId,
      );
    }
    return () => {
      if (widgetInstance && isElementOnTheDom) {
        widgetInstance.destroy(); // TODO this is not correct
        setWidgetInstance(undefined);
      }
    };
  }, [
    passage,
    signature,
    widgetInstance,
    switchMode,
    savePassage,
    bindBackButton,
    filterSaves,
  ]);

  useEffect(() => {
    if (!widgetInstance) {
      return;
    }

    let lastContentHash = '';
    let updateTimeout: NodeJS.Timeout | null = null;

    // Extract just the text content, removing all HTML tags
    const getCleanContent = (content: string) => {
      return content
        .replace(/<[^>]+>/g, '') // Remove all HTML tags
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
        .trim();
    };

    // Simple hash function for content
    const hashContent = (content: string) => {
      return getCleanContent(content)
        .split('')
        .reduce((a, b) => {
          a = (a << 5) - a + b.charCodeAt(0);
          return a & a;
        }, 0)
        .toString();
    };

    const updateWidgetContent = async () => {
      try {
        const widget = await widgetInstance.getWidget();
        const content = widget?.content;

        if (!content) {
          return;
        }

        if (!originalContent) {
          setOriginalContent(content);
          return;
        }

        // If switching to 'none', restore original content
        if (indexingStrategy === 'none') {
          if (content !== originalContent) {
            const newWidget = {
              ...widget,
              content: originalContent,
            };
            await widgetInstance.setWidget(newWidget);
            lastContentHash = hashContent(originalContent);
          }
          return;
        }

        // Get clean versions of content for comparison
        const currentHash = hashContent(content);

        // Check if content has already been processed
        if (currentHash === lastContentHash) {
          return;
        }

        const updatedContent = applyIndexing(originalContent);
        const updatedHash = hashContent(updatedContent);

        // Only update if the content actually changed
        if (currentHash !== updatedHash) {
          lastContentHash = updatedHash;
          const newWidget = {
            ...widget,
            content: updatedContent,
          };
          await widgetInstance.setWidget(newWidget);
        }
      } catch (err) {
        console.error('Error updating widget content:', err);
      }
    };

    // Debounced event handler
    const handleEvent = () => {
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }
      updateTimeout = setTimeout(updateWidgetContent, 300);
    };

    const events = ['widgetedit:widget:ready'];
    events.forEach((event) => {
      widgetInstance.on(event, handleEvent);
    });

    // Initial update
    updateWidgetContent();

    return () => {
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }
      events.forEach((event) => {
        widgetInstance.off(event, handleEvent);
      });
    };
  }, [widgetInstance, indexingStrategy, applyIndexing, originalContent]);

  return (
    <>
      <ShowIf If={isSaving || isGenerating}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '5rem',
            width: '100%',
            height: '60%',
          }}
        >
          <CircularProgress size={25} />
          <Typography variant="h3" sx={{ ml: '1rem' }}>
            {isGenerating ? 'Generating Passage...' : 'Saving Passage...'}
          </Typography>
        </Box>
      </ShowIf>
      {!isInputMode && renderIndexingControls()}
      <ShadowScrollbar
        style={{
          height: 'calc(60vh)',
          width: '100%',
        }}
      >
        {isInputMode ? renderInputArea() : <MenuColumn ref={mainRef} />}
      </ShadowScrollbar>
    </>
  );
};

export default PassageCreation;
