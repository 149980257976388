import {
  GroupAnswerType,
  PracticeStateType,
  PracticeState,
  stateType,
  answerType,
  lockedQuestionType,
} from '../types';

export enum PracticeActions {
  SET_ANSWER = 'SET_ANSWER',
  NEXT_QUESTION = 'NEXT_QUESTION',
  SET_GROUP_ANSWER = 'SET_GROUP_ANSWER',
  SET_PRACTICE_STATE = 'SET_PRACTICE_STATE',
  SET_QUESTION_ORDER = 'SET_QUESTION_ORDER',
  SET_STUDENTS_FINISHED_INDIVIDUAL = 'SET_STUDENTS_FINISHED_INDIVIDUAL',
  SET_INDIVIDUAL_WAITING_STATE = 'SET_INDIVIDUAL_WAITING_STATE',
  SET_GROUP_INSTRUCTION_STATE = 'SET_GROUP_INSTRUCTION_STATE',
  SET_REPORT_ERROR_MODAL_STATE = 'SET_REPORT_ERROR__MODAL_STATE',
  CLOSE_REPORT_ERROR_MODAL = 'CLOSE_REPORT_ERROR_MODAL',
  SET_LOCKED_GROUP_QUESTION = 'SET_LOCKED_GROUP_QUESTION',
}

type ACTIONTYPE =
  | {
      type: PracticeActions.SET_ANSWER;
      payload: {
        val: string | number;
        questionId: number | string;
      };
    }
  | {
      type: PracticeActions.NEXT_QUESTION;
    }
  | {
      type: PracticeActions.SET_GROUP_ANSWER;
      payload: Array<GroupAnswerType>;
    }
  | {
      type: PracticeActions.SET_PRACTICE_STATE;
      payload: PracticeStateType;
    }
  | {
      type: PracticeActions.SET_QUESTION_ORDER;
      payload: number;
    }
  | {
      type: PracticeActions.SET_STUDENTS_FINISHED_INDIVIDUAL;
      payload: Array<string | number>;
    }
  | {
      type: PracticeActions.SET_INDIVIDUAL_WAITING_STATE;
      payload: boolean;
    }
  | {
      type: PracticeActions.SET_GROUP_INSTRUCTION_STATE;
      payload: boolean;
    }
  | {
      type: PracticeActions.SET_LOCKED_GROUP_QUESTION;
      payload: lockedQuestionType;
    };
export const initialState = (): stateType => ({
  currentQuestionOrder: 0,
  groupAnswers: [],
  isGroupInstructionDialogOpen: false,
  isIndividualWaitingDialogOpen: false,
  lockedQuestions: {},
  lastTime: Date.now(),
  practiceAnswers: {},
  practiceState: PracticeState.Individual,
  studentsFinishedIndividual: [],
});
const checkGroupAnswersType = (groupAnswers: Array<GroupAnswerType>) => {
  if (groupAnswers.length === 0) {
    return true;
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < groupAnswers.length; i++) {
    const groupAnswer = groupAnswers[i];
    // don't check answer we need it if it undefined
    if (!groupAnswer.group_id || !groupAnswer.question_id || !groupAnswer.student_id) {
      // todo: add error message
      return false;
    }
  }
  return true;
};
const practiceReducer = (state: stateType, action: ACTIONTYPE): stateType => {
  switch (action.type) {
    case PracticeActions.SET_ANSWER: {
      // we want to calculate the response time for each answer when answer is set
      const timeFromLastModify = Date.now() - state.lastTime;
      const responseTime = state.practiceAnswers?.[action.payload.questionId]
        ?.response_time
        ? timeFromLastModify +
          // eslint-disable-next-line no-unsafe-optional-chaining
          state.practiceAnswers?.[action.payload.questionId].response_time
        : timeFromLastModify;
      const newState = {
        ...state,
        lastTime: Date.now(),
        practiceAnswers: {
          ...state.practiceAnswers,
          [action.payload.questionId]: {
            count: state.currentQuestionOrder,
            exam_question_id: action.payload.questionId,
            response_time: responseTime,
            value: action.payload.val,
          } as answerType,
        },
      };
      return newState;
    }
    case PracticeActions.NEXT_QUESTION:
      return {
        ...state,
        currentQuestionOrder: state.currentQuestionOrder + 1,
      };
    case PracticeActions.SET_GROUP_ANSWER:
      // since the data is coming from the WS , we need to check if the data is valid
      if (!checkGroupAnswersType(action.payload)) {
        return state;
      }
      return {
        ...state,
        groupAnswers: action.payload,
      };
    case PracticeActions.SET_PRACTICE_STATE:
      return {
        ...state,
        practiceState: action.payload,
      };
    case PracticeActions.SET_QUESTION_ORDER:
      return {
        ...state,
        currentQuestionOrder: action.payload,
      };
    case PracticeActions.SET_INDIVIDUAL_WAITING_STATE:
      return {
        ...state,
        isIndividualWaitingDialogOpen: action.payload,
      };
    case PracticeActions.SET_GROUP_INSTRUCTION_STATE:
      return {
        ...state,
        isGroupInstructionDialogOpen: action.payload,
        isIndividualWaitingDialogOpen: false,
      };
    case PracticeActions.SET_STUDENTS_FINISHED_INDIVIDUAL:
      return {
        ...state,
        studentsFinishedIndividual: action.payload,
      };
    case PracticeActions.SET_LOCKED_GROUP_QUESTION:
      return {
        ...state,
        lockedQuestions: action.payload,
      };
    default:
      return state;
  }
};
export default practiceReducer;
