import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import Tooltip from '@mui/material/Tooltip';
import ConfirmModal from '../ConfirmModal';
import SectionEditForm from './SectionEditForm';
import { renderAlertModal } from '../../utils/userAlert';
import {
  SectionDetailWrap,
  SectionDetailViewWrap,
  SectionDetailView,
  SectionActionGroup,
  SectionDetailTitle,
  SectionDetailInfo,
  SectionDetailLine,
} from './CourseManagementSection.style';
import { BreakLine } from '../SubNavBarItem/SubNavBar.style';
import { Button } from '../Generals/stylesheets/Button.style';
import { getSubjectPackage } from '../../utils/scheduling';
import { LESSON_PACKAGES } from '@utils/constants';
const DATE_IN_WEEK = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];


export default class SectionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
      isConfirmModalOpen: false,
    };
  }

  static propTypes = {
    sectionDetail: PropTypes.shape().isRequired,
    studentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    curriculumId: PropTypes.any,
    getCurriculumSubjectList: PropTypes.func,
    subjectsById: PropTypes.object,
    subjects: PropTypes.array,
    sectionsUpdate: PropTypes.func,
    deactivateSection: PropTypes.func.isRequired,
    isOwner: PropTypes.bool,
  };

  componentDidMount() {
    const { getCurriculumSubjectList, curriculumId, getAvailablePackages } = this.props;
    if (curriculumId) {
      getAvailablePackages(curriculumId);
    }
    getCurriculumSubjectList(curriculumId);
  }

  componentDidUpdate(prevProps) {
    const { getCurriculumSubjectList, curriculumId, getAvailablePackages} = this.props;
    const { curriculumId: prevCurriculumId } = prevProps;

    if (!_isEqual(curriculumId, prevCurriculumId)) {
      getCurriculumSubjectList(curriculumId);
    }
    if (prevProps.curriculumId != curriculumId && curriculumId) {
      getAvailablePackages(curriculumId);
    }
  }

  onOpenConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: true,
    });
  };

  onCloseConfirmModal = () => {
    this.setState({
      isConfirmModalOpen: false,
    });
  };

  onOpenModal = () => {
    this.setState({
      isEditable: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      isEditable: false,
    });
  };

  updateSection = (values) => {
    const { sectionsUpdate, sectionDetail, packagesPerSubject } = this.props;
    const existingSubjects = sectionDetail.subjects
    const sectionId = _get(sectionDetail, 'id', '');
    const { classDay, classDuration, subjects, ...rest } = values;
    const filterSubjects = subjects.filter(Boolean);
    const subjectsWithPackages = filterSubjects.map((subject) => {
      const packages = [];
      const foundationalPackage = getSubjectPackage(subject.id, LESSON_PACKAGES.FOUNDATIONAL, packagesPerSubject) || {};
      const corePackage = getSubjectPackage(subject.id, LESSON_PACKAGES.CORE, packagesPerSubject) || {};
      const subjectExistsWithPackage = existingSubjects.some(
        (oldSubject) => oldSubject.id === subject.id && oldSubject.packages.some((pkg) => pkg.name ===  LESSON_PACKAGES.FOUNDATIONAL)
      );
      if (foundationalPackage.id && subjectExistsWithPackage) {
        packages.push({ id: foundationalPackage.id });
      }
      packages.push({ id: corePackage.id });
      return { id: subject.id, units: [], packages };
    })
    const payload = {
      ...rest,
      class_days: (classDay || []).map((classDayItem) => ({
        day: classDayItem.day,
      })),
      subjects: subjectsWithPackages,
      class_duration: classDuration,
      holidays: sectionDetail.holidays,
    };

    if (
      subjects.length === 1 &&
      (subjects[0].name === 'Essay' || subjects[0].name === 'Writing')
    ) {
      renderAlertModal(`You can’t have only ${subjects[0].name} in your section`);
    } else {
      sectionsUpdate(sectionId, payload);
      this.setState({
        isEditable: false,
      });
    }
  };
  
  renderEditButtons = () => {
    const { sectionDetail } = this.props;
    const isDisable = sectionDetail.third_party_id;
    const tooltipMsg = isDisable ? 'Use Clever to manage your sections in ChalkTalk' : '';
    return (
      <Tooltip title={tooltipMsg} placement="bootom-center">
        <SectionActionGroup>
          <Button
            isDisable={isDisable}
            iconWidth
            className="chalktalk-edit"
            onClick={!isDisable ? this.onOpenModal : undefined}
          />
          <Button
            isDisable={isDisable}
            onClick={!isDisable ? this.onOpenConfirmModal : undefined}
          >
            Deactivate Section
          </Button>
        </SectionActionGroup>
      </Tooltip>
    );
  };

  handleDeactiveSection = () => {
    const { sectionDetail, deactivateSection } = this.props;
    const sectionId = _get(sectionDetail, 'id', '');
    deactivateSection(sectionId);
  };

  renderSubjectItem = (subject, index) => {
    const { subjectsById } = this.props;
    const subjectName = _get(subjectsById, `${subject.id}.name`, '');

    if (subjectName && index > 0) {
      return `, ${subjectName}`;
    }

    return subjectName;
  };

  renderClassDay = ({ day }, index) => {
    if (index > 0) {
      return `, ${DATE_IN_WEEK[day]}`;
    }

    return DATE_IN_WEEK[day];
  };

  renderProfileItem = (title, value, unit = '') => (
    <SectionDetailInfo>
      <SectionDetailTitle>{title}</SectionDetailTitle>
      <span>{`${value} ${unit}`}</span>
    </SectionDetailInfo>
  );

  renderSectionInfo = () => {
    const { sectionDetail, studentList, isOwner } = this.props;
    const { class_days: classDays } = sectionDetail;
    const sortedClassDays = _orderBy(classDays, 'day', 'asc');

    return (
      <SectionDetailViewWrap>
        {this.renderEditButtons()}

        <SectionDetailView>
          {this.renderProfileItem('Name', sectionDetail.name)}
          <BreakLine noMargin />
          {this.renderProfileItem('Student Code', _get(sectionDetail, 'code[0].code'))}
          <BreakLine noMargin />
          {this.renderProfileItem('Teacher Code', _get(sectionDetail, 'teacher_code'))}
          <BreakLine noMargin />
          {this.renderProfileItem('Student Count', studentList.length, 'students')}
          <BreakLine noMargin />

          <SectionDetailInfo>
            <SectionDetailTitle>Subjects</SectionDetailTitle>
            <span>
              {_isEmpty(sectionDetail.subjects) && 'Null'}
              {sectionDetail.subjects.map(this.renderSubjectItem)}
            </span>
          </SectionDetailInfo>
          <BreakLine noMargin />
          <SectionDetailInfo>
            <SectionDetailTitle>Days</SectionDetailTitle>
            <span>
              {_isEmpty(classDays) &&
                'This section is not set up yet. Click “Edit” below.'}
              {sortedClassDays.map(this.renderClassDay)}
            </span>
          </SectionDetailInfo>
          <BreakLine noMargin />
          {this.renderProfileItem('Duration', sectionDetail.class_duration, 'minutes')}
          <BreakLine noMargin />
          {/* TODO, ADD AN ICON FOR START AND END DAE */}
          {this.renderProfileItem('Start Date', sectionDetail.start_date)}
          <BreakLine noMargin />
          {this.renderProfileItem('End Date', sectionDetail.end_date)}
        </SectionDetailView>
      </SectionDetailViewWrap>
    );
  };

  renderEditForm = () => {
    const { isEditable } = this.state;
    const { sectionDetail, subjectsById, subjects } = this.props;
    const {
      name,
      subjects: subjectValue,
      class_days: classDay,
      class_duration: classDuration,
      id: sectionId,
    } = sectionDetail;
    const mappedSubjects = subjectValue.map((subject) => subjectsById[subject.id]);
    const initialValues = {
      name,
      subjects: mappedSubjects,
      classDay,
      classDuration,
    };

    return (
      <SectionEditForm
        initialValues={initialValues}
        sectionId={sectionId}
        subjects={subjects}
        isOpen={isEditable}
        onSubmit={this.updateSection}
        onClose={this.onCloseModal}
      />
    );
  };

  render() {
    const { isConfirmModalOpen } = this.state;

    return (
      <>
        <SectionDetailWrap>
          {this.renderSectionInfo()}
          {this.renderEditForm()}
        </SectionDetailWrap>
        <SectionDetailLine />
        <ConfirmModal
          content="Are you sure?"
          isOpen={isConfirmModalOpen}
          onConfirm={this.handleDeactiveSection}
          onClose={this.onCloseConfirmModal}
        />
      </>
    );
  }
}
