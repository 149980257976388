import styled, { css, keyframes } from 'styled-components';
import { Form } from 'formik';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const RegisterItemWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.isInModal ? 'auto' : '100vh'};
  background-color: ${(props) => props.theme.colorStyled.ColorBgSilver};
  ${({ isStudentMobile }) => isStudentMobile && css`
    @media (max-width: 768px) {
      position: absolute;
      height: 100%;
    }
  `}
`;

export const RegisterItem = styled.div`
`;

export const RegisterSuccessNotification = styled.div`
  width: calc(100% - 120px);
  max-width: 840px;
  margin: 35px auto;
  position: relative;
  background: ${(props) => props.theme.colorStyled.ColorWhite};
  padding: 10px;
  border: 2px solid ${(props) => props.theme.colorStyled.ColorSuccess};
  color: #1d1d1d;
  border-radius: 5px;
  font-size: 15px;
  text-align: center;
`;
export const RegisterTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 70px;
  border-bottom: 1px solid ${(props) => props.theme.colorStyled.ColorXBorder};
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  img {
    width: 200px;
  }
`;

export const RegisterHeaderTitle = styled.div`
  margin-right: 15px;
`;

export const RegisterFormDetail = styled.div`
  display: table-caption;
  align-items: right;
  text-align: right;
  justify-content: right;
  position: relative;
  color: darkgray;
  margin-top: -15px;
`;

export const RegisterButtonRight = styled.div`
  position: absolute;
  right: 60px;
  font-size: 12px;
  display: flex;
  align-items: center;
  transition: all 400ms ease;
  p {
    margin-right: 15px;
  }
  @media (max-width: 960px) {
    right: 20px;
  }
  /* @media (max-width: 840px) {
    p {
      display: none;
    }
  } */
`;
export const StudentListWrap = styled.div`
  font-size: 13px;
`;

export const InstructionContentWrap = styled.div`
  margin-bottom: 25px;
`;

export const InstructionText = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i {
    cursor: pointer;
  }
`;

export const ButtonStyled = styled.button`
  width: 320px;
  padding: 10px 30px;
  border-radius: 8.5px;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 1px solid ${(props) => props.theme.colorStyled.ColorBgDefault};
  background-color: ${(props) => props.theme.colorStyled.ColorBgDefault};
  box-shadow: 0px 4px 6.5px 0 #ff9e1630;
  margin-left: 15px;
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-color: #a3a0a0;
    background-color: #a3a0a0;
    box-shadow: 0 0 10.5px 0 rgba(0, 0, 0, 0.06);
  }
  &:first-child {
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    padding: 12px 25px;

  }
  ${({ small }) => small && css`
    width: auto;
    padding: 8px 30px;
    @media (max-width: 768px) {
      font-size: 1.175em;
    }
  `}
  ${({ medium }) => medium && css`
    width: 160px;
    padding: 8px 30px;
  `}
  ${({ manual }) => manual && css`
    width: 140px;
    margin-left: 0px;
  `}

`;

export const LearnMoreButton = styled.button`
  border: none;
  outline: none;
  font-family: 'MontserratSemiBold';
  font-style: italic;
  background-color: transparent;
  font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  &:hover {
    opacity: .7;
  }
`;

export const RegisterStepItemWrap = styled.div`
  margin-top: 30px;
`;

export const RegisterStepWrap = styled.div``;

export const StepWrap = styled.div`
  .rc-steps {
    width: 100%;
    line-height: 1.5;
    display: flex;
    height: 50px;
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  }
  .rc-steps-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    border-bottom: 1.5px solid ${(props) => props.theme.colorStyled.ColorXBorder};
  }
  .rcicon {
    margin-right: 5px;
  }
  .rc-steps-item-process,
  .rc-steps-item-finish {
    border-color: ${(props) => props.theme.colorStyled.ColorBgDefault};

    .rc-steps-item-content {
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
    }
    .rcicon {
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
    }
  }
  .rc-steps-item-process {
    &:after {
      position: absolute;
      content: '';
      background: ${(props) => props.theme.colorStyled.ColorWhite};
      border: 1.5px solid ${(props) => props.theme.colorStyled.ColorBgDefault};
      border-radius: 100%;
      right: 0px;
      bottom: -6px;
      width: 10px;
      height: 10px;
    }
  }
  @media (max-width: 768px) {
    .rc-steps-item-wait,
    .rc-steps-item-finish {
      display: none;
    }
    .rc-steps-item-process {
      &:after {
        content: none;
      }
      .rc-steps-item-title {
        text-transform: uppercase;
      }
    }
  }
`;

export const RegisterAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ large }) => large && css`
    a {
      width: 100%;
      max-width: 420px;
      &:first-child {
        margin-right: 15px;
      }
      &:last-child {
        margin-left: 15px;
      }
    }
    button {
      width: 100%;
    }
  `};

  ${({ isColumnDisplay }) => isColumnDisplay && css`
    flex-direction: column;
  `};
`;

export const RegisterMessageContentError = styled.div`
  min-height: ${(props) => props.isInModal ? '45px' : '200px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  p {
    line-height: 24px;
    text-align: center;
    color:red;

    span {
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
      margin-left: 5px;
      font-family: 'MontserratSemiBold';
    }
  }
  h1 {
    font-size: 50px;
    line-height: 100px;
  }
  input {
    border: none;
    border-bottom: 1px solid #dbdbdb;
    color: #333;
    position: relative;
    outline: none;
    width: 100%;
    max-width: 250px;
    font-size: 13px;
    padding: 5px 10px;
    height: 38px;
    transition: border 0.3s;
    background: transparent;
    line-height: 1.8;
    z-index: 2;
    font-family: 'MontserratMedium';
    text-align: center;
    &:focus {
      outline: none;
      border-bottom: 1.5px solid #ff9e16;
    }
    &::placeholder {
      font-style: italic;
      font-family: 'MontserratRegular';
    }
  }
`;

export const RegisterMessageContent = styled.div`
  min-height: ${(props) => props.isInModal ? '45px' : '200px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  p {
    line-height: 24px;
    text-align: center;
 

    span {
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
      margin-left: 5px;
      font-family: 'MontserratSemiBold';
    }
  }
  h1 {
    font-size: 50px;
    line-height: 100px;
  }
  input {
    border: none;
    border-bottom: 1px solid #dbdbdb;
    color: #333;
    position: relative;
    outline: none;
    width: 100%;
    max-width: 250px;
    font-size: 13px;
    padding: 5px 10px;
    height: 38px;
    transition: border 0.3s;
    background: transparent;
    line-height: 1.8;
    z-index: 2;
    font-family: 'MontserratMedium';
    text-align: center;
    &:focus {
      outline: none;
      border-bottom: 1.5px solid #ff9e16;
    }
    &::placeholder {
      font-style: italic;
      font-family: 'MontserratRegular';
    }
  }
`;

export const RegisterErrorMessage = styled.div`
  color: ${(props) => props.theme.colorStyled.ColorWarning};
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: ${(props) => props.theme.fontSize.SmallFontSize};
  animation-name: ${BounceInput};
  animation-duration: 300ms;
  animation-delay: 250ms;
  p {
    margin-bottom: 5px;
  }
`;

export const RegisterFormWrapper = styled.div`
${({ isStudentMobile }) => isStudentMobile && css`
    @media (max-width: 768px) {
      margin-left: 50px;
    }
  `}
`;

export const RegisterFormTitle = styled.div`
  width: ${(props) => props.isInModal ? 'auto' : 'calc(100% - 120px)'};
  max-width: 840px;
  margin: 40px auto;
  position: relative;
  max-width: 760px;
  h1 {
    font-family: 'MontserratBold';
    font-size: ${(props) => props.theme.fontSize.DefaultFontSize};
    margin-bottom: 10px;
  }
  h3 {
    font-size: 13px;
    font-family: 'MontserratRegular';
  }
  ul {
    font-size: 13px;
    font-family: 'MontserratRegular';
    list-style: disc inside;
    li{
      line-height: 2;
    }
  }
  ${({ large }) => large && css`
    max-width: unset;
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  `};
  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
`;

export const RegisterGroupItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 40px;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`;

export const RegisterFormInput = styled(Form)`
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  width: calc(100% - 120px);
  padding: 40px;
  max-width: 960px;
  margin: 0 auto;
  transition: all 300ms ease;
  margin-bottom: 1em;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    padding: 25px 30px;
  }
  ${({ isLoading }) => isLoading && css`
  margin-top: 50px;
  `}
  ${({ manual }) => manual && css`
    width: calc(35% - 15px);
    min-height: 350px;
    max-width: unset;
    margin: 0;
    padding: 25px;
    margin-left: 15px;
    @media (max-width: 1024px) {
      width: 100%;
      margin-left: 0px;
    }
  `}
`;

export const RegisterWrap = styled.div`
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  width: ${(props) => props.isInModal ? '100%' : 'calc(100% - 120px)'};
  padding: 40px;
  max-width: 960px;
  margin: 0 auto;
  transition: all 300ms ease;
  margin-top: 50px;
  ${({ isStudentMobile }) => isStudentMobile && css`
    @media (max-width: 768px) {
      width: calc(100% - 40px);
    }
  `}
`;

export const FormStyled = styled(Form)`
  border-radius: .325em;
  transition: all 300ms ease;
  padding: 1em 1.5em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
`;

export const DetailText = styled.p`
  color: darkgray;
  line-height: 6px;
  margin-bottom: 10px;
  font-size: 75% !important;
  font-family: 'MontserratMedium' !important;
  ${({ code }) => code && css`
    color: ${(props) => props.theme.colorStyled.ColorBgDefault};
  `}
`;

export const RegisterSubFormWrap = styled.div`
  margin: 10px 0px 25px;
  font-family: 'MontserratMedium';
  padding: 30px 20px 10px;
  border: 1px solid #eaedf0;
  border-radius: 8px;
  position: relative;
  h1 {
    padding: 0px 8px;
    background: ${(props) => props.theme.colorStyled.ColorWhite};
    color: #415063;
    position: absolute;
    z-index: 1;
    top: -8px;
    left: 11px;
  }
  h3 {
    margin-bottom: 30px;
    background: ${(props) => props.theme.colorStyled.ColorWhite};
    font-size: ${(props) => props.theme.fontSize.PrimaryFontSize};
    font-family: 'MontserratRegular';
    color: #415063;
    z-index: 1;
    margin-top: -15px;
    left: 11px;
  }
`;

export const RegisterCodeStyled = styled.h2`
  padding: 10px;
  background: ${(props) => props.theme.colorStyled.ColorBorder};
  color: ${(props) => props.theme.colorStyled.ColorBlack};
  border-radius: 10px;
  width: fit-content;
  margin: 10px auto;

  ${({ big }) => big && css`
    padding: 0.5em;
    margin: 0.2em auto;
    font-size: 2.5em;
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  `};
`;

export const DulicatedMessage = styled.div`
  margin-bottom: 1.5em;
  color: #E20002;
`;

export const ExamFormFieldsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ExamFormFieldsDesc = styled.div`
  flex-basis: 50%;
  font-size: 0.925em;
  & > ul {
    list-style-type: circle;
    & > li {
      line-height: 2;
    }
  }
`;

export const ExamFormFieldsSection = styled.div`
  flex-basis: calc(100%/3*2);
  & > div {
    & > div {
      width: 80%;
    }
  }
`;

// ================================================
export const RegistrationTimeContainer = styled.div`
`;
export const RegistrationTimeList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isCreateSection }) => isCreateSection && css`
    margin-top: 1em;
  `};

`;
export const RegistrationTimeItem = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: #4b82d4;
  font-family: 'MontserratSemiBold';
`;

export const RegistrationTotalTimeItem = styled(RegistrationTimeItem)`
  color: ${(props) => props.red ? 'red' : '#6fa557'};
`;
export const RegistrationTimeItemDataWrapper = styled.div`
`;
export const RegistrationTimeItemData = styled.div``;
export const RegistrationTimeItemUnit = styled.div`
  margin-right: 1em;
  margin-left: 1em;
`;
export const RegistrationTimeItemOps = styled.div`
  color: #222;
`;
export const RegistrationTimeItemNote = styled.div`
  text-align: center;
  margin-top: 1em;
  color: #222;
  font-family: 'MontserratSemiBold';
`;

export const ErrorExample = styled.div``;

export const ErrorExampleTitle = styled.div`
  margin: 1em 0em;
  font-weight: bold;
  color: ${(props) => props.theme.colorStyled.ColorBlack};
`;

export const ErrorExampleContent = styled.div`
  p {
    margin-bottom: .5em;
    border-radius: .325em;
    padding: .5em 1em;
    background: ${(props) => props.theme.colorStyled.ColorBorderPractice};
    color: ${(props) => props.theme.colorStyled.ColorBlack};
  }
`;

export const SystemRequirementLink = styled.div`
  margin-top: 5em;
  margin-bottom: 5em;
  text-align: left;
  width: 100%;
  display: flex;
  a {
    color: #ff9e16;
    padding-top: 2px;
    font-size: 16px;
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  img {
    width: 20px;
    margin-right: 10px;
    align-self: center;
    @media (max-width: 360px) {
      width: 15px;
      height: 15px;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 3em;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: 480px) {
    margin-bottom: 3em;
    text-align: center;
    justify-content: center;
  }
`;
