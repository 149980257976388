import { connect } from 'react-redux';
import CleverLoadingPage from '../../components/CleverLoadingPage';
import { actions as AuthActions, selectors as AuthSelectors } from '../../reducers/auth';

const mapStateToProps = state => ({
  isAuthenticated: AuthSelectors.getAuthenticatedStatus(state),
});

const mapDispatchToProps = {
  cleverLogin: AuthActions.cleverLogin,
  connectClever: AuthActions.connectClever,
};

export default connect(mapStateToProps, mapDispatchToProps)(CleverLoadingPage);
