import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import moment from 'moment';
import { DATE_FORMAT } from '../../utils/constants';
import {
  selectors as LeftSideBarSelector,
} from '../leftSideBar';

// action definition
export const SECTION_REVIEWS_ADD = 'sectionReviews/ADD';
export const SECTION_REVIEWS_ADD_SUCCESS = 'sectionReviews/ADD_SUCCESS';
export const SECTION_REVIEWS_ADD_FAIL = 'sectionReviews/ADD_FAIL';

export const SECTION_REVIEWS_GET_DETAIL = 'sectionReviews/GET_DETAIL';
export const SECTION_REVIEWS_GET_DETAIL_SUCCESS = 'sectionReviews/GET_DETAIL_SUCCESS';
export const SECTION_REVIEWS_GET_DETAIL_FAIL = 'sectionReviews/GET_DETAIL_FAIL';

export const SECTION_REVIEWS_UPDATE = 'sectionReviews/UPDATE';
export const SECTION_REVIEWS_UPDATE_SUCCESS = 'sectionReviews/UPDATE_SUCCESS';
export const SECTION_REVIEWS_UPDATE_FAIL = 'sectionReviews/UPDATE_FAIL';

export const SECTION_REVIEWS_GET = 'sectionReviews/GET';
export const SECTION_REVIEWS_GET_SUCCESS = 'sectionReviews/GET_SUCCESS';
export const SECTION_REVIEWS_GET_FAIL = 'sectionReviews/GET_FAIL';

export const SECTION_REVIEWS_DELETE = 'sectionReviews/DELETE';
export const SECTION_REVIEWS_DELETE_SUCCESS = 'sectionReviews/DELETE_SUCCESS';
export const SECTION_REVIEWS_DELETE_FAIL = 'sectionReviews/DELETE_FAIL';

export const SECTION_REVIEWS_SECTION_SUMMARY = 'sectionReviewsSecionSummary/GET';
export const SECTION_REVIEWS_SECTION_SUMMARY_SUCCESS = 'sectionReviewsSecionSummary/GET_SUCCESS';
export const SECTION_REVIEWS_SECTION_SUMMARY_FAIL = 'sectionReviewsSecionSummary/GET_FAIL';

export const SECTION_USER_REVIEWS_SECTION_SUMMARY = 'sectionReviewsSecionSummary/GET_USER';
export const SECTION_USER_REVIEWS_SECTION_SUMMARY_SUCCESS = 'sectionReviewsSecionSummary/GET_USER_SUCCESS';
export const SECTION_USER_REVIEWS_SECTION_SUMMARY_FAIL = 'sectionReviewsSecionSummary/GET_USER_FAIL';

const getReviewIds = ({ sectionReviews }) => _get(sectionReviews, 'reviewsAllIds', []);
const getReviewByIds = ({ sectionReviews }) => _get(sectionReviews, 'reviewsByIds', {});
const isLoadingReviewAdding = ({ sectionReviews }) => sectionReviews.isAdding;
const errorMsg = ({ sectionReviews }) => sectionReviews.error;
const getReviewList = createSelector(
  getReviewIds,
  getReviewByIds,
  (Ids, byIds) => Ids.map(id => byIds[id]),
);

const getReviewListWithSubjectId = createSelector(
  getReviewIds,
  getReviewByIds,
  LeftSideBarSelector.getSubjectId,
  (Ids, byIds, subjectId) => (
    Ids.map(id => byIds[id])
      .filter(review => review.subject === subjectId)
  ),
);

const getReviewUpcommingList = createSelector(
  getReviewListWithSubjectId,
  (reviewList) => {
    const today = moment().format(DATE_FORMAT.DATE);

    return reviewList
      .filter(session => session.date >= today)
      .sort((reviewA, reviewB) => moment(reviewA.date).diff(moment(reviewB.date)))
      .slice(0, 2);
  },
);

const getReviewUpcommingListWithoutSubject = createSelector(
  getReviewList,
  (reviewList) => {
    const today = moment().format(DATE_FORMAT.DATE);

    return reviewList
      .filter(session => session.date >= today)
      .sort((reviewA, reviewB) => moment(reviewA.date).diff(moment(reviewB.date)));
  },
);
const isSubmitSuccessfully = ({ sectionReviews }) => sectionReviews.submitSuccess;

const isUpdatingReview = ({ sectionReviews }) => sectionReviews.isUpdating;
const isGettingSectionReview = ({ sectionReviews }) => sectionReviews.isGetting;
const getUpdateErrorMessage = ({ sectionReviews }) => sectionReviews.updateError;
const getReviewDetailById = ({ sectionReviews }, reviewId) => _get(sectionReviews, `reviewsByIds.${reviewId}`, {});

const getShouldFetch = ({ sectionReviews }, sectionId) => _get(sectionReviews, 'hasFetchedOfSectionId', null) != sectionId; // eslint-disable-line
const getReviewAllByReviewId = ({ sectionReviews }) => _get(sectionReviews, 'reviewAllByReviewId', {});

const getTotalReview = ({ sectionReviews }) => _get(sectionReviews, 'totalReview', {});

export const selectors = {
  getReviewIds,
  getReviewByIds,
  getReviewList,
  errorMsg,
  isLoadingReviewAdding,
  isSubmitSuccessfully,
  isGettingSectionReview,
  getReviewUpcommingList,
  getReviewListWithSubjectId,
  getReviewUpcommingListWithoutSubject,

  isUpdatingReview,
  getUpdateErrorMessage,
  getReviewDetailById,
  getShouldFetch,

  getReviewAllByReviewId,

  getTotalReview,
};

const sectionReviewGet = (sectionId, subjectId) => ({
  type: SECTION_REVIEWS_GET,
  sectionId,
  subjectId,
});

const sectionReviewGetSuccess = (reviewList, sectionId) => ({
  type: SECTION_REVIEWS_GET_SUCCESS,
  reviewList,
  sectionId,
});

const sectionReviewGetFail = error => ({
  type: SECTION_REVIEWS_GET_FAIL,
  error,
});

const sectionReviewDelete = sectionId => ({
  type: SECTION_REVIEWS_DELETE,
  sectionId,
});

const sectionReviewDeleteSuccess = id => ({
  type: SECTION_REVIEWS_DELETE_SUCCESS,
  id,
});

const sectionReviewDeleteFail = error => ({
  type: SECTION_REVIEWS_DELETE_FAIL,
  error,
});
// action creator
/**
 * @param {*} reviewData Object
 */

const sectionReviewAdd = reviewData => ({
  type: SECTION_REVIEWS_ADD,
  reviewData,
});

/**
  * !Required define payload params clearly
 * @param {*} payload
 */
const sectionReviewAddSuccess = payload => ({
  type: SECTION_REVIEWS_ADD_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionReviewAddFail = payload => ({
  type: SECTION_REVIEWS_ADD_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 * @param {*} reviewId String
 */

const sectionReviewGetDetail = (sectionId, reviewId) => ({
  type: SECTION_REVIEWS_GET_DETAIL,
  sectionId,
  reviewId,
});

/**
  * !Required define payload params clearly
 * @param {*} payload
 */
const sectionReviewGetDetailSuccess = payload => ({
  type: SECTION_REVIEWS_GET_DETAIL_SUCCESS,
  payload,
});

/**
 * !Required define payload params clearly
 * @param {*} payload
 */
const sectionReviewGetDetailFail = payload => ({
  type: SECTION_REVIEWS_GET_DETAIL_FAIL,
  payload,
});

/**
 * @param {*} sectionId String
 * @param {*} reviewId String
 * @param {*} reviewData Object
 */

const sectionReviewUpdate = (sectionId, reviewId, reviewData) => ({
  type: SECTION_REVIEWS_UPDATE,
  sectionId,
  reviewId,
  reviewData,
});

/**
  * !Required define payload params clearly
 * @reviewId {String}
 * @reviewInfo {Object}
 */
const sectionReviewUpdateSuccess = (reviewId, reviewInfo) => ({
  type: SECTION_REVIEWS_UPDATE_SUCCESS,
  reviewId,
  reviewInfo,
});

/**
 * !Required define payload params clearly
 * @errorMsg {String}
 */
const sectionReviewUpdateFail = error => ({
  type: SECTION_REVIEWS_UPDATE_FAIL,
  error,
});

const sectionReviewSectionSummaryGet = (sectionId, subjectId) => ({
  type: SECTION_REVIEWS_SECTION_SUMMARY,
  sectionId,
  subjectId,
});

const sectionReviewSectionSummaryGetSuccess = totalReview => ({
  type: SECTION_REVIEWS_SECTION_SUMMARY_SUCCESS,
  totalReview,
});
const sectionReviewSectionSummaryGetFail = error => ({
  type: SECTION_REVIEWS_SECTION_SUMMARY_FAIL,
  error,
});

const sectionStudentReviewSectionSummaryGet = (sectionId, subjectId) => ({
  type: SECTION_USER_REVIEWS_SECTION_SUMMARY,
  sectionId,
  subjectId,
});

const sectionStudentReviewSectionSummaryGetSuccess = (totalReview, userId) => ({
  type: SECTION_USER_REVIEWS_SECTION_SUMMARY_SUCCESS,
  totalReview,
  userId,
});
const sectionStudentReviewSectionSummaryGetFail = error => ({
  type: SECTION_USER_REVIEWS_SECTION_SUMMARY_FAIL,
  error,
});

// selectors
// export const getSectionList = ({ section/reviews }) => section/reviews.sectionList || {};

export const actions = {
  sectionReviewSectionSummaryGet,
  sectionReviewSectionSummaryGetSuccess,
  sectionReviewSectionSummaryGetFail,

  // add review
  sectionReviewAdd,
  sectionReviewAddSuccess,
  sectionReviewAddFail,
  // get review detail
  sectionReviewGetDetail,
  sectionReviewGetDetailSuccess,
  sectionReviewGetDetailFail,
  // update review
  sectionReviewUpdate,
  sectionReviewUpdateSuccess,
  sectionReviewUpdateFail,
  // get review
  sectionReviewGet,
  sectionReviewGetSuccess,
  sectionReviewGetFail,

  sectionReviewDelete,
  sectionReviewDeleteSuccess,
  sectionReviewDeleteFail,

  sectionStudentReviewSectionSummaryGet,
  sectionStudentReviewSectionSummaryGetSuccess,
  sectionStudentReviewSectionSummaryGetFail,
};

// reducers

const initialState = {
  isAdding: false, // is review of section adding
  isGetDetail: false, // is get review detail of section

  isUpdating: false, // is review of section updating
  updateError: null,

  error: null,
  submitSuccess: false,
  isGetting: false,
  reviews: {},
  reviewsAllIds: [],
  reviewsByIds: {},

  isDelete: false,
  deleteSuccess: false,
  hasFetchedOfSectionId: null,

  reviewAllByReviewId: {},
  getReviewAllError: null,

  totalReview: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    // add session of section
    case SECTION_REVIEWS_ADD:
      return { ...state, isAdding: true, submitSuccess: false };
    case SECTION_REVIEWS_ADD_SUCCESS: {
      const { payload: reviewObj } = action;
      const { id } = reviewObj;
      const currentIds = _get(state, 'reviewsAllIds', []);
      const currntByIds = _get(state, 'reviewsByIds', {});
      return {
        ...state,
        isAdding: false,
        reviewsAllIds: [
          ...currentIds,
          id,
        ],
        reviewsByIds: {
          ...currntByIds,
          [id]: reviewObj,
        },
        error: null,
        submitSuccess: true,
      };
    }

    case SECTION_REVIEWS_ADD_FAIL: {
      const { payload } = action;
      return {
        ...state,
        isAdding: false,
        error: payload,
        submitSuccess: false,
      };
    }
    // get review of section
    case SECTION_REVIEWS_GET_DETAIL:
      return { ...state, isGetDetail: true };
    case SECTION_REVIEWS_GET_DETAIL_SUCCESS:
    case SECTION_REVIEWS_GET_DETAIL_FAIL:
      return { ...state, isGetDetail: false };
    // update review
    case SECTION_REVIEWS_UPDATE:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
      };

    case SECTION_REVIEWS_UPDATE_SUCCESS: {
      const {
        reviewId,
        reviewInfo,
      } = action;

      return {
        ...state,
        isUpdating: false,
        reviewsByIds: {
          ...state.reviewsByIds,
          [reviewId]: reviewInfo,
        },
      };
    }

    case SECTION_REVIEWS_UPDATE_FAIL: {
      const { error } = action;
      return {
        ...state,
        isUpdating: false,
        updateError: error,
      };
    }

    case SECTION_REVIEWS_GET: {
      const { sectionId } = action;
      const shouldFetch = sectionId != state.hasFetchedOfSectionId; // eslint-disable-line

      return { ...state, isGetting: shouldFetch };
    }
    case SECTION_REVIEWS_GET_SUCCESS: {
      const { reviewList = [], sectionId } = action;
      const reviewsAllIds = reviewList.map(({ id }) => id);
      const reviewsByIds = _keyBy(reviewList, 'id');

      return {
        ...state,
        isGetting: false,
        reviewsAllIds,
        reviewsByIds,
        hasFetchedOfSectionId: sectionId,
      };
    }
    case SECTION_REVIEWS_GET_FAIL:
      return {
        ...state,
        isGetting: false,
      };

    case SECTION_REVIEWS_DELETE:
      return {
        ...state,
        isDelete: true,
      };

    case SECTION_REVIEWS_DELETE_SUCCESS: {
      const { id } = action;
      const currentIds = _get(state, 'reviewsAllIds', []);
      const currntByIds = _get(state, 'reviewsByIds', {});
      const reviewsAllIds = currentIds.filter(x => x !== id);
      delete currntByIds[id];
      return {
        ...state,
        reviewsAllIds,
        reviewsByIds: currntByIds,
        error: null,
        isDelete: false,
        deleteSuccess: true,
      };
    }

    case SECTION_REVIEWS_DELETE_FAIL: {
      const { error } = action;
      return {
        ...state,
        error,
        isDelete: false,
        deleteSuccess: false,
      };
    }
    case SECTION_REVIEWS_SECTION_SUMMARY_SUCCESS:
    case SECTION_USER_REVIEWS_SECTION_SUMMARY_SUCCESS: {
      const { totalReview } = action;
      const reviewAllByReviewId = totalReview.reduce((prevValue, review) => {
        const { completed, count, id: reviewId } = review;
        const currentPercent = _get(prevValue, reviewId, 0);

        return {
          ...prevValue,
          [reviewId]: currentPercent + (completed / count),
        };
      }, {});
      return {
        ...state,
        totalReview,
        reviewAllByReviewId,
        getReviewAllError: null,
      };
    }

    case SECTION_USER_REVIEWS_SECTION_SUMMARY_FAIL:
    case SECTION_REVIEWS_SECTION_SUMMARY_FAIL: {
      const { error } = action;
      return {
        ...state,
        getReviewAllError: error,
      };
    }
    default: {
      return state;
    }
  }
}
