import React from 'react';
import Button from '@components/Atoms/Button';
import Box from '@components/Atoms/Box/Box';
import { handleBlobFromUrl } from '@utils/downloadFile';
import ShowIf from '@components/Atoms/ShowIf';
import { CircularProgress } from '@mui/material';
import { isGoogleDomain, downloadGoogleFile } from '../../utils/printGoogleFile';
import { DownloadIcon, GroupActivityWorksheetWrapper } from './GroupActivity.style';

const DownloadWorksheetElement = ({ item }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  const handleClickDownload = React.useCallback(async () => {
    setIsDownloading(true);
    await downloadGoogleFile(item.doc_url, item.name);
    setIsDownloading(false);
  }, [item.doc_url]);

  return (
    <Button
      onClick={handleClickDownload}
      disabled={isDownloading}
      variant="text"
      color="primary"
    >
      {isDownloading ? (
        <span>Loading...</span>
      ) : (
        <p>
          <DownloadIcon src="/images/down-arrow.svg" />
          <span>{item.name}</span>
        </p>
      )}
    </Button>
  );
};

const DownloadableWorksheet = ({ item }) => {
  const [isDownloading, setIsDownloading] = React.useState(false);
  if (isGoogleDomain(item.doc_url)) {
    return <DownloadWorksheetElement item={item} />;
  }

  const onAnchorClick = async () => {
    setIsDownloading(true);
    await handleBlobFromUrl(item.doc_url);
    setIsDownloading(false);
  };

  return (
    <Button
      variant="text"
      sx={{
        alignSelf: 'flex-start',
        '&:hover': {
          background: 'none',
        },
      }}
      onClick={onAnchorClick}
      disabled={isDownloading}
      startIcon={
        <>
          <ShowIf If={isDownloading}>
            <CircularProgress size={16} />
          </ShowIf>
          <ShowIf If={!isDownloading}>
            <DownloadIcon src="/images/down-arrow.svg" />
          </ShowIf>
        </>
      }
    >
      <Box component="span" sx={{ color: 'black' }}>
        {item.name}
      </Box>
    </Button>
  );
};

export const GroupActivityWorksheet = ({ isTeacher, documents }) => {
  const studentDocuments = documents.filter((doc) => doc.account_type === 1);
  return (
    <GroupActivityWorksheetWrapper>
      <h6>
        <b>Printable Worksheets</b>
      </h6>
      {[...(isTeacher ? documents : studentDocuments)].map((item) => (
        <DownloadableWorksheet item={item} key={`worksheet_${item.name}`} />
      ))}
    </GroupActivityWorksheetWrapper>
  );
};

export default React.memo(DownloadableWorksheet);
