import { connect } from 'react-redux';
import LessonReviewPage from '../../components/LessonReviewPage';
import { selectors as UserSelectors } from '../../reducers/user';
import { actions as sectionStudentActions } from '../../reducers/sectionStudent';
import {
  actions as sectionActivityActions,
} from '../../reducers/activity';
import { actions as sectionSkillsActions } from '../../reducers/sectionSkills';

const mapStateToProps = state => ({
  userId: UserSelectors.getUserId(state),
  userRole: UserSelectors.getUserRole(state),
});

const mapDispatchToProps = {
  getSectionReviewSessionData: sectionActivityActions.getSectionReviewSessionData, // for teacher
  getStudentReviewSessionData: sectionActivityActions.getStudentReviewSessionData, // for student
  sectionsStudentGet: sectionStudentActions.sectionsStudentGet, // for teacher
  sectionSkillsGet: sectionSkillsActions.sectionSkillsGet, // for student

};

export default connect(mapStateToProps, mapDispatchToProps)(LessonReviewPage);
