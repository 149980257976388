import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LeftSideBarCourseManagement from '../../containers/LeftSideBar/LeftSideBarCourseManagement';
import GroupInviteStudentItem from '../../containers/CourseManagementInviteStudent/GroupInviteStudentItem';
import QuickInviteStudentForm from '../../containers/CourseManagementInviteStudent/QuickInviteStudentForm';
import StudentListTable from '../../containers/StudentListTable';
import BreadcumbsFilter from '../../containers/BreadcumbsFilter';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import MainContent from '../../containers/MainContent';
import {
  CourseManagementContent,
  InviteStudentWrap,
} from './CourseManagementInviteStudent.style';
import {
  PrimaryWrap,
  DefaultContentWrap,
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';
import {
  RegisterMessageContent,
  RegisterCodeStyled,
} from '../StudentRegister/StudentRegister.style';
const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

export default class CourseManagementInviteStudent extends PureComponent {
  static propTypes = {
    isSideBarClose: PropTypes.bool,
    courseDetail: PropTypes.object,
  }

  render() {
    const { courseDetail, isSideBarClose, sectionDetail: { code } } = this.props;
    const sectionCode = (code || []).map(e => e.code);

    return (
      <PrimaryWrap>
        <LeftSideBarCourseManagement pathName="/management/course" />
        <MainContent>
          <BreadcumbsFilter
            haveSub={isSideBarClose}
            breadcumbSubList={[courseDetail.name]}
            breadcumbList={['Course Management', 'Invite Student']}
            mobileTitle="Invite Student"
            isDisplayFilter={false}
          />

          <ShadowScrollbar autoHide style={scrollStyle}>
            <DefaultContentWrap>
              <CourseManagementContent>
                <BlockWrap>
                  <BlockTitle>Student Code</BlockTitle>
                  <p>Students can enroll in section using the section student code.</p>
                  <RegisterCodeStyled big>
                    {sectionCode}
                  </RegisterCodeStyled>
                </BlockWrap>
                <InviteStudentWrap>
                  <GroupInviteStudentItem />
                  <QuickInviteStudentForm />
                </InviteStudentWrap>

                <StudentListTable />
              </CourseManagementContent>
            </DefaultContentWrap>
          </ShadowScrollbar>
        </MainContent>
      </PrimaryWrap>
    );
  }
}
