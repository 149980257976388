import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  UserProfileInfo,
  UserLabel,
  UserContent,
  UserProfileView,
} from '../Profile/Profile.style';
import { formatDate } from '../../utils/func-utils';
import { STANDARD_SESSION_TYPE } from '../../utils/constants';
import { ButtonWrapper } from './ScheduleAddLessonSessionForm.style';

class AddLessonSessionStepFive extends React.PureComponent {
  renderLessonBlock = () => {
    const { values } = this.props;
    const {
      lesson, activitySlide, activityWorksheet, activityPractice,
    } = values;

    return (
      <React.Fragment>
        <UserProfileInfo>
          <UserLabel>Lesson</UserLabel>
          <UserContent>{lesson.name}</UserContent>
        </UserProfileInfo>
        <UserProfileInfo>
          <UserLabel>Slides</UserLabel>
          <UserContent>{activitySlide.label}</UserContent>
        </UserProfileInfo>
        {activityWorksheet && (
          <UserProfileInfo>
            <UserLabel>Worksheet</UserLabel>
            <UserContent>{activityWorksheet.label}</UserContent>
          </UserProfileInfo>
        )}
        {activityPractice && (
          <UserProfileInfo>
            <UserLabel>Practice</UserLabel>
            <UserContent>{activityPractice.label}</UserContent>
          </UserProfileInfo>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { values } = this.props;
    const {
      date, sessionType, subject, unit,
    } = values;
    // const subjectList = subject.map(s => s.display_name || s.name).filter(Boolean).join`, `;
    // const unitList = unit.map(u => u.name).filter(Boolean).join`, `;
    const subjectName = subject.display_name || subject.name;
    const unitName = unit.name;
    const shouldRenderLessonBlock = sessionType.value === STANDARD_SESSION_TYPE.LESSSON;

    return (
      <div>
        <UserProfileView>
          <UserProfileInfo>
            <UserLabel>Date</UserLabel>
            <UserContent>{formatDate(date)}</UserContent>
          </UserProfileInfo>
          <UserProfileInfo>
            <UserLabel>Session Type</UserLabel>
            <UserContent>{sessionType.label}</UserContent>
          </UserProfileInfo>
          {/* <UserProfileInfo> */}
          {/*  <UserLabel>Subject</UserLabel> */}
          {/*  <UserContent>{subjectList}</UserContent> */}
          {/* </UserProfileInfo> */}
          {/* <UserProfileInfo> */}
          {/*  <UserLabel>Unit</UserLabel> */}
          {/*  <UserContent>{unitList}</UserContent> */}
          {/* </UserProfileInfo> */}
          <UserProfileInfo>
            <UserLabel>Subject</UserLabel>
            <UserContent>{subjectName}</UserContent>
          </UserProfileInfo>
          <UserProfileInfo>
            <UserLabel>Unit</UserLabel>
            <UserContent>{unitName}</UserContent>
          </UserProfileInfo>
          {shouldRenderLessonBlock && this.renderLessonBlock()}
        </UserProfileView>
        <ButtonWrapper>
          <Button submit onClick={this.props.goToPrevStep}>
            Previous step
          </Button>
          <Button submit type="submit" onClick={this.props.handleSubmit}>
            Create new session
          </Button>
        </ButtonWrapper>
      </div>
    );
  }
}

AddLessonSessionStepFive.propTypes = {
  goToPrevStep: PropTypes.func.isRequired,
  values: PropTypes.object,
  handleSubmit: PropTypes.func,
};

export default AddLessonSessionStepFive;
