import React from 'react';
import { TabPanel } from '@components/Shared/TabsDefaultSkin';
import LessonDetailTab from '@containers/LessonDetailTab';
import ActivityFactory from '@components/Component/OLP/tab/ActivityFactory';
import InterventionTab from '@containers/InterventionTab';
import PlanAndStandardTab from '@components/PlanAndStandardTab';
import StudyHall from '@components/StudyHall';
import ChalkTalkErrorBoundary from '@components/ChalkTalkErrorBoundary';
import { useReduxAttendanceDataUpdater } from '../hooks';
import { LessonTabs } from '../Types';
import PracticeFactory from './PracticeFactory';
import LearnosityAdaptivePracticeCard from './LearnosityAdaptivePracticeTab';
import { InteractionContextProvider } from '@components/ElementAnalytics/InteractionContext';

const tabPanelLists = [
  {
    id: 1,
    content: <PlanAndStandardTab />,
    tabKey: LessonTabs.LessonPlan,
  },
  {
    id: 2,
    content: <LessonDetailTab />,
    tabKey: LessonTabs.Lesson,
  },
  {
    id: 3,
    content: <ActivityFactory />,
    tabKey: LessonTabs.LessonPractice,
  },
  {
    id: 4,
    content: <PracticeFactory />,
    tabKey: LessonTabs.Practice,
  },

  {
    id: 5,
    content: <InterventionTab />,
    tabKey: LessonTabs.Intervention,
  },
  {
    id: 6,
    content: <StudyHall />,
    tabKey: LessonTabs.StudyHall,
  },
  {
    id: 7,
    content: <LearnosityAdaptivePracticeCard />,
    tabKey: LessonTabs.LearnosityAdaptivePractice,
  },
];
const TabViewContent = () => {
  // we use this hook to update Attendance and student lists in the intervention , and OLP , Lesson Detail tab
  useReduxAttendanceDataUpdater();

  return (
    <>
      {tabPanelLists.map((tabPanelInfo) => (
        <div
          style={{ display: 'contents' }}
          key={tabPanelInfo.id}
          data-testid={`tabPanel-${tabPanelInfo.tabKey}`}
        >
          <ChalkTalkErrorBoundary>
            <InteractionContextProvider interactionArea={tabPanelInfo.tabKey}>
              <TabPanel tabKey={tabPanelInfo.tabKey}>{tabPanelInfo.content}</TabPanel>
            </InteractionContextProvider>
          </ChalkTalkErrorBoundary>
        </div>
      ))}
    </>
  );
};

export default TabViewContent;
