import styled, { css } from 'styled-components';

export const FeedbackContainer = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 0;
`;

export const FeedbackIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  pointer-events: none;
`;

export const LessonCompleteContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 1.5em;
border-top: 1px solid #091e4214;
cursor: pointer;
box-shadow: #091e4240 0px 1px 1px, #091e424f 0px 0;
background-color: #ffffff;
border: 1px solid #f1f1f1;
border-radius: 0.5em;
margin: 1em 0.125em 0.75em;
overflow: hidden;
`

export const LessonCompleteStatus = styled.span`
background: ${props => props.theme.colorStyled.ColorBgAvartar};
font-size: ${props => props.theme.fontSize.PrimaryFontSize};
border-radius: 100%;
width: 1.75em;
height: 1.75em;
display: flex;
align-items: center;
justify-content: center;
transition: all 300ms ease;
cursor: pointer;
${({ isCompleted }) => isCompleted && css`
  background: ${props => props.theme.colorStyled.ColorActiveCheckbox};
  color: ${props => props.theme.colorStyled.ColorWhite};
`}

@media (max-width: 360px) {
  align-self: flex-end;
}
`


export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin-bottom: 1em;
  i {
    font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
    margin-right: .5em;
    color: #aeaeae;
  }
  color: #F6A527;
  justify-content: center;
  text-transform: none;
  font-size: 16px;
`;

export const ModalContent = styled.div`
  line-height: 20px;
  color: #6e6e6e;
  text-align: center;
  > i {
    position: absolute;
    right: 1.5em;
    top: 2em;
  }
`;

export const ModalGroupAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-content: center;
  margin-top: 1.25em;
  button {
    margin-left: .5em;
    text-align: center;
    border-radius: .325em;
    border-radius: 7px;
    font-family: 'MontserratSemiBold';
    cursor: pointer;
    border: none;
    color: #ffffff;
    width: 6em;
    height: 2.5em;
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
    &:first-child {
      background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    background-color: unset;
    color: #4786FC;
    width: fit-content;
    border: 1px solid #4786FD;
    }
    background-color: #848484;
    margin: 0 0.5em;
    background-color: #4786FD;
    padding: 1px 15px;
  }
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
