import React from 'react';
import InformationAlert from '@Atoms/InformationAlert';
import Typography from '@Atoms/Typography';
import { useIsTeacher } from '../../reducers/user/hooks';

const StandardsPracticeOverViewInformation = () => {
  const isTeacher = useIsTeacher();
  if (!isTeacher) {
    return null;
  }
  return (
    <InformationAlert title="Overview">
      <Typography>
        Our practice experience is based on State Standards not lessons, because standards
        mastery is the end goal
        <Typography component="strong" sx={{ fontWeight: 'bold' }}>
          &nbsp;Multiple lessons may align to the same standard.&nbsp;
        </Typography>
        Click on a standard to see all lessons aligned to it.
      </Typography>
      <Typography sx={{ mt: 1 }}>
        If you haven&#39;t taught all the lessons aligned to this standard, you can still
        ask your students to do the practice.
        <Typography component="strong" sx={{ fontWeight: 'bold' }}>
          &nbsp;All questions will be on closely related lessons.
        </Typography>
      </Typography>
    </InformationAlert>
  );
};

export default StandardsPracticeOverViewInformation;
