import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  LoginContainerWrap,
  Image,
  ImageWrap,
  Loading,
} from '../LoginPage/Login.style';

export default class EmailVerification extends Component {
  static propTypes = {
    verifyEmail: PropTypes.func.isRequired,
    // isLoading: PropTypes.bool.isRequired,
    match: PropTypes.any,
  }

  componentDidMount = () => {
    const { verifyEmail, match } = this.props;
    const { params } = match;
    verifyEmail(params.token);
  }

  render() {
    // const { isLoading } = this.props;
    return (
      <LoginContainerWrap verify>
        <ImageWrap>
          <Image className="chalktalk-email-verify">
            <i className="path1" />
            <i className="path2" />
            <i className="path3" />
            <i className="path4" />
            <i className="path5" />
            <i className="path6" />
            <i className="path7" />
            <i className="path8" />
            <i className="path9" />
            <i className="path10" />
            <i className="path11" />
            <i className="path12" />
          </Image>
          {/* <Loading>
            <LoadingIndicator isLoading={false} />
          </Loading> */}
        </ImageWrap>
      </LoginContainerWrap>
    );
  }
}
