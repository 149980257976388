import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import StudentGroupInviteTable from './StudentGroupInviteTable';
import { StudentGroupInviteFormWrap } from '../StudentListTable/StudentListTable.style';
import {
  FormStyled,
  RegisterAction,
  ButtonStyled,
} from '../StudentRegister/StudentRegister.style';
import {
  Button,
  ActionForm,
} from '../Generals/stylesheets/Button.style';
import {
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';

const initialValues = {
  studentInfo: [],
};

const validationSchema = Yup.object().shape({
  studentInfo: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Invalid email'),
      }),
    )
    .required('Student info must have value'),
});

class StudentGroupInviteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowPaste: false,
    };
    this.objStudent = {};
  }

  static propTypes = {
    submitInviteStudent: PropTypes.func.isRequired,
    sectionId: PropTypes.string,
    isInManagementpage: PropTypes.bool,
  }

  handleSubmitAddGroup = (values, { resetForm }) => {
    const { submitInviteStudent, sectionId } = this.props;
    const { studentInfo } = values;
    if (studentInfo.length === 0) return;
    submitInviteStudent(values.studentInfo, sectionId);
    resetForm();
  }

  togglePasteAction = allowPaste => (e) => {
    e.preventDefault();
    this.setState({
      allowPaste,
    });
  };

  renderStudentForm = () => {
    const { allowPaste } = this.state;
    const { isInManagementpage, inviteError } = this.props;

    return (
      <BlockWrap>
        <BlockTitle>Group Add</BlockTitle>
        <FormStyled
          tabIndex="0"
          noValidate
          onFocus={this.togglePasteAction(true)}
          onBlur={this.togglePasteAction(false)}
        >
          <StudentGroupInviteTable
            name="studentInfo"
            allowPaste={allowPaste}
          />
          { isInManagementpage
            ? (
              <ActionForm>
                <Button full>
                  Add
                  <i className="chalktalk-icon-add" />
                </Button>
              </ActionForm>
            ) : (
              <RegisterAction>
                <ButtonStyled medium>Invite</ButtonStyled>
              </RegisterAction>
            )}
        </FormStyled>
      </BlockWrap>
    );
  }

  render() {
    return (
      <StudentGroupInviteFormWrap>
        <Formik
          onSubmit={this.handleSubmitAddGroup}
          initialValues={initialValues}
          render={this.renderStudentForm}
          validationSchema={validationSchema}
        />
      </StudentGroupInviteFormWrap>
    );
  }
}

export default StudentGroupInviteItem;
