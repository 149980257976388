/* eslint-disable import/prefer-default-export */
import {
  getLearnosityLessonReview,
  getLearnosityLessonReviewScoresList,
  getLearnosityLessonReviewSessions,
  postLearnosityLessonReview,
} from '@apis/practice';
import {
  useCurrentSubject,
  useCurrentUnit,
} from '@components/Component/Lesson/LessonContainer/hooks/query';
import React from 'react';
import { useIsTeacher } from '@reducers/user/hooks';
import { useMutation, useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { useQGSAccess } from '@apis/questiongenerationservice';
import { LLRScoreResponse, LLRSessionResponse } from './types';

export const useLessonReview = () => {
  const { sectionId, lessonId } = useParams();
  const query = useQuery(
    ['lessonReview', sectionId, lessonId],
    async () => {
      const { response } = await getLearnosityLessonReview(sectionId, lessonId);
      return response.data;
    },
    {
      enabled: !!sectionId && !!lessonId,
      suspense: true,
      staleTime: Infinity,
    },
  );
  return query;
};
export const useNavigateToLessonReviewSetup = () => {
  const { sectionId, lessonId, courseId, subjectId, unitId } = useParams();
  const history = useHistory();
  return () => {
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/assessment/setup`,
    );
  };
};
export const useNavigateToLessonReview = () => {
  const { sectionId, lessonId, courseId, subjectId, unitId } = useParams();
  const history = useHistory();
  return () => {
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/assessment`,
    );
  };
};
export const useNavigateToLessonReviewPractice = () => {
  const { sectionId, lessonId, courseId, subjectId, unitId } = useParams();
  const history = useHistory();
  return () => {
    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${lessonId}/assessment-practice`,
    );
  };
};

export const usePostLearnosityLessonReview = () => {
  const { lessonId: lessonGroupId, sectionId } = useParams();
  const response = useMutation(
    (payload: { references: string[]; name?: string; type: 1 | 2 }) =>
      postLearnosityLessonReview(sectionId, lessonGroupId, payload),
    {
      mutationKey: ['postLearnosityReviewLesson', sectionId, lessonGroupId],
      retry: 2,
      retryDelay: 2 * 1000,
    },
  );
  return response;
};

const breadcrumbList = ['Lesson'];
export const useLessonReviewBreadcrumb = () => {
  const { data: lessonReview } = useLessonReview();
  const subject = useCurrentSubject();
  const unit = useCurrentUnit();
  const listBuilder = React.useMemo(() => {
    const baseList = [...breadcrumbList];
    if (!subject) {
      return baseList;
    }
    baseList.push(subject.display_name);
    if (!unit) {
      return baseList;
    }
    baseList.push(unit.name);
    if (!lessonReview?.name) {
      return baseList;
    }
    baseList.push(lessonReview?.name);
    return baseList;
  }, [lessonReview?.name, subject, unit]);
  return listBuilder;
};

export const useGetLearnosityLessonReviewScoresList = () => {
  const { sectionId, lessonId } = useParams();
  const query = useQuery<Array<LLRScoreResponse>>(
    ['learnosityLessonReviewScoresList', sectionId, lessonId],
    async () => {
      const { response } = await getLearnosityLessonReviewScoresList(sectionId, lessonId);
      return response.data;
    },
    {
      enabled: !!lessonId,
      suspense: true,
      // TODO: Make sure to invalidate the query when student submits the lesson review
      refetchOnMount: 'always',
      refetchOnWindowFocus: false,
    },
  );
  return query;
};

export const useGetLearnosityLessonReviewSessions = () => {
  const isTeacher = useIsTeacher();
  const { lessonId, sectionId } = useParams();
  const query = useQuery<LLRSessionResponse[]>(
    ['learnosityLessonReviewSessions', sectionId, lessonId],
    async () => {
      const { response } = await getLearnosityLessonReviewSessions(sectionId, lessonId);
      return response?.data;
    },
    {
      enabled: !!lessonId && !!sectionId && !isTeacher,
      suspense: true,
      staleTime: 1000 * 60 * 60 * 24,
      refetchOnMount: 'always',
    },
  );
  return query;
};

// TODO: Remove
// This is a temporary solution to show the feature to the author aide
// The feature should be shown only to the author aide and only for the listed subjects
const ALLOWED_SUBJECTS = [
  '165', // Algebra 1 Florida
  '167', // English 9 Florida
  '170', // English 10 Florida
  '158', // Algebra 1 Texas
  '160', // English 1 Texas
  '163', // English 2 Texas
  '173', // Algebra 1 Texas Demo
  '174', // English | Texas Demo
  '175', // English || Texas Demo
  '176', // Algebra 1 Florida Demo
  '177', // English 9 Florida Demo
  '178', // English 10 Florida Demo
];
export const useShouldShowCoAuthorFeature = (userId: string) => {
  const { subjectId } = useParams();
  const hasCoAuthorAccess = useQGSAccess(userId, false);

  return ALLOWED_SUBJECTS.includes(subjectId) && hasCoAuthorAccess;
};
