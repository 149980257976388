import React from 'react';
import Box from '@components/Atoms/Box';
import BreadcumbsFilter from '@containers/BreadcumbsFilter';
import { useCurrentCourse } from '@reducers/courses/hooks';
import LearnosityQuestionSelector from '@components/QuestionSelector/QuestionSelector';
import { useIsDistrictAdmin } from '@reducers/user/hooks';
import { Redirect } from 'react-router-dom';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import useLessonBreadcrumb from '@components/Component/Lesson/Hooks/useLessonBreadcrumb';

const QuestionSelector = () => {
  const isDistrictAdmin = useIsDistrictAdmin();
  const course = useCurrentCourse();
  const lessonBreadcrumb = useLessonBreadcrumb();
  const isAdaptivePracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();

  if (!isDistrictAdmin || !isAdaptivePracticeEnabled) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <BreadcumbsFilter
        haveSub
        breadcumbSubList={[course.name]}
        breadcumbList={[...lessonBreadcrumb, 'Question Selector']}
        mobileTitle={lessonBreadcrumb[lessonBreadcrumb.length - 1]}
        isDisplayFilter={false}
      />
      <Box height="100%">
        <LearnosityQuestionSelector />
      </Box>
    </Box>
  );
};
export default QuestionSelector;
