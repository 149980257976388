import axios from 'axios';
import { handleError } from './utils';

export function getGroupActivities(sectionId, lessonGroupId, lessonId) {
  const url = `/api/sections/${sectionId}/activity/online_group/${lessonGroupId}/${lessonId}/`;
  return axios
    .get(url, { noCache: true })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getInitialGroupings(groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}/initial/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function getGroupData(groupId) {
  const url = `/api/sections/grouping/group/${groupId}/`;
  return axios.get(url);
}
export function getQuestions(groupId) {
  const url = `/api/sections/grouping/${groupId}/review/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function getStudentQuestions(onlineResponseId, userId) {
  const url = `/api/sections/grouping/${onlineResponseId}/${userId}/questions/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function studentGroupReview(questionId, groupId) {
  const url = `/api/sections/grouping/${groupId}/${questionId}/student_review/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function createGroupActivity(payload) {
  const url = '/api/sections/grouping/create';
  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function createOnlineGroupActivity(sectionId, payload) {
  const url = `/api/sections/${sectionId}/activity/online_group/`;
  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateGroupActivity(payload) {
  const url = `/api/sections/grouping/update_grouping/`;
  return axios
    .put(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function updateIndividualGroupActivity(payload) {
  const url = `/api/sections/grouping/update_student/status`;
  return axios
    .put(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function moveStudentToNewActivityGroup(payload) {
  const url = `/api/sections/online-lesson-practice/group-activity/${payload.groupActivityId}/move-student-to-new-group/`;
  return axios.post(url, payload);
}
export function getActivityGroups(groupActivityId) {
  const url = `/api/sections/online-lesson-practice/group-activity/${groupActivityId}/group/`;
  return axios.get(url);
}
export function getActivityAbsentGroup(groupActivityId) {
  const url = `/api/sections/online-lesson-practice/group-activity/${groupActivityId}/group/absent/`;
  return axios.get(url);
}
export function swapActivityStudentGroups(payload) {
  const url = `/api/sections/online-lesson-practice/group-activity/${payload.groupActivityId}/swap-student-group/`;
  return axios.put(url, payload);
}
export function moveActivityStudentToGroup(payload) {
  const url = `/api/sections/online-lesson-practice/group-activity/${payload.groupActivityId}/move-student-to-group/`;
  return axios.put(url, payload);
}
export function moveActivityStudentToAbsentGroup(payload) {
  const url = `/api/sections/online-lesson-practice/group-activity/${payload.groupActivityId}/move-student-to-absent-group/`;
  return axios.put(url, payload);
}
export function rearrangeGroupsSize(payload) {
  const url = `/api/sections/online-lesson-practice/group-activity/${payload.groupActivityId}/rearrange-groups-size/`;
  return axios.post(url, payload);
}
export function startGroupActivity(groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}/1/`;

  return axios
    .put(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function endGroupActivity(groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}/2/`;

  return axios
    .put(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function restartGroupActivity(groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}/0/`;

  return axios
    .put(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function scoreGroupActivity(groupActivityId, numberOfQuestion) {
  const url = `/api/sections/grouping/${groupActivityId}/${numberOfQuestion}/get_score/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function calculateScoreGroupActivity(groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}/calculate_score/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function submitGroupQuestion(payload) {
  const url = '/api/sections/grouping/answer/group/';

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function moveGroupQuestion(payload) {
  const url = '/api/sections/grouping/answer/group/';

  return axios
    .put(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function submitQuestion(payload, userId, onlineResponseId, groupId) {
  const url = `/api/sections/grouping/${userId}/${onlineResponseId}/${groupId}/answer/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function submitQuestionV2({ payload, userId, onlineResponseId, groupId }) {
  const url = `/api/sections/grouping/${userId}/${onlineResponseId}/${groupId}/answer/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function getGroupActivityDetails(groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function submitScoreSummary(payload, groupActivityId) {
  const url = `/api/sections/grouping/${groupActivityId}/complete/`;
  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function resetPracticeForGroup(groupId) {
  const url = `/api/sections/grouping/reset_group_practice/${groupId}/`;
  return axios
    .put(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getGroupActivityInfo(groupActivityId, sectionId) {
  const url = `/api/sections/${sectionId}/online-lesson-practice/group-activity/${groupActivityId}/`;
  return axios.get(url).then((response) => response);
}

export function getInProgressOnlineLessonPractice(sectionId) {
  const url = `/api/sections/grouping/in_progress/${sectionId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export const resetOnlinePracticeActivity = (sectionId, lessonGroupId) => {
  const url = `/api/sections/${sectionId}/activity/reset_activity/${lessonGroupId}/`;

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
};
