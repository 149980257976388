import React, { Suspense } from 'react';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import { ContainerItem } from '@components/Generals/stylesheets/General.style';
// eslint-disable-next-line import/no-named-as-default
import TabProvider from '@components/Shared/TabsDefaultSkin';
import TabViewContent from './TabViewContent';
import TabNavigator from './TabNavigator';
import { LessonTabs } from './Types';
import LessonContainerSkeleton from './LessonContainer.skeleton';

const QUERY_PARAM_KEY = 'tab';
const initialTabIndexHelper = (queryParamsObject: object): string => {
  const initialTabIndex = LessonTabs.Lesson;
  const query = queryParamsObject[QUERY_PARAM_KEY];
  if (!query) {
    return initialTabIndex;
  }
  return query;
};

const LessonContainer = () => {
  const queryParamsObject = useSearchParams();
  const defaultTabKey = initialTabIndexHelper(queryParamsObject);

  return (
    <TabProvider defaultTab={defaultTabKey}>
      <Suspense fallback={<LessonContainerSkeleton />}>
        <ContainerItem>
          <TabNavigator />
          <TabViewContent />
        </ContainerItem>
      </Suspense>
    </TabProvider>
  );
};

export default LessonContainer;
