import React from 'react';
import Box from '../Box';

type Props = {
  from: React.ReactNode;
  to: React.ReactNode;
};

const FromTo = ({ from, to }: Props) => (
  <Box sx={{ display: 'flex', width: '100%', gap: '2rem', alignItems: 'center' }}>
    {from}
    <span className="chalktalk-long-arrow" />
    {to}
  </Box>
);

export default FromTo;
