const ColumnSize = [
  {
    percent: '15', smallSize: true, textCenter: true, borderRight: true,
  },
  {
    smallSize: true, textCenter: true, haveFilter: true, haveResponsive: true,
  },
  {
    size: '120', smallSize: true, textCenter: true, haveFilter: true, borderLeft: true,
  },
  {
    size: '100', smallSize: true, textCenter: true,
  },
];

export default ColumnSize;
