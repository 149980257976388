/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import axios, { AxiosResponse } from 'axios';
import { handleError } from './utils';

export const getSyllabusAlignment = async (
  syllabusGroupId: number | null = null,
  curriculumsubject_ids: number[] = [],
) => {
  let url = `/api/syllabus/${syllabusGroupId}/curriculum-alignment/`;

  const params = new URLSearchParams();

  if (curriculumsubject_ids.length > 0) {
    curriculumsubject_ids.forEach((subjectId) => {
      params.append('curriculumsubject_ids', subjectId.toString());
    });
  }

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  try {
    const response: AxiosResponse = await axios.get(url);
    return response.data;
  } catch (error) {
    handleError(error);
    return undefined;
  }
};
