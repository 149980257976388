import React, { Component } from 'react';
import BackgroundLeft from '../../BackgroundLeft/BackgroundLeft';
import ForgotPasswordForm from '../../../containers/ForgotPage/ForgotPassword/ForgotPasswordForm';
import {
  LoginContainerWrap,
  LoginBlockRight,
} from '../../LoginPage/Login.style';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LoginContainerWrap>
        <BackgroundLeft type="password" />
        <LoginBlockRight>
          <ForgotPasswordForm />
        </LoginBlockRight>
      </LoginContainerWrap>
    );
  }
}
