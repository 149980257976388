import styled from 'styled-components';

export const ContainerMain = styled.div`
  width: 100%;
  height: calc(100% - 4em);
`;

export const ReviewListContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f5f7;
  padding: 2em 2em;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ReviewListContent = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-x: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  min-width: 80em;
  padding: 2em 1em 1.5em 2em;
  margin-top: 1em;
  margin-bottom: 5px;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: 0.3em;
  border: 0;
  box-shadow: 0.5px 0.5px 2px 0px ${props => `${props.theme.colorStyled.ColorXLightGrey}80`};
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 2em;
  color: ${props => props.theme.colorStyled.ColorBgDefault};
  
  > i {
    font-weight: bolder;
  }
`;

export const RequestDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:last-child {
    justify-content: space-between;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 1em;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 55%;

  > button {
    margin-left: 2em;
    min-width: 12em;
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  }
`;

export const ContentRowSubItem = styled.div`
  display: flex;
  margin-top: 0.5em;
`;

export const TitleReviewList = styled.span`
  font-size: ${props => props.theme.fontSize.DefaultFontSize};
  font-family: 'MontserratLight';
`;

export const ForkNumberText = styled.span`
  font-size: ${props => props.theme.fontSize.SmallFontSize};
  margin-right: 2em;
`;

export const TextTeacherName = styled.span`
  font-size: ${props => props.theme.fontSize.HeadingH3FontSize};
  margin-right: 1em;
  width: 100%;
  font-family: MontserratBold;
`;

export const TextDate = styled.span`
  font-size: ${props => props.theme.fontSize.SmallFontSize};
  width: 15%;
  word-break: break-word;
  font-family: 'MontserratLight';
`;

export const TextLessonDetail = styled.span`
  font-size: ${props => props.theme.fontSize.SmallFontSize};
  font-family: 'MontserratLight';
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 20em;
`;

export const CurriculaContainer = styled.div`
  margin: 1em 0;
`;

export const TitleCurriculaList = styled.span`
  font-size: ${props => props.theme.fontSize.LargeFontSize};
  margin-bottom: 2em;
  font-family: 'MontserratLight';
`;

export const CurriculaListContent = styled.div`
  width: 100%;
  display: flex;
  margin: 3em 0;
  flex-wrap: wrap;
`;

export const CurriculumItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16em;
  height: 13em;
  background-color: ${props => props.theme.colorStyled.ColorWhite};
  border-radius: 0.3em;
  border: 0;
  box-shadow: 0.5px 0.5px 2px 0px ${props => `${props.theme.colorStyled.ColorXLightGrey}80`};
  margin-right: 1.5em;
  margin-left: 0.5em;
  padding: 10px;
  margin-bottom: 2em;
`;

export const CurriculumTitle = styled.span`
  text-align: center;
  font-size: 60px;
  font-weight: bolder;
  padding: 0.5em;
  font-family: 'MontserratBold';
`;

export const CurriculumLabel = styled.span`
  text-align: center;
  font-size: ${props => props.theme.fontSize.PrimaryFontSize};
  font-weight: 100;
  margin-top: 0.5em;
  font-family: 'MontserratLight';
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  aligin-items: center;
  justify-content: center;
  width: 100%;
  min-width: 50em;
  margin-top: 1em;
  > button {
    margin-right: 0.5em;
  }
`;

export const LoginButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;
  margin-top: 2em;
`;

export const LabelText = styled.p`
  padding: 1em;
  font-family: 'MontserratLight';
`;
