export default function firstSort(arr, key) {
  if (arr[0] && arr[0].last_name) {
    arr.sort((a, b) => {
      const nameA = a?.last_name?.toUpperCase(); // ignore upper and lowercase
      const nameB = b?.last_name?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  } else if (arr[0]) {
    arr.sort((a, b) => {
      const nameA = a?.[key]?.toUpperCase(); // ignore upper and lowercase
      const nameB = b?.[key]?.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }
}
