/* eslint-disable import/prefer-default-export */
import { useMutation } from 'react-query';
import {
  submitGroupQuestion,
  submitQuestionV2,
  submitScoreSummary,
  updateIndividualGroupActivity,
  moveGroupQuestion,
} from '@apis/groupActivity';
import { olpSubmitScoreType } from '@components/PracticeV2/types';

export const usePostGroupAnswer = () => {
  const mutationObject = useMutation(submitGroupQuestion, {
    mutationKey: 'postGroupAnswer',
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return mutationObject;
};

export const useMoveGroupAnswer = () => {
  const mutationObject = useMutation(moveGroupQuestion, {
    mutationKey: 'moveGroupAnswer',
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return mutationObject;
};

export const usePostIndividualAnswer = () => {
  const mutationObject = useMutation(submitQuestionV2, {
    mutationKey: 'postIndividualAnswer',
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return mutationObject;
};

export const useUpdateGroupStatus = () => {
  const response = useMutation(updateIndividualGroupActivity, {
    mutationKey: 'updateIndividualGroupActivity',
    retry: 3,
    retryDelay: 5 * 1000,
  });
  return response;
};

export const useOLPSubmit = ({ groupId, onSuccessfulSubmit }) => {
  const response = useMutation(
    (payload: olpSubmitScoreType) => submitScoreSummary(payload, groupId),
    {
      mutationKey: ['submitOLPComplete', groupId],
      onSuccess: () => {
        onSuccessfulSubmit();
      },
      retry: 3,
      retryDelay: 5 * 1000,
    },
  );
  return response;
};
