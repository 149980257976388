import React from 'react';
import Button from '@components/Atoms/Button';
import { useNavigateToPreview } from '@components/StandardsPractice/hooks';

const LearnosityAdaptivePracticePreviewButton = () => {
  const goToAdaptivePracticePreview = useNavigateToPreview();

  return (
    <Button
      variant="contained"
      dataTestId="preview-adaptive-practice-button"
      onClick={goToAdaptivePracticePreview}
    >
      Preview
    </Button>
  );
};

export default LearnosityAdaptivePracticePreviewButton;
