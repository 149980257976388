const ColumnSize = [
  {
    smallSize: true, textCenter: true, border: true,
  },
  {
    smallSize: true, textCenter: true, haveFilter: true,
  },
  {
    smallSize: true, textCenter: true, haveFilter: true,
  },
  {
    smallSize: true, textCenter: true, haveFilter: true,
  },
  {
    size: '80', smallSize: true, textCenter: true, border: true,
  },
];

export default ColumnSize;
