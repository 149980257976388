import styled from 'styled-components';

export const GroupDiscussionWrapper = styled.div`
  width: 100%;
`;

export const GroupDiscussionItem = styled.div``;

export const GroupDiscussionTitle = styled.div`
display: flex;
align-items: center;
font-size: 1.8em;
font-weight: bold;
margin-bottom: 0.8em;
margin-top: 1.2em;
line-height: 25px;
flex-direction: column;
@media (max-width: 768px) {
  font-size: 1.5em;
}
`;

export const GroupDiscussionContent = styled.div`
  width: 95%;
  height: 100%;
  padding: 5px;
  margin-bottom: 20px;
  & > p {
    color: #666666;
    font-family: 'Montserrat',Arial;
    font-size: 9.0pt;
    font-style: normal;
    font-weight: 200;
    margin-bottom: 10px
    text-align: left;
    line-height: 20px;
  }
  span {
    font-style: italic;
  }
`;
