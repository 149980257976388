/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
import { useEffect, useCallback, useState } from 'react';
import {
  getQGSSupportedQuestionTypes,
  postQGSQuestionRequest,
  submitQuestionsToItemBank,
  postUserReportIssue,
} from '@apis/questiongenerationservice';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getSectionSubjectList } from '@apis/sections';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import QuestionGenerationServiceEventEmitter, {
  QuestionGenerationServiceEvents,
} from './utils/QuestionGenerationServiceEmitter';
import {
  GeneratedQuestion,
  LearnosityItem,
  Metadata,
  QuestionGenerationServiceMenu,
  WidgetDefinition,
  SupportedQuestionTypes,
  GenerationStatusItem,
} from './types';
import { BLOOMS, GENERATION_STATUS, SUBJECTS } from './constants';
import { Passage } from '@components/QuestionGenerationPassages/types';

export const useGeneratedItem = (
  taskId: string | null,
  setGenerating: (value: boolean) => void,
  subject,
  blooms,
  passage,
) => {
  const [items, setItems] = useState<LearnosityItem[]>([]);

  const suffixQuestionReference = (reference: string) => {
    // check if reference has _response, if not add it
    if (reference.includes('_response')) {
      return reference;
    }
    return `${reference}_response`;
  };

  const buildPassageFeature = (passageJson: Passage) => [
    {
      reference: passageJson?.reference || uuidv4(),
      widget_type: 'feature',
      type: 'sharedpassage',
      data: {
        content: passageJson?.data?.content || '',
        type: 'sharedpassage',
      },
    },
  ];

  const buildWidgetDefinitions = (questionReference: string, passageFeature: array) => {
    const widgetDefintions: WidgetDefinition[] = [];

    if (passageFeature.length) {
      widgetDefintions.push({
        reference: passageFeature[0].reference,
        widget_type: 'feature',
      });
    }

    widgetDefintions.push({
      reference: questionReference,
      widget_type: 'response',
    });

    return widgetDefintions;
  };

  const buildMetaData = useCallback(
    (metadata: Metadata | undefined, currentSubject: unknown) => {
      const difficulty = metadata?.difficulty ? metadata?.difficulty : blooms.toString();

      const gradeLevel = metadata?.grade_level || '9';

      let meta_subject = metadata?.subject;
      if (!meta_subject) {
        if (currentSubject?.display_name?.includes('English')) {
          meta_subject = 'English';
        } else {
          meta_subject = 'Math';
        }
      }

      return {
        ...metadata,
        difficulty,
        grade_level: gradeLevel,
        subject: meta_subject,
      };
    },
    [blooms],
  );

  const addItem = useCallback(
    (generatedItem: GeneratedQuestion | LearnosityItem) => {
      if (!generatedItem.is_custom && generatedItem.task_id !== taskId) {
        return;
      }

      // turn off generating as soon as we have 1 non-custom question
      if (!generatedItem.is_custom) {
        setGenerating(false);
      }

      // if the item is already a LearnosityItem, just add it to the list
      if ((generatedItem as LearnosityItem).reference !== undefined) {
        setItems((prev) => [...prev, generatedItem as LearnosityItem]);
        return;
      }

      const generatedQuestion = generatedItem as GeneratedQuestion;

      // TODO: update CoAuthor service to start using learnosity keys 'reference' instead of response_id
      const itemReference = generatedQuestion.response_id;

      // Generated reference is for the item, lets suffix _response to diff it with
      // the question reference. This seems to be the convention in Learnosity.
      const questionReference = suffixQuestionReference(itemReference);

      // create a passage feature if there is a passage
      const passageFeature = passage ? buildPassageFeature(passage) : [];

      const newItem: LearnosityItem = {
        reference: itemReference,
        item: {
          reference: itemReference,
          definition: {
            template: 'dynamic',
            widgets: buildWidgetDefinitions(questionReference, passageFeature),
          },
        },
        questions: [
          {
            reference: questionReference,
            widget_type: 'response',
            type: generatedQuestion?.type,
            data: {
              reference: questionReference,
              is_math: generatedQuestion?.is_math || false,
              metadata: generatedQuestion?.metadata,
            },
          },
        ],
        features: passageFeature,
        is_custom: !!generatedQuestion?.is_custom,
        is_deleted: false,
        is_edited: !!generatedQuestion?.is_edited,
        user_id: generatedQuestion.user_id,
        metadata: buildMetaData(generatedQuestion?.metadata, subject),
        feedback: {
          rating: 0,
          text: '',
        },
      };

      setItems((prev) => [...prev, newItem]);
    },
    [buildMetaData, passage, setGenerating, subject, taskId],
  );

  const removeItem = useCallback((referenceId: string) => {
    setItems((prev) => prev.filter((item) => item.reference !== referenceId));
  }, []);

  const clearItems = useCallback(() => {
    if (!items.length) {
      return;
    }
    setItems([]);
    setGenerating(false);
  }, [items.length, setGenerating]);

  const updateItem = useCallback((item2Update) => {
    // updatedItem is expected to only have some properties of the item
    // to avoid overwriting the entire question object since the state does not update at lower levels
    if (!item2Update.reference) {
      // eslint-disable-next-line no-console
      console.error('No reference found in updated item', item2Update);
      return;
    }

    const updatedItem = { ...item2Update };

    setItems((prev) =>
      prev.map((item: LearnosityItem) => {
        if (item.reference === updatedItem.reference) {
          return {
            ...item,
            ...updatedItem,
          };
        }
        return item;
      }),
    );
  }, []);

  useEffect(() => {
    const handleEvent = (event: unknown) => {
      const { status, payload, task_id } = event as GenerationStatusItem;
      if (status === GENERATION_STATUS.GENERATED.status) {
        addItem({
          ...(payload as LearnosityItem),
          task_id,
        });
      }
    };

    QuestionGenerationServiceEventEmitter.on(
      QuestionGenerationServiceEvents.ADD_QGS_QUESTION,
      handleEvent,
    );

    return () => {
      QuestionGenerationServiceEventEmitter.off(
        QuestionGenerationServiceEvents.ADD_QGS_QUESTION,
        handleEvent,
      );
    };
  }, [addItem]);

  return {
    items,
    addItem,
    removeItem,
    clearItems,
    updateItem,
  };
};

export const useGenerationStatusItems = (taskId: string | null) => {
  const [generationStatuses, setGeneratingStatuses] = useState<GenerationStatusItem[]>(
    [],
  );

  const upsertStatus = useCallback(
    (newStatus: GenerationStatusItem) => {
      setGeneratingStatuses((prev) => {
        if (newStatus.task_id !== taskId) {
          return prev;
        }
        const index = prev.findIndex(
          (status) => status.question_id === newStatus.question_id,
        );

        if (index === -1) {
          return [...prev, newStatus];
        }

        return prev.map((status) =>
          status.question_id === newStatus.question_id ? newStatus : status,
        );
      });
    },
    [taskId],
  );

  const removeStatus = useCallback((questionId: number) => {
    setGeneratingStatuses((prev) =>
      prev.filter((item) => item.question_id !== questionId),
    );
  }, []);

  useEffect(() => {
    const handleEvent = (event: unknown) => {
      const { status, question_id } = event as GenerationStatusItem;

      if (status === GENERATION_STATUS.GENERATED.status) {
        removeStatus(question_id);
      } else {
        upsertStatus(event as GenerationStatusItem);
      }
    };

    QuestionGenerationServiceEventEmitter.on(
      QuestionGenerationServiceEvents.ADD_QGS_QUESTION,
      handleEvent,
    );

    return () => {
      QuestionGenerationServiceEventEmitter.off(
        QuestionGenerationServiceEvents.ADD_QGS_QUESTION,
        handleEvent,
      );
    };
  }, [removeStatus, taskId, upsertStatus]);

  const clearStatuses = useCallback(() => {
    setGeneratingStatuses([]);
  }, []);

  return {
    generationStatuses,
    clearStatuses,
  };
};

export const usePostQGSQuestionRequest = () => {
  const response = useMutation((payload: any) => postQGSQuestionRequest(payload), {
    mutationKey: ['postAuthorAideQuestionRequest'],
  });
  return response;
};

export const useSubmitQuestionsToItemBank = () => {
  const response = useMutation((payload: any) => submitQuestionsToItemBank(payload), {
    mutationKey: ['submitQuestionsToItemBank'],
  });
  return response;
};

export const useGetSectionSubjectList = () => {
  const { sectionId } = useParams();

  const res = useQuery(
    ['getSectionSubjectList', sectionId],
    async () => {
      if (!sectionId) {
        return SUBJECTS;
      }
      const response = (await getSectionSubjectList(sectionId)) as unknown as {
        response: {
          data;
        };
      };
      return response?.response?.data || ([] as any);
    },
    {
      staleTime: 1000 * 60 * 60,
      suspense: true,
    },
  );
  return res;
};

export const usePostUserReportIssue = () =>
  useMutation(
    (payload: { trace_id: string; content_type: string; content: string }) =>
      postUserReportIssue(payload),
    {
      mutationKey: ['postUserReportIssue'],
      onError: (error: any) => {
        const errorMessage = error?.error || 'Failed to submit report. Please try again.';
        toast.error(errorMessage);
      },
    },
  );

export const useGetQGSSupportedQuestionTypes = () =>
  useMutation<SupportedQuestionTypes, Error>(
    async () => {
      const response = await getQGSSupportedQuestionTypes();
      return response.data;
    },
    {
      mutationKey: ['getQGSSupportedQuestionTypes'],
      retry: 3,
      retryDelay: 5 * 1000,
      onError: (error: any) => {
        const errorMessage =
          error?.error || 'Failed to get supported question types. Please try again.';
        toast.error(errorMessage);
      },
    },
  );
