/* eslint-disable arrow-body-style */
import {
  ExamSectionObject,
  IExamResults,
  IExamScoringSubject,
  ScoreObject,
  SubScoreArray,
} from '../ExamScoreCard/types';
import { useGetExamDetail } from './query';

interface IGroupedExamSections {
  [id: string]: Array<ExamSectionObject>;
}

interface IGroupedExamSectionScoreObject {
  [assessmentNodeId: number]: Array<ScoreObject>;
}

export const HasUserTookAllTheRelatedExamSectionsForTheAssessmentNode = (
  userResult: IExamResults | undefined,
  subjectNodeExamSectionIds: Array<number>,
): boolean =>
  userResult?.section_report?.filter((sectionReport) =>
    subjectNodeExamSectionIds?.includes(sectionReport.section_id),
  ).length === subjectNodeExamSectionIds.length;

/** group each exam section by its related assessment Node Id
 *
 * @return ** {[English Assessment Node Id]:[English Sitting 1 object , English Sitting 2 object ]}
 */
export const groupExamSectionsByAssessmentNode = (
  examSectionsList: Array<ExamSectionObject> | undefined,
) => {
  if (!examSectionsList?.length) {
    return {};
  }
  const groupedExamSections: IGroupedExamSections = examSectionsList?.reduce(
    (acc, examSection) => {
      if (!examSection.examscoringdata_main_assessmentnode_id) {
        return acc;
      }
      if (!acc[examSection.examscoringdata_main_assessmentnode_id]) {
        acc[examSection.examscoringdata_main_assessmentnode_id] = [examSection];
        return acc;
      }
      acc[examSection.examscoringdata_main_assessmentnode_id].push(examSection);
      return acc;
    },
    {},
  );

  return groupedExamSections || {};
};

/** filter each exam sub score section to get completed exam sub score
 * by checking user's section reports.
 *
 * @return ** {[English Assessment Node Id]:[user 1 english subScore object , user 2 english subScore object ]}
 */
export const filterCompletedExamSectionsSubScore = (
  formattedExamSectionsObject: IGroupedExamSections | undefined,
  usersResults: Array<IExamResults> | undefined,
): IGroupedExamSectionScoreObject | undefined => {
  if (!formattedExamSectionsObject) {
    return;
  }
  if (!Object.keys(formattedExamSectionsObject).length) {
    return;
  }

  return Object.entries(formattedExamSectionsObject)?.reduce(
    (acc, [examScoringDataNodeId, examSections]) => {
      const examSectionIds: Array<number> = examSections?.map(
        (examSection) => examSection.id,
      );

      usersResults?.forEach((userResult) => {
        if (
          !HasUserTookAllTheRelatedExamSectionsForTheAssessmentNode(
            userResult,
            examSectionIds,
          )
        ) {
          return;
        }
        Object.values(userResult?.score?.subscores || {})?.forEach((subScore) => {
          if (!subScore) {
            return;
          }

          if (subScore.id !== Number(examScoringDataNodeId)) {
            return;
          }

          if (!acc[subScore.id]) {
            acc[subScore.id] = [subScore];
          } else {
            acc[subScore.id].push(subScore);
          }
        });
      });
      return acc;
    },
    {},
  );
};

/** calculate average subscore for each exam scoring assessment node.
 * @param ** {[English Assessment Node Id]:[user 1 english subScore object , user 2 english subScore object ]}
 * @return ** [ English Average subscore object for all users' English subScore object]
 */
export const calculateAverageSubScoreForEachExamScoringAssessmentNode = (
  filteredScoreObject: IGroupedExamSectionScoreObject | undefined,
): Array<IExamScoringSubject> => {
  if (!filteredScoreObject) {
    return [];
  }

  const averagedSubScoreList = Object.values(filteredScoreObject).map(
    (scoreObjectsList) => {
      if (!scoreObjectsList.length) {
        return {};
      }
      if (scoreObjectsList.length === 1) {
        return scoreObjectsList[0];
      }

      const averageScore =
        scoreObjectsList.reduce(
          (acc, scoreObject) => acc + scoreObject.score.scaled_score,
          0,
        ) / scoreObjectsList.length;
      const finalResult = structuredClone(scoreObjectsList[0]);
      finalResult.score.scaled_score = averageScore;
      return finalResult;
    },
  );

  return averagedSubScoreList;
};

export const getAverageOverAllScore = (
  usersResults: Array<IExamResults> | undefined,
  examSectionsCount: number,
): number | null => {
  const usersTookAllTheExamSections = usersResults?.filter(
    (userResult) => userResult?.section_report?.length === examSectionsCount,
  );
  if (!usersTookAllTheExamSections?.length) {
    return null;
  }
  const overAllScaledScore =
    usersTookAllTheExamSections.reduce(
      (acc, user) => acc + (user?.score?.overall_scaled_score?.score ?? 0),
      0,
    ) / usersTookAllTheExamSections.length;

  return overAllScaledScore;
};

export const mapExamSubScoreWithAverageSubScoreScaledScore = (
  subScoresList: SubScoreArray | undefined,
  averageSubScoreList: Array<IExamScoringSubject> | any,
) => {
  if (!subScoresList || !averageSubScoreList) {
    return [];
  }
  const averageSubScoreListIds = averageSubScoreList.map((score) => score?.id);

  const updatedScores = subScoresList.map((scoreObject) => {
    if (averageSubScoreListIds.includes(scoreObject.id)) {
      const subScoreObject = averageSubScoreList?.find(
        (el) => el?.id === scoreObject.id,
      )?.score;

      return { ...scoreObject, score: subScoreObject };
    }
    // eslint-disable-next-line no-param-reassign
    scoreObject.score.scaled_score = null;
    return scoreObject;
  });
  return updatedScores;
};

export const filterScheduledExamSectionSubScores = (examDetails, subscores) => {
  const allTestExamSectionAssessmentNodeIds = examDetails?.exam.sections.map(
    (examSection) => examSection.examscoringdata_main_assessmentnode_id,
  );
  return subscores?.filter((subScoreObject) =>
    allTestExamSectionAssessmentNodeIds?.includes(subScoreObject?.id),
  );
};

export const useHasSectionAllCourseExams = () => {
  const { data: examDetails } = useGetExamDetail();
  return (
    examDetails?.exam?.originalSections?.length === examDetails?.exam?.sections?.length
  );
};
