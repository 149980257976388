import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';
import _isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import ShowIf from '@components/Atoms/ShowIf';
import { useIsLearnosityLessonReviewEnabled } from '@reducers/curriculums/hooks';
import CourseSelect from '../../containers/CourseSelect';
import SectionSelect from '../../containers/SectionSelect';
import ScheduleAddLessonSession from '../../containers/ScheduleAddLessonSession';
import ScheduleAddReviewSession from '../../containers/ScheduleAddReviewSession';
import ScheduleAddExamSession from '../../containers/ScheduleAddExamSession';
import SectionInfo from '../../containers/SectionInformation';
import * as SectionAPI from '../../apis/sections';
import FormInput from '../Generals/FormInput/FormInput';
import {
  LeftSiderBarContentGroup,
  LeftSideBarList,
  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
  MultiItemsSiderBar,
  SideBarContent,
  UnitDataRow,
  UnitDataIcon,
  UnitDataText,
  UnitDataContener,
  LessonDataContainerWrap,
  Button,
  SelectStyles,
  SubjectProgress,
  FlexItems,
  scrollStyle,
  Details,
  LightTextParagraph,
  SelectWrap,
  List,
  DetailsWrapper,
  AccordionSummaryStyles,
  AccordionStyles,
  LessonBlocks,
} from './LeftSiderBar.style';
import { EditModalTitle } from '../SubNavBarItem/SubNavBar.style';
import { Modalbutton, ButtonsWrap } from '../ScheduleCalendar/Calendar.style';
import { ModalHeader } from '../Generals/stylesheets/General.style';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import EditLessonModal from '../Modals/EditLessonModal';

const MuiAccordionSummary = withStyles(AccordionSummaryStyles)(AccordionSummary);
const MuiAccordion = withStyles(AccordionStyles)(Accordion);

const editModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px 0 20px 0',
    maxWidth: '980px',
    width: '520px',
    maxHeight: 'calc(100vh - 30px)',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    padding: '30px',
    overflow: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const minimumeModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0px 0 20px 0',
    minWidth: '700px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    padding: '30px',
    overflow: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};
const convertDate = (inputFormat) => {
  function pad(s) {
    return s < 10 ? `0${s}` : s;
  }
  const d = new Date(inputFormat);
  return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
};
const RenderFilterComponent = ({ onClick, text, src }) => (
  <UnitDataRow onClick={onClick}>
    <UnitDataIcon>
      <img
        src={`/images/icon-svg/${src ? 'calendar-colored.svg' : 'calendar.svg'}`}
        alt="calendar"
      />
    </UnitDataIcon>
    <LightTextParagraph>{text}</LightTextParagraph>
  </UnitDataRow>
);
// eslint-disable-next-line arrow-body-style
const LessonAccordionBody = ({
  subject,
  scheduled,
  subjectLessons,
  subjectUnits,
  dataComponent,
  subjectIndex,
  handleAccordion,
  expanded,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <MuiAccordion expanded={expanded} onChange={() => handleAccordion(subject.id)}>
      <MuiAccordionSummary>
        <FlexItems>
          <Typography
            sx={{
              flexShrink: 0,
              fontSize: 'inherit',
              fontWeight: '600',
              width: '100%',
            }}
          >
            {subject.display_name}
          </Typography>
          <img
            src={`/images/icon-svg/${
              expanded ? 'arrow-down-orange.svg' : 'arrow-right.svg'
            }`}
            alt="arrow"
          />
        </FlexItems>
        <SubjectProgress subject rate={(scheduled * 100) / subjectLessons}>
          <p>Lessons Scheduled</p>
          <p>{`${scheduled} of ${subjectLessons}`}</p>
        </SubjectProgress>
      </MuiAccordionSummary>
      <DetailsWrapper>
        <Details>
          <AccordionDetails sx={{ padding: '0 9px' }}>
            <Typography component="div">
              {subjectUnits.length > 0 ? (
                subjectUnits.map((data, index) => (
                  <div key={`lesson-data-${index}`}>
                    {dataComponent(data, index + 1, subjectIndex)}
                  </div>
                ))
              ) : (
                <LightTextParagraph>Loading...</LightTextParagraph>
              )}
            </Typography>
          </AccordionDetails>
        </Details>
      </DetailsWrapper>
    </MuiAccordion>
  );
};
const LessonAccordionBodyMemo = React.memo(
  LessonAccordionBody,
  (prevProps, nextProps) =>
    prevProps.expanded === nextProps.expanded &&
    prevProps.subjectUnits.length === nextProps.subjectUnits.length,
);
export class LeftSideBarScheduleModule extends Component {
  static propTypes = {
    match: PropTypes.any,
    pathName: PropTypes.string,
    shouldDisplaySectionSelect: PropTypes.bool,
    userRole: PropTypes.string,
    toggleLeftSidebar: PropTypes.func,
    isSideBarClose: PropTypes.bool,
    history: PropTypes.any,
    courseId: PropTypes.any,
    sectionId: PropTypes.any,
    isOpenLessonSession: PropTypes.bool,
    isOpenReviewSession: PropTypes.bool,
    isOpenExamSession: PropTypes.bool,
    isOpenSectionInfo: PropTypes.bool,
    isSectionInfo: PropTypes.bool,
    isAddLessonSessionForm: PropTypes.bool,
    isAddSectionReviewSuccess: PropTypes.bool,
    isAddSectionExamSuccess: PropTypes.bool,
    isLimited: PropTypes.bool,

    onSelectSubject: PropTypes.func,
    selectedSubject: PropTypes.number,
    updateScheduleList: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubjectDropdown: true,
      unitView: false,
      sectionUnits: [],
      textSearch: '',
      isEditModal: false,
      unitSelected: null,
      reviewModal: false,
      editReviewModal: false,
      editReviewDate: null,
      unitEdit: [],
      preventRemoveModal: false,
      actionSelected: { id: 1, name: 'Lessons' },
      expandedSubjects: [],
    };
  }

  componentDidMount() {
    this.handleSectionAPI();
  }

  componentDidUpdate(prevProps) {
    const {
      isAddLessonSessionForm: prevIsAddLessonSessionForm,
      isAddSectionReviewSuccess: prevIsAddSectionReviewSuccess,
      isAddSectionExamSuccess: prevIsAddSectionExamSuccess,
      isSectionInfo: prevIsSectionInfo,
      sectionId: prevSectionId,
      sectionDetail: prevSectionDetail,
    } = prevProps;
    const {
      isAddLessonSessionForm,
      isAddSectionReviewSuccess,
      isAddSectionExamSuccess,
      isSectionInfo,
      sectionId,
      sectionDetail,
    } = this.props;

    if (
      !_isEqual(sectionId, prevSectionId) ||
      sectionDetail?.subjects?.length !== prevSectionDetail?.subjects?.length
    ) {
      this.setState({ expandedSubjects: [] });
      this.handleSectionAPI();
    }
    if (prevIsAddLessonSessionForm !== isAddLessonSessionForm && isAddLessonSessionForm) {
      this.closeModal();
    }
    if (
      prevIsAddSectionReviewSuccess !== isAddSectionReviewSuccess &&
      isAddSectionReviewSuccess
    ) {
      this.closeModal();
    }
    if (
      prevIsAddSectionExamSuccess !== isAddSectionExamSuccess &&
      isAddSectionExamSuccess
    ) {
      this.closeModal();
    }
    if (prevIsSectionInfo !== isSectionInfo && isSectionInfo) {
      this.closeModal();
    }
  }

  async handleSectionAPI() {
    const { sectionId } = this.props;
    if (sectionId) {
      const response = await SectionAPI.getSectionUnit(sectionId);
      this.setState({
        sectionUnits: response?.response?.data || [],
      });
    }
  }

  toggleLeftSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  };

  closeModal = () => {
    this.setState({
      isEditModal: false,
    });
    const { courseId, sectionId, history } = this.props;
    const url = `/schedule/course/${courseId}/section/${sectionId}`;
    history.push(url);
  };

  containsAny = (source, target) => {
    const result = source.filter((item) => target.indexOf(item) > -1);
    return result.length;
  };

  renderUnitsSelected = (subjectId) => {
    const { sectionUnits } = this.state;
    const tmpUnitsData = [];
    const Data = sectionUnits.find((unit) => unit.id === subjectId) || {};
    const unitsData = Data.units || [];

    unitsData.forEach((ele) => {
      if (ele.children.length > 0) {
        ele.children.forEach((child) => {
          if (child.children.length > 0) {
            child.children.forEach((child2) => {
              child2.subject = subjectId;
              tmpUnitsData.push(child2);
            });
          } else {
            child.subject = subjectId;
            tmpUnitsData.push(child);
          }
        });
      }
      ele.subject = subjectId;
      tmpUnitsData.push(ele);
    });
    const units = [];
    tmpUnitsData.forEach((ele) => {
      if (ele.lessons.length != 0) {
        units.push(ele);
      }
    });
    return units;
  };

  handleSelectUnit = (unit, subject, unitId) => {
    const { editMode, sectionDetail, match, isTeacher } = this.props;

    if (!isTeacher) {
      return;
    }
    const subjectFiltered = sectionDetail.subjects.find((ele) => ele.id == subject);
    const sectionId = _get(match, 'params.sectionId', '');

    unit.lessonName = unit.name;
    unit.activity_type = 0;
    unit.lesson = unit.id;
    unit.unit = unitId;
    unit.type = 'lessons';
    unit.title = '';
    unit.subjectName = subjectFiltered.display_name;
    unit.subject = subject;
    unit.section = sectionId;
    unit.lessonStatusIndicator = unit.indicator;

    this.setState({
      unitSelected: unit,
      isEditModal: editMode,
    });
  };

  renderReviewsSelected = () => {
    const { scheduleList } = this.props;

    const result = scheduleList.filter((review) => review.type == 'reviews');

    return result;
  };

  addNewReviewModal = () => {
    const { reviewModal } = this.state;
    this.setState({
      reviewModal: !reviewModal,
    });
  };

  editReview = (unit) => {
    const { editReviewModal } = this.state;
    this.setState({
      editReviewModal: !editReviewModal,
      unitEdit: unit,
      editReviewDate: null,
    });
  };

  handelReviewEditChange = (e) => {
    this.setState({
      editReviewDate: convertDate(new Date(e.getTime())),
    });
  };

  saveEdits = async (action) => {
    const { unitEdit, editReviewDate } = this.state;
    const { updateScheduleList } = this.props;
    unitEdit.date = editReviewDate;
    updateScheduleList(unitEdit, action);
    if (unitEdit?.id) {
      if (action === 'delete') {
        await SectionAPI.deleteReviewSessionData(unitEdit.id);
      }
      if (action === 'edit') {
        await SectionAPI.updateReviewSessionData(unitEdit.id, unitEdit);
      }
    }
    this.setState({
      editReviewModal: false,
      editReviewDate: null,
    });
  };

  removeReview = async () => {
    const { unitEdit, editReviewDate, preventRemoveModal } = this.state;
    const allReview = this.renderReviewsSelected();
    if (allReview.length !== 1) {
      unitEdit.date = editReviewDate;
      this.saveEdits('delete');
    } else {
      this.setState({
        preventRemoveModal: !preventRemoveModal,
        editReviewModal: false,
      });
    }
  };

  renderReviewView = () => {
    const { unitEdit, editReviewDate, reviewModal, editReviewModal, preventRemoveModal } =
      this.state;
    const reviewsData = this.renderReviewsSelected();

    let initialValues = {};
    let initDate = '';
    if (unitEdit) {
      if (editReviewDate) {
        initDate = editReviewDate;
      } else {
        initDate = unitEdit.date;
      }
      const reviewDate = new Date(initDate);
      initialValues = {
        date: reviewDate,
        subject: '',
        activityType: '',
      };
    }
    const { updateScheduleList } = this.props;
    return (
      <div>
        <UnitDataContener>
          <UnitDataRow onClick={() => this.addNewReviewModal()}>
            <UnitDataIcon addReview>
              <i className="chalktalk-plus" />
            </UnitDataIcon>
            <UnitDataText>Add Review Class</UnitDataText>
          </UnitDataRow>
          {reviewsData.map((review, index) => (
            <UnitDataRow
              key={`unit-data-row-${index}`}
              onClick={() => this.editReview(review)}
            >
              <UnitDataIcon>
                <img src="/images/icon-svg/calendar-colored.svg" alt="calendar" />
              </UnitDataIcon>
              <LightTextParagraph>{review.title}</LightTextParagraph>
            </UnitDataRow>
          ))}
        </UnitDataContener>
        <ScheduleAddReviewSession
          updateScheduleList={updateScheduleList}
          isOpen={reviewModal}
          style={editModalStyles}
          onClose={() => this.addNewReviewModal()}
        />

        <Modal
          isOpen={editReviewModal}
          style={editModalStyles}
          ariaHideApp={false}
          onRequestClose={() => this.editReview()}
        >
          <ModalHeader editReview>Edit Review Class</ModalHeader>
          <Formik initialValues={initialValues} onSubmit={this.onSubmitForm}>
            <Form>
              <FormInput
                type="date"
                name="date"
                value={initDate}
                minDate={new Date()}
                dateFormat="yyyy-MM-dd"
                placeholder="Review Class Date"
                onChange={this.handelReviewEditChange}
              />
              <ButtonsWrap>
                <Modalbutton onClick={() => this.editReview()}>Cancel</Modalbutton>
                <Modalbutton
                  pullRight
                  type="submit"
                  isSaveAndExit
                  onClick={() => this.saveEdits('edit')}
                >
                  Submit
                </Modalbutton>
                <Modalbutton
                  pullRight
                  autoWidth
                  type="button"
                  onClick={() => this.removeReview()}
                >
                  Remove Review Class
                </Modalbutton>
              </ButtonsWrap>
            </Form>
          </Formik>
        </Modal>
        <Modal
          isOpen={preventRemoveModal}
          style={minimumeModalStyles}
          ariaHideApp={false}
          onRequestClose={() => this.onClose()}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <EditModalTitle minPopup center>
              A minimum of 1 review session is required
            </EditModalTitle>
            <h3
              style={{
                margin: '20px 0',
              }}
            >
              You can't remove this review class as it is the only class you have
            </h3>
            <Modalbutton isSaveAndExit onClick={() => this.removeReview()} type="submit">
              Okay
            </Modalbutton>
          </div>
        </Modal>
      </div>
    );
  };

  handleAccordion = (subjectId) => {
    const { expandedSubjects } = this.state;
    if (expandedSubjects.includes(subjectId)) {
      const subjects = expandedSubjects.filter((subject) => subject != subjectId);
      this.setState({
        expandedSubjects: subjects,
      });
    } else {
      this.setState({
        expandedSubjects: [...expandedSubjects, subjectId],
      });
    }
  };

  renderSubjectsView = () => {
    const { sectionDetail, completedLessons } = this.props;
    const { expandedSubjects } = this.state;
    let sectionSubjects = [];
    if (!_isEmpty(sectionDetail)) {
      sectionSubjects = sectionDetail.subjects.sort((a, b) => a.order - b.order);
    }
    const unitsCompleted = completedLessons;
    const { textSearch } = this.state;
    const isSearching = textSearch.length !== 0;
    const sectionScheduledSubjects = sectionDetail.section_scheduled_subjects;

    const containsAnyLesson = (data) =>
      this.containsAny(
        unitsCompleted,
        data.lessons.map((l) => l.id),
      );

    const lessonFilter = (data) =>
      data.lessons
        .filter((obj) =>
          !isSearching ? true : obj.name.toLowerCase().includes(textSearch),
        )
        .map((lesson, index) => (
          <RenderFilterComponent
            key={`render-filter-component-${index}`}
            onClick={() => this.handleSelectUnit(lesson, data.subject, data.id, data)}
            src={completedLessons.includes(lesson.id)}
            text={lesson.name}
          />
        ));
    const toggleLessons = (index, subjectIndex) => {
      const accordionItemHeader = document.getElementById(
        `accordion-item-body-header${index}${subjectIndex}`,
      );
      const accordionItemBody = document.getElementById(
        `accordion-item-body-content${index}${subjectIndex}`,
      );
      accordionItemHeader.classList.toggle('active');
      if (accordionItemHeader.classList.contains('active')) {
        accordionItemBody.style.maxHeight = `${accordionItemBody.scrollHeight}px`;
      } else {
        accordionItemBody.style.maxHeight = 0;
      }
    };
    const dataComponent = (data, index, subjectIndex) => (
      <div>
        <SubjectProgress
          id={`accordion-item-body-header${index}${subjectIndex}`}
          onClick={() => toggleLessons(index, subjectIndex)}
          unit
          rate={(containsAnyLesson(data) * 100) / data.lessons.length}
        >
          {data.name}
          <p>{`${containsAnyLesson(data)} of ${data.lessons.length}`}</p>
        </SubjectProgress>
        <LessonBlocks id={`accordion-item-body-content${index}${subjectIndex}`}>
          {lessonFilter(data, index)}
        </LessonBlocks>
      </div>
    );

    return (
      <LessonDataContainerWrap>
        {sectionSubjects.map((subject, subjectIndex) => {
          const subjectLessons =
            sectionScheduledSubjects[subject.id]?.subject_lessons || 0;
          const scheduled = sectionScheduledSubjects[subject.id]?.scheduled || 0;
          const subjectUnits = this.renderUnitsSelected(subject.id);
          return (
            <LessonAccordionBodyMemo
              key={`subject-${subject.id}`}
              subject={subject}
              expanded={expandedSubjects.includes(subject.id)}
              scheduled={scheduled}
              subjectLessons={subjectLessons}
              subjectUnits={subjectUnits}
              dataComponent={dataComponent}
              subjectIndex={subjectIndex}
              handleAccordion={(id) => this.handleAccordion(id)}
            />
          );
        })}
      </LessonDataContainerWrap>
    );
  };

  changeAction = (action) => {
    this.setState({
      actionSelected: action,
    });
  };

  renderTopNav = () => {
    const { actionSelected } = this.state;
    const { isLearnosityLessonReviewEnabled } = this.props;
    let options = [
      { id: 1, name: 'Lessons' },
      { id: 2, name: 'Review Classes' },
    ];

    if (isLearnosityLessonReviewEnabled) {
      options = options.filter((option) => option.id !== 2);
    }

    return (
      <SelectWrap>
        <Select
          styles={SelectStyles}
          options={options}
          value={actionSelected}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          components={{
            IndicatorSeparator: () => null,
          }}
          onChange={this.changeAction}
          classNamePrefix="section-select"
        />
      </SelectWrap>
    );
  };

  renderSideBarContentTeacher() {
    const { isSubjectDropdown, unitView, actionSelected } = this.state;
    const {
      match,
      isOpenLessonSession,
      isOpenReviewSession,
      isOpenExamSession,
      isOpenSectionInfo,
      isLimited,
      isTeacher,
      editMode,
      updateScheduleList,
    } = this.props;

    const { courseId, sectionId } = match.params;

    let baseUrl = `/schedule/course/${courseId}/`;
    if (sectionId) {
      baseUrl += `section/${sectionId}`;
    } else {
      baseUrl += '#';
    }

    return (
      <LeftSiderBarContentGroup>
        {isTeacher && (
          <Link to={isLimited ? baseUrl : `${baseUrl}/section-info`}>
            <Button variant2>
              <span>Section Information</span>
            </Button>
          </Link>
        )}
        <LeftSideBarList noMargin>
          <List border>
            <MultiItemsSiderBar noLink>
              <SideBarContent isRotate={!isSubjectDropdown} />
            </MultiItemsSiderBar>
            {isTeacher && this.renderTopNav()}
          </List>
          <ShadowScrollbar autoHide style={scrollStyle}>
            <List>
              <ShowIf If={(unitView || editMode) && actionSelected.id === 1}>
                {this.renderSubjectsView()}
              </ShowIf>
              <ShowIf If={(unitView || editMode) && actionSelected.id === 2}>
                {this.renderReviewView()}
              </ShowIf>
            </List>
          </ShadowScrollbar>
        </LeftSideBarList>
        <ScheduleAddLessonSession
          isOpen={isOpenLessonSession}
          onClose={this.closeModal}
        />
        <ScheduleAddReviewSession
          isOpen={isOpenReviewSession}
          onClose={() => this.closeModal()}
          updateScheduleList={updateScheduleList}
        />
        <ScheduleAddExamSession isOpen={isOpenExamSession} onClose={this.closeModal} />

        <SectionInfo isOpen={isOpenSectionInfo} onClose={this.closeModal} />
      </LeftSiderBarContentGroup>
    );
  }

  render() {
    const { pathName, shouldDisplaySectionSelect, scheduleList } = this.props;
    const { unitSelected, isEditModal } = this.state;
    const { isSideBarClose, isTeacher } = this.props;
    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <EditLessonModal
          unitSelected={unitSelected}
          scheduleList={scheduleList}
          isEditModal={isEditModal}
          closeModal={() => this.setState({ isEditModal: false })}
          isTeacher={isTeacher}
        />
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <CourseSelect pathName={pathName} />
            {shouldDisplaySectionSelect && <SectionSelect pathName={pathName} />}
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        {this.renderSideBarContentTeacher()}
        <ActionShowHide className="toggle-icon" onClick={this.toggleLeftSidebar}>
          <MediaQuery maxWidth={960}>
            {(matches) =>
              matches ? (
                <span
                  className={isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'}
                />
              ) : (
                <span
                  className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'}
                />
              )
            }
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    );
  }
}

const mapStateToProps = (state) => ({
  dataFromCalendar: state,
});

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const isLearnosityLessonReviewEnabled = useIsLearnosityLessonReviewEnabled();

    return (
      <WrappedComponent
        {...props}
        isLearnosityLessonReviewEnabled={isLearnosityLessonReviewEnabled}
      />
    );
  };
  return ComponentWithData;
};

export default connect(mapStateToProps, null)(withDataWrapper(LeftSideBarScheduleModule));
