import React from 'react';
import Chip from '@mui/material/Chip';

type Props = {
  name: string;
};

const UserChip = ({ name }: Props) => {
  const chipRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    // on mount add shake transition
    if (chipRef.current) {
      // check layout component for css transition
      chipRef.current.classList.add('shake');
    }
    return () => {
      // on unmount remove shack transition
      if (chipRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chipRef.current.classList.remove('shake');
      }
    };
  }, []);
  return (
    <Chip
      label={name}
      clickable
      component="span"
      size="small"
      ref={chipRef}
      sx={{
        minWidth: 'fit-content',
      }}
    />
  );
};
export default React.memo(UserChip);
