import { connect } from 'react-redux';
import OnlineTestContent from '../../components/OnlineTestContent';
import { selectors } from '../../reducers/exam';
import { selectors as LeftSideBarSelector } from '../../reducers/leftSideBar';

const mapStateToProps = (state) => ({
  questions: selectors.getQuestions(state),
  assets: selectors.getQuestionAsset(state),
  lastIndex: selectors.getLastQuestionIndex(state),
  isPracticeNavigation: LeftSideBarSelector.getPracticeNavigation(state),
});

export default connect(mapStateToProps)(OnlineTestContent);
