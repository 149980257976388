import React, { Component } from 'react';
import Modal from 'react-modal';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  ModalHeader,
  ModalCLose,
} from '../Generals/stylesheets/General.style';
import { ButtonWrapper } from './ScheduleAddExamSession.style';
import FormInput from '../Generals/FormInput/FormInput';
import { Button } from '../Generals/stylesheets/Button.style';
import { EXAM_LENGTH_TYPES, EXAM_SESSION_TYPES } from '../../utils/constants';
import { formatDate } from '../../utils/func-utils';

const validationSchema = Yup.object().shape({
  sessionType: Yup.string().trim().required('Required'),
  start_date: Yup.date(),
  end_date: Yup.date().when('start_date', (st, schema) => Yup.date().min(st, 'End date should be greater than start date.')),
  lengthType: Yup.string().trim().required('Required'),
});

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '960px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    overflow: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class ScheduleAddExamSession extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    sectionExamAdd: PropTypes.func,
    sectionId: PropTypes.any,
    curriculumId: PropTypes.any,
    isAdding: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    isSubmitSuccessfully: PropTypes.bool,
    sessionDate: PropTypes.any,
    clearSessionDate: PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    const { isSubmitSuccessfully: pis } = prevProps;
    const { isSubmitSuccessfully } = this.props;

    if (pis !== isSubmitSuccessfully && isSubmitSuccessfully) this.onClose();
  }

  onClose = () => {
    const { onClose, clearSessionDate } = this.props;
    onClose();
    clearSessionDate();
  }

  onSubmit = (values) => {
    const {
      sectionId, curriculumId,
      sectionExamAdd,
    } = this.props;
    const {
      end_date: endDate, start_date: startDate,
      sessionType, lengthType,
    } = values;

    const payload = {
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
      session_type: sessionType.value,
      length_type: lengthType.value,
      section: sectionId,
      curriculum: curriculumId,
    };

    sectionExamAdd(payload);
  }

  render() {
    const { isAdding, isOpen, sessionDate } = this.props;
    const initialValues = {
      date: sessionDate || new Date(),
      sessionType: '',
      lengthType: '',
    };

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Create Exam Session
          <ModalCLose className="chalktalk-close" onClick={this.onClose} />
        </ModalHeader>
        <Formik
          // eslint-disable-next-line no-return-assign
          ref={ref => this.formik = ref}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
        >
          <Form noValidate>
            <FormInput
              type="select"
              name="sessionType"
              label="Session type"
              options={EXAM_SESSION_TYPES.arrays}
            />
            <FormInput
              type="date"
              name="start_date"
              label="Start date"
              dateFormat="yyyy/MM/dd"
            />
            <FormInput
              type="date"
              name="end_date"
              label="End date"
              dateFormat="yyyy/MM/dd"
            />
            <FormInput
              type="select"
              name="lengthType"
              label="Length Type"
              options={EXAM_LENGTH_TYPES.arrays}
            />
            <ButtonWrapper>
              <Button
                submit
                type="submit"
                disabled={isAdding}
              >
                Submit Exam Session
              </Button>
            </ButtonWrapper>
          </Form>
        </Formik>
      </Modal>
    );
  }
}
