// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { REPORT_TYPES, SHARED_ASSET_TYPE } from '@utils/enums';
import {
  FeedbackContainer,
  FeedbackIcon,
} from '@components/Generals/stylesheets/Button.style';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AssetTypeSpecial from '@components/QuestionAssets/AssetTypeSpecial';
import AssetTypeNormal from '@components/QuestionAssets/AssetTypeNormal';
import { useReportErrorModal } from '@components/PracticeV2/Provider/PracticeReportProvider';
import { useQuestion } from '@components/PracticeV2/Provider/PracticeProvider';
import { TabProvider, Tab, TabPanel, TabList } from '@components/Shared/TabsDefaultSkin';

export const AssetContentItem = ({ asset }) => {
  const { asset_type: assetType, id } = asset;

  switch (assetType) {
    case SHARED_ASSET_TYPE.PASSAGE:
    case SHARED_ASSET_TYPE.ANSWER:
      return (
        <TabPanel tabKey={asset.id} key={id}>
          <AssetTypeSpecial asset={asset} />
        </TabPanel>
      );
    // other type
    default:
      return (
        <TabPanel tabKey={asset.id} key={id}>
          <AssetTypeNormal asset={asset} />
        </TabPanel>
      );
  }
};

const QuestionAssets = () => {
  const { handleOpenReportModal } = useReportErrorModal();
  const question = useQuestion();
  const assets = question?.question?.shared_assets;
  if (!question || !assets?.length) {
    return null;
  }
  return (
    <Paper
      elevation={1}
      sx={{
        flex: 1,
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        // the content for some reason 30rem max ?
        maxWidth: {
          xs: '100%',
          md: '27rem',
        },
      }}
    >
      <TabProvider defaultTab={assets?.[0]?.id}>
        <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          boxShadow: '0px 1rem 0.6875rem 0.125rem rgb(255 255 255 / 96%)',
          zIndex: 1,
        }}>
          <TabList>
            {assets.map((asset) => (
              <Tab key={asset.id} tabKey={asset.id}>
                {asset.asset_type}
              </Tab>
            ))}
          </TabList>
          <FeedbackContainer
            onClick={() => handleOpenReportModal(REPORT_TYPES.SHARED_ASSET)}
          >
            <FeedbackIcon src="/images/feedback.png" />
          </FeedbackContainer>
        </Box>
        {assets.map((asset) => (
          <AssetContentItem key={asset.id} asset={asset} />
        ))}
      </TabProvider>
    </Paper>
  );
};

export default React.memo(QuestionAssets);
