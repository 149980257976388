import { connect } from 'react-redux';
import SetupYourCourse from '../../components/Modals/SetupYourCourse';
import {
  selectors as CurriculumSelectors,
  actions as CurriculumActions,
} from '../../reducers/curriculums';
import {
  selectors as UserSelectors,
} from '../../reducers/user';
import {
  selectors as CountrySelectors,
  actions as CountryActions,
} from '../../reducers/countries';
import {
  selectors as DistrictSelectors,
  actions as DistrictActions,
} from '../../reducers/districts';
import {
  actions as CourseActions,
  selectors as CourseSelectors,
} from '../../reducers/courses';

const mapStateToProps = (state) => ({
  standard: CurriculumSelectors.getStandardList(state),
  curriculum: CurriculumSelectors.getAllCurriculums(state),
  country: CountrySelectors.getCountryList(state),
  district: DistrictSelectors.getDistrictList(state),
  teacherDetail: UserSelectors.getCurrentUser(state),
  subscriptionData: UserSelectors.getSubscriptionData(state),
  isCreatingCourse: CourseSelectors.isCreatingCourse(state),
  isCourseCreated: CourseSelectors.isCourseCreated(state),
  errorMessage: CourseSelectors.getCreatingCourseErrorMsg(state),
  placementTypesObj: CurriculumSelectors.getPlacementTypesObj(state),
  exitTypesObj: CurriculumSelectors.getExitTypesObj(state),
});

const mapDispatchToProps = {
  fetchDistrictList: DistrictActions.fetchDistrictList,
  createCourse: CourseActions.createCourse,
  fetchCountryList: CountryActions.fetchCountryList,
  getCurriculumList: CurriculumActions.getCurriculumList,
  getCurriculumStandardList: CurriculumActions.getCurriculumStandardList,
  getCurriculumPlacementType: CurriculumActions.getCurriculumPlacementType,
  getCurriculumExitType: CurriculumActions.getCurriculumExitType,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupYourCourse);
