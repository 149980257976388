import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { ContainerWrap } from '../Generals/stylesheets/General.style';

export default class MainContent extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    isSideBarClose: PropTypes.bool,
    forceClose: PropTypes.bool,
  };

  render() {
    const { isLoading, isSideBarClose, forceClose } = this.props;
    if (isLoading) {
      return <LoadingIndicator isLoading={isLoading} />;
    }

    return (
      <ContainerWrap isSideBarClose={forceClose ? true : isSideBarClose}>
        {this.props.children}
      </ContainerWrap>
    );
  }
}
