import { useMemo } from 'react';
import { useGetSectionSubjectUnitLesson } from 'chalktalk-react/hooks/useGetSectionSubjectUnitLesson';
import { useGetSubjectsSkills } from 'chalktalk-react/hooks/genericSkillHooks';
import { useQueries } from 'react-query';

const SKILLS_FILES = {
  Algebra_1: 'Algebra_1.json',
  English_9: 'Ninth_grade_language_arts.json',
  English_10: 'Tenth_grade_language_arts.json',
  English_1: 'Ninth_grade_language_arts.json',
};

type SkillJsonRecord = {
  skillName: string;
  skillURL: string;
  categoryName: string;
};

const formatDataToTree = (subjects, categorizedSkills) => {
  const tree = subjects.map((subject) => {
    // Filter skills for this subject
    const subjectSkills = categorizedSkills.filter(
      (skill) => skill.subjectId === subject.id,
    );

    // Group skills by category
    const categorizedSkillsMap =
      subjectSkills?.reduce((acc, skill) => {
        const category = skill.categoryName || 'Others';
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(skill);
        return acc;
      }, {}) || {};

    // Create category nodes with their skills
    const categoryNodes = Object.entries(categorizedSkillsMap).map(
      ([category, skills]) => ({
        id: `${subject.id}-${category}`,
        label: category,
        noCheckbox: true,
        noChip: true,
        children: skills.map((skill) => ({
          id: skill.id.toString(),
          label: skill.name,
        })),
      }),
    );

    // Return subject node with category children
    return {
      id: subject.id.toString(),
      label: subject.display_name,
      noCheckbox: true,
      noChip: true,
      children: categoryNodes,
    };
  });

  return tree;
};

const fetchSkillsJSON = async (skillFileName) =>
  fetch(`/shared/${skillFileName}`)
    .then((res) => res.json())
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));

const useJsonSubjectsSkills = (subjects: Array<{ slug: string }>) => {
  // Create a map of unique skill files needed
  const skillFiles = useMemo(() => {
    const files = new Set<string>();
    subjects?.forEach((subject) => {
      const fileName = SKILLS_FILES[subject?.slug ?? ''];
      if (fileName) {
        files.add(fileName);
      }
    });
    return Array.from(files);
  }, [subjects]);

  // Use parallel queries for each unique file
  const queries = useQueries(
    skillFiles.map((fileName) => ({
      queryKey: ['JSON Skills', fileName],
      queryFn: async () => {
        const res = await fetchSkillsJSON(fileName);
        return { fileName, data: res };
      },
      enabled: skillFiles.length > 0,
      suspense: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    })),
  );

  // Combine and memoize results
  const combinedResults = useMemo(() => {
    const results: Record<string, SkillJsonRecord[]> = {};
    const isLoading = queries.some((query) => query.isLoading);
    const isError = queries.some((query) => query.isError);

    if (!isLoading && !isError) {
      queries.forEach((query) => {
        if (query.data) {
          const { fileName, data } = query.data;
          // Map back to subject slugs
          Object.entries(SKILLS_FILES).forEach(([slug, file]) => {
            if (file === fileName) {
              results[slug] = data;
            }
          });
        }
      });
    }

    return {
      data: results,
      isLoading,
      isError,
      errors: queries.map((q) => q.error).filter(Boolean),
    };
  }, [queries]);

  return combinedResults;
};

export const useCategorizedSkills = (subjects, skills) => {
  // Fetch the JSON skills associated with the selected subject
  const { data: skillsMetadata, isLoading } = useJsonSubjectsSkills(subjects);

  return useMemo(() => {
    if (isLoading) {
      return [];
    }

    // Process all subjects and their skills
    return subjects
      .flatMap((subject) => {
        const subjectSkills = skills[subject.id] || [];
        const subjectMetadata = skillsMetadata[subject.slug] || [];

        return subjectSkills.map((skill) => {
          const skillMetadata = subjectMetadata.find(
            (metadata) => metadata.skillName === skill.name,
          );

          return {
            ...skill,
            categoryName: skillMetadata?.categoryName || 'Others',
            subjectId: subject.id,
          };
        });
      })
      .sort((a, b) => {
        // First sort by subject name
        const subjectCompare = (a.subjectName || '').localeCompare(b.subjectName || '');
        if (subjectCompare !== 0) {
          return subjectCompare;
        }

        // Then sort by category name in reverse order
        return -(b.categoryName || '').localeCompare(a.categoryName || '');
      });
  }, [isLoading, skills, skillsMetadata, subjects]);
};

const useSkillTreeSelector = (targetSubjects: unknown[]) => {
  const { data: allSubjects } = useGetSectionSubjectUnitLesson();
  const subjects = targetSubjects || allSubjects;
  const { data: skills } = useGetSubjectsSkills(allSubjects.map((s) => s.id) ?? []);
  const categorizedSkills = useCategorizedSkills(allSubjects, skills);

  const skillTree = useMemo(
    () => formatDataToTree(subjects, categorizedSkills),
    [subjects, categorizedSkills],
  );

  const skillMap = useMemo(() => {
    const map = {};
    allSubjects.forEach((subject) => {
      (skills[subject.id] || []).forEach((skill) => {
        map[skill.id] = skill;
      });
    });
    return map;
  }, [allSubjects, skills]);

  return { skillMap, skillTree };
};

export default useSkillTreeSelector;
