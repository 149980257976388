import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import DatePicker from 'react-datepicker';
import MediaQuery from 'react-responsive';
import CustomTooltip from './CustomTooltip';
import {
  AttendanceReportRechartWrap,
  ChartActionWrap,
  WeeklyButton,
  DailyButton,
  SelectDayChartWrap,
} from '../DashboardAttendanceReport/DashboardAttendanceReport.style';

const valueY = [0, 20, 40, 60, 80, 100];

const maxDate = moment().endOf('week').toDate();

export default class DashboardAttendanceReportRechart extends Component {
  static propTypes = {
    data: PropTypes.any,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    setWeeklyView: PropTypes.func.isRequired,
    setDailyView: PropTypes.func.isRequired,
  };

  renderChartActionGroup = () => {
    const { startDate, endDate, setStartDate, setEndDate, setWeeklyView, setDailyView } =
      this.props;
    return (
      <MediaQuery minWidth={768}>
        {(matches) =>
          matches ? (
            <ChartActionWrap>
              <SelectDayChartWrap>
                <DatePicker
                  selected={startDate}
                  onChange={setStartDate}
                  selectsStart
                  endDate={maxDate}
                />
                <DatePicker
                  selected={endDate}
                  onChange={setEndDate}
                  selectsEnd
                  endDate={maxDate}
                />
              </SelectDayChartWrap>
              <SelectDayChartWrap>
                <WeeklyButton onClick={setWeeklyView}>Weekly</WeeklyButton>
                <DailyButton onClick={setDailyView}>Daily</DailyButton>
              </SelectDayChartWrap>
            </ChartActionWrap>
          ) : (
            <ChartActionWrap>
              <SelectDayChartWrap>
                <DatePicker
                  selected={startDate}
                  onChange={setStartDate}
                  selectsStart
                  endDate={maxDate}
                />
                <WeeklyButton onClick={setWeeklyView}>Weekly</WeeklyButton>
              </SelectDayChartWrap>
              <SelectDayChartWrap>
                <DatePicker
                  selected={endDate}
                  onChange={setEndDate}
                  selectsEnd
                  endDate={maxDate}
                />
                <DailyButton onClick={setDailyView}>Daily</DailyButton>
              </SelectDayChartWrap>
            </ChartActionWrap>
          )
        }
      </MediaQuery>
    );
  };

  render() {
    const { data } = this.props;

    return (
      <AttendanceReportRechartWrap>
        {this.renderChartActionGroup()}
        <ResponsiveContainer width="99%" height={350}>
          <AreaChart
            data={data}
            margin={{
              top: 5,
              right: 20,
              left: -10,
              bottom: 5,
            }}
          >
            <XAxis dataKey="date" tick={{ fontSize: 11 }} />
            <YAxis
              dataKey="value"
              name="stature"
              unit="%"
              ticks={valueY}
              tick={{ fontSize: 11 }}
              type="number"
            />
            <Tooltip content={<CustomTooltip />} />
            <Area type="monotone" dataKey="value" stroke="#4d72bb" activeDot={{ r: 5 }} />
          </AreaChart>
        </ResponsiveContainer>
      </AttendanceReportRechartWrap>
    );
  }
}
