import React from 'react';
import PropTypes from 'prop-types';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import CommonUtils from '../../utils/commonUtils';
import { NavigationContentWrap } from './QuestionAssets.style';
import addImageErrorListener from '../../utils/errorCapture';

const scrollStyle = {
  height: 'calc(100vh - 9em)',
  width: '100%',
  maxHeight: 'calc(100% - 1rem)',
};

const scrollStyleWithFooter = {
  height: 'calc(100vh - 12em)',
  width: '100%',
  maxHeight: 'calc(100% - 1rem)',
};
// All type except Reading Passage and Answer

const AssetTypeNormal = ({ asset, hasFooter, scrollBarStyle }) => {
  const assetRef = React.useRef(null);
  const { asset_type: assetType, text } = asset;
  const html = React.useMemo(
    () => CommonUtils.sharedAssetsStandardize(text, assetType).replace(/\\r\\n/g, ''),
    [text, assetType],
  );
  const defaultScrollbarStyle = hasFooter ? scrollStyleWithFooter : scrollStyle;

  React.useEffect(() => {
    if (assetRef.current) {
      addImageErrorListener(assetRef.current);
    }
  }, [html]);

  return (
    <ScrollWrapper autoHide sx={scrollBarStyle || defaultScrollbarStyle}>
      <NavigationContentWrap ref={assetRef} dangerouslySetInnerHTML={{ __html: html }} />
    </ScrollWrapper>
  );
};

AssetTypeNormal.propTypes = {
  asset: PropTypes.object,
  hasFooter: PropTypes.bool,
  scrollBarStyle: PropTypes.shape(),
};

export default AssetTypeNormal;
