import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import _isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { ModalHeader, ModalCLose } from '../Generals/stylesheets/General.style';
import ScheduleAddLessonSessionForm from '../../containers/ScheduleAddLessonSessionForm';

import {
  selectors as SectionScheduleSelectors,
  actions as SectionScheduleActions,
} from '../../reducers/sectionSchedule';
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '960px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    overflow: 'unset',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class ScheduleAddLessonSession extends Component {
  componentDidMount() {
    const { sectionId } = this.props;

    if (sectionId) {
      this.getInitialData();
    }
  }

  componentDidUpdate(prevProps) {
    const { sectionId } = this.props;
    const { sectionId: prevSectionId } = prevProps;

    if (sectionId && !_isEqual(sectionId, prevSectionId)) {
      this.getInitialData();
    }
  }

  onClose = () => {
    const { onClose, clearSessionDate } = this.props;
    onClose();
    clearSessionDate();
  };

  getInitialData() {
    const { sectionId, sectionUnitGet, fetchSectionSubjectList } = this.props;

    sectionUnitGet(sectionId);
    fetchSectionSubjectList(sectionId);
  }

  render() {
    const { isOpen } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Create Lesson Session
          <ModalCLose className="chalktalk-close" onClick={this.onClose} />
        </ModalHeader>
        <ScheduleAddLessonSessionForm onClose={this.onClose} />
      </Modal>
    );
  }
}

ScheduleAddLessonSession.propTypes = {
  sectionId: PropTypes.any,
  sectionUnitGet: PropTypes.func,
  fetchSectionSubjectList: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  clearSessionDate: PropTypes.func,
};

const mapDispatchToProps = {
  sectionScheduleUpdateFromCalendar:
    SectionScheduleActions.sectionScheduleUpdateFromCalendar,
};

export default connect()(ScheduleAddLessonSession);
