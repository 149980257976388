/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import FormInput from '../Generals/FormInput/FormInput';

import {
  LoginItemWrap,
  LoginItem,
  LoginPasswordAction,
  ButtonStyled,
  LoginErrorMessage,
  LoginContainerWrap,
  LoginBlockRight,
} from '../LoginPage/Login.style';
import { Arrow, Paragraph, Title } from '../LoginPage/SignUpType.style';
import BackgroundLeft from '../BackgroundLeft';
import {
  getErrorMessage,
  isRegistering,
  registerTeacher,
} from '../../reducers/register';

const phoneRegExp = /^(\+?()\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required('Required'),
  lastName: Yup.string().trim().required('Required'),
  email: Yup.string().email('Invalid Email').trim().required('Required'),
  password: Yup.string()
    .trim()
    .notOneOf(['123456', 123456], 'Your password is too simple')
    .min(8, 'Password must be a minimum of 8 characters long')
    .matches(/[0-9]/, 'The password contains at least one number')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Your password and confirm password is not match',
    )
    .required('Required'),

  role: Yup.mixed().required('Required'),
  phoneNumber: Yup.string()
    .trim()
    .min(7, 'Phone Number must be a minimum of 7 numbers long')
    .max(15, 'Phone Number must be a maximum of 15 numbers long')
    .matches(phoneRegExp, 'Phone number contain numbers only')
});

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

const roleOption = [
  createOption('ELA Teacher'),
  createOption('STEM Teacher'),
  createOption('Head of ELA Department'),
  createOption('Head of STEM Department'),
  createOption('School Administrator'),
  createOption('District Administrator'),
];

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  confirmPassword: '',
  role: '',
};
class TeacherRegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowPassword: false,
      gitMessage: false,
    };
  }

  static propTypes = {
    isLoading: PropTypes.bool,
    registerStudent: PropTypes.func.isRequired,
    errorMessage: PropTypes.any,
  };

  toggleShowPassword = (isShowPassword) => {
    this.setState({
      isShowPassword,
    });
  };

  handleSubmit = (values) => {

    const { value: roleValue } = values.role;
    const submittedValues = {
      ...values,
      role: roleValue,
    };
    const { registerTeacher } = this.props;
    registerTeacher(submittedValues);
    this.setState({ gitMessage: true });
  };

  registerStudent = (values) => {
    const { registerStudent } = this.props;
    registerStudent(values);
  };

  renderErrorMessage = (error, index) => {
    if (this.state.gitMessage) {
      return (<p key={index}>{error}</p>);
    }
    return null;
  }

  renderCreateAccountBtn = () => {
    const { isLoading } = this.props;
    return isLoading ? (
      <ButtonStyled primary>Loading ...</ButtonStyled>
    ) : (
      <ButtonStyled type="submit">Create account</ButtonStyled>
    );
  };

  renderPasswordAction = () => (
    <LoginPasswordAction>
      <label>
        <input type="checkbox" />
        <span className="checkmark" />
        <span>Remember me</span>
      </label>

    </LoginPasswordAction>
  )

  handleRoleChange = (form, fieldName) => (newValue) => {
    form.setFieldValue(fieldName, newValue);
  };

  renderFieldRole = (fieldProps) => {
    const {
      form,
      field,
      label,
      options,
    } = fieldProps;
    const { name: fieldName } = field;
    return (
      <FormInput
        name={fieldName}
        type="createselect"
        label={label}
        options={options}
        isClearable
        onChange={this.handleRoleChange(form, fieldName)}
        menuPlacement="top"
      />
    );
  };

  componentDidMount() {
    this.setState({ gitMessage: false });
  }

  render() {
    const { errorMessage } = this.props;
    const { isShowPassword } = this.state;

    return (
      <LoginContainerWrap>
        <BackgroundLeft />
        <LoginBlockRight>
          <LoginItemWrap>
            <LoginItem>
              <Link to="/login">
                <Arrow src="/images/left.png" />
              </Link>
              <Title>Welcome to ChalkTalk</Title>
              <Paragraph>
                <br />
                In just one semester, students who used ChalkTalk experienced up to 6x more
                growth than those who received a full year of traditional classroom instruction.
              </Paragraph>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={this.handleSubmit}
              >
                <Form noValidate>
                  <FormInput type="text" name="firstName" label="First Name" />
                  <FormInput type="text" name="lastName" label="Last Name" />
                  <FormInput
                    type="text"
                    name="phoneNumber"
                    label="Phone Number"
                  />
                  <FormInput type="email" name="email" label="Email" />

                  <FormInput
                    type={isShowPassword ? 'text' : 'password'}
                    name="password"
                    label="Password"
                  />
                  <FormInput
                    type="password"
                    name="confirmPassword"
                    label="Confirm Password"
                  />
                  <Field
                    component={this.renderFieldRole}
                    name="role"
                    label="Your Role"
                    options={roleOption}
                  />
                  {this.renderPasswordAction()}

                  {errorMessage && (
                    <LoginErrorMessage>
                      {errorMessage.map(this.renderErrorMessage)}
                    </LoginErrorMessage>
                  )}
                  <ButtonStyled type="submit">Create account</ButtonStyled>
                </Form>
              </Formik>
            </LoginItem>
          </LoginItemWrap>
        </LoginBlockRight>
      </LoginContainerWrap>
    );
  }
}
const mapStateToProps = (state) => ({
  errorMessage: getErrorMessage(state),
  isRegistering: isRegistering(state),
});

const mapDispatchToProps = {
  registerTeacher,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeacherRegisterForm);
