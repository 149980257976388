import { createSelector } from 'reselect';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { SECTIONS_UPDATE_SUCCESS } from '../sections';
// action definition
export const SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST = 'sectionSubjects/FETCH_SECTION_SUBJECT_LIST';
export const SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST_SUCCESS = 'sectionSubjects/FETCH_SECTION_SUBJECT_LIST_SUCCESS';
export const SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST_FAIL = 'sectionSubjects/FETCH_SECTION_SUBJECT_LIST_FAIL';

// selectors
const getSubjectById = ({ sectionSubjects }) => _get(sectionSubjects, 'subjectById', {});
const getSubjectInfoById = ({ sectionSubjects }, subjectId) => _get(sectionSubjects, ['subjectById', subjectId], {});
const getSubjectIds = ({ sectionSubjects }) => _get(sectionSubjects, 'subjectIds', []);
const getSubjectDetail = ({ sectionSubjects }, subjectId) => _get(sectionSubjects, `subjectById.${subjectId}`, {});
const getSectionSubjectList = createSelector(getSubjectIds, getSubjectById, (ids, byIds) => ids.map(id => byIds[id]));
const getSectionSubjectListOrder = createSelector(getSectionSubjectList, subjectList => _orderBy(subjectList, 'order', 'asc'));

const getShouldFetch = ({ sectionSubjects }, sectionId) => _get(sectionSubjects, 'hasFetchedOfSectionId', null) != sectionId; // eslint-disable-line

const countAllLessonGroupInAllSubject = (state) => {
  const subjectById = getSubjectById(state);
  const subjectIds = getSubjectIds(state);
  const lessonGroupArrEachSubject = subjectIds.map(id => subjectById[id].summary.lesson_count);
  const totalLessonGroup = lessonGroupArrEachSubject.reduce((acc, curr) => acc + curr, 0);
  return totalLessonGroup;
};

const isGettingSectionSubject = ({ sectionSubjects }) => sectionSubjects.isLoading;

export const selectors = {
  getSectionSubjectList,
  getSectionSubjectListOrder,
  getSubjectDetail,
  getShouldFetch,
  getSubjectIds,
  getSubjectById,
  countAllLessonGroupInAllSubject,
  isGettingSectionSubject,
  getSubjectInfoById,
};

// action creators
export const fetchSectionSubjectList = sectionId => ({
  type: SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST,
  payload: {
    sectionId,
  },
});

export const fetchSectionSubjectListSuccess = (sectionSubjectList, sectionId) => ({
  type: SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST_SUCCESS,
  payload: {
    sectionSubjectList,
    sectionId,
  },
});

export const fetchSectionSubjectListFailed = errorMsg => ({
  type: SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST_FAIL,
  payload: {
    errorMsg,
  },
});

export const actions = {
  fetchSectionSubjectList,
  fetchSectionSubjectListSuccess,
  fetchSectionSubjectListFailed,
};

// reducers

const initialState = {
  isLoading: null,
  errorMsg: null,
  subjectById: {},
  subjectIds: [],
  hasFetchedOfSectionId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST: {
      const { sectionId } = action.payload;
      const shouldFetch = state.hasFetchedOfSectionId != sectionId; // eslint-disable-line

      return {
        ...state,
        isLoading: shouldFetch,
        errorMsg: false,
      };
    }

    case SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST_SUCCESS: {
      const { sectionSubjectList = [], sectionId } = action.payload;
      const subjectById = _keyBy(sectionSubjectList, 'id');
      const subjectIds = sectionSubjectList.map(({ id }) => id);

      return {
        ...state,
        isLoading: false,
        subjectById,
        subjectIds,
        hasFetchedOfSectionId: sectionId,
      };
    }

    case SECTION_SUBJECTS_FETCH_SECTION_SUBJECT_LIST_FAIL: {
      const { errorMsg } = action.payload;
      return {
        ...state,
        isLoading: false,
        errorMsg,
      };
    }
    case SECTIONS_UPDATE_SUCCESS:
      return {
        ...state,
        hasFetchedOfSectionId: null,
      };

    default: {
      return state;
    }
  }
}
