/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  UnitPracticeWrap,
  UnitQuestionTypeWrap,
  UnitQuestionLogo,
  UnitQuestionTypeContentWrap,
  UnitQuestionTypeItemWrap,
  UnitQuestionTypeItem,
  UnitQuestionTopic,
  UnitQuestionTopicCover,
  UnitActivityOverviewWrap,
  UnitPracticeItemWrap,
  UnitPracticeItem,
  UnitPracticeTitle,
  UnitPracticeTableWrap,
  UnitPracticeTableSubTitle,
  UnitName,
  UnitScoreActive,
  QuestionTitle,
  MinimizeBtn,
  MinimizeBtnWrapper,
  StudentContentWrapper,
} from './UnitPracticeTeacherDetail.style';
import {
  LessonAssignStatus,
  UnAssignButton,
} from '../LessonManagement/LessonManagement.style';
import {
  TableContentItem,
  TableResponsiveContainer,
  TableHeadResponsive,
  TableContentResponsive,
} from '../Table/TableComponent.style';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { ROLE_TYPE } from '../../utils/enums';
import ColumnSize from './ColumnSize';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

class UnitPracticeTeacherDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lessonId: '',
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
      isMinimizeOverview: false,
      isMinimizeColumn: false,
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      history, practiceId,
      isInitialized,
    } = this.props;
    const prevUnitId = _get(prevProps, 'match.params.unitId');

    const {
      sectionId, subjectId,
      unitId, courseId,
    } = match.params;

    if (prevProps.isInitialized !== isInitialized && isInitialized) {
      history.push(`/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice/${practiceId}`);
    }

    if (prevUnitId !== unitId && unitId) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    const {
      userRole,
      match,
      getAllPracticeSessionActivities,
      lessonAssignmentDetail,
    } = this.props;
    const { sectionId, unitId } = match.params;

    if (userRole === ROLE_TYPE.STUDENT) {
      getAllPracticeSessionActivities(sectionId, unitId);
    } else {
      lessonAssignmentDetail(sectionId, null, unitId);
    }
  }

  assignStudent = (student) => () => {
    const {
      assignLesson, match,
      currentUserId,
    } = this.props;
    const { lessonId } = this.state;
    const { params } = match;
    const { sectionId, unitId } = params;
    const payload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUserId,
      assigned_to: student.id,
    };

    assignLesson(sectionId, payload);
  };

  unAssignStudent = (student) => () => {
    const {
      unAssignLesson, match,
      currentUserId,
    } = this.props;
    const { lessonId } = this.state;
    const { params } = match;
    const { sectionId, unitId } = params;
    const payload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUserId,
      assigned_to: student.id,
    };

    unAssignLesson(sectionId, payload);
  }

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  }

  customOrderBy = (student) => {
    const { practiceSummaryByUserId } = this.props;
    const { sortBy } = this.state;
    const { first_name: firstName, last_name: lastName, id } = student;
    const name = `${firstName} ${lastName}`;
    const summaryReport = _get(practiceSummaryByUserId, `${id}`, {});

    if (sortBy === 'last_name') {
      return student.last_name.toUpperCase();
    }

    if (sortBy === 'lastActivity') {
      return _get(practiceSummaryByUserId, [id, 'lastActivity'], null);
    }

    return _get(summaryReport, `${sortBy}.score`, null);
  }

  renderPracticeCoverItem = (lesson) => {
    const { id, name } = lesson;
    return (
      <div
        key={id}
      >
        <p>{name}</p>
      </div>
    );
  }

  renderQuestionType = () => {
    const { lessons, unitDetail: { name } } = this.props;

    return (
      <UnitQuestionTypeItemWrap>
        <UnitQuestionTypeItem>
          <UnitQuestionTopic>
            <p>Instructions</p>
            <span>
              When students practice reading on ChalkTalk, they select the passage type
              they want to practice from the right. The passage they receive is rated on a
              scale of “easy” to “hard” based on its lexile score.
            </span>
            <span>
              Every passage comes with <strong>its full set of questions</strong>, allowing 
              students to exercise their overall reading comprehension for that text. 
              Note that while we have easier and harder texts, all our texts are mock SAT/ACT 
              texts which tend to be more advanced texts overall.
            </span>
          </UnitQuestionTopic>

          <UnitQuestionTopic>
            <p>Topics covered</p>
            <UnitQuestionTopicCover>
              {lessons.map(this.renderPracticeCoverItem)}
            </UnitQuestionTopicCover>
          </UnitQuestionTopic>
        </UnitQuestionTypeItem>
      </UnitQuestionTypeItemWrap>
    );
  }

  renderAssetTypeItem = (item, index) => {
    const { practiceReport, students = [] } = this.props;
    const studentLength = students.length;
    const { id: assetType, name } = item;
    const assetReport = _get(practiceReport, assetType, {
      score: 0,
      maxScore: 0,
      count: 0,
    });
    const totalScore = assetReport.maxScore === 0 ? 0 : Math.round((assetReport.score / assetReport.maxScore) * 100);
    const percentActive = studentLength === 0 ? 0 : Math.round((assetReport.count / studentLength) * 100);

    return (
      <UnitPracticeItem key={index}>
        <UnitName>{name}</UnitName>
        <UnitScoreActive>
          <span>{`${totalScore}%`}</span>
          <span>score</span>
        </UnitScoreActive>
        <UnitScoreActive>
          <span>{`${percentActive}%`}</span>
          <span>active</span>
        </UnitScoreActive>
      </UnitPracticeItem>
    );
  }

  renderActivityOverview = () => {
    const { unitDetail } = this.props;
    const { assets = [] } = unitDetail;

    return (
      <UnitActivityOverviewWrap isMinimize={this.state.isMinimizeOverview}>
        <h3>Passage Type</h3>
        <UnitPracticeItemWrap>
          {assets.map(this.renderAssetTypeItem)}
        </UnitPracticeItemWrap>
      </UnitActivityOverviewWrap>
    );
  }

  handleMinimizeOverview = () => {
    this.setState((prevState) => ({
      isMinimizeOverview: !prevState.isMinimizeOverview,
    }));
  }

  handleMinimizeTableColumn = () => {
    this.setState((prevState) => ({
      isMinimizeColumn: !prevState.isMinimizeColumn,
    }));
  }

  renderUnitPracticeBlock = () => {
    const { unitDetail: { name } } = this.props;
    return (
      <UnitQuestionTypeWrap large>
        <UnitQuestionLogo isInstructorLogo>
          <img src="/images/bg-practice.png" alt="" />
          <QuestionTitle>
            {name}
          </QuestionTitle>
        </UnitQuestionLogo>
        <MinimizeBtnWrapper>
          <MinimizeBtn isMinimize={this.state.isMinimizeOverview} onClick={this.handleMinimizeOverview}>
            <i className="chalktalk-icon-bold-down" />
          </MinimizeBtn>
        </MinimizeBtnWrapper>
        <UnitQuestionTypeContentWrap isMinimize={this.state.isMinimizeOverview}>
          {this.renderQuestionType()}
          {this.renderActivityOverview()}
        </UnitQuestionTypeContentWrap>
      </UnitQuestionTypeWrap>
    );
  }

  renderStudentListHeaderItem = (item, index) => {
    const { unitDetail } = this.props;
    const { assets = [] } = unitDetail;
    const { name, id } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === id;

    return (
      <TableHeader
        isUnitPracticeTable
        {...ColumnSize[1]}
        value={name}
        key={index}
        isExpand={this.state.isMinimizeColumn}
        haveFilter={isFilterChoosen}
        dataLength={assets.length}
        onClick={() => this.sort(id, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  }

  renderStudentListHeader = () => {
    const { unitDetail } = this.props;
    const { assets = [] } = unitDetail;
    const { sortBy, orderBy } = this.state;
    const isNameFilter = sortBy === 'last_name';
    const isLastActivitiFilter = sortBy === 'lastActivity';

    return (
      <TableHeadResponsive isStudentListHeader>
        <TableHeader
          isUnitPracticeTable
          {...ColumnSize[0]}
          value="Student name"
          haveFilter={isNameFilter}
          onClick={() => this.sort('last_name', isNameFilter ? -orderBy : ORDER_BY.ASC)}
        />

        {assets.map(this.renderStudentListHeaderItem)}
        {/* <MinimizeBtnWrapper>
          <MinimizeBtn isTableBtn isMinimize={this.state.isMinimizeColumn} onClick={this.handleMinimizeTableColumn}>
            <i className="chalktalk-icon-bold-right" />
          </MinimizeBtn>
        </MinimizeBtnWrapper> */}
        <TableHeader
          isUnitPracticeTable
          {...ColumnSize[2]}
          value="Last Activity"
          haveFilter={isLastActivitiFilter}
          isMinimize={this.state.isMinimizeColumn}
          onClick={() => this.sort('lastActivity', isLastActivitiFilter ? -orderBy : ORDER_BY.ASC)}
        />
        <TableHeader
          isUnitPracticeTable
          {...ColumnSize[3]}
          isMinimize={this.state.isMinimizeColumn}
        />

      </TableHeadResponsive>
    );
  }

  renderStudentScoreItem = (studentId) => (item, index) => {
    const { practiceSummaryByUserId, match } = this.props;
    const { id: assetType } = item;

    const report = _get(practiceSummaryByUserId, [studentId, assetType], null);
    const {
      sectionId, unitId, courseId, subjectId,
    } = match.params;
    const reviewUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice-review/student/${studentId}?asset_type=${assetType}`;

    return (
      <TableContent isUnitPracticeTable isExpand={this.state.isMinimizeColumn} key={index} {...ColumnSize[1]}>
        {report
          ? (
            <Link to={reviewUrl}>
              {`${report.score}/${report.maxScore}`}
            </Link>
          )
          : '--'}
      </TableContent>
    );
  }

  renderStudentItem = (student) => {
    const { practiceSummaryByUserId, unitDetail, assignedByUserId } = this.props;
    const { assets = [] } = unitDetail;
    const { id, first_name: firstName, last_name: lastName } = student;
    const name = `${firstName} ${lastName}`;
    const lastActivity = _get(practiceSummaryByUserId, [id, 'lastActivity'], null);
    const isAssigned = _get(assignedByUserId, id, false);

    return (
      <TableContentItem smallSize key={id}>
        <TableContent isUnitPracticeTable {...ColumnSize[0]}>
          {name}
        </TableContent>

        {assets.map(this.renderStudentScoreItem(id))}

        <TableContent isUnitPracticeTable isMinimize={this.state.isMinimizeColumn} {...ColumnSize[2]}>
          {lastActivity ? moment(lastActivity).fromNow() : '--'}
        </TableContent>
        <TableContent isUnitPracticeTable isMinimize={this.state.isMinimizeColumn} {...ColumnSize[3]}>
          {isAssigned ? (
            <LessonAssignStatus
              isAssigned
            >
              Assigned
              <UnAssignButton className="chalktalk-close" onClick={this.unAssignStudent(student)} />
            </LessonAssignStatus>
          ) : (
            <LessonAssignStatus
              onClick={this.assignStudent(student)}
            >
              Assign
            </LessonAssignStatus>
          )}
        </TableContent>
      </TableContentItem>
    );
  }

  renderStudentListReport = () => {
    const { students = [] } = this.props;
    const { orderBy } = this.state;
    const filterStudentList = _orderBy(students, this.customOrderBy, ORDER_BY[orderBy]);

    return (
      <UnitPracticeTableWrap>
        <UnitPracticeTitle>
          <h1>Activity Detail</h1>
        </UnitPracticeTitle>
        <UnitPracticeTableSubTitle>
          Students scores for each passage type. Click on a score to review per session results.
        </UnitPracticeTableSubTitle>

        <TableResponsiveContainer>
          <TableContentResponsive>
            {this.renderStudentListHeader()}
            {/* <MinimizeBtnWrapper>
              <MinimizeBtn isTableBtn isColumnMinimize={this.state.isMinimizeColumn} isMinimize={this.state.isMinimizeColumn} onClick={this.handleMinimizeTableColumn}>
                <i className="chalktalk-icon-bold-right" />
              </MinimizeBtn>
            </MinimizeBtnWrapper> */}
            {filterStudentList.map(this.renderStudentItem)}
          </TableContentResponsive>
        </TableResponsiveContainer>
      </UnitPracticeTableWrap>
    );
  }

  render() {
    const {
      isGettingSectionUnit,
      isGettingUserPracticeSessionActivities,
      isGetSectionPractice,
    } = this.props;

    if (isGettingSectionUnit || isGettingUserPracticeSessionActivities || isGetSectionPractice) {
      return (
        <LoadingIndicator content="Loading" />
      );
    }
    return (
      <ShadowScrollbar
        autoHide
        style={scrollStyle}
      >
        <DefaultContentWrap>
          <UnitPracticeWrap isTeacher>
            {this.renderUnitPracticeBlock()}

            {this.renderStudentListReport()}
          </UnitPracticeWrap>
        </DefaultContentWrap>
      </ShadowScrollbar>
    );
  }
}

UnitPracticeTeacherDetail.propTypes = {
  practiceSummaryByUserId: PropTypes.object,
  lessons: PropTypes.array,
  students: PropTypes.array,
  match: PropTypes.objectOf(PropTypes.any),
  userRole: PropTypes.string,
  history: PropTypes.shape(),
  practiceId: PropTypes.number,
  isInitialized: PropTypes.bool,
  assignLesson: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  getAllPracticeSessionActivities: PropTypes.func.isRequired,
  userPracticeActivities: PropTypes.array,
  isGettingSectionUnit: PropTypes.bool,
  isGetSectionPractice: PropTypes.bool,
  isGettingUserPracticeSessionActivities: PropTypes.bool,
  unAssignLesson: PropTypes.func.isRequired,
  lessonAssignmentDetail: PropTypes.func,
  unitDetail: PropTypes.object,
  practiceReport: PropTypes.object,
};

export default UnitPracticeTeacherDetail;
