import axios from 'axios';
import _isNil from 'lodash/isNil';
import { handleError } from './utils';

// craft payload for update section session api
function updateSectionSessionSerializer(sessionData) {
  return sessionData;
}

const inviteStudentSerializer = (studentList) =>
  studentList.map((studentValue) => ({
    first_name: studentValue.firstName,
    last_name: studentValue.lastName,
    email: studentValue.email,
    grade_level: studentValue.gradeLevel,
  }));

function sectionAttendanceSerializer(sectionAttendanceInfo) {
  return sectionAttendanceInfo;
}

// api calls

// section students

export function getSectionStudent(sectionId) {
  const url = `/api/sections/${sectionId}/students/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function removeStudentFromSection(sectionId, studentId) {
  const url = `/api/sections/${sectionId}/students/${studentId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSectionSettings(sectionId, payload) {
  const url = `/api/sections/${sectionId}/update_sittings/`;
  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function inviteStudent(studentValue, sectionId) {
  const url = `/api/sections/${sectionId}/invite-students/`;
  return axios
    .post(url, inviteStudentSerializer(studentValue))
    .then((response) => ({ response }))
    .catch(handleError);
}

// section basic API

export function updateSection(sectionId, sectionValues) {
  const url = `/api/sections/${sectionId}/`;
  return axios
    .put(url, sectionValues)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionDetail(sectionId) {
  const url = `/api/sections/${sectionId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// teacher section APIs

export function getSectionTeacher(sectionId) {
  const url = `/api/sections/${sectionId}/teachers/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function removeTeacherFromSection(sectionId, teacherID) {
  const url = `/api/sections/${sectionId}/teachers/${teacherID}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function addTeacherToSection(sectionId, teacherID) {
  const url = `/api/sections/${sectionId}/teachers/${teacherID}/`;
  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// section misc API

export function requestSectionEnroll(sectionCode) {
  const url = '/api/sections/enroll/';
  return axios
    .post(url, {
      section_code: sectionCode,
    })
    .then((response) => ({ response }))
    .catch(handleError);
}
// for left side bar
export function getSectionUnit(sectionId, subjectId) {
  let url = `/api/sections/${sectionId}/units/`;
  if (subjectId) {
    url += `?subject_id=${subjectId}`;
  }

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionUnitMeta(sectionId, subjectId) {
  let url = `/api/sections/${sectionId}/units/meta`;
  if (subjectId) {
    url += `?subject_id=${subjectId}`;
  }

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// for schedule cards
export function getSectionSchedule(sectionId) {
  const url = `/api/sections/${sectionId}/schedule/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionExam(sectionId) {
  const url = `/api/sections/${sectionId}/exams/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// section session api

export function getSectionSessionDetail(sectionId, sessionId) {
  const url = `/api/sections/${sectionId}/session/${sessionId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSectionSession(sectionId, sessionId, sessionData) {
  const url = `/api/sections/${sectionId}/session/${sessionId}/`;
  return axios
    .put(url, updateSectionSessionSerializer(sessionData))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionLessonSessions(sectionId, subjectId) {
  let url = `/api/sections/${sectionId}/sessions/`;
  if (subjectId) {
    url += `?subject_id=${subjectId}`;
  }

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// section review api

export function getSectionReviewDetail(sectionId, reviewId) {
  const url = `/api/sections/${sectionId}/review/${reviewId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function deactiveSections(sectionId) {
  const url = `/api/sections/${sectionId}/deactivate/`;
  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionReviewSessions(sectionId, subjectId) {
  let url = `/api/sections/${sectionId}/reviews/`;
  if (subjectId) {
    url += `?subject_id=${subjectId}`;
  }

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getLessonSessionActivity(sectionId, lessonId) {
  const url = `/api/sections/${sectionId}/activity/lesson/${lessonId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function postLessonSessionActivity(sectionId, body) {
  const url = `/api/sections/${sectionId}/activity/lesson/`;
  return axios
    .post(url, body)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function userSelectionAllLessonActivity(sectionId) {
  const url = `/api/sections/${sectionId}/activity/lesson/me/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function assignLesson(sectionId, payload) {
  const url = `/api/sections/${sectionId}/activity/assign/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function unAssignLesson(sectionId, payload) {
  const url = `/api/sections/${sectionId}/activity/unassign/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function assignLessonBulk(sectionId, payload) {
  const url = `/api/sections/${sectionId}/activity/assign/bulk/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function lessonAssignmentDetail(sectionId, lessonId = null, unitId = null) {
  const url = `/api/sections/${sectionId}/activity/assigned/`;
  const params = {};

  if (lessonId) {
    params.lesson = lessonId;
  }

  if (unitId) {
    params.unit = unitId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSessionPracticeSessionActivity(
  sectionId,
  unitId,
  lessonId,
  studentId,
  reviewId,
) {
  const url = `api/sections/${sectionId}/activity/practice/`;
  const params = {};
  if (!_isNil(unitId)) {
    params.unit = unitId;
  }
  if (!_isNil(lessonId)) {
    params.lesson = lessonId;
  }

  if (!_isNil(studentId)) {
    params.student = studentId;
  }

  if (!_isNil(reviewId)) {
    params.review = reviewId;
  }
  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getPracticeSessionReport(sectionId, practiceId) {
  const url = `api/section/${sectionId}/activity/practice/${practiceId}/report/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSessionPracticeSessionResponse(sectionId, unitId, lessonId) {
  let url = `/api/sections/${sectionId}/activity/practice/report/?unit=${unitId}`;
  if (lessonId) {
    url += `&lesson=${lessonId}`;
  }

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getUserPracticeSessionActivity(sectionId, unitId, lessonId, reviewId) {
  let url = `api/sections/${sectionId}/activity/practice/me/`;
  if (unitId) {
    url += `?unit=${unitId}`;
  }
  if (lessonId) {
    url += `&lesson=${lessonId}`;
  }
  if (reviewId) {
    url += `&review=${reviewId}`;
  }
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionReviewSessionData(sectionId, reviewId) {
  // for teacher
  const url = `/api/sections/${sectionId}/activity/review/${reviewId}/data/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getStudentReviewSessionData(reviewId) {
  // for student
  const url = `/api/sessions/review/${reviewId}/data/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function deleteReviewSessionData(reviewId) {
  // for Delete Reviews
  const url = `/api/sessions/review/${reviewId}/`;
  return axios
    .delete(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

// for Update Reviews
export function updateReviewSessionData(reviewId, payload) {
  const url = `/api/sessions/review/${reviewId}/`;
  return axios
    .put(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function createReviewSessionData(review) {
  // for create Reviews
  const url = '/api/sessions/review/';
  return axios
    .post(url, review)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionAttendance(sectionId, fromDate, toDate) {
  const url = `/api/curriculum/lesson/attendance/?section=${sectionId}`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionEstimatedScores(sectionId) {
  const url = `/api/sections/${sectionId}/estimatedscores/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionStudentEstimatedScores(sectionId, studentId) {
  const url = `/api/sections/${sectionId}/estimatedscores/${studentId}/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionExamsScores(sectionId) {
  const url = `/api/sections/${sectionId}/exams/scores/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
// for skills
export function getSectionSkills(sectionId, lessonId = null, studentId = null) {
  const url = `/api/sections/${sectionId}/skills/`;
  const params = {};

  if (lessonId !== null) {
    params.lesson = lessonId;
  }

  if (studentId !== null) {
    params.student = studentId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}
// for student skills
export function getSectionSkillsUser(sectionId, lessonId = null) {
  const url = `/api/sections/${sectionId}/skills/me/`;
  const params = {};

  if (lessonId !== null) {
    params.lesson = lessonId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionPracticeSumary(sectionId, unitId, lessonId, subjectId) {
  const url = `/api/sections/${sectionId}/activity/practice/summary/`;
  const params = {};
  if (!_isNil(unitId)) {
    params.unit = unitId;
  }

  if (!_isNil(lessonId)) {
    params.lesson = lessonId;
  }

  if (!_isNil(subjectId)) {
    params.subject = subjectId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getUserSectionPracticeSumary(sectionId, unitId, lessonId, subjectId) {
  const url = `/api/sections/${sectionId}/activity/practice/summary/me/`;
  const params = {};
  if (!_isNil(unitId)) {
    params.unit = unitId;
  }

  if (!_isNil(lessonId)) {
    params.lesson = lessonId;
  }

  if (!_isNil(subjectId)) {
    params.subject = subjectId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionAttendanceSummary(sectionId) {
  const url = `/api/sections/${sectionId}/attendance/summary/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionSubjectList(sectionId) {
  const url = `/api/sections/${sectionId}/subjects/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getUserAssignedActivity(sectionId, lessonId, unitId = null) {
  const url = `/api/sections/${sectionId}/activity/assigned/me/`;
  const params = {};

  if (lessonId) {
    params.lesson = lessonId;
  }

  if (unitId) {
    params.unit = unitId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionLessonActivitySummary(
  sectionId,
  subjectId,
  unitId,
  lessonId,
  slideId,
) {
  const url = `/api/sections/${sectionId}/activity/lesson/summary/me/`;
  const params = {};
  if (subjectId) {
    params.subject = subjectId;
  }
  if (unitId) {
    params.unit = unitId;
  }
  if (lessonId) {
    params.lesson = lessonId;
  }
  if (slideId) {
    params.slide = slideId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getTeacherLessonActivitySummary(
  sectionId,
  subjectId,
  unitId,
  lessonId,
  slideId,
) {
  const url = `/api/sections/${sectionId}/activity/lesson/summary/`;
  const params = {};

  if (subjectId) {
    params.subject = subjectId;
  }

  if (unitId) {
    params.unit = unitId;
  }

  if (lessonId) {
    params.lesson = lessonId;
  }

  if (slideId) {
    params.slide = slideId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionReviewSectionSummary(sectionId, subjectId) {
  const url = `/api/sections/${sectionId}/activity/review/summary/`;
  const params = {};
  if (subjectId) {
    params.subject = subjectId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getStudentSectionReviewSectionSummary(sectionId, subjectId) {
  const url = `/api/sections/${sectionId}/activity/review/summary/me/`;
  const params = {};
  if (subjectId) {
    params.subject = subjectId;
  }

  return axios
    .get(url, { params })
    .then((response) => ({ response }))
    .catch(handleError);
}

export function submitSectionAttendance(sectionId, sectionAttendanceInfo) {
  const url = `/api/sections/${sectionId}/attendance/`;
  return axios
    .post(url, sectionAttendanceSerializer(sectionAttendanceInfo))
    .then((response) => ({ response }))
    .catch(handleError);
}

export function moveStudentToSection(sectionId, payload) {
  const url = `/api/sections/${sectionId}/students/move/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSectionsComplete(sectionId, payload) {
  const url = `/api/sections/${sectionId}/session/complete/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSectionsUnComplete(sectionId, payload) {
  const url = `/api/sections/${sectionId}/session/uncomplete/`;

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function editLessonData(payload) {
  const url = `/api/sessions/standard/${payload.section}/`;

  return axios
    .put(url, payload)
    .then((response) => {})
    .catch(handleError);
}

export function addLessonData(sectionId, payload) {
  const url = '/api/sessions/standard/';

  return axios
    .post(url, payload)
    .then((response) => {})
    .catch(handleError);
}

export function deleteLessonData(sectionId, payload) {
  const url = '/api/sessions/standard/';

  return axios
    .post(url, payload)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getSectionReportDownload(section) {
  const url = `/api/sections/${section.id}/report/download/`;

  return axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `ChalkTalk_Practice_Details_${section.name}.xlsx`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(downloadUrl);
  });
}

export function getAvailablePackages(sectionId) {
  const url = `/api/sections/${sectionId}/packages/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getStandardScore(sectionId) {
  const url = `/api/sections/${sectionId}/standardscore/`;

  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export const getSectionLessonSkillLevels = (sectionId, lessonGroupId) => {
  const url = `/api/sections/${sectionId}/skills/lesson/${lessonGroupId}/`;

  return axios.get(url).then((response) => ({ response }));
};

export const getSectionSkillLevelReport = (sectionId, skillIds) => {
  const url = `/api/skills/practice_summary/section/${sectionId}/`;

  return axios
    .get(url, { params: { skill_id: [skillIds] } })
    .then((response) => ({ response }));
};
