import styled from 'styled-components';

export const ReportGenoratorMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0.6px;
  text-align: center;
  height: 100%;
  overflow-y: scroll;
  padding-top: 50px;
  justify-content: flex-start;
`;

export const ReportGenoratorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
  font: normal normal normal 12px/16px Roboto;
  letter-spacing: 0.6px;
  text-align: center;
`;

export const ReportGenoratorHeader = styled.div`
  display: flex;
  align-items: center;
  h1 {
    font: normal normal 500 22px Montserrat;
    letter-spacing: 0px;
    color: #f6a527;
  }
  span.beta {
    background: #8d8d8d 0% 0% no-repeat padding-box;
    border-radius: 18px;
    text-align: left;
    font: normal normal normal 9px/12px Roboto;
    letter-spacing: 0.45px;
    color: #ffffff;
    opacity: 1;
    margin-left: 10px;
    padding: 4px 7px;
  }
`;

export const ReportGenoratorImg = styled.img`
  width: 326px;
  height: 255px;
`;

export const PastReportsContainer = styled.div`
  width: 80%;
  text-align: left;
  .MuiPagination-root {
    display: flex;
    justify-content: center;
  }
  .report-list {
    padding: 10px;
    li {
      margin-bottom: 15px;
      a {
        text-decoration: underline;
        color: #4786fc;
        cursor: pointer;
        text-align: left;
      }
    }
  }
`;

export const ReportLink = styled.div`
    a {
        display: flex;
        align-items: center;
        color: #4786FC;
        cursor: pointer;
        text-align: left;
        text-align: left;
        margin: 20px;
        gap: 10px;
        text-decoration: underline;
    }
}
`;
