// action definition
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import _omit from 'lodash/omit';
import { createSelector } from 'reselect';

export const COURSE_TEACHERS_GET_LIST = 'courseTeachers/COURSES_GET_LIST';
export const COURSE_TEACHERS_GET_LIST_SUCCESS = 'courseTeachers/COURSES_GET_LIST_SUCCESS';
export const COURSE_TEACHERS_GET_LIST_FAIL = 'courseTeachers/COURSES_GET_LIST_FAIL';

export const COURSE_TEACHERS_REMOVE = 'courseTeachers/REMOVE';
export const COURSE_TEACHERS_REMOVE_SUCCESS = 'courseTeachers/REMOVE_SUCCESS';
export const COURSE_TEACHERS_REMOVE_FAIL = 'courseTeachers/REMOVE_FAIL';

export const COURSE_TEACHERS_ADD_OWNER = 'courseTeachers/ADD_OWNER';
export const COURSE_TEACHERS_ADD_OWNER_SUCCESS = 'courseTeachers/ADD_OWNER_SUCCESS';
export const COURSE_TEACHERS_ADD_OWNER_FAIL = 'courseTeachers/ADD_OWNER_FAIL';

export const COURSE_TEACHERS_REMOVE_OWNER = 'courseTeachers/REMOVE_OWNER';
export const COURSE_TEACHERS_REMOVE_OWNER_SUCCESS = 'courseTeachers/REMOVE_OWNER_SUCCESS';
export const COURSE_TEACHERS_REMOVE_OWNER_FAIL = 'courseTeachers/REMOVE_OWNER_FAIL';

export const COURSE_TEACHERS_GET_OWNER = 'courseTeachers/GET_OWNER';
export const COURSE_TEACHERS_GET_OWNER_SUCCESS = 'courseTeachers/GET_OWNER_SUCCESS';
export const COURSE_TEACHERS_GET_OWNER_FAIL = 'courseTeachers/GET_OWNER_FAIL';

export const COURSE_TEACHERS_INVITE = 'courseTeachers/INVITE';
export const COURSE_TEACHERS_INVITE_SUCCESS = 'courseTeachers/INVITE_SUCCESS';
export const COURSE_TEACHERS_INVITE_FAIL = 'courseTeachers/INVITE_FAIL';

// action creators

// invite teacher

const courseTeacherInvite = (courseId, teacherInfo, callback) => ({
  type: COURSE_TEACHERS_INVITE,
  courseId,
  teacherInfo,
  callback,
});

const courseTeacherInviteSuccess = (teacherList) => ({
  type: COURSE_TEACHERS_INVITE_SUCCESS,
  teacherList,
});

const courseTeacherInviteFail = (error) => ({
  type: COURSE_TEACHERS_INVITE_FAIL,
  error,
});

// get course owner

const courseTeacherGetOwner = (courseId) => ({
  type: COURSE_TEACHERS_GET_OWNER,
  courseId,
});

const courseTeacherGetOwnerSuccess = (teacherList) => ({
  type: COURSE_TEACHERS_GET_OWNER_SUCCESS,
  teacherList,
});

const courseTeacherGetOwnerFail = (errorMsg) => ({
  type: COURSE_TEACHERS_GET_OWNER_FAIL,
  errorMsg,
});

// remove owner

const courseTeacherRemoveOwner = (courseId, teacherId) => ({
  type: COURSE_TEACHERS_REMOVE_OWNER,
  courseId,
  teacherId,
});

const courseTeacherRemoveOwnerSuccess = (teacherId) => ({
  type: COURSE_TEACHERS_REMOVE_OWNER_SUCCESS,
  teacherId,
});

const courseTeacherRemoveOwnerFail = (errorMsg) => ({
  type: COURSE_TEACHERS_REMOVE_OWNER_FAIL,
  errorMsg,
});

// add owner

const courseTeacherAddOwner = (courseId, teacherId) => ({
  type: COURSE_TEACHERS_ADD_OWNER,
  courseId,
  teacherId,
});

const courseTeacherAddOwnerSuccess = (teacherId) => ({
  type: COURSE_TEACHERS_ADD_OWNER_SUCCESS,
  teacherId,
});

const courseTeacherAddOwnerFail = (errorMsg) => ({
  type: COURSE_TEACHERS_ADD_OWNER_FAIL,
  errorMsg,
});

// remove teacher

const courseTeacherRemove = (teacherId, courseId, teacherName) => ({
  type: COURSE_TEACHERS_REMOVE,
  courseId,
  teacherId,
  teacherName,
});

const courseTeacherRemoveSuccess = (teacherId) => ({
  type: COURSE_TEACHERS_REMOVE_SUCCESS,
  teacherId,
});

const courseTeacherRemoveFail = (errorMsg) => ({
  type: COURSE_TEACHERS_REMOVE_FAIL,
  errorMsg,
});

function fetchCourseTeacherList(courseId) {
  return {
    type: COURSE_TEACHERS_GET_LIST,
    courseId,
  };
}

export const fetchCourseTeacherListSuccess = (teacherList) => ({
  type: COURSE_TEACHERS_GET_LIST_SUCCESS,
  teacherList,
});

export const fetchCourseTeacherListFail = (errorMessage) => ({
  type: COURSE_TEACHERS_GET_LIST_FAIL,
  errorMessage,
});

export const actions = {
  fetchCourseTeacherList,
  fetchCourseTeacherListSuccess,
  fetchCourseTeacherListFail,

  courseTeacherRemove,
  courseTeacherRemoveSuccess,
  courseTeacherRemoveFail,

  courseTeacherAddOwner,
  courseTeacherAddOwnerSuccess,
  courseTeacherAddOwnerFail,

  courseTeacherRemoveOwner,
  courseTeacherRemoveOwnerSuccess,
  courseTeacherRemoveOwnerFail,

  courseTeacherGetOwner,
  courseTeacherGetOwnerSuccess,
  courseTeacherGetOwnerFail,

  courseTeacherInvite,
  courseTeacherInviteSuccess,
  courseTeacherInviteFail,
};

// selectors

const getTeachersIds = ({ courseTeachers }) => _get(courseTeachers, 'teachers.Ids', []);

const getTeachersByIds = ({ courseTeachers }) => _get(courseTeachers, 'teachers.byIds', {});

const getCourseTeacherList = createSelector(getTeachersIds, getTeachersByIds, (ids, byIds) => ids.map((id) => byIds[id]));

const getOwnerIds = ({ courseTeachers }) => _get(courseTeachers, 'ownerIds', []);

const isGettingTeacherList = ({ courseTeachers }) => courseTeachers.isFetchingTeacherList;

export const selectors = {
  getCourseTeacherList,
  getOwnerIds,

  isGettingTeacherList,
};

// reducers

const initialState = {
  teachers: {},
  isFetchingTeacherList: false,
  errorMessage: '',

  isRemoving: false,
  removeErrorMsg: '',

  isGetOwner: false,
  getOwnerErrorMsg: '',
  ownerIds: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    // fetch course
    case COURSE_TEACHERS_GET_LIST:
      return {
        ...state,
        isFetchingTeacherList: true,
        errorMessage: '',
      };

    case COURSE_TEACHERS_GET_LIST_SUCCESS: {
      const { teacherList = [] } = action;
      const Ids = teacherList.map(({ id }) => id);
      const byIds = _keyBy(teacherList, 'id');

      return {
        ...state,
        teachers: {
          Ids,
          byIds,
        },
        isFetchingTeacherList: false,
      };
    }

    case COURSE_TEACHERS_GET_LIST_FAIL: {
      const errorMessage = action;

      return {
        ...state,
        isFetchingTeacherList: false,
        errorMessage,
      };
    }

    case COURSE_TEACHERS_REMOVE:
      return { ...state, isRemoving: true, removeErrorMsg: '' };
    case COURSE_TEACHERS_REMOVE_SUCCESS: {
      const currentIds = _get(state, 'teachers.Ids', []);
      const currentByIds = _get(state, 'teachers.byIds', {});

      const newIds = _filter(currentIds, (id) => id !== action.teacherId);
      const newByIds = _omit(currentByIds, action.teacherId);

      return {
        ...state,
        isRemoving: false,
        removeErrorMsg: '',
        teachers: {
          Ids: newIds,
          byIds: newByIds,
        },
      };
    }
    case COURSE_TEACHERS_REMOVE_FAIL:
      return { ...state, isRemoving: false, removeErrorMsg: action.errorMsg };

    case COURSE_TEACHERS_GET_OWNER:
      return { ...state, isGetOwner: true, getOwnerErrorMsg: '' };
    case COURSE_TEACHERS_GET_OWNER_SUCCESS: {
      const { teacherList } = action;
      const ownerIds = teacherList.map(({ id }) => id);

      return {
        ...state,
        isGetOwner: false,
        getOwnerErrorMsg: '',
        ownerIds,
      };
    }
    case COURSE_TEACHERS_GET_OWNER_FAIL:
      return { ...state, isGetOwner: false, getOwnerErrorMsg: action.errorMsg };

    case COURSE_TEACHERS_REMOVE_OWNER_SUCCESS: {
      const { teacherId } = action;
      const newOwnerIds = _filter(state.ownerIds, (id) => id !== teacherId);
      return { ...state, ownerIds: newOwnerIds };
    }

    case COURSE_TEACHERS_ADD_OWNER_SUCCESS: {
      const { teacherId } = action;
      const newOwnerIds = [...state.ownerIds, teacherId];
      return {
        ...state,
        ownerIds: newOwnerIds,
      };
    }
    case COURSE_TEACHERS_INVITE:
      return {
        ...state,
        isInvite: true,
      };
    case COURSE_TEACHERS_INVITE_SUCCESS: {
      const { teacherList = [] } = action;
      const Ids = teacherList.map(({ id }) => id);
      const byIds = _keyBy(teacherList, 'id');

      return {
        ...state,
        teachers: {
          Ids,
          byIds,
        },
        isInvite: false,
      };
    }
    case COURSE_TEACHERS_INVITE_FAIL:
      return {
        ...state,
        isInvite: false,
      };

    default: {
      return state;
    }
  }
}
