import React from 'react';
import * as Sentry from '@sentry/react';
import { FallbackComponentContainer } from './ChalkTalkErrorBoundary.style';
import { Button } from '../Generals/stylesheets/Button.style';

/**
 * Returns a fallback UI to render when an error happens. This accepts an optional function
 * that will be called when the 'Take me back' button is called. Otherwise, the default
 * behavior is to reload the page.
 *
 * @param {Function} reset a function to customize the handling of 'Take me Back' button
 */
const fallback = (reset) => {
  const fallbackUI = ({ eventId, resetError }) => (
    <FallbackComponentContainer>
      <img src="/images/500_error.svg" alt="Depicting a confused piece of paper" />
      <h1>Something went wrong</h1>
      <h3>Event ID: {eventId}</h3>
      <p>
        If you continue to experience issues, please send us an email at{' '}
        <a href="mailto:support@chalktalk.com">support@chalktalk.com</a> and we&apos;ll be
        sure to sort it out.
      </p>
      <Button
        onClick={() => {
          if (typeof reset === 'function') {
            reset(resetError);
          } else {
            window.location.reload();
          }
        }}
        blue
      >
        Take me back
      </Button>
    </FallbackComponentContainer>
  );

  return fallbackUI;
};

/**
 * Error boundary to use with composition API. To enrich the error report, provide a
 * beforeCapture prop that accepts a scope object to add taga and context.
 * More info can be found at https://www.notion.so/chalktalk/Frontend-Error-Boundaries-443dab730a5c43f9b66d235e4ca98c06?pvs=4
 * and Sentry.ErrorBoundaryProps.beforeCapture
 * Example usage:
 * <ChalkTalkErrorBoundary>
 *  <YourComponent/>
 * </ChalkTalkErrorBoundary>
 */
const ChalkTalkErrorBoundary = ({ children, reset = null, beforeCapture = () => {} }) => (
  <Sentry.ErrorBoundary fallback={fallback(reset)} beforeCapture={beforeCapture}>
    {children}
  </Sentry.ErrorBoundary>
);

/**
 * Use an HOC to wrap a component with error boundary. The second parameter are options for creating
 * the error boundary:
 *   beforeCapture: a callback that a scope object to add tags and context. More info can be found at
 * https://www.notion.so/chalktalk/Frontend-Error-Boundaries-443dab730a5c43f9b66d235e4ca98c06?pvs=4
 *   reset: a function to customize the behavior when resetting the error.
 * Example usage:
 *  withErrorBoundary(YourComponent)
 * @param {React.Component} Component component to wrap with error boundary
 * @return the wrapped component
 */
export const withChalkTalkErrorBoundary = (Component, { beforeCapture, reset } = {}) => {
  const wrapped = (props) => (
    <Sentry.ErrorBoundary fallback={fallback(reset)} beforeCapture={beforeCapture}>
      <Component {...props} />
    </Sentry.ErrorBoundary>
  );

  const name = Component.displayName || Component.name || 'Component';
  wrapped.displayName = `withChalkTalkErrorBoundary(${name})`;

  return wrapped;
};

export default ChalkTalkErrorBoundary;
