import React from 'react';
import {
    NavBarHeadText,
    NavBarItemCustom,
    NavBarItemDateWrap,
    NavBarItemDay,
    NavBarItemHead,
    NavBarItemMonth,
    NavBarItemWrap,
    NavBarList,
    NavBarName,
    ExamToolTip,
} from '@components/SubNavBarItem/SubNavBarV2.style';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { SECTION_STATUS } from '../../utils/enums';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';
import { ExamItem } from '../../components/DashboardExamScoreReport/hooks';

type TestSubNavItemV2Props = {
    examItem: ExamItem,
    previousExam: ExamItem,
};

const useNavigateToExamPage = () => {
    const history = useHistory();
    const { courseId = '', sectionId = '' } = useParams();

    return React.useCallback(
        (examId: string | number) => {
            history.push(`/test/course/${courseId}/section/${sectionId}/exam/${examId}`);
        },
        [courseId, history, sectionId],
    );
};

const renderTestDate = (examItem) => {
  const { start_date: startDate, end_date: endDate } = examItem;
  const firstDate = moment(startDate, 'YYYY-MM-DD');
  const secondDate = moment(endDate, 'YYYY-MM-DD');
  const day =
    startDate === endDate
      ? firstDate.format('DD')
      : `${firstDate.format('DD')} - ${secondDate.format('DD')}`;
  const month =
    firstDate.format('MMM') === secondDate.format('MMM')
      ? firstDate.format('MMM')
      : `${firstDate.format('MMM')} - ${secondDate.format('MMM')}`;
  const year = firstDate.format('YYYY');
  return (
    <NavBarItemDateWrap>
      <NavBarItemMonth>{month}</NavBarItemMonth>
      <NavBarItemDay wide>{day}</NavBarItemDay>
      <NavBarItemMonth>{year}</NavBarItemMonth>
    </NavBarItemDateWrap>
  );
};

const TestSubNavItemV2 = ({ examItem, previousExam }: TestSubNavItemV2Props) => {
    const {  examSessionId }= useParams()
    const {
        id,
        session_metadata: sessionMetadata,
        practice_test_order: practiceTestOrder,
    } = examItem;
    const title = `Practice Test ${practiceTestOrder + 1}`
    const examSections = sessionMetadata.sections || [];
    const sectionsCount = examSections.length;
    const completedSections = examSections.filter(section => section.is_complete === true);
    const completedSectionsCount = completedSections.length;
    const testletsStatus = `${completedSectionsCount}/${sectionsCount} Testlets Completed`;
    const previousExamSections = previousExam?.session_metadata?.sections || [];
    const previousExamCompleted = previousExamSections.filter(section => section.is_complete === true);
    const cantClick = !_isEmpty(previousExam) && previousExamCompleted.length === 0
    const navigateToExamPage = useNavigateToExamPage();

    return (
        <NavBarList key={id.toString()}>
            <ExamToolTip arrow title='Please complete the testlets from the previous session to unlock this one.' placement="bottom" disableHoverListener={!cantClick}>
                <NavBarItemWrap data-testid="test-nav-bar-item" onClick={cantClick ? undefined : () => navigateToExamPage(id)} isActive={examItem.id == examSessionId} cantClick={cantClick}>
                    <NavBarItemCustom>
                        <NavBarItemHead>
                            <NavBarHeadText>{cantClick ? 'Locked' : testletsStatus}</NavBarHeadText>
                        </NavBarItemHead>
                        <NavBarName>{title}</NavBarName>
                    </NavBarItemCustom>
                    {renderTestDate(examItem)}
                </NavBarItemWrap>
            </ExamToolTip>
        </NavBarList>
    );
};

export default TestSubNavItemV2;
