import { connect } from 'react-redux';
import StudentRegister from '../../components/StudentRegister/StudentRegister';
import { selectors as AuthSelectors } from '../../reducers/auth';

const mapStateToProps = state => ({
  isLoading: AuthSelectors.getIsLoggingIn(state),
  courseId: null, // getCourseId(state),
});

export default connect(mapStateToProps)(StudentRegister);
