import React from 'react';
import Box from '@components/Atoms/Box';
import { error as errorToast } from '@utils/toast';
import { Divider } from '@mui/material';
import { useSubmitLessonAttendance } from '../../hooks/mutations';
import {
  generateAttendanceRecords,
  useActiveAttendanceActivity,
  useMarkLessonAsCompleted,
} from '../../hooks/helpers';
import { cardHeaderContentStyle } from '../AttendanceCardHeader.style';
import AttendanceActions from './AttendanceActions';

interface CardHeaderProps {
  studentsList: any[];
  onLessonChange?: (lessonId: string | number, status) => void;
}

const CardHeader = ({ studentsList, onLessonChange }: CardHeaderProps) => {
  const activity = useActiveAttendanceActivity();
  const { mutate, isLoading } = useSubmitLessonAttendance();
  const { markLessonAsCompleted } = useMarkLessonAsCompleted({
    onLessonChange,
  });

  const handleAttendanceToggle = (status) => {
    const records = generateAttendanceRecords(studentsList, status);

    mutate(
      { activity, records },
      {
        onSuccess: () => {
          markLessonAsCompleted();
        },
        onError: (error) => {
          errorToast('Failed to mark all students');
          console.error('Failed to mark all students. Error:', error);
        },
      },
    );
  };

  return (
    <Box sx={cardHeaderContentStyle}>
      <AttendanceActions
        isLoading={isLoading}
        onToggleAttendance={handleAttendanceToggle}
      />
      <Divider
        sx={{
          margin: '0.75rem 0 0.5rem 0',
          backgroundColor: '#000',
          opacity: 0.1,
        }}
      />
    </Box>
  );
};

export default CardHeader;
