/* eslint-disable import/prefer-default-export */
import { useMutation, useIsMutating } from 'react-query';
import { getSectionReportDownload } from '@apis/sections';
import { toast } from 'react-toastify';

export const useDownloadSectionReport = ({ sectionDetail }) => {
  // Check if the skill report is currently being fetched or not
  const isMutatingSkillReport: number = useIsMutating([
    'getSectionReportDownload',
    sectionDetail,
  ]);

  const mutation = useMutation(() => getSectionReportDownload(sectionDetail), {
    mutationKey: ['getSectionReportDownload', sectionDetail],
    onSuccess: () => {
      toast.success('Report downloaded successfully');
    },
    onError: (e) => {
      console.error(e);
      toast.error(
        'Your report is not currently available. Please reach out to Customer Support.',
      );
    },
  });

  return { mutation, isLoading: isMutatingSkillReport > 0 };
};
