/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const StyledIframe = styled.iframe.attrs({
  allowFullScreen: true,
})`
  width: 100%;
  background-color: #dfe8ee;
  height: 756px;

  ${({ isStudyHall }) =>
    isStudyHall &&
    css`
      @media (max-width: 768px) {
        height: calc(100% - 2em);
        width: 100%;
        position: absolute;
      }
    `}

  @media (min-width: 768px) {
    height: 60vh;
  }

  ${({ isReport }) =>
  isReport &&
    css`
        height: 87vh!important;
        width: 90%;      
    `}

  ${(props) =>
    props.isFullScreen &&
    css`
      position: fixed !important;
      top: 0px !important;
      left: 0px !important;
      height: 756px !important;
      z-index: 10;
      width: 100% !important;
      transform: scale(1) translateY(calc(100vh - 756px));
    `}
`;

export const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  & > button {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  ${({ isReport }) =>
  isReport &&
    css`
        display: flex;
        justify-content: center;
    `}

`;
