import {
  all,
  call,
  put,
  takeLeading,
  takeLatest,
  takeEvery,
  select,
  delay,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import { push, getLocation } from 'connected-react-router';
import {
  error as ToastError,
  success as ToastSuccess,
} from '../../utils/toast';
import * as AuthAPIs from '../../apis/auth';
import {
  actions as AuthActions,
  selectors as AuthSelectors,
  AUTH_NO_TYPE_LOGIN,
  AUTH_STUDENT_LOGIN,
  AUTH_TEACHER_LOGIN,
  AUTH_CLEVER_LOGIN,
  AUTH_SCHOOLOGY_LOGIN,
  AUTH_VERIFY_EMAIL,
  AUTH_LOGOUT,
  AUTH_VERIFY_STUDENT_INVITE,
  AUTH_STUDENT_SET_PASSWORD,
  AUTH_FIND_EMAIL,
  AUTH_RESET_PASSWORD,
  AUTH_GET_NEW_TOKEN,
  AUTH_REFRESH_TOKEN,
  AUTH_LOGIN_SUCCESS,
  AUTH_CONNECT_CLEVER,
  AUTH_APPROVE_JOIN_COURSE_REQUEST,
  AUTH_CHANGE_EMAIL,
  AUTH_RESEND_VERIFICATION_EMAIL,
  AUTH_VERIFICATION_EMAIL,
  AUTH_CLASSLINK_LOGIN,
  AUTH_OPEN_WEBSOCKET,
  AUTH_CONNECT_CLASSLINK,
  AUTH_CONNECT_SCHOOLOGY,
} from '../../reducers/auth';
import { selectors as UserSelectors, actions as UserActions } from '../../reducers/user';
import { configToken } from '../../apis/config';
import { getExpiredTimeOfToken } from '../../utils/func-utils';
import { ROLE_TYPE } from '../../utils/enums';

function* handleNoTypeLogin({ payload }) {
  try {
    const { email, password } = payload;
    const { response, error } = yield call(
      AuthAPIs.noTypeLogin,
      email,
      password,
    );

    if (error) throw error;

    const { data } = response;
    const { user, tokens, is_verified } = data;
    const isEmailConfirmed = true;
    const { access: token, refresh: refreshToken } = tokens;

    configToken(token);
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));
  } catch (error) {
    yield put(AuthActions.loginFailed(error));
  }
}

function* handleStudentLogin({ payload }) {
  try {
    const { email, password } = payload;
    const { response, error } = yield call(AuthAPIs.getStudentToken, email, password);

    if (error) throw error;

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;

    configToken(token);
    yield put(AuthActions.loginSuccess(token, refreshToken, user));
  } catch (error) {
    yield put(AuthActions.loginFailed(error));
  }
}

function* handleTeacherLogin({ payload }) {
  try {
    const { email, password } = payload;
    const { response, error } = yield call(
      AuthAPIs.getTeacherToken,
      email,
      password,
    );

    if (error) throw error;

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;

    configToken(token);
    yield put(AuthActions.loginSuccess(token, refreshToken, user));
  } catch (error) {
    yield put(AuthActions.loginFailed(error));
  }
}

function* handleCleverLogin({ payload }) {
  const { code } = payload;
  let errorStatus = 200;
  try {
    if (!code) {
      ToastError('Invalid code! Please try again!');
      yield put(push('/login'));
      return;
    }
    const { response, error, errorStatus: resErrorStatus } = yield call(
      AuthAPIs.cleverLogin,
      code,
    );
    errorStatus = resErrorStatus;
    if (error){
      ToastError(`Account not found. You currently don't have an active license. If you're unsure about your licenses status, please contact our support team.`);
      yield put(push('/login'));
      return;
    }

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;
    const isEmailConfirmed = true;

    configToken(token);
    yield put(push('/'));
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));
  } catch (error) {
    if (
      errorStatus === 400
      && Array.isArray(error)
      && (error[0].includes(
        'User is already registered with this e-mail address',
      )
        || error[1].includes(
          'User is already registered with this e-mail address',
        ))
    ) {
      const email = error.find((e) => e.includes('@'));
      ToastError(
        `User is already registered with this e-mail address: ${email}.`,
      );
      // eslint-disable-next-line prefer-destructuring
      const CLIENT_ID = process.env.CLIENT_ID;

      const REDIRECT_URI = `${window.location.origin}/auth/complete/clever/`;
      const CLEVER_LOGIN_URL = `https://clever.com/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;
      yield put(AuthActions.loginRequiredAction(CLEVER_LOGIN_URL, email));
    }
    yield put(AuthActions.loginFailed(error));
    yield put(push('/login'));
  }
}

function* handleSchoologyLogin({ payload }) {
  const { code } = payload;
  let errorStatus = 200;
  try {
    if (!code) {
      ToastError('Invalid code! Please try again!');
      yield put(push('/login'));
      return;
    }
    const {
      response,
      error,
      errorStatus: resErrorStatus,
    } = yield call(AuthAPIs.schoologyLogin, code);
    errorStatus = resErrorStatus;
    if (error){
      ToastError(`Account not found. You currently don't have an active license. If you're unsure about your licenses status, please contact our support team.`);
      yield put(push('/login'));
      return;
    }

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;
    const isEmailConfirmed = true;

    configToken(token);
    yield put(push('/'));
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));
  } catch (error) {
    if (
      errorStatus === 400 &&
      Array.isArray(error) &&
      (error[0].includes('User is already registered with this e-mail address') ||
        error[1].includes('User is already registered with this e-mail address'))
    ) {
      const email = error.find((e) => e.includes('@'));
      ToastError(`User is already registered with this e-mail address: ${email}.`);
      const REDIRECT_URI = `${window.location.origin}/`;
      yield put(AuthActions.loginRequiredAction(REDIRECT_URI, email));
    }
    yield put(AuthActions.loginFailed(error));
    yield put(push('/login'));
  }
}

function* handleClasslinkLogin({ payload }) {
  const { code } = payload;
  let errorStatus = 200;
  console.log(code);
  try {
    if (!code) {
      ToastError('Invalid code! Please try again!');
      yield put(push('/login'));
      return;
    }
    const { response, error, errorStatus: resErrorStatus } = yield call(
      AuthAPIs.classlinkLogin,
      code,
    );
    errorStatus = resErrorStatus;
    if (error) 
    {
      ToastError(`Account not found. You currently don't have an active license. If you're unsure about your licenses status, please contact our support team.`);
      yield put(push('/login'));
      return;
    }

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;
    const isEmailConfirmed = true;

    configToken(token);
    yield put(push('/'));
    yield put(
      AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed),
    );
  } catch (error) {
    if (
      errorStatus === 400
      && Array.isArray(error)
      && (error[0].includes(
        'User is already registered with this e-mail address',
      )
        || error[1].includes(
          'User is already registered with this e-mail address',
        ))
    ) {
      const email = error.find((e) => e.includes('@'));
      ToastError(
        `User is already registered with this e-mail address: ${email}.`,
      );

      // eslint-disable-next-line prefer-destructuring
      const CLASSLINK_CLIENT_ID = process.env.CLASSLINK_CLIENT_ID;

      const CLASSLINK_REDIRECT_URI = `${window.location.origin}/auth/complete/classlink/"`;
      const CLASSLINK_LOGIN_URL = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${CLASSLINK_REDIRECT_URI}&client_id=${CLASSLINK_CLIENT_ID}&response_type=code`;

      yield put(AuthActions.loginRequiredAction(CLASSLINK_LOGIN_URL, email));
    }
    yield put(AuthActions.loginFailed(error));
    yield put(push('/login'));
  }
}

function* handleCleverConnect({ payload }) {
  const { code } = payload;
  try {
    if (!code) {
      ToastError('Invalid code! Please try again!');
      yield put(push('/'));
      return;
    }
    const { response, error } = yield call(AuthAPIs.cleverConnect, code);
    if (error){
      ToastError(`Account not found. You currently don't have an active license. If you're unsure about your licenses status, please contact our support team.`);
      yield put(push('/login'));
      return;
    }
    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;
    const isEmailConfirmed = true;
    configToken(token);
    yield put(push('/'));
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));
  } catch (error) {
    ToastError('Something went wrong when trying to link your accounts');
  }
}

function* handleSchoologyConnect({ payload }) {
  const { code } = payload;
  try {
    if (!code) {
      ToastError('Invalid code! Please try again!');
      yield put(push('/'));
      return;
    }
    const { response, error } = yield call(AuthAPIs.SchoologyConnect, code);
    if (error){
      ToastError(`Account not found. You currently don't have an active license. If you're unsure about your licenses status, please contact our support team.`);
      yield put(push('/login'));
      return;
    }

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;
    const isEmailConfirmed = true;
    configToken(token);
    yield put(push('/'));
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));
  } catch (error) {
    ToastError('Something went wrong when trying to link your accounts');
  }
}

function* handleClasslinkConnect({ payload }) {
  const { code } = payload;
  try {
    if (!code) {
      ToastError('Invalid code! Please try again!');
      yield put(push('/'));
      return;
    }
    const { response, error } = yield call(AuthAPIs.classlinkConnect, code);
    if (error){
      ToastError(`Account not found. You currently don't have an active license. If you're unsure about your licenses status, please contact our support team.`);
      yield put(push('/login'));
      return;
    }

    const { data } = response;
    const { user, tokens } = data;
    const { access: token, refresh: refreshToken } = tokens;
    const isEmailConfirmed = true;
    configToken(token);
    yield put(push('/'));
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));
  } catch (error) {
    ToastError('Something went wrong when trying to link your accounts');
  }
}
// this function for verify email
function* handleVerifyEmail({ payload }) {
  const { token: verifyToken } = payload;
  const isAuthenticated = yield select(AuthSelectors.getAuthenticatedStatus);
  try {
    const { error } = yield call(AuthAPIs.verifyEmail, verifyToken);
    if (error) throw error;

    yield put(AuthActions.verifyEmailSuccess());
    if (isAuthenticated) {
      ToastSuccess('Your account has been verified successfully!');
    }
    yield put(push('/'));
  } catch (error) {
    if (error[0].includes('Not found.')) {
      yield put(
        AuthActions.verifyEmailFailed('Token is invalid or not found!'),
      );
    } else {
      yield put(AuthActions.verifyEmailFailed(error));
    }
    if (isAuthenticated) {
      ToastError('Unable to verify your email! Please try again!');
    }
    yield put(push('/'));
  }
}

function* handleStudentInvitationConfirm({ payload }) {
  const { uid, token } = payload;
  try {
    const { response, error } = yield call(
      AuthAPIs.confirmInviteStudent,
      uid,
      token,
    );
    if (error) throw error;

    const { uid: pid, token: ptoken } = response.data;
    yield put(AuthActions.verifyStudentInvitationSuccess());
    yield put(push(`/password-reset/${pid}/${ptoken}`));
  } catch (error) {
    yield put(AuthActions.verifyStudentInvitationFailed(error));
    yield put(push('/login'));
  }
}

function* handleLogout({ payload }) {
  const { callbackUrl } = payload;
  const location = yield select(getLocation);
  const { pathname } = location;

  if (callbackUrl.length) {
    yield put(push(callbackUrl));
  } else if (pathname !== '/login') {
    yield put(push('/login'));
  }
}

function* handleStudentSetPassword({ payload }) {
  const {
    password, confirmPassword, uid, token,
  } = payload;
  try {
    const { error } = yield call(AuthAPIs.newPassword, {
      password,
      confirmPassword,
      uid,
      token,
    });
    if (error) throw error;

    yield put(AuthActions.studentSetPasswordSuccess());
    yield put(push('/login'));
  } catch (error) {
    yield put(AuthActions.studentSetPasswordFailed(error));
  }
}

function* handleFindEmail({ payload }) {
  const { findEmailData } = payload;
  try {
    const { response, error } = yield call(AuthAPIs.forgotEmail, findEmailData);
    if (error) throw error;

    const { data } = response;
    yield put(AuthActions.findEmailSuccess(data.email));
  } catch (error) {
    yield put(AuthActions.findEmailFailed(error));
  }
}

function* handleResetPassword({ payload }) {
  const { email } = payload;
  try {
    const { error } = yield call(AuthAPIs.resetPassword, email);
    if (error) throw error;

    yield put(AuthActions.resetPasswordSuccess());
  } catch (error) {
    yield put(AuthActions.resetPasswordFailed(error));
  }
}

function* handleChangeEmail({ payload }) {
  const { email, uid } = payload;
  try {
    const { response, error } = yield call(AuthAPIs.changeEmail, email, uid);
    if (error) throw error;

    const { data } = response;
    const { tokens, is_verified, user } = data;

    const isEmailConfirmed = is_verified;
    const { access: token, refresh: refreshToken } = tokens;

    ToastSuccess('Your email was updated successfully');

    yield put(AuthActions.logout());

    configToken(token);
    yield put(AuthActions.loginSuccess(token, refreshToken, user, isEmailConfirmed));

    yield put(AuthActions.loginSuccess);

    yield put(UserActions.updateUserData(user.email));
    yield put(push('account/confirmation'));
  } catch (error) {
    ToastError(error[0]);
  }
}

function* handleVerificationEmail({ payload }) {
  const { token } = payload;
  try {
    const { response, error } = yield call(AuthAPIs.verificationEmail, token);
    if (error) throw error;

    yield put(AuthActions.logout());
  } catch (error) {
    ToastError('Unable to send verification email! Please try again!');
  }
}

function* handleResendVerificationEmail({ payload }) {
  const { email } = payload;
  // change the api route
  try {
    const { response, error } = yield call(AuthAPIs.resendVerificationEmail, email);

    if (error) throw error;

    const { data } = response;
    const { message } = data;
    ToastSuccess(message);
  } catch (error) {
    ToastError(error[0]);
  }
}
function* handleApproveCourseJoinRequest({ payload }) {
  const { uid, token } = payload;
  const isAuth = yield select(AuthSelectors.getAuthenticatedStatus);

  if (!isAuth) {
    const { pathname } = yield select(getLocation);
    yield put(push('/login'));
    yield put(AuthActions.loginRequiredAction(pathname));
    return;
  }

  try {
    const { error } = yield call(AuthAPIs.approveJoinCourseRequest, uid, token);
    if (error) throw error;

    yield put(AuthActions.approveJoinCourseRequestSuccess());
    yield put(push('/'));
    yield call(ToastSuccess('The request has been approved'));
  } catch (error) {
    yield put(AuthActions.approveJoinCourseRequestFailed(error));
  }
}

function* authGetNewToken() {
  const refreshToken = yield select(AuthSelectors.getRefreshToken);

  yield put(AuthActions.authCloseWebsocket());
  if (!refreshToken) {
    yield put(AuthActions.logout());
    return;
  }

  const { response, error } = yield call(
    AuthAPIs.handleRefreshToken,
    refreshToken,
  );

  if (error) {
    yield put(AuthActions.logout());
    return;
  }

  const { access: accessToken, refresh } = response.data || {};
  yield put(AuthActions.refreshLoginToken(accessToken, refresh));

  configToken(accessToken);
  yield put(AuthActions.authOpenWebsocket());
}

function* subscribeSchedule({ payload }) {
  const token = _get(payload, 'token', null);

  if (!token) {
    return;
  }

  const expiredTime = getExpiredTimeOfToken(token);

  yield delay(expiredTime - 10 * 60 * 1000);
  yield delay(6000);
  yield put(AuthActions.authGetNewToken());
}

export function* handleCleverCallback() {
  const currentEmail = yield select(UserSelectors.getUserEmail);
  const cleverEmail = yield select(AuthSelectors.getCleverEmail);
  const callbackUrl = yield select(AuthSelectors.getCallbackURL);

  if (!callbackUrl) return;
  if (currentEmail !== cleverEmail) return;

  window.location.replace(callbackUrl);
}

function executeOnHubspotLoaded(exec) {
  if (window.HubSpotConversations) {
    exec();
  } else {
    window.hsConversationsOnReady = [exec];
  }
}
export function* handleHubSpot() {
  const userEmail = yield select(UserSelectors.getUserEmail);
  const userRole = yield select(UserSelectors.getUserRole);
  try {
    // remove hubSpot for student
    if (userRole !== ROLE_TYPE.DISTRICT_ADMIN && userRole !== ROLE_TYPE.INSTRUCTOR) {
      return;
    }
    // only for teacher and admins

    const { response, error } = yield call(AuthAPIs.getHubSpotToken);
    if (error) {
      return;
    }
    const hubSpotToken = response?.data?.hubspot_token || '';
    if (hubSpotToken) {
      executeOnHubspotLoaded(() => {
        window.hsConversationsSettings = {
          identificationEmail: userEmail,
          identificationToken: hubSpotToken,
        };
        window.HubSpotConversations.widget.load({ widgetOpen: false });
      });
    }
  } catch (error) {
    //
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(AUTH_LOGIN_SUCCESS, handleCleverCallback),
    takeLatest([AUTH_REFRESH_TOKEN, AUTH_LOGIN_SUCCESS], subscribeSchedule),
    takeLeading([AUTH_LOGIN_SUCCESS, AUTH_OPEN_WEBSOCKET], handleHubSpot),
    takeLeading(AUTH_NO_TYPE_LOGIN, handleNoTypeLogin),
    takeLeading(AUTH_STUDENT_LOGIN, handleStudentLogin),
    takeLeading(AUTH_TEACHER_LOGIN, handleTeacherLogin),
    takeLeading(AUTH_CLEVER_LOGIN, handleCleverLogin),
    takeLeading(AUTH_SCHOOLOGY_LOGIN, handleSchoologyLogin),
    takeLeading(AUTH_CLASSLINK_LOGIN, handleClasslinkLogin),
    takeLeading(AUTH_VERIFY_EMAIL, handleVerifyEmail),
    takeLeading(AUTH_VERIFY_STUDENT_INVITE, handleStudentInvitationConfirm),
    takeEvery(AUTH_LOGOUT, handleLogout),
    takeLeading(AUTH_STUDENT_SET_PASSWORD, handleStudentSetPassword),
    takeLeading(AUTH_FIND_EMAIL, handleFindEmail),
    takeLeading(AUTH_RESEND_VERIFICATION_EMAIL, handleResendVerificationEmail),
    takeLeading(AUTH_VERIFICATION_EMAIL, handleVerificationEmail),
    takeLeading(AUTH_CHANGE_EMAIL, handleChangeEmail),
    takeLeading(AUTH_RESET_PASSWORD, handleResetPassword),
    takeLeading(AUTH_CONNECT_CLEVER, handleCleverConnect),
    takeLeading(AUTH_CONNECT_SCHOOLOGY, handleSchoologyConnect),
    takeLeading(AUTH_CONNECT_CLASSLINK, handleClasslinkConnect),

    takeLeading(
      AUTH_APPROVE_JOIN_COURSE_REQUEST,
      handleApproveCourseJoinRequest,
    ),
    takeLeading(AUTH_GET_NEW_TOKEN, authGetNewToken),
  ]);
}
