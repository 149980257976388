import styled from 'styled-components';
import { Tab as TabBasic, TabPanel as TabPanelBasic } from '../tabs';

export const TabList = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;

  width: 100%;
  overflow-y: hidden;
  max-width: 100%;
  overflow-y: auto;
`;

export const Tab = styled(TabBasic)<any>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-block: 0.5rem;
  padding-inline: 0.5rem;
  background-color: #ffff;
  font: 'normal normal normal 12px/19px Roboto';
  border-bottom: 0.0938rem solid ${(props) => props.theme.colorStyled.ColorBorderInput};
  cursor: pointer;
  &:hover {
    color: ${(props) =>
      props.isLessonTab
        ? props.theme.colorStyled.ColorBgDefault
        : props.theme.colorStyled.ColorPrimary};
    border-bottom: ${(props) =>
      props.isLessonTab
        ? 'none'
        : `0.0938rem solid ${props.theme.colorStyled.ColorBlack}`};
  }
  ${TabList} &.react-tabs__tab--selected {
    font: 'normal normal medium 12px/19px Roboto';
    color: ${(props) => props.theme.colorStyled.ColorBgDefault};
    border-bottom: 0.0938rem solid ${(props) => props.theme.colorStyled.ColorBgDefault};
    &:hover {
      color: ${(props) =>
        props.isLessonTab
          ? props.theme.colorStyled.ColorBgDefault
          : props.theme.colorStyled.ColorPrimary};
      border-bottom: 0.0938rem solid
        ${(props) =>
          props.isLessonTab
            ? props.theme.colorStyled.ColorBgDefault
            : props.theme.colorStyled.ColorBgDefaultHover};
    }
  }
`;

export const TabPanel = styled(TabPanelBasic)``;
