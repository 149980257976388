import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _filter from 'lodash/filter';
import FormInput from '../Generals/FormInput/FormInput';
import { Button } from '../Generals/stylesheets/Button.style';
import { STANDARD_SESSION_TYPE } from '../../utils/constants';

const validationSchema = Yup.object().shape({
  sessionType: Yup.string().trim().required('Required'),
  date: Yup.date().required('Required'),
});

class AddLessonSessionStepTwo extends Component {
  handleSaveForm = (values) => {
    const { handleSubmit } = this.props;

    handleSubmit(values);
  };

  render() {
    const { initialValues } = this.props;
    const { subject } = initialValues;
    const sessionTypeFilter = _filter(STANDARD_SESSION_TYPE.arrays, item => item.value !== STANDARD_SESSION_TYPE.UNIT_PRACTICE || subject.review_type === 1);

    return (
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.handleSaveForm}
        >
          <Form noValidate>
            <FormInput
              type="select"
              name="sessionType"
              label="Session type"
              options={sessionTypeFilter}
            />
            <FormInput
              type="date"
              name="date"
              label="Session date"
              dateFormat="yyyy/MM/dd"
            />
            <Button submit onClick={this.props.goToPrevStep}>
              Previous step
            </Button>
            <Button
              submit
              alignRight
              type="submit"
            >
              Next step
            </Button>
          </Form>
        </Formik>
      </div>
    );
  }
}

AddLessonSessionStepTwo.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  goToPrevStep: PropTypes.func,
};

export default AddLessonSessionStepTwo;
