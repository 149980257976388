import _get from 'lodash/get';
import _meanBy from 'lodash/meanBy';
import { ROLE_TYPE } from '@utils/enums';
import { selectors as userSelectors } from '../user';

export const SECTION_SKILLS_GET = 'sectionSkills/GET';
export const SECTION_SKILLS_GET_SUCCESS = 'sectionSkills/GET_SUCCESS';
export const SECTION_SKILLS_GET_FAIL = 'sectionSkills/GET_FAIL';

const sectionSkillsGet = (sectionId, lessonId, studentId) => ({
  type: SECTION_SKILLS_GET,
  sectionId,
  lessonId,
  studentId,
});

const sectionSkillsGetSuccess = (payload) => ({
  type: SECTION_SKILLS_GET_SUCCESS,
  payload,
});

const sectionSkillsGetFail = (error) => ({
  type: SECTION_SKILLS_GET_FAIL,
  error,
});

export const actions = {
  sectionSkillsGet,
  sectionSkillsGetSuccess,
  sectionSkillsGetFail,
};

const calculateMeanCurrentSkill = (data, userRole) => {
  if (data === undefined || data === null || Object.keys(data).length === 0) {
    if (userRole === ROLE_TYPE.STUDENT) {
      return 1;
    }
    return null;
  }
  const values = Object.values(data);
  return Math.round(_meanBy(values, (value) => value.current)) || 1;
};

const getIsGetSkillData = ({ sectionSkills }) =>
  _get(sectionSkills, 'isGetSkillData', false);
const getSkillByLessonId = ({ sectionSkills }) =>
  _get(sectionSkills, 'skillByLessonId', {});
const getSkillsDataOfLesson = ({ sectionSkills }, lessonId) =>
  _get(sectionSkills, `skillByLessonId.${lessonId}`, {});
const getMeanOfCurrentLessonSkillData = (state, sectionId, lessonId) => {
  const userRole = userSelectors.getUserRole(state);
  const skillData = getSkillsDataOfLesson(state, lessonId);
  const meanCurrentSkill = calculateMeanCurrentSkill(skillData, userRole);
  return meanCurrentSkill;
};

export const selectors = {
  // used in the intervention tab
  getIsGetSkillData,
  getSkillByLessonId,
  getSkillsDataOfLesson,
  // used in LessonPracticeCard
  getMeanOfCurrentLessonSkillData,
};

const initialState = {
  isGetSkillData: false,
  skillByLessonId: {},
  error: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SECTION_SKILLS_GET:
      return { ...state, isGetSkillData: true };
    case SECTION_SKILLS_GET_SUCCESS: {
      const { payload } = action;

      return { ...state, isGetSkillData: false, skillByLessonId: payload };
    }
    case SECTION_SKILLS_GET_FAIL:
      return { ...state, isGetSkillData: false };
    default:
      return state;
  }
}
