import React from 'react';
import Box from '@mui/material/Box';
import MathJaxItem from '@components/MathJaxContainer';
import InformationAlert from '@components/Atoms/InformationAlert';
import Typography from '@components/Atoms/Typography';
import ShowIf from '@components/Atoms/ShowIf';
import Answer from '../Answer';
import { useQuestion, useIsQuestionLocked } from '../../Provider/PracticeProvider';
import { QuestionContentWrap } from './Question.style';

const Question = () => {
  const question = useQuestion();
  const isQuestionLocked = useIsQuestionLocked();
  const questionData = question?.question;
  const questionContent = React.useMemo(
    () => questionData?.prompt?.text || '',
    [questionData],
  );
  if (!question) {
    // Box is to reduce layout shift
    return (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        overflow="auto"
        pb="2rem"
        flex="1"
      />
    );
  }
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      overflow="auto"
      pb="2rem"
      flex="1"
      gap="1rem"
    >
      <QuestionContentWrap>
        <MathJaxItem data={questionContent} />
      </QuestionContentWrap>
      <ShowIf If={isQuestionLocked}>
        <InformationAlert>
          <Typography variant="body1">
            Your group has already submitted their answer, and we cannot accept any more
            changes. Click the Next button to proceed.
          </Typography>
        </InformationAlert>
      </ShowIf>

      {/* TODO: add handler  */}
      <Answer question_format={questionData?.question_format || 0} key={question.id} />
    </Box>
  );
};

export default React.memo(Question);
