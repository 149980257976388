import {
  call, put, takeLeading, select,
} from 'redux-saga/effects';
import { error as ToastError, success as ToastSuccess } from '../../utils/toast';
import * as CourseAPI from '../../apis/courses';
import {
  COURSE_STUDENTS_GET_LIST,
  actions as CourseStudentActions,
  COURSE_STUDENTS_SEND_RESET_PASSWORD,
  COURSE_STUDENTS_REMOVE_STUDENT,
  COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD, selectors as CourseStudentSelectors,
} from '../../reducers/courseStudents';
import * as AuthAPIs from '../../apis/auth';

export function* handleFetchStudentListFromCourse({ courseId }) {
  try {
    const { response, error } = yield call(CourseAPI.getCourseStudents, courseId);
    if (error) throw error;
    const { data } = response;

    yield put(CourseStudentActions.fetchCourseStudentListSuccess(data));
  } catch (error) {
    yield put(CourseStudentActions.fetchCourseStudentListFail(error));
  }
}

function* handleStudentSentResetPassword({ payload }) {
  const { email } = payload;
  try {
    const { error } = yield call(AuthAPIs.resetPassword, email);
    if (error) throw error;

    yield put(CourseStudentActions.resetPasswordSuccess(email));
    ToastSuccess(`Reset password email has been sent to ${email}!`);
  } catch (error) {
    yield put(CourseStudentActions.resetPasswordFailed(error, email));
    ToastError(`Failed to sent reset password email for ${email}!`);
  }
}

function* handleRemoveStudent({ payload }) {
  const { courseId, studentId, studentName } = payload;
  try {
    const { error } = yield call(CourseAPI.removeStudentFromCourse, courseId, studentId);
    if (error) throw error;

    yield put(CourseStudentActions.removeStudentSuccess(studentId));
    ToastSuccess(`Student ${studentName} has been removed!`);
  } catch (error) {
    yield put(CourseStudentActions.removeStudentFailed(error, studentId));
    ToastError(`Failed to remove student #${studentName}!`);
  }
}

function* handleTeacherResetStudentPassword({ payload }) {
  const {
    courseId, studentId, password, studentName,
  } = payload;
  try {
    const { error } = yield call(CourseAPI.teacherResetStudentPassword, courseId, studentId, password);
    if (error) throw error;
    yield put(CourseStudentActions.teacherChangePasswordSuccess(studentId));
    ToastSuccess(`You have successfully reset student ${studentName} password!`);
  } catch (error) {
    yield put(CourseStudentActions.teacherChangePasswordFail(error, studentId));
    ToastError(`Failed to change password of student #${studentId}!`);
  }
}

export default function* courseStudentsSaga() {
  yield takeLeading(COURSE_STUDENTS_GET_LIST, handleFetchStudentListFromCourse);
  yield takeLeading(COURSE_STUDENTS_SEND_RESET_PASSWORD, handleStudentSentResetPassword);
  yield takeLeading(COURSE_STUDENTS_REMOVE_STUDENT, handleRemoveStudent);
  yield takeLeading(COURSE_STUDENTS_TEACHER_CHANGE_PASSWORD, handleTeacherResetStudentPassword);
}
