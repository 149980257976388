import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import _get from 'lodash/get';
import _unionBy from 'lodash/unionBy';
import * as Yup from 'yup';
import FormInput from '../Generals/FormInput/FormInput';
import { Button } from '../Generals/stylesheets/Button.style';
import { SESSION_ACTIVITY_TYPE } from '../../utils/constants';
import { ButtonWrapper } from './ScheduleAddLessonSessionForm.style';

const validationSchema = Yup.object().shape({
  lesson: Yup.object().required('Required'),
});

class AddLessonSessionStepThree extends Component {
  onSubmit = (values) => {
    const { handleSubmit } = this.props;

    handleSubmit(values);
  }

  renderWorksheetField = (fieldProps) => {
    const { field, form } = fieldProps;
    const { name, value } = field;
    const hasGroupActivity = _get(form, 'values.unit.meta.group_activity', false);
    const hasDocument = _get(form, 'values.lesson.has_documents', false);
    let isDisable = true;

    if (hasGroupActivity && hasDocument) {
      isDisable = false;
    }

    return (
      <FormInput
        type="select"
        name={name}
        value={value}
        label="Worksheet"
        options={SESSION_ACTIVITY_TYPE.arrays}
        isDisabled={isDisable}
      />
    );
  }

  renderPracticeField = (fieldProps) => {
    const { field, form } = fieldProps;
    const { name, value } = field;
    const hasLessonPractice = _get(form, 'values.unit.meta.lesson_practice', false);
    const canPractice = _get(form, 'values.lesson.can_practice', false);
    let isDisable = true;

    if (hasLessonPractice && canPractice) {
      isDisable = false;
    }

    return (
      <FormInput
        type="select"
        name={name}
        value={value}
        label="Pratice"
        options={SESSION_ACTIVITY_TYPE.arrays}
        isDisabled={isDisable}
      />
    );
  }

  render() {
    const { initialValues, lessons } = this.props;
    const lessonFiltered = _unionBy(lessons, 'id');

    return (
      <div>
        <Formik
          validationSchema={validationSchema}
          onSubmit={this.onSubmit}
          initialValues={initialValues}
        >
          <Form noValidate>
            <FormInput
              type="select"
              name="lesson"
              label="Session lesson"
              options={lessonFiltered}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              isSearchable={false}
            />
            <FormInput
              type="select"
              name="activitySlide"
              label="Slides"
              options={SESSION_ACTIVITY_TYPE.arrays}
            />
            <Field
              type="select"
              name="activityWorksheet"
              component={this.renderWorksheetField}
            />
            <Field
              type="select"
              name="activityPractice"
              component={this.renderPracticeField}
            />
            <ButtonWrapper>
              <Button submit onClick={this.props.goToPrevStep}>
                Previous step
              </Button>
              <Button submit type="submit">
                Next step
              </Button>
            </ButtonWrapper>
          </Form>
        </Formik>
      </div>
    );
  }
}

AddLessonSessionStepThree.propTypes = {
  initialValues: PropTypes.object,
  goToPrevStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  lessons: PropTypes.array,
};

export default AddLessonSessionStepThree;
