const ColumnSize = [
  { size: '80', smallSize: true },
  { size: '90', smallSize: true },
  { size: '80', smallSize: true },
  { smallSize: true },
  {
    size: '65', smallSize: true, textCenter: true,
  },
  {
    size: '55', smallSize: true, textCenter: true,
  },
];

export default ColumnSize;
