import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import { TabProvider, TabList, Tab, TabPanel } from '../Shared/FancyTab';
import { Button } from '../Generals/stylesheets/Button.style';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import RatingGroup from './Components/RatingGroup';
import { useIWContext } from './IWContextProvider';
import CriteriaCard from './Components/CriteriaCard';
import { useSectionByIWId, useCriteriaGet } from './hooks';
import CriteriaList from './Components/CriteriaList';

const isTabClickable = true;

const InstructionalWalkThroughRenderer = ({ previewMode }) => {
  const tabProviderRef = React.useRef(null);
  const [currentTab, setCurrentTab] = React.useState(0);
  const [sendButtonDisabled, setSendButtonDisabled] = React.useState(true);
  const {
    values,
    addNote,
    selectedTeacher,
    currentPageIWId,
    currentSiderIWId,
    handleSaveReport,
  } = useIWContext();
  const IWId = selectedTeacher ? currentSiderIWId : currentPageIWId;
  const { data: sectionData } = useSectionByIWId({ IWId, defaultValue: [] });
  const sectionsIds = React.useMemo(() => {
    const mappedData = sectionData.map((section) => section.id);
    setCurrentTab(mappedData?.[0]);
    return mappedData;
  }, [sectionData]);
  const handleNext = () => {
    if (tabProviderRef.current) {
      const nextTab = sectionsIds.indexOf(currentTab) + 1;
      setCurrentTab(sectionData?.[nextTab]?.id);
      tabProviderRef.current.setTab(sectionsIds[nextTab]);
      if (nextTab === sectionData.length - 1 && selectedTeacher) {
        setSendButtonDisabled(false);
      }
    }
  };
  const handlePrev = () => {
    if (tabProviderRef.current) {
      const prevTab = sectionsIds.indexOf(currentTab) - 1;
      // currentTab = sectionsIds?.[prevTab];
      setCurrentTab(sectionsIds?.[prevTab]);
      tabProviderRef.current.setTab(sectionsIds[prevTab]);
    }
  };
  if (sectionData.length === 0) {
    return <p>No data. Please add a data to this instructional walkThrough template.</p>;
  }
  return (
    <div className="intructional_walkThrough_container">
      <TabProvider defaultTab={sectionData?.[0]?.id || null} ref={tabProviderRef}>
        <TabList>
          {sectionData?.map((section, index) => (
            <Tab key={section.id} tabKey={section.id} disabled={!isTabClickable}>
              <span style={{ width: 'max-content' }}>{section.displayName}</span>
            </Tab>
          ))}
        </TabList>
        <Divider style={{ marginTop: '1rem' }} />
        {sectionData?.map((section, index) => (
          <TabPanel key={section.id} tabKey={section.id}>
            <DefaultContentWrap>
              <CriteriaList sectionId={section.id} />
            </DefaultContentWrap>
          </TabPanel>
        ))}
        <Divider style={{ marginTop: '1rem' }} />
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Button
            onClick={handlePrev}
            disabled={sectionsIds.indexOf(currentTab) <= 0}
            full
            blue
            style={{ marginTop: '1rem' }}
          >
            Previous
          </Button>
          <Button
            onClick={handleNext}
            disabled={sectionsIds.indexOf(currentTab) >= sectionsIds.length - 1}
            full
            blue
            style={{ marginTop: '1rem' }}
          >
            Next
          </Button>
        </div>
        <TextField
          style={{ marginTop: '1rem' }}
          label="Note to Teacher"
          defaultValue=""
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={values.notes}
          onChange={(event) => addNote(event.target.value)}
          placeholder="These are brilliant! I would suggest…"
        />
        <Button
          onClick={handleSaveReport}
          disabled={sendButtonDisabled}
          full
          blue
          style={{ marginTop: '1rem' }}
        >
          Send
        </Button>
      </TabProvider>
    </div>
  );
};

export default InstructionalWalkThroughRenderer;
