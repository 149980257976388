import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Header from './Header';
import Box from '../Box';
import { TableHeaderTypes, rowObjectType } from './Types';
import Body from './Body';
import TablePagination from '../TablePagination';
import { TablePaginationActions } from '../TablePagination/TablePagination';
import ShowIf from '../ShowIf';

const paginationCustomizedStyle = {
  background: 'white',
  display: 'flex',
  borderRadius: ' 0 0 0.5rem 0.5rem',
  justifyContent: 'center',
  '.MuiTablePagination-displayedRows': {
    fontSize: '0.75rem',
    color: '#000000d1',
  },
  '.MuiTablePagination-selectLabel': {
    fontSize: '0.75rem',
    color: '#000000d1',
  },
  '.MuiInputBase-root': {
    fontSize: '0.75rem',
  },
};

type TableProps<T> = {
  rows: T[];
  columns: Array<TableHeaderTypes>;
  sx?: SxProps<Theme>;
  HeaderSx?: SxProps<Theme>;
  bodySx?: SxProps<Theme>;
  hasPagination: boolean;
  defaultRowsPerPage?: number;
  rowsPerPageOptions?: Array<number | { label: string; value: number }>;
};

/**
 *
 * @example
 * <Table
 *  columns={[
 *   { key: 'name', label: 'Name' },
 *   { key: 'age', label: 'Age' },
 *  ]}
 *  rows={[
 *  { name: 'John', age: 25 },
 *  { name: 'Jane', age: 30 },
 *  ]}
 *  hasPagination
 *  defaultRowsPerPage={20}
 *  rowsPerPageOptions={[20, 40, 80, { label: 'All', value: -1 }]}
 * />
 *
 */
const Table = <T extends rowObjectType>({
  columns = [],
  rows = [],
  sx,
  HeaderSx,
  bodySx,
  hasPagination,
  defaultRowsPerPage = 20,
  rowsPerPageOptions = [20, 40, 80, { label: 'All', value: -1 }],
}: TableProps<T>) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
  const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc' | string>(''); // State for sorting order
  const [sortedColumn, setSortedColumn] = React.useState<string>(''); // State for the sorted column

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (key: string) => {
    // Toggle sorting order if clicking the same column
    if (sortedColumn === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortOrder('asc'); // Reset sorting order for a new column
      setSortedColumn(key);
    }
  };

  const sortedRows = React.useMemo(() => {
    if (sortedColumn) {
      const sorted = [...rows].sort((a, b) => {
        const aValue = a[sortedColumn];
        const bValue = b[sortedColumn];

        if (sortOrder === 'asc') {
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue);
          }
          return aValue - bValue;
        }
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return bValue.localeCompare(aValue);
        }
        return bValue - aValue;
      });
      return sorted;
    }
    return rows;
  }, [rows, sortOrder, sortedColumn]);

  return (
    <Box sx={sx && { ...sx }}>
      <Header
        sx={HeaderSx}
        headerCells={columns}
        onSort={handleSort}
        sortedColumn={sortedColumn}
        sortOrder={sortOrder}
      />
      <Body
        rows={sortedRows}
        columns={columns}
        sx={bodySx}
        page={page}
        rowsPerPage={hasPagination ? rowsPerPage : -1}
      />
      <ShowIf If={hasPagination}>
        <TablePagination
          sx={paginationCustomizedStyle}
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={3}
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  '.MuiList-root': {
                    '.MuiMenuItem-root': {
                      fontSize: '0.75rem',
                    },
                  },
                },
              },
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </ShowIf>
    </Box>
  );
};

export default Table;
