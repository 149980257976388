import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import TopNavBar from '../../containers/TopNavBar';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { PrimaryLayout } from './MainLayout.style';
import Banner from '../Banner';
import { handleExamsBannerLogic } from '../Banner/helpers';
import { ROLE_TYPE } from '../../utils/enums';

const MainLayout = ({
  children,
  isLoading,
  exams,
  courseId,
  sectionId,
  userRole,
  hasExamV2Access,
  fetchExamV2Access,
}) => {
  const location = useLocation();
  const showPaths = ['/dashboard', '/lessons', '/schedule'];
  const [showBanner, setShowBanner] = useState(false);
  const [bannerText, setBannerText] = useState('');
  const [examId, setExamId] = useState(null);

  useEffect(() => {
    fetchExamV2Access();
  }, []);

  useEffect(() => {
    handleExamsBannerLogic({
      exams,
      location,
      userRole,
      hasExamV2Access,
      showPaths,
      setShowBanner,
      setBannerText,
      setExamId,
    });
  }, [exams, location.pathname, bannerText, userRole, hasExamV2Access]);

  if (isLoading) {
    return <LoadingIndicator isLoading={isLoading} />;
  }

  return (
    <>
      {showBanner && (
        <Banner
          bannerText={bannerText}
          bannerAction="Go To Tests Page"
          bannerUrl={`/test/course/${courseId}/section/${sectionId}/exam/${examId}`}
          hideButton={false}
          bannerType="alert"
        />
      )}
      <PrimaryLayout
        showBanner={showBanner}
        className={`primary-layout ${showBanner ? 'show-banner' : ''}`}
      >
        <TopNavBar />
        {children}
      </PrimaryLayout>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element,
  isLoading: PropTypes.bool,
  exams: PropTypes.array.isRequired,
  courseId: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  fetchExamV2Access: PropTypes.func.isRequired,
  hasExamV2Access: PropTypes.bool,
};

export default MainLayout;
