/* eslint-disable arrow-body-style */
import React from 'react';
import Box from '@components/Atoms/Box';
import Skeleton from '@components/Atoms/Skeleton';

const ExamScoreCardSkelton = () => {
  return (
    <Box
      component="div"
      sx={{
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        width: '100%',
        '& span': {
          transform: 'none',
        },
      }}
    >
      <Skeleton height="7.5rem" width="6.875rem" />

      <Box
        component="div"
        sx={{
          width: '6.875rem',
          display: 'flex',
          gap: '0.5rem',
          flexDirection: 'column',
        }}
      >
        <Skeleton height="3.5rem" />
        <Skeleton height="3.5rem" />
      </Box>
    </Box>
  );
};

export default ExamScoreCardSkelton;
