import { SkillRecord, StandardRecord } from "@CT-Types/lessonDomain";

export interface Feedback {
  rating: number;
  text: string;
}

export interface Metadata {
  difficulty?: string;
  subject?: string;
  grade_level?: string;
  [key: string]: unknown;
}

export interface GeneratedQuestion {
  response_id: string;
  task_id: string;
  user_id: string;
  type?: string;
  is_math?: boolean;
  is_deleted?: boolean;
  is_custom?: boolean;
  is_edited?: boolean;
  feedback?: Feedback;
  metadata?: Metadata;
  [key: string]: unknown;
}

export interface MenuObject {
  id?: number;
  reference?: string;
  [key: string]: unknown;
}

export interface FeatureJson {
  // https://help.learnosity.com/hc/en-us/articles/16684575643549-feature-types

  reference: string;
  widget_type: string; // 'feature'
  type: string; // 'sharedpassage' | 'audio' | 'video' etc...
  data: Record<string, unknown>;
}

export interface QuestionGenerationServiceMenu {
  subject: MenuObject;
  questionQuantity: number;
  includeSlides: boolean;
  lessonGroupId?: number;
  customNeeds: string;
  questionType: string;
}

export enum QuestionGenerationMenuType {
  SET_SUBJECT = 'SET_SUBJECT',
  SET_QUESTION_QUANTITY = 'SET_QUESTION_QUANTITY',
  SET_INCLUDE_SLIDES = 'SET_INCLUDE_SLIDES',
  SET_CUSTOM_NEEDS = 'SET_CUSTOM_NEEDS',
  SET_QUESTION_TYPE = 'SET_QUESTION_TYPE',
}

export interface QuestionType {
  type: string;
  name: string;
  description: string;
}

export interface WidgetDefinition {
  reference: string;
  widget_type: 'feature' | 'response';
}

export interface QuestionJson {
  // https://help.learnosity.com/hc/en-us/articles/16684586288925-question-types

  reference: string;
  widget_type: string; // 'response'
  type?: string; // 'mcq' | 'clozeassociation' | 'clozedropdown' etc...
  data: Record<string, any>;
}

export interface ItemJson {
  reference: string;
  definition: {
    template: string; // default 'dynamic'
    widgets: WidgetDefinition[];
  };
  [key: string]: unknown;
}

export interface LearnosityItem {
  reference: string;
  item: ItemJson;
  questions: QuestionJson[];
  features?: FeatureJson[];
  is_custom: boolean;
  is_deleted: boolean;
  is_edited: boolean;
  feedback: Feedback;
  user_id: string;
  [key: string]: unknown;
}

export interface SupportedQuestionTypes {
  math: QuestionType[];
  english: QuestionType[];
}

export interface GenerationStatusItem {
  status: string;
  message: string;
  payload?: LearnosityItem;
  user_id: string;
  question_id: number;
  task_id: string;
}
