import React from 'react';
import { MenuItem } from '@mui/material';
import Button from '../Button';
import { ButtonProps } from '../Button/Button';
import Menu from '../Menu';

type MenuButtonProps = {
  clickableNode?: React.ReactNode;
  label: string;
  list: Array<React.ReactElement<any, string | React.JSXElementConstructor<any>>>;
  buttonProps?: Omit<ButtonProps, 'children'>;
  testId: string;
};

const MenuButton = ({
  list,
  label,
  clickableNode,
  buttonProps = {},
  testId = 'drop-down',
}: MenuButtonProps) => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div ref={wrapperRef}>
      {clickableNode || (
        <Button
          aria-controls={open ? 'menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          {...buttonProps}
          fullWidth
          data-testid={`${testId}-button`}
        >
          {label}
        </Button>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        container={wrapperRef.current}
        sx={{ '& .MuiPaper-root': { minWidth: wrapperRef.current?.clientWidth } }}
        data-testid={`${testId}-menu`}
      >
        {(list || []).map(
          (item: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
            <MenuItem key={item.key}>{item}</MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
};
export default MenuButton;
