/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { SxProps, Theme } from '@mui/material';
import Cell from './Cell';
import Row from './Row';
import { TableHeaderTypes } from './Types';
import Typography from '../Typography';
import ShowIf from '../ShowIf';
import SortIcon from './SortIcon';

interface HeaderProps {
  headerCells: Array<TableHeaderTypes>;
  sx?: SxProps<Theme>;
  onSort?: (key: string | number) => void;
  sortedColumn: string;
  sortOrder: 'asc' | 'desc' | string;
}

const Header = ({ headerCells, sx, onSort, sortOrder, sortedColumn }: HeaderProps) => {
  const isAscending = sortOrder === 'asc';
  const isDescending = sortOrder === 'desc';
  return (
    <Row className="table-header no-highlight" sx={{ ...sx }} data-testid="table-header">
      {headerCells.map((cell) => (
        <Cell
          key={cell.id}
          sx={{
            flexBasis: cell.width,
            justifyContent: cell?.style?.textAlign,
            ...cell.style,
          }}
          data-testid="table-header-cell"
          onClick={() => cell?.isSortable && onSort?.(cell.relatedKey)}
        >
          <ShowIf If={!!cell?.isSortable}>
            <SortIcon
              isAscending={isAscending && sortedColumn === cell.relatedKey}
              isDescending={isDescending && sortedColumn === cell.relatedKey}
            />
          </ShowIf>
          <Typography>{cell.headerTitle}</Typography>
        </Cell>
      ))}
    </Row>
  );
};

export default Header;
