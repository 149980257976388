import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import _unionBy from 'lodash/unionBy';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import ReactTooltip from 'react-tooltip';
import { getSearchParamsObject } from '@utils/commonUtils';
import {
  NavBarItemCustome,
  NavBarName,
  NavBarItemHead,
  NavBarHeadText,
  NavBarStatusColumn,
  NavBarItemWrap,
  NavBarIconWrap,
  NavBarIcon,
  NavBarItemDateWrap,
  NavBarItemDay,
  NavBarItemMonth,
  PropertiseBarWrapper,
  PropertiseBarTitle,
  PropertiseBarProgress,
  PropertiseBar,
} from './SubNavBar.style';
import UnitPracticeSubNavItem from './UnitPracticeSubNavItem';

class SessionSubNavItem extends React.PureComponent {
  componentDidMount() {
    if (document.getElementById('active-lesson')) {
      document.getElementById('active-lesson').scrollIntoView({ behavior: 'smooth' });
    }
  }

  redirectToLessonDetailPage = () => {
    const { unitId, history, match, lesson, subjectId } = this.props;
    const { id } = lesson;
    const courseId = _get(match, 'params.courseId', '');
    const sectionId = _get(match, 'params.sectionId', '');

    const queryObject = getSearchParamsObject(history.location.search);
    const tabIndex = queryObject.tab ?? LessonTabs.Lesson;

    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/lesson/${id}?tab=${tabIndex}`,
    );
  };

  renderProgressBarItem = () => {
    const { lesson, lessonActivitySummaryById } = this.props;
    const lessonId = _get(lesson, 'id', '');
    const isCompled = _get(lessonActivitySummaryById, `${lessonId}.completed`, false);
    return (
      <NavBarStatusColumn>
        <NavBarIconWrap isSingleItem isFirstItem={false} isLastItem={false}>
          {isCompled ? (
            <NavBarIcon className="chalktalk-check-success" />
          ) : (
            <NavBarIcon className="chalktalk-check-incomplete" />
          )}
        </NavBarIconWrap>
      </NavBarStatusColumn>
    );
  };

  renderPropertiesBar = () => {
    const {
      lesson,
      unit,
      lessonActivitySummaryById,
      groupActivitySummaryById,
      practiceSummaryByLessonId,
      isTeacher,
      userId,
      studentList,
    } = this.props;
    const lessonCanPractice = _get(lesson, 'can_practice', false);
    const lessonHasGroupActivity = _get(lesson, 'has_documents', false);
    const unitHasLessonPractice = _get(unit, 'meta.lesson_practice', false);
    const unitHasGroupActivity = _get(unit, 'meta.group_activity', false);
    const shouldRenderPracticePropertise = lessonCanPractice && unitHasLessonPractice;
    const shouldRenderGroupActivity = lessonHasGroupActivity && unitHasGroupActivity;

    const lessonId = _get(lesson, 'id', '');
    const isCompletedLesson = _get(
      lessonActivitySummaryById,
      `${lessonId}.completed`,
      false,
    );
    const isCompletedGroupActivity = _get(
      groupActivitySummaryById,
      `${lessonId}.completed`,
      false,
    );
    let isCompletedPractice = false;
    const usersPracticeInfo = _get(practiceSummaryByLessonId, `${lesson.id}`, []);

    if (isTeacher) {
      isCompletedPractice = (usersPracticeInfo.length / studentList.length) * 100 >= 70;
    } else {
      isCompletedPractice = usersPracticeInfo.filter((x) => x.user == userId).length > 0;
    }

    const hasAllItems = shouldRenderGroupActivity && shouldRenderPracticePropertise;

    return (
      <PropertiseBar hasAllItems={hasAllItems} isSession>
        <PropertiseBarWrapper>
          <PropertiseBarTitle>🧑‍🏫 Lesson</PropertiseBarTitle>
          <PropertiseBarProgress isFinish={isCompletedLesson} />
        </PropertiseBarWrapper>
        {shouldRenderGroupActivity && (
          <PropertiseBarWrapper>
            <ReactTooltip place="top" effect="solid" id="tooltip-group-activity" />
            <PropertiseBarTitle
              data-tip="Group Activity"
              data-for="tooltip-group-activity"
            >
              👥 Activity
            </PropertiseBarTitle>
            <PropertiseBarProgress isFinish={isCompletedGroupActivity} />
          </PropertiseBarWrapper>
        )}
        {shouldRenderPracticePropertise && (
          <PropertiseBarWrapper>
            <PropertiseBarTitle>👤 Practice</PropertiseBarTitle>
            <PropertiseBarProgress isFinish={isCompletedPractice} />
          </PropertiseBarWrapper>
        )}
      </PropertiseBar>
    );
  };

  renderSessionDate = () => {
    const { sessionItem } = this.props;
    const { date } = sessionItem;
    const sessionDate = moment(date, 'YYYY-MM-DD');
    return (
      <NavBarItemDateWrap>
        <NavBarItemMonth>{`${sessionDate.format('MMM')}`}</NavBarItemMonth>
        <NavBarItemDay>{`${sessionDate.format('DD')}`}</NavBarItemDay>
        <NavBarItemMonth>{`${sessionDate.format('YYYY')}`}</NavBarItemMonth>
      </NavBarItemDateWrap>
    );
  };

  renderPracticeCard = () => {
    const {
      sessionItem,
      practiceSummaryByUnitId,
      isTeacher,
      userId,
      studentList,
      history,
      match,
      unit,
      unitIdFromPath,
      lessonIdFromPath,
    } = this.props;
    const selectionPracticeList = _get(practiceSummaryByUnitId, `${unit.id}`, []);
    let percent = 0;
    if (isTeacher) {
      const practiceStudentList = _unionBy(selectionPracticeList, 'user');
      percent =
        studentList.length > 0
          ? Math.round((practiceStudentList.length / studentList.length) * 100)
          : 0;
    } else {
      // eslint-disable-next-line eqeqeq
      const practiceStudentList = selectionPracticeList.filter((x) => x.user == userId);
      percent = practiceStudentList.length ? 100 : 0;
    }
    const isActive = unitIdFromPath == sessionItem.unit && !lessonIdFromPath;
    const { date } = sessionItem;
    return (
      <UnitPracticeSubNavItem
        isActive={isActive}
        name={unit.name}
        percent={percent}
        unitId={unit.id}
        subjectId={unit.curriculum_subject}
        history={history}
        match={match}
        date={date}
      />
    );
  };

  render() {
    const { sessionItem, unit, lesson, lessonIdFromPath, isAssigned } = this.props;

    const unitName = _get(unit, 'name', '');
    const lessonName = _get(lesson, 'name', '');

    if (sessionItem.activity_type === 1 && !_isEmpty(unit)) {
      return this.renderPracticeCard();
    }
    if (lessonName) {
      const isActive = sessionItem.lesson == lessonIdFromPath;
      return (
        <NavBarItemWrap
          id={isActive ? 'active-lesson' : ''}
          isActive={isActive}
          onClick={this.redirectToLessonDetailPage}
        >
          {this.renderProgressBarItem()}
          <NavBarItemCustome>
            <NavBarItemHead>
              <NavBarHeadText>{unitName}</NavBarHeadText>
            </NavBarItemHead>
            <NavBarName>
              {lessonName || 'OPS'}
              {isAssigned && <i className="chalktalk-error" />}
            </NavBarName>
            {this.renderPropertiesBar()}
          </NavBarItemCustome>
          {this.renderSessionDate()}
        </NavBarItemWrap>
      );
    }
    return '';
  }
}

SessionSubNavItem.propTypes = {
  sessionItem: PropTypes.object,
  unit: PropTypes.object,
  lesson: PropTypes.object,
  history: PropTypes.any,
  unitId: PropTypes.number,
  match: PropTypes.objectOf(PropTypes.any),
  subjectId: PropTypes.any,
  lessonIdFromPath: PropTypes.any,
  lessonActivitySummaryById: PropTypes.object,
  practiceSummaryByLessonId: PropTypes.object,
  isTeacher: PropTypes.bool,
  userId: PropTypes.any,
  studentList: PropTypes.array,
  isAssigned: PropTypes.any,
};

export default SessionSubNavItem;
