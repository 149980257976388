import React, { ReactElement } from 'react';
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material';

export interface TooltipProps extends MuiTooltipProps {
  children: ReactElement<any, any>;
}

const Tooltip = ({ children, ...rest }: TooltipProps) => (
  <MuiTooltip {...rest}>{children}</MuiTooltip>
);

export default Tooltip;
