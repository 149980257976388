import React, { useMemo } from 'react';
import MultiSelectTree from '@components/Atoms/MultiSelectTree';
import { useGetSectionSubjectUnitLesson } from 'chalktalk-react/hooks/useGetSectionSubjectUnitLesson';

const formatDataToTree = (data, filtered) => {
  // Handle empty data case
  if (!data || data.length === 0) {
    return [];
  }

  const formatLesson = (lesson) => ({
    ...lesson,
    id: lesson.id.toString(),
    label: lesson.name,
  });

  const formatUnit = (unit) => ({
    id: `u_${unit.id.toString()}`,
    label: unit.name,
    noChip: true,
    children: unit.lessons
      .filter((lesson) => !filtered || filtered.includes(lesson.id))
      .map(formatLesson),
  });

  // Special case: When there's only one subject, flatten the hierarchy by one level
  // and return units directly as top-level items (without subject parent)
  if (data.length === 1) {
    return data[0].units.map(formatUnit);
  }

  // Standard case: Multiple subjects, show full hierarchy
  return data?.map((subject) => ({
    id: `s_${subject.id.toString()}`,
    label: subject.display_name,
    noCheckbox: true,
    noChip: true,
    children: subject.units.map(formatUnit),
  }));
};

interface LessonTreeSelectorProps {
  subjectRestriction?: number[];
  disabled?: boolean;
  onChange?: (lessons: number[]) => void;
  selected?: number[];
  filtered?: number[];
  multiple?: boolean;
  onClose?: () => void;
  open?: boolean;
  tags?: Record<string, { name: string; color: string; tooltip: string }>;
  background: Record<string, string>;
  indeterminate?: number[];
}

const LessonTreeSelector = ({
  subjectRestriction,
  disabled,
  onChange,
  selected,
  filtered,
  multiple = true,
  onClose,
  open,
  tags,
  background,
  indeterminate,
}: LessonTreeSelectorProps) => {
  const { data: allSubjects } = useGetSectionSubjectUnitLesson();
  const subjects =
    subjectRestriction && subjectRestriction.length > 0
      ? allSubjects?.filter((subject) => subjectRestriction.includes(subject.id))
      : allSubjects;

  const lessonTree = useMemo(
    () => formatDataToTree(subjects, filtered),
    [subjects, filtered],
  );

  return (
    <MultiSelectTree
      tree={lessonTree}
      disabled={disabled}
      placeholder="Click here to select lessons..."
      onChange={onChange}
      selected={selected}
      multiple={multiple}
      onClose={onClose}
      open={open}
      tags={tags}
      background={background}
      indeterminate={indeterminate}
    />
  );
};

export default LessonTreeSelector;
