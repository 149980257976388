import styled, { css } from 'styled-components';

export const PracticeAnswerMultiChoiceWrap = styled.div``;

export const AnswerMultiChoiceItem = styled.div`
  display: flex;
  align-content: center;
  padding: 1em 0em;
  border-bottom: 1px solid ${props => props.theme.colorStyled.ColorBorder};
  width: ${({ activity }) => (activity === "online-group" ? "80%" : "100%")};
  `;

export const AnswerChoiceLabel = styled.div`
  height: 2.25em;
  flex: 0 0 2.25em;
  margin-right: 2em;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: 'MontserratMedium';
  border: 2px solid ${props => props.theme.colorStyled.ColorPrimaryBlue};
  font-size: ${props => props.theme.fontSize.LargeFontSize};
  color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  ${({ isSelected }) => isSelected && css`
    background-color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
    color: ${props => props.theme.colorStyled.ColorWhite};
  `}
  padding-top: 1px;
`;

export const AnswerChoiceContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: .5em;
  @media (max-width: 768px) {
    p {
        img {
          width: 100% !important;
          object-fit: scale-down;
        }
      }
  }
`;

export const AnswerWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .answer-text{
    width:20%;
    margin-right: 2px;
  }
  ${({ right }) => right && css`
    position: relative;
    right: 20%;
  `}
`

export const AnswerChoiceCorrect = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-size: ${props => props.theme.fontSize.XLargeFontSize};
`;

export const AnswerChoiceWrong = styled(AnswerChoiceCorrect)`
  color: ${props => props.theme.colorStyled.ColorRedPoint};
`;
