import React from 'react';

import { useIWContext } from '../IWContextProvider';
import { Button } from '../../Generals/stylesheets/Button.style';

const OpenSider = () => {
  const { setIsSiderOpen } = useIWContext();

  // We included this line because we've stopped using Firebase for feature visibility.
  // Additionally,we've set the default to be hidden.
  if (true) {
    return null;
  }
  return (
    <Button blue subSmall marginLeft onClick={() => setIsSiderOpen(true)}>
      Class Walkthrough
    </Button>
  );
};

export default OpenSider;
