/* eslint-disable import/prefer-default-export */
import axios, { AxiosResponse } from 'axios';
import { SkillRecord } from '@components/Component/Lesson/LessonContainer/Types';
import { handleError } from './utils';

export const getSkills = async (
  subjectId: string | null = null,
  standardIds: string[] = [],
): Promise<SkillRecord[] | undefined> => {
  let url = `/api/skills/`;

  const params = new URLSearchParams();

  if (subjectId) {
    params.append('curriculumsubject_id', subjectId.toString());
  }

  standardIds.forEach((standardId) => {
    params.append('standard_id', standardId.toString());
  });

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  try {
    const response: AxiosResponse = await axios.get(url);
    return response.data;
  } catch (error) {
    handleError(error);
    return undefined;
  }
};

export const getSubjectsSkills = async (
  subjectIds: number[] = [],
): Promise<SkillRecord[] | undefined> => {
  let url = `/api/skills/by-subject/`;

  const params = new URLSearchParams();

  subjectIds.forEach((subjectId) => {
    params.append('curriculumsubject_id', subjectId.toString());
  });

  if (params.toString()) {
    url += `?${params.toString()}`;
  }

  try {
    const response: AxiosResponse = await axios.get(url);
    return response.data;
  } catch (error) {
    handleError(error);
    return undefined;
  }
};
