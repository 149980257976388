/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';
import {
  SendInvitesWrapper,
  TextInContent,
  PlusSignInContent,
  FieldsTextInContent,
  ButtonWrapper,
  ButtonStyled,
  ErrorText,
  ContentWrapper,
} from './InviteStudentsModal.style';
import InviteStudentTable from './InviteStudentTable';

const initialValues = {
  listStudent: [],
};

const validationSchema = Yup.object().shape({
  listStudent: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().trim().required('Required'),
        grade: Yup.string().trim().required('Required'),
        email: Yup.string().required('Required').email('Invalid Email'),
      }),
    )
    .min(1, 'You should add at least 1 student to continue to Send Invites'),
});

export default class SendInvitesTab extends PureComponent {
  handleSubmit = (values) => {
    if (values.listStudent.length === 0) return;

    const { submitInviteStudent, sectionId, callbackFunction } = this.props;
    const clonedListStudent = values.listStudent.map((student) => {
      const clonedStudent = {};
      clonedStudent.firstName = student.firstName;
      clonedStudent.lastName = student.lastName;
      clonedStudent.gradeLevel = student.grade.grade_level;
      clonedStudent.email = student.email;
      return clonedStudent;
    });
    submitInviteStudent(clonedListStudent, sectionId, callbackFunction);
  };

  validateBeforeSubmit = (values) => {
    const { listStudent = [] } = values;
    let dublicateEmailsCount = 0;
    const tmpObj = {};
    const errors = {};

    listStudent.forEach((student) => {
      const { email } = student;

      if (tmpObj[email]) {
        dublicateEmailsCount += 1;
      } else {
        tmpObj[email] = true;
      }
    });

    if (dublicateEmailsCount > 0) {
      if (dublicateEmailsCount === 1) {
        errors.duplicateEmail = 'There is one duplicate email.';
      } else {
        errors.duplicateEmail = `There are ${dublicateEmailsCount} duplicate emails.`;
      }
    }

    return errors;
  };

  renderFormContent = (formProps) => {
    const studentList = _get(formProps, 'values.listStudent', []);
    const { errors } = formProps;

    return (
      <ContentWrapper>
        <SendInvitesWrapper>
          <TextInContent>
            Copy/Paste your student roster data or click
            {' '}
          </TextInContent>
          <PlusSignInContent>+</PlusSignInContent>
          <TextInContent> to add manually. Fields are </TextInContent>
          <FieldsTextInContent>
            First Name, Middle Name, Last Name, Grade Level, Email.
          </FieldsTextInContent>

          <Form noValidate>
            <InviteStudentTable />
          </Form>
        </SendInvitesWrapper>

        <ButtonWrapper onClick={formProps.handleSubmit}>
          <ButtonStyled>Send Invites</ButtonStyled>
        </ButtonWrapper>
        {
          studentList.length === 0 && (
            <ErrorText>{ errors.listStudent }</ErrorText>
          )
        }
        {
          errors.duplicateEmail && (
            <ErrorText>{ errors.duplicateEmail }</ErrorText>
          )
        }
      </ContentWrapper>
    );
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={this.renderFormContent}
        onSubmit={this.handleSubmit}
        validate={this.validateBeforeSubmit}
      />
    );
  }
}

SendInvitesTab.propTypes = {
  submitInviteStudent: PropTypes.func.isRequired,
  callbackFunction: PropTypes.func,
  sectionId: PropTypes.number,
};
