import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {
  TableContentItem,
  TableContentGroup,
  TableHeadWrapper,
  TableContentWrapper,
} from '../Table/TableComponent.style';
import { TableContent, TableHeader } from '../Table/TableComponent';
import { ActionGroup, Button } from '../Generals/stylesheets/Button.style';
import { ColumnSizeTeacherInvite } from './ColumSize';
import { formatDateByTime } from '../../utils/func-utils';
import {
  PrimaryWrap,
  DefaultContentWrap,
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';
import {
  RegisterMessageContent,
  RegisterCodeStyled,
} from '../StudentRegister/StudentRegister.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxHeight: 'calc(100% - 100px',
    maxWidth: '640px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    backgroundColor: '#f4f5f7',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class ListTeacherTable extends React.PureComponent {
  onSubmit = (values, { resetForm }) => {
    this.props.onSubmit(values);
    resetForm();
  };

  renderEmptyMsg = () => <div>Don&#39;t have any teacher in this course</div>;

  renderTeacherListHeader = () => (
    <TableHeadWrapper>
      <TableHeader {...ColumnSizeTeacherInvite[0]} value="User" />
      <TableHeader {...ColumnSizeTeacherInvite[3]} value="Actions" />
    </TableHeadWrapper>
  );

  handleInvite = (id, teacherInfo) => {
    const { inviteIntoSection } = this.props;
    inviteIntoSection(id, teacherInfo);
  };

  renderActionButtons = (id, teacherInfo) => (
    <ActionGroup>
      <Button
        onClick={() => this.handleInvite(id, teacherInfo)}
        smallest
        noBackground
      >
        Add
      </Button>
    </ActionGroup>
  );

  renderTeacherList = (teacherInfo) => (
    <TableContentItem smallSize noBorder key={teacherInfo.id}>
      <TableContent {...ColumnSizeTeacherInvite[0]}>
        <TableContentGroup>
          <p>{`${teacherInfo.first_name} ${teacherInfo.last_name}`}</p>
          <span>{teacherInfo.email}</span>
        </TableContentGroup>
      </TableContent>
      <TableContent {...ColumnSizeTeacherInvite[3]}>
        {this.renderActionButtons(teacherInfo.id, teacherInfo)}
      </TableContent>
    </TableContentItem>
  );

  render() {
    const {
      teacherCode, isOpen, onClose, teacherNotInSection,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <BlockWrap>
          <BlockTitle>Teacher Code</BlockTitle>
          <p>Teachers can join the section using the section teacher code.</p>
          <RegisterCodeStyled big>{teacherCode}</RegisterCodeStyled>
        </BlockWrap>
        {!teacherNotInSection.length ? (
          this.renderEmptyMsg()
        ) : (
          <React.Fragment>
            {this.renderTeacherListHeader()}
            <TableContentWrapper>
              {teacherNotInSection.map(this.renderTeacherList)}
            </TableContentWrapper>
          </React.Fragment>
        )}
      </Modal>
    );
  }
}

ListTeacherTable.propTypes = {
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  teacherCode: PropTypes.string,
  teacherNotInSection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  inviteIntoSection: PropTypes.func.isRequired,
};

export default ListTeacherTable;
