import Dialog from '@components/Atoms/Dialog';
import Skeleton from '@components/Atoms/Skeleton';
import Passages from '@components/QuestionGenerationPassages';
import { Passage } from '@components/QuestionGenerationPassages/types';
import { Box } from '@mui/material';
import React, { Suspense } from 'react';

const PassageSearchDialog = ({
  open,
  setOpen,
  setPassageObject,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  setPassageObject: (passage: Passage) => void;
}) => (
  <Dialog
    title="Search for Passages"
    maxWidth="xl"
    fullWidth
    open={open}
    onClose={() => {
      setOpen(false);
    }}
    actions={[
      {
        label: 'Close',
        onClick: () => {
          setOpen(false);
        },
        buttonProps: {
          variant: 'contained',
        },
      },
    ]}
  >
    <Suspense
      fallback={
        <Skeleton
          sx={{
            height: '25rem',
            width: '100%',
          }}
        />
      }
    >
      <Box sx={{ height: '75vh', width: '100%' }}>
        <Passages
          selectPassageCallback={(passage: Passage) => {
            setPassageObject(passage);
            setOpen(false);
          }}
          onCloseCallback={() => setOpen(false)}
          inDialog
        />
      </Box>
    </Suspense>
  </Dialog>
);

export default PassageSearchDialog;
