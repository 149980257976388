import React from 'react';
import Box from '@components/Atoms/Box';
import Button from '@components/Atoms/Button';
import Typography from '@components/Atoms/Typography';
import Card from '@components/Atoms/Card';
import {
  useNavigateToPractice,
  useNavigateToQuestionSelector,
  useNavigateToQuestionSelectorPreview,
  useNavigateToSessionResults,
} from '@components/StandardsPractice/hooks';
import { useExamScore } from '@reducers/sections/hooks';
import { useIsDistrictAdmin, useIsTeacher } from '@reducers/user/hooks';
import ShowIf from '@components/Atoms/ShowIf';
import { usePracticeTotalScoreCalculator } from '@components/Component/Lesson/LessonContainer/hooks';
import CreateIcon from '@mui/icons-material/Create';
import Tooltip from '@components/Atoms/Tooltip';
import usePersistShow from '@utils/hooks/usePersistShow';
import { useGetLearnosityDistrictQuestions } from '@components/QuestionSelector/hooks';
import Accordion from '@components/Atoms/Accordion';
import Paper from '@components/Atoms/Paper';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import FiveProgressMeter from '@components/Atoms/FiveProgressMeter';
import {
  useGetLessonDetail,
  useGetLearnosityAdaptivePracticeQuestions,
} from '../../hooks/query';
import QuestionSelectorDialog from './QuestionSelectorDialog';
import SelectQuestionTooltip from './SelectQuestionTooltip';
import LearnosityAdaptivePracticePreviewButton from './LearnosityAdaptivePracticePreviewButton';
import { useGetSectionLessonSkillLevels } from './hooks';

const LearnosityAdaptivePracticeCard = () => {
  const goToPractice = useNavigateToPractice(undefined, 'adaptive');
  const goToSessionResults = useNavigateToSessionResults();
  const { data: lessonDetail } = useGetLessonDetail();
  const goToQuestionSelector = useNavigateToQuestionSelector();
  const goToDistrictQuestionsPreview = useNavigateToQuestionSelectorPreview();
  const { data: adaptiveQuestions } = useGetLearnosityAdaptivePracticeQuestions();
  const { data: districtQuestions } = useGetLearnosityDistrictQuestions();
  const hasDistrictQuestions = !!districtQuestions?.references?.length;
  const hasAdaptiveQuestions = !!adaptiveQuestions?.length;
  const { data: skillLevels } = useGetSectionLessonSkillLevels();

  const skillDetails = (lessonDetail?.skills || []).map((skill) => {
    const skillLevel = skillLevels?.per_skill[skill.id] || 1;
    return {
      name: skill.name,
      skillLevel,
    };
  });

  const { totalScore, totalMaxScore } = usePracticeTotalScoreCalculator();

  const isTeacher = useIsTeacher();
  const isDistrictAdmin = useIsDistrictAdmin();
  const scoreSummary = useExamScore();
  const studentHasPracticed = scoreSummary.length > 0;
  const [open, setOpen] = React.useState(false);
  const { show, hide } = usePersistShow('select-question-dialog');
  const handleProceedWithCustomQuestions = () => {
    goToQuestionSelector();
  };
  const handleSelectQuestion = () => {
    if (!show) {
      handleProceedWithCustomQuestions();
      return;
    }
    if (show) {
      setOpen(true);
    }
  };

  return (
    <ScrollWrapper sx={{ maxHeight: 'calc(100vh - 119px)' }}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          minHeight: '50%',
          py: '3rem',
          width: '100%',
        }}
      >
        <Card
          sx={{
            border: '1px solid #cccccc29',
            boxShadow: '0px 0px 6px #00000029',
            minWidth: '400px',
            paddingInline: '24px',
            pb: '0.5rem',
            pt: '2rem',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            <Typography dataTestId="lesson-name" variant="h3" sx={{ width: '100%' }}>
              {lessonDetail?.name}
            </Typography>
            <Paper
              sx={{
                alignItems: 'center',
                alignSelf: 'center',
                border: (theme) => `solid 0.125rem ${theme.palette.primary.main}`,
                borderRadius: '1rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                height: '6rem',
                justifyContent: 'center',
                mt: '1rem',
                padding: '1rem',
                width: '6rem',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  color: '#2b1a08',
                  fontSize: '1rem',
                  textTransform: 'uppercase',
                }}
              >
                Score
              </Typography>
              <Typography
                dataTestId="practice-total-score"
                variant="h3"
                sx={{ color: '#2b1a08', fontSize: '1rem' }}
              >
                <ShowIf If={studentHasPracticed}>
                  {`${totalScore} / ${totalMaxScore}`}
                </ShowIf>
                <ShowIf If={!studentHasPracticed}>-- / --</ShowIf>
              </Typography>
            </Paper>
            <ShowIf If={!!skillLevels?.mean_skill_level}>
              <FiveProgressMeter
                level={skillLevels?.mean_skill_level || 1}
                title="average Skill Level"
                data-testid="mean-skill-level"
              />
            </ShowIf>
            <ShowIf If={!!skillDetails.length}>
              <Box
                sx={{
                  borderBottom: 'solid 1px #ccc',
                  borderTop: 'solid 1px #ccc',
                  width: '100%',
                }}
              >
                <Accordion
                  data-testid="skill-details"
                  title={
                    <Typography variant="h3" sx={{ color: '#2b1a08', fontSize: '1rem' }}>
                      Breakdown of Skills
                    </Typography>
                  }
                  accordionSx={{ border: 'none', boxShadow: 'none' }}
                  summarySx={{ color: 'black', fontSize: '1rem' }}
                  content={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.25rem',
                        width: '100%',
                      }}
                    >
                      {skillDetails?.map((item) => (
                        <React.Fragment key={item.name}>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '0.5rem',
                              width: '100%',
                            }}
                          >
                            <Typography dataTestId="skill-name" variant="subtitle1">
                              {item.name}
                            </Typography>

                            <FiveProgressMeter
                              level={item.skillLevel}
                              title="skill level"
                              data-testid="skill-level"
                            />
                          </Box>
                        </React.Fragment>
                      ))}
                    </Box>
                  }
                />
              </Box>
            </ShowIf>
            <Box
              sx={{
                display: 'flex',
                gap: '1rem',
                justifyContent: 'flex-end',
                paddingBottom: '.5rem',
                width: '100%',
              }}
            >
              {/* teacher */}
              <ShowIf If={isTeacher}>
                <ShowIf If={hasDistrictQuestions}>
                  <Typography variant="body3" dataTestId="no-district-questions-preview">
                    Your district admin has created an assessment for this lesson.
                  </Typography>
                </ShowIf>
                <ShowIf If={!hasDistrictQuestions && hasAdaptiveQuestions}>
                  <LearnosityAdaptivePracticePreviewButton />
                </ShowIf>
              </ShowIf>
              {/* student */}
              <ShowIf If={!isTeacher}>
                <Box sx={{ width: '100%' }}>
                  <Button
                    color="play"
                    sx={{ width: '100%' }}
                    variant="contained"
                    dataTestId="start-practice-button"
                    onClick={goToPractice}
                  >
                    Start Practice
                  </Button>
                </Box>
              </ShowIf>
              <ShowIf If={studentHasPracticed && !isTeacher}>
                <Box sx={{ width: '100%' }}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    dataTestId="review-scores-button"
                    onClick={goToSessionResults}
                  >
                    Review Scores
                  </Button>
                </Box>
              </ShowIf>
              {/* district admin */}
              {/* hidden temporarily check the hall commit */}
              <ShowIf If={isDistrictAdmin && false}>
                <Tooltip title={<SelectQuestionTooltip />} arrow>
                  {/* the span to make the tooltip always works */}
                  <span>
                    <Button
                      sx={{ width: '100%' }}
                      variant="outlined"
                      dataTestId="select-question-button"
                      startIcon={<CreateIcon />}
                      onClick={handleSelectQuestion}
                    >
                      Select Questions
                    </Button>
                  </span>
                </Tooltip>
                <ShowIf If={hasDistrictQuestions}>
                  <Button
                    variant="contained"
                    dataTestId="preview-district-practice-button"
                    onClick={goToDistrictQuestionsPreview}
                  >
                    Preview
                  </Button>
                </ShowIf>
                <ShowIf If={!hasDistrictQuestions && hasAdaptiveQuestions}>
                  <LearnosityAdaptivePracticePreviewButton />
                </ShowIf>
              </ShowIf>
            </Box>
          </Box>
        </Card>
        <QuestionSelectorDialog
          open={open}
          onClose={() => setOpen(false)}
          onConfirm={() => {
            hide();
            setOpen(false);
            handleProceedWithCustomQuestions();
          }}
        />
      </Box>
    </ScrollWrapper>
  );
};

export default LearnosityAdaptivePracticeCard;
