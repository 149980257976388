import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import AnswerBubbleGridChoice from '../../containers/OfflineTestAnswerSheet/AnswerBubbleGridChoice';
import AnswerBubbleSingleChoice from '../../containers/OfflineTestAnswerSheet/AnswerBubbleSingleChoice';
import {
  AnswerSheetWrap,
  AnswerSheetListWrap,
  AnswerSheetHeaderWrap,
  AnswerSheetNumber,
  IconToggleSheet,
  AnswerTestGroupAction,
} from './OfflineTestAnswerSheet.style';
import { Button } from '../Generals/stylesheets/Button.style';
import { QUESTION_TYPES } from '../../utils/enums';
import AnswerShortTextField from './AnswerShortTextField';

const scrollStyle = {
  height: 'calc(100vh - 14em)',
};

const scrollStyleMobile = {
  height: '32vh',
  width: '100%',
};

class OfflineTestAnswerSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowSelected: 0,
    };
  }

  onKeyUpForTab = (keyName) => {
    const { questions } = this.props;
    const { rowSelected } = this.state;
    const questionLength = questions.length;

    if (keyName === 'tab' && rowSelected < questionLength - 1) {
      this.setState({
        rowSelected: rowSelected + 1,
      });
    }

    if (keyName === 'shift+tab' && rowSelected > 0) {
      this.setState({
        rowSelected: rowSelected - 1,
      });
    }
  };

  selectRow = (questionIndex) => {
    this.setState({
      rowSelected: questionIndex,
    });
  };

  renderHeaderSheet = () => {
    const { questions = [], toggleAnswerSheet, isAnswerSheetOpen } = this.props;
    const totalQuestion = questions.length;

    return (
      <AnswerSheetHeaderWrap>
        <IconToggleSheet
          className="chalktalk-double-pre"
          isRotate={isAnswerSheetOpen}
          onClick={toggleAnswerSheet}
        />
        <AnswerSheetNumber>{`${totalQuestion} Questions`}</AnswerSheetNumber>
      </AnswerSheetHeaderWrap>
    );
  };

  renderQuestionItem = (question, index) => {
    const { question_format: questionFormat, order, id } = question;
    const { isAnswerSheetOpen, examChooseAnswer } = this.props;
    const { rowSelected } = this.state;

    switch (questionFormat) {
      case QUESTION_TYPES.SHORT_ANSWER:
        return (
          <AnswerShortTextField
            order={Math.max(order, index)}
            defaultValue={question?.value}
            key={order}
            onFocus={() => this.selectRow(Math.max(order - 1, index))}
            onChange={(value) => {
              const trimmedValue = value.trim();
              const isEmpty = trimmedValue === '';

              const payload = {
                exam_question_id: id,
                value: trimmedValue,
                response_time: 0,
                delete: isEmpty,
              };
              examChooseAnswer(id, payload);
            }}
          />
        );
      case QUESTION_TYPES.SINGLE_CHOICE:
      case QUESTION_TYPES.MULTIPLE_CHOICES:
        return (
          <AnswerBubbleSingleChoice
            questionIndex={Math.max(order - 1, index)}
            key={order}
            question={question}
            rowSelected={rowSelected}
            isAnswerSheetOpen={isAnswerSheetOpen}
            onKeyUpForTab={this.onKeyUpForTab}
            selectRow={this.selectRow}
          />
        );
      case QUESTION_TYPES.GRID_IN:
        return (
          <AnswerBubbleGridChoice
            questionIndex={Math.max(order - 1, index)}
            key={order}
            question={question}
            rowSelected={rowSelected}
            isAnswerSheetOpen={isAnswerSheetOpen}
            onKeyUpForTab={this.onKeyUpForTab}
            selectRow={this.selectRow}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { questions = [], isAnswerSheetOpen } = this.props;

    return (
      <AnswerSheetWrap isOpen={isAnswerSheetOpen}>
        {this.renderHeaderSheet()}
        <MediaQuery maxWidth={768}>
          {(matches) => (
            <ShadowScrollbar autoHide style={matches ? scrollStyleMobile : scrollStyle}>
              <AnswerSheetListWrap flexWrap={isAnswerSheetOpen}>
                {questions.map(this.renderQuestionItem)}
              </AnswerSheetListWrap>
            </ShadowScrollbar>
          )}
        </MediaQuery>

        <AnswerTestGroupAction>
          <Button onClick={this.props.completeTest}>Submit</Button>
        </AnswerTestGroupAction>
      </AnswerSheetWrap>
    );
  }
}

OfflineTestAnswerSheet.propTypes = {
  questions: PropTypes.array,
  completeTest: PropTypes.func,
  toggleAnswerSheet: PropTypes.func,
  isAnswerSheetOpen: PropTypes.bool,
};

export default OfflineTestAnswerSheet;
