import { connect } from 'react-redux';
import TestRoute from '../../components/routes/TestRoute';
import {
  selectors as AuthSelectors,
} from '../../reducers/auth';
import {
  selectors as examSelectors,
} from '../../reducers/exam';

const mapStateToProps = state => ({
  authenticated: AuthSelectors.getAuthenticatedStatus(state),
  responseId: examSelectors.getResponseId(state),
});

export default connect(mapStateToProps)(TestRoute);
