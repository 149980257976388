import React from 'react';
import { usePracticeData } from '@components/PracticeV2/Provider/PracticeDataProvider';
import { usePractice } from '@components/PracticeV2/Provider/PracticeProvider';
import { PracticeState } from '@components/PracticeV2/types';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { GroupSheetContainer } from './PracticeLayout.style';
import usePracticeGoBack from '../../hooks/usePracticeGoBack';
import IndividualWaitingDialog from '../IndividualWaitingDialog';
import PracticeWizard from '../PracticeWizard';
import GroupModeStartDialog from '../GroupModeStartDialog';
import PracticeContent from '../PracticeContent';

// note : PracticeTopNavBar status is a modification that we will make it static for now
// it will fire a toast on status 1 or 2

const PracticeLayout = () => {
  const goBack = usePracticeGoBack();
  const { duration, individualDuration, startedDate } = usePracticeData();
  const { state, handleTimeOut } = usePractice();
  return (
    <GroupSheetContainer>
      <PracticeTopNavBar
        goBack={goBack}
        onTimeOut={handleTimeOut}
        duration={
          state.practiceState === PracticeState.Individual ? individualDuration : duration
        }
        startTime={startedDate}
      />
      <PracticeContent />
      <PracticeWizard />
      <IndividualWaitingDialog />
      <GroupModeStartDialog />
    </GroupSheetContainer>
  );
};

export default React.memo(PracticeLayout);
