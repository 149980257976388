import { connect } from 'react-redux';
import UnitPracticeReview from '../../components/UnitPracticeReview';
import { actions as PracticeActions, selectors as PracticeSelectors } from '../../reducers/practice';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionUnitSelectors } from '../../reducers/sectionUnit';

const mapStateToProps = (state, { match }) => {
  const { params } = match;
  const { unitId } = params;
  return ({
    report: PracticeSelectors.getReport(state),
    isInitialized: PracticeSelectors.isInitialized(state),
    practiceId: PracticeSelectors.getPracticeId(state),
    currentUser: UserSelectors.getCurrentUser(state),
    currentUserId: UserSelectors.getUserId(state),
    lessons: SectionUnitSelectors.getAllLessonOfUnit(state, unitId),
  });
};

const mapDispatchToProps = {
  practiceSessionReport: PracticeActions.practiceSessionReport,
  initializePracticeSession: PracticeActions.initializePracticeSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitPracticeReview);
