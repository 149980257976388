import React, { useContext } from 'react';
import { AssessmentContext } from './LearnosityProvider';

const useServiceContext = () => {
  const context = useContext(AssessmentContext);
  if (!context) {
    throw new Error('useServiceContext must be used within a AssessmentProvider');
  }
  return context;
};

export default useServiceContext;
