import axios from 'axios';
import { handleError } from './utils';

export function getExamDetail(sessionExamId) {
  const url = `/api/sessions/exams/${sessionExamId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getExamResult(examSessionId) {
  const url = `/api/sessions/exams/${examSessionId}/scores/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getExamStudentResult(examSessionId) {
  const url = `/api/sessions/exams/${examSessionId}/scores/me/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getStudentSectionReport(sessionId, examSectionId, studentId) {
  const url = `/api/sessions/exams/${sessionId}/section/${examSectionId}/report/${studentId}/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function initializeSectionResponse(sessionId, examData) {
  const url = `/api/sessions/exams/${sessionId}/initialize/`;

  return axios
    .post(url, examData)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function takeSectionResponse(sessionId, examData) {
  const url = `/api/sessions/exams/${sessionId}/take/`;

  return axios
    .post(url, examData)
    .then((response) => ({ response }))
    .catch((error) => error);
}

export function submitQuestionResponse(sessionId, examData) {
  const url = `/api/sessions/exams/${sessionId}/submit_question_response/`;

  return axios
    .post(url, examData)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function completeSectionResponse(sessionId, examData) {
  const url = `/api/sessions/exams/${sessionId}/complete/`;

  return axios
    .post(url, examData)
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
}

export function unlockSessionExam(examSessionId, examSectionId) {
  const url = `/api/sessions/exams/${examSessionId}/${examSectionId}/unlock/`;

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function pauseSessionExam(examSessionId, examSectionId) {
  const url = `/api/sessions/exams/${examSessionId}/${examSectionId}/pause/`;

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function resetSessionExamSection(examSessionId, examSectionId, studentId) {
  let url = `/api/sessions/exams/${examSessionId}/${examSectionId}/reset-exam/`;

  if (studentId) {
    url = `/api/sessions/exams/${examSessionId}/${examSectionId}/reset-exam/?student=${studentId}`;
  }

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function resetSessionExam(examSessionId, examSectionId, studentId) {
  let url = `/api/sessions/exams/${examSessionId}/${examSectionId}/reset/`;

  if (studentId) {
    url = `/api/sessions/exams/${examSessionId}/${examSectionId}/reset/?student=${studentId}`;
  }

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function updateSectionOfExam(sessionId, sectionExamId, sessionExamInfo) {
  const url = `/api/sessions/exams/${sessionId}/${sectionExamId}/update/`;
  return axios
    .post(url, sessionExamInfo)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function updateSessionExam(sessionId, sessionExamInfo) {
  const url = `/api/sessions/exams/${sessionId}/`;
  return axios
    .put(url, sessionExamInfo)
    .then((response) => ({ response }))
    .catch(handleError);
}

export function getUserSectionScoreReport(sessionId, examSectionId) {
  const url = `/api/sessions/exams/${sessionId}/section/${examSectionId}/report/me/`;
  return axios
    .get(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function unlockSessionExamReview(examSessionId, examSectionId) {
  const url = `/api/sessions/exams/${examSessionId}/${examSectionId}/enable_review/`;

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
export function lockSessionExamReview(examSessionId, examSectionId) {
  const url = `/api/sessions/exams/${examSessionId}/${examSectionId}/disable_review/`;

  return axios
    .post(url)
    .then((response) => ({ response }))
    .catch(handleError);
}
export async function fetchReport(URL) {
  const response = await fetch(URL);
  return response;
}

export function getExamScaledScore(examSessionId) {
  const url = `api/sessions/exams/${examSessionId}/scaled-scored/me/`;

  return axios.get(url).then((response) => ({ response }));
}
