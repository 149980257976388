import React from 'react';
import FromTo from './FromTo';
import Paper from '../Paper';
import Typography from '../Typography';

type Props = {
  from: React.ReactNode;
  to: React.ReactNode;
  title: string;
  'data-testid'?: string;
};

const FromToBlock = ({ from, to, title, 'data-testid': testid }: Props) => (
  <Paper
    data-testid={testid}
    elevation={2}
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '1rem',
      gap: '0.25rem',
      padding: '1rem',
      width: '100%',
    }}
  >
    <Typography
      variant="body1"
      sx={{
        textTransform: 'capitalize',
        width: '100%',
      }}
    >
      {title}
    </Typography>
    <FromTo from={from} to={to} />
  </Paper>
);

export default FromToBlock;
