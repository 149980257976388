import React from 'react';
import Box from '@components/Atoms/Box';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Button from '@components/Atoms/Button';
import ShowIf from '@components/Atoms/ShowIf';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MODES } from './Passages';
import { Passage } from './types';

const PassageHeader: React.FC<{
  mode: string;
  switchMode: (mode: string, passage: Passage | null) => void;
  close?: () => void;
  inDialog?: boolean;
}> = ({ mode, switchMode, close, inDialog }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1rem' }}>
    <ShowIf If={!inDialog}>
      <Typography variant="subtitle1">
        View, create, and edit passages for your questions.
      </Typography>
    </ShowIf>

    <Box>
      <ShowIf If={mode !== MODES.LIST_MODE}>
        <Button
          variant="contained"
          sx={{ mr: '0.5rem' }}
          onClick={() => switchMode(MODES.LIST_MODE, null)}
        >
          <Typography variant="caption">Back to Passage List</Typography>
          <ArrowBackIosIcon sx={{ fontSize: '1rem', ml: '0.5rem' }} />
        </Button>
      </ShowIf>

      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          close?.();
        }}
      >
        <Typography variant="caption">Close</Typography>
      </Button>
    </Box>
  </Box>
);

export default PassageHeader;
