import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getExamDetail,
  getExamResult,
  getExamScaledScore,
  getExamStudentResult,
} from '@apis/exam';
import { useIsTeacher } from '@reducers/user/hooks';
import { getSectionEstimatedScores } from '@apis/sections';
import { useCurrentSection } from '@reducers/sections/hooks';
import { ExamDetailsObject, IExamResults, ScaledScoreData } from '../ExamScoreCard/types';

export const useGetExamScaledScore = () => {
  const { examSessionId } = useParams();

  const queryResponse = useQuery<ScaledScoreData>(
    ['getExamScaledScore', examSessionId],
    async () => {
      const { response } = (await getExamScaledScore(examSessionId)) as unknown as {
        response: { data: ScaledScoreData };
      };

      return response.data || [];
    },
    {
      suspense: true,
      useErrorBoundary: true,
      enabled: !!examSessionId,
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
    },
  );

  return queryResponse;
};

export const useGetExamDetail = () => {
  const { examSessionId } = useParams();
  const section = useCurrentSection();
  const activeExamSessionId = examSessionId || section.exams?.[0];
  if (!activeExamSessionId) {
    console.error('examSessionId is not defined in useGetExamDetail');
  }

  const queryResponse = useQuery<ExamDetailsObject>(
    ['getExamDetails', activeExamSessionId],
    async () => {
      const { response } = (await getExamDetail(activeExamSessionId)) as unknown as {
        response: { data: ExamDetailsObject };
      };

      return response.data || {};
    },
    {
      suspense: true,
      enabled: !!activeExamSessionId,
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
    },
  );

  return queryResponse;
};

export const useGetExamResult = () => {
  const { examSessionId } = useParams();

  const queryResponse = useQuery<Array<IExamResults>>(
    ['getExamResult', examSessionId],
    async () => {
      const { response } = (await getExamResult(examSessionId)) as unknown as {
        response: { data: Array<IExamResults> };
      };

      return response.data || {};
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!examSessionId,
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
    },
  );

  return queryResponse;
};
export const useGetExamStudentResult = () => {
  const { examSessionId } = useParams();
  const isTeacher = useIsTeacher();

  const queryResponse = useQuery<IExamResults>(
    ['getExamStudentResult', examSessionId],
    async () => {
      const { response } = (await getExamStudentResult(examSessionId)) as unknown as {
        response: { data: IExamResults };
      };

      return response.data || {};
    },
    {
      useErrorBoundary: true,
      suspense: true,
      enabled: !!examSessionId && !isTeacher,
      refetchOnMount: 'always',
      staleTime: 1000 * 60 * 15,
    },
  );

  return queryResponse;
};

export const useGetExamEstimatedScore = () => {
  const { sectionId } = useParams();

  const queryResponse = useQuery<any>(
    ['getSectionEstimatedScores', sectionId],
    async () => {
      try {
        const { response } = (await getSectionEstimatedScores(sectionId)) as unknown as {
          response: { data: any };
        };

        return response.data || [];
      } catch (error) {
        return [];
      }
    },
    {
      suspense: true,
      enabled: !!sectionId,
      staleTime: 1000 * 60 * 15,
    },
  );

  return queryResponse;
};
