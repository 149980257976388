export const cardBaseStyle = {
  display: 'flex',
  p: 3,
  flexDirection: { xs: 'column', sm: 'row', md: 'row' },
  pb: 5,
};
export const practiceInfoWrapperStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: { xs: 'column', sm: 'row', md: 'row' },
} as const;
export const actionCardBodyStyle = {
  width: '100%',
  display: 'flex',
  gap: 2,
  flexDirection: 'column',
} as const;
export const mediaCardStyle = { width: '6.6875rem', height: '6.6875rem' } as const;
export const mediaCardWrapperStyle = { paddingInlineEnd: 4 } as const;
export const typographyCustomStyle = {
  pt: 1,
  overflowWrap: 'anywhere',
} as const;

export const buttonsWrapperBoxStyle = {
  display: 'flex',
  gap: 1,
  flexDirection: { xs: 'column', sm: 'row', md: 'row' },
  flexWrap: 'wrap',
} as const;
