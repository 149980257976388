import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import Modal from 'react-modal';
import {
  GroupDiscussionContent,
  GroupDiscussionItem,
  GroupDiscussionTitle,
  GroupDiscussionWrapper,
} from './GroupDiscussionModal.style';
import { ActionGroup, Button } from '../../Generals/stylesheets/Button.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
    width: '541px',
    maxHeight: 'calc(100vh - 30px)',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const mobileModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    borderRadius: '0px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

const GroupDiscussionModal = (props) => (
  <MediaQuery maxWidth={768}>
    {(matches) => (
      <Modal
        isOpen={props.isModalOpen}
        onRequestClose={props.onCloseModal}
        style={matches ? mobileModalStyles : modalStyles}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <GroupDiscussionWrapper>
          <GroupDiscussionItem>
            <GroupDiscussionTitle>
              {props.endAcitivityModal ? (
                <h1>Group Activity Complete</h1>
              ) : (
                <h1>Group Discussion</h1>
              )}
            </GroupDiscussionTitle>
            {props.endAcitivityModal ? (
              <GroupDiscussionContent>
                <p>
                  Your {props.currentGroupStudents?.length > 1 ? 'and group' : ' '}{' '}
                  answers have been submitted.
                </p>
                <p>
                  Your teacher will check your answers and let you know what comes next.
                </p>
              </GroupDiscussionContent>
            ) : (
              <GroupDiscussionContent>
                <p>
                  Discuss each of the questions with your classmates to determine your
                  group's consensus answer.
                </p>
                <p>
                  Everyone should choose the same answer, press <span>Next</span> to
                  continue to the next question, and then repeat the process for each
                </p>
              </GroupDiscussionContent>
            )}
            <ActionGroup center>
              <Button onClick={props.onCloseModal} submit>
                {props.endAcitivityModal ? 'Close' : 'Continue'}
              </Button>
            </ActionGroup>
          </GroupDiscussionItem>
        </GroupDiscussionWrapper>
      </Modal>
    )}
  </MediaQuery>
);
GroupDiscussionModal.prototype = {
  isModalOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  endAcitivityModal: PropTypes.bool,
};

export default GroupDiscussionModal;
