import {
  all, call, put,
  takeLeading,
} from 'redux-saga/effects';
import * as SectionAPIs from '../../apis/sections';
import {
  LESSON_ASSIGNED_ACTIVITIES_GET_ALL,
  actions as LessonAssignedActivitiesActions,
} from '../../reducers/lessonAssignedActivities';

export function* fetchLessonAssignedActivityList({ payload }) {
  const { sectionId, lessonId } = payload;

  try {
    const { response, error } = yield call(SectionAPIs.getUserAssignedActivity, sectionId, lessonId);
    if (error) throw error;

    const { data: lessonAssignedActivitiesList } = response;
    yield put(LessonAssignedActivitiesActions.fetchLessonAssignedActivityListSuccess(lessonAssignedActivitiesList));
  } catch (error) {
    yield put(LessonAssignedActivitiesActions.fetchLessonAssignedActivityListFailed(error.message || error));
  }
}

export default function* lessonAssignedActivitiesSaga() {
  yield all([
    takeLeading(LESSON_ASSIGNED_ACTIVITIES_GET_ALL, fetchLessonAssignedActivityList),
  ]);
}
