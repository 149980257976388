import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, Skeleton } from '@mui/material';
import { PastReportsContainer, ReportLink } from './ReportGenerator.style';
import { BlockTitle, BlockWrap } from '../Generals/stylesheets/General.style';
import { usePastReportsQuery } from './ReportQueries';

const PastReportsList = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { isFetching, data: pastReportsList } = usePastReportsQuery(currentPage);
  return (
    <PastReportsContainer data-testid="pastReportsWrapper">
      <BlockWrap>
        <BlockTitle>
          <span>Past Reports</span>
        </BlockTitle>
        {isFetching ? (
          <Skeleton variant="rectangular" width="80%" height={20} />
        ) : (
          <>
            <ul className="report-list">
              {pastReportsList?.results?.map((report) => (
                <li key={report.id} data-testid={`pastReport-${report.id}`}>
                  <ReportLink>
                    <Link
                      to={{
                        pathname: `/admin/reports/generator/${report.id}`,
                        state: { pdf: report.path, from: '/admin/reports/generator' },
                      }}
                    >
                      <img src="/images/pdf-file.svg" alt="report admin" />
                      <span>{report.report_name}</span>
                    </Link>
                  </ReportLink>
                </li>
              ))}
            </ul>
            <Pagination
              count={pastReportsList ? Math.ceil(pastReportsList.count / 5) : 0}
              page={currentPage}
              onChange={(_, v) => setCurrentPage(v)}
            />
          </>
        )}
      </BlockWrap>
    </PastReportsContainer>
  );
};

export default PastReportsList;
