import { connect } from 'react-redux';
import _get from 'lodash/get';
import {
  actions as CourseStudentActions,
  selectors as CourseStudentSelectors,
} from '../../reducers/courseStudents';
import {
  selectors as CourseTeacherSelectors,
} from '../../reducers/courseTeachers';
import CourseManagementStudents from '../../components/CourseManagementStudents';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import { selectors as userSelectors } from '../../reducers/user';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const ownerIds = CourseTeacherSelectors.getOwnerIds(state);
  const userId = userSelectors.getUserId(state);
  const isOwner = !!ownerIds.find(id => id === userId);

  return {
    isOwner,
    courseDetail,
    studentList: CourseStudentSelectors.getCourseStudentList(state),
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
    isGettingStudentList: CourseStudentSelectors.isGettingStudentList(state),
  };
};

const mapDispatchToProps = {
  fetchCourseStudentList: CourseStudentActions.fetchCourseStudentList,
  removeStudent: CourseStudentActions.removeStudent,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseManagementStudents);
