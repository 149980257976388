import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import OLPGroupsEventEmitter, {
  OLPGroupsEvents,
  MessageFnType,
} from '../utils/OLPGroupsEventEmitter';

const useWebSocketListener = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    const updateGroupStatusSubscription: MessageFnType = (data) => {
      if (!data.section_activity) {
        return;
      }
      queryClient.invalidateQueries(['getActivityGroups', data.section_activity]);
    };

    OLPGroupsEventEmitter.on(
      OLPGroupsEvents.UPDATE_STUDENT_ACTIVITY_STATUS,
      updateGroupStatusSubscription,
    );
    return () => {
      OLPGroupsEventEmitter.off(
        OLPGroupsEvents.UPDATE_STUDENT_ACTIVITY_STATUS,
        updateGroupStatusSubscription,
      );
    };
  }, []);
};

export default useWebSocketListener;
