import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import moment from 'moment';
import { getSearchParamsObject } from '@utils/commonUtils';
import { useIsLearnosityLessonReviewEnabled } from '@reducers/curriculums/hooks';
import Box from '@components/Atoms/Box';
import Button from '@components/Atoms/Button';
import PacingGuideSelect from '@components/Component/Lesson/PacingGuide/PacingGuideSelect';
import ShowIf from '@components/Atoms/ShowIf';
import { Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import { VIEW_BY_OPTIONS } from '@utils/constants';
import ListLoader from '../LoadingPlaceholder/ListLoader';
import UnitLessonItem from '../SubNavBarItem/UnitLessonItem';
import SessionSubNavItem from '../../containers/SubNavBarItem/SessionSubNavItem';
import { UpcomingEmpty } from '../Generals/stylesheets/General.style';
import * as SectionAPI from '../../apis/sections';
import ReviewSubNavItem from '../../containers/ReviewSubNavItem';
import { ButtonCustome } from '../Generals/stylesheets/Button.style';
import UnitPracticeSubNavItem from '../SubNavBarItem/UnitPracticeSubNavItem';
import LessonAssignItem from '../../containers/SubNavbarAssignList/LessonAssignItem';
import {
  NavBarListWrap,
  NavBarTitle,
  NavBarList,
  NavBarAction,
  NavBarTitlePlus,
} from '../SubNavBarItem/SubNavBar.style';
import SessionTypeSelectButton from './SessionTypeSelectButton';

class SubNavbarLessonSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReviewClassEnabled: false,
      subjectUnits: [],
      sectionSessions: {},
      actionSelected: { id: VIEW_BY_OPTIONS.Curriculum, name: 'Curriculum' },
      chooseDefaultReview: false,
      forceLoadState: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    if (location?.state?.assignments) {
      this.setState({
        actionSelected: {
          id: VIEW_BY_OPTIONS.Assignments,
          name: ` Assignments ( ${location?.state?.assignments} )`,
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { unitTree, isSubNavBarClose, match, history, subject } = this.props;

    const { actionSelected, forceLoadState } = this.state;
    const { unitId, lessonId, courseId, sectionId, subjectId, reviewId } = match.params;

    const { subjectUnits } = this.state;
    const { units = [] } = unitTree;

    if (reviewId && actionSelected.id != VIEW_BY_OPTIONS.ReviewClasses) {
      this.setState({
        actionSelected: {
          id: VIEW_BY_OPTIONS.ReviewClasses,
          name: 'Review Classes',
        },
      });
    }
    if (!_isEmpty(subject) && !_isEmpty(units)) {
      let loadData = false;
      if (_isEmpty(subjectUnits)) {
        loadData = true;
      } else if (subjectUnits[0].curriculum_subject != subject.id) {
        loadData = true;
      }
      if (loadData) {
        this.setState({
          subjectUnits: units,
        });
      }
    }

    // Back to the subjects navBar if the subject not included in the section subjects
    // If the subject name is empty that's mean the subject is not on the list
    if (subjectId === undefined && !isSubNavBarClose) {
      history.replace(`/lessons/course/${courseId}/section/${sectionId}`);
    }

    if (
      !match.path.includes('practice') &&
      !(unitId && lessonId) &&
      !_isEmpty(unitTree) &&
      !isSubNavBarClose &&
      (prevProps.unitTree !== unitTree ||
        prevProps.isSubNavBarClose !== isSubNavBarClose ||
        forceLoadState)
    ) {
      const unit = units[0] || {};
      const { lessons = [], curriculum_subject: subjectId } = unit;
      const lesson = lessons[0];
      const queryObject = getSearchParamsObject(history.location.search);
      const tabIndex = queryObject.tab ?? LessonTabs.Lesson;
      const options = this.renderOptions();

      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unit.id}/lesson/${lesson.id}?tab=${tabIndex}`,
      );
      this.setState({
        actionSelected: options[actionSelected.id - 1],
        forceLoadState: false,
      });
    }
  }

  renderLessons = () => {
    const {
      subject,

      match,
      isTeacher,

      practiceSummaryByUnitId,
      isGettingSectionUnit,
      unitBySubject,
      subjectVersion,
      isLearnosityLessonReviewEnabled,
    } = this.props;
    const { isReviewClassEnabled } = this.state;
    if (_isEmpty(unitBySubject)) {
      return;
    }
    const subjectName = _get(subject, 'display_name', '');
    const displayTitle = `${subjectName} Lessons`;
    const { subjectId } = match.params;
    const subjectUnits = unitBySubject[subjectId];
    const units = _get(subjectUnits, 'units', []);
    return (
      <NavBarList>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.5rem',
          }}
        >
          <NavBarTitle lightColor>{displayTitle.trim()}</NavBarTitle>
          {isLearnosityLessonReviewEnabled && isTeacher && (
            <Box
              sx={{
                paddingInline: '1rem',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                size="medium"
                fullWidth
                onClick={() =>
                  this.setState((prev) => ({
                    isReviewClassEnabled: !prev?.isReviewClassEnabled,
                  }))
                }
              >
                {isReviewClassEnabled ? 'Exit Edit Mode' : 'Manage Assessments'}
              </Button>
            </Box>
          )}
        </Box>
        {isGettingSectionUnit ? (
          <ListLoader isLargeItem />
        ) : (
          <UnitLessonItem
            units={units}
            isReviewClassEnabled={isReviewClassEnabled}
            practiceSummaryByUnitId={practiceSummaryByUnitId}
            subjectVersion={subjectVersion}
          />
        )}
      </NavBarList>
    );
  };

  renderSessionItem = (session) => (
    <SessionSubNavItem
      key={session.id}
      sessionItem={session}
      groupActivitySummaryById={this.props.groupActivitySummaryById}
    />
  );

  renderSessionList = (sessionList) => {
    const { subject } = this.props;
    const subjectName = _get(subject, 'display_name', '');
    const displayTitle = `${subjectName} Sessions`;
    sessionList.sort((a, b) => moment(a.date).diff(moment(b.date)));
    sessionList.sort((a, b) => {
      if (moment(a.date).isSame(moment(b.date))) {
        if (a.id < b.id) {
          return -1;
        }
      }
      return 0;
    });

    if (sessionList.length > 0) {
      return (
        <>
          <NavBarTitle lightColor>{displayTitle.trim()}</NavBarTitle>
          <NavBarList hasExtraPadding>
            {sessionList.map(this.renderSessionItem)}
          </NavBarList>
        </>
      );
    }

    return <UpcomingEmpty>No Sessions</UpcomingEmpty>;
  };

  renderReviewItem = (review) => (
    <ReviewSubNavItem subject={this.props.subject} key={review.id} reviewItem={review} />
  );

  navigateToCreateReview = () => {
    const { history, match } = this.props;
    const { courseId, sectionId } = match.params;
    history.push(
      `/schedule/course/${courseId}/section/${sectionId}/create-review-session`,
    );
  };

  renderSessionsItems = () => {
    const { sessionList, match } = this.props;
    const { sectionSessions } = this.state;
    const { subjectId, sectionId } = match.params;
    const sessions = sectionSessions[sectionId];

    if (!_isEmpty(sessionList)) {
      return this.renderSessionList(sessionList);
    }

    if (!_isEmpty(sessions)) {
      const subjectSessions = sessions.filter((session) => session.subject == subjectId);
      return this.renderSessionList(subjectSessions);
    }

    SectionAPI.getSectionLessonSessions(sectionId, subjectId).then((responseData) => {
      const newSectionSessions = responseData.response.data;
      const subjectSessions = newSectionSessions.filter(
        (session) => session.subject == subjectId,
      );

      const new_sessions = sectionSessions;
      new_sessions[sectionId] = newSectionSessions;
      this.setState({
        sectionSessions: new_sessions,
      });
      return this.renderSessionList(subjectSessions);
    });
  };

  renderReviewItems = () => {
    const { reviews, match, reviewList } = this.props;
    const { subjectId } = match.params;

    if (!_isEmpty(reviews)) {
      return this.renderReviewList(reviews);
    }

    if (!_isEmpty(reviewList)) {
      const subjectReviews = reviewList?.filter((review) => review?.subject == subjectId);
      return this.renderReviewList(subjectReviews);
    }
  };

  renderReviewList = (reviews) => {
    const { isTeacher, isGettingSectionReview } = this.props;
    const { chooseDefaultReview } = this.state;

    if (chooseDefaultReview) {
      this.setState({
        chooseDefaultReview: false,
      });

      if (reviews?.length > 0) {
        const { history, match } = this.props;
        const { courseId, sectionId, subjectId } = match.params;
        const review = reviews?.[0];

        history.push(
          `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/review/${review?.id}/`,
        );
      }
    }

    return (
      <NavBarList hasExtraPadding>
        <NavBarTitle>
          {reviews?.length > 0 ? (
            <div>Review Sessions</div>
          ) : (
            <div>
              No reviews scheduled.
              <br />
              {isTeacher ? 'Click “+” to add' : ''}
            </div>
          )}
          {isTeacher && (
            <NavBarTitlePlus onClick={this.navigateToCreateReview}>+</NavBarTitlePlus>
          )}
        </NavBarTitle>
        {isGettingSectionReview ? (
          <ListLoader isLargeItem />
        ) : (
          reviews?.map(this.renderReviewItem)
        )}
        {isTeacher && reviews?.length < 0 && (
          <NavBarAction>
            <ButtonCustome full darkBackground onClick={this.navigateToCreateReview}>
              + Add new review
            </ButtonCustome>
          </NavBarAction>
        )}
      </NavBarList>
    );
  };

  selectSection = (action) => {
    let chooseDefaultReview = false;
    let forceLoadState = false;

    if (action.id === VIEW_BY_OPTIONS.ReviewClasses) {
      chooseDefaultReview = true;
    } else {
      this.props.match.params.reviewId = null;
      forceLoadState = true;
    }

    this.setState({
      actionSelected: action,
      chooseDefaultReview,
      forceLoadState,
    });
  };

  renderOptions = () => {
    const { isStudent, assignList, isLearnosityLessonReviewEnabled } = this.props;
    const options = [
      { id: VIEW_BY_OPTIONS.Curriculum, name: 'Curriculum' },
      { id: VIEW_BY_OPTIONS.Syllabus, name: 'Syllabus' },
      { id: VIEW_BY_OPTIONS.ReviewClasses, name: 'Review Classes' },
    ];
    if (isStudent) {
      const assignOption = ` Assignments ( ${assignList.length} )`;
      options.push({ id: VIEW_BY_OPTIONS.Assignments, name: assignOption });
    }
    if (isLearnosityLessonReviewEnabled) {
      return options.filter((option) => option.id !== VIEW_BY_OPTIONS.ReviewClasses);
    }
    return options;
  };

  backToSubject = () => {
    const { match, history } = this.props;
    const { courseId, sectionId } = match.params;
    this.setState({
      actionSelected: { id: VIEW_BY_OPTIONS.Curriculum, name: 'Curriculum' },
    });
    history.replace(`/lessons/course/${courseId}/section/${sectionId}`);
  };

  renderLessonSubNavItem = (lessonId, unitId) => {
    const {
      lessonActivitySummaryById,
      practiceSummaryByLessonId,
      isTeacher,
      studentList,
      userId,
      sectionAttendanceById,
      sessionList,
    } = this.props;
    const isActive = unitId == unitId && lessonId == lessonId; // eslint-disable-line

    const filterArr = _get(lessonActivitySummaryById, `${lessonId}`, {});
    const isFinish = _get(filterArr, 'completed', false);
    const usersPracticeInfo = _get(practiceSummaryByLessonId, `${lessonId}`, []);
    const currentLesson = sessionList.filter(
      (x) => x.lesson === lessonId && x.unit === unitId,
    );
    let isAttendance = false;

    const attendancList = _get(currentLesson[0], 'attendance', []);
    const sessionId = _get(attendancList[0], 'id', '');
    if (sessionId) {
      const recordList = _get(sectionAttendanceById, `${sessionId}.records`, []);
      isAttendance = !!recordList.filter(
        (record) => record.user === userId && record.present,
      ).length;
    }

    let isFinishPractice = false;
    if (isTeacher) {
      isFinishPractice =
        ((usersPracticeInfo.length / studentList.length) * 10000) / 100 >= 70;
    } else {
      // eslint-disable-next-line
      isFinishPractice = usersPracticeInfo.filter((x) => x.user == userId).length > 0;
    }

    return (
      <LessonAssignItem
        lessonId={lessonId}
        unitId={unitId}
        isSingleItem
        isActive={isActive}
        isFinish={isFinish || isAttendance}
        isFinishPractice={isFinishPractice}
      />
    );
  };

  renderUnitPracticeSubNavItem = (unitId) => {
    const {
      match,
      practiceSummaryByUnitId,
      studentList,
      isTeacher,
      userId,
      unitByIds,
      history,
      subjectVersion,
      subject,
    } = this.props;
    const unitItem = _get(unitByIds, unitId, {});
    const { name, id, curriculum_subject: subjectId, assets = [] } = unitItem;
    const assetLength = assets.length;
    const { params } = match;
    const selectionPracticeList = _get(practiceSummaryByUnitId, `${id}`, []);
    let percent = 0;
    if (subjectVersion !== 1) {
      if (isTeacher) {
        percent =
          studentList.length > 0 && assetLength
            ? Math.round(
                (selectionPracticeList.length / (studentList.length * assetLength)) * 100,
              )
            : 0;
      } else {
        // eslint-disable-next-line eqeqeq
        const practiceStudentList = selectionPracticeList.filter((x) => x.user == userId);
        percent =
          practiceStudentList.length && assetLength
            ? Math.round((practiceStudentList.length / assetLength) * 100)
            : 0;
      }
    } else if (isTeacher) {
      const practiceStudentList = _unionBy(selectionPracticeList, 'user');
      percent =
        studentList.length > 0
          ? Math.round((practiceStudentList.length / studentList.length) * 100)
          : 0;
    } else {
      // eslint-disable-next-line eqeqeq
      const practiceStudentList = selectionPracticeList.filter((x) => x.user == userId);
      percent = practiceStudentList.length ? 100 : 0;
    }
    const isActive = id == params.unitId && !params.lessonId; // eslint-disable-line

    return (
      <UnitPracticeSubNavItem
        key={unitId}
        isActive={isActive}
        name={name}
        percent={percent}
        unitId={unitId}
        subjectId={subject.id}
        match={match}
        history={history}
      />
    );
  };

  renderAssignItem = (item) => {
    const { lesson_group: lessonId, unit: unitId } = item;

    if (lessonId && unitId) {
      return this.renderLessonSubNavItem(lessonId, unitId);
    }

    return this.renderUnitPracticeSubNavItem(unitId);
  };

  renderAllAssigned = () => {
    const { assignList } = this.props;
    return (
      <NavBarList>
        <NavBarTitle>
          <div>
            {assignList.length > 0 ? 'Assigned Lesson' : 'No assignments available.'}
          </div>
        </NavBarTitle>
        <NavBarList hasExtraPadding>{assignList.map(this.renderAssignItem)}</NavBarList>
      </NavBarList>
    );
  };

  render() {
    const { isSubNavBarClose, isStudent, reviewList } = this.props;
    const { actionSelected } = this.state;

    const options = this.renderOptions();
    return (
      <NavBarListWrap isClose={isSubNavBarClose}>
        <Divider sx={{ pt: 2 }} />
        <Box sx={{ px: '1rem', pt: '1rem' }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            fullWidth
            onClick={() => this.backToSubject()}
          >
            Back to Subjects
          </Button>
        </Box>

        <SessionTypeSelectButton selectSection={this.selectSection} options={options} />
        <ShowIf If={actionSelected.id === VIEW_BY_OPTIONS.Curriculum}>
          <PacingGuideSelect />
        </ShowIf>

        <ScrollWrapper autoHide sx={{ height: 'calc(100vh - 18em)', mt: 2 }}>
          {actionSelected.id === VIEW_BY_OPTIONS.Curriculum && this.renderLessons()}
          {actionSelected.id === VIEW_BY_OPTIONS.Syllabus && this.renderSessionsItems()}
          {actionSelected.id === VIEW_BY_OPTIONS.ReviewClasses &&
            !!reviewList.length &&
            this.renderReviewItems()}
          {actionSelected.id === VIEW_BY_OPTIONS.ReviewClasses && !reviewList.length && (
            <NavBarList>
              <NavBarTitle style={{ justifyContent: 'center', margin: 0 }}>
                <div>No review classes are available </div>
              </NavBarTitle>
            </NavBarList>
          )}
          {isStudent &&
            actionSelected.id == VIEW_BY_OPTIONS.Assignments &&
            this.renderAllAssigned()}
        </ScrollWrapper>
      </NavBarListWrap>
    );
  }
}

SubNavbarLessonSessions.propTypes = {
  toggleSubNavBar: PropTypes.func,
  isSubNavBarClose: PropTypes.bool,
  subject: PropTypes.object,
  unitTree: PropTypes.object,
  match: PropTypes.shape(),
  history: PropTypes.shape(),
  lessonActivitySummaryById: PropTypes.object,
  groupActivitySummaryById: PropTypes.object,
  practiceSummaryByLessonId: PropTypes.object,
  isTeacher: PropTypes.bool,
  isStudent: PropTypes.bool,
  studentList: PropTypes.array,
  userId: PropTypes.any,
  practiceSummaryByUnitId: PropTypes.object,
  sessionList: PropTypes.array,
  sectionAttendanceById: PropTypes.object,
  isGettingSectionUnit: PropTypes.bool,
  subjectVersion: PropTypes.number,
  reviews: PropTypes.array,
  assignList: PropTypes.array,
  isGettingSectionReview: PropTypes.bool,
  unitBySubject: PropTypes.object,
};

const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const isLearnosityLessonReviewEnabled = useIsLearnosityLessonReviewEnabled();
    return (
      <WrappedComponent
        {...props}
        isLearnosityLessonReviewEnabled={isLearnosityLessonReviewEnabled}
      />
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(SubNavbarLessonSessions);
