import styled, { css } from 'styled-components';

export const BannerWrap = styled.div`
  background-color: ${({ bannerType }) => bannerType.backgroundColor};
  color: ${({ bannerType }) => bannerType.textColor};
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  padding: 10px 30px;
  position: fixed; 
  top: 0;
  width: 100%;
  z-index: 1000; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  height: 50px;
`;

export const BannerText = styled.p`
  margin: 0;
  font-size: 12.5px;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 8px;
  }
`;

export const BannerButton = styled.button`
  background-color: transparent; 
  color: white;
  border: 1px solid white;
  padding: 7px 12px;
  border-radius: 11px;
  font-size: 12.5px;
  cursor: pointer;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  ${({ hideButton }) => hideButton && css`
    display: none;
  `}
`;