import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import _get from "lodash/get";
import StandardsList from "../../components/StandardsList";
import { selectors as SectionUnitSelectors } from "../../reducers/sectionUnit";
import { selectors as CourseSelectors } from "../../reducers/courses";
import { selectors as SectionSelectors } from "../../reducers/sections";
import { selectors as SubjectSelector } from "../../reducers/sectionSubjects";
import { selectors as CurriculumLessonSelectors } from "../../reducers/curriculumLessons";

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, "params.courseId", "");
  const subjectId = _get(match, "params.subjectId", "");
  const courseData = CourseSelectors.getCourseById(state, courseId);

  const attendanceList = SectionSelectors.getSectionAttendanceList(state);
  const lessonIdsWithAttendance = [...new Set(attendanceList.map(attendance => attendance.lesson))];
  const unitTree = SectionUnitSelectors.getUnitTreeBySubjectId(state, subjectId);
  return {
    courseData,
    units: unitTree?.units,
    attendance: lessonIdsWithAttendance,
    subjects: SubjectSelector.getSubjectById(state),
  };
};

export default withRouter(connect(mapStateToProps, {})(StandardsList));
