import {
  takeEvery, put, call, takeLatest,
} from 'redux-saga/effects';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _unionBy from 'lodash/unionBy';
import {
  GET_LESSON_ACTIVITY,
  POST_LESSON_ACTIVITY,
  GET_ALL_LESSON_ACTIVITY,
  GET_SECTION_PRACTICE_SESSION_ACTIVITY,
  GET_SECTION_REVIEW_SESSION_DATA,
  GET_STUDENT_REVIEW_SESSION_DATA,
  GET_ALL_LESSON_ACTIVITY_SUMMARY,
  ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY,
  actions,
} from '../../reducers/activity';
import * as SectionAPI from '../../apis/sections';
import { actions as curriculumLessonActions } from '../../reducers/curriculumLessons';
function* getLessonActivity({ sectionId, lessonId }) {
  const { response, error } = yield call(SectionAPI.getLessonSessionActivity, sectionId, lessonId);
  if (response) {
    const { data } = response;
    yield put(actions.getLessonActivitySuccess(data));
  } else {
    yield put(actions.getLessonActivityFail(error));
  }
}

function* postLessonActivity({ sectionId, body }) {
  const { response, error } = yield call(SectionAPI.postLessonSessionActivity, sectionId, body);
  if (response) {
    const { data } = response;
    yield put(actions.postLessonActivitySuccess(data));
  } else {
    yield put(actions.postLessonActivityFail(error));
  }
}

function* getAllLessonActivity({ sectionId }) {
  const { response, error } = yield call(SectionAPI.userSelectionAllLessonActivity, sectionId);
  if (response) {
    const { data } = response;
    yield put(actions.getAllLessonActivitySuccess(data));
  } else {
    yield put(actions.getAllLessonActivityFail(error));
  }
}

function* getSessionPracticeSessionActivity({
  sectionId, unitId,
  lessonId, studentId, reviewId,
}) {
  const { response, error } = yield call(SectionAPI.getSessionPracticeSessionActivity, sectionId, unitId, lessonId, studentId, reviewId);
  if (response) {
    const { data } = response;
    yield put(actions.getSessionPracticeSessionActivitySuccess(data));
  } else {
    yield put(actions.getSessionPracticeSessionActivityFail(error));
  }
}

function* getSectionReviewSessionData({ sectionId, reviewId }) { // for teacher
  const { response, error } = yield call(SectionAPI.getSectionReviewSessionData, sectionId, reviewId);
  if (response) {
    const { data: reviewSession } = response;
    const allLessonList = _reduce(reviewSession, (prevValue, reviewItem) => {
      const lessons = _get(reviewItem, 'unit.lessons', []);

      return _unionBy(prevValue, lessons, 'id');
    }, []);

    yield put(curriculumLessonActions.curriculumLessonGetWithReview(allLessonList));
    yield put(actions.getSectionReviewSessionDataSuccess(reviewSession));
  } else {
    yield put(actions.getSectionReviewSessionDataFail(error));
  }
}

function* getStudentReviewSessionData({ reviewId }) { // for student
  const { response, error } = yield call(SectionAPI.getStudentReviewSessionData, reviewId);
  if (response) {
    const { data } = response;
    const allLessonList = _reduce(data, (prevValue, reviewItem) => {
      const lessons = _get(reviewItem, 'unit.lessons', []);

      return _unionBy(prevValue, lessons, 'id');
    }, []);
    yield put(curriculumLessonActions.curriculumLessonGetWithReview(allLessonList));
    yield put(actions.getStudentReviewSessionDataSuccess(data));
  } else {
    yield put(actions.getStudentReviewSessionDataFail(error));
  }
}

function* getSectionLessonActivitySummary({
  sectionId, subjectId,
  unitId, lessonId,
  slideId,
}) {
  if(sectionId > 0) {
    const { response, error } = yield call(SectionAPI.getSectionLessonActivitySummary, sectionId, subjectId, unitId, lessonId, slideId);
    if (response) {
      const { data } = response;
      yield put(actions.getAllLessonActivitySummarySuccess(data));
    } else {
      yield put(actions.getAllLessonActivitySummaryFail(error));
    }
  }
}

function* activityGetAllTeacherActivitySummary({
  sectionId, subjectId, unitId, lessonId, slideId,
}) {
  const { response, error } = yield call(SectionAPI.getTeacherLessonActivitySummary,
    sectionId,
    subjectId,
    unitId,
    lessonId,
    slideId);
  if (response) {
    const { data } = response;
    yield put(actions.activityGetAllTeacherActivitySummarySuccess(data));
  } else {
    yield put(actions.activityGetAllTeacherActivitySummaryFail(error));
  }
}

export default function* sectionActivitySaga() {
  yield takeEvery(GET_LESSON_ACTIVITY, getLessonActivity);
  yield takeEvery(POST_LESSON_ACTIVITY, postLessonActivity);
  yield takeEvery(GET_ALL_LESSON_ACTIVITY, getAllLessonActivity);
  yield takeLatest(GET_SECTION_PRACTICE_SESSION_ACTIVITY, getSessionPracticeSessionActivity);
  yield takeEvery(GET_SECTION_REVIEW_SESSION_DATA, getSectionReviewSessionData);
  yield takeEvery(GET_STUDENT_REVIEW_SESSION_DATA, getStudentReviewSessionData);
  yield takeEvery(GET_ALL_LESSON_ACTIVITY_SUMMARY, getSectionLessonActivitySummary);
  yield takeEvery(ACTIVITY_GET_ALL_TEACHER_LESSON_ACTIVITY_SUMMARY, activityGetAllTeacherActivitySummary);
}
