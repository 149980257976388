import React from 'react';
import {
  useCurrentSubject,
  useCurrentUnit,
  useGetLessonDetail,
} from '../LessonContainer/hooks/query';

const breadcrumbList = ['Lessons'];
const useLessonBreadcrumb = () => {
  const subject = useCurrentSubject();
  const unit = useCurrentUnit();

  const { data: lesson } = useGetLessonDetail();
  const listBuilder = React.useMemo(() => {
    const baseList = [...breadcrumbList];
    if (!subject) {
      return baseList;
    }
    baseList.push(subject.display_name);
    if (!unit) {
      return baseList;
    }
    baseList.push(unit.name);
    if (!lesson) {
      return baseList;
    }
    baseList.push(lesson.name);
    return baseList;
  }, [lesson, subject, unit]);
  return listBuilder;
};
export default useLessonBreadcrumb;
