import React from 'react';
import Typography from '@components/Atoms/Typography';
import Tooltip from '@components/Atoms/Tooltip';
import ShowIf from '@components/Atoms/ShowIf';
import Paper from '@components/Atoms/Paper';
import Box from '@components/Atoms/Box';
import StraightIcon from '@mui/icons-material/Straight';

export type StatisticProps = {
  label: string;
  value: string;
  subtext?: string;
  helptext?: string;
  delta?: number;
};

const Statistic = ({ label, value, helptext, subtext, delta }: StatisticProps) => (
  <Paper square variant="outlined" sx={{ border: 'none', width: '100%' }}>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      m={1}
    >
      {/* label with tooltip */}
      <ShowIf If={!!helptext}>
        <Tooltip title={helptext} arrow placement="top">
          <span>
            <Typography
              variant="h5"
              sx={{
                borderBottom: 1,
                borderBottomStyle: 'dashed',
                color: 'text.secondary',
              }}
            >
              {label}
            </Typography>
          </span>
        </Tooltip>
      </ShowIf>
      {/* label without tooltip */}
      <ShowIf If={!helptext}>
        <Typography variant="h5" sx={{ color: 'text.secondary' }}>
          {label}
        </Typography>
      </ShowIf>
      {/* value */}
      <Typography sx={{ alignItems: 'center', display: 'flex', fontSize: '1.5em' }}>
        {value}
        <ShowIf If={!!delta && delta > 0}>
          <StraightIcon color="success" />
          <Typography>{delta}</Typography>
        </ShowIf>
      </Typography>
      {/* subtext */}
      <Typography variant="caption" sx={{ color: 'text.secondary', minHeight: '.75rem' }}>
        {subtext}
      </Typography>
    </Box>
  </Paper>
);

export default Statistic;
