import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SubNavbarLessonSessions from '../../components/SubNavbarLessonSessions';
import {
  // selectors
  selectors as SideBarSelectors,
  // actions
  actions as SideBarAction,
} from '../../reducers/leftSideBar';
import {
  selectors as SectionUnitSelectors,
} from '../../reducers/sectionUnit';
import {
  selectors as SectionSubjectsSelectors,
} from '../../reducers/sectionSubjects';
import {
  selectors as ActivitySelectors,
} from '../../reducers/activity';
import {
  selectors as SectionsSelectors,
} from '../../reducers/sections';
import {
  selectors as SectionReviewSelectors,
} from '../../reducers/sectionReviews';
import { selectors as SectionSessionSelectors } from '../../reducers/sectionSession';
import { selectors as UserSelectors } from '../../reducers/user';
import { selectors as SectionStudentSelectors } from '../../reducers/sectionStudent';
import { ROLE_TYPE } from '../../utils/enums';
import { selectors as LessonAssignSelectors } from '../../reducers/lessonAssignedActivities';

const mapStateToProps = (state, { match }) => {
  const {
    sectionId = 0, subjectId
  } = match.params;
  const isTeacher = UserSelectors.getUserRole(state) === ROLE_TYPE.INSTRUCTOR;
  const isStudent = UserSelectors.getUserRole(state) === ROLE_TYPE.STUDENT;
  const assignList = LessonAssignSelectors.getAssignStatusBySubject(state, subjectId);
  const unitByIds =  SectionUnitSelectors.getUnitByIds(state);
  const unitBySubject = SectionUnitSelectors.getUnitBySubject(state);
  return {
    sectionId,
    subjectId,
    isTeacher,
    subject: SectionSubjectsSelectors.getSubjectDetail(state, subjectId),
    isSubNavBarClose: Boolean(!subjectId),
    unitTree: SectionUnitSelectors.getUnitTreeBySubjectId(state, subjectId),
    lessonActivitySummaryById: ActivitySelectors.getLessonActivitySummaryById(state),
    groupActivitySummaryById: ActivitySelectors.getGroupActivitySummaryById(state),
    practiceSummaryByLessonId: SectionsSelectors.getPracticeSummaryByLessonId(state),
    practiceSummaryByUnitId: SectionsSelectors.getPracticeSummaryByUnitId(state),
    studentList: SectionStudentSelectors.getStudentList(state, sectionId),
    userId: UserSelectors.getUserId(state),
    sessionList: SectionSessionSelectors.getSessionListWithSubjectId(state, subjectId),
    sectionAttendanceById: SectionsSelectors.getSectionAttendanceById(state),
    isGettingSectionUnit: SectionUnitSelectors.isGettingSectionUnit(state),
    reviews: SectionReviewSelectors.getReviewListWithSubjectId(state),
    isGettingSectionReview: SectionReviewSelectors.isGettingSectionReview(state),
    reviewList: SectionReviewSelectors.getReviewList(state),
    assignList,
    isStudent,
    unitByIds,
    unitBySubject,
  };
};

const mapDispatchToProps = {
  toggleSubNavBar: SideBarAction.toggleLessonSessionNavbar,
  toggleSubjectLessonNavbar: SideBarAction.toggleSubjectLessonNavbar,
  backSubjectLessonNavbar: SideBarAction.backSubjectLessonNavbar,
  toggleAssignList: SideBarAction.toggleAssignList,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubNavbarLessonSessions),
);
