/* eslint-disable eqeqeq */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import _isEqual from 'lodash/isEqual';
import _isNil from 'lodash/isNil';
import SectionCode from './SectionCode';
import { SectionSelectWrap, SectionBarCodeIcon } from './SectionSelect.style';
import { ROLE_TYPE } from '../../utils/enums';
import { setSectionOrCourse } from '../../utils/cacheCourseAndSection';

const customSelectStyle = {
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
};

class SectionSelect extends React.PureComponent {
  state = {
    isSectionCodeOpen: false,
  };

  componentDidMount() {
    const { sectionIdSelected, sectionList, selectedSection } = this.props;

    if (selectedSection || sectionList.length !== 0) {
      this.selectFirstSection();
    }

    if (selectedSection || sectionIdSelected !== null) this.sectionsGetDetail(sectionIdSelected || selectedSection);

    if (!selectedSection && !sectionIdSelected) {
      this.selectFirstSection();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      sectionIdSelected: prevSectionIdSelected,
      sectionList: prevSectionList,
    } = prevProps;
    const { sectionIdSelected, sectionList } = this.props;

    if (
      !_isEqual(sectionIdSelected, prevSectionIdSelected)
      && !_isNil(sectionIdSelected)
    ) {
      this.sectionsGetDetail(sectionIdSelected);
    }

    if (
      !_isEqual(sectionIdSelected, prevSectionIdSelected)
      && _isNil(sectionIdSelected)
    ) {
      this.selectFirstSection();
    }

    if (
      !_isEqual(sectionList, prevSectionList)
      && sectionList.length > 0
      && _isNil(sectionIdSelected)
    ) {
      this.selectFirstSection();
    }
  }

  onOpenSectionCode = () => {
    this.setState({
      isSectionCodeOpen: true,
    });
  };

  onCloseSectionCode = () => {
    this.setState({
      isSectionCodeOpen: false,
    });
  };

  selectFirstSection() {
    const { sectionList, selectedSection, sectionIdSelected } = this.props;
    if (sectionIdSelected || !sectionList.length) return;
    const hasSectionSelected = sectionList.some(
      (section) => section.id == selectedSection,
    );
    const sectionFirstItem = sectionList[0];
    const { id } = sectionFirstItem || {};
    const sectionId = hasSectionSelected ? selectedSection || id : id;

    this.redirectToSectionDetailRoute(sectionId);
  }

  sectionsGetDetail = (sectionId) => {
    const { sectionsGetDetail, sectionStudentFilterAddAllOrCancel } = this.props;
    sectionsGetDetail(sectionId);
    sectionStudentFilterAddAllOrCancel([]);
  };

  selectSectionId = (section) => {
    setSectionOrCourse(section.id);
    this.redirectToSectionDetailRoute(section.id);
  };

  redirectToSectionDetailRoute = (sectionId) => {
    const {
      courseIdSelected, history, pathName, selectSection,
    } = this.props;
    setSectionOrCourse(sectionId);
    history.push(`${pathName}/${courseIdSelected}/section/${sectionId}`);
    selectSection(sectionId);
  };

  renderSectionOption = (sectionItem) => {
    const { id, name } = sectionItem || {};

    return (
      <option key={id} value={id}>
        {name}
      </option>
    );
  };

  render() {
    const {
      sectionList = [],
      isSectionGetAll,
      sectionIdSelected,
      selectedSection,
      userRole,
    } = this.props;
    const { isSectionCodeOpen } = this.state;
    const currentSection = sectionList.find(
      (section) => section.id == sectionIdSelected || section.id == selectedSection,
    );
    const shouldRenderLoading = isSectionGetAll;

    if (!sectionList.length) {
      return (
        <SectionSelectWrap>You don&#39;t have any section</SectionSelectWrap>
      );
    }

    return (
      <SectionSelectWrap>
        <ReactTooltip place="right" effect="solid" id="course-code" />
        {shouldRenderLoading ? <span>Loading</span> : (
          <React.Fragment>
            {userRole !== ROLE_TYPE.STUDENT && (
              <SectionBarCodeIcon
                className="chalktalk-barcode"
                data-tip="Get course code"
                data-for="course-code"
                onClick={this.onOpenSectionCode}
              />
            )}
            <Select
              styles={customSelectStyle}
              value={currentSection}
              onChange={this.selectSectionId}
              options={sectionList}
              className="section-select-container"
              classNamePrefix="section-select"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isOptionSelected={(option) => sectionIdSelected == option.id}
            />
            <SectionCode
              isOpen={isSectionCodeOpen}
              onClose={this.onCloseSectionCode}
              currentSection={currentSection}
            />
          </React.Fragment>
        )}
      </SectionSelectWrap>
    );
  }
}

SectionSelect.propTypes = {
  sectionList: PropTypes.array,
  isSectionGetAll: PropTypes.bool,
  sectionIdSelected: PropTypes.any, // maybe string or number
  courseIdSelected: PropTypes.string,
  history: PropTypes.any,
  sectionsGetDetail: PropTypes.func,
  pathName: PropTypes.string,
  selectedSection: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectSection: PropTypes.func.isRequired,
  sectionStudentFilterAddAllOrCancel: PropTypes.func,
  userRole: PropTypes.any,
};

export default SectionSelect;
