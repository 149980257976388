import EventEmitter, { MessageFn as defaultMessageFnType } from '@utils/eventEmitter';

export enum ExamScoreEvents {
  EXAM_SCORED = 'EXAM_SCORED',
  RESET = 'RESET',
}

type ExamScoreEventEmitterType = {
  [ExamScoreEvents.EXAM_SCORED]: any;
  [ExamScoreEvents.RESET]: any;
};
export type MessageFnType = defaultMessageFnType<ExamScoreEventEmitterType>;

const ExamScoreEventEmitter = new EventEmitter<ExamScoreEventEmitterType>();
export default ExamScoreEventEmitter;
