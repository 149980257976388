import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import EditScheduleForm from '../../components/ScheduleSection/EditScheduleForm';
import {
    selectors as CourseSelectors,
} from '../../reducers/courses';
import {
    selectors as CurriculumSelectors,
} from '../../reducers/curriculums';
import {
    actions as SectionActions,
    selectors as SectionSelectors,
} from '../../reducers/sections';
import { selectors as SectionExamSelectors } from '../../reducers/sectionExam';
import {
    actions as scheduleActions,
  } from '../../reducers/sectionSchedule';
  
const mapStateToProps = (state, { match }) => {
    const courseId = _get(match, 'params.courseId', '');
    const sectionId = _get(match, 'params.sectionId', null);
    const courseData = CourseSelectors.getCourseById(state, courseId);
    const curriculumId = _get(courseData, 'curriculum', '');
    const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);
    const curriculumDetail = CurriculumSelectors.getCurriculumById(state, curriculumId);
    const examSessions = SectionExamSelectors.getExamSessionList(state, sectionId);
    return ({
        courseData,
        sectionId,
        courseId,
        curriculumId,
        subjectsById: CurriculumSelectors.getSubjectListById(state, curriculumId),
        allSections: SectionSelectors.getAllTeachersCourseSection(state),
        sectionDetail,
        curriculumDetail,
        packagesPerSubject: SectionSelectors.AvailablePackages(state),
        examSessions
    });
};
const mapDispatchToProps = {
    sectionsCreateNewSection: SectionActions.sectionsCreateNewSection,
    getAllSectionsByCourse: SectionActions.getAllSectionsByCourse,
    sectionsUpdate: SectionActions.sectionsUpdate,
    getAvailablePackages: SectionActions.getAvailablePackages,
    sectionScheduleGet: scheduleActions.sectionScheduleGet,
    updateSectionUnits: SectionActions.updateSectionUnits,
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditScheduleForm));
