import React from 'react';
import Box from '../Box';

const tableRowStyle = {
  display: 'flex',
  px: 1.25,
  alignItems: 'center',
  '&:hover': {
    backgroundColor: (theme) => theme.tableCT.hover,
    borderRadius: 1,
  },
  // Header Row Style
  '&.table-header': {
    py: 0.5,
    px: 2.5,
    borderRadius: 1,
    backgroundColor: (theme) => theme.tableCT.backgroundColor,
    overflow: 'hidden',
  },
};

interface RowProps {
  children: React.ReactNode | React.ReactNode[];
  sx?: any;
  className?: string;
}

const Row = ({ children, sx, className, ...rest }: RowProps) => (
  <Box className={className} sx={[tableRowStyle, { ...sx }]} {...rest}>
    {children}
  </Box>
);

export default Row;
