import { useEffect } from 'react';
import PresentationEventEmitter, {
  PresentationEvents,
} from '../LessonSlidesEventEmmiter';

function usePresentationEventHandlers(
  controller: any,
  sessionUUID: string,
  userId: number,
  sectionId: number,
  lessonNodeId: number,
  tab: string,
  additionalSourceId?: number,
) {
  useEffect(() => {
    if (!controller) {
      return;
    }

    const handleSlideChange = (slideIndex: number) => {
      const currentSlide = controller.currentSlide();
      PresentationEventEmitter.emit(PresentationEvents.SLIDE_CHANGED, {
        sessionUUID,
        slideIndex,
        title: currentSlide.title(),
        lastSlideIndex: controller.lastSlideIndex(),
        previousSlideIndex: controller.previousSlideIndex(),
        tab,
        userId,
        sectionId,
        lessonNodeId,
        additionalSourceId,
      });
    };

    const handleStepChange = () => {
      PresentationEventEmitter.emit(PresentationEvents.STEP_CHANGED, undefined);
    };

    const handlePlaybackComplete = () => {
      PresentationEventEmitter.emit(PresentationEvents.PLAYBACK_COMPLETED, undefined);
    };

    // Register the event handlers
    controller.slideChangeEvent().addHandler(handleSlideChange);
    controller.stepChangeEvent().addHandler(handleStepChange);
    controller.playbackCompleteEvent().addHandler(handlePlaybackComplete);

    // Cleanup function
    return () => {
      controller.slideChangeEvent().removeHandler(handleSlideChange);
      controller.stepChangeEvent().removeHandler(handleStepChange);
      controller.playbackCompleteEvent().removeHandler(handlePlaybackComplete);
    };
  }, [controller]);
}

export default usePresentationEventHandlers;
