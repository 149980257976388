import _get from 'lodash/get';
import moment from 'moment';
import React, {
  useCallback,
  useEffect, useReducer, useRef,
} from 'react';
import 'react-image-lightbox/style.css';
import { GroupActivityContainer } from '../GroupActivityTable/GroupActivityTable.style';
import GroupDiscussionModal from '../Modals/GroupDiscussionModal';
import GroupActivityContentItem from './GroupActivityContentItem';

const GroupActivityContent = ({
  questions,
  status,
  scores,
  getGroupStudentReview,
  groupId,
  userId,
  history,
  location,
  currentGroupStudents,
  hideModal,
  hideGroupModal,
  enableUseNextQuestionId,
  disableUseNextQuestionId,
  ...props
}) => {
  const initialState = {
    shouldDisplayGrammarHelper: false,
    startTime: moment(),
    isLightBoxOpen: false,
    imgSrc: '',
    isModalOpen: false,
    endActivityModal: false,
  };
  const reducer = (state, value) => ({ ...state, ...value });
  const [state, dispatch] = useReducer(reducer, initialState);
  const ref = useRef(null);

  const openLightBox = (src) => {
    dispatch({ isLightBoxOpen: true, imgSrc: src });
  };

  const closeLightbox = () => {
    dispatch({ isLightBoxOpen: false, imgSrc: '' });
  };

  const handleClick = (e) => {
    const targetTagName = _get(e, 'target.tagName', '');
    if (targetTagName === 'IMG' && !isLightBoxOpen) {
      const src = _get(e, 'target.src', '');
      openLightBox(src);
    }
  };

  const getSelectedQuestion = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return Number.parseInt(searchParams.get('question') || 0, 10);
  }, [window.location.search]);

  const questionSelected = getSelectedQuestion();

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('click', handleClick);
    }
    return () => {
      if (ref.current) {
        ref.current.removeEventListener('click', handleClick);
      }
    };
  }, [ref]);

  useEffect(() => {
    if (!hideModal && !state.isModalOpen && Number.parseInt(status, 10) === 2) {
      dispatch({ isModalOpen: true });
    }
  }, [hideModal]);

  useEffect(() => {
    if (status === 'review' && !props.isTeacher) {
      const questionId = questions[questionSelected]?.id;
      if (questionId) {
        getGroupStudentReview(questionId, groupId, userId);
      }
    }
  }, [questions, status, questionSelected, groupId]);

  const setSelectedQuestion = (question) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('question', question);
    history.push({
      search: searchParams.toString(),
    });
  };

  const nextQuestion = () => {
    const _questionSelected = getSelectedQuestion();
    if (_questionSelected + 1 >= questions.length) return;
    if (status === 'review' && !props.isTeacher) {
      const questionId = questions[_questionSelected + 1].id;
      getGroupStudentReview(questionId, groupId, userId);
    }
    dispatch({
      startTime: moment(),
      shouldDisplayGrammarHelper: false,
    });
    setSelectedQuestion(_questionSelected + 1);
    enableUseNextQuestionId();
  };

  const nextStage = () => {
    dispatch({
      startTime: moment(),
      shouldDisplayGrammarHelper: false,
      isModalOpen: true,
    });
    disableUseNextQuestionId();
    setSelectedQuestion(0);
  };

  const prevQuestion = () => {
    if (questionSelected - 1 < 0) return;
    if (status === 'review' && !props.isTeacher) {
      const questionId = questions[questionSelected - 1].id;
      getGroupStudentReview(questionId, groupId, userId);
    }
    dispatch({ startTime: moment(), shouldDisplayGrammarHelper: false });
    setSelectedQuestion(questionSelected - 1);
  };

  const selectQuestion = (newQuestionSelected, hasAnswer) => () => {
    if (!hasAnswer) return;
    dispatch({ shouldDisplayGrammarHelper: false });
    setSelectedQuestion(newQuestionSelected);
  };

  const {
    startTime,
    isLightBoxOpen,
    imgSrc,
    isModalOpen,
    endActivityModal,
    shouldDisplayGrammarHelper,
  } = state;

  const getGroupActivityContentItemProps = () => ({
    questions,
    scores,
    status,
    questionSelected,
    getGroupStudentReview,
    groupId,
    userId,
    history,
    location,
    shouldDisplayGrammarHelper,
    startTime,
    isLightBoxOpen,
    imgSrc,
    lightBoxImgSrc: imgSrc,
    isModalOpen,
    endActivityModal,
    nextQuestion,
    prevQuestion,
    selectQuestion,
    displayGrammarHelper: (value) => dispatch({ shouldDisplayGrammarHelper: value }),
    nextStage,
    setSelectedQuestion,
  });

  return (
    <GroupActivityContainer ref={ref}>
      <GroupDiscussionModal
        isModalOpen={isModalOpen}
        onCloseModal={() => {
          dispatch({ isModalOpen: false });
          hideGroupModal();
        }}
        endAcitivityModal={endActivityModal}
        currentGroupStudents={currentGroupStudents}
      />
      <GroupActivityContentItem
        closeLightbox={closeLightbox}
        currentGroupStudents={currentGroupStudents}
        {...props}
        {...getGroupActivityContentItemProps()}
      />
    </GroupActivityContainer>
  );
};

export default GroupActivityContent;
