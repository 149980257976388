import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {
  TableHead, TableRow, TableIcon, TableHeadText,
} from './TableComponent.style';

export const TableHeader = ({
  value, children, haveFilter, icon, dataTip, ...props
}) => (
  <TableHead {...props}>
    <ReactTooltip place="top" effect="solid" disable={!!dataTip} id="table" />
    {icon && <TableIcon className={icon} />}

    <TableHeadText
      hasDataTip={dataTip}
      data-tip={dataTip}
      data-for="table"
    >
      {value || children}
    </TableHeadText>

    {haveFilter && <i className="chalktalk-up-down-arrow" />}
  </TableHead>
);

export const TableContent = ({ value, children, ...props }) => (
  <TableRow {...props}>{value || children}</TableRow>
);

TableHeader.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
  haveFilter: PropTypes.bool,
  icon: PropTypes.string,
  dataTip: PropTypes.string,
};

TableContent.propTypes = {
  value: PropTypes.string,
  children: PropTypes.node,
};
