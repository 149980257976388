/* eslint-disable radix */
import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';

// action definition
export const CURRICULUMS_FETCH_LIST = 'curriculums/CURRICULUMS_FETCH_LIST';
export const CURRICULUMS_FETCH_LIST_SUCCESS = 'curriculums/CURRICULUMS_FETCH_LIST_SUCCESS';
export const CURRICULUMS_FETCH_LIST_FAIL = 'curriculums/CURRICULUMS_FETCH_LIST_FAIL';

export const CURRICULUMS_FETCH_STANDARD_LIST = 'curriculums/CURRICULUMS_FETCH_STANDARD_LIST';
export const CURRICULUMS_FETCH_STANDARD_LIST_SUCCESS = 'curriculums/CURRICULUMS_FETCH_STANDARD_LIST_SUCCESS';
export const CURRICULUMS_FETCH_STANDARD_LIST_FAIL = 'curriculums/CURRICULUMS_FETCH_STANDARD_LIST_FAIL';

export const CURRICULUMS_FETCH_SUBJECT_LIST = 'curriculums/CURRICULUMS_FETCH_SUBJECT_LIST';
export const CURRICULUMS_FETCH_SUBJECT_LIST_SUCCESS = 'curriculums/CURRICULUMS_FETCH_SUBJECT_LIST_SUCCESS';
export const CURRICULUMS_FETCH_SUBJECT_LIST_FAIL = 'curriculums/CURRICULUMS_FETCH_SUBJECT_LIST_FAIL';

export const CURRICULUMS_FETCH_PLACEMENT_TYPE = 'curriculums/FETCH_PLACEMENT_TYPE';
export const CURRICULUMS_FETCH_PLACEMENT_TYPE_SUCCESS = 'curriculums/FETCH_PLACEMENT_TYPE_SUCCESS';
export const CURRICULUMS_FETCH_PLACEMENT_TYPE_FAIL = 'curriculums/FETCH_PLACEMENT_TYPE_FAIL';

export const CURRICULUMS_FETCH_EXIT_TYPE = 'curriculums/FETCH_EXIT_TYPE';
export const CURRICULUMS_FETCH_EXIT_TYPE_SUCCESS = 'curriculums/FETCH_EXIT_TYPE_SUCCESS';
export const CURRICULUMS_FETCH_EXIT_TYPE_FAIL = 'curriculums/FETCH_EXIT_TYPE_FAIL';

export const CURRICULUMS_FETCH_TRAINNING = 'curriculums/TRAINNING';
export const CURRICULUMS_FETCH_TRAINNING_SUCCESS = 'curriculums/TRAINNING_SUCCESS';
export const CURRICULUMS_FETCH_TRAINNING_FAIL = 'curriculums/TRAINNING_FAIL';

export const CURRICULUMS_FETCH_TEACHER_TRAINING = 'curriculums/CURRICULUMS_FETCH_TEACHER_TRAINING';
export const CURRICULUMS_FETCH_TEACHER_TRAINING_SUCCESS = 'curriculums/CURRICULUMS_FETCH_TEACHER_TRAINING_SUCCESS';
export const CURRICULUMS_FETCH_TEACHER_TRAINING_FAIL = 'curriculums/CURRICULUMS_FETCH_TEACHER_TRAINING_FAIL';

export const CURRICULUMS_SELECT_TRAINING = 'curriculums/SELECT_TRAINING';
export const CURRICULUMS_SELECT_TEACHER_TRAINING = 'curriculums/SELECT_TEACHER_TRAINING';
// selectors
export const getAllCurriculums = ({ curriculums }) => (
  curriculums
    .curriculumList
    .map(id => curriculums.curriculumDetail[id])
);
export const getAllCurriculumData = ({ curriculums }) => curriculums
export const getCurriculumById = ({ curriculums }, id) => curriculums.curriculumDetail[id] || {};
export const isCurriculumLoading = ({ curriculums }) => !!curriculums.isLoadingCurriculum;
export const getCurriculumErrorMessage = ({ curriculums }) => curriculums.curriculumErrorMsg;

export const getCurriculumSelected = ({ courses }) =>
  (courses.courseListById[courses.courseSelected] || {}).curriculum || null;

// sectors for standards
export const getStandardByIds = ({ curriculums }) => curriculums.standardByIds;
export const getStandardList = ({ curriculums }) => (curriculums.standardListAllId.map(id => curriculums.standardByIds[id]));
export const isStandardLoading = ({ curriculums }) => !!curriculums.isLoadingStandard;
export const getStandardErrorMessage = ({ curriculums }) => curriculums.standardErrorMsg;

// selectors for subject
export const getSubjectList = ({ curriculums }, currId) => curriculums.subjectList[currId] || [];
const getTrainingList = ({ curriculums }, currId) => _get(curriculums, `trainings.${currId}`, []);
const getTeacherTrainingList = ({ curriculums }, currId) => _get(curriculums, `trainings.${currId}`, []);
const getSubjectListById = (state, currId) => {
  const subbjectList = getSubjectList(state, currId);
  return _keyBy(subbjectList, 'id');
};

export const isSubjectLoading = ({ curriculums }, currId) => !!curriculums.isLoadingCurriculum[currId];
export const getSubjectErrorMessage = ({ curriculums }, currId) => curriculums.subjectErrorMsg[currId];

const getAllSubjectById = ({ curriculums }) => _get(curriculums, 'subjectById', {});

const getPlacementTypesObj = ({ curriculums }) => _get(curriculums, 'placementType', {});

const getExitTypesObj = ({ curriculums }) => _get(curriculums, 'exitType', {});

const getPlacementTypes = ({ curriculums }, curriculumId) => _get(curriculums, `placementType.${curriculumId}`, []);

const getExitTypes = ({ curriculums }, curriculumId) => _get(curriculums, `exitType.${curriculumId}`, []);

const getSubjectByIdList = ({ curriculums }, ids) => {
  const subjectById = _get(curriculums, 'subjectById', {});

  return ids.map(id => _get(subjectById, id, {}));
};

const getSubjectById = (state, subjectId) => {
  const subjectById = getAllSubjectById(state);
  const subjectSelected = _get(subjectById, subjectId, {});

  if (!subjectSelected) {
    return {};
  }

  return subjectSelected;
};

const getStandardByStandardId = ({ curriculums }, id) => _get(curriculums, `standardByIds.${id}`, {});
const getCurrentSelectedTraining = ({ curriculums }) => _get(curriculums, 'selectedTraining', {});
const getCurrentSelectedTeacherTraining = ({ curriculums }) => _get(curriculums, 'selectedTeacherTraining', {});
const isGettingTrainning = ({ curriculums }) => curriculums.isFetchTrainning;

export const selectors = {
  getAllCurriculums,
  getCurriculumById,
  isCurriculumLoading,
  getCurriculumErrorMessage,
  getCurriculumSelected,

  getStandardByIds,
  getStandardList,
  isStandardLoading,
  getStandardErrorMessage,

  getSubjectList,
  getSubjectListById,
  isSubjectLoading,
  getSubjectErrorMessage,

  getPlacementTypesObj,
  getPlacementTypes,
  getExitTypesObj,
  getExitTypes,

  getTrainingList,
  getTeacherTrainingList,

  getAllSubjectById,
  getSubjectByIdList,
  getSubjectById,
  getStandardByStandardId,
  getCurrentSelectedTraining,
  getCurrentSelectedTeacherTraining,

  isGettingTrainning,
};

// action creators

// fetch trainning
const getCurriculumTraining = curriId => ({
  type: CURRICULUMS_FETCH_TRAINNING,
  curriId,
});

const getCurriculumTrainingSuccess = (curriId, trainingList) => ({
  type: CURRICULUMS_FETCH_TRAINNING_SUCCESS,
  trainingList,
  curriId,
});

const getCurriculumTrainingFail = errorMsg => ({
  type: CURRICULUMS_FETCH_TRAINNING_FAIL,
  errorMsg,
});

const getCurriculumTeacherTraining = curriId => ({
  type: CURRICULUMS_FETCH_TEACHER_TRAINING,
  curriId,
});

const getCurriculumTeacherTrainingSuccess = (curriId, TeacherTrainingList) => ({
  type: CURRICULUMS_FETCH_TEACHER_TRAINING_SUCCESS,
  TeacherTrainingList,
  curriId,
});

const getCurriculumTeacherTrainingFail = errorMsg => ({
  type: CURRICULUMS_FETCH_TEACHER_TRAINING_FAIL,
  errorMsg,
});

// FETCH CURRICULUM LIST
export const getCurriculumList = () => ({
  type: CURRICULUMS_FETCH_LIST,
});

export const getCurriculumListSuccess = curriculumList => ({
  type: CURRICULUMS_FETCH_LIST_SUCCESS,
  payload: {
    curriculumList,
  },
});

export const getCurriculumListFailed = errorMsg => ({
  type: CURRICULUMS_FETCH_LIST_FAIL,
  payload: {
    errorMsg,
  },
});

// FETCH STANDARD LIST
export const getCurriculumStandardList = () => ({
  type: CURRICULUMS_FETCH_STANDARD_LIST,
});

export const getCurriculumStandardListSuccess = standardList => ({
  type: CURRICULUMS_FETCH_STANDARD_LIST_SUCCESS,
  payload: {
    standardList,
  },
});

export const getCurriculumStandardListFailed = errorMsg => ({
  type: CURRICULUMS_FETCH_STANDARD_LIST_FAIL,
  payload: {
    errorMsg,
  },
});

// FETCH SUBJECT LIST
export const getCurriculumSubjectList = curriculumId => ({
  type: CURRICULUMS_FETCH_SUBJECT_LIST,
  payload: {
    curriculumId,
  },
});

export const getCurriculumSubjectListSuccess = (curriculumId, subjectList) => ({
  type: CURRICULUMS_FETCH_SUBJECT_LIST_SUCCESS,
  payload: {
    curriculumId,
    subjectList,
  },
});

export const getCurriculumSubjectListFailed = (curriculumId, errorMsg) => ({
  type: CURRICULUMS_FETCH_SUBJECT_LIST_FAIL,
  payload: {
    curriculumId,
    errorMsg,
  },
});

const getCurriculumPlacementType = curriculumId => ({
  type: CURRICULUMS_FETCH_PLACEMENT_TYPE,
  curriculumId,
});

const getCurriculumPlacementTypeSuccess = (curriculumId, placementTypes = []) => ({
  type: CURRICULUMS_FETCH_PLACEMENT_TYPE_SUCCESS,
  payload: placementTypes,
  curriculumId,
});

const getCurriculumPlacementTypeFail = errorMsg => ({
  type: CURRICULUMS_FETCH_PLACEMENT_TYPE_FAIL,
  errorMsg,
});

const getCurriculumExitType = curriculumId => ({
  type: CURRICULUMS_FETCH_EXIT_TYPE,
  curriculumId,
});

const getCurriculumExitTypeSuccess = (curriculumId, exitTypes = []) => ({
  type: CURRICULUMS_FETCH_EXIT_TYPE_SUCCESS,
  payload: exitTypes,
  curriculumId,
});

const getCurriculumExitTypeFail = errorMsg => ({
  type: CURRICULUMS_FETCH_EXIT_TYPE_FAIL,
  errorMsg,
});

const selectTraining = trainingDetail => ({
  type: CURRICULUMS_SELECT_TRAINING,
  trainingDetail,
});

const selectTeacherTraining = teacherTrainingDetail => ({
  type: CURRICULUMS_SELECT_TEACHER_TRAINING,
  teacherTrainingDetail,
});

export const actions = {
  getCurriculumList,
  getCurriculumListSuccess,
  getCurriculumListFailed,

  getCurriculumStandardList,
  getCurriculumStandardListSuccess,
  getCurriculumStandardListFailed,

  getCurriculumSubjectList,
  getCurriculumSubjectListSuccess,
  getCurriculumSubjectListFailed,

  getCurriculumPlacementType,
  getCurriculumPlacementTypeSuccess,
  getCurriculumPlacementTypeFail,

  getCurriculumExitType,
  getCurriculumExitTypeSuccess,
  getCurriculumExitTypeFail,

  getCurriculumTraining,
  getCurriculumTrainingSuccess,
  getCurriculumTrainingFail,

  getCurriculumTeacherTraining,
  getCurriculumTeacherTrainingSuccess,
  getCurriculumTeacherTrainingFail,

  selectTraining,
  selectTeacherTraining,
};

// reducers

const initialState = {
  curriculumList: [],
  curriculumDetail: {},
  isLoadingCurriculum: null,
  curriculumErrorMsg: null,

  standardListAllId: [],
  standardByIds: {},
  isLoadingStandard: null,
  standardErrorMsg: null,

  subjectList: {},
  isLoadingSubject: {},
  subjectErrorMsg: {},

  placementType: {},
  isLoadingPlacement: false,

  exitType: {},
  isLoadingExit: false,

  subjectById: {}, // all subject by id,

  isFetchTrainning: false,
  isFetchTeacherTraining: false,
  trainings: {},
  selectedTraining: null,
  selectedTeacherTraining: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CURRICULUMS_FETCH_LIST: {
      return {
        ...state,
        isLoadingCurriculum: true,
        curriculumErrorMsg: null,
      };
    }

    case CURRICULUMS_FETCH_LIST_SUCCESS: {
      const { curriculumList } = action.payload;
      const curriculumDetail = curriculumList.reduce((acc, curriculum) => {
        acc[curriculum.id] = curriculum;
        return acc;
      }, {});

      return {
        ...state,
        isLoadingCurriculum: false,
        curriculumList: curriculumList.map(curriculum => curriculum.id),
        curriculumDetail,
      };
    }

    case CURRICULUMS_FETCH_LIST_FAIL: {
      const { errorMsg: curriculumErrorMsg } = action.payload;

      return {
        ...state,
        isLoadingCurriculum: false,
        curriculumErrorMsg,
      };
    }

    // handle curriculum standard actions

    case CURRICULUMS_FETCH_STANDARD_LIST: {
      return {
        ...state,
        isLoadingStandard: true,
        standardErrorMsg: null,
      };
    }

    case CURRICULUMS_FETCH_STANDARD_LIST_SUCCESS: {
      const { standardList } = action.payload;
      const standardListAllId = standardList.map(({ id }) => id);
      const standardByIds = standardList.reduce((acc, standard) => {
        acc[standard.id] = standard;
        return acc;
      }, {});
      return {
        ...state,
        isLoadingStandard: false,
        standardListAllId,
        standardByIds,
      };
    }

    case CURRICULUMS_FETCH_STANDARD_LIST_FAIL: {
      const { errorMsg: standardErrorMsg } = action.payload;

      return {
        ...state,
        isLoadingStandard: false,
        standardErrorMsg,
      };
    }

    // handle curriculum subject action

    case CURRICULUMS_FETCH_SUBJECT_LIST: {
      const { curriculumId } = action.payload;
      const isLoadingSubject = {
        ...state.isLoadingSubject,
        [curriculumId]: true,
      };
      const subjectErrorMsg = {
        ...state.subjectErrorMsg,
        [curriculumId]: null,
      };

      return {
        ...state,
        isLoadingSubject,
        subjectErrorMsg,
      };
    }

    case CURRICULUMS_FETCH_SUBJECT_LIST_SUCCESS: {
      const { curriculumId, subjectList: newSubjectList } = action.payload;
      const isLoadingSubject = {
        ...state.isLoadingSubject,
        [curriculumId]: false,
      };
      const subjectList = {
        ...state.subjectList,
        [curriculumId]: newSubjectList.map(subject => (
          { id: subject.id, name: subject.display_name, public: subject.public }
        )),
      };

      const subjectById = _keyBy(newSubjectList, 'id');

      return {
        ...state,
        isLoadingSubject,
        subjectList,
        subjectById: {
          ...state.subjectById,
          ...subjectById,
        },
      };
    }

    case CURRICULUMS_FETCH_SUBJECT_LIST_FAIL: {
      const { curriculumId, errorMsg } = action.payload;
      const isLoadingSubject = {
        ...state.isLoadingSubject,
        [curriculumId]: false,
      };
      const subjectErrorMsg = {
        ...state.subjectErrorMsg,
        [curriculumId]: errorMsg,
      };

      return {
        ...state,
        isLoadingSubject,
        subjectErrorMsg,
      };
    }
    case CURRICULUMS_FETCH_PLACEMENT_TYPE:
      return {
        ...state,
        isLoadingPlacement: true,
      };
    case CURRICULUMS_FETCH_PLACEMENT_TYPE_SUCCESS: {
      const { curriculumId, payload } = action;

      return {
        ...state,
        isLoadingPlacement: false,
        placementType: {
          ...state.placementType,
          [curriculumId]: payload,
        },
      };
    }
    case CURRICULUMS_FETCH_PLACEMENT_TYPE_FAIL:
      return {
        ...state,
        isLoadingPlacement: false,
      };

    case CURRICULUMS_FETCH_EXIT_TYPE:
      return {
        ...state,
        isLoadingExit: true,
      };
    case CURRICULUMS_FETCH_EXIT_TYPE_SUCCESS: {
      const { curriculumId, payload } = action;

      return {
        ...state,
        isLoadingExit: false,
        exitType: {
          ...state.exitType,
          [curriculumId]: payload,
        },
      };
    }
    case CURRICULUMS_FETCH_EXIT_TYPE_FAIL:
      return {
        ...state,
        isLoadingExit: false,
      };

    case CURRICULUMS_FETCH_TRAINNING:
      return {
        ...state,
        isFetchTrainning: true,
      };

    case CURRICULUMS_FETCH_TRAINNING_SUCCESS: {
      const { trainingList, curriId } = action;

      return {
        ...state,
        isFetchTrainning: false,
        trainings: {
          [curriId]: trainingList,
        },
      };
    }

    case CURRICULUMS_FETCH_TRAINNING_FAIL:
      return {
        ...state,
        isFetchTrainning: false,
      };

    case CURRICULUMS_FETCH_TEACHER_TRAINING:
      return {
        ...state,
        isFetchTrainning: true,
      };

    case CURRICULUMS_FETCH_TEACHER_TRAINING_SUCCESS: {
      const { TeacherTrainingList, curriId } = action;

      return {
        ...state,
        isFetchTrainning: false,
        trainings: {
          [curriId]: TeacherTrainingList,
        },
      };
    }

    case CURRICULUMS_FETCH_TEACHER_TRAINING_FAIL:
      return {
        ...state,
        isFetchTrainning: false,
      };

    case CURRICULUMS_SELECT_TRAINING: {
      const { trainingDetail } = action;
      return {
        ...state,
        selectedTraining: trainingDetail,
      };
    }

    case CURRICULUMS_SELECT_TEACHER_TRAINING: {
      const { teacherTrainingDetail } = action;
      return {
        ...state,
        selectedTeacherTraining: teacherTrainingDetail,
      };
    }

    default: {
      return state;
    }
  }
}
