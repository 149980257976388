import React, { useRef, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { AssetContentItem } from '@components/PracticeV2/Component/QuestionAssets/QuestionAssets';
import { TabProvider } from '@components/Shared/tabs';
import { fetchContent } from '../../apis/practice';
import {
  PrimaryWrap,
  DefaultContentWrap,
  BlockWrap,
  BlockTitle,
} from '../Generals/stylesheets/General.style';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import { QUESTION_TYPES } from '../../utils/enums';
import {
  QuestionAnswer,
  QuestionDesc,
  QuestionContentWrap,
} from '../QuestionItem/QuestionItem.style';
import MathJaxItem from '../MathJaxContainer';
import { AnswerExplanationDecs } from '../AnswerExplanation/AnswerExplanation.style';
import {
  MainSelectorWrapper,
  AssetViewerWrapper,
  QuestionViewerWrapper,
} from './QuestionReviewer.style';

const scrollStyle = {
  height: 'calc(110vh - 20em)',
  width: '100%',
};

const CONTENT_TYPE = {
  QUESTION: 'question',
  ASSET: 'asset',
};

const QuestionReviewer = () => {
  const references = {};
  references[CONTENT_TYPE.QUESTION] = useRef(null);
  references[CONTENT_TYPE.ASSET] = useRef(null);

  const [question, setQuestion] = useState({});
  const [asset, setAsset] = useState({});

  const getContent = async (type) => {
    const targetReference = references[type];
    const input = parseInt(targetReference.current.value);

    if (!input || isNaN(input)) {
      return;
    }

    const response = await fetchContent(type, input);

    if ('error' in response) {
      alert(response.error);
      return;
    }

    if (type == CONTENT_TYPE.QUESTION) {
      setAsset({});
      setQuestion(response?.response?.data);
    } else {
      setQuestion({});
      setAsset(response?.response?.data);
    }
  };

  const renderAnswer = (question) => {
    if (_isEmpty(question)) {
      return <></>;
    }
    const answerType = question?.question_format;
    const choices = question?.choices;
    const correctChoices = question?.correct_choices;

    switch (answerType) {
      case QUESTION_TYPES.GRID_IN: {
        return (
          <AnswerGridChoice
            selectedValue={[]}
            correctAnswer={correctChoices}
            score={0}
            shouldDisplayAnswer
            chooseAnswer={() => {}}
          />
        );
      }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default:
        return (
          <AnswerMultiChoice
            chooseAnswer={() => {}}
            choices={choices}
            correctChoice={correctChoices}
            userChoice={[]}
            shouldDisplayAnswer
          />
        );
    }
  };

  return (
    <PrimaryWrap>
      <DefaultContentWrap>
        <MainSelectorWrapper>
          <BlockWrap>
            <BlockTitle>Question Viewer</BlockTitle>
            <p>Enter a question id to review its assets</p>
            <p>
              <input type="text" ref={references[CONTENT_TYPE.QUESTION]} />
              &nbsp;
              <button
                type="button"
                placeholder="question_id"
                onClick={() => getContent(CONTENT_TYPE.QUESTION)}
              >
                Fetch Question
              </button>
            </p>
          </BlockWrap>
          <BlockWrap>
            <BlockTitle>Asset Viewer</BlockTitle>
            <p>Enter an asset id to review it alone</p>
            <p>
              <input type="text" ref={references[CONTENT_TYPE.ASSET]} />
              &nbsp;
              <button
                type="button"
                placeholder="asset_id"
                onClick={() => getContent(CONTENT_TYPE.ASSET)}
              >
                Fetch Asset
              </button>
            </p>
          </BlockWrap>
        </MainSelectorWrapper>
        {Object.keys(question).length > 0 && (
          <QuestionViewerWrapper>
            <ShadowScrollbar autoHide style={scrollStyle}>
              <BlockWrap>
                <BlockTitle>
                  Question Prompt ({references[CONTENT_TYPE.QUESTION].current.value})
                </BlockTitle>
                <QuestionDesc>
                  <MathJaxItem data={question?.prompt?.text} />
                </QuestionDesc>
              </BlockWrap>
              <BlockWrap>
                <BlockTitle>
                  Question Choices ([
                  {question?.choices.map((choice) => choice.id).join(',')}])
                </BlockTitle>
                <QuestionAnswer>{renderAnswer(question)}</QuestionAnswer>
              </BlockWrap>
            </ShadowScrollbar>
            <ShadowScrollbar autoHide style={scrollStyle}>
              <BlockWrap>
                <BlockTitle>Answer Explanation</BlockTitle>
                <QuestionContentWrap>
                  <AnswerExplanationDecs>
                    <MathJaxItem data={question?.explanation?.text} />
                  </AnswerExplanationDecs>
                </QuestionContentWrap>
              </BlockWrap>
            </ShadowScrollbar>
          </QuestionViewerWrapper>
        )}
        {Object.keys(asset).length > 0 && (
          <AssetViewerWrapper>
            <BlockWrap>
              <BlockTitle>Single Asset Render</BlockTitle>
              <TabProvider defaultTab={asset?.id}>
                <AssetContentItem asset={asset} />
              </TabProvider>
            </BlockWrap>
          </AssetViewerWrapper>
        )}
      </DefaultContentWrap>
    </PrimaryWrap>
  );
};

export default QuestionReviewer;
