/* eslint-disable import/prefer-default-export */
import { postPassageUpsert } from '@apis/questiongenerationservice';
import { Passage } from '@components/QuestionGenerationPassages/types';
import { error as showError } from '@utils/toast';
import { useMutation } from 'react-query';

export const usePassageUpsert = () =>
  useMutation((payload: Passage) => postPassageUpsert(payload), {
    mutationKey: ['postPassageUpsert'],
    onError: (err: any) => {
      const errorMessage = err?.message || 'Failed to submit passage. Please try again.';
      showError(errorMessage);
    },
  });
