/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
  OptionsCreateOrJoinWrapper,
  NextIconOptions,
  ContentInsideWellWrapper,
} from './CreateOrJoinModal.style';

const CreateOrJoinModal = (props) => (
  <CreateOrJoinItemWrap>
    <CreateOrJoinItem>
      <CreateOrJoinTitle>
        <h1>Create or Join</h1>
        <p>Select one of the options below,</p>
        <OptionsCreateOrJoinWrapper onClick={props.moveToSubscriptionForm}>
          <ContentInsideWellWrapper>
            <span>Create or Join Course</span>
            <p>Create a new course or request to join an existing course</p>
          </ContentInsideWellWrapper>
          <NextIconOptions className="chalktalk-icon-next" />
        </OptionsCreateOrJoinWrapper>
        <OptionsCreateOrJoinWrapper onClick={props.moveToJoinSection}>
          <ContentInsideWellWrapper>
            <span>Join Section</span>
            <p>Join an existing teacher section</p>
          </ContentInsideWellWrapper>
          <NextIconOptions className="chalktalk-icon-next" />
        </OptionsCreateOrJoinWrapper>
      </CreateOrJoinTitle>
    </CreateOrJoinItem>
  </CreateOrJoinItemWrap>
);

CreateOrJoinModal.propTypes = {
  moveToSubscriptionForm: PropTypes.func,
  moveToJoinSection: PropTypes.func,
};

export default CreateOrJoinModal;
