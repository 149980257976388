import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const CreateOrJoinItemWrap = styled.div`
  width: 100%;
`;

export const CreateOrJoinItem = styled.div``;

export const CreateOrJoinTitle = styled.div`
  margin: 10px 0px 40px;
  & > h1 {
    font-size: 20.0pt;
    font-family: 'MontserratRegular';
    line-height: 35px;
    color: ${(props) => props.theme.colorStyled.ColorDarkerGrey};
    font-weight: bold;
    ${({ isCreateCourseModal }) => isCreateCourseModal && css`
      padding: 0 20px;
    `}
    @media (max-width: 480px) {
      font-size: 18.0pt;
    }
  }
  & > p {
    font-size: 16px;
    line-height: normal;
    font-family: 'MontserratLight';
    color: #7f7f7f;
  }
  form {
    margin-top: 20px;
    > div {
      display: flex;
      align-items: center;
      justify-items: center;
      > div {
        display: inline-block;
        width: 50%;
        height: inherit;
        padding: 0 10px;
        margin-bottom: 0;
      }
    }
    @media (max-width: 480px) {
      margin-top: 10px;
      > div {
        flex-direction: column;
        > div {
          width: 100%;
        }
      }
    }
  }
  .select__value-container {
    width: 85%;
    @media (max-width: 480px) {
      min-width: unset;
    }
  }
  .select__indicators {
    width: 15%;
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9em;
  }
`;

export const NextIconOptions = styled.i`
  width: 0;
  height: 0;
  font-size: 40px;
  position: relative;
  bottom: 50px;
  right: -570px;
  color: ${(props) => props.theme.colorStyled.ColorOrange};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ isSectionCreationModal }) => isSectionCreationModal && css`
    margin-top: 20px;
    flex-direction: row;
    padding-left: 25%;
  `}

  ${({ SubmitButton }) => SubmitButton && css`
    padding-left: 23%;
  `}

  ${({ isSectionNameModal }) => isSectionNameModal && css`
    margin: 30px 0 10px 0;
    flex-direction: row;
    align-items: flex-end;
  `}

  ${({ isJoinSectionModal }) => isJoinSectionModal && css`
    margin: 0 auto;
    margin-bottom: 1rem;
  `}

  ${({ isRelative }) => isRelative && css`
    position: relative;
  `}
`;

export const ButtonStyled = styled.button`
  width: 300px;
  height: 45px;
  margin: 30px 0;
  border-radius: 8.5px;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 1px solid ${(props) => props.theme.colorStyled.ColorBgDefault};
  background-color: ${(props) => props.theme.colorStyled.ColorBgDefault};

  ${({ isSubmittedBtnInModal }) => isSubmittedBtnInModal && css`
    border: 1px solid ${(props) => props.theme.colorStyled.ColorPurple};
    background-color: ${(props) => props.theme.colorStyled.ColorPurple};
    margin: 30px 0 0 0;
    border-radius: 2px;
    font-size: 10.0pt;
    font-family: 'MontserratLight';
  `}
  ${({ isBlueBtnInModal }) => isBlueBtnInModal && css`
    border: 1px solid ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    background-color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
    border-radius: 2px;
  `}
  box-shadow: 0px 4px 6.5px 0 #ff9e1630;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-color: #a3a0a0;
    background-color: #a3a0a0;
    box-shadow: 0 0 10.5px 0 rgba(0, 0, 0, 0.06);
  }
  ${({ primary }) => primary && css`
    color: ${(props) => props.theme.colorStyled.ColorPrimary};
    border-color: ${(props) => props.theme.colorStyled.ColorWhite};
    background-color: ${(props) => props.theme.colorStyled.ColorWhite};
    box-shadow: 0 0 10.5px 0 rgba(0, 0, 0, 0.06);
  `}

  ${({ isSectionNameModal }) => isSectionNameModal && css`
    padding: unset !important;
    width: 55%;
    margin: 0 0 0 15% !important;
  `}
  ${({ isJoinSectionModal }) => isJoinSectionModal && css`
    padding: unset !important;
    width: 50%;
  `}

  ${({ isNotMargin }) => isNotMargin && css`
    margin: 0 auto;
  `}

  ${({ isInSectionConfigSchedule }) => isInSectionConfigSchedule && css`
    margin: 0 auto;
    width: 100%;
  `}

  ${({ largeButton }) => largeButton && css`
    width: 150%;
  `}

  ${({ maxWidth }) => maxWidth && css`
    max-width: 400px;
  `}
  ${({ isSectionCreationModal }) => isSectionCreationModal && css`
    height: auto;
    width: 13em;
    font-size: 12px;
    font-weight: normal;
    padding: 5px 15px;
    border-radius: 10px;
    background-color: #453535;
    border: none;
    margin: 0;
    margin-left: 1em;
  }
`}
  ${({ isSubmitSection }) => isSubmitSection && css`
    margin-left: 10em;
  }
`}
  ${({ isContinueModal }) => isContinueModal && css`
    background-color: #ff9e16;
  }
`}

  ${({ marginTop }) => marginTop && css`
    margin-top: 3em;
  `}
`;

export const InviteTeachersText = styled.p`
  /* font: 400 13px system-ui !important; */
  font-style: italic !important;
  color: ${(props) => props.theme.colorStyled.ColorHint} !important;
  font-size: 8.0pt !important;
  font-family: 'MontserratLight' !important;
  font-weight: normal;
  a{
    font-style: normal;
    color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  }
`;

export const ExamSessionTypeContext = styled.div`
  background-color: ${(props) => props.theme.colorStyled.ColorHint};
  padding: 12px !important;
  border-radius: 10px;
  font-family: 'MontserratLight';
  p {
    color: black;
    font-size: 9.0pt;
  }
  @media (max-width: 480px) {
    margin: 1em 1em;
    width: 80% !important;
    p {
      font-size: 8.0pt;
    }
  }
`;

export const FreeExperienceWrapper = styled.div`
  width: 104px;
  position: absolute;
  top: 30px;
  right: 40px;
  border: 1px #CCCCCC dashed;
  color: #999999;
  font-family: 'Montserrat',Arial;
  font-size: 8pt;
  font-style: normal;
  font-weight: 200;
  line-height: 15px;
  padding: 4px;
  @media (max-width: 480px) {
    position: unset;
    text-align: center;
    font-size: 7pt;
    line-height: 10px;
    margin-top: 1em;
  }
`;

export const ExamFieldWrapper = styled.div`
  div{
    margin-bottom: unset;
  }
  p{
    font-family: 'Montserrat',Arial;
    font-size: 8.0pt;
    font-weight: 200;
    color: ${(props) => props.theme.colorStyled.ColorBlack};
  }
`;
