import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectors as SectionUnitSelectors } from './sectionUnit';

export const useUnits = (subject) => {
  const subjectSelected = useSelector((state: any) =>
    SectionUnitSelectors.getUnitTreeBySubjectId(state, subject?.id),
  );
  return React.useMemo(() => subjectSelected?.units || [], [subjectSelected]);
};

export const useUnitDetails = () => {
  const { unitId } = useParams();
  const unitDetails = useSelector((state: any) =>
    SectionUnitSelectors.getUnitById(state, unitId),
  );

  return unitDetails || {};
};
