import { all, put, select, takeLeading, takeEvery, call } from 'redux-saga/effects';
import { push, getLocation } from 'connected-react-router';
import { REHYDRATE } from 'redux-persist';
import { configToken } from '../../apis/config';
import {
  AUTH_LOGIN_SUCCESS,
  selectors as AuthSelectors,
  actions as authActions,
  AUTH_CONNECT_CLEVER_SUCCESS,
  AUTH_CONNECT_CLEVER_FAILED,
  AUTH_CONNECT_CLASSLINK_FAILED,
  AUTH_CONNECT_CLASSLINK_SUCCESS,
  AUTH_CONNECT_SCHOOLOGY_FAILED,
  AUTH_CONNECT_SCHOOLOGY_SUCCESS,
} from '../../reducers/auth';
import { actions as SystemActions } from '../../reducers/system';
import { selectors as UserSelectors } from '../../reducers/user';
import { handleRefreshToken } from '../../apis/auth';
import { getSectionListByCourseId } from '../../apis/courses';
import {
  // actions definnition
  COURSE_FETCH_ON_START_SUCCESS,
  // actions
  actions as CourseActions,
  selectors as CourseSelectors,
} from '../../reducers/courses';
import {
  // actions definition
  SECTIONS_GET_ON_START_SUCCESS,
  // actions
  actions as SectionActions,
} from '../../reducers/sections';

// !Get new access token when the first time access page
function* startPageStep() {
  const token = yield select(AuthSelectors.getToken);

  const isAuthenticated = !!token;

  if (!isAuthenticated) {
    yield put(SystemActions.systemSetPageLoading(false));
  } else {
    const refreshToken = yield select(AuthSelectors.getRefreshToken);
    const { response, error } = yield call(handleRefreshToken, refreshToken);

    if (error) {
      yield put(authActions.logout());
      return;
    }

    const { access: accessToken, refresh } = response.data || {};
    yield put(authActions.refreshLoginToken(accessToken, refresh));

    configToken(accessToken);

    const location = yield select(getLocation);
    const { pathname } = location;

    const isConnectedClever = yield select(AuthSelectors.isConnectedClever);
    if (!isConnectedClever && pathname === '/auth/complete/clever/') {
      yield put(SystemActions.systemSetPageLoading(false));
      return;
    }

    const isConnectedClasslink = yield select(AuthSelectors.isConnectedClasslink);
    if (!isConnectedClasslink && pathname === '/auth/complete/classlink/') {
      yield put(SystemActions.systemSetPageLoading(false));
      return;
    }

    yield put(authActions.authOpenWebsocket());
    yield put(CourseActions.courseFetchOnStart());
  }
}

function* startPageFetchCourseSuccess({ courseList }) {
  const isEmpty = courseList.length === 0;
  const userRole = yield select(UserSelectors.getUserRole);

  const path = window.location.pathname;
  var url = window.location;
  var token = new URLSearchParams(url.search).get('token');
  if (path == '/account/verify') {
    yield put(push('/account/verify?token=' + token));
    yield put(SystemActions.systemSetPageLoading(false));
    return;
  }

  if (isEmpty) {
    if (userRole === 'Teacher') yield put(push('/profile?isOpenTeacherCourseSetup=1'));
    if (userRole === 'Student') yield put(push('/new-student'));

    yield put(SystemActions.systemSetPageLoading(false));
    return;
  }

  let firstItem = courseList[0];
  for (let i = 0; i < courseList.length; i++) {
    const { response } = yield call(getSectionListByCourseId, courseList[i].id);

    if (response) {
      if (response.data.length > 0) {
        firstItem = courseList[i];
        break;
      }
    }
  }

  /*
  const haveSection = courseList.every((course) => course.sections && course.sections.length);
  let firstItem = courseList[0];
  if (!haveSection) {
    firstItem = courseList.find((course) => !course.sections || !course.sections.length);
  } */

  // course is not empty
  // start fetch sections

  const { id } = firstItem || [];

  yield put(SectionActions.sectionGetOnStart(id));

  // yield put(push(`/dashboard/course/${id}/`));
}

function* startPageFetchSectionSuccess({ sectionList, courseId }) {
  const isEmpty = sectionList.length === 0 || sectionList.length >= 100;
  const userRole = yield select(UserSelectors.getUserRole);

  if (isEmpty) {
    if (userRole === 'Teacher')
      yield put(push(`/management/course/${courseId}?isOpenCreateSection=1`));
    if (userRole === 'Student') yield put(push('/new-student'));

    yield put(SystemActions.systemSetPageLoading(false));
    return;
  }

  try {
    let emptySections = sectionList.map((ele) => {
      if (ele.subjects) {
        if (ele.subjects.length == 1) return ele;
      } else {
        return ele;
      }
    });

    emptySections = emptySections.filter((el) => el != null);

    let section = null;
    if (emptySections.length > 0) {
      emptySections.forEach((item) => {
        const keys = Object.keys(item.section_scheduled_subjects);
        if (keys.length == 1) {
          if (item.section_scheduled_subjects[keys[0]].scheduled == 0) {
            section = item;
          }
        } else if (keys.length == 0) {
          section = item;
        }
      });
    }

    if (section) {
      if (userRole === 'Teacher')
        yield put(
          push(`/schedule/course/${courseId}/section/${section.id}/section-info`),
        );
      // if (userRole === 'Student') yield put(push('/new-student'));
      yield put(SystemActions.systemSetPageLoading(false));
      return;
    }
  } catch (err) {
    console.log(`${err} in courseId = ${courseId} -- --`);
  }

  // section is not empty
  // progress to dashboard
  const location = yield select(getLocation);
  const { pathname } = location;

  if (pathname === '/register/teacher' || pathname === '/new-student') {
    yield put(push('/'));
  }
  yield put(SystemActions.systemSetPageLoading(false));
}

function* systemHandleLoginSuccess({ payload }) {
  const { token } = payload;
  configToken(token);

  const location = yield select(getLocation);
  const { pathname } = location;

  const isConnectedClever = yield select(AuthSelectors.isConnectedClever);
  if (!isConnectedClever && pathname === '/auth/complete/clever/') {
    yield put(SystemActions.systemSetPageLoading(false));
    return;
  }

  const isConnectedClasslink = yield select(AuthSelectors.isConnectedClasslink);
  if (!isConnectedClasslink && pathname === '/auth/complete/clever/') {
    yield put(SystemActions.systemSetPageLoading(false));
    return;
  }
  yield put(SystemActions.systemSetPageLoading(true));
  yield put(CourseActions.courseFetchOnStart());
}

export default function* systemSaga() {
  yield all([
    takeEvery(
      [
        AUTH_LOGIN_SUCCESS,
        AUTH_CONNECT_CLEVER_SUCCESS,
        AUTH_CONNECT_CLEVER_FAILED,
        AUTH_CONNECT_SCHOOLOGY_SUCCESS,
        AUTH_CONNECT_SCHOOLOGY_FAILED,
        AUTH_CONNECT_CLASSLINK_SUCCESS,
        AUTH_CONNECT_CLASSLINK_FAILED,
      ],
      systemHandleLoginSuccess,
    ),
    takeLeading(REHYDRATE, startPageStep),
    takeEvery(COURSE_FETCH_ON_START_SUCCESS, startPageFetchCourseSuccess),
    takeEvery(SECTIONS_GET_ON_START_SUCCESS, startPageFetchSectionSuccess),
  ]);
}
