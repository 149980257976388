import styled, { css, keyframes } from 'styled-components';

const BounceInput = keyframes`
 0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(14px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-14px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
`;

export const InviteStudentsItemWrap = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    margin-top: 20%;
  }
`;

export const InviteStudentsItem = styled.div``;

export const InviteStudentsTitle = styled.div`
  & > h1 {
    color: #666666;
    font-family: 'MontserratRegular',Arial;
    font-size: 20.0pt;
    line-height: 38px;
    @media (max-width: 768px) {
      padding: 10px;
    }
  }
  & > p {
    color: #999999;
    font-family: 'Montserrat',Arial;
    font-size: 10.0pt;
    font-style: normal;
    font-weight: 200;
  }
  @media (max-width: 480px) {
    text-align: center;
  }
`;

export const TabsInviteStudents = styled.div`
  position: relative;
  transition: all 300ms ease;
  padding: 10px 0;
  .react-tabs {
    width: 100%;
  }
  .react-tabs__tab-list {
    display: flex;
    align-items: center;
    width: fit-content;
    width: 100%;
    li {
      border: 1px #EFEFEF solid;
      border-radius: 17px 17px 0px 0px;
      border-bottom: none;
      box-shadow: none;
      width: 50%;
      color: #434343;
      font-family: 'MontserratRegular',Arial;
      font-size: 10.0pt;
      text-align: center;
      padding: 10px 0px;
      cursor: pointer;
    }
    .react-tabs__tab {
      background-color: #F3F3F3;
    }
    .react-tabs__tab--selected {
      background-color: white;
      font-weight: 600;
    }
  }
  .react-tabs__tab-panel--selected {
    width: 100%;
    display: table;
    max-height: 259px;
  }
`;

export const UsingCodeContentWrapper = styled.div`
  width: 439px;
  height: 261px;
  vertical-align: middle;
  text-align: center;
  display: table-cell;
  padding: 50px;
  border: 1px #EFEFEF solid;
`;

export const TextInUsingCodeTab = styled.span`
  width: 100%;
  color: #434343;
  text-align: center;
  font-family: 'Montserrat',Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-weight: 200;
  line-height: 0px;
`;

export const TextCodeWrapper = styled.span`
  color: #999999;
  font-family: 'Montserrat-Regular',Arial;
  font-size: 18.0pt;
  line-height: 18.0pt;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonStyled = styled.button`
  width: 300px;
  height: 45px;
  margin-top: 30px;
  color: ${(props) => props.theme.colorStyled.ColorWhite};
  border: 1px solid #6669C9;
  background-color: #6669C9;
  font-family: 'MontserratRegular',Arial;
  font-size: 10.0pt;
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colorStyled.ColorWhite};
    border-color: #5E5E5E;
    background-color: #5E5E5E;
  }
  @media (max-width: 1440px) {
    padding: 10px 60px;
  }
  @media (max-width: 480px) {
    padding: 10px 20px;
    width: 200px;
  }
`;

export const LinkSkipThisStepWrapper = styled.div`
  text-align: center;
  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

export const LinkSkipThisStep = styled.a`
  cursor: pointer;
  color: ${(props) => props.theme.colorStyled.ColorPrimaryBlue};
  font-family: 'Montserrat',Arial;
  font-size: 8.0pt;
  font-weight: 200;
`;

export const SendInvitesWrapper = styled.div`
  width: 100%;
  border: 1px #EFEFEF solid;
  padding: 10px;
`;

export const TextInContent = styled.span`
  color: #434343;
  font-family: 'Montserrat',Arial;
  font-size: 10.0pt;
  font-style: normal;
  font-weight: 200;
`;

export const PlusSignInContent = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

export const FieldsTextInContent = styled.span`
  color: #434343;
  font-family: 'Montserrat-LightItalic',Arial;
  font-size: 11.0pt;
  font-style: italic;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4A86E8;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  i {
    color: white;
    font-size: 20px;
  }
`;

export const StudentInformationTableWrapper = styled.div`
  min-height: 200px;
  max-height: 300px;
`;

export const StudentInformationTable = styled.table`
  margin: 10px 0;
  text-align: center;
  display: inline-block;
  overflow: auto;
  height: 150px;
  div {
    margin-bottom: 0;
  }
  td {
    width: 125px;
  }
  td:last-child {
    width: 20px;
    font-size: 20px;
    background: white;
    padding-left: 5px;
    cursor: pointer;
  }
  tr:nth-child(odd) {
    background: #F2F2F2;
    .select__control {
      background: #F2F2F2;
    }
  }
  .select__value-container {
    min-width: auto;
    font-size: 8.0pt;
  }
  .select__indicators {
    font-size: 2.3em;
  }
  input {
    color: #282828;
    text-align: left;
    text-decoration: none;
    font-family: 'Montserrat-Light',Arial;
    font-size: 8.0pt;
    line-height: 0px;
  }
`;

export const ErrorText = styled.div`
  margin-top: 10px;
  color: red;
  text-align: center;
`;

export const ContentWrapper = styled.div`
@media (max-width: 320px) {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 258px;
}
`;
