import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Preview } from '@learnosityassessment';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { useIsAdaptiveLearnosityPracticeEnabled } from '@reducers/curriculums/hooks';
import { useIsDistrictAdmin } from '@reducers/user/hooks';
import ScrollWrapper from '@components/Atoms/ScrollWrapper';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import PracticeTopNavBar from '../PracticeTopNavBar';
import { useGetLearnosityDistrictQuestions } from './hooks';

const QuestionSelectorPreview = () => {
  const history = useHistory();
  const { lessonId } = useParams();
  const { data: districtSelection } = useGetLearnosityDistrictQuestions();
  const { data: lessonDetail } = useGetLessonDetail();
  const isAdaptiveLearnosityPracticeEnabled = useIsAdaptiveLearnosityPracticeEnabled();
  const isDistrictAdmin = useIsDistrictAdmin();

  if (!isDistrictAdmin || !isAdaptiveLearnosityPracticeEnabled) {
    return history.push('/dashboard');
  }

  const goBack = () => {
    history.goBack();
  };

  return (
    <PracticeContainer>
      <PracticeTopNavBar goBackText="Go back" goBack={goBack} />
      <ScrollWrapper>
        <Preview
          activityId={lessonId}
          title={lessonDetail.name}
          questions={districtSelection.questions}
        />
      </ScrollWrapper>
    </PracticeContainer>
  );
};

export default React.memo(QuestionSelectorPreview);
