import styled, { css } from 'styled-components';

export const ItemBankCard = styled.div`
  flex: 0 0 calc(100% / 3 - 3em);
  margin-top: 1em;
  margin-bottom: 1.5em;
  padding: 1em 3em;
  background-color: ${(props) => props.theme.colorStyled.ColorWhite};
  border-radius: 0.5em;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 0px 3px, rgba(9, 30, 66, 0.31) 0px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  i {
    font-size: ${(props) => props.theme.fontSize.HeadingH2FontSize};
    margin-bottom: 0.75em;
    margin-right: auto;
  }
  &:nth-child(3n) {
    margin-right: 0em;
  }
  .chalktalk-in-progress {
    border-radius: 100%;
    background-color: #ddd;
  }
  font-family: 'MontserratMedium';
`;

export const ItemBankOverviewCardHeader = styled.div`
  color: #ff9d17;
  font-weight: bold;
  font-size: 1.1em;
  span {
    background: #ff9d17;
    border-radius: 100%;
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    text-align: center;
    line-height: 1.2em;
    font-size: 1.1em;
    color: white;
    font-weight: normal;
    margin-right: 5px;
  }
`;

export const ItemBankOverviewCardBlock = styled.div`
  margin: 1em 0em;
  line-height: 1.8em;
  p {
    display: block;
    margin-top: 1em;
  }
  strong {
    font-weight: bold;
  }
`;

export const ItemBankCardBlock = styled.div`
  margin: 1em 0em;
  padding-left: 20%;
  padding-right: 20%;
`;

export const ItemBankCardHeader = styled.div`
  color: #020b0d;
  font-family: 'MontserratMedium';
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  p {
    margin-top: 1em;
  }
  strong {
    font-weight: bold;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  gap: 1rem;
`;
