import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import AnswerGridChoice from '../AnswerGridChoice';
import AnswerMultiChoice from '../AnswerMultiChoice';
import AnswerShortText from '../AnswerShortText';
import { QUESTION_TYPES, REPORT_TYPES } from '../../utils/enums';
import { transformGrinInAnswer } from '../../utils/func-utils';
import {
  QuestionWrap,
  QuestionHeaderWrap,
  QuestionProgress,
  QuestionContentWrap,
  QuestionAnswer,
  QuestionAction,
  BottomButtons,
  QuestionOrderWrapper,
  QuestionOrderNumber,
  Wrapper,
  QuestionDesc,
} from '../QuestionItem/QuestionItem.style';
import {
  Button,
  FeedbackContainer,
  FeedbackIcon,
} from '../Generals/stylesheets/Button.style';
import MathJaxItem from '../MathJaxContainer';

export default class PracticeReviewItem extends Component {
  static propTypes = {
    questionSelected: PropTypes.object,
    totalQuestions: PropTypes.number,
    prevQuestion: PropTypes.func,
    nextQuestion: PropTypes.func,
    order: PropTypes.number,
    index: PropTypes.number,
    shouldDisplayGrammarHelper: PropTypes.bool,
    isTeacher: PropTypes.bool,
    score: PropTypes.object,
    completePractice: PropTypes.func.isRequired,
    openReportErrorModal: PropTypes.func,
    questionId: PropTypes.number,
  };

  openReportErrorModal = () => {
    const { openReportErrorModal, questionId } = this.props;

    openReportErrorModal(REPORT_TYPES.QUESTION, questionId);
  };

  renderAnswer = () => {
    const { questionSelected, isTeacher } = this.props;
    const answerType = _get(questionSelected, 'exam_question.question.question_format');
    const choices = _get(questionSelected, 'exam_question.question.choices', []);
    const userChoice = _get(questionSelected, 'question_response.choices', []);
    const correctChoice = _get(
      questionSelected,
      'exam_question.question.correct_choices',
      [],
    );

    const selectedValue = _get(questionSelected, 'question_response.text', '');

    const score = _get(questionSelected, 'score', {});

    switch (answerType) {
      case QUESTION_TYPES.SHORT_ANSWER: {
        return (
          <AnswerShortText
            inputValue={selectedValue}
            score={score}
            shouldDisplayAnswer
            choice_answer={correctChoice}
            isTeacher={isTeacher}
          />
        );
      }
      case QUESTION_TYPES.GRID_IN: {
        const transformValue = transformGrinInAnswer(
          isTeacher ? correctChoice : selectedValue,
        );
        const selectedValueArr = transformValue.split('');
        return (
          <AnswerGridChoice
            selectedValue={selectedValueArr}
            score={score}
            isTeacher={isTeacher}
            shouldDisplayAnswer
          />
        );
      }
      case QUESTION_TYPES.MULTIPLE_CHOICES:
      default:
        return (
          <AnswerMultiChoice
            choices={choices}
            correctChoice={correctChoice}
            userChoice={userChoice}
            shouldDisplayAnswer
          />
        );
    }
  };

  renderButtonGroup = () => {
    const {
      index,
      totalQuestions,
      toggleExplanationSideBar,
      completePractice,
      prevQuestion,
      nextQuestion,
      onlineQuestion,
      isGroupActivity,
      shouldNotDisplayComplete,
    } = this.props;
    const shouldRenderPrevButton = index > 0;
    const shouldRenderNextButton = index < totalQuestions - 1;

    return (
      <React.Fragment>
        <BottomButtons
          isGroupActivity={isGroupActivity && shouldRenderPrevButton}
          center={shouldRenderPrevButton}
        >
          {shouldRenderPrevButton && (
            <Button blue mobDesk textUppercase onClick={prevQuestion}>
              {'< Previous'}
            </Button>
          )}
          <Button
            blue
            textUppercase
            hideOnDesktop
            mobDesk
            onClick={toggleExplanationSideBar}
          >
            Show Explanation
          </Button>

          {(!shouldNotDisplayComplete || shouldRenderNextButton) && !onlineQuestion && (
            <Button
              blue
              mobDesk
              textUppercase
              onClick={shouldRenderNextButton ? nextQuestion : completePractice}
              id={
                shouldRenderNextButton
                  ? 'AdaptivePractice-NextQuestion'
                  : 'AdaptivePractice-CompletePractice'
              }
            >
              {shouldRenderNextButton ? 'Next >' : 'Complete'}
            </Button>
          )}
          {shouldRenderNextButton && onlineQuestion && (
            <Button blue mobDesk textUppercase onClick={nextQuestion}>
              {'Next >'}
            </Button>
          )}
        </BottomButtons>
      </React.Fragment>
    );
  };

  render() {
    const { questionSelected, totalQuestions, order, index, shouldDisplayGrammarHelper } =
      this.props;
    const getQuestionContent = _get(
      questionSelected,
      'exam_question.question.prompt.text',
      '',
    );
    return (
      <QuestionWrap>
        <QuestionHeaderWrap>
          <QuestionProgress>
            Question
            <span>{`${index + 1} / ${totalQuestions}`}</span>
          </QuestionProgress>
          <FeedbackContainer onClick={this.openReportErrorModal}>
            <FeedbackIcon src="/images/feedback.png" />
          </FeedbackContainer>
        </QuestionHeaderWrap>
        <QuestionContentWrap>
          <Wrapper>
            {shouldDisplayGrammarHelper && (
              <QuestionOrderWrapper>
                <span>This question refers to </span>
                <QuestionOrderNumber>{order}</QuestionOrderNumber>
              </QuestionOrderWrapper>
            )}
            <QuestionDesc>
              <MathJaxItem data={getQuestionContent} />
            </QuestionDesc>
            <QuestionAnswer>{this.renderAnswer()}</QuestionAnswer>
          </Wrapper>
          <QuestionAction>{this.renderButtonGroup()}</QuestionAction>
        </QuestionContentWrap>
      </QuestionWrap>
    );
  }
}
