import React from 'react';
import Box from '@components/Atoms/Box';
import Dialog from '@components/Atoms/Dialog';
import ShowIf from '@components/Atoms/ShowIf';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { error as errorToast } from '@utils/toast';
import Typography from '@components/Atoms/Typography';
import { useIsTeacher } from '@reducers/user/hooks';
import { LessonTabs } from '@components/Component/Lesson/LessonContainer/Types';
import { useNavigateToLesson } from '@components/Component/Lesson/LessonContainer/hooks';
import { useRemoveLearnosityLessonReview } from './hooks/query';
import { useIsReviewClassActive } from './hooks/helpers';

const LessonReviewDeleteButton = ({
  isReviewClassEnabled,
  hasEditModeEnabled,
  lessonId,
  name,
}) => {
  const navigateToLesson = useNavigateToLesson();
  const isReviewClassActive = useIsReviewClassActive();
  const [showDialog, setShowDialog] = React.useState(false);
  const isTeacher = useIsTeacher();
  const { mutate: removeLearnosityLessonReview } =
    useRemoveLearnosityLessonReview(lessonId);

  return (
    <ShowIf If={isTeacher && isReviewClassEnabled && !hasEditModeEnabled}>
      <Box
        sx={{
          my: '0.375rem',
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'flex-end',
          paddingInlineEnd: '0.5rem',
        }}
      >
        <DeleteRoundedIcon
          onClick={(e) => {
            e.stopPropagation();
            setShowDialog(true);
          }}
          sx={{
            fontSize: '1.125rem',
            fill: (theme) => theme.palette.error.main,
          }}
        />
      </Box>
      <Dialog
        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e.stopPropagation();
        }}
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        title="Delete Assessment"
        actions={[
          {
            label: 'cancel',
            onClick: () => {
              setShowDialog(false);
            },
          },
          {
            label: 'Delete',
            onClick: () => {
              removeLearnosityLessonReview(_, {
                onSuccess: () => {
                  setShowDialog(false);
                  if (isReviewClassActive) {
                    navigateToLesson({
                      tabKey: LessonTabs.Lesson,
                    });
                  }
                },
                onError: () => {
                  errorToast('Error deleting Assessment.');
                },
              });
            },
            buttonProps: {
              variant: 'contained',
              color: 'error',
            },
          },
        ]}
      >
        <Typography>
          Are you sure you want to delete the&nbsp;
          <Typography
            component="span"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {name}
          </Typography>
          ? It will be archived and can be restored within 30 days. After that, it will be
          permanently deleted.
        </Typography>
      </Dialog>
    </ShowIf>
  );
};
export default LessonReviewDeleteButton;
