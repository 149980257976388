import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import FormInput from '../Generals/FormInput/FormInput';
import {
  UserProfileView,
  UserLabel,
  UserContent,
} from '../Profile/Profile.style';
import {
  ActionGroup,
  Button,
} from '../Generals/stylesheets/Button.style';
import {
  InputFormWrapper,
} from '../CourseManagementSection/CourseManagementSection.style';
import {
  ModalHeader,
  ModalCLose,
  ModalSubText,
  ModalSubHeader,
} from '../Generals/stylesheets/General.style';
import {
  RegisterMessageContent,
  RegisterCodeStyled,
} from '../StudentRegister/StudentRegister.style';

const initialValues = {
  new_password: '',
  re_new_password: '',
};

const validationSchema = Yup.object().shape({
  new_password: Yup.string(),
  re_new_password: Yup.string(),
});

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '640px',
    margin: '0 auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

class StudentResetPasswordForm extends React.PureComponent {
  onSubmit = (values, { resetForm }) => {
    this.props.onSubmit(values);
    resetForm();
  }

  renderFormContent = () => {
    const { isChangePassword } = this.props;
    return (
      <UserProfileView>
        <RegisterMessageContent>
          <p>
            Use the dummy password (click Reset)
            <RegisterCodeStyled>
              SATworkshop
            </RegisterCodeStyled>
            OR enter a new one
          </p>
          <Form>
            <InputFormWrapper>
              <UserLabel>New Password</UserLabel>
              <UserContent>
                <FormInput type="password" name="new_password" />
              </UserContent>
            </InputFormWrapper>
            <InputFormWrapper>
              <UserLabel>Confirm New Password</UserLabel>
              <UserContent>
                <FormInput type="password" name="re_new_password" />
              </UserContent>
            </InputFormWrapper>
            <ActionGroup center marginTop>
              <Button
                smallest
                type="submit"
                disabled={isChangePassword}
              >
                {isChangePassword ? 'Loading' : 'Reset'}
              </Button>
            </ActionGroup>
          </Form>
        </RegisterMessageContent>
      </UserProfileView>
    );
  }

  render() {
    const {
      isOpen, onClose,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <ModalHeader>
          Reset Student Password
          <ModalCLose className="chalktalk-close" onClick={onClose} />
        </ModalHeader>
        <ModalSubHeader>
          <ModalSubText>
            You can either use the default dummy password or set your own password for the student.
          </ModalSubText>
        </ModalSubHeader>
        <Formik
          initialValues={initialValues}
          onSubmit={this.onSubmit}
          render={this.renderFormContent}
          validationSchema={validationSchema}
        />
      </Modal>
    );
  }
}

StudentResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  isChangePassword: PropTypes.bool,
};

export default StudentResetPasswordForm;
