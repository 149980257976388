import React from 'react';
import PropTypes from 'prop-types';
import TeacherTrainingVideo from '../../containers/TeacherTrainingVideo';
import LeftSideBarTeacherTraining from '../../containers/LeftSideBar/LeftSideBarTeacherTraining';
import { PrimaryWrap, TextTeacherTraining } from '../Generals/stylesheets/General.style';
import MainContent from '../../containers/MainContent';
class TeacherTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    courseDetail: PropTypes.object,
  }

  render() {
    const { courseDetail, isSideBarClose } = this.props;
    return (
      <PrimaryWrap>
        <LeftSideBarTeacherTraining pathName="/teacher/course" />
        <MainContent>
          <TextTeacherTraining>
            Training
          </TextTeacherTraining>
          <TeacherTrainingVideo />
        </MainContent>
      </PrimaryWrap>
    );
  }
}

export default TeacherTraining;

TeacherTraining;
