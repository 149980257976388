import React, { useEffect, useImperativeHandle } from 'react';
import { createAuthoringSignatureRequest } from '../../AssessmentServiceAPI';
import { useGetSignature } from '../../Hooks/Query';
import { AuthorWidgetWrapper } from './styles';
import { toast } from 'react-toastify';

const clickOnDisplayTotal = () => {
  const ele: HTMLButtonElement | null = document.querySelector(
    `[data-authorapi-selector="display-total"]`,
  );
  if (!ele) {
    return;
  }
  ele.click();
};

const containerId = 'learnosity_author_container';
const AuthorHarness = ({
  user,
  widgetRef,
  eventHandlers,
  setWidget,
  skills,
  createSignature = createAuthoringSignatureRequest,
}) => {
  const errorMessage =
    'Network error, check your internet connection or contact your network administrator to verify Chalktalk\'s domain whitelisted requirements!';
  const mainRef = React.useRef<HTMLDivElement>(null);
  const [widgetInstance, setWidgetInstance] = React.useState(undefined);
  const { data: signature } = useGetSignature(createSignature(user, skills), 'author', {
    enabled: !widgetInstance && !!(window as any).LearnosityAuthor,
    useErrorBoundary: (error: any) => {
      console.error(`Error initializing AuthorHarness, ${errorMessage}`, error);
      toast.error(`${errorMessage}`);

      return true;
    },
  });
  useImperativeHandle(widgetRef, () => ({ widgetInstance }), [widgetInstance]);

  useEffect(() => {
    mainRef.current!.appendChild(document.createElement('div')).id = containerId;
  }, []);
  useEffect(() => {
    if (!widgetInstance) {
      return;
    }
    widgetInstance.on('render:itemlist', clickOnDisplayTotal);
    return () => {
      widgetInstance.off('render:itemlist', clickOnDisplayTotal);
    };
  }, [widgetInstance]);

  const replaceKindcaidDropdown = () => {
    // manually replace the kindcard grade level dropdown with a custom one
    // its hacky for now but we'll replace it later with the algolia implementation
    if (!mainRef.current) {
      return;
    }

    const dropdowns = document.querySelectorAll(
      '[data-authorapi-selector="dropdown-wrapper"]',
    );

    if (!dropdowns.length || dropdowns.length < 2) {
      return;
    }

    const kincaidDiv = dropdowns[1] as HTMLDivElement;
    const kincaidUl = kincaidDiv.querySelector('ul') as HTMLUListElement;
    const listValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    kincaidUl.innerHTML = '';
    kincaidUl.textContent = '';

    listValues.forEach((value) => {
      const li = document.createElement('li');
      const label = document.createElement('label');
      label.classList.add('lrn-dropdown-choice');
      const input = document.createElement('input');
      input.setAttribute('type', 'checkbox');

      input.setAttribute('value', value === 0 ? '' : value.toString());
      input.setAttribute('data-authorapi-selector', 'dropdown-option');
      const div = document.createElement('div');
      div.classList.add('lrn-dropdown-choice-child');
      const span = document.createElement('span');
      span.classList.add('lrn-i-tick');
      const div2 = document.createElement('div');
      div2.classList.add('lrn-dropdown-select-text');
      const div3 = document.createElement('div');
      div3.textContent = value === 0 ? '--' : value.toString();
      div2.appendChild(div3);
      div.appendChild(span);
      div.appendChild(div2);
      label.appendChild(input);
      label.appendChild(div);
      li.appendChild(label);
      kincaidUl.appendChild(li);
    });
  };

  useEffect(() => {
    const isElementOnTheDom = !!document.getElementById(containerId);
    if (Object.keys(signature!).length && !widgetInstance && isElementOnTheDom) {
      const author = (window as any).LearnosityAuthor?.init(
        signature,
        {
          readyListener: () => {
            console.log('Authoring API has been initialized');
            setWidgetInstance(author);
            setWidget(author);
            setTimeout(() => {
              replaceKindcaidDropdown();
            }, 2000);
          },
          errorListener: (error: any) => {
            console.error('Error initializing Authoring API', error);
          },
        },
        containerId,
      );
      if (eventHandlers) {
        eventHandlers.forEach(({ name, callback }) => {
          author.on(name, (...args: any[]) => {
            callback(author, args);
          });
        });
      }
    }
    return () => {
      if (widgetInstance && isElementOnTheDom) {
        widgetInstance.destroy(); // TODO this is not correct
        setWidgetInstance(undefined);
        setWidget(undefined);
      }
    };
  }, [signature, widgetInstance, (window as any).LearnosityAuthor]);
  return (
    // TODO wrap in styles
    <AuthorWidgetWrapper ref={mainRef} />
  );
};
export default AuthorHarness;
