/* eslint-disable eqeqeq */
/* eslint-disable import/prefer-default-export */
import {
  answerType,
  createGroupAnswersType,
  GroupAnswerCombined,
  GroupAnswerType,
  GroupQuestionsAnswersType,
  GroupSubmissionAnswerType,
  olpSubmitScoreType,
  PracticeState,
  QuestionFormat,
  questionType,
  stateType,
  StudentType,
} from '../types';

export const checkGroupAnswersMatch = (answers: GroupAnswerType[]) => {
  // we don't want to execute if there are no answers
  if (answers.length === 0) {
    return false;
  }
  const firstAnswer = answers?.[0].answer;
  return answers.every((answer) => answer.answer === firstAnswer);
};
type checkAbilityToMoveToNextQuestionType = {
  currentQuestion?: questionType;
  state: stateType;
  groupQuestionAnswers: GroupAnswerType[];
  groupMembers: StudentType[];
};
export const checkAbilityToMoveToNextQuestion = ({
  currentQuestion,
  state,
  groupQuestionAnswers,
  groupMembers,
}: checkAbilityToMoveToNextQuestionType) => {
  if (state.practiceState == PracticeState.Individual && currentQuestion) {
    if (state.practiceAnswers[currentQuestion?.id]) {
      return true;
    }
    return false;
  }
  if (state.practiceState == PracticeState.Group) {
    // add logic for group mode
    if (
      groupQuestionAnswers.length == groupMembers.length &&
      checkGroupAnswersMatch(groupQuestionAnswers)
    ) {
      return true;
    }
    return false;
  }
  return false;
};
type getCurrentGroupAnswerType = {
  currentQuestion?: questionType;
  state: stateType;
};

export const getGroupQuestionAnswers = ({
  currentQuestion,
  state,
}: getCurrentGroupAnswerType) => {
  if (currentQuestion) {
    const groupAnswerForCurrentQuestion = state.groupAnswers.filter(
      (answer) => answer.question_id === currentQuestion.id && answer.answer,
    );
    return groupAnswerForCurrentQuestion;
  }
  return [];
};
type margeUserToAnswersType = {
  answers: GroupAnswerType[];
  groupMembers: StudentType[];
};
// ts arrow function output type

export const margeUserToAnswers = ({
  answers,
  groupMembers,
}: margeUserToAnswersType): GroupAnswerCombined[] => {
  const combinedAnswers = answers.map((answer) => {
    const member = groupMembers.find((m) => m.student_id == answer.student_id);

    return {
      ...answer,
      ...member,
    } as GroupAnswerCombined;
  });
  return combinedAnswers;
};
type getCurrentAnswerType = {
  currentQuestion?: questionType;
  state: stateType;
  userId: number;
};
export const getCurrentAnswer = ({
  currentQuestion,
  state,
  userId,
}: getCurrentAnswerType) => {
  if (currentQuestion && state.practiceState == PracticeState.Individual) {
    return state.practiceAnswers[currentQuestion.id]?.value;
  }

  if (currentQuestion && state.practiceState == PracticeState.Group) {
    return state.groupAnswers.find(
      (a) => a.question_id == currentQuestion.id && a.student_id == userId,
    )?.answer;
  }
  return undefined;
};
export const getFinishTime = (startTime: Date, duration: number) => {
  const finishTime = new Date(startTime);
  finishTime.setMinutes(finishTime.getMinutes() + duration);
  return finishTime;
};

export const createGroupAnswersFromIndividualAnswers = (
  individualAnswers: answerType[],
  groupMember: StudentType,
  groupId: string,
): GroupAnswerType[] => {
  const groupAnswers: GroupAnswerType[] = [];
  individualAnswers.forEach((answer) => {
    groupAnswers.push({
      answer: answer.value,
      group_id: groupId,
      question_id: answer.exam_question_id,
      student_id: groupMember.student_id,
    });
  });

  return groupAnswers;
};

export const createGroupAnswers = ({
  groupMembers,
  questions,
  userId,
  groupId,
  groupAnswers,
  individualAnswers,
}: createGroupAnswersType): olpSubmitScoreType => {
  const checkIfQuestionCorrect = (question: questionType, answer?: string | number) => {
    if (!answer) {
      return false;
    }
    if (question.question.question_format === QuestionFormat.MultiChoice) {
      return question.question?.correct_choices?.[0]
        ? question.question?.correct_choices?.[0] == answer
        : false;
    }
    if (
      question.question.question_format === QuestionFormat.ShortText ||
      question.question.question_format === QuestionFormat.GridIn
    ) {
      return question.question?.correct_choices
        ? question.question?.correct_choices == answer
        : false;
    }

    return false;
  };

  const getGroupScore = (question: questionType[], answers: GroupAnswerType[]) => {
    let correctAnswers = 0;
    question.forEach((q) => {
      const answer = answers.find((a) => a.question_id == q.id);
      if (answer) {
        if (checkIfQuestionCorrect(q, answer?.answer)) {
          // eslint-disable-next-line no-plusplus
          correctAnswers++;
        }
      }
    });

    return correctAnswers;
  };
  const getStudentScore = (question: questionType[], answers: answerType[]) => {
    let correctAnswers = 0;
    question.forEach((q) => {
      const answer = answers.find((a) => a.exam_question_id == q.id);
      if (answer) {
        if (checkIfQuestionCorrect(q, answer?.value)) {
          // eslint-disable-next-line no-plusplus
          correctAnswers++;
        }
      }
    });
    return correctAnswers;
  };

  const getGroupQuestionAnswersObject = (
    answer: GroupAnswerType | undefined,
    members: StudentType[],
  ): GroupSubmissionAnswerType[] => {
    if (!answer) {
      return [];
    }
    return members.map((a) => ({
      student_id: `${a.student_id}`,
      text_answer: `${answer?.answer || ''}`,
      locked: true,
    }));
  };

  const getGroupQuestionsAnswersObj = (
    membersQuestions: questionType[],
    answers: GroupAnswerType[],
    members: StudentType[],
  ) =>
    membersQuestions.reduce((acc, q) => {
      const answer = answers.find((a) => a.question_id == q.id);
      acc[q.id] = {
        isCorrect: checkIfQuestionCorrect(q, answer?.answer),
        students_answers: getGroupQuestionAnswersObject(answer, members),
        text_answer: `${answer?.answer || ''}`,
      };
      return acc;
    }, {} as GroupQuestionsAnswersType);

  const output = {
    group_id: groupId,
    group_questions_answers: getGroupQuestionsAnswersObj(
      questions,
      groupAnswers,
      groupMembers,
    ),
    group_score: getGroupScore(questions, groupAnswers),
    student_score: {
      score: getStudentScore(questions, individualAnswers),
      student_id: userId,
    },
  };
  return output;
};

export const createGroupAnswersForGroupOfOneUser = ({
  groupMembers,
  questions,
  userId,
  groupId,
  individualAnswers,
}: Omit<createGroupAnswersType, 'groupAnswers'>): olpSubmitScoreType => {
  const groupAnswers = createGroupAnswersFromIndividualAnswers(
    individualAnswers,
    groupMembers[0],
    groupId,
  );
  return createGroupAnswers({
    groupMembers,
    questions,
    userId,
    groupId,
    groupAnswers,
    individualAnswers,
  });
};
