import React from 'react';
import Dialog from '@components/Atoms/Dialog';
import Typography from '@components/Atoms/Typography';

type StartOLPModalProps = {
  show: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  dataTestId?: string;
};

const StartOnlineLessonPracticeDialog = ({
  show,
  onCancel,
  onConfirm,
  dataTestId = 'start-olp-modal',
}: StartOLPModalProps) => (
  <Dialog
    title="End Active Sessions?"
    sx={{ '& .MuiPaper-root': { p: '1rem', pt: '0.75rem' } }}
    actions={[
      {
        label: 'No',
        onClick: onCancel,
        buttonProps: { variant: 'outlined', dataTestId: 'cancel-start-olp' },
      },
      {
        label: 'Yes',
        onClick: onConfirm,
        buttonProps: { variant: 'contained', dataTestId: 'confirm-start-olp' },
      },
    ]}
    open={show}
    onClose={onCancel}
    data-testid={dataTestId}
  >
    <Typography variant="body1" sx={{ textAlign: 'start' }}>
      Starting this activity will close other active Group Practice sessions. Do you want
      to continue?
    </Typography>
  </Dialog>
);

export default StartOnlineLessonPracticeDialog;
