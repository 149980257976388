import React, { PureComponent } from 'react';
import md5 from 'md5';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ExamSectionsWrapper from '@components/Component/ExamSections';
import CourseSelect from '../../containers/CourseSelect';
// import CourseManagementCreateSectionForm from '../../containers/CourseManagementCreateSectionForm';
import SectionCreationModal from '../../containers/Modals/SectionCreationModal';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import {
  LeftSiderBarContentGroup,
  LeftSideBarList,
  TitleSideBar,
  ItemsSiderBar,
  SearchWrapper,
  SideBarContent,
  CollapseSideBar,
  CollapseSideBarItem,
  CollapseSideBarSectionsWrapper,
  LeftSiderBarWrap,
  LeftSiderBarHeader,
  LeftSiderBarHeaderGroup,
  ActionShowHide,
  SideBarTip,
} from './LeftSiderBar.style';
import FormInput from '../Generals/FormInput/FormInput';
import { BreakLine } from '../SubNavBarItem/SubNavBar.style';
import { ActionForm, Button } from '../Generals/stylesheets/Button.style';

const groupData = [
  {
    title: 'Teachers',
    endpoint: 'teachers',
  },
  {
    title: 'Students',
    endpoint: 'students',
  },
];

const scrollStyle = {
  height: 'calc(100vh - 7.5em)',
  width: '100%',
  overflow: 'auto',
};

const scrollStyleSections = {
  height: '100%',
  width: '100%',
  overflow: 'auto',
  paddingBottom: '1em',
  minHeight: '200px',
};

class LeftSideBarCourseManagement extends PureComponent {
  static propTypes = {
    match: PropTypes.any,
    pathName: PropTypes.string,
    courseId: PropTypes.string,
    courseItem: PropTypes.shape(),
    toggleLeftSidebar: PropTypes.func,
    isSideBarClose: PropTypes.bool,
    sectionList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    courseTeacherGetOwner: PropTypes.func,
    isOwner: PropTypes.bool,
    sectionsStudentSelectAllOrCancelGradeLevel: PropTypes.func,
    isOpenCreateSection: PropTypes.any,
  };

  constructor(props) {
    super(props);
    const { courseItem = {}, sectionList } = this.props;
    const { subscription = {} } = courseItem;
    const { max_sections: maxSections } = subscription;
    const isMaxSection = maxSections <= sectionList.length;

    this.state = {
      isGroupDropdown: true,
      isSectionDropdown: true,
      isCreateSectionOpen: props.isOpenCreateSection ? !isMaxSection : false,
      searchText: '',
      sectionListState: sectionList.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      ),
    };
  }

  componentDidMount() {
    const {
      courseTeacherGetOwner,
      courseId,
      sectionsStudentSelectAllOrCancelGradeLevel,
      getAllSectionsByCourse,
    } = this.props;
    courseTeacherGetOwner(courseId);
    sectionsStudentSelectAllOrCancelGradeLevel([]);
    getAllSectionsByCourse(courseId);
  }

  componentDidUpdate(prevProps) {
    const { isCreateSectionOpen } = this.state;
    const { courseId: prevCourseId } = prevProps;
    const { courseId, courseTeacherGetOwner, isOpenCreateSection, sectionList } =
      this.props;
    const { isOpenCreateSection: prevIsOpenCreateSection } = prevProps;

    if (courseId != prevCourseId) {
      // eslint-disable-line
      courseTeacherGetOwner(courseId);
    }

    if (
      prevIsOpenCreateSection !== isOpenCreateSection &&
      isOpenCreateSection > 0 &&
      !isCreateSectionOpen
    ) {
      this.openCreateSectionForm();
    }

    if (prevProps.sectionList !== sectionList) {
      this.resetSearch();
    }
  }

  toggleLeftSidebar = () => {
    const { toggleLeftSidebar } = this.props;
    toggleLeftSidebar();
  };

  toggleGroupDropdown = () => {
    this.setState((prevState) => ({
      isGroupDropdown: !prevState.isGroupDropdown,
    }));
  };

  toggleSectionDropdown = () => {
    this.setState((prevState) => ({
      isSectionDropdown: !prevState.isSectionDropdown,
    }));
  };

  checkCreateSectionCondition = () => {
    const { courseItem = {}, sectionList } = this.props;
    const { subscription = {} } = courseItem;
    const { max_sections: maxSections } = subscription;
    const isMaxSection = maxSections <= sectionList.length;

    return !isMaxSection;
  };

  openCreateSectionForm = () => {
    const canOpenCreateSection = this.checkCreateSectionCondition();

    if (!canOpenCreateSection) {
      return;
    }

    this.setState({
      isCreateSectionOpen: true,
    });
  };

  closeCreateSectionForm = () => {
    this.setState({
      isCreateSectionOpen: false,
    });
  };

  onChangeSearch = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  onKeyPress = (e) => {
    const { key } = e;
    if (key === 'Enter') {
      this.onSearch();
    }
  };

  onSearch = () => {
    const { searchText } = this.state;
    const { sectionList } = this.props;
    this.setState({
      sectionListState: sectionList
        .filter((section) =>
          section.name.toLowerCase().includes(searchText.toLowerCase()),
        )
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
    });
  };

  resetSearch = () => {
    const { sectionList } = this.props;
    this.setState({
      searchText: '',
      sectionListState: sectionList.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      ),
    });
  };

  renderSidebarItem = (values, index) => {
    const { title } = values;
    return (
      <ItemsSiderBar key={index}>
        <span>{title}</span>
      </ItemsSiderBar>
    );
  };

  renderGroupList = (values, index) => {
    const { title, endpoint } = values;
    const { courseId, match } = this.props;
    const url = `/management/course/${courseId}/${endpoint}/`;
    const isActive = url == match.url; // eslint-disable-line

    return (
      <Link to={url} key={index}>
        <CollapseSideBarItem isActive={isActive}>
          <span>{title}</span>
        </CollapseSideBarItem>
      </Link>
    );
  };

  renderSectionList = (values, index) => {
    const { name, id } = values;
    const { courseId, match } = this.props;
    const url = `/management/course/${courseId}/section/${id}/`;
    const isActive = url == match.url; // eslint-disable-line

    return (
      <Link to={url} key={index}>
        <CollapseSideBarItem isActive={isActive}>
          <span>{name}</span>
        </CollapseSideBarItem>
      </Link>
    );
  };

  renderCreateNewSectionForm = () => {
    const { isCreateSectionOpen } = this.state;
    const { sectionList, courseItem = {} } = this.props;

    let isCleverSection = false;
    let tooltipMsg = '';
    if (sectionList.length) {
      sectionList.forEach((section) => {
        if (section.third_party_id) {
          isCleverSection = true;
          tooltipMsg = 'Use Clever to manage your sections in ChalkTalk';
        }
      });
    }
    const { subscription = {} } = courseItem;
    const { max_sections: maxSections } = subscription;
    const isMaxSection = maxSections <= sectionList.length;

    const canOpenCreateSection = this.checkCreateSectionCondition();

    let buttonLabel = 'Create New Section';
    const isDisableButton = !canOpenCreateSection || isCleverSection;

    if (isMaxSection) {
      buttonLabel = 'Maximum number of sections reached';
    }

    return (
      <ActionForm>
        <Tooltip title={tooltipMsg} placement="bottom">
          <Button
            full
            darkBackground
            isDisable={isDisableButton}
            onClick={!isDisableButton ? this.openCreateSectionForm : undefined}
            autoHeight
          >
            {buttonLabel}
            {!isMaxSection && <i className="chalktalk-icon-add" />}
          </Button>
        </Tooltip>
        {isCreateSectionOpen && (
          <SectionCreationModal onClose={this.closeCreateSectionForm} />
        )}
      </ActionForm>
    );
  };

  renderSideBarContent() {
    const { isGroupDropdown, isSectionDropdown, searchText, sectionListState } =
      this.state;
    const { courseId, sectionList, match, isOwner, courseItem } = this.props;
    const { subscription = {} } = courseItem;
    const { max_sections: maxSections } = subscription;
    const groupHeight = groupData.length * 2.5;
    const url = `/management/course/${courseId}/`;
    const isActive = url == match.url; // eslint-disable-line

    return (
      <ExamSectionsWrapper>
        <LeftSiderBarContentGroup>
          <TitleSideBar>Course Management</TitleSideBar>

          <ShadowScrollbar autoHide style={scrollStyle} hideScroll>
            <LeftSideBarList>
              <ItemsSiderBar isActive={isActive}>
                <Link to={url}>
                  <SideBarContent>
                    <span>Course Details</span>
                  </SideBarContent>
                </Link>
              </ItemsSiderBar>

              <BreakLine noMargin />

              <ItemsSiderBar noLink>
                <SideBarContent isRotate={!isGroupDropdown}>
                  <span>Course Users</span>
                </SideBarContent>
              </ItemsSiderBar>
              <CollapseSideBar noIcon height={groupHeight} isClose={!isGroupDropdown}>
                {groupData.map(this.renderGroupList)}
              </CollapseSideBar>

              <BreakLine noMargin />

              <ItemsSiderBar noLink>
                <SideBarContent isRotate={!isSectionDropdown}>
                  <span>
                    Sections
                    <SideBarTip>{`(max. ${maxSections})`}</SideBarTip>
                  </span>
                </SideBarContent>
              </ItemsSiderBar>
              <SearchWrapper>
                <input
                  placeholder="Search..."
                  value={searchText}
                  onChange={this.onChangeSearch}
                  onKeyPress={this.onKeyPress}
                />
                <Button onClick={this.onSearch}>
                  <i className="chalktalk-search" />
                </Button>
              </SearchWrapper>
              <CollapseSideBarSectionsWrapper noIcon isClose={!isSectionDropdown}>
                {this.renderCreateNewSectionForm()}
                <BreakLine noMargin />
                <ShadowScrollbar autoHide style={scrollStyleSections} hideScroll>
                  {sectionListState.map(this.renderSectionList)}
                </ShadowScrollbar>
              </CollapseSideBarSectionsWrapper>
            </LeftSideBarList>
          </ShadowScrollbar>
        </LeftSiderBarContentGroup>
      </ExamSectionsWrapper>
    );
  }

  render() {
    const { pathName, match, isSideBarClose } = this.props;
    const courseHash = md5(`${match.params.courseId}@chalktalk`);

    return (
      <LeftSiderBarWrap isClose={isSideBarClose}>
        <LeftSiderBarHeader>
          <LeftSiderBarHeaderGroup>
            <CourseSelect pathName={pathName} />
          </LeftSiderBarHeaderGroup>
        </LeftSiderBarHeader>
        {this.renderSideBarContent()}
        <ActionShowHide className="toggle-icon" onClick={this.toggleLeftSidebar}>
          <MediaQuery maxWidth={960}>
            {(matches) =>
              matches ? (
                <span
                  className={isSideBarClose ? 'chalktalk-icon-bold-up' : 'chalktalk-menu'}
                />
              ) : (
                <span
                  className={isSideBarClose ? 'chalktalk-menu' : 'chalktalk-icon-bold-up'}
                />
              )
            }
          </MediaQuery>
        </ActionShowHide>
      </LeftSiderBarWrap>
    );
  }
}

export default LeftSideBarCourseManagement;
