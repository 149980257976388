// currently only use for exam
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  info as ToastInfo,
} from '../../utils/toast';
import {
  PracticeTimeWrap,
} from './PracticeTime.style';
class PracticeTime extends React.Component {
  static propTypes = {
    secondsRemaining: PropTypes.number,
    complete: PropTypes.func,
    shouldResetTimer: PropTypes.bool,
    examResetTimerSuccess: PropTypes.func,
    duration: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      duration: 0,
      isTimeClockClose: false,
    };
    this.durationSeconds = -1;
    this.intervalTime = null;
  }

  componentDidMount() {
    const { secondsRemaining } = this.props;
    this.setUpTimer(secondsRemaining);
  }

  componentDidUpdate(prevProps) {
    const {
      secondsRemaining, shouldResetTimer, examResetTimerSuccess, duration,
    } = this.props;
    const { shouldResetTimer: prevShouldResetTimer } = prevProps;
    if (secondsRemaining !== prevProps.secondsRemaining) {
      this.setUpTimer(secondsRemaining);
    }

    if (!prevShouldResetTimer && shouldResetTimer && examResetTimerSuccess) {
      if (this.intervalTime) {
        clearInterval(this.intervalTime);
        this.intervalTime = null;
      }
      this.setUpTimer(duration * 60);
      examResetTimerSuccess();
    }
  }

  componentWillUnmount() {
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
      this.intervalTime = null;
    }
    if (this.saveAnswerTimer) {
      clearInterval(this.saveAnswerTimer);
      this.saveAnswerTimer = null;
    }
  }

  onTimeout = () => {
    ToastInfo('You have used all the time available. Your answers have been submitted and your score will now be calculated.')   
    const { complete } = this.props;
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
      this.intervalTime = null;
    }
    complete();
  }

  setUpTimer = (secondsRemaining) => {
    // countdown
    this.durationSeconds = secondsRemaining;
    this.fireCountdownTimer();
  }

  intervalFunc = () => {
    this.durationSeconds -= 1;

    if (this.durationSeconds >= 0) {
      this.setState({ duration: this.durationSeconds });
    } else if (!this.props.status || (this.durationSeconds <= 0 && [1, 2].includes(parseInt(this.props.status, 10)))) {
      this.onTimeout();
    }
  }

  fireCountdownTimer = () => {
    if (!this.intervalTime) {
      this.intervalTime = setInterval(this.intervalFunc, 1000);
    }
  }

  renderCountdownTime = () => {
    const { duration } = this.state;

    if (duration >= 0) {
      const durationFormatted = moment.utc(duration * 1000).format('HH:mm:ss');
      return durationFormatted;
    }
    return 0;
  }

  toggleTimeClock = () => {
    this.setState(prevState => ({
      isTimeClockClose: !prevState.isTimeClockClose,
    }));
  }

  render() {
    const { isTimeClockClose } = this.state;
    return (
      <PracticeTimeWrap
        onClick={this.toggleTimeClock}
        isClose={isTimeClockClose}
      >
        <i className={isTimeClockClose ? 'chalktalk-icon-pre' : 'chalktalk-icon-next'} />
        <i className="chalktalk-clock" />
        <span>{this.renderCountdownTime()}</span>
      </PracticeTimeWrap>
    );
  }
}

export default PracticeTime;
