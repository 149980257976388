import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { selectors, actions } from '../../reducers/leftSideBar';
import LeftSideBarAdmin from '../../components/LeftSideBar/LeftSideBarAdmin';
import { getCurrentUser } from '../../reducers/user';



const mapStateToProps = (state, { match }) => {
  return ({
    isSideBarClose: selectors.getSideBar(state),
    user: getCurrentUser(state),
  });
};

const mapDispatchToProps = {
  toggleLeftSidebar: actions.toggleLeftSidebar,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LeftSideBarAdmin),
);