/* eslint-disable import/no-cycle */
import { eventChannel } from 'redux-saga';
import { all, take, put } from 'redux-saga/effects';

// new sagas
import authSaga from './auth';
import usersSaga from './users';
import courseSaga from './courses';
import sectionsSaga from './sections';
import sectionReviewsSaga from './sectionReviews';
import sectionSessionSaga from './sectionSession';
import registerSaga from './register';
import districtsSaga from './districts';
import countriesSaga from './countries';
import curriculumsSaga from './curriculums';
import curriculumLessonsSaga from './curriculumLessons';
import systemSaga from './system';
import sectionStudent from './sectionStudent';
import sectionTeacher from './sectionTeacher';
import sectionSchedule from './sectionSchedule';
import sectionExam from './sectionExam';
import sectionUnit from './sectionUnit';

import scheduleForms from './scheduleForms';
import courseTeachers from './courseTeachers';
import courseStudents from './courseStudents';

import sectionAttendance from './sectionAttendance';

import examResult from './examResult';
import examReport from './examReport';
import exam from './exam';

import sectionActivity from './sectionActivity';
import userActivitiesSaga from './userActivities';
import websocketSaga from './websocket/websocketSaga';
import lessonAssignmentSaga from './lessonAssignment';
import practice from './practice';
import practiceReport from './practiceReport';
import sectionSkills from './sectionSkills';
import sectionSubjects from './sectionSubjects';
import lessonAssignedActivities from './lessonAssignedActivities';

import adminReports from './adminReports';

// misc, not saga

import '../utils/axiosClient';
import groupActivitySaga from './groupActivity/groupActivity';
import {
  ONLINE_STATUS_ONLINE,
  ONLINE_STATUS_OFFLINE,
} from '../reducers/system/systemReducer';

function* onlineStatusWatcher() {
  const onlineStatusChannel = eventChannel((emit) => {
    const handleOnline = () => emit(true);
    const handleOffline = () => emit(false);
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  });
  while (true) {
    const isOnline = yield take(onlineStatusChannel);
    if (isOnline) {
      yield put({ type: ONLINE_STATUS_ONLINE });
    } else {
      yield put({ type: ONLINE_STATUS_OFFLINE });
    }
  }
}

export default function* rootSaga() {
  yield all([
    // new sagas
    authSaga(),
    usersSaga(),
    courseSaga(),
    sectionsSaga(),
    sectionReviewsSaga(),
    registerSaga(),
    districtsSaga(),
    countriesSaga(),
    curriculumsSaga(),
    curriculumLessonsSaga(),
    sectionSessionSaga(),
    systemSaga(),
    sectionStudent(),
    sectionSchedule(),
    scheduleForms(),
    courseTeachers(),
    courseStudents(),
    sectionTeacher(),
    sectionExam(),
    sectionUnit(),
    sectionAttendance(),

    examResult(),
    examReport(),
    exam(),

    sectionActivity(),
    userActivitiesSaga(),
    websocketSaga(),
    lessonAssignmentSaga(),
    practice(),
    practiceReport(),
    sectionSkills(),
    sectionSubjects(),
    lessonAssignedActivities(),
    groupActivitySaga(),

    adminReports(),
    onlineStatusWatcher(),
  ]);
}
