import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import AddLessonSessionStepOne from '../../components/ScheduleAddLessonSessionForm/AddLessonSessionStepOne';
import { selectors as SectionSubjectSelectors } from '../../reducers/sectionSubjects';

const mapStateToProps = (state, { match }) => {
  const sectionId = _get(match, 'params.sectionId', '');

  return {
    sectionId,
    subjects: SectionSubjectSelectors.getSectionSubjectList(state),
  };
};

export default withRouter(connect(mapStateToProps)(AddLessonSessionStepOne));
