/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import {
  PracticeTimeSeriesWrap,
  TopPracticeWrap,
  TopPracticeTitle,
  TopPracticeGroup,
  TopPracticeItem,
  TopPracticeName,
  TopPracticePercent,
} from './DashboardPracticeTimeSeries.style';
import { BlockWrap, BlockTitle } from '../Generals/stylesheets/General.style';
import DashboardPracticeTimeSeriesRechart from './DashboardPracticeTimeSeriesRechart';
import { DATE_FORMAT } from '../../utils/constants';

export default class DashboardPracticeTimeSeries extends Component {
  constructor(props) {
    super(props);
    const currentDate = moment();
    const startDate = currentDate.clone().startOf('week').subtract(2, 'week');
    const endDate = currentDate.clone().endOf('week');

    this.state = {
      startDate,
      endDate,
      unit: 'day',
      topPractice: [],
    };
  }

  static propTypes = {
    studentList: PropTypes.arrayOf(PropTypes.any),
    lessonById: PropTypes.objectOf(PropTypes.any),
    subjectById: PropTypes.objectOf(PropTypes.any),
    examsScoreSummary: PropTypes.arrayOf(PropTypes.shape()),
  };

  componentDidUpdate(prevProp, prevState) {
    const {
      examsScoreSummary: prevExamsScoreSummary,
      lessonById: prevLessonById,
      subjectById: prevSubjectById,
    } = prevProp;
    const { examsScoreSummary = [], lessonById = {}, subjectById } = this.props;

    const { startDate, endDate } = this.state;
    const { startDate: prevStartDate, endDate: prevEndDate } = prevState;

    if (
      (lessonById !== prevLessonById ||
        examsScoreSummary !== prevExamsScoreSummary ||
        subjectById !== prevSubjectById ||
        !moment(prevStartDate).isSame(moment(startDate)) ||
        !moment(prevEndDate).isSame(moment(endDate))) &&
      !_isEmpty(lessonById) &&
      !_isEmpty(examsScoreSummary) &&
      !_isEmpty(subjectById)
    ) {
      this.handleTopPractice();
    }
  }

  formatDate = (date) => moment(date).format('D/M');

  // eslint-disable-next-line consistent-return
  handleTopPractice = () => {
    const { examsScoreSummary, lessonById, subjectById } = this.props;
    const { startDate, endDate } = this.state;
    const examsScoreSummaryFilter = _filter(examsScoreSummary, (item) => {
      const { last_activity: lastActivity } = item;

      return moment(lastActivity).isBetween(moment(startDate), moment(endDate));
    });

    const lessonCount = examsScoreSummaryFilter.reduce((acc, summary) => {
      const { lesson } = summary;
      if (!acc[lesson]) {
        acc[lesson] = {
          count: 0,
          ...summary,
        };
      }
      acc[lesson].count += 1;
      return acc;
    }, {});

    if (_isEmpty(lessonCount)) {
      return this.setState({ topPractice: [] });
    }

    const topPracticeList = Object.values(lessonCount)
      .sort((a, b) => b.count - a.count)
      .slice(0, 3);
    const topPractice = topPracticeList.map((practice, i) => {
      const { lesson, unit, subject: subjectId, last_activity: lastActivity } = practice;
      const { name } = lessonById[lesson] || {};
      const value = Math.round((practice.count / examsScoreSummary.length) * 100);
      const subject = subjectById[subjectId] || {};
      const displayName = subject.display_name || '';

      return {
        name: name
          ? `${displayName} ${name}`
          : `${displayName} Review ${this.formatDate(lastActivity)}`,
        value,
        iconId: unit,
        subject,
      };
    });
    this.setState({ topPractice });
  };

  calculatePracticeDataWithinAWeek = (date, examsScoreSummaryMap) => {
    const mdate = moment(date, DATE_FORMAT.DATE);
    const start = mdate.clone().startOf('week');
    const current = start.clone();
    let maxStudentDoPractice = 0;
    for (let i = 0; i < 7; i += 1) {
      const currDateStr = current.format(DATE_FORMAT.DATE);
      const numberPracticeInDay = (examsScoreSummaryMap[currDateStr] || []).map(
        (p) => p.user,
      );
      const numberStudentHavePractice = new Set(numberPracticeInDay);

      if (numberStudentHavePractice.size > maxStudentDoPractice) {
        maxStudentDoPractice = numberStudentHavePractice.size;
      }
      current.add(1, 'day');
    }
    return maxStudentDoPractice;
  };

  calculatePracticeDataList = () => {
    const { studentList = [], examsScoreSummary = [] } = this.props;
    const { startDate, endDate, unit } = this.state;
    const currentDate = startDate.clone();
    const examsScoreSummaryMap = examsScoreSummary.reduce((acc, practice) => {
      const date = moment(practice.last_activity).format(DATE_FORMAT.DATE);
      if (!acc[date]) acc[date] = [];
      acc[date].push(practice);
      return acc;
    }, {});
    // const diff = endDate.diff(startDate, 'day') + 1;
    const diff = endDate.diff(startDate, unit) + 1;

    return Array.from({ length: diff }).map((_, i) => {
      const currDateStr = currentDate.format(DATE_FORMAT.DATE);
      const renderDate = currentDate.format(
        unit === 'day' ? DATE_FORMAT.DAY_DATE : DATE_FORMAT.WEEK_DATE,
      );
      // currentDate.add(1, 'day');
      currentDate.add(1, unit);

      if (!studentList.length || !examsScoreSummary.length) {
        return {
          date: renderDate,
          value: 0,
        };
      }

      let numberPracticeInDay = [];
      let numberStudentHavePractice = 0;

      if (unit === 'day') {
        numberPracticeInDay = (examsScoreSummaryMap[currDateStr] || []).map(
          (p) => p.user,
        );
        numberStudentHavePractice = new Set(numberPracticeInDay).size;
      } else {
        numberStudentHavePractice = this.calculatePracticeDataWithinAWeek(
          currDateStr,
          examsScoreSummaryMap,
        );
      }

      return {
        date: renderDate,
        value: Math.round((numberStudentHavePractice / studentList.length) * 100),
      };
    });
  };

  renderTopPractice = () => {
    const { topPractice } = this.state;
    const pathNumber = Array.from(Array(14).keys());
    return (
      <TopPracticeWrap>
        <TopPracticeTitle>
          Top Practiced
          <sub>&nbsp;(for selected date range)</sub>
        </TopPracticeTitle>
        <TopPracticeGroup>
          {!topPractice.length && <p>No practice</p>}
          {topPractice.map((practice, index) => (
            <TopPracticeItem key={index.toString()}>
              {practice.name === 'review' ? (
                <div className="chalktalk-neural-network">
                  {pathNumber.map((number, index1) => (
                    <i className={`path${number}`} key={index1} />
                  ))}
                </div>
              ) : (
                <div className="chalktalk-book-open">
                  {pathNumber.map((number, index1) => (
                    <i className={`path${number}`} key={index1} />
                  ))}
                </div>
              )}
              <TopPracticeName>{practice.name}</TopPracticeName>
              <TopPracticePercent>{`${practice.value}%`}</TopPracticePercent>
            </TopPracticeItem>
          ))}
        </TopPracticeGroup>
      </TopPracticeWrap>
    );
  };

  handleStartDateChange = (newDate) => {
    const { endDate } = this.state;
    const newStartDate = moment(newDate.toISOString());
    if (newStartDate.isAfter(endDate)) return;

    this.setState({
      startDate: newStartDate,
    });
  };

  handleEndDateChange = (newDate) => {
    const { startDate } = this.state;
    const newEndDate = moment(newDate.toISOString());
    if (newEndDate.isBefore(startDate)) return;

    this.setState({
      endDate: newEndDate,
    });
  };

  handleWeeklyView = () => {
    this.setState({
      unit: 'week',
    });
  };

  handleDailyView = () => {
    this.setState({
      unit: 'day',
    });
  };

  render() {
    const { startDate, endDate } = this.state;
    const practiceDataList = this.calculatePracticeDataList();

    return (
      <BlockWrap>
        <BlockTitle>
          <span>Practice Time Series</span>
        </BlockTitle>
        <p>Percentage of students who completed at least one practice session.</p>
        <PracticeTimeSeriesWrap>
          <DashboardPracticeTimeSeriesRechart
            data={practiceDataList}
            startDate={startDate.toDate()}
            endDate={endDate.toDate()}
            setStartDate={this.handleStartDateChange}
            setEndDate={this.handleEndDateChange}
            setWeeklyView={this.handleWeeklyView}
            setDailyView={this.handleDailyView}
          />

          {this.renderTopPractice()}
        </PracticeTimeSeriesWrap>
      </BlockWrap>
    );
  }
}
