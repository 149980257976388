import React from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';

export type CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = 'div',
> = MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>;

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = 'div',
>({
  ...props
}: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo, ChipComponent>) => (
  <MuiAutocomplete {...props} />
);

export default Autocomplete;
