import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _keyBy from 'lodash/keyBy';
import _get from 'lodash/get';
import MoveStudentTable from '../../components/CourseManagementSection/MoveStudentTable';
import { selectors as SectionSelectors } from '../../reducers/sections';
import { actions as SectionStudentActions, selectors as SectionStudentSelectors } from '../../reducers/sectionStudent';

const mapStateToProps = (state, { studentList, match }) => {
  const sectionList = SectionSelectors.getSectionList(state);
  const studentById = _keyBy(studentList, 'id');
  const sectionId = _get(match, 'params.sectionId', null);
  const sectionListFilter = sectionList.filter(({ id }) => id != sectionId);

  return {
    sectionList: sectionListFilter,
    studentById,
    sectionId,
    isMoving: SectionStudentSelectors.getIsMoving(state),
    moveError: SectionStudentSelectors.getMoveError(state),
  };
};

const mapDispatchToProps = {
  sectionsStudentMove: SectionStudentActions.sectionsStudentMove,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MoveStudentTable));
