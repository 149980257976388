import React, { createContext, useContext, ReactNode } from "react";
import { onlyText } from "./utils";
/**
 * Type for the interaction context
 * @interface InteractionContextType
 * @property {string} interactionKey - The current interaction context string
 * @property {function} getTestId - Function to generate a test ID for a component
 */
interface InteractionContextType {
    interactionKey: string;
    getTestId: (content: ReactNode) => string;
}

/**
 * Context for tracking interaction areas in the application
 * Used to generate consistent data-testid attributes for components
 */
export const InteractionContext = createContext<InteractionContextType>({
    interactionKey: "",
    getTestId: () => ""
});

/**
 * Props for the InteractionContextProvider component
 * @interface InteractionContextProviderProps
 * @property {string} interactionArea - The area/section of the application where interactions occur. Used to namespace data-testid attributes.
 * @property {ReactNode} children - Child components that will have access to the interaction context
 */
interface InteractionContextProviderProps {
    interactionArea: string;
    children: ReactNode;
}

/**
 * Provider component to establish interaction context
 * 
 * Example usage:
 * ```
 * <InteractionContextProvider interactionArea="dashboard">
 *   <MyComponent /> // data-testid will be prefixed with "dashboard" 
 *   <InteractionContextProvider interactionArea="user-profile">
 *     <OtherComponent /> // data-testid will be prefixed with "dashboard/user-profile" 
 *   </InteractionContextProvider>
 * </InteractionContextProvider>
 * ```
 * @param {InteractionContextProviderProps} props - Props for the provider component
 * @returns {ReactNode} Provider component with interaction context
 */
export const InteractionContextProvider: React.FC<InteractionContextProviderProps> = ({
    interactionArea,
    children
}) => {
    const { interactionKey } = useContext(InteractionContext);

    // Process the interaction area to remove spaces and special characters
    // const processedArea = interactionArea.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();

    const newInteractionKey = interactionKey
        ? `${interactionKey}/${interactionArea}`
        : interactionArea;

    const getTestId = (content: ReactNode): string => {
        return `${newInteractionKey}/${onlyText(content)}`;
    };

    return (
        <InteractionContext.Provider
            value={{
                interactionKey: newInteractionKey,
                getTestId
            }}
        >
            {children}
        </InteractionContext.Provider>
    );
};

/**
 * Hook to use in components
 * 
 * Example usage:
 * const { interactionKey } = useInteractionContext();
 * // Inside InteractionContextProvider with interactionArea="dashboard"
 * // interactionKey will be "dashboard"
 * // Inside nested InteractionContextProvider with interactionArea="user-profile" 
 * // interactionKey will be "dashboard/user-profile"
 * 
 * @returns {InteractionContextType} Current interaction context
 */
export const useInteractionContext = (): InteractionContextType => {
    const context = useContext(InteractionContext);
    if (!context) {
        throw new Error("useInteractionContext must be used within an InteractionContextProvider");
    }
    return context;
};

/**
 * Higher-Order Component (HOC) to wrap components with interaction context
 * 
 * Example usage:
 * const MyWrappedComponent = withInteractionContext(MyComponent, "dashboard");
 * // This will provide "dashboard" as the interaction context for MyComponent
 * // and all its children will have data-testids prefixed with "dashboard"
 * 
 * @param {React.ComponentType<P>} Component - The component to wrap
 * @param {string} interactionArea - The area/section of the application where interactions occur. Used to namespace data-testid attributes.
 * @returns {React.FC<P>} Wrapped component with interaction context
 */
export const withInteractionContext = <P extends object>(
    Component: React.ComponentType<P>,
    interactionArea: string
): React.FC<P> => {
    return (props: P) => (
        <InteractionContextProvider interactionArea={interactionArea}>
            <Component {...props} />
        </InteractionContextProvider>
    );
};
