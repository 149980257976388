/* eslint-disable eqeqeq */
import React, { useEffect, useImperativeHandle } from 'react';

type TabContextType = {
  state: { tab: number | string };
  dispatch: (action: { type: string; payload: number | string }) => void;
};
const TabContext = React.createContext<TabContextType>({
  state: { tab: 0 },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
});

function TabReducer(state, action) {
  switch (action.type) {
    case 'set': {
      return { ...state, tab: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
type Props = {
  children: React.ReactNode | React.ReactNode[];
  defaultTab: number | string;
  onSelect?: (tab: number | string) => void;
};
const TabProvider = React.forwardRef(({ children, defaultTab, onSelect }: Props, ref) => {
  const [state, dispatch] = React.useReducer(TabReducer, { tab: defaultTab ?? 0 });
  useEffect(() => {
    onSelect?.(state.tab);
  }, [state.tab]);
  useEffect(() => {
    if (defaultTab != state.tab && defaultTab !== undefined) {
      dispatch({ type: 'set', payload: defaultTab });
    }
  }, [defaultTab]);
  useImperativeHandle(ref, () => ({
    setTab: (tabKey) => {
      dispatch({ type: 'set', payload: tabKey || defaultTab });
    },
  }));
  const value = React.useMemo(() => ({ state, dispatch }), [state]);
  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
}) as React.ForwardRefRenderFunction<
  { setTab: (tabKey: number | string) => void },
  Props
>;
TabProvider.displayName = 'TabProvider';

function useCurrentTab() {
  const context = React.useContext(TabContext);
  if (context === undefined) {
    throw new Error('useTab must be used within a TabProvider');
  }
  return context.state?.tab;
}
const useSetTab = (tab) => {
  const context = React.useContext(TabContext);
  if (context === undefined) {
    throw new Error('useTab must be used within a TabProvider');
  }
  return React.useCallback(
    () => context.dispatch({ type: 'set', payload: tab }),
    [tab, context.dispatch],
  );
};

export { TabProvider, useCurrentTab, useSetTab };
