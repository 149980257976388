import React from 'react';
import { IconWarningWrapper } from '@components/LessonManagement/LessonManagement.style';
import {
  ExamIcon,
  ExamMessageContent,
  ExamMessageWrapper,
  StrongContent,
} from '@components/TestDetailContent/TestDetailContent.style';
import { Link, useParams } from 'react-router-dom';
import { EXAM_SESSION_TYPES } from '@utils/constants';
import { useIsTeacher } from '@reducers/user/hooks';
import ShowIf from '@components/Atoms/ShowIf';

import { useGetExamDetail } from '../hooks/query';

const IMAGE_URL = '/images/exam.svg';

const messageExamHelper = (isTeacher: boolean) =>
  isTeacher
    ? 'You can unlock it starting on that date, or reschedule it from the '
    : 'The test will be available then';

const ExamMessageCard = () => {
  const { data: examDetails } = useGetExamDetail();
  const { courseId, sectionId } = useParams();
  const isTeacher: boolean = useIsTeacher();

  const examType = EXAM_SESSION_TYPES.title[examDetails?.session_type];

  const msg = messageExamHelper(isTeacher);

  return (
    <div data-testid="exam-msg-card">
      <ExamMessageWrapper>
        <div className="card">
          <IconWarningWrapper>
            <ExamIcon src={IMAGE_URL} />
          </IconWarningWrapper>
          <div className="msg-content">
            <ExamMessageContent>
              <StrongContent>{examType} Test</StrongContent>
              {`This ${examType} Test is scheduled for`}

              <StrongContent md>{examDetails?.start_date}</StrongContent>

              {msg}
              <ShowIf If={isTeacher}>
                <Link to={`/schedule/course/${courseId}/section/${sectionId}`}>
                  section calendar
                </Link>
              </ShowIf>
            </ExamMessageContent>
          </div>
        </div>
      </ExamMessageWrapper>
    </div>
  );
};

export default ExamMessageCard;
