import React, { Component } from 'react';
import _orderBy from 'lodash/orderBy';
import _get from 'lodash/get';
import Hotkeys from 'react-hot-keys';
import PropTypes from 'prop-types';
import {
  AnswerRowBubbleWrap,
  AnswerLabel,
  AnswerRow,
  AnswerRowNumber,
} from './OfflineTestAnswerSheet.style';
import { ClearButton } from '../Generals/stylesheets/Button.style';
import { ORDER_LABEL } from '../../utils/constants';

const ORDER_SHORTCUT = '1, 2, 3, 4, 5, 6, 7, 8, 9, tab, shift+tab';

class AnswerBubbleSingleChoice extends Component {
  chooseAnswer = (choiceId) => {
    const {
      question, examChooseAnswer, selectRow, questionIndex,
    } = this.props;
    const { id } = question;

    const payload = {
      exam_question_id: id,
      value: choiceId,
      response_time: 0,
      delete: false,
    };

    selectRow(questionIndex);

    examChooseAnswer(id, payload);
  }

  clearAnswer = () => {
    const {
      question, examChooseAnswer, selectRow, questionIndex,
    } = this.props;
    const { id } = question;

    const payload = {
      exam_question_id: id,
      value: [],
      response_time: 0,
      delete: true,
    };

    selectRow(questionIndex);

    examChooseAnswer(id, payload);
  }

  onKeyUp = (keyName) => {
    const { question, onKeyUpForTab } = this.props;
    const { choices } = question;

    if (keyName === 'tab' || keyName === 'shift+tab') {
      onKeyUpForTab(keyName);
      return;
    }

    const orderChoices = _orderBy(choices, 'order', 'asc');

    const choicesSelected = orderChoices.find((item, index) => index + 1 == keyName); // eslint-disable-line

    if (!choicesSelected) {
      return;
    }

    this.chooseAnswer(choicesSelected.id);
  }

  renderChoiceItem = (choice) => {
    const { answer } = this.props;
    const selectedValue = _get(answer, 'value', '');
    const { id, order } = choice;
    const isSelected = selectedValue === id;
    const label = ORDER_LABEL[order - 1];

    return (
      <AnswerLabel key={id} isSelected={isSelected} onClick={() => this.chooseAnswer(id)}>
        {label}
      </AnswerLabel>
    );
  }

  renderAnswerContent = () => {
    const { rowSelected, questionIndex, question } = this.props;
    const { choices } = question;

    const isSelected = rowSelected === questionIndex;
    const orderChoices = _orderBy(choices, 'order', 'asc');

    if (isSelected) {
      return (
        <Hotkeys
          keyName={ORDER_SHORTCUT}
          onKeyUp={(keyName) => this.onKeyUp(keyName)}
        >
          {orderChoices.map(this.renderChoiceItem)}
        </Hotkeys>
      );
    }

    return orderChoices.map(this.renderChoiceItem);
  }

  render() {
    const {
      isAnswerSheetOpen, rowSelected, questionIndex,
    } = this.props;
    const shouldHaveBg = questionIndex === rowSelected;

    return (
      <AnswerRow isAnswerSheetOpen={isAnswerSheetOpen} rowSelected={shouldHaveBg}>
        <AnswerRowNumber>{`${questionIndex + 1}.`}</AnswerRowNumber>
        <AnswerRowBubbleWrap>
          {this.renderAnswerContent()}
        </AnswerRowBubbleWrap>
        <ClearButton data-index={questionIndex} onClick={this.clearAnswer}>
          clear
        </ClearButton>
      </AnswerRow>
    );
  }
}

AnswerBubbleSingleChoice.propTypes = {
  question: PropTypes.object,
  answer: PropTypes.object,
  examChooseAnswer: PropTypes.func,
  isAnswerSheetOpen: PropTypes.bool,
  rowSelected: PropTypes.any,
  onKeyUpForTab: PropTypes.func,
  questionIndex: PropTypes.number,
  selectRow: PropTypes.func,
};

export default AnswerBubbleSingleChoice;
