/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _differenceBy from 'lodash/differenceBy';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import ConfirmModal from '../ConfirmModal';
import {
  UserInfoListWrap,
  UserProfileTitle,
  UserActionWrap,
  UserActionPopup,
  UserActionItem,
} from '../Profile/Profile.style';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  TableContentGroup,
  TableEmptyContent,
  TableIcon,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import ListTeacherTable from './ListTeacherTable';
import { ColumnSizeTeacher } from './ColumSize';
import { formatDateByTime } from '../../utils/func-utils';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'User',
  },
  {
    uniqKey: 'last_login',
    value: 'Last login',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class TeacherList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInviteFormOpen: false,
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
      removeTeacherId: null,
      actionPopupById: null,
    };
  }

  static propTypes = {
    teacherList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sectionsTeachersRemove: PropTypes.func,
    sectionDetail: PropTypes.object,
    teacherListModal: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sectionsTeachersAdd: PropTypes.func.isRequired,
    courseId: PropTypes.string,
    fetchCourseTeacherList: PropTypes.func.isRequired,
    isGettingTeacherList: PropTypes.bool,
    isOwner: PropTypes.bool,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const { courseId } = this.props;
    const { courseId: prevCourseId } = prevProps;

    if (!_isEqual(courseId, prevCourseId)) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    const { courseId, fetchCourseTeacherList } = this.props;
    fetchCourseTeacherList(courseId);
  };

  openInviteTeacherForm = () => {
    this.setState({
      isInviteFormOpen: true,
    });
  };

  closeInviteTeacherForm = () => {
    this.setState({
      isInviteFormOpen: false,
    });
  };

  onCloseConfirmModal = () => {
    this.setState({
      removeTeacherId: null,
    });
  };

  onOpenConfirmModal = (teacherId) => {
    this.setState({
      removeTeacherId: teacherId,
    });
  };

  onCloseActionPopup = () => {
    this.setState({
      actionPopupById: null,
    });
  };

  onOpenActionPopup = (teacherId) => {
    this.setState({
      actionPopupById: teacherId,
    });
  };

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  sectionsTeachersRemove = (teacherId) => {
    const { sectionsTeachersRemove, sectionDetail } = this.props;
    const { id: sectionId } = sectionDetail;

    sectionsTeachersRemove(sectionId, teacherId);
  };

  inviteIntoSection = (teacherId, teacherInfo) => {
    const { sectionDetail, sectionsTeachersAdd } = this.props;
    const { id: sectionId } = sectionDetail;
    sectionsTeachersAdd(teacherId, sectionId, teacherInfo);
  };

  renderTeacherListHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return (
      <TableHeader
        {...ColumnSizeTeacher[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderTeacherListHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderTeacherListHeaderItem)}
      <TableHeader {...ColumnSizeTeacher[2]} value="Actions" />
    </TableHeadWrapper>
  );

  renderActionPopup = () => {
    const { actionPopupById } = this.state;
    return (
      <UserActionPopup>
        <UserActionItem
          onClick={() => this.onOpenConfirmModal(actionPopupById)}
        >
          Remove
        </UserActionItem>
      </UserActionPopup>
    );
  };

  renderTeacherListContent = (teacher, index) => {
    const { isOwner } = this.props;
    const { last_login: lastLogin } = teacher;
    const { actionPopupById } = this.state;
    const isActionPopupOpen = actionPopupById === teacher.id;
    const lastAcitivity = moment(lastLogin).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const diffDateActivity = moment(today).diff(lastAcitivity, 'days');

    return (
      <TableContentItem smallSize noBorder key={index}>
        <TableContent {...ColumnSizeTeacher[0]}>
          <TableContentGroup>
            <p>{`${teacher.first_name || ''} ${teacher.last_name || ''}`}</p>
            <span>{teacher.email || ''}</span>
          </TableContentGroup>
        </TableContent>
        <TableContent {...ColumnSizeTeacher[1]}>
          {lastLogin
            ? diffDateActivity > 30
              ? '30+ days'
              : moment(lastLogin).fromNow()
            : '--'}
        </TableContent>
        <TableContent {...ColumnSizeTeacher[2]}>
          {isOwner && (
            <UserActionWrap>
              <TableIcon
                className="chalktalk-more"
                onClick={() => this.onOpenActionPopup(teacher.id)}
              />
              {isActionPopupOpen && (
                <React.Fragment>
                  {this.renderActionPopup()}
                  <OverlayWrap
                    isOpen={isActionPopupOpen}
                    onClick={this.onCloseActionPopup}
                  />
                </React.Fragment>
              )}
            </UserActionWrap>
          )}
        </TableContent>
      </TableContentItem>
    );
  };

  render() {
    const {
      sectionDetail: { teacher_code },
      teacherList,
      teacherListModal,
      isGettingTeacherList,
    } = this.props;
    const {
      isInviteFormOpen, orderBy, sortBy, removeTeacherId,
    } = this.state;
    const teacherNotInSections = _differenceBy(
      teacherListModal,
      teacherList,
      'id',
    );
    firstSort(teacherList);
    const filterTeacherList = _orderBy(
      teacherList,
      [
        (d) => {
          if (sortBy === 'last_name') return d[sortBy].toUpperCase();
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <UserInfoListWrap>
        <UserProfileTitle>
          Teachers List
          <Button alignRight onClick={this.openInviteTeacherForm}>
            Invite
          </Button>
        </UserProfileTitle>
        {isGettingTeacherList ? (
          <TableLoader />
        ) : (
          <TableContainerWrap>
            {_isEmpty(teacherList) ? (
              <TableEmptyContent>
                No teacher data. Invite teachers in the Course Users page.
              </TableEmptyContent>
            ) : (
              <React.Fragment>
                {this.renderTeacherListHeader()}
                <TableContentWrapper>
                  {filterTeacherList.map(this.renderTeacherListContent)}
                </TableContentWrapper>
              </React.Fragment>
            )}
          </TableContainerWrap>
        )}
        <ListTeacherTable
          teacherNotInSection={teacherNotInSections}
          isOpen={isInviteFormOpen}
          teacherCode={teacher_code}
          onClose={this.closeInviteTeacherForm}
          inviteIntoSection={this.inviteIntoSection}
        />
        <ConfirmModal
          itemId={removeTeacherId}
          onClose={this.onCloseConfirmModal}
          content="Are you sure to delete this teacher?"
          onSubmit={this.sectionsTeachersRemove}
        />
      </UserInfoListWrap>
    );
  }
}
