/**
 * Calculates the time left until a certain duration has passed.
 *
 * @param {Date} startTime - The start time of the duration to be calculated.
 * @param {number} duration - The duration in seconds.
 * @returns {string} The time left in the format of "__:__".
 */
export const calculateTimeLeft = (startTime: Date, duration: number) => {
  const difference =
    duration * 1000 - (new Date().getTime() - new Date(startTime).getTime());
  let timeLeft = { hours: '00', minutes: '00', seconds: '00' };

  if (difference > 0) {
    // output in two digit format __:__ format
    const hours = `${Math.floor(difference / 1000 / 60 / 60) % 60}`;
    const minutes = `${Math.floor((difference / 1000 / 60) % 60)}`;
    const seconds = `${Math.floor((difference / 1000) % 60)}`;
    timeLeft = {
      hours: hours.length === 1 ? `0${hours}` : hours,
      minutes: minutes.length === 1 ? `0${minutes}` : minutes,
      seconds: seconds.length === 1 ? `0${seconds}` : seconds,
    };
  }

  return `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`;
};
/**
 * View the time left.
 * @param {number} timeInSeconds - The time in seconds.
 * @returns {string} The time left in the format of "_hh_:_mm_:_ss_".
 */
export const getFormattedDuration = (timeInSeconds: number): string => {
  const hour = Math.floor(timeInSeconds / 3600).toString();
  const minutes = Math.floor(Math.abs(3600 * +hour - timeInSeconds) / 60).toString();
  const seconds = (timeInSeconds % 60).toString();

  return `${hour.length === 1 ? `0${hour}` : hour} : ${
    minutes.length === 1 ? `0${minutes}` : minutes
  } : ${seconds.length === 1 ? `0${seconds}` : seconds}`;
};
