import styled from 'styled-components';

export const ReportErrorContent = styled.div`

  textarea, .slideNumber{
    background-color: #f8f8f8;
    height: 100px;
    width: 100%;
    resize: none;
    font-size: 15px;
    color: #020b0d;
    border: 1px solid #efefef;
    border-radius: 3px;
    padding: 15px;
    &:focus{
      outline: none;
      border-color: #4886ff;
    }
  }
  .slideNumber{
    height: 25px;
    padding: 15px;
  }
`;

export const ReportErrorButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.25em;

  button {
    margin-left: .5em;
    text-align: center;
    border-radius: .325em;
    font-family: 'MontserratSemiBold';
    cursor: pointer;
    border: none;
    color: #ffffff;
    height: 2.5em;
    font-size: ${props => props.theme.fontSize.PrimaryFontSize};
    background-color: ${props => props.theme.colorStyled.ColorBgDefault};
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 1.25em;
  color: red;
`;
