import React from 'react';
import PropTypes from 'prop-types';
import ShowIf from '@Atoms/ShowIf';
import { NavBarStatusColumn, NavBarIconWrap, NavBarIcon } from '../SubNavBar.style';

const ProgressBarItem = ({ isCompleted = false }) => {
  const pathNumbers = Array.from(Array(7).keys());

  return (
    <NavBarStatusColumn>
      <NavBarIconWrap isLastItem>
        <ShowIf If={isCompleted}>
          <NavBarIcon className="chalktalk-check-success" />
        </ShowIf>
        <ShowIf If={!isCompleted}>
          <NavBarIcon className="chalktalk-quiz">
            {pathNumbers.map((number) => (
              <i className={`path${number}`} key={number} />
            ))}
          </NavBarIcon>
        </ShowIf>
      </NavBarIconWrap>
    </NavBarStatusColumn>
  );
};

ProgressBarItem.propTypes = {
  isCompleted: PropTypes.bool,
};

export default ProgressBarItem;
