import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import Paper from '@components/Atoms/Paper';
import Box from '@components/Atoms/Box';
import Typography from '@components/Atoms/Typography';
import Button from '@components/Atoms/Button';
import Accordion from '@components/Atoms/Accordion';
import { useGetLessonDetail } from '@components/Component/Lesson/LessonContainer/hooks/query';
import ShowIf from '@components/Atoms/ShowIf';
import StandardsPracticeButton from '@components/StandardsPractice/StandardsPracticeButton';
import FiveProgress from '@Atoms/FiveProgressMeter/FiveProgress';
import FromToBlock from '@components/Atoms/FromToBlock';
import { useSearchParams } from '@utils/hooks/useSearchParams';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import PracticeTopNavBar from '../PracticeTopNavBar';
import {
  UPRWrapper,
  UPRStudentName,
  UPRSessionWrapper,
  UPRSessionTitle,
  UPRSessionList,
  UPRSessionItem,
  UPRSessionItemInfo,
  UPRSessionItemDate,
  UPRSessionItemDetail,
  UPRSessionItemDuration,
  UPRSessionItemCard,
  UPRSessionName,
} from '../UnitPracticeReview/UnitPracticeReview.style';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { PracticeContainer } from '../PracticePage/PracticePage.style';
import { calcDurationToSecond } from '../../utils/commonUtils';
import { PRACTICE_SESSION_TYPE } from '../../utils/constants';

const scrollStyle = {
  height: 'calc(100vh - 4em)',
  width: '100%',
};

class UnitPracticeTeacherReview extends Component {
  componentDidMount() {
    const {
      match,
      currentUser,
      sectionUnitGet,
      getSessionPracticeSessionActivity,
      sectionsStudentGet,
    } = this.props;
    const { sectionId, unitId, lessonId, studentId } = match.params;

    getSessionPracticeSessionActivity(sectionId, unitId, lessonId, studentId);
    sectionUnitGet(sectionId);
    if (_isEmpty(currentUser)) {
      sectionsStudentGet(sectionId);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      match,
      getSessionPracticeSessionActivity,
      sectionsStudentGet,
      sectionUnitGet,
    } = this.props;

    const { match: prevMatch } = prevProps;
    const { sectionId, unitId, lessonId } = match.params;
    const {
      sectionId: prevSectionId,
      unitId: prevUnitId,
      lessonId: prevLessonId,
    } = prevMatch.params;

    if (prevSectionId !== sectionId) {
      sectionsStudentGet(sectionId);
      sectionUnitGet(sectionId);
    }

    if (
      prevSectionId !== sectionId ||
      prevUnitId !== unitId ||
      prevLessonId !== lessonId
    ) {
      getSessionPracticeSessionActivity(sectionId, unitId, lessonId, null);
    }
  }

  handleGoBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  renderSkill = (skill, lessonId) => {
    const {
      currentUser,
      isGettingLesson,
      isLoading,
      lessonSkillsList,
      assetTypeQuery,
      lessons,
    } = this.props;

    const isUnitPractice = ['2', '3', '4'].includes(assetTypeQuery);
    const lesson = lessons?.find((l) => l.id == lessonId);
    if (isGettingLesson || isLoading) {
      return <UPRSessionItemCard>Loading...</UPRSessionItemCard>;
    }

    if (!lessons || !currentUser) {
      return <UPRSessionItemCard>N/A</UPRSessionItemCard>;
    }
    const initialSkill = _get(skill, 'initial', 'N/A');
    const currentSkill = _get(skill, 'current', 'N/A');

    return (
      <UPRSessionItemCard key={lessonId}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '2rem',
            pb: '1.5rem',
          }}
        >
          <ShowIf If={isUnitPractice}>
            <Typography variant="h3">{lesson?.name}</Typography>
          </ShowIf>
          <FromToBlock
            from={<FiveProgress level={initialSkill} />}
            to={<FiveProgress level={currentSkill} />}
            title="average Skill Level"
          />
        </Box>
        <ShowIf If={!!skill?.skill_list?.length && !!lessonSkillsList?.length}>
          <Box sx={{ borderTop: 'solid 1px #ccc' }}>
            <Accordion
              title={
                <Typography variant="h3" sx={{ fontSize: '1rem', color: '#2b1a08' }}>
                  Breakdown of Skills
                </Typography>
              }
              accordionSx={{ boxShadow: 'none', border: 'none' }}
              summarySx={{ fontSize: '1rem', color: 'black' }}
              content={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1.25rem',
                    width: '100%',
                  }}
                >
                  {skill?.skill_list?.map((item) => {
                    const skillName = lessonSkillsList?.find(
                      (el) => el?.id === item?.assessment_node_id,
                    )?.name;
                    return (
                      <React.Fragment key={item?.id}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0.5rem',
                            width: '100%',
                          }}
                        >
                          <Typography variant="subtitle1">{skillName}</Typography>
                          <FromToBlock
                            from={<FiveProgress level={initialSkill} />}
                            to={<FiveProgress level={currentSkill} />}
                            title="Skill Level"
                          />
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Box>
              }
            />
          </Box>
        </ShowIf>
      </UPRSessionItemCard>
    );
  };

  renderLessonSkill = (lessonId, skill) => {
    const { match } = this.props;
    const { studentId } = match.params;
    const skillValue = _get(skill, [lessonId, studentId], {
      initial: 0,
      current: 0,
      skill_list: [],
    });

    return this.renderSkill(skillValue, lessonId);
  };

  navigateToReviewAnswerPage = (practiceSessionId) => () => {
    const { match, history, pacingGuideId } = this.props;
    const { courseId, sectionId, unitId, lessonId, subjectId } = match.params;
    let route = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}`;
    if (lessonId) {
      route += `/lesson/${lessonId}`;
    }
    route += `/practice-result/${practiceSessionId}${
      pacingGuideId ? `?pacing-guide-id=${pacingGuideId}` : ''
    }`;

    history.push(route);
  };

  renderSession = (practice, index) => {
    const { courseData, lessonName } = this.props;

    const time = practice.ended
      ? `Ended: ${moment(practice.ended).format('MM-DD-YYYY hh:mm')}`
      : `Started: ${moment(practice.started).format('MM-DD-YYYY hh:mm')}`;

    const score = _get(practice, 'score.0.score', {}) || {};
    const { auto = 0, auto_max: autoMax = 0 } = score;
    const tot = practice.time_on_task;
    const { skill } = practice;
    const displayTot = calcDurationToSecond(tot);
    const useLearnosity = courseData?.standard_practice_enabled;
    return (
      <UPRSessionItem key={index.toString()}>
        <UPRSessionItemDetail>
          <UPRSessionName>
            <Typography variant="h3">{lessonName} </Typography>
          </UPRSessionName>
          <UPRSessionItemInfo>
            <UPRSessionItemDate>{time}</UPRSessionItemDate>
            <ShowIf If={!useLearnosity}>
              <UPRSessionItemDuration>{displayTot}</UPRSessionItemDuration>
            </ShowIf>
          </UPRSessionItemInfo>
          <Box
            sx={{
              borderTop: '1px solid #ccc',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Paper
              sx={{
                mt: '1rem',
                alignSelf: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '5.375rem',
                height: '5.375rem',
                border: (theme) => `solid 0.125rem ${theme.palette.primary.main}`,
                borderRadius: '1rem',
                padding: '1rem',
                gap: '0.5rem',
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: '1rem',
                  textTransform: 'uppercase',
                  color: '#2b1a08',
                }}
              >
                Score
              </Typography>
              <Typography variant="h3" sx={{ fontSize: '1rem', color: '#2b1a08' }}>
                {`${auto} / ${autoMax}`}
              </Typography>
            </Paper>
          </Box>
          {Object.keys(skill).map((item) => this.renderLessonSkill(item, skill))}
          <Box
            sx={[
              {
                paddingTop: '1em',
                paddingBottom: '1em',
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'right',
                color: 'primary.main',
                cursor: 'pointer',

                '&:hover': {
                  textDecoration: 'underline',
                },
              },
            ]}
          >
            <ShowIf If={useLearnosity}>
              <StandardsPracticeButton
                practiceSessionId={practice.id}
                state="review"
                text="Review Answer"
              />
            </ShowIf>
            <ShowIf If={!useLearnosity}>
              <Button
                variant="text"
                onClick={this.navigateToReviewAnswerPage(practice.id)}
              >
                Review Answer
              </Button>
            </ShowIf>
          </Box>
        </UPRSessionItemDetail>
      </UPRSessionItem>
    );
  };

  render() {
    const { practiceList, currentUser = {}, isLoading, isGettingLesson } = this.props;

    const name = `${currentUser.first_name || 'N/A'} ${currentUser.last_name || 'N/A'}`;
    let sortedPracticeList = practiceList.sort((a, b) => {
      if (a.ended && b.ended) {
        return moment(b.ended).diff(moment(a.ended));
      }
      return moment(b.start).diff(moment(a.start));
    });

    sortedPracticeList = sortedPracticeList.filter(
      (practice) => practice.type != PRACTICE_SESSION_TYPE.OGA,
    );

    return (
      <PracticeContainer>
        <PracticeTopNavBar goBackText="Go Back" goBack={this.handleGoBack} />
        <ShadowScrollbar autoHide style={scrollStyle}>
          {!(isLoading || isGettingLesson) ? (
            <UPRWrapper>
              <UPRStudentName>{name}</UPRStudentName>
              <UPRSessionWrapper>
                <UPRSessionTitle>Session Results</UPRSessionTitle>
                <UPRSessionList>
                  {sortedPracticeList.map(this.renderSession)}
                </UPRSessionList>
              </UPRSessionWrapper>
            </UPRWrapper>
          ) : (
            <LoadingIndicator isLoading={isLoading || isGettingLesson} />
          )}
        </ShadowScrollbar>
      </PracticeContainer>
    );
  }
}

UnitPracticeTeacherReview.propTypes = {
  lessons: PropTypes.arrayOf(PropTypes.any),
  practiceList: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentUser: PropTypes.shape(),
  history: PropTypes.shape(),
  match: PropTypes.shape(),
  isLoading: PropTypes.bool,
  getSessionPracticeSessionActivity: PropTypes.func.isRequired,
  sectionsStudentGet: PropTypes.func.isRequired,
  sectionUnitGet: PropTypes.func.isRequired,
  isGettingLesson: PropTypes.bool,
  courseData: PropTypes.object,
};
const withDataWrapper = (WrappedComponent) => {
  const ComponentWithData = (props) => {
    const { data: lessonDetails } = useGetLessonDetail();
    const { 'pacing-guide-id': pacingGuideId } = useSearchParams();

    return (
      <WrappedComponent
        {...props}
        lessonSkillsList={lessonDetails?.skills}
        lessonName={lessonDetails?.name}
        pacingGuideId={pacingGuideId}
      />
    );
  };
  return ComponentWithData;
};
export default withDataWrapper(UnitPracticeTeacherReview);
