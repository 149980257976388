import { useQuery, useMutation } from 'react-query';
import {
  getAvailableReports,
  generateReports,
  getUserPastReports,
  getFeatureAccess,
} from '../../apis/adminReports';

export const useAvailableReportsQuery = () => {
  const result = useQuery(
    ['availableReportsList'],
    async () => {
      const res = await getAvailableReports();
      return res.response.data;
    },
    {
      placeholderData: [],
    },
  );
  return result;
};

export const useFeatureAccessQuery = (feature) => {
  const result = useQuery(
    ['hasFeatureAccess'],
    async () => {
      const res = await getFeatureAccess(feature);
      return res.response.data?.has_access;
    },
    {
      placeholderData: false,
      staleTime: 60 * 60 * 1000,
    },
  );
  return result.data;
};

export const useGenerateReportsMutation = () =>
  useMutation({
    mutationFn: (payload) => generateReports(payload),
  });

export const usePastReportsQuery = (page) => {
  const result = useQuery(
    ['pastReportsList', page],
    async () => {
      const res = await getUserPastReports(page);
      return res.response.data;
    },
    { enabled: !!page, placeholderData: {} },
  );
  return result;
};
