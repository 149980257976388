/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _orderBy from 'lodash/orderBy';
import MoveStudentTable from '../../containers/CourseManagementSection/MoveStudentTable';
import InviteStudentsModal from '../Modals/InviteStudentsModal';
import PostStudentsInviteModal from '../Modals/PostStudentsInviteModal';
import ConfirmModal from '../ConfirmModal';
import {
  UserInfoListWrap,
  UserProfileTitle,
  UserActionWrap,
  UserActionPopup,
  UserActionItem,
} from '../Profile/Profile.style';
import { OverlayWrap } from '../Generals/stylesheets/General.style';
import { Button } from '../Generals/stylesheets/Button.style';
import {
  TableContainerWrap,
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
  TableContentGroup,
  TableEmptyContent,
  TableIcon,
  TableCheckboxLabel,
  TableCheckbox,
  TableCheckMark,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { ColumnSizeStudent } from './ColumSize';
import { formatDateByTime } from '../../utils/func-utils';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import StudentResetPasswordForm from '../CourseManagementStudents/StudentResetPasswordForm';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'User',
  },
  {
    uniqKey: 'last_login',
    value: 'Last Activity',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

class StudentList extends Component {
  static propTypes = {
    studentList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    sectionsStudentRemove: PropTypes.func,
    sectionDetail: PropTypes.object,
    history: PropTypes.shape().isRequired,
    match: PropTypes.shape().isRequired,
    isGettingStudentList: PropTypes.bool,
    isOwner: PropTypes.bool,
    sendResetPassword: PropTypes.func,
    isChangePasswordSuccess: PropTypes.bool,
    courseId: PropTypes.string.isRequired,
    resetStudentPassword: PropTypes.func,
    submitInviteStudent: PropTypes.func.isRequired,
    isOpenInviteStudent: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
      moveStudentOpen: false,
      removeStudentId: null,
      actionPopupById: null,
      selectedStudentIds: [],
      selectedStudentById: {},
      isOpen: false,
      studentInfo: null,
      isShowInviteStudentModal: props.isOpenInviteStudent,
      isShowPostStudentsInviteModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isShowInviteStudentModal } = this.state;
    const { studentList, isChangePasswordSuccess, isOpenInviteStudent } = this.props;
    const {
      studentList: prevStudentList,
      isChangePasswordSuccess: prevIsChangePasswordSuccess,
      isOpenInviteStudent: prevIsOpenInviteStudent,
    } = prevProps;

    if (!_isEqual(studentList, prevStudentList)) {
      this.setState({
        // eslint-disable-line
        selectedStudentIds: [],
        selectedStudentById: {},
      });
    }

    if (
      prevIsChangePasswordSuccess !== isChangePasswordSuccess
      && isChangePasswordSuccess
    ) {
      this.closeResetPasswordForm();
    }

    if (
      !prevIsOpenInviteStudent
      && isOpenInviteStudent
      && !isShowInviteStudentModal
    ) {
      this.setState({
        // eslint-disable-line
        isShowInviteStudentModal: true,
      });
    }
  }

  closeResetPasswordForm = () => {
    this.setState({ isOpen: false, studentInfo: null });
  };

  openResetPasswordForm = (studentInfo) => {
    this.setState({ isOpen: true, studentInfo });
  };

  onSubmitResetPassword = (values) => {
    const { studentInfo } = this.state;
    const { courseId, resetStudentPassword } = this.props;
    const { id, first_name: fName, last_name: lName } = studentInfo;
    const name = `${fName} ${lName}`;
    const { new_password: newPass } = values;

    resetStudentPassword(courseId, id, newPass, name);
  };

  selectStudent = (id) => {
    const { selectedStudentById, selectedStudentIds } = this.state;

    if (!selectedStudentById[id]) {
      this.setState({
        selectedStudentById: {
          ...selectedStudentById,
          [id]: true,
        },
        selectedStudentIds: [...selectedStudentIds, id],
      });
    } else {
      delete selectedStudentById[id];
      this.setState({
        selectedStudentIds: [
          ...selectedStudentIds.filter((itemId) => itemId !== id),
        ],
        selectedStudentById: { ...selectedStudentById },
      });
    }
  };

  selectAllStudent = () => {
    const { selectedStudentIds } = this.state;
    const { studentList } = this.props;

    if (studentList.length === selectedStudentIds.length) {
      this.setState({
        selectedStudentIds: [],
        selectedStudentById: {},
      });
    } else {
      const ids = [];
      const byId = {};
      studentList.forEach((item) => {
        const { id } = item;

        ids.push(id);
        byId[id] = true;
      });

      this.setState({
        selectedStudentIds: ids,
        selectedStudentById: byId,
      });
    }
  };

  onCloseMoveStudent = () => {
    this.setState({
      moveStudentOpen: false,
    });
  };

  onOpenMoveStudent = () => {
    this.setState({
      moveStudentOpen: true,
    });
  };

  onCloseConfirmModal = () => {
    this.setState({
      removeStudentId: null,
    });
  };

  onOpenConfirmModal = (studentId) => {
    this.setState({
      removeStudentId: studentId,
    });
  };

  onCloseActionPopup = () => {
    this.setState({
      actionPopupById: null,
    });
  };

  onOpenActionPopup = (studentId) => {
    this.setState({
      actionPopupById: studentId,
    });
  };

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  sectionsStudentRemove = (studentId) => {
    const { sectionsStudentRemove, sectionDetail } = this.props;
    const { id: sectionId } = sectionDetail;

    sectionsStudentRemove(sectionId, studentId);
  };

  renderStudentListHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;
    return (
      <TableHeader
        {...ColumnSizeStudent[index + 1]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderStudentListHeader = () => {
    const { selectedStudentIds } = this.state;
    const { studentList } = this.props;
    const isSelected = selectedStudentIds.length === studentList.length;

    return (
      <TableHeadWrapper>
        <TableHeader {...ColumnSizeStudent[0]}>
          <TableCheckboxLabel>
            <TableCheckbox
              type="checkbox"
              value={isSelected}
              checked={isSelected}
              onChange={this.selectAllStudent}
            />
            <TableCheckMark className="checkmark" />
          </TableCheckboxLabel>
        </TableHeader>
        {tableValues.map(this.renderStudentListHeaderItem)}
        <TableHeader {...ColumnSizeStudent[3]} value="Actions" />
      </TableHeadWrapper>
    );
  };

  renderActionPopup = (studentInfo) => {
    const { actionPopupById } = this.state;
    const { email } = studentInfo;
    const { sendResetPassword, isOwner } = this.props;
    return (
      <UserActionPopup>
        <UserActionItem blue onClick={() => sendResetPassword(email)}>
          Send reset link
        </UserActionItem>
        <UserActionItem
          red
          onClick={() => this.openResetPasswordForm(studentInfo)}
        >
          Reset password
        </UserActionItem>
        {isOwner && (
          <UserActionItem
            onClick={() => this.onOpenConfirmModal(actionPopupById)}
          >
            Remove
          </UserActionItem>
        )}
      </UserActionPopup>
    );
  };

  renderStudentListContent = (student, index) => {
    const { id: studentId, last_login: lastLogin, email } = student;
    const { selectedStudentById, actionPopupById } = this.state;
    const isSelected = !!selectedStudentById[studentId];
    const isActionPopupOpen = actionPopupById === student.id;
    const lastAcitivity = moment(lastLogin).format('YYYY-MM-DD');
    const today = moment().format('YYYY-MM-DD');
    const diffDateActivity = moment(today).diff(lastAcitivity, 'days');

    return (
      <TableContentItem smallSize noBorder key={index}>
        <TableContent {...ColumnSizeStudent[0]}>
          <TableCheckboxLabel>
            <TableCheckbox
              type="checkbox"
              checked={isSelected}
              value={isSelected}
              onChange={() => this.selectStudent(studentId)}
            />
            <TableCheckMark className="checkmark" />
          </TableCheckboxLabel>
        </TableContent>
        <TableContent {...ColumnSizeStudent[1]}>
          <TableContentGroup>
            <p>
              {`${student.first_name || ''} ${student.last_name || ''} (G.${
                student.grade_level
              })`}
            </p>
            <span>{student.email || ''}</span>
          </TableContentGroup>
        </TableContent>
        <TableContent {...ColumnSizeStudent[2]}>
          {lastLogin
            ? diffDateActivity > 30
              ? '30+ days'
              : moment(lastLogin).fromNow()
            : '--'}
        </TableContent>
        <TableContent {...ColumnSizeStudent[3]}>
          <UserActionWrap>
            <TableIcon
              className="chalktalk-more"
              onClick={() => this.onOpenActionPopup(student.id)}
            />
            {isActionPopupOpen && (
              <React.Fragment>
                {this.renderActionPopup(student)}
                <OverlayWrap
                  isOpen={isActionPopupOpen}
                  onClick={this.onCloseActionPopup}
                />
              </React.Fragment>
            )}
          </UserActionWrap>
        </TableContent>
      </TableContentItem>
    );
  };

  goToInviteForm = () => {
    // const { history, match } = this.props;
    // const { params } = match;
    // const { sectionId, courseId } = params;

    // history.push(`/management/course/${courseId}/section/${sectionId}/invite-student`);
    this.onToggleInviteStudentModal();
  };

  callbackFunction = () => {
    this.onToggleInviteStudentModal();
    this.onTogglePostStudentsInviteModal();
  };

  onToggleInviteStudentModal = () => {
    const { isShowInviteStudentModal } = this.state;
    this.setState({
      isShowInviteStudentModal: !isShowInviteStudentModal,
    });
  };

  onTogglePostStudentsInviteModal = () => {
    const { isShowPostStudentsInviteModal } = this.state;
    this.setState({
      isShowPostStudentsInviteModal: !isShowPostStudentsInviteModal,
    });
  };

  renderInviteStudentModal = () => {
    const { submitInviteStudent } = this.props;
    const { id: sectionId } = this.props.sectionDetail;
    return (
      <InviteStudentsModal
        isShowModal
        sectionDetail={this.props.sectionDetail}
        onCloseModal={this.onToggleInviteStudentModal}
        sectionId={sectionId}
        submitInviteStudent={submitInviteStudent}
        callbackFunction={this.callbackFunction}
      />
    );
  };

  renderPostStudentsInviteModal = () => (
    <PostStudentsInviteModal
      isShowModal
      onCloseModal={this.onTogglePostStudentsInviteModal}
    />
  );

  render() {
    const {
      orderBy,
      sortBy,
      moveStudentOpen,
      selectedStudentIds,
      removeStudentId,
      isOpen,
      isShowInviteStudentModal,
      isShowPostStudentsInviteModal,
    } = this.state;
    const { studentList, isGettingStudentList } = this.props;
    const shouldDisplayMoveButton = selectedStudentIds.length > 0;
    firstSort(studentList);
    const filterStudentList = _orderBy(
      studentList,
      [
        (d) => {
          if (sortBy === 'last_name') return d[sortBy].toUpperCase();
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <UserInfoListWrap>
        {isShowInviteStudentModal && this.renderInviteStudentModal()}
        {isShowPostStudentsInviteModal && this.renderPostStudentsInviteModal()}
        <UserProfileTitle isSpaceBetween>
          Students List
          <div>
            <Button
              blue
              disabled={!shouldDisplayMoveButton}
              onClick={this.onOpenMoveStudent}
            >
              Move
            </Button>
            <Button marginLeft onClick={this.goToInviteForm}>
              Invite
            </Button>
          </div>
        </UserProfileTitle>
        {isGettingStudentList ? (
          <TableLoader />
        ) : (
          <TableContainerWrap>
            {_isEmpty(studentList) ? (
              <TableEmptyContent>No Student data</TableEmptyContent>
            ) : (
              <React.Fragment>
                {this.renderStudentListHeader()}
                <TableContentWrapper>
                  {filterStudentList.map(this.renderStudentListContent)}
                </TableContentWrapper>
              </React.Fragment>
            )}
          </TableContainerWrap>
        )}
        <ConfirmModal
          itemId={removeStudentId}
          onClose={this.onCloseConfirmModal}
          content="Are you sure to delete this student?"
          onSubmit={this.sectionsStudentRemove}
        />
        {moveStudentOpen && (
          <MoveStudentTable
            isOpen={moveStudentOpen}
            onClose={this.onCloseMoveStudent}
            selectedStudentIds={selectedStudentIds}
            studentList={studentList}
            selectStudent={this.selectStudent}
          />
        )}
        <StudentResetPasswordForm
          onSubmit={this.onSubmitResetPassword}
          isOpen={isOpen}
          onClose={this.closeResetPasswordForm}
        />
      </UserInfoListWrap>
    );
  }
}

export default StudentList;
