import { connect } from 'react-redux';
import ProfileChangePassword from '../../components/ProfileChangePassword';
import {
  selectors as UserSelectors,
  actions as UserActions,
} from '../../reducers/user';

const mapStateToProps = state => ({
  isChangingPassword: UserSelectors.isChangingPassword(state),
  errorMsg: UserSelectors.getChangingPasswordError(state),
});

const mapDispatchToProps = {
  changePassword: UserActions.changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileChangePassword);
