import React from 'react';
import Box from '@components/Atoms/Box';
import Skeleton from '@components/Atoms/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';

const TabViewContentSkeleton = () => {
  const isLargeScreen = useMediaQuery('(min-width:1280px)');

  return (
    <Box
      sx={{
        '& span': {
          transform: 'none',
        },
        display: 'flex',
        flexDirection: isLargeScreen ? 'row' : 'column',
        gap: '1rem',
        justifyContent: 'center',
        padding: '1rem',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flex: '1',
          flexDirection: 'column',
          gap: '1rem',
          width: '100%',
          order: isLargeScreen ? 0 : 2,
        }}
      >
        <Skeleton height="4.375rem" />
        <Skeleton height="4.375rem" />
      </Box>
      <Box sx={{ width: '100%', flex: '2' }}>
        <Skeleton height="41.5rem" />
      </Box>
    </Box>
  );
};

export default TabViewContentSkeleton;
