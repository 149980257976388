import React from 'react';
import Box from '@components/Atoms/Box';
import Skeleton from '@components/Atoms/Skeleton';

const TabNavigatorSkeleton = () => (
  <Box
    sx={{
      '& span': {
        height: '4.0625rem',
        transform: 'none',
      },
      padding: '1rem',
      width: '100%',
    }}
  >
    <Skeleton />
  </Box>
);

export default TabNavigatorSkeleton;
