/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { iWApi, sectionAPI, criteriaAPI, reportAPI } from '../api';

const getData = (api, id, defaultValue) => {
  let data;
  if (id) {
    data = api.get(id);
  } else {
    data = api.get();
  }
  return data || defaultValue;
};

// -------------
export const useGet = ({ api, key, id, defaultValue = {} }) => {
  const [data, setData] = React.useState(getData(api, id, defaultValue));
  useEffect(() => {
    setData(getData(api, id, defaultValue));
  }, [id]);
  useEffect(() => {
    // event listener to invalidatate and re fetch data
    const listener = () => {
      setData(getData(api, id, defaultValue));
    };
    window.addEventListener(`invalidate-${key}`, listener);
    return () => window.removeEventListener(`invalidate-${key}`, listener);
  }, []);
  const value = React.useMemo(
    () => ({
      data,
      invalidate: () => {
        window.dispatchEvent(new Event(`invalidate-${key}`));
      },
    }),
    [data],
  );
  return value;
};
// ------------------

export const useIWGet = ({ id, defaultValue } = {}) =>
  useGet({ api: iWApi, key: 'IW', id, defaultValue });

export const useSectionGet = ({ id, defaultValue } = {}) =>
  useGet({ api: sectionAPI, key: 'section', id, defaultValue });

export const useSectionByIWId = ({ IWId, defaultValue } = {}) => {
  const defaultData = useGet({
    api: sectionAPI,
    key: 'section',
    undefined,
    defaultValue,
  });
  return React.useMemo(() => {
    const data = defaultData.data.filter((section) => section.IWId === IWId);
    return { ...defaultData, data };
  }, [defaultData, IWId]);
};

export const useCriteriaGet = ({ id, defaultValue } = {}) =>
  useGet({ api: criteriaAPI, key: 'criteria', id, defaultValue });

export const useCriteriaBySectionId = ({ sectionId, defaultValue } = {}) => {
  const defaultData = useGet({
    api: criteriaAPI,
    key: 'criteria',
    undefined,
    defaultValue,
  });
  return React.useMemo(() => {
    const data = defaultData.data.filter((criteria) => criteria.sectionId === sectionId);
    return { ...defaultData, data };
  }, [defaultData, sectionId]);
};
const filterDefault = [];
export const useReportGet = ({ id, defaultValue, filters = filterDefault } = {}) => {
  const reports = useGet({ api: reportAPI, key: 'report', id, defaultValue });
  const newReport = React.useMemo(() => {
    if (!filters.length) {
      return reports;
    }
    const data = reports.data.filter((report) => {
      let isMatch = true;
      filters.forEach((filter) => {
        if (report[filter.key] !== filter.value) {
          isMatch = false;
        }
      });
      return isMatch;
    });
    return { ...reports, data };
  }, [reports.data, filters]);
  return newReport;
};
