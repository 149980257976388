import {
  call, put, select, takeLeading,
} from 'redux-saga/effects';
import * as SectionAPI from '../../apis/sections';
import * as SessionAPI from '../../apis/sessions';
import {
  SECTIONS_SCHEDULE_GET_LIST,
  SECTIONS_SCHEDULE_UPDATE_DATE,
  actions as SectionScheduleActions,
  selectors as SectionScheduleSelectors,
} from '../../reducers/sectionSchedule';
import { CALENDAR_EVENT_TYPE } from '../../utils/constants';
import { error as ToastError } from '../../utils/toast';

/**
 * ! Need update callback
 */
// function calls
function* sectionsGetScheduleList({ sectionId }) {
  const shouldFetch = yield select(
    SectionScheduleSelectors.getShouldFetch,
    sectionId,
  );

  const { response, error } = yield call(
    SectionAPI.getSectionSchedule,
    sectionId,
  );

  if (response) {
    const { data } = response;

    yield put(
      SectionScheduleActions.sectionScheduleGetSuccess(data, sectionId),
    );
  } else {
    yield put(SectionScheduleActions.sectionScheduleGetFail(error));
  }
}

function getApiToUpdateSession(sessionType) {
  switch (sessionType) {
    case CALENDAR_EVENT_TYPE.EXAMS:
      return SessionAPI.updateSessionExam;
    case CALENDAR_EVENT_TYPE.REVIEWS:
      return SessionAPI.updateSessionReview;
    case CALENDAR_EVENT_TYPE.LESSONS:
      return SessionAPI.updateSessionStandard;
    default:
      throw new Error('Invalid session type');
  }
}

function* updateSessionByType({ sessionDetail }) {
  const { id, type } = sessionDetail;

  try {
    // const updateApi = getApiToUpdateSession(type);
    // const { response, error } = yield call(updateApi, id, sessionDetail);
    // if (error) throw error;
    // const { data } = response;

    yield put(
      SectionScheduleActions.sectionScheduleUpdateDateSuccess(id, type, sessionDetail),
    );
  } catch (error) {
    ToastError(error.message || error);
    yield put(SectionScheduleActions.sectionScheduleUpdateDateFail(id));
  }
}

export default function* sectionSchedule() {
  yield takeLeading(SECTIONS_SCHEDULE_GET_LIST, sectionsGetScheduleList);
  yield takeLeading(SECTIONS_SCHEDULE_UPDATE_DATE, updateSessionByType);
}
