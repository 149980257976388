import React from 'react';
import PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import ReactDOM from 'react-dom';
import _get from 'lodash/get';
import MediaQuery from 'react-responsive';
import Lightbox from 'react-image-lightbox';
import { PracticeContentWrap } from '../PracticePage/PracticePage.style';
import ReviewQuestionContainersDesktop from './ReviewQuestionContainersDesktop';
import ReviewQuestionContainersMobile from './ReviewQuestionContainersMobile';
import 'react-image-lightbox/style.css';

class ReviewQuestionContainers extends React.PureComponent {
  state = {
    questionSelected: 0,
    isLightBoxOpen: false,
    imgSrc: '',
  };

  componentDidMount() {
    const currentNode = ReactDOM.findDOMNode(this); // eslint-disable-line

    if (currentNode) {
      currentNode.addEventListener('click', this.handleClick, false);
    }
  }

  componentWillUnmount() {
    const currentNode = ReactDOM.findDOMNode(this); // eslint-disable-line

    if (currentNode) {
      currentNode.removeEventListener('click', this.handleClick, false);
    }
  }

  openLightBox = (imgSrc) => {
    this.setState({
      isLightBoxOpen: true,
      imgSrc,
    });
  };

  closeLightbox = () => {
    this.setState({
      isLightBoxOpen: false,
      imgSrc: '',
    });
  };

  handleClick = (e) => {
    const { isLightBoxOpen } = this.state;
    const targetTagName = _get(e, 'target.tagName', '');

    if (targetTagName === 'IMG' && !isLightBoxOpen) {
      const src = _get(e, 'target.src', '');
      this.openLightBox(src);
    }
  };

  nextQuestion = () => {
    const { questionSelected } = this.state;
    const { questions } = this.props;
    if (questionSelected + 1 >= questions.length) return;
    this.setState((prevState) => ({
      questionSelected: prevState.questionSelected + 1,
    }));
  };

  prevQuestion = () => {
    const { questionSelected } = this.state;
    if (questionSelected - 1 < 0) return;
    this.setState((prevState) => ({
      questionSelected: prevState.questionSelected - 1,
    }));
  };

  selectQuestion = (newQuestionSelected) => () => {
    this.setState({
      questionSelected: newQuestionSelected,
    });
  };

  render() {
    const { questions } = this.props;
    const totalQuestions = questions.length;

    if (!totalQuestions) {
      return (
        <PracticeContentWrap>
          This practice don&#39;t have anything for you to review
        </PracticeContentWrap>
      );
    }

    const allProps = {
      ...this.props,
      ...this.state,
      nextQuestion: this.nextQuestion,
      prevQuestion: this.prevQuestion,
      selectQuestion: this.selectQuestion,
    };

    const { isLightBoxOpen, imgSrc } = this.state;

    return (
      <React.Fragment>
        <MediaQuery minWidth={768}>
          {(matches) => matches ? (
            <ReviewQuestionContainersDesktop {...allProps} />
          )
          : (
            <ReviewQuestionContainersMobile {...allProps} />
          )}
        </MediaQuery>

        {isLightBoxOpen && (
          <Lightbox mainSrc={imgSrc} onCloseRequest={this.closeLightbox} />
        )}
      </React.Fragment>
    );
  }
}

ReviewQuestionContainers.propTypes = {
  questions: PropTypes.array,
  isPracticeNavigation: PropTypes.bool,
};

export default ReviewQuestionContainers;
