import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BackgroundLeft from '../BackgroundLeft/BackgroundLeft';
import StudentLoginForm from '../../containers/Login/StudentLoginForm';
import {
  RegisterSuccessNotification,
  SystemRequirementLink,
} from '../StudentRegister/StudentRegister.style';
import * as curriculumAPI from '../../apis/curriculum';
import { LoginContainerWrap, LoginBlockRight } from './Login.style';

export default class LoginPage extends PureComponent {
  static propTypes = {
    statusMessage: PropTypes.string,
    cleverEmail: PropTypes.string,
  };

  getSystemRequirementsurl = () => {
    const response = curriculumAPI
      .getExternalLink('system-requirement')
      .then((responseData) => {
        const sysReqLink = responseData.response.data;
        window.open(sysReqLink.url, '_blank');
      });
  };

  renderSystemRequirements = () => (
    <SystemRequirementLink>
      <img src="/images/icon-v2/system-requirements.png" alt="calendar" />
      <a onClick={this.getSystemRequirementsurl}>

        Explore system requirements
      </a>
    </SystemRequirementLink>
  );

  renderStatusMessage = () => {
    const { statusMessage, cleverEmail } = this.props;
    if (!statusMessage && !cleverEmail) return null;
    if (cleverEmail) {
      return (
        <RegisterSuccessNotification>
          Please login to connect your account with clever
        </RegisterSuccessNotification>
      );
    }
    return (
      <RegisterSuccessNotification>{statusMessage}</RegisterSuccessNotification>
    );
  };

  render() {
    return (
      <LoginContainerWrap>
        <BackgroundLeft />
        <LoginBlockRight>
          {this.renderStatusMessage()}
          <StudentLoginForm />
          {this.renderSystemRequirements()}
        </LoginBlockRight>
      </LoginContainerWrap>
    );
  }
}
