/* eslint-disable react/sort-comp */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _round from 'lodash/round';
import ColumnSize from './ColumnSize';
import TableLoader from '../LoadingPlaceholder/TableLoader';
import {
  TableHeadWrapper,
  TableContentItem,
  TableContentWrapper,
} from '../Table/TableComponent.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import { NoContent } from '../Generals/stylesheets/General.style';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'Student Name',
  },
  {
    uniqKey: 'practiceCoverage',
    value: 'Practice Coverage',
  },
  {
    uniqKey: 'pointsGained',
    value: 'Skill Improvement',
  },
  {
    uniqKey: 'sessionPerLesson',
    value: 'Practice Session per lesson',
  },
];

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

export default class DashboardStudentActivitySummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sortBy: 'last_name',
      orderBy: ORDER_BY.ASC,
    };
    this.sort = this.sort.bind(this);
  }

  componentDidMount() {
    const {
      lessonList,
      studentList = [],
      skillByLessonId = {},
      examsScoreSummary = [],
    } = this.props;
    if (lessonList && studentList.length && !_isEmpty(skillByLessonId)) {
      this.getAllLessonBySubjectId(
        lessonList,
        studentList,
        skillByLessonId,
        examsScoreSummary,
      );
    }
  }

  componentDidUpdate(prevProps) {
    const {
      lessonList,
      skillByLessonId,
      studentList = [],
      examsScoreSummary = [],
    } = this.props;
    const {
      lessonList: prevLessonList,
      skillByLessonId: prevSkillByLessonId,
      examsScoreSummary: prevExamsScoreSummary,
      studentList: prevStudentList,
    } = prevProps;
    const bool =
      (lessonList !== prevLessonList ||
        skillByLessonId !== prevSkillByLessonId ||
        examsScoreSummary !== prevExamsScoreSummary ||
        studentList !== prevStudentList) &&
      lessonList &&
      !_isEmpty(skillByLessonId) &&
      studentList.length;
    if (bool) {
      this.getAllLessonBySubjectId(
        lessonList,
        studentList,
        skillByLessonId,
        examsScoreSummary,
      );
    }
  }

  sort(key, order) {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  }

  renderSummaryTableHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const { courseDetail } = this.props;
    const isFilterChoosen = sortBy === uniqKey;

    if (uniqKey === 'pointsGained' && courseDetail?.standard_practice_enabled) {
      return <></>;
    }
    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderSummaryTableHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderSummaryTableHeaderItem)}
    </TableHeadWrapper>
  );

  // need refactor
  getAllLessonBySubjectId = (
    lessonList,
    studentList,
    skillByLessonId,
    examsScoreSummary,
  ) => {
    const result = [];
    studentList.forEach((student) => {
      let pointsGained = 0;
      let count = 0;
      let numberOfPractice = 0;
      let amountOfSession = 0;
      let totalLessonHasPracticeByStudent = 0;
      if (lessonList.length) {
        lessonList.forEach((lesson) => {
          // pointsGained
          const skill = _get(skillByLessonId, `${lesson}.${student.id}`, {});
          pointsGained += skill.current - skill.initial;
          count += 1;

          // practiceCoverage
          const practice = examsScoreSummary.filter(
            (x) => x.lesson === lesson && x.user === student.id,
          );
          numberOfPractice += practice.length ? 1 : 0;

          // sessionPerLesson
          if (practice.length) {
            practice.forEach((item) => {
              totalLessonHasPracticeByStudent += 1;
              amountOfSession += item.session_count;
            });
          }
        });
      }

      if (!count) {
        count = 1;
      }

      result.push({
        useName: `${student.first_name} ${student.last_name}`,
        last_name: student.last_name,
        practiceCoverage: Math.round((numberOfPractice / lessonList.length) * 100),
        pointsGained: _round(pointsGained / count, 1),
        sessionPerLesson: _round(amountOfSession / totalLessonHasPracticeByStudent, 1),
      });
    });
    firstSort(result);
    this.setState({ data: result });
  };

  renderSumaryTableContent = (values, index) => {
    const { courseDetail } = this.props;
    const hideSkill = courseDetail?.standard_practice_enabled;
    return (
      <TableContentItem key={index}>
        <TableContent {...ColumnSize[0]}>{values.useName}</TableContent>
        <TableContent {...ColumnSize[1]}>
          {values.practiceCoverage > 0 ? `${values.practiceCoverage} %` : '--'}
        </TableContent>
        {!hideSkill && (
          <TableContent {...ColumnSize[2]}>
            {values.pointsGained > 0 ? values.pointsGained : '--'}
          </TableContent>
        )}
        <TableContent {...ColumnSize[3]}>{values.sessionPerLesson || '--'}</TableContent>
      </TableContentItem>
    );
  };

  render() {
    const { data, orderBy, sortBy } = this.state;
    const { isGettingStudentList } = this.props;
    const filterActivitySummaryData = _orderBy(
      data,
      [
        (d) => {
          if (sortBy === 'last_name') {
            return d[sortBy].toUpperCase();
          }
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    if (isGettingStudentList) {
      return <TableLoader />;
    }

    return data.length > 0 ? (
      <>
        {this.renderSummaryTableHeader()}
        <TableContentWrapper>
          {filterActivitySummaryData.map(this.renderSumaryTableContent)}
        </TableContentWrapper>
      </>
    ) : (
      <NoContent>No Student</NoContent>
    );
  }
}

DashboardStudentActivitySummaryReport.propTypes = {
  lessonList: PropTypes.array,
  skillByLessonId: PropTypes.object,
  examsScoreSummary: PropTypes.array,
  studentList: PropTypes.array,
  isGettingStudentList: PropTypes.bool,
  courseDetail: PropTypes.object,
};
