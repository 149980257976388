import styled from 'styled-components';

// comment
export const SectionSelectWrap = styled.div`
  font-size: 13px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  .section-select-container {
    font-size: ${props => props.theme.fontSize.MediumFontSize};
  }
  .section-select__control {
    border: none;  
    cursor: pointer;
    min-height: 1.5em;
  }
  .section-select__control--is-focused {
    box-shadow: none;
  }
  .section-select__indicator-separator {
    display: none;
  }
  .section-select__indicator {
    padding: 4px;
    color: ${props => props.theme.colorStyled.ColorPrimary};
    svg {
      width: 1.125em !important;
      height: 1.125em !important;
    }
  }
  .section-select__option--is-focused {
    background-color: #bebebe;
    color: ${props => props.theme.colorStyled.ColorWhite};
  }
  .section-select__value-container {
    > div {
      white-space: normal;
      &:last-child {
        margin: 0;
      }
    }
  }
  .section-select__menu {
    top: 20px;
  }
  .section-select__single-value {
    color: ${props => props.theme.colorStyled.ColorPoint};
    position: relative;
    transform: none;
  }
  .section-select__input {
    color: transparent;
  }
`;

export const SectionBarCodeIcon = styled.i`
  font-size: ${props => props.theme.fontSize.XLargeFontSize};
  color: ${props => props.theme.colorStyled.ColorPrimaryBlue};
  margin-left: .5em;
  height: max-content;
`;

export const SectionCodeWrap = styled.div``;

export const SectionCodeTitle = styled.div`
  margin-bottom: 1em;
  text-align: center;
  font-size: ${props => props.theme.fontSize.HeadingH5FontSize};
`;

export const SectionCodeContent = styled.div`
  margin: 1em auto;
  text-align: center;
  padding: 1em .5em;
  width: 23em;
  border-radius: 1em;
  background: #f8f9fa;
  font-size: ${props => props.theme.fontSize.HeadingH4FontSize};
`;

export const SectionCodeDescription = styled.div`
  span {
    font-family: 'MontserratSemiBold';
    line-height: 2.5em;
  }
  p {
    color: ${props => props.theme.colorStyled.ColorDoveGray};
  }
`;

export const SectionCodeTabWrap = styled.div`
  .react-tabs__tab-list {
    display: flex;
    margin-bottom: 2em;
  }

  .react-tabs__tab {
    flex: 1;
    text-align: center;
    padding: .5em 1em;
    border-bottom: 1px solid #aeaeae;
    cursor: pointer;
    background: #eee;
  }
  .react-tabs__tab.react-tabs__tab--selected {
    border: 1px solid #aeaeae;
    border-radius: .5em .5em 0em 0em;
    background: #fff;
  }
`;
