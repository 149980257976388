import { getLearnositySkills } from '@apis/practice';
import { useCurrentSubject } from '@components/Component/Lesson/LessonContainer/hooks/query';
import { SkillRecord } from '@components/Component/Lesson/LessonContainer/Types';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

export type SkillJsonRecord = {
  skillName: string;
  skillURL: string;
  categoryName: string;
};

// any extra skills files should be added here
export const SKILLS_FILES = {
  Algebra_1: 'Algebra_1.json',
  English_9: 'Ninth_grade_language_arts.json',
  English_10: 'Tenth_grade_language_arts.json',
  English_1: 'Ninth_grade_language_arts.json',
};

export const fetchSkillsJSON = async (skillFileName) =>
  fetch(`/shared/${skillFileName}`)
    .then((res) => res.json())
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));

export const useJsonSkills = (givenSubject = null) => {
  const currentSubject = useCurrentSubject();
  const subject = givenSubject || currentSubject;
  const query = useQuery<Array<SkillJsonRecord>>(
    ['JSON Skills', subject?.slug],
    async () => {
      const res = await fetchSkillsJSON(SKILLS_FILES[subject?.slug ?? '']);
      return res;
    },
    {
      enabled: !!subject?.slug,
      suspense: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );

  return query;
};

export const useSkills = (subjectId?: string) => {
  const { subjectId: paramSubjectId } = useParams();
  const finalSubjectId = subjectId || paramSubjectId;

  if (!finalSubjectId) {
    console.error('No subject ID provided to useSkills hook');
  }

  const query = useQuery<SkillRecord[]>(
    ['Learnosity skills', finalSubjectId],
    async () => {
      const res = await getLearnositySkills(finalSubjectId);
      return res.response.data;
    },
    {
      enabled: !!finalSubjectId,
      suspense: true,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );
  return query;
};
