import InformationCard from '@components/Component/InformationCard';
import React from 'react';

const imgUrl = '/images/notInitialized.svg';

const NoActivityCard = () => (
  <InformationCard
    extraStyle={{
      width: '100%',
      height: '75%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    img={imgUrl}
    title="No review score yet"
    description="You can check your scores after completing a practice."
  />
);

export default NoActivityCard;
