import styled, { css } from 'styled-components';

export const GoBackBtn = styled.div`
    a {
        display: flex;
        align-items: center;
        color: #4786FC;
        cursor: pointer;
        text-align: left;
        text-align: left;
        font: normal normal medium 12px/15px Montserrat;
        margin: 20px;
        gap: 10px;
    }
    
`;

export const ReportLink = styled.div`
    a {
        display: flex;
        align-items: center;
        color: #4786FC;
        cursor: pointer;
        text-align: left;
        text-align: left;
        margin: 20px;
        gap: 10px;
        text-decoration: underline;
    }
}
`
