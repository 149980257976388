/* eslint-disable import/prefer-default-export */
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getSectionLessonSkillLevels } from '@apis/sections';

type sectionLessonSkillLevels = {
  lesson: number;
  section: number;
  mean_skill_level: number;
  per_skill: {
    [key: string]: number;
  };
};

export const useGetSectionLessonSkillLevels = () => {
  const { sectionId, lessonId } = useParams();
  const res = useQuery(
    ['sectionLessonMeanAndSkillLevels', sectionId, lessonId],
    async () => {
      const response = (await getSectionLessonSkillLevels(
        sectionId,
        lessonId,
      )) as unknown as {
        response: {
          data: sectionLessonSkillLevels;
        };
      };
      return response?.response?.data || ([] as any);
    },
    {
      enabled: !!sectionId && !!lessonId,
      staleTime: 1000 * 60 * 60,
      suspense: true,
      refetchOnMount: 'always',
    },
  );
  return res;
};
