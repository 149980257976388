import Box from '@components/Atoms/Box';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { toast } from 'react-toastify';
import {
  useSelectedQuestions,
  useSelectedType,
  useShowPreview,
} from '@components/QuestionSelector/QuestionSelector.provider';
import ShowIf from '@components/Atoms/ShowIf';
import {
  useLessonReview,
  useNavigateToLessonReview,
  usePostLearnosityLessonReview,
} from '../hooks';

const Footer = () => {
  const ctx = useSelectedQuestions();
  const [type] = useSelectedType();
  const [showPreview, setShowPreview] = useShowPreview();
  const navigateToLessonReview = useNavigateToLessonReview();
  const mutatePostLearnosityLessonReview = usePostLearnosityLessonReview();
  const { remove } = useLessonReview();

  const handelSave = () =>
    mutatePostLearnosityLessonReview.mutate(
      {
        references: ctx.selectedQuestions,
        type,
      },
      {
        onSuccess: () => {
          toast.success('Questions saved successfully');
          remove();
          navigateToLessonReview();
        },
        onError: (error: any) => {
          if (error?.response?.status === 403) {
            toast.error("you don't have permission to execute this action");
          } else {
            toast.error('Something went wrong');
          }
        },
      },
    );

  return (
    <Box display="flex" alignItems="center" gap={1} py={1} px={3}>
      <ShowIf If={showPreview}>
        <Button variant="text" onClick={() => setShowPreview(false)}>
          Back
        </Button>
      </ShowIf>
      <ShowIf If={!showPreview}>
        <Button variant="text" onClick={() => navigateToLessonReview()}>
          Cancel
        </Button>
      </ShowIf>
      <ShowIf If={!showPreview}>
        <Button
          disabled={!ctx.selectedQuestions.length}
          color="primary"
          variant="contained"
          onClick={() => setShowPreview(true)}
        >
          preview
        </Button>
      </ShowIf>
      <ShowIf If={showPreview}>
        <Button
          color="primary"
          variant="contained"
          endIcon={
            mutatePostLearnosityLessonReview.isLoading ? (
              <CircularProgress size={20} sx={{ color: 'white' }} />
            ) : null
          }
          onClick={() => handelSave()}
        >
          Save Changes
        </Button>
      </ShowIf>
    </Box>
  );
};

export default Footer;
