import {
  call, put, takeLeading, select,
} from 'redux-saga/effects';
import * as SectionAPI from '../../apis/sections';
import {
  SECTION_SKILLS_GET,
  actions as sectionSkillsActions,
} from '../../reducers/sectionSkills';
import { selectors as userSelectors } from '../../reducers/user';
import { ROLE_TYPE } from '../../utils/enums';

/**
 * ! Need update callback
 */
function* sectionSkillsGet({ sectionId, lessonId, studentId }) {
  const role = yield select(userSelectors.getUserRole);
  const api = role !== ROLE_TYPE.STUDENT ? SectionAPI.getSectionSkills : SectionAPI.getSectionSkillsUser;

  const { response, error } = yield call(api, sectionId, lessonId, studentId);

  if (response) {
    const { data } = response;
    yield put(sectionSkillsActions.sectionSkillsGetSuccess(data));
  } else {
    yield put(sectionSkillsActions.sectionSkillsGetFail(error));
  }
}

export default function* sectionSkillsSaga() {
  yield takeLeading(SECTION_SKILLS_GET, sectionSkillsGet);
}
