import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import _isEqual from 'lodash/isEqual';
import moment from 'moment';
import _chunk from 'lodash/chunk';
import {
  MonthWrapper,
  MonthRow,
  WeekRow,
  TwoWeekWrapper,
  TwoWeekRow,
  MonthHeaderRow,
  MonthHeaderItem,
  MonthHeaderItemContent,
  CalendarViewWrapper,
} from './Calendar.style';
import {
  MonthItem,
  TwoWeekItem,
} from '../../containers/ScheduleCalendar/CalendarItemComponents';

const DAYS_IN_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class CombinedCalendar extends PureComponent {
  state = {
    events: {},
    today: moment().startOf('day')
  };

  componentDidMount() {
    this.transformEvent();
  }

  componentDidUpdate(prevProps) {
    const { events: prevEvents } = prevProps;
    const { events, updating } = this.props;
    if (!_isEqual(prevEvents, events) || updating) {
      this.transformEvent();
    }
  }

  getRange(date) {
    let start;
    let end;
    if (this.props.activeView === 'week' || this.props.activeView === 'two-week') {
      start = moment(date).startOf('week');
      end = moment(date).endOf('week');
    } else if (this.props.activeView === 'month') {
      start = moment(date).startOf('month').startOf('week');
      end = moment(date).endOf('month').endOf('week');
    }

    const days = [];
    const current = start;

    this.props.activeView === 'two-week' ? end.add(1, 'week') : null;

    while (current <= end) {
      days.push(current.toDate());
      current.add(1, 'day');
    }

    return days;
  }

  transformEvent = () => {
    const transformEvents = this.props.events.reduce((acc, event) => {
      const dateKey = moment(event.date, 'YYYY-MM-DD').format('DDMMYYYY');

      if (!acc[dateKey]) acc[dateKey] = [];
      acc[dateKey].push(event);

      return acc;
    }, {});

    this.setState({
      events: transformEvents,
    });
  };

  renderSingleDay = (day) => {
    const daymm = moment(day);
    const key = daymm.format('DDMMYYYY');
    const isToday = daymm.isSame(this.state.today, 'day');
    const { events } = this.state;
    const eventList = events[key] || [];
    const isDisabled = daymm.isBefore(this.state.today);

    return this.props.activeView === 'month' ? (
      <MonthItem
        date={daymm}
        key={key}
        isToday={isToday}
        eventList={eventList}
        isDisabled={isDisabled}
      />
    ) : (
      <TwoWeekItem
        date={daymm}
        key={key}
        isToday={isToday}
        eventList={eventList}
        isDisabled={isDisabled}
      />
    );
  };

  renderWholeWeek = (week, index) => {
    const key = index + this.props.activeView;
    return (
      this.props.activeView === 'month' ? (
        <MonthRow key={key}>{week.map(this.renderSingleDay)}</MonthRow>
      ) : this.props.activeView === 'week' ? (
        <WeekRow key={key}>{week.map(this.renderSingleDay)}</WeekRow>
      ) : (
        <TwoWeekRow key={key}>{week.map(this.renderSingleDay)}</TwoWeekRow>
      )
    )
  }

  renderDayInWeek = (day) => (
    <MonthHeaderItem key={day}>
      <MonthHeaderItemContent>{day}</MonthHeaderItemContent>
    </MonthHeaderItem>
  );

  renderMonthHeader = () => (
    <MonthHeaderRow>{DAYS_IN_WEEK.map(this.renderDayInWeek)}</MonthHeaderRow>
  );

  render() {
    const range = this.getRange(this.props.date);
    const weeks = _chunk(range, 7);

    return (
      <>
        {this.renderMonthHeader()}
        <CalendarViewWrapper aria="week-wrap">
          {this.props.activeView === 'month' ? (
            <MonthWrapper aria="month-wrap">
              {weeks.map(this.renderWholeWeek)}
            </MonthWrapper>
          ) : this.props.activeView === 'week' ? (
            <TwoWeekWrapper>{weeks.map(this.renderWholeWeek)}</TwoWeekWrapper>
          ) : (
            <TwoWeekWrapper aria="TwoWeek-wrap">
              {weeks.map(this.renderWholeWeek)}
            </TwoWeekWrapper>
          )}
        </CalendarViewWrapper>
      </>
    );
  }
}

CombinedCalendar.propTypes = {
  events: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  date: PropTypes.any,
  activeView: PropTypes.string,
};

export default CombinedCalendar;
