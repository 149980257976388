import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import DashboardExamScoreReportTable from '../../components/DashboardExamScoreReport/DashboardExamScoreReportTable';
import { selectors as SectionStudent } from '../../reducers/sectionStudent';
import { selectors as SectionsSelectors } from '../../reducers/sections';
import { selectors as SectionExamSelectors, actions as SectionExamActions } from '../../reducers/sectionExam';
import { selectors as UserSelectors } from '../../reducers/user';
const mapStateToProps = (state, { match }) => {
  const { sectionId = '', courseId = '' } = match.params;
  const gradeLevelValue = SectionsSelectors.getGradeLevelValue(state, sectionId);
  const sectionDetail = SectionsSelectors.getSectionListById(state, sectionId);

  if (_isEmpty(sectionDetail)) {
    return {};
  }

  const subjects = SectionsSelectors.getSubjectFromEstimateScore(state);
  const examSessionScores = SectionsSelectors.examsScoreObject(state);

  return {
    estimateDelta: SectionsSelectors.getDeltaOfEstimateScore(state, sectionId),
    estimateHighScore: SectionsSelectors.getHighScoreOfEstimateScore(state, sectionId),
    estimateLowScore: SectionsSelectors.getLowScoreOfEstimateScore(state, sectionId),
    estimateMaxScore: SectionsSelectors.getMaxScoreOfEstimateScore(state, sectionId),
    estimateScore: SectionsSelectors.estimateScore(state),
    examSessionScores,
    examSessions: SectionExamSelectors.getExamSessionList(state),
    gradeLevelDelta: SectionsSelectors.getGradeLevelDelta(state, sectionId),
    gradeLevelValue,
    isGettingSectionExam: SectionExamSelectors.isGettingSectionExam(state),
    isGettingStudentList: SectionStudent.isGettingStudentList(state),
    isUpcomingExam: SectionExamSelectors.isUpcomingExam(state),
    lexileValue: SectionsSelectors.getLexileValue(state, gradeLevelValue),
    sectionDetail,
    studentList: SectionStudent.getStudentListWithFilter(state, sectionId),
    subjects,
    userRole: UserSelectors.getUserRole(state),
    hasExamV2Access: SectionExamSelectors.getExamV2Access(state, courseId, sectionId),
  };
};
const mapDispatchToProps = { fetchExamV2Access: SectionExamActions.fetchExamV2Access, };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardExamScoreReportTable),
);
