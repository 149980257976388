import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import InviteTeacherTable from '../../components/CourseManagementTeachers/InviteTeacherTable';
import { selectors as SectionSelectors } from '../../reducers/sections';
import {
  selectors as UserSelectors,
} from '../../reducers/user';

const mapStateToProps = (state, { location }) => {
  const sectionList = SectionSelectors.getSectionList(state);
  const searchParams = new URLSearchParams(location.search);
  const isOpenInviteTeacher = !!searchParams.get('isOpenInviteTeacher');
  const isInRegisterFlow = isOpenInviteTeacher;

  return {
    isInRegisterFlow,
    sectionList,
    subscriptionData: UserSelectors.getSubscriptionData(state),
  };
};

export default withRouter(connect(mapStateToProps)(InviteTeacherTable));
