import styled, { css } from 'styled-components';

export const TrainingVideoWrap = styled.div`
  width: 100%;
  height: calc(100% - 4em);
  padding: 1.5em;
  .full-screen-outer-button-wrapper {
    margin-top: 10px;
  }
  .full-screen-button-wrapper {
    position: fixed;  
    top: 0.8em;
    left: 0.5em;
    z-index: 999;
  }
`;

export const TraningVideoName = styled.div`
  margin-bottom: 1em;
  font-family: 'MontserratSemiBold';
  font-size: ${props => props.theme.fontSize.LargerFontSize};
`;

export const ToggleFullScreenButton = styled.button`
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  width: fit-content;
  color: white;
  border: solid 0px transparent;
  background-color: ${(props) => '#FF9E16'};
  border-radius: 6px;
  overflow: hidden;
`;

export const VideoContent = styled.div`
  min-height: 20em;
  height: calc(100% - 2em);
  background-color: #dfe8ee;
   ${(props) => props.isFullScreen
    && css`
      position: fixed;
      top: 0px !important;
      left: 0px !important;
      height: fill-available;
      z-index: 10;
      width: 100% !important;
      overflow: hidden;
    `}
`;
