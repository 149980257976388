import React from 'react';
import { Button } from '../Generals/stylesheets/Button.style';
import { useNavigateToPractice } from './hooks';
import useLearnosityQuestionCT from '../../utils/hooks/useLearnosityQuestionCT';
import { useIsTeacher } from '../../reducers/user/hooks';

const StandardsPracticeEnglishStartButton = ({ lessonDetail }) => {
  const isTeacher = useIsTeacher();
  const goToPractice = useNavigateToPractice('en');
  const { hasSpanishQuestions } = useLearnosityQuestionCT({
    lessonDetail,
  });

  if (isTeacher) {
    return null;
  }
  return (
    <Button green onClick={goToPractice}>
      {hasSpanishQuestions ? 'Practice in English (primary questions)' : 'Start Practice'}
    </Button>
  );
};

export default StandardsPracticeEnglishStartButton;
