import { connect } from 'react-redux';
import _get from 'lodash/get';
import DashboardExamScoreReport from '../../components/DashboardExamScoreReport';
import { actions as SectionStudentActions } from '../../reducers/sectionStudent';
import { actions as SectionsExamActions } from '../../reducers/sectionExam';
import { selectors as CourseSelectors } from '../../reducers/courses';
import { selectors as LeftSideBarSelectors } from '../../reducers/leftSideBar';
import {
  actions as SectionsActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';

const mapStateToProps = (state, { match }) => {
  const courseId = _get(match, 'params.courseId', '');
  const sectionId = _get(match, 'params.sectionId', null);
  const courseDetail = CourseSelectors.getCourseDetailById(state, courseId);
  const sectionDetail = SectionSelectors.getSectionListById(state, sectionId);

  return {
    sectionId,
    courseDetail,
    sectionDetail,
    isSideBarClose: LeftSideBarSelectors.getSideBar(state),
  };
};

const mapDispatchToProps = {
  sectionsExamsScoresGet: SectionsActions.sectionsExamsScoresGet,
  sectionsStudentGet: SectionStudentActions.sectionsStudentGet,
  sectionExamGet: SectionsExamActions.sectionExamGet,
  sectionsEstimatedGet: SectionsActions.sectionsEstimatedGet,
};
export default connect(mapStateToProps, mapDispatchToProps)(DashboardExamScoreReport);
