import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import {
  SectionCodeWrap,
  SectionCodeTitle,
  SectionCodeContent,
  SectionCodeDescription,
  SectionCodeTabWrap,
} from './SectionSelect.style';

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '30px',
    width: 'calc(100% - 30px)',
    maxWidth: '450px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '10px',
    border: 'none',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
};

export default class SectionCode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    currentSection: PropTypes.shape(),
  };

  static defaultProps = {
    currentSection: {},
  };

  render() {
    const { isOpen, onClose, currentSection } = this.props;
    const { code: codeList = [], teacher_code: teacherCode } = currentSection;
    const [codeObj = {}] = codeList;
    const { code = 'N/A' } = codeObj;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        style={modalStyles}
        ariaHideApp={false}
      >
        <Tabs>
          <TabList>
            <Tab />
            <Tab />
          </TabList>
          <TabPanel>
            <SectionCodeWrap>
              <SectionCodeTitle>Your Section Code</SectionCodeTitle>
              <SectionCodeContent>{code}</SectionCodeContent>
              <SectionCodeDescription>
                <p>
                  Provide this code only to new students enrolling in the
                  course.
                </p>
              </SectionCodeDescription>
            </SectionCodeWrap>
          </TabPanel>
          <TabPanel>
            <SectionCodeWrap>
              <SectionCodeTitle>Your Section Code</SectionCodeTitle>
              <SectionCodeContent>{teacherCode}</SectionCodeContent>
              <SectionCodeDescription>
                <p>
                  Provide this code only to new teachers enrolling in the
                  course.
                </p>
              </SectionCodeDescription>
            </SectionCodeWrap>
          </TabPanel>
        </Tabs>
      </Modal>
    );
  }
}
