import styled, { css } from 'styled-components';

export const GroupActivityWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  ${({ activityStarted }) =>
    activityStarted &&
    css`
      margin-bottom: 1.5em;
    `}
`;

export const DownloadIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const GroupActivityCardWrapper = styled.div`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #0000000d;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'MontserratRegular';

  @media (max-width: 480px) {
    flex-direction: column;
  }

  @media (max-width: 430px) {
    .activity-contnet-header-wrapper > div:first-child ~ div {
      align-items: flex-start !important;
      padding-top: 15px;
    }
  }

  .activity-icon-wrapper {
    width: 20%;
    min-width: 60px;
    max-width: 150px;
    img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  }
  .activity-content-wrapper {
    flex-direction: column;
    display: flex;
    flex: 1;

    .activity-contnet-header-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .question-number,
      .duration {
        font-weight: 800;
        color: #000;
      }
    }
    .activity-contnet-header-wrapper > div:first-child {
      width: 50%;
      min-width: 130px;
      margin-bottom: 15px;
      flex: 1;
    }
    .activity-contnet-header-wrapper div:first-child > span:first-child {
      text-align: center;
      letter-spacing: 0px;
      color: #7e7f83;
    }
    .activity-contnet-header-wrapper > div:first-child > span:first-child ~ p {
      margin-top: 6px;

      span {
        text-align: center;
        letter-spacing: 0px;
        color: #000000;
        font-size: 19px;
        font-weight: 700;
        line-height: 20px;
      }
    }
    .activity-contnet-header-wrapper > div:first-child ~ div {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-items: flex-end;
      min-width: 130px;
      margin-bottom: 15px;
      flex: 1;
    }
    .activity-contnet-header-wrapper > div:first-child ~ div > span:first-child {
      margin-bottom: 4px;
      text-align: right;
    }
    .actions-stack {
      width: 100%;
      display: flex;
      margin-top: 5px;
      align-items: center;
      flex-wrap: wrap;
    }
    .action-details-wrapper {
      width: 100%;
      margin-top: 19px;
    }
    .action-details-wrapper.record-attendance-details {
      margin-top: -10px !important;
      max-width: 350px;
    }
    .action-details-wrapper.record-attendance-details > div:first-child {
      padding-left: 2px;
      padding-right: 2px;
    }
    .with-left-margin {
      margin-left: 0.5rem;
    }
  }
`;

export const GroupActivityButton = styled.button`
  background-color: ${(props) =>
    props.isMajor ? props.bgColor || '#54CA39' : '#FFE2C0'};
  border-radius: 10px;
  width: fit-content;
  height: fit-content;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: ${(props) => (props.isMajor ? '1.05em' : '12px')};
  color: ${(props) => (props.isMajor ? 'white' : 'inherit')};
  border: 0px solid transparent;
  margin-bottom: 10px;
  font-weight: 300px;
  margin-right: 15px;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 10px;
    height: 10px;
    position: relative;
    top: 1px;
    left: 3px;
    transition: linear 120ms;
  }

  img.collapsed-icon {
    transform: rotate(180deg);
    transition: linear 120ms;
  }

  ${({ hasNoQuestions }) =>
    hasNoQuestions &&
    css`
      background-color: grey;
      color: white;

      &:hover {
        opacity: 1;
      }
    `}
`;

export const GroupActivityWorksheetWrapper = styled.div`
  width: 100%;
  & > h6 {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 20px;
  }

  & > h6 ~ a:hover {
    color: #f99d1d;
  }

  & > h6 ~ a {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: black;
    i {
      font-weight: 800;
      font-size: 17px;
      color: ${(props) => props.theme.colorStyled.ColorBgDefault};
      margin-right: 10px;
    }
  }
`;

export const GroupCreate = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 60px;
  width: 100%;
  @media (max-width: 768px) {
    margin-right: unset;
    flex-direction: column;
  }
`;
export const GroupBodyContainer = styled.div`
  overflow: auto;
  margin-top: 2rem;
`;
