import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import md5 from 'md5';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import LeftSideBarCourseManagement from '../../containers/LeftSideBar/LeftSideBarCourseManagement';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import CourseEditForm from '../../containers/CourseDetail/CourseEditForm';
import MainContent from '../../containers/MainContent';
import ConfirmModal from '../ConfirmModal';
import { PrimaryWrap } from '../Generals/stylesheets/General.style';
import {
  UserProfileView,
  UserProfileGroup,
  UserProfileInfo,
  UserLabel,
  UserContent,
  UserBackgroundHeaderWrap,
  UserImage,
  UserAvatarWrap,
  UserAvatar,
  UserDescription,
  UserProfileDetailWrap,
  UserProfileGroupContent,
  UserOrgazinationTitle,
  UserOrgazinationItem,
  ProfileContentWrapper,
} from '../Profile/Profile.style';
import { CourseActionGroup } from './CourseDetail.style';
import { Button } from '../Generals/stylesheets/Button.style';

const scrollStyle = {
  height: '100%',
  width: '100%',
};

class CourseDetail extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
      isUpload: false,
      isDeactivate: false,
    };
  }

  static propTypes = {
    courseDetail: PropTypes.shape(),
    courseUpdate: PropTypes.func,
    courseDeactivate: PropTypes.func,
    getCurriculumList: PropTypes.func,
    getCurriculumStandardList: PropTypes.func,
    curriculum: PropTypes.array,
    courseStandard: PropTypes.object,
    isOwner: PropTypes.bool,
  };

  componentDidMount() {
    const { getCurriculumList, getCurriculumStandardList } = this.props;
    getCurriculumList();
    getCurriculumStandardList();
  }

  onModalOpen = () => {
    this.setState({
      isEditable: true,
    });
  };

  onModalClose = () => {
    this.setState({
      isEditable: false,
    });
  };

  onOpenConfirmDeactivateModal = () => {
    this.setState({
      isDeactivate: true,
    });
  };

  onCloseConfirmDeactivateModal = () => {
    this.setState({
      isDeactivate: false,
    });
  };

  onToggleUpload = () => {
    const { isUpload } = this.state;
    this.setState({
      isUpload: !isUpload,
    });
  };

  courseDeactivate = () => {
    const { courseDetail, courseDeactivate } = this.props;
    const { id } = courseDetail;
    courseDeactivate(id);
  };

  courseUpdate = (courseInfo) => {
    const { courseUpdate, courseDetail } = this.props;
    const { id } = courseDetail;

    courseUpdate(id, courseInfo);
    this.setState({
      isEditable: false,
    });
  };

  renderActionButtons = () => (
    <CourseActionGroup>
      <Button onClick={this.onOpenConfirmDeactivateModal}>Deactivate Course</Button>
    </CourseActionGroup>
  );

  renderCourseInfo = () => {
    const { courseDetail, curriculum, courseStandard } = this.props;
    const standardName = _get(courseStandard, 'name', '');
    const curriculumItem =
      curriculum.find((cur) => cur.id === courseDetail.curriculum) || '';
    return (
      <UserProfileGroup flexLeft>
        <UserProfileView>
          <UserProfileInfo>
            <UserLabel>Course name</UserLabel>
            <UserContent>{courseDetail.name}</UserContent>
          </UserProfileInfo>

          <UserProfileInfo>
            <UserLabel>Curriculum</UserLabel>
            <UserContent>{curriculumItem.name}</UserContent>
          </UserProfileInfo>

          <UserProfileInfo>
            <UserLabel>Standards</UserLabel>
            <UserContent>{standardName}</UserContent>
          </UserProfileInfo>
        </UserProfileView>
      </UserProfileGroup>
    );
  };

  renderCourseEdit = () => {
    const { isEditable } = this.state;
    const { courseDetail } = this.props;
    const {
      name: courseName = '',
      schoolName = '',
      country = '',
      district = '',
    } = courseDetail;

    const initialValues = {
      courseName,
      schoolName,
      country,
      district,
    };

    return (
      <CourseEditForm
        initialValues={initialValues}
        courseDetail={courseDetail}
        onSubmit={this.courseUpdate}
        onClose={this.onModalClose}
        isOpen={isEditable}
      />
    );
  };

  renderHeaderBackground = () => {
    const imageUrl = false;
    const { isUpload } = this.state;
    return (
      <UserBackgroundHeaderWrap haveImage={!imageUrl} isUpload={isUpload}>
        {imageUrl && <UserImage src="" />}
      </UserBackgroundHeaderWrap>
    );
  };

  renderUserAvatar = () => {
    const { courseDetail, isOwner } = this.props;
    const courseHash = md5(`${courseDetail.id}@chalktalk`);
    return (
      <UserAvatarWrap courseCustome>
        <UserAvatar>
          <img
            src={`https://secure.gravatar.com/avatar/${courseHash}?d=identicon&s=200`}
            alt=""
          />
        </UserAvatar>
        <UserDescription>
          <p>{courseDetail.name}</p>
        </UserDescription>
        {isOwner && this.renderActionButtons()}
      </UserAvatarWrap>
    );
  };

  renderOrgazinationItem = () => {
    const { courseDetail } = this.props;
    const { organization = {} } = courseDetail;
    const { name, district } = organization;

    return (
      <UserOrgazinationItem>
        <UserProfileInfo>
          <UserLabel>Name</UserLabel>
          <UserContent>{name}</UserContent>
        </UserProfileInfo>

        <UserProfileInfo>
          <UserLabel>District</UserLabel>
          <UserContent>
            {_isEmpty(district) ? 'Unknown' : `${district.name}, ${district.state}`}
          </UserContent>
        </UserProfileInfo>
      </UserOrgazinationItem>
    );
  };

  render() {
    const { isDeactivate } = this.state;
    return [
      <PrimaryWrap>
        <LeftSideBarCourseManagement pathName="/management/course" />
        <MainContent>
          <ShadowScrollbar autoHide style={scrollStyle}>
            {this.renderHeaderBackground()}
            <ProfileContentWrapper medium>
              {this.renderUserAvatar()}
              <UserProfileDetailWrap>
                {this.renderCourseInfo()}
                <UserProfileGroupContent>
                  <UserOrgazinationTitle>ORGANIZATION INFO</UserOrgazinationTitle>
                  {this.renderOrgazinationItem()}
                </UserProfileGroupContent>
              </UserProfileDetailWrap>
            </ProfileContentWrapper>
          </ShadowScrollbar>
        </MainContent>
      </PrimaryWrap>,
      <ConfirmModal
        content="Are you sure?"
        isOpen={isDeactivate}
        onConfirm={this.courseDeactivate}
        onClose={this.onCloseConfirmDeactivateModal}
      />,
    ];
  }
}

export default CourseDetail;
