import React, { useEffect, useImperativeHandle } from 'react';
import { createReviewQuestionSignatureRequest } from '../../AssessmentServiceAPI';
import { useGetSignature } from '../../Hooks/Query';
import { AuthorWidgetWrapper } from '../AuthorHarness/styles';

const containerId = 'learnosity_preview_question_container';
const PreviewQuestion = ({ widgetRef, eventHandlers, referenceId }) => {
  const mainRef = React.useRef<HTMLDivElement>(null);
  const [widgetInstance, setWidgetInstance] = React.useState(undefined);
  const { data: signature } = useGetSignature(
    createReviewQuestionSignatureRequest(referenceId),
    'author',
    {
      enabled: !widgetInstance && !!(window as any).LearnosityAuthor,
    },
  );
  useImperativeHandle(widgetRef, () => ({ widgetInstance }), [widgetInstance]);

  useEffect(() => {
    mainRef.current!.appendChild(document.createElement('div')).id = containerId;
  }, []);

  useEffect(() => {
    if (!widgetInstance) {
      return;
    }
    widgetInstance.navigate(`items/${referenceId}`);
  }, [referenceId]);

  useEffect(() => {
    const isElementOnTheDom = !!document.getElementById(containerId);
    if (Object.keys(signature!).length && !widgetInstance && isElementOnTheDom) {
      const author = (window as any).LearnosityAuthor?.init(
        signature,
        {
          readyListener: () => {
            console.log('Authoring API has been initialized');
            setWidgetInstance(author);
          },
          errorListener: (error: any) => {
            console.error('Error initializing Authoring API', error);
          },
        },
        containerId,
      );
      if (eventHandlers) {
        eventHandlers.forEach(({ name, callback }) => {
          author.on(name, (...args: any[]) => {
            callback(author, args);
          });
        });
      }
    }
    return () => {
      if (widgetInstance && isElementOnTheDom) {
        widgetInstance.destroy(); // TODO this is not correct
        setWidgetInstance(undefined);
        setWidget(undefined);
      }
    };
  }, [signature, widgetInstance, (window as any).LearnosityAuthor]);
  return (
    // TODO wrap in styles
    <AuthorWidgetWrapper ref={mainRef} />
  );
};
export default PreviewQuestion;
