import moment from 'moment';
import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import jwtDecode from 'jwt-decode';
import { DATE_FORMAT } from './constants';
import _isEqual from 'lodash/isEqual';


export const formatDate = date => moment(date).format(DATE_FORMAT.DATE);
export const formatDateAndTime = date => moment(date).format(DATE_FORMAT.FULL_V2);

export const formatDateByTime = (date, time = 'FULL') => moment(date).format(DATE_FORMAT[time]);

export const splitLessonTree = (unitObj, lessonArr = []) => {
  const newLessonArr = lessonArr.concat(unitObj.lessons);
  const { children = [] } = unitObj;
  const isEmpty = children.length === 0;

  if (isEmpty) {
    return newLessonArr;
  }
  return splitLessonChildren(children, newLessonArr);
};

export const splitLessonChildren = (unitArr, finalLessonArr) => {
  const lessons = _reduce(unitArr, (prev, unit) => splitLessonTree(unit, prev), []);

  return [
    ...finalLessonArr,
    ...lessons,
  ];
};

const splitUnitTree = (unitObj, unitsArr = []) => {
  unitsArr.push(unitObj);
  const { children = [] } = unitObj;
  const isEmpty = children.length === 0;

  if (isEmpty) {
    return unitsArr;
  }
  return splitUnitChildren(children, unitsArr);
};

export const splitUnitChildren = (unitArr, finalUnitArr) => {
  const units = _reduce(unitArr, (prev, unit) => splitUnitTree(unit, prev), []);

  return [
    ...finalUnitArr,
    ...units,
  ];
};

export const transformGrinInAnswer = (value = '') => `${value.replace(/[^\w\s_.\/]/g, '')}____`.substring(0, 4);

export const transformGridInAnsToInt = (value) => {
  return getNumericVal(value.replace(/_/g, ''))
}

export const gridinAnswersEqual = (answer1, answer2) => _isEqual(transformGridInAnsToInt(answer1), transformGridInAnsToInt(answer2))


export const getNumericVal = (val) => {
  try {
   return parseFloat(eval(val))
  } catch {
    try{
     return parseFloat(val)
    } catch {
    return false
  }
  };
}


export const isNumeric = (val) => {
  try {
    eval(val)
    return true
  } catch {
    try{
      parseFloat(val)
      return true;
    } catch {
    return false
  }
  };
}

export const isNumerciallyEqual = (value1, value2) => {
  if(isNumeric(value1) && isNumeric(value2)){
    return _isEqual(getNumericVal(value1), getNumericVal(value2));
  }
  else
    return false;
}

export const getExpiredTimeOfToken = (token) => {
  try {
    const payload = jwtDecode(token);
    const expiredTime = _get(payload, 'exp', 0);

    return moment(expiredTime * 1000).diff(moment());
  } catch (error) {
    return 0;
  }
};
