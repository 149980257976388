import styled from 'styled-components';

export const SkipWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4A86E8;
    cursor: pointer;
    a{
        font-size: 8.0pt;
        font-family: 'Montserrat',Arial;
    }
`;
