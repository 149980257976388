/* eslint-disable jsx-a11y/accessible-emoji */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  RegisterItemWrapper,
  RegisterItem,
  RegisterWrap,
  ButtonStyled,
  RegisterMessageContent,
  RegisterCodeStyled,
  RegisterAction,
  RegisterFormTitle,
  RegisterFormWrapper,
  RegisterButtonRight,
  RegisterTitle,
  RegisterMessageContentError,
} from '../StudentRegister/StudentRegister.style';

class NewStudentSectionEnroll extends PureComponent {
  state = {
    sectionCode: '',
  }

  handleSectionCodeChanged = ({ target }) => {
    this.setState({
      sectionCode: target.value,
    });
  }

  sectionEnrollment = () => {
    const { sectionCode } = this.state;
    const { sectionRequestEnrollment } = this.props;

    sectionRequestEnrollment(sectionCode.trim());
  }

  renderRequestView = () => {
    const { sectionCode } = this.state;
    const { isRequestingEnrollment, errorMsg } = this.props;
    return (
      <RegisterFormWrapper>
        <RegisterFormTitle>
          <h1>Join section</h1>
          <h3>
            Enter section code provided to you to join others in the course.
          </h3>
        </RegisterFormTitle>
        <RegisterWrap isLoading>
          <RegisterMessageContentError>
            <input
              type="text"
              value={sectionCode}
              onChange={this.handleSectionCodeChanged}
              placeholder="Enter section code"
            />
            {errorMsg && (
              <p>
                <br />
                <br />
                Invalid section code. Please, check your code and try again
              </p>
            )}
          </RegisterMessageContentError>

          <RegisterAction>
            <ButtonStyled
              type="submit"
              onClick={this.sectionEnrollment}
              disabled={isRequestingEnrollment}
            >
              {isRequestingEnrollment ? 'Loading' : 'Submit'}
            </ButtonStyled>

          </RegisterAction>

        </RegisterWrap>
      </RegisterFormWrapper>
    );
  }

  renderRequestedSuccess = () => {
    const { sectionData } = this.props;
    const { name = '' } = sectionData;

    return (
      <RegisterWrap isLoading>
        <RegisterMessageContent>
          <h1>&#127881;</h1>
          <p>
            You have successfully joined
            <RegisterCodeStyled>
              {name}
            </RegisterCodeStyled>
          </p>
        </RegisterMessageContent>
        <RegisterAction large>
          <Link to="/">
            <ButtonStyled>Go to dashboard</ButtonStyled>
          </Link>
        </RegisterAction>
      </RegisterWrap>
    );
  }

  render() {
    const { isRequestedEnrollment, logout } = this.props;
    return (
      <RegisterItemWrapper>
        <RegisterItem>
          <RegisterTitle>
            <img src="/images/bg-login.jpg" alt="logo" />
            <RegisterButtonRight>
              <ButtonStyled small onClick={logout}>Logout</ButtonStyled>
            </RegisterButtonRight>
          </RegisterTitle>

          {isRequestedEnrollment
            ? this.renderRequestedSuccess()
            : this.renderRequestView()}
        </RegisterItem>
      </RegisterItemWrapper>
    );
  }
}

NewStudentSectionEnroll.propTypes = {
  sectionRequestEnrollment: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isRequestedEnrollment: PropTypes.bool.isRequired,
  isRequestingEnrollment: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  sectionData: PropTypes.shape(),
};

export default NewStudentSectionEnroll;
