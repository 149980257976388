import { connect } from 'react-redux';
import JoinSectionModal from '../../components/Modals/JoinSectionModal';
import {
  actions as SectionActions,
  selectors as SectionSelectors,
} from '../../reducers/sections';
import {
  selectors as CourseSelectors,
} from '../../reducers/courses';
import {
  actions as AuthActions,
} from '../../reducers/auth';

const mapStateToProps = (state) => {
  const courseList = CourseSelectors.getCourseList(state);
  const shouldDisplayFreeOption = courseList.length === 0;

  return {
    isRequestedEnrollment: SectionSelectors.isRequestedEnrollment(state),
    isRequestingEnrollment: SectionSelectors.isRequestingEnrollment(state),
    errorMsg: SectionSelectors.getErrorMessage(state),
    sectionData: SectionSelectors.getCurrentSection(state),
    shouldDisplayFreeOption
  }
};

const mapDispatchToProps = {
  sectionRequestEnrollment: SectionActions.sectionRequestEnrollment,
  logout: AuthActions.logout,
  sectionSetDefaultEnrollment: SectionActions.sectionSetDefaultEnrollment,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinSectionModal);
