import React from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import {
  PracticeAnswerShortTextWrap,
  AnswerShortTextContent,
  AnswerShortTextCorrect,
  AnswerShortTextIncorrect,
  AnswerShortTextLabel,
  StudentAnswer,
} from './AnswerShortText.style';

const emptyFn = () => null;

/**
 * Handles answer type 'Short Text' for Practice
 */
class AnswerShortText extends React.Component {
  static propTypes = {
    /** callback to update store with the given answer inputted by user */
    selectAnswer: PropTypes.func,
    /** flag passed by review component to indicate displaying of results only */
    shouldDisplayAnswer: PropTypes.bool,
    /** score object from api to check correctness of results */
    score: PropTypes.object,
    /** holds the inputted value the student gave for current question */
    inputValue: PropTypes.string,
  };

  static defaultProps = {
    selectAnswer: emptyFn,
  };

  updateAnswer = ({ target }) => {
    const { selectAnswer } = this.props;
    selectAnswer(target.value);
  };

  renderTextContent = (shouldDisplayAnswer) => {
    const { choice_answer, isTeacher, inputValue } = this.props;
    if (shouldDisplayAnswer) {
      return (
        <AnswerShortTextLabel>
          <span>{isTeacher ? 'Answer: ' : 'You Answered: '}</span>
          {choice_answer ?? inputValue}
        </AnswerShortTextLabel>
      );
    }

    return (() => {
      return (
        <input
          value={this.props.inputValue}
          type="text"
          placeholder="Answer"
          onChange={this.updateAnswer}
          onKeyUp={this.updateAnswer}
        />
      );
    })();
  };

  renderIconResult = (score) => {
    const autoScore = score?.auto || 0;
    const autoMaxScore = score?.auto_max || 0;
    const zeroScore = autoScore === 0 && autoMaxScore === 0;

    const isCorrect =
      score?.result === 'Correct' || (!zeroScore && autoScore === autoMaxScore);

    return isCorrect ? (
      <AnswerShortTextCorrect className="chalktalk-correct" />
    ) : (
      <AnswerShortTextIncorrect className="chalktalk-close" />
    );
  };

  renderStudentsAnswer = (questionData, studet_id) => {
    const { userId } = this.props;
    if (userId == studet_id) return;
    const studentAnswer = questionData[studet_id];
    const studentAnswerText = studentAnswer.text_answer.replaceAll('_', '');
    return (
      <>
        <StudentAnswer>
          <div className="name"> {studentAnswer.studentName}'s answer is </div>
          <div className="answer"> {studentAnswerText} </div>
        </StudentAnswer>
      </>
    );
  };

  renderStudentsAnswers = () => {
    const { groupId, questionId } = this.props;
    let groupAnswers = JSON.parse(
      localStorage.getItem(`oga_practice_${groupId}_group__answers`) || '{}',
    );
    if (!_isEmpty(groupAnswers)) {
      const questionData = groupAnswers[groupId][questionId];
      const studentIds = Object.keys(questionData);
      if (studentIds.length > 0) {
        return (
          <>
            {studentIds.map((studet_id) =>
              this.renderStudentsAnswer(questionData, studet_id),
            )}
          </>
        );
      }
    }
  };

  render() {
    const { shouldDisplayAnswer, score, activity, isTeacher } = this.props;
    return (
      <>
        <PracticeAnswerShortTextWrap>
          <AnswerShortTextContent>
            {this.renderTextContent(shouldDisplayAnswer)}
          </AnswerShortTextContent>
          {!isTeacher && shouldDisplayAnswer && this.renderIconResult(score)}
        </PracticeAnswerShortTextWrap>
        {activity == 'online-group' && this.renderStudentsAnswers()}
      </>
    );
  }
}

export default AnswerShortText;
