import loadScript from 'simple-load-script';
import React, { createContext, useContext, useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';

const MathJaxContext = createContext(null);

const Provider = (props) => {
  const url = props.url || DEFAULT_URL;
  const options = props.options || DEFAULT_OPTIONS;

  const [MathJax, setMathJax] = useState(options);
  const [isMathJaxLoaded, setIsMathJaxLoaded] = useState(false);

  window.MathJax = MathJax;

  useEffect(() => {
    loadScript(url).then(onLoad).catch(onErr);
  }, [url]);

  function onLoad() {
    setMathJax(window.MathJax);
    setIsMathJaxLoaded(true);
    window.MathJax.Callback = function (args) {
      if (Array.isArray(args)) {
        if (args.length === 1 && typeof args[0] === 'function') {
          return args[0];
        } if (
          typeof args[0] === 'string'
          && args[1] instanceof Object
          && typeof args[1][args[0]] === 'function'
        ) {
          return Function.bind.apply(args[1][args[0]], args.slice(1));
        } if (typeof args[0] === 'function') {
          return Function.bind.apply(args[0], [window].concat(args.slice(1)));
        } if (typeof args[1] === 'function') {
          return Function.bind.apply(args[1], [args[0]].concat(args.slice(2)));
        }
      } else if (typeof args === 'function') {
        return args;
      }
      throw Error("Can't make callback from given data");
    };

    window.MathJax.Hub = {
      Queue() {
        for (let i = 0, m = arguments.length; i < m; i++) {
          // eslint-disable-next-line prefer-rest-params
          const fn = window.MathJax.Callback(arguments[i]);
          window.MathJax.startup.promise = window.MathJax.startup.promise.then(fn);
        }
        return window.MathJax.startup.promise;
      },
      Typeset(elements, callback) {
        let promise = window.MathJax.typesetPromise(elements);
        if (callback) {
          promise = promise.then(callback);
        }
        return promise;
      },
      getAllJax: () => window.MathJax.startup.document.getMathItemsWithin(document.body),
      Register: {
        MessageHook() {
          console.log('MessageHooks are not supported in version 3');
        },
        StartupHook() {
          console.log('StartupHooks are not supported in version 3');
        },
        LoadHook() {
          console.log('LoadHooks are not supported in version 3');
        },
      },
      Config() {
        console.log('MathJax configurations should be converted for version 3');
      },
    };
  }

  function onErr(error) {
    Sentry.withScope((scope) => {
      scope.setContext('Error Information', {
        message: error,
      });
      scope.setTag('exception', 'Script failed to load.');
      Sentry.captureMessage(error);
    });
  }

  return <MathJaxContext.Provider value={isMathJaxLoaded ? MathJax : null} {...props} />;
};

const DEFAULT_OPTIONS = {};
const DEFAULT_URL = 'https://cdn.jsdelivr.net/npm/mathjax@3.0.1/es5/tex-mml-chtml.js';

export const useMathJaxContext = () => useContext(MathJaxContext);

export default React.memo(Provider);
