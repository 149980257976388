import { connect } from "react-redux";
import GroupActivityQuestionItem from "../../components/GroupActivityContent/GroupActivityQuestionItem";
import {
  selectors as ActivitySelector,
  actions as ActivityActions,
} from "../../reducers/groupActivity";
import {
  actions as PracticeActions,
  selectors as PracticeSelectors,
} from "../../reducers/practice";
import { selectors as GroupActivitySelector } from "../../reducers/groupActivity";
import _get from "lodash/get";

export const mapStateToProps = (state, { questionSelected, match }) => {
  const { id } = questionSelected;
  const lessonId = _get(match, "params.lessonId", "");
  const searchParams = new URLSearchParams(window.location.search);
  const question = Number(searchParams.get('question'));

  const {
    groupActivity: { groupActivityInfo, updateInfo, questionAnsweredAlready },
  } = state;


  return {
    question: question || 0,
    answer: PracticeSelectors.getAnswerByQuestionId(state, id), // get answer
    isSubmittingAnswer: PracticeSelectors.isSubmittingAnswer(state),
    submitData: ActivitySelector.selectSubmitStatus(state),
    groupAnswers: ActivitySelector.selectGroupQuestionResponse(state),
    onlineActivityStatus: updateInfo?.group_status,
    groupActivityId: groupActivityInfo.id,
    groupStatus: updateInfo?.group_status, // duplicate with line 41
    groupActivityInfo,
    questionAnsweredAlready,
    individualQuestionSubmitted: ActivitySelector.individualQuestionSubmitted(state),
  };
};

const mapDispatchToProps = {
  chooseAnswer: PracticeActions.chooseAnswer,
  resetAnswers: PracticeActions.resetAnswers,
  answerGroupActivityQuestion: ActivityActions.answerGroupActivityQuestion,
  answerGroupActivityGroupQuestion:
    ActivityActions.answerGroupActivityGroupQuestion,
  individualGroupActivity: ActivityActions.updateIndividualGroupActivity,
  getOnlineActivityQuestions: ActivityActions.getOnlineActivityQuestions,
  submitScoreSummary: ActivityActions.submitScoreSummary,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupActivityQuestionItem);
