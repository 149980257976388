import { TreeView } from '@mui/x-tree-view/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ShowIf from '@components/Atoms/ShowIf/ShowIf';
import { Node } from './MultiSelectTree';
import Box from '../Box';

// Recursively traverse a node and its descendants
// We grab their id's and add/remove them to the array that represents the selected nodes
const selectNodeRecursive = (
  parentNode: Node,
  checked: boolean,
  selected: string[],
): string[] => {
  let updatedNodes = [...selected];

  // Add or remove the parent node if its a selectable node
  if (checked) {
    updatedNodes.push(parentNode.id);
  } else {
    updatedNodes = updatedNodes.filter((id) => id !== parentNode.id);
  }

  // Recursively handle children
  if (Array.isArray(parentNode.children)) {
    parentNode.children.forEach((childNode) => {
      updatedNodes = selectNodeRecursive(childNode, checked, updatedNodes);
    });
  }

  // return new array of selected nodes
  return updatedNodes;
};

const buildTree = (
  tree: Node[],
  selected: string[],
  setSelectedNode: (value: string[]) => void,
  searchText: string,
  multiple: boolean,
  indeterminate: string[],
) => {
  if (!tree?.length) {
    return null;
  }

  return tree.map((node) => {
    const { id, label, children, noCheckbox = false, noChip = false } = node;

    // get rendered children nodes
    const filteredChildren = children
      ? buildTree(
          children,
          selected,
          setSelectedNode,
          searchText,
          multiple,
          indeterminate,
        )
      : null;

    const labelMatchesSearch =
      !searchText || label.toLowerCase().includes(searchText.toLowerCase());
    const hasVisibleChildren = filteredChildren?.some(Boolean);

    // If the node doesn't match the search and doesn't have any visible children
    // we don't want to render it at all
    if (!labelMatchesSearch && !hasVisibleChildren) {
      return null;
    }

    // when a node is checked, we want to select all its children
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedSelectedNodes = selectNodeRecursive(node, e.target.checked, selected);
      setSelectedNode(updatedSelectedNodes);
    };

    // if its not multiple mode, we only want to select one node
    const handleLineClick = () => {
      if (noCheckbox || multiple || noChip) {
        return;
      }
      setSelectedNode([node.id]);
    };

    const isIndeterminate = () => {
      if (noChip) {
        return false;
      }

      return indeterminate?.includes(id);
    };

    const isChecked = () => {
      if (noChip) {
        return children?.every((child) => selected.includes(child.id));
      }

      return !isIndeterminate() && selected.includes(id);
    };

    return (
      <TreeItem
        key={id}
        nodeId={id}
        label={
          <Box sx={{ py: noCheckbox ? '0.5rem' : 0 }} key={id} onClick={handleLineClick}>
            <ShowIf If={multiple && !noCheckbox}>
              <Checkbox
                size="small"
                onClick={(e) => e.stopPropagation()}
                onChange={handleCheckboxChange}
                indeterminate={isIndeterminate()}
                checked={isChecked()}
              />
            </ShowIf>
            {label}
          </Box>
        }
      >
        {filteredChildren}
      </TreeItem>
    );
  });
};

const TreeViewSearch: React.FC<{
  tree: Node[];
  selected: string[];
  setSelectedNodes: (value: string[]) => void;
  searchText?: string;
  multiple: boolean;
  indeterminate?: string[];
}> = ({ tree, selected, setSelectedNodes, searchText = '', multiple, indeterminate }) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const treeNodes = useMemo(
    () =>
      buildTree(tree, selected, setSelectedNodes, searchText, multiple, indeterminate),
    [tree, selected, setSelectedNodes, searchText, multiple, indeterminate],
  );

  // Recursively traverse the tree and get all the node id's since
  // we need to expand all the nodes that have children
  const getNodeIdsRecursive = useCallback(
    (nodes: (React.JSX.Element | null)[]) =>
      nodes.reduce((acc, node) => {
        if (!node) {
          return acc;
        }
        if (node?.props?.children) {
          return [...acc, node.key, ...getNodeIdsRecursive(node.props.children)];
        }
        return [...acc, node.key];
      }, []),
    [],
  );

  useEffect(() => {
    if (searchText && treeNodes) {
      setExpanded(getNodeIdsRecursive(treeNodes));
    } else {
      setExpanded([]);
    }
    // We dont want a treeNode to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <>
      <ShowIf If={!!treeNodes && treeNodes?.length > 0}>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          expanded={expanded}
          onNodeToggle={(event, nodes) => setExpanded(nodes)}
        >
          {treeNodes}
        </TreeView>
      </ShowIf>
      <ShowIf If={!treeNodes || treeNodes?.length === 0}>
        <MenuItem key="no-options" value="no-options" dense disableGutters>
          -- No Options --
        </MenuItem>
      </ShowIf>
    </>
  );
};

export default TreeViewSearch;
