/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _get from 'lodash/get';
import _round from 'lodash/round';
import _orderBy from 'lodash/orderBy';
import moment from 'moment';
import ShadowScrollbar from '../Scrollbar/ShadowScrollbars';
import {
  LessonManagementBlock,
  LessonUnitPracticeWrap,
  LessonBlockTitle,
  LessonSection,
  LessonTableWrap,
  LessonAssignStatus,
  LessonBlockIcon,
  UnAssignButton,
} from '../LessonManagement/LessonManagement.style';
import { UnitDetailButtonGroup, UnitDetailScore } from './UnitPracticeDetail.style';
import {
  TableHeadWrapper,
  TableContentWrapper,
  TableContentItem,
} from '../Table/TableComponent.style';
import { DefaultContentWrap } from '../Generals/stylesheets/General.style';
import { TableHeader, TableContent } from '../Table/TableComponent';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import ColumnSize from './ColumnSize';
import { calcDurationToSecond } from '../../utils/commonUtils';
import { PRACTICE_TYPE, ROLE_TYPE } from '../../utils/enums';
import { Button } from '../Generals/stylesheets/Button.style';
import firstSort from '../../utils/firstSort';

const tableValues = [
  {
    uniqKey: 'last_name',
    value: 'Student Name',
  },
  {
    uniqKey: 'lastActivity',
    value: 'Last Activity',
  },
  {
    uniqKey: 'score',
    value: 'Score',
  },
  {
    uniqKey: 'timeOnTask',
    value: 'Time on Task',
  },
];

const scrollStyle = {
  height: 'calc(100vh - 6em)',
  width: '100%',
};

const ORDER_BY = {
  1: 'asc',
  [-1]: 'desc',
  ASC: 1,
  DESC: -1,
};

class UnitPracticeDetail extends PureComponent {
  state = {
    lessonId: '',
    sortBy: 'last_name',
    orderBy: ORDER_BY.ASC,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentDidUpdate(prevProps) {
    const { match, history, practiceId, isInitialized } = this.props;
    const prevUnitId = _get(prevProps, 'match.params.unitId');

    const { sectionId, subjectId, unitId, courseId } = match.params;

    if (prevProps.isInitialized !== isInitialized && isInitialized) {
      history.push(
        `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice/${practiceId}`,
      );
    }

    if (prevUnitId !== unitId && unitId) {
      this.getInitialData();
    }
  }

  renderPracticeCoverItem = (lesson) => {
    const { id, name } = lesson;
    return (
      <div key={id}>
        <p>{name}</p>
      </div>
    );
  };

  getInitialData = () => {
    const { userRole, match, getAllPracticeSessionActivities, lessonAssignmentDetail } =
      this.props;
    const { sectionId, unitId } = match.params;

    if (userRole === ROLE_TYPE.STUDENT) {
      getAllPracticeSessionActivities(sectionId, unitId);
    } else {
      lessonAssignmentDetail(sectionId, null, unitId);
    }
  };

  assignStudent = (student) => () => {
    const { assignLesson, match, currentUserId } = this.props;
    const { lessonId } = this.state;
    const { params } = match;
    const { sectionId, unitId } = params;
    const payload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUserId,
      assigned_to: student.id,
    };

    assignLesson(sectionId, payload);
  };

  unAssignStudent = (student) => () => {
    const { unAssignLesson, match, currentUserId } = this.props;
    const { lessonId } = this.state;
    const { params } = match;
    const { sectionId, unitId } = params;
    const payload = {
      section: sectionId,
      unit: unitId,
      lesson_group: lessonId,
      assigned_by: currentUserId,
      assigned_to: student.id,
    };

    unAssignLesson(sectionId, payload);
  };

  sort = (key, order) => {
    this.setState({
      sortBy: key,
      orderBy: order,
    });
  };

  initializePractice = () => {
    const { match, currentUserId, initializePracticeSession } = this.props;
    const { sectionId, lessonId, unitId } = match.params;
    initializePracticeSession(
      lessonId,
      sectionId,
      unitId,
      currentUserId,
      _,
      PRACTICE_TYPE.ADAPTIVE_PRACTICE,
    );
  };

  calculateButtonText = () => {
    const { userPracticeActivities } = this.props;
    if (!userPracticeActivities || !userPracticeActivities.length)
      return ['Practice', false];

    const isContinuePractice = userPracticeActivities.some((activity) => !activity.ended);
    const shouldShowViewResult = userPracticeActivities.some(
      (activity) => activity.ended,
    );

    if (isContinuePractice) return ['Continue Practice', shouldShowViewResult];

    return ['Additional Practice', true];
  };

  calculateSkillLevel = () => {
    const { userPracticeActivities, sectionPracticeList } = this.props;

    let activityList = userPracticeActivities;
    activityList = sectionPracticeList;

    if (!activityList || !activityList.length) return 0;
    const skillList = activityList
      .map((s) => s.skill)
      .map((e) => {
        const key = Object.keys(e).shift();
        const o = e[key];
        const userKey = Object.keys(o).shift();
        return o[userKey];
      });
    if (!skillList.length) return 0;
    return _round(
      skillList.reduce((acc, skill) => acc + skill.current, 0) / skillList.length,
      2,
    );
  };

  viewOverallReview = () => {
    const { history, match } = this.props;
    const { sectionId, unitId, courseId, subjectId } = match.params;

    history.push(
      `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice-review`,
    );
  };

  renderPracticeCovers = () => {
    const { lessons, userRole, isInitializing } = this.props;
    const pathNumber = Array.from(Array(7).keys());
    const [buttonText, isViewResult] = this.calculateButtonText();
    const [auto, autoMax] = this.calculateScore();

    return (
      <LessonManagementBlock signleBlock={userRole === ROLE_TYPE.STUDENT}>
        <LessonBlockIcon className="chalktalk-quiz">
          {pathNumber.map((number, index) => (
            <i className={`path${number}`} key={index} />
          ))}
        </LessonBlockIcon>
        <LessonBlockTitle>The lessons this practice covers</LessonBlockTitle>
        <LessonSection>{lessons.map(this.renderPracticeCoverItem)}</LessonSection>
        {userRole === ROLE_TYPE.STUDENT && (
          <UnitDetailScore>{`${auto}/${autoMax}`}</UnitDetailScore>
        )}
        {userRole === ROLE_TYPE.STUDENT && !!lessons.length && (
          <UnitDetailButtonGroup>
            <Button
              green
              full
              onClick={this.initializePractice}
              disabled={isInitializing}
            >
              {isInitializing ? 'Initializing' : buttonText}
            </Button>
            {isViewResult && (
              <Button blue full onClick={this.viewOverallReview}>
                View Results
              </Button>
            )}
          </UnitDetailButtonGroup>
        )}
      </LessonManagementBlock>
    );
  };

  renderStudentListHeaderItem = (item, index) => {
    const { value, uniqKey } = item;
    const { sortBy, orderBy } = this.state;
    const isFilterChoosen = sortBy === uniqKey;

    return (
      <TableHeader
        {...ColumnSize[index]}
        value={value}
        key={uniqKey}
        haveFilter={isFilterChoosen}
        onClick={() => this.sort(uniqKey, isFilterChoosen ? -orderBy : ORDER_BY.ASC)}
      />
    );
  };

  renderStudentListHeader = () => (
    <TableHeadWrapper>
      {tableValues.map(this.renderStudentListHeaderItem)}
      <TableHeader {...ColumnSize[4]} />
    </TableHeadWrapper>
  );

  renderStudentItem = (student) => {
    const { match, practiceSummaryByUserId, assignedStatusByUserId } = this.props;
    const { sectionId, unitId, courseId, subjectId } = match.params;
    const reviewUrl = `/lessons/course/${courseId}/section/${sectionId}/subject/${subjectId}/unit/${unitId}/practice-review/student/${student.id}`;
    const { id, first_name: firstName, last_name: lastName } = student;
    const name = `${firstName} ${lastName}`;
    const sumAuto = _get(practiceSummaryByUserId, `${id}.score`, '--');
    const sumAutoMax = _get(practiceSummaryByUserId, `${id}.maxScore`, '--');
    const timeOnTask = _get(practiceSummaryByUserId, `${id}.timeOnTask`, null);

    const userAssigned = _get(assignedStatusByUserId, id, null);
    const lastCompleted = _get(userAssigned, 'last_completed', null);
    const lastAssigned = _get(userAssigned, 'last_assigned', null);
    const newAssigned = _get(userAssigned, 'assigned', null);
    const isAssigned =
      newAssigned ||
      (!lastCompleted && lastAssigned) ||
      moment(lastAssigned).isAfter(moment(lastCompleted));
    const lastActivity = _get(practiceSummaryByUserId, `${id}.lastActivity`, null);
    const finalTot = timeOnTask !== null ? calcDurationToSecond(timeOnTask) : null;

    return (
      <TableContentItem smallSize key={id}>
        <TableContent {...ColumnSize[0]}>{name}</TableContent>
        <TableContent {...ColumnSize[1]}>
          {lastActivity ? moment(lastActivity).fromNow() : '--'}
        </TableContent>
        <TableContent {...ColumnSize[2]}>
          <Link to={reviewUrl}>{`${sumAuto}/${sumAutoMax}`}</Link>
        </TableContent>
        <TableContent {...ColumnSize[3]}>
          {timeOnTask !== null ? finalTot : '--'}
        </TableContent>
        <TableContent {...ColumnSize[4]}>
          {isAssigned ? (
            <LessonAssignStatus isAssigned>
              Assigned
              <UnAssignButton
                className="chalktalk-close"
                onClick={this.unAssignStudent(student)}
              />
            </LessonAssignStatus>
          ) : (
            <LessonAssignStatus onClick={this.assignStudent(student)}>
              Assign
            </LessonAssignStatus>
          )}
        </TableContent>
      </TableContentItem>
    );
  };

  renderStudentListReport = () => {
    const { students = [] } = this.props;
    const { sortBy, orderBy } = this.state;
    firstSort(students);
    const filterStudentList = _orderBy(
      students,
      [
        (d) => {
          if (sortBy === 'last_name') return d[sortBy].toUpperCase();
          return d[sortBy];
        },
      ],
      ORDER_BY[orderBy],
    );

    return (
      <LessonTableWrap>
        {this.renderStudentListHeader()}
        <TableContentWrapper>
          {filterStudentList.map(this.renderStudentItem)}
        </TableContentWrapper>
      </LessonTableWrap>
    );
  };

  calculateScore = () => {
    const { userPracticeActivities } = this.props;

    const [auto, autoMax] = userPracticeActivities.reduce(
      (acc, act) => {
        const score = _get(act, 'score.0.score', {}) || {};
        const { auto: autoScore = 0, auto_max: autoMaxScore = 0 } = score;
        acc[0] += autoScore;
        acc[1] += autoMaxScore;
        return acc;
      },
      [0, 0],
    );

    return [auto, autoMax];
  };

  render() {
    const {
      userRole,
      isGettingSectionUnit,
      isGetSectionPractice,
      isGettingUserPracticeSessionActivities,
    } = this.props;

    if (
      isGettingSectionUnit ||
      isGetSectionPractice ||
      isGettingUserPracticeSessionActivities
    ) {
      return <LoadingIndicator content="Loading" />;
    }

    return (
      <ShadowScrollbar autoHide style={scrollStyle}>
        <DefaultContentWrap>
          <LessonUnitPracticeWrap>
            {this.renderPracticeCovers()}
            {userRole === ROLE_TYPE.INSTRUCTOR && this.renderStudentListReport()}
          </LessonUnitPracticeWrap>
        </DefaultContentWrap>
      </ShadowScrollbar>
    );
  }
}

UnitPracticeDetail.propTypes = {
  practiceSummaryByUserId: PropTypes.object,
  lessons: PropTypes.array,
  students: PropTypes.array,
  match: PropTypes.objectOf(PropTypes.any),
  userRole: PropTypes.string,
  history: PropTypes.shape(),
  practiceId: PropTypes.number,
  isInitialized: PropTypes.bool,
  isInitializing: PropTypes.bool,
  assignLesson: PropTypes.func.isRequired,
  initializePracticeSession: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  getAllPracticeSessionActivities: PropTypes.func.isRequired,
  userPracticeActivities: PropTypes.array,
  sectionPracticeList: PropTypes.any,
  isGettingSectionUnit: PropTypes.bool,
  isGetSectionPractice: PropTypes.bool,
  isGettingUserPracticeSessionActivities: PropTypes.bool,
  unAssignLesson: PropTypes.func.isRequired,
  lessonAssignmentDetail: PropTypes.func,
  assignedStatusByUserId: PropTypes.object,
};

export default UnitPracticeDetail;
