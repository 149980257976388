/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const AuthorWidgetWrapper = styled.div`
  background: inherit;
  /* Common Rules */
  .lrn {
    font-family: 'MontserratRegular';
    color: #010101 !important;
    background: inherit;
    & .lrn-author-ui {
      border-radius: 12px;
      overflow: clip;
      & .lrn-btn-default-legacy {
        border-radius: 4px;
        background-color: #4786fc;
        height: 2.25rem;
        font-weight: 500;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-size: 0.875rem;
        line-height: 1.75;
        border-radius: 0.5rem;
        text-transform: capitalize;
        color: white;
        &:hover {
          text-decoration: none;
          background-color: rgb(49, 93, 176);
        }
      }
      & .lrn-popup {
        border-radius: 12px;
        background-color: white;
        top: 32px;
      }
      & .lrn-custom-select select {
        border-radius: 4px;
        &:hover {
          border-color: #4786fc;
        }
      }
    }
    & .lrn-form-control-wrapper {
      & > input[type='text'] {
        border-radius: 4px;
        &:hover {
          border-color: #4786fc;
        }
      }
    }
  }

  & .lrn.lrn-author {
    padding-bottom: 2rem;
    padding-inline: 1rem;
    font-family: 'Montserrat';
  }
  .lrn-author-ui {
    border-radius: 12px;
    overflow: clip;
  }
  .lrn-author-ui-no-preview {
    a,
    div,
    span {
      font-family: 'Montserrat';
    }
  }

  .lrn-ellipses {
    overflow: visible;
    white-space: break-spaces;
  }
  .lrn-list-view-heading {
    display: hidden;
  }
  .lrn-list-view-alternative-reference {
    display: none;
  }
  &.lrn.lrn-author .lrn-author-item-nav,
  [data-authorapi-selector='nav-bar'],
  .lrn.lrn-author .lrn-author-item-nav {
    background: white;

    [data-authorapi-selector='back'] {
      .lrn-i-previous {
        background: #4786fc;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: white;
        padding: 0.25rem;
        font-size: 0.6rem;
        :before {
          top: 0;
        }
      }
      color: #4786fc;
      font-weight: bold;
    }
  }
  a[type='button'] {
    color: #4786fc;
  }
  .lrn .lrn-author-ui-no-preview button {
    &.MuiButtonBase-root {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      box-sizing: border-box;
      background-color: transparent;
      outline: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      text-decoration: none;
      color: inherit;
      font-weight: 500;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      min-width: 64px;
      padding: 6px 16px;
      border-radius: 8px;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      color: rgba(255, 255, 255, 0.87);
      background-color: #4786fc;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      text-transform: capitalize;
    }
  }
  /* regex for pattern  */
  button[class^='lrn-btn-'] {
    border-radius: 0.5rem;
  }
  .lrn-tool-panel-search {
    background: white;
    border-radius: 10px 10px 0px 0px;
    button.lrn-btn-legacy.lrn-btn-lg {
      border-radius: 0.5rem;
      border-color: #4786fc;
      color: #4786fc;
      &[type='submit'] {
        background: #4786fc;
        color: white;
      }
    }
    .lrn-input-group .lrn-form-control {
      border-color: #4786fc;
      border-radius: 0.5rem 0 0 0.5rem;
      & + .lrn-input-group-btn {
        border-radius: 0 0.5rem 0.5rem 0;
        border-color: #4786fc;
      }
    }

    & .lrn-label-group {
      background: #ebebeb;
      display: flex;
      gap: 0.125rem;
      padding-inline: 0.25rem;
      border-radius: 12px;

      .lrn-label-group-inner {
        align-items: center;
      }

      .lrn-label {
        background: inherit;
        color: black;
        font-weight: bold;
        border: none;
      }
      button {
        background: #aeaeae;
        color: #ebebeb;
        border-radius: 60%;
        width: 1rem;
        height: 1rem;
        font-size: 8px;
        :before {
          top: 0;
        }
      }
    }
  }

  div[data-authorapi-view='pagination-1'] {
    display: none;
  }
  div[data-authorapi-view='pagination-2'] {
    & .lrn-pagination ul {
      background-color: white;
      border-radius: 0px 0px 10px 10px;
    }
    button {
      color: #4786fc;
    }
  }
  .lrn .lrn-author-ui div[data-authorapi-view='items'] {
    & .lrn-author-ui-no-preview {
      padding-inline: 0.5rem;
    }
    & .lrn-list-view-loaded {
      & .lrn-list-view-empty {
        padding-bottom: 2rem;
        margin-block: 0;
        max-width: 555px;
        margin: auto;
        display: flex;
        flex-direction: column-reverse;
        gap: 1rem;
        align-items: center;

        & .MuiBadge-badge {
          background-color: #f6a626;
        }

        & .lrn-list-view-empty-label {
          font-size: 1.25rem;
        }
        & .lrn-list-view-empty-img {
          background-image: url('/images/no-content.png');
          aspect-ratio: 16/9;
          width: 80%;
          margin: 0;
        }
      }
      background: white;
    }
    & .lrn-check-box-span {
      border-radius: 4px;
      border-color: #4786fc;
    }
    & .lrn-list-striped > li:nth-child(even) {
      background: transparent;
    }
    & .lrn-list-view-item button[data-authorapi-selector='item-tags-toggle'] {
      border-radius: 15px;
      background: white;
      border: 1px solid #bdbdbd;
      display: flex;
      align-items: center;
      padding-block: 8px;
      padding-inline: 10px;
      gap: 4px;

      & .lrn-i-tag:before {
        color: #bdbdbd;
      }
    }
    & .lrn-list-view-heading-wrapper a.lrn-list-view-heading {
      visibility: hidden;
      position: relative;
      width: 10rem;
      color: #4786fc;
      &::before {
        content: 'Preview question';
        position: absolute;
        width: 100%;
        height: 100%;
        background: white;
        top: 0;
        left: 0;
        z-index: 1;
        visibility: visible;
        color: inherit;
      }
      &:hover::before {
        color: #1662ef;
      }
      & * {
        visibility: visible;
      }
    }
  }

  /* Hide all optgroups except 'Questions' and 'Common' */
  select[name='widgetType'] optgroup:not([label='Questions']):not([label='Common']) {
    display: none;
  }

  /* Show only specific options */
  select[name='widgetType'] option {
    display: none;
  }

  // Show all options in Questions optgroup
  select[name='widgetType'] optgroup[label='Questions'] option[value='mcq'],
  select[name='widgetType'] option[value='clozeformulaV2'],
  select[name='widgetType'] optgroup[label='Questions'] option[value='clozeformula'],
  select[name='widgetType'] optgroup[label='Questions'] option[value='clozeassociation'],
  select[name='widgetType'] optgroup[label='Questions'] option[value='clozedropdown'],
  select[name='widgetType'] option[value='choicematrix'],
  select[name='widgetType'] option[value='clozetext'],
  select[name='widgetType'] option[value='orderlist'],
  select[name='widgetType'] option[value='tokenhighlight'] {
    display: block;
  }

  // Show sharedpassage option in Common optgroup
  select[name='widgetType'] optgroup[label='Common'] option[value='sharedpassage'] {
    display: block;
  }
`;
