export const ColumnSize = [
  { size: '125', smallSize: true },
  { smallSize: true },
  { size: '130', smallSize: true, textCenter: true },
  { size: '120', smallSize: true, textCenter: true },
  { percent: '20', smallSize: true, textCenter: true },
];

export const TeacherColumnSize = [
  { percent: '15', smallSize: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
  { smallSize: true, textCenter: true },
];
