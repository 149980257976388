import { useCurrentTab } from '../context/TabsContext';

function TabPanel({ tabKey, children }) {
  const currentTab = useCurrentTab();
  if (currentTab != tabKey) {
    return null;
  }
  return children;
}

export default TabPanel;
