/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  CreateOrJoinItemWrap,
  CreateOrJoinItem,
  CreateOrJoinTitle,
  ButtonWrapper,
  ButtonStyled,
} from './CourseCreatedModal.style';

class CourseCreatedModal extends PureComponent {
  handleRequestInviteCourse = (e) => {
    e.preventDefault();
    const { onClose } = this.props;
    const { requestInviteCourse, subscriptionData } = this.props;
    const { subscriptionCode } = subscriptionData || {};
    requestInviteCourse(subscriptionCode);
    onClose();
  }

  renderRedirecToCourse=() => {
    const { onClose } = this.props;
    const { requestInviteCourse, subscriptionData } = this.props;
    const { subscriptionCode } = subscriptionData || {};
    requestInviteCourse(subscriptionCode);
    onClose();
  }

  renderRequestSuccessMessage = () => (

    <CreateOrJoinItemWrap>
      <CreateOrJoinItem>
        <CreateOrJoinTitle>
          <h1>Your request was send successfully</h1>
          <p>An email was send to the course owner.</p>
          <ButtonWrapper>
            <ButtonStyled>Waiting for course owner to approve</ButtonStyled>
          </ButtonWrapper>
        </CreateOrJoinTitle>
      </CreateOrJoinItem>
    </CreateOrJoinItemWrap>
  )

  renderCourseCreatedMessage = () => (
    <CreateOrJoinItemWrap>
      <CreateOrJoinItem>
        <CreateOrJoinTitle>
          <h1>Course was already created.</h1>
          <p>Good news, the subscription code was already used to create a course.</p>
          <p>Request your invite to join the course by it&apos;s owner</p>
          <ButtonWrapper>
            <ButtonStyled onClick={this.handleRequestInviteCourse}>Join Course</ButtonStyled>
          </ButtonWrapper>
        </CreateOrJoinTitle>
      </CreateOrJoinItem>
    </CreateOrJoinItemWrap>
  )

  render() {
    this.renderRedirecToCourse();
  }
}

CourseCreatedModal.propTypes = {
  requestInviteCourse: PropTypes.func,
  subscriptionData: PropTypes.object,
  isRequestedInvite: PropTypes.bool,
};

export default CourseCreatedModal;
