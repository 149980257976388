import { eventChannel, END } from 'redux-saga';
import { take, call, put, select, takeEvery } from 'redux-saga/effects';
import { Link } from 'react-router-dom';
import timemachine from 'timemachine';
import React from 'react';
import { captureException, withScope } from '@sentry/react';

import PracticeEventEmitter from '@components/PracticeV2/utils/practiceEventEmitter';
import OLPGroupsEventEmitter from '@components/GroupActivityTable/utils/OLPGroupsEventEmitter';
import ActionCardEventEmitter from '@components/Component/OLP/tab/utils/ActionCardEventEmitter';
import ExamScoreEventEmitter from '@components/Component/Exams/ExamScoreEventEmitter';
import AuthorAideEventEmitter from '@components/QuestionGenerationService/utils/QuestionGenerationServiceEmitter';
import { getSearchParamsObject } from '@utils/commonUtils';
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_CLOSE_WEBSOCKET,
  AUTH_LOGOUT,
  AUTH_OPEN_WEBSOCKET,
  selectors as AuthSelectors,
  actions as AuthActions,
} from '../../reducers/auth';
import ReconnectWebsocket from '../../utils/reconnectWebsocket';
import { QUESTION_TYPES, SOCKET_TYPES } from '../../utils/enums';
import { actions as SectionExamActions } from '../../reducers/sectionExam';
import { actions as GroupActivityActions } from '../../reducers/groupActivity';
import { selectors as userSelectors } from '../../reducers/user';
import { actions as ExamResultActions } from '../../reducers/examResult';
import { actions as CurriculumLessonActions } from '../../reducers/curriculumLessons';
import { actions as SectionActions } from '../../reducers/sections';
import history from '../../utils/history';

import { info as infoToast } from '../../utils/toast';
import {
  gridinAnswersEqual,
  isNumerciallyEqual,
  isNumeric,
} from '../../utils/func-utils';
import { ONLINE_STATUS_ONLINE, ONLINE_STATUS_OFFLINE } from '../../reducers/system';

// Call reset to restore the override of the Date constructor which
// is changed from the import of timemachine
timemachine.reset();

let globalWs = null;
// eslint-disable-next-line prefer-destructuring
const WS_PROTOCOL = process.env.WS_PROTOCOL;
// eslint-disable-next-line prefer-destructuring
const WS_ENDPOINT = process.env.WS_ENDPOINT;

const removeOgaLocalStorage = () => {
  const ogaKeysToRemove = [
    'oga_student_data',
    'exam_questions',
    'localStatusStudentRegister',
    'studentsThatAnsweredOGA',
    'OGALessonsFinished',
    'oga_student_history',
  ];
  for (let i = 0; i < localStorage.length; i++) {
    if (localStorage.key(i).startsWith('oga_practice_')) {
      ogaKeysToRemove.push(localStorage.key(i));
    }
  }
  ogaKeysToRemove.forEach((k) => localStorage.removeItem(k));
};

function initWebsocket(token, role, location, userId) {
  return eventChannel((emitter) => {
    console.log('WS_PROTOCOL - ', WS_PROTOCOL, WS_ENDPOINT);
    const url = `${WS_PROTOCOL}://${WS_ENDPOINT}/ws/updates/?token=${token}`;
    globalWs = new ReconnectWebsocket(url, null, {
      reconnectInterval: 2000,
      maxReconnectAttempts: 3,
      automaticOpen: false,
      timeoutInterval: 4000,
    });
    globalWs.open(true);

    globalWs.onopen = () => {
      console.log('opening...');
      //      if (disconnectFlag) {
      /// /        toast.update(toastId, {
      /// /          render: "We got you back!",
      /// /          autoClose: 2000,
      /// /          type: toast.TYPE.SUCCESS,
      /// /          onClose: () => {
      /// /            window.location.reload();
      /// /          }
      /// /        });
      //        console.log("Connection to Websocket has been restored.");
      //        setTimeout(() => {
      //            window.location.reload();
      //        }, 500)
      //      }
      // temp call to ws echo to demo the connection working in both directions
      globalWs.send(JSON.stringify({ echo_message: 'hello server' }));
    };

    globalWs.onerror = (error) => {
      console.error(`WebSocket error ${JSON.stringify(error)}`);
      //      console.log("ID", toastId);
      //      if (toastId === null)
      //        toastId = toast.error(
      //          "We're having issues connecting you to ChalkTalk, We are retrying to connect you back, please hold on...",
      //          {
      //            position: toast.POSITION.BOTTOM_CENTER,
      //            autoClose: false
      //          }
      //        );
      console.dir(error);
    };

    globalWs.onclose = (event) => {
      if (event?.detail?.source === 'forcedClose') {
        return;
      }
      if (event?.detail?.source === 'unauthenticated') {
        emitter(AuthActions.authGetNewToken());
        emitter(END);
        return;
      }
      withScope((scope) => {
        scope.setContext('Error Information', {
          name: 'WebSocket closed.',
          message: 'WebSocket closed.',
          details: JSON.stringify(event),
        });
        scope.setTag('exception', 'WebSocket closed.');
        captureException('WebSocket closed.');
      });
      emitter(END);
      location.reload();
    };

    globalWs.onmessage = (e) => {
      let msg = null;
      try {
        msg = JSON.parse(e.data);
      } catch (event) {
        console.error(`Error parsing : ${event.data}`);
      }

      if (msg) {
        const { payload, event_type: eventType } = msg;
        console.log(`incoming message event_type:  "${eventType}". Payload: `, payload);
        // PracticeEventEmitter sends events to the practice page
        // check event in PracticeEvents enum
        PracticeEventEmitter.emit(eventType, payload);
        ActionCardEventEmitter.emit(eventType, payload);
        OLPGroupsEventEmitter.emit(eventType, payload);
        ExamScoreEventEmitter.emit(eventType, payload);
        AuthorAideEventEmitter.emit(eventType, payload);
        switch (eventType) {
          case SOCKET_TYPES.TEST_ECHO: {
            console.log('TEST_ECHO incoming event', role);
            timemachine.config({
              dateString: payload.date,
              tick: true,
            });
            return emitter({
              type: 'TEST_ECHO',
              payload,
            });
          }

          case SOCKET_TYPES.PAUSE: {
            const { session_id: examSessionId, section_id: examSectionId } = payload;
            return emitter(
              SectionExamActions.sectionExamPauseSuccess(examSessionId, examSectionId),
            );
          }

          case SOCKET_TYPES.UNLOCKED: {
            const { session_id: examSessionId, section_id: examSectionId } = payload;
            return emitter(
              SectionExamActions.sectionExamUnlockSuccess(examSessionId, examSectionId),
            );
          }

          case SOCKET_TYPES.RESET: {
            const { session_id: examSessionId, section_id: examSectionId } = payload;
            return emitter(
              SectionExamActions.sectionExamResetSuccess(examSessionId, examSectionId),
            );
          }

          case SOCKET_TYPES.EXAM_STARTED: {
            return emitter(ExamResultActions.examUpdateScore());
          }

          case SOCKET_TYPES.EXAM_COMPLETED: {
            return emitter(ExamResultActions.examUpdateScore());
          }

          case SOCKET_TYPES.EXAM_SCORED: {
            return emitter(ExamResultActions.examUpdateScore());
          }

          case SOCKET_TYPES.ENABLE_REVIEW: {
            const { session_id: examSessionId, section_id: examSectionId } = payload;
            return emitter(
              SectionExamActions.sectionExamReviewUnlockSuccess(
                examSessionId,
                examSectionId,
              ),
            );
          }

          case SOCKET_TYPES.DISABLE_REVIEW: {
            const { session_id: examSessionId, section_id: examSectionId } = payload;
            return emitter(
              SectionExamActions.sectionExamReviewLockSuccess(
                examSessionId,
                examSectionId,
              ),
            );
          }

          case SOCKET_TYPES.START_GROUP_ACTIVITY:
          case SOCKET_TYPES.UPDATE_STUDENT_GROUPINGS:
          case SOCKET_TYPES.UPDATE_STUDENT_ACTIVITY_STATUS: {
            const {
              id,
              student,
              students,
              score,
              section_activity,
              online_activity_status,
              time_activity_started,
              lesson_group,
              section,
              online_question,
              unit,
              curriculum_subject_id,
              curriculum_id,
              lesson,
              course_id,
              new_grouping,
              student_ids,
              user,
              groupings,
            } = payload;

            const ogaStudentHistory = JSON.parse(
              localStorage.getItem(`oga_student_history`),
            );
            let group_status;
            if (
              role === 'Student' &&
              eventType == SOCKET_TYPES.START_GROUP_ACTIVITY &&
              online_activity_status == 1
            ) {
              groupings.forEach((group) => {
                const groupFiltered = group.students.filter(
                  (student) => student.student_id == parseInt(userId),
                );
                if (groupFiltered.length > 0) {
                  group_status = group?.group_status;
                }
              });
              const ogaStudentData = {
                lessonGroup: lesson_group,
                status: group_status,
              };
              localStorage.setItem('oga_student_data', JSON.stringify(ogaStudentData));
            } else {
              localStorage.removeItem('oga_student_data');
            }

            if (eventType === SOCKET_TYPES.UPDATE_STUDENT_ACTIVITY_STATUS && student) {
              let localStatusStudentRegister =
                JSON.parse(localStorage.getItem('localStatusStudentRegister')) || {};

              let localStatusStudentRegisterGroup =
                localStatusStudentRegister[student.group_id] || {};

              localStatusStudentRegisterGroup[student.student_id] = student;

              localStorage.setItem(
                'localStatusStudentRegister',
                JSON.stringify({
                  ...localStatusStudentRegister,
                  [student.group_id]: { ...localStatusStudentRegisterGroup },
                }),
              );

              localStatusStudentRegister =
                JSON.parse(localStorage.getItem('localStatusStudentRegister')) || {};
              localStatusStudentRegisterGroup =
                localStatusStudentRegister[student.group_id] || {};

              const studentsListed = Object.keys(localStatusStudentRegisterGroup);
              let group_status = 0;
              if (role === 'Student') {
                if (studentsListed.length != student_ids.length) {
                  return;
                }
                group_status = 2;
                for (let i = 0; i < student_ids.length; i++) {
                  if (studentsListed[i] == userId) {
                    continue;
                  }
                  if (localStatusStudentRegisterGroup[studentsListed[i]].status == 1) {
                    return;
                  }
                }
                if (student_ids.length > 1) {
                  localStorage.setItem(
                    'oga_student_history',
                    JSON.stringify({
                      ...ogaStudentHistory,
                      status: 2,
                    }),
                  );
                }
                localStorage.setItem(
                  'group_stage_start_time',
                  JSON.stringify(Date.now()),
                );
              } else if (studentsListed.length != student_ids.length) {
                group_status = 1;
              } else {
                for (let j = 0; j < student_ids.length; j++) {
                  if (
                    localStatusStudentRegisterGroup[studentsListed[j]].status >
                    group_status
                  ) {
                    group_status =
                      localStatusStudentRegisterGroup[studentsListed[j]].status;
                  }
                }
              }

              emitter(
                GroupActivityActions.updateGroupStatus(
                  id,
                  students,
                  group_status,
                  score,
                  section_activity,
                  online_activity_status,
                  time_activity_started,
                  lesson_group,
                  section,
                  online_question,
                  unit,
                  curriculum_subject_id,
                  lesson,
                  course_id,
                  new_grouping,
                ),
              );
            } else if (eventType === SOCKET_TYPES.START_GROUP_ACTIVITY) {
              if (role === 'Teacher') {
                // refresh the attendance
                emitter(
                  CurriculumLessonActions.curriculumLessonGetAttendance(
                    lesson_group,
                    section,
                  ),
                );
                emitter(SectionActions.sectionsAttendanceGet(section));
              }
              if (online_activity_status !== 2) {
                if (role == 'Student') {
                  emitter(
                    GroupActivityActions.getGroupActivities(
                      section,
                      lesson_group,
                      lesson,
                      user,
                      unit,
                      curriculum_id,
                      false,
                      lesson,
                    ),
                  );
                }
                localStorage.setItem('activity_start_time', JSON.stringify(Date.now()));
              } else if (role === 'Student') {
                const { tab, 'pacing-guide-id': pacingGuideId } = getSearchParamsObject(
                  history.location.search,
                );
                const redirectLink = `/lessons/course/${course_id}/section/${section}/subject/${curriculum_subject_id}/unit/${unit}/lesson/${lesson_group}?tab=${tab}${
                  pacingGuideId ? `&pacing-guide-id=${pacingGuideId}` : ''
                }`;
                const url_string = location.href;
                const url = new URL(url_string);
                const listParams = url.pathname.split('/');
                const message = (
                  <span>
                    Your teacher has ended the Group Activity. &nbsp;
                    <Link to={redirectLink}>
                      Return to the activity page to review your answers and check your
                      score.
                    </Link>
                    &nbsp;
                  </span>
                );
                const sectionId = listParams[listParams.indexOf('section') + 1];
                const lessonId = listParams[listParams.indexOf('lesson') + 1];
                if (
                  listParams.includes('lessons') &&
                  parseInt(sectionId) === section &&
                  parseInt(lessonId) === lesson_group
                ) {
                  removeOgaLocalStorage();
                  localStorage.removeItem('activity_start_time');
                  infoToast('The teacher has ended the session.');
                  history.push(redirectLink);
                } else {
                  infoToast(message);
                }
              }
              emitter(
                GroupActivityActions.updateGroupActivityStatus(
                  online_activity_status,
                  lesson_group,
                ),
              );
            }

            emitter(
              GroupActivityActions.notifyGroupStatus(
                online_activity_status,
                course_id,
                curriculum_subject_id,
                section,
                unit,
                lesson_group,
              ),
            );

            return;
          }

          case SOCKET_TYPES.SCORE_CALCULATION_COMPLETED: {
            const { group_id, lesson_id, number_of_question } = payload;
            return emitter(
              GroupActivityActions.fetchStudentScore(
                2,
                lesson_id,
                parseInt(group_id),
                parseInt(number_of_question),
              ),
            );
          }

          case SOCKET_TYPES.UPDATE_STUDENT_INDIVIDUAL_ACTIVITY_ANSWER: {
            // TODO this object changed now it looks like
            // student_answer: [
            //  choice: number,
            //  exam_question_id: number,
            //  text: probably null,
            //  user_id: number
            // ],
            // students_number: number

            // TODO, save answers on local to check later if all of the students already answered

            // TODO right now the API is failing and returning the correct array of stundet_answer just for one student,
            // but all of the students are still receiving the same amount of questions answered websocket
            const { students_number, student_answer, section_id, lesson_id } = payload;
            {
              let questions = JSON.parse(localStorage.getItem('exam_questions') || '[]');
              student_answer.forEach((answer) => {
                const question = questions.find((q) => q.id === answer.exam_question_id);
                if (question) {
                  let individual_answers = [];
                  const answerAlreadyExists =
                    question.individual_answers &&
                    question.individual_answers.find(
                      (a) =>
                        a.exam_question_id === answer.exam_question_id &&
                        a.user_id === answer.user_id,
                    );
                  if (answerAlreadyExists) {
                    individual_answers = question.individual_answers.map((a) =>
                      a.exam_question_id === answer.exam_question_id &&
                      a.user_id === answer.user_id
                        ? answer
                        : a,
                    );
                  } else {
                    individual_answers = question.individual_answers
                      ? [...question.individual_answers, answer]
                      : [answer];
                  }
                  questions = questions.map((q) =>
                    q.id === question.id ? { ...question, individual_answers } : q,
                  );
                }
              });

              localStorage.setItem('exam_questions', JSON.stringify(questions));
            }
            if (role === 'Teacher') {
              emitter(
                CurriculumLessonActions.curriculumLessonGetAttendance(
                  lesson_id,
                  section_id,
                ),
              );
              emitter(SectionActions.sectionsAttendanceGet(section_id));
            }
            return emitter(
              GroupActivityActions.updateGroupActivityScoring(
                students_number,
                student_answer,
              ),
            );
          }

          case SOCKET_TYPES.UPDATE_STUDENT_GROUP_ACTIVITY_ANSWER: {
            const {
              students_number,
              data: { student_answer, exam_question, group },
              student_changed_answer,
            } = payload;

            let studentGroupActivityAnswers =
              JSON.parse(localStorage.getItem(`oga_practice_${group}_group__answers`)) ||
              {};
            const questions = JSON.parse(localStorage.getItem('exam_questions') || '[]');

            studentGroupActivityAnswers = {
              ...studentGroupActivityAnswers,
              [group]: { ...studentGroupActivityAnswers[group] },
            };

            if (Object.keys(studentGroupActivityAnswers[group]).length === 0) {
              questions.forEach((q) => (studentGroupActivityAnswers[group][q.id] = {}));
            }
            const { question } = questions.find(
              (question) => question.id === exam_question,
            );
            const { correct_choices } = question;

            if (question.question_format == QUESTION_TYPES.MULTIPLE_CHOICES) {
              student_answer.isCorrect = correct_choices.includes(
                student_answer.text_answer,
              );
            } else if (question.question_format == QUESTION_TYPES.GRID_IN) {
              student_answer.isCorrect = gridinAnswersEqual(
                correct_choices,
                student_answer.text_answer,
              );
            } else if (
              question.question_format == QUESTION_TYPES.SHORT_ANSWER &&
              isNumeric(correct_choices)
            ) {
              student_answer.isCorrect = isNumerciallyEqual(
                correct_choices,
                student_answer.text_answer,
              );
            } else {
              student_answer.isCorrect = true;
            }

            student_answer.studentName = student_changed_answer;
            studentGroupActivityAnswers[group][exam_question][student_answer.student_id] =
              student_answer;

            localStorage.setItem(
              `oga_practice_${group}_group__answers`,
              JSON.stringify(studentGroupActivityAnswers),
            );
            studentGroupActivityAnswers = JSON.parse(
              localStorage.getItem(`oga_practice_${group}_group__answers`),
            );

            const student_answers = Object.values(
              studentGroupActivityAnswers[group][exam_question],
            );

            const isSubmitted = student_answers.length === students_number;
            let hasSameAnswers = true;
            for (let i = 0; i < student_answers.length - 1; i++) {
              const answer1 = student_answers[i]?.text_answer;
              const answer2 = student_answers[i + 1]?.text_answer;
              if (
                question.question_format == QUESTION_TYPES.GRID_IN &&
                !gridinAnswersEqual(answer1, answer2)
              ) {
                hasSameAnswers = false;
                break;
              } else if (
                question.question_format == QUESTION_TYPES.SHORT_ANSWER &&
                isNumeric(question.correct_choices) &&
                !isNumerciallyEqual(answer1, answer2)
              ) {
                hasSameAnswers = false;
                break;
              } else if (
                question.question_format == QUESTION_TYPES.MULTIPLE_CHOICES &&
                answer1 !== answer2
              ) {
                hasSameAnswers = false;
                break;
              }
            }

            if (!hasSameAnswers && role === 'Student') {
              infoToast('Answers provided by the students in the group are not the same');
            }

            {
              let questions = JSON.parse(localStorage.getItem('exam_questions') || '[]');
              const question = questions.find((q) => q.id === exam_question);
              questions = question
                ? questions.map((q) =>
                    q.id === question.id
                      ? { ...question, group_answers: student_answers }
                      : q,
                  )
                : questions;
              localStorage.setItem('exam_questions', JSON.stringify(questions));
            }

            return emitter(
              GroupActivityActions.updateGroupActivityGroupScoring(
                student_answers,
                student_answer,
                exam_question,
                isSubmitted,
              ),
            );
          }
          //     case SOCKET_TYPES.ADDITIONAL_PRACTICE_ASSIGNED:
          //       return emitter({ type: ADDITIONAL_PRACTICE_ASSIGNED_BY_SOCKET, payload });
          //
          //     case SOCKET_TYPES.EXAM_UNLOCKED: {
          //       const { class_id: classId = null, course: { pk: courseId = null } } = payload;
          //       return emitter({
          //         type: UNLOCK_EXAM_CLASS_BY_SOCKET, classId, courseId, fromSocket: true,
          //       });
          //     }
          default:
            break;
          // nothing to do
        }
      }
      return null;
    };

    // unsubscribe function
    return () => {
      console.log('redux websocket off');
    };
  });
}

function* connectWebsocket() {
  const token = yield select(AuthSelectors.getToken);
  const role = yield select(userSelectors.getUserRole);
  const userId = yield select(userSelectors.getUserId);

  if (!token) {
    return;
  }
  if (!window.navigator.onLine) {
    return;
  }

  if (globalWs) {
    globalWs.close();
  }

  const channel = yield call(initWebsocket, token, role, location, userId);

  while (true) {
    const action = yield take(channel);
    yield put(action);
  }
}

function* closeWebsocket() {
  if (globalWs) {
    globalWs.close();
  }
}

export default function* websocketSaga() {
  yield takeEvery(
    [AUTH_LOGIN_SUCCESS, AUTH_OPEN_WEBSOCKET, ONLINE_STATUS_ONLINE],
    connectWebsocket,
  );
  yield takeEvery(
    [AUTH_CLOSE_WEBSOCKET, AUTH_LOGOUT, ONLINE_STATUS_OFFLINE],
    closeWebsocket,
  );
}
