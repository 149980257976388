import React, { createRef, lazy, useState } from 'react';
import { useGetSessionQuestions } from '../../Hooks/Query/index';

const ItemBankHarness = lazy(() =>
  import('../ItemBankHarness').then((module) => ({ default: module.ItemBankHarness })),
);

interface ReviewProps {
  userId: string;
  sessionId: string;
  activityId: string;
  name: string;
  title: string;
  subtitle: string;
  loading?: JSX.Element;
}

const Review: React.FC<ReviewProps> = ({
  userId,
  sessionId,
  activityId,
  name,
  title,
  subtitle,
  loading = <div>Pulling Practice Data...</div>,
}) => {
  const widgetRef = createRef<any>();
  const [hasError, setHasError] = useState(false);
  const { data: questions, error, isLoading } = useGetSessionQuestions(sessionId);

  const renderAssessment = !isLoading && questions?.length;

  if (error || hasError) {
    return <div>Something wen&apos;t wrong. Please try again</div>;
  }
  if (!renderAssessment) {
    return loading;
  }
  return (
    <ItemBankHarness
      ref={widgetRef}
      userId={userId}
      sessionId={sessionId}
      activityId={activityId}
      renderingType="assess"
      type="submit_practice"
      name={name}
      state="review"
      questionReferences={questions}
      config={{
        configuration: {
          lazyload: false,
          onsave_redirect_url: false,
          onsubmit_redirect_url: false,
        },
        questions_api_init_options: {
          attribute_overrides: {
            instant_feedback: false,
          },
          showCorrectAnswers: true,
          show_distractor_rationale: {
            per_question: 'incorrect',
            per_response: 'always',
          },
        },
        regions: 'main',
        subtitle,
        title,
      }}
      errorCallback={(errorOnItemBank: any) => {
        console.error(errorOnItemBank);
        setHasError(true);
      }}
    />
  );
};

export { Review, ReviewProps };
